(function() {
	const services = new WeakMap();

	class FacilitySelectionDropdownController {
		constructor(facilityListService, utilityService, ENVIRONMENT) {
			services.set(this, {
				facilityListService,
				utilityService,
				ENVIRONMENT,
			});

			this.currentPage = 0;
			this.pageSize = 20; // shows pagination and limits quantity of facilities per page according to this.pageSize
			this.isDevEnvironment = true;
		}

		$onInit() {
			!this.isDisabled && this.loadFacilities();
		}

		$onChanges({isDisabled}) {
			if (isDisabled && !isDisabled.isFirstChange() && !isDisabled.currentValue && !this.facilities) {
				this.loadFacilities();
			}
		}

		menuClickHandler(facility, $event) {
			this.menuClick({facility});
			$event.preventDefault();
			$event.stopPropagation();
		}

		numberOfPages(filteredFacilities) {
			return Array.isArray(filteredFacilities) ? Math.ceil(filteredFacilities.length / this.pageSize) : 0;
		}

		loadFacilities() {
			const {facilityListService, utilityService, ENVIRONMENT} = services.get(this);

			utilityService.getEnvironmentType().then(environment => {
				facilityListService.getFacilities((progress, items) => {
					this.facilities = items;

					if (environment !== ENVIRONMENT.DEV) {
						this.isDevEnvironment = false;
						this.pageSize = items.length;
					}
				});
			});
		}
	}

	angular.module('TISCC').component('facilitySelectionDropdown', {
		templateUrl: 'components/facility-selection-dropdown/facility-selection-dropdown.html',
		controller: FacilitySelectionDropdownController,
		bindings: {
			itemTitle: '@',
			isDisabled: '<',
			buttonLabel: '<',
			menuClick: '&',
		},
	});
})();
