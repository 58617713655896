import _get from 'lodash.get';
import {CPR_DATE_FORMATS} from '../../../chiller-performance-report-config';

const NO_DATA_VALUE = -1;
const services = new WeakMap();

(function() {
	class CprHeatmapComponentController {
		constructor($translate) {
			services.set(this, {$translate});
			this.heatMapDataThresholds = [0, 20, 40, 60, 80];
			this.heatMapDataColors = ['#F1F3F4', '#BADEA7', '#457D2F', '#EEA85E', '#CB7983', '#93544E'];
			this.legendItems = [];
			this.fullData = [];
			this.numberDaysInMonth = 0;
			this.isNoData = false;
			this.isDataReady = false;
		}

		$onInit() {
			const {$translate} = services.get(this);
			const dataAxesList = Object.values(this.heatMapData.axes);

			if (!dataAxesList || !dataAxesList.length) {
				this.isNoData = true;
				return;
			}
			const {additionalXLine} = _get(this.heatMapData, 'otherInfoFromUrl.includeCoolingDegreeDays') ? this.heatMapData : {};
			const data = dataAxesList[0].data;

			this.heatMapDataThresholds.concat(100).reduce((start, current, index) => {
				this.legendItems.push({
					label: $translate(start === NO_DATA_VALUE ? 'LABEL_TIMELINE_NO_DATA' : `${start} - ${current} %`),
					color: this.heatMapDataColors[index],
				});
				return current;
			}, NO_DATA_VALUE);

			const dataAsObject = data.reduce((obj, item) => {
				const date = item.x.clone().startOf('hour');
				const dateString = date.format(CPR_DATE_FORMATS.FULL_DATE_TIME_TZ_FORMAT);
				if (dateString !== item.dateString) {
					obj[dateString] = {
						...item,
						dateString,
						x: date,
					};
				} else {
					obj[item.dateString] = item;
				}
				return obj;
			}, {});

			const toDate = this.toDate.clone();
			const fromDate = toDate.clone().startOf('month');
			const currentMonth = fromDate.get('month');

			for (let date = fromDate.clone(); date.get('month') === currentMonth; date = date.clone().add(1, 'hour')) {
				const dateString = moment(date).format(CPR_DATE_FORMATS.FULL_DATE_TIME_TZ_FORMAT);

				if (dateString in dataAsObject) {
					this.fullData.push(dataAsObject[dateString]);
				} else {
					this.fullData.push({
						x: date,
						y: NO_DATA_VALUE,
						dateString,
						isAutoGenerated: true,
					});
				}
			}
			this.additionalXLine = additionalXLine;
			this.numberDaysInMonth = fromDate.daysInMonth();
			this.isDataReady = true;
		}
	}

	angular.module('TISCC').component('cprHeatmapComponent', {
		templateUrl: 'components/reports/chiller-performance/page/report-section/heatmap/cpr-heatmap-component.html',
		controller: CprHeatmapComponentController,
		bindings: {
			header: '<',
			description: '<',
			heatMapData: '<',
			toDate: '<',
		},
	});
})();
