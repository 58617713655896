const services = new WeakMap();

class AnalyticParameterService {
	constructor($http, locationDetailsService) {
		services.set(this, {$http, locationDetailsService});
	}

	async setAnalyticParameterValueInTisObjectScope({parameterId, tisObjectId, requestBody} = {}) {
		if (!parameterId || !tisObjectId || !requestBody) {
			throw new Error('Input arguments are incorrect.');
		}

		const {$http} = services.get(this);

		const response = await $http.post(`/ext_api/analytics-api/analytic/parameter/${parameterId}/value/tisObject/${tisObjectId}`, requestBody);

		return response.data;
	}

	async getAnalyticParametersByTisObjectIdsAndTisObjectProperties({hpath, tisObjectIds, from, to, timezone, timeZone} = {}) {
		if (!hpath || !tisObjectIds || !from || !to) {
			throw new Error('Input arguments are incorrect.');
		}

		const {$http, locationDetailsService} = services.get(this);

		timeZone = timeZone || timezone;
		tisObjectIds = Array.isArray(tisObjectIds) ? tisObjectIds : [tisObjectIds];

		const config = {
			headers: {
				TisObjectIds: tisObjectIds.join(','),
			},
			params: {
				hpath,
				startDate: from.format(),
				endDate: to.format(),
				timeZone: timeZone || locationDetailsService.getLocationTimezone(),
			},
		};

		const response = await $http.get('/ext_api/analytics-api/analytic/parameter', config);

		return response.data;
	}
}

angular.module('TISCC').service('analyticParameterService', AnalyticParameterService);
