import {CPR_DATE_FORMATS} from '../../chiller-performance-report-config';

const services = new WeakMap();

class MilestonesSettingsPopupController {
	constructor(locationDetailsService, helpers) {
		services.set(this, {locationDetailsService, helpers});
	}

	$onInit() {
		this.CPR_DATE_FORMATS = CPR_DATE_FORMATS;
		const {CPR_DETAILS_ACTION_TYPE} = services.get(this);

		this.itemMaxLength = 100;
		this.header = this.data.header || 'ENTER_MILESTONES';
		this.maxDt = this.data.maxDt.clone().add(100, 'year');
		this.newMilestone = this._getNewMilestone();
		this.originalMilestones = [];
		this.milestones = this.data.values.map(item => {
			item.actionDate = moment(item.actionDate);
			return Object.assign({}, item);
		});
		this.originalMilestones = this.milestones.map(item => {
			return Object.assign({}, item);
		});
	}

	addMilestone() {
		this.milestones = [...this.milestones, this.newMilestone];
		this.newMilestone = this._getNewMilestone();
	}

	get isAddButtonEnabled() {
		return this.newMilestone.actionItem && this.newMilestone.actionDate;
	}

	get isSaveButtonEnabled() {
		const isMilestonesNotEmpty = this.milestones.every(milestone => milestone.actionItem && milestone.actionDate);
		return isMilestonesNotEmpty && !angular.equals(this.milestones, this.originalMilestones);
	}

	removeMilestone(milestone) {
		this.milestones = this.milestones.filter(item => item !== milestone);
	}

	save() {
		this.modalInstance.close({
			milestones: this.milestones,
		});
	}

	cancel() {
		this.modalInstance.dismiss('cancel');
	}

	_getNewMilestone() {
		const {locationDetailsService, helpers} = services.get(this);
		return {
			actionItem: '',
			actionDate: moment()
				.tz(locationDetailsService.getLocationTimezone())
				.startOf('day'),
			id: helpers.generateUniqueId(),
		};
	}
}

export default {
	templateUrl: 'components/reports/chiller-performance/modals/milestones-settings/milestones-settings-popup.html',
	controller: MilestonesSettingsPopupController,
	bindings: {
		modalInstance: '<',
		data: '<',
	},
};
