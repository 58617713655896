/* eslint quotes: [0, 'single'] */
/* eslint eqeqeq: [0, 'always'] */
/* eslint semi: [0, 'always'] */
import _get from 'lodash.get';

angular.module('TISCC').controller('FormatChartCtrl', FormatChartCtrl);

function FormatChartCtrl(
	$scope,
	$modalInstance,
	$translate,
	$timeout,
	LINE_TYPE,
	LINE_THICKNESS,
	PALETTE,
	MARKER_TYPE,
	MARKER_SIZE,
	data,
	helpers,
	onApplyHandler
) {
	const lineSettings = ['lineType', 'lineThickness', 'markerType', 'markerSize'];
	let changesMap = {};
	let isAxisRangeChanged = false;
	let eventObject = data.eventObject;
	const chartConfig = data.chartConfig;

	$scope.lines = data.lines;
	$scope.yAxes = data.yAxes.map(yAxisItem => {
		const {calculatedRange} = yAxisItem;

		if (checkIfAxisRangeIsValid(calculatedRange)) {
			yAxisItem.customRange = [].concat(calculatedRange);
			yAxisItem.isCustomRangeValid = true;
		} else {
			yAxisItem.isSkip = true;
		}

		return yAxisItem;
	});
	$scope.defaults = {
		lineType: LINE_TYPE.SOLID,
		lineThickness: LINE_THICKNESS.SIZE_1,
		markerType: MARKER_TYPE.DOT.name,
		markerSize: MARKER_SIZE.SIZE_1,
	};
	$scope.selectedLineType = $scope.defaults.lineType;
	$scope.selectedLineThickness = $scope.defaults.lineThickness;
	$scope.selectedMarkerType = $scope.defaults.markerType;
	$scope.selectedMarkerSize = $scope.defaults.markerSize;
	$scope.selectedColor = {
		palette: PALETTE,
		color: null,
	};
	$scope.lineTypes = Object.values(LINE_TYPE);
	$scope.lineThicknessValues = Object.values(LINE_THICKNESS);
	$scope.markerTypes = MARKER_TYPE;
	$scope.markerSizes = MARKER_SIZE;
	$scope.isApplyButtonEnabled = false;
	$scope.isApplyFeedbackVisible = false;
	$scope.isCustomRangesValid = true;
	$scope.selectLine = selectLine;
	$scope.selectAll = selectAll;
	$scope.setLineSetting = setLineSetting;
	$scope.getLineTypeCode = getLineTypeCode;
	$scope.getSizeObject = getSizeObject;
	$scope.changeAxisRange = changeAxisRange;
	$scope.onApply = onApply;
	$scope.cancel = cancel;

	$modalInstance.result.catch(onDestroy);

	function selectLine(line) {
		$scope.selectedProperty = line;
		$scope.allSelected = false;
		$scope.isApplyFeedbackVisible = false;
		lineSettings.forEach(setting => {
			setLineSetting(setting, line[setting] || $scope.defaults[setting], false);
		});
		if (!line.defaultColor) {
			line.defaultColor = line.color;
		}
		// reset color prop value with string to trigger watch ('selectedColor.color') function defined below.
		$scope.selectedColor.color = '';
		$timeout(() => {
			$scope.selectedColor.reloaded = true;
			$scope.selectedColor.color = $scope.selectedProperty.color;
		});
		$scope.selectedColor.defaultColor = line.defaultColor;
	}

	function selectAll() {
		lineSettings.forEach(setting => {
			let value = null;
			if ($scope.lines.every(line => !line[setting] || line[setting] === $scope.defaults[setting])) {
				value = $scope.defaults[setting];
			} else if ($scope.lines.every((line, _, array) => line[setting] && array[0][setting] === line[setting])) {
				value = $scope.lines[0][setting];
			}
			setLineSetting(setting, value, false);
		});
		$scope.selectedProperty = $translate('FILTER_ALL');
		$scope.allSelected = true;
		$scope.isApplyFeedbackVisible = false;
		$scope.lines.forEach(line => {
			if (!line.defaultColor) {
				line.defaultColor = line.color;
			}
		});
		if ($scope.lines.every((line, _, array) => array[0].color === line.color)) {
			$scope.selectedColor.color = $scope.lines[0].color;
		} else {
			$timeout(() => {
				$scope.selectedColor.reloaded = true;
				$scope.selectedColor.color = '';
			});
		}
		$scope.selectedColor.defaultColor = false;
	}

	function refreshApplyButtonState(isApplyFeedbackVisible, isChangeFeedbackVisibility = true) {
		$scope.isApplyButtonEnabled = isApplyFeedbackVisible;

		if (isChangeFeedbackVisibility) {
			$scope.isApplyFeedbackVisible = !isApplyFeedbackVisible;
		}
	}

	function setLineSetting(name, value, refresh = true) {
		$scope['selected' + helpers.capitalizeFirstLetter(name)] = value;
		refresh && refreshApplyButtonState(true);
	}

	function getLineTypeCode(lineType) {
		return Object.keys(LINE_TYPE).find(key => LINE_TYPE[key] === lineType);
	}

	function getSizeObject(value) {
		return {
			pixels: value,
		};
	}

	function changeAxisRange() {
		isAxisRangeChanged = true;
		refreshApplyButtonState(true);

		if (!$scope.isCustomRangesValid) {
			$scope.isCustomRangesValid = true;
			$scope.yAxes.forEach(yAxisItem => (yAxisItem.isCustomRangeValid = true));
		}
	}

	function onApply() {
		const addChangesToChangesMap = ({lineHash, lineType, lineThickness, markerType, markerSize, color}) =>
			(changesMap[lineHash] = {
				lineType,
				lineThickness,
				markerType,
				markerSize,
				color,
			});

		const checkIsCustomRangesValid = () => {
			$scope.isCustomRangesValid = true;

			$scope.yAxes.forEach(yAxisItem => {
				if (!yAxisItem.isSkip && !checkIfAxisRangeIsValid(yAxisItem.customRange)) {
					yAxisItem.isCustomRangeValid = false;
					$scope.isCustomRangesValid = false;
				}
			});

			return $scope.isCustomRangesValid;
		};

		if (!checkIsCustomRangesValid()) {
			return refreshApplyButtonState(false, false);
		}

		if ($scope.allSelected) {
			$scope.lines.forEach(line => {
				line.lineType = $scope.selectedLineType || line.lineType;
				line.lineThickness = $scope.selectedLineThickness || line.lineThickness;
				line.color = $scope.selectedColor.color || line.color;
				line.markerType = $scope.selectedMarkerType || line.markerType;
				line.markerSize = $scope.selectedMarkerSize || line.markerSize;
				addChangesToChangesMap(line);
			});
		} else if ($scope.selectedProperty) {
			$scope.selectedProperty.lineType = $scope.selectedLineType;
			$scope.selectedProperty.lineThickness = $scope.selectedLineThickness;
			$scope.selectedProperty.markerType = $scope.selectedMarkerType;
			$scope.selectedProperty.markerSize = $scope.selectedMarkerSize;
			$scope.selectedProperty.color = $scope.selectedColor.color || $scope.selectedProperty.color;
			addChangesToChangesMap($scope.selectedProperty);
		}

		onApplyHandler({
			allSelected: $scope.allSelected,
			lineHash: !$scope.allSelected && _get($scope, 'selectedProperty.lineHash'),
			changesMap,
			defaults: $scope.defaults,
			color: $scope.selectedColor,
			formattingProps: {...chartConfig.formattingProps},
		});
		applyFormatting(changesMap);
	}

	function applyFormatting(changesMap) {
		chartConfig.setFormattingProps(changesMap);
		eventObject.emit('formatChart', changesMap);
		isAxisRangeChanged && eventObject.emit('updateAxisRange');
		refreshApplyButtonState(false);
	}

	function cancel() {
		$modalInstance.dismiss('cancel');
	}

	function checkIfAxisRangeIsValid(range = []) {
		const [minValue, maxValue] = range;
		let isValid = true;

		if (!helpers.isNumber(minValue) || !helpers.isNumber(maxValue) || minValue > maxValue) {
			isValid = false;
		}

		return isValid;
	}

	function onDestroy() {
		$scope.yAxes.forEach(yAxisItem => {
			delete yAxisItem.customRange;
			delete yAxisItem.isCustomRangeValid;
			delete yAxisItem.isSkip;
		});
	}

	$scope.$watch('selectedColor.color', function(newColor, oldColor) {
		if ($scope.selectedColor.reloaded) {
			$scope.selectedColor.reloaded = false;
			$scope.isApplyButtonEnabled = false;
			$scope.isApplyFeedbackVisible = false;
		} else if (newColor && newColor !== oldColor) {
			refreshApplyButtonState(true);
		}
	});
}
