angular.module('TISCC').controller('EditPropertiesCtrl', EditPropertiesCtrl);

function EditPropertiesCtrl(
	$scope,
	$rootScope,
	$routeParams,
	$timeout,
	$filter,
	$modalInstance,
	chartConfigService,
	tisObjectTypePropertiesService,
	locationEquipmentService,
	rawDataService,
	rawDataServerlessService,
	translateService,
	configService,
	helpers,
	data,
	onApplyHandler,
	ENVIRONMENT
) {
	const {chartConfig, originalAxesUoms} = data;
	const previousChartConfigState = chartConfigService.createNew(chartConfig.customChartProps);
	const MAX_AXES_COUNT = 4;

	$scope.environment = configService.getEnvironmentType();
	$scope.isProdEnv = $scope.environment === ENVIRONMENT.PROD;
	if (!$scope.isProdEnv) rawDataService = rawDataServerlessService;

	let translate = $filter('translate');
	let checkedPropertyNames = {};
	let defaultEquipment = {name: translate('OPTIONS_SELECT_ONE'), initValue: true};
	let prevRightListData = [];
	let prevLeftCheckedCount = null;
	let activeUoms = [...originalAxesUoms];
	const ENUMERATED = 'Enumerated';

	$scope.viewModel = {
		checkAllRight: false,
		checkAll: false,
		isEquipmentTypesLoading: true,
	};
	$scope.locationId = $routeParams.locationId ? $routeParams.locationId : data.locationData.locationId;
	$scope.defaultEquipment = data.defaultSelection || null;
	$scope.isAddChildPrefixToHpath = true;
	$scope.popupLeftListData = [];
	$scope.popupRightListData = [];
	$scope.filteredPopupRightListData = [];
	$scope.filteredPopupLeftListData = [];
	$scope.selectedEquipmentType = defaultEquipment;
	$scope.updateSelectedEquipmentType = updateSelectedEquipmentType;
	$scope.processSubMenu = processSubMenu;
	$scope.selectDeselectAll = selectDeselectAll;
	$scope.selectDeselectRight = selectDeselectRight;
	$scope.updateRightCheckboxes = updateRightCheckboxes;
	$scope.updateSelectAllBtn = updateSelectAllBtn;
	$scope.updateCheckboxes = updateCheckboxes;
	$scope.filterDisabledItems = filterDisabledItems;
	$scope.onApply = handleApplyButtonClick;
	$scope.cancel = cancel;

	initializeData();

	function initializeData() {
		if (!$scope.selectedEquipmentType.initValue) {
			filterLeftPanelData();
		} else {
			updateSelectAllBtn();
		}

		locationEquipmentService.getLocationObjectsList($scope.locationId, null, true).then(function(locationData) {
			data.equipmentsData = locationData.tisObjectList;

			$scope.equipmentTypes = tisObjectTypePropertiesService.getEquipmentTypes(locationData.tisObjectList);

			$scope.viewModel.isEquipmentTypesLoading = false;

			// Below condition is for Equipment level performance chart So dont check below condition for facility level (data.isFacilityChartOnly.)
			if (!data.isFacilityChartOnly && $scope.defaultEquipment && (data.isChart || data.isEquipmentSummary)) {
				checkEquipments(data.equipmentsData, $scope.defaultEquipment.tisObjectName);
				$scope.updateSelectedEquipmentType(getSelectedEquipmentType());
			}
		});
	}

	function getSelectedEquipmentType() {
		let equipments = data.equipmentsData;
		let defaultEquipment = $scope.defaultEquipment;
		let foundParent = null;

		const groupName = defaultEquipment.tisObjectType.tisObjectTypeGroupName;

		if (['LoadValve', 'VAV-BOX'].includes(groupName)) {
			foundParent = findParent(equipments, defaultEquipment.tisObjectId);
		}
		let equipment = foundParent || defaultEquipment;
		return {
			name: translateService.translateEquipment(equipment.tisObjectType.tisObjectTypeGroupName),
			id: equipment.tisObjectId,
			groupId: equipment.tisObjectType.tisObjectTypeGroupNumber,
			typeName: $scope.defaultEquipment.tisObjectType.tisObjectTypeGroupName,
			sortBy: 1,
		};
	}

	function findParent(equipments, id, parentEq) {
		let foundParent = null;
		for (let i = 0; i < equipments.length; i++) {
			let equipment = equipments[i];

			if (equipment.tisObjectId === id && parentEq) {
				foundParent = parentEq;
				break;
			} else if (equipment.children) {
				foundParent = findParent(equipment.children, id, equipment);
				if (foundParent) {
					break;
				}
			}
		}
		return foundParent;
	}

	function filterDisabledItems(listData) {
		return listData.filter(item => !item.disabled);
	}

	function processSubMenu(items, $event, side = 'left') {
		if (items && items.length && items.length !== 0) {
			$rootScope.showSubMenu(items, $event, side);
		} else {
			$rootScope.hideSubMenu();
		}
	}

	function checkEquipments(equipments, name) {
		equipments.forEach(function(equipment) {
			if (equipment.tisObjectName === name) {
				equipment.checked = true;
			}
			if (equipment.children) {
				checkEquipments(equipment.children, name);
			}
		});
	}

	// ----------------- LEFT PANEL --------------------------------
	function selectDeselectRight(status) {
		((this && this.filteredPopupRightListData) || []).forEach(property => {
			if (!property.disabled) {
				property.checked = status;
			}
		});
		refreshApplyButtonState();
		disablePropertiesWithDifferentUom();
	}

	function filterLeftPanelData() {
		let leftPanelData = tisObjectTypePropertiesService.getLeftPanelData({
			defaultEquipment: $scope.defaultEquipment,
			selectedEquipmentType: $scope.selectedEquipmentType,
			tisObjects: data.equipmentsData,
		});
		rawDataService.setCurrentSelectedElements(leftPanelData.selectedElements);

		$scope.rightListLoadingPromise = null;
		$scope.popupLeftListData = leftPanelData.preparedTisObjects;
		$scope.selectedEquipmentType = leftPanelData.selectedEquipmentType;

		updateSelectAllBtn();
	}

	function updateSelectAllBtn() {
		let checkedCount = getCheckedItemsCount($scope.popupLeftListData);

		if (!checkedCount) {
			$scope.viewModel.checkAll = false;
			selectDeselectRight.call(this, false);
			$scope.viewModel.checkAllRight = false;
		} else if ($scope.popupLeftListData.length === checkedCount || $scope.isCellLimitExceeded) {
			$scope.viewModel.checkAll = true;
		} else {
			$scope.viewModel.checkAll = null;
		}

		prevRightListData = $scope.popupRightListData;
		$scope.popupRightListData = [];
		$scope.rightListLoadingPromise = tisObjectTypePropertiesService
			.getRightPanelData($scope.popupLeftListData, $scope.isAddChildPrefixToHpath)
			.then(successRightListLoaded);

		updateRightSelectAllBtn();
	}

	function isRightListActuallyUpdated(oldList, newList) {
		let checkedCount = getCheckedItemsCount($scope.popupLeftListData);

		// check if anything has changed on left panel list
		if (prevLeftCheckedCount === null) {
			prevLeftCheckedCount = checkedCount;
		}
		if (checkedCount !== prevLeftCheckedCount) {
			return true;
		}

		if (oldList.length !== newList.length) {
			return true;
		}

		const oldListProps = helpers.mapToObject(oldList, prop => [prop.propertyName, true]);

		const oldListPropsResolution = helpers.mapToObject(oldList, prop => [prop.propertyName, prop.digitResolution]);

		for (let i = 0; i < newList.length; i++) {
			if (!oldListProps[newList[i].propertyName]) {
				return true;
			}
			if (oldListPropsResolution[newList[i].propertyName] !== newList[i].digitResolution) {
				return true;
			}
		}

		return false;
	}

	function successRightListLoaded(rightPanelProperties) {
		if (Array.isArray(rightPanelProperties)) {
			rightPanelProperties = rightPanelProperties.filter(prop => prop.dataType !== ENUMERATED);
		}

		const handleProperties = properties => {
			$scope.popupRightListData = properties;
			disableTimelineProperties(properties);
		};

		if (isRightListActuallyUpdated(prevRightListData, rightPanelProperties)) {
			handleProperties(rightPanelProperties);
			handleRightListUpdated();
		} else {
			handleProperties(prevRightListData);
			disablePropertiesWithDifferentUom();
			updateRightSelectAllBtn();
		}

		$scope.rightListLoadingPromise = null;
	}

	function updatePropertiesUomText() {
		if (activeUoms.length === MAX_AXES_COUNT) {
			const MAX_UOM_COUNT_TO_USE_OR = 2;
			let uomDisplayNames = activeUoms.map(uom => `(${uom.symbol})`);
			let joiner = uomDisplayNames.length > MAX_UOM_COUNT_TO_USE_OR ? ', ' : translate('OR');

			$scope.propertiesUomsText = translate('ONLY_PROPERTIES_WITH_RIGHT_UOM', {
				uoms: uomDisplayNames.join(joiner),
				color: `<span class="highlighted-blue">${translate('ORIGINAL_CHART_PROPS_COLOR')}</span>`,
			});
		} else {
			$scope.propertiesUomsText = translate('CURRENT_CHART_PROPERTIES_SHOWN', {
				color: `<span class="highlighted-blue">${translate('ORIGINAL_CHART_PROPS_COLOR')}</span>`,
			});
		}
		$scope.selectedEquipmentsText = translate('CURRENT_CHART_EQUIPMENTS_ARE_SHOWN', {
			color: `<span class="highlighted-blue">${translate('ORIGINAL_CHART_PROPS_COLOR')}</span>`,
		});
	}

	function handleRightListUpdated() {
		$scope.popupRightListData.forEach(setCheckBoxInitialState);

		disablePropertiesWithDifferentUom();
		$scope.popupRightListData.sort(rightListPropertiesComparator);
	}

	function updateCheckboxes() {
		$timeout(updateSelectAllBtn);
	}

	function selectDeselectAll(status) {
		let selectionLength = (this.filteredPopupLeftListData || []).length;
		for (let i = selectionLength; i--; ) {
			this.filteredPopupLeftListData[i].checked = status;
		}

		if (!status) {
			selectDeselectRight.call(this, false);
			$scope.viewModel.checkAllRight = false;
		} else {
			updateCheckboxes();
			selectDeselectRight.call(this, false);
		}
	}

	function setCheckBoxInitialState(propertyFromList) {
		const selectedTypeName = $scope.selectedEquipmentType.typeName;
		const allIds = chartConfig.getAllTisObjectIdsByType(selectedTypeName);

		function validator(propInstancesTisObjectId) {
			return function callback({instanceTisObjectId = {}}) {
				return Object.keys(propInstancesTisObjectId || {}).some(id => instanceTisObjectId[id]);
			};
		}

		propertyFromList.highlighted = allIds.some(id => {
			const isAlreadySelected = chartConfig.validateCustomPropertyWithOwnTisObjectId(
				id,
				propertyFromList.propertyName,
				null,
				validator(propertyFromList.instancesTisObjectId)
			);

			// if property is already selected and stored on customProps object, isAlreadySelected will be 2 and highlight the prop
			if (isAlreadySelected === 2) return true;

			const orignalProp = chartConfig.hasOriginalProperty(id, propertyFromList.propertyName, selectedTypeName);
			return orignalProp;
		});
	}

	function disablePropertiesWithDifferentUom() {
		activeUoms = [...originalAxesUoms];

		const equipmentTypeNames = chartConfig.getEquipmentTypes();
		const isExistingAxisUom = uom => activeUoms.some(item => item.name === uom.name);
		equipmentTypeNames.forEach(equipmentTypeName => {
			const customAddedUoms = chartConfig.getCustomAddedUoms(equipmentTypeName);
			activeUoms.push(...customAddedUoms.filter(uom => !isExistingAxisUom(uom)));
		});
		$scope.popupRightListData.forEach(property => {
			if (property.isTimelineProperty) return;
			if (!isExistingAxisUom(property.uom) && (property.checked || property.highlighted) && activeUoms.length < MAX_AXES_COUNT) {
				activeUoms.push(property.uom);
			}
		});
		$scope.popupRightListData.forEach(property => {
			if (property.isTimelineProperty) return;
			const isCorrectPropertyUom = isExistingAxisUom(property.uom);
			if (activeUoms.length === MAX_AXES_COUNT) {
				property.disabled = !isCorrectPropertyUom;
			} else {
				property.disabled = false;
			}
			if (property.disabled && !isCorrectPropertyUom) {
				property.checked = false;
			}
		});
		updatePropertiesUomText();
	}

	function isApplyButtonActive(initialState, current) {
		for (let i = 0; i < current.length; i++) {
			if ((current[i].checked && !initialState[current[i].value]) || (!current[i].checked && initialState[current[i].value])) {
				return true;
			}
		}

		return false;
	}

	function refreshApplyButtonState(isApplyFeedbackVisible) {
		$scope.isApplyButtonEnabled =
			isApplyButtonActive(checkedPropertyNames, $scope.popupRightListData) && $scope.popupLeftListData.filter(e => e.checked).length;
		$scope.isApplyFeedbackVisible = isApplyFeedbackVisible;
	}

	function handleApplyButtonClick() {
		onApplyHandler && onApplyHandler($scope.popupRightListData.filter(({checked}) => checked).map(({propertyName}) => propertyName));

		checkedPropertyNames = $scope.popupRightListData.reduce((obj, property) => {
			if (property.checked) {
				obj[property.value] = true;
			}

			return obj;
		}, {});

		const updateData = {
			propertyList: $scope.popupRightListData,
			tisObjectList: $scope.popupLeftListData,
			equipmentTypeName: $scope.selectedEquipmentType.typeName,
		};

		chartConfigService.update(chartConfig, updateData);

		const selectOne = {name: translate('OPTIONS_SELECT_ONE'), initValue: true};

		updateSelectedEquipmentType(selectOne);
		refreshApplyButtonState(true);
	}

	function handleDialogClosed() {
		if (!chartConfig.equals(previousChartConfigState)) {
			$rootScope.$broadcast('reloadChart', {chartConfig, editProperties: 1});
		}
	}

	// ----------------- RIGHT PANEL --------------------------------

	function getCheckedItems(items = []) {
		return $filter('filter')(items, {checked: true});
	}

	function getCheckedItemsCount(items) {
		return getCheckedItems(items).length;
	}

	function updateRightSelectAllBtn() {
		let currentData = $scope.isSearchActive ? this.filteredPopupRightListData || [] : $scope.popupRightListData;
		let checkedPropertiesCount = getCheckedItemsCount(currentData);
		let propertiesCount = $filter('filterOutCharacteristics')(currentData.filter(item => !item.disabled)).length;

		if (!checkedPropertiesCount) {
			$scope.viewModel.checkAllRight = false;
		} else if (propertiesCount === checkedPropertiesCount) {
			$scope.viewModel.checkAllRight = true;
		} else {
			$scope.viewModel.checkAllRight = null;
		}
	}

	function updateRightCheckboxes(item) {
		disablePropertiesWithDifferentUom();
		refreshApplyButtonState(false);
		$timeout(updateRightSelectAllBtn);
	}

	function disableTimelineProperties(properties = []) {
		const {timelines = []} = data;
		const timelinePropertyNames = timelines.map(timelines => timelines.propertyName);

		properties.forEach(property => {
			if (timelinePropertyNames.includes(property.propertyName)) {
				property.isTimelineProperty = true;
				property.disabled = true;
				property.checked = false;
			}
		});
	}

	function handleRightListLoaded() {
		checkedPropertyNames = {};

		handleRightListUpdated();

		const customIds = chartConfig.getCustomTisObjectIdsByType($scope.selectedEquipmentType.typeName) || [];
		const originalIds = chartConfig.getOriginalTisObjectIds();
		const ids = new Set([...customIds, ...originalIds]);

		$scope.popupLeftListData.forEach(item => {
			if (ids.has(item.tisObjectId)) {
				item.highlighted = true;
			}
			if (originalIds.includes(item.tisObjectId)) {
				item.checked = true;
			}
		});

		refreshApplyButtonState(false);
		$scope.updateSelectAllBtn();
	}

	function rightListPropertiesComparator(propertyOne, propertyTwo) {
		if (propertyOne.highlighted && !propertyTwo.highlighted) {
			return -1;
		}

		if (!propertyOne.highlighted && propertyTwo.highlighted) {
			return 1;
		}

		if (propertyOne.disabled && !propertyTwo.disabled) {
			return 1;
		}

		if (!propertyOne.disabled && propertyTwo.disabled) {
			return -1;
		}

		return propertyOne.value < propertyTwo.value ? -1 : 1;
	}

	function updateSelectedEquipmentType(equipmentType) {
		$scope.selectedEquipmentType = equipmentType;
		filterLeftPanelData();
		handleRightListLoaded();
	}

	function cancel() {
		handleDialogClosed();
		$modalInstance.dismiss('cancel');
	}
}
