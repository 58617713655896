export const USER_EVENTS = {
	EQUIPMENT_SUMMARY_PAGE: {
		categoryName: 'TCC4 Equipment Summary',
		events: {
			GO_TO_NOTES: 'Go to Notes',
			GO_TO_SERVICE_ADVISORIES: 'Go to Service Advisories',
			GO_TO_DIGITAL_INSPECTION: 'Go to Digital Inspection',
			GO_TO_PERFORMANCE_CHART: 'Go to Performance Chart',
			VIEW_FACILITY_MAP: 'View Facility Map',
			VIEW_WEATHER_DETAILS: 'View Weather Details',
			APPLY_FILTER: 'Apply Filter',
			REMOVE_FILTER: 'Remove Filter',
			EXPAND_TEST_RESULTS_VIEW: 'Expand Test Results View',
			CLICK_ON_PAGINATION_OF_TEST_RESULTS: 'Click on Pagination of Test Results',
			CHANGE_DATES_OF_TEST_RESULTS: 'Change Dates of Test Results',
			SEARCH_TESTS: 'Search Tests',
			VIEW_SUGGESTIONS: 'View Suggestions',
			SELECT_AUTOMATED_TEST: 'Select Automated Test',
		},
		properties: {
			BUILDING_ID: 'BuildingID',
			BUILDING_SALES_OFFICE: 'Building Sales Office',
			BUILDING_OFFERINGS: 'Building Offerings',
			COLUMN: 'Column',
			DIRECTION: 'Direction',
			EQUIPMENT_TYPE: 'Equipment Type',
			TEST_NAME: 'Test Name',
			OFFERING: 'Offering',
			TEST_STATUS: 'Latest Test Status',
			DATE: 'Date',
		},
	},
};
