angular.module('TISCC').service('objectTypeService', function($http, cacheService, handleServiceWorker) {
	this.getObjectTypes = function(callback) {
		$http
			.get('/ext_api/api/tisObjectType', {
				headers: handleServiceWorker.createServiceWorkerCacheKeyForRequest({
					cacheKey: 'objectTypeService_getObjectTypes_/ext_api/api/tisObjectType',
					expiryTime: handleServiceWorker.CACHE_EXPIRE_TIME.IN_7_DAY,
				}),
			})
			.success(callback);
	};

	this.getObjectProperties = function(id, all) {
		const url = `/ext_api/api/tisObjectType/${id}/properties${all ? '?displayState=all' : ''}`;
		return $http
			.get(url, {
				cache: cacheService.getCacheInstance('objectTypeService', `ObjectProperties${all ? 'displayStateAll' : ''}`, id),
				headers: handleServiceWorker.createServiceWorkerCacheKeyForRequest({
					cacheKey: `objectTypeService_ObjectProperties_${url}`,
					expiryTime: handleServiceWorker.CACHE_EXPIRE_TIME.IN_3_DAYS,
				}),
			})
			.then(data => {
				if (all) {
					const dataToProcess = data.data;
					dataToProcess.tisObjectPropertyList = dataToProcess.tisObjectPropertyList.filter(item => {
						const {developmentPropertyIndicator, propertyAttribute} = item;
						return developmentPropertyIndicator || (propertyAttribute && propertyAttribute.displayState);
					});
				}
				return data;
			});
	};

	this.getObjectCharacteristics = function(tisObjectTypeGroupNumber) {
		const url = `/ext_api/analytics-api/analytic/parameter?tisObjectTypeGroupNumber=${tisObjectTypeGroupNumber}`;

		return $http.get(url, {
			cache: cacheService.getCacheInstance('objectTypeService', 'ObjectCharacteristics', tisObjectTypeGroupNumber),
			headers: handleServiceWorker.createServiceWorkerCacheKeyForRequest({
				cacheKey: `objectTypeService_ObjectCharacteristics_${url}`,
				expiryTime: handleServiceWorker.CACHE_EXPIRE_TIME.IN_1_DAY,
			}),
		});
	};

	this.getObjectParameterByNameAndTisObjectId = function(parameterName, tisObjectId) {
		const cacheKey = [parameterName, tisObjectId].join('/');
		return $http
			.get(`/ext_api/analytics-api/analytic/parameter/${parameterName}?tisObjectId=${tisObjectId}`, {
				cache: cacheService.getCacheInstance('objectTypeService', 'ObjectParameterByNameAndTisObjectId', cacheKey),
			})
			.then(({data}) => data);
	};

	this.getObjectParameterByName = function(parameterName) {
		return $http
			.get(`/ext_api/analytics-api/analytic/parameter/global/${parameterName}`, {
				cache: cacheService.getCacheInstance('objectTypeService', 'ObjectParameterByName', parameterName),
			})
			.then(({data}) => data);
	};
});
