import _get from 'lodash.get';
import {USER_EVENTS} from '../../../common/usage-tracking/categories';
import {CURRENT_PAGE, PRIMARY_OFFERING} from '../../../common/usage-tracking/common/properties-names';
import {PRIMARY_OFFERINGS} from '../../../common/usage-tracking/common/primary-offerings';
import {resolveParentScope} from '../../../utils';
import {getTimePeriodNameByRangeMode} from '../../../common/usage-tracking/categories/report-generation/utils';
import {getSyncStatusName, getChartType} from '../../../common/usage-tracking/categories/equipment-performance-charts/utils';
import {RANGE_MODE, RANGE_FROM, RANGE_TO} from '../../../common/event-listener/date-range-listener';
const {
	EQUIPMENT_PERFORMANCE_CHARTS: {
		events: EQUIPMENT_PERFORMANCE_CHARTS_EVENTS,
		properties: EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES,
		categoryName: EQUIPMENT_PERFORMANCE_CHARTS_CATEGORY_NAME,
	},
} = USER_EVENTS;

(function() {
	class ChartControlsController {
		constructor($rootScope, $route, $scope, usageTrackingService, CHART_TYPE) {
			this.$rootScope = $rootScope;
			this.$scope = $scope;
			this.usageTrackingService = usageTrackingService;
			this.crossChairsActive = false;
			this.currentFacility = angular.copy(this.selectedFacility);
			this.controlsDisabled = false;
			this.loadedCharts = false;
			this.hideNegativeValuesInChart = false;
			this.chartTypes = CHART_TYPE;

			this.sortOrderMap = {
				highToLow: {
					title: 'Pareto High to Low',
				},
				lowToHigh: {
					title: 'Pareto Low to High',
				},
				equipmentByDate: {
					title: 'Equipment By Date',
				},
			};

			this.enableControlsonEventBinded = this.enableControlsonEvent.bind(this);
			this.onChangeLoadedChartsBinded = this.onChangeLoadedCharts.bind(this);
			this.toggleHideNegativeValuesInChart = this.toggleHideNegativeValuesInChart.bind(this);

			this.$onInit = () => {
				if ($rootScope.globalDateSelectionRange) {
					this.rangeMode = _get($rootScope.globalDateSelectionRange, RANGE_MODE, this.rangeMode);
					this.range.from = _get($rootScope.globalDateSelectionRange, RANGE_FROM, this.range.from);
					this.range.to = _get($rootScope.globalDateSelectionRange, RANGE_TO, this.range.to);
				}
				if (this.eventObject && typeof this.eventObject.on === 'function') {
					this.eventObject.on('disableControls', this.enableControlsonEventBinded);
					if (this.isFacilityPerformanceChart) {
						this.eventObject.on('changeLoadedCharts', this.onChangeLoadedChartsBinded);
					}
				}
			};

			this.$onDestroy = () => {
				if (this.eventObject && typeof this.eventObject.off === 'function') {
					this.eventObject.off('disableControls', this.enableControlsonEventBinded);
					if (this.isFacilityPerformanceChart) {
						this.eventObject.off('changeLoadedCharts', this.onChangeLoadedChartsBinded);
					}
				}
			};

			this.trackEvent = this.usageTrackingService.trackEventByCategory(EQUIPMENT_PERFORMANCE_CHARTS_CATEGORY_NAME, {
				[CURRENT_PAGE]: _get($route, 'current.metricsConfig.pathName', $route.current.templateUrl),
			});
		}
		enableControlsonEvent() {
			this.controlsDisabled = true;
			this.crossChairsActive = false;
			this.eventObject.once('enableControls', () => {
				this.controlsDisabled = false;
			});
		}
		onChangeLoadedCharts() {
			this.loadedCharts = true;
		}
		toggleHideNegativeValuesInChart(e, value) {
			this.hideNegativeValuesInChart = true;
			this.eventObject.emit('hideNegativeValues', value);
		}
		selectFacilityEquipmentTypeHandler(equipment) {
			this.crossChairsActive = false;
			this.selectFacilityEquipmentType({equipment});
		}
		setChartHandler(item) {
			this.crossChairsActive = false;
			this.setChart({item});
			this.trackChartChange(item);
		}
		selectEquipmentHandler(item) {
			this.crossChairsActive = false;
			this.selectEquipment({item});
			this.trackEquipmentChange(item);
		}
		selectFacilityHandler(facility) {
			this.selectedFacility = facility;
			this.crossChairsActive = false;
			this.selectFacility({facility});
		}
		setFacilitiesHandler(list) {
			this.crossChairsActive = false;
			this.setFacilities({list});
		}
		setParetoChartSortOrderHandler(sortOrder) {
			this.trackSortOrderChange(sortOrder);
			this.setParetoChartSortOrder({sortOrder});
		}
		showPrintExportDialog() {
			this.$rootScope.$broadcast('facility-performance-chart__export-dialog--show');
		}
		enableChartRullerHandler() {
			this.crossChairsActive = !this.crossChairsActive;
			this.enableChartRuller({rullerEnabled: this.crossChairsActive});
			this.crossChairsActive && this.trackCrossHairToolActivating();
		}
		setRangeMode(mode) {
			this.crossChairsActive = false;
			this.rangeMode = mode;
			this.onRangeModeChange();
		}
		fitTwoChartsIntoWindow() {
			this.$rootScope.fitCharts = !this.$rootScope.fitCharts;
		}
		tuneDatePicker(event) {
			const pickerWindow = event.target.closest('.dropdown').getElementsByClassName('dropdown-menu')[0];
			const pickerRigthEdge = document.body.clientWidth - event.target.getBoundingClientRect().right;
			const pickerLeftEdge = event.target.getBoundingClientRect().left;
			pickerWindow.classList.remove('pull-right');
			pickerWindow.classList.remove('pull-left');
			if (pickerRigthEdge < 200) {
				pickerWindow.classList.add('pull-right');
			} else if (pickerLeftEdge < 200) {
				pickerWindow.classList.add('pull-left');
			}
		}
		trackEquipmentChange({tisObjectType}) {
			setTimeout(() => {
				this.trackEvent(EQUIPMENT_PERFORMANCE_CHARTS_EVENTS.CHANGE_EQUIPMENT, {
					[PRIMARY_OFFERING]: PRIMARY_OFFERINGS.IS,
					[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_NAME]: _get(this.selectedChart, 'title'),
					[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_ID]: this.getChartNumber(),
					[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.EQUIPMENT_TYPE]: tisObjectType.tisObjectTypeGroupName,
				});
			}, 0);
		}
		trackSortOrderChange(sortOrder) {
			let sortOrderName = this.sortOrderMap[sortOrder].title;

			if (sortOrderName) {
				setTimeout(() => {
					this.trackEvent(EQUIPMENT_PERFORMANCE_CHARTS_EVENTS.CHANGE_CHART_SORT_ORDER, {
						[PRIMARY_OFFERING]: PRIMARY_OFFERINGS.IS,
						[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_NAME]: _get(this.selectedChart, 'title'),
						[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_ID]: this.getChartNumber(),
						[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.SORT_ORDER]: sortOrderName,
						[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.EQUIPMENT_TYPE]: _get(this.selectedChart.tisObjectType, 'tisObjectTypeGroupName'),
					});
				}, 0);
			}
		}
		trackChartChange(chart) {
			setTimeout(() => {
				this.trackEvent(EQUIPMENT_PERFORMANCE_CHARTS_EVENTS.CHANGE_CHART, {
					[PRIMARY_OFFERING]: PRIMARY_OFFERINGS.IS,
					[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_NAME]: _get(chart, 'title'),
					[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_ID]: this.getChartNumber(),
				});
			}, 0);
		}
		trackCrossHairToolActivating() {
			setTimeout(() => {
				this.trackEvent(EQUIPMENT_PERFORMANCE_CHARTS_EVENTS.ACTIVATE_CROSSHAIR_TOOL, {
					[PRIMARY_OFFERING]: PRIMARY_OFFERINGS.IS,
					[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_NAME]: _get(this.selectedChart, 'title'),
				});
			}, 0);
		}

		// Determine which chart on the page the user are working with in the case he has multiple charts open.
		getChartNumber() {
			const {charts} = resolveParentScope(this.$scope, $scope => Array.isArray($scope.charts));

			return charts.indexOf(charts.find(chart => chart.chartObj === this.chartObject)) + 1;
		}

		onDatesRangeChange({data: {start, end}}) {
			this.$rootScope.globalDateSelectionRange.rangeFrom = start;
			this.$rootScope.globalDateSelectionRange.rangeTo = end;
			this.$rootScope.globalDateSelectionRange.rangeMode = this.rangeMode;

			if (this.rangeMode === 'custom') {
				setTimeout(() => {
					this.trackEvent(EQUIPMENT_PERFORMANCE_CHARTS_EVENTS.CHANGE_CHART_TIME_PERIOD, {
						[PRIMARY_OFFERING]: PRIMARY_OFFERINGS.IS,
						[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_NAME]: _get(this.selectedChart, 'title'),
						[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.TIME_PERIOD]: `${getTimePeriodNameByRangeMode(this.rangeMode)}: ${end.diff(
							start,
							'days'
						)} days`,
						[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_ID]: this.getChartNumber(),
					});
				}, 0);
			}
		}

		onRangeModeChange() {
			this.$rootScope.globalDateSelectionRange[RANGE_MODE] = this.rangeMode;
			this.$rootScope.globalDateSelectionRange[RANGE_FROM] = this.range.from;
			this.$rootScope.globalDateSelectionRange[RANGE_TO] = this.range.to;
			if (this.rangeMode === 'custom') return;

			setTimeout(() => {
				this.trackEvent(EQUIPMENT_PERFORMANCE_CHARTS_EVENTS.CHANGE_CHART_TIME_PERIOD, {
					[PRIMARY_OFFERING]: PRIMARY_OFFERINGS.IS,
					[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_NAME]: _get(this.selectedChart, 'title'),
					[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.TIME_PERIOD]: getTimePeriodNameByRangeMode(this.rangeMode),
					[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_ID]: this.getChartNumber(),
				});
			}, 0);
		}

		onUpdateSyncStatus(status) {
			setTimeout(() => {
				this.trackEvent(EQUIPMENT_PERFORMANCE_CHARTS_EVENTS.SYNC_CHARTS, {
					[PRIMARY_OFFERING]: PRIMARY_OFFERINGS.IS,
					[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_NAME]: _get(this.selectedChart, 'title'),
					[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.STATUS]: getSyncStatusName(status),
				});
			}, 0);
		}

		onFitCharts() {
			if (!this.$rootScope.fitCharts) return;

			setTimeout(() => {
				this.trackEvent(EQUIPMENT_PERFORMANCE_CHARTS_EVENTS.FIT_CHARTS, {
					[PRIMARY_OFFERING]: PRIMARY_OFFERINGS.IS,
					[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_NAME]: _get(this.selectedChart, 'title'),
					[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_TYPE]: getChartType(_get(this.selectedChart, 'chartTypeLevel')),
				});
			}, 0);
		}
	}

	angular.module('TISCC').component('chartControls', {
		templateUrl: 'components/chart/chart-controls-component/chart-controls.html',
		controller: ChartControlsController,
		bindings: {
			chartList: '<',
			groupedEquipments: '<',
			equipmentTypeChart: '<',
			isFacilityChart: '<',
			isParetoChart: '<',
			isFacilityPerformanceChart: '<',
			isChartListLoading: '<',
			isEquipmentListLoading: '<',
			chartObject: '<',
			selectedTypeLabel: '<', // getSelectedTypeLabel()
			selectedChart: '<', // // chartObj.selectedChart // chartObj.selectedChart.title
			tisObjectName: '<', // chartObj.selectedEquipment.tisObjectName || 'OPTIONS_SELECT_ONE'|translate
			paretoChartSortOrder: '<', // chartObj.paretoChartSortOrder
			range: '<', // $ctrl.range
			rangeMode: '<', // :todo refactor to remove this.  Probably we need to move logic from date-range to Range class
			selectedFacility: '=',
			selectedFacilities: '<',
			selectFacilityEquipmentType: '&', // selectFacilityEquipmentType(equipment)
			setChart: '&', // setChart(item)
			selectEquipment: '&', // selectEquipment(item)
			selectFacility: '&', // selectFacility(facility)
			setFacilities: '&',
			setParetoChartSortOrder: '&', // setParetoChartSortOrder(sortOrder)
			addChart: '&',
			removeChart: '&',
			canRemoveChart: '<',
			canAddChart: '<',
			chartIndex: '<',
			updateSyncStatus: '&',
			canSyncChart: '<',
			eventObject: '<',
			showPrintExportDialog: '&',
			enableChartRuller: '&',
		},
	});
})();
