/* eslint quotes: [0, 'single'] */
/* eslint one-var: 0 */
/* eslint max-depth: ["error", 8]*/
/* eslint max-nested-callbacks: [2, 6] */

import _get from 'lodash.get';

import {USER_EVENTS} from '../../common/usage-tracking/categories';
import {getColumnName} from '../../common/usage-tracking/categories/facility-summary-page/utils';
import {PRIMARY_OFFERING} from '../../common/usage-tracking/common/properties-names';
import {PRIMARY_OFFERINGS} from '../../common/usage-tracking/common/primary-offerings';
// new comment
const {
	FACILITY_SUMMARY_PAGE: {
		events: FACILITY_SUMMARY_PAGE_EVENTS,
		properties: FACILITY_SUMMARY_PAGE_PROPERTIES,
		categoryName: FACILITY_SUMMARY_PAGE_CATEGORY_NAME,
	},
	NAVIGATION: {events: NAVIGATION_EVENTS},
} = USER_EVENTS;

angular.module('TISCC').controller('LocationDetailsCtrl', LocationDetailsCtrl);

function LocationDetailsCtrl(
	$scope,
	$locale,
	$routeParams,
	$filter,
	$q,
	$location,
	locationDetailsService,
	LocationSettingsFctry,
	ErrorPageFactory,
	chartService,
	helpers,
	$rootScope,
	locationEquipmentService,
	modalHelperService,
	serviceAdvisoryService,
	errorHandler,
	tisObjectListTransformService,
	urlService,
	utilityService,
	tisObjectService,
	subtypeFilterService,
	googleAnalyticsService,
	offeringService,
	FIXED_TREE_SORT,
	STATUS,
	CHART_DATE_FORMAT,
	PAGE_TYPE,
	GEN3_URL,
	TC_URL,
	REPORT_TYPES,
	configService,
	ENVIRONMENT,
	usageTrackingService,
	$sessionStorage
) {
	const trackEvent = usageTrackingService.trackEventByCategory(FACILITY_SUMMARY_PAGE_CATEGORY_NAME);
	const locationSettings = LocationSettingsFctry.getLocation($routeParams.locationId);
	const externalLinks = configService.getExternalLinks();
	const EQUIPMENTS_PORTION_SIZE = 100;
	const MILLISECONDS_IN_DAY = 86400000;
	const NO_DATA_KEY = 'No data';
	const NA_DATA_KEY = 'Not Applicable';
	const TWO_WEEK_LENGTH = 14;
	const PATH_404 = '404';
	const INVALID_DATE_FUTURE_KEY = 'invalidDateFuture';
	const INVALID_DATE_FORMAT_KEY = 'invalidDateFormat';
	const showedTisObjectTypeGroupName = _get(configService.getAutomatedTestsResultsConfig(), 'showedTisObjectTypeGroupName');
	const VFD = 'VFD';
	const TIS_OBJECT_SUBTYPE = 'tisObjectSubType';
	const TIS_OBJECT_CLASSIFICATION_TYPE = 'tisObjectClassificationType';
	const CHILLER = 'Chiller';
	const CHILLER_PLANT = 'Chiller Plant';
	const CP = 'CP';
	const CWS = 'CWS';
	const HS = 'HS';
	const HP = 'HP';
	const CT = 'CT';
	const CTC = 'CTC';
	const BOILER = 'Boiler';

	let allFlatEquipments = [];
	let equipmentsOriginalHierarchyCache = [];
	let watchDate = false;
	let defaultStatusList = [];
	let naStatusList = [];
	let statusModes = [];
	let uniqueAdvisoryTypesByLocation = undefined;
	$scope.weather = false;
	$scope.isWeatherStationUnavailable = true;
	$scope.isEquipmentListEmpty = true;
	$scope.days = $locale.DATETIME_FORMATS.SHORTDAY;
	$scope.summaryVisible = true;
	$scope.uiStateKey = 'facilityController.uiState.' + $routeParams.locationId;
	$scope.twoWeekRange = [];
	$scope.equipments = [];
	$scope.portionedFlatEquipments = [];
	$scope.equipmentsLoaded = false;
	$scope.featureToggles = configService.getFeatureToggles();
	$scope.locationId = $routeParams.locationId;
	$scope.EVENTS = {...FACILITY_SUMMARY_PAGE_EVENTS, ...NAVIGATION_EVENTS};
	$scope.PRIMARY_OFFERING = PRIMARY_OFFERING;
	$scope.PRIMARY_OFFERINGS = PRIMARY_OFFERINGS;
	$scope.trackOnLinkClick = $rootScope.getTrackOnLinkClickHandler(FACILITY_SUMMARY_PAGE_CATEGORY_NAME);
	$scope.isBPContractExpired = null;
	$scope.screenName = 'location-equipment';
	$scope.environment = configService.getEnvironmentType();

	$scope.onApplyFilterHandler = ({column}) => {
		$scope.trackOnLinkClick(FACILITY_SUMMARY_PAGE_EVENTS.APPLY_FILTER, {
			[FACILITY_SUMMARY_PAGE_PROPERTIES.COLUMN]: getColumnName(column),
		});
		trackEvent(FACILITY_SUMMARY_PAGE_EVENTS.APPLY_FILTER);
	};

	$scope.onRemoveFilterHandler = ({column}) => {
		$scope.trackOnLinkClick(FACILITY_SUMMARY_PAGE_EVENTS.REMOVE_FILTER, {
			[FACILITY_SUMMARY_PAGE_PROPERTIES.COLUMN]: getColumnName(column),
		});
		trackEvent(FACILITY_SUMMARY_PAGE_EVENTS.REMOVE_FILTER);
	};

	$scope.onExpandTestsButtonClick = () => {
		$scope.trackOnLinkClick(FACILITY_SUMMARY_PAGE_EVENTS.EXPAND_TEST_RESULTS_VIEW);
	};

	$scope.onDatesRangeChange = () => {
		$scope.trackOnLinkClick(FACILITY_SUMMARY_PAGE_EVENTS.CHANGE_DATES_OF_TEST_RESULTS);
	};

	$scope.onEquipmentSearch = text => {
		text && $scope.trackOnLinkClick(FACILITY_SUMMARY_PAGE_EVENTS.SEARCH_EQUIPMENT);
		trackEvent(FACILITY_SUMMARY_PAGE_EVENTS.SEARCH_EQUIPMENT);
	};

	let now = moment()
		.tz(locationDetailsService.getLocationTimezone())
		.startOf('day');
	let toDate = moment(now);
	$scope.rangeOffset = Number.isNaN(locationSettings.rangeOffset) ? 0 : locationSettings.rangeOffset;

	if ($scope.rangeOffset) {
		toDate.subtract($scope.rangeOffset, 'days');
	}
	for (let i = 0; i < TWO_WEEK_LENGTH; i++) {
		defaultStatusList[i] = STATUS.MODE[NO_DATA_KEY];
		naStatusList[i] = STATUS.MODE[NA_DATA_KEY];
		naStatusList[i].text = 'N/A';
		let d = moment(now).subtract($scope.rangeOffset + i, 'days');
		$scope.twoWeekRange.unshift({
			name: d.format('d'),
			title: d.format('dddd, MMMM D, YYYY'),
			date: formatMomentDateForUrl(d),
		});
	}

	Object.keys(STATUS.MODE).forEach(function(key) {
		statusModes.push(STATUS.MODE[key].title);
	});
	$scope.showLegend = {
		text: false,
		date: '',
		element: false,
	};

	const promiseStatusOfBPEPsupscription = locationDetailsService
		.getLocationDetailsWithoutServiceAdvisories($routeParams.locationId)
		.then(showDetailedLocationInfo);

	function showDetailedLocationInfo(data) {
		const [location] = data.locationSummaryList;
		const {address: addr, organizationId, facilityId, locationId, locationConnectivity: connectivity} = location;

		$scope.location = location;
		$scope.loadtime = moment()
			.tz(locationDetailsService.getLocationTimezone())
			.format('LLLL');
		$scope.lastCollected = '';
		$scope.locationSearchString = '';
		$scope.salesOffice = '';
		$scope.buildingSetupLink = externalLinks.buildingSetupLink({locationId});
		$scope.notesLink = externalLinks.notesLink({locationId, facilityId});
		$scope.addNoteLink = externalLinks.addNoteLink({locationId, facilityId});
		$scope.automatedTestSettingsLink = externalLinks.automatedTestSettingsLink({accountId: organizationId, locationId});
		$scope.automatedTestSuppressionsLink = externalLinks.automatedTestSuppressionsLink({accountId: organizationId, locationId});
		$scope.remoteAccessLink = externalLinks.remoteAccessLink({accountId: organizationId, locationId});

		utilityService.getEnvironmentType().then(env => {
			$scope.GEN3FacilitySummaryURL = `${GEN3_URL[env.toUpperCase()]}?linkParameters=|page=facility|facilityId=${facilityId}`;
			$scope.GEN3ReportsURL = `${GEN3_URL[env.toUpperCase()]}?linkParameters=|page=Reports|facilityId=${facilityId}|report=`;
			$scope.GEN4EquipmentSetupURL = externalLinks.equipmentSetupLink({locationId, organizationId});
		});

		if (addr && addr.line1) {
			$scope.locationSearchString =
				addr.line1 + (addr.city ? ', ' + addr.city : '') + ', ' + (addr.region || '') + ' ' + (addr.postalCode || '') + ' ' + (addr.country || '');
		}

		$scope.locationSearchUrl = 'https://www.google.com/maps/search/' + encodeURI($scope.locationSearchString.replace(/,/g, ''));

		$scope.toggleLegend = function(status, index, event) {
			$scope.showLegend.text = status ? $filter('translate')(status.title) : false;
			if (!status) return;
			if (status.className === 'invalid-application') {
				$scope.showLegend.text = $filter('translate')('AGGREGATED_STATUSES.NOT_APPLICABLE');
			}

			$scope.showLegend.date = $scope.twoWeekRange[index].title;

			if (!$scope.showLegend.element) {
				$scope.showLegend.element = document.getElementsByClassName('bottom-tooltip')[0];
			}

			// FIX for IE
			$scope.showLegend.element.style.top = event.clientY + 10 + 'px';
			$scope.showLegend.element.style.left = event.clientX + 'px';
		};

		$scope.weatherLoading = true;
		locationDetailsService.getWeatherForLocation($scope.location).then(setWeatherDataToScope);

		locationDetailsService
			.getLocationServiceAdvisoriesCount($scope.locationId)
			.then(setServiceAdvisoryCountToScope)
			.catch(() => setServiceAdvisoryCountToScope());

		locationDetailsService
			.getLocationNotesCount($scope.locationId)
			.then(count => ($scope.facilityNotesCount = count))
			.catch(() => ($scope.facilityNotesCount = '?'));

		// show salesOfficeCode only if adress is missing
		if ($scope.location.salesOffice) {
			if ($scope.location.salesOffice.officeName) {
				$scope.salesOffice += $scope.location.salesOffice.officeName;
				if ($scope.location.salesOffice.officeCode) {
					$scope.salesOffice += ' (' + $scope.location.salesOffice.officeCode + ')';
				}
			} else if ($scope.location.salesOffice.officeCode) {
				$scope.salesOffice += $scope.location.salesOffice.officeCode;
			}
		}
		// calculate lastCollected
		if (connectivity && connectivity.devices) {
			$scope.lastCollectedDate = null;
			connectivity.devices.forEach(device => {
				if (device.status && device.status.lastTimestamp) {
					let date = moment(device.status.lastTimestamp).tz(locationDetailsService.getLocationTimezone());
					if (date > $scope.lastCollectedDate || !$scope.lastCollectedDate) {
						$scope.lastCollected = date.format('LLLL');
						$scope.lastCollectedDate = date;
					}
				}
			});
		}

		// Show BP and EP offerings if such present for the location.
		const offeringsToDisplay = (location.offeringSourceMaps || []).filter(
			({shortName, expirationDate, activeStatus}) => offeringService.OFFERINGS_TO_DISPLAY.includes(shortName) && expirationDate && activeStatus
		);
		const offerings = offeringService.filterValidOfferings(offeringsToDisplay, location.locationTimeZone);
		$scope.offeringsToDisplay = Object.values(offerings);
		$scope.showOfferings = Boolean($scope.offeringsToDisplay.length);
		if ($scope.offeringsToDisplay.length) {
			const isBPContractValid = offeringService.checkIsBPContractValid($scope.offeringsToDisplay, location.locationTimeZone);
			$scope.isBPContractExpired = !isBPContractValid;
		} else {
			$scope.isBPContractExpired = true;
		}

		$scope.navigation = [];
		$scope.pageTitle = {
			title: location.locationName,
		};
		updateRange();
		return Promise.resolve($scope.isBPContractExpired);
	}

	function getBoilerTisObjects(tisObjectList) {
		const boilersList = [];
		const tisObjectHSList = [];
		const tisObjectRemainderList = [];
		const listToFilter = [HP, HS, BOILER];

		// Separate tisObjectList into HS and remainder lists
		tisObjectList.forEach(tisObject => {
			if (
				tisObject.tisObjectType &&
				tisObject.tisObjectType.tisObjectTypeGroupName &&
				listToFilter.includes(tisObject.tisObjectType.tisObjectTypeGroupName)
			) {
				tisObjectHSList.push(tisObject);
			} else {
				tisObjectRemainderList.push(tisObject);
			}
		});

		// Run a loop on the list of HS objs
		tisObjectHSList.forEach(tisObjectHS => {
			if (tisObjectHS.children && tisObjectHS.children.length) {
				tisObjectHS.children.forEach(child => {
					if (child.tisObjectType && child.tisObjectType.tisObjectTypeGroupName === HP && child.children && child.children.length) {
						if (child.children && child.children.length) {
							child.children.forEach(secondChild => {
								if (secondChild.tisObjectType.tisObjectTypeGroupName === BOILER) {
									boilersList.push(secondChild);
								}
							});
						}
					}
					if (child.tisObjectType.tisObjectTypeGroupName === BOILER) {
						boilersList.push(child);
					}
				});
			}

			if (tisObjectHS.tisObjectType.tisObjectTypeGroupName === BOILER) {
				boilersList.push(tisObjectHS);
			}
		});

		return {boilersList, tisObjectHSList, tisObjectRemainderList};
	}

	async function getCharacteristicsFromAnalyticParams(chillerTisObjectIds, boilerTisObjectIds, tisObjectList) {
		// Boiler
		const paramsBoiler = subtypeFilterService.generateBoilerParamsForCharacteristics(boilerTisObjectIds, $scope.from, $scope.to);
		const {tisObjectDataList: tisObjectDataListBoiler} = await tisObjectService.getAllValuesByIds(paramsBoiler);

		let newUpdatedBoilersList = [];

		const {boilersList, tisObjectHSList, tisObjectRemainderList} = getBoilerTisObjects(tisObjectList);

		// Extract boilers and send to subtype.js
		if (boilersList.length) {
			newUpdatedBoilersList = subtypeFilterService.getCharacteristicsValuesIntoBoilers(boilersList, tisObjectDataListBoiler);
		}

		// Update children of HS objects with newUpdatedBoilersList
		tisObjectHSList.forEach(tisObjectHS => {
			if (tisObjectHS.children && tisObjectHS.children.length) {
				tisObjectHS.children.forEach(child => {
					if (child.tisObjectType && child.tisObjectType.tisObjectTypeGroupName === HP && child.children && child.children.length) {
						child.children.forEach(secondChild => {
							if (secondChild.tisObjectType.tisObjectTypeGroupName === BOILER) {
								const boilerTisObject = newUpdatedBoilersList.find(bList => bList.tisObjectId === secondChild.tisObjectId);
								secondChild = boilerTisObject;
							}
						});
					}
				});
			} else if (tisObjectHS.tisObjectType.tisObjectTypeGroupName === BOILER) {
				const boilerTisObject = newUpdatedBoilersList.find(bList => bList.tisObjectId === tisObjectHS.tisObjectId);
				tisObjectHS = boilerTisObject;
			}
		});
		$sessionStorage['boilersList'] = newUpdatedBoilersList;
		// Chiller
		const paramsChiller = subtypeFilterService.generateParamsForCharacteristics(chillerTisObjectIds, $scope.from, $scope.to);
		const {tisObjectDataList: tisObjectDataListChiller} = await tisObjectService.getAllValuesByIds(paramsChiller);
		const filteredTisObjList = [];
		const filteredTisObjChildList = [];
		const remnantTisObjList = [];
		const chillerPlantOtherList = [];
		const chillerPlantList = [];

		for (let tisObject of tisObjectRemainderList) {
			if (Object.hasOwn(tisObject, TIS_OBJECT_SUBTYPE) && Object.hasOwn(tisObject, TIS_OBJECT_CLASSIFICATION_TYPE)) {
				filteredTisObjList.push(tisObject);
			} else if (tisObject.tisObjectName && tisObject.tisObjectName === CHILLER_PLANT) {
				chillerPlantList.push(tisObject);
				if (tisObject.children && tisObject.children.length) {
					for (let child of tisObject.children) {
						if (child.tisObjectType && child.tisObjectType.tisObjectTypeGroupName && child.tisObjectType.tisObjectTypeGroupName === CHILLER) {
							filteredTisObjChildList.push(child);
						} else {
							chillerPlantOtherList.push(child);
						}
					}
				}
			} else {
				remnantTisObjList.push(tisObject);
			}
		}

		if (!filteredTisObjList.length && !filteredTisObjChildList.length) {
			return tisObjectList;
		}

		let standAloneFilteredChillerList = [];
		let chillerPlantFilteredChillerList = [];

		let finalChillerCharacterList = [];
		let newChildrenChillersList = [];

		if (filteredTisObjChildList.length) {
			chillerPlantFilteredChillerList = subtypeFilterService.getCharacteristicsValuesIntoChillers(filteredTisObjChildList, tisObjectDataListChiller);
		}

		if (chillerPlantFilteredChillerList.length) {
			newChildrenChillersList = [...chillerPlantOtherList, ...chillerPlantFilteredChillerList];

			for (let chillerPlant of chillerPlantList) {
				chillerPlant.children = newChildrenChillersList;
			}
			finalChillerCharacterList = [...chillerPlantList, ...remnantTisObjList];
		} else {
			finalChillerCharacterList = [...remnantTisObjList];
		}

		if (filteredTisObjList.length) {
			standAloneFilteredChillerList = subtypeFilterService.getCharacteristicsValuesIntoChillers(filteredTisObjList, tisObjectDataListChiller);
		}

		if (standAloneFilteredChillerList.length) {
			finalChillerCharacterList = [...standAloneFilteredChillerList, ...remnantTisObjList];
		}

		// Merge Boiler lists... before returning
		const finalCharacteristicsList = [...finalChillerCharacterList, ...tisObjectHSList];

		return finalCharacteristicsList;
	}

	function checkRouteParamDates() {
		if ($routeParams.startDate && $routeParams.endDate) {
			$scope.from = moment.tz($routeParams.startDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, locationDetailsService.getLocationTimezone()).startOf('day');
			$scope.to = moment
				.tz($routeParams.endDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, locationDetailsService.getLocationTimezone())
				.startOf('day')
				.add(1, 'days');
			$scope.rangeOffset = calculateRangeOffset($scope.to);

			// if endDate is invalid, use startDate to calculate range offset
			if (!$scope.to.isValid() && $scope.from.isValid()) {
				$scope.rangeOffset = calculateRangeOffset($scope.from) - TWO_WEEK_LENGTH;
			}
			locationSettings.rangeOffset = $scope.rangeOffset;

			locationDetailsService.getLocationDetailsWithoutServiceAdvisories($scope.locationId).then(() => {
				const timezone = locationDetailsService.getLocationTimezone();
				const from = moment.tz($routeParams.startDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, timezone).startOf('day');
				const to = moment.tz($routeParams.endDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, timezone).startOf('day');
				const urlDateRangeFormatIsValid = helpers.checkUrlRangeFormat(
					from,
					to,
					$routeParams.startDate,
					$routeParams.endDate,
					CHART_DATE_FORMAT.RANGE_DATE_FORMAT
				);
				const urlDateRangeIsValid = helpers.checkUrlRangeValidity(from, to, timezone);
				if (!urlDateRangeFormatIsValid) {
					ErrorPageFactory.createErrorPage(INVALID_DATE_FORMAT_KEY, $location.path(), PAGE_TYPE.LOCATION);
					urlService.changeUrl(PATH_404);
				} else if (!urlDateRangeIsValid) {
					ErrorPageFactory.createErrorPage(INVALID_DATE_FUTURE_KEY, $location.path(), PAGE_TYPE.LOCATION);
					urlService.changeUrl(PATH_404);
				} else {
					updateRange();
				}
			});
		} else {
			const replaceDates = true;
			urlService.changeDateRangeInUrl($scope.from.clone(), $scope.to.clone().add(-1, 'days'), replaceDates);
		}
	}

	function fetchAggregatedServiceAdvisories() {
		$scope.serviceAdvisoryPromise = serviceAdvisoryService.getAggregatedServiceAdvisoriesByLocation(
			$scope.locationId,
			{
				from: $scope.from,
				to: $scope.to,
			},
			function(data) {
				// Create an object that contains the Aggregated Services Advisories using the tisObjectId as the key
				if (data && data.aggregatedServiceAdvisoryList) {
					let aggregatedServiceAdvisoryList = data.aggregatedServiceAdvisoryList,
						aggregatedHierarchicalServiceAdvisoriesByObjectId = {}, // These ASAs will create Hierarchical rows
						aggregatedServiceAdvisoriesByObjectId = {}, // These ASAs will be used for the existing rows
						l = aggregatedServiceAdvisoryList.length;
					for (let i = 0; i < l; i++) {
						// Loop through the service advisories that are received in an array form
						let status = {},
							advisory = aggregatedServiceAdvisoryList[i],
							mode = STATUS.MODE[advisory.performanceIndicatorPerDay] || STATUS.MODE[NO_DATA_KEY],
							// Format the timestamp provided by the service to match the weekRange times
							formattedTimestamp = moment.tz(advisory.propertyTimestamp, locationDetailsService.getLocationTimezone()).format('YYYYMMDD'),
							dayIndex = $scope.twoWeekRange
								.map(function(e) {
									// Find out what day in the week range needs to be replaced
									return e.date;
								})
								.indexOf(formattedTimestamp);
						status.className = mode.className;
						status.title = mode.title;
						if (advisory.type === 'hierarchical') {
							if (!aggregatedHierarchicalServiceAdvisoriesByObjectId[advisory.tisObjectId]) {
								// Default the days to insufficient data
								aggregatedHierarchicalServiceAdvisoriesByObjectId[advisory.tisObjectId] = getDefaultStatusList();
							}

							// Replace the days that are received from the service
							aggregatedHierarchicalServiceAdvisoriesByObjectId[advisory.tisObjectId][dayIndex] = status;
						} else {
							// Existing rows
							if (!aggregatedServiceAdvisoriesByObjectId[advisory.tisObjectId]) {
								aggregatedServiceAdvisoriesByObjectId[advisory.tisObjectId] = getDefaultStatusList();
							}
							aggregatedServiceAdvisoriesByObjectId[advisory.tisObjectId][dayIndex] = status;
						}
					}

					// TODO: do smth with it.
					addAggregatedServiceAdvisories($scope.equipments, aggregatedServiceAdvisoriesByObjectId, aggregatedHierarchicalServiceAdvisoriesByObjectId);
					addAggregatedServiceAdvisories(allFlatEquipments, aggregatedServiceAdvisoriesByObjectId, aggregatedHierarchicalServiceAdvisoriesByObjectId);
					$scope.loadNextEquipmentPortion(true);
				}
			}
		);
	}

	function getDefaultStatusList(isNA) {
		return isNA ? [...naStatusList] : [...defaultStatusList];
	}

	function getTisObjectIds(tisObjectList) {
		const chillerTisObjectIds = [];
		const boilerTisObjectIds = [];

		for (let tisObject of tisObjectList) {
			if (tisObject.tisObjectName && tisObject.tisObjectName === CHILLER_PLANT) {
				if (tisObject.children && tisObject.children.length) {
					for (let child of tisObject.children) {
						if (child.tisObjectType && child.tisObjectType.tisObjectTypeGroupName && child.tisObjectType.tisObjectTypeGroupName === CHILLER) {
							chillerTisObjectIds.push(child.tisObjectId);
						}
					}
				}
			}
			if (tisObject.tisObjectType && tisObject.tisObjectType.tisObjectTypeGroupName) {
				if (
					tisObject.tisObjectType.tisObjectTypeGroupName === CHILLER ||
					tisObject.tisObjectType.tisObjectTypeGroupName === CP ||
					tisObject.tisObjectType.tisObjectTypeGroupName === CWS
				) {
					chillerTisObjectIds.push(tisObject.tisObjectId);
				}
				// Reading TisObjectIds of Boilers with Heating System as parent
				if (tisObject.tisObjectType.tisObjectTypeGroupName === HS && tisObject.children && tisObject.children.length) {
					tisObject.children.forEach(child => {
						if (child.tisObjectType.tisObjectTypeGroupName === HP && child.children && child.children.length) {
							child.children.forEach(secondChild => {
								if (secondChild.tisObjectType.tisObjectTypeGroupName === BOILER) {
									boilerTisObjectIds.push(secondChild.tisObjectId);
								}
							});
						}
					});
				}
				// Reading TisObjectIds of Boilers with Heating Plant as parent
				if (tisObject.tisObjectType.tisObjectTypeGroupName === HP && tisObject.children && tisObject.children.length) {
					tisObject.children.forEach(child => {
						if (child.tisObjectType.tisObjectTypeGroupName === BOILER) {
							boilerTisObjectIds.push(child.tisObjectId);
						}
					});
				}
				// Reading TisObjectIds of stand-alone Boilers
				if (tisObject.tisObjectType.tisObjectTypeGroupName === BOILER) {
					boilerTisObjectIds.push(tisObject.tisObjectId);
				}
			}
		}
		return {chillerTisObjectIds, boilerTisObjectIds};
	}

	promiseStatusOfBPEPsupscription.then(isExpired => {
		if (isExpired) return;

		checkRouteParamDates();
		// Preloading PI details. This will be cached and used later
		serviceAdvisoryService.getAggregatedServiceAdvisoriesByLocation($routeParams.locationId, {
			from: $scope.from,
			to: $scope.to,
		});

		const apiPromises = [serviceAdvisoryService.getTypesByLocationId($routeParams.locationId), chartService.getAllCharts()];

		// Preloading equipment objects from selected location . This will be cached and used later
		const apiPromiseGetLocationObjectsList = $routeParams.locationId
			? locationEquipmentService.getLocationObjectsList($routeParams.locationId, null, true)
			: Promise.reject({});

		const apiPromiseGetLocatioDetailsWithoutSA = locationDetailsService.getLocationDetailsWithoutServiceAdvisories($scope.locationId);

		$q
			.all(apiPromises)
			.then(data => {
				let [serviceAdvisoryTypeListByLocation, chartsList] = data;
				uniqueAdvisoryTypesByLocation = getUniqueTypes(serviceAdvisoryTypeListByLocation);
				let uniqueChartTypes = getUniqueTypes(chartsList);

				// calculates unique equipment types from chart list and service advisory types
				// that allows to filter equipments by the next rule
				// if the equipment has automated test or has chart link it is shown
				let uniqueTypes = new Set([...uniqueAdvisoryTypesByLocation, ...uniqueChartTypes, VFD, CT, CTC]);

				apiPromiseGetLocationObjectsList.then(function(data) {
					const {chillerTisObjectIds, boilerTisObjectIds} = getTisObjectIds(data.tisObjectList);

					let characteristicsTisObjectsDataList = [];

					getCharacteristicsFromAnalyticParams(chillerTisObjectIds, boilerTisObjectIds, data.tisObjectList).then(function(data) {
						characteristicsTisObjectsDataList = [...data];
						if (characteristicsTisObjectsDataList) {
							equipmentsOriginalHierarchyCache = characteristicsTisObjectsDataList;

							let out = tisObjectListTransformService.transform(characteristicsTisObjectsDataList, $scope.locationId, uniqueTypes);
							$scope.equipments = out.tree;
							allFlatEquipments = out.flat;
							$scope.isEquipmentListEmpty = !allFlatEquipments.length;
							$scope.loadNextEquipmentPortion(true);
						}
						$scope.equipmentsLoaded = true;
						apiPromiseGetLocatioDetailsWithoutSA.then(checkRouteParamDates).then(fetchAggregatedServiceAdvisories);
					});
				});
			})
			.catch(errorHandler.showErrorModal);
	});

	$scope.exceptionSortingValues = {
		sortProperty: 'tisObjectTypeGroupName',
		applyFor: 'VAS',
		exceptionalValues: ['AHU', 'VAV-BOX'],
	};
	$scope.expandingProperty = 'tisObjectName';
	$scope.expandingPropertySecond = 'groupNameHeader';
	$scope.goToSummaryPage = function(branch) {
		if (branch.tisObjectId) {
			$scope.go('/facility/' + $scope.locationId + '/equipment/' + branch.tisObjectId + '/summary');
		}
	};

	$scope.toggleComponents = function(equipment) {
		if (equipment.components && equipment.components.length) equipment.expanded = !equipment.expanded;
	};

	$scope.activeTab = 'summary';
	$scope.sortParams = [
		{
			column: 'tisObjectName',
			order: true,
		},
	];
	$scope.sortParamsTree = [
		...$scope.sortParams,
		{
			column: 'groupNameHeader',
			order: true,
		},
	];

	$scope.closeCalendar = function() {
		document.body.click();
	};

	let facilityDataRangeCalendar = null;

	$scope.openCalendar = function() {
		if (!facilityDataRangeCalendar) {
			facilityDataRangeCalendar = document.getElementById('facilityDataRangeCalendar');
		}

		if (!angular.element(facilityDataRangeCalendar.parentNode).hasClass('open')) {
			setTimeout(function() {
				facilityDataRangeCalendar.dispatchEvent(new MouseEvent('click', {view: window}));
			}, 10);
		}
	};

	let filteredEquipmentsCache = null;

	$scope.loadNextEquipmentPortion = function(isFirstPortion) {
		if (!allFlatEquipments.length) {
			return;
		}

		if (isFirstPortion) {
			$scope.portionedFlatEquipments = [];
			const sortParams = [$scope.sortParams[0], {column: 'tisObjectName', order: $scope.sortParams[0].order}];
			let tmpEquipments = allFlatEquipments;

			if ($scope.searchObj) {
				tmpEquipments = $filter('filterBySearchText')(allFlatEquipments, {
					searchFieldsList: ['tisObjectName', 'groupName'],
					searchText: $scope.searchObj.searchText,
					searchNested: false,
				});
			}

			let filteredByColumn = $filter('tableFilter')(tmpEquipments, $scope.filters);
			filteredEquipmentsCache = $filter('tableSort')(filteredByColumn, sortParams);

			if (sortParams[0].column === FIXED_TREE_SORT.COLUMN_NAME) {
				filteredEquipmentsCache = filteredEquipmentsCache.filter(equipmentIsComponentEquipment);
			}
			filteredEquipmentsCache = filteredEquipmentsCache.filter(isNotExcludedTisObjectType);
		}

		let start = $scope.portionedFlatEquipments.length;
		let nextPortion = filteredEquipmentsCache.slice(start, start + EQUIPMENTS_PORTION_SIZE);
		Array.prototype.push.apply($scope.portionedFlatEquipments, nextPortion);
	};

	$scope.broadcastTableLoaded = $rootScope.$broadcast.bind($rootScope, 'treeGridLoaded');

	$scope.$watch('searchObj.searchText', function(nw, old) {
		if (nw === old) {
			return;
		}

		$scope.loadNextEquipmentPortion(true);
	});

	$rootScope.$on('applyFilter', function(event, filterParams) {
		const {filters, parentScreen} = filterParams;
		if (parentScreen === $scope.screenName) {
			$scope.filters = filters;
			$scope.loadNextEquipmentPortion(true);
		}
	});

	$rootScope.$on('applySort', function(event, sortParams) {
		$scope.sortParams = [...sortParams];
		if (sortParams && sortParams[0] && sortParams[0].column !== 'groupName') {
			sortParams = [
				...sortParams,
				{
					column: 'groupNameHeader',
					order: sortParams[0].order,
				},
			];
			$scope.sortParamsTree = sortParams;
		}
		$scope.loadNextEquipmentPortion(true);
	});

	function formatMomentDateForUrl(date) {
		return date instanceof moment
			? date.format(CHART_DATE_FORMAT.RANGE_DATE_FORMAT)
			: moment(date, CHART_DATE_FORMAT.RANGE_DATE_FORMAT).format(CHART_DATE_FORMAT.RANGE_DATE_FORMAT);
	}

	function equipmentIsComponentEquipment(equipment = {}) {
		return equipment.tisObjectTypeClassification !== 'Component';
	}

	function isNotExcludedTisObjectType(equipment) {
		return !['LoadValve'].includes(equipment.tisObjectTypeGroupName);
	}

	function updateRange() {
		// :todo this function is always called when date range is changed so I will store rangeOffset into factory here
		$scope.twoWeekRange = [];
		let now = moment()
			.tz(locationDetailsService.getLocationTimezone())
			.startOf('day');
		let toDate = moment(now).subtract($scope.rangeOffset, 'days');
		let fromDate = moment(now).subtract($scope.rangeOffset + 13, 'days');
		for (let i = 0; i < TWO_WEEK_LENGTH; i++) {
			let d = moment(now).subtract($scope.rangeOffset + i, 'days');
			$scope.twoWeekRange.unshift({
				name: d.format('d'),
				title: d.format('dddd, MMMM D, YYYY'),
				date: d.format('YYYYMMDD'),
			});
		}
		watchDate = false;
		$scope.from = moment(fromDate);
		$scope.to = moment(toDate).add(1, 'days');
		$scope.maxDt = moment(now);
		$scope.toText = toDate.format('LL');
		$scope.fromText = $scope.from.format('LL');
		$scope.nextPeriodTitle = $scope.rangeOffset ? $filter('translate')('NEXT_TWO_WEEKS_SCROLL_TOOLTIP') : undefined;
		setTimeout(function() {
			watchDate = true;
		}, 50);
	}

	$scope.nextPeriod = helpers.debounce(function() {
		$scope.rangeOffset -= TWO_WEEK_LENGTH;
		$scope.rangeOffset = Math.max($scope.rangeOffset, 0);
		locationSettings.rangeOffset = $scope.rangeOffset;
		updateRange();
		urlService.changeDateRangeInUrl($scope.from, $scope.to.clone().add(-1, 'days'));
		fetchAggregatedServiceAdvisories();

		$scope.trackOnLinkClick(FACILITY_SUMMARY_PAGE_EVENTS.CHANGE_PAGINATION_OF_TEST_RESULTS, {
			[FACILITY_SUMMARY_PAGE_PROPERTIES.DIRECTION]: 'Forward',
		});
	}, 200);

	$scope.prevPeriod = helpers.debounce(function() {
		$scope.rangeOffset += TWO_WEEK_LENGTH;
		locationSettings.rangeOffset = $scope.rangeOffset;
		updateRange();
		urlService.changeDateRangeInUrl($scope.from, $scope.to.clone().add(-1, 'days'));
		fetchAggregatedServiceAdvisories();

		$scope.trackOnLinkClick(FACILITY_SUMMARY_PAGE_EVENTS.CHANGE_PAGINATION_OF_TEST_RESULTS, {
			[FACILITY_SUMMARY_PAGE_PROPERTIES.DIRECTION]: 'Backward',
		});
	}, 200);

	$scope.fromText = '';
	$scope.toText = '';
	$scope.from = moment()
		.tz(locationDetailsService.getLocationTimezone())
		.startOf('day');
	$scope.to = moment()
		.tz(locationDetailsService.getLocationTimezone())
		.startOf('day');
	$scope.maxDt = moment()
		.tz(locationDetailsService.getLocationTimezone())
		.startOf('day');
	$scope.openReport = openReport;
	$scope.isCprAllowed = locationDetailsService.isCprAllowed;
	$scope.isDirAllowed = locationDetailsService.isDirAllowed;
	$scope.isOfferingExpired = locationDetailsService.isOfferingExpired;
	$scope.getRangeHash = getRangeHash;
	$scope.locationURL = '';

	$scope.$watch('from', function() {
		if (watchDate) {
			$scope.rangeOffset = calculateRangeOffset($scope.to);

			// if endDate is invalid, use startDate to calculate range offset
			if (!$scope.to.isValid() && $scope.from.isValid()) {
				$scope.rangeOffset = calculateRangeOffset($scope.from) - TWO_WEEK_LENGTH;
			}
			locationSettings.rangeOffset = $scope.rangeOffset;
			updateRange();
			urlService.changeDateRangeInUrl($scope.from, $scope.to.clone().add(-1, 'days'));
			fetchAggregatedServiceAdvisories();
		}
	});

	function calculateRangeOffset(toDate) {
		const to = moment(+toDate.format('x'));
		const diffWithNowInMs = moment().diff(to);
		return Math.abs(Math.ceil(diffWithNowInMs / MILLISECONDS_IN_DAY));
	}

	function openReport(report) {
		if (report === REPORT_TYPES.REPORT_CHILLER_PERFORMANCE_ENGLISH.report && !locationDetailsService.isCprAllowed($scope.location)) {
			return;
		}

		googleAnalyticsService.sendFlowEvent('Report generation window', 'Open window', {
			label: 'RDR-embedded',
			value: 0,
		});
		if (report !== REPORT_TYPES.RAW_DATA.report || ($scope.location && $scope.location.locationName)) {
			$scope.modal = modalHelperService.open({
				templateUrl: 'components/reports/report-dialog.html',
				controller: 'ReportDialogCtrl',
				backdrop: 'static',
				windowClass: 'report-dialog full-height',
				resolve: {
					data: function() {
						return {
							report: report,
							locationData: $scope.location,
							defaultSelection: equipmentsOriginalHierarchyCache[0],
							equipmentsData: equipmentsOriginalHierarchyCache,
							limitToOneReportOnly: false,
							rangeFrom: $scope.from,
							rangeTo: $scope.to,
							maxDt: $scope.maxDt,
							rangeMode: 'custom',
						};
					},
				},
			});
		}
	}

	$scope.updateBetaText = function(selector) {
		let list = document.querySelectorAll(selector + ' li.beta'),
			betaText = $filter('translate')('BETA_TEXT');
		for (let i = 0; i < list.length; i++) list[i].setAttribute('title', betaText);
	};

	function addAggregatedServiceAdvisories(data, singleRowAdvisories, hierarchicalAdvisories) {
		// Adds Service Advisory data to the grid
		for (let i = 0; i < data.length; i++) {
			const equipmentData = data[i];
			const isHierarchical = hierarchicalAdvisories.hasOwnProperty(equipmentData.tisObjectId);
			const hasSingleRowAdvisories = singleRowAdvisories.hasOwnProperty(equipmentData.tisObjectId);
			const isAutomatedTestResult = equipmentData.hasOwnProperty('testType') && equipmentData.testType === 'hierarchical';

			const noTests = equipmentData.tisObjectTypeGroupName && !uniqueAdvisoryTypesByLocation.has(equipmentData.tisObjectTypeGroupName);
			equipmentData.statusList = getDefaultStatusList(noTests); // Give the item a default statusList of insufficient data

			if (!showedTisObjectTypeGroupName || showedTisObjectTypeGroupName.includes(equipmentData.tisObjectTypeGroupName)) {
				if (isAutomatedTestResult && hasSingleRowAdvisories) {
					equipmentData.statusList = singleRowAdvisories[equipmentData.tisObjectId];
				} else if (isHierarchical && !isAutomatedTestResult) {
					// Check if the data is hierarchical
					equipmentData.statusList = hierarchicalAdvisories[equipmentData.tisObjectId];
				} else if (hasSingleRowAdvisories) {
					equipmentData.statusList = singleRowAdvisories[equipmentData.tisObjectId];
				}
			}

			if (equipmentData.type === 'folder') {
				equipmentData.statusList = new Array(TWO_WEEK_LENGTH);
			}

			if (equipmentData.children) {
				addAggregatedServiceAdvisories(equipmentData.children, singleRowAdvisories, hierarchicalAdvisories);
			}
		}
	}

	function getUniqueTypes(serviceAdvisoryTypeList) {
		return serviceAdvisoryTypeList.reduce(function(types, el) {
			if (el.tisObjectType !== undefined) {
				types.add(el.tisObjectType.tisObjectTypeGroupName);
			}
			return types;
		}, new Set());
	}

	function setWeatherDataToScope(weatherObj) {
		$scope.weatherLoading = false;
		$scope.weather = weatherObj;
		if (weatherObj.lastCollected) {
			$scope.weather.lastCollected = weatherObj.lastCollected.tz(locationDetailsService.getLocationTimezone()).format('LLLL');
		} else {
			$scope.weather.lastCollected = $filter('translate')('N/A');
		}
		$scope.weather.isMoreWeather = false;
		$scope.weatherNA = $scope.weather === undefined;
		$scope.isWeatherStationUnavailable = $scope.weatherNA || !$scope.weather.isStation;
	}

	function setServiceAdvisoryCountToScope(count) {
		const number = Number.isInteger(+count) ? Number(count) : '?';
		$scope.location.serviceAdvisoryCount = number;
		$scope.facilityServiceAdvisoryCount = number;
		$scope.locationURL = $location
			.path()
			.split('/')
			.join('');
		$sessionStorage[$scope.locationURL] = $scope.facilityServiceAdvisoryCount;
	}
	$scope.facilityServiceAdvisoryCount = $sessionStorage[$scope.locationURL];

	function getRangeHash() {
		return `${$scope.from.format(CHART_DATE_FORMAT.RANGE_DATE_FORMAT)}-${$scope.to.format(CHART_DATE_FORMAT.RANGE_DATE_FORMAT)}`;
	}
	$scope.$on('$translateChangeSuccess', function() {
		let out = tisObjectListTransformService.transform(equipmentsOriginalHierarchyCache, $scope.locationId);
		$scope.equipments = out.tree;
		allFlatEquipments = out.flat;
	});

	$scope.$on('$routeChangeStart', function(next, current) {
		if ($scope.modal && $scope.modal.dismiss && $scope.modal.opened.$$state.value) {
			try {
				$scope.modal.dismiss();
			} catch (err) {
				return false;
			}
		}
	});

	$scope.updateTableHeader = helpers.debounce($rootScope.$broadcast.bind($rootScope, 'updateHeader'), 100);

	updateRange();
}
