/* eslint eqeqeq: [0, 'always'] */
/* eslint semi: [0, 'always'] */

// This controller is used for "Inventory" and "Internal Inventory" reports.
import {USER_EVENTS} from '../../../common/usage-tracking/categories';
import {getReportNameByKey} from '../../../common/usage-tracking/categories/report-generation/utils.js';

const {REPORT_GENERATION: {properties: REPORT_GENERATION_PROPERTIES}} = USER_EVENTS;

angular.module('TISCC').controller('InventoryReportCtrl', InventoryReportCtrl);

function InventoryReportCtrl($scope, $controller, $http, fileSaverService, REPORT_TYPES) {
	angular.extend(this, $controller('AbstractReportCtrl', {$scope: $scope}));
	angular.extend(this, $controller('AbstractCc3ReportCtrl', {$scope: $scope}));

	$scope.generateReportHandler = generateReportHandler;

	function generateReportHandler() {
		const {facilityId, locationName} = $scope.data.locationData;
		const {activeReport: {reportType}} = $scope;

		$scope.trackGenerateReportEvent({
			[REPORT_GENERATION_PROPERTIES.REPORT]: getReportNameByKey(Object.values(REPORT_TYPES).find(report => report.reportType === reportType).name),
		});

		return fileSaverService.downloadFromApi({
			...$scope.COMMON_REQUEST_PARAMETERS,
			data: {
				reportType,
				params: {
					facilityIDs: `${facilityId}`,
					facilityName: locationName,
				},
			},
		});
	}
}
