angular.module('TISCC').service('notificationsService', function($http, handleServiceWorker) {
	const storageKey = 'TCC4NotificationsOpened';

	this.setClosed = function() {
		window.sessionStorage.setItem(storageKey, 0);
	};

	this.setOpened = function() {
		window.sessionStorage.setItem(storageKey, 1);
	};

	this.wasOpened = function() {
		return !!Number(window.sessionStorage.getItem(storageKey));
	};

	/**
	 * @typedef {Object} Notification
	 * @property {string} content
	 * @property {string} displayFormat
	 * @property {string} iconType
	 * @property {number} notificationId
	 * @property {string} selected
	 * @property {string} startDate
	 * @property {string} status
	 * @property {string} title
	 *
	 * @example of Notification
	 * {
	 *   content: "The Command Center 3 database will be migrated to a new server with more CPU"
	 *   displayFormat: "Modeless Dialog"
	 *   iconType: "Release"
	 *   notificationId: 42
	 *   selected: false
	 *   startDate: "2020-06-20T00:00:00Z"
	 *   status: "Unread"
	 *   title: "Notification test 8"
	 * }
	 */

	/**
	 * Retrieves the list of notifications for the current user
	 * @return {Promise<Notification[]>}
	 */
	this.getList = function() {
		const url = '/ext_api/api/notification';
		return $http
			.get(url, {
				headers: handleServiceWorker.createServiceWorkerCacheKeyForRequest({
					cacheKey: `notificationsService_notification_${url}`,
					expiryTime: handleServiceWorker.CACHE_EXPIRE_TIME.IN_1_HOUR,
				}),
			})
			.then(data => {
				if (data.status === 200 || data.statusText === 'OK') {
					return data.data.tisNotificationList;
				} else {
					throw new Error('Unable to get notifications');
				}
			});
	};

	/**
	 * Deletes a notification by id
	 * @param {number} tisNotificationId
	 * @return {Promise<Notification[]>} - the list of deleted notifications
	 */
	this.deleteNotification = tisNotificationId =>
		$http.delete(`/ext_api/api/notification/${tisNotificationId}`).then(data => {
			if (data.status === 200 || data.statusText === 'OK') {
				return true;
			} else {
				throw new Error('Unable to delete an notification');
			}
		});

	/**
	 * Deletes the notifications by ids
	 * @param {number[]} tisNotificationIds
	 * @return {Promise<Notification[]>} - the list of deleted notifications
	 */
	this.deleteNotifications = tisNotificationIds =>
		$http
			.delete('/ext_api/api/notification', {
				data: tisNotificationIds,
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then(data => {
				if (data.status === 200 || data.statusText === 'OK') {
					return true;
				} else {
					throw new Error('Unable to delete the notifications');
				}
			});

	/**
	 * Marks as read a notification by id
	 * @param {number} tisNotificationId
	 * @return {Promise<Notification[]>} - the list of updated notifications
	 */
	this.markAsReadNotification = tisNotificationId =>
		$http.put(`/ext_api/api/notification/${tisNotificationId}/read`).then(data => {
			if (data.status === 200 || data.statusText === 'OK') {
				return true;
			} else {
				throw new Error('Unable to mark as read an notification');
			}
		});

	/**
	 * Marks as read the notifications by ids
	 * @param {number[]} tisNotificationIds
	 * @return {Promise<Notification[]>} - the list of updated notifications
	 */
	this.markAsReadNotifications = tisNotificationIds =>
		$http.put('/ext_api/api/notification/read', tisNotificationIds).then(data => {
			if (data.status === 200 || data.statusText === 'OK') {
				return true;
			} else {
				throw new Error('Unable to mark as read the notifications');
			}
		});
});
