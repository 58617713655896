angular.module('TISCC').service('overrideService', function($http, cacheService) {
	this.getOverridePriorityNames = function(codeName) {
		let config = {
			cache: cacheService.getCacheInstance('overrideService', 'getOverridePriorityNames', codeName),
		};
		let code = 'OverrideLevel' + codeName.charAt(0).toUpperCase() + codeName.toLowerCase().slice(1);
		return $http.get(`/ext_api/api/presentationCode?name=${code}`, config);
	};

	this.getDeviceType = function(locationId) {
		let config = {
			cache: cacheService.getCacheInstance('overrideService', 'getDeviceType', locationId),
		};
		return $http.get(`/ext_api/api/location/${locationId}/connectivity`, config);
	};
	this.getOverrideList = function(deviceUniqueIdentifierIds, startDate, timeZone, overrideLevelIds, callback) {
		let config = {
			params: {
				startDate: startDate.format(),
				timeZone: timeZone,
				overrideLevelIds: overrideLevelIds.join(','),
				duiIds: deviceUniqueIdentifierIds.join(','),
			},
		};
		return $http.get('/ext_api/api/device/override', config);
	};
});
