import _get from 'lodash.get';

angular
	.module('TISCC')
	.service('rawDataServerlessService', function($http, $filter, $q, $cookies, locationDetailsService, subtypeFilterService, configService, DEFAULTS) {
		const MIN_INSTANCES_NUMBER = 2;
		const CHILLER = 'Chiller';
		const COMPRESSOR = 'Compressor';
		const CIRCUIT = 'Circuit';
		const CIRCUIT_DETAILS = 'circuit_details';

		let translate = $filter('translate');
		let tisObjectIdsByTisObjectType;
		let currentSelectedElements = [];

		this.REPORT_API_ENDPOINT = configService.getExportAPIConfig();
		this.getTisObjectTypesFromTisObjects = getTisObjectTypesFromTisObjects;
		this.getTisObjectSubTypesFromTisObjects = getTisObjectSubTypesFromTisObjects;
		this.getPropertiesForRightList = getPropertiesForRightList;
		this.findTisObjectById = findTisObjectById;
		this.createEquipmentsList = createEquipmentsList;
		this.generateReportMetadata = generateReportMetadata;
		this.setCurrentSelectedElements = setCurrentSelectedElements;
		this.fixRequestEndTime = fixRequestEndTime;
		this.sendExportRequest = sendExportRequest;
		this.setBalancerCookie = setBalancerCookie;

		function setCurrentSelectedElements(elements) {
			currentSelectedElements = elements;
		}

		/**
		 * findSingleMultipleCircuit
		 *
		 * fn to indentify the checked / selected equipment (chiller) has single or multiple circuit.
		 * Based on circuit type, property display name will be shown on RDR
		 *
		 * This will check for chiller equipment only.
		 *
		 * @param {*} tisObjects
		 * @returns
		 */

		function findSingleMultipleCircuit(tisObjects = []) {
			const singleAndMultpleCircuitChillers = tisObjects.reduce(
				(av, tisObject) => {
					// if equipent other than chiller & not selected, don't proceed
					if (!tisObject.checked || tisObject.tisObjectType.tisObjectTypeGroupName !== CHILLER) return av;

					// if chiller has CIRCUIT equipments whch are greater than or equal to MIN_INSTANCES_NUMBER as children,
					//		it would be multiple circuit chiller
					// else
					//		it would be a single circuit chiller
					if (_get(tisObject, 'children.length') >= MIN_INSTANCES_NUMBER) {
						av.multiple = tisObject.children.filter(equipment => equipment.tisObjectType.tisObjectTypeGroupName === CIRCUIT).length > 1;
					} else {
						av.single = true;
					}

					return av;
				},
				{
					single: false,
					multiple: false,
				}
			);

			// function to map the findings ( single / multiple circuit ) with COMPRESSOR object where it will be used to show labels
			return function mapCircuitDetailsWithCompressorTypes(currentType) {
				const equipmentType = currentType.tisObjectType.tisObjectTypeGroupName;
				if (equipmentType !== COMPRESSOR) return;
				currentType[CIRCUIT_DETAILS] = singleAndMultpleCircuitChillers;
			};
		}

		function getTisObjectTypesFromTisObjects(tisObjects) {
			const types = {};

			const mapCircuitDetailsWithCompressorTypes = findSingleMultipleCircuit(tisObjects);

			tisObjects.forEach(tisObject => {
				const isEquipmentType = tisObject.tisObjectType.tisObjectTypeClassification === 'Equipment';

				if (tisObject.checked) {
					updateTisObjectTypes(types, tisObject);

					if (tisObject.children && !isEquipmentType) {
						processLeftPanelChildren(tisObject, types);
					}
				}
			});

			// Check selected equipment has chiller object or not
			const isChiller = Object.keys(types).some(typeName => {
				return typeName === CHILLER;
			});

			Object.keys(types).forEach(function(typeName) {
				// If tisObject includes fewer instances than MIN_INSTANCES_NUMBER, do not show this instance name in property name
				// Example: Comp Running (if < MIN_INSTANCES_NUMBER instances) vs Comp Ckt 1 Running (if >= MIN_INSTANCES_NUMBER)
				if (types[typeName].instances.length < MIN_INSTANCES_NUMBER) {
					types[typeName].instances = [null];
				} else {
					const selectedTisObjects = tisObjects.filter(obj => obj.checked);
					const isMultipleTisObjectsSelected = selectedTisObjects.length > 1;
					const isSomeTisObjectsWithOnlyOneChildSelected = selectedTisObjects.some(({children = []}) => children.length === 1);

					if (isMultipleTisObjectsSelected && isSomeTisObjectsWithOnlyOneChildSelected) {
						// when both a single & multiple circuit chiller is selected,
						// Don't add extra null value into COMPRESSOR instance
						if (!(typeName === COMPRESSOR && isChiller)) types[typeName].instances.push(null);
					}
				}
				mapCircuitDetailsWithCompressorTypes(types[typeName]);
			});
			tisObjectIdsByTisObjectType = types;
			return types;
		}

		function getTisObjectSubTypesFromTisObjects(tisObjects) {
			const subTypes = [];

			tisObjects.forEach(tisObject => {
				const isEquipmentType = tisObject.tisObjectType.tisObjectTypeClassification === 'Equipment';

				if (tisObject.checked && tisObject.children && !isEquipmentType && tisObject.subTypeFilterKey) {
					if (!subTypes.includes(tisObject.subTypeFilterKey)) {
						subTypes.push(tisObject.subTypeFilterKey);
					}
				}
			});

			return subTypes;
		}

		function processLeftPanelChildren(tisObject, types) {
			tisObject.children.forEach(function(tisObjectChild) {
				let classification = tisObjectChild.tisObjectType.tisObjectTypeClassification;

				if (classification !== 'ComponentObject' && classification !== 'Equipment') {
					updateTisObjectTypes(types, tisObjectChild);
				}
				if (tisObjectChild.children) {
					processLeftPanelChildren(tisObjectChild, types);
				}
			});
		}

		function updateTisObjectTypes(types, tisObject) {
			const tisObjectTypeGroupName = tisObject.tisObjectType.tisObjectTypeGroupName;
			const instance = tisObject.instance;

			if (!types[tisObjectTypeGroupName]) {
				types[tisObjectTypeGroupName] = {
					instances: [],
					instancesTisObjectId: {},
					tisObjectIds: [],
					tisObjectType: tisObject.tisObjectType,
				};
			}
			const currentType = types[tisObjectTypeGroupName];

			if (instance && currentType.instances.indexOf(instance) === -1) {
				currentType.instances.push(instance);
			}
			if (currentType.tisObjectIds.indexOf(tisObject.tisObjectId) === -1) {
				currentType.tisObjectIds.push(tisObject.tisObjectId);
			}

			// if equipment has multi circuit,
			// store the circuit ids which will be used to render correct property when one of multi circuit associated property is selected
			if (instance) {
				if (!currentType.instancesTisObjectId[instance]) currentType.instancesTisObjectId[instance] = {};

				const addInstanceIds = currentType.instancesTisObjectId[instance];

				addInstanceIds[tisObject.tisObjectId] = true;
			}
		}

		/**
		 * @param {{instances: {tisObjectType: string},
		 * property: {propertyAttribute: {enumerationGroupNameAndName: string}}, tisObjectType: string}}propertyData
		 * @returns {Array}
		 */
		function getPropertiesForRightList(propertyData) {
			let property = propertyData.property;

			return propertyData.instances.map(function(instance) {
				return {
					tisObjectType: propertyData.tisObjectType,
					value: createPropertyNameValue(propertyData, instance),
					instance: instance,
					propertyName: property.propertyName,
					propertyEnumeration: property.propertyAttribute && property.propertyAttribute.enumerationGroupNameAndName,
					isCharacteristic: property.isCharacteristic,
					digitResolution: property.propertyAttribute && property.propertyAttribute.resolution ? property.propertyAttribute.resolution : undefined,
					unitOfMeasureName:
						property.propertyAttribute && property.propertyAttribute.unitOfMeasureName ? property.propertyAttribute.unitOfMeasureName : undefined,
					uom: propertyData.property.unitOfMeasure,
					typeName: property.typeName,
					checked: false,
					dataType: _get(property, 'propertyAttribute.dataType', ''),
					instancesTisObjectId: (propertyData.instancesTisObjectId || {})[instance] || null,
				};
			});
		}

		function createPropertyNameValue(propertyData, instance) {
			let property = propertyData.property;
			let propertyName = property.propertyName;
			let tisObjectTypeName = propertyData.tisObjectType.tisObjectTypeGroupName;
			let value;

			if (instance) {
				value = $filter('translateProperty')('M_' + propertyName, tisObjectTypeName, {
					name: translate(instance),
				});
				// TODO Remove this check when localization files will be filled.
				if (value.indexOf('M_') === 0) {
					value = translate(instance) + ' ' + $filter('translateProperty')(propertyName, tisObjectTypeName);
				}
			} else {
				value = $filter('translateProperty')(propertyName, tisObjectTypeName);
			}
			return updateCharacteristicDisplayName(value, property);
		}

		function updateCharacteristicDisplayName(displayName, obj) {
			return displayName.replace('‘', '') + (obj.isCharacteristic ? ' ***' : '');
		}

		function findTisObjectById(objectId, searchArr, parentId) {
			let foundTisObject = null;

			if (!searchArr) {
				searchArr = currentSelectedElements;
			}

			for (let i = 0; i < searchArr.length; i++) {
				let type = searchArr[i].tisObjectType.tisObjectTypeClassification;

				if (searchArr[i].tisObjectId === objectId) {
					foundTisObject = searchArr[i];

					setParentIdIfNeeded(foundTisObject, parentId);
					break;
				} else if (searchArr[i].children && searchArr[i].children.length !== 0) {
					let compObjId;
					if (type === 'ComponentObject') {
						compObjId = searchArr[i].tisObjectId;
					} else if (parentId) {
						compObjId = parentId;
					}
					foundTisObject = findTisObjectById(objectId, searchArr[i].children, compObjId);

					if (foundTisObject) {
						setParentIdIfNeeded(foundTisObject, compObjId);
						break;
					}
				}
			}
			return foundTisObject;
		}

		function setParentIdIfNeeded(tisObject, parentId) {
			if (parentId && isComponent(tisObject.tisObjectType)) {
				tisObject.parentId = parentId;
			}
		}

		function createEquipmentsList(params) {
			const {equipments, typeId, rootId} = params;
			const validParents = [rootId];

			return equipmentsIterator({
				equipments: equipments,
				typeId: typeId,
				rootId: rootId,
				validParents: validParents,
			});
		}

		function equipmentsIterator(params) {
			const {equipments, typeId, rootId, parentId, validParents} = params;
			let equipmentsData = [];

			equipments.forEach(function(equipment) {
				if (equipment.tisObjectType.tisObjectTypeGroupNumber === typeId) {
					const {tisObjectId, tisObjectSubType, tisObjectClassificationType, children} = equipment;
					if (tisObjectId && tisObjectSubType && tisObjectClassificationType && children) {
						equipment.subTypeFilterKey = subtypeFilterService.getFilterKeyNameFromObjectWithHierarchy(equipment);
					}
					if (parentId) {
						if (isVasOrLoadValve(typeId)) {
							if (validParents.indexOf(parentId) !== -1) {
								validParents.push(equipment.tisObjectId);
								equipmentsData.push(equipment);
							}
						} else {
							equipmentsData.push(equipment);
						}
					} else {
						equipmentsData.push(equipment);
					}
				}
				if (equipment.children) {
					equipmentsData = equipmentsData.concat(
						equipmentsIterator({
							equipments: equipment.children,
							typeId: typeId,
							rootId: rootId,
							validParents: validParents,
							parentId: equipment.tisObjectId,
						})
					);
				}
			});
			return equipmentsData;
		}

		function isVasOrLoadValve(id) {
			// tisObjectTypeGroupNumber values we need to show sub-sets
			return [14, 21].indexOf(id) !== -1; // Hardcoded VAS type and LoadValve
		}

		function generateReportMetadata(params) {
			const {
				reportFormat,
				locationName,
				reportExtension,
				equipmentType,
				includeDataWithErrors,
				equipmentList,
				locationId,
				isAddChildPrefixToHpath,
				isIncludeNonStandardProperties,
				allProperties,
				isIncludeWeather,
			} = params;
			let {selectedProperties, selectedEquipments} = params;

			const properties = selectedProperties.map(prop => prop.propertyName);
			selectedEquipments = selectedEquipments.map(element => {
				return {
					tisObjectId: element.tisObjectId,
					tisObjectName: element.tisObjectName,
					children: element.children,
					tisObjectType: {
						tisObjectTypeGroupName: element.tisObjectType.tisObjectTypeGroupName,
						tisObjectTypeClassification: element.tisObjectType.tisObjectTypeClassification,
					},
				};
			});

			let propertiesByTisObjectType = separatePropertiesByTisObjectType(selectedProperties);
			let timezone = locationDetailsService.getLocationTimezone();
			let from = params.from.tz(timezone).format();
			let to = fixRequestEndTime(params.to.tz(timezone)).format();

			let tisObjectIds = [];

			Object.keys(propertiesByTisObjectType)
				.filter(tisObjectTypeName => tisObjectTypeName !== 'weather')
				.forEach(tisObjectTypeName => {
					let tisObjectTypeProperties = propertiesByTisObjectType[tisObjectTypeName];
					let retrievedTisObjects = tisObjectIdsByTisObjectType[tisObjectTypeName].tisObjectIds;

					const tisObjectType = tisObjectTypeProperties[0].tisObjectType;
					if (isComponent(tisObjectType)) {
						retrievedTisObjects = getComponentParentID({tisObjectType, tisObjectIds: retrievedTisObjects, equipmentList});
					}
					tisObjectIds.push(...retrievedTisObjects);
					tisObjectIds = [...new Set(tisObjectIds)]; // Just a array.unique
				});
			const config = {
				type: 'report',
				subtype: 'raw-data',
				locationName,
				equipmentType,
				from,
				to,
				locationId,
				tisObjectIds,
				properties: allProperties ? [] : properties,
				reportExtension,
				timezone: timezone,
				reportFormat,
				includeDataWithErrors,
				isAddChildPrefixToHpath,
				isIncludeNonStandardProperties,
				allProperties,
				isIncludeWeather,
			};

			return $q.resolve(config);
		}

		function getComponentParentID({tisObjectTypeProperties, tisObjectIds = [], equipmentList}) {
			const findElement = (inputArray, idToFind) => {
				let foundID = null;
				const iteratingFunction = (inputArray, idToFind, parentID = null) => {
					inputArray.some(item => {
						if (isComponent(item.tisObjectType) && item.tisObjectId === idToFind) {
							foundID = parentID;
							return true;
						} else if (item.children && item.children.length > 0) {
							iteratingFunction(item.children, idToFind, isComponent(item.tisObjectType) ? parentID : item.tisObjectId);
						}
					});
				};
				iteratingFunction(inputArray, idToFind);
				return foundID;
			};

			let resultingTisObjectIds = [];
			resultingTisObjectIds = tisObjectIds.map(tisObjectIDToFind => {
				return findElement(equipmentList, tisObjectIDToFind);
			});

			return resultingTisObjectIds;
		}
		function fixRequestEndTime(endTime) {
			const now = moment().tz(endTime.tz());

			if (moment(endTime).isAfter(now)) {
				endTime = now;
			} else if (moment(endTime).isBefore(now)) {
				endTime = moment(endTime).subtract(1, 'seconds');
			}

			return endTime;
		}

		function separatePropertiesByTisObjectType(properties) {
			let tisObjectTypeProperties = {};

			properties.forEach(function(property) {
				let tisObjectType = property.tisObjectType ? property.tisObjectType.tisObjectTypeGroupName : 'weather';
				let typeData = tisObjectTypeProperties[tisObjectType];

				if (typeof typeData !== 'object') {
					typeData = tisObjectTypeProperties[tisObjectType] = [];
				}
				if (typeData.indexOf(property) === -1) {
					typeData.push(property);
				}
			});
			return tisObjectTypeProperties;
		}

		/*
	Adding a special cookie, for load balancers to preserve request batch
	 */
		function setBalancerCookie(progressKey) {
			$cookies.put(DEFAULTS.LOAD_BALANCER_COOKIE_NAME, progressKey);
		}

		function sendExportRequest(data) {
			setBalancerCookie(data.progressKey);
			return $http.post(`${this.REPORT_API_ENDPOINT}/export`, data, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			});
		}

		function isComponent(tisObjectType) {
			const componentKeyName = 'Component';

			return tisObjectType && tisObjectType.tisObjectTypeClassification === componentKeyName;
		}
	});
