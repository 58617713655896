const services = new WeakMap();

class ChillerProfileSettingsPopupComponentController {
	constructor(helpers) {
		services.set(this, {helpers});
		this.formDisabled = true;
	}

	$onInit() {
		this.intactValues = {};

		const {ChillerCapacityNominal, ChillerEnteredService} = this.data.values;
		const getChillerEnteredServiceAsMomentDate = () => ChillerEnteredService && moment.tz(ChillerEnteredService, this.data.timeZone);

		this.intactValues.chillerCapacityNominal = ChillerCapacityNominal;
		this.intactValues.chillerEnteredService = getChillerEnteredServiceAsMomentDate();

		this.chillerCapacityNominal = ChillerCapacityNominal;
		this.chillerEnteredService = getChillerEnteredServiceAsMomentDate();
		this.tmpDate = this.chillerEnteredService && this.chillerEnteredService.clone();
		this.formDisabled = false;
		this.header = this.data.header || 'ENTER_CHILLER_PROFILE_DATA';
	}

	save() {
		this.modalInstance.close({
			ChillerCapacityNominal: this.chillerCapacityNominal,
			ChillerEnteredService: this.chillerEnteredService,
		});
	}

	cancel() {
		this.modalInstance.dismiss('cancel');
	}

	onChangeDate({start}) {
		this.chillerEnteredService = start;
	}

	isSaveButtonEnabled() {
		const isFormIntact =
			this.chillerCapacityNominal === this.intactValues.chillerCapacityNominal &&
			this.chillerEnteredService &&
			this.intactValues.chillerEnteredService &&
			this.chillerEnteredService.isSame(this.intactValues.chillerEnteredService);

		return this.chillerCapacityNominal !== undefined && this.chillerCapacityNominal !== null && this.chillerEnteredService && !isFormIntact;
	}
}

export default {
	templateUrl: 'components/reports/chiller-performance/modals/chiller-profile-settings/chiller-profile-settings-popup.html',
	controller: ChillerProfileSettingsPopupComponentController,
	bindings: {
		modalInstance: '<',
		data: '<',
	},
};
