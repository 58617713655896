(function(ScatterChartRenderer) {
	const DAMPER_OFFSET = 15;

	class ScatterChartWithLimitsRenderer extends ScatterChartRenderer {
		constructor(svg, externalMethods, clipPathId, x) {
			super(svg, externalMethods, clipPathId, x);
			this.subType = 'scatterWithLimits';
			this.svg = svg;
			this.topDamper = svg.append('g');
			this.bottomDamper = svg.append('g');
		}

		onSvgReceivedSize(updateData) {
			super.onSvgReceivedSize(updateData);
			this.createDamperRegions(this.svg);
			this.bottomDamper.selectAll('text').attr('transform', 'translate(' + updateData.width + ',' + updateData.chartHeight + ')');
		}

		createDamperRegions(svg) {
			const damperVisibilityValue = this.noData ? 'hidden' : 'visible';
			this.topDamper.selectAll('*').remove();
			this.topDamper
				.style('visibility', damperVisibilityValue)
				.append('text')
				.text('Damper Stuck Open Region')
				.attr('class', 'damper-caption')
				.attr('y', 8)
				.attr('dx', DAMPER_OFFSET)
				.attr('dy', DAMPER_OFFSET);
			this.bottomDamper.selectAll('*').remove();
			this.bottomDamper
				.style('visibility', damperVisibilityValue)
				.append('text')
				.text('Damper Stuck Closed Region')
				.attr('class', 'damper-caption')
				.attr('text-anchor', 'end')
				.attr('dx', -DAMPER_OFFSET)
				.attr('dy', -DAMPER_OFFSET);
		}
	}

	window.ScatterChartWithLimitsRenderer = ScatterChartWithLimitsRenderer;
})(window.ScatterChartRenderer);
