/* eslint quotes: [0, 'single'] */
/* eslint eqeqeq: [0, 'always'] */
/* eslint one-var: 0 */
import * as R from 'ramda';

(function() {
	let EMPTY_FUNCTION = function() {};
	let COLUMN_MIN_WIDTH = 6; // in %

	angular
		.module('fixedHeader', [])
		.directive('fixedHeader', function(
			$window,
			$translate,
			$rootScope,
			$timeout,
			$compile,
			$http,
			modalHelperService,
			$templateRequest,
			$filter,
			uiStateService,
			helpers,
			FIXED_TREE_SORT
		) {
			return {
				scope: {
					data: '=',
					parameters: '=',
					search: '=',
					filters: '=',
					toggleSuppression: '=',
					screen: '=',
					onApplyFilterHandler: '&',
					onRemoveFilterHandler: '&',
				},
				priority: 100,
				link: function link(scope, elem, attributes) {
					let tableElement = elem[0];
					let hiddenHeader;
					let baseTable = elem.next().find('table');
					let currentSortEl = null;
					let cloneTh;
					let th;
					let resizeIndex;
					let width;
					let prevWidth = [];
					let startXPos = 0;
					let dropdown;
					let lastOrder = false;
					let popup;
					let storedState;

					$templateRequest('common/fixed-header/advanced-sort-dropdown.html').then(function(resp) {
						dropdown = $compile(resp)(scope);
					});

					if (!scope.uiStateKey) {
						scope.uiStateKey = attributes.uistatekey ? attributes.uistatekey : null;
						if (scope.uiStateKey && uiStateService.stateExists(scope.uiStateKey)) {
							storedState = uiStateService.getState(scope.uiStateKey);
						}
					}
					if (!scope.parameters) {
						if (storedState && storedState.parameters) {
							scope.parameters = storedState.parameters;
						} else {
							scope.parameters = [{}];
						}
						update();
					}

					if (!scope.filters) {
						if (storedState && storedState.filters) {
							scope.filters = storedState.filters;
						} else {
							scope.filters = {};
						}
					}
					scope.searchObj = {
						searchText: '',
					};

					function copyHeaders() {
						if (!baseTable) return;

						if (hiddenHeader) {
							hiddenHeader.remove();
						}
						hiddenHeader = elem.find('thead').clone();
						hiddenHeader.addClass('hidden-header');
						baseTable.prepend(hiddenHeader);
						cloneTh = angular.element(hiddenHeader[0].querySelectorAll('th[sort]'));
					}

					function copyHeadersUpdateWidth() {
						copyHeaders();
						updateTimeoutWidth();
					}

					function applyStoredState() {
						if (scope.uiStateKey && uiStateService.stateExists(scope.uiStateKey)) {
							storedState = uiStateService.getState(scope.uiStateKey);
							if (storedState.headers && storedState.headers.length) {
								for (let idx = 0; idx < th.length; idx++) {
									th[idx].style.width = storedState.headers[idx];
									if (cloneTh !== undefined && cloneTh[idx] !== undefined) {
										cloneTh[idx].style.width = storedState.headers[idx];
									}
								}
							}
							if (storedState.filters) {
								scope.filters = storedState.filters;
								updateFilters();
							}

							if (storedState.parameters) {
								scope.parameters = storedState.parameters;
								update();
							}
						}
					}

					function clickEvent(event) {
						currentSortEl = angular.element(event.currentTarget);

						let column = currentSortEl.attr('sort');
						let target = angular.element(event.target);

						if (target.hasClass('resize')) {
							// do nothing;
						} else if (target.hasClass('advanced-sort')) {
							let clickedColName = currentSortEl[0].querySelector('.js-col-name');
							scope.columnName = clickedColName ? clickedColName.innerHTML : '';
							scope.columnType = currentSortEl.attr('type') || 'string';
							$timeout(function() {
								showDropdown(target, column);
							}, 0);
						} else if (target.hasClass('toggle-suppression')) {
							hideDropdown();
						} else if (column && event.shiftKey) {
							addSort(column);
						} else if (column) {
							setMainSort(column);
						}
					}

					function getOrderClass(order) {
						return order ? 'up' : 'down';
					}

					function setMainSort(column) {
						let order = lastOrder;
						if (currentSortEl.hasClass('down')) {
							order = true;
						} else if (currentSortEl.hasClass('up')) {
							order = false;
						}
						scope.parameters = [
							{
								column: column,
								order: order,
							},
						];
						update();
					}

					function updateSortArrows() {
						let prevSortEl = angular.element(tableElement.querySelectorAll('.up, .down'));

						if (prevSortEl.length) {
							prevSortEl.removeClass('up');
							prevSortEl.removeClass('down');
						}

						addSortArrows();
					}

					function findSortObject(column) {
						for (let i = scope.parameters.length; i--; ) {
							if (scope.parameters[i].column === column) return scope.parameters[i];
						}
						return null;
					}

					function changeSort(order) {
						let sortObj = findSortObject(scope.activeColumn);
						if (!sortObj) {
							sortObj = {column: scope.activeColumn};
							scope.parameters.push(sortObj);
						}
						sortObj.order = order;
						update();
						hideDropdown();
					}

					function addSort(column) {
						let sortObj = findSortObject(column);

						if (!sortObj) {
							scope.parameters.push({
								column: column,
								order: lastOrder,
							});
						} else {
							sortObj.order = !sortObj.order;
						}
						update();
					}

					scope.sortAsc = function(event) {
						changeSort(true);
						event.stopPropagation();
					};

					scope.sortDesc = function(event) {
						changeSort(false);
						event.stopPropagation();
					};

					scope.removeSort = function(event) {
						let sortObj = findSortObject(scope.activeColumn);
						let index = scope.parameters.indexOf(sortObj);

						scope.parameters.splice(index, 1);
						update();
						hideDropdown();
						event.stopPropagation();
					};

					function update() {
						updateSortArrows();
						let uiState = uiStateService.getState(scope.uiStateKey) || {};
						if (scope.parameters) {
							uiState.parameters = scope.parameters;
						}
						uiStateService.registerState(scope.uiStateKey, uiState);
						scope.$emit('applySort', scope.parameters);
						$timeout(EMPTY_FUNCTION);
					}

					function showDropdown(button, column) {
						window.addEventListener('click', hideDropdown);
						button.append(dropdown);
						scope.activeColumn = column;
					}

					function hideDropdown() {
						if (dropdown.parent().length) {
							dropdown[0].parentNode.removeChild(dropdown[0]);
							window.removeEventListener('click', hideDropdown);
						}
					}

					function startResize(e) {
						for (let i = 0, len = th.length; i < len; i++) {
							if (th[i] === e.target.parentNode) resizeIndex = i;
							prevWidth[i] = parseFloat(th[i].style.width);
						}
						startXPos = e.clientX;
						window.addEventListener('mousemove', moveResize);
						window.addEventListener('mouseup', detachMoveResizeEvent);
					}

					function cutWidth(width, index, direct) {
						if (index < 0 || index >= th.length) {
							return width;
						}

						let newWidth = prevWidth[index] - width;
						let overWidth = 0;

						if (newWidth < COLUMN_MIN_WIDTH) {
							newWidth = COLUMN_MIN_WIDTH;
							overWidth = cutWidth(width - prevWidth[index] + COLUMN_MIN_WIDTH, index + direct, direct);
						}

						th[index].style.width = newWidth + '%';
						cloneTh[index].style.width = th[index].style.width;

						return overWidth;
					}

					function moveResize(e) {
						let offset = (e.clientX - startXPos) * 100 / width;

						if (offset >= 0) {
							offset -= cutWidth(offset, resizeIndex + 1, 1);
							th[resizeIndex].style.width = prevWidth[resizeIndex] + offset + '%';
							cloneTh[resizeIndex].style.width = th[resizeIndex].style.width;
						} else {
							offset += cutWidth(-offset, resizeIndex, -1);
							th[resizeIndex + 1].style.width = prevWidth[resizeIndex + 1] - offset + '%';
							cloneTh[resizeIndex + 1].style.width = th[resizeIndex + 1].style.width;
						}

						updateWidth();
					}

					function detachMoveResizeEvent() {
						// Save new state to the service
						let preparedState = uiStateService.getState(scope.uiStateKey) || {};
						preparedState.headers = [];

						if (scope.uiStateKey) {
							Array.prototype.forEach.call(th, function(col) {
								if (col.style.width) {
									preparedState.headers.push(col.style.width);
								}
							});

							if (preparedState.headers.length > 0) {
								uiStateService.registerState(scope.uiStateKey, preparedState);
							}
						}
						window.removeEventListener('mousemove', moveResize);
						window.removeEventListener('mouseup', detachMoveResizeEvent);
					}

					function addSortArrows() {
						angular.forEach(scope.parameters, function(obj, index) {
							let domEl = tableElement.querySelector("[sort='" + obj.column + "']");
							let el = angular.element(domEl);
							el.addClass(getOrderClass(obj.order));
							lastOrder = obj.order;
							updateSortLevel(domEl, index);
						});
						showHideSortLevels();
					}

					function showHideSortLevels() {
						if (scope.parameters.length > 1) {
							elem.removeClass('hide-levels');
						} else {
							elem.addClass('hide-levels');
						}
					}

					function updateSortLevel(columnObj, index) {
						let domEl = columnObj.querySelector('.sort-level');
						domEl.innerHTML = index + 1;
					}

					// ------------------------ FILTERS --------------------------------

					function getFilteredData(column) {
						let newFilters = copyFilters();
						let data = scope.data;
						deleteFilter(newFilters, column);
						data = $filter('filter')(data, scope.search);
						data = $filter('tableFilter')(data, newFilters);

						return data;
					}

					function findUniqueData(column) {
						let data = getFilteredData(column);
						let res = [];
						let i, item, value;
						let isNestedColumnName = column.includes('.');
						if (scope.toggleSuppression && !scope.toggleSuppression.isShowSuppressed) {
							data = data.filter(item => item.testStatus.isNotSuppressed);
						}

						for (i = data.length; i--; ) {
							item = data[i];
							if (isNestedColumnName) {
								value = helpers.getPropertyByPath(item, column);
							} else {
								value = item[column];
							}
							if (!res.includes(value)) {
								res.push(value);
							}
						}
						return res;
					}

					function findListUniqueData(column) {
						let data = getFilteredData(column),
							res = [],
							i,
							j,
							item,
							emptyValue = false;

						for (i = data.length; i--; ) {
							for (j = data[i][column].length; j--; ) {
								item = data[i][column][j];
								if (!res.includes(item)) {
									res.push(item);
								}
							}
							!data[i][column].length && (emptyValue = true);
						}
						if (emptyValue) {
							res.push(undefined);
						}
						return res;
					}

					function findTreeUniqueData(column) {
						const newFilters = copyFilters();
						const convertToPlainArray = function(data, inputArray) {
							for (let i = 0; i < data.length; i++) {
								const item = data[i];
								inputArray.push(item);
								if (item.children) {
									convertToPlainArray(item.children, inputArray);
								}
							}
							return inputArray;
						};

						const filterOutComponentEquipments = value => {
							return !FIXED_TREE_SORT.COMPONENT_CRITERIA.includes(value);
						};

						const getData = (data, arr = []) => {
							for (let i = 0; i < data.length; i++) {
								let value = data[i][column];

								if (!arr.includes(value)) {
									if (data[i].type == 'folder') continue;
									arr.push(value);
								}
							}
							return arr;
						};
						let resultingData = [];

						deleteFilter(newFilters, column);
						const filteredData = $filter('filter')(scope.data, scope.search);
						resultingData = convertToPlainArray(filteredData, resultingData);
						resultingData = $filter('tableFilter')(resultingData, newFilters);
						resultingData = getData(resultingData);
						// Excluding components from the list
						if (column === FIXED_TREE_SORT.COLUMN_NAME) {
							resultingData = resultingData.filter(filterOutComponentEquipments);
						}
						return resultingData;
					}

					function generatePopupData(column, type) {
						let checked = getFilterProp(scope.activeColumn, 'equals');
						let uniqueData,
							res = [],
							item,
							i;
						let defState = !checked.length;

						if (type === 'list') {
							uniqueData = findListUniqueData(column);
						} else {
							uniqueData = findUniqueData(column);
						}

						for (i = uniqueData.length; i--; ) {
							item = uniqueData[i];
							res.push({value: item, checked: defState});

							if (checked.includes(item)) {
								res[res.length - 1].checked = true;
							}
						}

						return res;
					}

					function generateTreePopupData(column) {
						let checked = getFilterProp(scope.activeColumn, 'equals'),
							uniqueData,
							res = [],
							item,
							i,
							defState = !checked.length;

						uniqueData = findTreeUniqueData(column);

						for (i = uniqueData.length; i--; ) {
							item = uniqueData[i];

							if (!item) {
								continue;
							}

							res.push({value: item, checked: defState});

							if (checked.includes(item)) {
								res[res.length - 1].checked = true;
							}
						}

						return res;
					}

					function filterPopupList() {
						scope.popupListData = $filter('filter')(scope.columnData, scope.searchObj.searchText);
						scope.hiddenItemsByFilter =
							$filter('filter')(scope.columnData || [], {checked: true}).length -
							$filter('filter')(scope.popupListData || [], {checked: true}).length;

						updateSelectAllBtn();
					}

					function generateDate(year, month, day) {
						return moment({years: year, months: month, days: day});
					}

					function showFilterDatePopup() {
						let item,
							min,
							max,
							column = scope.activeColumn,
							data = getFilteredData(column),
							filter = getFilterProp(column, 'date');
						data = data.filter(item => item[column] !== ''); // To exclude empty timestamps
						if (data && data.length) {
							min = max = data[0][column];

							for (let i = data.length; i--; ) {
								item = data[i];
								if (item[column] < min) {
									min = item[column];
								}
								if (item[column] > max) {
									max = item[column];
								}
							}
						} else {
							min = max = new Date();
						}

						scope.from = filter.min || generateDate(min.getFullYear(), min.getMonth(), min.getDate());
						scope.to = filter.max || generateDate(max.getFullYear(), max.getMonth(), max.getDate() + 1);
						scope.maxDt = generateDate(max.getFullYear(), max.getMonth(), max.getDate());
						scope.maxDtCopy = generateDate(max.getFullYear(), max.getMonth(), max.getDate() + 1);
						scope.minDt = generateDate(min.getFullYear(), min.getMonth(), min.getDate());
					}

					function showFilterTreePopup() {
						scope.searchObj.searchText = getFilterProp(scope.activeColumn, 'search');
						scope.columnData = generateTreePopupData(scope.activeColumn, scope.columnType);
						scope.popupListData = scope.columnData;
						filterPopupList();
						updateSelectAllBtn();
					}

					function showFilterStringPopup() {
						scope.searchObj.searchText = getFilterProp(scope.activeColumn, 'search');
						scope.columnData = generatePopupData(scope.activeColumn, scope.columnType);
						scope.popupListData = scope.columnData;
						updateSelectAllBtn();
					}

					function showFilterNumberPopup() {
						let item,
							min,
							max,
							column = scope.activeColumn,
							data = getFilteredData(column),
							datacpy = R.clone(data),
							filter = getFilterProp(column, 'range');
						for (let i = datacpy.length; i--; ) {
							datacpy[i][column] = datacpy[i][column] ? datacpy[i][column] : -999999;
						}

						if (data && data.length) {
							min = max = datacpy[0][column];

							for (let i = datacpy.length; i--; ) {
								item = datacpy[i];
								if (item[column] < min) {
									min = item[column];
								}
								if (item[column] > max) {
									max = item[column];
								}
								if (min === -999999) min = 0;
								if (max === -999999) max = 0;
							}
						} else {
							min = max = 0;
						}
						scope.from = filter.min && filter.min > min ? filter.min : min;
						scope.to = filter.max && filter.max < max ? filter.max : max;
						scope.min = min;
						scope.max = max;
					}

					function updatePopupData() {
						switch (scope.columnType) {
							case 'date':
								showFilterDatePopup();
								break;
							case 'time':
							case 'number':
								showFilterNumberPopup();
								break;
							case 'tree':
								showFilterTreePopup();
								break;
							default:
								showFilterStringPopup();
								break;
						}
					}

					function showFilterPopup() {
						if (scope.columnType) {
							hideDropdown();
							updatePopupData();
							popup = modalHelperService.open({
								templateUrl: `common/fixed-header/filter-popups/${scope.columnType}.html`,
								scope: scope,
								backdrop: 'static',
								windowClass: 'small',
							});
						}
					}

					function updateFilterIcons() {
						let prevSortEl = angular.element(tableElement.querySelectorAll('.filter-on'));
						if (prevSortEl.length) {
							prevSortEl.removeClass('filter-on');
						}
						addFilterIcons();
					}

					function addFilterIcons() {
						for (let column in scope.filters) {
							if (scope.filters[column]) {
								let domEl = tableElement.querySelector("[sort='" + column + "']"),
									el = angular.element(domEl);
								el.addClass('filter-on');
							}
						}
					}

					function getFilterProp(column, prop) {
						let f = scope.filters[column] || {};
						return f[prop] || [];
					}

					function setStringFilter(column, text, columnData, skipUiCheck) {
						scope.filters[column] = scope.filters[column] || {type: scope.columnType};
						let f = scope.filters[column],
							values = [],
							item;
						if (skipUiCheck) {
							values = columnData;
						} else {
							for (let i = columnData.length; i--; ) {
								item = columnData[i];
								if (item.checked) {
									values.push(item.value);
								}
							}

							if (values.length == columnData.length) {
								values = [];
							}
						}
						f.search = text.toString();
						f.equals = values;

						if (!f.search && (!values || !values.length)) {
							deleteFilter(scope.filters, column);
						} else {
							!f.search && delete f.search;
							(!values || !values.length) && delete f.equals;
							scope.$emit('applyFilter', {filters: scope.filters, parentScreen: scope.screen});
						}
					}

					function setDateFilter(column, from, to) {
						scope.filters[column] = scope.filters[column] || {type: scope.columnType};
						let f = scope.filters[column];

						if (!scope.maxDtCopy.isSame(to) || !scope.minDt.isSame(from)) {
							f.date = !from && !to ? f.date : {min: from, max: to};
							scope.$emit('applyFilter', {filters: scope.filters, parentScreen: scope.screen});
						} else {
							delete scope.filters[column];
						}
					}

					function setNumberFilter(column, from, to, skipUiCheck) {
						scope.filters[column] = scope.filters[column] || {type: scope.columnType};
						let f = scope.filters[column];

						if (scope.max != to || scope.min != from) {
							f.range = !from && !to ? f.range : {min: from, max: to};
							scope.$emit('applyFilter', {filters: scope.filters, parentScreen: scope.screen});
						} else {
							delete scope.filters[column];
						}
					}

					function deleteFilter(filters, column) {
						delete filters[column];
						scope.$emit('applyFilter', {filters: scope.filters, parentScreen: scope.screen});
					}

					function copyFilters() {
						return angular.copy(scope.filters);
					}

					function updateSelectAllBtn() {
						let checkedCount = $filter('filter')(scope.popupListData || [], {checked: true}).length;

						if (!checkedCount) {
							scope.checkAll = false;
						} else if (scope.popupListData.length == checkedCount) {
							scope.checkAll = true;
						} else {
							scope.checkAll = null;
						}
					}

					scope.timeFormat = function(value) {
						return $filter('date')(value, 'shortTime');
					};

					scope.changeFilter = function(event) {
						showFilterPopup();
						event.stopPropagation();
					};

					scope.removeFilter = function(event) {
						scope.searchObj.searchText = '';
						deleteFilter(scope.filters, scope.activeColumn);

						updateFilter();
						hideDropdown();
						event.stopPropagation();
					};

					scope.applyFilter = function(skipUiCheck) {
						skipUiCheck = skipUiCheck || false;
						switch (scope.columnType) {
							case 'date':
								setDateFilter(scope.activeColumn, scope.from, scope.to);
								break;
							case 'time':
							case 'number':
								setNumberFilter(scope.activeColumn, scope.from * 1, scope.to * 1);
								break;
							default:
								setStringFilter(scope.activeColumn, scope.searchObj.searchText, scope.columnData, skipUiCheck);
								break;
						}
						updateTimeoutWidth();
					};

					scope.doneFilter = function() {
						scope.applyFilter();
						scope.closeFilterPopup();
					};

					scope.closeFilterPopup = function() {
						updateFilter();
						popup.close();
					};

					scope.selectDeselectAll = function(status) {
						for (let i = scope.popupListData.length; i--; ) {
							scope.popupListData[i].checked = status;
						}
					};

					scope.clearAllFilters = function() {
						scope.filters = {};

						updatePopupData();

						updateFilter();
					};

					scope.updateCheckboxes = function(state, value, switchEmpty) {
						if (switchEmpty) {
							if (!value && state) {
								scope.selectDeselectAll(false);
							} else if (value) {
								for (let i = scope.popupListData.length; i--; ) {
									if (!scope.popupListData[i].value) scope.popupListData[i].checked = false;
								}
							}
						}
						$timeout(updateSelectAllBtn, 0);
					};

					scope.onApplyFilter = () => {
						scope.applyFilter();
						scope.onApplyFilterHandler &&
							scope.onApplyFilterHandler({
								$event: {
									column: scope.activeColumn,
								},
							});
					};

					scope.onRemoveFilter = event => {
						scope.removeFilter(event);
						scope.onRemoveFilterHandler &&
							scope.onRemoveFilterHandler({
								$event: {
									column: scope.activeColumn,
								},
							});
					};

					function updateFilter() {
						updateFilterIcons();
						$timeout(function() {}, 0);
						updateTimeoutWidth();
					}

					function updateFilters() {
						updateFilterIcons();
						for (let column in scope.filters) {
							scope.activeColumn = column;
							scope.columnType = scope.filters[column].type;
							if (
								scope.filters[column].range &&
								scope.filters[column].range.min &&
								scope.filters[column].range.max &&
								scope.filters[column].range.max > scope.filters[column].range.min
							) {
								scope.from = scope.filters[column].range.min;
								scope.to = scope.filters[column].range.max;
							} else if (scope.columnType == 'date') {
								scope.from = moment(scope.filters[column].date.min);
								scope.to = moment(scope.filters[column].date.max);
								scope.maxDt = generateDate(scope.to.year(), scope.to.month(), scope.to.day());
								scope.maxDtCopy = generateDate(scope.to.year(), scope.to.month(), scope.to.day() + 1);
								scope.minDt = generateDate(scope.from.year(), scope.from.month(), scope.from.day());
							} else {
								scope.columnData = scope.filters[column].equals;
							}

							scope.applyFilter(true);
						}
					}

					// ------------------------- INIT ----------------------------------

					function initialize() {
						th = angular.element(tableElement.querySelectorAll('th[sort]'));

						// bind sort events
						th.on('click', clickEvent);

						th.prepend("<div class='icon icon-filter filter-indicator'></div>");
						th.prepend("<div class='icon icon-actions advanced-sort'></div>");
						// add resize
						th.prepend("<div class='resize'></div>");
						th[th.length - 1].className += ' hide-resize';
						// add sort level tags
						th.append("<div class='sort-level'></div>");

						// init arrows
						addSortArrows();

						// bind resize
						elem.on('mousedown', function(e) {
							let el = angular.element(e.target);
							if (el.hasClass('resize')) {
								startResize(e);
							}
						});
						if (scope.uiStateKey && uiStateService.stateExists(scope.uiStateKey)) {
							applyStoredState();
						}
					}

					function updateTimeoutWidth() {
						$timeout(updateWidth, 100);
					}

					function updateWidth() {
						let uiState = uiStateService.getState(scope.uiStateKey) || {};

						if (baseTable.length) {
							width = baseTable[0].offsetWidth;

							if (!width) {
								return;
							}

							elem.css('width', width + 'px');
						} else {
							elem.css('width', '100%');
						}

						if (scope.filters) {
							uiState.filters = scope.filters;
						}

						uiStateService.registerState(scope.uiStateKey, uiState);
					}

					function updateBaseTable() {
						baseTable = elem.next().find('table');
						copyHeadersUpdateWidth();
					}

					updateTimeoutWidth();
					initialize();

					angular.element($window).on('resize', updateTimeoutWidth);
					$rootScope.$on('$translateChangeSuccess', function() {
						scope.filters = {};
						updateFilter();
						copyHeadersUpdateWidth();
					});
					scope.$watch('data', copyHeadersUpdateWidth);
					scope.$watch('search', updateTimeoutWidth);
					scope.$watch('parameters', function(n, o) {
						if (n != o && storedState) {
							if (!storedState.parameters) {
								storedState.parameters = [{}];
							}
							storedState.parameters = n;
						}
					});

					scope.$on('updateHeader', updateTimeoutWidth);
					scope.$watch('searchObj.searchText', filterPopupList);
					scope.$on('treeGridLoaded', updateBaseTable);
				},
			};
		});
})();
