export const USER_EVENTS = {
	PAGE_LOAD: {
		categoryName: 'Page Load',
		events: {
			PAGE_LOAD: 'Page Load',
		},
		properties: {
			PAGE_LOAD_TIME: 'Page Load Time',
			PATH_TEMPLATE: 'Path Template',
			PATH_NAME: 'Path Name',
			ORIGINAL_PATH: 'Original Path',
			HOST_NAME: 'Host Name',
			APP_NAME: 'App Name',
			VERSION: 'Version',
			USER_ID: 'User Id',
			USER_NAME: 'User Name',
		},
	},
};
