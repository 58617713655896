import _get from 'lodash.get';
import _groupBy from 'lodash.groupby';
import {USER_EVENTS} from '../../common/usage-tracking/categories';

const services = new WeakMap();
const {HOME_PAGE: {categoryName: HOME_PAGE_CATEGORY_NAME, events: HOME_PAGE_EVENTS}} = USER_EVENTS;
const NOTIFICATION_STATUS = {
	READ: 'Read',
	UNREAD: 'Unread',
};
const EVENT_PROPERTIES = {
	SHOW_ON_STARTUP: 'Show on Startup',
	CLIENT: 'Client',
};

class NotificationsController {
	constructor($filter, $scope, $timeout, configService, notificationsService, usageTrackingService, userDataService) {
		services.set(this, {$timeout, configService, notificationsService, userDataService});

		this.$filter = $filter;
		this.featureToggles = configService.getFeatureToggles();
		this.loading = true;
		this.selectedItem = null;
		this.showOnStartup = false;
		this.trackEvent = usageTrackingService.trackEventByCategory(HOME_PAGE_CATEGORY_NAME, {[EVENT_PROPERTIES.CLIENT]: 'CCUI'});
		this.readNotifications = [];
		this.unreadNotifications = [];
		this.clickOnBody = this.clickOnBody.bind(this);
		this.delete = this.delete.bind(this);
		this.deleteAll = this.deleteAll.bind(this);
		this.markAllAsRead = this.markAllAsRead.bind(this);
		this.markAsRead = this.markAsRead.bind(this);
		this.onToggleNotification = this.onToggleNotification.bind(this);
		this.onToggleNotifications = this.onToggleNotifications.bind(this);
		this.toggleShowOnStartup = this.toggleShowOnStartup.bind(this);

		this.fetchNotifications();

		userDataService.getUserData().then(data => {
			this.showOnStartup = _get(data, 'userDataList[0].data.notificationList.showOnStartup', false);
			this.notificationsOpenedOnStart = this.showOnStartup && !notificationsService.wasOpened();
			notificationsService.setOpened();
		});
	}

	clickOnBody(e) {
		e.stopPropagation();

		this.deselectSelectedItem();
	}

	fetchNotifications() {
		const {notificationsService} = services.get(this);

		this.loading = true;

		notificationsService
			.getList()
			.then(data => {
				const sortedItems = data.sort((a, b) => {
					let result = new Date(b.startDate) - new Date(a.startDate);
					if (!result) {
						result = b.notificationId - a.notificationId;
					}
					return result;
				});

				const groupedItemsByStatus = _groupBy(sortedItems, 'status');
				this.unreadNotifications = groupedItemsByStatus[NOTIFICATION_STATUS.UNREAD] || [];
				this.readNotifications = groupedItemsByStatus[NOTIFICATION_STATUS.READ] || [];
			})
			.finally(() => {
				this.loading = false;
			});
	}

	delete(e, {notificationId, title}) {
		const {notificationsService} = services.get(this);

		this.loading = true;

		notificationsService.deleteNotification(notificationId).finally(() => this.fetchNotifications());

		this.trackEvent(HOME_PAGE_EVENTS.DELETE_CC4_NOTIFICATION);

		e.stopPropagation();
	}

	deleteAll() {
		const {notificationsService} = services.get(this);
		const readItemsIds = this.readNotifications.map(({notificationId}) => notificationId);

		if (readItemsIds.length) {
			this.loading = true;

			notificationsService.deleteNotifications(readItemsIds).finally(() => this.fetchNotifications());

			this.trackEvent(HOME_PAGE_EVENTS.DELETE_ALL_CC4_NOTIFICATIONS);
		}
	}

	markAsRead(e, {notificationId, title}) {
		const {notificationsService} = services.get(this);

		this.loading = true;

		notificationsService.markAsReadNotification(notificationId).finally(() => this.fetchNotifications());

		this.trackEvent(HOME_PAGE_EVENTS.MARK_CC4_NOTIFICATION_AS_READ);

		e.stopPropagation();
	}

	markAllAsRead() {
		const {notificationsService} = services.get(this);
		const unreadItemsIds = this.unreadNotifications.map(({notificationId}) => notificationId);

		if (unreadItemsIds.length) {
			this.loading = true;

			notificationsService.markAsReadNotifications(unreadItemsIds).finally(() => this.fetchNotifications());

			this.trackEvent(HOME_PAGE_EVENTS.MARK_ALL_CC4_NOTIFICATIONS_AS_READ);
		}
	}

	deselectSelectedItem() {
		this.selectedItem && (this.selectedItem.selected = false);
	}

	getFormattedDate(date) {
		return moment(date).format('MMM D, YYYY');
	}

	onToggleNotification(item) {
		if (this.selectedItem && this.selectedItem.notificationId !== item.notificationId) {
			this.selectedItem.selected = false;
		}

		item.selected = !item.selected;

		this.selectedItem = item;

		item.selected && this.trackEvent(HOME_PAGE_EVENTS.VIEW_CC4_NOTIFICATION);
	}

	onToggleNotifications(a, b, c) {
		this.deselectSelectedItem();

		this.trackEvent(HOME_PAGE_EVENTS.OPEN_CLOSE_CC4_NOTIFICATIONS);
	}

	toggleShowOnStartup(e, value) {
		const {userDataService} = services.get(this);
		const callback = angular.noop();
		const errorCallback = angular.noop();

		userDataService.setUserData(
			{
				data: {
					notificationList: {
						showOnStartup: value,
					},
				},
			},
			callback,
			errorCallback
		);

		this.trackEvent(HOME_PAGE_EVENTS.SHOW_CC4_NOTIFICATIONS_ON_STARTUP, {
			[EVENT_PROPERTIES.SHOW_ON_STARTUP]: value,
		});

		e && e.stopPropagation();
	}
}

angular.module('TISCC').component('notifications', {
	templateUrl: 'components/notifications/notifications.html',
	controller: NotificationsController,
	bindings: {},
});
