import * as R from 'ramda';

const charts = {
	5: new Set(['Motor Power Performance']),
};

// ------------------------------------------------------------- Register Service -------------------------------------------------------------
angular.module('TISCC').service('instanceBasedChartService', function(helpers) {
	const _scope = {
		helpers,
	};

	// ------------------------------------------------------------- Public Api's -------------------------------------------------------------
	this.createInstanceRoute = createInstanceRoute;

	this.createInstanceChart = createInstanceChart;

	this.getInstanceDetails = function(chartObj, item, isPageOnload) {
		return getInstanceDetails(_scope, chartObj, item, isPageOnload);
	};

	this.filterChartByInstance = filterChartByInstanceIfinstancePresentsOnChartList;

	this.getSelectedChartDetailsAndEquipmentMatchStatus = function(chartObj, chartList) {
		return getSelectedChartDetailsAndEquipmentMatchStatus(_scope, chartObj, chartList);
	};

	this.restoreChildrenDetailsFromTempProperty = restoreChildrenDetailsFromTempProperty;

	this.processApiDataBySelectedInstance = processApiDataBySelectedInstance;
});

// ------------------------------------------------------------- Private functions -------------------------------------------------------------

/**
 * (Immutable) function create route path for instance based charts
 *
 * @param {*} param0
 * @returns String
 */
function createInstanceRoute({locationId, equipmentId, chartId, selectedInstanceEquipmentType, instanceName, instanceId}) {
	// eslint-disable-next-line max-len
	return `/facility/${locationId}/equipment/${equipmentId}/chart/${chartId}/instance-type/${selectedInstanceEquipmentType}/instance/${instanceName}/id/${instanceId}`;
}

/**
 * (Immutable) function to create for each instance if a selected Equipment has multiple instance
 *
 * @param {*} chart
 * @param {*} selectedEquipment
 * @returns
 */
function createInstanceChart(chart, selectedEquipment = {}) {
	let tisObjectTypeGroupNumber;

	if (chart.tisObjectType) {
		tisObjectTypeGroupNumber = R.path(['tisObjectType', 'tisObjectTypeGroupNumber'], chart);
	}

	const allowedChart = charts[tisObjectTypeGroupNumber];

	if (!allowedChart || !allowedChart.has(chart.title) || tisObjectTypeGroupNumber === undefined) {
		return false;
	}

	const instances = _getCompressorInstances(selectedEquipment.children || []);

	const {hasMultipleCircuitInstance} = selectedEquipment;

	if (instances && (instances.length > 1 || (instances.length === 1 && hasMultipleCircuitInstance === true))) {
		return instances.map(({tisObjectId, instance, tisObjectTypeGroupName}) => ({
			...chart,
			title: `${chart.title} - ${tisObjectTypeGroupName} ${instance}`,
			selectedInstanceEquipmentType: tisObjectTypeGroupName,
			instanceName: instance,
			instanceId: tisObjectId,
		}));
	}

	return false;
}

/**
 * (Mutable) function update object is standalone or not
 *
 * @param {*} data
 * @param {*} type
 * @param {*} parent
 * @param {*} isRoot
 */
function _getCompressorInstances(data = [], instances = []) {
	data.forEach(tisObject => _validateComponentHasSingleOrMultipleInstance(tisObject, instances));
	return instances.length ? R.sortBy(R.prop('instance'))(instances) : [];
}

function _validateComponentHasSingleOrMultipleInstance(tisObject, instances) {
	const {tisObjectType = {}, tisObjectId, children = [], instance} = tisObject;
	const {tisObjectTypeClassification, tisObjectTypeGroupName, tisObjectTypeGroupNumber} = tisObjectType;
	// const key = tisObjectTypeClassification + '--' + tisObjectTypeGroupName + '--' + tisObjectTypeGroupNumber;

	// Conditions to check
	const isComponent = tisObjectTypeClassification === 'Component';
	const isCompressor = tisObjectTypeGroupName === 'Compressor';

	// const isCircuit = tisObjectTypeGroupName === 'Circuit';

	if (isCompressor && isComponent && instance) {
		instances.push({instance, tisObjectId, tisObjectTypeGroupName});
	}

	if (children.length > 0) {
		_getCompressorInstances(children, instances);
	}
}

/**
 * (Immutable) function which returns instance details of selected item
 *
 * @param {*} scope
 * @param {*} chartObj
 * @param {*} item
 * @param {*} isPageOnload
 * @returns {selectedInstanceEquipmentType, instanceName, instanceId} || null
 */
function getInstanceDetails(scope, chartObj, item, isPageOnload) {
	const {helpers} = scope;

	const {hasMultipleCircuitInstance, hasMultipleCompressorInstances, tisObjectType = {}} = item;

	// On page load, selected chart wont be available and below details are in route path that will be store in chart object directly
	// So on page load, get below details from chart obj instead of selectedChart
	const propSelectedChartId = isPageOnload ? 'chartId' : 'selectedChart.chartId';
	const propSelectedInstanceEquipmentType = isPageOnload ? 'selectedInstanceEquipmentType' : 'selectedChart.selectedInstanceEquipmentType';
	const propInstanceName = isPageOnload ? 'instanceName' : 'selectedChart.instanceName';
	const propInstanceId = isPageOnload ? 'instanceId' : 'selectedChart.instanceId';

	const selectedChartId = helpers.getPropertyByPath(chartObj, propSelectedChartId);
	const selectedInstanceEquipmentType = helpers.getPropertyByPath(chartObj, propSelectedInstanceEquipmentType);
	const selectedInstanceName = helpers.getPropertyByPath(chartObj, propInstanceName);
	const selectedInstanceId = helpers.getPropertyByPath(chartObj, propInstanceId);
	const selectedChartTitle = helpers.getPropertyByPath(chartObj, 'selectedChart.title');

	// Decides url on addressbar was to handle multiple instance or not
	const isPrevChartToHandleMultipleInstances = !!(selectedInstanceEquipmentType || selectedInstanceName || selectedInstanceId);

	let isRoutePathToHandleMultipleChartOnPageload = true;

	// On page load, verify url on address bar is for multiple instance / not
	if (isPageOnload) {
		isRoutePathToHandleMultipleChartOnPageload = isPrevChartToHandleMultipleInstances;
	}

	if (hasMultipleCircuitInstance === undefined || hasMultipleCircuitInstance === null) {
		// if selecte equipent is not chiller, reset prev instance details in chart object
		return {
			selectedInstanceEquipmentType: null,
			instanceName: null,
			instanceId: null,
		};
	} else if (hasMultipleCircuitInstance !== undefined && isRoutePathToHandleMultipleChartOnPageload) {
		// This section for chiller to update chart by instance wise

		if (!hasMultipleCompressorInstances && isPrevChartToHandleMultipleInstances) {
			// if current chiller has single compressor and prev selected chiller has multi compressor
			// reset prev instance deteils in chart object to display single compressor details
			return {
				selectedInstanceEquipmentType: null,
				instanceName: null,
				instanceId: null,
			};
		}

		if (hasMultipleCompressorInstances === true && selectedChartId) {
			// Current chiller has multiple compressor and there was a chart selected already

			if (!isPageOnload && selectedChartTitle) {
				// when moving to other equipment
				// Need to check that selected charts are instance based chart or not
				// if not, return null for all properties
				// if its instance based chart, return respective details

				const chartSet = charts[tisObjectType.tisObjectTypeGroupNumber];

				const titles = chartSet ? [...chartSet.values()] : [];

				const isSameChart = titles.some(title => (selectedChartTitle || '').startsWith(title));

				if (!isSameChart) {
					return {
						selectedInstanceEquipmentType: null,
						instanceName: null,
						instanceId: null,
					};
				}
			}

			const {data: compressorInstances, first: firstInstance} = _getCompressorInstancesWithEquipmentIdAsObject(item);

			// check if current chiller has same instance (1A) of selected chart (instance - 1A)
			// if yes, get same intance(1A) detail from (current chiller) compressors
			// if no, use first instace detail from compressors
			const {instance, tisObjectId, tisObjectTypeGroupName} = compressorInstances[selectedInstanceName] || firstInstance;

			return {
				selectedInstanceEquipmentType: tisObjectTypeGroupName,
				instanceName: instance,
				instanceId: tisObjectId,
			};
		}
	}

	return null;
}

function _getCompressorInstancesWithEquipmentIdAsObject(selectedEquipment) {
	let children;

	if (!selectedEquipment) {
		return {data: {}, first: null};
	} else if (selectedEquipment.children && Array.isArray(selectedEquipment.children)) {
		children = selectedEquipment.children.slice();
	} else if (selectedEquipment && Array.isArray(selectedEquipment)) {
		children = selectedEquipment.slice();
	} else {
		return {data: {}, first: null};
	}

	const data = _getCompressorInstances(children);

	return data.reduce(
		(av, cv) => {
			if (cv.instance) {
				av.data[cv.instance] = cv;
			}
			if (av.first === null) {
				av.first = cv;
			}
			return av;
		},
		{data: {}, first: null}
	);
}

/**
 * (Immutable) function to filter charts by instance if chart list contains instance based charts
 * @param {*} chartObj
 * @param {*} chartList
 * @returns
 */
function filterChartByInstanceIfinstancePresentsOnChartList(chartObj, chartList) {
	return chartList.filter(chartItem => {
		if (chartObj.instanceName && chartObj.instanceId) {
			return chartObj.chartId === chartItem.chartId && chartObj.instanceName === chartItem.instanceName;
		}
		return chartObj.chartId === chartItem.chartId;
	});
}

/**
 * (Immutable) function get selected Chart details and find selected equipment belongs to selected chart type or not
 *
 * @param {*} scope
 * @param {*} chartObj
 * @param {*} chartList
 * @returns
 */
function getSelectedChartDetailsAndEquipmentMatchStatus(scope, chartObj, chartList) {
	const {helpers} = scope;

	let selectedChartDetails = null;

	const selectedChartId = helpers.getPropertyByPath(chartObj, 'selectedChart.chartId');
	const selectedInstanceName = helpers.getPropertyByPath(chartObj, 'selectedChart.instanceName');

	if (!chartList) {
		return {};
	}

	const {filteredCharts, instanceCharts} = chartList.reduce(
		(av, cv) => {
			if (cv.chartId === selectedChartId) {
				av.filteredCharts.push(cv);

				if (cv.instanceName) {
					av.instanceCharts.push(cv);
				}
			}
			return av;
		},
		{filteredCharts: [], instanceCharts: []}
	);

	const hasCharts = Boolean(filteredCharts.length);

	// if no chart selected (routePath/null), selectedChartId is null / undefine
	const isEquipmentMatchedToSelectedChart = !selectedChartId || hasCharts;

	// Secnario: A chart was selected and charts belongs to the selected equipment
	if (selectedChartId && hasCharts) {
		if (!selectedInstanceName && instanceCharts.length) {
			/**
			 * Case 1: !selectedInstanceName && instanceCharts.length
			 *
			 * !selectedInstanceName - last equipment chart has no instance (charts)
			 * instanceCharts.length - selected equipment has multiple instance (charts)
			 *
			 * Flow :
			 *
			 * 	Moving from single circuit equipment to multiple circuit equipmet
			 * 	Chart Id is same, but current equipment has multiple instances
			 * 	So assign first chart instance of same chart id
			 */

			selectedChartDetails = instanceCharts[0];
		} else if (selectedInstanceName && instanceCharts.length) {
			/**
			 * Case 2: selectedInstanceName && instanceCharts.length
			 *
			 * selectedInstanceName - last equipment chart has multiple instance (charts)
			 * instanceCharts.length - selected equipment also has multiple instance (charts)
			 *
			 * Flow :
			 *
			 * 	Moving from multiple circuit equipment to multiple circuit equipmet
			 * 	Chart Id is same, but both equipment has multiple instances
			 * 	Checking current equipment has same instance
			 * 	if No - assign first chart instance of same chart id
			 * 	if Yes - assign the same instance (filtered by selectedInsance condition)
			 */

			const hasCurrentEquipmentSameInstance = instanceCharts.filter(i => i.instanceName === selectedInstanceName);

			selectedChartDetails = !hasCurrentEquipmentSameInstance.length ? instanceCharts[0] : hasCurrentEquipmentSameInstance[0];
		} else if (selectedInstanceName && !instanceCharts.length && filteredCharts.length) {
			/**
			 * Case 3: selectedInstanceName && !instanceCharts.length && filteredCharts.length
			 *
			 * selectedInstanceName - last equipment chart has multiple instance (charts)
			 * instanceCharts.length - selected equipment  has no multiple instance (charts)
			 * filteredCharts.length - selected equipment has a chart with same id
			 *
			 * Flow :
			 *
			 * 	Moving from multiple circuit equipment to single circuit equipmet
			 * 	Chart Id is same, but current equipment has single instance (chart)
			 * 	So assign first chart which has same chart id
			 */

			selectedChartDetails = filteredCharts[0];
		}
	}

	return {
		isEquipmentMatchedToSelectedChart,
		selectedChartDetails,
	};
}

/**
 * (Immutable) function to copy children details which stored in temporary property(childObjects) in same object and to store in children property
 *
 * Bz children property were made null (in chart-block.js) to hide submenu in Equipment dropdown.
 * But it requires further for finding chart list & get list of instances from selected equipment
 *
 * @param {*} selectedItem
 * @returns
 */
function restoreChildrenDetailsFromTempProperty(selectedItem) {
	let item = null;

	if (selectedItem) {
		item = {...selectedItem};
		if (item.children === null && item.childObjects) {
			item = {
				...item,
				children: Array.isArray(item.childObjects) && item.childObjects.length ? item.childObjects.slice() : null,
				childObjects: null,
			};
		}
	}

	return item;
}

/**
 * (Immutable) function filter out selected instance type data from Api response
 *
 * @param {*} data
 * @param {*} selectedChartDetails
 * @returns
 */
function processApiDataBySelectedInstance(data, selectedChartDetails) {
	function _extractInstanceData(data, instanceName) {
		const {tisObjectId, relatedDataEntries, ...rest} = data;

		const {instanceId} = selectedChartDetails[tisObjectId] || {};

		if (instanceId && instanceName) {
			// Filtering out selected equipment instance type data

			relatedDataEntries.forEach(rltdEntry => {
				if (rltdEntry.dataEntries && rltdEntry.dataEntries.length) {
					// Here we are comparing by selected instance Id. bz this instance id belongs to selected equipment.
					rltdEntry.dataEntries = rltdEntry.dataEntries.filter(v => v.tisObjectId === instanceId);
				}
			});
		} else if (!instanceId && instanceName) {
			// When a property of other equipment is added throught Add Property in chart
			// Api will get data for both ids (selected equipment & added property's equipment)
			// In this case, we should include data of selected Instance type (1A, 2A) for other (added property) equipment along with selected equioment data
			// Here filtering out same instance type data

			if (relatedDataEntries.length) {
				relatedDataEntries.forEach(rltdEntry => {
					if (rltdEntry.dataEntries && rltdEntry.dataEntries.length) {
						// Here we are comparing by selected instance type instead of instance Id.
						// bz instance type common for both selected & added property equipment
						rltdEntry.dataEntries = rltdEntry.dataEntries.filter(v => (v.tisObject.tisObjectName || '').includes(instanceName));
					}
				});
			}
		}
		return {
			...rest,
			tisObjectId,
			relatedDataEntries,
		};
	}

	if (selectedChartDetails.instanceName) {
		data = data.map(obj => _extractInstanceData(obj, selectedChartDetails.instanceName));
	}

	return data;
}
