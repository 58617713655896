(function(d3, moment, AbstractChartRenderer) {
	const CIRCLE_RADIUS = 2;
	const MAX_GROUP_RADIUS = 35;
	const WHITE_COLOR = '#FFFFFF';
	const GREY_COLOR = '#808080';
	const TRANSPARENT = 'transparent';

	const PROPERTY_VALUE_DEVIATION = 0.25;
	const PROPERTY_VALUE_VERTICAL_PADDING = 0.05;
	const PROPERTY_VALUE_HORIZONTAL_PADDING = 0.01;
	const PARETO_HIGH_TO_LOW_SORT_KEY = 'highToLow';
	const PARETO_EQUIPMENT_BY_DATE = 'equipmentByDate';
	const MULTIPOINT_CHART_TYPE = 'paretoMultipoints';
	const MINIMUM_TICKS_ON_YAXIS = 10;
	const ONE_CHAR_AFTER_COMMA_RESOLUTION = '0.1';
	const TWO_CHARS_AFTER_COMMA_RESOLUTION = '0.01';
	const THREE_CHARS_AFTER_COMMA_RESOLUTION = '0.001';
	const RESOLUTION_THRESHOLDS = {
		HIGHER_THRESHOLD: 1,
		LOWER_THRESHOLD: 0.1,
	};

	let dotColor = WHITE_COLOR;
	let isExport = false;

	function naturalCompare(a, b) {
		let ax = [];
		let bx = [];

		a.replace(/(\d+)|(\D+)/g, (_, $1, $2) => ax.push([$1 || Infinity, $2 || '']));
		b.replace(/(\d+)|(\D+)/g, (_, $1, $2) => bx.push([$1 || Infinity, $2 || '']));

		while (ax.length && bx.length) {
			let an = ax.shift();
			let bn = bx.shift();
			let nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
			if (nn) {
				return nn;
			}
		}

		return ax.length - bx.length;
	}

	class ParetoChartRenderer extends AbstractChartRenderer {
		constructor(svg, externalMethods, clipPathId, x) {
			super(externalMethods.tooltip, externalMethods.translate);
			this.gridNode = svg.append('g').attr('class', 'grid');
			this.yAxes = [];
			this.extraDataFormatting = externalMethods.extraDataFormatting;
			this.chartId = clipPathId;
			this.CHART_LABEL_OFFSET = {left: -2, right: 2, top: -2, bottom: 11};
			this.dataNode = svg.append('g');
			this.x = x;
			this.xAxis = null;
			this.groupMap = new WeakMap();
			addAxes.call(this, svg);

			isExport = checkIsVisualExport();
			if (checkIsVisualExportWithWhiteBackground()) {
				dotColor = GREY_COLOR;
			}
		}

		get yAxis() {
			const [yAxis] = this.yAxes;
			return yAxis;
		}

		onSvgReceivedSize(updateData) {
			this.width = updateData.width;
			this.height = updateData.height;
			this.chartHeight = updateData.chartHeight;
		}

		onSvgSizeUpdate(updateData) {
			this.onSvgReceivedSize(updateData);
			this.updateAxesHeight(updateData.width, updateData.chartHeight);
		}

		toggleChartLine(chartOptions, chartData) {
			this.dataNode.selectAll('*').remove();
			this.drawData(chartOptions, chartData);
		}

		updateAxisLabel() {
			this.yAxes.forEach(yAxis => {
				let uomLabelOffset = yAxis.orient === 'left' ? -30 : this.width + 30;
				let rotateDegree = yAxis.orient === 'left' ? -90 : 90;
				if (yAxis.orient === 'right') {
					yAxis.node.attr('transform', `translate(${this.width}, 0)`);
				}
				yAxis.uomLabel
					.attr('transform', `translate(${uomLabelOffset}, ${this.chartHeight / 2}) rotate(${rotateDegree})`)
					.attr('class', 'axis-label')
					.text(yAxis.uomSymbol);
			});
		}

		updateYAxes() {
			let ticks;
			let defaultDisplayLabel = (resolution, isPercentageAxis, d) => {
				return isPercentageAxis ? d : this.extraDataFormatting.applyDecimalFormatting(d, resolution, true);
			};
			this.yAxes.forEach((yAxis, i) => {
				if (yAxis.visible) {
					const [minValue, maxValue] = yAxis.y.domain();
					const difference = maxValue - minValue;
					let customResolution = '';

					if (
						difference < RESOLUTION_THRESHOLDS.HIGHER_THRESHOLD &&
						difference > RESOLUTION_THRESHOLDS.LOWER_THRESHOLD &&
						Number(TWO_CHARS_AFTER_COMMA_RESOLUTION) < Number(yAxis.resolution)
					) {
						customResolution = TWO_CHARS_AFTER_COMMA_RESOLUTION;
					} else if (difference < RESOLUTION_THRESHOLDS.LOWER_THRESHOLD && Number(THREE_CHARS_AFTER_COMMA_RESOLUTION) < Number(yAxis.resolution)) {
						customResolution = THREE_CHARS_AFTER_COMMA_RESOLUTION;
					} else {
						customResolution = (difference / MINIMUM_TICKS_ON_YAXIS).toFixed(1) <= 0.5 ? ONE_CHAR_AFTER_COMMA_RESOLUTION : yAxis.resolution;
					}

					const currentDefaultDisplayLabel = defaultDisplayLabel.bind(null, customResolution || yAxis.resolution, yAxis.isPercentageAxis);
					if (yAxis.isSingleValue) {
						ticks = 2;
					}
					const f = yAxis.axis
						.tickSize(0, 0, 0)
						.tickFormat(currentDefaultDisplayLabel)
						.ticks(ticks);
					yAxis.node
						.attr('display', '')
						.call(f)
						.selectAll('text')
						.attr('dx', this.CHART_LABEL_OFFSET[yAxis.orient]);
				} else {
					yAxis.node.attr('display', 'none');
					yAxis.uomLabel.attr('display', 'none');
				}
			});

			if (this.yAxes[0].visible) {
				this.gridNode.attr('display', '').call(this.yAxes[0].axis.tickSize(-this.width, 0, 0).tickFormat(''));
				this.updateAxisLabel();
			} else {
				this.gridNode.attr('display', 'none');
			}
		}

		calculateData(chartData, chartOptions) {
			let backgrounds = null;
			const brushRange = {
				from: this.x.domain()[0].getTime(),
				to: this.x.domain()[1].getTime(),
			};
			chartData = chartData.filter(item => brushRange.from <= item.timestamp.getTime() && brushRange.to >= item.timestamp.getTime());

			if (chartOptions.sortOrder === PARETO_EQUIPMENT_BY_DATE) {
				chartData = chartData.sort((a, b) => {
					let result = naturalCompare(a.equipmentName, b.equipmentName);
					if (result === 0) {
						result = a.timestamp - b.timestamp;
					}
					return result;
				});
				backgrounds = chartData.reduce((result, item) => {
					if (!result || !result.length || !result[result.length - 1] || result[result.length - 1].id !== item.equipmentId) {
						let start = 0;
						let opacity = 0;
						if (result && result.length) {
							start = result[result.length - 1].count + result[result.length - 1].start;
							if (!result[result.length - 1].opacity) {
								opacity = 0.2;
							}
						}
						result.push({id: item.equipmentId, count: 1, start, opacity});
					} else {
						result[result.length - 1].count++;
					}
					return result;
				}, []);
			} else {
				chartData = chartData.sort((a, b) => a.valueAtMaxDeviancePerDay - b.valueAtMaxDeviancePerDay);
				if (chartOptions.sortOrder === PARETO_HIGH_TO_LOW_SORT_KEY) {
					chartData = chartData.reverse();
				}
			}
			chartData.map((item, index) => {
				item.x = index / 10;
				item.y = Math.round(item.valueAtMaxDeviancePerDay * 100) / 100;
				const weekRange = generateWeekRange(item.timestamp);
				item.chartURL = generateChartURL(item.location.locationId, item.equipmentId, chartOptions.chartId, weekRange);
				item.locationURL = generateLocationURL(item.location.locationId, weekRange);
			});

			return {chartData, backgrounds};
		}

		updateYAxesScale() {
			return false;
		}

		processYAxes(chartOptions, chartData) {
			let xExtents = [];
			let yExtents = [];

			function isPercentageDataAxis(uom) {
				return uom && uom.symbol && uom.symbol === '%';
			}

			function fixPercentageRange(min = null, max = null) {
				return [Math.min(0, min), Math.max(100, max)];
			}

			function addValueRangePadding(value, min, max) {
				const padding = (max - min) * value;
				const minValue = min - padding;
				const maxValue = max + padding;
				return [minValue, maxValue];
			}

			function getMinMaxValues(extents) {
				const minValue = d3.min(extents, d => d[0]);
				const maxValue = d3.max(extents, d => d[1]);
				return [minValue, maxValue];
			}

			function generateValueRange(value) {
				let multiplied = Math.abs(value * PROPERTY_VALUE_DEVIATION);
				!multiplied && (multiplied = PROPERTY_VALUE_DEVIATION);
				const minValue = value - multiplied;
				const maxValue = value + multiplied;
				return [minValue, maxValue];
			}

			function flattenValuesAndThresholds(inputData, chartOptions) {
				const thresholds = chartOptions.thresholds;
				let extractValues = inputArr => {
					let yValue = parseFloat(inputArr.y);
					let thresholdValues = thresholds.reduce((accum, threshold) => {
						let value = parseFloat(
							threshold &&
								threshold.name &&
								inputArr.thresholds &&
								inputArr.thresholds[threshold.name] &&
								inputArr.thresholds[threshold.name].value
						);
						if (!isNaN(value)) {
							accum.push(value);
						}
						return accum;
					}, []);

					return [yValue, ...thresholdValues].filter(item => !isNaN(item));
				};
				return inputData.reduce(function(flat, toFlatten) {
					return flat.concat(Array.isArray(toFlatten) ? flattenValuesAndThresholds(toFlatten, chartOptions) : extractValues(toFlatten));
				}, []);
			}

			const propertyInfo = chartOptions.propertyInfo;
			const resolution = propertyInfo.propertyAttribute ? propertyInfo.propertyAttribute.resolution : 1;
			const uom = propertyInfo.unitOfMeasure;
			const isPercentageAxis = isPercentageDataAxis(uom);
			let isSingleValue = false;
			xExtents.push(d3.extent(chartData, d => parseFloat(d.x)));
			yExtents.push(d3.extent(flattenValuesAndThresholds(chartData, chartOptions), d => d));

			let [yMinValue, yMaxValue] = getMinMaxValues(yExtents);
			if (isPercentageAxis) {
				[yMinValue, yMaxValue] = fixPercentageRange(yMinValue, yMaxValue);
			} else {
				[yMinValue, yMaxValue] = addValueRangePadding(PROPERTY_VALUE_VERTICAL_PADDING, yMinValue, yMaxValue);
				yMinValue = Math.min(yMinValue, 0);
			}
			if (yMinValue && yMaxValue && yMinValue === yMaxValue) {
				[yMinValue, yMaxValue] = generateValueRange(yMinValue);
				isSingleValue = true;
			}

			let [xMinValue, xMaxValue] = getMinMaxValues(xExtents);
			[xMinValue, xMaxValue] = addValueRangePadding(PROPERTY_VALUE_HORIZONTAL_PADDING, xMinValue, xMaxValue);
			if (xMinValue === xMaxValue) {
				[xMinValue, xMaxValue] = generateValueRange(xMaxValue);
			}

			this.xAxis.x.range([0, this.width]);
			this.xAxis.x.domain([xMinValue, xMaxValue]);
			this.xAxis.axis = d3.svg.axis().scale(this.xAxis.x);

			this.yAxes.forEach(yAxis => {
				yAxis.visible = chartData.length > 0;
				yAxis.isSingleValue = isSingleValue;
				yAxis.resolution = resolution;
				yAxis.uomSymbol = uom && uom.symbol ? (uom.symbol === '0' ? '' : uom.symbol) : '';
				yAxis.isPercentageAxis = isPercentageAxis;
				yAxis.y.range([this.chartHeight, 0]);
				yAxis.y.domain([yMinValue, yMaxValue]);
				yAxis.axis = d3.svg
					.axis()
					.orient(yAxis.orient)
					.scale(yAxis.y);
			});

			this.updateYAxes();
		}

		updateAxesHeight(width, chartHeight) {
			this.yAxes.forEach(item => {
				item.y.range([chartHeight, 0]);
				item.axis = d3.svg
					.axis()
					.orient(item.orient)
					.scale(item.y);
			});

			this.updateYAxes();
			this.xAxis.x.range([0, width]);
			this.xAxis.axis = d3.svg.axis().scale(this.xAxis.x);
		}

		timedOutOpenTooltip(d, fill, group) {
			clearTimeout(this.tooltip.closeToolTip);
			let openToolTipItem = this.tooltip.openToolTip.get(group);

			if (!openToolTipItem) {
				openToolTipItem = setTimeout(() => {
					let uom = this.yAxis.uomSymbol;
					let date = moment(d.timestamp).format('M/D/YY h:mm A');
					const tooltipParams = {
						xPos: this.xAxis.x(d.x),
						yPos: this.yAxis.y(d.y),
						class: ['hovered'],
					};
					if (group.count > 1) {
						const groupItems = this.groupMap.get(group);
						tooltipParams.chartType = MULTIPOINT_CHART_TYPE;
						tooltipParams.multiPoints = [...new Map(groupItems.map(item => [item['equipmentName'], item])).values()].map(item => {
							return {
								locationName: item.location.locationName,
								equipmentName: item.equipmentName,
								value: item.y,
								color: fill,
								uom,
								locationURL: item.locationURL,
								chartURL: item.chartURL,
							};
						});
					} else {
						Object.assign(tooltipParams, {
							chartURL: d.chartURL,
							locationURL: d.locationURL,
							locationName: d.location.locationName,
							equipmentName: d.equipmentName,
							value: d.y,
							color: fill,
							uom,
						});
					}
					tooltipParams.date = date;

					const mouseenter = () => {
						clearTimeout(this.tooltip.closeToolTip);
					};

					const mouseleave = () => {
						this.timedOutCloseTooltip(group);
					};

					const mouseclick = () => {
						this.closeTooltip(group);
					};

					const handlers = {
						mouseenter,
						mouseleave,
						mouseclick,
					};

					this.tooltip.showTooltip(tooltipParams, null, handlers);
					this.tooltip.openToolTip.set(group, openToolTipItem);
				}, 50);
			}
		}

		timedOutCloseTooltip(group) {
			this.tooltip.closeToolTip = setTimeout(() => {
				clearTimeout(this.tooltip.openToolTip.get(group));
				this.tooltip.hideTooltip();
			}, 500);
		}

		closeTooltip(group) {
			clearTimeout(this.tooltip.openToolTip.get(group));
			this.tooltip.hideTooltip();
		}

		calculateGroups(chartData) {
			chartData.reduce((result, item) => {
				let group = {number: 1, count: 1};
				let nextFirstPoint = item;

				if (result.firstPoint && result.prevPoint) {
					let {firstPoint, prevPoint} = result;

					let firstX = this.xAxis.x(firstPoint.x);
					let firstY = this.yAxis.y(firstPoint.y);

					let prevX = this.xAxis.x(prevPoint.x);
					let prevY = this.yAxis.y(prevPoint.y);

					let lastX = this.xAxis.x(item.x);
					let lastY = this.yAxis.y(item.y);

					let prevDistance = Math.sqrt((prevX - lastX) * (prevX - lastX) + (prevY - lastY) * (prevY - lastY));

					let groupDistance = Math.sqrt((firstX - lastX) * (firstX - lastX) + (firstY - lastY) * (firstY - lastY));

					if (groupDistance < MAX_GROUP_RADIUS && prevDistance < (CIRCLE_RADIUS + 1) * 2) {
						group = result.firstPoint.group;
						group.count++;
						nextFirstPoint = result.firstPoint;
					} else {
						group = {number: ++result.firstPoint.group.number, count: 1};
					}
					let strokeLength = Math.max(lastX - prevX, 0);
					if (typeof prevPoint.strokeLength === 'number') {
						item.strokeLength = Math.max(strokeLength - prevPoint.strokeLength, 0);
					} else {
						item.strokeLength = strokeLength / 2;
						prevPoint.strokeLength = strokeLength / 2;
					}
				}
				item.group = group;
				if (group && this.groupMap.has(group)) {
					this.groupMap.get(group).push(item);
				} else {
					this.groupMap.set(group, [item]);
				}
				return Object.assign(result, {
					firstPoint: nextFirstPoint,
					prevPoint: item,
				});
			}, {});
		}

		checkData(chartData, timelineData, lines, lanes) {
			return true; // Enable for the time being until the data call is incorporated.
		}

		drawData(chartOptions, chartData) {
			this.tooltip.hideTooltip();
			let data = this.calculateData(chartData, chartOptions);
			let backgrounds = data.backgrounds;
			chartData = data.chartData;
			chartOptions.exportData = data;

			this.processYAxes(chartOptions, chartData);

			let targetNode = this.dataNode.append('g');
			this.calculateGroups(chartData);

			if (backgrounds && backgrounds.length) {
				let plates = targetNode.append('g').attr('class', 'plates');
				plates
					.selectAll()
					.data(backgrounds)
					.enter()
					.append('rect')
					.attr('x', d => {
						let start = this.xAxis.x((d.start - 0.5) / 10);
						return start;
					})
					.attr('y', -200)
					.attr('width', d => {
						let start = this.xAxis.x((d.start - 0.5) / 10);
						let end = this.xAxis.x((d.start + d.count - 0.5) / 10);
						return end - start;
					})
					// :todo we need to find the way to get real size
					.attr('height', 2800)
					.attr('color', dotColor)
					.attr('fill-opacity', d => {
						return d.opacity || 0;
					})
					.attr('fill', dotColor);
			}

			this.chartData = data.chartData;
			this.chartOptions = chartOptions;
			this.thresholds = this.chartData.reduce((result, item) => {
				this.chartOptions.thresholds.forEach(threshold => {
					if (!result[threshold.name]) {
						result[threshold.name] = [{...item, y: item.thresholds[threshold.name].value}];
					} else {
						result[threshold.name].push({...item, y: item.thresholds[threshold.name].value});
					}
				});
				return result;
			}, {});
			this.drawParetoLevelLimits(this.thresholds, chartOptions, this.dataNode);

			if (!chartData.length) {
				this.commonRenderer.showEmptyDatasetMessage();
			}
			if (chartOptions.lines[0].visible) {
				let dots = targetNode
					.append('g')
					.attr('class', 'lines')
					.attr('clip-path', 'url(#clipBorder' + this.chartId + ')');
				let that = this;
				dots
					.selectAll()
					.data(chartData)
					.enter()
					.append('circle')
					.attr('class', 'axis white-dot')
					.attr('clip-path', 'url(#clipBorder' + this.chartId + ')')
					.attr('r', CIRCLE_RADIUS + 0.5)
					.attr('cx', d => {
						let val = this.xAxis.x(d.x);
						return val;
					})
					.attr('cy', d => this.yAxis.y(d.y))
					.on('click', d => {
						window.location.href = d.chartURL;
					})
					.on('mouseover', function(d) {
						d3.select(this).attr('r', CIRCLE_RADIUS + 1.5);
						that.timedOutOpenTooltip(d, window.getComputedStyle(this, null).fill, d.group);
					})
					.on('mouseout', function(d) {
						d3.select(this).attr('r', CIRCLE_RADIUS + 0.5);
						that.timedOutCloseTooltip(d.group);
					});
			}
		}

		updateChartPos() {
			this.dataNode
				.selectAll('.plates')
				.selectAll('rect')
				.attr('x', d => {
					let start = this.xAxis.x((d.start - 0.5) / 10);
					return start;
				})
				.attr('width', d => {
					let start = this.xAxis.x((d.start - 0.5) / 10);
					let end = this.xAxis.x((d.start + d.count - 0.5) / 10);
					return end - start;
				});
			this.dataNode
				.selectAll('.lines')
				.selectAll('circle')
				.attr('cx', d => this.xAxis.x(d.x));
			this.dataNode.selectAll('path.axis').attr('d', this.yAxis.line);
			this.dataNode.selectAll('circle.axis').attr('cy', d => this.yAxis.y(d.y));
			this.dataNode.select('#axis-thresholds').remove();
			this.calculateGroups(this.chartData);
			this.drawParetoLevelLimits(this.thresholds, this.chartOptions, this.dataNode);
		}

		drawParetoLevelLimits(chartData, chartOptions, dataNode) {
			let that = this;
			that.singlePointThresholdCase = false;

			let limitsLayer = dataNode
				.append('g')
				.attr('id', 'axis-thresholds')
				.attr('isolation', 'isolate'); // When both limits are the same we show critical one

			function normalizeChartData(data) {
				let normalizedChartData = data;
				if (normalizedChartData.length === 1) {
					let point = Object.assign({}, normalizedChartData[0]);
					point.x = 0.2;
					normalizedChartData = [
						{
							...point,
							x: 0,
						},
						point,
						{
							...point,
							x: 0.25,
						},
					];
					that.singlePointThresholdCase = true;
				} else {
					that.singlePointThresholdCase = false;
				}
				return normalizedChartData;
			}

			function buildThresholdGenerator(propertyName) {
				return d3.svg
					.line()
					.defined(d => {
						return d.thresholds[propertyName].value !== null;
					})
					.x(d => {
						return that.singlePointThresholdCase && d.x === 0 ? 0 : that.xAxis.x(d.x);
					})
					.y(d => that.yAxis.y(d.y))
					.interpolate('step-after');
			}

			function buildThresholdHoverPasses() {
				return d3.svg
					.line()
					.x(d => d.x)
					.y(d => d.y)
					.interpolate('step-after');
			}

			chartOptions.thresholds.forEach(threshold => {
				if (threshold.visible) {
					that = this;
					let normalizedChartData = normalizeChartData(chartData[threshold.name]);
					this.calculateGroups(normalizedChartData);
					limitsLayer
						.append('path')
						.attr('d', () => {
							return buildThresholdGenerator(threshold.name)(normalizedChartData);
						})
						.attr('class', `line axis-threshold-${threshold.name}`)
						.attr('stroke', threshold.color)
						.attr('opacity', 1)
						.attr('stroke-width', isExport ? 2 : 1)
						.attr('mix-blend-mode', 'normal')
						.attr('isolation', 'isolate')
						.attr('fill', 'none');

					const circlesMap = new WeakMap();

					let circlesGroups = limitsLayer
						.append('g')
						.attr('class', `dots axis-threshold-${threshold.name}`)
						.selectAll()
						.data(normalizedChartData)
						.enter()
						.append('g');

					circlesGroups
						.append('circle')
						.attr('class', 'axis')
						.attr('r', function(d) {
							circlesMap.set(d, this);
							return CIRCLE_RADIUS;
						})
						.attr('cx', d => this.xAxis.x(d.x))
						.attr('cy', d => this.yAxis.y(d.y))
						.attr('fill', TRANSPARENT);

					circlesGroups
						.append('path')
						.attr('d', d => {
							let y = this.yAxis.y(d.y);
							let hoverPathData = [
								{
									x: this.xAxis.x(d.x) - d.strokeLength,
									y,
								},
								{
									x: this.xAxis.x(d.x) + d.strokeLength,
									y,
								},
							];
							return buildThresholdHoverPasses()(hoverPathData);
						})
						.attr('stroke', TRANSPARENT)
						.attr('opacity', 1)
						.attr('stroke-width', 2)
						.attr('mix-blend-mode', 'normal')
						.attr('isolation', 'isolate')
						.on('mouseover', function(d) {
							let data = Object.assign({}, d);

							let circle = circlesMap.get(d);
							if (data.group) {
								if (circle) {
									d3.select(circle).attr('fill', dotColor);
									that.timedOutOpenTooltip(data, threshold.color, data.group);
								}
							}
						})
						.on('mouseout', function(d) {
							let circle = circlesMap.get(d);
							if (circle) {
								d3.select(circle).attr('fill', TRANSPARENT);
							}
							that.timedOutCloseTooltip(d.group);
						});
				}
			});
		}
	}

	function addAxes(svg) {
		let x = d3.scale.linear().domain([0, 0]);
		this.xAxis = {
			x: x,
			node: svg.append('g').attr('class', 'axis'),
			axis: d3.svg.axis().scale(x),
			uomSymbol: '',
		};
		this.yAxes.push(addYAxis('left', svg, x));
		// this.yAxes.push(addYAxis('right', svg, x));
	}

	function addYAxis(orientation, svg, x) {
		let y = d3.scale.linear().domain([0, 0]);

		return {
			y: y,
			node: svg.append('g').attr('class', 'axis'),
			axis: d3.svg.axis().scale(y),
			orient: orientation,
			uomSymbol: '',
			uomLabel: svg.append('g').append('text'),
			line: d3.svg
				.line()
				.x(d => x(d.x))
				.y(d => y(d.y)),
		};
	}

	function generateWeekRange(propertyTimestamp) {
		const DATE_FORMAT = 'MM-DD-YYYY';
		let endRange = moment(propertyTimestamp).format(DATE_FORMAT);
		let startRange = moment(propertyTimestamp)
			.subtract(6, 'days')
			.format(DATE_FORMAT);
		return [startRange, endRange];
	}

	function generateChartURL(locationId, equipmentId, chartId, [startRange, endRange]) {
		return `/#/facility/${locationId}/equipment/${equipmentId}/chart/${chartId}?startDate=${startRange}&endDate=${endRange}`;
	}

	function generateLocationURL(locationId, [startRange, endRange]) {
		return `/#/facility/${locationId}?startDate=${startRange}&endDate=${endRange}`;
	}

	function checkIsVisualExport() {
		return checkIsBodyIncludesClass('export');
	}

	function checkIsVisualExportWithWhiteBackground() {
		return checkIsBodyIncludesClass('export') && checkIsBodyIncludesClass('white');
	}

	function checkIsBodyIncludesClass(className) {
		return Array.from(document.querySelector('body').classList).includes(className);
	}

	window.ParetoChartRenderer = ParetoChartRenderer;
})(window.d3, window.moment, window.AbstractChartRenderer);
