angular.module('TISCC').service('tisObjectService', function($http, cacheService, locationEquipmentService, helpers) {
	this.getObjectProperties = function(id) {
		return $http.get('/ext_api/api/tisObject/' + id + '/properties?displayState=all', {
			cache: cacheService.getCacheInstance('tisObjectService', 'ObjectProperties', id),
		});
	};

	this.getObjectPropertiesByTisObjectTypeGroupNames = function(tisObjectTypeGroupNames) {
		tisObjectTypeGroupNames = (Array.isArray(tisObjectTypeGroupNames) ? tisObjectTypeGroupNames : [tisObjectTypeGroupNames]).join();

		return $http.get(`/ext_api/api/tisObjectType/properties?displayState=all&tisObjectTypeGroupNames=${tisObjectTypeGroupNames}`, {
			cache: cacheService.getCacheInstance('tisObjectService', 'ObjectTypeGroupNumberProperties', tisObjectTypeGroupNames),
		});
	};

	this.getAllValuesByIds = function(params) {
		let apiUrl = '/ext_api/api/tisObject/data';
		let from = params.from.tz(params.timeZone);
		let to = params.to.tz(params.timeZone);
		let now = moment().tz(params.timeZone);
		let config = {};

		if (to.isAfter(now, 'day')) {
			to = moment(now);
		}

		config.headers = {
			Enumerations: params.enumerations,
			'Units-Of-Measure': params.uoms,
			TisObjectIds: params.ids.filter(Boolean).join(','),
			'Hydration-Strategy-Default': 'nullcopyforward',
			'Missing-Updates-Default': 'skip',
			'Filter-Special-Mark-Values': false,
		};

		let interval = params.interval ? params.interval : 'PT15m';
		config.params = {
			// TODO remove :  rangeKey (dev purpose )
			rangeKey: params.rangeKey,
			startDate: from.format(),
			endDate: to.format(),
			interval: interval,
			hpath: params.hpath || '""',
			timeZone: params.timeZone,
			isPareto: params.isPareto,
		};
		if (params.timeoutPromise) {
			config.timeout = params.timeoutPromise.promise;
		}

		const cacheKey = ({params, headers}) => {
			return `${params.startDate}-${params.endDate}-${params.hpath}-${params.timeZone}-${headers.Enumerations}-${headers.TisObjectIds}-${
				headers['Units-Of-Measure']
			}`;
		};

		config.cache = cacheService.getCacheInstance('tisObjectService', 'getallValuesByIds', cacheKey(config));

		return $http.get(apiUrl, config).then(function(resp) {
			return resp.data;
		});
	};

	this.getCprReportHeader = function(params) {
		let apiUrl = '/ext_api/api/chillerAnalyticsParameter';

		let config = {};
		config.headers = {
			'Hydration-Strategy-Default': 'nullcopyforward',
			'Missing-Updates-Default': 'skip',
			'Filter-Special-Mark-Values': false,
		};

		config.params = {
			tisObjectId: params.ids.filter(Boolean).join(','),
		};

		config.cache = cacheService.getCacheInstance('tisObjectService', 'chillerPerformanceChartCprReportHeader', config.params.tisObjectId);

		if (params.timeoutPromise) {
			config.timeout = params.timeoutPromise.promise;
		}

		return $http.get(apiUrl, config).then(function(resp) {
			return resp.data;
		});
	};

	this.getAHUValues = params => {
		let apiUrl = '/ext_api/api/tisObject/data';
		let config = {};

		config.params = {
			...params,
		};
		config.headers = {
			Enumerations: params.enumerations,
			'Units-Of-Measure': params.uoms,
			TisObjectIds: params.ids.filter(Boolean).join(','),
			'Hydration-Strategy-Default': 'nullcopyforward',
			'Missing-Updates-Default': 'skip',
			'Filter-Special-Mark-Values': false,
		};
		return $http.get(apiUrl, config).then(function(resp) {
			return resp.data;
		});
	};

	this.findParentTisObjectForChart = function(chartType, tisObject, tisObjectsHierarchy) {
		let parent = locationEquipmentService.searchParentTisObject(tisObject, tisObjectsHierarchy, null);
		while (parent && helpers.getPropertyByPath(parent, 'tisObjectType.tisObjectTypeGroupNumber') !== chartType) {
			parent = locationEquipmentService.searchParentTisObject(parent, tisObjectsHierarchy, null);
		}
		return parent;
	};
});
