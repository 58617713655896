/* eslint-disable max-len */
// ------------------------------------------------------------- Register hPath id and test fn -------------------------------------------------------------

/**
 * Configured CustomHpath-Ids and associated test condition
 * Below will be used to show hPaths on UI based on requirement for each chart if data statifies the test condition
 *
 * chartProdIDandCustomizedHpaths : For Dev puporse - to know which hpaths are processed through the test function
 *
 * 		chartProdIDandCustomizedHpaths : {
 * 			[chart Prod Id] : 'Hpaths that are modified / customized to display based on test function result'
 * 		}
 *
 * test : function will be called to determine whether a hpath can be included or not
 *
 */
export const hPathIdsAndStateValidator = {
	h1001: {
		chartProdIDandCustomizedHpaths: {
			20: '//child::Circuit@EvaporatorRefrigerantTemperature,//child::Circuit@CondenserSaturatedRefrigerantTemperature',
		},
		test: function(chillerType, compressorType, cricuitType) {
			if (chillerType === 'watercooled') {
				if ('centrifugal,helical'.includes(compressorType) && cricuitType === 'single') {
					return false;
				}
			}
			return true;
		},
	},
	h1002: {
		chartProdIDandCustomizedHpaths: {
			20: '//child::Circuit@EvaporatorSatRfgtTempCommResult,//child::Circuit@CondenserSatRfgtTempCommResult',
		},
		test: function(chillerType, compressorType, cricuitType) {
			if (chillerType === 'watercooled') {
				if ('centrifugal,helical'.includes(compressorType) && cricuitType === 'single') {
					return true;
				}
			}
			return false;
		},
	},
	h1003: {
		chartProdIDandCustomizedHpaths: {
			20: '//child::Compressor@InletGuideVanesPosition',
			22: '//child::Compressor@InletGuideVanesPosition',
			27: '@PurgePumpoutRateChillerOn30day,@PurgePumpoutRateChillerOff30day,@PurgePumpoutAverage30day,@PurgeRefrigerantCompressorSuctionTemperature,@PurgeLiquidTemperature',
		},
		test: function(chillerType, compressorType, cricuitType) {
			if (chillerType === 'watercooled') {
				if ('centrifugal'.includes(compressorType) && cricuitType === 'single') {
					return true;
				}
			}
			return false;
		},
	},
	h1004: {
		chartProdIDandCustomizedHpaths: {
			20: '//child::Circuit@ExpansionValvePosition',
		},
		test: function(chillerType, compressorType, cricuitType) {
			if (chillerType === 'watercooled') {
				if ('helical'.includes(compressorType) && cricuitType === 'single') {
					return true;
				}
			}
			return false;
		},
	},
	h1005: {
		chartProdIDandCustomizedHpaths: {
			19: '@CondenserWaterPumpOutput,@CondenserWaterFlowStatus',
			21: '@CondenserWaterTemperatureEntering,@CondenserWaterTemperatureLeaving',
		},
		test: function(chillerType) {
			return chillerType === 'aircooled' ? false : true;
		},
	},
	h1006: {
		chartProdIDandCustomizedHpaths: {
			13: '@CondenserWaterFlowRateNormalized',
			20: '@CondenserWaterTemperatureEntering,@CondenserWaterTemperatureLeaving',
		},
		test: function(chillerType, compressorType, cricuitType) {
			if (chillerType === 'aircooled') {
				if ('helical,scroll'.includes(compressorType) && cricuitType === 'multiple') {
					return false;
				}
			}
			return true;
		},
	},
	h1007: {
		chartProdIDandCustomizedHpaths: {
			20: '@OutdoorAirTemperature,WeatherSourceV2::@Temperature',
		},
		test: function(chillerType, compressorType, cricuitType) {
			if (chillerType === 'aircooled') {
				if ('helical,scroll'.includes(compressorType) && cricuitType === 'multiple') {
					return true;
				}
			}
			return false;
		},
	},
	h1008: {
		chartProdIDandCustomizedHpaths: {
			13: '//child::Circuit@CircuitCurrentDrawRLACalc',
			17: '//child::Circuit@CircuitCurrentDrawRLACalc',
			18: '//child::Circuit@CircuitCurrentDrawRLACalc',
			20: '//child::Compressor@CompressorCurrentDrawResult',
			24: '//child::Circuit@CircuitCurrentDrawRLACalc, //child::Circuit@EvaporatorRefrigerantTemperature',
		},
		test: function(chillerType, compressorType, cricuitType) {
			// allow if type is either multiple or unknown
			if (cricuitType !== 'single') {
				return true;
			}
			return false;
		},
	},
	h1009: {
		chartProdIDandCustomizedHpaths: {
			17: '@ChillerLoadNormalized',
			18: '@ChillerLoadNormalized',
			22: '//child::Compressor@DriveFrequency',
		},
		test: function(chillerType, compressorType, cricuitType) {
			// allow if type is either single or unknown
			if (cricuitType !== 'multiple') {
				return true;
			}
			return false;
		},
	},
	// To support instance level chart (ex : Motor power performance)
	h1010: {
		chartProdIDandCustomizedHpaths: {
			25: '@ChillerCurrentEnteringDrawRla, @ChillerCurrentEnteringLimitSetpointActive',
		},
		test: function(chillerType, compressorType, cricuitType, compressorCountType) {
			// allow if circuit & compressor count is either single or unknown
			if (compressorCountType !== 'multiple') {
				return true;
			}
			return false;
		},
	},
	// To support instance level chart (ex : Motor power performance)
	h1011: {
		chartProdIDandCustomizedHpaths: {
			25: `//child::Compressor@CompressorVoltagePhaseABNormalized, 
				 //child::Compressor@CompressorVoltagePhaseBCNormalized,
				 //child::Compressor@CompressorVoltagePhaseCANormalized,`,
		},
		test: function(chillerType, compressorType, cricuitType, compressorCountType, instance) {
			if (compressorCountType !== 'multiple') {
				return true;
			}
			// allow if circuit & compressor count is either multiple or unknown and valid below defined instance
			const allowedInstance = {
				'1A': true,
				'2A': true,
			};
			if ((allowedInstance[instance] || 'centrifugal'.includes(compressorType)) && (compressorCountType !== 'single' || cricuitType !== 'single')) {
				return true;
			}
			return false;
		},
	},
	h1012: {
		chartProdIDandCustomizedHpaths: {
			23: '//child::Circuit@CircuitCondenserFanOutput',
		},
		test: function(chillerType, compressorType, cricuitType) {
			if (chillerType === 'aircooled' && cricuitType === 'multiple') {
				return true;
			}
			return false;
		},
	},
	h1013: {
		chartProdIDandCustomizedHpaths: {
			14: '@CondenserWaterTemperatureDeltaT, @CondenserWaterFlowRateNormalized',
			23: '@CondenserWaterTemperatureSetpointActive, @CondenserWaterTemperatureEntering, @CondenserWaterTemperatureLeaving, @CondenserWaterFlowRateNormalized, @CondenserHeadPressureControlOutput',
		},
		test: function(chillerType) {
			return chillerType === 'watercooled' ? true : false;
		},
	},
	h1014: {
		chartProdIDandCustomizedHpaths: {
			23: '@CondenserHeatRecoveryWaterTemperatureEntering, @CondenserHeatRecoveryWaterTemperatureLeaving, //child::Circuit@CondenserSatRfgtTempCommResult',
		},
		test: function(chillerType, compressorType, cricuitType) {
			if (chillerType === 'watercooled' && cricuitType === 'single') {
				return true;
			}
			return false;
		},
	},
	h1015: {
		chartProdIDandCustomizedHpaths: {
			23: '//child::Circuit@CondenserSaturatedRefrigerantTemperature, //child::Circuit@CircuitCurrentDrawRLACalc',
		},
		test: function(chillerType, compressorType, cricuitType) {
			if ((chillerType === 'watercooled' || chillerType === 'aircooled') && cricuitType === 'multiple') {
				return true;
			}
			return false;
		},
	},
	h1016: {
		chartProdIDandCustomizedHpaths: {
			14: '@ChilledWaterTemperatureDeltaT, @ChilledWaterFlowRateNormalized',
		},
		test: function(chillerType, compressorType, cricuitType) {
			if (chillerType === 'aircooled') {
				return 'helical,scroll'.includes(compressorType) ? true : false;
			}
			return true;
		},
	},
	h1017: {
		chartProdIDandCustomizedHpaths: {
			14: '//child::Circuit@CircuitCurrentDrawRLACalc',
		},
		test: function(chillerType, compressorType, cricuitType) {
			if ((chillerType === 'aircooled' || chillerType === 'watercooled') && cricuitType === 'multiple') {
				// if water cooled, dont check compressor type, return true always
				// if air cooled, check compressor type, return value based on condition
				return chillerType === 'watercooled' || 'helical,scroll'.includes(compressorType) ? true : false;
			}
			return false;
		},
	},
	h1018: {
		chartProdIDandCustomizedHpaths: {
			14: '@ChillerCurrentEnteringDrawRla',
		},
		test: function(chillerType, compressorType, cricuitType) {
			if ((chillerType === 'aircooled' || chillerType === 'watercooled') && cricuitType === 'single') {
				// if water cooled, check compressor type is helical or scroll
				// if air cooled, check compressor type is scroll or not
				return (chillerType === 'watercooled' ? 'centrifugal,helical' : 'scroll').includes(compressorType) ? true : false;
			}
			return false;
		},
	},
	h1019: {
		chartProdIDandCustomizedHpaths: {
			50: '//child::Compressor@CompressorVoltagePhaseABNormalized, //child::Compressor@CompressorVoltagePhaseBCNormalized, //child::Compressor@CompressorVoltagePhaseCANormalized, //child::Compressor@CompressorDriveTemperatureInverterBase, //child::Compressor@CompressorDriveTemperatureRectifierBase, //child::Compressor@CompressorDriveTemperatureTransistor',
		},
		test: function(chillerType, compressorType, cricuitType) {
			return (cricuitType === 'single' ? 'centrifugal,helical' : 'centrifugal').includes(compressorType);
		},
	},
	h1020: {
		chartProdIDandCustomizedHpaths: {
			24: '//child::Circuit@EvaporatorSatRfgtTempCommResult',
			50: '//child::Compressor@CompressorMotorWindingTemperaturePhase1, //child::Compressor@CompressorMotorWindingTemperaturePhase2, //child::Compressor@CompressorMotorWindingTemperaturePhase3, //child::Compressor@CompressorStarterHeatSinkTemperature ',
		},
		test: function(chillerType, compressorType, cricuitType) {
			if (cricuitType === 'single') {
				return 'centrifugal,helical'.includes(compressorType);
			}
			return false;
		},
	},
	h1021: {
		chartProdIDandCustomizedHpaths: {
			19: '@CondenserWaterFlowSwitchStuckClosedException',
		},
		test: function(chillerType) {
			return chillerType === 'aircooled' ? false : true;
		},
	},
};

// ------------------------------------------------------------- Register Service -------------------------------------------------------------

angular.module('TISCC').service('stateBasedHpathService', function() {
	this.chillerState = '';

	this.getValidator = function(id) {
		return hPathIdsAndStateValidator[id] || null;
	};

	this.getArgumentsValueForChillerValidator = getArgumentsValueForChillerValidator;
});

// ------------------------------------------------------------- Data gen function -------------------------------------------------------------

/**
 *
 * (Immutable) function to generate values required to find hPath based on conditions
 *
 * @param {*} chillerCompressorType
 * @returns
 */
function getArgumentsValueForChillerValidator(chillerCompressorType) {
	const {tisObjectSubType = '', tisObjectClassificationType = '', hasMultipleCircuitInstance, hasMultipleCompressorInstances} = chillerCompressorType || {};

	const chillerName = (tisObjectClassificationType || '').replace(/ /g, '').toLowerCase();
	const compressorName = (tisObjectSubType || '').replace(/ /g, '').toLowerCase();

	// check chiller type (option : watercooled / aircooled / unknown)
	const chillerType = (chillerName.startsWith('water') && 'watercooled') || (chillerName.startsWith('air') && 'aircooled') || 'Unknown';

	// check compressor type (option : centrifugal / helical / scroll / unknown)
	const hasCompressor = compType => compressorName.startsWith(compType) && compType;
	const compressorType = hasCompressor('centrifugal') || hasCompressor('helical') || hasCompressor('scroll') || 'Unknown';

	const cricuitType = hasMultipleCircuitInstance === undefined ? 'Unknown' : hasMultipleCircuitInstance ? 'multiple' : 'single';

	const compressorCountType = hasMultipleCompressorInstances === undefined ? 'Unknown' : hasMultipleCircuitInstance ? 'multiple' : 'single';

	return {
		chillerType,
		compressorType,
		cricuitType,
		compressorCountType,
	};
}
