/* global EventEmitter*/
import MyViewsAdapterV2 from './my-views-adapter-v2';
import {USER_EVENTS} from '../../common/usage-tracking/categories';

const {HOME_PAGE: {events: HOME_PAGE_EVENTS, properties: HOME_PAGE_PROPERTIES, categoryName: HOME_PAGE_CATEGORY_NAME}} = USER_EVENTS;

(function() {
	const services = new WeakMap();

	class MyViewsController {
		constructor(userDataService, uiSessionStateService, modalHelperService, usageTrackingService, VIEW_FILTERS) {
			services.set(this, {
				uiSessionStateService,
				userDataService,
				modalHelperService,
			});
			this.VIEW_FILTERS = VIEW_FILTERS;
			this.currentViewFilter = VIEW_FILTERS.ALL_FILTER;
			this.myViewsList = [];
			this.uiStateKeyCurrentView = null;
			this.trackEvent = usageTrackingService.trackEventByCategory(HOME_PAGE_CATEGORY_NAME);

			const setcurrentViewIndex = () => {
				const viewFound = this.myViewsList.some((view, index) => {
					if (view.name === this.currentView) {
						this.currentViewData = view.list;
						this.currentViewIndex = index;
						return true;
					}
				});
				if (!viewFound) {
					this.currentViewIndex = false;
					this.currentView = false;
					this.currentViewData = [];
				}
			};

			const userDataCallback = function(data) {
				if (!(data && data.userDataList && data.userDataList.length && data.userDataList[0].data)) {
					this.viewDataEvent.emit('initCurrentView', null);
					return;
				}
				const userData = data.userDataList[0].data;
				if (!userData.viewList) {
					this.viewDataEvent.emit('initCurrentView', null);
					return;
				}
				this.myViewsList = userData.viewList.map(data => (Number(data.version) === MyViewsAdapterV2.VERSION ? data : new MyViewsAdapterV2(data)));
				this.currentView = userData.currentView;
				this.defaultView = userData.currentView;

				if (uiSessionStateService.stateExists(this.uiStateKeyCurrentView)) {
					this.currentView = uiSessionStateService.getState(this.uiStateKeyCurrentView, true);
				}
				setcurrentViewIndex();

				this.viewDataEvent.emit('initCurrentView', {
					currentViewName: this.currentView,
					currentViewData: this.currentViewData,
				});
			};

			const openMyViewDelete = index => {
				if (index === this.currentViewIndex) {
					this.setAll();
				}
			};

			const updateMyView = data => {
				const callback = data.callback || function(data) {};
				const errorCallback = data.errorCallback || function(data) {};

				this.myViewsList = data.data;

				userDataService.setUserData(
					{
						data: {
							viewList: this.myViewsList,
							currentView: this.defaultView,
						},
					},
					callback,
					errorCallback
				);

				if (data.updatedViewName && data.editCurrentView) {
					this.currentViewData = data.updatedViewData;
					this.currentView = data.updatedViewName;
					this.viewDataEvent.emit('changeCurrentView', {currentViewData: this.currentViewData, currentViewName: this.currentView, forceUpdate: true});
				}
				if (data.deletedViewName && data.deletedViewName === this.currentView) {
					this.setAll();
				}
			};

			const changeCurrentView = data => {
				if (data.forceUpdate || (data.currentViewName && data.currentViewData)) {
					// this.currentViewData = data.currentViewData;
					this.currentView = data.currentViewName;
					setcurrentViewIndex();
				}
			};

			this.$onInit = () => {
				if (!this.viewDataEvent || typeof this.viewDataEvent.on !== 'function') {
					this.viewDataEvent = new EventEmitter();
				}

				this.uiStateKeyCurrentView = this.uiStateKey + '.currentView';
				this.currentView = uiSessionStateService.getState(this.uiStateKeyCurrentView, true) || false;
				userDataService.getUserData().then(userDataCallback.bind(this));

				this.viewDataEvent.on('openMyViewDelete', openMyViewDelete);
				this.viewDataEvent.on('updateMyView', updateMyView);
				this.viewDataEvent.on('changeCurrentView', changeCurrentView);
			};

			this.$onDestroy = () => {
				this.viewDataEvent.off('openMyViewDelete', openMyViewDelete);
				this.viewDataEvent.off('updateMyView', updateMyView);
				this.viewDataEvent.off('changeCurrentView', changeCurrentView);
			};
		}

		setAll() {
			const {uiSessionStateService} = services.get(this);

			this.currentViewIndex = false;
			this.currentView = false;
			this.currentViewData = [];
			this.viewDataEvent.emit('changeCurrentView', {
				forceUpdate: true,
				currentViewData: this.currentViewData,
				currentViewFilter: this.currentViewFilter,
				currentViewName: this.currentView,
			});

			uiSessionStateService.registerState(this.uiStateKeyCurrentView, '', true);
		}

		setFilter(viewFilter) {
			const {VIEW_FILTERS} = this;

			this.currentViewFilter = viewFilter;

			if (viewFilter === VIEW_FILTERS.ALL_FILTER) {
				this.setAll();
			} else {
				this.viewDataEvent.emit('changeCurrentViewFilter', {viewFilter});
			}

			this.onViewChange({isCustomView: false});
		}

		changeCurrentView(newView) {
			const {uiSessionStateService} = services.get(this);
			this.currentView = newView.name;
			this.currentViewData = newView.list;
			this.viewDataEvent.emit('changeCurrentView', {currentViewData: this.currentViewData, currentViewName: this.currentView});
			uiSessionStateService.registerState(this.uiStateKeyCurrentView, this.currentView, true);
			this.onViewChange({isCustomView: true});
		}

		setAsDefault() {
			this.defaultView = this.currentView;
			this.viewDataEvent.emit('updateMyView', {
				data: this.myViewsList,
			});
		}

		myViewsManage() {
			const {modalHelperService} = services.get(this);

			this.modal = modalHelperService.open({
				templateUrl: 'components/my-views/my-views-list-dialog.html',
				controller: 'MyViewsListDialogCtrl',
				backdrop: 'static',
				resolve: {
					viewDataEvent: () => {
						return this.viewDataEvent;
					},
					list: () => {
						return this.myViewsList;
					},
					currentViewName: () => {
						return this.currentView;
					},
				},
			});
		}

		onViewChange({isCustomView = false}) {
			this.trackEvent(HOME_PAGE_EVENTS.CHOOSE_VIEW, {
				[HOME_PAGE_PROPERTIES.CUSTOM_VIEW]: isCustomView,
			});
		}

		onSetViewAsDefaultClick() {
			this.trackEvent(HOME_PAGE_EVENTS.SET_MY_VIEW_AS_DEFAULT);
			this.setAsDefault();
		}

		onManageMyViewsClick() {
			this.trackEvent(HOME_PAGE_EVENTS.MANAGE_MY_VIEWS);
			this.myViewsManage();
		}
	}

	angular.module('TISCC').component('myViews', {
		templateUrl: 'components/my-views/my-views.html',
		controller: MyViewsController,
		bindings: {
			uiStateKey: '<',
			viewDataEvent: '<',
		},
	});
})();
