import {REPORT_LANGUAGE_LIST, DEFAULT_REPORT_LANGUAGE, SYSTEM_OF_MEASURE_LIST, DEFAULT_SYSTEM_OF_MEASURE} from './digital-inspection-report-service';
import {getReportNameByKey, getReportGenerationStatus} from '../../../common/usage-tracking/categories/report-generation/utils.js';
import {USER_EVENTS} from '../../../common/usage-tracking/categories';

const {
	REPORT_GENERATION: {categoryName: REPORT_GENERATION_CATEGORY_NAME, events: REPORT_GENERATION_EVENTS, properties: REPORT_GENERATION_PROPERTIES},
} = USER_EVENTS;

angular.module('TISCC').controller('DigitalInspectionReportCtrlServerless', DigitalInspectionReportCtrl);

function DigitalInspectionReportCtrl(
	$scope,
	$controller,
	$routeParams,
	$location,
	$filter,
	$q,
	$log,
	locationDetailsService,
	REPORT_TYPES,
	exportProgressServiceServerless,
	$http,
	configService,
	CHART_DATE_FORMAT,
	locationEquipmentService,
	MetricsFactory,
	GA_ACTION_NAMES,
	urlService,
	googleAnalyticsService,
	tisObjectTypePropertiesService,
	rawDataService
) {
	angular.extend(this, $controller('AbstractReportCtrl', {$scope, $log}));
	const FILE_FORMAT = 'pdf';
	let translate = $filter('translate');
	let reportGenerationStartTime = null;
	let defaultExportProgressMessages = {
		message: $filter('translate')('LOADING_REPORT'),
		progressPercentage: 0,
		loading: false,
	};
	let reportGenerationStarted = null;
	$scope.tisObjectList = [];
	$scope.equipmentTypes = [];
	$scope.popupLeftListData = [];
	$scope.updateLeftCheckboxes = updateLeftCheckboxes;
	$scope.isEquipmentSelected = isEquipmentSelected;
	$scope.activeLanguage = DEFAULT_REPORT_LANGUAGE;
	$scope.reportLanguageList = REPORT_LANGUAGE_LIST;
	$scope.reportSystemOfMesureList = SYSTEM_OF_MEASURE_LIST;
	$scope.activeSystemOfMesure = DEFAULT_SYSTEM_OF_MEASURE;
	$scope.rangeMode = '2D-1Y';
	$scope.exportProgressMessages = angular.copy(defaultExportProgressMessages);
	$scope.resetProgressRequest = resetProgressRequest;
	$scope.triggerEmail = triggerEmail;
	$scope.reportCompletion = reportCompletion;
	$scope.emailRequested = false;
	$scope.showEmailTrigger = false;
	$scope.viewModel = {
		selectedEquipement: {name: translate('OPTIONS_SELECT_ONE'), initValue: true},
		checkAllRight: true,
		isLoadingStarted: false,
	};
	if ($routeParams && $routeParams.startDate) {
		$scope.range.from = moment.tz($routeParams.startDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, locationDetailsService.getLocationTimezone());
		$scope.rangeStart = moment.tz($routeParams.startDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, locationDetailsService.getLocationTimezone());
		$scope.rangeEnd = moment.tz($routeParams.endDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, locationDetailsService.getLocationTimezone());
		$scope.range.to = moment.tz($routeParams.endDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, locationDetailsService.getLocationTimezone());
	}
	$scope.rightListLoadingPromise = null;
	initialize();
	function switchSystemOfMeasure(systemOfMesure) {
		$scope.activeSystemOfMesure = systemOfMesure;
	}
	$scope.$on('generateReport', generateReportHandler);
	$scope.switchSystemOfMeasure = switchSystemOfMeasure;
	$scope.cancelLoading = cancelLoading;
	$scope.updateSelectedEquipmentType = function(eq) {
		$scope.viewModel.selectedEquipement = {
			name: eq.name,
			value: eq.typeName,
		};
		filterLeftPanelData();
	};
	// $scope.$on('updateSelectedEquipmentType', filterLeftPanelData);
	$scope.$watchGroup([() => $scope.popupLeftListData && $scope.popupLeftListData.some(item => item.checked), () => $scope.isGeneratingReport], () => {
		const leftListData = $scope.popupLeftListData || [];
		const isLeftCheckedItemsPresent = leftListData.some(item => {
			googleAnalyticsService.setEquipmentId(item.tisObjectId);
			return item.checked;
		});
		$scope.setDisableGenerateButton($scope.isGeneratingReport || !isLeftCheckedItemsPresent);
	});
	function updateExportProgressMessages() {
		$scope.exportProgressMessages = {
			currentMessage: $filter('translate')('LOADING_REPORT'),
		};
	}
	function getSelectedEquipment() {
		return $scope.popupLeftListData.find(item => item.checked);
	}
	function updateLeftCheckboxes(item) {
		$scope.popupLeftListData.forEach(tisObject => {
			if (tisObject !== item) {
				tisObject.checked = false;
			}
		});
		hideConfirmationMessage();
	}
	function isEquipmentSelected() {
		const {popupLeftListData = []} = $scope;

		return popupLeftListData.find(item => item.checked);
	}
	function hideConfirmationMessage() {
		$scope.$emit('hideConfirmationMessage');
	}

	function initialize() {
		updateExportProgressMessages();
		locationEquipmentService
			.getLocationObjectsList($scope.locationId, null, true)
			.then(data => {
				const {tisObjectList} = data;
				$scope.tisObjectList = tisObjectList;
				$scope.equipmentTypes = tisObjectTypePropertiesService
					.getEquipmentTypes(tisObjectList)
					.filter(type => ['AHU', 'Chiller'].includes(type.typeName));
				if ($scope.defaultEquipment) {
					const selected = $scope.equipmentTypes.find(eq => eq.typeName === $scope.defaultEquipment.tisObjectType.tisObjectTypeGroupName);
					$scope.updateSelectedEquipmentType(selected);
				}
			})
			.catch(() => {
				$scope.popupLeftListData = [];
			});
	}

	$scope.$watchGroup([() => $scope.popupLeftListData && $scope.popupLeftListData.some(item => item.checked), () => $scope.isGeneratingReport], () => {
		const leftListData = $scope.popupLeftListData || [];
		const isLeftCheckedItemsPresent = leftListData.some(item => {
			googleAnalyticsService.setEquipmentId(item.tisObjectId);
			return item.checked;
		});
		$scope.setDisableGenerateButton($scope.isGeneratingReport || !isLeftCheckedItemsPresent);
	});

	function sendExportRequest(data) {
		return $http.post(`${configService.getExportAPIConfig()}/export${data.visualExport ? '/visual' : ''}`, data, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		});
	}

	function generateReportHandler() {
		/* eslint camelcase: 0*/
		MetricsFactory.mark(MetricsFactory.METRIC_TYPES.DIR_LOAD, {
			range_type: $scope.rangeMode,
			range_duration: $scope.range.to - $scope.range.from,
			report_type: 'Chiller',
			objects_count: 1,
		});
		const data = prepareData();
		const {DIR: labels} = GA_ACTION_NAMES;
		reportGenerationStarted = new Date();
		hideConfirmationMessage();
		$scope.isGeneratingReport = true;
		$scope.viewModel.isLoadingStarted = true;

		$scope.trackGenerateReportEvent({
			[REPORT_GENERATION_PROPERTIES.REPORT]: getReportNameByKey('EQUIPMENT_MENU_DIGITAL_INSPECTION'),
			[REPORT_GENERATION_PROPERTIES.FROM_DATE]: $scope.range.from.clone().format('MM-DD-YYYY'),
			[REPORT_GENERATION_PROPERTIES.TO_DATE]: $scope.rangeEnd.clone().format('MM-DD-YYYY'),
			[REPORT_GENERATION_PROPERTIES.REPORT_GENERATION_STATUS]: getReportGenerationStatus('started'),
		});

		reportGenerationStartTime = performance.now();
		$scope.$emit('resetDownloadLink');
		sendExportRequest(data)
			.then(({data}) => {
				const {progressKey} = data;
				$scope.progressKey = progressKey;
				$scope.viewModel.isLoadingStarted = true;
				exportProgressServiceServerless.open(progressKey).then(finalizeExport, failExportWrapper, showExportProgress);
			})
			.catch(() => {
				$scope.resetProgressRequest();
				$scope.$emit('hideLoader');
			});
	}
	$scope.$watch('rangeEnd', function() {
		if ($scope.rangeMode === '2D-1Y') {
			const newDate = moment($scope.rangeEnd);
			if (newDate.isSame($scope.maxDt)) {
				$scope.range.to = $scope.maxDt;
			} else {
				$scope.rangeEnd.add(1, 'days');
				$scope.range.to = $scope.rangeEnd;
			}
		}
	});
	function prepareData() {
		const selectedEquipment = getSelectedEquipment();
		const startDate = $scope.range.from.clone().format('MM-DD-YYYY');
		const endDate = $scope.rangeEnd.clone().format('MM-DD-YYYY');
		const data = {};
		data.visualExport = true;
		data.url = [
			urlService.updateHttpsToHttp($location.absUrl()).split('#')[0],
			`#/facility/${$scope.locationId}/equipment/${selectedEquipment.tisObjectId}/digital-inspection-report?`,
			`lang=${$scope.activeLanguage.value}`,
			`&system=${$scope.activeSystemOfMesure.value}`,
			`&startDate=${startDate}`,
			`&endDate=${endDate}`,
			'&reportSections=',
		].join('');
		$log.log('DIR url: ', data.url);
		data.filename = `${$scope.data.locationData.locationName}_${startDate}_${selectedEquipment.value}_Digital Inspection.${FILE_FORMAT}`;
		data.format = FILE_FORMAT;
		data.reportType = REPORT_TYPES.DIGITAL_INSPECTION.report;
		data.locationId = $scope.data.locationData.locationId;
		return data;
	}

	function finalizeExport(input = {}) {
		$scope.viewModel.isLoadingStarted = false;
		$scope.isGeneratingReport = false;
		$scope.$emit('generateComplete');
		$scope.$emit('hideLoader');
		$scope.exportProgressMessages = {...defaultExportProgressMessages};
		const {progressPercentage, data} = input;
		if (progressPercentage) {
			$scope.exportProgressMessages.progressPercentage = progressPercentage;
			if (progressPercentage === 100) startDownloadProcess(data);
			let timeTaken = performance.now() - reportGenerationStartTime;
			$scope.trackGenerateReportEvent({
				[REPORT_GENERATION_PROPERTIES.REPORT]: getReportNameByKey('EQUIPMENT_MENU_DIGITAL_INSPECTION'),
				[REPORT_GENERATION_PROPERTIES.FROM_DATE]: $scope.range.from.clone().format('MM-DD-YYYY'),
				[REPORT_GENERATION_PROPERTIES.TO_DATE]: $scope.rangeEnd.clone().format('MM-DD-YYYY'),
				[REPORT_GENERATION_PROPERTIES.REPORT_GENERATION_STATUS]: getReportGenerationStatus('success'),
				[REPORT_GENERATION_PROPERTIES.TOTAL_TIME]: timeTaken,
			});
		}
	}

	function failExportWrapper() {
		$scope.$emit('hideLoader');
		$scope.resetProgressRequest();
		$scope.viewModel.isLoadingStarted = false;
		let timeTaken = performance.now() - reportGenerationStartTime;
		$scope.trackGenerateReportEvent({
			[REPORT_GENERATION_PROPERTIES.REPORT]: getReportNameByKey('EQUIPMENT_MENU_DIGITAL_INSPECTION'),
			[REPORT_GENERATION_PROPERTIES.FROM_DATE]: $scope.range.from.clone().format('MM-DD-YYYY'),
			[REPORT_GENERATION_PROPERTIES.TO_DATE]: $scope.rangeEnd.clone().format('MM-DD-YYYY'),
			[REPORT_GENERATION_PROPERTIES.REPORT_GENERATION_STATUS]: getReportGenerationStatus('failed'),
			[REPORT_GENERATION_PROPERTIES.TOTAL_TIME]: timeTaken,
		});
	}

	function startDownloadProcess(data) {
		let progressKey = exportProgressServiceServerless.getProgressKey();
		if (progressKey && data) {
			$scope.submitExportFormServerless(data);
		} else {
			failExportWrapper(true, false);
		}
	}

	function showExportProgress(input = {}) {
		if (input.emailRequested) return;
		const {progressPercentage, message} = input;
		if (message) {
			$scope.exportProgressMessages.message = message;
		}
		if (progressPercentage) {
			$scope.exportProgressMessages.progressPercentage = progressPercentage;
		}
	}

	function reportCompletion() {
		$scope.resetProgressRequest();
		$scope.$emit('hideLoader');
		$scope.$emit('generateComplete');
		$scope.viewModel.isLoadingStarted = false;
	}

	async function triggerEmail() {
		$scope.emailRequested = true;
		$scope.exportProgressMessages.loading = true;
		const response = await exportProgressServiceServerless.sendEmailRequest({
			progressKey: $scope.progressKey,
		});
		$scope.reportCompletion();
	}

	function resetProgressRequest() {
		$scope.isGeneratingReport = false;
		$scope.progressKey = null;
		$scope.exportProgressMessages = angular.copy(defaultExportProgressMessages);
		$scope.emailRequested = false;
	}

	function cancelLoading() {
		if ($scope.isGeneratingReport) {
			$scope.exportProgressMessages.loading = true;
			exportProgressServiceServerless
				.sendAbortRequest($scope.progressKey)
				.then(() => {
					$scope.exportProgressMessages.loading = false;
					$scope.resetProgressRequest();
					$scope.$emit('hideLoader');
					$scope.viewModel.isLoadingStarted = false;
				})
				.catch(() => {
					$scope.reportCompletion();
				});
		}
	}

	// ----------------- LEFT PANEL --------------------------------
	function filterLeftPanelData() {
		const selectedEquipmentType = $scope.equipmentTypes.find(eq => eq.typeName === $scope.viewModel.selectedEquipement.value);
		const preparedTisObjects = [];
		function isTisObjectInArray(tisObject, arr) {
			return arr.some(function(item) {
				return item.tisObjectId === tisObject.tisObjectId;
			});
		}
		const selectedElements = rawDataService.createEquipmentsList({
			equipments: $scope.tisObjectList,
			typeId: selectedEquipmentType.groupId,
			rootId: selectedEquipmentType.id,
		});
		selectedElements.forEach(function(tisObject) {
			let isValidNotAddedTisObject = !isTisObjectInArray(tisObject, preparedTisObjects);
			tisObject.tisObjectType && tisObject.tisObjectType.tisObjectTypeGroupNumber === selectedEquipmentType.groupId;
			if (isValidNotAddedTisObject) {
				preparedTisObjects.push({
					value: tisObject.tisObjectName,
					objectId: tisObject.objectId,
					tisObjectId: tisObject.tisObjectId,
					tisObjectType: tisObject.tisObjectType,
					subTypeFilterKey: tisObject.subTypeFilterKey,
					id: tisObject.tisObjectType.tisObjectTypeGroupNumber,
					checked: $scope.defaultEquipment ? $scope.defaultEquipment.tisObjectId === tisObject.tisObjectId : false,
					children: tisObject.children || [],
					isStandalone: locationEquipmentService.searchParentTisObject(tisObject, $scope.tisObjectList, null) === null,
				});
			}
		});
		$scope.popupLeftListData = preparedTisObjects;
		$scope.setDisableGenerateButton(true);
	}
}
