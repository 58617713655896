(function() {
	const services = new WeakMap();

	class FilterComponentsModalCtrl {
		constructor($scope, $filter, $localStorage, $modalInstance, modalData) {
			this.searchObj = {searchText: ''};
			this.$modalInstance = $modalInstance;
			this.childComponents = modalData.childComponents;
			this.applyFilter = modalData.applyFilter;

			this.bySearchText = this.bySearchText.bind(this);
			services.set(this, {$scope, $filter, $localStorage, $modalInstance, modalData});
		}

		closeFilterPopup() {
			const {$modalInstance} = services.get(this);
			$modalInstance.close();
		}

		bySearchText(item) {
			const visibleString = item.displayName.toLowerCase();
			const searchText = this.searchObj.searchText.toLowerCase();
			return visibleString.includes(searchText);
		}

		selectDeselectComponent(item) {
			const {$filter, modalData} = services.get(this);
			item.isIntermediate = false;
			let checkedChildComponents = $filter('filter')(modalData.childComponents.list, {isChecked: true});
			let checkedGroupsLen = checkedChildComponents.length;

			modalData.childComponents.isAllChecked = modalData.childComponents.list.length === checkedGroupsLen;
			modalData.childComponents.isAllIntermediate = modalData.childComponents.isAllChecked ? false : checkedGroupsLen !== 0;
		}

		selectDeselectAllComponents() {
			const {$filter, modalData} = services.get(this);
			let filteredChildComponents = $filter('filter')(modalData.childComponents.list, this.bySearchText);

			filteredChildComponents.forEach(component => {
				component.isChecked = modalData.childComponents.isAllChecked;
				component.isIntermediate = false;
			});
			modalData.childComponents.isAllIntermediate = filteredChildComponents.length < modalData.childComponents.list.length;
		}
	}

	angular.module('TISCC').controller('FilterComponentsModalCtrl', FilterComponentsModalCtrl);
})();
