/* eslint eqeqeq: [0, 'always'] */

import ChillerPerformanceReportSectionConfig from './chiller-performance-report-section-config';

export const CPR_DATE_FORMATS = {
	FULL_DATE_TIME_TZ_FORMAT: 'YYYY-MM-DDTHH:mm:ssZ',
	LONG_MONTH_YEAR_FORMAT: 'MMMM YYYY',
	MEDIUM_MONTH_YEAR_FORMAT: 'MMM YYYY',
	LONG_MONTH_FORMAT: 'MMMM',
	MEDIUM_MONTH_FORMAT: 'MMM',
	LONG_DATE_FORMAT: 'MMMM D, YYYY',
	MEDIUM_DATE_FORMAT: 'MMM D, YYYY',
	DATA_DATE_FORMAT: 'YYYY-MM-DD',
	EU_DATA_DATE_FORMAT: 'DD/MM/YYYY',
	CHILLER_DATA_DATE_FORMAT: 'MM/DD/YYYY',
	TIME_FORMAT_H_MM_A: 'h:mm A',
	TIME_FORMAT_H_MM: 'h:mm',
	TIME_FORMAT_HH_MM: 'HH:mm',
	MEDIUM_DAY_FORMAT: 'ddd',
	SHORT_DAY_FORMAT: 'D',
	TIMEZONE_FORMAT: 'z',
};

export const DEFAULT_DESIGN_VALUE = 'none';
const NA_DESIGN_VALUE = 'N/A';

(function() {
	angular
		.module('TISCC')
		.factory('chillerPerformanceReportConfig', function(CPR_REPORT_SECTIONS, locationDetailsService, $translate, helpers, $rootScope, $routeParams) {
			const config = {};

			const getFormattedXTickValues = (timezone, date) => {
				const getDate = val =>
					moment(val)
						.tz(timezone)
						.format()
						.slice(0, 10);
				return moment(getDate(date));
			};

			const totalStartsDataFilter = data => {
				const localData = data.filter(value => {
					return helpers.isNumber(value.value);
				});
				if (localData && localData.length > 1) {
					return localData[localData.length - 1].value - localData[0].value;
				}
				return 0;
			};
			if ($routeParams && $routeParams.lang) {
				$translate.uses($routeParams.lang);
				moment.locale($routeParams.lang);
				d3Locale.current = d3.locale(d3Locale[$routeParams.lang]);
				config.lang = $routeParams.lang;
			}
			if ($routeParams && $routeParams.system) {
				config.systemOfMeasurement = $routeParams.system;
			}
			const timeFormat = d3Locale.current.timeFormat;

			config[CPR_REPORT_SECTIONS.CHILLER_PROFILE] = new ChillerPerformanceReportSectionConfig({
				orderInPopup: 1,
				isChecked: true,
				dialogWidth: '992px',
				imageWidth: '925px',
				imageHeight: '156px',
				imageSource: '/images/cpr/new-chiller-profile-img.png',
				infoText: 'CHILLER_PROFILE_INFO_TEXT',
				height: 135,
				editParameters: ['ChillerCapacityNominal', 'ChillerEnteredService'],
				popupHandler: 'chillerProfileSettingsHandler',
			});

			config[CPR_REPORT_SECTIONS.COMPRESSOR_RUN_HOURS_AND_STARTS] = new ChillerPerformanceReportSectionConfig({
				orderInPopup: 2,
				coSection: CPR_REPORT_SECTIONS.CHILLER_LIFE,
				isChecked: true,
				hpath: '@ActualRunTime|SUM(),//Compressor@CompressorStarts',
				uoms: ['@ActualRunTime=hour'],

				rangeThisMonth: (fromDate, toDate) => {
					return {fromDate: moment(fromDate), toDate: moment(toDate)};
				},
				rangeLastMonth: (fromDate, toDate) => {
					const newFromDate = moment(toDate)
						.add(-1, 'month')
						.startOf('month');
					const newToDate = moment(toDate)
						.add(-1, 'month')
						.endOf('month');
					return {fromDate: newFromDate, toDate: newToDate};
				},
				rangeLastYear: (fromDate, toDate) => {
					const newFromDate = moment(toDate)
						.add(-1, 'year')
						.startOf('month');
					const newToDate = moment(toDate)
						.add(-1, 'year')
						.endOf('month');
					return {fromDate: newFromDate, toDate: newToDate};
				},
				rangeTotalLife: (fromDate, toDate, dataCollectionStartTimestamp) => {
					const newFromDate =
						dataCollectionStartTimestamp && moment(dataCollectionStartTimestamp).startOf('day') < moment(fromDate)
							? moment(dataCollectionStartTimestamp).startOf('day')
							: moment(toDate)
									.add(-1, 'year')
									.startOf('month');
					const newToDate = moment(toDate);
					return {fromDate: newFromDate, toDate: newToDate};
				},
				properties: {
					totalRunHoursThisMonth: {
						propertyName: 'ActualRunTime',
						rangeKey: 'thisMonth',
						range: function(fromDate, toDate) {
							return this.rangeThisMonth(fromDate, toDate);
						},
						dataFilter: data => {
							return data && data[0] && data[0].value;
						},
					},
					totalStartsThisMonth: {
						propertyName: 'CompressorStarts',
						rangeKey: 'thisMonthMinus',
						range: function(fromDate, toDate) {
							const range = this.rangeThisMonth(fromDate, toDate);
							return range;
						},
						dataFilter: data => {
							return totalStartsDataFilter(data);
						},
					},
					averageDailyStartsThisMonth: {
						rangeKey: 'thisMonth',
					},
					averageRunHoursPerStartThisMonth: {
						rangeKey: 'thisMonth',
					},
					totalRunHoursLastMonth: {
						propertyName: 'ActualRunTime',
						rangeKey: 'lastMonth',
						range: function(fromDate, toDate) {
							return this.rangeLastMonth(fromDate, toDate);
						},
						dataFilter: data => {
							return data && data[0] && data[0].value;
						},
					},
					totalStartsLastMonth: {
						propertyName: 'CompressorStarts',
						rangeKey: 'lastMonthMinus',
						range: function(fromDate, toDate) {
							const range = this.rangeLastMonth(fromDate, toDate);
							return range;
						},
						dataFilter: data => {
							return totalStartsDataFilter(data);
						},
					},
					averageDailyStartsLastMonth: {
						rangeKey: 'lastMonth',
					},
					averageRunHoursPerStartLastMonth: {
						rangeKey: 'lastMonth',
					},
					totalRunHoursTotalLife: {
						propertyName: 'ActualRunTime',
						rangeKey: 'totalLife',
						range: function(fromDate, toDate, dataCollectionStartTimestamp) {
							return this.rangeTotalLife(fromDate, toDate, dataCollectionStartTimestamp);
						},
						dataFilter: data => {
							return data && data[0] && data[0].value;
						},
					},
					totalStartsTotalLife: {
						propertyName: 'CompressorStarts',
						rangeKey: 'totalLife',
						range: function(fromDate, toDate, dataCollectionStartTimestamp) {
							const range = this.rangeTotalLife(fromDate, toDate, dataCollectionStartTimestamp);
							return range;
						},
						dataFilter: data => {
							return totalStartsDataFilter(data);
						},
					},
					averageDailyStartsTotalLife: {
						rangeKey: 'totalLife',
					},
					averageRunHoursPerStartTotalLife: {
						rangeKey: 'totalLife',
					},
				},
				imageSource: '/images/cpr/new-chiller-run-hours-and-starts-img.png',
				infoText: 'COMPRESSOR_RUN_HOURS_AND_STARTS_INFO_TEXT',
				dialogWidth: '992px',
				imageWidth: '920px',
				imageHeight: '302px',
				sectionTitleHeight: 33,
				height: 230,
				popupHandler: 'chillerRunHoursAndStartsSettingsHandler',
				divideSectionPerPages: function({MAX_MAIN_CONTENT_HEIGHT, page, section, reportSectionsData, createNewPage, updatePage}) {
					const remainingPageHeight = MAX_MAIN_CONTENT_HEIGHT - page.height;
					const {sectionTitleHeight, height} = this;
					const defaultSectionHeight = sectionTitleHeight + height;

					let sectionCount = reportSectionsData[section].length || 0;

					const dividePerPages = ({isNewPage = true}) => {
						if (sectionCount === 0) {
							const remainingPageHeight = MAX_MAIN_CONTENT_HEIGHT - page.height;
							page = remainingPageHeight < defaultSectionHeight ? createNewPage() : page;

							const sectionName = `${CPR_REPORT_SECTIONS.COMPRESSOR_RUN_HOURS_AND_STARTS}_0`;

							reportSectionsData[sectionName] = {...reportSectionsData[section]};

							updatePage(page, {height: defaultSectionHeight, section: sectionName});

							delete reportSectionsData[section];
						} else {
							for (let i = 0; sectionCount !== 0; i++) {
								page = isNewPage ? createNewPage() : page;

								const remainingPageHeight = MAX_MAIN_CONTENT_HEIGHT - page.height;

								const sectionHeight = isNewPage || i === 0 ? defaultSectionHeight : height;

								const nextAvailableHeight = (isNewPage ? MAX_MAIN_CONTENT_HEIGHT : remainingPageHeight) - sectionHeight;

								if (nextAvailableHeight <= 0) {
									isNewPage = true;
									continue;
								}

								const sectionName = `${CPR_REPORT_SECTIONS.COMPRESSOR_RUN_HOURS_AND_STARTS}_${i}`;
								const sectionData = reportSectionsData[section].splice(0, 1);

								reportSectionsData[sectionName] = [...sectionData[0]];

								updatePage(page, {height: sectionHeight, section: sectionName});

								sectionCount = reportSectionsData[section].length;

								if (sectionCount === 0) {
									delete reportSectionsData[section];
								}

								isNewPage = false;
							}
						}
					};

					dividePerPages({isNewPage: remainingPageHeight < defaultSectionHeight});

					return page;
				},
			});

			config[CPR_REPORT_SECTIONS.CHILLER_LIFE] = new ChillerPerformanceReportSectionConfig({
				orderInPopup: 3,
				coSection: CPR_REPORT_SECTIONS.COMPRESSOR_RUN_HOURS_AND_STARTS,
				isChecked: false,
				imageSource: '/images/cpr/new-chiller-life-img.png',
				infoText: 'CHILLER_LIFE_INFO_TEXT',
				dialogWidth: '369px',
				imageWidth: '302px',
				imageHeight: '307px',
				height: 205,
				editParameters: ['ChillerEnteredService', 'ChillerLife'],
				popupHandler: 'chillerLifeSettingsHandler',
				others: {
					staticData: {
						minValue: 0,
						maxValue: 40,
						stepPct: 12.5,
						marksCount: 4,
					},
				},
			});

			config[CPR_REPORT_SECTIONS.MILESTONES] = new ChillerPerformanceReportSectionConfig({
				orderInPopup: 4,
				isChecked: false,
				imageSource: '/images/cpr/new-milestones-img.png',
				infoText: 'MILESTONES_INFO_TEXT',
				height: 0,
				dialogWidth: '992px',
				imageWidth: '925px',
				imageHeight: '248px',
				popupHandler: 'milestonesSettingsHandler',
				others: {},
				minHeight: 90,
				rowHeight: 20,
				rowMargins: 10,
				calculateRowHeight(milestone) {
					const rowLengthItem = 105;

					let maxRows = Math.ceil(milestone.actionItem.length / rowLengthItem);

					return maxRows * this.rowHeight + this.rowMargins;
				},
				calculateHeight: function({milestones}) {
					return (
						this.minHeight +
						(milestones && milestones.length ? milestones.reduce((sum, milestone) => sum + this.calculateRowHeight(milestone), 0) : 0)
					);
				},
				divideSectionPerPages: function(options) {
					const {milestones = {}, isFailed = false} = options.reportSectionsData[CPR_REPORT_SECTIONS.MILESTONES];
					if (!Object.keys(milestones).length && !isFailed) return;

					this.divideEditableTablePerPages(options, {propertyName: 'milestones'});
				},
			});

			config[CPR_REPORT_SECTIONS.PERFORMANCE_IMPROVEMENTS] = new ChillerPerformanceReportSectionConfig({
				orderInPopup: 5,
				isChecked: false,
				imageSource: '/images/cpr/new-performance-improvements-img.png',
				infoText: 'PERFORMANCE_IMPROVEMENTS_INFO_TEXT',
				dialogWidth: '992px',
				imageWidth: '925px',
				imageHeight: '280px',
				minHeight: 150,
				minHeightWithoutFullHeader: 80,
				rowHeight: 20,
				rowMargins: 10,
				calculateRowHeight(improvement) {
					const rowLengthItem = 120;
					const rowLengthImprovementIn = 50;

					let maxRows = Math.max(
						Math.ceil(improvement.actionItem.length / rowLengthItem),
						Math.ceil(improvement.actionImprovement.length / rowLengthImprovementIn)
					);

					return maxRows * this.rowHeight + this.rowMargins;
				},
				calculateHeight: function({improvements}, isFullHeader = true) {
					return (
						(isFullHeader ? this.minHeight : this.minHeightWithoutFullHeader) +
						(improvements && improvements.length ? improvements.reduce((sum, improvement) => sum + this.calculateRowHeight(improvement), 0) : 0)
					);
				},
				popupHandler: 'performanceImprovementsSettingsHandler',
				divideSectionPerPages: function(options) {
					const {improvements = {}, isFailed = false} = options.reportSectionsData[CPR_REPORT_SECTIONS.PERFORMANCE_IMPROVEMENTS];
					if (!Object.keys(improvements).length && !isFailed) return;

					this.divideEditableTablePerPages(options, {propertyName: 'improvements'});
				},
			});

			config[CPR_REPORT_SECTIONS.CONDENSER_HEAT_TRANSFER] = new ChillerPerformanceReportSectionConfig({
				orderInPopup: 9,
				isChecked: true,
				// eslint-disable-next-line quotes
				hpath: "@ChillerCurrentEnteringDrawRla|BETWEEN(41,100)|AVG(DAY),//child::Circuit@CondenserApproachTempResult|Filter(RLA'GT'40)|AVG(DAY)",
				hpathForMultipleCircuit:
					'//child::Circuit@CircuitCurrentDrawRLACalc|BETWEEN(41,100)|AVG(DAY),' +
					// eslint-disable-next-line quotes
					"//child::Circuit@CondenserApproachTempResult|Filter(RLA'GT'40)|AVG(DAY)",
				isChart: true,
				hasCircuitProperty: true,
				additionalQueriesRelatedToProperties: [
					{
						name: 'trendline',
						type: 'polyline',
						hpath:
							'@ChillerCurrentEnteringDrawRla|BETWEEN(41,100)|AVG(DAY)|TRENDLINE(),' +
							// eslint-disable-next-line quotes
							"//child::Circuit@CondenserApproachTempResult|Filter(RLA'GT'40)|AVG(DAY)|TRENDLINE()",
					},
				],
				additionalQueriesRelatedToPropertiesForMultipleCircuit: [
					{
						name: 'trendline',
						type: 'polyline',
						hpath:
							'//child::Circuit@CircuitCurrentDrawRLACalc|BETWEEN(41,100)|AVG(DAY)|TRENDLINE(),' +
							// eslint-disable-next-line quotes
							"//child::Circuit@CondenserApproachTempResult|Filter(RLA'GT'40)|AVG(DAY)|TRENDLINE()",
					},
				],
				properties: {
					CondenserApproachTempResult: {
						postPropertyProcessFunc: function(column) {
							column.propertyName = `${$translate('PROPERTIES.CircuitCondenserApproachTempResult')}`;
						},
						dataFilter: data => {
							return data;
						},
						type: 'dots',
						section: CPR_REPORT_SECTIONS.CONDENSER_HEAT_TRANSFER,
						numberMarks: 4,
						colorValue: '#97564F',
						multiCircuitPropertyName: '{{name}} Cond Appr Temp: Result',
					},
					ChillerCurrentEnteringDrawRla: {
						additionaLinesCanBeStoredOn: 'CircuitCurrentDrawRLACalc',
						multiCircuitPropertyName: '{{name}} Current Draw (%RLA): Calc',
						type: 'dots',
						section: CPR_REPORT_SECTIONS.CONDENSER_HEAT_TRANSFER,
						numberMarks: 4,
						colorValue: '#76777A',
						minVal: 0,
						maxVal: 100,
						orient: 'right',
						ticFormat: d => d,
					},
					CircuitCurrentDrawRLACalc: {
						alternateRlaPropertyOf: 'ChillerCurrentEnteringDrawRla',
						multiCircuitPropertyName: '{{name}} Current Draw (%RLA): Calc',
						type: 'dots',
						section: CPR_REPORT_SECTIONS.CONDENSER_HEAT_TRANSFER,
						numberMarks: 4,
						colorValue: '#76777A',
						minVal: 0,
						maxVal: 100,
						orient: 'right',
						ticFormat: d => d,
						dataFilter: data => {
							return data;
						},
					},
				},
				others: {
					range: {
						number: 6,
						measure: 'month',
						from: function(fromDate, toDate) {
							return moment(toDate)
								.add(-(this.number - 1), this.measure)
								.startOf(this.measure);
						},
						to: function(fromDate, toDate) {
							return moment(toDate);
						},
					},
					xTicsGenerator: function*(minDate, maxDate) {
						for (
							let x = 0, tickDate = moment(minDate).add(x, 'month');
							tickDate.isSameOrBefore(maxDate);
							x++, tickDate = moment(minDate).add(x, 'month')
						) {
							let formattedXTickValues = getFormattedXTickValues(minDate.tz(), tickDate);
							yield formattedXTickValues.toDate();
						}
					},
					yTicsGenerator: function*(minValue, maxValue, ticsCount) {
						let diff = maxValue - minValue;
						for (let i = 0; i <= ticsCount; i++) {
							yield minValue + diff / ticsCount * i;
						}
					},
					yTicsCount: 8,
					xTicFormat: timeFormat('%b'),
					interval: 'PT15M',
				},
				imageSource: '/images/cpr/new-cond-heat-transfer-img.png',
				dialogWidth: '781px',
				imageWidth: '713px',
				imageHeight: '320px',
				infoText: 'CONDENSER_HEAT_TRANSFER_INFO_TEXT',
				dataFilter: function() {
					return removeRedundantData.bind(this)('ChillerCurrentEnteringDrawRla', ...arguments);
				},
				height: 186,
				divideSectionPerPages: function(options) {
					this.divideChartPerPages(options);
				},
				getCircuitPropertyName() {
					return ['CondenserApproachTempResult', 'CircuitCurrentDrawRLACalc'];
				},
			});

			config[CPR_REPORT_SECTIONS.EVAPORATOR_HEAT_TRANSFER] = new ChillerPerformanceReportSectionConfig({
				orderInPopup: 10,
				isChecked: true,
				// eslint-disable-next-line quotes
				hpath: "@ChillerCurrentEnteringDrawRla|BETWEEN(41,100)|AVG(DAY),//child::Circuit@EvaporatorApproachTempResult|Filter(RLA'GT'40)|AVG(DAY)",
				hpathForMultipleCircuit:
					'//child::Circuit@CircuitCurrentDrawRLACalc|BETWEEN(41,100)|AVG(DAY),' +
					// eslint-disable-next-line quotes
					"//child::Circuit@EvaporatorApproachTempResult|Filter(RLA'GT'40)|AVG(DAY)",
				additionalQueriesRelatedToProperties: [
					{
						name: 'trendline',
						type: 'polyline',
						hpath:
							'@ChillerCurrentEnteringDrawRla|BETWEEN(41,100)|AVG(DAY)|TRENDLINE(),' +
							// eslint-disable-next-line quotes
							"//child::Circuit@EvaporatorApproachTempResult|Filter(RLA'GT'40)|AVG(DAY)|TRENDLINE()",
					},
				],
				additionalQueriesRelatedToPropertiesForMultipleCircuit: [
					{
						name: 'trendline',
						type: 'polyline',
						hpath:
							'//child::Circuit@CircuitCurrentDrawRLACalc|BETWEEN(41,100)|AVG(DAY)|TRENDLINE(),' +
							// eslint-disable-next-line quotes
							"//child::Circuit@EvaporatorApproachTempResult|Filter(RLA'GT'40)|AVG(DAY)|TRENDLINE()",
					},
				],
				isChart: true,
				hasCircuitProperty: true,
				properties: {
					EvaporatorApproachTempResult: {
						postPropertyProcessFunc: function(column) {
							column.propertyName = `${$translate('PROPERTIES.CircuitEvaporatorApproachTempResult')}`;
						},
						type: 'dots',
						section: CPR_REPORT_SECTIONS.EVAPORATOR_HEAT_TRANSFER,
						numberMarks: 4,
						colorValue: '#97564F',
					},
					ChillerCurrentEnteringDrawRla: {
						additionaLinesCanBeStoredOn: 'CircuitCurrentDrawRLACalc',
						type: 'dots',
						numberMarks: 4,
						section: CPR_REPORT_SECTIONS.EVAPORATOR_HEAT_TRANSFER,
						colorValue: '#76777A',
						minVal: 0,
						maxVal: 100,
						orient: 'right',
						ticFormat: d => d,
					},
					CircuitCurrentDrawRLACalc: {
						alternateRlaPropertyOf: 'ChillerCurrentEnteringDrawRla',
						multiCircuitPropertyName: '{{name}} Current Draw (%RLA): Calc',
						type: 'dots',
						numberMarks: 4,
						section: CPR_REPORT_SECTIONS.EVAPORATOR_HEAT_TRANSFER,
						colorValue: '#76777A',
						minVal: 0,
						maxVal: 100,
						orient: 'right',
						ticFormat: d => d,
					},
				},
				others: {
					range: {
						number: 6,
						measure: 'month',
						from: function(fromDate, toDate) {
							return moment(toDate)
								.add(-(this.number - 1), this.measure)
								.startOf(this.measure);
						},
						to: function(fromDate, toDate) {
							return moment(toDate);
						},
					},
					xTicsGenerator: function*(minDate, maxDate) {
						for (
							let x = 0, tickDate = moment(minDate).add(x, 'month');
							tickDate.isSameOrBefore(maxDate);
							x++, tickDate = moment(minDate).add(x, 'month')
						) {
							let formattedXTickValues = getFormattedXTickValues(minDate.tz(), tickDate);
							yield formattedXTickValues.toDate();
						}
					},
					yTicsGenerator: function*(minValue, maxValue, ticsCount) {
						let diff = maxValue - minValue;
						for (let i = 0; i <= ticsCount; i++) {
							yield minValue + diff / ticsCount * i;
						}
					},
					yTicsCount: 8,
					xTicFormat: timeFormat('%b'),
					interval: 'PT15M',
				},
				imageSource: '/images/cpr/new-evap-heat-transfer-img.png',
				dialogWidth: '781px',
				imageWidth: '713px',
				imageHeight: '320px',
				infoText: 'EVAPORATOR_HEAT_TRANSFER_INFO_TEXT',
				dataFilter: function() {
					return removeRedundantData.bind(this)('ChillerCurrentEnteringDrawRla', ...arguments);
				},
				height: 186,
				divideSectionPerPages: function(options) {
					this.divideChartPerPages(options);
				},
				getCircuitPropertyName() {
					return ['EvaporatorApproachTempResult', 'CircuitCurrentDrawRLACalc'];
				},
			});

			config[CPR_REPORT_SECTIONS.PERFORMANCE_EFFICIENCY] = new ChillerPerformanceReportSectionConfig({
				orderInPopup: 7,
				isChecked: true,
				hpath: '@ChillerEfficiencyActive|BETWEEN(0.00000000000000001,99999999999999)|AVG(DAY)',
				isChart: true,
				properties: {
					ChillerEfficiencyActive: {
						propertyNameFunc: function(data) {
							return data.columns[0].sourceUnitOfMeasure.symbol;
						},
						type: 'polyline',
						numberMarks: 8,
						colorValue: '#57706A',
						additionalLines: [
							{
								name: 'Avg',
								label: function(data) {
									if (data && data.y1 && typeof data.y1.toFixed === 'function') {
										return {
											noUOM: true,
											name: 'AVG_DOT',
											value: data.y1.toFixed(2),
										};
									} else {
										return null;
									}
								},
								type: 'line',
								data: function(data) {
									const avg = data.reduce(
										(accumulator, currentValue, index, array) => {
											let accumulatorVal = accumulator;
											if (currentValue.y) {
												Object.assign(accumulatorVal, {
													val: accumulatorVal.val + currentValue.y,
													count: accumulatorVal.count + 1,
												});
											}
											if (index === array.length - 1) {
												return accumulatorVal.val / accumulatorVal.count;
											}
											return accumulatorVal;
										},
										{val: 0, count: 0}
									);
									return {
										x1: data[0].x,
										y1: avg,
										x2: data[data.length - 1].x,
										y2: avg,
									};
								},
							},
						],
					},
				},
				others: {
					range: {
						number: 1,
						measure: 'month',
						from: function(fromDate, toDate) {
							return moment(toDate)
								.add(-(this.number - 1), this.measure)
								.startOf(this.measure);
						},
						to: function(fromDate, toDate) {
							return moment(toDate);
						},
					},
					xTicsGenerator: function*(minDate, maxDate) {
						for (
							let x = 0, tickDate = moment(minDate).add(x, 'day');
							tickDate.isSameOrBefore(maxDate);
							x = x + 6, tickDate = moment(minDate).add(x, 'day')
						) {
							let formattedXTickValues = getFormattedXTickValues(minDate.tz(), tickDate);
							yield formattedXTickValues.toDate();
						}
					},
					yTicsGenerator: function*(minValue, maxValue, ticsCount) {
						let diff = maxValue - minValue;
						for (let i = 0; i <= ticsCount; i++) {
							yield minValue + diff / ticsCount * i;
						}
					},
					yTicsCount: 8,
					xTicFormat: timeFormat('%b %d'),
					interval: 'PT15M',
				},
				imageSource: '/images/cpr/new-perf-efficiency-img.png',
				dialogWidth: '781px',
				imageWidth: '713px',
				imageHeight: '320px',
				infoText: 'PERFORMANCE_EFFICIENCY_INFO_TEXT',
				height: 170,
				divideSectionPerPages: function(options) {
					this.divideChartPerPages(options);
				},
			});

			config[CPR_REPORT_SECTIONS.PERFORMANCE_WATER] = new ChillerPerformanceReportSectionConfig({
				orderInPopup: 8,
				isChecked: true,
				hpath: '@CondenserWaterTemperatureLeaving|AVG(DAY),@ChilledWaterTemperatureLeaving|AVG(DAY)',
				isChart: true,
				properties: {
					CondenserWaterTemperatureLeaving: {
						type: 'polyline',
						numberMarks: 8,
						colorValue: '#97564F',
						propertyNameFunc: function(data) {
							return 'CONDENSER_LVG';
						},
						additionalLines: [
							{
								name: 'Cond Avg.',
								label: function(data) {
									if (data && data.y1 && typeof data.y1.toFixed === 'function') {
										return {
											name: 'COND_AVG',
											value: data.y1.toFixed(2),
										};
									} else {
										return null;
									}
								},
								type: 'line',
								data: function(data) {
									const avg = data.reduce(
										(accumulator, currentValue, index, array) => {
											let accumulatorVal = accumulator;
											if (currentValue.y) {
												Object.assign(accumulatorVal, {
													val: accumulatorVal.val + currentValue.y,
													count: accumulatorVal.count + 1,
												});
											}
											if (index === array.length - 1) {
												return accumulatorVal.val / accumulatorVal.count;
											}
											return accumulatorVal;
										},
										{val: 0, count: 0}
									);
									return {
										x1: data[0].x,
										y1: avg,
										x2: data[data.length - 1].x,
										y2: avg,
									};
								},
							},
						],
					},
					ChilledWaterTemperatureLeaving: {
						type: 'polyline',
						numberMarks: 8,
						colorValue: '#57706A',
						propertyNameFunc: function(data) {
							return 'EVAPORATOR_LVG';
						},
						additionalLines: [
							{
								name: 'Evap Avg.',
								label: function(data) {
									if (data && data.y1 && typeof data.y1.toFixed === 'function') {
										return {
											name: 'EVAP_AVG',
											value: data.y1.toFixed(2),
										};
									} else {
										return null;
									}
								},
								type: 'line',
								data: function(data) {
									const avg = data.reduce(
										(accumulator, currentValue, index, array) => {
											let accumulatorVal = accumulator;
											if (currentValue.y) {
												Object.assign(accumulatorVal, {
													val: accumulatorVal.val + currentValue.y,
													count: accumulatorVal.count + 1,
												});
											}
											if (index === array.length - 1) {
												return accumulatorVal.val / accumulatorVal.count;
											}
											return accumulatorVal;
										},
										{val: 0, count: 0}
									);
									return {
										x1: data[0].x,
										y1: avg,
										x2: data[data.length - 1].x,
										y2: avg,
									};
								},
							},
						],
					},
				},
				others: {
					range: {
						number: 1,
						measure: 'month',
						from: function(fromDate, toDate) {
							return moment(toDate)
								.add(-(this.number - 1), this.measure)
								.startOf(this.measure);
						},
						to: function(fromDate, toDate) {
							return moment(toDate);
						},
					},
					xTicsGenerator: function*(minDate, maxDate) {
						for (
							let x = 0, tickDate = moment(minDate).add(x, 'day');
							tickDate.isSameOrBefore(maxDate);
							x = x + 6, tickDate = moment(minDate).add(x, 'day')
						) {
							let formattedXTickValues = getFormattedXTickValues(minDate.tz(), tickDate);
							yield formattedXTickValues.toDate();
						}
					},
					yTicsGenerator: function*(minValue, maxValue, ticsCount) {
						let diff = maxValue - minValue;
						for (let i = 0; i <= ticsCount; i++) {
							yield minValue + diff / ticsCount * i;
						}
					},
					yTicsCount: 8,
					xTicFormat: timeFormat('%b %d'),
					interval: 'PT15m',
				},
				imageSource: '/images/cpr/new-perf-water-temp-img.png',
				infoText: 'PERFORMANCE_WATER_INFO_TEXT',
				dialogWidth: '781px',
				imageWidth: '713px',
				imageHeight: '320px',
				height: 163,
				divideSectionPerPages: function(options) {
					this.divideChartPerPages(options);
				},
			});

			config[CPR_REPORT_SECTIONS.PERFORMANCE_ASSESSMENT] = new ChillerPerformanceReportSectionConfig({
				orderInPopup: 12,
				isChecked: true,
				hpath:
					'@CompressorRunning,' +
					'@ChillerCurrentEnteringLimitSetpointActive,@ChillerLoadResult,@ChillerCurrentEnteringDrawRla,' +
					'@Power,@ChillerEfficiencyActive,@ChilledWaterTemperatureSetpointActive,@ChilledWaterTemperatureLeaving,' +
					'@ChilledWaterTemperatureEntering,@ChilledWaterTemperatureDeltaT,//child::Circuit@EvaporatorApproachTempResult,' +
					'@CondenserWaterTemperatureLeaving,@CondenserWaterTemperatureEntering,@CondenserWaterTemperatureDeltaT,' +
					'//child::Circuit@CondenserApproachTempResult,//child::Compressor@OilTemperatureCompressor,' +
					'//child::Compressor@OilPressureDifferential,//child::Circuit@CondenserSatRfgtTempCommResult,' +
					'//child::Compressor@CompressorDischargeRefrigerantTemperature,' +
					'//child::Circuit@EvaporatorSatRfgtTempCommResult,//child::Compressor@CompressorCurrentDrawLine1Normalized,' +
					'//child::Compressor@CompressorCurrentDrawLine2Normalized,//child::Compressor@CompressorCurrentDrawLine3Normalized,' +
					'//child::Compressor@CompressorDriveMotorCurrentUPercentRla,//child::Compressor@CompressorDriveMotorCurrentVPercentRla,' +
					'//child::Compressor@CompressorDriveMotorCurrentWPercentRla,' +
					'//child::Compressor@CompressorVoltagePhaseA_B,//child::Compressor@CompressorVoltagePhaseB_C,' +
					'//child::Compressor@CompressorVoltagePhaseC_A,//child::Circuit@PurgeLiquidTemperature,' +
					'//child::Circuit@PurgePumpoutRateLife,//child::Circuit@PurgeDailyPumpoutRate,' +
					'//child::Circuit@PurgePumpoutRateChillerOn7Days,//child::Circuit@PurgePumpoutRateChillerOff7Days',
				enumerations: '@CompressorRunning=compRunning/compRunningDisplay',
				properties: {
					CompressorRunning: {
						skipInLowAvgPeakCalculation: true,
						formatData(value) {
							const YES = 'Yes';
							const NO = 'No';

							return [YES, NO].includes(value) ? 1 : value;
						},
						calculateValueForBin(infoValues) {
							const PARTS_PER_HOUR = 4;
							const sum = infoValues.reduce((a, b) => a + b, 0);

							return sum / PARTS_PER_HOUR;
						},
						customUom: {symbol: 'Hrs'},
						customName: 'CPR_LABELS.OPERATION_HOURS',
					},
					ChillerCurrentEnteringLimitSetpointActive: {},
					ChillerLoadResult: {},
					ChillerCurrentEnteringDrawRla: {},
					Power: {},
					ChillerEfficiencyActive: {
						getDesignValue({propertyName, result}) {
							const Power = result['Power'].designValue;
							const ChillerLoadResult = result['ChillerLoadResult'].designValue;
							return helpers.isNumber(Power) && helpers.isNumber(ChillerLoadResult) ? Power / ChillerLoadResult : NA_DESIGN_VALUE;
						},
					},
					ChilledWaterTemperatureSetpointActive: {},
					ChilledWaterTemperatureLeaving: {},
					ChilledWaterTemperatureEntering: {},
					ChilledWaterTemperatureDeltaT: {
						getDesignValue({propertyName, result}) {
							const suffix = helpers.getSuffix('ChilledWaterTemperatureDeltaT', propertyName);
							const entering = result['ChilledWaterTemperatureEntering' + suffix].designValue;
							const leaving = result['ChilledWaterTemperatureLeaving' + suffix].designValue;
							return helpers.isNumber(entering) && helpers.isNumber(leaving) ? entering - leaving : NA_DESIGN_VALUE;
						},
					},
					EvaporatorApproachTempResult: {},
					CondenserWaterTemperatureLeaving: {},
					CondenserWaterTemperatureEntering: {},
					CondenserWaterTemperatureDeltaT: {
						getDesignValue({propertyName, result}) {
							const suffix = helpers.getSuffix('CondenserWaterTemperatureDeltaT', propertyName);
							const entering = result['CondenserWaterTemperatureEntering' + suffix].designValue;
							const leaving = result['CondenserWaterTemperatureLeaving' + suffix].designValue;
							return helpers.isNumber(entering) && helpers.isNumber(leaving) ? leaving - entering : NA_DESIGN_VALUE;
						},
					},
					CondenserApproachTempResult: {},
					OilTemperatureCompressor: {},
					OilPressureDifferential: {},
					CondenserSatRfgtTempCommResult: {},
					EvaporatorSatRfgtTempCommResult: {},
					CompressorCurrentDrawLine1Normalized: {},
					CompressorCurrentDrawLine2Normalized: {},
					CompressorCurrentDrawLine3Normalized: {},
					CompressorDriveMotorCurrentUPercentRla: {},
					CompressorDriveMotorCurrentVPercentRla: {},
					CompressorDriveMotorCurrentWPercentRla: {},
					// prettier-ignore
					'CompressorVoltagePhaseA_B': {},
					// prettier-ignore
					'CompressorVoltagePhaseB_C': {
						getDesignValue({propertyName, result}) {
							const suffix = helpers.getSuffix('CompressorVoltagePhaseB_C', propertyName);
							return result['CompressorVoltagePhaseA_B' + suffix]
								? result['CompressorVoltagePhaseA_B' + suffix].designValue
								: DEFAULT_DESIGN_VALUE;
						}
					},
					// prettier-ignore
					'CompressorVoltagePhaseC_A': {
						getDesignValue({propertyName, result}) {
							const suffix = helpers.getSuffix('CompressorVoltagePhaseC_A', propertyName);
							return result['CompressorVoltagePhaseA_B' + suffix]
								? result['CompressorVoltagePhaseA_B' + suffix].designValue
								: DEFAULT_DESIGN_VALUE;
						}
					},
					PurgeLiquidTemperature: {},
					PurgePumpoutRateLife: {},
					PurgeDailyPumpoutRate: {},
					PurgePumpoutRateChillerOn7Days: {},
					PurgePumpoutRateChillerOff7Days: {},
				},
				others: {
					range: {
						number: 1,
						measure: 'month',
					},
					interval: 'PT15m',
					// This function is used when need to calculate new property from existing properties.
					postProcessData(processedData = {}) {
						Object.keys(processedData).forEach(section => {
							const data = processedData[section].data && processedData[section].data.filter(item => item.value);
							if (!data || !data.length) {
								delete processedData[section];
							}
						});
						const {CompressorRunning} = processedData;

						if (!CompressorRunning) return processedData;

						const CompressorRunningProportion = angular.copy({...CompressorRunning, data: null});

						const sumBins = CompressorRunningProportion.bins.reduce((sum, value) => (sum += value || 0), 0);

						// Map hours to proportion.
						CompressorRunningProportion.bins = CompressorRunningProportion.bins.map(binValue => (binValue ? binValue / sumBins * 100 : binValue));

						CompressorRunningProportion.unitOfMeasure = {symbol: '%'};
						CompressorRunningProportion.propertyName = $translate('CPR_LABELS.OPERATION_HOURS_PROPORTION');

						// Return processed data in such way in order to achieve the right order.
						return {
							CompressorRunning,
							CompressorRunningProportion,
							...processedData,
						};
					},
				},
				imageSource: '/images/cpr/new-perf-assess-img.png',
				dialogWidth: '992px',
				imageWidth: '925px',
				imageHeight: '320px',
				infoText: 'PERFORMANCE_ASSESSMENT_INFO_TEXT',
				headerHeight: 80,
				rowHeight: 20,
				noDataHeight: 80,
				divideSectionPerPages: function({MAX_MAIN_CONTENT_HEIGHT, page, section, reportSectionsData, createNewPage, updatePage}) {
					const remainingHeight = MAX_MAIN_CONTENT_HEIGHT - page.height;
					const {headerHeight, rowHeight, noDataHeight} = this;

					const dividePerPages = ({isNewPage = true}) => {
						if (Object.keys(reportSectionsData[section]).length === 0) {
							const actualHeight = headerHeight + rowHeight * 2 + noDataHeight;

							page = remainingHeight < actualHeight ? createNewPage() : page;

							updatePage(page, {
								height: actualHeight,
								section: `${CPR_REPORT_SECTIONS.PERFORMANCE_ASSESSMENT}_${0}`,
							});
						} else {
							for (let i = 0; Object.keys(reportSectionsData[section]).length !== 0; i++) {
								page = !i && !isNewPage ? page : createNewPage();

								const allowedHeight = (isNewPage ? MAX_MAIN_CONTENT_HEIGHT : remainingHeight) - (headerHeight + rowHeight * 2);
								const allowedRowsNumber = Math.floor(allowedHeight / rowHeight);

								if (!allowedRowsNumber) {
									isNewPage = true;
									continue;
								}

								const sectionName = `${CPR_REPORT_SECTIONS.PERFORMANCE_ASSESSMENT}_${i}`;
								const properties = Object.keys(reportSectionsData[section]).filter((_, index) => index < allowedRowsNumber);

								reportSectionsData[sectionName] = Object.keys(reportSectionsData[section]).reduce((obj, property) => {
									if (properties.includes(property)) {
										obj[property] = reportSectionsData[section][property];
										delete reportSectionsData[section][property];
									}

									return obj;
								}, {});

								updatePage(page, {
									height: headerHeight + properties.length * rowHeight,
									section: sectionName,
								});

								isNewPage = true;
							}
						}
					};

					remainingHeight < headerHeight + rowHeight * 2 ? dividePerPages({isNewPage: true}) : dividePerPages({isNewPage: false});
					return page;
				},
			});

			config[CPR_REPORT_SECTIONS.PLANT_LOAD_ANALYSIS] = new ChillerPerformanceReportSectionConfig({
				isEditable: true,
				isChecked: true,
				orderInPopup: 6,
				hpath: 'parent::CP@LoadNormalized|MAX(HOUR), WeatherSourceV2::@Temperature',
				properties: {
					LoadNormalized: {},
					Temperature: {
						postPropertyProcessFunc: function(column) {
							const {data = []} = column;
							const mappedData = data.reduce((result, item) => {
								const key = `${item.x.format(CPR_DATE_FORMATS.MEDIUM_DAY_FORMAT)}_${item.x.format(CPR_DATE_FORMATS.SHORT_DAY_FORMAT)}`;
								const {min, max} = result[key] || {};
								result[key] = {
									min: min === undefined ? item.y : Math.min(min, item.y),
									max: max === undefined ? item.y : Math.max(max, item.y),
								};
								return result;
							}, {});
							for (const key in mappedData) {
								const {min, max} = mappedData[key];
								mappedData[key].coolingDegreeDays = Math.max(Math.round((min + max) / 2 - 65), 0);
							}
							column.data = mappedData;
						},
						propertiesInfoMap: {
							propertyAttribute: {
								resolution: 0.01,
							},
						},
						additionalXLine: true,
						title: () => $translate('CPR_SETTINGS.COOLING_DEGREE_DAYS.TITLE'),
						titleKey: '24:00',
					},
				},
				others: {
					range: {
						number: 1,
						measure: 'month',
						from: function(fromDate, toDate) {
							return moment(toDate)
								.add(-(this.number - 1), this.measure)
								.startOf(this.measure);
						},
						to: function(fromDate, toDate) {
							return moment(toDate);
						},
					},
					interval: 'PT15M',
				},
				imageSource: '/images/cpr/new-plant-load-analysis-img.png',
				dialogWidth: '992px',
				imageWidth: '925px',
				imageHeight: '320px',
				infoText: 'PLANT_LOAD_ANALYSIS_INFO_TEXT',
				height: 295,
				divideSectionPerPages: function(options) {
					const {axes = {}, isFailed = false} = options.reportSectionsData[CPR_REPORT_SECTIONS.PLANT_LOAD_ANALYSIS];
					if (!Object.keys(axes).length && !isFailed) return;

					this.commonDivider(options);
				},
			});

			config[CPR_REPORT_SECTIONS.DIAGNOSTIC_CONDITIONS] = new ChillerPerformanceReportSectionConfig({
				orderInPopup: 14,
				isChecked: true,
				hpath: '@DiagnosticCondition',
				enumerations: '@DiagnosticCondition=diagnosticCondition/diagnosticConditionDisplay',
				properties: {
					DiagnosticCondition: {},
				},
				others: {
					range: {
						number: 2,
						measure: 'month',
						from: function(fromDate, toDate) {
							return moment(toDate)
								.add(-(this.number - 1), this.measure)
								.startOf(this.measure);
						},
						to: function(fromDate, toDate) {
							return moment(toDate);
						},
					},
					interval: 'PT15M',
				},
				imageSource: '/images/cpr/new-diag-cond-img.png',
				dialogWidth: '992px',
				imageWidth: '925px',
				imageHeight: '320px',
				infoText: 'DIAGNOSTIC_CONDITIONS_INFO_TEXT',
				minHeight: 120,
				calculateHeight: function({currentMonthData: {data = {}} = {}, isFailed = false}) {
					if (isFailed) {
						return this.minHeight;
					} else {
						const rowsNumber = Object.keys(data).length;

						return this.minHeight + rowsNumber * this.rowHeight;
					}
				},
			});
			config[CPR_REPORT_SECTIONS.LOAD_PROFILE] = new ChillerPerformanceReportSectionConfig({
				orderInPopup: 11,
				isChecked: true,
				hpath: '@ChillerCurrentEnteringDrawRla',
				isChart: true,
				properties: {
					ChillerCurrentEnteringDrawRlaPreviousMonth: {
						step: 5,
						propertyName: 'ChillerCurrentEnteringDrawRla',
						title: 'PREVIOUS_MONTH',
						type: 'bars',
						colorValue: '#F26722',
						dataFilter: function(data, from, to) {
							const fromDate = moment(from)
								.add(1, 'year')
								.add(-1, 'month');
							const toDate = moment(fromDate).endOf('month');
							return getDataDistribution(data, this.step, fromDate, toDate);
						},
						postPropertyProcessFunc: function({unitOfMeasure = {}}) {
							unitOfMeasure.symbol = $translate('PROPORTION_OF_TIME_PERCENT');
						},
						minVal: 0,
						maxVal: 100,
						numberMarks: 5,
						unitOfMeasureX: {
							noTics: 1,
							orient: 'bottom',
							name: 'percent',
							symbol: '% RLA',
							from: -5,
							to: 105,
							ticsFrom: 0,
							ticsTo: 100,
						},
						ticFormat: d => {
							return `${d}`;
						},
					},
					ChillerCurrentEnteringDrawRlaCurrMonth: {
						step: 5,
						propertyName: 'ChillerCurrentEnteringDrawRla',
						title: 'CURRENT_MONTH',
						type: 'bars',
						colorValue: '#97564F',
						dataFilter: function(data, from, to) {
							const fromDate = moment(from).add(1, 'year');
							const toDate = moment(fromDate).endOf('month');
							return getDataDistribution(data, this.step, fromDate, toDate);
						},
						postPropertyProcessFunc: function({unitOfMeasure = {}}) {
							unitOfMeasure.symbol = $translate('PROPORTION_OF_TIME_PERCENT');
						},
						minVal: 0,
						maxVal: 100,
						numberMarks: 5,
						unitOfMeasureX: {
							noTics: 1,
							orient: 'bottom',
							name: 'percent',
							symbol: '% RLA',
							from: -5,
							to: 105,
							ticsFrom: 0,
							ticsTo: 100,
							tickSize: 0,
						},
						ticFormat: d => {
							return `${d}`;
						},
					},
				},
				others: {
					range: {
						number: 2,
						measure: 'month',
						from: function(fromDate, toDate) {
							return moment(toDate)
								.add(-1, 'year')
								.startOf(this.measure);
						},
						to: function(fromDate, toDate) {
							return moment(toDate);
						},
					},
					xTicsGenerator: function*(minValue, maxValue, ticsCount) {
						let diff = maxValue - minValue;
						for (let i = 0; i <= ticsCount; i++) {
							yield minValue + diff / ticsCount * i;
						}
					},
					yTicsGenerator: function*(minValue, maxValue, ticsCount) {
						let diff = maxValue - minValue;
						for (let i = 0; i <= ticsCount; i++) {
							yield minValue + diff / ticsCount * i;
						}
					},
					yTicsCount: 8,
					xTicFormat: d => d,
					interval: 'PT15M',
				},
				imageSource: 'images/cpr/new-load-prof-img.png',
				dialogWidth: '781px',
				imageWidth: '713px',
				imageHeight: '320px',
				infoText: 'LOAD_PROFILE_INFO_TEXT',
				height: 163,
				divideSectionPerPages: function(options) {
					this.divideChartPerPages(options);
				},
			});

			config[CPR_REPORT_SECTIONS.OPERATING_MODES] = new ChillerPerformanceReportSectionConfig({
				orderInPopup: 13,
				isChecked: true,
				hpath: '@ChillerOperatingModeNormalized',
				properties: {
					ChillerOperatingModeNormalized: {},
				},
				others: {
					range: {
						number: 2,
						measure: 'month',
						from: function(fromDate, toDate) {
							return moment(toDate)
								.add(-(this.number - 1), this.measure)
								.startOf(this.measure);
						},
						to: function(fromDate, toDate) {
							return moment(toDate);
						},
					},
					interval: 'PT15M',
				},
				imageSource: '/images/cpr/new-operating-mode-img.png',
				dialogWidth: '992px',
				imageWidth: '925px',
				imageHeight: '320px',
				infoText: 'OPERATING_MODES_INFO_TEXT',
				minHeight: 120,
				calculateHeight: function({currentMonthData: {data = {}} = {}, isFailed = false}) {
					if (isFailed) {
						return this.minHeight;
					} else {
						const rowsNumber = Object.keys(data).length;

						return this.minHeight + rowsNumber * this.rowHeight;
					}
				},
			});

			config[CPR_REPORT_SECTIONS.SHUTDOWNS] = new ChillerPerformanceReportSectionConfig({
				orderInPopup: 15,
				isChecked: true,
				hpath: '@DiagnosticManualResetRequiredNormalized,@DiagnosticAutomaticResetNormalized',
				properties: {
					DiagnosticManualResetRequiredNormalized: {
						translation: 'NUMBER_OF_SHUTDOWNS_DUE_TO_MANUAL_RESET_DIAGNOSTICS',
					},
					DiagnosticAutomaticResetNormalized: {
						translation: 'NUMBER_OF_SHUTDOWNS_DUE_TO_AUTOMATIC_RESET_DIAGNOSTICS',
					},
				},
				others: {
					range: {
						number: 2,
						measure: 'month',
						from: function(fromDate, toDate) {
							return moment(toDate)
								.add(-(this.number - 1), this.measure)
								.startOf(this.measure);
						},
						to: function(fromDate, toDate) {
							return moment(toDate);
						},
					},
					interval: 'PT15M',
					additionalNames: {
						numbersColumnName: 'NUMBER_OF_SHUTDOWNS',
					},
					additionalHandlers: {
						getCorrectValue: value => value || '--',
					},
					additionalStyles: {
						numbersRowBackgroundColor: '#FFFFFF',
					},
				},
				dialogWidth: '992px',
				imageWidth: '925px',
				imageHeight: '320px',
				imageSource: '/images/cpr/new-shutdowns-img.png',
				infoText: 'SHUTDOWNS_INFO_TEXT',
				minHeight: 120,
				calculateHeight: function({currentMonthData: {data = {}} = {}, isFailed = false}) {
					if (isFailed) {
						return this.minHeight;
					} else {
						const rowsNumber = Object.keys(data).length;

						return this.minHeight + rowsNumber * this.rowHeight;
					}
				},
			});

			return config;
		});

	function removeRedundantData(checkedPropertyName, processedData) {
		const {properties} = this;
		const getDateStringWithoutTime = dateString => dateString && dateString.split('T')[0];

		let data = processedData[checkedPropertyName];

		// For Multiple circuit
		// if checkedPropertyName property is not available, use the alternate property related to checkedPropertyName to compare data
		// For example : ChillerCurrentEnteringDrawRla will not be available, So use alternate properties that used to get RLA data i.e CircuitCurrentDrawRLACalc
		if (!data && processedData) {
			const matchedPropertyName = Object.keys(processedData || {}).filter(propName => {
				const value = processedData[propName];

				if (!value) return false;

				if (!Array.isArray(value) && value) {
					return !!(value.alternateRlaPropertyOf === checkedPropertyName);
				}

				return value.some((prop = {}) => !!(prop.alternateRlaPropertyOf === checkedPropertyName));
			});

			checkedPropertyName = matchedPropertyName[0] || checkedPropertyName;
		}

		data = processedData[checkedPropertyName];

		if (!data) {
			return processedData;
		}

		// If there are no values related to [checkedPropertyName] for some days,
		// do not show values for other properties for these days.
		let validDates = {};

		if (Array.isArray(data)) {
			validDates = data.reduce((av, cv) => {
				const validDate = cv.data.map(({dateString}) => getDateStringWithoutTime(dateString));
				av[cv.instance || 'CKT'] = validDate;

				return av;
			}, {});
		} else {
			validDates = {
				// By default, circuit instance name is CKT1
				CKT1: data.data.map(({dateString}) => getDateStringWithoutTime(dateString)),
			};
		}

		Object.keys(properties)
			.filter(property => property !== checkedPropertyName)
			.forEach(
				property =>
					processedData[property] &&
					(Array.isArray(processedData[property]) ? processedData[property] : [processedData[property]]).forEach(
						processedDataItem =>
							Array.isArray(processedDataItem.data) &&
							(processedDataItem.data = processedDataItem.data.filter(({dateString}) =>
								(validDates[processedDataItem.instance || 'CKT'] || []).includes(getDateStringWithoutTime(dateString))
							))
					)
			);

		return processedData;
	}

	function getDataDistribution(data, step, fromDate, toDate) {
		const percentData = data.reduce(
			(accumulator, currentValue) => {
				if (currentValue.x.isSameOrAfter(fromDate) && currentValue.x.isSameOrBefore(toDate)) {
					const key = Math.trunc(currentValue.y / step) * step;
					accumulator.data[key] = typeof accumulator.data[key] === 'undefined' ? 1 : accumulator.data[key] + 1;
					accumulator.count = accumulator.count + 1;
				}
				return accumulator;
			},
			{data: {}, count: 0}
		);

		const resultData = [];
		Object.keys(percentData.data).forEach(key => {
			resultData.push({x: parseInt(key), y: percentData.data[key] / percentData.count * 100});
		});
		return resultData;
	}
})();
