import {USER_EVENTS} from '../../common/usage-tracking/categories';
import * as R from 'ramda';
import _get from 'lodash.get';

const {
	REPORT_GENERATION: {events: REPORT_GENERATION_EVENTS, categoryName: REPORT_GENERATION_CATEGORY_NAME, properties: REPORT_GENERATION_PROPERTIES},
} = USER_EVENTS;

angular.module('TISCC').controller('ReportDialogCtrl', ReportDialogCtrl);

function ReportDialogCtrl(
	$scope,
	$modalInstance,
	data,
	$filter,
	$rootScope,
	helpers,
	locationDetailsService,
	usageTrackingService,
	REPORT_TYPES,
	configService,
	ENVIRONMENT
) {
	$scope.REPORT_TYPES = REPORT_TYPES;
	$scope.featureToggles = configService.getFeatureToggles();
	$scope.environment = configService.getEnvironmentType();
	$scope.isProdEnv = $scope.environment === ENVIRONMENT.PROD;
	// Disable if DI Feature Toggle is false
	$scope.REPORT_TYPES['DIGITAL_INSPECTION'].showInReportListDropDown = $scope.featureToggles.digitalInspectionReport;
	$scope.reportList = Object.values(REPORT_TYPES).filter(({showInReportListDropDown}) => showInReportListDropDown);
	$scope.data = data;
	$scope.limitToOneReportOnly = data.limitToOneReportOnly;
	$scope.switchReport = switchReport;
	$scope.generateReport = generateReport;
	$scope.setDisableGenerateButton = setDisableGenerateButton;
	$scope.setGlobalDateRange = setGlobalDateRange;
	$scope.checkIfReportIsDisabledByLocation = checkIfReportIsDisabledByLocation;
	$scope.trackGenerateReportEvent = trackGenerateReportEvent;
	$scope.onApply = $rootScope.$broadcast.bind($rootScope, 'dialogApplyClicked');
	$scope.cancel = cancel;
	$scope.getReportFile = getReportFile;
	$scope.loader = false;
	$scope.exportComplete = false;
	$scope.showConfirmationMessage = false;
	$scope.isApplyButtonEnabled = false;
	$scope.controls = {
		disableGenerateButton: true,
	};
	applyRoleLimitations();
	switchReport($scope.reportList.find(({report}) => report === $scope.data.report), '', {});

	function getReportFile() {
		const report = $scope.activeReport.directory;
		const reportFile = _get($scope.activeReport, 'serverless', []).includes($scope.environment) ? '-serverless' : '';
		return `components/reports/${report}/${report}${reportFile}.html`;
	}

	function cancel() {
		$rootScope.$broadcast('dialogCancelClicked');
		$modalInstance.dismiss('cancel');

		// Reset selected global Date range, if only dialog opened to generate report for RDR / EHR
		const isDialogOpenedForReports = $scope.reportList.some(({report}) => report === $scope.data.report);

		if (isDialogOpenedForReports) {
			$rootScope.globalDateSelectionRange = {};
		}

		if (data && typeof data.onClose === 'function') {
			data.onClose();
		}
	}

	function switchReport(report, rangeMode, range) {
		if (report.disabled) {
			$scope.errorMessage = $filter('translate')('DISABLED_REPORT_MESSAGE');
			return;
		}
		if (checkIfReportIsDisabledByLocation(report)) {
			$scope.errorMessage = $filter('translate')('NO_EQUIPMENT_FOR_FACILITY');
			return;
		}
		$scope.activeReport = report;
		$scope.globalRangeMode = rangeMode;
		$scope.globalRange = range;
		$scope.selectedEquipmentType = {name: $filter('translate')('OPTIONS_SELECT_ONE'), initValue: true};
	}

	function generateReport() {
		$scope.loader = true;
		$scope.exportComplete = false;
		$scope.$broadcast('generateReport');
	}

	function applyRoleLimitations() {
		if ($scope.limitToOneReportOnly) {
			$scope.cancel = function() {};
		}
	}

	function setDisableGenerateButton(value) {
		$scope.controls.disableGenerateButton = !!value;
	}

	function setGlobalDateRange(key, value) {
		$rootScope.globalDateSelectionRange[key] = value;
	}

	function checkIfReportIsDisabledByLocation(reportItem) {
		const disableCprItemIfNotAllowed =
			reportItem.report === REPORT_TYPES.REPORT_CHILLER_PERFORMANCE_ENGLISH.report && !locationDetailsService.isCprAllowed($scope.data.locationData);
		const disableDirItemIfNotAllowed =
			reportItem.report === REPORT_TYPES.DIGITAL_INSPECTION.report && !locationDetailsService.isDirAllowed($scope.data.locationData);
		const disableReportItemIfNoFacilityId =
			[REPORT_TYPES.INTERNAL_INVENTORY.report, REPORT_TYPES.INVENTORY.report, REPORT_TYPES.ALARMS_AND_EVENTS.report].includes(reportItem.report) &&
			helpers.getPropertyByPath($scope, 'data.locationData.facilityId') <= 0;
		const disableReportItemIfOfferingExpired =
			reportItem.report === REPORT_TYPES.EXCEPTION_HISTORY.report && !locationDetailsService.isOfferingExpired($scope.data.locationData);
		return disableCprItemIfNotAllowed || disableReportItemIfNoFacilityId || disableReportItemIfOfferingExpired || disableDirItemIfNotAllowed;
	}

	function trackGenerateReportEvent(options = {}) {
		usageTrackingService.trackEvent(REPORT_GENERATION_CATEGORY_NAME, REPORT_GENERATION_EVENTS.GENERATE_REPORT, {
			[REPORT_GENERATION_PROPERTIES.BUILDING_ID]: $scope.data.locationData.locationId,
			[REPORT_GENERATION_PROPERTIES.BUILDING_SALES_OFFICE]: $scope.data.locationData.salesOffice.officeCode,
			[REPORT_GENERATION_PROPERTIES.BUILDING_OFFERINGS]: ($scope.data.locationData.offeringSourceMaps || []).map(({shortName}) => shortName),
			...options,
		});
	}

	$scope.toggleLegend = function(visibility) {
		$scope.showLegend = visibility;
	};

	$scope.$on('hideLoader', function() {
		$scope.loader = false;
	});

	$scope.$on('generateComplete', function() {
		$scope.exportComplete = true;
	});

	$scope.$on('resetDownloadLink', function() {
		let link = document.getElementById('download-file');
		link.href = '';
		link.setAttribute('download', '');
	});

	$scope.$on('showConfirmationMessage', function(event, message) {
		if (message) {
			$scope.$evalAsync(() => {
				$scope.showConfirmationMessage = true;
				$scope.confirmationMessage = message;
			});
		}
	});

	$scope.$on('hideConfirmationMessage', function() {
		$scope.showConfirmationMessage = false;
	});

	$scope.$on('applyButtonUpdate', function(_, {isApplyButtonEnabled, isApplyFeedbackVisible}) {
		$scope.isApplyButtonEnabled = isApplyButtonEnabled;
		$scope.isApplyFeedbackVisible = isApplyFeedbackVisible;
	});

	$scope.$on('updateIsCellLimitExceeded', function(event, {isCellLimitExceeded}) {
		$scope.isCellLimitExceeded = isCellLimitExceeded;
	});
}
