(function() {
	class ParetoSortOrderDropdownController {
		constructor() {
			this.sortOrderMap = {
				highToLow: {
					title: 'PARETO_HIGH_TO_LOW',
				},
				lowToHigh: {
					title: 'PARETO_LOW_TO_HIGH',
				},
				equipmentByDate: {
					title: 'PARETO_EQUIPMENT_BY_DATE',
					isDisabled: false,
				},
			};
		}

		updateDrpDwnPosition(event) {
			let parentNode = event.currentTarget.parentNode;
			let drpMenuNode = parentNode.querySelector('.dropdown-menu');

			drpMenuNode.style.display = 'block';

			let shift = parentNode.offsetLeft + parentNode.offsetWidth - drpMenuNode.offsetWidth;
			drpMenuNode.style.right = shift < 0 ? shift + 'px' : '';
			drpMenuNode.style.display = '';
		}

		setSortOrderHandler(sortOrder) {
			this.setSortOrder({sortOrder});
		}
	}

	angular.module('TISCC').component('paretoSortOrderDropdown', {
		templateUrl: 'components/pareto-sort-order-dropdown/pareto-sort-order-dropdown.html',
		controller: ParetoSortOrderDropdownController,
		bindings: {
			itemTitle: '@',
			selectedSortOrder: '<',
			setSortOrder: '&',
		},
	});
})();
