(function(d3, AbstractChartRenderer) {
	const RED_COLOR = '#d31d2e';
	const GREEN_COLOR = '#00b816';
	const PERFORMANCE_CURVE = 'performanceCurve';
	const BOTTOM_LABEL_OFFSET = 30;
	const PROPERTY_VALUE_DEVIATION = 0.25;
	const PROPERTY_VALUE_VERTICAL_PADDING = 0.5;
	const PROPERTY_VALUE_HORIZONTAL_PADDING = 0.05;
	const MULTIPOINT_CHART_TYPE = 'scatterMultipoints';

	const {CIRCLE_RADIUS, CIRCLE_RADIUS_HOVERED, calculateGroups} = AbstractChartRenderer;

	class ScatterChartRenderer extends AbstractChartRenderer {
		constructor(svg, externalMethods, clipPathId, x) {
			super(externalMethods.tooltip, externalMethods.translate);
			this.gridNode = svg.append('g').attr('class', 'grid');
			this.minorGrid = svg.append('g').attr('class', 'grid');
			this.yAxes = [];
			this.extraDataFormatting = externalMethods.extraDataFormatting;
			this.chartId = clipPathId;
			this.subType = 'scatter';
			this.CHART_TYPE = externalMethods.CHART_TYPE;
			this.CHART_LABEL_OFFSET = {left: -2, right: 2, top: -2, bottom: 11};
			this.dataNode = svg.append('g');
			this.x = x;
			this.scatterXAxis = null;
			this.topDamper = null;
			this.bottomDamper = null;
			this.leftLabel = svg.append('g').append('text');
			this.bottomLabel = svg.append('g').append('text');
			this.groupsToCalculate = [];
			this.groupMap = new WeakMap();

			this.CHART_TYPE = externalMethods.CHART_TYPE;
			this.LINE_TYPE = externalMethods.LINE_TYPE;
			this.LINE_THICKNESS = externalMethods.LINE_THICKNESS;
			this.MARKER_TYPE = externalMethods.MARKER_TYPE;
			this.MARKER_SIZE = externalMethods.MARKER_SIZE;
			this.CHART_LABEL_OFFSET = {
				left: -2,
				right: 2,
				top: -2,
				bottom: 11,
			};

			addAxes.call(this, svg);
		}

		checkData(chartData, timelineData, lines, lanes) {
			this.commonRenderer.noData = !this.commonRenderer.checkIfDataToDrawChartExist(chartData, timelineData, lines, lanes);

			if (!this.commonRenderer.noData) {
				const checkIfChartDataExists = chartData.some(function(element) {
					return (
						element &&
						element.length &&
						element.some(function(element) {
							return element.value !== null;
						})
					);
				});
				this.commonRenderer.noData = !(timelineData.length !== 0 || checkIfChartDataExists);
			}
		}

		onSvgReceivedSize(updateData) {
			this.width = updateData.width;
			this.height = updateData.height;
			this.chartHeight = updateData.chartHeight;
		}

		onSvgSizeUpdate(updateData) {
			this.onSvgReceivedSize(updateData);
			this.updateAxesHeight(updateData.width, updateData.chartHeight);
			this.toggleChartLine(this.chartOptions, this.chartData);
		}

		toggleChartLine(chartOptions, chartData) {
			this.dataNode.selectAll('*').remove();
			this.drawData(chartOptions, chartData);
		}

		updateAxisLabel() {
			this.scatterXAxis.node.attr('transform', 'translate(0,' + this.chartHeight + ')');

			if (this.scatterXAxis.visible) {
				this.scatterXAxis.node
					.attr('display', '')
					.call(
						this.scatterXAxis.axis.tickSize(0, 0, 0).tickFormat(function(d) {
							return d;
						})
					)
					.selectAll('text')
					.attr('dy', this.CHART_LABEL_OFFSET.bottom);
			} else {
				this.scatterXAxis.node.attr('display', 'none');
			}

			this.yAxes.forEach(yAxis => {
				let uomLabelOffset = yAxis.orient === 'left' ? -30 : this.width + 30;
				let rotateDegree = yAxis.orient === 'left' ? -90 : 90;
				if (yAxis.orient === 'right') {
					yAxis.node.attr('transform', 'translate(' + this.width + ',0)');
				}
				yAxis.uomLabel
					.attr('transform', `translate(${uomLabelOffset},${this.chartHeight / 2}) rotate(${rotateDegree})`)
					.attr('class', 'axis-label')
					.text(yAxis.uomSymbol);
			});
			this.bottomLabel
				.attr('transform', 'translate(' + this.width / 2 + ',' + this.chartHeight + ')')
				.attr('class', 'axis-label')
				.attr('dy', BOTTOM_LABEL_OFFSET)
				.text(this.scatterXAxis.uomSymbol);
		}

		updateYAxes() {
			let that = this;
			let ticks;
			let defaultDisplayLabel = function(resolution, isPercentageAxis, d) {
				return isPercentageAxis ? d : that.extraDataFormatting.applyDecimalFormatting(d, resolution, true);
			};
			let binaryDisplayLabel = function(maxValue, binaryFormat, d) {
				if (d === maxValue || d === 0) {
					return '';
				}

				return that.extraDataFormatting.applyBoolean(!(d % 2 > 0), binaryFormat);
			};
			this.yAxes.forEach(function(yAxis, i) {
				let currentBinaryDisplayLabel;
				let currentDefaultDisplayLabel;
				if (yAxis.visible) {
					if (yAxis.binary) {
						let maxValue = yAxis.y.domain()[1];
						ticks = maxValue + 1;
						currentBinaryDisplayLabel = binaryDisplayLabel.bind(null, maxValue, yAxis.binaryFormat);
					} else {
						currentDefaultDisplayLabel = defaultDisplayLabel.bind(null, yAxis.resolution, yAxis.isPercentageAxis);
					}
					// display binary values for second axis if it has not data
					if (i > 0 && currentBinaryDisplayLabel && that.yAxes[0].y.domain().toString() === yAxis.y.domain().toString()) {
						currentDefaultDisplayLabel = currentBinaryDisplayLabel;
					}
					if (yAxis.isSingleValue) {
						ticks = 2;
					}
					const f = yAxis.axis
						.tickSize(0, 0, 0)
						.tickFormat(yAxis.binary ? currentBinaryDisplayLabel : currentDefaultDisplayLabel)
						.ticks(ticks);
					yAxis.node
						.attr('display', '')
						.call(f)
						.selectAll('text')
						.attr('dx', that.CHART_LABEL_OFFSET[yAxis.orient]);
				} else {
					yAxis.node.attr('display', 'none');
					yAxis.uomLabel.attr('display', 'none');
				}
			});

			let isAnyAxisVisible = that.yAxes.some(item => {
				if (item && item.visible) {
					let tickSize = item.orient === 'left' ? -that.width : that.width;
					that.gridNode.attr('display', '').call(item.axis.tickSize(tickSize, 0, 0).tickFormat(''));
					return true;
				}
			});
			if (!isAnyAxisVisible) {
				that.gridNode.attr('display', 'none');
			}
		}

		calculateData(chartData, chartOptions) {
			let calculatedData = [];
			let x = this.scatterXAxis.index;
			let brushRange = {
				from: this.x.domain()[0].getTime(),
				to: this.x.domain()[1].getTime(),
			};
			let xAxisChartData = chartData[x];
			const limitsLine = chartOptions.lines.find(line => line.chartAxisId === 'limits');
			const limitsLineAxisIndex = limitsLine ? chartOptions.lines.indexOf(limitsLine) : null;

			for (let i = 0; i < xAxisChartData.length; i++) {
				if (brushRange.from > xAxisChartData[i].x.getTime() || brushRange.to < xAxisChartData[i].x.getTime()) {
					continue;
				}
				// gather data for x,y values for available object(s)
				for (let y = 0; y < chartOptions.lines.length; y++) {
					const xData = chartData[x][i];

					if (y === x || y === limitsLineAxisIndex || chartOptions.lines[y].chartAxisId === PERFORMANCE_CURVE || !xData) {
						continue;
					}

					const xDataTimestamp = xData.x.getTime();
					const yData = (chartData[y] || []).find(({x}) => x && x.getTime() === xDataTimestamp);

					if (!yData || yData.y === undefined || yData.y === null || xData.y === undefined || xData.y === null) {
						continue;
					}

					const dot = {
						x: xData.y,
						date: xData.x,
						y: yData.y,
					};

					if (this.subType === this.CHART_TYPE.SCATTER_WITH_LIMITS && chartOptions.thresholds.length) {
						dot.limits = [];
						chartOptions.thresholds.forEach(function(item, count) {
							const thresholdData = (chartData[item.chartDataIndex] || []).find(({x}) => x && x.getTime() === xDataTimestamp);

							if (!thresholdData) {
								return;
							}

							dot.limits[count] = thresholdData.y;
						});
					}
					if (!isNaN(dot.y)) {
						calculatedData[y] = calculatedData[y] || [];
						calculatedData[y].push(dot);
					}
				}
			}
			return calculatedData;
		}

		updateYAxesScale(chartOptions, chartData) {
			let that = this;
			let xMinExtent;
			let xMaxExtent;
			let xExtents = [];
			const axisToScaleArray = new Map();
			const [minDate, maxDate] = this.x.domain();

			function isPercentageDataAxis(data) {
				return data.uom && data.uom.symbol && data.uom.symbol === '%';
			}

			function fixPercentageRange(min = null, max = null) {
				return [Math.min(0, min), Math.max(100, max)];
			}

			function addValueRangePadding(value, min, max, resolution) {
				const padding = resolution === 1 ? 1 : (max - min) * value;
				const minValue = min - padding;
				const maxValue = padding < 1 ? max * 2 + padding + value : max + padding;
				return [minValue, maxValue];
			}

			function getMinMaxValues(extents) {
				const minValue = d3.min(extents, d => d[0]);
				const maxValue = d3.max(extents, d => d[1]);
				return [minValue, maxValue];
			}

			function generateValueRange(value, resolution) {
				const multiplied = resolution === 1 ? 1 : Math.abs(value * PROPERTY_VALUE_DEVIATION);
				const minValue = +that.extraDataFormatting.applyDecimalFormatting(value - multiplied, resolution);
				const maxValue = +that.extraDataFormatting.applyDecimalFormatting(value + multiplied, resolution);
				return [minValue, maxValue];
			}

			this.yAxes.forEach(yAxis => {
				yAxis.hasData = false;
				yAxis.extents = [];
				yAxis.id = null;
				yAxis.isSingleValue = false;
			});

			for (let i = 0; i < chartOptions.lines.length; i++) {
				let line = chartOptions.lines[i];
				if (line === undefined || !line.visible) {
					continue;
				}
				const resolution = line.resolution;
				const isPercentageAxis = isPercentageDataAxis(line);
				if (line.chartAxisId.startsWith('y')) {
					const isLineContainingData = chartData[i] && chartData[i].length;
					let yAxisData = chartOptions.yAxis.find(item => item.chartAxisId === line.chartAxisId);
					let yAxis = this.yAxes.find(item => item.orient === yAxisData.side);
					yAxis.isPercentageAxis = isPercentageAxis;
					if (!yAxis.id) {
						yAxis.valueRangeExtents = [];
						yAxis.id = line.chartAxisId;
						yAxis.index = i;
						if (!yAxis.resolution || yAxis.resolution > resolution) {
							yAxis.resolution = resolution;
						}
						yAxis.y.range([this.chartHeight, 0]);
						yAxis.uomSymbol = line.uom && line.uom.symbol ? line.uom.symbol : '';
					}

					if (isLineContainingData) {
						const filteredData = chartData[i].filter(point => point.x >= minDate && maxDate >= point.x);
						const extent = d3.extent(filteredData, d => parseFloat(d.y));
						yAxis.hasData = true;
						yAxis.extents.push(extent);
						line.extent = extent;
					}

					if (!isLineContainingData && line.valueRange && line.valueRange.length) {
						yAxis.valueRangeExtents.push(line.valueRange);
					}
				} else if (line.chartAxisId === 'x') {
					this.scatterXAxis.id = line.chartAxisId;
					this.scatterXAxis.x.range([0, this.width]);

					if (chartData[i] && chartData[i].length) {
						const filteredData = chartData[i].filter(point => point.x >= minDate && maxDate >= point.x);
						xExtents.push(d3.extent(filteredData, d => parseFloat(d.y)));
					}
					[xMinExtent, xMaxExtent] = getMinMaxValues(xExtents);

					if (line.uom && line.uom.symbol === '%') {
						[xMinExtent, xMaxExtent] = fixPercentageRange(xMinExtent, xMaxExtent);
					} else {
						[xMinExtent, xMaxExtent] = addValueRangePadding(PROPERTY_VALUE_HORIZONTAL_PADDING, xMinExtent, xMaxExtent, resolution);
					}

					if (xMinExtent === xMaxExtent) {
						[xMinExtent, xMaxExtent] = generateValueRange(xMinExtent, resolution);
					}

					this.scatterXAxis.x.domain([xMinExtent, xMaxExtent]);
					this.scatterXAxis.axis = d3.svg.axis().scale(this.scatterXAxis.x);
					this.scatterXAxis.index = i;
					this.scatterXAxis.uomSymbol = line.uom && line.uom.symbol ? line.uom.symbol : '';
					this.scatterXAxis.visible = xExtents.length > 0 || isPercentageAxis;
				} else if (line.chartAxisId === PERFORMANCE_CURVE) {
					let yAxis = this.yAxes[0];
					if (chartData[i] && chartData[i].length) {
						yAxis.extents = yAxis.extents || [];
						const minDateMoment = moment(minDate)
							.utc()
							.format()
							.toString();
						const maxDateMoment = moment(maxDate)
							.utc()
							.format()
							.toString();
						const filteredData = chartData[i].filter(point => point.date >= minDateMoment && maxDateMoment >= point.date);
						yAxis.extents.push(d3.extent(filteredData, d => d.y));
					}
				}
			}
			this.yAxes.forEach((yAxis, index) => {
				let data = chartOptions.yAxis ? chartOptions.yAxis[index] : null;
				if (yAxis.id) {
					let [minValue, maxValue] = getMinMaxValues(yAxis.extents);
					if (yAxis.isPercentageAxis) {
						[minValue, maxValue] = fixPercentageRange(minValue, maxValue);
					} else {
						[minValue, maxValue] = addValueRangePadding(PROPERTY_VALUE_VERTICAL_PADDING, minValue, maxValue, yAxis.resolution);
					}
					if (minValue && maxValue && minValue === maxValue) {
						[minValue, maxValue] = generateValueRange(minValue, yAxis.resolution);
						yAxis.isSingleValue = true;
					} else if (!yAxis.hasData && yAxis.valueRangeExtents.length > 0) {
						[minValue, maxValue] = getMinMaxValues(yAxis.valueRangeExtents);
					}

					yAxis.axis = d3.svg
						.axis()
						.orient(yAxis.orient)
						.scale(yAxis.y);
					yAxis.visible = yAxis.extents.length > 0 || yAxis.valueRangeExtents.length > 0;
					let scaleArray = [minValue, maxValue];
					if (data && data.customRange) {
						scaleArray = data.customRange;
					}
					yAxis.y.domain(scaleArray);

					axisToScaleArray.set(yAxis.id, scaleArray);
				}
			});
			this.updateYAxes();
			return axisToScaleArray;
		}

		updateAxesHeight(width, chartHeight) {
			this.yAxes.forEach(item => {
				item.y.range([chartHeight, 0]);
				item.axis = d3.svg
					.axis()
					.orient(item.orient)
					.scale(item.y);
			});

			this.updateYAxes();
			this.scatterXAxis.x.range([0, width]);
			this.scatterXAxis.axis = d3.svg.axis().scale(this.scatterXAxis.x);
		}

		updateGrid() {
			if (this.scatterXAxis && this.scatterXAxis.visible) {
				const xx = this.scatterXAxis.axis.tickSize(this.chartHeight, 0, 0).tickFormat('');
				this.minorGrid.attr('display', '').call(xx);
			} else {
				this.minorGrid.attr('display', 'none');
			}
		}
		_getStrokeIntervalByLineType(lineType, lineThickness) {
			const isThicknessSizeThreeOrFour = lineThickness === this.LINE_THICKNESS.SIZE_3 || lineThickness === this.LINE_THICKNESS.SIZE_4;
			let result = '';

			switch (lineType) {
				case this.LINE_TYPE.DASHED:
					if (isThicknessSizeThreeOrFour) {
						result = '20, 20';
					} else {
						result = '5, 5';
					}
					break;

				case this.LINE_TYPE.DOTTED:
					if (isThicknessSizeThreeOrFour) {
						result = '1, 10';
					} else if (lineThickness === this.LINE_THICKNESS.SIZE_2) {
						result = '1, 4';
					} else {
						result = '1, 2';
					}
					break;

				case this.LINE_TYPE.DASH_DOT:
					if (isThicknessSizeThreeOrFour) {
						result = '15, 15, 1, 15';
					} else {
						result = '5, 5, 1, 5';
					}

					break;

				case this.LINE_TYPE.LONG_DASH:
					if (isThicknessSizeThreeOrFour) {
						result = '30, 30';
					} else {
						result = '15, 10';
					}
					break;
			}

			return result;
		}

		_getStrokeShapeByLineType(lineType) {
			if (lineType === this.LINE_TYPE.DOTTED || lineType === this.LINE_TYPE.DASH_DOT) {
				return 'round';
			} else {
				return 'square';
			}
		}
		changeLine(lineHash, changes = {}) {
			this.dataNode
				.selectAll(`g[line-hash="${lineHash}"] > path`)
				.attr('stroke-dasharray', this._getStrokeIntervalByLineType(changes.lineType, changes.lineThickness))
				.attr('stroke-linecap', this._getStrokeShapeByLineType(changes.lineType))
				.attr('stroke-width', changes.lineThickness)
				.attr('marker-start', `url(#${lineHash}_${changes.markerType})`)
				.attr('marker-mid', `url(#${lineHash}_${changes.markerType})`)
				.attr('marker-end', `url(#${lineHash}_${changes.markerType})`);

			this.dataNode.selectAll('g[isPerformaceCurve="false"] > path').attr('stroke-width', 0);
			this.dataNode
				.select(`g[line-hash="${lineHash}"]`)
				.attr('stroke', changes.color)
				.selectAll('circle')
				.attr('fill', changes.color);
			if (changes.markerType !== this.MARKER_TYPE.NONE.name) {
				this.dataNode
					.selectAll(`g[line-hash="${lineHash}"] marker`)
					.attr('markerWidth', changes.markerSize)
					.attr('markerHeight', changes.markerSize)
					.attr('fill', changes.color);
			}
			if (changes.markerType === this.MARKER_TYPE.DOT.name) {
				let dotRadius = CIRCLE_RADIUS;
				changes.markerSize && (dotRadius = changes.markerSize / 2 - 0.5);
				this.dataNode
					.select(`g[line-hash="${lineHash}"]`)
					.selectAll('circle')
					.attr('opacity', '1')
					.attr('r', dotRadius)
					.attr('dotRadius', dotRadius);
			} else {
				this.dataNode
					.select(`g[line-hash="${lineHash}"]`)
					.selectAll('circle')
					.attr('opacity', '0');
			}
		}

		drawData(chartOptions, chartData) {
			this.chartOptions = chartOptions;
			this.chartData = chartData;

			let that = this;
			that.tooltip.hideTooltip();
			if (!chartOptions.lines || !chartData.length) {
				return;
			}
			this.calculatedData = that.calculateData(chartData, chartOptions);
			let lines = that.dataNode
				.append('g')
				.attr('class', 'lines')
				.attr('clip-path', 'url(#clipBorder' + that.chartId + ')');
			if (that.subType === that.CHART_TYPE.SCATTER_WITH_PERFORMANCE_CURVE) {
				that.drawPerformanceCurve(lines, chartData, chartOptions);
			} else if (that.subType === that.CHART_TYPE.SCATTER_WITH_LIMITS && this.calculatedData.length) {
				that.drawScatterWithLimitsChart(lines, this.calculatedData, chartOptions);
			} else {
				if (!this.calculatedData.length) {
					return;
				}
				that.drawTypicalScatterChart(lines, this.calculatedData, chartOptions);
			}
			calculateGroups(this.groupsToCalculate, this.groupMap);
		}

		drawTypicalScatterChart(lines, chartData, chartOptions) {
			let that = this;
			let xAxis = this.scatterXAxis;

			let lastLine = chartOptions.lines.length - 1;

			// work with y lines in each loop
			// for every y lines we have the same x line in chartData
			for (let i = 0; i <= lastLine; i++) {
				// Order of layers matters for chart rendering
				let index = chartOptions.isChartLinesSortedAsc ? i : lastLine - i;

				let line = chartOptions.lines[index];
				let chartAxisId = line.chartAxisId;
				let color = line.color;
				let yAxis = this.yAxes.find(axis => axis.id === chartAxisId);

				if (chartAxisId === 'x' || chartAxisId === PERFORMANCE_CURVE || !line.visible) {
					continue;
				}
				const groupToCalculate = {
					data: [],
					xAxis,
					yAxis,
				};
				this.groupsToCalculate.push(groupToCalculate);

				if (chartData[index]) {
					lines
						.selectAll('dot')
						.data(chartData[index])
						.enter()
						.append('circle')
						.attr('class', 'axis-' + yAxis.index)
						.attr('clip-path', 'url(#clipBorder' + that.chartId + ')')
						.attr('r', CIRCLE_RADIUS)
						.attr('cx', d => xAxis.x(d.x))
						.attr('cy', d => yAxis.y(d.y))
						.attr('color', () => color)
						.attr('fill', function(d) {
							d.color = this.getAttribute('color');
							groupToCalculate.data.push(d);
							return d.color;
						})
						.attr('stroke', function() {
							return this.getAttribute('color');
						})
						.on('mouseover', function(d) {
							const name = {x: chartOptions.lines[xAxis.index].name, y: line.name};
							const axes = {xAxis, yAxis, element: this};
							that.timedOutOpenTooltip({d, axes, name});
						})
						.on('mouseout', function() {
							d3.select(this).attr('r', CIRCLE_RADIUS);
							that.tooltip.hideTooltip();
						});
				}
			}
		}

		drawScatterWithLimitsChart(lines, chartData, chartOptions) {
			let that = this;
			let xAxis = this.scatterXAxis;
			let yAxis = this.yAxes[0];

			if (!chartData[yAxis.index]) {
				return;
			}
			const groupToCalculate = {
				data: [],
				xAxis,
				yAxis,
			};
			this.groupsToCalculate.push(groupToCalculate);
			lines
				.selectAll('dot')
				.data(chartData[yAxis.index])
				.enter()
				.append('circle')
				.attr('class', `axis-${yAxis.index}`)
				.attr('clip-path', 'url(#clipBorder' + that.chartId + ')')
				.attr('r', CIRCLE_RADIUS)
				.attr('cx', d => xAxis.x(d.x))
				.attr('cy', d => yAxis.y(d.y))
				.attr('color', getCircleColor)
				.attr('fill', function(d) {
					d.color = this.getAttribute('color');
					groupToCalculate.data.push(d);
					return this.getAttribute('color');
				})
				.attr('stroke', function() {
					return this.getAttribute('color');
				})
				.on('mouseover', function(d) {
					const name = {x: chartOptions.lines[xAxis.index].name, y: chartOptions.lines[yAxis.index].name};
					const axes = {xAxis, yAxis, element: this};
					that.timedOutOpenTooltip({d, axes, name});
				})
				.on('mouseout', function() {
					d3.select(this).attr('r', CIRCLE_RADIUS);
					that.tooltip.hideTooltip();
				});
		}

		updateChartPos() {
			let that = this;

			if (this.dataNode) {
				this.dataNode
					.selectAll('.line')
					.selectAll('circle.circle-dot')
					.attr('cx', d => that.scatterXAxis.x(d.x));
			}

			if (this.yAxes) {
				this.yAxes.forEach(axis => {
					this.dataNode.selectAll('path.axis-' + axis.index).attr('d', axis.line);
					this.dataNode.selectAll('circle.axis-' + axis.index).attr('cy', d => axis.y(d.y));
				});
			}

			if (this.subType === this.CHART_TYPE.SCATTER_WITH_PERFORMANCE_CURVE && this.dataNode) {
				this.dataNode.selectAll('.performanceCurveLine').attr('d', that.yAxes[0].line);
				this.dataNode
					.selectAll('.performanceCurveDot')
					.attr('cx', d => that.scatterXAxis.x(d.x))
					.attr('cy', d => that.yAxes[0].y(d.y));
			}
			calculateGroups(this.groupsToCalculate, this.groupMap);
		}

		timedOutOpenTooltip({d, axes, name}) {
			const {x, y, date, group} = d;
			clearTimeout(this.tooltip.closeToolTip);
			let openToolTipItem = this.tooltip.openToolTip.get(group);
			if (!openToolTipItem) {
				openToolTipItem = setTimeout(() => {
					const {xAxis, yAxis, element} = axes;
					let uom = {x: xAxis.uomSymbol, y: yAxis.uomSymbol};
					const tooltipParams = {
						xPos: xAxis.x(x),
						yPos: yAxis.y(y),
						name: name,
						uom: uom,
					};
					if (group && group.count > 1) {
						const groupItems = this.groupMap.get(group);
						tooltipParams.chartType = MULTIPOINT_CHART_TYPE;
						tooltipParams.multiPoints = groupItems.map(item => ({
							x: item.x,
							y: item.y,
							date: moment(item.date).format('M/D/YY h:mm A'),
							color: item.color,
						}));
					} else {
						Object.assign(tooltipParams, {
							color: d.color,
							scatterData: {x, y, date: moment(date).format('M/D/YY h:mm A')},
						});
					}
					this.tooltip.showTooltip(tooltipParams);
					d3.select(element).attr('r', CIRCLE_RADIUS_HOVERED);
					this.tooltip.openToolTip.set(group, openToolTipItem);
				}, 50);
			}
		}
	}

	function addAxes(svg) {
		let x = d3.scale.linear().domain([0, 0]);

		this.scatterXAxis = {
			x: x,
			node: svg.append('g').attr('class', 'axis'),
			axis: d3.svg.axis().scale(x),
			uomSymbol: '',
		};
		this.yAxes.push(addYAxis('left', svg, x));
		this.yAxes.push(addYAxis('right', svg, x));
	}

	function addYAxis(orientation, svg, x) {
		let y = d3.scale.linear().domain([0, 0]);

		return {
			y: y,
			node: svg.append('g').attr('class', 'axis'),
			axis: d3.svg.axis().scale(y),
			orient: orientation,
			uomSymbol: '',
			uomLabel: svg.append('g').append('text'),
			line: d3.svg
				.line()
				.x(d => x(d.x))
				.y(d => y(d.y)),
		};
	}

	function getCircleColor(d) {
		if (d.limits) {
			// We assume limits contain the following values:
			// [0]: LowerRedLimit
			// [1]: UpperRedLimit
			// [3]: Capacity (optional)
			let lowerCriticalLimit = parseInt(d.limits[0]) || 50;
			let upperCriticalLimit = parseInt(d.limits[1]) || 50;
			let capacity = parseInt(d.limits[2]) || 100;
			let a = d.x * capacity / 100; // A = OAD (Outside Air Damper) Position x Max Capacity
			// Unexpected Values if:
			// OAF (Outdoor Air Fraction) < A - Lower Critical Limit
			// OR
			// OAF (Outdoor Air Fraction) > A + Upper Critical Limit
			if (d.y < a - Math.abs(lowerCriticalLimit) || d.y > a + upperCriticalLimit) {
				return RED_COLOR;
			} else {
				return GREEN_COLOR;
			}
		} else {
			const TOP_DAMPER = {
				x1: 0,
				y1: 50,
				x2: 50,
				y2: 100,
			};

			const BOTTOM_DAMPER = {
				x1: 50,
				y1: 0,
				x2: 50,
				y2: 50,
			};

			const point = {x: d.x, y: d.y};
			const isRed = getCrossProduct(TOP_DAMPER, point) <= 0 || getCrossProduct(BOTTOM_DAMPER, point) >= 0;
			return isRed ? RED_COLOR : GREEN_COLOR;
		}
	}

	function getCrossProduct(line, point) {
		let v1 = {x: line.x2 - line.x1, y: line.y2 - line.y1};
		let v2 = {x: line.x2 - point.x, y: line.y2 - point.y};
		return v1.x * v2.y - v1.y * v2.x;
	}

	window.ScatterChartRenderer = ScatterChartRenderer;
})(window.d3, window.AbstractChartRenderer);
