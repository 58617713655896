angular.module('TISCC').controller('AbstractCc3ReportCtrl', function($scope, $q) {
	$scope.DATE_FORMAT = 'MM/DD/YYYY';
	$scope.COMMON_REQUEST_PARAMETERS = {
		url: '/export',
		method: 'POST',
		data: {},
		onProgress({loadedInPercents}) {
			$scope.$evalAsync(() => {
				$scope.progress = loadedInPercents;
			});
		},
	};
	$scope.viewModel = {
		isLoadingStarted: false,
	};

	$scope.setDisableGenerateButton(false);

	$scope.$on('generateReport', () => {
		$q
			.resolve()
			.then(() => {
				$scope.progress = 0;
				$scope.showProgressPercents = true;
				$scope.viewModel.isLoadingStarted = true;
				$scope.setDisableGenerateButton(true);
			})
			.then(() => $scope.generateReportHandler())
			.then(() => $scope.$emit('generateComplete'))
			.finally(() => {
				$scope.viewModel.isLoadingStarted = false;
				$scope.setDisableGenerateButton(false);
				$scope.$emit('hideLoader');
			});
	});
});
