export const USER_EVENTS = {
	DIGITAL_INSPECTION_PAGE: {
		categoryName: 'TCC4 Digital Inspection',
		events: {
			SELECT_AUTOMATED_TEST: 'Select Automated Test',
			GO_TO_PERFORMANCE_CHART: 'Go to Performance Chart',
			CHANGE_DATES_OF_TEST_RESULTS: 'Change Dates of Test Results',
			VIEW_SUGGESTIONS: 'View Suggestions',
			VIEW_LATEST_DETECTION: 'View Latest Detection',
			VIEW_FIRST_DETECTION: 'View First Detection',
		},
		properties: {
			BUILDING_ID: 'BuildingID',
			BUILDING_SALES_OFFICE: 'Building Sales Office',
			BUILDING_OFFERINGS: 'Building Offerings',
			COLUMN: 'Column',
			DIRECTION: 'Direction',
			EQUIPMENT_TYPE: 'Equipment Type',
			OFFERING: 'Offering',
			TEST_NAME: 'Test Name',
			DAYS_SELECTED: 'Number of Days Selected ',
			DETECTION_RATE: 'Detection Rate',
			FIRST_DETECTION_DATE: 'First Detection Date',
			LATEST_DETECTION_DATE: 'Latest Detection Date',
			TEST_CATEGORY: 'Test Category',
		},
	},
};
