(function() {
	const MAX_SHOWED_AMOUNT = 4;
	const MAX_SHOWED_ITEMS_PER_SECTION = 2;
	class ConnectivityInfoController {
		constructor(offeringService, locationDetailsService) {
			Object.assign(this, {offeringService, locationDetailsService});
		}
		$onInit() {
			this.showOfferingsInfoPopup = param => {
				this.showOfferingsInfoPopupHandler({param});
			};
			this.closeMoreInfoPopup = param => {
				this.closeMoreInfoPopupHandler({param});
			};
			this.isExpandable = false;
			this.isExpanded = false;
			this.checkWhatToShow();
		}
		expandMoreToggle() {
			this.isExpanded = !this.isExpanded;
			this.checkWhatToShow();
		}
		checkWhatToShow() {
			this.offerings = this.facility.offeringsDataList;
			this.offerings.sort((a, b) => a.shortName.localeCompare(b.shortName));
			this.itemsCount = this.offerings.length + this.facility.uniqDevices.length;
			if (this.itemsCount > MAX_SHOWED_AMOUNT && !this.isExpanded) {
				this.isExpandable = true;
				this.devices = this.facility.uniqDevices.slice(0, MAX_SHOWED_ITEMS_PER_SECTION - 2);
				this.offerings = this.offerings.slice(0, MAX_SHOWED_AMOUNT - this.devices.length - 1);
				this.countToExpand = this.itemsCount - (this.devices.length + this.offerings.length);
			} else {
				this.devices = this.facility.uniqDevices;
			}
		}
	}
	angular.module('TISCC').component('connectivityInfo', {
		templateUrl: 'components/home/connectivity-info-component/connectivity-info-component.html',
		controller: ConnectivityInfoController,
		bindings: {
			facility: '<',
			showOfferingsInfoPopupHandler: '&',
			closeMoreInfoPopupHandler: '&',
		},
	});
})();
