(function() {
	class PageHeaderController {
		constructor() {}
	}

	angular.module('TISCC').component('dirPageHeaderComponent', {
		templateUrl: 'components/reports/digital-inspection/page/page-header/page-header-component.html',
		controller: PageHeaderController,
		bindings: {
			isMain: '<',
			facilityName: '<',
			addressLine: '<',
			startTimePeriod: '<',
			endTimePeriod: '<',
			reportCreatedDate: '<',
			chillerName: '<',
			chillerSerialNumber: '<',
			pageName: '<',
			placeHolder: '<',
			equipmentImage: '<',
			defaultEquipmentImage: '<',
			equipmentDescription: '<',
		},
	});
})();
