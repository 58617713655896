(function(d3, moment) {
	const CHART_TYPE_TO_RENDERING_INFO = {
		stackedBar: {
			renderer: 'BarChartRenderer',
			hasTimeLine: false,
		},
		pie: {
			renderer: 'PieChartRenderer',
			hasTimeLine: false,
		},
		pareto: {
			renderer: 'ParetoChartRenderer',
			hasTimeLine: false,
		},
		scatter: {
			renderer: 'ScatterChartRenderer',
			hasTimeLine: true,
		},
		scatterWithPerformanceCurve: {
			renderer: 'ScatterChartWithCurveRenderer',
			hasTimeLine: true,
		},
		scatterWithLimits: {
			renderer: 'ScatterChartWithLimitsRenderer',
			hasTimeLine: true,
		},
		line: {
			renderer: 'LineChartRenderer',
			hasTimeLine: true,
		},
		lineWithBinaryStates: {
			renderer: 'LineChartRenderer',
			hasTimeLine: true,
		},
		lineWithControlRangeAndMean: {
			renderer: 'LineChartWithControlRangeRenderer',
			hasTimeLine: true,
		},
	};

	const CENTER_OFFSET = 50;
	const MESSAGE_LETTER_WIDTH = 9;
	const LABEL_NUMBER = 5;
	const CHART_LABEL_OFFSET = {
		left: -2,
		right: 2,
		top: -2,
		bottom: 11,
	};

	function CommonRenderer(range, timeLineData, svgMainGroup, externalMethods, chartType) {
		const CLIP_PATH_ID = new Date().getTime() % 1000;
		const chartRendererName = CHART_TYPE_TO_RENDERING_INFO[chartType].renderer;
		const timeZone = range.to.tz();

		this.noData = false;
		this.range = range;
		this.timeZone = timeZone;
		this.element = svgMainGroup.node().parentNode;
		this.translate = externalMethods.translate;

		this.prevRange = {
			from: moment().tz(timeZone),
			to: moment().tz(timeZone),
		};

		this.x = d3.time.scale();
		this.xAxis = d3.svg.axis().scale(this.x);

		this.CHART_MARGIN = {
			top: 44,
			left: 48,
		};

		this.chartRenderer = new window[chartRendererName](svgMainGroup, externalMethods, CLIP_PATH_ID, this.x, this.xAxis);
		this.chartRenderer.commonRenderer = this; // TODO: remove hack when refactor PieChartRenderer.js

		svgMainGroup.attr('class', 'main-group').attr('transform', `translate(${this.CHART_MARGIN.left}, ${this.CHART_MARGIN.top})`);

		this.clipPathRect = svgMainGroup
			.append('clipPath')
			.attr('id', `clipBorder${CLIP_PATH_ID}`)
			.append('rect')
			.attr('x', 1)
			.attr('y', -8);
		this.chartOnlyClipPathRect = svgMainGroup
			.append('clipPath')
			.attr('id', `chartClipBorder${CLIP_PATH_ID}`)
			.append('rect')
			.attr('x', 1)
			.attr('y', -8);

		if (CHART_TYPE_TO_RENDERING_INFO[chartType].hasTimeLine) {
			this.timelineRenderer = new window.TimelineRenderer(
				CLIP_PATH_ID,
				this.translate,
				svgMainGroup,
				timeLineData,
				externalMethods.tooltip,
				externalMethods.debounce,
				this.x
			);

			// Move the `data-node` element as the last child of the SVG element (right after the `timeline` element).
			// This is needed because chart rulers elements (which are under the `data-node` element) should overlay the timeline's elements.
			svgMainGroup.select('.data-node').each(function() {
				this.parentNode.appendChild(this);
			});

			this.timelineRenderer.createTimeline();
		}
	}

	CommonRenderer.prototype.updateRangeX = function() {
		const isSameFrom = this.prevRange.from.isSame(this.range.from, 'minute');
		const isSameTo = this.prevRange.to.isSame(this.range.to, 'minute');

		if (!isSameFrom || !isSameTo) {
			const format = 'DD MMMM YYYY HH:mm';
			this.prevRange.from = this.range.from.clone();
			this.prevRange.to = this.range.to.clone();

			const newRange = {
				from: this.range.from.clone(),
				to: this.range.to.clone(),
				brush: this.range.brush,
			};

			this.chartRenderer.onRangeXUpdated(newRange, this.timeZone);

			if (this.timelineRenderer) {
				this.timelineRenderer.onRangeXUpdated(newRange, this.timeZone);
			}

			this.x.domain([new Date(this.range.from.format(format)), new Date(this.range.to.format(format))]);
		}
	};

	CommonRenderer.prototype.updateSvgSize = function(isResizeEvent) {
		const chartObj = this.chartRenderer;
		const timeline = this.timelineRenderer;
		const {offsetHeight, offsetWidth} = this.element.parentNode;
		this.width = Math.abs(offsetWidth - 2 * this.CHART_MARGIN.left); // svg.node().offsetWidth;
		this.height = Math.abs(offsetHeight - 2 * this.CHART_MARGIN.top); // svg.node().offsetHeight;
		this.clipPathRect.attr('width', this.width).attr('height', this.height + 5);
		chartObj.x.range([0, this.width]);

		if (timeline) {
			const {chartHeight, timelineHeight} = timeline.updateTimelineHeight(this.height);
			this.chartHeight = Math.abs(chartHeight);
			this.timelineHeight = timelineHeight;
		} else {
			this.chartHeight = this.height;
			this.timelineHeight = 0;
		}
		this.chartOnlyClipPathRect.attr('width', this.width).attr('height', this.chartHeight + 16);

		const updatedata = {
			width: this.width,
			height: this.height,
			chartHeight: this.chartHeight,
			timelineHeight: this.timelineHeight,
		};

		if (timeline) {
			timeline.onSvgSizeUpdate(updatedata);
		}

		if (isResizeEvent) {
			chartObj.onSvgSizeUpdate(updatedata);
		} else {
			chartObj.dataNode.selectAll('*').remove();
			chartObj.onSvgReceivedSize(updatedata, this.element);
		}
	};

	CommonRenderer.prototype.draw = function(chartOptions, chartData) {
		this.chartRenderer.drawData(chartOptions, chartData);

		if (this.timelineRenderer) {
			this.timelineRenderer.drawTimeline(this.chartRenderer);
		}

		this.updateBrushRange(chartOptions, chartData);
	};

	CommonRenderer.prototype.updateBrushRange = function(chartOptions, chartData) {
		function stripTz(date) {
			return new Date(moment(date).format('DD MMMM YYYY HH:mm'));
		}
		let brushRangeSelected = false;
		if (this.range.brush) {
			brushRangeSelected =
				this.range.brush.from.isBetween(this.range.from, this.range.to) || this.range.brush.to.isBetween(this.range.from, this.range.to);
		}
		if (brushRangeSelected) {
			if (this.range.brush.from.isSameOrBefore(this.range.from)) {
				this.range.brush.from = this.range.from.clone();
			}
			if (this.range.brush.to.isSameOrAfter(this.range.to)) {
				this.range.brush.to = this.range.to.clone();
			}
			this.x.domain([stripTz(this.range.brush.from), stripTz(this.range.brush.to)]);
			this.updateGrid();
			this.brushRange(this.range, chartOptions, chartData);
		}
	};

	CommonRenderer.prototype.showEmptyDatasetMessage = function() {
		const {offsetHeight, offsetWidth} = this.element.parentNode;
		const offsetX = offsetWidth / 2 - CENTER_OFFSET;
		const offsetY = offsetHeight < 600 ? offsetHeight / 2 : offsetHeight / 2 - CENTER_OFFSET;
		const messageText = this.translate('NO_DATA_TO_SHOW_IN_CHART');

		this.chartRenderer.dataNode.selectAll('*').remove();
		this.chartRenderer.dataNode
			.append('g')
			.attr('transform', `translate(${offsetX},${offsetY})`)
			.append('text')
			.attr('transform', 'translate(-' + messageText.length / 2 * MESSAGE_LETTER_WIDTH + ',' + 0 + ')')
			.attr('class', 'chart-message')
			.text(messageText);
	};

	CommonRenderer.prototype.checkData = function(chartData, timelineData, lines, lanes) {
		if (typeof this.chartRenderer.checkData === 'function') {
			this.chartRenderer.checkData(chartData, timelineData, lines, lanes);
		} else {
			this.noData = !this.checkIfDataToDrawChartExist(chartData, timelineData, lines, lanes);
		}
	};

	CommonRenderer.prototype.checkIfDataToDrawChartExist = function(chartData, timelineData, lines, lanes) {
		let isChartDataExist = chartData.some(element => element && element.length);
		let isTimelineDataExist = timelineData.some(element => element.text !== null);
		let areLinesExist = lines && lines.length;
		let areLanesExist = lanes && lanes.length;

		return (isChartDataExist || isTimelineDataExist) && (areLinesExist || areLanesExist);
	};

	CommonRenderer.prototype.brushRange = function(brushRange, chartOptions, chartData) {
		this.chartRenderer.onBrushRange(brushRange, chartOptions, chartData);

		if (this.timelineRenderer) {
			this.timelineRenderer.onBrushRange(brushRange, this.timeZone);
		}
	};

	CommonRenderer.prototype.updateGrid = function(period) {
		this.chartRenderer.updateGrid(period, LABEL_NUMBER);

		if (this.timelineRenderer) {
			this.timelineRenderer.setTimelineGrid(LABEL_NUMBER, CHART_LABEL_OFFSET.bottom, this.xAxis);
			this.timelineRenderer.updateTimeline(this.chartRenderer);
		}

		if (!this.noData) {
			this.chartRenderer.updateAxisLabel(LABEL_NUMBER);
		}
	};

	window.CommonRenderer = CommonRenderer;
})(window.d3, window.moment);
