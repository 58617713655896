import _get from 'lodash.get';

const services = new WeakMap();
const privateFields = new WeakMap();

export default class ZendeskHelper {
	constructor(configService, $window, $route) {
		this.$window = $window;
		this.$route = $route;
		services.set(this, {configService});
		privateFields.set(this, {
			isInitialized: false,
		});
	}

	initialize() {
		if (_get(this.$route, 'current.zendeskConfig.disable') || _get(this.$route, 'current.params.isExport')) {
			return;
		}

		if (privateFields.get(this).isInitialized) {
			throw new Error('ZenDesk is already initialized');
		}

		const {zendeskKey, zendeskTicketFormID} = services.get(this).configService.getZendeskConfig();
		this.insertScript(zendeskKey);
		this.$window.zESettings = this.getConfig(zendeskTicketFormID);
		privateFields.get(this).isInitialized = true;
	}

	insertScript(zendeskKey) {
		this.script = this.$window.document.createElement('script');
		this.script.defer = true;
		this.script.id = 'ze-snippet';
		this.script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
		this.script.addEventListener('load', this.onScriptLoaded.bind(this));
		this.$window.document.body.appendChild(this.script);
	}

	getConfig(ticketFormID) {
		return {
			webWidget: {
				contactForm: {
					title: {
						'*': 'Request Help / Give Feedback',
					},
					ticketForms: [
						{
							id: Number(ticketFormID),
						},
					],
				},
			},
		};
	}

	onScriptLoaded() {
		this.command('webWidget', 'setLocale', 'en-US');
	}

	canUseDOM() {
		return !(typeof this.$window === 'undefined' || !this.$window.document || !this.$window.document.createElement);
	}

	command(...args) {
		if (this.canUseDOM() && this.$window.zE) {
			this.$window.zE.apply(null, args);
		}
	}

	identify(firstName, lastName, email) {
		this.command('webWidget', 'identify', {
			name: `${firstName} ${lastName}`,
			email,
		});
		this.command('webWidget', 'prefill', {
			name: {
				value: `${firstName} ${lastName}`,
			},
			email: {
				value: email,
			},
		});
	}

	dispose() {
		if (privateFields.get(this).isInitialized) {
			this.command('webWidget', 'clear');
			this.command('webWidget', 'logout');
		}
	}
}
