angular.module('TISCC').service('locationDetailsService', function($http, $filter, helpers, $q, cacheService, offeringService, handleServiceWorker, OFFERINGS) {
	// TODO: get rid of moment.tz.guess()
	// We should rewrite getLocationTimezone to utilize getLocationDetailsWithoutServiceAdvisories
	let currentTimezone = moment.tz.guess();

	this.getLocationTimezone = () => currentTimezone;

	/**
	 * @param locationId
	 * @returns {promise} AngularJS future object
	 */
	this.getLocationDetailsWithoutServiceAdvisories = function(locationId) {
		let deferred = $q.defer();
		let cacheConfig = {
			cache: cacheService.getCacheInstance('locationDetailsService', 'LocationDetails', locationId),
			headers: handleServiceWorker.createServiceWorkerCacheKeyForRequest({
				cacheKey: `locationDetailsService_LocationDetails_/ext_api/api/location/${locationId}/summary`,
				expiryTime: handleServiceWorker.CACHE_EXPIRE_TIME.IN_1_HOUR,
			}),
		};

		$http
			.get(`/ext_api/api/location/${locationId}/summary`, cacheConfig)
			.success(onSuccessHandler)
			.error(deferred.reject.bind(deferred));

		return deferred.promise;

		function onSuccessHandler(res) {
			setCurrentTimezone(res);
			deferred.resolve(res);
		}
	};

	/**
	 * @param locationId
	 * @returns {promise} AngularJS future object
	 */
	this.getLocationServiceAdvisoriesCount = function(locationId) {
		if (!locationId) {
			return Promise.reject(new Error('Invalid locationId'));
		}

		// Remove below line once API fixes the service advisory count issue.
		if (locationId) {
			return Promise.reject(new Error('Disabling SA count request as Data is not accurate.'));
		}

		const config = {
			params: {
				locationId,
				automatedTestLevels: 'Combined,Individual,AddonException',
			},
		};

		return $http.get('/ext_api/analytics-api/serviceAdvisory/count', config).then(response => {
			const countPath = 'serviceAdvisoryStatisticsList[0].serviceAdvisoriesCount';
			return helpers.getPropertyByPath(response.data, countPath);
		});
	};

	this.getLocationNotesCount = function(locationId) {
		if (!locationId) {
			return Promise.reject(new Error('Invalid locationId'));
		}

		const url = '/ext_api/api/note/count?locationId=' + locationId;

		return $http
			.get(url, {
				headers: handleServiceWorker.createServiceWorkerCacheKeyForRequest({
					cacheKey: `locationDetailsService_getLocationNotesCount_${url}`,
					expiryTime: handleServiceWorker.CACHE_EXPIRE_TIME.IN_4_HOURS,
				}),
			})
			.then(response => {
				const count = helpers.getPropertyByPath(response.data, 'noteStatisticsList[0].notesCount');
				return Number.isInteger(+count) ? Number(count) : '?';
			});
	};

	this.getEnergyStarData = function(callback) {
		$http.get('data/estar-data.json').success(callback);
	};

	this.getWeatherForLocation = function(location) {
		const {locationId, locationTimeZone} = location;
		const hpathPrefix = 'WeatherSourceV2::@';
		const weatherProperties = [
			'Temperature',
			'Humidity',
			'Condition',
			'windMPH',
			'WindDirection',
			'PressureIN',
			'PressureTrend',
			'VisibilityMI',
			'IconFileName',
		].map(property => `${property}|LATEST()`);
		const params = {
			stp: 'currentDay',
			timeZone: locationTimeZone,
			hpath: hpathPrefix + weatherProperties.join(',' + hpathPrefix),
		};

		const link = '/ext_api/api/location/' + locationId + '/data';

		const headers = {
			...handleServiceWorker.createServiceWorkerCacheKeyForRequest({
				cacheKey: `locationDetailsService_getWeatherForLocation_${link}`,
				expiryTime: handleServiceWorker.CACHE_EXPIRE_TIME.IN_1_HOUR,
			}),
		};

		return $http.get(link, {params, headers}).then(createWeatherObjFromResponse);
	};
	this.getFloorArea = function(id) {
		return $http.get('/ext_api/analytics-api/analytic/parameter/FloorArea?locationId=' + id);
	};

	this.isCprAllowed = function(location) {
		const {tisObjectTypeGroupNames = []} = location || {};
		if (!tisObjectTypeGroupNames.includes('Chiller')) {
			return false;
		}
		return this.isOfferingExpired(location);
	};
	this.isDirAllowed = function(location) {
		const {tisObjectTypeGroupNames = []} = location || {};
		if (!tisObjectTypeGroupNames.includes('Chiller') && !tisObjectTypeGroupNames.includes('AHU')) {
			return false;
		}
		return this.isOfferingExpired(location);
	};
	this.isOfferingExpired = function(location) {
		const {offeringSourceMaps = [], locationTimeZone} = location || {};
		const activeOfferings = OFFERINGS.filter(offeringName => {
			const offering = offeringSourceMaps.find(o => o.shortName === offeringName);
			if (offering) {
				const {isExpired, remainingDaysNumberInGracePeriod} = offeringService.addExpirationMetaData(offering, {locationTimeZone});
				return !isExpired || remainingDaysNumberInGracePeriod >= 0;
			}
			return false;
		});

		// Enable report if there is at least one active offering
		if (activeOfferings.length > 0) {
			return true;
		} else {
			return false;
		}
	};

	function createWeatherObjFromResponse(response) {
		let weatherObj = getWeatherData(response);

		weatherObj.columns.forEach(function(weatherColumn) {
			let symbol = weatherColumn.sourceUnitOfMeasure ? ' ' + weatherColumn.sourceUnitOfMeasure.symbol : '';
			let data = weatherColumn.values.length !== 0 ? weatherColumn.values[0] : {};
			let timestamp = data.timestamp ? moment(data.timestamp) : null;
			let value = data.value || angular.isNumber(data.value) ? data.value : '';

			weatherObj.isWeatherDataExist = weatherObj.isWeatherDataExist || !!value;
			weatherObj[weatherColumn.name] = {
				value: value,
				symbol: symbol || '',
			};
			if (timestamp && (!weatherObj.lastCollected || weatherObj.lastCollected > timestamp)) {
				weatherObj.lastCollected = timestamp;
			}
		});
		return weatherObj;
	}

	function setCurrentTimezone(data) {
		if (helpers.objectHasProperty(data, 'locationSummaryList[0].locationTimeZone')) {
			currentTimezone = data.locationSummaryList[0].locationTimeZone;
		}
	}

	function getWeatherData(apiResponse) {
		let weatherData = helpers.getPropertyByPath(apiResponse, 'data.locationDataList[0].relatedDataEntries[0].dataEntries[0]');

		let weatherObj = {
			lastCollected: false,
			isStation: false,
			isWeatherDataExist: false,
			stationKey: null,
			columns: [],
		};

		if (weatherData) {
			let isStation = !!weatherData.weatherStation;

			weatherObj.isStation = isStation;
			weatherObj.columns = weatherData.columns;
			if (isStation) {
				weatherObj.stationKey = weatherData.weatherStation.stationKey;
			}
		}
		return weatherObj;
	}
});
