/* global process*/

angular.module('TISCC').service('utilityService', function($http, cacheService, ENVIRONMENT, handleServiceWorker) {
	this.getAppVersionData = function() {
		const url = `data/version.json?${process.env.APP_CURRENT_VERSION}`;

		const config = {
			cache: cacheService.getCacheInstance('utilityService', 'AppVersionData'),

			headers: handleServiceWorker.createServiceWorkerCacheKeyForRequest({
				cacheKey: `utilityService_AppVersionData_${url}`,
				expiryTime: handleServiceWorker.CACHE_EXPIRE_TIME.IN_3_DAYS,
			}),
		};

		return $http.get(url, config).then(resp => {
			return {
				version: resp.data.version,
				year: new Date().getFullYear(),
			};
		});
	};

	this.getEnvironmentConfig = function() {
		const config = {
			cache: cacheService.getCacheInstance('utilityService', 'EnvironmentConfig'),
			headers: handleServiceWorker.createServiceWorkerCacheKeyForRequest({
				cacheKey: 'utilityService_EnvironmentConfig_environment.json',
				expiryTime: handleServiceWorker.CACHE_EXPIRE_TIME.IN_3_DAYS,
			}),
		};

		return $http
			.get('environment.json', config)
			.then(({data}) => data)
			.catch(() => ({
				ENV_TYPE: ENVIRONMENT.PROD,
			}));
	};

	this.getEnvironmentType = function() {
		return this.getEnvironmentConfig().then(({ENV_TYPE}) => ENV_TYPE);
	};

	this.getRawDataConfig = function() {
		return $http.get('data/raw-data-request-config.json').then(response => response.data);
	};

	this.getApplicationInfo = function() {
		return $http.get('/ext_api/api/applicationInfo').then(response => response.data);
	};
});
