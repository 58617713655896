/* global EventEmitter*/

(function() {
	const syncEmitter = new EventEmitter();
	let chartIndex = 0;

	const ChartFactory = function(
		locationDetailsService,
		CHART_DATE_FORMAT,
		helpers,
		ErrorPageFactory,
		urlService,
		chartConfigService,
		locationEquipmentService,
		$timeout,
		PAGE_TYPE,
		$filter,
		$translate
	) {
		// :todo move using urlService into controller
		const PATH_404 = '404';
		const INVALID_DATE_FUTURE_KEY = 'invalidDateFuture';
		const INVALID_DATE_FORMAT_KEY = 'invalidDateFormat';
		const facilityServiceAdvisoryCountMap = new WeakMap();
		const facilityNotesCountMap = new WeakMap();
		const _range = new WeakMap();

		class ChartRange {
			constructor(timeZone, eventObject) {
				this.timerID = null;
				this.timeZone = timeZone;
				this.eventObject = eventObject;
				this.exportRange = {};
				this.main = false;

				const from = moment()
					.tz(this.timeZone)
					.startOf('day');
				const to = moment()
					.tz(this.timeZone)
					.startOf('day')
					.add(1, 'days');
				_range.set(this, {from, to});
				this.setRange({emitUpdateChartRange: false});

				this.brushChartRemoteBinded = this.brushChartRemote.bind(this);
				this.changeRangeRemoteBinded = this.changeRangeRemote.bind(this);
				this.syncRangeRemoteBinded = this.syncRangeRemote.bind(this);

				this.chartReadyEvent = new EventEmitter();
				this.chartReadyPromise = new Promise((resolve, reject) => {
					this.chartReadyEvent.on('chartReady', () => {
						resolve();
					});
				});
			}

			set from(from) {
				const range = _range.get(this);
				if (range.from && !from.isSame(range.from)) {
					range.from = from;
					_range.set(this, range);
					this.setRange();
					this.sendSyncRange();
				}
			}

			get from() {
				const {from} = _range.get(this);
				return from;
			}

			set to(to) {
				const range = _range.get(this);
				if (range.to && !to.isSame(range.to)) {
					range.to = to;
					_range.set(this, range);
					this.setRange();
					this.sendSyncRange();
				}
			}

			get to() {
				const {to} = _range.get(this);
				return to;
			}

			get dateDiff() {
				return this.to.diff(this.from, 'days');
			}

			setRangeInternal(range) {
				// There is problem with syncing in multiple charts (particularly pareto charts with multiple facilities)
				// when time zones of locations are different.
				// The date is meant to be interpreted as an exact point on the global timeline.
				// That's why we need to specify a parse format that ignores the timezone offset on the set range time stamp.
				if (range.from.tz() !== this.from.tz()) {
					const formatWithoutTz = 'YYYY-MM-DDTHH:mm:ss';
					const convertDateWithoutSavingTz = point => moment.tz(range[point].format(formatWithoutTz), formatWithoutTz, this[point].tz());

					_range.set(this, {
						from: convertDateWithoutSavingTz('from'),
						to: convertDateWithoutSavingTz('to'),
					});
				} else {
					_range.set(this, range);
				}
			}

			adjustTo(dateDiff) {
				this.to = moment(this.from).add(dateDiff, 'days');
			}

			get rangeMode() {
				let rangeMode = 'custom';
				switch (this.dateDiff) {
					case 1:
						rangeMode = '1 day';
						break;
					case 7:
						rangeMode = '1 week';
						break;
					case 28:
						rangeMode = '4 week';
						break;
				}
				return rangeMode;
			}

			set isSynchronizable(status) {
				if (status) {
					this.setAsChild();
				} else {
					this.setAsStandAlone();
				}
			}

			get isSynchronizable() {
				return this._syncronized;
			}

			// This date is used for generating visual export file names.
			// Date in exported chart file name should match the end date in UI.
			get visualExportEndDate() {
				const toDate = this.to.clone();

				return toDate.isSame(toDate.startOf('day')) ? toDate.add(-1, 'days') : toDate;
			}

			brushChartInternal(extent) {
				const from = moment(extent[0]).tz(this.timeZone);
				const to = moment(extent[1]).tz(this.timeZone);
				const brushFrom = from.add(moment().utcOffset() - from.utcOffset(), 'minutes');
				const brushTo = to.add(moment().utcOffset() - to.utcOffset(), 'minutes');

				this.setExportRange(brushFrom, brushTo);

				this.brush = {
					from: brushFrom,
					to: brushTo,
				};
			}

			brushChart(extent) {
				this.sending = true;
				const from = moment(extent[0]);
				const to = moment(extent[1]);
				const diffFrom = from - this.from;
				const diffTo = to - this.to;
				this.diffExtent = {diffFrom, diffTo};

				this.brushChartInternal([from, to]);
				if (this.childEmitter) {
					this.childEmitter.timeZone = this.timeZone;
					this.childEmitter.lastExtent = extent;
					this.childEmitter.diffExtent = this.diffExtent;
					this.childEmitter.emit('brushChart', {diffFrom, diffTo});
				}
				this.sendingTimeout = window.setTimeout(() => {
					this.sending = false;
				}, 200);
			}

			brushChartAdjust(extent) {
				const from = moment(extent[0]);
				const to = moment(extent[1]);
				const diffFrom = from - this.from;
				const diffTo = to - this.to;
				this.diffExtent = {diffFrom, diffTo};
				if (this.childEmitter) {
					this.childEmitter.timeZone = this.timeZone;
					this.childEmitter.lastExtent = extent;
					this.childEmitter.diffExtent = this.diffExtent;
				}
			}

			brushChartRemote(diff, extent = null) {
				if (!this.sending) {
					let from, to;

					if (extent) {
						from = moment(extent[0]);
						to = moment(extent[1]);
					} else {
						const {diffFrom, diffTo} = diff;
						from = moment(this.from).add(diffFrom);
						to = moment(this.to).add(diffTo);
					}

					this.brushChartInternal([from, to]);
					this.emitBrushDebounsed([from.toDate(), to.toDate(), 1]);
				}
				if (this.sendingTimeout) {
					window.clearInterval(this.sendingTimeout);
				}
				this.sendingTimeout = window.setTimeout(() => {
					this.sending = false;
				}, 200);
			}

			sendSyncRangeDebounce() {
				if (this.childEmitter) {
					this.sending = true;
					this.childEmitter.emit('syncRange', {
						from: this.from,
						to: this.to,
					});
					if (this.sendingTimeout) {
						window.clearInterval(this.sendingTimeout);
					}
					this.sendingTimeout = window.setTimeout(() => {
						this.sending = false;
					}, 400);
				}
			}

			sendSyncRange() {
				if (this.sendSyncTimerID) {
					window.clearTimeout(this.sendSyncTimerID);
				}
				this.sendSyncTimerID = window.setTimeout(() => {
					this.sendSyncRangeDebounce();
					this.sendSyncTimerID = null;
				}, 300);
			}

			setRangeDebounce({emitUpdateChartRange = true} = {}) {
				Object.assign(this.exportRange, {from: this.from, to: this.to});
				this.rangeEnd = moment(this.to).add(-1, 'days');
				this.calendarRange = moment(this.to).diff(moment(this.from), 'days');
				this.maxDt = moment()
					.tz(this.timeZone)
					.startOf('day');
				urlService.changeDateRangeInUrl(this.from, this.rangeEnd);
				emitUpdateChartRange && this.eventObject.emit('updateChartRange', {range: this});

				if (this.childEmitter) {
					this.childEmitter.from = this.from;
					this.childEmitter.to = this.to;
					this.childEmitter.dateDiff = this.dateDiff;
				}
			}

			setRange({emitUpdateChartRange = true} = {}) {
				if (this.timerID) {
					window.clearTimeout(this.timerID);
				}
				this.timerID = window.setTimeout(() => {
					this.setRangeDebounce({emitUpdateChartRange});
					this.timerID = null;
				}, 500);
			}

			setExportRange(from, to) {
				Object.assign(this.exportRange, {from, to});
			}

			syncRangeInternal(range) {
				const {from, to} = _range.get(this);
				if (!(from.isSame(range.from) && to.isSame(range.to))) {
					this.setRangeInternal(range);
					this.setRange();
				}
			}

			changeRangeInternal(direction) {
				const shift = moment(this.to).diff(moment(this.from), 'days');
				let newRangeFrom = moment(this.from).add(shift * (direction === 'prev' ? -1 : 1), 'days');
				let maxRangeFrom = moment()
					.tz(this.timeZone)
					.add(1 - shift, 'days')
					.startOf('day');

				if (newRangeFrom > maxRangeFrom) {
					newRangeFrom = maxRangeFrom;
				}

				this.setRangeInternal({
					from: newRangeFrom.tz(this.timeZone),
					to: moment(newRangeFrom).add(shift, 'days'),
				});
				this.setRange();
			}

			changeRange(direction) {
				this.sending = true;
				this.changeRangeInternal(direction);
				if (this.childEmitter) {
					this.childEmitter.emit('changeRange', direction);
				}
				this.sendingTimeout = window.setTimeout(() => {
					this.sending = false;
				}, 200);
			}

			syncRangeRemote(range) {
				if (!this.sending) {
					this.syncRangeInternal(Object.assign({}, range));
				}
				if (this.sendingTimeout) {
					window.clearInterval(this.sendingTimeout);
				}

				this.sendingTimeout = window.setTimeout(() => {
					this.sending = false;
				}, 200);
			}

			changeRangeRemote(direction) {
				if (!this.sending) {
					this.changeRangeInternal(direction);
				}
				if (this.sendingTimeout) {
					window.clearInterval(this.sendingTimeout);
				}

				this.sendingTimeout = window.setTimeout(() => {
					this.sending = false;
				}, 200);
			}

			setAsMain() {
				this.main = true;
				this.setAsChild();
				this.childEmitter.from = this.from;
				this.childEmitter.to = this.to;
				this.childEmitter.dateDiff = this.dateDiff;
				if (!this.diffExtent) {
					const getDiff = momentDate => (momentDate.utcOffset() - moment.tz(momentDate, moment.tz.guess()).utcOffset()) * 60 * 1000;
					this.diffExtent = {
						diffFrom: getDiff(this.from),
						diffTo: getDiff(this.to),
					};
				}
				this.childEmitter.diffExtent = this.diffExtent;
			}

			setAsChild({emitUpdateChartRange = true} = {}) {
				if (syncEmitter && syncEmitter.from && syncEmitter.to && (syncEmitter.from !== this.from || syncEmitter.to !== this.to)) {
					this.setRangeInternal({from: syncEmitter.from, to: syncEmitter.to});
					this.setRange({emitUpdateChartRange});
				}
				if (!this.childEmitter) {
					this.childEmitter = syncEmitter;
					this.childEmitter.on('brushChart', this.brushChartRemoteBinded);
					this.childEmitter.on('changeRange', this.changeRangeRemoteBinded);
					this.childEmitter.on('syncRange', this.syncRangeRemoteBinded);
					this._syncronized = true;
				}
				if (this.childEmitter.diffExtent) {
					this.chartReadyPromise.then(() => {
						setTimeout(() => {
							this.brushChartRemote(
								this.childEmitter.diffExtent,
								this.childEmitter.timeZone && this.childEmitter.timeZone !== this.timeZone && this.childEmitter.lastExtent
							);
						}, 500);
					});
				}
			}

			setAsStandAlone() {
				if (this.childEmitter) {
					this.childEmitter.off('brushChart', this.brushChartRemoteBinded);
					this.childEmitter.off('changeRange', this.changeRangeRemoteBinded);
					this.childEmitter.off('syncRange', this.syncRangeRemoteBinded);
					if (this.main) {
						this.childEmitter.dateDiff = undefined;
						delete this.childEmitter.from;
						delete this.childEmitter.to;
						delete this.childEmitter.diffExtent;
						this.main = false;
					}
					this.childEmitter = null;
					this._syncronized = false;
				}
			}

			unSubscribeAll() {
				syncEmitter.off('brushChart', this.brushChartRemoteBinded);
				syncEmitter.off('changeRange', this.changeRangeRemoteBinded);
				syncEmitter.off('syncRange', this.syncRangeRemoteBinded);
				if (this.eventObject) {
					this.eventObject.removeEvent();
				}
			}

			emitBrushDebounsed(data) {
				if (this.emitBrushID) {
					window.clearTimeout(this.emitBrushID);
				}
				this.emitBrushID = window.setTimeout(() => {
					this.eventObject.emit('brushChart', data);
				}, 200);
			}
		}

		class AbstractChart {
			constructor(initRange, locationId, chartId, index) {
				this.chartIndex = index || chartIndex;
				chartIndex = this.chartIndex + 1;

				this.chartReady = false;
				this.locationDetailsComplete = false;
				this.eventObject = new EventEmitter();
				this.passedChartState = null;
				this.chartObj = {
					locationId: parseInt(locationId) || null,
					chartId: parseInt(chartId) || null,
				};
				this.isChartLegendExpanded = true;
				if (!initRange) {
					initRange = {};
				}
				const {startDate, endDate} = initRange;
				this.afterinitRangeVariables = locationDetailsService.getLocationDetailsWithoutServiceAdvisories(this.chartObj.locationId).then(result => {
					this.range = new ChartRange(locationDetailsService.getLocationTimezone(), this.eventObject);
					this.initRangeVariables(startDate, endDate);
					this.eventObject.emit('rangeReady');
					return result;
				});

				this.eventObject.on('brushChart', values => {
					if (!values[2]) {
						this.range.brushChart(values);
					}
				});
				this.eventObject.on('brushChartAdjust', values => {
					this.range.brushChartAdjust(values);
				});
			}

			get facilityServiceAdvisoryCount() {
				return facilityServiceAdvisoryCountMap.get(this);
			}

			set facilityServiceAdvisoryCount(count) {
				facilityServiceAdvisoryCountMap.set(this, Number.isInteger(+count) ? Number(count) : '?');
				this.eventObject.emit('setFacilityServiceAdvisoryCount', this.facilityServiceAdvisoryCount);
			}

			get facilityNotesCount() {
				return facilityNotesCountMap.get(this);
			}

			set facilityNotesCount(count) {
				facilityNotesCountMap.set(this, count);
				this.eventObject.emit('setFacilityNotesCount', this.facilityNotesCount);
			}

			initRangeVariables(startDate, endDate) {
				if (startDate && endDate) {
					const from = moment.tz(startDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, this.range.timeZone).startOf('day');
					const to = moment.tz(endDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, this.range.timeZone).startOf('day');

					const urlDateRangeFormatIsValid = helpers.checkUrlRangeFormat(from, to, startDate, endDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT);
					const urlDateRangeIsValid = helpers.checkUrlRangeValidity(from, to, this.range.timeZone);

					if (!urlDateRangeFormatIsValid) {
						ErrorPageFactory.createErrorPage(INVALID_DATE_FORMAT_KEY, null, PAGE_TYPE.CHART);
					} else if (!urlDateRangeIsValid) {
						ErrorPageFactory.createErrorPage(INVALID_DATE_FUTURE_KEY, null, PAGE_TYPE.CHART);
					} else {
						this.range.setRangeInternal({from, to: to.add(1, 'days')});
					}
					if (!urlDateRangeFormatIsValid || !urlDateRangeIsValid) {
						urlService.changeUrl(PATH_404);
					}
				} else {
					urlService.changeDateRangeInUrl(this.range.from, moment(this.range.to).add(-1, 'days'));
				}
				this.range.setExportRange(this.range.from, this.range.to);
			}

			updatePageTitle(title) {
				this.eventObject.emit('updatePageTitle', title);
			}

			setChartObjectProperty(name, value) {
				this.chartObj[name] = value;
			}

			setChartWithAddPropsSupport(value) {
				this.isChartWithAddPropsSupport = value;
			}

			changeLoadedCharts(value) {
				if (Number.isInteger(+value)) {
					this.loadedCharts += value;
				}
				this.eventObject.emit('changeLoadedCharts', value);
			}

			addChart() {
				this.eventObject.emit('addChart');
			}

			removeChart() {
				this.eventObject.emit('removeChart');
			}

			createPageTitle(pageTitle) {
				this.pageTitle = pageTitle;
				this.eventObject.emit('createPageTitle', this.pageTitle);
			}

			setLocationServiceAdvisoriesCountInScope(count) {
				this.facilityServiceAdvisoryCount = count;
				this.eventObject.emit('setLocationServiceAdvisoriesCountInScope', count);
			}

			setLocationNotesCountInScope(count) {
				this.facilityNotesCount = count;
				this.eventObject.emit('setLocationNotesCountInScope', count);
			}

			setChartReady() {
				this.chartReady = true;
				this.afterinitRangeVariables.then(() => {
					this.range.chartReadyEvent.emit('chartReady', 1);
				});
			}

			toggleSync() {
				this.eventObject.emit('toggleSync', {emitUpdateChartRange: true});
			}
		}

		class ParetoChart extends AbstractChart {
			constructor(initRange, locationId, chartId, eqType, serviceAdvisoryTypeId, paretoChartSortOrder, selectedFacilities, index) {
				super(initRange, locationId, chartId, index);
				this.isParetoChart = true;
				this.isFacilityChart = true;
				this.chartObj.viewTimeline = false;
				this.chartObj.equipmentType = parseInt(eqType) || null;
				this.chartObj.serviceAdvisoryTypeId = parseInt(serviceAdvisoryTypeId) || null;
				this.chartObj.paretoChartSortOrder = paretoChartSortOrder;
				this.chartObj.selectedFacilities = selectedFacilities;

				this.afterinitRangeVariables.then(locationDetailsLoadedHandler.bind(this));

				function locationDetailsLoadedHandler(data) {
					this.location = data.locationSummaryList[0];
					this.navigation = [];
					const pageTitle = {
						title: this.location.locationName,
					};
					this.createPageTitle(pageTitle);

					locationDetailsService
						.getLocationServiceAdvisoriesCount(this.chartObj.locationId)
						.then(count => this.setLocationServiceAdvisoriesCountInScope(count))
						.catch(() => this.setLocationServiceAdvisoriesCountInScope());

					locationDetailsService
						.getLocationNotesCount(this.chartObj.locationId)
						.then(count => this.setLocationNotesCountInScope(count))
						.catch(() => this.setLocationNotesCountInScope());

					$timeout(() => {
						this.locationDetailsComplete = true;
					});
				}
			}
		}

		class FacilityPerformanceChart extends ParetoChart {
			constructor(initRange, locationId, chartId, eqType, serviceAdvisoryTypeId, paretoChartSortOrder, selectedFacilities, index) {
				super(initRange, locationId, chartId, eqType, serviceAdvisoryTypeId, paretoChartSortOrder, selectedFacilities, index);
				this.isFacilityPerformanceChart = true;
			}
		}

		class EquipmentChart extends AbstractChart {
			constructor(
				initRange,
				locationId,
				chartId,
				equipmentId,
				equipmentTypeNumber,
				equipmentTypeChart,
				index,
				// To support instance level chart (ex : Motor power performance)
				selectedInstanceEquipmentType,
				instanceName,
				instanceId
			) {
				super(initRange, locationId, chartId, index);
				this.equipmentLevel = true;
				this.equipmentTypeChart = equipmentTypeChart;
				this.chartConfig = chartConfigService.createNew();
				const locationDetailsScope = {};
				this.chartObj.equipmentId = equipmentId;
				this.chartObj.equipmentType = equipmentTypeNumber;
				this.chartObj.viewTimeline = true;
				const chillerSubComponents = {
					Circuit: [],
					Compressor: [],
				};
				// To handle instance level charts (Motor power performance)
				this.chartObj.selectedInstanceEquipmentType = selectedInstanceEquipmentType || null;
				this.chartObj.instanceName = instanceName || null;
				this.chartObj.instanceId = instanceId || null;

				this.afterinitRangeVariables.then(locationDetailsLoadedHandler.bind(this));

				function locationDetailsLoadedHandler(locationDetailsData) {
					let that = this;
					this.location = locationDetailsData.locationSummaryList[0];
					if (this.equipmentTypeChart) {
						this.defaultEquipmentType = {
							name: $translate('OPTIONS_SELECT_ONE'),
						};
						this.optionFacility = {
							name: $translate('CHART_OPTIONS_FACILITY'),
						};
						// for beta version
						this.equipmentTypeChartBeta = true;
					}

					function saveChillerSubComponents(tisObjects = []) {
						tisObjects.forEach(function(tisObject) {
							if (chillerSubComponents[tisObject.tisObjectType.tisObjectTypeGroupName]) {
								chillerSubComponents[tisObject.tisObjectType.tisObjectTypeGroupName].push(tisObject);
							}
							if (tisObject.children) {
								saveChillerSubComponents(tisObject.children);
							}
						});
					}
					if (this.equipmentLevel && (!this.canRemoveChart || this.chartObj.equipmentId)) {
						locationEquipmentService.getLocationObjectsList(locationId, null, true).then(function(data) {
							let groupNames = [];

							let tisObjectsHierarchy = data.tisObjectList;
							locationDetailsScope.equipment = $filter('filterNested')(tisObjectsHierarchy, {tisObjectId: parseInt(equipmentId)}, true).pop();
							locationDetailsScope.groupName = locationDetailsScope.equipment.tisObjectType.tisObjectTypeGroupName;

							const workingTisObjectIds = [locationDetailsScope.equipment.tisObjectId];

							if (locationDetailsScope.groupName === 'Chiller') {
								groupNames = [locationDetailsScope.groupName, 'Circuit', 'Compressor'];
								saveChillerSubComponents(locationDetailsScope.equipment.children);

								Object.keys(chillerSubComponents).forEach(key =>
									chillerSubComponents[key].forEach(equipment => workingTisObjectIds.push(equipment.tisObjectId))
								);
							} else {
								groupNames = [locationDetailsScope.groupName];
							}

							workingTisObjectIds.map(tisObjectId => {
								locationEquipmentService.getEquipmentServiceAdvisoryCount(tisObjectId, function(result) {
									if (locationDetailsScope.equipmentServiceAdvisoryCount) {
										locationDetailsScope.equipmentServiceAdvisoryCount =
											result.serviceAdvisoryStatisticsList[0].serviceAdvisoriesCount + locationDetailsScope.equipmentServiceAdvisoryCount;
										that.setEquipmentServiceAdvisoryCountInScope(locationDetailsScope.equipmentServiceAdvisoryCount);
									} else {
										locationDetailsScope.equipmentServiceAdvisoryCount = result.serviceAdvisoryStatisticsList[0].serviceAdvisoriesCount;
										that.setEquipmentServiceAdvisoryCountInScope(locationDetailsScope.equipmentServiceAdvisoryCount);
									}
								});
							});
						});
						locationEquipmentService.getEquipmentNotesCount(this.chartObj.equipmentId, this.setEquipmentNotesCountInScope.bind(this));
					}

					locationDetailsService
						.getLocationServiceAdvisoriesCount(this.chartObj.locationId)
						.then(count => this.setLocationServiceAdvisoriesCountInScope(count))
						.catch(() => this.setLocationServiceAdvisoriesCountInScope());

					locationDetailsService
						.getLocationNotesCount(this.chartObj.locationId)
						.then(count => this.setLocationNotesCountInScope(count))
						.catch(() => this.setLocationNotesCountInScope());

					locationEquipmentService.getLocationObjectsList(this.chartObj.locationId).then(data => {
						if (this.equipmentTypeChart) {
							let patternObject = {
								tisObjectType: {
									tisObjectTypeGroupName: this.chartObj.equipmentType,
								},
							};

							this.equipmentType = $filter('filter')(data.tisObjectList, patternObject, true).pop();
						} else {
							this.equipment = data.tisObjectList.find(obj => obj.tisObjectId === this.chartObj.equipmentId);
						}

						this.navigation = [
							{
								href: '#/facility/' + locationId,
								title: this.location.locationName,
							},
						];

						this.eventObject.emit('setNavigation', this.navigation);
						const pageTitle = {};

						if (this.equipmentLevel && this.equipment) {
							pageTitle.title = this.equipment.tisObjectName;
						} else {
							pageTitle.title = this.location.locationName;
						}
						this.createPageTitle(pageTitle);
					});
					$timeout(() => {
						this.locationDetailsComplete = true;
					});
				}
			}

			setChartLocation(location) {
				this.location = location;
				this.navigation = [
					{
						href: '#/facility/' + location.locationId,
						title: this.location.locationName,
					},
				];

				this.eventObject.emit('setNavigation', this.navigation);

				const pageTitle = {title: ''};
				this.createPageTitle(pageTitle);
			}

			setEquipmentServiceAdvisoryCountInScope(responseData) {
				if (responseData) {
					this.equipmentServiceAdvisoryCount = responseData;
					this.eventObject.emit('setEquipmentServiceAdvisoryCountInScope', this.equipmentServiceAdvisoryCount);
				}
			}

			setEquipmentNotesCountInScope(count) {
				this.equipmentNotesCount = count;
				this.eventObject.emit('setEquipmentNotesCountInScope', this.equipmentNotesCount);
			}
		}

		class FacilityChart extends AbstractChart {
			constructor(initRange, locationId, chartId, eqType, selectedFacilities, index) {
				super(initRange, locationId, chartId, index);
				this.isFacilityChart = true;
				this.chartConfig = chartConfigService.createNew();

				this.chartObj.viewTimeline = false;
				this.chartObj.equipmentType = eqType === 'facility' ? eqType : parseInt(eqType) || null;
				this.chartObj.selectedFacilities = selectedFacilities;
				this.afterinitRangeVariables.then(locationDetailsLoadedHandler.bind(this));

				function locationDetailsLoadedHandler(locationDetailsData) {
					this.location = locationDetailsData.locationSummaryList[0];
					this.navigation = [];
					const pageTitle = {
						title: this.location.locationName,
					};

					this.createPageTitle(pageTitle);

					locationDetailsService
						.getLocationServiceAdvisoriesCount(this.chartObj.locationId)
						.then(count => this.setLocationServiceAdvisoriesCountInScope(count))
						.catch(() => this.setLocationServiceAdvisoriesCountInScope());

					locationDetailsService
						.getLocationNotesCount(this.chartObj.locationId)
						.then(count => this.setLocationNotesCountInScope(count))
						.catch(() => this.setLocationNotesCountInScope());

					$timeout(() => {
						this.locationDetailsComplete = true;
					});
				}
			}
		}

		this.getNewChart = (initRange, locationId, chartId) => {
			return new AbstractChart(initRange, locationId, chartId);
		};

		this.getNewFacilityChart = (initRange, locationId, chartId, eqType, selectedFacilities, index) => {
			return new FacilityChart(initRange, locationId, chartId, eqType, selectedFacilities, index);
		};

		this.getNewParetoChart = (initRange, locationId, chartId, eqType, serviceAdvisoryTypeId, paretoChartSortOrder, selectedFacilities, index) => {
			return new ParetoChart(initRange, locationId, chartId, eqType, serviceAdvisoryTypeId, paretoChartSortOrder, selectedFacilities, index);
		};

		this.getNewFacilityPerformanceChart = (
			initRange,
			locationId,
			chartId,
			eqType,
			serviceAdvisoryTypeId,
			paretoChartSortOrder,
			selectedFacilities,
			index
		) => {
			return new FacilityPerformanceChart(initRange, locationId, chartId, eqType, serviceAdvisoryTypeId, paretoChartSortOrder, selectedFacilities, index);
		};

		this.getNewEquipmentChart = (
			initRange,
			locationId,
			chartId,
			equipmentId,
			equipmentTypeNumber,
			equipmentTypeChart,
			index,
			// To support instance level chart (ex : Motor power performance)
			selectedInstanceEquipmentType,
			instanceName,
			instanceId
		) => {
			return new EquipmentChart(
				initRange,
				locationId,
				chartId,
				equipmentId,
				equipmentTypeNumber,
				equipmentTypeChart,
				index,
				// To support instance level chart (ex : Motor power performance)
				selectedInstanceEquipmentType,
				instanceName,
				instanceId
			);
		};

		return this;
	};

	angular.module('TISCC').factory('ChartFactory', ChartFactory);
})();
