/* eslint-disable max-nested-callbacks */

import {CACHE_EXPIRE, REQUEST_MATCH_HEADERS} from '../sw/config';

const validCacheExpiryTimes = Object.keys(CACHE_EXPIRE).reduce((ac, expiryKey) => {
	const key = CACHE_EXPIRE[expiryKey];

	if (key) {
		ac['' + key] = 'valid';
	}

	return ac;
}, {});

/* eslint-disable no-console */
angular.module('TISCC').service('handleServiceWorker', function($cacheFactory) {
	let that = this;

	this.clearSWCache = function() {
		if ('serviceWorker' in navigator) {
			// Unregister all SW first
			navigator.serviceWorker.getRegistrations().then(function(registrations) {
				const unregisterPromises = [];
				for (let registration of registrations) {
					console.log('SW in this page :', registration);
					unregisterPromises.push(registration.unregister());
				}

				Promise.allSettled(unregisterPromises)
					.then(() => {
						// then clear all caches
						let isThereExistingCahce = false;
						caches.keys().then(function(cacheNames) {
							console.log('Cache in this page :', cacheNames);
							const deletePromises = cacheNames.map(function(cacheName) {
								isThereExistingCahce = true;
								return caches.delete(cacheName);
							});

							isThereExistingCahce &&
								Promise.allSettled(deletePromises).then(() => {
									// Reload the application once removed the caches
									setTimeout(() => {
										console.log('start reloading the page after 3000sec');
										location && location.reload(true);
									}, 1000);
								});
						});
					})
					.catch(unregistrationError => {
						console.log('Unregistering SW is failed due to :', unregistrationError);
					});
			});
		}
	};

	this.clearSWCacheOnSignOff = function() {
		// Remove all user, session related data
		return caches.keys().then(function(cacheNames) {
			cacheNames.filter(cacheName => cacheName.startsWith('cc-session-api-')).forEach(function(cacheName) {
				caches.delete(cacheName);
			});
		});
	};

	/**
	 * CACHE_EXPIRE_TIME
	 * 	IN_1_MIN
	 * 	IN_10_MINS
	 * 	IN_15_MINS
	 * 	IN_55_MINS
	 * 	IN_1_HOUR
	 * 	IN_3_HOURS
	 * 	IN_4_HOURS
	 * 	IN_1_DAY
	 * 	IN_3_DAYS
	 * 	IN_7_DAYS
	 */
	this.CACHE_EXPIRE_TIME = {...CACHE_EXPIRE};

	this.CACHE_NAME = {
		DEFAULT_API: 'app-api',
		SESSION: 'session-api',
	};

	/**
	 * @param {createServiceWorkerCacheKeyForRequest} config
	 * @param {string} [config.cacheKey] (required) Cache key name
	 * @param {number} [config.expiryTime] (required) The maximum age of the cache before
	 * it's treated as stale and removed.
	 * @param {string} [config.cacheName] (optional) store url in given Cache name. default cache name= 'AppApi'
	 * only two api cahces are configured in sw (app-api / session-api)
	 * @param {boolean} [config.disableCache] (optional) Disable the request to be cached
	 */
	this.createServiceWorkerCacheKeyForRequest = function(config) {
		const {cacheKey = null, expiryTime = null, cacheName = 'app-api', disableCache = false} = config;
		const header = {};
		if (cacheKey && cacheKey !== null && validCacheExpiryTimes[expiryTime] && !disableCache) {
			// eslint-disable-next-line no-undef
			header[REQUEST_MATCH_HEADERS.CACHE_KEY] = process.env.APP_CURRENT_VERSION + '_' + cacheKey;
			header[REQUEST_MATCH_HEADERS.CACHE_EXPIRE] = expiryTime;
			header[REQUEST_MATCH_HEADERS.CACHE_NAME] = cacheName;
		}

		if (disableCache) {
			header[REQUEST_MATCH_HEADERS.CACHE_ALLOW] = 'block';
		}

		return header;
	};
});
