/**
 * Checkbox shared component
 *
 * @params {object} bindings
 * @params {boolean} bindings.disabled
 * @params {string} bindings.id
 * @params {string} bindings.label
 * @params {boolean|null} bindings.value
 * @params {function} bindings.onChange - On change handler. Receives the changed value.
 *
 * @example
 * <tiscc-checkbox
 *		data-id="unique-id"
 *		data-label="Label text"
 *		data-value="true"
 *		data-on-change="onChange">
 * </tiscc-checkbox>
 */
angular.module('TISCC').component('tisccCheckbox', {
	templateUrl: 'common/checkbox/checkbox.html',
	bindings: {
		disabled: '<',
		inputId: '@',
		label: '@',
		value: '<',
		onChange: '=',
	},
});
