import * as R from 'ramda';
const COMP_COMMUNICATION_STATE_DOWN = 'down';
const COMP_TOTAL_LIFE = 'totalLife';
const COMP_TOTALS_STARTS_TOTAL_LIFE = 'totalStartsTotalLife';
const CHILLER_ENTERED_SERVICE = 'ChillerEnteredService';

/**
 * A standlone service layer to calculate run hours and starts for compressors (single / multiple)
 */
angular
	.module('TISCC')
	.service('compressorRunHoursAndStartsService', function(
		chillerPerformanceReportConfig,
		dataFormattingService,
		helpers,
		locationDetailsService,
		tisObjectService
	) {
		const deps = {
			cprConfig: chillerPerformanceReportConfig,
			dataFormattingService,
			helpers,
			locationDetailsService,
			tisObjectService,
		};

		// ------------------------------ Core functions which are exposed as public api ------------------------------

		async function getCompressorRunHoursAndStarts({cprSectionName, equipment, dataCollectionStartTimestamp, fromDate, tisObjectId, toDate}) {
			const section = deps.cprConfig[cprSectionName];
			const {properties, uoms} = section;

			if (!properties) return {};

			const propertiesKeys = Object.keys(properties);

			const timezone = locationDetailsService.getLocationTimezone();

			const otherProps = {
				cprSectionName,
				dataCollectionStartTimestamp,
				fromDate,
				properties,
				propertiesKeys,
				section,
				timezone,
				tisObjectId,
				toDate,
				uoms,
			};

			try {
				const compressorDeatils = getCompressorDetails(equipment);

				const apiRespone = await getDataFromApi(otherProps);

				const compressorWiseData = await splitChillerDetailsByCompressorCount(apiRespone);

				const propertyWiseData = transformIntoPropertyWiseData(compressorWiseData, otherProps);

				await getCompressorPropertiesDetails(propertyWiseData, otherProps);

				const rangeKeyWiseData = transformIntoRangeKeyWise(propertyWiseData, otherProps);

				const finalData = await transformIntoFinalData(rangeKeyWiseData, {...otherProps, compressorDeatils});

				const sortedFinalData = sortByCompressorCircuitAndInstance(finalData);

				return sortedFinalData; // [[{},{},{}], [{},{},{}],[{},{},{}]]
			} catch (error) {
				// DON'T REMOVE until this feature is stable - for debug purpose in all env incase if any error

				// eslint-disable-next-line no-console
				console.error('Dev purpose : error message =>', error.message);

				// eslint-disable-next-line no-console
				console.error('Dev purpose : error cause  =>', error.cause);

				throw new Error(error.message, {cause: error});
			}
		}

		// ------------------------------ public methods  ------------------------------

		return {getCompressorRunHoursAndStarts};

		// ------------------------------ Priveate functions used by Core functions  ------------------------------

		function getCompressorDetails(chiller) {
			const circuits = chiller.children || [];

			if (R.isEmpty(circuits)) return {};

			return circuits.reduce((av, {children = [], instance}) => {
				children.forEach(compressor => {
					av[compressor.tisObjectId] = {
						circuitInstance: instance || '',
						instance: compressor.instance || '',
						type: R.pathOr('Compressor', ['compressor', 'tisObjectType', 'tisObjectTypeGroupName'], compressor),
					};
				});
				return av;
			}, {});
		}

		/**
		 * (Immutable | side effects) function to get compressor data from server
		 *
		 * @param {*} otherProps
		 * @returns Promise
		 */
		function getDataFromApi(otherProps) {
			const {tisObjectService} = deps;
			const {dataCollectionStartTimestamp, fromDate, properties, propertiesKeys, section, timezone, tisObjectId, toDate, uoms} = otherProps;

			const allowedApi = {
				totalRunHoursThisMonth: 'thisMonth',
				totalRunHoursLastMonth: 'lastMonth',
				totalRunHoursTotalLife: 'totalLife',
			};

			const apiRequestPromise = propertiesKeys.reduce((promiseArr, key) => {
				const property = properties[key];

				if (!allowedApi[key] || !property || !R.is(Function, property.range)) return promiseArr;

				const range = R.bind(property.range, section)(fromDate, toDate, dataCollectionStartTimestamp);

				const params = {
					ids: [tisObjectId],
					hpath: '//Compressor@CompressorRunningTime,//Compressor@CompressorStarts,@CommunicationState',
					enumerations: '@CommunicationState=communicationStatusChiller/communicationStatusChillerDisplay',
					from: moment(range.fromDate),
					to: moment(range.toDate),
					timeZone: timezone,
					interval: 'PT15M',
					uoms,
					rangeKey: key,
				};

				const fallbackCalculationApi = fallbackCalculationApiSetup(params, allowedApi[key]);

				const promise = tisObjectService.getAllValuesByIds(params).then(data => ({data, rangeKey: allowedApi[key], fallbackCalculationApi}));
				promiseArr.push(promise);

				return promiseArr;
			}, []);

			return Promise.all(apiRequestPromise).then(
				data => data,
				error => {
					throw new Error('Promise All failed : Unable to complete getDataFromApi request', {cause: error});
				}
			);
		}

		/**
		 * (Immutable | side effects) function to get compressor data from server to calculate fallback (secondary) approach
		 *
		 * @param {*} params
		 * @param {*} rangeKey
		 * @returns
		 */
		function fallbackCalculationApiSetup(params, rangeKey) {
			const {tisObjectService} = deps;

			let {hpath, enumerations, to, ...rest} = params;

			return () => {
				const queryParams = {
					...rest,
					to: moment(to).add(1, 'seconds'),
					hpath: '//Compressor@CompressorOutputNormalized',
				};

				return tisObjectService.getAllValuesByIds(queryParams).then(data => ({data, rangeKey}));
			};
		}

		/**
		 * (Immutable) function to convert single chiller object which contains one or more compressor data into compressor wise
		 *
		 * @param {*} chillerWithCompressors
		 * @returns {*} object i.e : { [rangeKey] : { [compressorId] : {compressor-data}, ...}, ...}
		 */
		async function splitChillerDetailsByCompressorCount(chillerWithCompressors) {
			const accumulator = {};

			for (let index = 0; index < chillerWithCompressors.length; index++) {
				const compressor = chillerWithCompressors[index];
				const {data, rangeKey, fallbackCalculationApi} = compressor;

				accumulator[rangeKey] = await _transformIntoCompressorWiseData(data.tisObjectDataList[0], rangeKey, fallbackCalculationApi);
			}
			return accumulator;
		}

		/**
		 * (Immutable) helper function to convert single chiller object which contains one or more compressor data into compressor wise
		 *
		 * @param {*} data
		 * @param {*} rangeKey
		 * @returns {*} object i.e : { [compressorId] : {compressor-data}, ...}
		 */
		async function _transformIntoCompressorWiseData(data, rangeKey, fallbackCalculationApi) {
			const compressorObjectPath = ['relatedDataEntries', 0, 'dataEntries'];

			// Get compressor details from root chiller
			const getCompressors = R.pathOr([], compressorObjectPath);

			// Remove compressor details on new object
			const chillerDetails = R.compose(R.dissocPath(compressorObjectPath), R.dissocPath(['columns', 0]))(data);

			// if no child compressor, return actual chiller data
			if (R.isEmpty(getCompressors(data))) {
				const updatedCompressorData = _getRunHoursAndStartsIfNoCompressorData(chillerDetails);
				return R.indexBy(R.path(['tisObjectId']))([updatedCompressorData]);
			}

			const calc = compressor => _getRunHoursAndStarts({data, compressor, rangeKey, fallbackCalculationApi});

			const promiseArr = R.compose(R.map(compressor => calc(compressor)), getCompressors)(data);

			const compressorsDetailWithCalculatedRunHourAndStarts = await Promise.all(promiseArr);

			// create chiller object for each compressor
			const mapFn = compressor => R.assocPath([...compressorObjectPath, 0], compressor, R.mergeRight(chillerDetails, {}));

			return R.compose(R.indexBy(R.path([...compressorObjectPath, 0, 'tisObjectId'])), R.map(mapFn))(compressorsDetailWithCalculatedRunHourAndStarts);
		}

		/**
		 * (Immutable) helper function to create fallback object with run hours if no compressor data available
		 *
		 * @param {*} data
		 * @returns {*} object<compressor>
		 */
		function _getRunHoursAndStartsIfNoCompressorData(data) {
			const compressorObjectPath = ['relatedDataEntries', 0, 'dataEntries', 0, 'columns', 0];

			const compressorActualRunTime = [
				{
					timestamp: data.startDate,
					value: 0,
				},
			];

			return R.assocPath(compressorObjectPath, {name: 'ActualRunTime', values: compressorActualRunTime}, data);
		}

		/**
		 * (Mutable | Side effects) helper function to calculate compressor run hours and starts
		 *
		 * @param {*} {data, rangeKey, compressor}
		 * @returns {*} object<compressor>
		 */
		async function _getRunHoursAndStarts({data, rangeKey, compressor, fallbackCalculationApi}) {
			const getData = (obj, key) => R.compose(R.defaultTo({values: []}), R.find(R.propEq('name', key)), R.pathOr({}, ['columns']))(obj);

			const compressorCommunicationState = getData(data, 'CommunicationState');
			const compressorRunningTime = getData(compressor, 'CompressorRunningTime');
			const compressorStarts = getData(compressor, 'CompressorStarts');

			let totalRunHoursAndStarts = {totalRunHours: 0, totalStarts: 0};

			if (R.isEmpty(compressorStarts.values) || R.isEmpty(compressorRunningTime.values)) {
				const fallbackData = await fallbackCalculationApi();
				//	console.group(rangeKey);
				// console.log('compressor', compressor.tisObject.tisObjectName);
				totalRunHoursAndStarts = fallbackCalculation(fallbackData, compressor.tisObjectId);
				compressorRunningTime.fallback = true;
				compressorStarts.fallback = true;
				//	console.groupEnd();
			} else {
				//	console.group('Else :' + rangeKey + '-' + compressor.tisObject.tisObjectName);
				// console.log(compressor);
				totalRunHoursAndStarts = _calculateTotalRunHoursAndStarts(compressorCommunicationState, compressorRunningTime, compressorStarts, rangeKey);

				//	console.log(totalRunHoursAndStarts);

				if (!totalRunHoursAndStarts.isValid) {
					const fallbackData = await fallbackCalculationApi();
					totalRunHoursAndStarts = fallbackCalculation(fallbackData, compressor.tisObjectId);
					compressorRunningTime.fallback = true;
					compressorStarts.fallback = true;

					//	console.log('fallbackData', totalRunHoursAndStarts);
				}
				//	console.groupEnd();
			}

			compressorRunningTime.values = [
				{
					timestamp: data.startDate,
					value: totalRunHoursAndStarts.totalRunHours,
				},
			];
			compressorRunningTime.name = 'ActualRunTime';

			compressorStarts.values = [
				{
					timestamp: data.startDate,
					value: 0,
				},
				{
					timestamp: data.startDate,
					value: totalRunHoursAndStarts.totalStarts,
				},
			];

			return compressor;
		}

		/**
		 * (Immutable) function to calculate totalRunHours and Starts using secondary approach
		 *
		 * @param {*} data
		 * @param {*} tisObjectId
		 * @returns {totalRunHours, totalStarts}
		 */
		function fallbackCalculation(data, tisObjectId) {
			const getCurrentCompressorData = R.compose(
				//	R.tap(v => console.log('getCurrentCompressorData', v.tisObject.tisObjectName)),
				R.find(R.propEq('tisObjectId', tisObjectId)),
				R.pathOr([], ['tisObjectDataList', 0, 'relatedDataEntries', 0, 'dataEntries'])
			);

			const getCompressorOutputNormalizedData = R.compose(
				R.unless(R.complement(R.isEmpty), () => R.identity(null)),
				R.pathOr([], ['values']),
				R.find(R.propEq('name', 'CompressorOutputNormalized')),
				R.pathOr([], ['columns'])
			);

			const compressorOutputNormalizedValues = R.compose(R.defaultTo(null), getCompressorOutputNormalizedData, getCurrentCompressorData)(data.data);

			if (compressorOutputNormalizedValues === null) {
				return {totalRunHours: 0, totalStarts: 0};
			}

			const currentVsPriorStateWithRunHour = {
				'on-on': 15,
				'on-off': 7.5,
				'on-other': 7.5,
				'off-on': 7.5,
				'off-off': 0,
				'off-other': 0,
				'other-on': 7.5,
				'other-off': 0,
				'other-other': 0,
			};

			const currentVsPriorStateWithStarts = {
				'on-on': 0,
				'on-off': 1,
				'on-other': 0,
				'off-on': 0,
				'off-off': 0,
				'off-other': 0,
				'other-on': 0,
				'other-off': 0,
				'other-other': 0,
			};

			const getState = value => {
				const val = String(value).toLowerCase();
				return val === 'on' || val === 'off' ? val : 'other';
			};

			let left = 1;
			let right = compressorOutputNormalizedValues.length - 1;

			let accumulator = {
				totalRunHours: 0,
				totalStarts: 0,
			};

			function getValue(index, data, accumulator) {
				const current = data[index] || {};
				const prior = data[index - 1] || {};

				const currentState = getState(current.value);
				const priorState = getState(prior.value);

				const currentVsPriorState = currentState + '-' + priorState;

				const output = {
					totalRunHours: accumulator.totalRunHours + currentVsPriorStateWithRunHour[currentVsPriorState] || 0,
					totalStarts: accumulator.totalStarts + currentVsPriorStateWithStarts[currentVsPriorState] || 0,
				};

				// eslint-disable-next-line no-console
				// if (output.totalRunHours > 0 || output.totalStarts > 0)
				// //	console.log(
				// 		`${tisObjectId} : c-state - p-state => ${currentState}-${priorState}
				// 			: value - ${current.value}
				// 			: RunHour - ${accumulator.totalRunHours} + ${currentVsPriorStateWithRunHour[currentVsPriorState]} => ${output.totalRunHours}
				// 			: Starts  - ${accumulator.totalStarts} + ${currentVsPriorStateWithStarts[currentVsPriorState]} => ${output.totalStarts}
				// 		`
				// 	);

				return output;
			}

			while (left <= right) {
				accumulator = getValue(left, compressorOutputNormalizedValues, accumulator);

				if (left < right) {
					accumulator = getValue(right, compressorOutputNormalizedValues, accumulator);
				}

				left++;
				right--;
			}

			accumulator.totalRunHours = (accumulator.totalRunHours / 60).toFixed(2) * 1 || 0;

			//	console.log(tisObjectId, ' : accumulator :', accumulator);

			return accumulator;
		}

		/**
		 * (Immutable) function to calculate total Run hours and Starts (default approach)
		 *
		 * @param {*} compressorCommunicationState
		 * @param {*} compressorRunningTime
		 * @param {*} compressorStarts
		 * @param {*} rangeKey
		 * @returns {totalRunHours, totalStarts, isValid}
		 */
		function _calculateTotalRunHoursAndStarts(compressorCommunicationState, compressorRunningTime, compressorStarts, rangeKey) {
			function getValidValue(compressorData, compressorState) {
				return function getValue(index) {
					const compData = compressorData[index];
					const compState = compressorState[index];

					const isInValidCommunicationState = value => {
						const val = String(value).toLowerCase();
						return !(val === 'null' || val !== COMP_COMMUNICATION_STATE_DOWN);
					};

					if (compData.value === null || compData.value <= 0 || isInValidCommunicationState(compState.value)) {
						return null;
					}

					if (compState.timestamp === compData.timestamp) {
						return compData.value || null;
					}

					return null;
				};
			}

			function hasValidValue(begin, end) {
				return end === true && (rangeKey !== COMP_TOTAL_LIFE ? begin === true : true) ? true : null;
			}

			const communicationStateValues = compressorCommunicationState.values || [];

			let totalRunHours = 0;
			let totalStarts = 0;

			let runHourBegin = 0;
			let runHourEnd = 0;
			const runHourValues = compressorRunningTime.values || [];
			const hasDataToCalculateRunHours = !!runHourValues.length;
			const getValidValueForRunHours = getValidValue(runHourValues, communicationStateValues);

			let startsBegin = 0;
			let startsEnd = 0;
			const startsValues = compressorStarts.values || [];
			const hasDataToCalculateStarts = !!startsValues.length;
			const getValidValueForStarts = getValidValue(startsValues, communicationStateValues);

			let isValid = null;

			let leftIndex = 0;
			let runHourRightIndex = runHourValues.length - 1;
			let startsRightIndex = startsValues.length - 1;

			let isRunHourBeginVaild = null;
			let isRunHourEndVaild = null;
			let isStartsBeginVaild = null;
			let isStartsEndVaild = null;

			let increment = 0;

			while (hasDataToCalculateRunHours || hasDataToCalculateStarts) {
				increment++;
				if (hasDataToCalculateRunHours) {
					if (rangeKey !== COMP_TOTAL_LIFE && isRunHourBeginVaild === null) {
						const runHourBeginValue = getValidValueForRunHours(leftIndex);
						if (runHourBeginValue !== null) {
							runHourBegin = runHourBeginValue;
							isRunHourBeginVaild = true;
						}
					}
					if (isRunHourEndVaild === null) {
						const runHourEndValue = getValidValueForRunHours(runHourRightIndex);

						if (runHourEndValue !== null) {
							runHourEnd = runHourEndValue;
							isRunHourEndVaild = true;
						}
					}
				}

				if (hasDataToCalculateStarts) {
					if (rangeKey !== COMP_TOTAL_LIFE && isStartsBeginVaild === null) {
						const startsBeginValue = getValidValueForStarts(leftIndex);
						if (startsBeginValue !== null) {
							startsBegin = startsBeginValue;
							isStartsBeginVaild = true;
						}
					}

					if (isStartsEndVaild === null) {
						const startsEndValue = getValidValueForStarts(startsRightIndex);

						if (startsEndValue !== null) {
							startsEnd = startsEndValue;
							isStartsEndVaild = true;
						}
					}
				}

				leftIndex++;
				isRunHourEndVaild === null && runHourRightIndex--;
				isStartsEndVaild === null && startsRightIndex--;

				const isRunHourValid = hasValidValue(isRunHourBeginVaild, isRunHourEndVaild);
				const isStartsValid = hasValidValue(isStartsBeginVaild, isStartsEndVaild);

				isValid = isRunHourValid === true && isStartsValid === true ? true : isValid;
				if (isValid === false) {
					//	console.log('breaking at no valus found', isRunHourValid, isStartsValid, leftIndex, leftIndex, runHourRightIndex, startsRightIndex);
				}

				// break at middle point
				if (leftIndex > runHourRightIndex && isRunHourValid === null) {
					//	console.log('breaking at runhour', leftIndex, runHourRightIndex, leftIndex > runHourRightIndex);
					isValid = false;
				}

				if (leftIndex > startsRightIndex && isStartsValid === null) {
					//	console.log('breaking at starts', leftIndex, startsRightIndex, leftIndex > startsRightIndex);
					isValid = false;
				}

				if (isValid === true || isValid === false) {
					break;
				}
			}

			//	console.log('before validation :', {runHourBegin, runHourEnd, startsBegin, startsEnd, isValid});

			if (isValid) {
				if (rangeKey === COMP_TOTAL_LIFE) {
					totalRunHours = runHourEnd.toFixed(2) * 1 || 0;
				} else if (runHourBegin === 0 || runHourEnd === 0) {
					totalRunHours = 0;
				} else totalRunHours = (runHourEnd - runHourBegin).toFixed(2) * 1 || 0;

				if (rangeKey === COMP_TOTAL_LIFE) {
					totalStarts = startsEnd.toFixed(2) * 1 || 0;
				} else if (startsEnd === 0 || startsBegin === 0 || startsEnd === startsBegin) {
					totalStarts = totalRunHours ? 1 : 0;
				} else totalStarts = (startsEnd - startsBegin).toFixed(2) * 1 || 0;
			}
			return {totalRunHours, totalStarts, isValid};
		}

		/**
		 * (Immutable) function to transform compressor object into property wise which are configured @ chiller-performance-report-config
		 *
		 * @param {*} compressorWiseData
		 * @param {*} otherProps
		 * @returns [object]  i.e : [ {totalRunHoursThisMonth : {}, totalStartsThisMonth : {}, ... },  ...]
		 */
		function transformIntoPropertyWiseData(compressorWiseData, otherProps) {
			const {properties, propertiesKeys} = otherProps;

			const equipments = Object.keys(compressorWiseData.thisMonth);

			const props = propertiesKeys.reduce((av, key) => {
				const {rangeKey, propertyName, range} = properties[key];
				av[key] = {
					data: null,
					isDataMappingRequired: R.is(Function, range),
					propertyName,
					rangeKey,
					compressorId: null,
				};
				return av;
			}, {});

			return equipments.map(equipmentId => {
				return propertiesKeys.reduce((av, key) => {
					const {rangeKey, isDataMappingRequired} = props[key];
					av[key] = {
						...props[key],
						compressorId: equipmentId,
					};

					if (isDataMappingRequired) {
						const rKey = R.endsWith('MonthMinus', rangeKey) ? rangeKey.replace('Minus', '') : rangeKey;
						const compressorDetails = compressorWiseData[rKey];
						const compressorDetail = compressorDetails[equipmentId];
						av[key].data = [];
						av[key].data.push(compressorDetail);
					}
					return av;
				}, {});
			});
		}

		/**
		 * (Mutable) function to get and update compressor properties into give input
		 *
		 * @param {*} propertyWiseData
		 * @param {*} {cprSectionName}
		 * @returns void
		 */
		function getCompressorPropertiesDetails(propertyWiseData, {cprSectionName}) {
			const allPromise = propertyWiseData.reduce((promiseArr, compressor) => {
				R.forEachObjIndexed(property => {
					if (property.data) {
						const newPromise = _getCompressorPropertiesApi(property.data, cprSectionName).then(propertiesData => {
							property.propertiesData = propertiesData;
						});
						promiseArr.push(newPromise);
					}
				}, compressor);
				return promiseArr;
			}, []);

			return Promise.all(allPromise).then(
				() => null,
				error => {
					throw new Error('Promise All failed : Unable to complete getCompressorPropertiesDetails request', {cause: error});
				}
			);
		}

		/**
		 * (Immutable | side effects) helper function to get properties of tisObjectIds from server
		 *
		 * @param {*} data
		 * @param {*} cprSectionName
		 * @returns Promise<properties>
		 */
		function _getCompressorPropertiesApi(data, cprSectionName) {
			const tisObjectIds = _getTisObjectPropsByPropertyPath(data, 'tisObjectId');
			const promiseArr = tisObjectIds.map(tisObjectId =>
				getObjectProperties({
					cprSectionName,
					tisObjectId,
				})
			);

			return Promise.all(promiseArr).then(
				data => data,
				error => {
					throw new Error('Promise All failed : Unable to complete getCompressorPropertiesApi request', {cause: error});
				}
			);
		}

		/**
		 * (Immutable) function to transform object into rage wise
		 *
		 * @param {*} data
		 * @param {*} otherProps
		 * @returns [object] i.e : [{
		 *
		 * 		thisMonth : { totalRunHoursThisMonth: {}, totalStartsThisMonth: {} },
		 *
		 * 		lastMonth : { totalRunHoursThisMonth: {}, totalStartsThisMonth: {} }
		 *
		 * 	},
		 *	...
		 * ]
		 */
		function transformIntoRangeKeyWise(data, otherProps) {
			const {properties, section, fromDate, toDate, dataCollectionStartTimestamp} = otherProps;

			return data.map(compressor => {
				return Object.keys(compressor).reduce((av, propName) => {
					const {rangeKey, isDataMappingRequired} = compressor[propName];
					if (!av[rangeKey]) {
						av[rangeKey] = {
							configData: {},
						};
					}
					if (isDataMappingRequired) {
						const range = R.bind(properties[propName].range, section)(fromDate, toDate, dataCollectionStartTimestamp);

						av[rangeKey] = {
							...av[rangeKey],
							...compressor[propName],
							range,
						};
					}

					av[rangeKey].configData[propName] = properties[propName];

					return av;
				}, {});
			});
		}

		/**
		 * (Immutable) function to create final output required for UI display the data
		 *
		 * @param {*} data
		 * @param {*} {tisObjectId, timezone}
		 * @returns Array of [
		 *
		 * 	{totalRunHoursThisMonth : {}, totalStartsThisMonth : {}, ... },
		 *
		 * 	{totalRunHoursThisMonth : {}, totalStartsThisMonth : {}, ... },
		 *
		 * ... ]
		 */
		async function transformIntoFinalData(data, {tisObjectId, timezone, compressorDeatils}) {
			const output = [];
			for (let index = 0; index < data.length; index++) {
				const compressor = data[index];
				const compData = await _calculateCompressorData(compressor, tisObjectId, timezone);

				const compressorId = R.pathOr(false, ['thisMonth', 'compressorId'], compressor);

				if (compressorId) {
					compData.push({compressorDetail: compressorDeatils[compressorId]});
				}

				output.push(compData);
			}
			return output;
		}

		/**
		 * (Immutable | side effects) helper function to compose data at required format
		 *
		 * @param {*} queries
		 * @param {*} tisObjectId
		 * @param {*} timezone
		 * @returns Promise
		 *
		 */
		function _calculateCompressorData(queries, tisObjectId, timezone) {
			const {tisObjectService} = deps;
			const queriesKeys = Object.keys(queries);
			const dataQueries = [];

			queriesKeys.forEach(queryKey => {
				const query = queries[queryKey];
				const properties = query.configData;
				const {data, propertiesData} = query;

				const beforeProcess = mapProperties({tisObjectDataList: data, query, propertiesDataList: propertiesData, properties, queryKey});

				const dataQuery = beforeProcess.then(({propertiesInfoMap, propertiesConfigData, tisObjectDataList}) => {
					const data = _processDataList({
						tisObjectDataList,
						propertiesList: Object.keys(properties),
						timezone,
						propertiesInfoMap,
						propertiesConfigData,
						dataFormattingService,
						isFormatResponseValues: false,
					});

					return data;
				});

				dataQueries.push(dataQuery);
			});

			async function mapProperties({tisObjectDataList, query, propertiesDataList, properties, queryKey}) {
				let propertiesInfoMap = {};
				let propertiesConfigData = {};

				const propertiesConfigDataTemp = {};

				propertiesDataList.forEach(propertiesData => {
					const propertiesDataMapped = R.indexBy(R.prop('propertyName'))(propertiesData);

					R.forEachObjIndexed((value, propertyName) => {
						const propertyNameCalculated = value.propertyName || propertyName;
						if (propertiesDataMapped[propertyNameCalculated]) {
							propertiesInfoMap[propertyName] = propertiesDataMapped[propertyNameCalculated];
							propertiesConfigDataTemp[propertyName] = value;
						}
					}, properties);
				});

				const propKeys = R.keys(properties);

				for (let index = 0; index < propKeys.length; index++) {
					const propertyName = propKeys[index];

					propertiesConfigData[propertyName] = propertiesConfigDataTemp[propertyName];

					const prop = propertiesConfigData[propertyName];

					if (prop) {
						propertiesConfigData[propertyName].calculatedRange = {...query.range};

						if (prop.rangeKey === COMP_TOTAL_LIFE && propertyName === COMP_TOTALS_STARTS_TOTAL_LIFE) {
							const params = {
								ids: [tisObjectId],
								enumerations: '',
								timeZone: timezone,
							};

							try {
								await tisObjectService.getCprReportHeader(params).then(res => {
									const chillerEnteredService = res[CHILLER_ENTERED_SERVICE];
									prop.calculatedRange.fromDate = chillerEnteredService ? moment(chillerEnteredService) : 0;
								});
							} catch (error) {
								throw new Error('Promise failed : Unable to complete getCprReportHeader request', {cause: error});
							}
						}
					}
				}

				return {
					propertiesInfoMap,
					propertiesConfigData,
					tisObjectDataList,
				};
			}

			return Promise.all(dataQueries);
		}

		/**
		 * (Immutable) function to sort the final data by compressor circuit and instance
		 *
		 * @param {*} data
		 * @returns []
		 */
		function sortByCompressorCircuitAndInstance(data) {
			// returns value like CKT1-1A, CKT1-1B
			const sortByFn = R.compose(
				R.join('-'),
				R.props(['circuitInstance', 'instance']),
				R.propOr({circuitInstance: 'CKT9', instance: '9A'}, 'compressorDetail'),
				R.last
			);

			return R.sortBy(sortByFn, data);
		}

		/**
		 * (Immutable) function update properties based on avaiable config
		 *
		 * @param {*} param0
		 * @returns object i.e :{totalRunHoursThisMonth : {}, totalStartsThisMonth : {}, ... }
		 */
		function _processDataList({
			tisObjectDataList = [],
			propertiesList,
			timezone,
			propertiesInfoMap,
			propertiesConfigData,
			dataFormattingService,
			colorService,
			isFormatResponseValues = true,
			isProcessAllChildren = false,
			from,
			to,
		}) {
			const result = {};
			const colorGenerator = colorService ? colorService.generator() : null;

			const processColumns = (columns = []) => {
				const columnsMapped = columns.reduce((previousValue, column) => {
					previousValue[column.name] = column;
					return previousValue;
				}, {});

				Object.keys(propertiesConfigData).forEach(propertyKey => {
					const propertyConfigData = propertiesConfigData[propertyKey];
					if (!propertyConfigData) {
						return;
					}

					const key = propertyConfigData.propertyName || propertyKey;
					if (columnsMapped[key]) {
						const column = columnsMapped[key];
						const {propertyAttribute = {}} = propertiesInfoMap[propertyKey] || {};
						const isNeedExtraFormatting = propertyAttribute.resolution !== null;

						let newPropertyObject = {};
						let colorValue = '';

						if (propertyAttribute.colorValue) {
							colorValue = colorGenerator.next(`#${propertyAttribute.colorValue}`).value;
						}
						if (!colorValue && colorGenerator) {
							colorValue = colorGenerator.next().value;
						}

						if (isProcessAllChildren && result[propertyKey]) {
							// Process all chiller's related data entries.
							Array.isArray(result[propertyKey])
								? result[propertyKey].push(newPropertyObject)
								: (() => {
										result[propertyKey] = [result[propertyKey]];
										result[propertyKey].push(newPropertyObject);
								  })();
						} else {
							result[propertyKey] = newPropertyObject;
						}

						Object.assign(
							newPropertyObject,
							{
								name: propertyKey,
								colorValue: colorValue,
							},
							propertyConfigData
						);

						newPropertyObject.fallback = !!column.fallback;

						if (propertyConfigData.propertyName) {
							newPropertyObject.propertyName = propertyConfigData.propertyName;
						}

						if (typeof propertyConfigData.propertyNameFunc === 'function') {
							newPropertyObject.propertyName = propertiesConfigData[propertyKey].propertyNameFunc(tisObjectDataList[0]);
						}

						if (typeof propertyConfigData.postPropertyProcessFunc === 'function') {
							newPropertyObject.postPropertyProcessFunc = propertyConfigData.postPropertyProcessFunc;
						}

						if (propertyConfigData.title) {
							newPropertyObject.propertyName = propertyConfigData.title;
						}

						let preData = !isFormatResponseValues
							? column.values
							: column.values.filter(value => value.timestamp !== undefined && value.value !== undefined).map(value => {
									let y = value.value;
									if (isNeedExtraFormatting) {
										y = dataFormattingService.applyDecimalFormatting(y, propertyAttribute.resolution);
									}
									if (!isNaN(y)) {
										y = parseFloat(y);
									}
									return {
										x: moment(value.timestamp).tz(timezone),
										y,
										dateString: moment(value.timestamp)
											.tz(timezone)
											.format('YYYY-MM-DDTHH:mm:ssZ'),
									};
							  });

						if (typeof propertiesConfigData[propertyKey].dataFilter === 'function') {
							preData = propertiesConfigData[propertyKey].dataFilter.bind(propertiesConfigData[propertyKey])(preData, from, to);
						}

						newPropertyObject.data = preData;
					}
				});
			};

			tisObjectDataList.forEach(tisObjectData => {
				processColumns(tisObjectData.columns);
				tisObjectData.relatedDataEntries &&
					tisObjectData.relatedDataEntries.forEach(relatedDataEntry => {
						relatedDataEntry.dataEntries &&
							relatedDataEntry.dataEntries.forEach(dataEntry => {
								processColumns(dataEntry.columns);
							});
					});
			});
			return result;
		}

		/**
		 * (Immutable) function to get a property value from tisObject
		 *
		 * @param {*} tisObjectDataList
		 * @param {*} propertyPath
		 * @returns [propertyValue]
		 */
		function _getTisObjectPropsByPropertyPath(tisObjectDataList = [], propertyPath) {
			const {helpers} = deps;

			const {getPropertyByPath} = helpers;
			const tisObjectProps = new Set();

			tisObjectDataList.forEach(({relatedDataEntries, ...tisObjectData}) => {
				tisObjectProps.add(getPropertyByPath(tisObjectData, propertyPath));
				Array.isArray(relatedDataEntries) &&
					relatedDataEntries.forEach(({dataEntries}) => {
						Array.isArray(dataEntries) &&
							dataEntries.forEach(dataEntry => {
								const propertyByPath = getPropertyByPath(dataEntry, propertyPath);
								if (propertyByPath) {
									tisObjectProps.add(getPropertyByPath(dataEntry, propertyPath));
								}
							});
					});
			});

			return Array.from(tisObjectProps);
		}

		/**
		 * (Immutable | side effects ) function to get object properties from server
		 *
		 * @param {*} param0
		 * @returns [properties]
		 */
		function getObjectProperties({cprSectionName, tisObjectId, tisObjectTypeGroupNames}) {
			const {tisObjectService, cprConfig} = deps;
			const properties = cprConfig[cprSectionName].properties;
			const propertiesKeyValue = R.indexBy(R.prop('propertyName'), R.values(properties));

			const promise = tisObjectId
				? tisObjectService.getObjectProperties(tisObjectId)
				: tisObjectService.getObjectPropertiesByTisObjectTypeGroupNames(tisObjectTypeGroupNames);

			return promise
				.then(response => response.data)
				.then(({tisObjectPropertyList}) =>
					tisObjectPropertyList.filter(({propertyName} = {}) => !!properties[propertyName] || !!propertiesKeyValue[propertyName])
				);
		}
	});
