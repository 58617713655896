/* global ga*/
import {GA_ACTION_NAMES} from './google-analytics-action-names';

const gaAccountMappings = {
	dev: 'UA-32029121-3',
	stg: 'UA-32029121-4',
	prod: 'UA-32029121-5',
};

const extractLocationID = url => {
	const regex = /.*\/facility\/([0-9]*).*/;
	const matchResult = url.match(regex);
	return matchResult && matchResult[1] ? matchResult[1] : null;
};

export class GoogleAnalyticsService {
	constructor() {
		this.attached = false;
		this.labels = GA_ACTION_NAMES;
		this.ga = (...params) => {
			if (this.attached && ga && ga instanceof Function) {
				ga(...params);
			}
		};
		this.attachGAAccount();
	}

	get googleAnalyticsKey() {
		const {hostname} = window.location;

		// TODO: Rewrite this functionality using environment variable from environment.json
		if (hostname.includes('localhost') || hostname.includes('dev')) {
			return gaAccountMappings.dev;
		} else if (hostname.includes('stg') || hostname.includes('staging')) {
			return gaAccountMappings.stg;
		} else {
			return gaAccountMappings.prod;
		}
	}

	attachGAAccount() {
		try {
			ga('create', this.googleAnalyticsKey, 'auto');
			this.attached = true;
		} catch (err) {
			this.attached = false;
		}
	}

	trackPage(page) {
		this.setLocationID(extractLocationID(page));
		this.ga('set', 'page', page);
		this.ga('send', 'pageview');
	}

	authenticationError(page) {
		this.ga('set', 'page', page);
		this.ga('send', 'event', this.labels.NAVIGATION.NAVIGATION, this.labels.NAVIGATION.AUTH_ERROR, this.labels.NAVIGATION.AUTH_ERROR);
	}

	authorizationError(page, label) {
		this.ga('set', 'page', page);
		this.ga('send', 'event', this.labels.NAVIGATION.NAVIGATION, this.labels.NAVIGATION.AUTH_ERROR, label);
	}

	apiError(path, responseCode) {
		this.ga('send', 'event', this.labels.API, path, path, responseCode);
	}

	setUser(userName) {
		ga('set', {
			dimension1: userName,
		});
	}

	setRoles(primaryRole, secondaryRole = '') {
		ga('set', {
			dimension2: `${primaryRole}`,
			dimension3: `${secondaryRole}`,
		});
	}
	setLocationID(locationID = null) {
		if (locationID) {
			ga('set', {
				dimension4: `${locationID}`,
			});
		}
	}

	setEquipmentId(equipmentId = null) {
		if (equipmentId) {
			ga('set', {
				dimension6: `${equipmentId}`,
			});
		}
	}

	setCprLanguage(language) {
		if (language) {
			ga('set', {
				dimension7: language,
			});
		}
	}

	setCprUnitsOfMeasure(uom) {
		if (uom) {
			ga('set', {
				dimension8: uom,
			});
		}
	}

	sendFlowEvent(category, action, options) {
		if (options.progressKey) {
			ga('set', {
				dimension5: `${options.progressKey}`,
			});
		}
		this.ga('send', 'event', category, action, options.label || '', options.value || 0);
	}
	get timings() {
		return {
			CPR: {
				sendReportGenerationTime: time => {
					if (time) {
						this.ga('send', {
							hitType: 'timing',
							timingCategory: this.labels.CPR.CPR,
							timingVar: this.labels.CPR.GENERATION,
							timingLabel: 'CPR generation time',
							timingValue: time,
						});
					}
				},
			},
		};
	}
	get events() {
		return {
			RDR: {
				changeDate: (from, to) => {
					this.ga('send', 'event', this.labels.RDR.RDR, this.labels.RDR.SELECT_DATE, `${this.labels.RDR.START_DATE}: ${from}`, 1);
					this.ga('send', 'event', this.labels.RDR.RDR, this.labels.RDR.SELECT_DATE, `${this.labels.RDR.END_DATE}: ${to}`, 1);
				},
				selectObject: (instance, type) => {
					this.ga(
						'send',
						'event',
						this.labels.RDR.RDR,
						this.labels.RDR.SELECT_OBJECTS,
						`${this.labels.RDR.OBJECT_INSTANCE}: ${instance} (${type})`,
						1
					);
				},
				selectDeselectAllObjects: (selectAll = true) => {
					this.ga(
						'send',
						'event',
						this.labels.RDR.RDR,
						this.labels.RDR.SELECT_OBJECTS,
						selectAll ? this.labels.RDR.SELECT_ALL : this.labels.RDR.DESELECT_ALL
					);
				},
			},
			CPR: {
				setReportDate: from => {
					this.ga('send', 'event', this.labels.CPR.CPR, this.labels.CPR.SELECT_DATE, `${this.labels.CPR.START_DATE}: ${from}`, 1);
				},
				sendIncludedSection: name => {
					this.ga(
						'send',
						'event',
						this.labels.CPR.CPR,
						this.labels.CPR.INCLUDED_SECTIONS,
						`The ${this.labels.CPR.SECTION_NAME} was included: ${name}`
					);
				},
				sectionDataChanged: sectionName => {
					this.ga(
						'send',
						'event',
						this.labels.CPR.CPR,
						this.labels.CPR.SECTION_DATA_CHANGED,
						`${this.labels.CPR.SECTION_DATA_CHANGED_MSG}: "${sectionName}"`
					);
				},
				generationFailed: () => {
					this.ga('send', 'event', this.labels.CPR.CPR, this.labels.CPR.GENERATION, this.labels.CPR.GENERATION_FAILED);
				},
			},
			HOME: {
				navigateToFacility: (id, name) => {
					this.ga('send', 'event', this.labels.FS.FS, this.labels.FS.NAVIGATE_TO_FS, `${this.labels.FS.FACILITY_NAME}: ${name} (${id})`, 1);
				},
				useActionMenuItem: (id, name, actionName) => {
					this.ga(
						'send',
						'event',
						this.labels.FS.FS,
						this.labels.FS.USE_ACTION_MENU,
						`${this.labels.FS.FACILITY_NAME}: ${name} (${id}). ${this.labels.FS.ACTION_ITEM_NAME}: ${actionName}`,
						1
					);
				},
				searchUsed: (searchText = '') => {
					if (searchText !== '') {
						this.ga('send', 'event', this.labels.NAV.NAV, this.labels.NAV.SEARCH_USED, `${searchText}`, 1);
					}
				},
				connectivityClicked: id => {
					this.ga('send', 'event', this.labels.NAV.NAV, this.labels.NAV.CONNECTIVITY_STATUS_CLICKED, `${id}`, 1);
				},
				viewChanged: view => {
					this.ga('send', 'event', this.labels.NAV.NAV, this.labels.NAV.VIEW_CHANGED, `${view}`, 1);
				},
				myViewsRowCreated: () => {},
			},
			CHARTS: {
				changeChartSelection: item => {
					let labelString = `TypeName: ${this.labels.CHARTS.CHART}: ${item.chartTypeName}, Level: ${item.chartTypeLevel}`;
					if (item.chartId) {
						labelString += `, ChartID: ${item.chartId}`;
					}
					this.ga('send', 'event', this.labels.CHARTS.CHARTS, this.labels.CHARTS.CHART_SELECTED_FROM_DROPDOWN, labelString, 1);
				},
				openServiceAdvisoryChart: chartUrl => {
					this.ga('send', 'event', this.labels.CHARTS.CHARTS, this.labels.CHARTS.CHART_ACCESSED_FROM_SERVICE_ADVISORY, chartUrl, 1);
				},
			},
		};
	}
}
