import {USER_EVENTS} from '../../common/usage-tracking/categories';
import {getReportNameByKey, getReportGenerationStatus} from '../../common/usage-tracking/categories/report-generation/utils.js';

const {
	REPORT_GENERATION: {events: REPORT_GENERATION_EVENTS, categoryName: REPORT_GENERATION_CATEGORY_NAME, properties: REPORT_GENERATION_PROPERTIES},
} = USER_EVENTS;

angular.module('TISCC').controller('ServiceAdvisoryCtrl', ServiceAdvisoryCtrl);

function ServiceAdvisoryCtrl(
	// Function parameters
	$scope,
	$route,
	$filter,
	$routeParams,
	$q,
	helpers,
	$rootScope,
	modalHelperService,
	locationDetailsService,
	locationEquipmentService,
	serviceAdvisoryService,
	automatedTestsService,
	utilityService,
	authorization,
	googleAnalyticsService,
	exportService,
	exportProgressService,
	chartService,
	REPORT_TYPES,
	CHART_DATE_FORMAT,
	GEN3_URL,
	configService,
	$location,
	urlService,
	TC_URL,
	errorHandler,
	$translate,
	$sessionStorage,
	$window,
	usageTrackingService
	// End of function parameters
) {
	const EQUIPMENTS_PORTION_SIZE = 100;
	// Variables
	let automatedTests = [];
	let equipmentList = [];
	let equipmentListHierarchical = [];
	let chartList = [];
	let workingTisObjectIds = [];
	const externalLinks = configService.getExternalLinks();
	let getSuggestionByServiceAdvisoryTypeActionName = angular.noop;
	let reportGenerationStartTime = null;

	// $scope
	$scope.featureToggles = configService.getFeatureToggles();
	$scope.equipmentLevel = $route.current.$$route.equipmentLevel;
	$scope.uiStateKey = 'serviceAdvisoryController.uiState.' + $routeParams.locationId;
	$scope.sortParams = [
		{
			column: 'tisObjectName',
			order: true,
		},
	];
	const chillerSubComponents = {
		Circuit: [],
		Compressor: [],
	};
	$scope.activeTab = 'service-advisory';
	$scope.locationId = $routeParams.locationId;
	$scope.isCprAllowed = loc => locationDetailsService.isCprAllowed(loc);
	$scope.isDirAllowed = loc => locationDetailsService.isDirAllowed(loc);

	$scope.equipmentId = $routeParams.equipmentId;
	$scope.noteTooltip = noteTooltip;
	$scope.showNotesDialog = showNotesDialog;
	$scope.showViewsDialog = showViewsDialog;
	$scope.updateBetaText = updateBetaText;
	$scope.generateSAReport = generateSAReport;
	$scope.trackOpenChartClick = trackOpenChartClick;
	$scope.addFinding = addFinding;
	$scope.updateServiceAdvisoriesStatus = updateServiceAdvisoriesStatus;
	$scope.onSelectAdvisoryHandler = onSelectAdvisoryHandler;
	$scope.onSelectAllSA = onSelectAllSA;
	$scope.selectAll = false;
	$scope.hasSuggestion = angular.noop;
	$scope.locationURL = '';
	$scope.filteredEquipments = [];
	$scope.screenName = 'service-advisories';
	$scope.lastItemSelected = {};
	$scope.translationsData = {};
	$scope.openReport = openReport;

	serviceAdvisoryService.getActions(actions => {
		getSuggestionByServiceAdvisoryTypeActionName = serviceAdvisoryService.generateGetActionsFunction(actions);
		$scope.hasSuggestion = serviceAdvisoryTypeActionName => Boolean(getSuggestionByServiceAdvisoryTypeActionName({id: serviceAdvisoryTypeActionName}));
	});

	serviceAdvisoryService.getTranslations(translationsData => ($scope.translationsData = translationsData));

	// Execution
	if ($routeParams.equipmentId && $routeParams.equipmentId > 0) {
		$scope.uiStateKey += '.' + $routeParams.equipmentId;
	}

	automatedTestsService.getAnalyticModels('', function(res) {
		automatedTests = res.analyticModel;
	});

	const locationDetailsPromise = locationDetailsService.getLocationDetailsWithoutServiceAdvisories($routeParams.locationId);
	const locationEquipmentPromise = locationEquipmentService.getLocationObjectsList($routeParams.locationId);
	const locationEquipmentHierarchicalPromise = locationEquipmentService.getLocationObjectsList($routeParams.locationId, null, true);
	const allChartsPromise = chartService.getAllCharts();
	let serviceAdvisoryPromise;

	let promises;

	if ($scope.equipmentId) {
		locationEquipmentService
			.getLocationObjectsList($routeParams.locationId, null, true)
			.then(function(data) {
				let groupNames = [];

				equipmentListHierarchical = data.tisObjectList;
				$scope.equipment = $filter('filterNested')(equipmentListHierarchical, {tisObjectId: parseInt($routeParams.equipmentId)}, true).pop();

				$scope.groupName = $scope.equipment.tisObjectType.tisObjectTypeGroupName;

				workingTisObjectIds = [$scope.equipment.tisObjectId];

				if ($scope.groupName === 'Chiller') {
					groupNames = [$scope.groupName, 'Circuit', 'Compressor'];
					saveChillerSubComponents($scope.equipment.children);

					Object.keys(chillerSubComponents).forEach(key =>
						chillerSubComponents[key].forEach(equipment => workingTisObjectIds.push(equipment.tisObjectId))
					);
				} else {
					groupNames = [$scope.groupName];
				}
			})
			.then(() => {
				if ($scope.equipmentId) {
					serviceAdvisoryPromise = createServiceAdvisoryPromise();
				} else {
					serviceAdvisoryPromise = serviceAdvisoryService.getLocationList($scope.locationId, $rootScope.useSACache);
					$rootScope.useSACache = true;
				}
			})
			.then(() => {
				promises = [locationDetailsPromise, locationEquipmentPromise, allChartsPromise, serviceAdvisoryPromise];
				$q.all([...promises]).then(handleResponsesData);
				$scope.loadingPromises = [...promises];
			});
	} else {
		locationEquipmentService
			.getLocationObjectsList($routeParams.locationId, null, true)
			.then(function(data) {
				equipmentListHierarchical = data.tisObjectList;
			})
			.then(() => {
				serviceAdvisoryPromise = serviceAdvisoryService.getLocationList($scope.locationId, $rootScope.useSACache);
				promises = [locationDetailsPromise, locationEquipmentPromise, allChartsPromise, serviceAdvisoryPromise];
				$q.all([...promises]).then(handleResponsesData);
				$scope.loadingPromises = [...promises];
				$rootScope.useSACache = true;
			});
	}

	locationDetailsService
		.getLocationServiceAdvisoriesCount($scope.locationId)
		.then(count => setServiceAdvisoryCountToScope(count))
		.catch(() => ($scope.facilityServiceAdvisoryCount = '?'));

	locationDetailsService
		.getLocationNotesCount($scope.locationId)
		.then(count => ($scope.facilityNotesCount = count))
		.catch(() => ($scope.facilityNotesCount = '?'));

	// Functions
	function trackGenerateReportEvent(options = {}) {
		usageTrackingService.trackEvent(REPORT_GENERATION_CATEGORY_NAME, REPORT_GENERATION_EVENTS.GENERATE_REPORT, {
			[REPORT_GENERATION_PROPERTIES.BUILDING_ID]: $scope.locationId,
			...options,
		});
	}

	function openReport(report) {
		if (report === REPORT_TYPES.REPORT_CHILLER_PERFORMANCE_ENGLISH.report && !locationDetailsService.isCprAllowed($scope.location)) {
			return;
		}

		googleAnalyticsService.sendFlowEvent('Report generation window', 'Open window', {
			label: 'RDR-embedded',
			value: 0,
		});
		if (report !== REPORT_TYPES.RAW_DATA.report || ($scope.location && $scope.location.locationName)) {
			$scope.modal = modalHelperService.open({
				templateUrl: 'components/reports/report-dialog.html',
				controller: 'ReportDialogCtrl',
				backdrop: 'static',
				windowClass: 'report-dialog full-height',
				resolve: {
					data: function() {
						return {
							report: report,
							locationData: $scope.location,
							defaultSelection: $scope.equipment,
							equipmentsData: [$scope.equipment],
							limitToOneReportOnly: false,
							rangeFrom: $scope.from,
							rangeTo: $scope.to,
							maxDt: $scope.maxDt,
							rangeMode: 'custom',
						};
					},
				},
			});
		}
	}

	function setServiceAdvisoryCountToScope(count) {
		$scope.facilityServiceAdvisoryCount = Number.isInteger(+count) ? Number(count) : '?';
		$scope.locationURL = $location
			.path()
			.split('/')
			.join('');
		$sessionStorage[$scope.locationURL] = $scope.facilityServiceAdvisoryCount;
	}
	$scope.facilityServiceAdvisoryCount = $sessionStorage[$scope.locationURL];
	function noteTooltip(equipment) {
		let tooltip = 'NOTE_TOOLTIP_ADD';

		if (equipment.notesCount === 1) {
			tooltip = 'NOTE_TOOLTIP_ONE';
		} else if (equipment.notesCount > 1) {
			tooltip = 'NOTE_TOOLTIP_MANY';
		}

		return tooltip;
	}

	function saveChillerSubComponents(tisObjects = []) {
		tisObjects.forEach(function(tisObject) {
			if (chillerSubComponents[tisObject.tisObjectType.tisObjectTypeGroupName]) {
				chillerSubComponents[tisObject.tisObjectType.tisObjectTypeGroupName].push(tisObject);
			}
			if (tisObject.children) {
				saveChillerSubComponents(tisObject.children);
			}
		});
	}

	function createServiceAdvisoryPromise() {
		return Promise.all(workingTisObjectIds.map(tisObjectId => serviceAdvisoryService.getEquipmentList(tisObjectId))).then(tisObjectSats =>
			// Add tisObjectId to each SAT and return all SATs as one array
			helpers.flattenDeep(
				tisObjectSats.map((serviceAdvisoryTypes, index) => [
					...serviceAdvisoryTypes.map(sat => ({
						...sat,
						tisObjectId: workingTisObjectIds[index],
					})),
				])
			)
		);
	}

	function handleResponsesData(response) {
		const [locationEquipmentData, equipmentListData, chartListData, serviceAdvisoryList] = response;
		[$scope.location] = locationEquipmentData.locationSummaryList;
		equipmentList = equipmentListData.tisObjectList;
		chartList = chartListData;

		const {locationId, facilityId, organizationId} = $scope.location;
		const equipmentId = $scope.equipmentId;
		const equipment = equipmentList.find(equipmentItem => equipmentItem.tisObjectId === Number(equipmentId));
		const {objectId, tisObjectName, tisObjectId} = equipment || {};

		$scope.notesLink = externalLinks.notesLink({locationId, facilityId, tisObjectName});
		$scope.addNoteLink = externalLinks.addNoteLink({locationId, facilityId, tisObjectId});
		$scope.automatedTestSettingsLink = externalLinks.automatedTestSettingsLink({accountId: organizationId, locationId, tisObjectId: $scope.equipmentId});
		$scope.automatedTestSuppressionsLink = externalLinks.automatedTestSuppressionsLink({accountId: organizationId, locationId});

		utilityService.getEnvironmentType().then(env => {
			$scope.GEN4EquipmentSetupURL = externalLinks.equipmentSetupLink({locationId, organizationId});

			$scope.TC_URL = TC_URL[env.toUpperCase()];
		});

		serviceAdvisoryList.forEach(setServiceAdvisoryTimestamps);

		$scope.serviceAdvisories = serviceAdvisoryList.map(setEquipmentTisObjectName);

		serviceAdvisoryService.getAllTypes().then(updateData);

		initializeNavigationWithData();
		$scope.loadNextServiceAdvisoriesPortion(true);
	}
	function handleServiceAdvisoryCount(SACount) {
		if ($scope.equipmentServiceAdvisoryCount) {
			$scope.equipmentServiceAdvisoryCount = SACount + $scope.equipmentServiceAdvisoryCount;
		} else {
			$scope.equipmentServiceAdvisoryCount = SACount;
		}
	}
	function initializeNavigationWithData() {
		$scope.navigation = [];

		if ($scope.equipmentLevel) {
			$scope.navigation.push({
				href: '#/facility/' + $routeParams.locationId,
				title: $scope.location.locationName,
				active: !$scope.equipmentLevel,
			});
			delete $scope.equipmentServiceAdvisoryCount; // Resetting the count during update on equipment Level.
			workingTisObjectIds.map(tisObjectId => {
				locationEquipmentService.getEquipmentServiceAdvisoryCount(tisObjectId, function(result) {
					handleServiceAdvisoryCount(result.serviceAdvisoryStatisticsList[0].serviceAdvisoriesCount);
				});
			});

			locationEquipmentService.getEquipmentNotesCount($routeParams.equipmentId, function(count) {
				$scope.equipmentNotesCount = count;
			});

			$scope.equipment = equipmentList.find(equipment => equipment.tisObjectId === Number($routeParams.equipmentId));
		}

		$scope.pageTitle = {
			title: $scope.equipmentLevel ? $scope.equipment.tisObjectName : $scope.location.locationName,
		};
	}
	function setEquipmentTisObjectName(equipment) {
		let tisObject = findTisObjectName(equipment.tisObjectId);
		if (tisObject.instance) {
			equipment.tisObjectName = $scope.equipmentLevel ? $scope.equipment.tisObjectName : findTisObjectName(tisObject.compositeTisObjectId).tisObjectName;
			equipment.instance = tisObject.instance;
		} else {
			equipment.tisObjectName = tisObject.tisObjectName;
		}
		return equipment;
	}

	function findTisObjectName(tisObject) {
		let parentEquipment = equipmentList.find(equipmentItem => equipmentItem.tisObjectId === Number(tisObject));
		return parentEquipment;
	}

	function setServiceAdvisoryTimestamps(serviceAdvisory) {
		serviceAdvisory.firstException = new Date(serviceAdvisory.firstException);
		serviceAdvisory.latest = new Date(serviceAdvisory.lastException);
		serviceAdvisory.worst = new Date(serviceAdvisory.worstDate);
	}

	function showNotesDialog(equipment) {
		if (!equipment.notesCount) {
			modalHelperService
				.open({
					templateUrl: 'components/notes/add-note.html',
					controller: 'AddNoteCtrl',
					backdrop: 'static',
				})
				.result.then(function() {
					equipment.notesCount = 1;
				});
		} else {
			modalHelperService.open({
				templateUrl: 'components/notes/view-notes.html',
				controller: 'ViewNotesCtrl',
				backdrop: 'static',
			});
		}
	}

	function showViewsDialog(item) {
		let suggestion = getSuggestionByServiceAdvisoryTypeActionName({id: item.actionName});
		$scope.modal = modalHelperService.open({
			templateUrl: 'components/service-advisory/view-automated-test.html',
			controller: 'ViewAutomatedTestCtrl',
			backdrop: 'static',
			resolve: {
				suggestion: function() {
					return suggestion;
				},
			},
		});
	}

	function showStatusUpdate() {
		$scope.modal = modalHelperService.open({
			templateUrl: 'components/service-advisory/service-advisories-status-update-progress.html',
			backdrop: 'static',
			windowClass: 'sa-status',
		});
	}

	function showStatusUpdateSuccess() {
		modalHelperService.open({
			templateUrl: 'common/messages/success-message.html',
			controller: 'ErrorMessageCtrl',
			backdrop: 'static',
			windowClass: 'sa-status',
			resolve: {
				details: () => 'STATUS_UPDATE_SUCCESS',
			},
		});
	}

	function updateBetaText(selector) {
		let list = document.querySelectorAll(selector + ' li.beta');
		let betaText = $filter('translate')('BETA_TEXT');

		for (let i = 0; i < list.length; i++) {
			list[i].setAttribute('title', betaText);
		}
	}

	function generateSAReport() {
		const downloadableSAs =
			Object.keys($scope.filterParams).length === 0 && $scope.searchObj.searchText === '' ? $scope.serviceAdvisories : $scope.filteredEquipments;
		// skip if location or equipments data not present
		if (!$scope.location || (!downloadableSAs || !downloadableSAs.length)) {
			return;
		}
		const translations = {
			suggestions: downloadableSAs.reduce(
				(suggestions, serviceAdvisoryType) => {
					suggestions[serviceAdvisoryType.actionName] = getSuggestionByServiceAdvisoryTypeActionName({id: serviceAdvisoryType.actionName});
					return suggestions;
				},
				// Show the empty string instead of the default value if no suggestions.
				{defaultSuggestion: ''}
			),
			translationsData: $scope.translationsData,
			headers: {
				TITLE_EQUIPMENT: $filter('translate')('TITLE_EQUIPMENT'),
				TITLE_DESCRIPTION: $filter('translate')('TITLE_DESCRIPTION'),
				TITLE_FAILURES: $filter('translate')('TITLE_FAILURES'),
				TITLE_STATUS: $filter('translate')('TITLE_STATUS'),
				TITLE_FIRST_EXCEPTION: $filter('translate')('TITLE_FIRST_EXCEPTION'),
				TITLE_LATEST: $filter('translate')('TITLE_LATEST'),
				TITLE_WORST: $filter('translate')('TITLE_WORST'),
				TITLE_COMFORT: $filter('translate')('TITLE_COMFORT'),
				TITLE_ENERGY: $filter('translate')('TITLE_ENERGY'),
				TITLE_RELIABILITY: $filter('translate')('TITLE_RELIABILITY'),
				TITLE_SUGGESTIONS: $filter('translate')('TITLE_SUGGESTIONS'),
				TITLE_NOTES: $filter('translate')('TITLE_NOTES'),
			},
		};
		const data = {
			type: 'report',
			subtype: REPORT_TYPES.SERVICE_ADVISORIES.report,
			timezone: locationDetailsService.getLocationTimezone(),
			token: authorization.getToken(),
			filename: $scope.location.locationName + '_' + $filter('date')(new Date(), 'yyyy-MM-dd') + '_Service_Advisories.xlsx',
			tisObjectId: $scope.equipmentId,
			locationId: $scope.locationId,
			serviceAdvisoryUniqIds: downloadableSAs.map(({serviceAdvisoryTypeId, tisObjectId, status}) => `${serviceAdvisoryTypeId}_${tisObjectId}_${status}`),
			sortParams: $scope.sortParams,
			translations,
		};

		reportGenerationStartTime = performance.now();

		exportService
			.sendExportRequest(data)
			.then(({progressKey}) => exportProgressService.open(progressKey))
			.then(finalizeExport, failExport, showExportProgress);

		trackGenerateReportEvent({
			[REPORT_GENERATION_PROPERTIES.BUILDING_ID]: $scope.locationId,
			[REPORT_GENERATION_PROPERTIES.REPORT]: getReportNameByKey(REPORT_TYPES.SERVICE_ADVISORIES.downloadReport),
			[REPORT_GENERATION_PROPERTIES.REPORT_GENERATION_STATUS]: getReportGenerationStatus('started'),
		});
	}

	function finalizeExport() {
		// Do nothing so far.
		let timeTaken = performance.now() - reportGenerationStartTime;
		trackGenerateReportEvent({
			[REPORT_GENERATION_PROPERTIES.BUILDING_ID]: $scope.locationId,
			[REPORT_GENERATION_PROPERTIES.REPORT]: getReportNameByKey(REPORT_TYPES.SERVICE_ADVISORIES.downloadReport),
			[REPORT_GENERATION_PROPERTIES.REPORT_GENERATION_STATUS]: getReportGenerationStatus('success'),
			[REPORT_GENERATION_PROPERTIES.TOTAL_TIME]: timeTaken,
		});
	}

	function failExport() {
		let timeTaken = performance.now() - reportGenerationStartTime;
		trackGenerateReportEvent({
			[REPORT_GENERATION_PROPERTIES.BUILDING_ID]: $scope.locationId,
			[REPORT_GENERATION_PROPERTIES.REPORT]: getReportNameByKey(REPORT_TYPES.SERVICE_ADVISORIES.downloadReport),
			[REPORT_GENERATION_PROPERTIES.REPORT_GENERATION_STATUS]: getReportGenerationStatus('failed'),
			[REPORT_GENERATION_PROPERTIES.TOTAL_TIME]: timeTaken,
		});
		exportProgressService.cancel();
	}

	function showExportProgress(input = {}) {
		if (helpers.getPropertyByPath(input, 'data.readyToDownload')) {
			startDownloadProcess();
		}
	}

	function startDownloadProcess() {
		const progressKey = exportProgressService.getProgressKey();
		if (progressKey) {
			let exportData = {
				progressKey,
				subtype: REPORT_TYPES.SERVICE_ADVISORIES.report,
				downloadXLSX: true,
			};

			angular.element(document.getElementById('export-data')).val(JSON.stringify(exportData));
			document.getElementById('export-form').submit();
		}
	}

	function getActionId(name, group) {
		for (let i = 0, len = automatedTests.length; i < len; i++) {
			if (automatedTests[i].name === name && automatedTests[i].groupName === group) {
				return automatedTests[i].typeId;
			}
		}
		return null;
	}

	function updateData(typesList) {
		$scope.serviceAdvisoryTypes = typesList;
		$scope.serviceAdvisories.forEach(function(equipment) {
			typesList.forEach(function(serviceAdvisoryType) {
				if (equipment.serviceAdvisoryTypeId === serviceAdvisoryType.serviceAdvisoryTypeId) {
					equipment.title = serviceAdvisoryType.serviceAdvisoryTypeName;
					equipment.actionName = serviceAdvisoryType.actionName;
					equipment.actionId = getActionId(serviceAdvisoryType.actionName, serviceAdvisoryType.tisObjectType.tisObjectTypeGroupName);
					equipment.chartLink = serviceAdvisoryService.generateChartLink(equipment, serviceAdvisoryType, {
						tisObjectsHierarchy: equipmentListHierarchical,
						chartList,
					});
					if (equipment.instance) {
						equipment.description = $filter('translate')(`PROPERTIES.M_${serviceAdvisoryType.exceptionPropertyName}`, {
							name: $filter('translate')(equipment.instance),
						});
					} else {
						equipment.description = $filter('translate')(`PROPERTIES.${serviceAdvisoryType.exceptionPropertyName}`);
					}

					processImpactCategories(equipment, serviceAdvisoryType);
				}
			});
		});
		$scope.enableDownloadReport = true;
	}

	function processImpactCategories(equipment, type) {
		type.impactCategories.forEach(function(category) {
			equipment[category.impactCategoryName] = category.impactCategoryValue;
		});
	}

	function isDate(arg) {
		return arg instanceof Date;
	}

	function trackOpenChartClick(url) {
		googleAnalyticsService.events.CHARTS.openServiceAdvisoryChart(url);
	}

	function addFinding() {
		const selectedAdvisory = $scope.filteredEquipments.find(sa => sa.selected);
		const exportLink = selectedAdvisory.chartLink;
		const filename = selectedAdvisory.description.split(' ').join('_');
		const data = prepareData(exportLink, filename);

		const {locationId, tisObjectId, serviceAdvisoryTypeId} = selectedAdvisory;
		const organizationId = $scope.location.organizationId;
		const url = [
			`${$scope.TC_URL}/findings/add?`,
			`organization=${organizationId}`,
			`&location=${locationId}`,
			`&equipment=${tisObjectId}`,
			`&chartLink=${encodeURIComponent(data.absUrl)}`,
			`&serviceAdvisoryType=${serviceAdvisoryTypeId}`,
		].join('');
		$window.open(url, '_blank');
	}

	function updateServiceAdvisoriesStatus(data) {
		serviceAdvisoryPromise = undefined;
		showStatusUpdate();
		serviceAdvisoryService
			.updateServiceAdvisoryStatus($scope.selectedServiceAdvisories, data)
			.then(() => {
				if ($scope.equipmentId) {
					$rootScope.useSACache = false;
					serviceAdvisoryPromise = createServiceAdvisoryPromise();
				} else {
					$rootScope.useSACache = false;
					serviceAdvisoryPromise = serviceAdvisoryService.getLocationList($scope.locationId);
				}
				locationDetailsService
					.getLocationServiceAdvisoriesCount($scope.locationId)
					.then(count => setServiceAdvisoryCountToScope(count))
					.catch(() => ($scope.facilityServiceAdvisoryCount = '?'));
				let promise = [locationDetailsPromise, locationEquipmentPromise, allChartsPromise, serviceAdvisoryPromise];

				$q
					.all([...promise])
					.then(data => {
						handleResponsesData(data);
					})
					.then(() => {
						closeStatusUpdateModal();
					})
					.then(() => {
						showStatusUpdateSuccess();
						$scope.filteredEquipments.map(sa => (sa.selected = false));
						onSelectAdvisoryHandler();
					});
			})
			.catch(error => {
				closeStatusUpdateModal();
			});
	}

	function closeStatusUpdateModal() {
		if ($scope.modal && $scope.modal.dismiss && $scope.modal.opened.$$state.value) {
			try {
				$scope.modal.dismiss();
			} catch (err) {
				return false;
			}
		}
	}

	function onSelectAdvisoryHandler(item, event) {
		// check if shift key is pressed
		if (event && !event.shiftKey) {
			if (item.selected) {
				$scope.lastItemSelected = item;
			} else {
				delete $scope.lastItemSelected;
			}
			const selectedAdvisories = $scope.filteredEquipments.filter(sa => sa.selected);
			enableSAStatusChange(selectedAdvisories);
			if ($scope.findingsEnabled) {
				const organizationId = $scope.location.organizationId;
				const {locationId, tisObjectId} = selectedAdvisories[0];
				$scope.addFindingLink = externalLinks.addFindingLink({organizationId, locationId, tisObjectId});
			}
		} else {
			if (item && item.selected) {
				let startIndex = $scope.filteredEquipments.indexOf($scope.lastItemSelected);
				let endIndex = $scope.filteredEquipments.indexOf(item);
				if (startIndex < endIndex) {
					shiftMultiSelect(startIndex, endIndex);
				} else {
					shiftMultiSelect(endIndex, startIndex);
				}
			} else {
				const selectedAdvisories = $scope.filteredEquipments.filter(sa => sa.selected);
				enableSAStatusChange(selectedAdvisories);
			}
		}
	}

	// Shift Multi Select
	function shiftMultiSelect(start, end) {
		const selectedAdvisories = $scope.filteredEquipments.slice(start, end + 1);
		selectedAdvisories.map(data => (data.selected = true));
		enableSAStatusChange(selectedAdvisories);
	}

	// Make Select All on Header Column selected on all rows select
	function checkSelectAll() {
		if (!$scope.selectedServiceAdvisories || $scope.selectedServiceAdvisories.length !== $scope.filteredEquipments.length) {
			$scope.selectAll = false;
		} else {
			$scope.selectAll = true;
		}
	}

	// Select All Button
	function onSelectAllSA(event) {
		$scope.selectAll = event.target.checked;
		$scope.filteredEquipments.map(sa => (sa.selected = event.target.checked));
		const selectedAdvisories = $scope.filteredEquipments.filter(sa => sa.selected);
		$scope.selectedServiceAdvisories = selectedAdvisories;
		enableSAStatusChange(selectedAdvisories);
	}

	// Enable Disable Status Change Buttons
	function enableSAStatusChange(selectedAdvisories) {
		$scope.selectedServiceAdvisories = selectedAdvisories;
		let selectedAdvisoriesCount = selectedAdvisories.length;
		$scope.findingsEnabled = selectedAdvisoriesCount === 1;
		$scope.moreThanOneSelected = selectedAdvisoriesCount > 1;
		$scope.enableStatusChange = selectedAdvisoriesCount > 0;
		checkSelectAll();
	}

	function prepareData(url, filename) {
		let data = {
			filename,
			visualExport: true,
			format: 'jpg',
			screenshotUpload: true,
			exportOrientation: 'landscape',
		};

		const formats = {
			backgroundColor: 'white',
		};
		data.token = authorization.getToken();

		let parameters = '&isExport=1';
		parameters += '&isSideLegend=1';
		parameters += `&backgroundColor=${formats.backgroundColor}`;

		const absUrl = `${$location.protocol()}://${$location.host()}:${$location.port()}/${url}`;
		data.filename += `.${data.format}`;
		data.absUrl = absUrl;
		data.url = `${absUrl}${parameters}`;
		return data;
	}

	let filteredServiceAdvisoriesCache = null;

	$scope.loadNextServiceAdvisoriesPortion = function(isFirstPortion) {
		if (isFirstPortion) {
			$scope.filteredEquipments = [];
			const sortParams = [$scope.sortParams[0], {column: 'tisObjectName', order: $scope.sortParams[0].order}];
			let tmpServiceAdvisories = $scope.serviceAdvisories;

			if ($scope.searchObj) {
				tmpServiceAdvisories = $filter('filterBySearchText')($scope.serviceAdvisories, {
					searchFieldsList: [
						'tisObjectName',
						'description',
						'daysFailed',
						'status',
						'firstException',
						'latest',
						'worst',
						'Comfort',
						'Energy',
						'Reliability',
					],
					searchText: $scope.searchObj.searchText,
					searchNested: false,
				});
			}

			let filteredByColumn = $filter('tableFilter')(tmpServiceAdvisories, $scope.filterParams);
			filteredServiceAdvisoriesCache = $filter('tableSort')(filteredByColumn, sortParams);

			$scope.filteredEquipments = filteredServiceAdvisoriesCache.slice(0, EQUIPMENTS_PORTION_SIZE);
		} else {
			let start = $scope.filteredEquipments.length;
			let nextPortion = filteredServiceAdvisoriesCache.slice(start, start + EQUIPMENTS_PORTION_SIZE);
			Array.prototype.push.apply($scope.filteredEquipments, nextPortion);
			checkSelectAll();
		}
	};

	$scope.$watch('searchObj.searchText', function(nw, old) {
		if (nw === old) {
			return;
		}

		$scope.loadNextServiceAdvisoriesPortion(true);
	});

	$rootScope.$on('applyFilter', function(event, filterParams) {
		const {filters, parentScreen} = filterParams;
		if (parentScreen === $scope.screenName) {
			$scope.filters = filters;
			$scope.loadNextServiceAdvisoriesPortion(true);
		}
	});

	$rootScope.$on('applySort', function(event, sortParams) {
		$scope.sortParams = [...sortParams];
		if (sortParams && sortParams[0] && sortParams[0].column !== 'groupName') {
			sortParams = [
				...sortParams,
				{
					column: 'groupNameHeader',
					order: sortParams[0].order,
				},
			];
			$scope.sortParamsTree = sortParams;
		}
		$scope.loadNextServiceAdvisoriesPortion(true);
	});

	$scope.$on('$routeChangeStart', function(next, current) {
		if ($scope.modal && $scope.modal.dismiss && $scope.modal.opened.$$state.value) {
			try {
				$scope.modal.dismiss();
			} catch (err) {
				return false;
			}
		}
	});

	// Show Digital Inspection Button only for Chillers
	$scope.$watchGroup(['equipment'], () => {
		if ($scope.equipment && $scope.equipment.tisObjectType) {
			$scope.groupName = $scope.equipment.tisObjectType.tisObjectTypeGroupName;
		}
	});
}
