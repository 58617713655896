export const USER_EVENTS = {
	NAVIGATION: {
		categoryName: 'Navigation',
		events: {
			CREATE_NOTE: 'Create Note',
			GO_TO_CC3_FACILITY_SUMMARY_PAGE: 'Go to CC3 Facility Summary Page',
			GO_TO_EDIT_FACILITY_SETTINGS: 'Go to Edit Facility Settings',
			RUN_ALARMS_AND_EVENTS_REPORT: 'Run Alarms and Events Report',
			RUN_CHILLER_PERFORMANCE_REPORT: 'Run Chiller Performance Report',
			RUN_EXCEPTION_HISTORY_REPORT: 'Run Exception History Report',
			RUN_FACILITY_DETAILS_REPORT: 'Run Facility Details Report',
			RUN_INVENTORY_REPORT: 'Run Inventory Report',
			RUN_INTERNAL_INVENTORY_REPORT: 'Run Internal Inventory Report',
			RUN_RAW_DATA_REPORT: 'Run Raw Data Report',
			RUN_SCHEDULES_REPORT: 'Run Schedules Report',
			NAVIGATE_TO_HOME_PAGE: 'Navigate to Home Page',
			NAVIGATE_TO_FACILITY_PAGE: 'Navigate to Facility Page',
			GO_TO_REMOTE_ACCESS: 'Go to Remote Access',
			GO_TO_AUTOMATED_TEST_SETTINGS: 'Go to Automated Test Settings',
			GO_TO_AUTOMATED_TEST_SUPPRESSIONS: 'Go to Automated Test Suppressions',
			EDIT_EQUIPMENT: 'Edit Equipment',
		},
	},
};
