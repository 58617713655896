(function() {
	const CIRCUIT_NAME = 'Circuit';
	const NO_FILTER_KEY_FOUND = 'NO_FILTER_KEY_FOUND';
	const APPLICATION_DATA_KEY_PREFIX = 'ChillerSubtypeFilter';
	const APPLICATION_DATA_KEY_PREFIX_BOILER = 'BoilerSubtypeFilter';
	const BOILER = 'Boiler';
	const DEFAULT_FILTER = {
		automatedTests: [],
		charts: [],
		properties: [],
	};
	const COMPRESSOR_TYPE = 'CompressorType';
	const CONDENSER_TYPE = 'CondenserType';
	const TIS_OBJECT_SUBTYPE = 'tisObjectSubType';
	const TIS_OBJECT_CLASSIFICATION_TYPE = 'tisObjectClassificationType';
	const APPLICATION_TYPE = 'ApplicationType';

	class subtypeFilterService {
		constructor($http, locationEquipmentService, locationDetailsService, handleServiceWorker) {
			this.$http = $http;
			this.locationEquipmentService = locationEquipmentService;
			this.locationDetailsService = locationDetailsService;
			this.handleServiceWorker = handleServiceWorker;
			this.filterFiles = {};
			this.equipmentKeyNames = {};
		}

		getFilterKeyName(tisObjectId, tisObjectSubType, tisObjectClassificationType) {
			tisObjectSubType = tisObjectSubType.replace(' ', '');
			tisObjectClassificationType = tisObjectClassificationType.replace(' ', '');

			const filterKeyName = new Promise((resolve, reject) => {
				if (this.equipmentKeyNames[tisObjectId]) {
					resolve(this.equipmentKeyNames[tisObjectId]);
				} else {
					this.locationEquipmentService.getEquipmentChildHierarchy(tisObjectId, data => {
						const equipmentHierarchy = data.tisObjectList;

						let numberOfCircuits = 0;
						let countCircuits = children => {
							if (children.length) {
								for (let i = 0; i < children.length; i++) {
									const circuit = children[i];
									if (circuit.tisObjectType.tisObjectTypeGroupName === CIRCUIT_NAME) {
										numberOfCircuits++;
										circuit.children && countCircuits(circuit.children);
									}
								}
							}
						};

						countCircuits(equipmentHierarchy);

						if (numberOfCircuits && tisObjectSubType && tisObjectClassificationType) {
							this.equipmentKeyNames[tisObjectId] = `${tisObjectClassificationType}${tisObjectSubType}${numberOfCircuits}`;
							resolve(this.equipmentKeyNames[tisObjectId]);
						} else {
							// key name is not found and rejecting the promise with NO_FILTER_KEY_FOUND message.
							reject(NO_FILTER_KEY_FOUND);
						}
					});
				}
			});
			return filterKeyName;
		}

		getFilterKeyNameFromObjectWithHierarchy(tisObject) {
			// Since there is the possibility that Chiller hasn't children, tisObjectSubType, tisObjectClassificationType - set the default value as an empty array and empty strings
			let {tisObjectId, tisObjectSubType = '', tisObjectClassificationType = '', children = []} = tisObject;
			tisObjectSubType = tisObjectSubType.replace(' ', '');
			tisObjectClassificationType = tisObjectClassificationType.replace(' ', '');
			if (this.equipmentKeyNames[tisObjectId]) {
				return this.equipmentKeyNames[tisObjectId];
			} else {
				let numberOfCircuits = 0;
				let countCircuits = children => {
					if (children.length) {
						for (let i = 0; i < children.length; i++) {
							const circuit = children[i];
							if (circuit.tisObjectType.tisObjectTypeGroupName === CIRCUIT_NAME) {
								numberOfCircuits++;
								circuit.children && countCircuits(circuit.children);
							}
						}
					}
				};

				countCircuits(children);
				if (numberOfCircuits && tisObjectSubType && tisObjectClassificationType) {
					this.equipmentKeyNames[tisObjectId] = `${tisObjectClassificationType}${tisObjectSubType}${numberOfCircuits}`;
					return this.equipmentKeyNames[tisObjectId];
				} else {
					this.equipmentKeyNames[tisObjectId] = `${tisObjectClassificationType}${tisObjectSubType}`;
					return this.equipmentKeyNames[tisObjectId];
				}
			}
		}

		getFilterData(tisObjectId, tisObjectSubType, tisObjectClassificationType) {
			return this.getFilterKeyName(tisObjectId, tisObjectSubType, tisObjectClassificationType).then(
				filterKeyName => {
					if (!this.filterFiles[filterKeyName]) {
						const url = `/ext_api/api/applicationData?classification=TCC&keys=${this.buildApplicationDataFilterKeyName(filterKeyName)}`;
						return this.$http
							.get(url, {
								headers: this.handleServiceWorker.createServiceWorkerCacheKeyForRequest({
									cacheKey: `subtypeFilterService_getFilterData_${url}`,
									expiryTime: this.handleServiceWorker.CACHE_EXPIRE_TIME.IN_1_DAY,
								}),
							})
							.then(response => {
								this.filterFiles[filterKeyName] = response.data.applicationDataList[0];
								this.filterFiles[filterKeyName].value = JSON.parse(this.filterFiles[filterKeyName].value);
								return this.filterFiles[filterKeyName];
							})
							.catch(() => {
								this.filterFiles[filterKeyName] = {value: DEFAULT_FILTER};
								return Promise.resolve({value: DEFAULT_FILTER});
							});
					} else {
						return Promise.resolve(this.filterFiles[filterKeyName]);
					}
				},
				// If no filter key available, retrun default value
				() => {
					return Promise.resolve({value: DEFAULT_FILTER});
				}
			);
		}

		getFiltersData(filterKeyNames, isBoiler = false) {
			if (!filterKeyNames.every(filterKeyName => this.filterFiles[filterKeyName])) {
				const filterKeyNamesString = this.buildApplicationDataFilterKeyName(filterKeyNames, isBoiler).join(',');
				const url = `/ext_api/api/applicationData?classification=TCC&keys=${filterKeyNamesString}`;
				return this.$http
					.get(url, {
						headers: this.handleServiceWorker.createServiceWorkerCacheKeyForRequest({
							cacheKey: `subtypeFilterService_getFiltersData_${url}`,
							expiryTime: this.handleServiceWorker.CACHE_EXPIRE_TIME.IN_1_DAY,
						}),
					})
					.then(response => {
						const filterList = response.data.applicationDataList;
						filterList.forEach(filterItem => {
							const key = isBoiler
								? filterItem.key.replace(APPLICATION_DATA_KEY_PREFIX_BOILER, '')
								: filterItem.key.replace(APPLICATION_DATA_KEY_PREFIX, '');
							this.filterFiles[key] = filterItem;
							this.filterFiles[key].value = JSON.parse(filterItem.value);
						});
						return filterKeyNames.map(key => this.filterFiles[key]).filter(i => i);
					})
					.catch(() => {
						return Promise.resolve([]);
					});
			} else {
				return Promise.resolve(filterKeyNames.map(key => this.filterFiles[key]));
			}
		}

		buildApplicationDataFilterKeyName(filterKeyName, isBoiler) {
			if (filterKeyName instanceof Array) {
				return filterKeyName.map(key => {
					return isBoiler ? `${APPLICATION_DATA_KEY_PREFIX_BOILER}${key}` : `${APPLICATION_DATA_KEY_PREFIX}${key}`;
				});
			} else {
				return `${APPLICATION_DATA_KEY_PREFIX}${filterKeyName}`;
			}
		}

		getAutomatedTestsFilter(tisObjectId, tisObjectSubType, tisObjectClassificationType) {
			return this.getFilterData(tisObjectId, tisObjectSubType, tisObjectClassificationType).then(result => {
				return this._getItemsByKey(result.value, 'automatedTests');
			});
		}

		getChartsFilter(tisObjectId, tisObjectSubType, tisObjectClassificationType) {
			return this.getFilterData(tisObjectId, tisObjectSubType, tisObjectClassificationType).then(result => {
				return this._getItemsByKey(result.value, 'charts');
			});
		}

		processFiltersData(result) {
			let filteredProperties;
			if (result.length === 0) {
				filteredProperties = [];
			} else if (result.length === 1) {
				filteredProperties = this._getItemsByKey(result[0].value, 'properties');
			} else {
				const allProperties = result.map(item => this._getItemsByKey(item.value, 'properties'));
				// Find common properties present for ALL subTypes (all lists intersection)
				filteredProperties = allProperties.shift().reduce((res, current) => {
					if (!res.includes(current) && allProperties.every(a => a.includes(current))) {
						res.push(current);
					}
					return res;
				}, []);
			}
			return filteredProperties;
		}

		getPropertiesFilter(filterKeyNames, objectGroups) {
			if (objectGroups && objectGroups.includes(BOILER)) {
				return this.getFiltersData(filterKeyNames, true).then(result => {
					return this.processFiltersData(result);
				});
			} else {
				return this.getFiltersData(filterKeyNames).then(result => {
					return this.processFiltersData(result);
				});
			}
		}

		_getItemsByKey(data, key) {
			return data[key] && data[key].length ? data[key] : [];
		}

		generateParamsForCharacteristics(tisObjectIds, from, to) {
			const properties = {
				UnitSerialNumber: {
					name: COMPRESSOR_TYPE,
					sign: '~',
				},
				UnitModelNumber: {
					name: CONDENSER_TYPE,
					sign: '~',
				},
			};
			return {
				ids: tisObjectIds,
				hpath: Object.values(properties).map(property => `${property.sign}${property.name}|LATEST()`),
				from: moment(from),
				to: moment(to),
				timeZone: this.locationDetailsService.getLocationTimezone(),
			};
		}

		generateBoilerParamsForCharacteristics(tisObjectIds, from, to) {
			const properties = {
				UnitSerialNumber: {
					name: APPLICATION_TYPE,
					sign: '~',
				},
			};
			return {
				ids: tisObjectIds,
				hpath: Object.values(properties).map(property => `${property.sign}${property.name}|LATEST()`),
				from: moment(from),
				to: moment(to),
				timeZone: this.locationDetailsService.getLocationTimezone(),
			};
		}

		_pushParameters(parameters, newFilteredTisObjData) {
			for (let parameter of parameters) {
				if (parameter.name === COMPRESSOR_TYPE) {
					newFilteredTisObjData[TIS_OBJECT_SUBTYPE] = parameter.values[0].value;
				}
				if (parameter.name === CONDENSER_TYPE) {
					newFilteredTisObjData[TIS_OBJECT_CLASSIFICATION_TYPE] = parameter.values[0].value;
				}
			}
		}

		getCharacteristicsValuesIntoChillers(givenFilteredTisObjList, tisObjectDataList) {
			const newFilteredCharacteristicsValueList = [];
			let newFilteredTisObjData = {};

			for (let tisObjectData of tisObjectDataList) {
				for (let newTisObject of givenFilteredTisObjList) {
					if (newTisObject.tisObjectId === tisObjectData.tisObjectId) {
						newFilteredTisObjData = {...newTisObject};
						const {parameters} = tisObjectData;
						this._pushParameters(parameters, newFilteredTisObjData);
						newFilteredCharacteristicsValueList.push(newFilteredTisObjData);
					}
				}
			}
			return newFilteredCharacteristicsValueList;
		}

		_pushBoilerParams(parameters, boiler) {
			for (let parameter of parameters) {
				if (parameter.name === APPLICATION_TYPE) {
					if (parameter.values && parameter.values.length) {
						boiler['applicationType'] = parameter.values[0].value;
					}
				}
			}
		}

		getCharacteristicsValuesIntoBoilers(boilersList, tisObjectDataListBoiler) {
			const boilerCharacteristics = [];
			boilersList.forEach(boiler => {
				for (let tisObjectBoiler of tisObjectDataListBoiler) {
					if (boiler.tisObjectId === tisObjectBoiler.tisObjectId) {
						const {parameters} = tisObjectBoiler;
						this._pushBoilerParams(parameters, boiler);
						boilerCharacteristics.push(boiler);
					}
				}
			});
			return boilerCharacteristics;
		}

		getBoilerChartsFilter(applicationType) {
			const filterKeyName = APPLICATION_DATA_KEY_PREFIX_BOILER + applicationType.replace(' ', '');
			const url = `/ext_api/api/applicationData?classification=TCC&keys=${filterKeyName}`;
			return this.$http
				.get(url, {
					headers: this.handleServiceWorker.createServiceWorkerCacheKeyForRequest({
						cacheKey: `subtypeFilterService_getBoilerFilterData_${url}`,
						expiryTime: this.handleServiceWorker.CACHE_EXPIRE_TIME.IN_1_DAY,
					}),
				})
				.then(response => {
					const {data: {applicationDataList}} = response;
					if (applicationDataList && applicationDataList.length) {
						const {charts} = JSON.parse(applicationDataList[0].value);
						return charts;
					}
				})
				.catch(() => {
					return Promise.resolve([]);
				});
		}

		getBoilerTestsFilter(applicationType) {
			const filterKeyName = APPLICATION_DATA_KEY_PREFIX_BOILER + applicationType.replace(' ', '');
			const url = `/ext_api/api/applicationData?classification=TCC&keys=${filterKeyName}`;
			return this.$http
				.get(url, {
					headers: this.handleServiceWorker.createServiceWorkerCacheKeyForRequest({
						cacheKey: `subtypeFilterService_getBoilerFilterATData_${url}`,
						expiryTime: this.handleServiceWorker.CACHE_EXPIRE_TIME.IN_1_DAY,
					}),
				})
				.then(response => {
					const {data: {applicationDataList}} = response;
					if (applicationDataList && applicationDataList.length) {
						const {automatedTests} = JSON.parse(applicationDataList[0].value);
						return automatedTests;
					}
				})
				.catch(() => {
					return Promise.resolve([]);
				});
		}
	}
	angular.module('TISCC').service('subtypeFilterService', subtypeFilterService);
})();
