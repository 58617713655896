/* globals saveAs */
/* eslint quotes: [0, 'single'] */
/* eslint one-var: 0 */
import _get from 'lodash.get';

angular
	.module('TISCC')
	.service('facilityService', function($http) {
		this.chunkSize = 250;
		/*
	NOTE: this function returns immediately, but resource.data is modified asynchronously;
		  controller should watch for changes in resource.data and update view;
		  callback is called after every chunk load, % of loaded data is passed as argument
	*/
		this.getFacilitiesAsync = function(callback) {
			let getRange = function(uri, offset, size) {
				return $http.get(uri, {
					headers: {
						Range: 'items=' + offset + '-' + (offset + size - 1),
					},
				});
			};
			let parseRange = function(range) {
				if (range) {
					let spec = range.match(/items (\d+)-(\d+)\/(\d+)/);
					return {
						from: parseInt(spec[1]),
						to: parseInt(spec[2]),
						total: parseInt(spec[3]),
						rest: spec[3] - spec[2] - 1,
					};
				} else return null;
			};
			let results = [];
			let loadChunk = function(offset, size) {
				getRange('/ext_api/api/location/summary', offset, size).success(function(data, status, headers) {
					// getRange('https://tcc.tis-dev.trane.com/app/ext_api/api/locationDetails', offset, size).success(function(data, status, headers) {
					Array.prototype.push.apply(results, data.locationSummaryList);
					let range = parseRange(headers('Content-Range'));
					if (range) {
						callback(Math.round((range.to + 1) / range.total * 100));
						if (range.rest) {
							loadChunk(range.to + 1, range.rest > size ? size : range.rest);
						}
					} else {
						callback(100);
					}
				});
			};
			loadChunk(0, this.chunkSize);
			return results;
		};
		this.getFacilities = function(callback) {
			let results = [];
			$http.get('/ext_api/api/location/summary').success(function(data) {
				Array.prototype.push.apply(results, data.locationSummaryList);
				callback(100);
			});
			return results;
		};
	})
	.service('facilityListService', function($http, $timeout, $q, utilityService, cacheService, handleServiceWorker, VIEW_FILTERS) {
		const that = this;
		this.facilities = [];
		this.filteredFacilites = [];
		this.sieves = {};
		this.activeStatusStates = 'Active';
		// this.disabledStatusStates = 'Discontinued';

		this.getFacilities = function(callback) {
			let deferred = $q.defer();
			if (this.facilities.length === 0) {
				that
					.getFacilitiesByFilter({viewFilter: VIEW_FILTERS.ALL_FILTER}, callback)
					.then(function(facilities) {
						that.facilities = facilities;
						if (callback) callback(100, that.facilities);
						deferred.resolve(facilities);
					})
					.catch(function(e) {
						deferred.reject(e);
					});
			} else {
				if (callback) callback(100, that.facilities);
				deferred.resolve(that.facilities);
			}
			return deferred.promise;
		};

		this.getFacilitiesByFilter = function(filter, callback) {
			const {ACTIVE_FILTER, DISABLED_FILTER, ALL_FILTER, MIXED_FILTER} = VIEW_FILTERS;
			const {currentViewName} = filter;
			const getCacheInstance = uniqKey => cacheService.getCacheInstance('facilityListService', uniqKey);
			const url = '/ext_api/api/location/summaryNew';
			let {viewFilter = ALL_FILTER} = filter;

			const urlWithQueryParams = `${url}?activeStatusStates=${this.activeStatusStates}`;

			const requestsByFilter = {
				[ACTIVE_FILTER]: () =>
					$http.get(urlWithQueryParams, {
						cache: getCacheInstance('getFacilitiesByFilterActive'),
						headers: handleServiceWorker.createServiceWorkerCacheKeyForRequest({
							cacheKey: `getFacilitiesBy_${ACTIVE_FILTER}_${urlWithQueryParams}`,
							expiryTime: handleServiceWorker.CACHE_EXPIRE_TIME.IN_55_MINS,
						}),
					}),
				// [DISABLED_FILTER]: () =>
				// 	$http.get(`${url}?activeStatusStates=${this.disabledStatusStates}`, {cache: getCacheInstance('getFacilitiesByFilterDisabled')}),
			};

			if (currentViewName && [ACTIVE_FILTER, DISABLED_FILTER, ALL_FILTER].includes(viewFilter)) {
				viewFilter = MIXED_FILTER;

				requestsByFilter[MIXED_FILTER] = () =>
					$http.get(`${url}?view=${encodeURIComponent(currentViewName)}&activeStatusStates=${this.activeStatusStates}`, {
						headers: handleServiceWorker.createServiceWorkerCacheKeyForRequest({
							cacheKey: `getFacilitiesBy_${MIXED_FILTER}_${url}?view=${encodeURIComponent(currentViewName)}&activeStatusStates=${
								this.activeStatusStates
							}`,
							expiryTime: handleServiceWorker.CACHE_EXPIRE_TIME.IN_55_MINS,
						}),
					});
			}

			let locationSummaryPromise = null;

			switch (viewFilter) {
				case ACTIVE_FILTER:
				case ALL_FILTER: {
					locationSummaryPromise = requestsByFilter[ACTIVE_FILTER]();
					break;
				}
				// case DISABLED_FILTER: {
				// 	locationSummaryPromise = requestsByFilter[DISABLED_FILTER]();
				// 	break;
				// }
				// case ALL_FILTER: {
				// 	locationSummaryPromise = $q.all([requestsByFilter[ACTIVE_FILTER]()]).then(([{data: {locationSummaryList: locationSummaryListActive}}]) => {
				// 		const concatenatedList = [...locationSummaryListActive];

				// 		return {data: {locationSummaryList: concatenatedList}};
				// 	});
				// 	break;
				// }
				case MIXED_FILTER: {
					locationSummaryPromise = requestsByFilter[MIXED_FILTER]();
					break;
				}
				default:
					return $q.reject(new Error('Unknown filter is passed'));
			}

			const gatewayPromise = $http.get('/ext_api/api/location/gateway?type=all', {
				cache: getCacheInstance('getFacilitiesGateway'),
				headers: handleServiceWorker.createServiceWorkerCacheKeyForRequest({
					cacheKey: `getFacilitiesGateway_AllTypes_/ext_api/api/location/gateway?type=all`,
					expiryTime: handleServiceWorker.CACHE_EXPIRE_TIME.IN_10_MINS,
				}),
			});

			return Promise.all([locationSummaryPromise, gatewayPromise]).then(
				([{data: {locationSummaryList = []}} = {}, {data: {locationGatewayList = []}} = {}]) => {
					// TODO REMOVE: to check number of milliseconds to take complete the process
					const start = Date.now();

					const locationSummaryLength = locationSummaryList.length || 0;
					const locationGatwayLength = locationGatewayList.length || 0;
					const maxLength = Math.max.apply(null, [locationSummaryLength, locationGatwayLength]);

					const facilitiess = [];
					const gatewayKeyValObj = {};

					for (let i = 0; i < maxLength; i++) {
						if (i < locationSummaryLength) {
							const facility = locationSummaryList[i];
							facility.offerings = facility.offeringSourceMaps || facility.offerings;
							facilitiess.push(facility);
						}
						if (i < locationGatwayLength) {
							const gatewayFacility = locationGatewayList[i];
							gatewayKeyValObj[gatewayFacility.locationId] = gatewayFacility.gatewayList;
						}
					}

					facilitiess.forEach(facility => {
						const gatewayList = gatewayKeyValObj[facility.locationId] || false;
						if (gatewayList) {
							facility.gatewayList = gatewayList;
						}
					});

					// old implemenation for reference

					// const facilities = [...locationSummaryList].map(item => {
					// 	item.offerings = item.offeringSourceMaps || item.offerings;
					// 	item.gatewayList = (locationGatewayList.find(({locationId}) => locationId === item.locationId) || {}).gatewayList;
					// 	return item;
					// });

					// this.filteredFacilites = facilities;
					// callback && callback(100, facilities);
					// return facilities;

					// TODO REMOVE: to check number of milliseconds to take complete the process
					const end = Date.now();

					// eslint-disable-next-line no-console
					console.log('Time Difference :', end - start);

					this.filteredFacilites = facilitiess;

					callback && callback(100, facilitiess);

					return facilitiess;
				}
			);
		};

		this.getIndexedUniqueLists = function(properties) {
			const that = this;
			const propertyKeys = Object.keys(properties);

			let promise = $q.resolve();

			if (!this.facilities.length) {
				promise = this.getFacilities();
			}

			return promise.then(() => {
				const facilities = this.facilities;
				const facilitiesLength = facilities.length;
				const DOT = '.';
				let facility;

				const getFacilityPropValue = function(facility, prop) {
					const valueArr = prop.split(DOT);
					const valueArrLength = valueArr.length;
					let value = facility;

					if (!prop.includes(DOT)) return facility[prop];

					for (let i = 0; i < valueArrLength; i++) {
						value = value[valueArr[i]];
						if (value === undefined || value === null) return null;
					}
					return value;
				};

				propertyKeys.forEach(function(key) {
					that.sieves[key] = {};
				});

				for (let i = 0; i < facilitiesLength; i++) {
					facility = facilities[i];
					propertyKeys.forEach(function(key) {
						let propertyValue;
						let propertyType = properties[key].type;
						if (propertyType === 'object' || propertyType === 'array') {
							propertyValue = getFacilityPropValue(facility, properties[key].name);
							if (propertyValue === null || propertyValue === undefined) {
								return;
							}
						}
						switch (properties[key].type) {
							case 'object':
								that.sieves[key][propertyValue] = propertyValue;
								break;
							case 'array':
								propertyValue &&
									propertyValue.forEach(function(item) {
										if (item) {
											that.sieves[key][item] = item;
										}
									});
								break;
							case 'connectivity': {
								const connectivity = facility.locationConnectivity;
								if (connectivity && connectivity.status) {
									connectivity.devices.forEach(function(deviceList) {
										that.sieves.connectivityType[deviceList.device.deviceType] = deviceList.device.deviceType;
									});
								}
								break;
							}
							case 'states':
								if (facility.address && facility.address.country && facility.address.region) {
									let state = `${facility.address.country}: ${facility.address.region}`;
									that.sieves.states[state] = state;
								}
								break;
							case 'offerings':
								if (facility.offerings) {
									facility.offerings.forEach(function(offering) {
										if (offering.name) {
											that.sieves.offeringType[offering.name] = offering.name;
										}
									});
								}
								break;
						}
					});
				}

				propertyKeys.forEach(function(key) {
					let inputObject = that.sieves[key];
					that.sieves[key] = Object.keys(inputObject).sort();
				});

				return this.sieves;
			});
		};
	})
	.service('locationEquipmentService', function($http, helpers, cacheService, $q, handleServiceWorker) {
		let that = this;

		/**
		 *
		 * @param locationId {String | Number} id of location
		 * @param objectType pass 'falsy' value to get all objects at location
		 * 					 or string which will be used to filter results.
		 * @param isHierarchical
		 * @returns {promise}
		 */
		that.getLocationObjectsList = function(locationId, objectType, isHierarchical, tisObjectTypeGroupNumber) {
			let deferred = $q.defer();
			let cacheKey = 'LocationObjects' + (isHierarchical ? 'Hierarchy' : '');
			let url = '/ext_api/api/location/' + locationId + '/tisObject' + (isHierarchical ? '?displayType=hierarchical' : '');

			let config = {
				cache: cacheService.getCacheInstance('locationEquipmentService', cacheKey, locationId),
				headers: handleServiceWorker.createServiceWorkerCacheKeyForRequest({
					cacheKey: `locationEquipmentService_getLocationObjectsList_${url}`,
					expiryTime: handleServiceWorker.CACHE_EXPIRE_TIME.IN_4_HOURS,
				}),
			};

			$http.get(url, config).then(successHandler, deferred.reject.bind(deferred));

			function successHandler(data) {
				let tisObjList = data.data.tisObjectList;

				if (objectType) {
					if (isHierarchical) {
						throw new Error('Not implemented!');
					}

					tisObjList = data.data.tisObjectList.filter(function(tisObj) {
						return tisObj.tisObjectType.tisObjectTypeGroupName === objectType;
					});
				}

				deferred.resolve({
					tisObjectList: tisObjList,
				});
			}

			return deferred.promise;
		};

		that.getEquipmentServiceAdvisoryCount = function(tisObjectId, callback, errorCallback) {
			errorCallback = errorCallback || function() {};

			// Remove below line once API fixes the service advisory count issue.
			if (tisObjectId) {
				errorCallback(new Error('Disabling SA count request as Data is not accurate.'));
				return;
			}

			const config = {
				params: {
					tisObjectId,
					automatedTestLevels: 'Combined,Individual,AddonException',
				},
			};

			$http
				.get('/ext_api/analytics-api/serviceAdvisory/count', config)
				.success(callback)
				.error(errorCallback);
		};

		that.getEquipmentNotesCount = function(id, callback, errorCallback = () => {}) {
			const url = '/ext_api/api/note/count?noteType=Equipment&tisObjectId=' + id;
			return $http
				.get(url, {
					headers: handleServiceWorker.createServiceWorkerCacheKeyForRequest({
						cacheKey: `locationEquipmentService_getEquipmentNotesCount_${url}`,
						expiryTime: handleServiceWorker.CACHE_EXPIRE_TIME.IN_4_HOURS,
					}),
				})
				.success(response => {
					const count = helpers.getPropertyByPath(response, 'noteStatisticsList[0].notesCount');
					callback(Number.isInteger(+count) ? Number(count) : '?');
				})
				.error(errorCallback);
		};

		that.getEquipmentSerialNumber = function(id, callback, errorCallback = () => {}) {
			$http
				.get('/ext_api/api/tisObject/' + id + '/serialNumber')
				.success(callback)
				.error(errorCallback);
		};
		that.getEquipmentModelNumber = function(id, callback, errorCallback) {
			errorCallback = errorCallback || function() {};
			$http
				.get('/ext_api/api/tisObject/' + id + '/modelNumber')
				.success(callback)
				.error(errorCallback);
		};
		that.getEquipmentChildHierarchy = function(id, callback, errorCallback) {
			errorCallback = errorCallback || function() {};
			$http
				.get('/ext_api/api/tisObject/' + id + '/child/?displayType=hierarchical')
				.success(callback)
				.error(errorCallback);
		};
		that.searchParentTisObject = function(desiredTisObject, tisObjectsArr, parentTisObject) {
			let foundParent = null;
			tisObjectsArr.some(tisObject => {
				if (desiredTisObject.tisObjectId === tisObject.tisObjectId) {
					foundParent = parentTisObject;
					return true;
				} else if (tisObject.children && tisObject.children.length !== 0) {
					foundParent = that.searchParentTisObject(desiredTisObject, tisObject.children, tisObject);
					return foundParent || false;
				} else {
					return false;
				}
			});
			return foundParent;
		};
	})
	.service('automatedTestsService', function($http) {
		this.getAnalyticModels = function(name, callback, errorCallback) {
			errorCallback = errorCallback || function() {};
			// $http.get('/ext_api/analytics-api/analytic/model?tisObjectTypeGroupName=' + name).success(callback).error(errorCallback);
			$http
				.get('data/automated-tests.json')
				.success(callback)
				.error(errorCallback);
		};
	})
	.service('dimensionsService', function($http) {
		this.getDimensions = function(callback) {
			$http.get('/ext_api/api/dimensions').success(callback);
		};
	})
	.service('userDataService', function($http) {
		let config = {
			params: {
				classification: 'TCC',
			},
		};
		this.getUserData = function() {
			return $http
				.get('/ext_api/api/user/data', config)
				.then(response => response.data)
				.catch(response => undefined);
		};
		this.setUserData = function(data, callback, errorCallback) {
			$http
				.put('/ext_api/api/user/data', data, config)
				.success(callback)
				.error(errorCallback);
		};
	})
	.service('dataFormattingService', function($filter) {
		let that = this;
		that.MAX_ALLOWED_NUMBER_LENGTH = 6;
		// Should be Pow of 10 - 10, 100 or 0.1, 0.01 etc.
		that.floatPrecision = 1;

		/**
		 * Used to convert binaries into meaningful HReadable strings
		 *
		 * Function is duplicated in tisexport project code
		 *
		 * @param value
		 * @param binaryType
		 * @returns {*}
		 */
		that.applyBoolean = function(value, binaryType) {
			const binaryValueMappings = {
				heatCool: ['Cool', 'Heat'],
				default: ['Off', 'On'],
			};
			return binaryType && binaryValueMappings[binaryType]
				? binaryValueMappings[binaryType][+!!value] // To protect from breaking on input other than boolean or 1/0
				: binaryValueMappings.default[+!!value];
		};

		that.getDateInUTC = function(date) {
			let momentData = moment(date);
			momentData.add(momentData.utcOffset(), 'minutes');
			return momentData.utc();
		};

		/**
		 * Sets precision for current property
		 * @param value
		 */
		that.setPrecision = function(value) {
			const precisionDivider = '.';
			let precision = value + '';
			// Avoid potential div by 0, in case some DB error provides us with 0 here
			if (precision === '0' || precision === '1') {
				precision += precisionDivider;
			}
			that.floatPrecision = precision.split(precisionDivider)[1].length;
		};
		/**
		 * Applied value rounded to this.floatPrecision digits
		 * @param value
		 * @param resolution define number of digits
		 * @param useAbbreviation if set to true, convert number to 1K, 1M, 1B etc
		 * @returns {string}
		 */
		that.applyDecimalFormatting = function(value, resolution, useAbbreviation = false) {
			if (resolution !== undefined) {
				that.setPrecision(resolution);
			}
			if (value === null) {
				return NaN;
			}
			let formattedValue = Number(value).toFixed(that.floatPrecision);
			if (useAbbreviation && formattedValue.length > that.MAX_ALLOWED_NUMBER_LENGTH) {
				return d3.format('.2s')(value);
			} else {
				return formattedValue;
			}
		};
		/**
		 * Checks for existence of precision value, then sets one if true
		 * @param precisionValue
		 * @returns {boolean}
		 */
		that.checkPrecision = function(precisionValue) {
			if (precisionValue !== undefined) {
				precisionValue = +precisionValue;
				that.setPrecision(precisionValue);
				return true;
			}

			return false;
		};
		that.isEnumeration = function(name) {
			return [
				'DiagnosticAutomaticReset',
				'DiagnosticManualResetRequired',
				'OccupancyActive',
				'ConstantVolumeOperation',
				'DefaultBinaryProperty',
			].includes(name);
		};
		that.remapEnumeration = function(itemName, value) {
			// TODO: Consider merging these properties options with the BINARY_PROPERTY_MAPPING options.
			const properties = {
				DiagnosticAutomaticReset: [
					{
						variants: [false, 'false', 0, '0', 'Normal'],
						displayValue: $filter('translate')('LABEL_TIMELINE_NORMAL'),
					},
					{
						variants: [true, 'true', '1', 1, 'Alarm'],
						displayValue: $filter('translate')('LABEL_TIMELINE_ALARM'),
					},
				],
				DiagnosticManualResetRequired: [
					{
						variants: [false, 'false', 0, '0', 'Normal'],
						displayValue: $filter('translate')('LABEL_TIMELINE_NORMAL'),
					},
					{
						variants: [true, 'true', 1, '1', 'Alarm'],
						displayValue: $filter('translate')('LABEL_TIMELINE_ALARM'),
					},
				],
				OccupancyActive: [
					{
						variants: [true, '1', 'Unoccupied'],
						displayValue: $filter('translate')('LABEL_TIMELINE_UNOCCUPIED'),
					},
					{
						variants: [false, '0', 'Occupied'],
						displayValue: $filter('translate')('LABEL_TIMELINE_OCCUPIED'),
					},
				],
				ConstantVolumeOperation: [
					{
						variants: [true, '1', 'Yes'],
						displayValue: $filter('translate')('LABEL_TIMELINE_YES'),
					},
					{
						variants: [false, '0', 'No'],
						displayValue: $filter('translate')('LABEL_TIMELINE_NO'),
					},
				],
				HeatCoolMode: [
					{
						variants: [true, '1', 'Heat'],
						displayValue: $filter('translate')('LABEL_TIMELINE_HEAT'),
					},
					{
						variants: [false, '0', 'Cool'],
						displayValue: $filter('translate')('LABEL_TIMELINE_COOL'),
					},
				],
				FanState: [
					{
						variants: [true, '1', 'On'],
						displayValue: $filter('translate')('LABEL_TIMELINE_ON'),
					},
					{
						variants: [false, '0', 'Off'],
						displayValue: $filter('translate')('LABEL_TIMELINE_OFF'),
					},
				],
				DefaultBinaryProperty: [
					{
						variants: [true, '1', 'Yes'],
						displayValue: $filter('translate')('LABEL_TIMELINE_YES'),
					},
					{
						variants: [false, '0', 'No'],
						displayValue: $filter('translate')('LABEL_TIMELINE_NO'),
					},
				],
			};

			const remappedValue = properties[itemName].find(item => item.variants.includes(value));

			return remappedValue ? remappedValue.displayValue : value;
		};
	})
	.service('connectivityTypesService', function($http, handleServiceWorker) {
		this.getAllConnectivityTypes = function() {
			return $http
				.get('/ext_api/api/connectivityType', {
					headers: handleServiceWorker.createServiceWorkerCacheKeyForRequest({
						cacheKey: 'connectivityTypesService_connectivityTypeList_/ext_api/api/connectivityType',
						expiryTime: handleServiceWorker.CACHE_EXPIRE_TIME.IN_3_DAYS,
					}),
				})
				.then(response => {
					return _get(response, 'data.connectivityTypeList');
				})
				.catch(response => undefined);
		};
	});
