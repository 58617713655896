/**
 * Every chart that you add should be extended from it.
 * Defines common interface for chart rendering lifecycle.
 */
class AbstractChartRenderer {
	constructor(tooltip, translate) {
		this.translate = translate;
		this.tooltip = tooltip;
	}

	/**
	 * Will be called before first drawData call.
	 * @param newSvgSizeParams {width, height, chartHeight}
	 * @param svg
	 */
	onSvgReceivedSize(newSvgSizeParams, svg) {
		// not implemented
	}

	/**
	 * Will be called every time when user change screen size.
	 * @param newSvgSizeParams {width, height, chartHeight}
	 */
	onSvgSizeUpdate(newSvgSizeParams) {
		// not implemented
	}

	/**
	 * Will be called every time user clicks on ruller icons.
	 * @param {boolean} enabledRuller
	 */
	onSwitchRuller(enabledRuller) {
		// not implemented
	}

	/**
	 *
	 * @param chartOptions
	 * @param chartData
	 */
	drawData(chartOptions, chartData) {
		// not implemented
	}

	/**
	 *
	 * @param range new range
	 * @param tz time zone
	 */
	onRangeXUpdated(range, tz) {
		// not implemented
	}

	/**
	 * Called when user narrow down time range.
	 * @param chartOptions
	 * @param chartData
	 */
	onBrushRange(brushRange, chartOptions, chartData) {
		this.dataNode.selectAll('*').remove();
		this.drawData(chartOptions, chartData);
	}

	/**
	 * Will be called every time user enables/disables displaying of chart property.
	 * @param chartOptions
	 * @param chartData
	 * @param toggledLine
	 */
	toggleChartLine(chartOptions, chartData, toggledLine) {
		this.dataNode.selectAll('*').remove();
		this.drawData(chartOptions, chartData);
	}

	updateGrid() {}

	static calculateGroups(groupsToCalculate, groupMap) {
		const MAX_GROUP_RADIUS = 25;
		groupsToCalculate.forEach(item => {
			const {data, xAxis, yAxis} = item;
			data.reduce((result, item) => {
				let group = {number: 1, count: 1};
				let nextFirstPoint = item;

				if (result.firstPoint && result.prevPoint) {
					let {firstPoint, prevPoint} = result;

					let firstX = xAxis.x(firstPoint.x);
					let firstY = yAxis.y(firstPoint.y);

					let prevX = xAxis.x(prevPoint.x);
					let prevY = yAxis.y(prevPoint.y);

					let lastX = xAxis.x(item.x);
					let lastY = yAxis.y(item.y);

					let prevDistance = Math.sqrt(Math.pow(prevX - lastX, 2) + Math.pow(prevY - lastY, 2));

					let groupDistance = Math.sqrt(Math.pow(firstX - lastX, 2) + Math.pow(firstY - lastY, 2));

					if (groupDistance < MAX_GROUP_RADIUS && prevDistance < (AbstractChartRenderer.CIRCLE_RADIUS + 1) * 2) {
						group = result.firstPoint.group;
						group.count++;
						nextFirstPoint = result.firstPoint;
					} else {
						group = {number: ++result.firstPoint.group.number, count: 1};
					}
					let strokeLength = Math.max(lastX - prevX, 0);
					if (typeof prevPoint.strokeLength === 'number') {
						item.strokeLength = Math.max(strokeLength - prevPoint.strokeLength, 0);
					} else {
						item.strokeLength = strokeLength / 2;
						prevPoint.strokeLength = strokeLength / 2;
					}
				}
				item.group = group;
				if (group && groupMap.has(group)) {
					groupMap.get(group).push(item);
				} else {
					groupMap.set(group, [item]);
				}
				return Object.assign(result, {
					firstPoint: nextFirstPoint,
					prevPoint: item,
				});
			}, {});
		});
	}
}

AbstractChartRenderer.CIRCLE_RADIUS_HOVERED = 3.5;
AbstractChartRenderer.CIRCLE_RADIUS = 2.5;

window.AbstractChartRenderer = AbstractChartRenderer;
