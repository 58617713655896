import CriteriaObject from './criteria-object';
import MyViewsAdapterV2 from './my-views-adapter-v2';
import MyViewsGroup from './my-views-group-class';
import './my-views-edit-dialog.scss';
import {USER_EVENTS} from '../../common/usage-tracking/categories';

const {HOME_PAGE: {events: HOME_PAGE_EVENTS, properties: HOME_PAGE_PROPERTIES, categoryName: HOME_PAGE_CATEGORY_NAME}} = USER_EVENTS;

const CODE_UP_KEY = 38;
const CODE_DOWN_KEY = 40;
const CODE_ENTER_KEY = 13;

angular.module('TISCC').controller('MyViewsEditDialogCtrl', MyViewsEditDialogCtrl);

function MyViewsEditDialogCtrl(
	$scope,
	$modalInstance,
	viewDataEvent,
	data,
	index,
	list,
	currentViewName,
	userDataService,
	facilityListService,
	$filter,
	OPERATOR,
	helpers,
	usageTrackingService
) {
	const myViewConfirmations = ['applied', 'appliedAndSaved'];

	let searchString = '';
	let timedSearchID = null;
	let hoverByMouseTimer = null;

	$scope.viewDataEvent = viewDataEvent;
	$scope.myViewsList = list;
	$scope.emptyItems = true;
	$scope.isChanged = false;
	$scope.currentViewName = currentViewName;
	$scope.editCurrent = false;
	$scope.saveProccess = false;
	$scope.myViewValues = {};
	$scope.listElements = [];
	$scope.dimensions = Object.keys(OPERATOR);
	$scope.name = '';
	$scope.oldName = '';
	$scope.emptyName = false;
	$scope.duplicatedName = false;
	$scope.isNew = true;
	$scope.list = [];

	$scope.changeName = changeName;
	$scope.findSettings = findSettings;
	$scope.callbackOnEdit = callbackOnEdit;
	$scope.addGroup = addGroup;
	$scope.removeLastGroup = removeLastGroup;
	$scope.apply = apply;
	$scope.cancel = cancel;
	$scope.onSaveButtonClick = onSaveButtonClick;

	init();

	// Functions
	function init() {
		document.addEventListener('keypress', findCriteriaByKey);
		document.addEventListener('keydown', navigateCriteriaByArrowKey);
		document.addEventListener('mousemove', setMouseHover);
		document.addEventListener('mouseover', navigateCriteriaByMouse);

		$scope.$on('$destroy', function() {
			document.removeEventListener('keypress', findCriteriaByKey);
			document.removeEventListener('keydown', navigateCriteriaByArrowKey);
			document.removeEventListener('mousemove', setMouseHover);
			document.removeEventListener('mouseover', navigateCriteriaByMouse);
		});

		hideConfirmationText();

		facilityListService
			.getIndexedUniqueLists({
				accountNames: {name: 'accountName', type: 'object'},
				connectivityType: {name: 'locationConnectivity.devices', type: 'connectivity'},
				facilityPhase: {name: 'statusName', type: 'object'},
				offeringType: {name: 'offerings', type: 'offerings'},
				TISEquipmentType: {name: 'tisObjectTypeGroupNames', type: 'array'},
				traneOfficeCode: {name: 'salesOffice.officeCode', type: 'object'},
				countries: {name: 'address.country', type: 'object'},
				states: {name: 'address.country', type: 'states'},
			})
			.then(values => {
				$scope.myViewValues = values;
				$scope.listElements = getListElements($scope.myViewValues);

				for (let i = 0; i < $scope.listElements.length; i++) {
					const byLocaleCompare = (a, b) => (a.translate && b.translate ? a.translate.localeCompare(b.translate) : a.name.localeCompare(b.name));
					const isTextValue = $scope.listElements[i].value.name === 'text';
					for (let m = 0; m < $scope.listElements[i].value.length; m++) {
						const name = $scope.listElements[i].value[m].name;
						if ($scope.listElements[i].needTranslate) {
							let translateObj = $filter('translate')(`EQUIPMENT_TYPE_NAME_TABLE.${name}`);
							if (translateObj.indexOf('EQUIPMENT_TYPE_NAME_TABLE') === 0) {
								translateObj = $filter('translate')(name);
							}
							$scope.listElements[i].value[m].translate = translateObj;
						} else if (isTextValue) {
							$scope.listElements[i].value.translate = false;
						} else {
							$scope.listElements[i].value[m].translate = name;
						}
					}
					if (!isTextValue) {
						$scope.listElements[i].value.sort(byLocaleCompare);
					}
				}

				if (data) {
					$scope.isNew = false;
					$scope.name = data.name;
					$scope.oldName = data.name + '';
					$scope.editCurrent = $scope.currentViewName === data.name;

					data.groups.forEach(({groupOperator, ruleOperator, rules}) => {
						$scope.list.push(
							new MyViewsGroup({
								groupOperator,
								ruleOperator,
								rules: rules.map(
									rule =>
										new CriteriaObject({
											name: rule.name,
											operator: rule.operator !== undefined ? rule.operator : rule.equal,
											criteria: rule.value,
											findSettings,
											callbackOnEdit,
										})
								),
							})
						);
					});

					$scope.emptyItems = !checkIsFilled($scope.list);
				} else {
					$scope.list.push(
						new MyViewsGroup({
							rules: [
								new CriteriaObject({
									name: $scope.listElements[0].name,
									findSettings,
									callbackOnEdit,
								}),
							],
						})
					);
				}
			});
	}

	function addGroup() {
		$scope.list.push(
			new MyViewsGroup({
				rules: [
					new CriteriaObject({
						name: $scope.listElements[0].name,
						findSettings,
						callbackOnEdit,
					}),
				],
			})
		);
		$scope.emptyItems = !checkIsFilled($scope.list);
	}

	function removeLastGroup() {
		$scope.list.pop();
		$scope.emptyItems = !checkIsFilled($scope.list);
	}

	function cancel() {
		$modalInstance.dismiss('cancel');
	}

	function apply(action) {
		const showConfirmationText = action => {
			const property = myViewConfirmations.includes(action) ? action : 'saved';
			$scope.saveProccess = false;
			$scope[property] = true;
		};

		const result = {
			version: MyViewsAdapterV2.VERSION,
			name: $scope.name,
			groups: [],
		};
		$scope.emptyName = false;
		$scope.duplicatedName = false;

		hideConfirmationText();

		if (!$scope.name) {
			$scope.emptyName = true;
			return;
		}
		if (!checkUniq($scope.name)) {
			$scope.duplicatedName = true;
			return;
		}

		if (!$scope.emptyItems) {
			for (let i = 0; i < $scope.list.length; i++) {
				if ($scope.list[i]) {
					const {groupOperator, ruleOperator, rules} = $scope.list[i];

					result.groups.push(
						new MyViewsGroup({
							groupOperator,
							ruleOperator,
							rules: rules.map(rule => rule.getJSON()),
						})
					);
				}
			}
		}
		if (index === false) {
			index = $scope.myViewsList.length;
			$scope.myViewsList.push(result);
		} else {
			$scope.myViewsList[index] = result;
		}
		$scope.oldName = $scope.name + '';
		let needApply = $scope.editCurrent;
		if (!needApply) {
			if ($scope.isChanged) {
				needApply = true;
			} else {
				$scope.viewDataEvent.emit('changeCurrentView', {
					currentViewName: $scope.name,
					currentViewData: result.list,
					forceUpdate: true,
				});
				return showConfirmationText(action);
			}
		}
		$scope.saveProccess = true;
		$scope.viewDataEvent.emit('updateMyView', {
			data: $scope.myViewsList,
			updatedViewName: $scope.name,
			updatedViewData: result.list,
			editCurrentView: needApply,
			callback: showConfirmationText.bind(null, action),
			errorCallback: () => {
				$scope.saveProccess = false;
				hideConfirmationText();
			},
		});
	}

	function checkIsFilled(groupList = []) {
		return (
			groupList.length &&
			groupList.every(({groupOperator, ruleOperator, rules}) => {
				return (
					groupOperator &&
					ruleOperator &&
					rules.length &&
					rules.every(rule => {
						const {name, operator, value} = rule.getJSON();
						return name && Number(operator) > -1 && value;
					})
				);
			})
		);
	}

	function changeName(name) {
		$scope.emptyName = false;
		$scope.duplicatedName = false;
		$scope.isChanged = true;
		$scope.name = name;
		if (!checkUniq(name)) {
			$scope.duplicatedName = true;
		}
		hideConfirmationText();
	}

	function checkUniq(name) {
		if (!name || name === $scope.oldName) return true;
		for (let i = 0; i < $scope.myViewsList.length; i++) {
			if ($scope.myViewsList[i].name === name) {
				return false;
			}
		}
		return true;
	}

	function findSettings(name) {
		for (let i = 0; i < $scope.listElements.length; i++) {
			if ($scope.listElements[i].name === name) {
				const isTextValue = helpers.getPropertyByPath($scope.listElements[i], 'value.name') === 'text';
				if (!isTextValue) {
					return [$scope.listElements[i].value, $scope.listElements[i].dimensions];
				}
				return [false, $scope.listElements[i].dimensions];
			}
		}

		return [false, false];
	}

	function getListElements(myViewValues) {
		return [
			{
				name: 'Account',
				value: myViewValues.accountNames.map(t => ({name: t})),
			},
			{
				name: 'Connectivity Status',
				value: ['LEGEND_CONNECTED', 'LEGEND_NOT_CONNECTED', 'LEGEND_PARTIALLY_CONNECTED'].map(t => ({name: t})),
				needTranslate: true,
			},
			{
				name: 'Connectivity Type',
				value: myViewValues.connectivityType.map(t => ({name: t})),
				needTranslate: true,
			},
			{
				name: 'Country',
				value: myViewValues.countries.map(t => ({name: t})),
			},
			{
				name: 'Facility Name',
				value: {name: 'text'},
				dimensions: true,
			},
			{
				name: 'Facility Phase',
				value: myViewValues.facilityPhase.map(t => ({name: t})),
				needTranslate: true,
			},
			{
				name: 'Offering Type',
				value: myViewValues.offeringType.map(t => ({name: t})),
				needTranslate: true,
			},
			{
				name: 'Office Code',
				value: myViewValues.traneOfficeCode.map(t => ({name: t})),
			},
			{
				name: 'State',
				value: myViewValues.states.map(t => ({name: t})),
			},
			{
				name: 'TIS Equipment Type',
				value: myViewValues.TISEquipmentType.map(t => ({name: t})),
				needTranslate: true,
			},
		];
	}

	function callbackOnEdit() {
		$scope.isChanged = true;
		$scope.emptyItems = !checkIsFilled($scope.list);
		hideConfirmationText();
	}

	function hideConfirmationText() {
		myViewConfirmations.concat('saved').forEach(property => {
			$scope[property] = false;
		});
	}

	function scrollToItem(func, menuItems) {
		let found = false;
		Array.prototype.some.call(menuItems, item => {
			const searchItem = item.innerHTML.toLowerCase();
			if (func.call(searchItem, searchString)) {
				item.scrollIntoView();
				item.classList.add('selected');
				found = true;
				return true;
			}
		});
		return found;
	}

	function timedSearch(menuItems) {
		if (menuItems && menuItems.length) {
			const func = String.prototype.startsWith;
			let found = scrollToItem(func, menuItems);
			if (!found && searchString.length > 2) {
				const func = String.prototype.includes;
				scrollToItem(func, menuItems);
			}
		}

		searchString = '';
		timedSearchID = null;
	}

	// Event Listeners
	function findCriteriaByKey(event) {
		const char = event.char || event.key;
		if (char || event.keyCode === CODE_ENTER_KEY) {
			if (hoverByMouseTimer) {
				clearTimeout(hoverByMouseTimer);
				hoverByMouseTimer = null;
			}
			const criteriaList = document.getElementsByClassName('criteria-list open');
			if (criteriaList && criteriaList[0]) {
				const menuItems = criteriaList[0].getElementsByClassName('critera-item');
				if (event.keyCode === CODE_ENTER_KEY) {
					event.preventDefault();
					const selectedItem = criteriaList[0].getElementsByClassName('critera-item selected').item(0);
					if (selectedItem) {
						selectedItem.classList.remove('selected');
						selectedItem.click();
					}
				} else if (char) {
					searchString = searchString + char;
					if (timedSearchID) {
						clearTimeout(timedSearchID);
					}
					timedSearchID = setTimeout(() => {
						const selectedItem = criteriaList[0].getElementsByClassName('critera-item selected')[0];
						if (selectedItem) {
							selectedItem.classList.remove('selected');
						}
						timedSearch(menuItems);
					}, 1000);
				}
			}
		}
	}

	function navigateCriteriaByArrowKey(event) {
		if (event.keyCode === CODE_UP_KEY || event.keyCode === CODE_DOWN_KEY) {
			if (hoverByMouseTimer) {
				clearTimeout(hoverByMouseTimer);
				hoverByMouseTimer = null;
			}
			const criteriaList = document.getElementsByClassName('criteria-list open');
			if (criteriaList && criteriaList[0]) {
				const menuItems = criteriaList[0].getElementsByClassName('critera-item');
				event.preventDefault();
				const selectedItem = criteriaList[0].getElementsByClassName('critera-item selected')[0];
				if (!selectedItem) {
					menuItems[0].classList.add('selected');
				} else {
					Array.prototype.some.call(menuItems, (menuItem, index, array) => {
						if (menuItem.classList.contains('selected')) {
							menuItem.classList.remove('selected');
							let nextItem = null;
							if (event.keyCode === CODE_DOWN_KEY) {
								nextItem = array[index + 1] || array[0];
							} else if (event.keyCode === CODE_UP_KEY) {
								nextItem = array[index - 1] || array[array.length - 1];
							}
							if (nextItem) {
								criteriaList[0].classList.add('keyboard');
								nextItem.classList.add('selected');
								nextItem.scrollIntoView();
							}
							return true;
						}
					});
				}
			}
		}
	}

	function setMouseHover(event) {
		if (event.target.classList && event.target.classList.contains('critera-item') && !hoverByMouseTimer) {
			hoverByMouseTimer = setTimeout(() => {
				const criteriaList = document.getElementsByClassName('criteria-list open keyboard');
				if (criteriaList && criteriaList[0]) {
					criteriaList[0].classList.remove('keyboard');
					hoverByMouseTimer = null;
				}
			}, 200);
		}
	}

	function navigateCriteriaByMouse(event) {
		const criteriaList = document.getElementsByClassName('criteria-list open keyboard');
		if (!(criteriaList && criteriaList[0])) {
			if (event.target.classList && event.target.classList.contains('critera-item')) {
				const selectedItem = document.getElementsByClassName('critera-item selected')[0];
				if (selectedItem) {
					selectedItem.classList.remove('selected');
				}
				event.target.classList.add('selected');
			}
		}
	}

	function onSaveButtonClick() {
		apply('appliedAndSaved');
		usageTrackingService.trackEvent(HOME_PAGE_CATEGORY_NAME, HOME_PAGE_EVENTS.CREATE_NEW_VIEW);
	}
}
