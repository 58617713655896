angular.module('TISCC').service('characteristicCurveService', function($http) {
	this.getCurveValuesForTisObjectIds = function(params) {
		const apiUrl = '/ext_api/api/characteristicCurve';
		const config = {};

		config.headers = {
			CircuitIds: params.ids.join(','),
		};
		if (params.curveTypeName) {
			config.headers.curveTypeName = params.curveTypeName;
		}
		return $http.get(apiUrl, config).then(result => {
			/** @namespace result.data.characteristicCurveList */
			return result.data.characteristicCurveList;
		});
	};
});
