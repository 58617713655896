/* eslint quotes: [0, 'single'] */
/* eslint eqeqeq: [0, 'always'] */
/* eslint semi: [0, 'always'] */

angular.module('TISCC').controller('MultipleFacilitySelectionModalCtrl', MultipleFacilitySelectionModalCtrl);

function MultipleFacilitySelectionModalCtrl($scope, $modalInstance, $translate, $timeout, data, facilityListService) {
	const RENDERED_ITEMS_LIMIT = 200;

	$scope.facilities = data.facilities;
	$scope.selectedFacilities = data.selectedFacilities;
	$scope.viewData = {
		checkAll: false,
	};
	$scope.changeCheckboxes = changeCheckboxes;
	$scope.selectDeselectAll = selectDeselectAll;
	$scope.getCheckedFacilities = getCheckedFacilities;
	$scope.onApply = onApply;
	$scope.cancel = cancel;
	$scope.selectAccount = selectAccount;
	$scope.getFilteredFacilities = getFilteredFacilities;

	$scope.facilities.forEach(facility => {
		facility.checked = !!$scope.selectedFacilities.find(f => f.locationId === facility.locationId);
	});

	$scope.listAccounts = [
		{
			name: 'All Accounts',
			value: 'ALL',
		},
	];
	$scope.defaultSelection = 'All Accounts';
	$scope.readingAccounts = true;
	facilityListService
		.getIndexedUniqueLists({
			accountNames: {name: 'accountName', type: 'object'},
		})
		.then(values => {
			$scope.listAccounts = values.accountNames.reduce(
				(result, value) => {
					result.push({name: value, value});
					return result;
				},
				[...$scope.listAccounts]
			);
			$scope.readingAccounts = false;
		});

	updateSelectAllBtn();
	refreshApplyButtonState(false, false);

	function selectAccount(index) {
		$scope.currentAccount = index;
	}

	function getFilteredFacilities(searchText) {
		const currentAccountName = $scope.currentAccount ? $scope.listAccounts[$scope.currentAccount].name : undefined;
		const preFilteredFacilities = $scope.facilities
			.filter(facility => {
				return (
					(!currentAccountName || facility.accountName === currentAccountName) &&
					(!searchText || facility.locationName.toLowerCase().includes(searchText.toLowerCase()))
				);
			})
			.filter((facility, index) => {
				return currentAccountName || index < RENDERED_ITEMS_LIMIT || facility.checked;
			});
		return preFilteredFacilities;
	}

	function changeCheckboxes($event, item) {
		// This handler is added to the label which is the wrapper for the input.
		// Previously we connected label and input via for/id, and had the following bug:
		// the first click on the location name label doesn't check/uncheck the checkbox (https://tranetis.jira.com/browse/TCCFOUR-12166).
		// There is a known issue with Angular UI modal scope (https://stackoverflow.com/questions/18924577/scope-issues-with-angular-ui-modal),
		// but fix from stackoverflow didn't resolve our problem.
		// That's why ng-model is not used, and the changeCheckboxes function added as the handler on label click.
		$event.preventDefault();

		$timeout(() => {
			item.checked = !item.checked;
			updateSelectAllBtn();
		}, 0);
	}

	function getCheckedFacilities() {
		const facilities = this.filteredFacilities || $scope.facilities;
		return facilities.filter(f => f.checked);
	}

	function updateSelectAllBtn() {
		let checkedCount = $scope.facilities.filter(f => f.checked).length;

		if (!checkedCount) {
			$scope.viewData.checkAll = false;
		} else if ($scope.facilities.length === checkedCount) {
			$scope.viewData.checkAll = true;
		} else {
			$scope.viewData.checkAll = null;
		}
		if (checkedCount === 0) {
			refreshApplyButtonState(false, false);
		} else {
			refreshApplyButtonState(true);
		}
	}

	function selectDeselectAll(status) {
		const facilities = this.filteredFacilities || $scope.facilities;
		let useDeselectAll = false;
		useDeselectAll = status === false || (status && (!this.filteredFacilities || facilities.every(facility => facility.checked === true)));
		status = !useDeselectAll;

		facilities.forEach(facility => (facility.checked = status));
		updateSelectAllBtn();
	}

	function refreshApplyButtonState(isApplyButtonVisible, isChangeFeedbackVisibility = true) {
		$scope.isApplyButtonEnabled = isApplyButtonVisible;

		if (isChangeFeedbackVisibility) {
			$scope.isApplyFeedbackVisible = !isApplyButtonVisible;
		}
	}

	function onApply() {
		$scope.selectedFacilities = $scope.facilities.filter(f => f.checked);
		refreshApplyButtonState(false);
	}

	function cancel() {
		$modalInstance.close($scope.selectedFacilities);
	}
}
