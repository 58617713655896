import _get from 'lodash.get';

angular.module('TISCC').service('alarmAndEventsService', function($http, cacheService, handleServiceWorker) {
	this.getLocationDeviceList = function(locationId, callback) {
		let config = {};
		let cacheKey = [locationId].join('/');
		const url = `/ext_api/api/location/${locationId}/connectivity`;

		config.params = {
			locationId,
		};
		config.cache = cacheService.getCacheInstance('alarmAndEventsService', 'alarmAndEvents', cacheKey);

		config.headers = handleServiceWorker.createServiceWorkerCacheKeyForRequest({
			cacheKey: `alarmAndEventsService_getLocationDeviceList_${url}/${cacheKey}`,
			expiryTime: handleServiceWorker.CACHE_EXPIRE_TIME.IN_55_MINS,
		});

		let request = $http.get(url);

		if (callback) {
			return request.success(callback);
		} else {
			return request;
		}
	};
});
