import {CPR_DATE_FORMATS} from '../../../chiller-performance-report-config';

(function() {
	const PLACE_HOLDER = '--';
	const services = new WeakMap();

	class PerformanceAssessmentTableComponentController {
		constructor(dataFormattingService, helpers) {
			services.set(this, {dataFormattingService, helpers});

			this.bins = [0, 20, 40, 60, 80];
		}

		$onInit() {
			this.currentMonth = this.toDate.format(CPR_DATE_FORMATS.LONG_MONTH_FORMAT);
			this.currentMonthAndYear = this.toDate.format(CPR_DATE_FORMATS.LONG_MONTH_YEAR_FORMAT);
		}

		getValue(value, resolution) {
			const {dataFormattingService, helpers} = services.get(this);

			if (value === null || typeof value === 'undefined') {
				return PLACE_HOLDER;
			} else if (helpers.isNumber(value)) {
				return dataFormattingService.applyDecimalFormatting(parseFloat(value), resolution);
			} else {
				return value;
			}
		}
	}

	angular.module('TISCC').component('cprPerformanceAssessmentTableComponent', {
		templateUrl: 'components/reports/chiller-performance/page/report-section/assessment/performance-assessment-table-component.html',
		controller: PerformanceAssessmentTableComponentController,
		bindings: {
			header: '<',
			description: '<',
			equipment: '<',
			tableData: '<',
			toDate: '<',
			isFirst: '<',
		},
	});
})();
