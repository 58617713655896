import {USER_EVENTS} from '../../common/usage-tracking/categories';
import {CURRENT_PAGE, PRIMARY_OFFERING} from '../../common/usage-tracking/common/properties-names';
import {PRIMARY_OFFERINGS} from '../../common/usage-tracking/common/primary-offerings';
import _get from 'lodash.get';
import _uniqBy from 'lodash/uniqBy';

const {
	EQUIPMENT_PERFORMANCE_CHARTS: {
		events: EQUIPMENT_PERFORMANCE_CHARTS_EVENTS,
		properties: EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES,
		categoryName: EQUIPMENT_PERFORMANCE_CHARTS_CATEGORY_NAME,
	},
} = USER_EVENTS;

(function() {
	const services = new WeakMap();
	let MEAN_OF_ALL_TEXT;

	const getParentContainerByClassName = (element, className) => {
		let p = element.parentNode;
		while (!p.className.includes(className)) {
			if (!p.parentNode) break;
			p = p.parentNode;
		}
		return p.getBoundingClientRect();
	};

	class ChartLegendController {
		constructor($scope, $timeout, $route, colorService, translateService, usageTrackingService, CHART_TYPE) {
			services.set(this, {$scope, $timeout, colorService});
			this.CHART_TYPE = CHART_TYPE;
			this.areLinesNotModified = true;
			this.uniqId = $scope.$id;
			this.$onInit = () => {
				this.renderedChartAxisHeader = {};
				MEAN_OF_ALL_TEXT = translateService.translateProperty('MeanOfAll');
				this.isChartLegendFilterDropDownVisible = false;
				this.hideChartLegendFilterDropDownBound = this.hideChartLegendFilterDropDown.bind(this);
				this.isTimelineLegendFilterDropDownVisible = false;
				this.hideTimelineLegendFilterDropDownBound = this.hideTimelineLegendFilterDropDown.bind(this);
				this.updateChartLegendToggleStateBound = this.updateChartLegendToggleState.bind(this);
				this.setLinesModifiedBound = this.setLinesModified.bind(this);
				this.isTimelineVisible = true;
				this.isChartLegendExpanded = true;

				if (this.eventObject) {
					this.eventObject.on('updateChartLegendToggleState', this.updateChartLegendToggleStateBound);
					this.eventObject.on('setLinesModified', this.setLinesModifiedBound);
				}
			};
			this.$onDestroy = () => {
				if (this.eventObject && typeof this.eventObject.off === 'function') {
					this.eventObject.off('updateChartLegendToggleState', this.updateChartLegendToggleStateBound);
					this.eventObject.off('setLinesModified', this.setLinesModifiedBound);
				}
			};

			this.trackEvent = usageTrackingService.trackEventByCategory(EQUIPMENT_PERFORMANCE_CHARTS_CATEGORY_NAME, {
				[CURRENT_PAGE]: _get($route, 'current.metricsConfig.pathName', $route.current.templateUrl),
			});
		}

		fitDataIntoWindowHandler() {
			this.areLinesNotModified = true;
			this.fitDataIntoWindow();
		}

		setLinesModified() {
			this.areLinesNotModified = false;
		}

		isChartAxisHeaderRendered(line) {
			const symbol = line.uom.symbol;
			if (!this.renderedChartAxisHeader[symbol]) {
				this.renderedChartAxisHeader[symbol] = true;
				return true;
			}
			return false;
		}

		getXAxis(lines) {
			this.renderedChartAxisHeader = {};
			return lines.filter(line => line.chartAxisId === 'x').sort((a, b) => a.uom.symbol.localeCompare(b.uom.symbol));
		}

		sortYAxis(lines) {
			return lines.sort((a, b) => a.name.localeCompare(b.name));
		}

		toggleLineHandler(line) {
			this.setLinesModified();
			line.visible = !line.visible;
			line.touched = true;

			if (this.legendSettings.chartLegendToggleState === 'disabled') {
				this.legendSettings.chartLegendToggleState = 'hide';
			} else if (this.legendSettings.chartLegendToggleState === 'show') {
				line.isDisabledByLegendFilter = true;
			} else {
				if (this.checkAllLinesVisiblity()) {
					this.legendSettings.chartLegendToggleState = 'disabled';
				}
			}
			this.toggleLine({line});
		}

		toggleLinesHandler(axis, value) {
			// switch on all lines and mean
			// switch off all lines except mean one
			this.lines.forEach(line => {
				if (line.chartAxisId === axis.chartAxisId) {
					if (value || (!value && line.description !== MEAN_OF_ALL_TEXT)) {
						line.visible = value;
					}
				}
			});
			this.toggleLines({value});
		}

		checkAllLinesVisiblity() {
			let isAllLinesVisible = this.lines.every(line => {
				return line.visible;
			});

			if (isAllLinesVisible && this.thresholds && typeof this.thresholds.every === 'function') {
				isAllLinesVisible = this.thresholds.every(e => {
					return e.visible;
				});
			}
			return isAllLinesVisible;
		}

		getTimeLineMarkStyle(color) {
			let {colorService} = services.get(this);
			const opacity = 0.3;
			this.lanes = _uniqBy(this.lanes, 'name');
			if (Array.isArray(color)) {
				const [color1, color2] = color;

				return {
					'background-image': `linear-gradient(135deg, ${colorService.rgbToRgba(color1, opacity)} 50%, ${colorService.rgbToRgba(
						color2,
						opacity
					)} 50%)`,
				};
			}

			return {
				'background-color': colorService.rgbToRgba(color, opacity),
				border: `1px solid ${color}`,
			};
		}

		showChartLegendFilterDropDown(event) {
			event.stopPropagation();

			if (!this.isChartLegendFilterDropDownVisible) {
				this.isChartLegendFilterDropDownVisible = true;

				setTimeout(() => {
					window.addEventListener('click', this.hideChartLegendFilterDropDownBound);
				}, 0);
				this.hideTimelineLegendFilterDropDown();
			} else {
				this.isChartLegendFilterDropDownVisible = false;
			}
		}

		hideChartLegendFilterDropDown() {
			const {$timeout} = services.get(this);

			window.removeEventListener('click', this.hideChartLegendFilterDropDownBound);
			$timeout(() => {
				this.isChartLegendFilterDropDownVisible = false;
			});
		}

		showTimelineLegendFilterDropDown(event) {
			event.stopPropagation();
			if (event.target.parentNode && !this.isChartLegendExpanded) {
				const elementRect = event.target.getBoundingClientRect();
				const scrollContainer = getParentContainerByClassName(event.target, 'scroll-container');
				this.legendFilterDropDownYOffset = elementRect.top + 46 - scrollContainer.top + 'px';
			}

			if (!this.isTimelineLegendFilterDropDownVisible) {
				this.isTimelineLegendFilterDropDownVisible = true;

				setTimeout(() => {
					window.addEventListener('click', this.hideTimelineLegendFilterDropDownBound);
				}, 0);
				this.hideChartLegendFilterDropDown();
			} else {
				this.isTimelineLegendFilterDropDownVisible = false;
			}
		}

		hideTimelineLegendFilterDropDown() {
			const {$timeout} = services.get(this);

			window.removeEventListener('click', this.hideTimelineLegendFilterDropDownBound);
			$timeout(() => {
				this.isTimelineLegendFilterDropDownVisible = false;
			});
		}

		sortChartLegendHandler(params) {
			if (params) {
				this.legendSettings.isChartLegendHasAscOrder = params.isAscOrder;
			} else {
				this.legendSettings.isChartLegendHasAscOrder = !this.legendSettings.isChartLegendHasAscOrder;
			}
			this.sortChartLegend();
		}

		toggleDisabledHandler(e) {
			if (this.legendSettings.chartLegendToggleState === 'disabled') {
				return false;
			}

			let isCurrentHideState = this.legendSettings.chartLegendToggleState === 'hide';

			this.lines.forEach(line => {
				if (!line.visible) {
					line.isDisabledByLegendFilter = isCurrentHideState;
				}
			});

			if (this.thresholds && typeof this.thresholds.forEach === 'function') {
				this.thresholds.forEach(thresholds => {
					if (!thresholds.visible) {
						thresholds.isDisabledByLegendFilter = isCurrentHideState;
					}
				});
			}

			this.legendSettings.chartLegendToggleState = isCurrentHideState ? 'show' : 'hide';
			this.toggleDisabled();
		}

		updateChartLegendToggleState() {
			if (this.checkAllLinesVisiblity()) {
				this.legendSettings.chartLegendToggleState = 'disabled';
			} else if (this.legendSettings.chartLegendToggleState === 'disabled') {
				this.legendSettings.chartLegendToggleState = 'hide';
			}
		}

		toggleTimelineHandler(lane) {
			lane.touched = true;
			lane.visible = !lane.visible;

			if (lane.visible) {
				this.isTimelineVisible = true;
			} else if (!this.lanes.find(item => item.visible)) {
				this.isTimelineVisible = false;
			}

			this.toggleTimeline({legend: lane});
		}

		switchTimelineVisibilityHandler(lane) {
			this.isTimelineVisible = !this.isTimelineVisible;

			if (!this.isTimelineVisible) {
				this.lanesPreviousState = this.lanes.map(lane => {
					const previousState = {
						propertyName: lane.propertyName,
						visible: lane.visible,
					};

					lane.touched = true;
					lane.visible = false;

					return previousState;
				});
			} else {
				this.lanesPreviousState &&
					this.lanesPreviousState.forEach(laneState => {
						this.lanes.forEach(lane => {
							if (lane.propertyName === laneState.propertyName) {
								lane.touched = true;
								lane.visible = laneState.visible;
							}
						});
					});
			}

			this.trackEvent(EQUIPMENT_PERFORMANCE_CHARTS_EVENTS.HIDE_SHOW_TIMELINE, {
				[PRIMARY_OFFERING]: PRIMARY_OFFERINGS.IS,
				[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_NAME]: this.selectedChart.title,
			});

			this.toggleTimeline();
		}

		openFilterByComponentSetupHandler(event) {
			this.openFilterByComponentSetup({event});
		}
		setSortByHandler(sort) {
			this.setSortBy({sort});
		}

		toggleSuppressionsHandler(e) {
			if (this.isSuppressionsAvailable) {
				let isCurrentHideState = this.isSuppressionsEnabled;

				this.lanes.filter(lane => lane.isSuppression).forEach(lane => {
					lane.isDisabledByLegendFilter = isCurrentHideState;
					lane.touched = true;
				});

				this.isSuppressionsEnabled = !isCurrentHideState;

				this.trackEvent(EQUIPMENT_PERFORMANCE_CHARTS_EVENTS.SHOW_HIDE_SUPPRESSIONS, {
					[PRIMARY_OFFERING]: PRIMARY_OFFERINGS.IS,
					[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_NAME]: this.selectedChart.title,
				});

				this.toggleTimeline();
			}
		}

		formatChartHandler() {
			if (this.lines && this.lines.length && [this.CHART_TYPE.LINE, this.CHART_TYPE.SCATTER_WITH_PERFORMANCE_CURVE].indexOf(this.chartType) !== -1) {
				this.formatChart();
			}
		}

		getCorrectUomSymbol(uomSymbol) {
			// "No Unit of Measure" symbol by default is '0'.
			// It's not understandable. And it was suggested to replace it by nothing.
			const uomSymbolToBeReplaced = '0';
			return uomSymbol !== uomSymbolToBeReplaced ? uomSymbol : '';
		}

		toggleLegendCollapse() {
			const {$timeout} = services.get(this);

			this.isChartLegendExpanded = !this.isChartLegendExpanded;
			$timeout(() => {
				this.eventObject && this.eventObject.emit('chartResize');
			}, 610);

			this.trackEvent(EQUIPMENT_PERFORMANCE_CHARTS_EVENTS.CLOSE_OPEN_CHART_LEGEND, {
				[PRIMARY_OFFERING]: PRIMARY_OFFERINGS.IS,
				[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_NAME]: this.selectedChart.title,
			});
		}
	}

	angular.module('TISCC').component('chartLegend', {
		templateUrl: 'components/chart-legend/chart-legend.html',
		controller: ChartLegendController,
		bindings: {
			yAxis: '<',
			lines: '<',
			thresholds: '<',
			controlRanges: '<',
			lanes: '<',
			unoccupied: '<',
			sortBy: '<',
			sortModes: '<',
			chartType: '<',
			isStackedBarWithOccupancy: '<',
			isLoadValvePositionChart: '<',
			isAirValvePositionChart: '<',
			isChartWithAddPropsSupport: '<',
			isCustomPropertiesAdded: '<',
			isFacilityChart: '<',
			isSuppressionsAvailable: '<',
			isOneYAxis: '<',
			linesVisible: '<',
			toggleLinesCheckbox: '<',
			viewTimeline: '<',
			childComponentsListLength: '<',
			legendSettings: '<',
			selectedChart: '<',
			isSuppressionsEnabled: '=',
			isChartLegendExpanded: '=',
			eventObject: '<',
			editProperties: '&',
			removeAllAddedProperties: '&',
			formatChart: '&',
			toggleDisabled: '&',
			toggleLine: '&',
			toggleLines: '&',
			toggleUnoccupied: '&',
			openFilterByComponentSetup: '&',
			sortChartLegend: '&',
			toggleTimeline: '&',
			setSortBy: '&',
			fitDataIntoWindow: '&',
			checkIsAllLinesHidden: '&',
		},
	});
})();
