(function() {
	const services = new WeakMap();

	function* generateIndex() {
		for (let i = 0; i <= 200; i++) {
			yield i;
		}
	}

	const indexGenerator = generateIndex();

	const barsGenerator = function*(minValue, maxValue, step, colorsArray) {
		const colors = [...colorsArray];
		for (let i = minValue + step; i <= maxValue; i = i + step) {
			yield {y: i, color: colors.shift()};
		}
	};

	class CprColorBarComponentController {
		constructor($element, helpers) {
			services.set(this, {
				helpers,
			});

			this.chartIndex = indexGenerator.next().value;

			this.$onInit = () => {
				if (!this.sectionData) {
					this.sectionData = {};
				}
				this.drawBar($element);
			};
		}

		drawBar($element) {
			const {helpers} = services.get(this);
			const {marksCount, minValue, stepPct} = this.sectionData;
			const maxValue = this.maxValue || this.sectionData.maxValue;
			const step = maxValue / 100 * stepPct;

			const svgWidth = 90;
			const svgHeight = 135;

			const margin = {
				top: 0,
				right: 20,
				bottom: 0,
				left: 15,
			};
			const grid = {
				width: svgWidth - margin.left - margin.right,
				height: svgHeight - margin.top - margin.bottom,
			};

			const x = d3.time.scale().range([0, grid.width]);
			const y = d3.scale.linear().range([grid.height, 0]);

			const svg = d3.select($element[0]).select('svg');

			svg.attr('viewBox', `0 0 ${svgWidth} ${svgHeight}`).attr('preserveAspectRatio', 'none');
			svg
				.append('g')
				.attr('transform', `translate(${margin.left},${margin.top})`)
				.append('clipPath')
				.attr('id', `clipcolorbar${this.chartIndex}`)
				.append('rect')
				.attr('width', grid.width)
				.attr('height', grid.height);

			const barsPlace = svg
				.append('g')
				.attr('class', 'back')
				.attr('clip-path', `url(#clipcolorbar${this.chartIndex})`)
				.attr('transform', `translate(${margin.left},${margin.top})`)
				.append('g')
				.attr('class', 'area');

			if (helpers.isNumber(minValue) && helpers.isNumber(maxValue) && helpers.isNumber(step)) {
				y.domain([minValue, maxValue]);
				x.domain([0, 1]);

				const yAxis = d3.svg
					.axis()
					.scale(y)
					.ticks(marksCount)
					.tickFormat(d => d)
					.tickSize(0)
					.orient('right');

				let translateX = grid.width + margin.left;
				let translateY = margin.top;

				const yAxisLReady = svg
					.append('g')
					.attr('class', 'y axis')
					.attr('transform', `translate(${translateX},${translateY})`)
					.call(yAxis);

				const barsData = [];
				const colors = ['#3e5a48', '#387a50', '#588c6a', '#82c29a', '#aedab6', '#f8991d', '#f26722', '#ed512d'];

				for (let value of barsGenerator(minValue, maxValue, step, colors)) {
					barsData.push(value);
				}
				barsPlace
					.selectAll('rect')
					.data(barsData)
					.enter()
					.append('rect')
					.attr('width', x(1))
					.attr('height', d => {
						return y(0) - y(step);
					})
					.attr('y', d => {
						return y(d.y);
					})
					.attr('x', 0)
					.style('fill', d => d.color)
					.style('stroke-width', 0);

				if (this.currentValue) {
					const currentValuePosX = x(1);
					const minCurrentValuePosY = Math.min(y(this.currentValue), grid.height - 8);

					barsPlace
						.append('polygon')
						.attr(
							'points',
							`${currentValuePosX - 8},
							${minCurrentValuePosY} ${currentValuePosX},
							${minCurrentValuePosY + 6} ${currentValuePosX},
							${minCurrentValuePosY - 6}`
						)
						.attr('fill', 'white');

					barsPlace
						.append('g')
						.attr('transform', `translate(${grid.width - 11},0)`)
						.attr('class', 'age-value')
						.append('text')
						.attr('x', 0)
						.attr('y', minCurrentValuePosY)
						.attr('dy', '0.3em')
						.attr('text-anchor', 'end')
						.text(this.currentValue < 1 ? '< 1' : Math.floor(this.currentValue));
				}
				if (this.sectionDescriptionVertical) {
					svg
						.append('g')
						.attr('class', 'description')
						.append('text')
						.text(this.sectionDescriptionVertical);
					const label = svg.select('.description text').node();
					const {width, height} = label.getBoundingClientRect();
					label.setAttribute('transform', `translate(${height - 4}, ${width - 20}) rotate(-90)`);
				}
			}
		}
	}

	angular.module('TISCC').component('cprColorBarComponent', {
		templateUrl: 'components/reports/chiller-performance/page/report-section/color-bar/cpr-color-bar-component.html',
		controller: CprColorBarComponentController,
		bindings: {
			sectionHeader: '<',
			sectionDescription: '<',
			sectionDescriptionVertical: '<',
			sectionData: '<',
			maxValue: '<',
			currentValue: '<',
		},
	});
})();
