import lzString from 'lz-string';

angular.module('TISCC').controller('PrintExportDialogCtrl', PrintExportDialogCtrl);

function PrintExportDialogCtrl(
	$scope,
	$rootScope,
	$modalInstance,
	getData,
	getCsvXlsxData,
	$location,
	authorization,
	helpers,
	exportService,
	exportProgressService,
	ExportDataGenerationService,
	urlService,
	charts,
	onActionHandler,
	onCopyLinkHandler,
	CHART_DATE_FORMAT
) {
	$scope.exportPreferences = {
		SEPARATE_FILES: 'separateFiles',
		AS_SHOWN_ON_SCREEN: 'multiple',
	};
	$scope.exportOrientations = {
		PORTRAIT: 'portrait',
		LANDSCAPE: 'landscape',
	};
	$scope.option = {
		optionType: 'exportImage',
		chartType: 'png',
		dataType: 'xlsx',
		color: 'transparent',
		exportPreference: $scope.exportPreferences.SEPARATE_FILES,
		exportOrientation: $scope.exportOrientations.PORTRAIT,
	};
	$scope.chartsNumber = charts.length;
	$scope.copiedToClipboard = false;
	$scope.exportCompleted = false;
	$scope.isExportInProgress = false;

	$scope.$watch('option.color', function(value) {
		hideConfirmationText();

		if (value === 'transparent') $scope.option.chartType = 'png';
	});

	$scope.$watch('option.chartType', function() {
		hideConfirmationText();
	});

	$scope.$watch('option.dataType', function() {
		hideConfirmationText();
	});

	$scope.$watch('option.exportPreference', function() {
		hideConfirmationText();
	});

	$scope.changeOptions = function(optionType, color) {
		hideConfirmationText();

		if (color) {
			$scope.option.color = color;
		}

		if (optionType === 'copyLink') {
			const dataToEncode = getData();
			const compressedChartState = lzString.compressToEncodedURIComponent(ExportDataGenerationService.encodeChartState(dataToEncode));

			$scope.option.link = `${$location.$$absUrl}&chartState=${encodeURIComponent(compressedChartState)}`;
			onCopyLinkHandler($scope.option.link);
			helpers.copyTextToClipboard($scope.option.link);
			$scope.option.link = '';
			$scope.copiedToClipboard = true;
		} else {
			$scope.option.optionType = optionType;
		}
	};

	$modalInstance.result.catch(() => {
		// When user closes modal window via the 'Escape' button.
		cancelVisualExport();
	});

	$scope.cancel = function() {
		cancelVisualExport();
		$modalInstance.dismiss('cancel');
	};

	$scope.export = function() {
		if ($scope.isExportInProgress) return;

		hideConfirmationText();

		if ($scope.option.optionType === 'exportData') {
			sendRequestViaForm(prepareData());
			$scope.exportCompleted = true;
		} else {
			const data = prepareData();

			$scope.isExportInProgress = true;

			exportService
				.sendExportRequest(data)
				.then(({progressKey}) => {
					exportProgressService
						.open(progressKey, {'is-visual-export': true})
						.then(finalizeExport.bind(null, progressKey, data.filename), failExportWrapper.bind(null, data.format), showExportProgress);
				})
				.catch(() => {
					$scope.isExportInProgress = false;
				});
		}

		onActionHandler($scope.option);
	};

	$scope.print = function() {
		$scope.cancel();
		$rootScope.backgroundColor = $scope.option.color;
		charts.forEach(chart => chart.eventObject.emit('chartResizeImmediately', {isBeforePrint: true, isMultipleCharts: charts.length > 1}));
		onActionHandler($scope.option);
		window.setTimeout(() => {
			window.print();
		}, 500);
	};

	function finalizeExport(progressKey, filename, input = {data: {}}) {
		if (input.data.readyToDownload) {
			startDownloadProcess({progressKey, filename});
		}
	}

	function failExportWrapper(format, error) {
		startDownloadProcess({format, isDownloadFailureFile: true});
	}

	function showExportProgress(input = {}) {
		// This function is empty now. Maybe in the future the loader popup will be added.
	}

	function startDownloadProcess({progressKey, filename, format, isDownloadFailureFile = false}) {
		const exportData = {
			progressKey,
			filename,
			format,
			isDownloadFailureFile,
			downloadVisualData: !isDownloadFailureFile,
		};

		sendRequestViaForm(exportData);
		hideConfirmationText();

		$scope.isExportInProgress = false;
		$scope.exportCompleted = true;
	}

	function hideConfirmationText() {
		$scope.copiedToClipboard = false;
		$scope.exportCompleted = false;
	}

	function prepareData() {
		let data = null;
		const useDataExport = $scope.option.optionType === 'exportData';

		if (useDataExport) {
			data = getCsvXlsxData();
			data.filename += '.' + $scope.option.dataType;
		} else {
			const needArchiveFiles =
				$scope.chartsNumber > 1 &&
				$scope.option.optionType === 'exportImage' &&
				$scope.option.exportPreference === $scope.exportPreferences.SEPARATE_FILES;

			data = getData();

			data.format = $scope.option.optionType === 'exportImage' ? $scope.option.chartType : 'pdf';
			data.visualExport = true;
			data.backgroundColor = $scope.option.color;
			data.needArchiveFiles = needArchiveFiles;
			data.exportOrientation = $scope.option.exportOrientation;

			const getParametersToUrl = (chartState, backgroundColor) => {
				const compressedChartState = lzString.compressToEncodedURIComponent(ExportDataGenerationService.encodeChartState(chartState));

				let parameters = `&chartState=${encodeURIComponent(compressedChartState)}`;
				parameters += '&isExport=1';
				parameters += `&backgroundColor=${backgroundColor || chartState.backgroundColor}`;

				return parameters;
			};

			if (needArchiveFiles) {
				const archiveFormat = 'zip';

				data.filename += archiveFormat;
				data.urls = generateSeparateUrls(data, getParametersToUrl);
				data.fileNames = generateUniqueFileNames(data);
			} else {
				const absUrl = urlService.updateHttpsToHttp($location.absUrl());
				data.filename += data.format;
				data.url = $scope.isFacilityPerformanceChart
					? `${absUrl.split('#')[0]}#${$scope.facilityPerformanceChartPath}${getParametersToUrl(data)}`
					: `${absUrl}${getParametersToUrl(data)}`;
			}
		}
		return data;
	}

	function getDateParamsForUrl(key, date, isEndDate) {
		if (!date) return '';

		return key + (isEndDate ? date.clone().add(-1, 'days') : date).format(CHART_DATE_FORMAT.RANGE_DATE_FORMAT);
	}

	function generateSeparateUrls({exportedDataArray, backgroundColor}, getParametersToUrl) {
		const absUrl = urlService.updateHttpsToHttp($location.absUrl());

		return exportedDataArray.map(chartData => {
			let url = '';
			const {charts: [{isFacilityChart, isParetoChart, equipmentType, serviceAdvisoryTypeId, isEquipmentLevel}]} = chartData;
			const urlBeginning = absUrl.split('#')[0];

			if (isFacilityChart) {
				const query = absUrl.split('?')[1];

				isParetoChart
					? (url += `${urlBeginning}#/facility/${chartData.locationId}/type/${equipmentType}/pareto/${serviceAdvisoryTypeId}?${query}`)
					: (url += `${urlBeginning}#/facility/${chartData.locationId}/type/facility/chart/${chartData.chartId}?${query}`);
			} else if (isEquipmentLevel) {
				const startDate = getDateParamsForUrl('&startDate=', chartData.range.from);
				const endDate = getDateParamsForUrl('&endDate=', chartData.range.to, true);

				url += `${urlBeginning}#/facility/${chartData.locationId}/equipment/${chartData.equipmentId}/chart/${chartData.chartId}?${startDate}${endDate}`;
			} else {
				url = absUrl;
			}

			return url + getParametersToUrl({exportedDataArray: [chartData]}, backgroundColor);
		});
	}

	function generateUniqueFileNames({exportedDataArray}) {
		const maxChartsNumber = 10;
		const counters = Array.from(new Array(maxChartsNumber), (val, index) => index + 1);
		const uniqueFileNames = [];

		exportedDataArray
			.map(({charts: [chart], visualExportEndDate}) => {
				const {location: {name: locationName}, equipmentName, chartName, selectedFacilities = []} = chart;
				const date = visualExportEndDate.format('YYYY-MM-DD');

				return [
					selectedFacilities.length > 1 ? 'Multiple Facilities' : selectedFacilities[0].locationName || locationName,
					date,
					equipmentName,
					chartName,
				]
					.filter(item => item)
					.join('_');
			})
			.forEach(fileName => {
				fileName = helpers.sanitizeFilename(fileName);

				if (uniqueFileNames.includes(fileName)) {
					const counter = counters.find(counter => {
						return !uniqueFileNames.includes(`${fileName}_${counter}`);
					});

					fileName = `${fileName}_${counter}`;
				}

				uniqueFileNames.push(fileName);
			});

		return uniqueFileNames;
	}

	function sendRequestViaForm(data) {
		angular.element(document.getElementById('export-data')).val(JSON.stringify(data));
		document.getElementById('export-form').submit();
	}

	function cancelVisualExport() {
		if (['exportImage', 'exportPDF'].includes($scope.option.optionType) && $scope.isExportInProgress && !$scope.isVisualExportCanceled) {
			$scope.isVisualExportCanceled = true;
			exportProgressService.cancel({'is-visual-export': true});
		}
	}
}
