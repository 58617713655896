/* global jsonpipe:true*/
import _get from 'lodash.get';

(() => {
	const services = {};
	let _token;
	let _progressKey;
	let _currentRequest;
	let _retryAttemptsLeft;
	let _deferred;

	const MAX_CONNECTION_RESTORE_ENTRIES = 30;
	const TIS_EXPORT_ERROR_MESSAGE = 'Error fetching data from API';
	const TIS_VISUAL_EXPORT_ERROR_MESSAGE = 'Error visual export';
	const XLSX_GENERATION_ENDED = 'XLSX generation is done!';
	const XLSX_GENERATION_PROGRESS = 'XLSX generation in progress';
	const VISUAL_EXPORT_GENERATION_ENDED = 'Export process has finished';
	const VISUAL_EXPORT_GENERATION_PROGRESS = 'Export process is in progress';
	const EXPORT_STARTED = 'Export process has started';
	const ERROR_MESSAGES = {
		'401': 'User authentication failure. Please logout and retry.',
		EXCEEDED_RETRY: 'Job duration limit exceeded. Please retry.',
		FAILED: 'Job execution failure. Please retry',
	};

	class ProgressFlowRequest {
		constructor(deferred, started, headers = {}) {
			this.deferred = deferred;
			this.started = started;
			this.request = this.startFlow(headers);
		}

		onChunk(chunk) {
			let result = {};
			if (chunk.error || (chunk.data && chunk.data.error)) {
				const errorMessage = _get(chunk, 'data.error.message', '');

				if (errorMessage.includes('reached retry status limit')) {
					this.onError({
						errorCode: 'EXCEEDED_RETRY',
						error: ERROR_MESSAGES['EXCEEDED_RETRY'],
					});
				} else if (errorMessage.includes('FAILED')) {
					this.onError({
						errorCode: 'FAILED',
						error: ERROR_MESSAGES['FAILED'],
					});
				} else {
					const error = {
						errorCode: chunk.data && chunk.data.errorCode ? chunk.data.errorCode : null,
						error:
							chunk.data && chunk.data.errorCode && ERROR_MESSAGES[chunk.data.errorCode]
								? ERROR_MESSAGES[chunk.data.errorCode]
								: TIS_EXPORT_ERROR_MESSAGE,
					};
					this.onError(error);
				}
			}
			if (chunk.data) {
				result.data = chunk.data;
			}
			if (chunk.message) {
				result.message = chunk.message;
			}
			if (result.data && result.data.readyToDownload) {
				this.ready = true;
			}
			if (
				(result.message === EXPORT_STARTED || result.message === XLSX_GENERATION_PROGRESS || result.message === VISUAL_EXPORT_GENERATION_PROGRESS) &&
				!this.started
			) {
				this.started = true;
				this.ready = false;
			}
			if (result.message === TIS_EXPORT_ERROR_MESSAGE || result.message === TIS_VISUAL_EXPORT_ERROR_MESSAGE) {
				this.started = false;
				this.errorOnBackend(result.message);
			} else if (result.message === XLSX_GENERATION_ENDED || result.message === VISUAL_EXPORT_GENERATION_ENDED) {
				this.started = false;
				this.deferred.resolve(result);
			} else {
				this.deferred.notify(result);
			}
		}

		onError(errorMsg) {
			if (this.aborted) return;

			if ((errorMsg && errorMsg.errorCode) || _retryAttemptsLeft < 0 || !this.restartFlow()) {
				this.deferred.reject(errorMsg.error);
				this.started = false;
			}
		}

		onComplete(data) {
			if (_retryAttemptsLeft < 0 || !this.started || this.ready || !this.restartFlow()) {
				if (this.started && !this.ready) {
					this.deferred.reject();
					this.started = false;
				}
				if (this.ready) {
					_retryAttemptsLeft = MAX_CONNECTION_RESTORE_ENTRIES * 2;
				}
			}
		}

		errorOnBackend(message) {
			this.deferred.reject(message);
		}

		startFlow(additionalHeaders = {}) {
			const {exportService, API_ENDPOINTS} = services;

			exportService.setBalancerCookie(_progressKey);

			const requestData = {
				delimiter: '\r\n', // String. The delimiter separating valid JSON objects; default is '\n\n'
				success: this.onChunk.bind(this),
				error: this.onError.bind(this),
				complete: this.onComplete.bind(this),
				timeout: 30000000,
				method: 'GET',
				headers: {
					Authorization: `token ${_token}`,
					ProgressKey: _progressKey,
				},
				withCredentials: true,
			};

			Object.assign(requestData.headers, additionalHeaders);

			return jsonpipe.flow(API_ENDPOINTS.tisExport, requestData);
		}

		restartFlow() {
			_retryAttemptsLeft--;
			if (this.request && !this.request.aborted) {
				this.request.abort();
			}
			let newRequest = new ProgressFlowRequest(this.deferred, this.started);
			this.started = false;

			if (newRequest) {
				_currentRequest = newRequest;
				return true;
			} else {
				return false;
			}
		}

		abort() {
			this.started = false;
			this.aborted = true;

			// Add setTimeout() as there was problem with Chrome - it crashes without setTimeout()
			setTimeout(() => {
				if (this.request && this.request instanceof XMLHttpRequest && this.request.abort && this.request.abort instanceof Function) {
					this.request.abort();
				}
			}, 3000);
		}
	}

	class ExportProgressService {
		constructor($http, $q, helpers, $injector, exportService, API_ENDPOINTS) {
			Object.assign(services, {
				$http,
				$q,
				helpers,
				exportService,
				API_ENDPOINTS,
				authorization: $injector.get('authorization'),
			});

			_token = null;
			_progressKey = null;
			_currentRequest = null;
			_retryAttemptsLeft = 0;
		}

		getProgressKey() {
			return _progressKey;
		}

		cancel(headers = {}) {
			if (_currentRequest) {
				_currentRequest.abort();
			}
			this.sendAbortRequest(headers);
		}

		sendAbortRequest(headers = {}) {
			const {$http, API_ENDPOINTS} = services;

			const abortParams = {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				ProgressKey: _progressKey,
				AbortExportProgress: true,
			};

			if (_currentRequest) {
				_currentRequest.started = false;
			}
			return $http.delete(API_ENDPOINTS.tisExport, {
				headers: Object.assign({}, abortParams, headers),
				withCredentials: true,
			});
		}

		open(key, headers = {}) {
			const {authorization, $q} = services;

			_retryAttemptsLeft = MAX_CONNECTION_RESTORE_ENTRIES;
			_token = authorization.getToken();
			_progressKey = key;
			_deferred = $q.defer();
			_currentRequest = new ProgressFlowRequest(_deferred, false, headers);
			return _deferred.promise;
		}
	}

	angular.module('TISCC').service('exportProgressService', ExportProgressService);
})();
