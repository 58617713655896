/* eslint semi: [0, 'always'] */
/* eslint one-var: 0 */
/* eslint no-bitwise: 0 */

let Module = angular.module('datePicker', []);

Module.constant('datePickerConfig', {
	template: 'common/calendar/angular-datepicker.html',
	view: 'date',
	views: ['year', 'month', 'date', 'hours', 'minutes'],
	step: 5,
});

Module.filter('time', function() {
	function format(date) {
		return ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
	}

	return function(date) {
		if (!(date instanceof Date)) {
			date = new Date(date);
			if (isNaN(date.getTime())) {
				return undefined;
			}
		}
		return format(date);
	};
});

function getVisibleMinutes(date, step) {
	let minutes = [],
		stop;
	date = date && date._isAMomentObject ? date : moment().startOf('hour');
	minutes.push(date);
	stop = moment(date).add(1, 'hour');
	while (date.isBefore(stop)) {
		date = moment(date).add(1, 'minute');
		minutes.push(date);
	}
	return minutes;
}

function getVisibleWeeks(date) {
	let weeks = [],
		curdate,
		shift;
	curdate = date && date._isAMomentObject ? moment(date) : moment();

	curdate = curdate.startOf('month');
	curdate = curdate.startOf('week');

	while (weeks.length < 6) {
		let week = [];
		for (let i = 0; i < 7; i++) {
			week.push(moment(curdate));
			curdate.add(1, 'days');
		}
		weeks.push(week);
	}

	return weeks;
}

function getVisibleYears(date) {
	let years = [],
		curdate;
	curdate = date && date._isAMomentObject ? moment(date) : moment();
	curdate.startOf('year');
	curdate.year(curdate.year() - curdate.year() % 20);
	years.push(moment(curdate));
	for (let i = 1; i < 20; i++) {
		years.push(moment(curdate.add(1, 'years')));
	}
	return years;
}

function getDaysOfWeek(date, shift) {
	let days = [],
		curdate;
	curdate = date && date._isAMomentObject ? moment(date) : moment();
	curdate.startOf('week');
	days.push(moment(curdate));
	for (let i = 0; i < 6; i++) {
		days.push(moment(curdate.add(1, 'days')));
	}
	return days;
}

function getVisibleMonths(date) {
	const months = [];
	let curDate = date && date._isAMomentObject ? date : moment();
	curDate = moment(curDate.startOf('year')).subtract(1, 'months');
	for (let i = 0; i < 12; i++) {
		months.push(moment(curDate.add(1, 'months')));
	}
	return months;
}

function getVisibleHours(date) {
	let hours = [];
	date = date && date._isAMomentObject ? date : moment().startOf('day');
	hours.push(date);
	for (let i = 0; i < 24; i++) {
		hours.push(moment(date.add(1, 'hours')));
	}
	return hours;
}

function isAfter(model, date) {
	return model && date.isAfter(model, 'day');
}

function isBefore(model, date) {
	return model && date.isBefore(model, 'day');
}

function isSameYear(model, date) {
	return model && model.year() === date.year();
}

function isSameMonth(model, date) {
	return model && isSameYear(model, date) && model.month() === date.month();
}

function isSameDay(model, date) {
	return model && isSameYear(model, date) && isSameMonth(model, date) && model.date() === date.date();
}

function isSameHour(model, date) {
	return model && model.isSame(date, 'hour');
}

function isSameMinutes(model, date) {
	return model && model.isSame(date, 'minute');
}

Module.directive('datePicker', [
	'datePickerConfig',
	function datePickerDirective(datePickerConfig) {
		// noinspection JSUnusedLocalSymbols
		return {
			// this is a bug ?
			template: '<div ng-include="template"></div>',
			scope: {
				model: '=datePicker',
				after: '=?',
				before: '=?',
			},
			link: function(scope, element, attrs) {
				scope.date = scope.model ? moment(scope.model) : moment();
				scope.views = datePickerConfig.views.concat();
				scope.view = attrs.view || datePickerConfig.view;
				scope.now = moment();
				scope.template = attrs.template || datePickerConfig.template;

				let step = parseInt(attrs.step || datePickerConfig.step, 10);
				let partial = !!attrs.partial;

				function updateVariables() {
					step = parseInt(attrs.step || datePickerConfig.step, 10);
					partial = !!attrs.partial;
					if (attrs.view) {
						scope.view = attrs.view;
						attrs.view = '';
					}
					updateViews();
				}

				function updateViews() {
					/** @namespace attrs.minView, attrs.maxView */
					scope.views = scope.views.slice(scope.views.indexOf(attrs.maxView || 'year'), scope.views.indexOf(attrs.minView || 'minutes') + 1);

					if (scope.views.length === 1 || scope.views.indexOf(scope.view) === -1) {
						scope.view = scope.views[0];
					}
				}

				scope.setView = function(nextView) {
					if (scope.views.indexOf(nextView) !== -1) {
						scope.view = nextView;
					}
				};

				scope.setDate = function(date) {
					// change next view
					let nextView = scope.views[scope.views.indexOf(scope.view) + 1];

					if (attrs.disabled || ((scope.isBefore(date) || scope.isAfter(date)) && !nextView)) {
						return;
					}
					scope.date = moment(date);
					if (!nextView || partial) {
						scope.model = moment(date);
						scope.$emit('setDate', scope.model, scope.view);
					}

					if (nextView) {
						scope.setView(nextView);
					}
				};

				function update() {
					let view = scope.view;
					let date = scope.date;
					switch (view) {
						case 'year':
							scope.years = getVisibleYears(date);
							break;
						case 'month':
							scope.months = getVisibleMonths(date);
							break;
						case 'date':
							scope.weekdays = scope.weekdays || getDaysOfWeek(null, parseInt(attrs.weekstart, 10));
							scope.weeks = getVisibleWeeks(date);
							break;
						case 'hours':
							scope.hours = getVisibleHours(date);
							break;
						case 'minutes':
							scope.minutes = getVisibleMinutes(date, step);
							break;
					}
				}

				function watch() {
					if (scope.view !== 'date') {
						return scope.view;
					}
					return scope.model ? scope.model.month() : null;
				}

				function updateAndSwitch() {
					updateVariables();
					scope.date = moment(scope.model);
					update();
				}

				scope.$watch('model', updateAndSwitch);
				scope.$watch(watch, update);
				scope.$watch(
					function() {
						return attrs.weekstart;
					},
					function() {
						scope.weekdays = null;
						update();
					}
				);

				scope.next = function(delta) {
					let date = scope.date;
					delta = delta || 1;
					switch (scope.view) {
						case 'year':
						/* falls through*/
						case 'month':
							date.add(delta, 'years');
							break;
						case 'date':
							date.add(delta, 'months');
							break;
						case 'hours':
						/* falls through*/
						case 'minutes':
							date.add(delta, 'hours');
							break;
					}
					update();
				};

				scope.prev = function(delta) {
					return scope.next(-delta || -1);
				};

				scope.isAfter = function(date) {
					return scope.after && !scope.isNow(date, scope.after) && isAfter(date, scope.after);
				};

				scope.isBefore = function(date) {
					return scope.before && isBefore(date, scope.before);
				};

				scope.isSameMonth = function(date) {
					return isSameMonth(scope.model, date);
				};

				scope.isSameYear = function(date) {
					return isSameYear(scope.model, date);
				};

				scope.isSameDay = function(date) {
					return isSameDay(scope.model, date);
				};

				scope.isSameHour = function(date) {
					return isSameHour(scope.model, date);
				};

				scope.isSameMinutes = function(date) {
					return isSameMinutes(scope.model, date);
				};

				scope.isNow = function(date, nowDate) {
					let is = true,
						now = nowDate || scope.now;
					// noinspection FallThroughInSwitchStatementJS
					switch (scope.view) {
						case 'minutes':
							is &= date.isSame(now, 'minute');
						/* falls through*/
						case 'hours':
							is &= date.isSame(now, 'hour');
						/* falls through*/
						case 'date':
							is &= date.isSame(now, 'day');
						/* falls through*/
						case 'month':
							is &= date.isSame(now, 'month');
						/* falls through*/
						case 'year':
							is &= date.isSame(now, 'year');
					}
					return is;
				};
			},
		};
	},
]);
