import {REPORT_LANGUAGE_LIST, DEFAULT_REPORT_LANGUAGE, SYSTEM_OF_MEASURE_LIST, DEFAULT_SYSTEM_OF_MEASURE} from './digital-inspection-report-service';
import {getReportNameByKey, getReportGenerationStatus} from '../../../common/usage-tracking/categories/report-generation/utils.js';
import {USER_EVENTS} from '../../../common/usage-tracking/categories';

const {
	REPORT_GENERATION: {categoryName: REPORT_GENERATION_CATEGORY_NAME, events: REPORT_GENERATION_EVENTS, properties: REPORT_GENERATION_PROPERTIES},
} = USER_EVENTS;

angular.module('TISCC').controller('DigitalInspectionReportCtrl', DigitalInspectionReportCtrl);

function DigitalInspectionReportCtrl(
	$scope,
	$controller,
	$routeParams,
	$location,
	$filter,
	$q,
	$log,
	locationDetailsService,
	REPORT_TYPES,
	exportService,
	exportProgressService,
	CHART_DATE_FORMAT,
	locationEquipmentService,
	MetricsFactory,
	GA_ACTION_NAMES,
	urlService,
	googleAnalyticsService,
	usageTrackingService
) {
	angular.extend(this, $controller('AbstractReportCtrl', {$scope, $log}));
	const FILE_FORMAT = 'pdf';
	let reportGenerationStarted = null;
	let reportGenerationStartTime = null;
	let defaultExportProgressMessages = {
		message: $filter('translate')('LOADING_REPORT'),
		progressPercentage: 0,
		loading: false,
	};
	$scope.updateLeftCheckboxes = updateLeftCheckboxes;
	$scope.isEquipmentSelected = isEquipmentSelected;
	$scope.activeLanguage = DEFAULT_REPORT_LANGUAGE;
	$scope.reportLanguageList = REPORT_LANGUAGE_LIST;
	$scope.reportSystemOfMesureList = SYSTEM_OF_MEASURE_LIST;
	$scope.activeSystemOfMesure = DEFAULT_SYSTEM_OF_MEASURE;
	$scope.rangeMode = '2D-1Y';
	$scope.exportProgressMessages = angular.copy(defaultExportProgressMessages);
	$scope.resetProgressRequest = resetProgressRequest;
	$scope.triggerEmail = triggerEmail;
	$scope.reportCompletion = reportCompletion;
	$scope.emailRequested = false;
	$scope.viewModel = {
		selectedSystemName: 'Chiller',
		checkAllRight: true,
		isLoadingStarted: false,
	};
	if ($routeParams && $routeParams.startDate) {
		$scope.range.from = moment.tz($routeParams.startDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, locationDetailsService.getLocationTimezone());
		$scope.rangeStart = moment.tz($routeParams.startDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, locationDetailsService.getLocationTimezone());
		$scope.rangeEnd = moment.tz($routeParams.endDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, locationDetailsService.getLocationTimezone());
		$scope.range.to = moment.tz($routeParams.endDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, locationDetailsService.getLocationTimezone());
	}
	$scope.rightListLoadingPromise = null;
	initialize();
	function switchSystemOfMeasure(systemOfMesure) {
		$scope.activeSystemOfMesure = systemOfMesure;
	}
	$scope.$on('generateReport', generateReportHandler);
	$scope.switchSystemOfMeasure = switchSystemOfMeasure;
	$scope.cancelLoading = cancelLoading;

	$scope.$watchGroup([() => $scope.popupLeftListData && $scope.popupLeftListData.some(item => item.checked), () => $scope.isGeneratingReport], () => {
		const leftListData = $scope.popupLeftListData || [];
		const isLeftCheckedItemsPresent = leftListData.some(item => {
			googleAnalyticsService.setEquipmentId(item.tisObjectId);
			return item.checked;
		});

		$scope.setDisableGenerateButton($scope.isGeneratingReport || !isLeftCheckedItemsPresent);
	});
	function updateExportProgressMessages() {
		$scope.exportProgressMessages = {
			currentMessage: $filter('translate')('LOADING_REPORT'),
		};
	}
	function getSelectedEquipment() {
		return $scope.popupLeftListData.find(item => item.checked);
	}
	function updateLeftCheckboxes(item) {
		$scope.popupLeftListData.forEach(tisObject => {
			if (tisObject !== item) {
				tisObject.checked = false;
			}
		});
		hideConfirmationMessage();
	}
	function isEquipmentSelected() {
		const {popupLeftListData = []} = $scope;

		return popupLeftListData.find(item => item.checked);
	}
	function hideConfirmationMessage() {
		$scope.$emit('hideConfirmationMessage');
	}
	function initialize() {
		updateExportProgressMessages();

		$q
			.all([
				locationEquipmentService.getLocationObjectsList($scope.locationId, $scope.viewModel.selectedSystemName, false),
				locationEquipmentService.getLocationObjectsList($scope.locationId, null, true),
			])
			.then(data => {
				let [{tisObjectList: flatTisObjectList}, {tisObjectList}] = data;
				const {tisObjectId} = $scope.defaultEquipment || {};

				$scope.popupLeftListData = flatTisObjectList.map(tisObject => {
					return {
						value: tisObject.tisObjectName,
						objectId: tisObject.objectId,
						tisObjectId: tisObject.tisObjectId,
						tisObjectType: tisObject.tisObjectType,
						subTypeFilterKey: tisObject.subTypeFilterKey,
						id: tisObject.tisObjectType.tisObjectTypeGroupNumber,
						checked: tisObjectId === tisObject.tisObjectId,
						children: tisObject.children,
						isStandalone: locationEquipmentService.searchParentTisObject(tisObject, tisObjectList, null) === null,
					};
				});
			})
			.catch(() => {
				$scope.popupLeftListData = [];
			});
	}

	$scope.$watchGroup([() => $scope.popupLeftListData && $scope.popupLeftListData.some(item => item.checked), () => $scope.isGeneratingReport], () => {
		const leftListData = $scope.popupLeftListData || [];
		const isLeftCheckedItemsPresent = leftListData.some(item => {
			googleAnalyticsService.setEquipmentId(item.tisObjectId);
			return item.checked;
		});
		$scope.setDisableGenerateButton($scope.isGeneratingReport || !isLeftCheckedItemsPresent);
	});
	function generateReportHandler() {
		/* eslint camelcase: 0*/
		MetricsFactory.mark(MetricsFactory.METRIC_TYPES.DIR_LOAD, {
			range_type: $scope.rangeMode,
			range_duration: $scope.range.to - $scope.range.from,
			report_type: 'Chiller',
			objects_count: 1,
		});
		const data = prepareData();
		const {DIR: labels} = GA_ACTION_NAMES;
		reportGenerationStarted = new Date();
		hideConfirmationMessage();
		$scope.isGeneratingReport = true;
		$scope.viewModel.isLoadingStarted = true;

		$scope.trackGenerateReportEvent({
			[REPORT_GENERATION_PROPERTIES.REPORT]: getReportNameByKey('EQUIPMENT_MENU_DIGITAL_INSPECTION'),
			[REPORT_GENERATION_PROPERTIES.FROM_DATE]: $scope.range.from.clone().format('MM-DD-YYYY'),
			[REPORT_GENERATION_PROPERTIES.TO_DATE]: $scope.rangeEnd.clone().format('MM-DD-YYYY'),
			[REPORT_GENERATION_PROPERTIES.REPORT_GENERATION_STATUS]: getReportGenerationStatus('started'),
		});

		reportGenerationStartTime = performance.now();

		exportService
			.sendExportRequest(data)
			.then(({progressKey}) => {
				exportProgressService
					.open(progressKey, {'is-visual-export': true})
					.then(
						finalizeExport.bind(null, progressKey, data.filename),
						failExportWrapper.bind(null, data.format, null, progressKey),
						showExportProgress
					)
					.then(() => {
						MetricsFactory.measure(MetricsFactory.METRIC_TYPES.DIR_LOAD);
					});
			})
			.catch(() => {
				$scope.isGeneratingReport = false;
				$scope.viewModel.isLoadingStarted = false;
				$scope.$emit('hideLoader');
				reportGenerationStarted = null;
			});
	}
	$scope.$watch('rangeEnd', function() {
		if ($scope.rangeMode === '2D-1Y') {
			const newDate = moment($scope.rangeEnd);
			if (newDate.isSame($scope.maxDt)) {
				$scope.range.to = $scope.maxDt;
			} else {
				$scope.rangeEnd.add(1, 'days');
				$scope.range.to = $scope.rangeEnd;
			}
		}
	});
	function prepareData() {
		const selectedEquipment = getSelectedEquipment();
		const startDate = $scope.range.from.clone().format('MM-DD-YYYY');
		const endDate = $scope.rangeEnd.clone().format('MM-DD-YYYY');
		const data = {};
		data.visualExport = true;
		data.url = [
			urlService.updateHttpsToHttp($location.absUrl()).split('#')[0],
			`#/facility/${$scope.locationId}/equipment/${selectedEquipment.tisObjectId}/digital-inspection-report?`,
			`lang=${$scope.activeLanguage.value}`,
			`&system=${$scope.activeSystemOfMesure.value}`,
			`&startDate=${startDate}`,
			`&endDate=${endDate}`,
		].join('');
		$log.log('DIR url: ', data.url);
		data.filename = `${$scope.data.locationData.locationName}_${startDate}_${endDate}_${selectedEquipment.value}_Digital Inspection.${FILE_FORMAT}`;
		data.format = FILE_FORMAT;
		data.visualExportType = REPORT_TYPES.DIGITAL_INSPECTION.report;
		return data;
	}

	function finalizeExport(progressKey, filename, input = {data: {}}) {
		const {DIR: labels} = GA_ACTION_NAMES;
		if (input.data.readyToDownload) {
			startDownloadProcess({progressKey, filename});
			let timeTaken = performance.now() - reportGenerationStartTime;

			$scope.trackGenerateReportEvent({
				[REPORT_GENERATION_PROPERTIES.REPORT]: getReportNameByKey('EQUIPMENT_MENU_DIGITAL_INSPECTION'),
				[REPORT_GENERATION_PROPERTIES.FROM_DATE]: $scope.range.from.clone().format('MM-DD-YYYY'),
				[REPORT_GENERATION_PROPERTIES.TO_DATE]: $scope.rangeEnd.clone().format('MM-DD-YYYY'),
				[REPORT_GENERATION_PROPERTIES.REPORT_GENERATION_STATUS]: getReportGenerationStatus('success'),
				[REPORT_GENERATION_PROPERTIES.TOTAL_TIME]: timeTaken,
			});
		}
	}

	function startDownloadProcess({progressKey, filename, format, isDownloadFailureFile = false}) {
		const exportData = {
			progressKey,
			filename,
			format,
			isDownloadFailureFile,
			downloadVisualData: !isDownloadFailureFile,
		};

		sendRequestViaForm(exportData);

		$scope.isGeneratingReport = false;
		$scope.viewModel.isLoadingStarted = false;
		$scope.$emit('hideLoader');
		$scope.$emit('generateComplete');

		updateExportProgressMessages();
	}
	function sendRequestViaForm(data) {
		angular.element(document.getElementById('export-data')).val(JSON.stringify(data));
		document.getElementById('export-form').submit();
	}
	async function triggerEmail() {
		$scope.emailRequested = true;
		$scope.exportProgressMessages.loading = true;
		const response = await exportProgressService.sendEmailRequest({
			progressKey: $scope.progressKey,
		});
		$scope.reportCompletion();
	}

	function resetProgressRequest() {
		$scope.isGeneratingReport = false;
		$scope.progressKey = null;
		$scope.exportProgressMessages = angular.copy(defaultExportProgressMessages);
		$scope.emailRequested = false;
	}

	function reportCompletion() {
		$scope.resetProgressRequest();
		$scope.$emit('hideLoader');
		$scope.$emit('generateComplete');
		$scope.viewModel.isLoadingStarted = false;
	}

	function cancelLoading() {
		if ($scope.isGeneratingReport) {
			$scope.exportProgressMessages.loading = true;
			exportProgressService
				.sendAbortRequest($scope.progressKey)
				.then(() => {
					$scope.exportProgressMessages.loading = false;
					$scope.resetProgressRequest();
					$scope.$emit('hideLoader');
					$scope.viewModel.isLoadingStarted = false;
				})
				.catch(() => {
					$scope.reportCompletion();
				});
		}
	}

	function failExportWrapper(format, error, progressKey) {
		const {DIR: labels} = GA_ACTION_NAMES;
		startDownloadProcess({format, isDownloadFailureFile: true});
		reportGenerationStarted = null;

		let timeTaken = performance.now() - reportGenerationStartTime;
		$scope.trackGenerateReportEvent({
			[REPORT_GENERATION_PROPERTIES.REPORT]: getReportNameByKey('EQUIPMENT_MENU_DIGITAL_INSPECTION'),
			[REPORT_GENERATION_PROPERTIES.FROM_DATE]: $scope.range.from.clone().format('MM-DD-YYYY'),
			[REPORT_GENERATION_PROPERTIES.TO_DATE]: $scope.rangeEnd.clone().format('MM-DD-YYYY'),
			[REPORT_GENERATION_PROPERTIES.REPORT_GENERATION_STATUS]: getReportGenerationStatus('failed'),
			[REPORT_GENERATION_PROPERTIES.TOTAL_TIME]: timeTaken,
		});
	}

	function showExportProgress(input = {}) {
		if (input.message) {
			$scope.exportProgressMessages.currentMessage = input.message;
		}
	}
}
