/* eslint eqeqeq: [0, 'always'] */
/* eslint indent: [0, 'tab'] */
/* global EventEmitter*/
import {USER_EVENTS} from '../../common/usage-tracking/categories';
import {getViewNameByQueryParameter} from '../../common/usage-tracking/categories/home-page/utils';
import {getReportNameByKey, getReportGenerationStatus} from '../../common/usage-tracking/categories/report-generation/utils.js';
import {PRIMARY_OFFERING} from '../../common/usage-tracking/common/properties-names';
import {PRIMARY_OFFERINGS} from '../../common/usage-tracking/common/primary-offerings';

const {
	HOME_PAGE: {events: HOME_PAGE_EVENTS, properties: HOME_PAGE_PROPERTIES, categoryName: HOME_PAGE_CATEGORY_NAME},
	NAVIGATION: {events: NAVIGATION_EVENTS, categoryName: NAVIGATION_CATEGORY_NAME},
	REPORT_GENERATION: {events: REPORT_GENERATION_EVENTS, categoryName: REPORT_GENERATION_CATEGORY_NAME, properties: REPORT_GENERATION_PROPERTIES},
} = USER_EVENTS;

angular.module('TISCC').controller('LocationsCtrl', LocationsCtrl);

function LocationsCtrl(
	$scope,
	$rootScope,
	$filter,
	modalHelperService,
	$location,
	$timeout,
	$q,
	facilityListService,
	locationDetailsService,
	userDataService,
	uiStateService,
	utilityService,
	authorization,
	exportService,
	exportProgressService,
	helpers,
	LocationSettingsFctry,
	googleAnalyticsService,
	offeringService,
	MetricsFactory,
	OPERATOR,
	GEN3_URL,
	TC_URL,
	REPORT_TYPES,
	VIEW_FILTERS,
	configService,
	connectivityTypesService,
	usageTrackingService
) {
	const FACILITIES_CHUNK_SIZE = 56;
	const CONNECTIVITY_MODULE = 'CM';
	const externalLinks = configService.getExternalLinks();
	const tisOfferingsToHide = configService.getTisOfferingsConfig().tisOfferingsToHide;
	const trackEvent = usageTrackingService.trackEventByCategory(HOME_PAGE_CATEGORY_NAME);
	let facilitiesChunkSize = FACILITIES_CHUNK_SIZE;
	let facilitiesCache = [];
	let facilitiesViewCache = [];
	let allFacilitiesLoadedDefer = $q.defer();
	let reportGenerationStartTime = null;
	LocationSettingsFctry.cleanLocations();
	$scope.pageTitle = {
		title: 'Command Center',
		firstPage: true,
	};
	$scope.VIEW = {
		TILES: 'tiles',
		LIST: 'list',
	};

	$scope.navigation = null;
	$scope.progress = 100;
	$scope.currentShown = 0;
	$scope.userData = {};
	$scope.currentView = false;
	$scope.defaultView = false;
	$scope.isAllSelected = false;
	$scope.noFacilitySelected = true;
	$scope.currentViewData = false;
	$scope.relevantFacilities = [];
	$scope.facilities = [];
	// :todo Remove unused $scope.userService everywhere
	$scope.userService = userDataService;
	$scope.searchObj = {searchText: ''};
	$scope.uiStateKey = 'homeController.uiState';
	$scope.uiStateKeyView = $scope.uiStateKey + '.view'; // tiles or list
	$scope.currentViewFilter = VIEW_FILTERS.ACTIVE_FILTER;
	$scope.loading = true;
	$scope.sortParams = [
		{
			column: 'locationName',
			order: true,
		},
	];
	$scope.connectivityList = [];
	$scope.offeringList = [];
	$scope.isGeneratingReport = false;
	$scope.viewModel = {isLoadingStarted: false};
	$scope.featureToggles = configService.getFeatureToggles();
	$scope.EVENTS = {...HOME_PAGE_EVENTS, ...NAVIGATION_EVENTS};
	$scope.PRIMARY_OFFERING = PRIMARY_OFFERING;
	$scope.PRIMARY_OFFERINGS = PRIMARY_OFFERINGS;
	updateExportProgressMessages();
	// clearing Date Range when user exits the facility page
	delete $rootScope.appDateRange;
	delete $rootScope.from;
	delete $rootScope.to;

	$scope.openReport = openReport;
	$scope.isCprAllowed = locationDetailsService.isCprAllowed;
	$scope.isOfferingExpired = locationDetailsService.isOfferingExpired;
	$scope.exportFacilityDetails = exportFacilityDetails;
	$scope.cancelLoading = cancelLoading;
	$scope.trackFacilityClicked = trackFacilityClicked;
	$scope.trackChangeView = trackChangeView;
	$scope.onFacilitySearch = onFacilitySearch;
	$scope.trackOnLinkClick = $rootScope.getTrackOnLinkClickHandler(NAVIGATION_CATEGORY_NAME);

	$scope.getAddressLine2 = function(facility) {
		facility.address = facility.address || {};
		let region = facility.address.region || '';
		let city = facility.address.city || '';
		let postalCode = facility.address.postalCode || '';
		let countryCode = facility.address.countryCode || '';
		let rightPart = (region + ' ' + postalCode + ' ' + countryCode).trim();
		return city && rightPart ? city + ', ' + rightPart : city + rightPart;
	};

	const facilitesCallBack = function(progress, items) {
		if (progress === 100) {
			facilitiesCache = $filter('tableSort')(items, $scope.sortParams);

			let facilitiesLength = facilitiesCache.length;

			for (let i = 0; i < facilitiesLength; i++) {
				let facility = facilitiesCache[i];
				facility.statusCSS = 'not-connected';
				facility.status = 'LEGEND_NOT_CONNECTED';
				facility.services = [];
				facility.offeringsDataList = [];
				if (facility.offerings) {
					const offerings = offeringService.filterValidOfferings(facility.offerings, facility.locationTimeZone);
					const offeringsToDisplay = Object.values(offerings);
					offeringsToDisplay.forEach(offering => {
						facility.services.push(offering.shortName);
						facility.offeringsDataList.push(offeringService.addExpirationMetaData(offering, {locationTimeZone: facility.locationTimeZone}));
					});
				} else {
					facility.offerings = [];
				}

				// TODO: improve sort attribute in directive and remove resigning.
				if (facility.address) {
					if (facility.address.line1) {
						facility.line1 = facility.address.line1;
					}
					if (facility.address.region) {
						facility.region = facility.address.region;
					}
					if (facility.address.country) {
						facility.country = facility.address.country;
					}
					if (facility.address.city) {
						facility.city = facility.address.city;
					}
					if (facility.address.postalCode) {
						facility.postalCode = facility.address.postalCode;
					}
				}

				let connectivity = facility.locationConnectivity;
				if (connectivity && connectivity.status) {
					facility.statusCSS = convertConnectivityStatusToCSSClass(connectivity.status);
					facility.status = convertConnectivityStatusToTitle(connectivity.status);

					if (connectivity.devices.length && connectivity.devices[0].device) {
						connectivity.devices = connectivity.devices.filter(function(value) {
							return value.device.deviceType !== CONNECTIVITY_MODULE;
						});

						facility.devices = connectivity.devices;
						facility.uniqDevices = Array.from(
							new Set(
								connectivity.devices.map(({device: {deviceType}}) => {
									switch (deviceType) {
										case 'SEC':
											return 'S700';
										case 'LEC':
											return 'S800';
										default:
											return deviceType;
									}
								})
							)
						);
						facility.connectivityTypeName = facility.uniqDevices.sort().join();
					}
				} else {
					facility.devices = [
						{
							device: {
								name: '',
								deviceType: '',
								identifier: '',
							},
							status: {
								connected: false,
							},
						},
					];
					facility.uniqDevices = [];
				}

				if (!facility.gatewayList) {
					facility.gatewayList = [{description: '', type: '', identifier: '', status: {connected: false}}];
				}

				facility.remoteAccessStatus = defineRemoteAccessStatus(
					facility.gatewayList,
					(connectedCount, notConnectedCount) =>
						connectedCount && notConnectedCount ? 'LEGEND_PARTIALLY_AVAILABLE' : connectedCount ? 'LEGEND_AVAILABLE' : 'LEGEND_NOT_AVAILABLE'
				);
				facility.remoteAccessStatusCSS = defineRemoteAccessStatus(
					facility.gatewayList,
					(connectedCount, notConnectedCount) =>
						connectedCount && notConnectedCount ? 'partially-available' : connectedCount ? 'available' : 'not-available'
				);
			}
		}
	};

	$scope.viewDataEvent = new EventEmitter();
	$scope.generateBuildingSetupLink = externalLinks.buildingSetupLink;
	$scope.generateAddNoteLink = externalLinks.addNoteLink;
	$scope.generateRemoteAccessLink = externalLinks.remoteAccessLink;
	$scope.generateAutomatedTestSettingsLink = externalLinks.automatedTestSettingsLink;
	$scope.generateAutomatedTestSuppressionsLink = externalLinks.automatedTestSuppressionsLink;

	// :todo refactor it is not safe to use Event to Reolve Promise
	const viewDataPromise = new Promise((resolve, reject) => {
		$scope.viewDataEvent.on('initCurrentView', data => {
			$scope.currentViewData = data && data.currentViewData;
			$scope.currentViewName = data && data.currentViewName;
			resolve();
		});
	});

	Promise.all([
		facilityListService.getIndexedUniqueLists({connectivityType: {name: 'locationConnectivity.devices', type: 'connectivity'}}),
		connectivityTypesService.getAllConnectivityTypes(),
	]).then(result => {
		const [{connectivityType}, connectivityList] = result;
		const removeCM = connectivityType.filter(function(deviceName) {
			return deviceName !== CONNECTIVITY_MODULE;
		});

		$scope.connectivityList = connectivityList.filter(item => removeCM.includes(item.connectivityTypeName));
	});

	offeringService.getAllOfferings().then(result => {
		const caseInsensitiveSort = (a, b) => {
			return a.shortName.localeCompare(b.shortName, undefined, {sensitivity: 'base'});
		};
		$scope.offeringList = result
			.filter(({shortName}) => !tisOfferingsToHide.some(value => value.toLowerCase() === shortName.toLowerCase()))
			.sort(caseInsensitiveSort);
	});

	utilityService.getEnvironmentType().then(env => {
		$scope.GEN3FacilitySummaryURL = `${GEN3_URL[env.toUpperCase()]}?linkParameters=|page=facility|facilityId=`;
		$scope.GEN3ReportsURL = `${GEN3_URL[env.toUpperCase()]}?linkParameters=|page=Reports|facilityId=`;
	});

	$q
		.resolve(viewDataPromise)
		.then(() => {
			return facilityListService.facilities.length && !$scope.currentViewName
				? facilityListService.getFacilities(facilitesCallBack)
				: facilityListService.getFacilitiesByFilter(
						{
							viewFilter: $scope.currentViewFilter,
							currentViewName: $scope.currentViewName,
						},
						facilitesCallBack
				  );
		})
		.then(() => {
			populateViewFacilities();
			$scope.loading = false;

			// When just active facilities were obtained and displayed, the all facilities started loading under the hood.
			// This is because of we need all facilities to build appropriate select list when create/edit my views.
			facilityListService.facilities.length
				? allFacilitiesLoadedDefer.resolve()
				: facilityListService.getFacilities(facilitesCallBack).then(() => allFacilitiesLoadedDefer.resolve());
		});

	setTimeout(onPageLoad, 1000);

	$scope.loadMoreFacilities = function() {
		if (!$scope.relevantFacilities.length || $scope.relevantFacilities.length === $scope.facilities.length) {
			return;
		}
		const filteredRelevantFacilities = filterRelevantFacilities();
		const startIndex = $scope.facilities.length;
		const nextChunk = filteredRelevantFacilities.slice(startIndex, startIndex + facilitiesChunkSize);

		Array.prototype.push.apply($scope.facilities, nextChunk);
		$scope.relevantFacilities = getFacilitiesByCurrentViewFilter(this.getSourceList());
	};

	$scope.view = isViewValid($location.search().view) && $location.search().view;
	$scope.$on('$routeUpdate', function() {
		const view = $location.search().view;
		if (!isViewValid(view)) {
			if (uiStateService.stateExists($scope.uiStateKeyView)) {
				$location.search('view', uiStateService.getState($scope.uiStateKeyView));
			} else {
				$location.search('view', $scope.VIEW.TILES);
			}
		} else {
			$scope.view = view;
		}
	});
	$scope.$watch('view', onViewChange);

	$scope.$watch('searchObj.searchText', function(n, o) {
		if (n === o) return;
		populateFacilities($scope.getSourceList());
	});

	$scope.getSourceList = function() {
		return $scope.currentViewName ? facilitiesViewCache : facilitiesCache;
	};

	function trackGenerateReportEvent(options = {}) {
		usageTrackingService.trackEvent(REPORT_GENERATION_CATEGORY_NAME, REPORT_GENERATION_EVENTS.GENERATE_REPORT, {
			[REPORT_GENERATION_PROPERTIES.BUILDING_ID]: $scope.locationId,
			...options,
		});
	}

	function populateFacilities(facilities = []) {
		facilities = getFacilitiesByCurrentViewFilter(facilities);

		$scope.facilities = [];
		$scope.relevantFacilities = [];

		const filteredRelevantFacilities = filterRelevantFacilities(facilities);

		Array.prototype.push.apply($scope.facilities, filteredRelevantFacilities.slice(0, facilitiesChunkSize));
		$scope.currentShown = filteredRelevantFacilities.length;
		$scope.relevantFacilities = facilities;
	}

	function filterRelevantFacilities(source) {
		let relevantFacilities = source && source.length ? source : $scope.relevantFacilities;

		if ($scope.searchObj.searchText) {
			relevantFacilities = $filter('filterBySearchText')(relevantFacilities, {
				searchFieldsList: ['locationName', 'accountName', 'line1', 'region', 'country', 'city', 'address'],
				searchText: $scope.searchObj.searchText,
				searchNested: false,
			});
		}

		if ($scope.filters) {
			relevantFacilities = $filter('tableFilter')(relevantFacilities, $scope.filters);
		}
		if ($scope.parameters) {
			relevantFacilities = $filter('tableSort')(relevantFacilities, $scope.parameters);
		}

		return relevantFacilities;
	}

	function convertConnectivityStatusToCSSClass(status) {
		let cssCls;
		switch (status) {
			case 'Connected':
				cssCls = 'connected';
				break;
			case 'PartiallyConnected':
				cssCls = 'partially-connected';
				break;
			default:
				cssCls = 'not-connected';
		}
		return cssCls;
	}

	function convertConnectivityStatusToTitle(status) {
		let title;
		switch (status) {
			case 'Connected':
				title = 'LEGEND_CONNECTED';
				break;
			case 'PartiallyConnected':
				title = 'LEGEND_PARTIALLY_CONNECTED';
				break;
			default:
				title = 'LEGEND_NOT_CONNECTED';
		}
		return title;
	}

	function defineRemoteAccessStatus(gatewayList = [], predicate) {
		const connectedCount = gatewayList.filter(({status}) => status.connected).length;
		const notConnectedCount = gatewayList.filter(({status}) => !status.connected).length;

		return predicate(connectedCount, notConnectedCount);
	}

	$scope.openChart = function() {
		const selectedFacilities = $filter('filter')($scope.facilities, {selected: true});

		if (Array.isArray(selectedFacilities) && selectedFacilities.length > 0) {
			googleAnalyticsService.events.HOME.useActionMenuItem(
				selectedFacilities.map(({locationId}) => locationId).join(','),
				selectedFacilities.map(({locationName}) => locationName).join(','),
				'openChart'
			);

			if (selectedFacilities.length === 1) {
				$scope.go(`/facility/${selectedFacilities[0].locationId}/type/null/chart/null`);
			} else {
				// open dialog
				$scope.modalFPC = modalHelperService.open({
					templateUrl: 'components/chart/facility-performance-chart-dialog/facility-performance-chart-dialog-controller.html',
					controller: 'FacilityPerformanceChartDialogCtrl',
					backdrop: 'static',
					windowClass: 'report-dialog full-height',
					resolve: {
						data: function() {
							return {
								locationId: selectedFacilities[0].locationId,
								facilitiesIds: selectedFacilities,
								equipmentTypeNumber: null,
								serviceAdvisoryTypeId: 'null',
							};
						},
					},
				});
			}
		}
	};

	$scope.updateBetaText = function(selector) {
		let list = document.querySelectorAll(selector + ' li.beta');
		let betaText = $filter('translate')('BETA_TEXT');

		for (let i = 0; i < list.length; i++) {
			list[i].setAttribute('title', betaText);
		}
	};

	$scope.selectAll = function(isAllSelected) {
		filterRelevantFacilities().forEach(facility => (facility.selected = isAllSelected));
		$scope.isAllSelected = isAllSelected;
		$scope.facilitySelectedHandler();

		if (isAllSelected) {
			onFacilityCheck();
		}
	};

	$scope.facilitySelectedHandler = function(isSelected) {
		const selectedFacilities = $filter('filter')($scope.facilities, {selected: true});
		$scope.noFacilitySelected = selectedFacilities.length === 0;
		$scope.oneFacilitySelected = selectedFacilities.length === 1;
		$scope.multipleFacilitiesSelected = selectedFacilities.length > 1;
		if ($scope.oneFacilitySelected) {
			[$scope.selectedFacility] = selectedFacilities;
		}
		if (isSelected) {
			onFacilityCheck();
		}
	};

	$scope.showConnectivityInfoPopup = function({facility, $event}) {
		facility.showConnectivityInfoPopup = true;
		showMoreInfoPopup({facility, $event});
	};

	$scope.showOfferingsInfoPopup = function(param) {
		const {facility, offering, $event} = param;
		offering.showOfferingsInfoPopup = true;
		showMoreInfoPopup({facility, $event});
	};

	$scope.showRemoteAccessInfoPopup = function({facility, $event}) {
		facility.showRemoteAccessInfoPopup = true;
		showMoreInfoPopup({facility, $event});
	};

	$scope.closeMoreInfoPopup = function({facility, offering, $event}) {
		facility.showConnectivityInfoPopup = false;
		facility.showRemoteAccessInfoPopup = false;
		facility.popupClass = '';

		if (offering) {
			offering.showOfferingsInfoPopup = false;
		}
		$event.stopPropagation();
	};

	function openReport(report) {
		let selectedFacility = $scope.facilities.find(f => f.selected);

		if (report === REPORT_TYPES.REPORT_CHILLER_PERFORMANCE_ENGLISH.report && !locationDetailsService.isCprAllowed(selectedFacility)) {
			return;
		}

		if (report !== REPORT_TYPES.RAW_DATA.report || selectedFacility.locationName) {
			googleAnalyticsService.events.HOME.useActionMenuItem(selectedFacility.locationId, selectedFacility.locationName, report);
			if ($scope.oneFacilitySelected) {
				$scope.modal = modalHelperService.open({
					templateUrl: 'components/reports/report-dialog.html',
					controller: 'ReportDialogCtrl',
					backdrop: 'static',
					windowClass: 'report-dialog full-height',
					resolve: {
						data: function() {
							return {
								report: report,
								locationData: selectedFacility,
							};
						},
					},
				});
			}
		}
	}

	function exportFacilityDetails() {
		const translations = {
			headers: {
				TITLE_FACILITY_ID: $filter('translate')('TITLE_FACILITY_ID'),
				TITLE_FACILITY_NAME: $filter('translate')('TITLE_FACILITY_NAME'),
				TITLE_CRM_SITE_ID: $filter('translate')('TITLE_CRM_SITE_ID'),
				TITLE_ADDRESS: $filter('translate')('TITLE_ADDRESS'),
				TITLE_CITY: $filter('translate')('TITLE_CITY'),
				TITLE_STATE: $filter('translate')('TITLE_STATE'),
				TITLE_COUNTRY: $filter('translate')('TITLE_COUNTRY'),
				TITLE_ACCOUNT_NAME: $filter('translate')('TITLE_ACCOUNT_NAME'),
				TITLE_CONNECTIVITY_TYPE: $filter('translate')('TITLE_CONNECTIVITY_TYPE'),
				TITLE_FACILITY_CREATED_DATE: $filter('translate')('TITLE_FACILITY_CREATED_DATE'),
				TITLE_FACILITY_CREATED_BY: $filter('translate')('TITLE_FACILITY_CREATED_BY'),
				TITLE_DATA_LAST_COLLECTED: $filter('translate')('TITLE_DATA_LAST_COLLECTED'),
				TITLE_DATA_FIRST_COLLECTED: $filter('translate')('TITLE_DATA_FIRST_COLLECTED'),
				TITLE_OFFICE_CODE: $filter('translate')('TITLE_OFFICE_CODE'),
				TITLE_BBMD_IP: $filter('translate')('TITLE_BBMD_IP'),
				TITLE_UDP_PORT: $filter('translate')('TITLE_UDP_PORT'),
				TITLE_ACTIVE_MONITORING: $filter('translate')('TITLE_ACTIVE_MONITORING'),
				TITLE_ALARM_NOTIFICATION: $filter('translate')('TITLE_ALARM_NOTIFICATION'),
				TITLE_BP: $filter('translate')('TITLE_BP'),
				TITLE_EP: $filter('translate')('TITLE_EP'),
				TITLE_TRIAL: $filter('translate')('TITLE_TRIAL'),
				TITLE_PILOT: $filter('translate')('TITLE_PILOT'),
				TITLE_BP_EXPIRATION_DATE: $filter('translate')('TITLE_BP_EXPIRATION_DATE'),
				TITLE_EP_EXPIRATION_DATE: $filter('translate')('TITLE_EP_EXPIRATION_DATE'),
			},
		};

		const locationIds = filterRelevantFacilities().map(item => item.locationId);

		const data = {
			filename: $filter('date')(new Date(), 'yyyy-MM-dd') + ' Locations.xlsx',
			subtype: REPORT_TYPES.FACILITY_DETAILS.subtype,
			token: authorization.getToken(),
			type: 'report',
			locationIds,
			viewName: null,
			sortParams: $scope.sortParams,
			translations,
		};

		$scope.isGeneratingReport = true;
		$scope.viewModel.isLoadingStarted = true;

		reportGenerationStartTime = performance.now();
		exportService
			.sendExportRequest(data)
			.then(({progressKey}) => exportProgressService.open(progressKey))
			.then(finalizeExport, failExport, showExportProgress)
			.catch(() => {
				$scope.isGeneratingReport = false;
				$scope.viewModel.isLoadingStarted = false;
			});

		trackGenerateReportEvent({
			[REPORT_GENERATION_PROPERTIES.REPORT]: getReportNameByKey(REPORT_TYPES.FACILITY_DETAILS.name),
			[REPORT_GENERATION_PROPERTIES.REPORT_GENERATION_STATUS]: getReportGenerationStatus('started'),
		});
	}

	function finalizeExport() {
		// Do nothing so far.
		let timeTaken = performance.now() - reportGenerationStartTime;
		trackGenerateReportEvent({
			[REPORT_GENERATION_PROPERTIES.REPORT]: getReportNameByKey(REPORT_TYPES.FACILITY_DETAILS.name),
			[REPORT_GENERATION_PROPERTIES.REPORT_GENERATION_STATUS]: getReportGenerationStatus('success'),
			[REPORT_GENERATION_PROPERTIES.TOTAL_TIME]: timeTaken,
		});
	}

	function failExport() {
		let timeTaken = performance.now() - reportGenerationStartTime;
		trackGenerateReportEvent({
			[REPORT_GENERATION_PROPERTIES.REPORT]: getReportNameByKey(REPORT_TYPES.FACILITY_DETAILS.name),
			[REPORT_GENERATION_PROPERTIES.REPORT_GENERATION_STATUS]: getReportGenerationStatus('failed'),
			[REPORT_GENERATION_PROPERTIES.TOTAL_TIME]: timeTaken,
		});
		exportProgressService.cancel();
		updateExportProgressMessages();
	}

	function showExportProgress(input = {}) {
		if (input.message) {
			$scope.exportProgressMessages.currentMessage = input.message;
		}

		if (input.data && input.data.readyToDownload) {
			startDownloadProcess();

			$scope.isGeneratingReport = false;
			$scope.viewModel.isLoadingStarted = false;
			updateExportProgressMessages();
		}
	}

	function startDownloadProcess() {
		const progressKey = exportProgressService.getProgressKey();
		if (progressKey) {
			let exportData = {
				progressKey,
				subtype: REPORT_TYPES.FACILITY_DETAILS.subtype,
				downloadXLSX: true,
			};

			angular.element(document.getElementById('export-data')).val(JSON.stringify(exportData));
			document.getElementById('export-form').submit();
		}
	}

	function updateExportProgressMessages() {
		$scope.exportProgressMessages = {
			currentMessage: $filter('translate')('LOADING_REPORT'),
		};
	}

	function cancelLoading() {
		if ($scope.isGeneratingReport) {
			exportProgressService.cancel();
			$scope.viewModel.isLoadingStarted = false;
			$scope.isGeneratingReport = false;
		}
	}

	function trackFacilityClicked(facility) {
		googleAnalyticsService.events.HOME.navigateToFacility(facility.locationId, facility.locationName);
		trackEvent(HOME_PAGE_EVENTS.GO_TO_FACILITY_SUMMARY_PAGE);

		$location.url([`/facility/${facility.locationId}`]);
	}

	function trackChangeView(view) {
		googleAnalyticsService.events.HOME.viewChanged($scope.view);
		trackEvent(view === $scope.VIEW.TILES ? HOME_PAGE_EVENTS.CHANGE_TO_TILE_VIEW : HOME_PAGE_EVENTS.CHANGE_TO_LIST_VIEW);
	}

	// ---- My Views

	function checkFacilitiesByDimension(facilityValue, value, dimension) {
		switch (dimension) {
			case OPERATOR.CONTAINS:
				return facilityValue.toLowerCase().includes(value.toLowerCase());
			case OPERATOR.DOES_NOT_CONTAIN:
				return !facilityValue.toLowerCase().includes(value.toLowerCase());
			case OPERATOR.NOT_EQUAL_TO:
				return facilityValue !== value;
			case OPERATOR.IS_EQUAL_TO:
				return facilityValue === value;
			case OPERATOR.DOES_NOT_END_WITH:
				return !facilityValue.endsWith(value);
			case OPERATOR.DOES_NOT_START_WITH:
				return !facilityValue.startsWith(value);
			case OPERATOR.ENDS_WITH:
				return facilityValue.endsWith(value);
			case OPERATOR.STARTS_WITH:
				return facilityValue.startsWith(value);
		}
		return false;
	}

	// Each time when the filter/view is changed the locations are obtained from API
	// That's why the checkFacilitiesByCurrentView() function is not used currently.
	// It can be improved to filter facilities by the new version of my views (which includes groups).
	function checkFacilitiesByCurrentView(facility) {
		let ret = false;
		if (!($scope.currentViewData || $scope.currentViewData.length)) {
			return true;
		}
		$scope.currentViewData.forEach(function(view) {
			if (ret) return;
			let operator = OPERATOR[view.equal];
			if (!operator) {
				const index = Object.keys(OPERATOR)[view.equal];
				operator = OPERATOR[index];
			}
			const isNegativeOperator = operator.isNegative;
			const isNotEqualToOperator = operator => operator.name === OPERATOR.NOT_EQUAL_TO.name;
			switch (view.name) {
				case 'Account':
					ret = checkFacilitiesByDimension(facility.accountName, view.value, operator);
					break;
				case 'Connectivity Status':
					{
						let tmp = {
							LEGEND_CONNECTED: 'connected',
							LEGEND_NOT_CONNECTED: 'not-connected',
							LEGEND_PARTIALLY_CONNECTED: 'partially-connected',
						};
						ret = checkFacilitiesByDimension(facility.statusCSS, tmp[view.value], operator);
					}
					break;
				case 'Connectivity Type':
					if (helpers.getPropertyByPath(facility, 'locationConnectivity.devices.length') > 0) {
						if (isNegativeOperator) {
							ret = facility.locationConnectivity.devices.every(d => {
								return checkFacilitiesByDimension(d.device.deviceType, view.value, operator);
							});
						} else {
							ret = facility.locationConnectivity.devices.some(d => {
								return checkFacilitiesByDimension(d.device.deviceType, view.value, operator);
							});
						}
					} else if (isNotEqualToOperator(operator)) {
						ret = true;
					}
					break;
				case 'Country':
					if (facility.address && facility.address.country) {
						ret = checkFacilitiesByDimension(facility.address.country, view.value, operator);
					} else if (isNotEqualToOperator(operator)) {
						ret = true;
					}
					break;
				case 'Facility Name':
					ret = checkFacilitiesByDimension(facility.locationName, view.value, operator);
					break;
				case 'Facility Phase':
					ret = checkFacilitiesByDimension(facility.statusName, view.value, operator);
					break;
				case 'Offering Type':
					if (facility.offerings && facility.offerings.length) {
						if (isNegativeOperator) {
							ret = facility.offerings.every(d => {
								return checkFacilitiesByDimension(d.name, view.value, operator);
							});
						} else {
							ret = facility.offerings.some(d => {
								return checkFacilitiesByDimension(d.name, view.value, operator);
							});
						}
					} else if (isNotEqualToOperator(operator)) {
						ret = true;
					}
					break;
				case 'Office Code':
					if (facility.salesOffice) {
						ret = checkFacilitiesByDimension(facility.salesOffice.officeCode, view.value, operator);
					} else if (isNotEqualToOperator(operator)) {
						ret = true;
					}
					break;
				case 'State':
					if (facility.address && facility.address.country && facility.address.region) {
						ret = checkFacilitiesByDimension(`${facility.address.country}: ${facility.address.region}`, view.value, operator);
					} else if (isNotEqualToOperator(operator)) {
						ret = true;
					}
					break;
				case 'TIS Equipment Type':
					if (facility.tisObjectTypeGroupNames && facility.tisObjectTypeGroupNames.length) {
						if (isNegativeOperator) {
							ret = facility.tisObjectTypeGroupNames.every(d => {
								return checkFacilitiesByDimension(d, view.value, operator);
							});
						} else {
							ret = facility.tisObjectTypeGroupNames.some(d => {
								return checkFacilitiesByDimension(d, view.value, operator);
							});
						}
					} else if (isNotEqualToOperator(operator)) {
						ret = true;
					}
					break;
			}
		});
		return ret;
	}

	function populateViewFacilities() {
		$scope.currentShown = 0;
		if ($scope.currentViewName) {
			// Each time when the filter/view is changed the locations are obtained from API
			// That's why the checkFacilitiesByCurrentView() function is not used currently. It can be improved as it was before.
			// facilitiesViewCache = facilitiesCache.filter(checkFacilitiesByCurrentView) || [];
			facilitiesViewCache = [...facilitiesCache];
			populateFacilities(facilitiesViewCache);
		} else {
			populateFacilities(facilitiesCache);
		}
	}

	function getFacilitiesByCurrentViewFilter(facilities = []) {
		switch ($scope.currentViewFilter) {
			case VIEW_FILTERS.ACTIVE_FILTER:
				return facilities.filter(({activeStatusState}) => facilityListService.activeStatusStates.includes(activeStatusState));
			case VIEW_FILTERS.DISABLED_FILTER:
				return facilities.filter(({activeStatusState}) => facilityListService.disabledStatusStates.includes(activeStatusState));
			default:
				return facilities;
		}
	}

	function updateViewFacilities() {
		$q
			.resolve()
			.then(() => ($scope.loading = true))
			.then(() => allFacilitiesLoadedDefer.promise)
			// Each time when the filter/view is changed the locations are obtained from API
			.then(() =>
				facilityListService.getFacilitiesByFilter(
					{
						viewFilter: $scope.currentViewFilter,
						currentViewName: $scope.currentViewName,
					},
					facilitesCallBack
				)
			)
			.then(() => populateViewFacilities())
			.then(() => ($scope.loading = false));
	}

	function showMoreInfoPopup({facility, $event}) {
		googleAnalyticsService.events.HOME.connectivityClicked(facility.locationId);
		$event.stopPropagation();
	}

	$scope.toggleLegend = function(visibility) {
		$scope.showLegend = visibility;
	};

	$scope.$on('$routeChangeStart', function(next, current) {
		if ($scope.modal && $scope.modal.dismiss && $scope.modal.opened.$$state.value) {
			try {
				$scope.modal.dismiss();
			} catch (err) {
				return false;
			}
		}
	});

	$rootScope.$on('applyFilter', function(event, filterParams) {
		const {filters} = filterParams;
		$scope.filters = filters;
		const sourceList = $scope.getSourceList();
		if (sourceList.length > 0) {
			populateFacilities(sourceList);
		}
	});

	$rootScope.$on('applySort', function(event, parameters) {
		$scope.parameters = parameters;
		populateFacilities($scope.getSourceList());
	});

	$scope.viewDataEvent.on('changeCurrentViewFilter', ({viewFilter}) => {
		if (viewFilter !== $scope.currentViewFilter) {
			$scope.currentViewFilter = viewFilter;

			updateViewFacilities();
		}
	});

	$scope.viewDataEvent.on('changeCurrentView', ({currentViewName, currentViewData, currentViewFilter, forceUpdate = false}) => {
		if (forceUpdate || $scope.currentViewName !== currentViewName) {
			$scope.currentViewName = currentViewName;
			currentViewFilter && ($scope.currentViewFilter = currentViewFilter);

			updateViewFacilities();
		}
	});

	function onPageLoad() {
		trackEvent(HOME_PAGE_EVENTS.HOME_PAGE_LOAD, {
			[HOME_PAGE_PROPERTIES.VIEW]: getViewNameByQueryParameter($location.search().view),
		});
	}

	function onFacilitySearch(text) {
		if (text) {
			googleAnalyticsService.events.HOME.searchUsed(text);
			trackEvent(HOME_PAGE_EVENTS.SEARCH_FACILITIES);
		}
	}

	function onFacilityCheck() {
		trackEvent(HOME_PAGE_EVENTS.SELECT_A_FACILITY);
	}

	function calculateFacilitiesChunkSize() {
		if ($scope.view === $scope.VIEW.TILES) {
			const TILE_WIDTH = 250;
			const TILE_HEIGHT = 121;
			const containerElement = document.body;
			const facilitiesListArea = containerElement.clientWidth * containerElement.clientHeight;

			facilitiesChunkSize = Math.ceil(facilitiesListArea / (TILE_WIDTH * TILE_HEIGHT));
		} else {
			facilitiesChunkSize = FACILITIES_CHUNK_SIZE;
		}
	}

	function isViewValid(view) {
		return view && Object.values($scope.VIEW).includes(view);
	}

	function onViewChange(view) {
		calculateFacilitiesChunkSize();
		populateFacilities($scope.getSourceList());
		if (view) {
			$location.search('view', view);
			uiStateService.registerState($scope.uiStateKeyView, view);
		} else {
			$location.search('view', 'tiles').replace();
		}
	}

	function onWindowResize() {
		$scope.$evalAsync(() => onViewChange($scope.view));
	}

	window.addEventListener('resize', onWindowResize);

	$scope.$on('$destroy', () => window.removeEventListener('resize', onWindowResize));
}
