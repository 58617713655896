import _get from 'lodash.get';

angular
	.module('TISCC')
	.service('rawDataService', function(
		$http,
		$filter,
		$q,
		$cookies,
		helpers,
		locationDetailsService,
		tisObjectService,
		utilityService,
		subtypeFilterService,
		$injector,
		API_ENDPOINTS,
		DEFAULTS
	) {
		const MIN_INSTANCES_NUMBER = 2;
		const CHILLER = 'Chiller';
		const COMPRESSOR = 'Compressor';
		const CIRCUIT = 'Circuit';
		const CIRCUIT_DETAILS = 'circuit_details';

		let translate = $filter('translate');
		let tisObjectIdsByTisObjectType;
		let currentSelectedElements = [];
		let authorization = $injector.get('authorization');

		this.getTisObjectTypesFromTisObjects = getTisObjectTypesFromTisObjects;
		this.getTisObjectSubTypesFromTisObjects = getTisObjectSubTypesFromTisObjects;
		this.getPropertiesForRightList = getPropertiesForRightList;
		this.findTisObjectById = findTisObjectById;
		this.createEquipmentsList = createEquipmentsList;
		this.generateReportMetadata = generateReportMetadata;
		this.setCurrentSelectedElements = setCurrentSelectedElements;
		this.fixRequestEndTime = fixRequestEndTime;
		this.sendExportRequest = sendExportRequest;
		this.setBalancerCookie = setBalancerCookie;

		function setCurrentSelectedElements(elements) {
			currentSelectedElements = elements;
		}

		/**
		 * findSingleMultipleCircuit
		 *
		 * fn to indentify the checked / selected equipment (chiller) has single or multiple circuit.
		 * Based on circuit type, property display name will be shown on RDR
		 *
		 * This will check for chiller equipment only.
		 *
		 * @param {*} tisObjects
		 * @returns
		 */

		function findSingleMultipleCircuit(tisObjects = []) {
			const singleAndMultpleCircuitChillers = tisObjects.reduce(
				(av, tisObject) => {
					// if equipent other than chiller & not selected, don't proceed
					if (!tisObject.checked || tisObject.tisObjectType.tisObjectTypeGroupName !== CHILLER) return av;

					// if chiller has CIRCUIT equipments whch are greater than or equal to MIN_INSTANCES_NUMBER as children,
					//		it would be multiple circuit chiller
					// else
					//		it would be a single circuit chiller
					if (_get(tisObject, 'children.length') >= MIN_INSTANCES_NUMBER) {
						av.multiple = tisObject.children.filter(equipment => equipment.tisObjectType.tisObjectTypeGroupName === CIRCUIT).length > 1;
					} else {
						av.single = true;
					}

					return av;
				},
				{
					single: false,
					multiple: false,
				}
			);

			// function to map the findings ( single / multiple circuit ) with COMPRESSOR object where it will be used to show labels
			return function mapCircuitDetailsWithCompressorTypes(currentType) {
				const equipmentType = currentType.tisObjectType.tisObjectTypeGroupName;
				if (equipmentType !== COMPRESSOR) return;
				currentType[CIRCUIT_DETAILS] = singleAndMultpleCircuitChillers;
			};
		}

		function getTisObjectTypesFromTisObjects(tisObjects) {
			const types = {};

			const mapCircuitDetailsWithCompressorTypes = findSingleMultipleCircuit(tisObjects);

			tisObjects.forEach(tisObject => {
				const isEquipmentType = tisObject.tisObjectType.tisObjectTypeClassification === 'Equipment';

				if (tisObject.checked) {
					updateTisObjectTypes(types, tisObject);

					if (tisObject.children && !isEquipmentType) {
						processLeftPanelChildren(tisObject, types);
					}
				}
			});

			// Check selected equipment has chiller object or not
			const isChiller = Object.keys(types).some(typeName => {
				return typeName === CHILLER;
			});

			Object.keys(types).forEach(function(typeName) {
				// If tisObject includes fewer instances than MIN_INSTANCES_NUMBER, do not show this instance name in property name
				// Example: Comp Running (if < MIN_INSTANCES_NUMBER instances) vs Comp Ckt 1 Running (if >= MIN_INSTANCES_NUMBER)
				if (types[typeName].instances.length < MIN_INSTANCES_NUMBER) {
					types[typeName].instances = [null];
				} else {
					const selectedTisObjects = tisObjects.filter(obj => obj.checked);
					const isMultipleTisObjectsSelected = selectedTisObjects.length > 1;
					const isSomeTisObjectsWithOnlyOneChildSelected = selectedTisObjects.some(({children = []}) => children.length === 1);

					if (isMultipleTisObjectsSelected && isSomeTisObjectsWithOnlyOneChildSelected) {
						// when both a single & multiple circuit chiller is selected,
						// Don't add extra null value into COMPRESSOR instance
						if (!(typeName === COMPRESSOR && isChiller)) types[typeName].instances.push(null);
					}
				}
				mapCircuitDetailsWithCompressorTypes(types[typeName]);
			});
			tisObjectIdsByTisObjectType = types;
			return types;
		}

		function getTisObjectSubTypesFromTisObjects(tisObjects) {
			const subTypes = [];
			tisObjects.forEach(tisObject => {
				const isEquipmentType = tisObject.tisObjectType.tisObjectTypeClassification === 'Equipment';
				const isBoiler = tisObject.tisObjectType.tisObjectTypeGroupName === 'Boiler';

				if (tisObject.checked && tisObject.children && !isEquipmentType && tisObject.subTypeFilterKey) {
					if (!subTypes.includes(tisObject.subTypeFilterKey)) {
						subTypes.push(tisObject.subTypeFilterKey);
					}
				} else if (tisObject.checked && isBoiler && isEquipmentType && tisObject.subTypeFilterKey) {
					if (!subTypes.includes(tisObject.subTypeFilterKey)) {
						subTypes.push(tisObject.subTypeFilterKey.replace(' ', ''));
					}
				}
			});

			return subTypes;
		}

		function processLeftPanelChildren(tisObject, types) {
			tisObject.children.forEach(function(tisObjectChild) {
				let classification = tisObjectChild.tisObjectType.tisObjectTypeClassification;

				if (classification !== 'ComponentObject' && classification !== 'Equipment') {
					updateTisObjectTypes(types, tisObjectChild);
				}
				if (tisObjectChild.children) {
					processLeftPanelChildren(tisObjectChild, types);
				}
			});
		}

		function updateTisObjectTypes(types, tisObject) {
			const tisObjectTypeGroupName = tisObject.tisObjectType.tisObjectTypeGroupName;
			const instance = tisObject.instance;

			if (!types[tisObjectTypeGroupName]) {
				types[tisObjectTypeGroupName] = {
					instances: [],
					instancesTisObjectId: {},
					tisObjectIds: [],
					tisObjectType: tisObject.tisObjectType,
				};
			}
			const currentType = types[tisObjectTypeGroupName];

			if (instance && currentType.instances.indexOf(instance) === -1) {
				currentType.instances.push(instance);
			}
			if (currentType.tisObjectIds.indexOf(tisObject.tisObjectId) === -1) {
				currentType.tisObjectIds.push(tisObject.tisObjectId);
			}

			// if equipment has multi circuit,
			// store the circuit ids which will be used to render correct property when one of multi circuit associated property is selected
			if (instance) {
				if (!currentType.instancesTisObjectId[instance]) currentType.instancesTisObjectId[instance] = {};

				const addInstanceIds = currentType.instancesTisObjectId[instance];

				addInstanceIds[tisObject.tisObjectId] = true;
			}
		}

		/**
		 * @param {{instances: {tisObjectType: string},
		 * property: {propertyAttribute: {enumerationGroupNameAndName: string}}, tisObjectType: string}}propertyData
		 * @returns {Array}
		 */
		function getPropertiesForRightList(propertyData) {
			let property = propertyData.property;
			return propertyData.instances.map(function(instance) {
				return {
					tisObjectType: propertyData.tisObjectType,
					value: createPropertyNameValue(propertyData, instance),
					instance: instance,
					propertyName: property.propertyName,
					propertyEnumeration: property.propertyAttribute && property.propertyAttribute.enumerationGroupNameAndName,
					isCharacteristic: property.isCharacteristic,
					digitResolution: property.propertyAttribute && property.propertyAttribute.resolution ? property.propertyAttribute.resolution : undefined,
					unitOfMeasureName:
						property.propertyAttribute && property.propertyAttribute.unitOfMeasureName ? property.propertyAttribute.unitOfMeasureName : undefined,
					uom: propertyData.property.unitOfMeasure,
					typeName: property.typeName,
					checked: false,
					dataType: _get(property, 'propertyAttribute.dataType', ''),
					instancesTisObjectId: (propertyData.instancesTisObjectId || {})[instance] || null,
				};
			});
		}

		function createPropertyNameValue(propertyData, instance) {
			let property = propertyData.property;
			let propertyName = property.propertyName;
			let tisObjectTypeName = propertyData.tisObjectType.tisObjectTypeGroupName;
			let value;

			if (instance) {
				value = $filter('translateProperty')('M_' + propertyName, tisObjectTypeName, {
					name: translate(instance),
				});
				// TODO Remove this check when localization files will be filled.
				if (value.indexOf('M_') === 0) {
					value = translate(instance) + ' ' + $filter('translateProperty')(propertyName, tisObjectTypeName);
				}
			} else {
				value = $filter('translateProperty')(propertyName, tisObjectTypeName);
			}
			return updateCharacteristicDisplayName(value, property);
		}

		function updateCharacteristicDisplayName(displayName, obj) {
			return displayName.replace('‘', '') + (obj.isCharacteristic ? ' ***' : '');
		}

		function findTisObjectById(objectId, searchArr, parentId) {
			let foundTisObject = null;

			if (!searchArr) {
				searchArr = currentSelectedElements;
			}

			for (let i = 0; i < searchArr.length; i++) {
				let type = searchArr[i].tisObjectType.tisObjectTypeClassification;

				if (searchArr[i].tisObjectId === objectId) {
					foundTisObject = searchArr[i];

					setParentIdIfNeeded(foundTisObject, parentId);
					break;
				} else if (searchArr[i].children && searchArr[i].children.length !== 0) {
					let compObjId;
					if (type === 'ComponentObject') {
						compObjId = searchArr[i].tisObjectId;
					} else if (parentId) {
						compObjId = parentId;
					}
					foundTisObject = findTisObjectById(objectId, searchArr[i].children, compObjId);

					if (foundTisObject) {
						setParentIdIfNeeded(foundTisObject, compObjId);
						break;
					}
				}
			}
			return foundTisObject;
		}

		function setParentIdIfNeeded(tisObject, parentId) {
			if (parentId && isComponent(tisObject.tisObjectType)) {
				tisObject.parentId = parentId;
			}
		}

		function createEquipmentsList(params) {
			const {equipments, typeId, rootId} = params;
			const validParents = [rootId];

			return equipmentsIterator({
				equipments: equipments,
				typeId: typeId,
				rootId: rootId,
				validParents: validParents,
			});
		}

		function equipmentsIterator(params) {
			const {equipments, typeId, rootId, parentId, validParents} = params;
			let equipmentsData = [];

			equipments.forEach(function(equipment) {
				if (equipment.tisObjectType.tisObjectTypeGroupNumber === typeId) {
					const {tisObjectId, tisObjectSubType, tisObjectClassificationType, children, applicationType} = equipment;
					if (tisObjectId && tisObjectSubType && tisObjectClassificationType && children) {
						equipment.subTypeFilterKey = subtypeFilterService.getFilterKeyNameFromObjectWithHierarchy(equipment);
					}
					if (applicationType) {
						equipment.subTypeFilterKey = applicationType;
					}
					if (parentId) {
						if (isVasOrLoadValve(typeId)) {
							if (validParents.indexOf(parentId) !== -1) {
								validParents.push(equipment.tisObjectId);
								equipmentsData.push(equipment);
							}
						} else {
							equipmentsData.push(equipment);
						}
					} else {
						equipmentsData.push(equipment);
					}
				}
				if (equipment.children) {
					equipmentsData = equipmentsData.concat(
						equipmentsIterator({
							equipments: equipment.children,
							typeId: typeId,
							rootId: rootId,
							validParents: validParents,
							parentId: equipment.tisObjectId,
						})
					);
				}
			});
			return equipmentsData;
		}

		function isVasOrLoadValve(id) {
			// tisObjectTypeGroupNumber values we need to show sub-sets
			return [14, 21].indexOf(id) !== -1; // Hardcoded VAS type and LoadValve
		}

		function generateReportMetadata(params) {
			let equipmentsList = params.equipmentList;
			let selectedProperties = params.selectedProperties;
			let selectedEquipments = params.selectedEquipments;
			let from = params.from;
			let to = params.to;
			let reportFormat = params.reportFormat;
			let progressKey = params.progressKey;
			let filename = params.filename;
			let propertiesByTisObjectType = separatePropertiesByTisObjectType(selectedProperties);
			let includeDataWithErrors = params.includeDataWithErrors;
			let schema =
				reportFormat === 'single'
					? getWorksheetForSelectedPropertiesSingleFormat(selectedEquipments)
					: getWorksheetForSelectedPropertiesMultiFormat(selectedProperties);

			const token = authorization.getToken();
			let timezone = locationDetailsService.getLocationTimezone();

			let allRequests = []; // Dummy for RDR templates support - multi-sheet environment
			allRequests.push({
				worksheetName: 'RDR Sheet 1',
				requests: [],
			});

			let sheetRequests = [{propertiesForRequest: [], tisObjectIds: []}];
			Object.keys(propertiesByTisObjectType)
				.filter(tisObjectTypeName => tisObjectTypeName !== 'weather')
				.forEach(tisObjectTypeName => {
					let tisObjectTypeProperties = propertiesByTisObjectType[tisObjectTypeName];
					let propertiesForRequest = getPropertiesForRequest(tisObjectTypeProperties);
					let tisObjectIds = tisObjectIdsByTisObjectType[tisObjectTypeName].tisObjectIds;

					const tisObjectType = tisObjectTypeProperties[0].tisObjectType;
					if (isComponent(tisObjectType)) {
						tisObjectIds = getComponentParentID({tisObjectType, tisObjectIds, equipmentsList});
					}
					sheetRequests[0].propertiesForRequest.push(...propertiesForRequest);
					sheetRequests[0].tisObjectIds.push(...tisObjectIds);

					sheetRequests[0].tisObjectIds = [...new Set(sheetRequests[0].tisObjectIds)]; // Just a array.unique
				});
			if (params.isIncludeWeather) {
				sheetRequests.push(createWeatherRequest(selectedProperties));
			}
			sheetRequests = splitRequestByTimeIntervals(sheetRequests, from, to, timezone);
			allRequests[0].requests = sheetRequests;

			const config = {
				type: 'report',
				subtype: 'raw-data',
				progressKey: progressKey,
				filename: filename,
				from: from,
				to: to,
				allTypesRequests: JSON.stringify(allRequests),
				selectedEquipments: JSON.stringify(currentSelectedElements),
				selectedProperties: JSON.stringify(selectedProperties),
				schema: JSON.stringify(schema),
				'Missing-Updates-Default': 'raw',
				'Filter-Special-Mark-Values': true,
				maxRequestFails: 3,
				timezone: timezone,
				reportFormat: reportFormat,
				includeDataWithErrors: includeDataWithErrors,
				token: token,
			};

			return $q.resolve(config);
		}

		function getComponentParentID({tisObjectTypeProperties, tisObjectIds = [], equipmentsList}) {
			const findElement = (inputArray, idToFind) => {
				let foundID = null;
				const iteratingFunction = (inputArray, idToFind, parentID = null) => {
					inputArray.some(item => {
						if (isComponent(item.tisObjectType) && item.tisObjectId === idToFind) {
							foundID = parentID;
							return true;
						} else if (item.children && item.children.length > 0) {
							iteratingFunction(item.children, idToFind, isComponent(item.tisObjectType) ? parentID : item.tisObjectId);
						}
					});
				};
				iteratingFunction(inputArray, idToFind);
				return foundID;
			};

			let resultingTisObjectIds = [];
			resultingTisObjectIds = tisObjectIds.map(tisObjectIDToFind => {
				return findElement(equipmentsList, tisObjectIDToFind);
			});

			return resultingTisObjectIds;
		}
		function fixRequestEndTime(endTime) {
			const now = moment().tz(endTime.tz());

			if (moment(endTime).isAfter(now)) {
				endTime = now;
			} else if (moment(endTime).isBefore(now)) {
				endTime = moment(endTime).subtract(1, 'seconds');
			}

			return endTime;
		}

		function createWeatherRequest(selectedProperties) {
			let weatherPropertiesForRequest = selectedProperties.filter(function(property) {
				return !property.tisObjectType;
			});
			let tisObjectIds = Object.values(tisObjectIdsByTisObjectType).reduce((acc, {tisObjectType, tisObjectIds}) => {
				// Filter out children from the weather request.
				if (!isComponent(tisObjectType)) {
					acc.push(...tisObjectIds);
				}

				return acc;
			}, []);

			weatherPropertiesForRequest.forEach(function(prop) {
				prop.hpath = createPropertyHpath(prop);
			});
			return {
				propertiesForRequest: getPropertiesForRequest(weatherPropertiesForRequest),
				tisObjectIds,
			};
		}

		function getTimeIntervals(from, to, maxRangeInDays, intervals) {
			intervals = intervals || [];

			if (to.diff(from, 'days') <= maxRangeInDays) {
				intervals.push({
					from: from,
					to: to,
				});
			} else {
				let transitiveTo = from.clone().add(maxRangeInDays, 'days');
				intervals.push({
					from: from,
					to: transitiveTo,
				});
				getTimeIntervals(transitiveTo, to, maxRangeInDays, intervals);
			}
			return intervals;
		}

		function splitRequestByTimeIntervals(requests, from, to, timezone) {
			let timeIntervals = [{from, to}];
			const resultingSheetsArray = [];

			timeIntervals.forEach(timeInterval => {
				resultingSheetsArray.push(
					requests.map(request => {
						return {
							propertiesForRequest: request.propertiesForRequest,
							tisObjectIds: request.tisObjectIds,
							from: timeInterval.from.tz(timezone).format(),
							to: fixRequestEndTime(timeInterval.to.tz(timezone)).format(),
						};
					})
				);
			});
			return resultingSheetsArray;
		}

		function separatePropertiesByTisObjectType(properties) {
			let tisObjectTypeProperties = {};

			properties.forEach(function(property) {
				let tisObjectType = property.tisObjectType ? property.tisObjectType.tisObjectTypeGroupName : 'weather';
				let typeData = tisObjectTypeProperties[tisObjectType];

				if (typeof typeData !== 'object') {
					typeData = tisObjectTypeProperties[tisObjectType] = [];
				}
				if (typeData.indexOf(property) === -1) {
					typeData.push(property);
				}
			});
			return tisObjectTypeProperties;
		}

		function createEnumerationStr(item) {
			let prefix = item.isCharacteristic ? '~' : '@';
			prefix = item.typeName !== null ? '//' + item.typeName + prefix : prefix;
			return item.propertyEnumeration ? prefix + item.propertyName + '=' + item.propertyEnumeration : '';
		}

		function createUomStr(item) {
			let prefix = item.isCharacteristic ? '~' : '@';
			prefix = item.typeName !== null ? '//' + item.typeName + prefix : prefix;
			return item.unitOfMeasureName ? prefix + item.propertyName + '=' + item.unitOfMeasureName : '';
		}

		function getWorksheetForSelectedPropertiesSingleFormat(selectedEquipments) {
			let schemaIds = {};
			let worksheet = {
				schema: [],
				column: [{wch: 13}, {wch: 13}],
			};
			addDefaultColumnsToExcelWorksheetSingleFormat(worksheet);

			selectedEquipments
				.sort((a, b) => {
					return a.value.localeCompare(b.value);
				})
				.forEach(function(equipment) {
					let equipmentIdInSchema = worksheet.schema.push(equipment.tisObjectId);
					schemaIds[equipment.tisObjectId] = equipmentIdInSchema - 1;
					worksheet.column.push({wch: 7});
				});
			return {schemaIds, worksheet};
		}

		function getWorksheetForSelectedPropertiesMultiFormat(selectedProperties) {
			let schemaIds = {};
			let worksheet = {
				schema: [],
				column: [{wch: 13}, {wch: 13}, {wch: 20}],
			};

			addDefaultColumnsToExcelWorksheetMultiFormat(worksheet);

			selectedProperties.forEach(function(property) {
				const propertyIdInSchema = worksheet.schema.push(property.value);
				const instance = property.instance || '';
				let key = `${property.propertyName}${instance}`;

				if (property.tisObjectType && property.tisObjectType.tisObjectTypeGroupName) {
					key = `${property.tisObjectType.tisObjectTypeGroupName}:${property.propertyName}${instance}`;
				}

				schemaIds[key] = propertyIdInSchema - 1;
				worksheet.column.push({wch: 7});
			});
			return {schemaIds, worksheet};
		}

		function getPropertiesForRequest(selectedProperties) {
			const propertiesForRequest = [];

			selectedProperties.forEach(function(property) {
				let enumeration = false;
				let unitOfMeasure = false;

				if (property.propertyEnumeration) {
					enumeration = createEnumerationStr(property);
				}
				if (property.unitOfMeasureName) {
					unitOfMeasure = createUomStr(property);
				}
				const hpath = property.tisObjectType && isComponent(property.tisObjectType) ? `//${property.typeName}${property.hpath}` : property.hpath;

				propertiesForRequest.push({
					hpath,
					enumeration: enumeration,
					unitOfMeasure: unitOfMeasure,
				});
			});
			return filterPropertiesForRequest(propertiesForRequest);
		}

		function filterPropertiesForRequest(properties) {
			let filteredProperties = [];

			properties.forEach(function(property) {
				let isAdded = filteredProperties.some(function(propertyForRequest) {
					return (
						property.hpath === propertyForRequest.hpath &&
						property.enumeration === propertyForRequest.enumeration &&
						property.unitOfMeasure === propertyForRequest.unitOfMeasure
					);
				});
				if (!isAdded) {
					filteredProperties.push(property);
				}
			});
			return filteredProperties;
		}

		function addDefaultColumnsToExcelWorksheetMultiFormat(excelWorksheet) {
			excelWorksheet.schema.push(translate('PROPERTY_DATE'));
			excelWorksheet.schema.push(translate('PROPERTY_TIME'));
			excelWorksheet.schema.push(translate('EQUIPMENT_NAME'));
		}

		function addDefaultColumnsToExcelWorksheetSingleFormat(excelWorksheet) {
			excelWorksheet.schema.push(translate('PROPERTY_DATE'));
			excelWorksheet.schema.push(translate('PROPERTY_TIME'));
		}

		function createPropertyHpath(property) {
			let sourcePrefix = property.sourcePrefix ? property.sourcePrefix + '::' : '';
			let namePrefix = property.isCharacteristic ? '~' : '@';
			let fullPrefix = sourcePrefix + namePrefix;

			return fullPrefix + property.propertyName;
		}

		/*
	Adding a special cookie, for load balancers to preserve request batch
	 */
		function setBalancerCookie(progressKey) {
			$cookies.put(DEFAULTS.LOAD_BALANCER_COOKIE_NAME, progressKey);
		}

		function sendExportRequest(data) {
			setBalancerCookie(data.progressKey);
			return $http.post(API_ENDPOINTS.tisExport, data, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				withCredentials: true,
			});
		}

		function isComponent(tisObjectType) {
			const componentKeyName = 'Component';

			return tisObjectType && tisObjectType.tisObjectTypeClassification === componentKeyName;
		}
	});
