/* global EventEmitter*/
import {
	AHU_FILTER_CONFIG_OBJ_CHART,
	SPACE_HUMIDIFICATION_CONTROL,
	SPACE_DEHUMIDIFICATION_CONTROL,
	SUPPLY_AIR_TEMPERATURE_CONTROL,
	FILTER_CHECK,
} from '../../../common/usage-tracking/common/ahu-filters';
(function() {
	const services = new WeakMap();
	const eventListeners = new WeakMap();
	const VAV_BOX_TIS_OBJECT_TYPE_GROUP_NUMBER = 21;

	/**
	 * (Mutable) function to check each chiller and its associated components(Circuit, Compressor) are standalone or not
	 *
	 * @param {*} component - component object
	 * @param {*} type - store unique key name to indenfiy the component is standalone or not
	 * @param {*} parent - parent object
	 * @param {*} isRoot - Root component (CWS, VAS, Standalone-components)
	 */

	function validateComponentHasSingleOrMultipleInstance(tisObject, type, parent, isRoot) {
		const {tisObjectType = {}, tisObjectId, children = []} = tisObject;
		const {tisObjectTypeClassification, tisObjectTypeGroupName, tisObjectTypeGroupNumber} = tisObjectType;
		const key = tisObjectTypeClassification + '--' + tisObjectTypeGroupName + '--' + tisObjectTypeGroupNumber;

		// conditions to check
		const isComponent = tisObjectTypeClassification === 'Component';
		const isCompressor = tisObjectTypeGroupName === 'Compressor';
		const isCircuit = tisObjectTypeGroupName === 'Circuit';

		// Unique key mapper
		if (isComponent && !type[key]) {
			type[key] = {
				[tisObjectId]: true,
				isStandaloneInstance: true,
				// Storing the tisObject at first time.
				// So then next iteration scope use this object and update the standalone status in stored tisObject incase if its not standalone.
				prevTisObject: tisObject,
			};
		} else if (isComponent && type[key]) {
			const value = type[key][tisObjectId];
			if (!value) {
				type[key].isStandaloneInstance = false;
			}
		}

		const isStandaloneInstance = (type[key] || {}).isStandaloneInstance;

		// update only if its isCircuit object
		if (isComponent && isCircuit && type[key] && !isRoot) {
			tisObject['isStandaloneCircuitInstance'] = isStandaloneInstance;
			if (type[key].prevTisObject) {
				type[key].prevTisObject['isStandaloneCircuitInstance'] = isStandaloneInstance;
			}

			// update parent about circuits count (single / multiple)
			if (parent) {
				parent['hasMultipleCircuitInstance'] = !isStandaloneInstance;
			}
		}

		if (children.length > 0) {
			updateObjectStandaloneStatus(children, {}, tisObject);
		}

		// update parent about compressors count (single / multiple)
		const hasMultipleCompressor = tisObject['hasMultipleCompressorInstances'];

		if (isComponent && isCircuit && type[key] && !isRoot && parent && hasMultipleCompressor !== undefined) {
			const isPrevCircuitHasMultipleCompressors = (type[key].prevTisObject || {})['hasMultipleCompressorInstances'];

			if (isPrevCircuitHasMultipleCompressors === true) {
				parent['hasMultipleCompressorInstances'] = true;
			} else {
				const isPrevCircuitSingleCompressor =
					parent['hasMultipleCompressorInstances'] !== undefined && parent['hasMultipleCompressorInstances'] === false ? true : false;
				parent['hasMultipleCompressorInstances'] = isPrevCircuitSingleCompressor
					? true
					: parent['hasMultipleCompressorInstances'] || hasMultipleCompressor;
			}
		}

		// update only if its compressor object
		if (isComponent && isCompressor && type[key] && !isRoot) {
			tisObject['isStandaloneCompressorInstance'] = isStandaloneInstance;
			if (type[key].prevTisObject) {
				type[key].prevTisObject['isStandaloneCompressorInstance'] = isStandaloneInstance;
			}

			if (parent) {
				parent['hasMultipleCompressorInstances'] = !isStandaloneInstance;
			}

			// Need to store parent circuit details in compressor object to identify circuit is standalone or not for a compressor
			if (parent)
				tisObject['parentCircuitDetails'] = {
					get isStandaloneCircuitInstance() {
						return parent.isStandaloneCircuitInstance;
					},
				};
		}
	}

	/**
	 * (Mutable) function update object is standalone or not
	 *
	 * @param {*} datas
	 * @param {*} type
	 * @param {*} parent
	 * @param {*} isRoot
	 */
	function updateObjectStandaloneStatus(datas = [], type, parent, isRoot) {
		datas.forEach(tisObject => validateComponentHasSingleOrMultipleInstance(tisObject, type, parent, isRoot));
	}

	function filteringAHUCharts(filterParamsCriteria) {
		const humidityFilterCriteria = [];
		const humidityFilterConstants = [SPACE_HUMIDIFICATION_CONTROL, SPACE_DEHUMIDIFICATION_CONTROL];

		for (let humidityFilter of humidityFilterConstants) {
			for (let criteria of filterParamsCriteria) {
				if (criteria.name === humidityFilter) {
					humidityFilterCriteria.push(criteria.name);
				}
			}
		}

		return humidityFilterCriteria;
	}

	class ChartBlockController {
		constructor(
			$rootScope,
			modalHelperService,
			locationEquipmentService,
			chartService,
			configService,
			$timeout,
			$translate,
			$filter,
			$sessionStorage,
			locationDetailsService,
			helpers,
			subtypeFilterService,
			serviceAdvisoryService,
			CONTROLLER_CHART_TYPES,
			objectTypeService,
			tisObjectService,
			stateBasedHpathService,
			instanceBasedChartService
		) {
			services.set(this, {
				modalHelperService,
				locationEquipmentService,
				chartService,
				$timeout,
				$translate,
				$filter,
				helpers,
				locationDetailsService,
				subtypeFilterService,
				serviceAdvisoryService,
				CONTROLLER_CHART_TYPES,
				objectTypeService,
				tisObjectService,
				stateBasedHpathService,
				instanceBasedChartService,
			});
			this.featureToggles = configService.getFeatureToggles();
			this.hideChartHeader = $rootScope.hideChartHeader;
			this.isEquipmentListLoading = true;
			this.isChartListLoading = false;
			this.facilityTypeObj = {
				type: 'FACILITY',
				tisObjectTypeNumber: 'facility',
			};
			this.boilersList = $sessionStorage.boilersList || [];
			this.selectedBoiler = {};
			locationDetailsService.getLocationDetailsWithoutServiceAdvisories(this.chartObj.locationId).then(data => {
				this.chartObj.selectedLocation = data.locationSummaryList[0];
				this.chartObj.selectedFacilities = this.chartObj.selectedFacilities || [this.chartObj.selectedLocation];
			});

			let locationIds = this.chartObj.locationId;
			if (this.chartObj.selectedFacilities && this.chartObj.selectedFacilities.length) {
				locationIds = this.chartObj.selectedFacilities.map(f => f.locationId);
			}
			this.loadEquipments(locationIds);

			this.$onInit = () => {
				if (!this.eventObject) {
					this.eventObject = new EventEmitter();
				}
				$timeout(() => {
					this.equipmentSelected = true;
				});
			};

			this.$onDestroy = () => {
				this.range.unSubscribeAll();
			};
		}

		setChartReady() {
			this.setChartReady();
		}

		async getAHUFilterDetails() {
			const {objectTypeService, tisObjectService} = services.get(this);
			const TIMEZONE = 'UTC';
			const AHU_CONFIG = 1;
			const INTERVAL = 'pt1440m';
			const DATE_FORMAT = 'YYYY-MM-DDTHH:00:00';
			const DATE_CONVERSION = 24 * 60 * 60 * 1000;
			const {equipmentId} = this.chartObj;
			return objectTypeService.getObjectCharacteristics(AHU_CONFIG).then(function(data) {
				const propNames = [];
				for (let tisObject of data.data.analyticParameters) {
					propNames.push(tisObject.parameterName);
				}
				const queryParams = {
					hpath: `~${propNames.join(',~')}`,
					startDate: moment(new Date().toJSON()).format(DATE_FORMAT),
					endDate: moment(new Date(new Date().getTime() + DATE_CONVERSION).toJSON()).format(DATE_FORMAT),
					ids: [equipmentId],
					timeZone: TIMEZONE,
					interval: INTERVAL,
				};

				return tisObjectService.getAHUValues(queryParams).then(data => data);
			});
		}

		updateVavBoxEquipment(obj) {
			const {$filter} = services.get(this);
			const equipment = angular.copy(obj);
			equipment.tisObjectName = $filter('translate')('EQUIPMENT_TYPE_NAME_TABLE.VAVBoxes') + equipment.tisObjectName;
			equipment.order = 0;
			// Filter out AHUs from VAS children list
			if (equipment.children && equipment.children.length > 0) {
				equipment.children = equipment.children.filter(function(item) {
					return item.tisObjectType.tisObjectTypeGroupName !== 'AHU';
				});
			}
			return equipment;
		}

		getSelectedTypeLabel() {
			const {$translate} = services.get(this);
			const t = this.chartObj.selectedEquipmentType;
			return $translate(t ? 'EQUIPMENT_TYPE_NAME_TABLE.' + t.type : 'OPTIONS_SELECT_ONE');
		}

		setChartWithAddPropsSupportHandler(value) {
			this.setChartWithAddPropsSupport({value: value});
		}

		loadEquipments(locationIds) {
			const {locationEquipmentService, chartService} = services.get(this);
			let promises = [];

			if (!(locationIds instanceof Array)) {
				locationIds = [locationIds];
			}
			promises = locationIds.map(locationId => locationEquipmentService.getLocationObjectsList(locationId, null, true));

			return Promise.all(promises).then(data => {
				data = data.reduce((tisObjectList, value) => {
					tisObjectList = tisObjectList.concat(value.tisObjectList);
					return tisObjectList;
				}, []);

				updateObjectStandaloneStatus(data, {}, null, true);

				this.tisObjectList = data || [];
				this.equipmentList = chartService.addUniqueTisObjectToList(data, [], []) || [];
				const uniqueTypeList = chartService.getUniqueTypeList(this.equipmentList);

				this.groupedEquipments = [];
				if (this.equipmentTypeChart) {
					this.updateEquipmentTypeList(uniqueTypeList);
					// Refactor this. We have to place event listeners in component constructor
					// Probably we need to check this.groupedEquipments inside event ?
					if (this.eventObject && typeof this.eventObject.on === 'function') {
						const listeners = eventListeners
							.set(this, {
								$translateChangeSuccessListener: this.updateTranslations.bind(this.groupedEquipments),
							})
							.get(this);

						this.eventObject.on('$translateChangeSuccess', listeners.$translateChangeSuccessListener);
					}
					this.$onDestroy = () => {
						if (this.eventObject && typeof this.eventObject.off === 'function') {
							const listeners = eventListeners.get(this);
							this.eventObject.off('$translateChangeSuccess', listeners.$translateChangeSuccessListener);
						}
					};
				} else {
					this.updateEquipmentInstancesList(uniqueTypeList, locationIds);
				}

				this.isEquipmentListLoading = false;
			}, this.errorCallback.bind(this));
		}

		updateTranslations(data) {
			this.updateLabelTranslations();
			this.updateEquipmentTypeListTranslations(data);
			this.updateOptionFacilityTranslations();
		}

		updateLabelTranslations() {
			const {$translate} = services.get(this);

			this.defaultEquipmentType.name = $translate('OPTIONS_SELECT_ONE');
		}

		updateEquipmentTypeListTranslations(data) {
			const {$translate} = services.get(this);

			for (let i = data.length; i--; ) {
				data[i].name = $translate('EQUIPMENT_TYPE_NAME_TABLE.' + data[i].type);
			}
		}

		updateOptionFacilityTranslations() {
			const {$translate} = services.get(this);

			this.optionFacility.name = $translate('CHART_OPTIONS_FACILITY');
		}

		updateEquipmentTypeList(typesList) {
			const {$translate} = services.get(this);
			let equipmentType;

			typesList.forEach(type => {
				let tisObjectTypeNumber;
				this.equipmentList.some(item => {
					if (type === item.tisObjectType.tisObjectTypeGroupName) {
						tisObjectTypeNumber = item.tisObjectType.tisObjectTypeGroupNumber;
						return true;
					}
				});
				this.groupedEquipments.push({
					name: $translate('EQUIPMENT_TYPE_NAME_TABLE.' + type),
					type,
					tisObjectTypeNumber,
				});
			});

			if (this.chartObj.equipmentType && this.equipmentList.length) {
				this.groupedEquipments.some(item => {
					if (this.chartObj.equipmentType === item.type) {
						equipmentType = item;
						return true;
					}
				});
				if (equipmentType) {
					this.setEquipmentType(equipmentType);
				} else {
					this.showErrorMessage($translate('EQUIPMENT_TYPE_DOES_NOT_EXIST', {equipmentType: this.chartObj.equipmentType}));
				}
			} else if (this.equipmentList.length) {
				// if only one equipment present show it
				// otherwise show select label
				if (this.equipmentList.length === 1) {
					this.setEquipmentType(this.equipmentList[0]);
				} else {
					this.setEquipmentType();
				}
			} else {
				this.showErrorMessage($translate('FACILITY_NO_EQUIPMENT'));
			}
		}

		updateEquipmentInstancesList(typesList, locationIds) {
			const {$filter, chartService, $translate} = services.get(this);

			let equipment;
			let vavBoxEquipments = [];
			// let vavBoxEquipmentsIds = [];
			let vasChildren = [];
			let vasChildrenIds = [];
			let vavBoxesStandAlone = [];

			typesList.forEach(type => {
				const objects = this.equipmentList.reduce((av, item) => {
					if (item.tisObjectType.tisObjectTypeGroupName === type) {
						av.push({...item});
					}
					return av;
				}, []);

				if (objects.length) {
					const tisObjectTypeNumber = objects[0].tisObjectType.tisObjectTypeGroupNumber;
					objects.forEach(object => {
						const groupName = object.tisObjectType.tisObjectTypeGroupName;

						if (groupName === 'VAS' && object.children) {
							// vavBoxEquipmentsIds.push(object.tisObjectId);
							vavBoxEquipments.push(this.updateVavBoxEquipment(object));
							vasChildren = vasChildren.concat(object.children);
						}

						// To support instance level chart (ex : Motor power performance)
						object.childObjects = (object.children || []).length ? (object.children || []).slice() : null;

						object.children = null;
					});
					if (type !== 'VAV-BOX') {
						this.groupedEquipments.push({type, tisObjectTypeNumber, objects});
					} else {
						vavBoxesStandAlone = objects;
					}
				}
			});

			vasChildrenIds = vasChildren.map(vasChild => {
				return vasChild ? vasChild.tisObjectId : null;
			});
			vavBoxesStandAlone = vavBoxesStandAlone.filter(vavBoxStandAlone => {
				return vasChildrenIds.indexOf(vavBoxStandAlone.tisObjectId) === -1;
			});
			if (vavBoxesStandAlone.length !== 0) {
				vavBoxEquipments.push({
					children: vavBoxesStandAlone,
					tisObjectName: $filter('translate')('EQUIPMENT_TYPE_NAME_TABLE.VAVBoxes:Standalone'),
					order: 1,
				});
			}
			if (vavBoxEquipments.length !== 0) {
				// We need to explicitly set tisObjectTypeNumber for VAV-BOX because its child objects have different tisObjectTypeNumber
				this.groupedEquipments.push({
					tisObjectTypeNumber: VAV_BOX_TIS_OBJECT_TYPE_GROUP_NUMBER,
					type: 'VAV-BOX',
					objects: vavBoxEquipments,
				});
			}

			if (this.chartObj.equipmentId && this.equipmentList.length) {
				this.equipmentList.some(item => {
					if (this.chartObj.equipmentId === item.tisObjectId) {
						equipment = item;
						return true;
					}
				});
				if (equipment) {
					this.setEquipment(equipment, true);
				} else {
					this.showErrorMessage($translate('EQUIPMENT_DOES_NOT_EXIST', {equipmentId: this.chartObj.equipmentId}));
				}
			} else if (this.equipmentList.length) {
				// if only one equipment present show it
				// otherwise show select label
				if (this.equipmentList.length === 1) {
					this.setEquipment(this.equipmentList[0], true);
				} else {
					this.setEquipment();
				}
			} else {
				this.showErrorMessage($translate('FACILITY_NO_EQUIPMENT'));
			}

			if (this.isFacilityChart) {
				chartService.getAllFacilityCharts(locationIds).then(this.initFacilityEqTypeDropdown.bind(this, this.groupedEquipments));
			} else {
				chartService.getAllCharts().then(this.filterEquipmentDropDownWithChartData.bind(this, this.groupedEquipments));
			}
		}

		selectEquipment(item) {
			const {$timeout, chartService, instanceBasedChartService} = services.get(this);

			item = instanceBasedChartService.restoreChildrenDetailsFromTempProperty(item);

			$timeout(() => {
				this.equipmentSelected = false;
			});
			const selectedGroupNumber = item ? item.tisObjectType.tisObjectTypeGroupNumber : null;

			chartService
				.getAvailableCharts(selectedGroupNumber, item)
				.then(chartList => this.filterChartsByTisObjectSubType(chartList, item))
				.then(chartList => {
					this.chartList = chartList;

					const {isEquipmentMatchedToSelectedChart, selectedChartDetails} = instanceBasedChartService.getSelectedChartDetailsAndEquipmentMatchStatus(
						this.chartObj,
						chartList
					);

					if (item.tisObjectType.tisObjectTypeGroupNumber === selectedGroupNumber && isEquipmentMatchedToSelectedChart) {
						this.updateEquipment(item);
						selectedChartDetails && this.setChartObjectProperty({name: 'selectedChart', value: selectedChartDetails});
					} else {
						const forceClear = !isEquipmentMatchedToSelectedChart;
						this.clearChartsData(item, forceClear);
						this.setEquipment(item);
					}

					$timeout(() => {
						this.equipmentSelected = true;
						$timeout(() => {
							this.eventObject.emit('reloadChart');
						}, 300);
					});
				});
		}

		selectFacility(item) {
			const selectedLocation = this.chartObj.selectedLocation;
			if (selectedLocation.locationId === item.locationId) return;

			this.setChartLocation({location: item});
			this.setChartObjectProperty({name: 'selectedLocation', value: item});
			this.chartObj.selectedEquipment = null;
			this.setChartObjectProperty({name: 'tisObjectName', value: null});
			this.setChartObjectProperty({name: 'equipmentId', value: null});
			this.setChartObjectProperty({name: 'locationId', value: item.locationId});
			this.setChartObjectProperty({name: 'selectedFacilities', value: [item]});
			this.isEquipmentListLoading = true;
			this.loadEquipments(item.locationId);
			this.clearChartsData();
			this.isChartListLoading = false;
		}

		setFacilities(list) {
			this.setChartObjectProperty({name: 'selectedFacilities', value: list});
			this.isEquipmentListLoading = true;
			this.loadEquipments(list.map(facility => facility.locationId)).then(() => {
				if (
					!this.chartObj.selectedEquipmentType ||
					!this.groupedEquipments.find(equipment => equipment.type === this.chartObj.selectedEquipmentType.type)
				) {
					this.selectEquipmentType();
					this.setEquipment();
					this.isChartListLoading = false;
				}
			});
		}

		selectEquipmentType(item) {
			this.clearChartsData(item);
			this.setEquipmentType(item);
		}

		selectFacilityEquipmentType(item, initialChartId) {
			const {chartService, serviceAdvisoryService} = services.get(this);
			const locationIds = this.chartObj.selectedFacilities.map(facility => facility.locationId);

			this.clearChartsData();
			if (!this.groupedEquipments.find(equipment => equipment.type === item.type)) {
				this.setEquipmentType();
				this.isChartListLoading = false;
			} else {
				/**
				 * For Pareto Chart
				 * 	To display child equipments SAs along with Selected Root Equipment SAs in chart list,
				 * 	map child equipments tisObjectTypeGroupName with Root equipment type name
				 */
				const equipmentsWithChildren = {
					// Chiller: ['Circuit', 'Compressor'],	//Commenting to hide child equipment, can be enabled once API is ready.
					// Ex: VAS : [...,..,...]
				};
				const tisGroupName = item.type;

				this.setEquipmentType(item);
				chartService
					.getAllFacilityCharts(locationIds, tisGroupName, equipmentsWithChildren[tisGroupName])
					.then(chartData => serviceAdvisoryService.filterOutIntermediateTests(chartData))
					.then(this.initChartDropdownForFacility.bind(this, initialChartId));
			}
		}

		selectAllEquipmentTypes() {
			this.clearChartsData();
			this.setAllEquipmentTypes();
		}

		setAllEquipmentTypes() {
			this.chartObj.selectedEquipmentType = this.optionFacility;
			// TODO: collect charts for the whole facility
		}

		initChartDropdownForFacility(selectedItemId, chartData) {
			this.chartList = chartData;
			this.isChartListLoading = false;

			if (selectedItemId) {
				for (let i = 0; i < chartData.length; i++) {
					let isSelectedChart = chartData[i].chartId === selectedItemId;
					if (this.isParetoChart) {
						isSelectedChart = chartData[i].serviceAdvisoryTypeId === selectedItemId;
					}
					if (isSelectedChart) {
						this.setChart(chartData[i]);
						break;
					}
				}
			}
		}

		setEquipment(item, isPageOnload) {
			const {chartService} = services.get(this);

			if (typeof item === 'undefined') {
				this.chartObj.selectedEquipment = null;
			} else {
				this.updateEquipment(item, isPageOnload);

				item = this.lookForParent(item, isPageOnload);
				this.tisObjectTypeGroupNumber = item.tisObjectType.tisObjectTypeGroupNumber;

				chartService
					.getAvailableCharts(this.tisObjectTypeGroupNumber, item)
					.then(this.filterChartsByTisObjectSubType.bind(this))
					.then(this.updateCharts.bind(this))
					.catch(this.errorCallback.bind(this));
			}
		}

		// A function to find the parent equipment of a given equipment and a parent group name
		findParent(equip, parentGroupName) {
			const parentEquip = this.equipmentList.find(equipment => equipment.tisObjectId === equip.parentId);
			if (parentEquip.tisObjectType.tisObjectTypeGroupName === parentGroupName) {
				return parentEquip;
			}
			return this.findParent(parentEquip, parentGroupName);
		}

		// A function to look for the parent equipment of a given item and update it if needed
		lookForParent(item, isPageOnload) {
			const lookUpParentArray = [
				{
					parent: 'CDWP',
					children: ['CT', 'CTC', 'FAN'],
				},
				// Add config for future use
			];

			const lookUpParent = lookUpParentArray.find(i => i.children.includes(item.tisObjectType.tisObjectTypeGroupName));
			if (lookUpParent) {
				const parentEquip = this.findParent(item, lookUpParent.parent);
				this.updateEquipment(parentEquip, isPageOnload);
				return parentEquip;
			}
			return item;
		}

		async filterChartsByTisObjectSubType(chartList = [], equipment = null) {
			const {subtypeFilterService, stateBasedHpathService} = services.get(this);

			for (let boiler of this.boilersList) {
				if (this.chartObj.selectedEquipment.tisObjectId === boiler.tisObjectId) {
					this.selectedBoiler = {...boiler};
				}
			}
			const {applicationType} = this.selectedBoiler;
			if (applicationType) {
				return subtypeFilterService.getBoilerChartsFilter(applicationType).then(chartIds => {
					if (chartIds && chartIds.length) {
						chartList.filter(chart => chartIds.includes(chart.chartId));
						return chartList.filter(chart => !chartIds.includes(chart.chartId));
					} else {
						return chartList;
					}
				});
			}
			const {tisObjectId, tisObjectSubType, tisObjectClassificationType, hasMultipleCircuitInstance, hasMultipleCompressorInstances, tisObjectType} =
				equipment || this.chartObj.selectedEquipment;

			if (tisObjectId && tisObjectSubType && tisObjectClassificationType) {
				// storing chiller compressor details for validating custom path at getChartMetaData fn
				const {tisObjectTypeGroupName} = tisObjectType;

				stateBasedHpathService.chillerState = {
					tisObjectSubType,
					tisObjectClassificationType,
					hasMultipleCircuitInstance,
					hasMultipleCompressorInstances,
					tisObjectTypeGroupName,
				};
				return subtypeFilterService.getChartsFilter(tisObjectId, tisObjectSubType, tisObjectClassificationType).then(chartIds => {
					return chartList.filter(chart => {
						return !chartIds.includes(chart.chartId);
					});
				});
			} else {
				if (this.chartObj.selectedEquipment.tisObjectType.tisObjectTypeGroupName === 'AHU') {
					const filterDetailsAHU = await this.getAHUFilterDetails();
					const {tisObjectDataList} = filterDetailsAHU;
					const filterParametersAHU = tisObjectDataList[0].parameters
						.map(({name, values}) => ({name, filterValues: values.map(v => v.value)}))
						.filter(({filterValues}) => filterValues.length > 0);
					let filterParamsCriteria = filterParametersAHU
						.map(({name, filterValues}) => ({name, filterStr: filterValues.join('')}))
						.filter(({name, filterStr}) => filterStr.includes(FILTER_CHECK) && name !== SUPPLY_AIR_TEMPERATURE_CONTROL);
					const humidityFilterCriteria = filteringAHUCharts(filterParamsCriteria);

					if (humidityFilterCriteria.length === 1) {
						filterParamsCriteria = filterParamsCriteria.filter(({name}) => name !== humidityFilterCriteria[0]);
					}
					const automatedTestsToFilter = filterParamsCriteria.flatMap(({name}) => AHU_FILTER_CONFIG_OBJ_CHART[name]);
					return chartList.filter(chart => {
						if (!automatedTestsToFilter.includes(chart.title)) {
							return chart;
						}
					});
				} else {
					return chartList;
				}
			}
		}

		// To support instance level chart (ex : Motor power performance)
		updateInstanceDetails(item) {
			this.setChartObjectProperty({name: 'selectedInstanceEquipmentType', value: item.selectedInstanceEquipmentType || null});
			this.setChartObjectProperty({name: 'instanceName', value: item.instanceName || null});
			this.setChartObjectProperty({name: 'instanceId', value: item.instanceId || null});
		}

		updateEquipment(item, isPageOnload) {
			const {instanceBasedChartService} = services.get(this);

			// To support instance level chart (ex : Motor power performance)
			const instanceDetails = instanceBasedChartService.getInstanceDetails(this.chartObj, item, isPageOnload);

			instanceDetails && this.updateInstanceDetails(instanceDetails);

			const equipmentIsComponentObj = item.tisObjectType.tisObjectTypeClassification === 'ComponentObject';
			this.setChartObjectProperty({name: 'selectedEquipment', value: item});
			this.setChartObjectProperty({name: 'equipmentId', value: item.tisObjectId});
			this.setProperty({name: 'equipmentId', value: item.tisObjectId});
			this.setChartObjectProperty({name: 'equipmentIsComponentObj', value: equipmentIsComponentObj});
			this.updatePageTitle({title: item.tisObjectName});
		}

		setEquipmentType(item) {
			const {$timeout, CONTROLLER_CHART_TYPES} = services.get(this);
			if (typeof item === 'undefined') {
				this.chartObj.selectedEquipmentType = this.defaultEquipmentType;
			} else {
				this.chartObj.selectedEquipmentType = item;
				this.chartObj.equipmentType = this.tisObjectTypeGroupNumber = item.tisObjectTypeNumber;

				// :todo change url for the parent chart only
				if (item.type !== 'FACILITY') {
					$timeout(() => {
						this.eventObject.emit('setEquipmentType', {
							charttype: CONTROLLER_CHART_TYPES.PARETO_CHART,
							locationId: this.chartObj.selectedFacilities ? this.chartObj.selectedFacilities[0].locationId : this.chartObj.locationId,
							equipmentType: this.chartObj.equipmentType,
							serviceAdvisoryTypeId: this.chartObj.serviceAdvisoryTypeId,
							paretoChartSortOrder: this.chartObj.paretoChartSortOrder,
							selectedFacilities: this.chartObj.selectedFacilities,
						});
					});
				} else {
					$timeout(() => {
						this.eventObject.emit('setEquipmentType', {
							charttype: CONTROLLER_CHART_TYPES.FACILITY_CHART,
							locationId: this.chartObj.selectedFacilities ? this.chartObj.selectedFacilities[0].locationId : this.chartObj.locationId,
							equipmentType: this.chartObj.equipmentType,
							chartId: this.chartObj.chartId,
							selectedFacilities: this.chartObj.selectedFacilities,
						});
					});
				}
			}
		}

		updateCharts(chartList = []) {
			const {$translate, instanceBasedChartService} = services.get(this);

			if (this.chartObj.chartId && chartList.length) {
				// To support instance level chart (ex : Motor power performance)
				const filteredChartList = instanceBasedChartService.filterChartByInstance(this.chartObj, chartList);

				const chart = filteredChartList[0];

				if (chart) {
					this.setChart(chart);
				} else {
					this.showErrorMessage(
						$translate('CHART_DOES_NOT_EXIST', {
							chartId: this.chartObj.chartId,
						})
					);
				}
			} else if (chartList.length) {
				// if only one chart present show it, otherwise show select label
				this.setChart(chartList.length === 1 ? chartList[0] : undefined);
			} else {
				this.showErrorMessage($translate('EQUIPMENT_NO_CHARTS'));
			}
			this.chartList = chartList;
			this.isChartListLoading = false;
		}

		setChart(chart) {
			this.handleSetChart(chart, true);
		}

		handleSetChart(chart, notUpdateForInteralFunctionCall) {
			const {$timeout} = services.get(this);

			if (typeof chart === 'undefined') {
				this.setChartObjectProperty({name: 'selectedChart', value: null});
			} else {
				if (!this.chartObj.selectedChart) {
					this.changeLoadedCharts({value: 1});
				}
				if (this.isParetoChart) {
					this.setChartObjectProperty({name: 'serviceAdvisoryTypeId', value: chart.serviceAdvisoryTypeId});
				} else {
					this.setChartObjectProperty({name: 'chartId', value: chart.chartId});

					// To support instance level chart (ex : Motor power performance)
					// Update Instance values in chartObj when a chart is selected via chart dropdown
					if (!notUpdateForInteralFunctionCall) this.updateInstanceDetails(chart.instanceName ? chart : {});
				}
				this.setChartObjectProperty({name: 'selectedChart', value: chart});

				// We need to be sure that controller was loaded
				// before event broadcasting. Otherwise, chart will
				// not be loaded in case if we opened it as direct link.
				$timeout(() => {
					this.eventObject.emit('reloadChart');
				});
			}
		}

		setParetoChartSortOrder(sortOrder) {
			const {$timeout} = services.get(this);

			this.chartObj.paretoChartSortOrder = sortOrder;
			$timeout(() => {
				this.eventObject.emit('applySortOrder');
			});
		}

		clearChartsData(item, forceClear = false) {
			let selectedEquipment = this.chartObj.selectedEquipment;
			let selectedTypeName = selectedEquipment ? selectedEquipment.tisObjectType.tisObjectTypeGroupName : null;

			if (this.chartObj.selectedChart) {
				this.changeLoadedCharts({value: -1});
			}
			if (forceClear || !selectedTypeName || !item || item.tisObjectType.tisObjectTypeGroupName !== selectedTypeName) {
				this.setChartObjectProperty({name: 'chartId', value: null});
				this.setChartObjectProperty({name: 'selectedChart', value: null});
				this.isParetoChart && this.setChartObjectProperty({name: 'serviceAdvisoryTypeId', value: null});
				this.chartList = [];
				this.isChartListLoading = true;
				this.eventObject.emit('clearChart');
			}
		}

		initFacilityEqTypeDropdown(equipments, facilityCharts) {
			if (!this.featureToggles.paretoCharts) {
				this.groupedEquipments = [];
				this.setEquipmentType(this.facilityTypeObj);
			}

			if (Array.isArray(this.chartObj.selectedFacilities) && this.chartObj.selectedFacilities.length === 1) {
				this.groupedEquipments.push(this.facilityTypeObj);
			}

			this.filterEquipmentDropDownWithChartData(equipments, facilityCharts);

			if (this.chartObj.equipmentType === 'facility') {
				this.selectFacilityEquipmentType(this.facilityTypeObj, this.chartObj.chartId);
			} else {
				for (let i = 0; i < equipments.length; i++) {
					if (equipments[i].tisObjectTypeNumber === this.chartObj.equipmentType) {
						this.selectFacilityEquipmentType(equipments[i], this.chartObj.serviceAdvisoryTypeId);
						break;
					}
				}
			}
		}

		filterEquipmentDropDownWithChartData(equipments, charts) {
			const {helpers} = services.get(this);

			const existingChartTypes = helpers.reduceArrayToSet(charts, chart => {
				return chart.tisObjectType ? chart.tisObjectType.tisObjectTypeGroupName : 'FACILITY';
			});

			this.groupedEquipments = this.groupedEquipments.filter(equipmentType => {
				return existingChartTypes[equipmentType.type];
			});
		}

		showErrorMessage(message) {
			const {modalHelperService} = services.get(this);

			modalHelperService.open({
				templateUrl: 'common/messages/error-message.html',
				controller: 'ErrorMessageCtrl',
				backdrop: 'static',
				resolve: {
					details: () => {
						return message;
					},
				},
			});
		}

		errorCallback() {
			this.eventObject.emit('hideChart');
		}

		updateDrpDwnPositionHandler(event) {
			this.updateDrpDwnPosition({event});
		}

		enableChartRuller(rullerEnabled) {
			this.eventObject.emit('switchRuller', {rullerEnabled});
		}
	}

	angular.module('TISCC').component('chartBlock', {
		templateUrl: 'components/chart/chart-block-component/chart-block.html',
		controller: ChartBlockController,
		bindings: {
			eventObject: '<',
			chartConfig: '<',
			chartObj: '<',
			location: '<',
			equipment: '<',
			range: '<',
			equipmentTypeChart: '<',
			defaultEquipmentType: '<',
			optionFacility: '<',
			isParetoChart: '<',
			isFacilityChart: '<',
			isFacilityPerformanceChart: '<',
			isChartWithAddPropsSupport: '<',
			isChartLegendExpanded: '=',
			passedChartState: '<',
			isIncludeDataWithErrors: '<',
			searchObj: '<', // looks like I need to remove this line
			setChartWithAddPropsSupport: '&',
			setChartObjectProperty: '&',
			setChartLocation: '&',
			setProperty: '&',
			updatePageTitle: '&',
			changeLoadedCharts: '&',
			updateDrpDwnPosition: '&',
			addChart: '&',
			removeChart: '&',
			canAddChart: '<',
			canRemoveChart: '<',
			setChartReady: '&',
			chartIndex: '<',
			updateSyncStatus: '&',
			canSyncChart: '<',
			showPrintExportDialog: '&',
		},
	});
})();
