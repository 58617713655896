export const GA_ACTION_NAMES = {
	NAVIGATION: {
		NAVIGATION: 'NAVIGATION',
		AUTH_ERROR: 'Authorization error',
	},
	API: 'API',
	RDR: {
		RDR: 'RDR',
		SELECT_DATE: 'Select Date',
		SELECT_OBJECTS: 'Select Objects',
		START_DATE: 'Start Date',
		END_DATE: 'End Date',
		OBJECT_INSTANCE: 'Object Instance',
		OBJECT_TYPE: 'Object Type',
		SELECT_ALL: 'Selected all properties',
		DESELECT_ALL: 'Deselected all properties',
	},
	CPR: {
		CPR: 'CPR',
		GENERATION: 'generation',
		SELECT_DATE: 'Select Date',
		INCLUDED_SECTIONS: 'Included Sections',
		SECTION_NAME: 'Section',
		START_DATE: 'Start Date',
		END_DATE: 'End Date',
		GENERATION_STARTED: 'Generation started',
		GENERATION_DONE: 'Generation done',
		GENERATION_FAILED: 'Generation failed',
		SECTION_DATA_CHANGED: 'Section data changed',
		SECTION_DATA_CHANGED_MSG: 'Data was changed for the section',
	},
	FS: {
		FS: 'Facility summary',
		NAVIGATE_TO_FS: 'Navigate to  Facility Summary page',
		USE_ACTION_MENU: 'Navigate to Actions Menu item',
		FACILITY_NAME: 'Facility name',
		ACTION_ITEM_NAME: 'Action item name',
	},
	NAV: {
		NAV: 'Navigation',
		SEARCH_USED: 'Search used',
		CONNECTIVITY_STATUS_CLICKED: 'Connectivity status clicked',
		VIEW_CHANGED: 'View changed',
	},
	CHARTS: {
		CHARTS: 'Charts',
		CHART: 'Chart',
		CHART_SELECTED_FROM_DROPDOWN: 'Chart selected from dropdown',
		CHART_ACCESSED_FROM_SERVICE_ADVISORY: 'Chart accessed from service advisory link',
	},
};
