function* generateIndex() {
	for (let i = 0; i <= 200; i++) {
		yield i;
	}
}

const indexGenerator = generateIndex();
const services = new WeakMap();

export class CprChartComponentController {
	constructor($element, translateService, helpers, $translate, $filter, CPR_REPORT_SECTIONS) {
		services.set(this, {translateService, $translate, $filter});

		this.$element = $element;
		this.chartIndex = indexGenerator.next().value;
		this.isNumber = helpers.isNumber;
		this.CPR_REPORT_SECTIONS = CPR_REPORT_SECTIONS;
		this.addLines = [];
	}

	$onInit() {
		const {translateService, $translate} = services.get(this);
		const {$element} = this;

		if (this.chartData && this.chartData.axes) {
			const data = Object.values(this.chartData.axes).filter(axisData => {
				return axisData.type && axisData.type !== 'hidden';
			});

			if (typeof this.chartData.yTicsGenerator === 'function') {
				this.yTicsGenerator = this.chartData.yTicsGenerator;
			} else {
				this.yTicsGenerator = function*() {
					yield undefined;
				};
			}

			if (typeof this.chartData.xTicsGenerator === 'function') {
				this.xTicsGenerator = this.chartData.xTicsGenerator;
			} else {
				this.xTicsGenerator = function*() {
					yield undefined;
				};
			}

			if (typeof this.chartData.xTicFormat === 'function') {
				this.xTicFormat = this.chartData.xTicFormat;
			} else {
				this.xTicFormat = function(val) {
					return val;
				};
			}
			this.yTicsCount = this.chartData.yTicsCount;

			data.forEach(axisData => {
				const {data, additionalLines} = axisData;
				let allData = data;
				if (additionalLines && additionalLines.length) {
					allData = allData.concat(...additionalLines.map(line => line.data));
				}
				if (allData.length) {
					if (typeof axisData.minVal === 'undefined') {
						axisData.minVal = d3.min(allData, function(d) {
							if (d && d.y) {
								return d.y;
							}
							return null;
						});
					}
					if (typeof axisData.maxVal === 'undefined') {
						axisData.maxVal = d3.max(allData, function(d) {
							if (d && d.y) {
								return d.y;
							}
							return null;
						});
					}
					if (axisData.minVal === axisData.maxVal) {
						axisData.minVal = axisData.minVal - 5;
						axisData.maxVal = axisData.maxVal + 10;
					}
				}
				if (typeof axisData.orient === 'undefined') {
					axisData.orient = 'left';
				}
				if (typeof axisData.colorValue === 'undefined') {
					axisData.colorValue = 'black';
				}

				let ticFormat =
					typeof axisData.ticFormat === 'function'
						? axisData.ticFormat
						: d => {
								if (typeof d === 'number') {
									return d.toFixed(2);
								}
								return d;
						  };
				let propertyName = axisData.propertyName || translateService.translateProperty(axisData.name);
				if (
					axisData.section === this.CPR_REPORT_SECTIONS.EVAPORATOR_HEAT_TRANSFER ||
					axisData.section === this.CPR_REPORT_SECTIONS.CONDENSER_HEAT_TRANSFER
				) {
					if (axisData.isMultiCircuit) {
						propertyName = translateService.translateProperty(
							axisData.name,
							axisData.tisObjectTypeGroupName,
							{
								name: $translate(axisData.instance),
							},
							true
						);
					}
				}

				Object.assign(axisData, {
					ticFormat,
					propertyName: propertyName,
				});
				if (axisData.additionalLines && typeof axisData.additionalLines.forEach === 'function') {
					axisData.additionalLines.forEach(addLine => {
						const data =
							typeof addLine.data === 'function' ? (axisData.data && axisData.data.length ? addLine.data(axisData.data) : null) : addLine.data;
						addLine.data = data;
						if (typeof addLine.label === 'function') {
							let name = addLine.label(data);
							if (name) {
								if (!name.noUOM) {
									name.uom = axisData.unitOfMeasure.symbol;
								}
								name.colorValue = axisData.colorValue;
								this.addLines.push(name);
							}
						}
					});
				}
			});
			// :todo I believe we need to return data from the service as sorted array, will fix this in a further tasks
			data.sort((item1, item2) => {
				if (item1.name === 'CondenserApproachTempResult' || item1.name === 'EvaporatorApproachTempResult') return -1;
				if (item1.name === 'ChillerCurrentEnteringDrawRla') return 1;
				return 0;
			});
			this.axesData = data;
			this.drawChart($element, data);
		}
	}

	drawChart($element, data) {
		const {translateService} = services.get(this);
		const svg = d3.select($element[0]).select('svg');
		const svgWidth = 321;
		const svgHeight = 102;
		const margin = {
			top: 5,
			right: 30,
			bottom: 20,
			left: 30,
		};
		const grid = {
			width: svgWidth - margin.left - margin.right,
			height: svgHeight - margin.top - margin.bottom,
		};
		const x = d3.time.scale().range([0, grid.width]);
		const y = d3.scale.linear().range([grid.height, 0]);
		const xAxes = {};
		const yAxes = {};

		this.drawGrid({svg, grid, margin, svgWidth, svgHeight});
		this.drawAxes({svg, grid, margin, data, xAxes, yAxes, x, y});

		let diff = 0;
		const count = data.length;
		const barChartPadding = 0.2;

		data.forEach(axisData => {
			const {step, orient, colorValue, unitOfMeasure, type, unitOfMeasureX = {}} = axisData;
			let {from: fromX, to: toX, orient: orientX, name: nameX, symbol: symbolX} = unitOfMeasureX;

			if (orient && unitOfMeasure && unitOfMeasure.name && yAxes[orient] && yAxes[orient][unitOfMeasure.name]) {
				if (xAxes[orientX] && xAxes[orientX][nameX]) {
					let {minVal, maxVal} = xAxes[orientX][nameX];
					x.domain([minVal, maxVal]);
				}

				const {minVal, maxVal} = yAxes[orient][unitOfMeasure.name];

				y.domain([minVal, maxVal]);
				const dataNode = svg
					.append('g')
					.attr('class', 'data')
					.attr('clip-path', `url(#clip${this.chartIndex})`)
					.attr('transform', `translate(${margin.left},${margin.top})`);

				if (type === 'dots') {
					let dots = dataNode.append('g').attr('class', 'lines');
					dots
						.selectAll()
						.data(axisData.data)
						.enter()
						.append('circle')
						.attr('fill', colorValue)
						.attr('class', 'dots')
						.attr('r', 1)
						.attr('cx', d => {
							return x(d.x);
						})
						.attr('cy', d => {
							if (d && d.y) {
								return y(d.y);
							}
							return null;
						});
				} else if (type === 'polyline') {
					this.drawPolyline({dataNode, data: axisData.data, colorValue: axisData.colorValue, x, y});
				} else if (type === 'bars') {
					let bars = dataNode.append('g').attr('class', 'bars');
					bars
						.selectAll()
						.data(axisData.data)
						.enter()
						.append('rect')
						.attr('fill', colorValue)
						.attr('class', 'bars')
						.attr('x', d => {
							return x(d.x + diff);
						})
						.attr('y', d => {
							return y(d.y);
						})
						.attr('width', d => {
							return x(step / count - barChartPadding) - x(0);
						})
						.attr('height', d => {
							return -(y(d.y) - y(0));
						});
					diff = diff + step / count - barChartPadding;
				}

				if (axisData.additionalLines && typeof axisData.additionalLines.forEach === 'function') {
					axisData.additionalLines.forEach(addLine => {
						const data = addLine.data;
						if (data) {
							if (addLine.type === 'line') {
								let line = dataNode.append('g').attr('class', 'trend');
								line
									.append('line')
									.attr('x1', x(data.x1))
									.attr('y1', y(data.y1))
									.attr('x2', x(data.x2))
									.attr('y2', y(data.y2))
									.attr('stroke', colorValue);
							} else if (addLine.type === 'polyline' && data.length && data.length > 1) {
								let line = dataNode.append('g').attr('class', 'trend');
								line
									.append('line')
									.attr('x1', x(data[0].x))
									.attr('y1', y(data[0].y))
									.attr('x2', x(data[1].x))
									.attr('y2', y(data[1].y))
									.attr('stroke', axisData.colorValue);
							}
						}
					});
				}
			}
		});

		if (!CprChartComponentController.checkIfDataPresent(data)) {
			CprChartComponentController.drawNoData({svg, margin, grid, text: translateService.translate('LABEL_TIMELINE_NO_DATA')});
		}
	}

	drawGrid({svg, grid, margin, svgWidth, svgHeight}) {
		svg.attr('viewBox', `0 0 ${svgWidth} ${svgHeight}`).attr('preserveAspectRatio', 'none');

		svg
			.append('g')
			.attr('transform', `translate(${margin.left},${margin.top})`)
			.append('clipPath')
			.attr('id', `clip${this.chartIndex}`)
			.append('rect')
			.attr('width', grid.width)
			.attr('height', grid.height);

		svg
			.append('g')
			.attr('class', 'back')
			.attr('clip-path', `url(#clip${this.chartIndex})`)
			.attr('transform', `translate(${margin.left},${margin.top})`)
			.append('g')
			.attr('class', 'area')
			.append('rect')
			.attr('width', grid.width)
			.attr('height', grid.height);
	}

	drawAxes({svg, grid, margin, data, xAxes, yAxes, x, y}) {
		let maxDate = this.chartData.to
			? this.chartData.to
			: moment(
					d3.max(data[0].data, function(d) {
						return d.x;
					})
			  )
					.endOf('month')
					.add(1, 'day');

		let minDate = this.chartData.from ? this.chartData.from : moment(maxDate).subtract(7, 'month');
		let customXAxes = false;
		data.forEach(axisData => {
			let {minVal, maxVal, orient, unitOfMeasure, unitOfMeasureX = {}} = axisData;
			let {from: fromX, to: toX, orient: orientX, name: nameX, noTics} = unitOfMeasureX;

			if (xAxes[orientX] && xAxes[orientX][nameX] && this.isNumber(fromX) && this.isNumber(toX)) {
				xAxes[orientX][nameX].minVal =
					typeof xAxes[orientX][nameX].minVal === 'number' ? Math.min(xAxes[orientX][nameX].minVal, parseFloat(fromX)) : parseFloat(fromX);
				xAxes[orientX][nameX].maxVal =
					typeof xAxes[orientX][nameX].maxVal === 'number' ? Math.max(xAxes[orientX][nameX].maxVal, parseFloat(toX)) : parseFloat(toX);
			} else {
				if (!xAxes[orientX]) {
					xAxes[orientX] = {};
				}
				if (!xAxes[orientX][nameX] && this.isNumber(fromX) && this.isNumber(toX)) {
					xAxes[orientX][nameX] = {
						minVal: parseFloat(fromX),
						maxVal: parseFloat(toX),
						noTics: noTics,
					};
					customXAxes = true;
				}
			}

			if (yAxes[orient] && yAxes[orient][unitOfMeasure.name]) {
				minVal = minVal < 0 ? minVal : 0;
				maxVal = unitOfMeasure.name !== 'percent' ? maxVal : maxVal > 100 ? maxVal : 100;

				yAxes[orient][unitOfMeasure.name].minVal = Math.min(yAxes[orient][unitOfMeasure.name].minVal, minVal);
				yAxes[orient][unitOfMeasure.name].maxVal = Math.max(yAxes[orient][unitOfMeasure.name].maxVal, maxVal);
			} else {
				if (!yAxes[orient]) {
					yAxes[orient] = {};
				}
				if (!yAxes[orient][unitOfMeasure.name] && this.isNumber(minVal) && this.isNumber(maxVal)) {
					yAxes[orient][unitOfMeasure.name] = {
						minVal: parseFloat(minVal),
						maxVal: parseFloat(maxVal),
						yTicsCount: this.yTicsCount,
					};
				}
			}
		});

		if (!customXAxes) {
			let xTickValues = [];
			for (let value of this.xTicsGenerator(minDate, maxDate)) {
				xTickValues.push(value);
			}
			this.drawXAxis(svg, x, xTickValues, grid, margin, minDate, maxDate);
		}

		data.forEach(axisData => {
			let {step, orient, numberMarks, numberTics, unitOfMeasure, unitOfMeasureX = {}} = axisData;
			let {orient: orientX, name: nameX, symbol: symbolX, ticsFrom, ticsTo, noTics} = unitOfMeasureX;

			if (xAxes[orientX] && xAxes[orientX][nameX]) {
				let {minVal, maxVal} = xAxes[orientX][nameX];

				let xTickValues = [];
				for (let value of this.xTicsGenerator(ticsFrom, ticsTo, ticsTo / step)) {
					xTickValues.push(value);
				}
				this.drawXAxis(svg, x, xTickValues, grid, margin, minVal, maxVal, noTics);

				if (symbolX) {
					const xLabelOffset = x(maxVal / 2);
					svg
						.append('g')
						.attr('class', 'axis-label-x')
						.append('text')
						.attr('transform', `translate(${xLabelOffset}, ${grid.height + margin.top + margin.bottom / 2 + 10})`)
						.text(symbolX);
				}
			}

			if (yAxes[orient] && yAxes[orient][unitOfMeasure.name]) {
				const labelMargin = 21;
				let {minVal, maxVal, yTicsCount} = yAxes[orient][unitOfMeasure.name];

				if (this.isNumber(minVal) && this.isNumber(maxVal)) {
					y.domain([minVal, maxVal]);

					let tickSize = 0;

					if (yTicsCount) {
						tickSize = -grid.width;
					}

					let yTickValues = [];
					if (numberMarks > 0) {
						for (let value of this.yTicsGenerator(minVal, maxVal, numberMarks)) {
							yTickValues.push(value);
						}
					}

					const yAxis = d3.svg
						.axis()
						.scale(y)
						.tickValues(yTickValues)
						.tickFormat(axisData.ticFormat)
						.tickSize(tickSize)
						.orient(orient);

					let translateX = margin.left;
					let translateY = margin.top;
					if (axisData.orient === 'right') {
						translateX = grid.width + margin.left;
					}
					const yAxisLReady = svg
						.append('g')
						.attr('class', 'y axis')
						.attr('transform', `translate(${translateX},${translateY})`)
						.call(yAxis);

					yAxisLReady.selectAll('line').attr('transform', 'translate(0, 0)');

					const uomLabelOffset = orient === 'left' ? margin.left - labelMargin : margin.left + grid.width + labelMargin;
					const rotateDegree = orient === 'left' ? -90 : -90;
					svg
						.append('g')
						.attr('class', 'axis-label')
						.append('text')
						.attr('transform', `translate(${uomLabelOffset}, ${(grid.height + margin.bottom) / 2}) rotate(${rotateDegree})`)
						.text(axisData.unitOfMeasure.symbol);

					if (numberTics > 0) {
						let yTickValues = [];
						for (let value of this.yTicsGenerator(minVal, maxVal, numberTics)) {
							yTickValues.push(value);
						}
						const yAxis = d3.svg
							.axis()
							.scale(y)
							.tickValues(yTickValues)
							.tickFormat(axisData.ticFormat)
							.tickSize(tickSize)
							.orient(orient);
						const yAxisLReady = svg
							.append('g')
							.attr('class', 'y axis')
							.attr('transform', 'translate(' + translateX + ',' + translateY + ')')
							.call(yAxis);

						yAxisLReady.selectAll('text').style('display', 'none');
					}
				}
			}
		});
	}

	drawPolyline({dataNode, data, colorValue, x, y}) {
		const polyline = dataNode.append('g').attr('class', 'line');
		const dots = data.map(data => {
			return `${x(data.x)},${y(data.y)}`;
		});

		polyline
			.append('polyline')
			.datum(data)
			.attr('fill', 'none')
			.attr('stroke', colorValue || 'black')
			.attr('stroke-width', '2')
			.attr('points', dots.join(' '));
	}

	drawXAxis(svg, x, xTickValues, grid, margin, minVal, maxVal, noTics) {
		x.domain([minVal, maxVal]);
		const xAxisShort = d3.svg
			.axis()
			.scale(x)
			.tickValues(xTickValues)
			.tickFormat(this.xTicFormat)
			.tickSize(-margin.bottom);

		const xAxis = d3.svg
			.axis()
			.scale(x)
			.tickValues(xTickValues)
			.tickFormat(this.xTicFormat)
			.tickSize(-(grid.height + margin.top / 2));

		const xAxisReadyShort = svg
			.append('g')
			.attr('class', 'x axis short')
			.attr('transform', 'translate(' + margin.left + ',' + (grid.height + margin.top) + ')')
			.call(xAxisShort);

		xAxisReadyShort.selectAll('line').attr('transform', 'translate(0, ' + margin.bottom / 2 + ')');

		xAxisReadyShort
			.selectAll('text')
			.attr('y', 1)
			.attr('x', 2)
			.style('text-anchor', 'start');

		if (!noTics) {
			const xAxisReady = svg
				.append('g')
				.attr('class', 'x axis')
				.attr('transform', 'translate(' + margin.left + ',' + (grid.height + margin.top) + ')')
				.call(xAxis);

			xAxisReady.selectAll('text').style('display', 'none');
		}
	}

	static checkIfDataPresent(chartData = []) {
		return chartData.some(({data = [], additionalLines = []}) => {
			return data.length || additionalLines.some(({data = []}) => data && data.length);
		});
	}

	static drawNoData({svg, margin, grid, text}) {
		const group = svg
			.append('g')
			.attr('transform', `translate(${margin.left},${margin.top})`)
			.attr('class', 'area');

		group
			.append('rect')
			.attr('width', grid.width)
			.attr('height', grid.height);

		group
			.append('text')
			.text(text)
			.attr('x', grid.width / 2)
			.attr('y', grid.height / 2)
			.attr('class', 'no-data')
			.attr('dominant-baseline', 'middle')
			.attr('text-anchor', 'middle');
	}
}

angular.module('TISCC').component('cprChartComponent', {
	templateUrl: 'components/reports/chiller-performance/page/report-section/chart/cpr-chart-component.html',
	controller: CprChartComponentController,
	bindings: {
		chartHeader: '<',
		chartDescription: '<',
		chartData: '<',

		// The number of chart in the section which consists of two charts.
		// This property is used to determine for which chart the common header (if such present) will be displayed.
		chartNumber: '<',
		chartCommonHeader: '<',
	},
});
