import _groupBy from 'lodash.groupby';
import translations from '../../../../../translations/en.json';

(function() {
	const services = new WeakMap();
	class InsepctionSummaryController {
		constructor(DIR_STATUS, $filter) {
			services.set(this, {
				DIR_STATUS,
				$filter,
			});
			this.DIR_STATUS = DIR_STATUS;
		}

		$onInit() {
			const dirStatus = services.get(this).DIR_STATUS;
			function getStatusResultText(data) {
				let resultText = dirStatus.Red.resultText;
				if (data && data.Red) {
					if (data.Red.length > 1) {
						resultText = resultText.replace('Test Requires', 'Tests Require');
					} else {
						resultText = resultText.replace('Tests Require', 'Test Requires');
					}
					return {
						className: dirStatus.Red.className,
						resultText: `${data.Red.length} ${resultText}`,
						verticalBar: dirStatus.Red.verticalBar,
					};
				} else if (data && data.Yellow) {
					let resultText = dirStatus.Yellow.resultText;
					if (data.Yellow.length === 1) {
						resultText = resultText.replace('Tests', 'Test');
					}
					return {
						className: dirStatus.Yellow.className,
						resultText: `${data.Yellow.length} ${resultText}`,
						verticalBar: dirStatus.Yellow.verticalBar,
					};
				} else if (data && data.Green) {
					return {className: dirStatus.Green.className, resultText: `${dirStatus.Green.resultText}`, verticalBar: dirStatus.Green.verticalBar};
				}
				return {
					className: dirStatus['No Data'].className,
					resultText: `${dirStatus['No Data'].resultText}`,
					verticalBar: dirStatus['No Data'].verticalBar,
				};
			}
			// $translate('CPR_SETTINGS.COOLING_DEGREE_DAYS.TITLE')

			this.data = Object.entries(this.failureData)
				.filter(mockData => {
					return mockData[0] !== 'default';
				})
				.map(testData => {
					const [categoryName, summary] = [...testData];
					const findGroupName = summary[0].groupName;
					const category = categoryName === 'others' ? 'Miscellaneous' : categoryName;
					const groupedItemsByStatus = _groupBy(category === 'Miscellaneous' ? summary : summary, 'failureRateIndicator');
					const {className, resultText, verticalBar} = getStatusResultText(groupedItemsByStatus);
					const description = translations.DIR_DESCRIPTION[findGroupName][category];
					return {
						category,
						// testSummaryIndicator: summary.testSummaryIndicator,
						description: description,
						resultText,
						className: `${className} center status status-bg`,
						verticalBar,
					};
				});
		}
	}

	angular.module('TISCC').component('dirInspectionSummaryComponent', {
		templateUrl: 'components/reports/digital-inspection/page/inspection-summary/inspection-summary-component.html',
		controller: InsepctionSummaryController,
		bindings: {
			failureData: '<',
		},
	});
})();
