import _get from 'lodash.get';

/**
 * Used to store all property data we have ever downloaded
 */
angular.module('TISCC').service('propertyStoreService', function($http, $q, $route, propertyResolutionService, handleServiceWorker) {
	let propertyStore = {};
	let atSymbol = '@';
	let tildeSymbol = '~';
	let hatSymbol = '^';
	let fetchedAllPropertiesForType = {};
	let tisObjectIDToTypeRel = {};
	let tisObjectTypeToIDRel = {};

	/* TODO: What will be if tisObjectIDToTypeRel is not loaded but client code call service methods?*/
	function initialize() {
		const config = {
			headers: {
				Accept: 'application/tisObjectType+json',
			},
		};

		$http.get('/ext_api/api/tisObjectType', config).then(response => {
			response.data.tisObjectTypeList.forEach(function(row) {
				const id = row.tisObjectTypeGroupNumber.toString();
				let name = row.tisObjectTypeGroupName.toLowerCase();
				tisObjectIDToTypeRel[id] = name;
				name = name === 'weatherstation' ? 'weathersourcev2' : name;
				tisObjectTypeToIDRel[name] = +id;
			});
		});
	}

	function getEquipmentId(tisObjectTypeGroupNumber) {
		return '' + propertyResolutionService.isAllowedEquipmentType(tisObjectTypeGroupNumber) ? _get($route, 'current.scope.equipmentId', '') : '';
	}

	function assignFetchedPropertiesToStorage(list, typeID) {
		const equipmentId = getEquipmentId(typeID);

		list.forEach(function(item) {
			if (tisObjectIDToTypeRel[typeID] && item.propertyName) {
				const propertyName = (tisObjectIDToTypeRel[typeID] + atSymbol + item.propertyName).toLowerCase();
				addPropertyToStorage(propertyName, item);
				if (equipmentId) {
					const propertyName = (equipmentId + tisObjectIDToTypeRel[typeID] + atSymbol + item.propertyName).toLowerCase();
					addPropertyToStorage(propertyName, item);
				}
			}
		});
		// Add additional check whether all properties were succesfully added
		fetchedAllPropertiesForType[tisObjectIDToTypeRel[typeID]] = true;
		equipmentId && (fetchedAllPropertiesForType[equipmentId + tisObjectIDToTypeRel[typeID]] = true);
	}

	function addPropertyToStorage(name, value) {
		propertyStore[name] = value;
		return propertyStore[name] === value;
	}

	function getPropertiesByTisObjectTypeGroup(typeID) {
		const propertiesList = {};
		const typeName = tisObjectIDToTypeRel[typeID];

		const equipmentId = getEquipmentId(typeID);
		const keyStartsWith = (equipmentId + typeName + atSymbol).trim();

		Object.keys(propertyStore)
			.filter(keyName => keyName.lastIndexOf(keyStartsWith) === 0)
			.forEach(keyName => (propertiesList[keyName] = propertyStore[keyName]));

		return propertiesList;
	}

	function removeSpecialCases(str) {
		return str.replace(/[^\w\s]/gi, '');
	}

	this.getSinglePropertyInfo = function(name) {
		const lowercaseName = name.toLowerCase();
		return propertyStore[lowercaseName] || {};
	};

	this.getPropertiesByType = function(typeID) {
		const equipmentId = getEquipmentId(typeID);

		let propertiesList, deferred;
		deferred = $q.defer();
		if (fetchedAllPropertiesForType[equipmentId + tisObjectIDToTypeRel[typeID]]) {
			deferred.resolve(getPropertiesByTisObjectTypeGroup(typeID));
		} else {
			propertyResolutionService.getAnalogResolution(typeID, equipmentId).then(response => {
				const url = '/ext_api/api/tisObjectType/' + typeID + '/properties?displayState=all';
				$http
					.get(url, {
						headers: handleServiceWorker.createServiceWorkerCacheKeyForRequest({
							cacheKey: `propertyStoreService_getPropertiesByType_${url}`,
							expiryTime: handleServiceWorker.CACHE_EXPIRE_TIME.IN_3_DAYS,
						}),
					})
					.then(function(propertiesResult) {
						propertiesList =
							propertiesResult.data && propertiesResult.data.tisObjectPropertyList ? propertiesResult.data.tisObjectPropertyList : [];
						const resolution = _get(response, '[0].resolution', null);
						propertiesList = propertyResolutionService.updateResolutionForAllProperties(propertiesList, resolution, typeID);

						if (propertiesList.length > 0) {
							assignFetchedPropertiesToStorage(propertiesList, typeID);
						}
						deferred.resolve(getPropertiesByTisObjectTypeGroup(typeID));
					});
			});
		}
		return deferred.promise;
	};

	this.getPropertyTypeGroupIdByName = function(name) {
		name = removeSpecialCases(name.toLowerCase());
		return tisObjectTypeToIDRel[name] || '';
	};

	this.getPropertyByHpathAndName = function(name, hpath, defaultTypeNumber) {
		const lowercaseName = name.toLowerCase();
		const relationPrefixes = ['//child::', '//parent::', '//'];
		const defaultTypeName = tisObjectIDToTypeRel[defaultTypeNumber] || '';

		// We search for multiple indices, but handle first one
		// TODO in case of several found indices we'll need gapFilled API change, to make more precise assumption
		let propertyReference = {};

		for (let i = 0; i < hpath.length; i++) {
			const lowerCaseItem = hpath[i].toLowerCase().trim();

			if (lowerCaseItem.endsWith(lowercaseName)) {
				if (lowerCaseItem === atSymbol + lowercaseName) {
					propertyReference = defaultTypeName + atSymbol + lowercaseName;
					break;
				} else if (!lowerCaseItem.includes(tildeSymbol) && !lowerCaseItem.includes(hatSymbol)) {
					const prefix = relationPrefixes.find(p => lowerCaseItem.startsWith(p));
					propertyReference = prefix ? lowerCaseItem.replace(prefix, '') : propertyReference;
				}
			}
		}

		return propertyStore[propertyReference] || {};
	};

	initialize();

	// TODO: get rid of that RETURN!
	return {
		// Get PropertyTypeGroupNameById
		getPropertyTypeGroupIdByName: this.getPropertyTypeGroupIdByName,
		// Get property by short name, from Hpath list
		getPropertyByHpathAndName: this.getPropertyByHpathAndName,
		// Get property list by type
		getPropertiesByType: this.getPropertiesByType,
		// Get single property by Name
		getByName: this.getSinglePropertyInfo,
	};
});
