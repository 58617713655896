(function() {
	class PageFooterController {
		constructor() {
			this.currentYear = new Date().getFullYear();
			this.footerText = 'OF';
		}
	}

	angular.module('TISCC').component('dirPageFooterComponent', {
		templateUrl: 'components/reports/digital-inspection/page/page-footer/page-footer-component.html',
		controller: PageFooterController,
		bindings: {
			facilityName: '<',
			currentPageNumber: '<',
			totalPagesNumber: '<',
		},
	});
})();
