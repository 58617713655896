(function() {
	const services = new WeakMap();

	class MultipleFacilitySelectionController {
		constructor($translate, modalHelperService, facilityListService) {
			services.set(this, {$translate, modalHelperService, facilityListService});
			this.MAX_TOOLTIP_ITEMS = 10;
			this.facilities = [];
			this.showTooltip = false;
		}

		$onInit() {
			!this.isDisabled && this.loadFacilities();
		}

		$onChanges({isDisabled}) {
			if (isDisabled && !isDisabled.isFirstChange() && !isDisabled.currentValue && !this.facilities.length) {
				this.loadFacilities();
			}
		}

		openMultipleFacilitySelectionDialog() {
			const that = this;
			const {modalHelperService} = services.get(this);
			const modalInstance = modalHelperService.open({
				templateUrl: 'components/multiple-facility-selection/multiple-facility-selection-modal.html',
				controller: 'MultipleFacilitySelectionModalCtrl',
				backdrop: 'static',
				windowClass: 'multiple-facilities-selection-dialog fixed full-height',
				resolve: {
					data: () => {
						return {
							currentFacility: this.currentFacility,
							selectedFacilities: this.selectedFacilities,
							facilities: this.facilities,
						};
					},
				},
			});
			modalInstance.result.then(function(list) {
				that.selectedFacilities = list;
				that.setFacilities({list});
			});

			this.onOpen && this.onOpen();
		}

		buildLabel() {
			const {$translate} = services.get(this);
			if (!this.selectedFacilities) return;

			let location = $translate('MULTIPLE_FACILITIES');
			if (this.selectedFacilities && this.selectedFacilities[0] && this.selectedFacilities[0].locationName && !this.selectedFacilities[1]) {
				location = this.selectedFacilities[0].locationName;
			}
			return $translate('LOCATION') + ' ' + location;
		}

		toggleTooltip(visibility) {
			this.showTooltip = (!this.isDisabled || this.selectedFacilities.length > 1) && visibility;
		}

		loadFacilities() {
			const {facilityListService} = services.get(this);

			this.isLoading = true;
			facilityListService.getFacilities().then(items => {
				this.facilities = items;
				this.isLoading = false;
			});
		}
	}

	angular.module('TISCC').component('multipleFacilitySelection', {
		templateUrl: 'components/multiple-facility-selection/multiple-facility-selection.html',
		controller: MultipleFacilitySelectionController,
		bindings: {
			isDisabled: '<',
			selectedFacilities: '<',
			setFacilities: '&',
			onOpen: '&',
		},
	});
})();
