angular
	.module('TISCC')
	.controller('GenericErrorMessageCtrl', GenericErrorMessageCtrl)
	.controller('ErrorMessageCtrl', ErrorMessageCtrl)
	.controller('ConfirmMessageCtrl', ConfirmMessageCtrl)
	.controller('RoleAccessErrorMessageCtrl', RoleAccessErrorMessageCtrl);

function GenericErrorMessageCtrl($scope, $modalInstance, title, details) {
	$scope.title = title;
	$scope.details = details;

	$scope.cancel = function() {
		$modalInstance.dismiss('cancel');
	};
}

function ErrorMessageCtrl($scope, $modalInstance, details) {
	$scope.details = details;

	$scope.cancel = function() {
		$modalInstance.dismiss('cancel');
	};
}

function ConfirmMessageCtrl($scope, $modalInstance, content) {
	$scope.content = content;

	$scope.cancel = function() {
		$modalInstance.dismiss('cancel');
	};

	$scope.confirm = function() {
		$modalInstance.close('confirm');
	};
}

function RoleAccessErrorMessageCtrl($scope, $modalInstance, $localStorage, $location) {
	$scope.cancel = function() {
		$modalInstance.dismiss('cancel');
		if ($localStorage.mostRecentRDRLink && $location.path() !== $localStorage.mostRecentRDRLink) {
			$location.url($localStorage.mostRecentRDRLink);
		}
	};
}
