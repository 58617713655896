import {USER_EVENTS} from './user-events';

export const getSyncStatusName = status => {
	const SYNC = 'Sync';
	const UNSYNC = 'Unsync';

	return status ? SYNC : UNSYNC;
};

export const getChangedFieldsOnFormatChart = ({allSelected, lineHash, changesMap, defaults, color: {defaultColor, color}, formattingProps}) => {
	let changes;

	if (allSelected) {
		[[lineHash, changes]] = Object.entries(changesMap);
	} else {
		changes = changesMap[lineHash];
	}

	if (!changes) {
		return [];
	}

	const previousValues = formattingProps[lineHash] || {
		...defaults,
		color: defaultColor !== color || color,
	};
	const changedFields = [];

	Object.entries(previousValues).forEach(([key, value]) => {
		if (value !== changes[key]) {
			changedFields.push(key);
		}
	});

	return changedFields.map(getFormattedFieldName);
};

const getFormattedFieldName = field => {
	switch (field) {
		case 'color':
			return 'Color';
		case 'lineType':
			return 'Line Type';
		case 'lineThickness':
			return 'Line Thickness';
		case 'markerType':
			return 'Marker Type';
		case 'markerSize':
			return 'Marker Size';
	}
};

export const getChartType = type => {
	switch (type) {
		case 'TisObject':
			return 'Equipment';
		case 'Location':
			return 'Facility';
		default:
			return type;
	}
};

export const getPropertiesOnExportChart = options => {
	const {BACKGROUND_COLOR, EXPORT_TYPE, FORMAT, ORIENTATION} = USER_EVENTS.EQUIPMENT_PERFORMANCE_CHARTS.properties;

	return {
		[EXPORT_TYPE]: getExportType(options),
		[BACKGROUND_COLOR]: getBackgroundColor(options),
		[ORIENTATION]: getOrientation(options),
		[FORMAT]: getFormat(options),
	};
};

export const getChartNameForExport = charts => {
	return charts.length > 1 ? 'MULTIPLE' : charts[0].chartObj.selectedChart.title;
};

const getExportType = options => {
	switch (options.optionType) {
		case 'exportImage':
			return 'Image';
		case 'exportData':
			return 'Data';
		case 'exportPDF':
			return 'PDF';
		case 'printChart':
			return 'Print';
	}
};

const getBackgroundColor = options => {
	if (options.optionType === 'exportData') return;

	switch (options.color) {
		case 'white':
			return 'White';
		case 'black':
			return 'Black';
		case 'transparent':
			return 'Transparent';
	}
};

const getOrientation = options => {
	if (['exportData', 'printChart'].includes(options.optionType)) return;

	switch (options.exportOrientation) {
		case 'portrait':
			return 'Portrait';
		case 'landscape':
			return 'Landscape';
	}
};

const getFormat = options => {
	if (['exportPDF', 'printChart'].includes(options.optionType)) return;

	if (options.optionType === 'exportImage') {
		switch (options.chartType) {
			case 'jpg':
				return 'JPEG';
			case 'png':
				return 'PNG';
		}
	}

	if (options.optionType === 'exportData') {
		switch (options.dataType) {
			case 'xlsx':
				return 'XLSX';
			case 'csv':
				return 'CSV';
		}
	}
};
