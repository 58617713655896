import CriteriaObject from '../criteria-object';
import MyViewsGroupClass from '../my-views-group-class';
import './my-views-group.scss';

export class MyViewsGroupController {
	constructor() {
		this.ruleOperators = [{key: MyViewsGroupClass.AND_OPERATOR, value: 'And'}, {key: MyViewsGroupClass.OR_OPERATOR, value: 'Or'}];
	}

	$onInit() {
		this.showGroupOperator = Boolean(this.index);
	}

	addRule() {
		this.group.rules = [
			...this.group.rules,
			new CriteriaObject({
				name: this.listElements[0].name,
				findSettings: this.findSettings,
				callbackOnEdit: this.callbackOnEdit,
			}),
		];
		this.callbackOnEdit();
	}

	removeRule(ruleIndex) {
		this.group.rules = this.group.rules.filter((_, index) => index !== ruleIndex);
		this.callbackOnEdit();
	}

	changeRuleOperator(newRuleOperator) {
		this.group.ruleOperator = newRuleOperator;
		this.callbackOnEdit();
	}

	changeGroupOperator(newGroupOperator) {
		this.callbackOnEdit();
	}

	getRuleOperatorValue(ruleOperator) {
		return this.ruleOperators.find(({key}) => key === ruleOperator).value;
	}
}

export default {
	templateUrl: 'components/my-views/my-views-group/my-views-group.html',
	controller: MyViewsGroupController,
	bindings: {
		index: '<',
		listElements: '<',
		dimensions: '<',
		group: '<',
		findSettings: '<',
		callbackOnEdit: '<',
	},
};
