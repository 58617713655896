(function() {
	class ExportService {
		constructor($cookies, $http, API_ENDPOINTS, configService, DEFAULTS) {
			this.$http = $http;
			this.$cookies = $cookies;
			this.REPORT_API_ENDPOINT = configService.getExportAPIConfig();
			this.API_ENDPOINTS = API_ENDPOINTS;
			this.DEFAULTS = DEFAULTS;
		}

		generateProgressKey() {
			let now = new Date().getTime();
			let key =
				Math.random()
					.toString(36)
					.substr(2, 5) + now;
			return key;
		}

		sendExportRequest(data, config) {
			data.progressKey = this.generateProgressKey();
			this.setBalancerCookie(data.progressKey);
			return this.$http.post(this.API_ENDPOINTS.tisExport, data, config).then(response => {
				return response.data;
			});
		}

		async sendExportServerlessRequest(data, config) {
			this.setBalancerCookie(data.progressKey);
			try {
				const response = await this.$http.post(this.REPORT_API_ENDPOINT + '/export', data, config);
				return response.data;
			} catch (e) {
				throw e;
			}
		}

		async resetDownloadLink() {
			let link = document.getElementById('download-file');
			link.href = '';
			link.setAttribute('download', '');
		}

		setBalancerCookie(progressKey) {
			this.$cookies.put(this.DEFAULTS.LOAD_BALANCER_COOKIE_NAME, progressKey);
		}
	}

	angular.module('TISCC').service('exportService', ExportService);
})();
