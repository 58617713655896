import {CPR_DATE_FORMATS} from '../../../chiller-performance-report-config';

(function() {
	const NUMBER_OF_VALUES_THRESHOLD = 10;
	const FIXED_VALUE = 2;
	const PLACE_HOLDER = '--';
	const DEFAULT_COLUMN_NAME = 'NUMBER_OF_HOURS';
	const services = new WeakMap();

	class ComparableTableComponentController {
		constructor(helpers) {
			services.set(this, {helpers});
		}

		getCorrectValue(value) {
			const {helpers} = services.get(this);
			return helpers.isNumber(value) ? (Number.isInteger(Number(value)) ? value : value.toFixed(FIXED_VALUE)) : PLACE_HOLDER;
		}

		$onInit() {
			const {previousMonthData, currentMonthData, numbersColumnName, numbersRowBackgroundColor, getCorrectValue} = this.tableData;

			this.currentMonth = this.toDate.format(CPR_DATE_FORMATS.LONG_MONTH_FORMAT);
			this.columnName = numbersColumnName || DEFAULT_COLUMN_NAME;
			this.numbersRowStyle = {};
			this.getCorrectValue = getCorrectValue || this.getCorrectValue;

			if (numbersRowBackgroundColor) {
				this.numbersRowStyle = {
					'background-color': numbersRowBackgroundColor,
				};
			}

			this.tableData = {
				dataList: Object.keys(currentMonthData.data)
					.map(key => {
						return {
							name: key,
							currentValue: currentMonthData.data[key],
							previousValue: previousMonthData.data[key],
						};
					})
					.sort(({currentValue: aCurrentValue}, {currentValue: bCurrentValue}) => {
						return aCurrentValue <= bCurrentValue ? 1 : -1;
					})
					.filter((_, index) => index < NUMBER_OF_VALUES_THRESHOLD),
			};

			if (currentMonthData.missingDataHours !== undefined && previousMonthData.missingDataHours !== undefined) {
				this.tableData.missingData = {
					currentValue: currentMonthData.missingDataHours > 0 ? currentMonthData.missingDataHours : 0,
					previousValue: previousMonthData.missingDataHours > 0 ? previousMonthData.missingDataHours : 0,
				};
			}
		}
	}

	angular.module('TISCC').component('cprComparableTableComponent', {
		templateUrl: 'components/reports/chiller-performance/page/report-section/comparable-table/comparable-table-component.html',
		controller: ComparableTableComponentController,
		bindings: {
			header: '@',
			tableData: '<',
			toDate: '<',
		},
	});
})();
