export const USER_EVENTS = {
	ERROR_CAPTURE_EVENTS: {
		categoryName: 'Error Alerts',
		events: {
			CC4_API_ALERTS: 'CC4 API Alerts',
		},
		properties: {
			STATUS: 'Status Code',
			STATUS_TEXT: 'Status Text',
			HTTP_METHOD: 'Http Method',
			CURRENT_PAGE: 'Current Page',
			URL: 'API URL',
			API_ENDPOINT: 'API Endpoint',
			API_COMPONENET: 'API Component',
			PARAMS: 'Request Params',
		},
	},
};
