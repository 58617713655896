angular
	.module('TISCC')
	/**
	 * Used to store all ui state configs in sessionStorage
	 */
	.service('uiSessionStateService', function() {
		const storageKey = 'tcc4UiState';
		let itemStorage = {};
		let stateRestored = false;

		function restoreState() {
			let state = JSON.parse.call(null, window.sessionStorage.getItem(storageKey));
			if (state) {
				itemStorage = state;
				stateRestored = true;
			}
		}

		function saveState() {
			if (typeof itemStorage === 'object') {
				window.sessionStorage.setItem(storageKey, JSON.stringify(itemStorage));
			}
		}

		this.stateExists = function(key) {
			return !!(key && itemStorage[key] && itemStorage[key]);
		};

		this.registerState = function(key, value) {
			if (key && (value || value === '')) {
				itemStorage[key] = value;
			}
			saveState();
		};

		this.getState = function(key) {
			let returnValue = null;
			if (this.stateExists(key)) {
				returnValue = itemStorage[key];
			}
			return returnValue;
		};

		// Restore state from sessionStorage upon reload
		restoreState();
	});
