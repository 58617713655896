import _get from 'lodash.get';

class OfferingService {
	constructor($http, handleServiceWorker) {
		this.$http = $http;
		this.handleServiceWorker = handleServiceWorker;
	}
	static getNowDate(timeZone) {
		const now = moment();

		return timeZone ? now.tz(timeZone) : now;
	}

	static getDateByDateString(dateString, timeZone) {
		if (!dateString) {
			return null;
		}

		const date = moment(dateString);

		return timeZone ? date.tz(timeZone) : date;
	}

	static checkIsOfferingValid({expirationDate}, {locationTimeZone}) {
		if (!expirationDate) {
			return true;
		}

		const {getNowDate, getDateByDateString} = OfferingService;

		return getNowDate().isBefore(getDateByDateString(expirationDate, locationTimeZone));
	}
	static get GRACE_PERIOD() {
		return 60;
	}

	get GRACE_PERIOD_DAYS_NUMBER() {
		return 60;
	}

	get OFFERINGS_TO_DISPLAY() {
		return ['BP', 'EP', 'CAP', 'DI-CH'];
	}

	addExpirationMetaData(offering, {locationTimeZone}) {
		const {getNowDate, getDateByDateString, checkIsOfferingValid} = OfferingService;
		const expirationDate = getDateByDateString(offering.expirationDate, locationTimeZone);
		const startDate = getDateByDateString(offering.startDate, locationTimeZone);
		const resultOffering = {
			...offering,
			expirationDate,
			startDate,
		};

		if (checkIsOfferingValid(offering, {locationTimeZone})) {
			return resultOffering;
		}

		const expiredDaysNumber = getNowDate().diff(expirationDate, 'days');

		return {
			...resultOffering,
			isExpired: true,
			remainingDaysNumberInGracePeriod: this.GRACE_PERIOD_DAYS_NUMBER - expiredDaysNumber,
		};
	}

	filterValidOfferings(offeringLists = [], locationTimeZone) {
		const {getEarliestStartDate, getLastestExpirytDate} = OfferingService;
		const offerings = offeringLists.reduce((offerings, offering) => {
			const {shortName} = offering;
			if (!offerings[shortName]) {
				offerings[shortName] = {
					shortName,
				};
			}
			offerings[shortName].startDate = getEarliestStartDate({
				offeringsStartDate: offerings[shortName].startDate,
				offeringStartDate: offering.startDate,
				locationTimeZone,
			});
			const resultOffering = getLastestExpirytDate({
				offeringsExpirationDate: offerings[shortName].expirationDate,
				offering,
				locationTimeZone,
			});
			offerings[offering.shortName] = {
				...offerings[shortName],
				...resultOffering,
			};
			return offerings;
		}, {});
		return offerings;
	}

	static getEarliestStartDate({offeringsStartDate, offeringStartDate, locationTimeZone}) {
		const {getDateByDateString} = OfferingService;
		const startDate = getDateByDateString(offeringStartDate, locationTimeZone);
		return !offeringsStartDate || !offeringsStartDate.isBefore(startDate) ? startDate : offeringsStartDate;
	}

	static getLastestExpirytDate({offering, offeringsExpirationDate, locationTimeZone}) {
		const {getNowDate, getDateByDateString, checkIsOfferingValid, GRACE_PERIOD} = OfferingService;
		const expirationDate = getDateByDateString(offering.expirationDate, locationTimeZone);
		const resultOffering = {
			isExpired: false,
		};
		resultOffering.expirationDate = !offeringsExpirationDate || !offeringsExpirationDate.isAfter(expirationDate) ? expirationDate : offeringsExpirationDate;

		if (checkIsOfferingValid(resultOffering, {locationTimeZone})) {
			return resultOffering;
		}

		const expiredDaysNumber = getNowDate().diff(resultOffering.expirationDate, 'days');

		return {
			...resultOffering,
			isExpired: true,
			remainingDaysNumberInGracePeriod: GRACE_PERIOD - expiredDaysNumber,
		};
	}
	checkIsBPContractValid(offerings) {
		const {getNowDate} = OfferingService;
		let index = 0;
		let expireDays = 0;
		let isValid = true;
		if (offerings.length > 1) {
			offerings.filter((offering, i) => {
				const expiredDaysNumber = getNowDate().diff(offering.expirationDate, 'days');
				if (i === 0 || (expiredDaysNumber < 0 && expireDays > expiredDaysNumber)) {
					expireDays = expiredDaysNumber;
					index = i;
				}
			});
		}
		const resultOffering = offerings[index];
		if (resultOffering.isExpired) {
			isValid = !(resultOffering.remainingDaysNumberInGracePeriod < 0);
		}
		return isValid;
	}

	getAllOfferings() {
		return this.$http
			.get('/ext_api/api/tisOffering', {
				headers: this.handleServiceWorker.createServiceWorkerCacheKeyForRequest({
					cacheKey: 'getAllOfferings_tisOfferingList_/ext_api/api/tisOffering',
					expiryTime: this.handleServiceWorker.CACHE_EXPIRE_TIME.IN_3_DAYS,
				}),
			})
			.then(response => _get(response, 'data.tisOfferingList', []))
			.catch(() => []);
	}
}

angular.module('TISCC').service('offeringService', OfferingService);
