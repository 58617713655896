/* eslint-disable max-nested-callbacks */
import {USER_EVENTS} from '../../common/usage-tracking/categories';

const {
	DIGITAL_INSPECTION_PAGE: {
		events: DIGITAL_INSPECTION_PAGE_EVENTS,
		properties: DIGITAL_INSPECTION_PAGE_PROPERTIES,
		categoryName: DIGITAL_INSPECTION_PAGE_CATEGORY_NAME,
	},
} = USER_EVENTS;
angular.module('TISCC').controller('DigitalInspectionCtrl', DigitalInspectionCtrl);

function DigitalInspectionCtrl(
	// Function parameters
	$scope,
	$route,
	$filter,
	$routeParams,
	modalHelperService,
	locationEquipmentService,
	$location,
	locationDetailsService,
	serviceAdvisoryService,
	tisObjectService,
	$q,
	digitalInspectionService,
	uiStateService,
	errorHandler,
	chartService,
	CHART_DATE_FORMAT,
	$rootScope,
	ErrorPageFactory,
	$timeout,
	configService,
	PAGE_TYPE,
	urlService,
	STATUS,
	REPORT_TYPES,
	CHART_TYPE_LEVEL,
	helpers
	// End of function parameters
) {
	let tisObjectsHierarchy;
	let modalInstance = null;
	let progressIncrement;
	let equipments = [];
	let loadedStatusProcess = false;
	const externalLinks = configService.getExternalLinks();
	const allChartsServicePromise = chartService.getAllCharts();

	let getSuggestionByServiceAdvisoryTypeActionName = angular.noop;
	let now = moment()
		.tz(locationDetailsService.getLocationTimezone())
		.startOf('day');
	// $rootScope.maxDt = now;
	$rootScope.globalDateSelectionRange = {};

	if ($rootScope.appDateRange) {
		if (!moment($rootScope.appDateRange.endDate).isSame($scope.to, 'day')) {
			const fromDate = moment($rootScope.appDateRange.endDate).subtract(29, 'days');
			$scope.from = moment(fromDate);
			$scope.to = moment($rootScope.appDateRange.endDate);
			updateAllRange();
		} else if (!$scope.to) {
			const fromDate = moment($rootScope.appDateRange.endDate).subtract(29, 'days');
			$scope.from = moment(fromDate);
			$scope.to = moment($rootScope.appDateRange.endDate);
			updateAllRange();
		}
	}

	function updateDate() {
		// Loading through Url
		if ($routeParams.startDate && $routeParams.endDate && !$scope.to) {
			updateAllRange();
		} else if (!$routeParams.endDate && !$scope.to && !$rootScope.appDateRang) {
			// URL doesnt have any date
			$scope.to = moment(now);
			const fromDate = moment($scope.to).subtract(29, 'days');
			$scope.from = moment(fromDate);
			updateAllRange();
		}
	}

	function updateAllRange() {
		$rootScope.to = moment($scope.to);
		$rootScope.from = moment($scope.from);
	}
	// $scope
	$scope.openReport = openReport;
	$scope.toggleSuppression = toggleSuppression;
	$scope.suppressionFilter = suppressionFilter;
	$scope.featureToggles = configService.getFeatureToggles();
	$scope.equipmentLevel = $route.current.$$route.equipmentLevel;
	$scope.environment = configService.getEnvironmentType();
	$scope.uiStateKey = 'digitalInspectionController.uiState.' + $routeParams.locationId;
	$scope.sortParams = [
		{
			column: 'tisObjectName',
			order: true,
		},
	];
	$scope.activeTab = 'digital-inspection';
	$scope.locationId = $routeParams.locationId;
	$scope.organizationId = '';
	$scope.equipmentId = $routeParams.equipmentId;
	$scope.hasSuggestion = angular.noop;
	$scope.automatedTestsCollection = {};
	$scope.showGenericCauses = true;
	const chillerSubComponents = {
		Circuit: [],
		Compressor: [],
	};
	const PATH_404 = '404';
	const INVALID_DATE_FUTURE_KEY = 'invalidDateFuture';
	const INVALID_DATE_FORMAT_KEY = 'invalidDateFormat';
	const UNKNOWN = 'Unknown';
	// Progress / Loading
	$scope.locationDetailsDeferred = $q.defer();
	$scope.locationDetailsPromise = $scope.locationDetailsDeferred.promise;
	$scope.serviceAdvisoryTypesDeferred = $q.defer();
	$scope.serviceAdvisoryTypesPromise = $scope.serviceAdvisoryTypesDeferred.promise;
	$scope.locationEquipmentObjectsDeferred = $q.defer();
	$scope.locationEquipmentObjectsPromise = $scope.locationEquipmentObjectsDeferred.promise;
	$scope.loadFailureRatesDeferred = $q.defer();
	$scope.loadFailureRatesPromise = $scope.loadFailureRatesDeferred.promise;
	$scope.loadingPromises = [
		$scope.serviceAdvisoryTypesPromise,
		$scope.locationDetailsPromise,
		$scope.locationEquipmentObjectsPromise,
		$scope.loadFailureRatesPromise,
	];
	$scope.progress = 0;
	$scope.automatedTestsLoaded = false;
	$scope.isCprAllowed = locationDetailsService.isCprAllowed;
	$scope.isDirAllowed = locationDetailsService.isDirAllowed;

	$scope.isOfferingExpired = locationDetailsService.isOfferingExpired;
	progressIncrement = 100 / ($scope.loadingPromises.length + 1);
	$scope.trackOnLinkClick = $rootScope.getTrackOnLinkClickHandler(DIGITAL_INSPECTION_PAGE_CATEGORY_NAME);
	$scope.EVENTS = {...DIGITAL_INSPECTION_PAGE_EVENTS};
	$scope.PROPERTIES = DIGITAL_INSPECTION_PAGE_PROPERTIES;
	$scope.serviceAdvisoryTypeIDs = [];
	$scope.isShowSuppressed = false;
	serviceAdvisoryService.getActions(actions => {
		getSuggestionByServiceAdvisoryTypeActionName = serviceAdvisoryService.generateGetActionsFunction(actions);
		$scope.hasSuggestion = serviceAdvisoryTypeActionName => Boolean(getSuggestionByServiceAdvisoryTypeActionName({id: serviceAdvisoryTypeActionName}));
	});

	if (uiStateService.getState($scope.uiStateKey)) {
		$scope.isShowSuppressed = uiStateService.getState($scope.uiStateKey).isShowSuppressed;
		$scope.toggleSuppression.isShowSuppressed = $scope.isShowSuppressed;
	} else {
		uiStateService.registerState($scope.uiStateKey, {isShowSuppressed: false});
	}

	$scope.trackAutomatedTest = test => {
		const offering = {
			[DIGITAL_INSPECTION_PAGE_PROPERTIES.OFFERING]:
				$scope.equipment && $scope.equipment.tisObjectType.tisObjectTypeGroupName === 'Chiller' ? 'CMSA' : UNKNOWN,
		};
		$scope.trackOnLinkClick(DIGITAL_INSPECTION_PAGE_EVENTS.GO_TO_PERFORMANCE_CHART, {...test});
		$scope.trackOnLinkClick(DIGITAL_INSPECTION_PAGE_EVENTS.SELECT_AUTOMATED_TEST, {...test, ...offering});
	};

	$scope.trackViewSuggestionClick = test => {
		const offering = {
			[DIGITAL_INSPECTION_PAGE_PROPERTIES.OFFERING]:
				$scope.equipment && $scope.equipment.tisObjectType.tisObjectTypeGroupName === 'Chiller' ? 'CMSA' : UNKNOWN,
		};
		$scope.trackOnLinkClick(DIGITAL_INSPECTION_PAGE_EVENTS.VIEW_SUGGESTIONS, {...test, ...offering});
	};

	$scope.trackLatestDetection = test => {
		const props = {
			[DIGITAL_INSPECTION_PAGE_PROPERTIES.TEST_NAME]: test.diActionNameTitle,
			[DIGITAL_INSPECTION_PAGE_PROPERTIES.LATEST_DETECTION_DATE]: test.latestException,
			[DIGITAL_INSPECTION_PAGE_PROPERTIES.DETECTION_RATE]: test.failureRate ? test.failureRate + '%' : null,
			[DIGITAL_INSPECTION_PAGE_PROPERTIES.OFFERING]:
				$scope.equipment && $scope.equipment.tisObjectType.tisObjectTypeGroupName === 'Chiller' ? 'CMSA' : UNKNOWN,
		};
		$scope.trackOnLinkClick(DIGITAL_INSPECTION_PAGE_EVENTS.VIEW_LATEST_DETECTION, props);
	};

	$scope.trackFirstDetection = test => {
		const props = {
			[DIGITAL_INSPECTION_PAGE_PROPERTIES.TEST_NAME]: test.diActionNameTitle,
			[DIGITAL_INSPECTION_PAGE_PROPERTIES.FIRST_DETECTION_DATE]: test.firstException,
			[DIGITAL_INSPECTION_PAGE_PROPERTIES.DETECTION_RATE]: test.failureRate ? test.failureRate + '%' : null,
			[DIGITAL_INSPECTION_PAGE_PROPERTIES.OFFERING]:
				$scope.equipment && $scope.equipment.tisObjectType.tisObjectTypeGroupName === 'Chiller' ? 'CMSA' : UNKNOWN,
		};
		$scope.trackOnLinkClick(DIGITAL_INSPECTION_PAGE_EVENTS.VIEW_FIRST_DETECTION, props);
	};

	function checkRouteParamDates() {
		if ($routeParams.startDate && $routeParams.endDate) {
			$scope.from = moment.tz($routeParams.startDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, locationDetailsService.getLocationTimezone()).startOf('day');
			$scope.to = moment.tz($routeParams.endDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, locationDetailsService.getLocationTimezone()).startOf('day');

			locationDetailsService.getLocationDetailsWithoutServiceAdvisories($routeParams.locationId).then(() => {
				const timezone = locationDetailsService.getLocationTimezone();
				const from = moment.tz($routeParams.startDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, timezone).startOf('day');
				const to = moment.tz($routeParams.endDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, timezone).startOf('day');
				const urlDateRangeFormatIsValid = helpers.checkUrlRangeFormat(
					from,
					to,
					$routeParams.startDate,
					$routeParams.endDate,
					CHART_DATE_FORMAT.RANGE_DATE_FORMAT
				);
				const urlDateRangeIsValid = helpers.checkUrlRangeValidity(from, to, timezone);
				if (!urlDateRangeFormatIsValid) {
					ErrorPageFactory.createErrorPage(INVALID_DATE_FORMAT_KEY, $location.path(), PAGE_TYPE.EQUIPMENT);
					urlService.changeUrl(PATH_404);
				} else if (!urlDateRangeIsValid) {
					ErrorPageFactory.createErrorPage(INVALID_DATE_FUTURE_KEY, $location.path(), PAGE_TYPE.EQUIPMENT);
					urlService.changeUrl(PATH_404);
				} else {
					updateRange();
				}
			});
		} else {
			const replaceDates = true;
			urlService.changeDateRangeInUrl($scope.from.clone(), $scope.to.clone().add(-1, 'days'), replaceDates);
		}
	}

	locationDetailsService.getLocationDetailsWithoutServiceAdvisories($routeParams.locationId).then(function(data) {
		[$scope.location] = data.locationSummaryList;
		now = moment()
			.tz(locationDetailsService.getLocationTimezone())
			.startOf('day');
		$rootScope.maxDt = now;
		const {address: facilityId, locationId, organizationId} = $scope.location;
		$scope.organizationId = organizationId;
		$scope.addressLine = '';

		locationEquipmentService.getLocationObjectsList($routeParams.locationId, null, true).then(function(data) {
			let groupNames = [];

			tisObjectsHierarchy = data.tisObjectList;
			$scope.equipment = $filter('filterNested')(tisObjectsHierarchy, {tisObjectId: parseInt($routeParams.equipmentId)}, true).pop();

			const {tisObjectId, tisObjectName} = $scope.equipment;

			$scope.notesLink = externalLinks.notesLink({locationId, tisObjectName, facilityId});
			$scope.addNoteLink = externalLinks.addNoteLink({locationId, tisObjectId, facilityId});
			$scope.automatedTestSettingsLink = externalLinks.automatedTestSettingsLink({
				accountId: organizationId,
				locationId,
				tisObjectId: $scope.equipmentId,
			});
			$scope.automatedTestSuppressionsLink = externalLinks.automatedTestSuppressionsLink({accountId: organizationId, locationId});

			$scope.navigation = [
				{
					href: '#/facility/' + $routeParams.locationId,
					title: $scope.location.locationName,
				},
			];
			$scope.pageTitle = {
				title: $scope.equipment.tisObjectName,
			};
			$scope.groupName = $scope.equipment.tisObjectType.tisObjectTypeGroupName;

			const workingTisObjectIds = [$scope.equipment.tisObjectId];

			if ($scope.groupName === 'Chiller') {
				groupNames = [$scope.groupName, 'Circuit', 'Compressor'];
				saveChillerSubComponents($scope.equipment.children);

				Object.keys(chillerSubComponents).forEach(key =>
					chillerSubComponents[key].forEach(equipment => workingTisObjectIds.push(equipment.tisObjectId))
				);
			} else {
				groupNames = [$scope.groupName];
			}

			incrementProgressIndicator($scope.locationEquipmentObjectsDeferred);
			const tisObjectsSatsPromise = Promise.all(workingTisObjectIds.map(tisObjectId => serviceAdvisoryService.getTypesByTisObjectId(tisObjectId))).then(
				tisObjectSats =>
					// Add tisObjectId to each SAT and return all SATs as one array
					helpers.flattenDeep(
						tisObjectSats.map((serviceAdvisoryTypes, index) => [
							...serviceAdvisoryTypes.map(sat => ({
								...sat,
								tisObjectId: workingTisObjectIds[index],
							})),
						])
					)
			);

			$q
				.all([allChartsServicePromise, tisObjectsSatsPromise])
				.then(function([chartList, serviceAdvisoryTypeList]) {
					let charts = {};
					chartList.forEach(function(chart) {
						if (chart && chart.tisObjectType && chart.tisObjectType.tisObjectTypeGroupNumber) {
							charts[chart.chartId] = chart.tisObjectType.tisObjectTypeGroupNumber;
						}
					});

					$scope.automatedTests = $filter('orderBy')(serviceAdvisoryTypeList, 'serviceAdvisoryTypeName');
					$scope.automatedTests = serviceAdvisoryService.filterOutAddonExceptionTests($scope.automatedTests);
					$scope.automatedTests = serviceAdvisoryService.filterOutIntermediateTests($scope.automatedTests).filter(automatedTest => {
						return automatedTest.tisObjectType && groupNames.includes(automatedTest.tisObjectType.tisObjectTypeGroupName);
					});

					$scope.automatedTests = $scope.automatedTests.map(function(item) {
						const itemType = item.tisObjectType.tisObjectTypeGroupNumber;
						const chart = chartList.find(({chartId}) => chartId === item.chartId);

						if (item.actionName) {
							item.diActionNameTitle = $filter('translate')('AUTOMATED_TEST_ACTIONS.' + item.actionName);
							item.tisObjectName = $scope.equipment.tisObjectName;
							$scope.serviceAdvisoryTypeIDs.push(item.serviceAdvisoryTypeId);
						}
						if (item.chartId && chart) {
							const parentTisObject = tisObjectService.findParentTisObjectForChart(charts[item.chartId], item, tisObjectsHierarchy);
							if (chart.chartTypeLevel === CHART_TYPE_LEVEL.TIS_OBJECT && itemType !== charts[item.chartId] && !parentTisObject) {
								let tisObjectTypeName = item.tisObjectType.tisObjectTypeGroupName;
								let isChillerSubComponentPresent =
									chillerSubComponents && chillerSubComponents[tisObjectTypeName] && chillerSubComponents[tisObjectTypeName].length;
								if (tisObjectTypeName !== 'Circuit' && tisObjectTypeName !== 'Compressor' && !isChillerSubComponentPresent) {
									item.chartId = null;
									item.noChartInRelationship = true;
								}
							}

							item.chartLink =
								item.chartId &&
								serviceAdvisoryService.generateChartLink(
									{tisObjectId: Number($scope.equipmentId), locationId: Number($scope.locationId)},
									item,
									{tisObjectsHierarchy, chartList}
								);
						}
						return item;
					});

					$scope.automatedTests.forEach(processCircuitAndCompressorAutomatedTests);

					function processCircuitAndCompressorAutomatedTests(automatedTest) {
						const {tisObjectTypeGroupName} = automatedTest.tisObjectType;

						if (tisObjectTypeGroupName === 'Circuit' || tisObjectTypeGroupName === 'Compressor') {
							if (!equipments.includes(automatedTest.tisObjectId)) {
								equipments.push(automatedTest.tisObjectId);
							}

							automatedTest.diActionNameTitle = $filter('translateTest')(automatedTest.actionName, {
								name: $filter('translate')(
									helpers.getPropertyByPath(
										chillerSubComponents[tisObjectTypeGroupName].find(({tisObjectId}) => tisObjectId === automatedTest.tisObjectId),
										'instance'
									)
								),
							});

							automatedTest.subComponentParentTisObjectId = $scope.equipment.tisObjectId;
							automatedTest.subComponentTisObjectId = automatedTest.tisObjectId;
						}
					}

					$scope.automatedTests.forEach(function(automatedTest) {
						let selector = getUniqueAutomatedTestSelector(automatedTest);

						if (automatedTest.serviceAdvisoryTypeId) {
							$scope.automatedTestsCollection[selector] = automatedTest;
						}
					});

					$scope.automatedTestsLoaded = true;

					updateTestStatusColumn();

					incrementProgressIndicator($scope.serviceAdvisoryTypesDeferred);
					equipments.push($scope.equipmentId);
					equipments = equipments.join(',');

					locationDetailsService
						.getLocationDetailsWithoutServiceAdvisories($routeParams.locationId)
						.then(updateDate)
						.then(checkRouteParamDates)
						.then(updateAllRange)
						.then(loadDigitalInspections);
				})
				.catch(errorHandler.showErrorMessage);

			workingTisObjectIds.map(tisObjectId => {
				locationEquipmentService.getEquipmentServiceAdvisoryCount(tisObjectId, function(result) {
					if ($scope.equipmentServiceAdvisoryCount) {
						$scope.equipmentServiceAdvisoryCount =
							result.serviceAdvisoryStatisticsList[0].serviceAdvisoriesCount + $scope.equipmentServiceAdvisoryCount;
					} else {
						$scope.equipmentServiceAdvisoryCount = result.serviceAdvisoryStatisticsList[0].serviceAdvisoriesCount;
					}
				});
			});

			locationEquipmentService.getEquipmentNotesCount($scope.equipment.tisObjectId, function(count) {
				$scope.equipmentNotesCount = count;
			});
		});
		incrementProgressIndicator($scope.locationDetailsDeferred);
		$scope.pageLoaded = false;
	});

	function updateRange() {
		// rootscope.to rootscope.from is not required here , but removing is causing issues
		// to be cleaned
		while ($rootScope.to !== $scope.to) {
			$rootScope.to = $scope.to;
			updateRange();
		}
		while ($rootScope.from !== $scope.from) {
			$rootScope.from = $scope.from;
			updateRange();
		}
		if ($rootScope.to === $scope.to && $rootScope.from === $scope.from) {
			updateText();
		}
	}
	function updateText() {
		if ($scope.from && $scope.to) {
			let appDateRange = {
				startDate: $scope.from,
				endDate: $scope.to,
			};
			$scope.toText = $scope.to.format('dddd, MMMM DD, YYYY');
			$scope.fromText = $scope.from.format('dddd, MMMM DD, YYYY');
			$scope.toDateTestUrl = formatMomentDateForUrl($scope.to);
			$scope.fromDateTestUrl = formatMomentDateForUrl($scope.from);
			urlService.changeDateRangeInUrl($scope.from, $scope.to);
			$rootScope.appDateRange = appDateRange;
		}
	}

	function formatMomentDateForUrl(date) {
		return date instanceof moment
			? date.format(CHART_DATE_FORMAT.RANGE_DATE_FORMAT)
			: moment(date, CHART_DATE_FORMAT.RANGE_DATE_FORMAT).format(CHART_DATE_FORMAT.RANGE_DATE_FORMAT);
	}

	$scope.$watch('from', function() {
		updateRange();
		loadDigitalInspections();
	});
	$scope.$watch('to', function() {
		updateRange();
		loadDigitalInspections();
	});

	$scope.onDatesRangeChange = () => {
		const offering = {
			[DIGITAL_INSPECTION_PAGE_PROPERTIES.OFFERING]:
				$scope.equipment && $scope.equipment.tisObjectType.tisObjectTypeGroupName === 'Chiller' ? 'CMSA' : UNKNOWN,
		};
		const difference = moment($scope.to).diff(moment($scope.from), 'days') + 1;
		$scope.trackOnLinkClick(DIGITAL_INSPECTION_PAGE_EVENTS.CHANGE_DATES_OF_TEST_RESULTS, {
			[DIGITAL_INSPECTION_PAGE_PROPERTIES.DAYS_SELECTED]: difference,
			...offering,
		});
	};

	$scope.closeCalendar = function() {
		$timeout(() => {
			document.body.click();
		}, 0);
	};
	$scope.openCalendar = function() {
		if (angular.element(document.getElementById('diEquipmentDataRangeCalendar').parentNode).hasClass('open')) return;
		setTimeout(function() {
			document.getElementById('diEquipmentDataRangeCalendar').dispatchEvent(
				new MouseEvent('click', {
					view: window,
				})
			);
		}, 10);
	};
	$scope.$on('$routeChangeStart', function(next, current) {
		if ($scope.modal && $scope.modal.dismiss && $scope.modal.opened.$$state.value) {
			try {
				$scope.modal.dismiss();
			} catch (err) {
				return false;
			}
		}
	});

	$scope.showViewsDialog = function(item) {
		let suggestion = getSuggestionByServiceAdvisoryTypeActionName({id: item.actionName});
		modalHelperService.open({
			templateUrl: 'components/service-advisory/view-automated-test.html',
			controller: 'ViewAutomatedTestCtrl',
			backdrop: 'static',
			scope: $scope,
			resolve: {
				suggestion: function() {
					return suggestion;
				},
			},
		});
	};

	function loadDigitalInspections() {
		if (!$scope.serviceAdvisoryTypeIDs.length || loadedStatusProcess) {
			return;
		}
		loadedStatusProcess = true;
		let serviceAdvisoryTypeIDs = $scope.serviceAdvisoryTypeIDs.join(',');
		let callRange = {
			from: $scope.from,
			to: $scope.to,
		};
		$scope.digitalInspectionsPromise = digitalInspectionService
			.getDigitalInspections($scope.locationId, $scope.organizationId, equipments, serviceAdvisoryTypeIDs, callRange, true)
			.then(({failureRateList}) => {
				loadedStatusProcess = false;

				$scope.chartFrom = moment
					.tz($scope.to, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, locationDetailsService.getLocationTimezone())
					.startOf('day')
					.subtract(1, 'week')
					.add(1, 'days')
					.format(CHART_DATE_FORMAT.RANGE_DATE_FORMAT);

				$scope.chartTo = moment
					.tz($scope.to, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, locationDetailsService.getLocationTimezone())
					.startOf('day')
					.format(CHART_DATE_FORMAT.RANGE_DATE_FORMAT);

				incrementProgressIndicator($scope.loadFailureRatesDeferred);
				Array.isArray(failureRateList) &&
					failureRateList.forEach(item => {
						let testItem = $scope.automatedTestsCollection[getUniqueAutomatedTestSelector(item)];

						if (!testItem) return;

						testItem.failureRate = item.failureRatePercentage;

						testItem.firstExceptionStart = item.firstException
							? moment
									.tz(item.firstException, locationDetailsService.getLocationTimezone())
									.startOf('day')
									.subtract(1, 'week')
									.add(1, 'days')
									.format(CHART_DATE_FORMAT.RANGE_DATE_FORMAT)
							: '';
						testItem.firstExceptionTo = item.firstException
							? moment.tz(item.firstException, locationDetailsService.getLocationTimezone()).format(CHART_DATE_FORMAT.RANGE_DATE_FORMAT)
							: '';

						testItem.latestExceptionStart = item.latestException
							? moment
									.tz(item.latestException, locationDetailsService.getLocationTimezone())
									.startOf('day')
									.subtract(1, 'week')
									.add(1, 'days')
									.format(CHART_DATE_FORMAT.RANGE_DATE_FORMAT)
							: '';
						testItem.latestExceptionTo = item.latestException
							? moment.tz(item.latestException, locationDetailsService.getLocationTimezone()).format(CHART_DATE_FORMAT.RANGE_DATE_FORMAT)
							: '';
						let suggestion = getSuggestionByServiceAdvisoryTypeActionName({id: testItem.actionName});
						testItem.testCategory = suggestion && suggestion.category ? suggestion.category : '--';
						testItem.failureIndicator = STATUS.MODE[item.failureRateIndicator] || STATUS.MODE['No data'];
						testItem.latestException = item.latestException
							? moment.tz(item.latestException, locationDetailsService.getLocationTimezone()).format(CHART_DATE_FORMAT.RANGE_DATE_FORMAT)
							: '--';
						testItem.firstException = item.firstException
							? moment.tz(item.firstException, locationDetailsService.getLocationTimezone()).format(CHART_DATE_FORMAT.RANGE_DATE_FORMAT)
							: '--';
						testItem.statusBar = `linear-gradient(to right, ${testItem.failureIndicator.dirpageClassName} ${testItem.failureRate}%, black ${
							testItem.failureRate
						}%)`;
					});
			});
	}

	$scope.getAutomatedTests = function(searchText) {
		if (!$scope.automatedTests) {
			return [];
		}

		let data = $scope.automatedTests.map(item => {
			let suggestion = getSuggestionByServiceAdvisoryTypeActionName({id: item.actionName});
			item.testCategory = suggestion && suggestion.category ? suggestion.category : '--';
			return item;
		});

		if (searchText) {
			data = $filter('filterBySearchText')(data, {
				searchFieldsList: ['tisObjectName', 'diActionNameTitle', 'testCategory', 'failureRate', 'firstException', 'latestException'],
				searchText: $scope.searchObj.searchText,
				searchNested: false,
			});
		}
		data = $filter('tableFilter')(data, this.filterParams);
		$scope.toggleSuppression.canToggleSuppression = false;
		data.some(test => {
			if (test.testStatus && !test.testStatus.isNotSuppressed) {
				$scope.toggleSuppression.canToggleSuppression = true;
				return true;
			}
		});
		return data;
	};

	function saveChillerSubComponents(tisObjects = []) {
		tisObjects.forEach(function(tisObject) {
			if (chillerSubComponents[tisObject.tisObjectType.tisObjectTypeGroupName]) {
				chillerSubComponents[tisObject.tisObjectType.tisObjectTypeGroupName].push(tisObject);
			}
			if (tisObject.children) {
				saveChillerSubComponents(tisObject.children);
			}
		});
	}

	function getUniqueAutomatedTestSelector(automatedTest) {
		return automatedTest.serviceAdvisoryTypeId + '$' + (automatedTest.subComponentTisObjectId || automatedTest.tisObjectId);
	}

	function incrementProgressIndicator(deferred) {
		if (typeof deferred !== 'undefined') {
			deferred.resolve();
		}
		$scope.progress = $scope.progress + progressIncrement;
	}

	function toggleSuppression() {
		if (!toggleSuppression.canToggleSuppression) {
			return;
		}
		$scope.isShowSuppressed = !$scope.isShowSuppressed;
		toggleSuppression.isShowSuppressed = $scope.isShowSuppressed;

		// TODO: add function updateState(state, updateFunction)
		let storedState = uiStateService.getState($scope.uiStateKey);
		storedState.isShowSuppressed = $scope.isShowSuppressed;
		uiStateService.registerState($scope.uiStateKey, storedState);
	}

	/**
	 * Updates test status table column
	 */
	function updateTestStatusColumn() {
		const checkIsSuppressed = (analyticParameterValue = null) => (analyticParameterValue === null ? false : Boolean(Number(analyticParameterValue)));

		$scope.automatedTests.forEach(test => {
			const suppressionAnalyticParameterValue = helpers.getPropertyByPath(test, 'suppressionAnalyticParameter.values[0]');
			const isSuppressed = suppressionAnalyticParameterValue ? checkIsSuppressed(suppressionAnalyticParameterValue.analyticParameterValue) : false;

			test.testStatus = {
				isNotSuppressed: !isSuppressed,
				text: $filter('translate')(isSuppressed ? 'SUPPRESSED' : 'ACTIVE'),
			};
		});

		$scope.$broadcast('treeGridLoaded');
	}

	/**
	 * @param automatedTest automated test object
	 * @returns {boolean} true if row should be shown.
	 */
	function suppressionFilter(automatedTest) {
		if (!automatedTest.testStatus) {
			return true;
		}

		return !(!automatedTest.testStatus.isNotSuppressed && !$scope.isShowSuppressed);
	}

	function openReport(report) {
		if (report === REPORT_TYPES.REPORT_CHILLER_PERFORMANCE_ENGLISH.report && !locationDetailsService.isCprAllowed($scope.location)) {
			return;
		}

		if (report !== REPORT_TYPES.RAW_DATA.report || ($scope.location && $scope.location.locationName)) {
			modalInstance = modalHelperService.open({
				templateUrl: 'components/reports/report-dialog.html',
				controller: 'ReportDialogCtrl',
				backdrop: 'static',
				windowClass: 'report-dialog full-height',
				resolve: {
					data: function() {
						return {
							report: report,
							locationData: $scope.location,
							defaultSelection: $scope.equipment,
							equipmentsData: [$scope.equipment],
							isEquipmentSummary: true,
							rangeFrom: $scope.from,
							rangeTo: $scope.to,
							maxDt: $scope.maxDt,
							rangeMode: 'custom',
						};
					},
				},
			});
		}
	}
}
