import {BINARY_PROPERTY_MAPPING} from '../../../settings/property/property-mapping';
import _get from 'lodash.get';

(function(d3, moment) {
	const HOVERED_OVERLAY_LEFT_CLASS = 'hovered-overlay-left';
	const HOVERED_OVERLAY_RIGHT_CLASS = 'hovered-overlay-right';
	const HOVERED_OVERLAY_OPACITY = '0.6';
	const MAX_TIME_LINE_HEIGHT = 300;
	const TEXT_SIZE = 8;
	const MULTI_BAR_HEIGHT = 6;
	const binaryBarHeight = 7;
	const MIN_BAR_HEIGHT = 3;
	const MAX_TIMELINE_SPACE = 35;
	const MIN_TIMELINE_SPACE = 0;
	const MIN_WIDTH = 20;
	const BAR_PADDING = {
		left: 4,
		bottom: 4,
		top: 4,
	};

	function TimelineRenderer(chartId, translate, svgMainGroup, timelineData, tooltip, debounce, x) {
		this.tooltip = tooltip;
		this.debounce = debounce;
		this.translate = translate;
		this.svgMainGroup = svgMainGroup;
		this.timelineNode = svgMainGroup.append('g').attr('class', 'timeline');
		this.timelineData = timelineData;
		this.height = 0;
		this.chartId = chartId;
		this.timelineTop = 0;
		this.timelineHeight = 0;
		this.timelineSpace = 0;
		this.labelAxis = d3.svg.axis().scale(x);
	}

	TimelineRenderer.prototype.createTimeline = function() {
		this.timelineAxis = this.timelineNode.append('g').attr('class', 'grid');
		this.timelineGrid = this.timelineNode.append('g').attr('class', 'axis');
		this.timelineBar = this.timelineNode.append('g').attr('clip-path', `url(#clipBorder${this.chartId})`);
	};

	TimelineRenderer.prototype.onSvgSizeUpdate = function({height, width}) {
		this.height = height;
		this.width = width;
	};

	TimelineRenderer.prototype.onBrushRange = function(range, tz) {
		this.maxTimelineWidthScale = calculateMaxTimelineWidthScale(range, tz);
	};

	TimelineRenderer.prototype.onRangeXUpdated = function(range, tz) {
		this.timezone = tz;
		this.maxTimelineWidthScale = calculateMaxTimelineWidthScale(range, tz);
	};

	TimelineRenderer.prototype.removeTimeline = function() {
		this.timelineNode.selectAll('*').remove();
	};

	TimelineRenderer.prototype.updateTimeline = function(chartObj) {
		if (!hasLanes(this.timelineData)) {
			return;
		}

		let textShifted = (MULTI_BAR_HEIGHT + TEXT_SIZE) / 2 + this.timelineTop;

		this.timelineBar
			.selectAll('.clipText, .barRect')
			.attr('x', d => chartObj.x(d.start))
			.attr('y', d => this.getLaneProperty(d.lane, 'top') + this.timelineTop)
			.attr('width', d => this.calculateBarWidth(d, chartObj));

		this.timelineBar
			.selectAll('.grey-line')
			.attr('y', d => d.top + this.timelineTop)
			.attr('width', d => this.width * this.maxTimelineWidthScale)
			.attr('height', d => d.height / 1.5);

		this.timelineBar
			.selectAll('clipPath')
			.attr('width', d => {
				let barWidth = Math.min(chartObj.x(d.end) - chartObj.x(d.start), chartObj.x(new Date()) - chartObj.x(d.start));
				return barWidth > BAR_PADDING.left ? barWidth - BAR_PADDING.left : barWidth;
			})
			.attr('x', d => chartObj.x(d.start))
			.attr('y', d => this.getLaneProperty(d.lane, 'top') + this.timelineTop);

		this.timelineBar
			.selectAll('text')
			.attr('x', d => chartObj.x(d.start))
			.attr('dx', BAR_PADDING.left)
			.attr('dy', d => this.getLaneProperty(d.lane, 'top') + textShifted)
			.attr('display', d => {
				if (!this.getLaneProperty(d.lane, 'multiState') || chartObj.x(d.end) - chartObj.x(d.start) < MIN_WIDTH || !this.timelinePropertyIsMultiState(d))
					return 'none';
			});
	};

	function addHoverHandlers(that, chartObj, bar) {
		let timeout;

		bar
			.on(
				'mouseover',
				that.debounce(function(d) {
					let laneMetadata = that.timelineData.lanes[d.lane];
					let start = chartObj.x(d.start) < 0 ? 0 : chartObj.x(d.start);
					let end = chartObj.x(d.end) > that.width ? that.width : chartObj.x(d.end);
					let xPos = (start + end) / 2;
					let rect = this.childNodes[0];
					let height = parseInt(rect.getAttribute('height')) / 2;
					let top = parseInt(rect.getAttribute('y'));
					let yPos = height + top;
					let text = laneMetadata.multiState ? d.text : undefined;
					let color = laneMetadata.color;
					let hasNoData = d.text === null;
					let propertyName = laneMetadata.name;
					let currentRectangle = d3.select(this).select('rect.multistate');
					let leftOverlay = d3.select('.' + HOVERED_OVERLAY_LEFT_CLASS);
					let rightOverlay = d3.select('.' + HOVERED_OVERLAY_RIGHT_CLASS);
					let startTime = d.start;
					let endTime = d.end;

					if (d.text === null) {
						text = that.translate('LABEL_TIMELINE_NO_DATA');
					} else if (laneMetadata.isException) {
						// Hiding 'Yes/no' timeline captions for Exceptions
						text = '';
					}

					if (!currentRectangle.empty()) {
						clearTimeout(timeout);

						leftOverlay = d3.select(`.${HOVERED_OVERLAY_LEFT_CLASS}`);
						rightOverlay = d3.select(`.${HOVERED_OVERLAY_RIGHT_CLASS}`);

						if (!leftOverlay.empty()) {
							leftOverlay
								.attr('fill-opacity', HOVERED_OVERLAY_OPACITY)
								.attr('width', start)
								.attr('display', '');
						} else {
							that.timelineBar
								.append('rect')
								.attr('clip-path', `url(#clipBorder${that.chartId})`)
								.attr('id', 'left-overlay-rect')
								.attr('fill-opacity', HOVERED_OVERLAY_OPACITY)
								.attr('class', HOVERED_OVERLAY_LEFT_CLASS)
								.attr('x', 0)
								.attr('y', -3)
								.attr('width', start)
								// height must be as attribute of rect, not in css file.
								// If height is defined in css file, current functionality works only in Chrome.
								.attr('height', '100%');

							// Since the Z-order of SVG elements is dictated by the order of the SVG DOM elements in their container,
							// render overlay on the top level, using <use> SVG tag, because it should overlay all dots/lines/timelines, etc.
							that.svgMainGroup
								.append('use')
								.attr('href', '#left-overlay-rect')
								.attr('id', 'left-overlay-rect-moved');
						}

						if (!rightOverlay.empty()) {
							rightOverlay
								.attr('fill-opacity', HOVERED_OVERLAY_OPACITY)
								.attr('x', +currentRectangle.attr('x') + Number(currentRectangle.attr('width')))
								.attr('display', '');
						} else {
							that.timelineBar
								.append('rect')
								.attr('clip-path', `url(#clipBorder${that.chartId})`)
								.attr('id', 'right-overlay-rect')
								.attr('fill-opacity', HOVERED_OVERLAY_OPACITY)
								.attr('class', HOVERED_OVERLAY_RIGHT_CLASS)
								.attr('x', +currentRectangle.attr('x') + Number(currentRectangle.attr('width')))
								.attr('y', -3)
								.attr('width', '100%')
								.attr('height', '100%');

							// Since the Z-order of SVG elements is dictated by the order of the SVG DOM elements in their container,
							// render overlay on the top level, using <use> SVG tag, because it should overlay all dots/lines/timelines, etc.
							that.svgMainGroup
								.append('use')
								.attr('href', '#right-overlay-rect')
								.attr('id', 'right-overlay-rect-moved');
						}
					}

					if (chartObj.commonRenderer.range && chartObj.commonRenderer.range.brush) {
						const [tFrom, tTo] = chartObj.x.domain();
						if (startTime < tFrom) startTime = tFrom;
						if (endTime > tTo) endTime = tTo;
					}

					that.tooltip.showTooltip(
						{
							xPos: xPos,
							yPos: yPos,
							name: text,
							hasNoData: hasNoData,
							propertyName: propertyName,
							color: getColor(color, d),
							startPoint: moment(startTime).format('M/D/YY h:mm A'),
							endPoint: moment(endTime).format('M/D/YY h:mm A'),
						},
						true
					);
				}, 100)
			)
			.on(
				'mouseout',
				that.debounce(function() {
					that.tooltip.hideTooltip();
					let leftOverlay = d3.select(`.${HOVERED_OVERLAY_LEFT_CLASS}`);
					let rightOverlay = d3.select(`.${HOVERED_OVERLAY_RIGHT_CLASS}`);

					rightOverlay.attr('fill-opacity', 0);
					leftOverlay.attr('fill-opacity', 0);

					// Timeout is used to remove element after fadeout
					timeout = setTimeout(() => {
						// Remove those elements, because on multiple charts their position is fixed.
						rightOverlay.remove();
						leftOverlay.remove();
						that.svgMainGroup.select('use#left-overlay-rect-moved').remove();
						that.svgMainGroup.select('use#right-overlay-rect-moved').remove();
					}, 100);
				}, 100)
			);
	}

	// TODO: @problematic chartObj
	TimelineRenderer.prototype.calculateBarWidth = function(d, chartObj) {
		const dateWithoutTimezone = moment(stripTz(moment(), this.timezone));
		const width1 = chartObj.x(d.end) - chartObj.x(d.start);
		const width2 = chartObj.x(dateWithoutTimezone) - chartObj.x(d.start);
		let barWidth = Math.min(width1, width2);

		if (moment(d.end).isSame(moment(chartObj.endTime), 'second') || !barWidth) {
			return barWidth + 2;
		}

		return barWidth;
	};

	// TODO: @problematic chartObj
	TimelineRenderer.prototype.drawTimeline = function(chartObj) {
		const that = this;
		const textShifted = (MULTI_BAR_HEIGHT + TEXT_SIZE) / 2 + this.timelineTop;
		const now = moment(stripTz(moment(), this.timezone));

		if (this.timelineBar) {
			this.timelineBar.selectAll('*').remove();
		}

		if (!hasLanes(this.timelineData)) {
			return;
		}

		const lanesData = this.timelineData.lanes.filter(
			d => d.visible && !(d.isException && d.isCompletelySuppressed) && !(d.isSuppression && d.isDisabledByLegendFilter)
		);

		that.timelineBar
			.selectAll('grey-line')
			.data(lanesData)
			.enter()
			.append('rect')
			.attr('class', 'grey-line')
			.attr('y', d => d.top + this.timelineTop)
			.attr('width', d => this.width * this.maxTimelineWidthScale)
			.attr('height', d => d.height);

		// Mapping which data blocks need to be hidden
		that.timelineData.data.map(function(dataItem) {
			dataItem = that.remapBooleanValuesInTimeline(dataItem);

			if (dataItem.end && dataItem.end > now) {
				dataItem.end = roundTimeValueToQuarterHour(now).toDate();
			}

			return dataItem;
		});

		const visibleTimelineData = that.timelineData.data
			.filter(d => that.getLaneProperty(d.lane, 'visible'))
			.filter(d => !(that.getLaneProperty(d.lane, 'isSuppression') && that.getLaneProperty(d.lane, 'isDisabledByLegendFilter')));
		const bar = that.timelineBar
			.selectAll('bar')
			.data(visibleTimelineData)
			.enter()
			.append('g')
			.attr('class', 'bar');

		addHoverHandlers(this, chartObj, bar);

		const getAttrStyle = transparentValue => data => {
			const color = this.getLaneColor(data);
			return data.isTransparent ? transparentValue : getColor(color, data);
		};

		bar
			.append('rect')
			.attr('stroke', getAttrStyle('none'))
			.attr('fill', getAttrStyle(false))
			.attr('fill-opacity', d => (d.isTransparent ? '0' : false))
			.attr('class', d => getClassList.call(this, d))
			.attr('x', d => chartObj.x(d.start))
			.attr('y', d => this.getLaneProperty(d.lane, 'top') + this.timelineTop)
			.attr('width', d => this.calculateBarWidth(d, chartObj))
			.attr('height', d => this.getLaneProperty(d.lane, 'height'));

		bar
			.append('clipPath')
			.attr('id', (d, i) => 'clipText' + i + this.chartId)
			.append('rect')
			.attr('class', 'clipText')
			.attr('width', d => {
				const width1 = chartObj.x(d.end) - chartObj.x(d.start);
				const width2 = chartObj.x(now) - chartObj.x(d.start);
				let barWidth = Math.min(width1, width2);
				return barWidth > BAR_PADDING.left ? barWidth - BAR_PADDING.left : barWidth;
			})
			.attr('height', MULTI_BAR_HEIGHT)
			.attr('display', d => {
				if (!this.getLaneProperty(d.lane, 'multiState') || this.getLaneProperty(d.lane, 'height') !== MULTI_BAR_HEIGHT) {
					return 'none';
				}
			})
			.attr('x', d => chartObj.x(d.start))
			.attr('y', d => this.getLaneProperty(d.lane, 'top') + this.timelineTop);

		bar
			.append('text')
			.text(d => {
				if (this.getLaneProperty(d.lane, 'multiState')) {
					return d.text;
				}
			})
			.attr('clip-path', (d, i) => {
				if (this.getLaneProperty(d.lane, 'multiState')) {
					return 'url(#clipText' + i + this.chartId + ')';
				}
			})
			.attr('display', d => {
				const isNotMultiState = !this.getLaneProperty(d.lane, 'multiState') || !this.timelinePropertyIsMultiState(d);

				if (isNotMultiState || chartObj.x(d.end) - chartObj.x(d.start) < MIN_WIDTH) {
					return 'none';
				}
			})
			.attr('x', d => chartObj.x(d.start))
			.attr('y', 0)
			.attr('dx', BAR_PADDING.left)
			.attr('dy', d => this.getLaneProperty(d.lane, 'top') + textShifted);
	};

	TimelineRenderer.prototype.remapBooleanValuesInTimeline = function(chunk) {
		const propertyName = (this.timelineData.lanes[chunk.lane] || {}).property;
		const valueMapping = BINARY_PROPERTY_MAPPING[propertyName] || BINARY_PROPERTY_MAPPING.DefaultBinaryProperty;

		chunk.isTransparent = false;

		if (propertyName) {
			const propertyState = valueMapping.find(item => item.variants.includes(chunk.text));

			if (propertyState) {
				chunk.text = this.translate(propertyState.displayValueTranslationKey);
				chunk.isTransparent = propertyState.transparent;

				if (propertyState.color) {
					chunk.colorIndex = propertyState.colorIndex;
				}
			}
		}

		return chunk;
	};

	TimelineRenderer.prototype.recalculateLinesHeight = function(binaryLinesCount, multiLinesCount) {
		let overHeight = this.timelineHeight - MAX_TIME_LINE_HEIGHT;
		let maxCompressBinary = binaryLinesCount * (binaryBarHeight - MIN_BAR_HEIGHT);
		let maxCompressMulti = multiLinesCount * (MULTI_BAR_HEIGHT - MIN_BAR_HEIGHT);
		let maxCompress = maxCompressBinary + maxCompressMulti;
		let currHeight = 0;
		let compress = {
			multiBarHeight: MIN_BAR_HEIGHT,
			binaryBarHeight: MIN_BAR_HEIGHT,
		};

		if (overHeight < maxCompressBinary) {
			compress.binaryBarHeight = binaryBarHeight - overHeight / binaryLinesCount;
			compress.multiBarHeight = MULTI_BAR_HEIGHT;
		} else if (overHeight < maxCompress) {
			compress.binaryBarHeight = binaryBarHeight - overHeight * (maxCompressBinary / maxCompress) / binaryLinesCount;
			compress.multiBarHeight = MULTI_BAR_HEIGHT - overHeight * (maxCompressMulti / maxCompress) / multiLinesCount;
		}

		this.timelineData.lanes.forEach(d => {
			if (d.visible) {
				d.top = currHeight;
				d.height = d.multiState ? compress.multiBarHeight : compress.binaryBarHeight;
				currHeight += d.height + BAR_PADDING.bottom;
			}
		});

		this.timelineHeight = currHeight + BAR_PADDING.top;
		this.timelineTop = this.height - currHeight;
	};

	TimelineRenderer.prototype.getLaneProperty = function(line, name) {
		return _get(this.timelineData, `lanes[${line}][${name}]`, 0);
	};

	TimelineRenderer.prototype.getLaneColor = function(row) {
		return row.text === null ? '#000' : this.getLaneProperty(row.lane, 'color');
	};

	TimelineRenderer.prototype.timelinePropertyIsMultiState = function(chunk) {
		const multiStateProperties = ['ChillerOperatingMode'];
		const laneMetadata = this.timelineData.lanes[chunk.lane] || {};
		return multiStateProperties.includes(laneMetadata.property);
	};

	TimelineRenderer.prototype._updateAxisLabel = function(offset, labelNumber) {
		let d3LocaleId = d3Locale[d3Locale.localeId];

		this.timelineAxis.attr('transform', `translate(0, ${this.height})`);
		this.timelineAxis
			.call(
				this.labelAxis
					.orient('bottom')
					.ticks(labelNumber)
					.tickFormat(
						d3Locale.current.timeFormat.multi([
							[d3LocaleId.minutes, d => d.getMinutes()],
							[d3LocaleId.timeShort, d => d.getHours()],
							[d3LocaleId.dateShort, d => d.getDate()],
						])
					)
					.tickSize(0, 0, 0)
			)
			.selectAll('text')
			.attr('dy', offset);
	};

	TimelineRenderer.prototype.updateTimelineHeight = function(height) {
		let currHeight = 0;
		let binaryLinesCount = 0;
		let multiLinesCount = 0;

		if (hasLanes(this.timelineData)) {
			this.timelineSpace = MAX_TIMELINE_SPACE;
			this.timelineData.lanes.forEach(lane => {
				if (lane.visible && !(lane.isSuppression && lane.isDisabledByLegendFilter)) {
					lane.top = currHeight;
					lane.height = lane.multiState ? MULTI_BAR_HEIGHT : binaryBarHeight;
					currHeight += lane.height + BAR_PADDING.bottom;

					if (!lane.multiState) {
						binaryLinesCount++;
					}

					if (lane.multiState) {
						multiLinesCount++;
					}
				}
			});
		}

		if (currHeight === 0) {
			this.timelineSpace = MIN_TIMELINE_SPACE;
			this.removeTimeline();
		} else if (this.timelineNode.node().childNodes.length === 0) {
			this.createTimeline();
		}

		this.timelineHeight = currHeight + BAR_PADDING.top;
		this.timelineTop = height - currHeight;

		if (this.timelineHeight > MAX_TIME_LINE_HEIGHT) {
			this.recalculateLinesHeight(binaryLinesCount, multiLinesCount, this);
		}

		const chartHeight = height - this.timelineHeight - this.timelineSpace;
		const timelineHeight = this.timelineHeight;
		return {chartHeight, timelineHeight};
	};

	TimelineRenderer.prototype.setVisibilityForAllLanes = function(isVisible) {
		if (hasLanes(this.timelineData)) {
			this.timelineData.lanes.forEach(lane => (lane.visible = isVisible));
		}
	};

	TimelineRenderer.prototype.setTimelineGrid = function(labelNumber, offset, xAxis) {
		const y = this.height - this.timelineHeight;
		this.timelineGrid.attr('transform', `translate(0, ${y})`);
		const x = xAxis
			.ticks(labelNumber)
			.tickSize(this.timelineHeight, 0, 0)
			.tickFormat('');
		this.timelineGrid
			.call(x)
			.selectAll('.tick')
			.select('line')
			.attr('class', 'major');
		this._updateAxisLabel(offset, labelNumber);
	};

	function calculateMaxTimelineWidthScale(range, tz) {
		let tFrom = range.brush ? range.brush.from : range.from;
		let tTo = range.brush ? range.brush.to : range.to;
		let now = moment(stripTz(moment(), tz));
		let tNow = roundTimeValueToQuarterHour(now);
		tFrom = moment(stripTz(tFrom, tz));
		tTo = moment(stripTz(tTo, tz));
		tTo = roundTimeValueToQuarterHour(tTo);

		if (tTo > tNow && tTo > tFrom) {
			return 1 - (tTo - tNow) / (tTo - tFrom);
		}

		return 1;
	}

	function roundTimeValueToQuarterHour(value) {
		let timeValue = moment(value);
		return timeValue.minutes(timeValue.minutes() - timeValue.minutes() % 15).seconds(0);
	}

	function hasLanes(data) {
		return data && data.lanes && data.lanes.length;
	}

	function stripTz(date, timeZone) {
		return new Date(
			moment(date)
				.tz(timeZone)
				.format('DD MMMM YYYY HH:mm')
		);
	}

	function getClassList(d) {
		let classList = 'barRect';

		if (this.getLaneProperty(d.lane, 'multiState')) {
			classList += ' multistate';
		}
		if (d.text === null) {
			classList = 'barRect nodata';
		}
		if (d.isTransparent) {
			classList += ' invisible';
		}

		return classList;
	}

	function getColor(color, {colorIndex}) {
		return Array.isArray(color) ? color[colorIndex] : color;
	}

	window.TimelineRenderer = TimelineRenderer;
})(window.d3, window.moment);
