/* global process*/

import amplitudeJs from 'amplitude-js';
import {UsageTracker} from '../../usage-tracker';
import {DEFAULT_PROPERTIES_TO_SEND_BY_EVENT} from '../../common';
import {PROPERTIES_TO_SEND_BY_EVENT} from '../../categories';

const services = new WeakMap();
const privateFields = new WeakMap();

export class AmplitudeUsageTracker extends UsageTracker {
	constructor(configService, utilityService) {
		super();

		services.set(this, {configService, utilityService});

		let resolveInitializationPromise = null;
		const initializationPromise = new Promise(resolve => (resolveInitializationPromise = resolve));

		privateFields.set(this, {
			amplitudeInstance: amplitudeJs.getInstance(),
			appVersion: '',
			initializationPromise,
			resolveInitializationPromise,
			isInitialized: false,
			userSessionStatus: UsageTracker.USER_SESSION_STATUS.PRISTINE,
		});
	}

	async initialize() {
		const {configService, utilityService} = services.get(this);
		const {isInitialized, amplitudeInstance} = privateFields.get(this);

		if (isInitialized) {
			throw new Error('The Service is already initialized');
		}

		const {version: appVersion} = await utilityService.getAppVersionData();

		amplitudeInstance.init(configService.getAmplitudeKey());

		privateFields.get(this).appVersion = appVersion;
		privateFields.get(this).isInitialized = true;
		privateFields.get(this).resolveInitializationPromise();
	}

	get isUserSessionStarted() {
		return privateFields.get(this).userSessionStatus === UsageTracker.USER_SESSION_STATUS.STARTED;
	}

	startUserSession(userData = null) {
		return privateFields.get(this).initializationPromise.then(() => {
			privateFields.get(this).userSessionStatus = UsageTracker.USER_SESSION_STATUS.STARTED;
			userData && this.trackUser(userData);
		});
	}

	endUserSession() {
		privateFields.get(this).userSessionStatus = UsageTracker.USER_SESSION_STATUS.ENDED;
	}

	trackUser(userInfo) {
		const {amplitudeInstance} = privateFields.get(this);
		const id = new amplitudeJs.Identify();

		id.set('userId', userInfo.userId);
		id.set('roles', userInfo.roles);
		id.set('Client', 'CCUI');
		id.set('Version', privateFields.get(this).appVersion);

		amplitudeInstance.setUserId(userInfo.userId);
		amplitudeInstance.identify(id);
		amplitudeInstance.logEvent('LOGGED_IN', {});
	}

	trackEvent(category, event, customProperties = {}) {
		return privateFields.get(this).initializationPromise.then(() => {
			const {amplitudeInstance} = privateFields.get(this);

			amplitudeInstance.logEvent(event, {
				...DEFAULT_PROPERTIES_TO_SEND_BY_EVENT,
				...PROPERTIES_TO_SEND_BY_EVENT[event],
				...customProperties,
			});
		});
	}

	trackEventByCategory(category, defaultProperties = {}) {
		return (event, customProperties = {}) => this.trackEvent(category, event, {...defaultProperties, ...customProperties});
	}
}
