import {CPR_DATE_FORMATS} from '../chiller-performance-report-config';

const services = new WeakMap();

class ChillerPerformanceModalsService {
	constructor(
		modalHelperService,
		chillerPerformanceReportApiService,
		objectTypeService,
		analyticParameterService,
		CPR_REPORT_SECTIONS,
		CPR_DETAILS_ACTION_TYPE,
		googleAnalyticsService,
		$log
	) {
		services.set(this, {
			modalHelperService,
			chillerPerformanceReportApiService,
			objectTypeService,
			analyticParameterService,
			CPR_REPORT_SECTIONS,
			CPR_DETAILS_ACTION_TYPE,
			googleAnalyticsService,
			$log,
		});
	}

	async chillerProfileSettingsHandler({tisObject, range, maxDt, otherInfo}) {
		const dialogParams = ['ChillerCapacityNominal', 'ChillerEnteredService'];
		const {modalHelperService, objectTypeService, analyticParameterService, googleAnalyticsService, CPR_REPORT_SECTIONS, $log} = services.get(this);
		const componentHtmlName = 'chiller-profile-settings-popup-component';
		const {[CPR_REPORT_SECTIONS.CHILLER_PROFILE]: chillerProfileData = {}, [CPR_REPORT_SECTIONS.CHILLER_LIFE]: chillerLifeData = {}} = otherInfo;

		const modalInstance = modalHelperService.open({
			template: this._generateTemplate(componentHtmlName),
			controller: function($scope, $modalInstance, locationDetailsService) {
				$scope.$modalInstance = $modalInstance;
				$scope.data = {
					maxDt: maxDt.clone(),
					values: chillerProfileData,
					timeZone: locationDetailsService.getLocationTimezone(),
				};
			},
			backdrop: 'static',
			windowClass: 'chiller-profile-settings-popup small',
		});

		const result = await modalInstance.result;

		otherInfo[CPR_REPORT_SECTIONS.CHILLER_LIFE] = {
			...chillerLifeData,
			...result,
		};

		otherInfo[CPR_REPORT_SECTIONS.CHILLER_PROFILE] = {
			...chillerProfileData,
			...result,
		};

		for (let parameterObject of await Promise.all(dialogParams.map(parameterName => objectTypeService.getObjectParameterByName(parameterName)))) {
			// The analytic parameter values are set one by one because if they are set simultaneously the HTTP 500 is returned.
			// It could be rewritten as soon as the API side will handle several setups of analytic parameters values at one time.
			await analyticParameterService.setAnalyticParameterValueInTisObjectScope({
				parameterId: parameterObject.parameterId,
				tisObjectId: tisObject.tisObjectId,
				requestBody: {
					analyticParameterValue: result[parameterObject.parameterName],
					startDate: range.from.clone(),
				},
			});
		}

		googleAnalyticsService.events.CPR.sectionDataChanged(CPR_REPORT_SECTIONS.CHILLER_PROFILE);

		return {[CPR_REPORT_SECTIONS.CHILLER_PROFILE]: result};
	}

	async chillerLifeSettingsHandler({tisObject, range, maxDt, otherInfo}) {
		const dialogParams = ['ChillerEnteredService', 'ChillerLife'];
		const {modalHelperService, analyticParameterService, objectTypeService, googleAnalyticsService, CPR_REPORT_SECTIONS, $log} = services.get(this);
		const componentHtmlName = 'chiller-life-settings-popup-component';
		const {[CPR_REPORT_SECTIONS.CHILLER_LIFE]: chillerLifeData = {}, [CPR_REPORT_SECTIONS.CHILLER_PROFILE]: chillerProfileData = {}} = otherInfo;

		const modalInstance = modalHelperService.open({
			template: this._generateTemplate(componentHtmlName),
			controller: function($scope, $modalInstance, locationDetailsService) {
				$scope.$modalInstance = $modalInstance;
				$scope.data = {
					maxDt: maxDt.clone(),
					values: chillerLifeData,
					timeZone: locationDetailsService.getLocationTimezone(),
				};
			},
			backdrop: 'static',
			windowClass: 'chiller-life-settings-popup small',
		});

		const result = await modalInstance.result;

		otherInfo[CPR_REPORT_SECTIONS.CHILLER_PROFILE] = {
			...chillerProfileData,
			...result,
		};

		otherInfo[CPR_REPORT_SECTIONS.CHILLER_LIFE] = {
			...chillerLifeData,
			...result,
		};

		for (let parameterObject of await Promise.all(dialogParams.map(parameterName => objectTypeService.getObjectParameterByName(parameterName)))) {
			await analyticParameterService.setAnalyticParameterValueInTisObjectScope({
				parameterId: parameterObject.parameterId,
				tisObjectId: tisObject.tisObjectId,
				requestBody: {
					analyticParameterValue: result[parameterObject.parameterName],
					startDate: range.from.clone(),
				},
			});
		}

		googleAnalyticsService.events.CPR.sectionDataChanged(CPR_REPORT_SECTIONS.CHILLER_LIFE);

		return {[CPR_REPORT_SECTIONS.CHILLER_LIFE]: result};
	}

	async performanceImprovementsSettingsHandler({tisObject, otherInfo}) {
		const {modalHelperService, chillerPerformanceReportApiService, googleAnalyticsService, CPR_REPORT_SECTIONS, CPR_DETAILS_ACTION_TYPE} = services.get(
			this
		);
		const componentHtmlName = 'performance-improvements-settings-popup-component';
		let {cprSavedData = {}, cprSavedData: {chillerPerformanceReportId}} = otherInfo;
		const isNewReport = !chillerPerformanceReportId;

		const modalInstance = modalHelperService.open({
			template: this._generateTemplate(componentHtmlName),
			controller: function($scope, $modalInstance) {
				$scope.$modalInstance = $modalInstance;
				$scope.data = {
					values: cprSavedData.details.filter(item => item.actionType === CPR_DETAILS_ACTION_TYPE.IMPROVEMENT && item.actionPriority),
				};
			},
			backdrop: 'static',
			windowClass: 'performance-improvements-settings-popup',
		});

		const result = await modalInstance.result;
		const oldDetails = (cprSavedData.details && cprSavedData.details.length ? cprSavedData.details : []).filter(item => {
			return !item.actionType || item.actionType !== CPR_DETAILS_ACTION_TYPE.IMPROVEMENT;
		});
		const newDetails = result && result.improvements && result.improvements.length ? result.improvements : [];
		const cprUpdatedData = {
			...cprSavedData,
			tisObjectId: tisObject.tisObjectId,
			details: [...oldDetails, ...newDetails],
		};

		({chillerPerformanceReportId} = await chillerPerformanceReportApiService[
			isNewReport ? 'createChillerPerformanceReportData' : 'updateChillerPerformanceReportData'
		](cprUpdatedData));
		cprUpdatedData.chillerPerformanceReportId = chillerPerformanceReportId;

		googleAnalyticsService.events.CPR.sectionDataChanged(CPR_REPORT_SECTIONS.PERFORMANCE_IMPROVEMENTS);
		return {cprSavedData: cprUpdatedData};
	}

	async milestonesSettingsHandler({tisObject, maxDt, otherInfo} = {}) {
		const {modalHelperService, chillerPerformanceReportApiService, googleAnalyticsService, CPR_REPORT_SECTIONS, CPR_DETAILS_ACTION_TYPE} = services.get(
			this
		);
		const componentHtmlName = 'milestones-settings-popup-component';
		let {cprSavedData = {}, cprSavedData: {chillerPerformanceReportId}} = otherInfo;
		const isNewReport = !chillerPerformanceReportId;

		const modalInstance = modalHelperService.open({
			template: this._generateTemplate(componentHtmlName),
			controller: function($scope, $modalInstance) {
				$scope.$modalInstance = $modalInstance;
				$scope.data = {
					maxDt,
					values: cprSavedData.details.filter(item => item.actionType === CPR_DETAILS_ACTION_TYPE.MILESTONE),
				};
			},
			backdrop: 'static',
			windowClass: 'milestones-settings-popup',
		});

		const result = await modalInstance.result;
		const oldDetails = (cprSavedData.details && cprSavedData.details.length ? cprSavedData.details : []).filter(item => {
			return !item.actionType || item.actionType !== CPR_DETAILS_ACTION_TYPE.MILESTONE;
		});
		const newDetails = (result && result.milestones && result.milestones.length ? result.milestones : []).map(item => {
			item.actionType = CPR_DETAILS_ACTION_TYPE.MILESTONE;
			item.actionDate = item.actionDate.format(CPR_DATE_FORMATS.DATA_DATE_FORMAT);
			return item;
		});
		const cprUpdatedData = {
			...cprSavedData,
			tisObjectId: tisObject.tisObjectId,
			details: [...oldDetails, ...newDetails],
		};

		({chillerPerformanceReportId} = await chillerPerformanceReportApiService[
			isNewReport ? 'createChillerPerformanceReportData' : 'updateChillerPerformanceReportData'
		](cprUpdatedData));
		cprUpdatedData.chillerPerformanceReportId = chillerPerformanceReportId;

		googleAnalyticsService.events.CPR.sectionDataChanged(CPR_REPORT_SECTIONS.MILESTONES);
		return {cprSavedData: cprUpdatedData};
	}

	async chillerRunHoursAndStartsSettingsHandler({tisObject, maxDt, otherInfo}) {
		const {modalHelperService, chillerPerformanceReportApiService, googleAnalyticsService, CPR_REPORT_SECTIONS} = services.get(this);
		const componentHtmlName = 'chiller-run-hours-and-starts-settings-popup-component';
		let {cprSavedData = {}, cprSavedData: {chillerPerformanceReportId}} = otherInfo;
		const isNewReport = !chillerPerformanceReportId;

		const modalInstance = modalHelperService.open({
			template: this._generateTemplate(componentHtmlName),
			controller: function($scope, $modalInstance, locationDetailsService) {
				$scope.$modalInstance = $modalInstance;
				$scope.data = {
					maxDt: maxDt.clone(),
					timeZone: locationDetailsService.getLocationTimezone(),
					values: cprSavedData,
				};
			},
			backdrop: 'static',
			windowClass: 'chiller-run-hours-and-starts-settings-popup-component',
		});

		const result = await modalInstance.result;
		const cprUpdatedData = {
			...cprSavedData,
			tisObjectId: tisObject.tisObjectId,
			totalRunHours: result.totalRunHours,
			totalStarts: result.totalStarts,
		};

		({chillerPerformanceReportId} = await chillerPerformanceReportApiService[
			isNewReport ? 'createChillerPerformanceReportData' : 'updateChillerPerformanceReportData'
		](cprUpdatedData));
		cprUpdatedData.chillerPerformanceReportId = chillerPerformanceReportId;

		googleAnalyticsService.events.CPR.sectionDataChanged(CPR_REPORT_SECTIONS.COMPRESSOR_RUN_HOURS_AND_STARTS);
		return {cprSavedData: cprUpdatedData};
	}

	_generateTemplate(componentHtmlName) {
		return `<${componentHtmlName} modal-instance="$modalInstance" data="data"></${componentHtmlName}>`;
	}
}

angular.module('TISCC').service('chillerPerformanceModalsService', ChillerPerformanceModalsService);
