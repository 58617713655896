angular
	.module('TISCC')
	.controller('NotesCtrl', NotesCtrl)
	.controller('ViewNoteFromNotesCtrl', ViewNoteFromNotesCtrl)
	.controller('AddNoteCtrl', AddNoteCtrl)
	.controller('ViewNotesCtrl', ViewNotesCtrl)
	.controller('DeleteAttachmentCtrl', DeleteAttachmentCtrl);

function NotesCtrl($scope, $route, $routeParams, $filter, locationDetailsService, locationEquipmentService, modalHelperService, notesService, configService) {
	$scope.equipmentLevel = $route.current.$$route.equipmentLevel;
	$scope.featureToggles = configService.getFeatureToggles();
	$scope.locationId = $routeParams.locationId;

	locationDetailsService.getLocationDetailsWithoutServiceAdvisories($scope.locationId).then(function(data) {
		$scope.location = data.locationSummaryList[0];
		$scope.navigation = [
			{
				href: '#/facility/' + $scope.locationId,
				title: $scope.location.locationName,
				active: true,
			},
		];

		if ($scope.equipmentLevel) {
			locationEquipmentService.getEquipmentServiceAdvisoryCount($routeParams.equipmentId, function(result) {
				$scope.equipmentServiceAdvisoryCount = result.serviceAdvisoryStatisticsList[0].serviceAdvisoriesCount;
			});
			locationEquipmentService.getLocationObjectsList($scope.locationId).then(function(data) {
				$scope.equipment = $filter('filter')(data.tisObjectList, {tisObjectId: $routeParams.equipmentId}).pop();
				$scope.navigation.push({
					title: $scope.equipment.tisObjectName,
					active: true,
				});

				$scope.navigation[0].active = false;

				$scope.pageTitle = {
					title: $scope.equipment.tisObjectName,
				};

				$scope.equipmentId = $scope.equipment.tisObjectId;
			});
		} else {
			$scope.pageTitle = {
				title: $scope.location.locationName,
			};
		}
	});

	locationDetailsService
		.getLocationServiceAdvisoriesCount($scope.locationId)
		.then(count => ($scope.facilityServiceAdvisoryCount = Number.isInteger(+count) ? Number(count) : '?'))
		.catch(() => ($scope.facilityServiceAdvisoryCount = '?'));

	$scope.sortParams = [
		{
			column: 'created',
			order: true,
		},
	];
	$scope.activeTab = 'notes';

	$scope.showNotesDialog = function(index) {
		modalHelperService.open({
			templateUrl: 'components/notes/view-notes.html',
			controller: 'ViewNoteFromNotesCtrl',
			resolve: {
				index: function() {
					return index;
				},
				getData: function() {
					return function(index) {
						return {
							note: $scope.notes[index],
							showPrev: $scope.notes.length !== index + 1,
							showNext: !!index,
						};
					};
				},
			},
			backdrop: 'static',
		});
	};

	function getTime(date) {
		return Math.abs(date.getHours() * 60 * 60 + (date.getMinutes() + date.getTimezoneOffset()) * 60 + date.getSeconds()) * 1000;
	}

	notesService.getList(function(data) {
		let files, i, j, len, item;
		for (i = data.length; i--; ) {
			item = data[i];
			item.created = new Date(item.created);
			item.createdTime = getTime(item.created);

			files = [];
			for (j = 0, len = item.attachments.length; j < len; j++) files.push(item.attachments[j].name);
			item.files = files.join(', ');
		}
		$scope.notes = data;
	});
}

ViewNoteFromNotesCtrl.$inject = ['$scope', '$modalInstance', 'modalHelperService', 'index', 'getData'];
function ViewNoteFromNotesCtrl($scope, $modalInstance, modalHelperService, index, getData) {
	let notesGroupId;

	$scope.cancel = function() {
		$modalInstance.dismiss('cancel');
	};

	$scope.openAddNoteDialog = function() {
		modalHelperService.open({
			templateUrl: 'components/notes/add-note.html',
			controller: 'AddNoteCtrl',
			backdrop: 'static',
		});
	};

	function applyNotes(index) {
		let data = getData(index);
		$scope.note = data.note;
		$scope.showPrev = data.showPrev;
		$scope.showNext = data.showNext;
		notesGroupId = index;
	}

	$scope.next = function() {
		applyNotes(notesGroupId - 1);
	};

	$scope.prev = function() {
		applyNotes(notesGroupId + 1);
	};

	applyNotes(index);
}

AddNoteCtrl.$inject = ['$scope', '$modalInstance', 'modalHelperService', '$timeout'];
function AddNoteCtrl($scope, $modalInstance, modalHelperService, $timeout) {
	$scope.cancel = function() {
		$modalInstance.dismiss('cancel');
	};
	$scope.approve = function() {
		$modalInstance.close();
	};

	$scope.confirmDelete = function(attachment) {
		modalHelperService
			.open({
				templateUrl: 'components/notes/delete-file.html',
				controller: 'DeleteAttachmentCtrl',
				backdrop: 'static',
			})
			.result.then(function() {
				let index = $scope.attachments.indexOf(attachment);
				$scope.attachments.splice(index, 1);
			});
	};

	$scope.uploadList = [];

	let uploadTimer = 0;
	$scope.uploadFiles = function(obj) {
		for (let i = 0, len = obj.files.length; i < len; i++) {
			$scope.uploadList.push({
				name: obj.files[i].name,
				size: obj.files[i].size,
				loaded: 0,
			});
		}
		obj.parentNode.click();
		obj.value = '';

		function uploadSimulate() {
			if ($scope.uploadList.length) {
				uploadTimer = $timeout(function() {
					for (let i = $scope.uploadList.length; i--; ) {
						if ($scope.uploadList[i].loaded >= 100) {
							$scope.uploadList[i].hasChange = true;
							$scope.attachments.push($scope.uploadList[i]);
							$scope.uploadList.splice(i, 1);
						} else {
							$scope.uploadList[i].loaded += 2;
						}
					}
					uploadSimulate();
				}, 100);
			}
		}
		$timeout.cancel(uploadTimer);
		uploadSimulate();
	};

	$scope.update = function(obj) {
		// Don`t remove
	};

	/* ------------------------------ CC FUNCTIONALITY --------------------------------------- */
	/* $scope.ccList = [];

	$scope.openDropDown = function() {
		var ccCombobox = document.getElementById('ccCombobox');
		if(!angular.element(ccCombobox.parentNode).hasClass("open")){
			ccCombobox.click();
		}
	};

	$scope.addCc = function(ccPerson) {
		ccPerson.hasChange = true;
		$scope.ccList.push(ccPerson);
		document.getElementById('ccSearch').value = "";
	};

	$scope.removeCc = function(ccPerson) {
		$scope.ccList.splice($scope.ccList.indexOf(ccPerson), 1);
	};

	$scope.personTooltip = {};
	var tooltipTimer, pos = {};
	$scope.applyTooltip = function(ccPerson, event) {
		$scope.personTooltip = ccPerson;

		pos.left = event.clientX;
		pos.top = event.clientY;

		tooltipTimer = $timeout(function(){
			var tooltip = document.getElementById("personTooltip"),
				popup = tooltip.parentNode;

			tooltip.style.left = pos.left - popup.offsetLeft + "px";
			tooltip.style.top = pos.top - popup.offsetTop + "px";
			$scope.tooltip = true;
		}, 500);
	};

	$scope.updateTooltip = function(event) {
		pos.left = event.clientX;
		pos.top = event.clientY;
	};

	$scope.hideTooltip = function() {
		$scope.tooltip = false;
		$timeout.cancel(tooltipTimer);
	};

	personService.getCcList(function(data) {
		$scope.personList = data;
	});*/

	$scope.attachments = [];
}

ViewNotesCtrl.$inject = ['$scope', '$modalInstance', '$timeout', 'modalHelperService', 'notesService'];
function ViewNotesCtrl($scope, $modalInstance, modalHelperService, notesService) {
	$scope.cancel = function() {
		$modalInstance.dismiss('cancel');
	};

	$scope.openAddNoteDialog = function() {
		modalHelperService.open({
			templateUrl: 'components/notes/add-note.html',
			controller: 'AddNoteCtrl',
			backdrop: 'static',
		});
	};

	/* ------------------------------ CC FUNCTIONALITY --------------------------------------- */
	/* $scope.personTooltip = {};
	var tooltipTimer, pos = {};
	$scope.applyTooltip = function(ccPerson, event) {
		$scope.personTooltip = ccPerson;

		pos.left = event.clientX;
		pos.top = event.clientY;

		tooltipTimer = $timeout(function(){
			var tooltip = document.getElementById("personTooltip"),
				popup = tooltip.parentNode;

			tooltip.style.left = pos.left - popup.offsetLeft + "px";
			tooltip.style.top = pos.top - popup.offsetTop + "px";
			$scope.tooltip = true;
		}, 500);
	};

	$scope.updateTooltip = function(event) {
		pos.left = event.clientX;
		pos.top = event.clientY;
	};

	$scope.hideTooltip = function() {
		$scope.tooltip = false;
		$timeout.cancel(tooltipTimer);
	};

	$scope.removeCc = function(ccPerson) {
		$scope.ccList.splice($scope.ccList.indexOf(ccPerson), 1);
	};

	$scope.ccList = [];
	*/

	notesService.getViewListByDate(new Date(), function(data) {
		$scope.note = data;
	});
}

DeleteAttachmentCtrl.$inject = ['$scope', '$modalInstance'];
function DeleteAttachmentCtrl($scope, $modalInstance) {
	$scope.cancel = function() {
		$modalInstance.dismiss('cancel');
	};
	$scope.approve = function() {
		$modalInstance.close();
	};
}
