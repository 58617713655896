/**
 * Resolve parent scope from the child on the chain of scopes.
 *
 * @param $scope
 * @param predicate
 * @returns $scope
 */
export const resolveParentScope = ($scope, predicate) => {
	while (!predicate($scope)) {
		$scope = $scope.$parent;
	}

	return $scope;
};
