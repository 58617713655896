(function(d3, AbstractChartRenderer) {
	const MIN_CIRCLE_RADIUS = 170;
	const DEFAULT_LOWER_RANGE_END = 60;
	const DEFAULT_UPPER_RANGE_END = 95;
	const PULL_GAP = 0.1;
	const UNOCCUPIED_MESSAGE_Y_OFFSET = 40;
	const OUTER_ARM_LENGTH = 90;
	const armLength = 30;
	const innerArmLength = 40;
	const LOWER_PERCENTAGE_THRESHOLD = 5;
	const UNOCCUPIED = Symbol('UNOCCUPIED');
	const LOAD_VALVE = 'LoadValve';

	class PieChartRenderer extends AbstractChartRenderer {
		constructor(svg, externalMethods, clipPathId, x) {
			super(externalMethods.tooltip, externalMethods.translate);
			this.x = x;
			this.dataNode = svg.append('g');

			this.color = d3.scale.category20c();
			this.radius = MIN_CIRCLE_RADIUS;
			this.chartAreaCenter = 0;
			this.pieOffsetY = 0;
			this.loadChildEquipment = externalMethods.loadChildEquipment;
			this.data = [];
		}

		onBrushRange(range, chartOptions, chartData) {
			this.extentFrom = stripTz(range.brush.from, range.to.tz()).valueOf() || null;
			this.extentTo = stripTz(range.brush.to, range.to.tz()).valueOf() || null;
			this.dataNode.selectAll('*').remove();
			this.drawData(chartOptions, chartData);
		}

		onSvgReceivedSize(updateData) {
			this.onSvgSizeUpdate(updateData);
		}

		onSvgSizeUpdate(updateData) {
			this.chartAreaCenter = updateData.width / 2;
			this.pieOffsetY = updateData.height / 2;
			this.radius = calculateRadius(updateData.width, updateData.height, 0.13);
		}

		updateChartPos(chartOptions, chartData, options = {}) {
			this.dataNode.selectAll('.piechart .slice, .piechart text').remove();
			this.drawData(chartOptions, chartData, options);
		}

		drawData(chartOptions, chartData, {isBeforePrint = false, isMultipleCharts = false} = {}) {
			this.tooltip.hideTooltip();
			// Options
			const color = this.color;
			const r = this.radius;
			let that = this;

			if (chartOptions && chartData) {
				chartOptions.exportData = processExportData(chartOptions, chartData);
				this.data = buildChartDataset.call(this, chartOptions, chartData);
			}

			if (Array.isArray(this.data) && this.data.length === 0) {
				this.commonRenderer.showEmptyDatasetMessage();
			} else if (this.data === UNOCCUPIED) {
				this.buildVAVBoxesUnoccupiedMessage(chartOptions.isLoadValvePositionChart);
				return;
			}

			let transformAttr = `translate(${this.chartAreaCenter},${this.pieOffsetY})`;

			if (isBeforePrint) {
				let {height: htmlHeight, width: htmlWidth} = d3
					.select('html')
					.node()
					.getBoundingClientRect();

				if (isMultipleCharts || (htmlHeight < 800 || htmlWidth < 1400)) {
					transformAttr += ' scale(.6)';
				}
			}

			let piechart = this.dataNode
				.data([this.data])
				.attr('class', 'piechart')
				.attr('transform', transformAttr);

			let arc = d3.svg.arc().outerRadius(r);
			let pie = d3.layout.pie().value(d => d.value || 0);

			let toggleSliceState = function(d) {
				if (d.isPulled) {
					d.radiusVectorX = 0;
					d.radiusVectorY = 0;
					d.isPulled = false;
				} else {
					d.radiusVectorX = arc.centroid(d)[0] * PULL_GAP;
					d.radiusVectorY = arc.centroid(d)[1] * PULL_GAP;
					d.isPulled = true;
				}

				return d.isPulled;
			};

			let handleSlicePull = function(d, i) {
				const state = toggleSliceState(d);

				if (d.isPulled) {
					piechart
						.selectAll('g.slice')
						.transition()
						.attr('d', d => (d.isPulled = false))
						.attr('transform', 'translate(0,0)');
				}

				d3
					.select(this)
					.transition()
					.attr('d', d => (d.isPulled = state))
					.attr('transform', `translate(${d.radiusVectorX},${d.radiusVectorY})`);

				renderTooltip(that, d, i, 'arc');
				chartOptions.currentlyPulledSlice = d.isPulled ? i : null;
			};

			let arcs = piechart
				.selectAll('g.slice')
				.data(pie)
				.enter()
				.append('g')
				.attr('class', 'slice')
				.on('click', handleSlicePull);

			arcs
				.append('path')
				.attr('fill', (d, i) => color(i))
				.attr('d', arc)
				.on('mouseover', (d, i) => renderTooltip(this, d, i, 'arc'))
				.on('mouseout', () => {
					const hoverTo = d3.event.relatedTarget;
					this.tooltip.hideTooltip(hoverTo);
				});

			arcs
				.append('text')
				.attr('class', 'pie-chart-inner-label')
				.attr('transform', d => {
					d.innerRadius = r - 50;
					d.outerRadius = r;

					d.centroid = arc.centroid(d);

					return 'translate(' + arc.centroid(d) + ')';
				})
				.attr('text-anchor', 'middle')
				.text((d, i) => this.data[i].label)
				.on('mouseover', (d, i) => renderTooltip(this, d, i, 'arc'))
				.on('mouseout', () => {
					const hoverTo = d3.event.relatedTarget;
					this.tooltip.hideTooltip(hoverTo);
				});

			arcs
				.append('line')
				.attr('x1', d => {
					d.innerRadius = r - innerArmLength;
					d.outerRadius = r;
					d.x1 = arc.centroid(d)[0];
					return d.x1;
				})
				.attr('y1', d => {
					d.innerRadius = r - innerArmLength;
					d.outerRadius = r;
					d.y1 = arc.centroid(d)[1];
					return d.y1;
				})
				.attr('x2', d => {
					d.innerRadius = r + OUTER_ARM_LENGTH;
					d.outerRadius = r + OUTER_ARM_LENGTH;
					d.x2 = arc.centroid(d)[0];
					return d.x2;
				})
				.attr('y2', d => {
					d.innerRadius = r + OUTER_ARM_LENGTH;
					d.outerRadius = r + OUTER_ARM_LENGTH;
					d.y2 = arc.centroid(d)[1];
					return d.y2;
				})
				.attr('class', 'piechart-arm');

			arcs
				.append('line')
				.attr('x1', d => d.x2)
				.attr('y1', d => d.y2)
				.attr('x2', d => {
					d.x3 = d.x2 > 0 ? d.x2 + armLength : d.x2 - armLength;
					return d.x3;
				})
				.attr('y2', d => {
					d.y3 = d.y2;
					return d.y3;
				})
				.attr('stroke', '#929292')
				.attr('stoke-width', '1');

			arcs
				.append('text')
				.attr('class', 'piechart-outer-label')
				.attr('transform', d => {
					if (d.x3 < 0) {
						d.x3 = d.x3 - d.data.armLabel.length * 6;
					} else {
						d.x3 += 5;
					}

					d.y3 += 5;

					return 'translate(' + d.x3 + ',' + d.y3 + ')';
				})
				.attr('text-anchor', d => (d.x3 > 0 ? 'right' : 'left'))
				.text((d, i) => this.data[i].armLabel)
				.on('mouseover', (d, i) => {
					d3.select('body').style('cursor', 'pointer');
					renderTooltip(this, d, i, 'label');
				})
				.on('mouseout', () => {
					d3.select('body').style('cursor', 'default');
					this.tooltip.hideTooltip();
				})
				.on('click', pie => {
					let equipmentId = pie.data.tisObjectId;
					const tisObjectType = pie.data.tisObjectType;
					if (equipmentId && tisObjectType !== LOAD_VALVE) {
						this.loadChildEquipment(equipmentId);
					}
				});

			arcs.each(function(d, i) {
				if (chartOptions.currentlyPulledSlice === i) {
					toggleSliceState(d);
					this.setAttribute('transform', `translate(${d.radiusVectorX},${d.radiusVectorY})`);
				}
			});
		}

		buildVAVBoxesUnoccupiedMessage(isLoadValvePositionChart) {
			const row1Text = isLoadValvePositionChart ? this.translate('LOAD_VALVES_UNOCCUPIED') : this.translate('VAV_BOXES_UNOCCUPIED');
			const row2Text = this.translate('FOR_THE_PERIOD_VIEWED');
			this.dataNode.selectAll('*').remove();
			let text = this.dataNode
				.attr('transform', `translate(${this.chartAreaCenter},${this.pieOffsetY - UNOCCUPIED_MESSAGE_Y_OFFSET})`)
				.append('text')
				.attr('class', 'chart-message');
			text
				.append('tspan')
				.attr('x', 0)
				.attr('text-anchor', 'middle')
				.text(row1Text);
			text
				.append('tspan')
				.attr('dy', 24)
				.attr('x', 0)
				.attr('text-anchor', 'middle')
				.text(row2Text);
		}

		updateYAxesScale() {
			return false;
		}

		updateAxisLabel() {}
	}

	function stripTz(date, timeZone) {
		return new Date(
			moment(date)
				.tz(timeZone)
				.format('DD MMMM YYYY HH:mm')
		);
	}

	function buildChartDataset(options, data) {
		const that = this;
		let othersSliceFailureCount = 0;
		let totalFailuresCount = 0;
		let othersSliceDivisionSymbol = (options.lines[0] && options.lines[0].uom.symbol) || '%';
		let returnValue = [];

		options.lines.forEach((line, idx) => {
			const LOWER_RANGE_END = options.controlRanges.low || DEFAULT_LOWER_RANGE_END;
			const UPPER_RANGE_END = options.controlRanges.high || DEFAULT_UPPER_RANGE_END;
			let failureCount = 0;

			// Excluding empty values and values out of timeline range
			let dataRow = data[idx].filter(function(value) {
				if (that.extentFrom && that.extentTo) {
					const microTime = new Date(value.x).getTime();
					return microTime > that.extentFrom && microTime < that.extentTo && parseFloat(value.y) > 0;
				}

				return parseFloat(value.y) > 0;
			});

			line.lower = line.upper = line.above = 0;
			dataRow.forEach(function(item) {
				if (item.y < LOWER_RANGE_END) {
					line.lower++;
				} else if (item.y < UPPER_RANGE_END) {
					line.upper++;
				} else {
					line.above++;
				}
			});
			if (dataRow.length > 0) {
				failureCount = dataRow.reduce(function(matchingCount, current) {
					if (parseFloat(current.y) > UPPER_RANGE_END) {
						totalFailuresCount++;
						matchingCount++;
					}
					return matchingCount;
				}, 0);
			}

			if (failureCount > 0) {
				returnValue.push({
					value: failureCount,
					armLabel: line.name,
					tisObjectId: line.tisObjectId,
					tisObjectType: line.tisObjectType,
					symbol: line.uom.symbol,
				});
			}
		});

		if (totalFailuresCount > 0) {
			returnValue = returnValue.map(function(equipment) {
				let failuresCount = equipment.value;
				let failuresPercentage = (failuresCount / totalFailuresCount * 100).toFixed(0);

				if (failuresPercentage > LOWER_PERCENTAGE_THRESHOLD) {
					equipment.label = String(failuresPercentage) + equipment.symbol;
					equipment.value = failuresPercentage;
					delete equipment.symbol;
				} else {
					othersSliceFailureCount += failuresCount;
					equipment.value = 0;
				}

				return equipment;
			});
			if (othersSliceFailureCount > 0) {
				let othersPercentageValue = (othersSliceFailureCount / totalFailuresCount * 100).toFixed(0);
				returnValue.push(createOthersSliceData(othersPercentageValue, othersSliceDivisionSymbol, that.translate));
			}
		}
		// Filter out values that were merged into Others slice
		returnValue = returnValue.filter(value => value.value > 0); // Excluding empty values

		if (returnValue.length === 0) {
			returnValue = UNOCCUPIED;
		}

		return returnValue;
	}

	function createOthersSliceData(othersPercentageValue, othersSliceDivisionSymbol, translate) {
		return {
			label: `${othersPercentageValue}${othersSliceDivisionSymbol}`,
			value: othersPercentageValue,
			armLabel: translate('LABEL_OTHERS'),
			tisObjectId: null,
		};
	}

	function naturalCompare(a, b) {
		let ax = [];
		let bx = [];

		a.name.replace(/(\d+)|(\D+)/g, (_, $1, $2) => ax.push([$1 || Infinity, $2 || '']));
		b.name.replace(/(\d+)|(\D+)/g, (_, $1, $2) => bx.push([$1 || Infinity, $2 || '']));

		while (ax.length && bx.length) {
			let an = ax.shift();
			let bn = bx.shift();
			let nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
			if (nn) {
				return nn;
			}
		}

		return ax.length - bx.length;
	}

	function processExportData(chartOptions, chartData) {
		let data = [];
		let lines = chartOptions.lines;
		let lowerTotal = lines.reduce((a, b) => (!b.lower ? a : a + b.lower), 0);
		let upperTotal = lines.reduce((a, b) => (!b.upper ? a : a + b.upper), 0);
		let aboveTotal = lines.reduce((a, b) => (!b.above ? a : a + b.above), 0);

		lines.sort(naturalCompare).forEach(function(line, index) {
			if (line.visible && chartData[index]) {
				data.push(calculateData(line, lowerTotal, upperTotal, aboveTotal));
			}
		});

		return data;
	}

	function getDataByPercent(count, all) {
		return Math.round(count / all * 10000) / 100 || 0;
	}

	function calculateData(line, lowerTotal, upperTotal, aboveTotal) {
		let name = line.name;
		let row = {
			name: name,
			above: line.above || 0,
			upper: line.upper || 0,
			lower: line.lower || 0,
			percentages: [],
			values: [],
		};

		row.percentages.push({
			y0: 0,
			y1: getDataByPercent(row.lower, lowerTotal),
			className: 'lower',
		});
		row.percentages.push({
			y0: row.percentages[0].y1,
			y1: row.percentages[0].y1 + getDataByPercent(row.upper, upperTotal),
			className: 'upper',
		});
		row.percentages.push({
			y0: row.percentages[1].y1,
			y1: row.percentages[1].y1 + getDataByPercent(row.above, aboveTotal),
			className: 'above',
		});

		row.values = [
			+(row.percentages[0].y1 - row.percentages[0].y0).toFixed(2),
			+(row.percentages[1].y1 - row.percentages[1].y0).toFixed(2),
			+(row.percentages[2].y1 - row.percentages[2].y0).toFixed(2),
		];

		return row;
	}

	function calculateRadius(width, height, ratio) {
		const diagonal = Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2));
		const optimalRadius = diagonal * ratio;
		let radius = Math.max(optimalRadius, MIN_CIRCLE_RADIUS);
		const realChartHeight = radius * 2 + OUTER_ARM_LENGTH;

		if (realChartHeight > height) {
			radius = height / 2 - OUTER_ARM_LENGTH / 2;
		}

		return radius;
	}

	// Calculate position and draw tooltip in a right place
	function renderTooltip(that, d, i, target) {
		let xPos;
		let yPos;

		if (target === 'arc') {
			xPos = d.isPulled ? d.centroid[0] + that.chartAreaCenter + d.radiusVectorX : d.centroid[0] + that.chartAreaCenter;
			yPos = d.isPulled ? d.centroid[1] + that.pieOffsetY + d.radiusVectorY - 20 : d.centroid[1] + that.pieOffsetY - 20;
		} else if (target === 'label') {
			xPos = d.isPulled ? d.x3 + d.radiusVectorX + that.chartAreaCenter : d.x3 + that.chartAreaCenter;
			yPos = d.isPulled ? d.y3 + d.radiusVectorY + that.pieOffsetY - 20 : d.y3 + that.pieOffsetY - 20;
		}

		const name = that.data[i].armLabel;
		const value = that.data[i].value;
		const hexColor = that.color(i);
		const parentElem = d3.event.target.parentNode;

		that.tooltip.showTooltip({
			xPos: xPos,
			yPos: yPos,
			name: name,
			color: hexColor,
			sliceValue: value,
			parentElem: parentElem,
		});
	}

	window.PieChartRenderer = PieChartRenderer;
})(window.d3, window.AbstractChartRenderer);
