(function() {
	const services = new WeakMap();

	class FacilityPerformanceChartDialogCtrl {
		constructor($controller, $filter, $modalInstance, $scope, $rootScope, $routeParams, ChartFactory, MetricsFactory, urlService, data) {
			$scope.$ctrl = this;

			services.set(this, {
				$controller,
				$modalInstance,
				$scope,
				$rootScope,
				$routeParams,
				ChartFactory,
				urlService,
				data,
			});

			// for correct work of AbstractChartCtrl
			$routeParams.locationId = data.locationId;

			angular.extend(this, $controller('AbstractChartCtrl', {$scope: $scope}));

			const newChartIndex = $scope.getNewChartIndex();
			const chart = ChartFactory.getNewFacilityPerformanceChart(
				this.initRange,
				data.locationId,
				null,
				data.equipmentTypeNumber,
				data.serviceAdvisoryTypeId,
				null,
				null,
				newChartIndex
			);

			if (chart.eventObject) {
				chart.eventObject.on('reloadChart', () => {
					/* eslint camelcase: 0*/
					MetricsFactory.mark(MetricsFactory.METRIC_TYPES.FACILITY_PERFORMANCE_CHART_LOAD, {
						facilities_count: chart.chartObj.selectedFacilities.length,
						equipment_name: $filter('translate')('EQUIPMENT_TYPE_NAME_TABLE.' + chart.chartObj.selectedEquipmentType.type),
						equipment_id: chart.chartObj.selectedEquipmentType.tisObjectTypeNumber,
						chart_id: chart.chartObj.selectedChart.chartId,
						chart_name: chart.chartObj.selectedChart.title,
						range_type: $scope.range.rangeMode,
						range_duration: $scope.range.exportRange.to - $scope.range.exportRange.from,
					});
				});

				chart.eventObject.on('chartRendered', () => {
					MetricsFactory.measure(MetricsFactory.METRIC_TYPES.FACILITY_PERFORMANCE_CHART_LOAD);
				});

				chart.eventObject.on('changeLoadedCharts', value => {
					if (Number.isInteger(+value)) {
						$scope.loadedCharts += value;
					}
				});

				chart.eventObject.on('rangeReady', () => {
					$scope.chartConfig = chart.chartConfig;
					$scope.chartObj = chart.chartObj;
					$scope.locationId = chart.chartObj.locationId;
					$scope.location = chart.location;
					$scope.navigation = chart.navigation;
					$scope.range = chart.range;
				});
			}

			$scope.charts = [chart];
			$scope.isFacilityPerformanceChart = true;

			if (Array.isArray(data.facilitiesIds) && data.facilitiesIds.length > 0) {
				chart.chartObj.selectedFacilities = data.facilitiesIds.map(({locationId, locationName}) => ({locationId, locationName}));
			}

			$scope.$watchGroup(['chartObj.locationId', 'chartObj.equipmentType', 'chartObj.serviceAdvisoryTypeId', 'range.from', 'range.to'], function(
				newValues,
				oldValues
			) {
				if (angular.equals(newValues, oldValues)) {
					return;
				}

				$scope.facilityPerformanceChartPath =
					`/facility/${$scope.chartObj.locationId}` +
					`/type/${$scope.chartObj.equipmentType}` +
					`/pareto/${$scope.chartObj.serviceAdvisoryTypeId}` +
					`?startDate=${$scope.range.from.format('MM-DD-YYYY')}` +
					`?endDate=${$scope.range.to.format('MM-DD-YYYY')}`;
			});

			const showExportDialogListenerDestructor = $rootScope.$on('facility-performance-chart__export-dialog--show', () => {
				$scope.showPrintExportDialog();
			});

			$scope.$on('$destroy', () => {
				showExportDialogListenerDestructor();
			});
		}

		cancel() {
			const {$rootScope, $modalInstance, urlService} = services.get(this);

			$rootScope.$broadcast('dialogCancelClicked');
			$modalInstance.dismiss('cancel');

			// removes startDate and endDate from url
			urlService.changeDateRangeInUrl();
		}
	}

	angular.module('TISCC').controller('FacilityPerformanceChartDialogCtrl', FacilityPerformanceChartDialogCtrl);
})();
