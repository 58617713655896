/* eslint quotes: [0, 'single'] */
/* eslint semi: [0, 'always'] */
/* eslint one-var: 0 */
/* eslint max-nested-callbacks: [2, 4] */
/* global EventEmitter*/
import {USER_EVENTS} from '../../common/usage-tracking/categories';
import {CURRENT_PAGE, PRIMARY_OFFERING} from '../../common/usage-tracking/common/properties-names';
import {PRIMARY_OFFERINGS} from '../../common/usage-tracking/common/primary-offerings';
import {getChartNameForExport, getPropertiesOnExportChart} from '../../common/usage-tracking/categories/equipment-performance-charts/utils';
import _get from 'lodash.get';
import lzString from 'lz-string';
import * as R from 'ramda';

const {
	EQUIPMENT_PERFORMANCE_CHARTS: {
		events: EQUIPMENT_PERFORMANCE_CHARTS_EVENTS,
		properties: EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES,
		categoryName: EQUIPMENT_PERFORMANCE_CHARTS_CATEGORY_NAME,
	},
} = USER_EVENTS;

angular
	.module('TISCC')
	.controller('AbstractChartCtrl', function(
		$scope,
		$routeParams,
		$route,
		ExportDataGenerationService,
		$timeout,
		locationDetailsService,
		locationEquipmentService,
		serviceAdvisoryService,
		utilityService,
		$filter,
		$location,
		modalHelperService,
		$rootScope,
		helpers,
		ErrorPageFactory,
		CHART_DATE_FORMAT,
		CHART_TYPE,
		CONTROLLER_CHART_TYPES,
		GEN3_URL,
		TC_URL,
		REPORT_TYPES,
		configService,
		authorization,
		urlService,
		exportService,
		$window,
		errorHandler,
		usageTrackingService
	) {
		const externalLinks = configService.getExternalLinks();
		const trackEvent = usageTrackingService.trackEventByCategory(EQUIPMENT_PERFORMANCE_CHARTS_CATEGORY_NAME, {
			[CURRENT_PAGE]: _get($route, 'current.metricsConfig.pathName', $route.current.templateUrl),
		});

		$rootScope.embedded = $routeParams.embedded || false;
		$rootScope.multipleChart = false;
		$rootScope.fitCharts = Boolean($routeParams.fitCharts);

		$scope.isIncludeDataWithErrors = $routeParams.includeDataWithErrors === 'true';
		$scope.passedChartState = null;
		$scope.canAddChart = true;
		$scope.isSynced = false;
		$scope.featureToggles = configService.getFeatureToggles();
		$scope.isOfferingExpired = locationDetailsService.isOfferingExpired;

		this.MAX_ALLOWED_CHARTS = 10;

		if ($routeParams.startDate && $routeParams.endDate) {
			this.initRange = {
				startDate: $routeParams.startDate,
				endDate: $routeParams.endDate,
			};
		}
		// Pass over chart state within headless generation scope.

		if ($routeParams.chartState) {
			try {
				const dCompressedChartState = lzString.decompressFromEncodedURIComponent($routeParams.chartState);
				$scope.passedChartState = decodeChartState(dCompressedChartState);
			} catch (err) {
				$scope.passedChartState = null;
			}
		}

		$scope.activeTab = 'performance';
		$scope.maximizeCharts = false; // can't find usage
		$scope.loadedCharts = 0; //

		const externalLinksInfo = {};

		locationDetailsService
			.getLocationDetailsWithoutServiceAdvisories($routeParams.locationId)
			.then(data => {
				const {organizationId, facilityId, locationId, locationName} = data.locationSummaryList[0];
				Object.assign(externalLinksInfo, {organizationId, facilityId, locationId, locationName});
			})
			.then(() => {
				return helpers.isNumber(parseInt($routeParams.equipmentId))
					? locationEquipmentService.getLocationObjectsList($routeParams.locationId, null, true)
					: null;
			})
			.then(data => {
				return data
					? (externalLinksInfo.equipment = $filter('filterNested')(data.tisObjectList, {tisObjectId: parseInt($routeParams.equipmentId)}, true).pop())
					: (externalLinksInfo.equipment = {});
			})
			.then(() => utilityService.getEnvironmentType())
			.then(env => {
				const {organizationId, facilityId, locationId, locationName, equipment: {objectId}} = externalLinksInfo;
				$scope.TC_URL = TC_URL[env.toUpperCase()];
				$scope.notesLink = externalLinks.notesLink({locationId, facilityId, locationName});
				$scope.addFindingLink = externalLinks.addFindingLink({organizationId, locationId, tisObjectId: objectId});
				$scope.automatedTestSettingsLink = externalLinks.automatedTestSettingsLink({
					accountId: organizationId,
					locationId,
					tisObjectId: $scope.equipmentId,
				});
				$scope.automatedTestSuppressionsLink = externalLinks.automatedTestSuppressionsLink({accountId: organizationId, locationId});
				$scope.remoteAccessLink = externalLinks.remoteAccessLink({accountId: organizationId, locationId, tisObjectId: objectId});
				$scope.GEN4EquipmentSetupURL = externalLinks.equipmentSetupLink({objectId, locationId});
			});

		let getSuggestionByServiceAdvisoryTypeActionName = angular.noop;
		serviceAdvisoryService.getActions(actionsHtml => {
			getSuggestionByServiceAdvisoryTypeActionName = serviceAdvisoryService.generateGetActionsFunction(actionsHtml);
		});

		$scope.setProperty = setProperty;

		$scope.updatePageTitle = function(title) {
			// Remove this
			$scope.setProperty('pageTitle', {title});
		};

		$scope.updateDrpDwnPosition = function($event) {
			const parentNode = $event.currentTarget.parentNode;
			const drpMenuNode = parentNode.querySelector('.dropdown-menu');
			let shift;

			$rootScope.subMenuItems = null;
			$rootScope.subSubMenuItems = null;
			drpMenuNode.style.display = 'block';
			shift = parentNode.offsetLeft + parentNode.offsetWidth - drpMenuNode.offsetWidth;
			drpMenuNode.style.right = shift < 0 ? shift + 'px' : '';
			drpMenuNode.style.display = '';
		};

		$scope.updateBetaText = function(selector) {
			const list = document.querySelectorAll(selector + ' li.beta'),
				betaText = $filter('translate')('BETA_TEXT');
			for (let i = 0; i < list.length; i++) list[i].setAttribute('title', betaText);
		};

		$scope.createFinding = async function() {
			const exceptionList = $scope.chartObj.timeline.lanes.filter(lane => lane.isException && lane.visible);

			if (exceptionList.length > 1) {
				$scope.modal = modalHelperService.open({
					templateUrl: 'components/chart/select-exceptions-modal.html',
					controller: 'SelectExceptionsDialogCtrl',
					backdrop: 'static',
					windowClass: 'select-exceptions-dialog',
					resolve: {
						list: () => exceptionList.map(item => ({...item})),
						addFindingLink: () => $scope.addFindingLink,
						onSelected: () => async selectedList => {
							const chartImage = (await uploadChartImage()) || {};
							const isMultipleExceptions = selectedList.length > 1;
							selectedList.forEach(exception => createFindingInTraneConnect(exception, chartImage, isMultipleExceptions));
						},
					},
				});
			} else if (exceptionList.length === 1) {
				createFindingInTraneConnect(exceptionList[0]);
			} else {
				createFindingInTraneConnect();
			}
		};

		$scope.showPrintExportDialog = function() {
			if (!$scope.chartObj.selectedEquipment) {
				$scope.chartObj.selectedEquipment = {
					tisObjectName: 'Facility',
					tisObjectType: {},
				};
			}

			const preparedCharts = $scope.charts.filter(({chartObj = {}}) => chartObj.chart);

			let scope = $rootScope.$new();

			if ($scope.isFacilityPerformanceChart) {
				scope.isFacilityPerformanceChart = $scope.isFacilityPerformanceChart;
				scope.facilityPerformanceChartPath = $scope.facilityPerformanceChartPath;
			}

			$scope.modal = modalHelperService.open({
				templateUrl: 'components/chart/print-export-dialog.html',
				controller: 'PrintExportDialogCtrl',
				backdrop: 'static',
				scope: scope,
				resolve: {
					getData: function() {
						return getChartsData.bind(null);
					},
					getCsvXlsxData: function() {
						const charts = preparedCharts.map(chart => {
							let cloneChartObj = R.clone(chart.chartObj);

							if (cloneChartObj.timeline && cloneChartObj.timeline.lanes) {
								cloneChartObj.timeline.lanes = cloneChartObj.timeline.lanes.filter(lane => Object.keys(lane.fullPropertyInfo).length > 0);
							}
							return {
								chartObj: cloneChartObj,
								range: chart.range,
								exportRange: chart.range.exportRange,
							};
						});

						return ExportDataGenerationService.getCsvXlsxData.bind(null, {
							charts,
							filename: createFileName(preparedCharts, false),
						});
					},
					charts: () => preparedCharts,
					onActionHandler: () => options => {
						trackEvent(EQUIPMENT_PERFORMANCE_CHARTS_EVENTS.EXPORT_CHART, {
							[PRIMARY_OFFERING]: PRIMARY_OFFERINGS.IS,
							[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_NAME]: getChartNameForExport($scope.charts),
							...getPropertiesOnExportChart(options),
						});
					},
					onCopyLinkHandler: () => () => {
						trackEvent(EQUIPMENT_PERFORMANCE_CHARTS_EVENTS.COPY_CHART_LINK, {
							[PRIMARY_OFFERING]: PRIMARY_OFFERINGS.IS,
							[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_NAME]: getChartNameForExport($scope.charts),
						});
					},
				},
			});
		};

		$scope.openReport = function(report) {
			if (report === REPORT_TYPES.REPORT_CHILLER_PERFORMANCE_ENGLISH.report && !locationDetailsService.isCprAllowed($scope.charts[0].location)) {
				return;
			}

			$scope.modal = modalHelperService.open({
				templateUrl: 'components/reports/report-dialog.html',
				controller: 'ReportDialogCtrl',
				backdrop: 'static',
				windowClass: 'report-dialog full-height',
				resolve: {
					data: function() {
						return {
							report: report,
							locationData: $scope.charts[0].location,
							defaultSelection: $scope.chartObj.selectedEquipment,
							equipmentsData: [$scope.chartObj.selectedEquipment],
							isChart: true,
							rangeFrom: $scope.charts[0].range.from,
							rangeTo: $scope.charts[0].range.to,
							rangeEnd: $scope.charts[0].range.rangeEnd,
							maxDt: $scope.charts[0].range.maxDt,
							rangeMode: $scope.charts[0].range.rangeMode,
						};
					},
				},
			});
		};

		$scope.toggleSync = (value, fromChart, {emitUpdateChartRange = false} = {}) => {
			$timeout(() => {
				if (typeof value === 'boolean') {
					$scope.isSynced = value;
				} else {
					$scope.isSynced = !$scope.isSynced;
				}

				if ($scope.isSynced) {
					if (fromChart) {
						fromChart.range.setAsMain();
					}
					$scope.charts.forEach(({range = {}, chartIndex}, index) => {
						if (index === 0 && !fromChart) {
							range.setAsMain && range.setAsMain();
						} else if (!fromChart || chartIndex !== fromChart.chartIndex) {
							range.setAsChild && range.setAsChild({emitUpdateChartRange});
						}
					});
				} else {
					$scope.charts.forEach(({range = {}}) => {
						range.setAsStandAlone && range.setAsStandAlone();
					});
				}
			});
		};

		$scope.getAppropriateRange = function(range) {
			const format = 'MM-DD-YYYY';

			let startDate = moment(range.from);
			let endDate = moment(range.to);

			// Convert dates, as dates in encoded charts state are kept in utc.
			startDate.subtract(startDate.utcOffset(), 'minutes').startOf('day');
			endDate.subtract(endDate.utcOffset(), 'minutes').startOf('day');

			const appropriateRange = {
				startDate: startDate.format(format),
			};

			endDate.isSameOrBefore(startDate)
				? (appropriateRange.endDate = endDate.format(format))
				: (appropriateRange.endDate = endDate.add(-1, 'days').format(format));

			return appropriateRange;
		};

		$scope.addCommonEventListenersToChart = chart => {
			chart.eventObject.on('setEquipmentServiceAdvisoryCountInScope', val => {
				$scope.equipmentServiceAdvisoryCount = val;
			});

			chart.eventObject.on('setLocationServiceAdvisoriesCountInScope', val => {
				$scope.facilityServiceAdvisoryCount = val;
			});

			chart.eventObject.on('setLocationNotesCountInScope', val => {
				$scope.facilityNotesCount = val;
			});

			chart.eventObject.on('setEquipmentNotesCountInScope', val => {
				$scope.equipmentNotesCount = val;
			});

			chart.eventObject.on('createPageTitle', title => {
				$scope.setProperty('pageTitle', title);
			});

			chart.eventObject.on('setNavigation', navigation => {
				$scope.setProperty('navigation', navigation);
			});

			chart.eventObject.on('updatePageTitle', title => {
				$scope.setProperty('pageTitle', {title});
			});

			chart.eventObject.on('changeLoadedCharts', value => {
				if (Number.isInteger(+value)) {
					$scope.loadedCharts += value;
				}
			});

			chart.eventObject.on('toggleSync', ({emitUpdateChartRange} = {}) => {
				$scope.toggleSync(undefined, chart, {emitUpdateChartRange});
			});

			chart.eventObject.on('outOfSync', () => {
				$scope.toggleSync(false);
			});

			chart.eventObject.on('setEquipmentType', params => {
				if (params.charttype === CONTROLLER_CHART_TYPES.PARETO_CHART) {
					$location.path(`/facility/${params.locationId}/type/${params.equipmentType}/pareto/${params.serviceAdvisoryTypeId || null}`).replace();
				} else if (params.charttype === CONTROLLER_CHART_TYPES.FACILITY_CHART) {
					$location.path(`/facility/${params.locationId}/type/${params.equipmentType}/chart/${params.chartId || null}`).replace();
				}
			});

			chart.eventObject.on('rangeReady', () => {
				$scope.chartConfig = chart.chartConfig;
				$scope.chartObj = chart.chartObj;
				$scope.locationId = chart.chartObj.locationId;
				$scope.location = chart.location;
				$scope.navigation = chart.navigation;
				$scope.range = chart.range;
			});
		};

		$scope.addEventListenersToChartForExport = chart => {
			chart.eventObject.on('chartRendered', () => {
				chart.eventObject.emit('chartResize');
			});
			chart.eventObject.on('chartResized', () => {
				$scope.$evalAsync(() => (chart.resized = true));
			});
		};

		$scope.checkCanAddChart = () => {
			$scope.canAddChart = $scope.charts.length < this.MAX_ALLOWED_CHARTS;
		};

		$scope.getNewChartIndex = () => {
			if ($scope.charts && $scope.charts.length > 0) {
				const firstChartIndex = $scope.charts[0].chartIndex;
				return $scope.charts.map(c => c.chartIndex).reduce((a, b) => Math.max(a, b), firstChartIndex) + 1;
			} else {
				return 0;
			}
		};

		$scope.generateAddChartFunction = (chartType, ChartFactory) => {
			const addChart = (params, currentChart, isReplaceCurrentChart = false) => {
				let newChart = null;

				if (!isReplaceCurrentChart && $scope.charts.length >= this.MAX_ALLOWED_CHARTS) return;

				if (chartType === CONTROLLER_CHART_TYPES.EQUIPMENT_CHART) {
					newChart = ChartFactory.getNewEquipmentChart(
						params.range || this.initRange,
						params.locationId,
						params.chartId,
						params.equipmentId,
						params.equipmentTypeNumber,
						params.equipmentTypeChart,
						params.chartIndex,
						// To support instance level chart (ex : Motor power performance)
						params.selectedInstanceEquipmentType || null,
						params.instanceName || null,
						params.instanceId || null
					);
				} else {
					if (params.charttype === CONTROLLER_CHART_TYPES.PARETO_CHART) {
						newChart = ChartFactory.getNewParetoChart(
							params.range || this.initRange,
							params.locationId,
							null,
							params.equipmentType,
							params.serviceAdvisoryTypeId,
							params.paretoChartSortOrder,
							params.selectedFacilities,
							params.chartIndex
						);
					} else if (params.charttype === CONTROLLER_CHART_TYPES.FACILITY_CHART) {
						newChart = ChartFactory.getNewFacilityChart(
							params.range || this.initRange,
							params.locationId,
							params.chartId,
							params.equipmentType,
							params.selectedFacilities,
							params.chartIndex
						);
					}
				}

				if (isReplaceCurrentChart) {
					$scope.charts.forEach((item, i, arr) => {
						if (item === currentChart) {
							arr[i] = newChart;
						}
					});
				} else {
					if (currentChart) {
						$scope.charts.forEach((item, i, arr) => {
							if (item === currentChart) {
								arr.splice(i + 1, 0, newChart);
							}
						});

						!$rootScope.multipleChart && newChart.eventObject.once('chartRendered', () => currentChart.eventObject.emit('chartResize'));
					} else {
						$scope.charts.push(newChart);
					}

					$scope.checkCanAddChart();

					if ($scope.charts.length === 2) {
						$scope.toggleSync(true);
					}
				}

				if (newChart && newChart.eventObject) {
					newChart.eventObject.on('removeChart', () => {
						$scope.charts.forEach((item, i, arr) => {
							if (item === newChart) {
								newChart.range.setAsStandAlone();
								arr.splice(i, 1);
								$scope.checkCanAddChart();
								if (arr.length === 1) {
									const chart = arr.find(item => item);
									$rootScope.multipleChart = false;
									chart && chart.eventObject.emit('chartResize');
									$scope.toggleSync(false);
								}
							}
						});
					});

					newChart.eventObject.on('rangeReady', () => {
						if ($scope.isSynced) {
							newChart.range.setAsChild();
						}
					});

					newChart.eventObject.on('toggleSync', ({emitUpdateChartRange} = {}) => {
						$scope.toggleSync(undefined, newChart, {emitUpdateChartRange});
					});

					newChart.eventObject.on('outOfSync', () => {
						$scope.toggleSync(false);
					});

					if (chartType === CONTROLLER_CHART_TYPES.EQUIPMENT_CHART) {
						newChart.eventObject.on('addChart', () => {
							const {chartObj: {locationId, chartId, equipmentId, selectedInstanceEquipmentType, instanceName, instanceId}, range} = newChart;

							$scope.trackAddChartButtonClick(newChart);

							addChart(
								{
									range: $scope.getAppropriateRange(range),
									locationId,
									chartId,
									equipmentId,
									equipmentTypeNumber: params.equipmentTypeNumber,
									equipmentTypeChart: params.equipmentTypeChart,
									// To support instance level chart (ex : Motor power performance)
									selectedInstanceEquipmentType: selectedInstanceEquipmentType || null,
									instanceName: instanceName || null,
									instanceId: instanceId || null,
								},
								newChart
							);
						});
					} else {
						newChart.eventObject.on('setEquipmentType', newparams => {
							if (newparams.charttype !== params.charttype || newparams.equipmentType !== params.equipmentType) {
								const childChart = addChart(newparams, newChart, true);
								childChart.passedChartState = newChart.passedChartState;
								$rootScope.isExport && $scope.addEventListenersToChartForExport(childChart);
							}
						});

						newChart.eventObject.on('addChart', () => {
							const {
								chartObj: {locationId, equipmentType, chartId, serviceAdvisoryTypeId, paretoChartSortOrder, selectedFacilities},
								range,
								isParetoChart,
							} = newChart;

							addChart(
								{
									range: $scope.getAppropriateRange(range),
									charttype: isParetoChart ? CONTROLLER_CHART_TYPES.PARETO_CHART : CONTROLLER_CHART_TYPES.FACILITY_CHART,
									locationId,
									chartId,
									equipmentType,
									serviceAdvisoryTypeId,
									paretoChartSortOrder,
									selectedFacilities,
								},
								newChart
							);
						});
					}

					newChart.canRemoveChart = true;
					$rootScope.multipleChart = true;
				}

				return newChart;
			};

			return addChart;
		};

		$scope.trackAddChartButtonClick = chart => {
			const {chartObj: {selectedChart}, chartIndex} = chart;

			trackEvent(EQUIPMENT_PERFORMANCE_CHARTS_EVENTS.ADD_CHART_BELOW, {
				[PRIMARY_OFFERING]: PRIMARY_OFFERINGS.IS,
				[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_NAME]: selectedChart.title,
				[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_ID]: chartIndex,
			});
		};

		function createFileName(charts, isDotNeeded = true) {
			const MULTIPLE_FACILITIES = 'Multiple Facilities';
			const MULTIPLE_DATES = 'Multiple Dates';
			const MULTIPLE_EQUIPMENT = 'Multiple Equipment';
			const MULTIPLE_CHARTS = 'Multiple Charts';

			let locationNames = {};
			let dates = {};
			let tisObjectNames = {};
			let chartNames = {};
			let isParetoChart = false;
			let isMultipleLocations = false;

			const getName = (names, defaultName) => {
				return names.length === 1 ? names[0] : defaultName;
			};

			charts.map(({chartObj, range}) => ({chartObj, range})).forEach(({chartObj, range}) => {
				if (!isParetoChart && chartObj.chart.options.chartType === CHART_TYPE.PARETO) {
					isParetoChart = chartObj.chart.options.chartType;
				}

				const {selectedFacilities = []} = chartObj;

				if (!isMultipleLocations && selectedFacilities && selectedFacilities.length > 1) {
					isMultipleLocations = true;
				}

				locationNames[selectedFacilities.length === 1 ? selectedFacilities[0].locationName : chartObj.selectedLocation.locationName] = null;
				dates[range.visualExportEndDate.format('YYYY-MM-DD')] = null;
				tisObjectNames[helpers.getPropertyByPath(chartObj, 'selectedEquipment.tisObjectName')] = null;
				chartNames[chartObj.selectedChart.chartName || chartObj.selectedChart.title] = null;
			});

			locationNames = Object.keys(locationNames);
			dates = Object.keys(dates);
			tisObjectNames = Object.keys(tisObjectNames);
			chartNames = Object.keys(chartNames);

			const locationName = isMultipleLocations ? MULTIPLE_FACILITIES : getName(locationNames, MULTIPLE_FACILITIES);
			const date = getName(dates, MULTIPLE_DATES);
			const tisObjectName = getName(tisObjectNames, MULTIPLE_EQUIPMENT);
			const chartName = getName(chartNames, MULTIPLE_CHARTS);

			let input = isParetoChart ? [locationName, date, chartName] : [locationName, date, tisObjectName, chartName];

			return helpers.sanitizeFilename(input.join('_')) + (isDotNeeded ? '.' : '');
		}

		function setProperty(name, value) {
			$scope[name] = value;
		}

		function getChartsData(chartsList) {
			const preparedCharts = (chartsList || $scope.charts).filter(({chartObj = {}, equipmentLevel}) => ({
				...chartObj.chart,
				isEquipmentLevel: equipmentLevel,
			}));
			const charts = ExportDataGenerationService.getMappedChartsData(preparedCharts);
			return ExportDataGenerationService.getExportData({
				charts,
				filename: createFileName(preparedCharts),
				language: $rootScope.lang(),
				unitsSystem: $rootScope.unitsSystem,
			});
		}

		async function uploadChartImage() {
			$scope.isExportInProgress = true;
			let data = getChartsData([$scope.charts[0]]);
			data.format = 'jpg';
			data.filename += data.format;
			data.visualExport = true;
			data.backgroundColor = 'white';
			data.screenshotUpload = true;
			data.exportOrientation = 'wide_landscape';
			const encodedChartState = lzString.compressToEncodedURIComponent(ExportDataGenerationService.encodeChartState(data));
			data.url = [
				urlService.updateHttpsToHttp($location.absUrl()),
				`&chartState=${encodedChartState}`,
				'&isExport=1',
				'&isSideLegend=1',
				`&backgroundColor=${data.backgroundColor}`,
				`&hideChartHeader=1`,
			].join('');

			try {
				const response = await exportService.sendExportRequest(data);
				$scope.isExportInProgress = false;
				return response;
			} catch (e) {
				$scope.isExportInProgress = false;
				errorHandler.showErrorModal(e);
			}
		}

		$scope.$on('$routeChangeStart', function(next, current) {
			if ($scope.modal && $scope.modal.dismiss && $scope.modal.opened.$$state.value) {
				try {
					$scope.modal.dismiss();
				} catch (err) {
					return false;
				}
			}
		});

		$scope.$on('reloadChart', function(event, {chartConfig, editProperties}) {
			const chart = $scope.charts.find(chart => {
				return chart.chartConfig === chartConfig;
			});

			chart && chart.eventObject.emit('reloadChart', {editProperties});
		});

		$scope.$on('$translateChangeSuccess', function() {
			$scope.charts.forEach(chart => {
				chart.eventObject.emit('$translateChangeSuccess');
			});
		});

		async function createFindingInTraneConnect(exception, chartImage, isMultipleExceptions) {
			const {selectedLocation: {organizationId, locationId}, equipmentId, selectedChart: {title}} = $scope.chartObj;
			const {imageId} = chartImage || (await uploadChartImage()) || {};

			const serviceAdvisoryParams = [];
			if (exception) {
				const serviceAdvisory = await getServiceAdvisoryByException(exception, equipmentId);
				serviceAdvisoryParams.push(
					serviceAdvisory && `&serviceAdvisoryType=${serviceAdvisory.serviceAdvisoryTypeId}`,
					`&description=${exception.name}`
				);
			}
			const compressedChartState = lzString.compressToEncodedURIComponent(ExportDataGenerationService.encodeChartState(getChartsData()));
			const url = [
				`${$scope.TC_URL}/findings/add?`,
				`organization=${organizationId}`,
				`&location=${locationId}`,
				`&equipment=${equipmentId}`,
				`&chartName=${title}`,
				`&chartImage=${imageId}`,
				`&chartLink=${encodeURIComponent($location.$$absUrl)}`,
				`&chartState=${encodeURIComponent(compressedChartState)}`,
				isMultipleExceptions ? '&multipleExceptions=true' : '',
				...serviceAdvisoryParams.filter(Boolean),
			].join('');
			$window.open(url, '_blank');
		}

		async function getServiceAdvisoryByException(exception, equipmentId) {
			let serviceAdvisory;
			const exceptionPropertyName = helpers.getPropertyByPath(exception, 'fullPropertyInfo.propertyName');
			if (exceptionPropertyName) {
				const tisObjectSats = await serviceAdvisoryService.getTypesByTisObjectId(equipmentId);
				serviceAdvisory = serviceAdvisoryService
					.filterOutIntermediateTests(tisObjectSats)
					.find(item => item.exceptionPropertyName === exceptionPropertyName);

				if (serviceAdvisory) {
					serviceAdvisory.impactCategories.forEach(function(category) {
						serviceAdvisory[category.impactCategoryName] = category.impactCategoryValue;
					});
				}
			}
			return serviceAdvisory;
		}

		function decodeChartState(chartState) {
			chartState = JSON.parse(chartState);

			chartState.forEach(state => {
				state.lines = helpers.decompressArrayOfObjects(state.lines);
				state.lanes = helpers.decompressArrayOfObjects(state.lanes);
				state.yAxes = helpers.decompressArrayOfObjects(state.yAxes);
				state.thresholds = helpers.decompressArrayOfObjects(state.thresholds);
				state.meanLines = helpers.decompressArrayOfObjects(state.meanLines);

				if (state.selectedFacilities) {
					state.selectedFacilities = helpers.decompressArrayOfObjects(state.selectedFacilities);
				}

				let [
					equipmentId,
					locationId,
					chartId,
					equipmentTypeChart,
					equipmentType,
					serviceAdvisoryTypeId,
					chartIndex,
					// To support instance level chart (ex : Motor power performance)
					selectedInstanceEquipmentType,
					instanceName,
					instanceId,
				] = state.otherInfo;

				Object.assign(state, {
					equipmentId,
					locationId,
					chartId,
					equipmentTypeChart,
					equipmentType,
					serviceAdvisoryTypeId,
					chartIndex,
					// To support instance level chart (ex : Motor power performance)
					selectedInstanceEquipmentType,
					instanceName,
					instanceId,
				});

				let [_, unoccupied, isFacilityChart, isParetoChart, isSuppressionsEnabled] = state.booleanValues
					.toString(2)
					.split('')
					.map(i => Boolean(Number(i)));
				Object.assign(state, {unoccupied, isFacilityChart, isParetoChart, isSuppressionsEnabled});

				if (state.chartConfig) {
					// Decompress originalProps
					const originalProps = state.chartConfig.originalProps;
					Object.keys(originalProps).forEach(tisObjectType => {
						Object.keys(originalProps[tisObjectType]).forEach(tisObjectId => {
							originalProps[tisObjectType][tisObjectId] = originalProps[tisObjectType][tisObjectId].map(propertyName => ({propertyName}));
						});
					});

					// Decompress customChartProps
					const customChartProps = state.chartConfig.customChartProps;
					if (customChartProps && Object.keys(customChartProps).length) {
						Object.keys(customChartProps).forEach(tisObjectType => {
							Object.keys(customChartProps[tisObjectType]).forEach(tisObjectId => {
								customChartProps[tisObjectType][tisObjectId] = helpers.decompressArrayOfObjects(customChartProps[tisObjectType][tisObjectId]);

								customChartProps[tisObjectType][tisObjectId].forEach(customProp => {
									customProp.uom = state.chartConfig.uomsById[customProp.uom];
								});
							});
						});

						delete state.chartConfig.uomsById;
					}
				}
			});

			return chartState;
		}
	});
