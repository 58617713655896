/* eslint-disable max-nested-callbacks */
import _get from 'lodash.get';
import {filter, test} from 'ramda';
import {conversionTable, dateFormatByLang, DIR_DATE_FORMATS} from '../digital-inspection-report-service';
const {CHILLER_DATA_DATE_FORMAT, TIMEZONE_FORMAT} = DIR_DATE_FORMATS;

(function() {
	const CHILLER_GROUP_NAME = 'Chiller';
	const services = new WeakMap();
	const privateMembers = new WeakMap();

	class DigitalInspectionReportController {
		constructor(
			DIR_STATUS,
			AUTOMATED_TEST_LEVELS,
			$scope,
			$location,
			$rootScope,
			$routeParams,
			$filter,
			$log,
			$q,
			urlService,
			helpers,
			CHART_DATE_FORMAT,
			ErrorPageFactory,
			digitalInspectionService,
			digitalInspectionReportService,
			serviceAdvisoryService,
			locationDetailsService,
			locationEquipmentService,
			LocationSettingsFctry,
			tisObjectService
		) {
			services.set(this, {
				DIR_STATUS,
				AUTOMATED_TEST_LEVELS,
				$scope,
				$location,
				$rootScope,
				$routeParams,
				$filter,
				$log,
				$q,
				urlService,
				helpers,
				CHART_DATE_FORMAT,
				ErrorPageFactory,
				locationDetailsService,
				digitalInspectionService,
				digitalInspectionReportService,
				serviceAdvisoryService,
				locationEquipmentService,
				LocationSettingsFctry,
				tisObjectService,
			});
			privateMembers.set(this, {
				equipmentId: $routeParams.equipmentId,
			});

			$rootScope.backgroundColor = 'white';
			$rootScope.isExport = true;
			$rootScope.exportClass = 'page';
			this.getSuggestionByServiceAdvisoryTypeActionName = angular.noop;
			serviceAdvisoryService.getActions(actions => {
				this.getSuggestionByServiceAdvisoryTypeActionName = serviceAdvisoryService.generateGetActionsFunction(actions);
			});
			this.failRateData = {};
			this.PLACE_HOLDER = '*****';
			this.isGenerated = false;
			this.notAChiller = false;
			this.currentYear = new Date().getFullYear();
			this.facilityName = '';
			this.addressLine = '';
			this.chillerName = '';
			this.equipmentImage = '';
			this.defaultEquipmentImage = 'images/vav-box-sample.png';
			this.equipmentDescription = '';
			this.chillerSerialNumber = '';
			this.startTimePeriod = '';
			this.endTimePeriod = '';
			this.pages = [];
			this.lang = 'en';
			this.notAChiller = false;
			this.serviceAdvisoryTypeIDs = [];
			this.from = moment();
			this.to = moment();
			this.location = {};
			this.splitPages = () => {};
			this.initialize();
		}
		initialize() {
			const {$log} = services.get(this);
			this.splitPages = this._splitPages;
			return this._getLocationDetails()
				.then(() => this._getLocationObjectsList())
				.then(() => this._getObjectList())
				.then(() => this._getFailRateData())
				.then(() => this._buildData())
				.then(() => this._setProperDates())
				.then(() => this._getOtherChillerInfo())
				.then(() => this._createNewPages())
				.then(() => {
					this.isGenerated = true;
				})
				.catch(error => $log.error(error));
		}

		_splitPages({pages, detailPages}) {
			const generateNewDetailsPageObject = () => ({height: 860, sections: [], pageName: 'INSPECTION_DETAILS'});
			// generalise code for details and categories
			this.pages = [
				...pages.map((val, key) => {
					const allSections = Object.keys(this.failRateData);
					const {start, end} = {
						start: key === 0 ? 0 : val,
						end: key === pages.length - 1 ? allSections.length : pages[key + 1],
					};
					const sectionNames = allSections.slice(start || 0, end || 0);
					const sections = sectionNames.reduce((acc, sectionName) => {
						acc[sectionName] = this.failRateData[sectionName];
						return acc;
					}, {});
					return {
						height: 860,
						sections,
						pageName: 'DIGITAL_INSPECTION_REPORT',
					};
				}),
				...detailPages.map((val, key) => {
					const allSections = Object.keys(this.fialRateInspectionData);
					const {start, end} = {
						start: key === 0 ? 0 : val,
						end: key === detailPages.length - 1 ? allSections.length : detailPages[key + 1],
					};
					const sectionNames = allSections.slice(start || 0, end || 0);
					const sections = sectionNames.reduce((acc, sectionName) => {
						acc[sectionName] = this.fialRateInspectionData[sectionName];
						return acc;
					}, {});
					return {
						height: 860,
						sections,
						failRateCategoryKeys: sectionNames,
						failRateCategories: sectionNames.reduce((acc, name) => {
							acc[name] = this.failRateCategoriesForInspectionDetailsToDisplay[name];
							return acc;
						}, {}),
						pageName: 'INSPECTION_DETAILS',
					};
				}),
			];
		}
		_getLocationDetails() {
			const {$routeParams, locationDetailsService} = services.get(this);

			return locationDetailsService
				.getLocationDetailsWithoutServiceAdvisories($routeParams.locationId)
				.then(data => (privateMembers.get(this).locationDetails = data));
		}
		_getLocationObjectsList() {
			const {$filter, $routeParams, locationEquipmentService} = services.get(this);
			const _privateMembers = privateMembers.get(this);
			return locationEquipmentService.getLocationObjectsList($routeParams.locationId, null, true).then(data => {
				_privateMembers['locationObjectsList'] = data;
				_privateMembers['equipment'] = $filter('filterNested')(
					_privateMembers.locationObjectsList.tisObjectList,
					{
						tisObjectId: parseInt(_privateMembers.equipmentId),
					},
					true
				).pop();
			});
		}
		_setProperDates() {
			const {$routeParams, locationDetailsService, CHART_DATE_FORMAT, chillerPerformanceReportConfig: dirConfig} = services.get(this);
			const timezone = locationDetailsService.getLocationTimezone();
			const month = moment()
				.tz(timezone)
				.startOf('month');
			let fromDate = moment(month);
			let toDate = moment().tz(timezone);

			if ($routeParams.startDate) {
				const lastAllowedDay = moment().tz(timezone);

				fromDate = moment.tz($routeParams.startDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, timezone);
				toDate = moment.tz($routeParams.endDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, timezone);

				if (toDate.isAfter(lastAllowedDay, 'day')) {
					toDate = moment(lastAllowedDay);
				}
			}

			if (toDate.isBefore(fromDate)) {
				toDate = moment().tz(timezone);
			}

			this.from = fromDate;
			this.to = toDate;
			this.startTimePeriod = this.from.format(CHILLER_DATA_DATE_FORMAT);
			this.endTimePeriod = this.to.format(CHILLER_DATA_DATE_FORMAT);
		}
		_getOtherChillerInfo() {
			const _privateMembers = privateMembers.get(this);
			const properties = {
				UnitSerialNumber: {
					name: 'UnitSerialNumber',
					sign: '~',
					sourceUnitOfMeasure: 'N/A',
				},
				UnitModelNumber: {
					name: 'UnitModelNumber',
				},
			};

			const {tisObjectService, locationDetailsService} = services.get(this);

			const params = {
				ids: [parseInt(privateMembers.get(this).equipmentId)],
				hpath: Object.values(properties).map(property => `${property.sign}${property.name}|LATEST()`),
				enumerations: '',
				from: moment(this.from),
				to: moment(), // to - as the current date since the latest value is needed
				timeZone: locationDetailsService.getLocationTimezone(),
			};

			return tisObjectService.getCprReportHeader(params).then(dirRes => {
				const propertiesNames = Object.keys(properties);
				const items = Object.keys(dirRes);
				items &&
					propertiesNames.forEach(name => {
						_privateMembers[name] = {
							name,
							value: items.includes(name) ? dirRes[name] : '',
							sourceUnitOfMeasure: properties[name].sourceUnitOfMeasure,
						};
					});

				this._fillHeaderData();
			});
		}
		_fillHeaderData() {
			const {equipment, locationDetails, UnitModelNumber, UnitSerialNumber} = privateMembers.get(this);
			const {$filter} = services.get(this);
			const [location] = locationDetails.locationSummaryList;
			const address = location.address;

			this.facilityName = location.locationName;

			if (address && address.line1) {
				this.addressLine =
					address.line1 + (address.city ? ', ' + address.city : '') + ', ' + (address.regionCode || '') + ' ' + (address.postalCode || '');
			}
			this.chillerName = equipment.tisObjectName.length > 20 ? equipment.tisObjectName.substring(0, 19) + '...' : equipment.tisObjectName;
			this.equipmentImage = equipment.tisObjectImageURL;
			this.equipmentDescription = $filter('translate')('DIR_SUMMARY.' + equipment.tisObjectType.tisObjectTypeGroupName);
			this.chillerSerialNumber = UnitSerialNumber.value;
		}
		_getObjectList() {
			const {digitalInspectionReportService} = services.get(this);
			return digitalInspectionReportService.getObjectData().then(data => (privateMembers.get(this).sAList = data));
		}

		_getFailRateData() {
			const {$routeParams, digitalInspectionReportService} = services.get(this);
			let locationSummaryList = privateMembers.get(this).locationDetails.locationSummaryList[0];
			let locationId = locationSummaryList.locationId;
			let organizationId = locationSummaryList.organizationId;
			let equipments = privateMembers.get(this).sAList.equipmentList;
			const {AUTOMATED_TEST_LEVELS} = services.get(this);
			privateMembers.get(this).sAList.sAList.map(data => {
				if ([AUTOMATED_TEST_LEVELS.INTERMEDIATE, AUTOMATED_TEST_LEVELS.ADDONEXCEPTION].indexOf(data.automatedTestLevel) > -1) {
					return;
				}
				this.serviceAdvisoryTypeIDs.push(data.serviceAdvisoryTypeId);
				return data.serviceAdvisoryTypeId;
			});
			let callRange = {
				from: moment($routeParams.startDate).add(1, 'days'),
				to: moment($routeParams.endDate).add(1, 'days'),
			};
			return digitalInspectionReportService.getFailRateData(locationId, organizationId, equipments, this.serviceAdvisoryTypeIDs, callRange).then(data => {
				privateMembers.get(this).failureRateList = data.failureRateList;
			});
		}

		_buildData() {
			const {$filter, helpers, DIR_STATUS} = services.get(this);
			let sAList = privateMembers.get(this).sAList.sAList;
			let fRList = privateMembers.get(this).failureRateList;

			updateTestStatusColumn(sAList);

			let filteredData = {};
			const chillerSubComponents = {
				Circuit: [],
				Compressor: [],
			};
			const workingTisObjectIds = [privateMembers.get(this).equipmentId];
			let equipmentData = privateMembers.get(this).locationObjectsList;
			let equipment = $filter('filterNested')(equipmentData.tisObjectList, {tisObjectId: parseInt(privateMembers.get(this).equipmentId)}, true).pop();
			saveChillerSubComponents(equipment.children);
			Object.keys(chillerSubComponents).forEach(key => chillerSubComponents[key].forEach(equipment => workingTisObjectIds.push(equipment.tisObjectId)));
			let newTestObject = {};
			fRList.map(fRData => {
				let testFound = sAList.find(
					sAData => sAData.tisObjectId === fRData.tisObjectId && sAData.serviceAdvisoryTypeId === fRData.serviceAdvisoryTypeId
				);
				if (!testFound.testStatus.isNotSuppressed) {
					return;
				}
				let testCatFound = this.getSuggestionByServiceAdvisoryTypeActionName({id: testFound.actionName});
				if (testCatFound && testCatFound.category) {
					newTestObject = {
						automatedTestName: testCatFound.name || '-',
						testName: getTisObjectName(testFound),
						failureRateIndicator: fRData.failureRateIndicator,
						tisObjectId: fRData.tisObjectId,
						serviceAdvisoryTypeId: fRData.serviceAdvisoryTypeId,
						TestCategoryName: testCatFound.category || '-',
						groupName: equipment.tisObjectType.tisObjectTypeGroupName,
					};
					checkCategoryExists(newTestObject, testCatFound);
				} else {
					newTestObject = {
						automatedTestName: '-',
						testName: getTisObjectName(testFound),
						failureRateIndicator: fRData.failureRateIndicator,
						tisObjectId: fRData.tisObjectId,
						serviceAdvisoryTypeId: fRData.serviceAdvisoryTypeId,
						groupName: equipment.tisObjectType.tisObjectTypeGroupName,
						TestCategoryName: '-',
					};
					checkCategoryExists(newTestObject, testCatFound);
				}
			});

			function checkATExists(filteredData, testObject) {
				const data = filteredData.find(
					data => `${data.tisObjectId}$${data.serviceAdvisoryTypeId}` === `${testObject.tisObjectId}$${testObject.serviceAdvisoryTypeId}`
				);
				return !!data;
			}

			function checkCategoryExists(testObject, testCatFound) {
				if (!filteredData.Miscellaneous && (!testCatFound || !testCatFound.category)) {
					filteredData.Miscellaneous = [testObject];
				} else if (filteredData.Miscellaneous && (!testCatFound || !testCatFound.category)) {
					!checkATExists(filteredData.Miscellaneous, testObject) && filteredData.Miscellaneous.push(testObject);
				} else if (filteredData[testCatFound.category] && (testCatFound && testCatFound.category)) {
					!checkATExists(filteredData[testCatFound.category], testObject) && filteredData[testCatFound.category].push(testObject);
				} else if (!filteredData[testCatFound.category] && (testCatFound || testCatFound.category)) {
					filteredData[testCatFound.category] = [testObject];
				}
			}
			function getTisObjectName(tisObject) {
				const {tisObjectTypeGroupName} = tisObject.tisObjectType;
				if (tisObjectTypeGroupName === 'Circuit' || tisObjectTypeGroupName === 'Compressor') {
					return $filter('translateTest')(tisObject.actionName, {
						name: $filter('translate')(
							helpers.getPropertyByPath(
								chillerSubComponents[tisObjectTypeGroupName].find(({tisObjectId}) => tisObjectId === tisObject.tisObjectId),
								'instance'
							)
						),
					});
				} else {
					return $filter('translate')('AUTOMATED_TEST_ACTIONS.' + tisObject.actionName);
				}
			}
			function saveChillerSubComponents(tisObjects = []) {
				tisObjects.forEach(function(tisObject) {
					if (chillerSubComponents[tisObject.tisObjectType.tisObjectTypeGroupName]) {
						chillerSubComponents[tisObject.tisObjectType.tisObjectTypeGroupName].push(tisObject);
					}
					if (tisObject.children) {
						saveChillerSubComponents(tisObject.children);
					}
				});
			}
			function sortCategories(filtData) {
				let sortedData = Object.keys(filtData)
					.sort()
					.reduce((obj, key) => {
						obj[key] = filtData[key];
						return obj;
					}, {});
				// To Add Miscellaneous to the end after sorting
				if (sortedData.Miscellaneous) {
					delete sortedData.Miscellaneous;
					sortedData['Miscellaneous'] = filtData.Miscellaneous;
				}
				return sortedData;
			}
			function updateTestStatusColumn(sAList) {
				const checkIsSuppressed = (analyticParameterValue = null) =>
					analyticParameterValue === null ? false : Boolean(Number(analyticParameterValue));

				sAList.forEach(test => {
					const suppressionAnalyticParameterValue = helpers.getPropertyByPath(test, 'suppressionAnalyticParameter.values[0]');
					const isSuppressed = suppressionAnalyticParameterValue
						? checkIsSuppressed(suppressionAnalyticParameterValue.analyticParameterValue)
						: false;

					test.testStatus = {
						isNotSuppressed: !isSuppressed,
						text: $filter('translate')(isSuppressed ? 'SUPPRESSED' : 'ACTIVE'),
					};
				});
			}
			function splitIfSingleInspectionDetailsExceedsPage(failRateData) {
				// perPageSpaceAvailableSpace is 863 ,each automated test max height is 25 , approx
				// 33 automated tests per category is displayable
				const maxNumberOfAutomatedTestDisplayablePerCategory = 33;
				const _failRateData = Object.keys(failRateData).reduce((acc, key) => {
					if (failRateData[key].length > maxNumberOfAutomatedTestDisplayablePerCategory) {
						const splitter = Math.ceil(failRateData[key].length / maxNumberOfAutomatedTestDisplayablePerCategory);
						const splitData = helpers.chunks(failRateData[key], Math.ceil(failRateData[key].length / splitter));
						splitData.forEach((data, index) => {
							if (index > 0) {
								acc[`${key}_${index}`] = data;
							} else {
								acc[key] = data;
							}
						});
						return acc;
					} else {
						acc[key] = failRateData[key];
						return acc;
					}
				}, {});
				return _failRateData;
			}

			let failRateData = sortCategories(filteredData);
			this.failRateData = failRateData;
			this.fialRateInspectionData = splitIfSingleInspectionDetailsExceedsPage(failRateData);
			this.failRateCategoriesToSort = Object.keys(this.failRateData);
			this.failRateCategoriesToSortInspectionDetails = Object.keys(this.fialRateInspectionData);
			this.failRateCategoriesForInspectionDetailsToDisplay = Object.keys(this.fialRateInspectionData).reduce((acc, val) => {
				if (/(_\d+)$/.test(val)) {
					acc[val] = `${val.split('_')[0]} Continued...`;
					return acc;
				} else {
					acc[val] = val;
					return acc;
				}
			}, {});
			this.failRateCategoriesToSort.map(key => this.failRateData[key].sort((a, b) => a.testName.localeCompare(b.testName)));
			this.failRateCategoriesToSortInspectionDetails.map(key => this.fialRateInspectionData[key].sort((a, b) => a.testName.localeCompare(b.testName)));
			this.cssClassStyles = DIR_STATUS;
			return this.failRateData;
		}
		_createNewPages() {
			const generateNewInspectionPageObject = () => ({height: 860, sections: this.failRateData, pageName: 'DIGITAL_INSPECTION_REPORT'});
			const generateNewDetailsPageObject = () => ({
				height: 860,
				sections: this.fialRateInspectionData,
				failRateCategoryKeys: this.failRateCategoriesToSortInspectionDetails,
				failRateCategories: this.failRateCategoriesForInspectionDetailsToDisplay,
				pageName: 'INSPECTION_DETAILS',
			});

			const pages = [generateNewInspectionPageObject(), generateNewDetailsPageObject()];

			this.pages = pages;
		}
	}

	angular
		.module('TISCC')
		.controller('DigitalInspectionReportController', DigitalInspectionReportController)
		.directive('numberOfPages', function($timeout) {
			// move directive to different file
			return {
				link: function postLink(scope, element, attrs) {
					$timeout(function() {
						const getBox = ele => {
							if (!ele) {
								return;
							}
							return ele.getBoundingClientRect();
						};
						const pageHeight = 1056; // 11inch = 1056px
						const commonFooterHeight = 57;
						const emptyPageAvailableHeight = 863; // pageHeight - (commonFooterHeight + commonHeaderHeight)
						const summaryBoxOffsetTop = getBox(angular.element(document.getElementsByClassName('dir-summary')[0])[0]).top;
						const pageHeightAvailableForSummary = pageHeight - (commonFooterHeight + summaryBoxOffsetTop);
						const detailsBoxFullheight = angular.element(document.getElementsByClassName('dir-detailed-section')[0])[0].scrollHeight;
						const detailsElements = Array.from(angular.element(document.getElementsByClassName('dir-detailed-element')));
						const {summaryTotalHeight, eachSummaryHeightList} = Array.from(
							angular.element(document.getElementsByClassName('section-summary'))
						).reduce(
							(acc, val) => {
								acc.summaryTotalHeight = acc.summaryTotalHeight + getBox(val).height;
								acc.eachSummaryHeightList.push(getBox(val).height);
								return acc;
							},
							{summaryTotalHeight: 0, eachSummaryHeightList: []}
						);
						const pages = [0];
						const detailPages = [0];
						let summaryHeightAccumalator = 0;
						if (summaryTotalHeight > pageHeightAvailableForSummary) {
							eachSummaryHeightList.map((val, numberOfCategories) => {
								summaryHeightAccumalator = val + summaryHeightAccumalator;
								if (pages.length === 1) {
									if (summaryHeightAccumalator > pageHeightAvailableForSummary) {
										pages.push(numberOfCategories);
										summaryHeightAccumalator = val;
									}
								} else if (pages.length > 1) {
									if (summaryHeightAccumalator > emptyPageAvailableHeight) {
										pages.push(numberOfCategories);
										summaryHeightAccumalator = val;
									}
								}
							});
						}
						if (detailsBoxFullheight > emptyPageAvailableHeight) {
							let buffer = 0;
							let detailsHeightAccumalator = 0;
							detailsElements.map((val, key) => {
								if (key % 2 === 0) {
									buffer = val.scrollHeight;
									if (key === detailsElements.length - 1) {
										const valHeight = buffer;
										buffer = 0;
										detailsHeightAccumalator = valHeight + detailsHeightAccumalator;
										if (detailsHeightAccumalator > emptyPageAvailableHeight) {
											detailPages.push(key);
										}
									}
								} else {
									const valHeight = buffer > val.scrollHeight ? buffer : val.scrollHeight;
									buffer = 0;
									detailsHeightAccumalator = valHeight + detailsHeightAccumalator;
									if (detailsHeightAccumalator > emptyPageAvailableHeight) {
										detailPages.push(key - 1);
										detailsHeightAccumalator = valHeight;
									}
								}
							});
						}
						// give controller access more angular way
						scope.$parent.dir.splitPages && scope.$parent.dir.splitPages({pages, detailPages});
					});
				},
			};
		});
})();
