(function() {
	class urlService {
		constructor($location, CHART_DATE_FORMAT) {
			this.location = $location;
			this.dateFormat = CHART_DATE_FORMAT.RANGE_DATE_FORMAT;
		}

		changePath(newPath) {
			this.location.path(newPath).replace();
		}

		changeUrl(newUrl) {
			this.location.url(newUrl).replace();
		}

		/**
		 * Sets the startDate and endDate in url params
		 * If 'from' and/or 'to' params is/are null the properties startDate and/or endDate will be deleted from url
		 * @param {moment} [from=null]
		 * @param {moment} [to=null]
		 */
		changeDateRangeInUrl(from = null, to = null, replace) {
			// we revert this for correct visual
			// Create debouncer
			from = from === null ? null : from.format(this.dateFormat);
			to = from === null ? null : to.format(this.dateFormat);
			this.location.search({startDate: from, endDate: to});
			replace && this.location.replace(); // clear last history route
		}

		updateHttpsToHttp(url = '') {
			if (url.indexOf('https') === 0) {
				return url.replace('https', 'http');
			}

			return url;
		}
	}

	angular.module('TISCC').service('urlService', urlService);
})();
