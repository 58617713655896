const LocationSettingsFctry = function() {
	let locations = new Map();

	class LocationSettings {
		constructor() {
			this.rangeOffset = 0;
		}
	}

	this.getLocation = id => {
		if (!locations.has(id)) {
			locations.clear();
			locations.set(id, new LocationSettings());
		}
		return locations.get(id);
	};

	this.cleanLocations = () => {
		locations.clear();
	};

	return this;
};

angular.module('TISCC').factory('LocationSettingsFctry', LocationSettingsFctry);
