export const RANGE_MODE = 'rangeMode';
export const RANGE_FROM = 'rangeFrom';
export const RANGE_TO = 'rangeTo';

export const rangeModeListener = (scope, callback) => {
	rangeLisener(scope, {watch: 'rangeMode', key: RANGE_MODE}, callback);
};

export const rangeDateListener = (scope, callback) => {
	rangeLisener(scope, {watch: 'range.from', key: RANGE_FROM}, callback);
	rangeLisener(scope, {watch: 'range.to', key: RANGE_TO}, callback);
};

export const rangeLisener = (scope, obj, callback) => {
	scope.$watch(obj.watch, function(newValue, oldValue) {
		if (newValue === oldValue) {
			return;
		}
		return callback(obj.key, newValue);
	});
};
