angular.module('TISCC').service('colorService', function() {
	let COLOR_TABLE = [
		// vivid            //light            //dark
		[255, 255, 0],
		[232, 232, 156],
		[108, 117, 2], // yellow
		[175, 19, 253],
		[221, 154, 255],
		[106, 61, 154], // purple
		[227, 26, 28],
		[253, 143, 142],
		[143, 35, 35], // red
		[4, 176, 2],
		[125, 232, 118],
		[29, 91, 0], // green
		[0, 197, 255],
		[0, 162, 162],
		[0, 106, 138], // light blue
		[255, 127, 0],
		[253, 191, 111],
		[140, 70, 0], // orange
		[14, 112, 255],
		[144, 163, 255],
		[0, 40, 228], // blue
		[255, 0, 170],
		[255, 110, 207],
		[154, 0, 103], // pink
	];

	let ATTEMPTS_COUNT_TO_GET_SIMILAR_COLOR = 10;
	let ACCEPTABLE_COLOR_DIFF = 60;

	let MIXIN_COLORS = [[127, 127, 127], [255, 255, 255], [0, 0, 0], [127, 0, 0], [0, 127, 0], [127, 0, 0]];

	let COLUMNS_IN_TABLE = 3;
	let MAX_AMOUNT_OF_COLORS = COLOR_TABLE.length * (MIXIN_COLORS.length + 1);

	let colorCounter = 0;
	let getRandomColor = function() {
		let colorTemplate = [
			'#476AF5',
			'#00A2C7',
			'#107896',
			'#0A8A48',
			'#1AA81D',
			'#829356',
			'#A88C00',
			'#F26D21',
			'#B45C2D',
			'#D13823',
			'#C74385',
			'#A150D7',
		];
		const color = colorTemplate[colorCounter % 12];
		colorCounter++;
		return color;
	};

	this.rgbToRgba = function(hexString, opacity) {
		let alpha = opacity === undefined ? 1 : opacity;
		return hexString.replace('rgb', 'rgba').replace(')', ', ' + alpha + ')');
	};

	/**
	 * Creates new color generator which has similar to ES6 generators interface.
	 *
	 * @returns {{next: next, reset: reset}}
	 */
	this.generator = function() {
		let isOccupiedColor = new Array(COLOR_TABLE.length).fill(false);
		let usedDesiredColors = {};
		let usedPredefinedColors = 0;

		function isGeneratorDone() {
			return usedPredefinedColors + 1 === MAX_AMOUNT_OF_COLORS;
		}

		function next(desiredColor, forceColor) {
			let result = {
				value: null,
			};

			if (desiredColor && (!usedDesiredColors[desiredColor] || forceColor)) {
				if (!usedDesiredColors[desiredColor]) {
					usedPredefinedColors++;
				}

				result.value = getDesiredColor(desiredColor, isOccupiedColor);

				if (result.value !== null) {
					usedDesiredColors[desiredColor] = true;
				}
			}

			if (result.value === null && !isGeneratorDone()) {
				usedPredefinedColors++;
				if (!desiredColor && usedPredefinedColors < isOccupiedColor.length) {
					result.value = getPredefinedColor(isOccupiedColor);
				} else if (desiredColor) {
					let similarColor = getRandomColor();
					result.value = hexToRgbArray(similarColor);
					usedDesiredColors[similarColor] = true;
				} else {
					result.value = getMixedDefinedColor(usedPredefinedColors - isOccupiedColor.length);
				}

				// On last value (168), allow to convert rgb array to Hex value. else it would throw error if it returns rgb value
				if (isGeneratorDone()) {
					result.value = rgbArrayToHex(result.value);
				}
			}

			result.done = isGeneratorDone();

			if (!result.done) {
				result.value = rgbArrayToHex(result.value);
			}
			return result;
		}

		function reset() {
			usedPredefinedColors = 0;
			usedDesiredColors = {};
			isOccupiedColor.fill(false);
		}

		function occupy(colorsArray) {
			colorsArray.forEach(color => {
				if (color) getDesiredColor(color, isOccupiedColor);
				usedDesiredColors[color] = true;
				usedPredefinedColors++;
			});
		}

		return {
			next: next,
			reset: reset,
			occupy: occupy,
		};
	};
	function getMixedDefinedColor(mixedColorNumber) {
		let mixinId = parseInt(mixedColorNumber % MIXIN_COLORS.length);
		let mixinColor = MIXIN_COLORS[mixinId];
		return getMixedColor(COLOR_TABLE[mixedColorNumber % COLOR_TABLE.length], mixinColor);
	}

	function getDesiredColor(desiredColor, isOccupiedColor) {
		let result = findClosestColor(COLOR_TABLE, desiredColor);

		if (result.closestColorDiff > ACCEPTABLE_COLOR_DIFF) {
			return hexToRgbArray(desiredColor);
		} else if (!isOccupiedColor[result.closestColorIndex]) {
			isOccupiedColor[result.closestColorIndex] = true;
			return hexToRgbArray(desiredColor);
		}

		return null;
	}

	function getPredefinedColor(isOccupiedColor) {
		for (let shift = 0; shift < COLUMNS_IN_TABLE; shift++) {
			for (let j = 0; j < COLOR_TABLE.length / COLUMNS_IN_TABLE; j++) {
				let nextId = j * COLUMNS_IN_TABLE + shift;

				if (!isOccupiedColor[nextId]) {
					isOccupiedColor[nextId] = true;
					return COLOR_TABLE[nextId];
				}
			}
		}

		throw new Error('All predefined Colors are used!');
	}

	function getMixedColor(a, b) {
		let middleColor = [];
		a = a ? a : 0;
		b = b ? b : 0;

		for (let i = 0; i < 3; i++) {
			middleColor[i] = parseInt((a[i] + b[i]) / 2);
		}

		return middleColor;
	}

	function findClosestColor(colors, hexColor) {
		let rgbColor = hexToRgbArray(hexColor);
		let result = {
			closestColorIndex: -1,
			closestColorDiff: Infinity,
		};

		for (let i = 0; i < colors.length; i++) {
			let diff = rgbColorDiff(rgbColor, colors[i]);

			if (result.closestColorDiff > diff) {
				result.closestColorIndex = i;
				result.closestColorDiff = diff;
			}
		}

		return result;
	}

	function rgbColorDiff(a, b) {
		return Math.abs(a[0] - b[0]) + Math.abs(a[1] - b[1]) + Math.abs(a[2] - b[2]);
	}

	function hexToRgbArray(hexString) {
		let hex = hexString.trim().substr(1);
		let hexArr = hex.length === 6 ? hex.match(/.{2}/g) : hex.split('').map(duplicateString);

		function convertHexToDecimal(hex) {
			return parseInt(hex, 16);
		}

		function duplicateString(str) {
			return str + str;
		}

		return hexArr.map(convertHexToDecimal);
	}

	function rgbArrayToHex(rgbArray) {
		const [r, g, b] = rgbArray;

		function componentToHex(c) {
			const hex = c.toString(16);
			return hex.length === 1 ? '0' + hex : hex;
		}

		function rgbToHex(r, g, b) {
			return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
		}

		return rgbToHex(r, g, b);
	}
});
