angular.module('TISCC').service('chartConfigService', function(helpers) {
	this.update = function(configToUpdate, params) {
		const checkedProperties = params.propertyList.reduce(extractCheckedProperties, []);
		const checkedTisObjectIds = params.tisObjectList.reduce(extractCheckedTisObjects, []);
		const updateObj = {};

		checkedTisObjectIds.forEach(tisObjectId => (updateObj[tisObjectId] = checkedProperties));
		const customProps = removeOriginalProps(updateObj, configToUpdate.originalProps[params.equipmentTypeName]);

		if (Object.keys(customProps).length) {
			configToUpdate.update(params.equipmentTypeName, customProps);
		}
	};

	this.createNew = data => new ChartConfig(data);

	function removeOriginalProps(allProps, originalProps = {}) {
		Object.keys(allProps).forEach(property => {
			if (originalProps[property]) {
				const filtered = helpers.arrayRemoveIntersections(allProps[property], originalProps[property], o => o.propertyName);

				if (!filtered.length) {
					delete allProps[property];
				} else {
					allProps[property] = filtered;
				}
			}
		});

		return allProps;
	}

	function extractCheckedProperties(propsArray, property) {
		if (!property.disabled && property.checked) {
			const propertyToSave = {
				uom: property.uom,
				hpath: property.hpath,
				enumeration: property.propertyEnumeration || null,
				propertyName: property.propertyName,
				instanceTisObjectId: property.instancesTisObjectId,
			};

			propsArray.push(propertyToSave);
		}

		return propsArray;
	}

	function extractCheckedTisObjects(tisObjectIds, tisObject) {
		if (tisObject.checked) {
			tisObjectIds.push(tisObject.tisObjectId);
		}

		return tisObjectIds;
	}

	// TODO: extract to separate file when build process will allow it to do.
	class ChartConfig {
		constructor(data = {}) {
			this.customChartProps = JSON.parse(JSON.stringify(data));
			this.originalProps = {};
			this.formattingProps = {};
		}

		setFromClone(data = {}) {
			const {customChartProps, originalProps, formattingProps} = data;
			this.customChartProps = customChartProps || this.customChartProps;
			this.originalProps = originalProps || this.originalProps;
			this.formattingProps = formattingProps || this.formattingProps;
		}

		clear() {
			this.customChartProps = {};
			this.originalProps = {};
		}

		setOriginalProps(originalProps) {
			this.originalProps = originalProps;
		}

		setFormattingProps(formattingProps) {
			Object.assign(this.formattingProps, formattingProps);
		}

		getFormattingProps() {
			return this.formattingProps;
		}

		clearFormattingFor(hash) {
			if (this.formattingProps[hash]) {
				delete this.formattingProps[hash];
			}
		}

		hasFormattingProps() {
			return Object.keys(this.formattingProps).length !== 0; //
		}

		hasCustomProperty(tisObjectId, propertyName, tisObjectsType) {
			return this._hasProperty(this.customChartProps, tisObjectId, propertyName, tisObjectsType);
		}

		hasOriginalProperty(tisObjectId, propertyName, tisObjectsType) {
			return this._hasProperty(this.originalProps, tisObjectId, propertyName, tisObjectsType);
		}

		_hasProperty(props, tisObjectId, propertyName, tisObjectsType) {
			const types = Object.keys(props);

			for (let i = 0; i < types.length; i++) {
				const type = types[i];
				const ids = props[type];

				if (!ids || !ids[tisObjectId]) {
					continue;
				}

				if (ids[tisObjectId].find(p => p.propertyName === propertyName)) {
					if (!tisObjectsType || tisObjectsType === type) {
						return true;
					}
				}
			}

			return false;
		}

		/**
		 *
		 * Method to validate a property is available in customProperty object
		 *
		 * Has additional check for multi circuit objects
		 *
		 * @param {*} tisObjectId - selected equipment tisObject Id
		 * @param {*} propertyName
		 * @param {*} childTisObjectId - child equipment tisObject Id. Each property has details of tisObjectId which mapped with.
		 * @param {*} callback - callback to write on condition for some() fn.
		 * @returns numbers
		 * 			1 - propertyName is not a custom property.
		 * 			2 - propertyName is a custom property and it successfully meets the condition
		 * 			3 - propertyName is a custom property and it is failed to meet the condition
		 *
		 *  Based on output, handle your logics
		 */
		validateCustomPropertyWithOwnTisObjectId(tisObjectId, propertyName, childTisObjectId, callback) {
			const types = Object.keys(this.customChartProps);

			for (let i = 0; i < types.length; i++) {
				const type = types[i];
				const ids = this.customChartProps[type];

				if (!ids || !ids[tisObjectId]) {
					// check if other equipment has same property or not
					if (ids && tisObjectId && !ids[tisObjectId]) {
						return Object.keys(ids).reduce((av, cv) => {
							if (!ids[cv]) return av;

							const otherEquipmentHasSameProps = ids[cv].filter(p => p.propertyName === propertyName);

							// if the propName is mapped with other selected equipment, returns 3 to stop rendering line / legend
							return otherEquipmentHasSameProps.length ? 3 : av;
						}, 1);
					}
					continue;
				}
				const matchedObjects = ids[tisObjectId].filter(p => p.propertyName === propertyName);

				// if no matchedObjects, it's not available in custom Object and check if other equipment has same property or not
				if (matchedObjects.length === 0) {
					return Object.keys(ids).reduce((av, cv) => {
						if (ids[tisObjectId]) return av;

						const otherEquipmentHasSameProps = ids[cv].filter(p => p.propertyName === propertyName);

						// if the propName is mapped with other selected equipment, returns 3 to stop rendering line / legend
						return otherEquipmentHasSameProps.length ? 3 : av;
					}, 1);
				}

				const isMetCondition = matchedObjects.some(object => {
					const {instanceTisObjectId} = object;

					// if instanceTisObjectId is null means, selected proerty is not associated with multiple circuit
					if (instanceTisObjectId === null) return true;

					// if selected proerty is associated with multiple circuit, check which circuit property is selected
					return typeof callback === 'function' ? callback(object) : instanceTisObjectId[childTisObjectId];
				});

				return isMetCondition ? 2 : 3;
			}
			// no data associated with passed tisObjectId, returns 1
			return 1;
		}

		update(equipmentTypeName, updateObj) {
			if (!this.customChartProps[equipmentTypeName]) {
				this.customChartProps[equipmentTypeName] = {};
			}

			const currentTypeConfig = this.customChartProps[equipmentTypeName];
			const checkedTisObjectIds = Object.keys(updateObj);

			checkedTisObjectIds.forEach(tisObjectId => {
				if (!currentTypeConfig[tisObjectId]) {
					currentTypeConfig[tisObjectId] = [];
				}

				const propsSet = new Set([...currentTypeConfig[tisObjectId], ...updateObj[tisObjectId]]);
				currentTypeConfig[tisObjectId] = Array.from(propsSet);
			});
		}

		getOriginalTisObjectIds() {
			const allIds = [];

			Object.keys(this.originalProps).forEach(type => {
				allIds.push(...this.getOriginalTisObjectIdsByType(type));
			});

			return allIds;
		}

		getAllTisObjectIdsByType(type) {
			return [...(this.getCustomTisObjectIdsByType(type) || []), ...(this.getOriginalTisObjectIdsByType(type) || [])];
		}

		getOriginalTisObjectIdsByType(type) {
			if (!this.originalProps[type]) {
				return null;
			}

			return Object.keys(this.originalProps[type]).map(Number);
		}

		getCustomTisObjectIdsByType(type) {
			if (!this.customChartProps[type]) {
				return null;
			}

			return Object.keys(this.customChartProps[type]).map(Number);
		}

		/**
		 *
		 * @param {String} type - type of equipment i.e Chiller, Circuit etc
		 * @param {func} callback - custom validator func to arrayUnique helper
		 * @returns
		 */
		getPropertiesByType(type, callback) {
			const ids = this.getCustomTisObjectIdsByType(type);

			if (!ids) {
				return null;
			}

			const allPropsByType = [];
			ids.forEach(id => {
				allPropsByType.push(...this.customChartProps[type][id]);
			});

			return helpers.arrayUnique(allPropsByType, typeof callback === 'function' ? callback : p => p.hpath);
		}

		getOriginalPropertiesByType(type) {
			const ids = this.getOriginalTisObjectIdsByType(type);

			if (!ids) {
				return [];
			}

			const allPropsByType = [];
			ids.forEach(id => {
				if (isNaN(id)) {
					id = null;
				}
				allPropsByType.push(...this.originalProps[type][id]);
			});
			return helpers.arrayUnique(allPropsByType, p => p.hpath);
		}

		getEquipmentTypes() {
			return Object.keys(this.customChartProps);
		}

		hasCustomProps() {
			return !!this.getEquipmentTypes().length;
		}

		isEmpty() {
			return !this.hasCustomProps() && Object.keys(this.originalProps).length === 0;
		}

		hasAdditionalEquipmentTypes() {
			return this.hasCustomProps();
		}

		getCustomAddedUoms(tisObjectType) {
			const originalPropertyNames = this.getOriginalPropertiesByType(tisObjectType).map(item => item.propertyName);
			return helpers
				.arrayUnique(this.getPropertiesByType(tisObjectType) || [], p => p.uom.name)
				.filter(item => !originalPropertyNames.includes(item.propertyName))
				.map(item => item.uom);
		}

		/**
		 *
		 * This equal menthod implemenation is specific to edit-properties-controller
		 * check the logic before use it
		 *
		 * @param { object } otherConfig
		 * @returns { boolean }
		 */

		equals(otherConfig) {
			if (this.hasCustomProps() !== otherConfig.hasCustomProps()) {
				return false;
			}

			const thatTypes = this.getEquipmentTypes().sort();
			const otherTypes = otherConfig.getEquipmentTypes().sort();

			if (!helpers.arrayEquals(thatTypes, otherTypes)) {
				return false;
			}

			const propertyValueExtractor = p => {
				if (!p.instanceTisObjectId) return p.hpath;
				const value = Object.keys(p.instanceTisObjectId || {}).toString();
				return p.hpath + '-' + value;
			};

			for (let i = 0; i < thatTypes.length; i++) {
				const byHpath = (a, b) => a.hpath > b.hpath;
				const thatProps = this.getPropertiesByType(thatTypes[i], propertyValueExtractor).sort(byHpath);
				const otherProps = otherConfig.getPropertiesByType(otherTypes[i], propertyValueExtractor).sort(byHpath);

				if (!helpers.arrayEquals(thatProps, otherProps, (a, b) => a.hpath === b.hpath)) {
					return false;
				}

				const thatIds = this.getCustomTisObjectIdsByType(thatTypes[i]).sort();
				const otherIds = otherConfig.getCustomTisObjectIdsByType(otherTypes[i]).sort();

				if (!helpers.arrayEquals(thatIds, otherIds)) {
					return false;
				}
			}

			return true;
		}
	}
});
