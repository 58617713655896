export default class MyViewsGroup {
	constructor({groupOperator, ruleOperator, rules = []}) {
		this.groupOperator = groupOperator || MyViewsGroup.DEFAULT_GROUP_OPERATOR;
		this.ruleOperator = ruleOperator || MyViewsGroup.DEFAULT_RULE_OPERATOR;
		this.rules = rules;
	}
}

MyViewsGroup.AND_OPERATOR = 'and';
MyViewsGroup.OR_OPERATOR = 'or';
MyViewsGroup.DEFAULT_GROUP_OPERATOR = MyViewsGroup.AND_OPERATOR;
MyViewsGroup.DEFAULT_RULE_OPERATOR = MyViewsGroup.AND_OPERATOR;
