import {CPR_DATE_FORMATS} from '../../chiller-performance-report-config';

const services = new WeakMap();

class ChillerLifeSettingsPopupComponentController {
	constructor(helpers) {
		services.set(this, {helpers});
		this.formDisabled = true;
	}

	$onInit() {
		this.CPR_DATE_FORMATS = CPR_DATE_FORMATS;
		this.intactValues = {};

		const {ChillerEnteredService, ChillerLife} = this.data.values;
		const getChillerEnteredServiceAsMomentDate = () => ChillerEnteredService && moment.tz(ChillerEnteredService, this.data.timeZone);

		this.intactValues.chillerEnteredService = getChillerEnteredServiceAsMomentDate();
		this.intactValues.chillerLife = ChillerLife;

		this.chillerEnteredService = getChillerEnteredServiceAsMomentDate();
		this.chillerLife = ChillerLife;
		this.tmpDate = this.chillerEnteredService && this.chillerEnteredService.clone();
		this.formDisabled = false;

		this.header = this.data.header || 'ENTER_CHILLER_LIFE_DATA';
	}

	save() {
		this.modalInstance.close({
			ChillerEnteredService: this.chillerEnteredService,
			ChillerLife: this.chillerLife,
		});
	}

	cancel() {
		this.modalInstance.dismiss('cancel');
	}

	onChangeDate({start}) {
		this.chillerEnteredService = start;
	}

	isSaveButtonEnabled() {
		const isFormIntact =
			this.chillerEnteredService &&
			this.intactValues.chillerEnteredService &&
			this.chillerEnteredService.isSame(this.intactValues.chillerEnteredService) &&
			this.chillerLife === this.intactValues.chillerLife;

		return this.chillerLife !== undefined && this.chillerLife !== null && this.chillerEnteredService && !isFormIntact;
	}
}

export default {
	templateUrl: 'components/reports/chiller-performance/modals/chiller-life-settings/chiller-life-settings-popup.html',
	controller: ChillerLifeSettingsPopupComponentController,
	bindings: {
		modalInstance: '<',
		data: '<',
	},
};
