(function() {
	class DetailedPageController {
		constructor() {}
	}

	angular.module('TISCC').component('dirDetailedPageComponent', {
		templateUrl: 'components/reports/digital-inspection/page/detailed-page/detailed-page-component.html',
		controller: DetailedPageController,
		bindings: {
			failureData: '<',
			failRateCategories: '<',
			failRateCategoriesKeys: '<',
			cssClassStyles: '<',
		},
	});
})();
