const services = new WeakMap();

const ICONS_MAP = {
	['Release']: 'icon-smiley-face',
	['Outage']: 'icon-outage-alarm',
};

const checkSetTitle = function(el, title) {
	if (el.scrollWidth > el.clientWidth && !el.title) {
		el.setAttribute('title', title);
	} else if (el.scrollWidth <= el.clientWidth) {
		el.removeAttribute('title');
	}
};

class NotificationItemController {
	constructor($timeout, notificationsService) {
		services.set(this, {$timeout, notificationsService});
		this.iconsMap = ICONS_MAP;
		this.item.selected = false;
	}

	getFormattedDate(date) {
		return moment(date).format('MMM D, YYYY');
	}

	changeState(e) {
		const {currentTarget} = e;
		const titleElement = currentTarget.getElementsByClassName('message-item-title')[0];
		const {$timeout} = services.get(this);

		$timeout(() => {
			checkSetTitle(titleElement, this.item.title);
		}, 800);

		this.onToggle(this.item);

		e.stopPropagation();
	}

	titleOnOver(e) {
		const el = e.target;
		checkSetTitle(el, this.item.title);
	}
}

angular.module('TISCC').component('notificationItem', {
	templateUrl: 'components/notifications/notification-item.html',
	controller: NotificationItemController,
	bindings: {
		item: '<',
		actionClass: '@',
		actionTitle: '@',
		onActionClick: '<',
		onToggle: '<',
	},
});
