const services = new WeakMap();
const PRIORITIES = ['LOW', 'MEDIUM', 'HIGH', 'CRITICAL'];

export class PerformanceImprovementsSettingsPopupComponentController {
	constructor(CPR_DETAILS_ACTION_TYPE) {
		services.set(this, {CPR_DETAILS_ACTION_TYPE});
		this.formImprovement = {};
		this.activeImprovements = [];
		this.originalImprovements = [];
		this.PRIORITIES = PRIORITIES;
	}

	$onInit() {
		this.actionItemMaxLength = 100;
		this.actionImprovementMaxLength = 200;
		this.header = this.data.header || 'ENTER_PERFORMANCE_IMPROVEMENTS';
		this.activeImprovements = this.data.values;
		this.originalImprovements = this.activeImprovements.map(item => {
			return Object.assign({}, item);
		});
	}

	updateSelectedPriority(priority, item) {
		if (item) {
			item.actionPriority = priority;
		} else {
			this.formImprovement.actionPriority = priority;
		}
	}

	addImprovement(item) {
		const {CPR_DETAILS_ACTION_TYPE} = services.get(this);
		item.id = new Date().toString();
		item.actionType = CPR_DETAILS_ACTION_TYPE.IMPROVEMENT;
		this.activeImprovements.push(item);
		this.formImprovement = {};
	}

	removeImprovement(index) {
		if (index >= 0) {
			this.activeImprovements.splice(index, 1);
		}
	}

	isSaveButtonEnabled() {
		const isImprovementsNotEmpty = this.activeImprovements.reduce((result, improvement) => {
			return result && (improvement && improvement.actionItem && improvement.actionImprovement && improvement.actionPriority);
		}, true);
		return isImprovementsNotEmpty && !angular.equals(this.activeImprovements, this.originalImprovements);
	}

	save() {
		this.modalInstance.close({
			tableHeader: {
				actionItem: {name: 'ITEM', style: {width: '420px'}},
				actionPriority: {name: 'PRIORITY', style: {width: '92px'}},
				actionImprovement: {name: 'IMPROVEMENT_IN', style: {width: 'auto'}},
			},
			improvements: this.activeImprovements,
		});
	}

	cancel() {
		this.modalInstance.dismiss('cancel');
	}

	checkIfCanAdd() {
		return !(this.formImprovement && this.formImprovement.actionItem && this.formImprovement.actionImprovement && this.formImprovement.actionPriority);
	}
}

export default {
	templateUrl: 'components/reports/chiller-performance/modals/performance-improvements-settings/performance-improvements-settings-popup.html',
	controller: PerformanceImprovementsSettingsPopupComponentController,
	bindings: {
		modalInstance: '<',
		data: '<',
	},
};
