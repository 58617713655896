import _get from 'lodash.get';

(function(d3, ScatterChartRenderer) {
	const PERFORMANCE_CURVE = 'performanceCurve';
	const PURPLE = '#6855a1';
	const CHILLER_LOAD_NORMALIZED = 'ChillerLoadNormalized';
	class ScatterChartWithCurveRenderer extends ScatterChartRenderer {
		constructor(svg, externalMethods, clipPathId, x) {
			super(svg, externalMethods, clipPathId, x);
			this.subType = 'scatterWithPerformanceCurve';
			this.MARKER_TYPE = externalMethods.MARKER_TYPE;
			this.MARKER_SIZE = externalMethods.MARKER_SIZE;
			this.CHART_TYPE = externalMethods.CHART_TYPE;
			this.LINE_TYPE = externalMethods.LINE_TYPE;
			this.LINE_THICKNESS = externalMethods.LINE_THICKNESS;
		}
		getPerformanceCurveLineData(lines, chartData, chartOptions) {
			const ChillerLoadNormalizedIndex = chartOptions.lines.findIndex(line => line.propertyName === CHILLER_LOAD_NORMALIZED);
			return chartOptions.lines.reduce((resultingData, line, index) => {
				const lineThickness = line.lineThickness || 1;
				const lineType = line.lineType || 'solid';
				const markerSize = line.markerSize || 3;
				const markerType = line.markerType || 'DOT';
				if (line.chartAxisId === PERFORMANCE_CURVE && line.visible) {
					let chartDataFiltered = [];
					if (Array.isArray(chartData[index])) {
						chartDataFiltered = chartData[index]
							.filter(item => {
								return !isNaN(item.y);
							})
							.sort((a, b) => a.x - b.x);
					}
					const xAxisIndex = chartOptions.lines.findIndex(l => l.chartAxisId.startsWith('x') && l.componentName === line.circuit);

					let performanceCurveRow = {
						performanceCurveData: chartDataFiltered,
						performanceCurveLineName: line.name,
						lineHash: line.lineHash,
						isPerformaceCurve: true,
						lineThickness: lineThickness,
						lineType: lineType,
						xAxisName: xAxisIndex !== -1 ? chartOptions.lines[xAxisIndex].name : null,
						markerSize: markerSize,
						markerType: markerType,
					};

					performanceCurveRow.performanceCurveLineColor = line.color;
					resultingData.push(performanceCurveRow);
					return resultingData;
				} else if (ChillerLoadNormalizedIndex > -1 && line.chartAxisId.startsWith('y') && line.visible) {
					const ChillerLoadNormalizedData = ChillerLoadNormalizedIndex === -1 ? [] : chartData[ChillerLoadNormalizedIndex];
					const data = chartData[index];
					let noPlots = true;
					const axisData = data.reduce((axis, item, index) => {
						const yAxisDate = moment(_get(item, 'x')).format();
						const xAxisDate = moment(_get(ChillerLoadNormalizedData[index], 'x')).format();
						// Compare if the dates match
						if (yAxisDate === xAxisDate) {
							noPlots = false;
							axis.push({
								x: _get(ChillerLoadNormalizedData[index], 'value'),
								y: item.value,
								date: item.x,
							});
						} else {
							// find the date from chiller load normalized list if teh dates dont match
							const ChillerLoadNormalizedEntry = ChillerLoadNormalizedData.find(
								NormItem => moment(NormItem.x).format() === moment(item.x).format()
							);
							if (ChillerLoadNormalizedEntry) {
								noPlots = false;
								axis.push({
									x: ChillerLoadNormalizedEntry.value,
									y: item.value,
									date: item.x,
								});
							}
						}
						return axis;
					}, []);
					if (!noPlots) {
						resultingData.push({
							performanceCurveData: axisData,
							performanceCurveLineName: line.name,
							isPerformaceCurve: false,
							lineHash: line.lineHash,
							performanceCurveLineColor: line.color,
							lineThickness: lineThickness,
							lineType: lineType,
							markerSize: markerSize,
							markerType: markerType,
						});
						return resultingData;
					} else return resultingData;
				} else if (line.chartAxisId.startsWith('y') && line.visible) {
					const xAxisIndex = chartOptions.lines.findIndex(l => l.chartAxisId.startsWith('x') && l.componentName === line.componentName);
					const yAxisIndex = chartOptions.lines.findIndex(l => l.chartAxisId === line.chartAxisId && l.componentName === line.componentName);
					const xAxisData = chartData[xAxisIndex];
					const yAxisData = chartData[yAxisIndex];
					let noPlots = true;
					const axisData = xAxisData.reduce((axis, item, index) => {
						const xAxisDate = moment(_get(item, 'x')).format();
						const yAxisDate = moment(_get(yAxisData[index], 'x')).format();
						// Compare if the dates match
						if (yAxisDate === xAxisDate) {
							noPlots = false;
							axis.push({
								x: item.value,
								y: _get(yAxisData[index], 'value'),
								date: item.x,
							});
						} else {
							// filter Axes data based on date if the dates dont match
							const yDataIndex = yAxisData.find(yItem => moment(yItem.x).format() === moment(item.x).format());
							if (yDataIndex) {
								noPlots = false;
								axis.push({
									x: item.value,
									y: yDataIndex.value,
									date: item.x,
								});
							}
						}
						return axis;
					}, []);
					if (!noPlots) {
						resultingData.push({
							performanceCurveData: axisData,
							performanceCurveLineName: line.name,
							isPerformaceCurve: false,
							lineHash: line.lineHash,
							performanceCurveLineColor: line.color,
							xAxisName: chartOptions.lines[xAxisIndex].name,
							lineThickness: lineThickness,
							lineType: lineType,
							markerSize: markerSize,
							markerType: markerType,
						});
						return resultingData;
					} else return resultingData;
				} else return resultingData;
			}, []);
		}
		_createLineMarkers(container, line) {
			const defs = container.append('defs');
			const MARKER_SIZE = line.markerSize || 3;
			defs
				.append('marker')
				.attr('id', `${line.lineHash}_${this.MARKER_TYPE.SQUARE.name}`)
				.attr('markerUnits', 'userSpaceOnUse')
				.attr('viewBox', '-50 -50 100 95')
				.attr('refX', 0)
				.attr('refY', 0)
				.attr('stroke-width', 10)
				.attr('markerWidth', MARKER_SIZE)
				.attr('markerHeight', MARKER_SIZE)
				.attr('orient', 0)
				.attr('fill', line.color)
				.append('polygon')
				.attr('points', '40 -40, 40 40, -40 40, -40 -40');

			defs
				.append('marker')
				.attr('id', `${line.lineHash}_${this.MARKER_TYPE.TRIANGLE.name}`)
				.attr('markerUnits', 'userSpaceOnUse')
				.attr('viewBox', '-50 -50 100 95')
				.attr('refX', 0)
				.attr('refY', 0)
				.attr('stroke-width', 10)
				.attr('markerWidth', MARKER_SIZE)
				.attr('markerHeight', MARKER_SIZE)
				.attr('orient', 0)
				.attr('fill', line.color)
				.append('polygon')
				.attr('points', '0 -40, 50 45, -50 45');

			defs
				.append('g')
				.attr('id', `${line.lineHash}_${this.MARKER_TYPE.DOT.name}`)
				.append('circle')
				.attr('r', MARKER_SIZE / 2);

			const xMarker = defs
				.append('marker')
				.attr('id', `${line.lineHash}_${this.MARKER_TYPE.X.name}`)
				.attr('markerUnits', 'userSpaceOnUse')
				.attr('viewBox', '-5 -5 10 10')
				.attr('refX', 0)
				.attr('refY', 0)
				.attr('stroke-width', 2)
				.attr('markerWidth', MARKER_SIZE)
				.attr('markerHeight', MARKER_SIZE)
				.attr('orient', 0)
				.attr('stroke', line.color);
			xMarker.append('polygon').attr('points', '-40 -40, 0 1, 40 40');

			xMarker.append('polygon').attr('points', '-40 40,0 1, 40 -40');
		}

		_getStrokeIntervalByLineType(lineType, lineThickness) {
			const isThicknessSizeThreeOrFour = lineThickness === this.LINE_THICKNESS.SIZE_3 || lineThickness === this.LINE_THICKNESS.SIZE_4;
			let result = '';

			switch (lineType) {
				case this.LINE_TYPE.DASHED:
					if (isThicknessSizeThreeOrFour) {
						result = '20, 20';
					} else {
						result = '5, 5';
					}
					break;

				case this.LINE_TYPE.DOTTED:
					if (isThicknessSizeThreeOrFour) {
						result = '1, 10';
					} else if (lineThickness === this.LINE_THICKNESS.SIZE_2) {
						result = '1, 4';
					} else {
						result = '1, 2';
					}
					break;

				case this.LINE_TYPE.DASH_DOT:
					if (isThicknessSizeThreeOrFour) {
						result = '15, 15, 1, 15';
					} else {
						result = '5, 5, 1, 5';
					}

					break;

				case this.LINE_TYPE.LONG_DASH:
					if (isThicknessSizeThreeOrFour) {
						result = '30, 30';
					} else {
						result = '15, 10';
					}
					break;
			}

			return result;
		}

		_getStrokeShapeByLineType(lineType) {
			if (lineType === this.LINE_TYPE.DOTTED || lineType === this.LINE_TYPE.DASH_DOT) {
				return 'round';
			} else {
				return 'square';
			}
		}

		drawPerformanceCurve(targetNode, chartData, chartOptions) {
			let that = this;
			let xAxis = this.scatterXAxis;
			let yAxis = this.yAxes[0];

			this.getPerformanceCurveLineData(...arguments).forEach(
				({
					performanceCurveData,
					performanceCurveLineName,
					performanceCurveLineColor,
					isPerformaceCurve,
					lineHash,
					xAxisName = null,
					lineThickness,
					lineType,
					markerSize,
					markerType,
				}) => {
					let lines = targetNode
						.append('g')
						.attr('line-hash', lineHash)
						.attr('class', 'line')
						.attr('isPerformaceCurve', `${isPerformaceCurve}`)
						.attr('line-name', performanceCurveLineName)
						.attr('stroke', performanceCurveLineColor)
						.attr('class', 'line');
					// if (isPerformaceCurve) {
					this._createLineMarkers(lines, {color: performanceCurveLineColor, lineHash});

					lines
						.append('path')
						.datum(performanceCurveData)
						.attr('d', yAxis.line)
						// This is line settings which were passed for export.
						// If these are empty - they are not applied.
						.attr('stroke-dasharray', this._getStrokeIntervalByLineType(lineType, lineThickness))
						.attr('stroke-linecap', this._getStrokeShapeByLineType(lineType))
						.attr('stroke-width', lineThickness)
						.attr('markerWidth', markerSize)
						.attr('markerHeight', markerSize)
						.attr('marker-start', `url(#${lineHash}_${markerType})`)
						.attr('marker-mid', `url(#${lineHash}_${markerType})`)
						.attr('marker-end', `url(#${lineHash}_${markerType})`);

					if (!isPerformaceCurve) {
						lines.selectAll('g[isPerformaceCurve="false"] > path').attr('stroke-width', 0);
					}

					lines
						.selectAll('dot')
						.data(performanceCurveData)
						.enter()
						.append('circle')
						.attr('class', 'circle-dot')
						.attr('fill', performanceCurveLineColor)
						.attr('dotRadius', 1)
						.attr('r', markerSize / 2 - 0.5)
						.attr('cx', d => xAxis.x(d.x))
						.attr('cy', d => yAxis.y(d.y))
						.on('mouseover', function(d) {
							let xPos = xAxis.x(d.x);
							let yPos = yAxis.y(d.y);

							let name = {
								x: performanceCurveLineName,
								y: xAxisName ? xAxisName : chartOptions.lines[xAxis.index].name,
							};
							let uom = {
								x: yAxis.uomSymbol,
								y: xAxis.uomSymbol,
							};
							let data = {
								x: d.y,
								y: d.x,
								date: moment(d.date).format('M/D/YY h:mm A'),
							};

							that.tooltip.showTooltip({
								xPos: xPos,
								yPos: yPos,
								name: name,
								color: this.getAttribute('fill'),
								scatterData: data,
								uom: uom,
							});
						})
						.on('mouseout', function() {
							that.tooltip.hideTooltip();
						});
				}
			);
		}
	}

	window.ScatterChartWithCurveRenderer = ScatterChartWithCurveRenderer;
})(window.d3, window.ScatterChartRenderer);
