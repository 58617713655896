/* eslint eqeqeq: [0, 'always'] */

import {USER_EVENTS} from '../../../common/usage-tracking/categories';
import {
	getTimePeriodNameByRangeMode,
	getReportNameByKey,
	getEquipmentTypeByKey,
	getReportGenerationStatus,
	CHILLER_COMPONENTS,
} from '../../../common/usage-tracking/categories/report-generation/utils.js';
import {getPropertiesTranslation} from '../../../common/usage-tracking/categories/report-generation/utils';

const {
	REPORT_GENERATION: {categoryName: REPORT_GENERATION_CATEGORY_NAME, events: REPORT_GENERATION_EVENTS, properties: REPORT_GENERATION_PROPERTIES},
} = USER_EVENTS;

angular.module('TISCC').controller('ExceptionHistoryReportCtrl', ExceptionHistoryReportCtrl);
import {rangeModeListener, rangeDateListener} from '../../../common/event-listener/date-range-listener';

function ExceptionHistoryReportCtrl(
	$scope,
	$filter,
	$timeout,
	$controller,
	modalHelperService,
	dataFormattingService,
	$q,
	serviceAdvisoryService,
	chartService,
	locationEquipmentService,
	translateService,
	ExportDataGenerationService,
	exceptionHistoryService,
	exportService,
	exportProgressService,
	locationDetailsService,
	authorization,
	usageTrackingService,
	MetricsFactory,
	REPORT_TYPES
) {
	angular.extend(this, $controller('AbstractReportCtrl', {$scope: $scope}));

	const defaultExportProgressMessages = {
		currentMessage: $filter('translate')('LOADING_REPORT'),
		requestsProcessed: 0,
		requestsTotal: 0,
	};

	let translate = $filter('translate');
	let that = this;
	let uniqueTypes = {};
	let typesList = [];
	let typeExcNames = [];
	let typeExcNamesRequested = [];
	let serviceAdvisoryPromises = [];
	let reportGenerationStartTime = null;
	$scope.equipmentTypesExc = [];
	$scope.list = [];
	$scope.filteredList = [];
	$scope.isGeneratingReport = false;
	$scope.selectDeselectAll = selectDeselectAll;
	$scope.updateCheckboxes = updateCheckboxes;
	$scope.cancelLoading = cancelLoading;
	$scope.selectedFacilities = [$scope.data.locationData];
	$scope.viewModel = {
		isLoadingStarted: false,
	};
	$scope.exportProgressMessages = {...defaultExportProgressMessages};

	$scope.setFacilitiesHandler = list => {
		$scope.$emit('showEquipmentTypesLoader');
		$scope.equipmentTypesExc = [];
		typeExcNamesRequested = [];
		typeExcNames = [];
		typesList = [];
		$scope.updateSelectedEquipmentType();

		$scope.selectedFacilities = [...list];
		getLocationObjectsList($scope.selectedFacilities);
	};
	$scope.onFacilitiesDialogOpen = () => {
		usageTrackingService.trackEvent(REPORT_GENERATION_CATEGORY_NAME, REPORT_GENERATION_EVENTS.EDIT_FACILITY_FOR_REPORT);
	};
	updateSelectAllBtn();

	serviceAdvisoryService.getAllTypes().then(serviceAdvisoryTypeList => {
		for (let i = 0; i < serviceAdvisoryTypeList.length; i++) {
			if (serviceAdvisoryTypeList[i].tisObjectType !== undefined) {
				uniqueTypes[serviceAdvisoryTypeList[i].tisObjectType.tisObjectTypeGroupName] = true;
			}
		}
		chartService.getAllCharts().then(function(chartList) {
			chartList.forEach(chartObj => {
				if (chartObj.tisObjectType !== undefined) {
					uniqueTypes[chartObj.tisObjectType.tisObjectTypeGroupName] = true;
				}
			});
			getLocationObjectsList($scope.selectedFacilities);
		});
	});

	function getLocationObjectsList(locations) {
		const locationTisObjectListPromises = [];
		locations.forEach(location => {
			if (location && location.locationId) {
				locationTisObjectListPromises.push(locationEquipmentService.getLocationObjectsList(location.locationId, null, true));
			}
		});
		$q
			.all(locationTisObjectListPromises)
			.then(function(data) {
				return data.reduce((result, item) => {
					if (item && item.tisObjectList) {
						result.splice(-1, result.length, ...item.tisObjectList);
					}
					return result;
				}, []);
			})
			.then(function(data) {
				$scope.data.equipmentsData = data;
				generateEquipmentDropDown($scope.data.equipmentsData, $scope.data.equipmentsData);
				const hideEquipmentTypesLoader = $scope.$emit.bind($scope, 'hideEquipmentTypesLoader');
				$q.all(serviceAdvisoryPromises).then(hideEquipmentTypesLoader);

				if (
					$scope.defaultEquipment &&
					$scope.selectedFacilities &&
					$scope.selectedFacilities.length === 1 &&
					$scope.selectedFacilities[0].locationId === $scope.defaultEquipment.locationId &&
					($scope.data.isChart || $scope.data.isEquipmentSummary)
				) {
					that.checkEquipments($scope.data.equipmentsData, $scope.defaultEquipment.tisObjectName);
					$scope.updateSelectedEquipmentType({
						name: translateService.translateEquipment($scope.defaultEquipment.tisObjectType.tisObjectTypeGroupName),
						id: $scope.defaultEquipment.tisObjectId,
						groupId: $scope.defaultEquipment.tisObjectType.tisObjectTypeGroupNumber,
						typeName: $scope.defaultEquipment.tisObjectType.tisObjectTypeGroupName,
						sortBy: 1,
					});
				}
			});
	}

	function generateEquipmentDropDown(equipments, isChildren) {
		equipments.forEach(function(equipment) {
			let tisObjectId = equipment.tisObjectId;
			let tisObjectTypeGroupName = equipment.tisObjectType.tisObjectTypeGroupName;
			let tisObjectTypeGroupNumber = equipment.tisObjectType.tisObjectTypeGroupNumber;
			let tisObjectTypeClassification = equipment.tisObjectType.tisObjectTypeClassification;
			let isVavBox = tisObjectTypeGroupName === 'VAV-BOX';
			let isDistributionLoop = tisObjectTypeGroupName === 'Distribution Loop';

			if (!typesList.includes(tisObjectTypeGroupName) && tisObjectTypeClassification !== 'Component') {
				addExcDropDownItem({
					name: translateService.translateEquipment(tisObjectTypeGroupName),
					typeName: tisObjectTypeGroupName,
					id: tisObjectId,
					groupId: tisObjectTypeGroupNumber,
				});
				typesList.push(tisObjectTypeGroupName);
			} else if (isChildren && tisObjectTypeClassification !== 'Component') {
				addExcDropDownItem({
					name: translateService.translateEquipment(tisObjectTypeGroupName),
					typeName: tisObjectTypeGroupName,
					id: tisObjectId,
					groupId: tisObjectTypeGroupNumber,
				});
			}
			if (!isVavBox && !isDistributionLoop && equipment.children && equipment.children.length) {
				generateEquipmentDropDown(equipment.children, true);
			}
		});
	}

	function addExcDropDownItem(opts) {
		if (!typeExcNamesRequested.includes(opts.name) && uniqueTypes[opts.typeName] !== undefined) {
			const serviceAdvisoryPromise = serviceAdvisoryService.getTypesByTisObjectTypeGroupName(
				opts.typeName,
				(function(opts) {
					return function(data) {
						if (!typeExcNames.includes(opts.name) && data.length !== 0 && data.serviceAdvisoryTypeList.length > 0) {
							$scope.equipmentTypesExc = [
								...$scope.equipmentTypesExc,
								{
									name: opts.name,
									typeName: opts.typeName,
									id: opts.id,
									groupId: opts.groupId,
								},
							];
							typeExcNames.push(opts.name);
						}
					};
				})(opts)
			);
			serviceAdvisoryPromises.push(serviceAdvisoryPromise);
			typeExcNamesRequested.push(opts.name);
		}
	}

	function generateReportHandler() {
		/* eslint camelcase: 0*/
		const checkedServiceAdvisoryTypes = $scope.list.filter(({checked}) => checked);
		MetricsFactory.mark(MetricsFactory.METRIC_TYPES.EHR_LOAD, {
			range_type: $scope.rangeMode,
			range_duration: $scope.range.to - $scope.range.from,
			report_type: $scope.selectedEquipmentType.name,
			properties_count: $scope.list.reduce((acc, value) => (value.checked ? acc + 1 : acc), 0) || 0,
		});
		$scope.isGeneratingReport = true;
		$scope.viewModel.isLoadingStarted = true;

		const data = {
			type: 'report',
			subtype: REPORT_TYPES.EXCEPTION_HISTORY.report,
			from: dataFormattingService.getDateInUTC($scope.range.from),
			to: dataFormattingService.getDateInUTC($scope.range.to),
			timezone: locationDetailsService.getLocationTimezone(),
			token: authorization.getToken(),
			typeName: $scope.optionAll.id === $scope.selectedEquipmentType.id ? $scope.optionAll.id.toUpperCase() : $scope.selectedEquipmentType.typeName,
			selectedServiceAdvisoryTypesList: checkedServiceAdvisoryTypes.map(({id}) => id),
			selectedFacilities: $scope.selectedFacilities.map(({locationId, locationName}) => ({locationId, locationName})),
			standaloneEquipment: [...$scope.equipmentTypesExc],
			origin: location.origin,
			translations: checkedServiceAdvisoryTypes.reduce(
				(translations, sat) => {
					const excludedActionNames = [
						'CHILLERcompressorShortCyclingCentrifugal',
						'CHILLERcompressorShortCyclingScroll',
						'CHILLERcompressorShortCyclingHelical',
					];
					const exceptionName = excludedActionNames.includes(sat.actionName)
						? $filter('translate')(`AUTOMATED_TEST_ACTIONS.${sat.actionName}`)
						: $filter('translate')(`PROPERTIES.${sat.exceptionName}`);

					return {
						...translations,
						[sat.actionName]: exceptionName,
					};
				},
				{
					EXCEPTION_HISTORY: $filter('translate')('EXCEPTION_HISTORY'),
					EXCEPTION: $filter('translate')('EXCEPTION'),
					GRAND_TOTAL: $filter('translate')('GRAND_TOTAL'),
					...getPropertiesTranslation(),
				}
			),
		};

		reportGenerationStartTime = performance.now();

		exceptionHistoryService
			.createEHRFileName($scope.selectedFacilities, $scope.range.to, $scope.selectedEquipmentType.name)
			.then(filename => (data.filename = filename))
			.then(() => exportService.sendExportRequest(data))
			.then(({progressKey}) => exportProgressService.open(progressKey))
			.then(finalizeExport, failExport.bind(null, true), showExportProgress)
			.then(() => MetricsFactory.measure(MetricsFactory.METRIC_TYPES.EHR_LOAD));

		const fromDate = $scope.range.from.clone().format('MM-DD-YYYY');
		const toDate = $scope.range.to
			.clone()
			.add(-1, 'day')
			.format('MM-DD-YYYY');
		$scope.trackGenerateReportEvent({
			[REPORT_GENERATION_PROPERTIES.BUILDING_ID]:
				$scope.selectedFacilities.length > 1 ? $scope.selectedFacilities.map(({locationId}) => locationId) : $scope.selectedFacilities[0].locationId,
			[REPORT_GENERATION_PROPERTIES.BUILDING_SALES_OFFICE]:
				$scope.selectedFacilities.length > 1
					? $scope.selectedFacilities.map(({salesOffice}) => salesOffice.officeCode)
					: $scope.selectedFacilities[0].salesOffice.officeCode,
			[REPORT_GENERATION_PROPERTIES.BUILDING_OFFERINGS]:
				$scope.selectedFacilities.length > 1
					? // Get unique offerings from all facilities
					  $scope.selectedFacilities
							.map(({offeringSourceMaps}) => offeringSourceMaps.map(({shortName}) => shortName))
							.reduce((list, offerings) => [...list, ...offerings], [])
							.filter((offering, index, array) => array.indexOf(offering) === index)
					: $scope.selectedFacilities[0].offeringSourceMaps.map(({shortName}) => shortName),
			[REPORT_GENERATION_PROPERTIES.REPORT]: getReportNameByKey(REPORT_TYPES.EXCEPTION_HISTORY.name),
			[REPORT_GENERATION_PROPERTIES.TIME_PERIOD]: getTimePeriodNameByRangeMode($scope.rangeMode),
			[REPORT_GENERATION_PROPERTIES.FROM_DATE]: fromDate,
			[REPORT_GENERATION_PROPERTIES.TO_DATE]: toDate,
			[REPORT_GENERATION_PROPERTIES.REPORT_GENERATION_STATUS]: getReportGenerationStatus('started'),
			[REPORT_GENERATION_PROPERTIES.EQUIPMENT_TYPE]: getEquipmentTypeByKey($scope.selectedEquipmentType.typeName || $scope.selectedEquipmentType.name),
			[REPORT_GENERATION_PROPERTIES.EXCEPTIONS_INCLUDED]: checkedServiceAdvisoryTypes.map(({value}) => value).sort(),
		});
	}

	function finalizeExport() {
		$scope.viewModel.isLoadingStarted = false;
		$scope.isGeneratingReport = false;
		$scope.$emit('generateComplete');
		$scope.$emit('hideLoader');
		$scope.exportProgressMessages = {...defaultExportProgressMessages};

		let timeTaken = performance.now() - reportGenerationStartTime;
		const fromDate = $scope.range.from.clone().format('MM-DD-YYYY');
		const toDate = $scope.range.to
			.clone()
			.add(-1, 'day')
			.format('MM-DD-YYYY');
		$scope.trackGenerateReportEvent({
			[REPORT_GENERATION_PROPERTIES.BUILDING_ID]:
				$scope.selectedFacilities.length > 1 ? $scope.selectedFacilities.map(({locationId}) => locationId) : $scope.selectedFacilities[0].locationId,
			[REPORT_GENERATION_PROPERTIES.BUILDING_SALES_OFFICE]:
				$scope.selectedFacilities.length > 1
					? $scope.selectedFacilities.map(({salesOffice}) => salesOffice.officeCode)
					: $scope.selectedFacilities[0].salesOffice.officeCode,
			[REPORT_GENERATION_PROPERTIES.BUILDING_OFFERINGS]:
				$scope.selectedFacilities.length > 1
					? // Get unique offerings from all facilities
					  $scope.selectedFacilities
							.map(({offeringSourceMaps}) => offeringSourceMaps.map(({shortName}) => shortName))
							.reduce((list, offerings) => [...list, ...offerings], [])
							.filter((offering, index, array) => array.indexOf(offering) === index)
					: $scope.selectedFacilities[0].offeringSourceMaps.map(({shortName}) => shortName),
			[REPORT_GENERATION_PROPERTIES.REPORT]: getReportNameByKey(REPORT_TYPES.EXCEPTION_HISTORY.name),
			[REPORT_GENERATION_PROPERTIES.TIME_PERIOD]: getTimePeriodNameByRangeMode($scope.rangeMode),
			[REPORT_GENERATION_PROPERTIES.FROM_DATE]: fromDate,
			[REPORT_GENERATION_PROPERTIES.TO_DATE]: toDate,
			[REPORT_GENERATION_PROPERTIES.REPORT_GENERATION_STATUS]: getReportGenerationStatus('success'),
			[REPORT_GENERATION_PROPERTIES.TOTAL_TIME]: timeTaken,
			[REPORT_GENERATION_PROPERTIES.EQUIPMENT_TYPE]: getEquipmentTypeByKey($scope.selectedEquipmentType.typeName || $scope.selectedEquipmentType.name),
		});
	}

	function failExport(showPopup = true, message = false) {
		$scope.isGeneratingReport = false;
		$scope.viewModel.isLoadingStarted = false;
		exportProgressService.cancel();

		$scope.$emit('hideLoader');

		$scope.exportProgressMessages = {...defaultExportProgressMessages};

		if (showPopup) {
			modalHelperService.open({
				templateUrl: 'common/messages/rdr-error-message.html',
				controller: 'ErrorMessageCtrl',
				backdrop: 'static',
				resolve: {
					details: function() {
						return message || $filter('translate')('SEND_REQUEST_FAILED');
					},
				},
				scope: $scope,
			});
		}

		let timeTaken = performance.now() - reportGenerationStartTime;
		const fromDate = $scope.range.from.clone().format('MM-DD-YYYY');
		const toDate = $scope.range.to
			.clone()
			.add(-1, 'day')
			.format('MM-DD-YYYY');
		$scope.trackGenerateReportEvent({
			[REPORT_GENERATION_PROPERTIES.BUILDING_ID]:
				$scope.selectedFacilities.length > 1 ? $scope.selectedFacilities.map(({locationId}) => locationId) : $scope.selectedFacilities[0].locationId,
			[REPORT_GENERATION_PROPERTIES.BUILDING_SALES_OFFICE]:
				$scope.selectedFacilities.length > 1
					? $scope.selectedFacilities.map(({salesOffice}) => salesOffice.officeCode)
					: $scope.selectedFacilities[0].salesOffice.officeCode,
			[REPORT_GENERATION_PROPERTIES.BUILDING_OFFERINGS]:
				$scope.selectedFacilities.length > 1
					? // Get unique offerings from all facilities
					  $scope.selectedFacilities
							.map(({offeringSourceMaps}) => offeringSourceMaps.map(({shortName}) => shortName))
							.reduce((list, offerings) => [...list, ...offerings], [])
							.filter((offering, index, array) => array.indexOf(offering) === index)
					: $scope.selectedFacilities[0].offeringSourceMaps.map(({shortName}) => shortName),
			[REPORT_GENERATION_PROPERTIES.REPORT]: getReportNameByKey(REPORT_TYPES.EXCEPTION_HISTORY.name),
			[REPORT_GENERATION_PROPERTIES.TIME_PERIOD]: getTimePeriodNameByRangeMode($scope.rangeMode),
			[REPORT_GENERATION_PROPERTIES.FROM_DATE]: fromDate,
			[REPORT_GENERATION_PROPERTIES.TO_DATE]: toDate,
			[REPORT_GENERATION_PROPERTIES.REPORT_GENERATION_STATUS]: getReportGenerationStatus('failed'),
			[REPORT_GENERATION_PROPERTIES.TOTAL_TIME]: timeTaken,
			[REPORT_GENERATION_PROPERTIES.EQUIPMENT_TYPE]: getEquipmentTypeByKey($scope.selectedEquipmentType.typeName || $scope.selectedEquipmentType.name),
		});
	}

	function cancelLoading() {
		failExport(false);
	}

	function showExportProgress(input = {}) {
		if (input.message) {
			$scope.exportProgressMessages.currentMessage = input.message;
		}

		if (input.data) {
			let data = input.data;
			if (data.readyToDownload) {
				startDownloadProcess();
			} else {
				$scope.exportProgressMessages.requestsProcessed = data.requestsProcessed || 0;

				if (data.requestsTotal) {
					$scope.exportProgressMessages.requestsTotal = data.requestsTotal;
				}

				$scope.exportProgressMessages.rowsProcessed = data.rowsProcessed || 0;
				if (data.rowsTotal) {
					$scope.exportProgressMessages.rowsTotal = data.rowsTotal;
				}
			}
		}
	}

	function startDownloadProcess() {
		let progressKey = exportProgressService.getProgressKey();
		if (progressKey) {
			let exportData = {
				progressKey: progressKey,
				subtype: REPORT_TYPES.EXCEPTION_HISTORY.report,
				downloadXLSX: true,
			};
			$scope.changeExportData(JSON.stringify(exportData));
			$scope.submitExportForm();
		}
	}

	function updateSelectAllBtn() {
		const checkedCount = $filter('filter')($scope.list || [], {checked: true}).length;

		if (!checkedCount) {
			$scope.checkAll = false;
		} else if ($scope.list.length === checkedCount) {
			$scope.checkAll = true;
		} else {
			$scope.checkAll = null;
		}
	}

	function selectDeselectAll(status) {
		const selectionLength = $scope.filteredList.length;
		if (status && selectionLength && $scope.filteredList.every(prop => prop.checked === true)) {
			status = false;
		}
		for (let i = selectionLength; i--; ) {
			$scope.filteredList[i].checked = status;
		}
		updateCheckboxes();
	}

	function updateCheckboxes() {
		$timeout(updateSelectAllBtn, 0);
	}

	function updateList() {
		$scope.list = [];
		const validAutomatedTestFilter = automatedTest => !!automatedTest.exceptionPropertyName;
		const generateListItem = (item, type) => {
			return {
				value: $filter('translate')(`PROPERTIES.${item.exceptionPropertyName}`),
				id: item.serviceAdvisoryTypeId,
				type,
				checked: false,
				automatedTestLevel: item.automatedTestLevel,
				actionName: item.actionName,
				exceptionName: item.exceptionPropertyName,
			};
		};
		const addItemToList = item => {
			let type = null;
			if (item.tisObjectType) {
				const typeGroupName = item.tisObjectType.tisObjectTypeGroupName;
				type = translate(`EQUIPMENT_TYPE_NAME_TABLE.${typeGroupName}`);
			}
			$scope.list.push(generateListItem(item, type));
		};

		const handleUpdateList = serviceAdvisoryTypeList => serviceAdvisoryTypeList.filter(validAutomatedTestFilter).forEach(item => addItemToList(item));

		const handleFilterUpdateList = serviceAdvisoryTypeList => {
			const availableTypeNames = [...$scope.equipmentTypesExc.map(item => item.typeName), ...CHILLER_COMPONENTS];

			serviceAdvisoryTypeList.filter(validAutomatedTestFilter).forEach(item => {
				const typeGroupName = item.tisObjectType.tisObjectTypeGroupName;
				let type = null;

				if (item.tisObjectType) {
					type = translate(`EQUIPMENT_TYPE_NAME_TABLE.${typeGroupName}`);
				}

				if (availableTypeNames.includes(typeGroupName)) {
					$scope.list.push(generateListItem(item, type));
				}
			});
		};

		let typeNames = [];
		let exceptionsByServiceAdvisories = [];
		if ($scope.selectedEquipmentType.typeName) {
			typeNames.push($scope.selectedEquipmentType.typeName);
			if ($scope.selectedEquipmentType.typeName === 'Chiller') {
				typeNames = [...typeNames, ...CHILLER_COMPONENTS];
			}
			exceptionsByServiceAdvisories = Promise.all(
				typeNames.map(async typeName => await serviceAdvisoryService.getTypesByTisObjectTypeGroupName(typeName))
			);
			Promise.all([exceptionsByServiceAdvisories]).then(([data]) => {
				data.forEach(item => {
					handleUpdateList(item);
				});
				$scope.list = serviceAdvisoryService.filterOutIntermediateTests($scope.list);
			});
		} else {
			let promise = serviceAdvisoryService.getAllTypes().then(handleFilterUpdateList);
			promise.then(() => {
				$scope.list = serviceAdvisoryService.filterOutIntermediateTests($scope.list);
			});
		}
		updateSelectAllBtn();
	}

	function validateDefaultSelectedEquipmentType() {
		if (!$scope.isEquipmentTypesLoading) {
			if ($scope.defaultEquipment.tisObjectId === $scope.selectedEquipmentType.id && !typeExcNames.includes($scope.selectedEquipmentType.name)) {
				$scope.updateSelectedEquipmentType();
			}
		}
	}

	rangeModeListener($scope, $scope.setGlobalDateRange);
	rangeDateListener($scope, $scope.setGlobalDateRange);

	$scope.$on('updateSelectedEquipmentType', updateList);
	$scope.$on('generateReport', generateReportHandler);
	$scope.$watch('equipmentTypesExc', validateDefaultSelectedEquipmentType);
	$scope.$watch(
		function() {
			return '' + $scope.checkAll + $scope.isGeneratingReport;
		},
		function() {
			$scope.setDisableGenerateButton($scope.checkAll === false || $scope.isGeneratingReport);
		}
	);
}
