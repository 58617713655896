import {USER_EVENTS as HOME_PAGE_USER_EVENTS} from './home-page/user-events';
import {USER_EVENTS as FACILITY_SUMMARY_PAGE_USER_EVENTS} from './facility-summary-page/user-events';
import {USER_EVENTS as DIGITAL_INSPECTION_PAGE_USER_EVENTS} from './digital-inspection-page/user-events';
import {USER_EVENTS as EQUIPMENT_SUMMARY_PAGE_USER_EVENTS} from './equipment-summary-page/user-events';
import {USER_EVENTS as EQUIPMENT_PERFORMANCE_CHARTS_USER_EVENTS} from './equipment-performance-charts/user-events';
import {USER_EVENTS as REPORT_GENERATION_USER_EVENTS} from './report-generation/user-events';
import {USER_EVENTS as NAVIGATION_USER_EVENTS} from './navigation/user-events';
import {USER_EVENTS as PAGE_LOAD_EVENTS} from './page-load/user-events';
import {USER_EVENTS as ERROR_ALERT_EVENTS} from './error-alerts/user-events';

export const USER_EVENTS = {
	...HOME_PAGE_USER_EVENTS,
	...FACILITY_SUMMARY_PAGE_USER_EVENTS,
	...DIGITAL_INSPECTION_PAGE_USER_EVENTS,
	...EQUIPMENT_SUMMARY_PAGE_USER_EVENTS,
	...EQUIPMENT_PERFORMANCE_CHARTS_USER_EVENTS,
	...REPORT_GENERATION_USER_EVENTS,
	...NAVIGATION_USER_EVENTS,
	...PAGE_LOAD_EVENTS,
	...ERROR_ALERT_EVENTS,
};

export const PROPERTIES_TO_SEND_BY_EVENT = {};
