import {OPERATOR} from '../../common/constants';

const DEFAULT_OPERATOR = Object.keys(OPERATOR).indexOf(OPERATOR.IS_EQUAL_TO.name);

export default class CriteriaObject {
	static getTranslation(criteria, originalValues) {
		if (originalValues && originalValues instanceof Array) {
			const found = originalValues.find(item => item.name === criteria);
			criteria = found ? found.translate : '';
		}
		return criteria;
	}

	constructor({name, operator = null, criteria, findSettings, callbackOnEdit}) {
		const settings = findSettings(name);

		this.name = name;
		this.operator = operator === null ? DEFAULT_OPERATOR : operator;
		this.criteria = criteria || null;
		this.variants = settings[0] || false;
		this.dimensions = settings[1] || false;
		this.criteriaTranslate = CriteriaObject.getTranslation(criteria, this.variants);
		this.callbackOnEdit = callbackOnEdit;
		this.findSettings = findSettings;
	}

	editName(newName) {
		const settings = this.findSettings(newName);

		this.name = newName;
		this.operator = DEFAULT_OPERATOR;
		this.variants = settings[0] || false;
		this.dimensions = settings[1] || false;
		this.criteria = null;
		this.criteriaTranslate = '';

		this.callbackOnEdit && this.callbackOnEdit();
	}

	editCriteria(newCriteria) {
		if (this.variants) {
			this.criteria = this.variants[newCriteria].name;
			this.criteriaTranslate = this.variants[newCriteria].translate || newCriteria;
		} else {
			this.criteria = newCriteria;
		}

		this.callbackOnEdit && this.callbackOnEdit();
	}

	editDimension(newOperator) {
		this.operator = newOperator;
		this.callbackOnEdit && this.callbackOnEdit();
	}

	getJSON() {
		return {
			name: this.name,
			operator: this.operator,
			value: this.criteria,
		};
	}
}
