angular.module('TISCC').controller('OverridesReportCtrl', OverridesReportCtrl);

function OverridesReportCtrl($scope, $filter, $controller, $timeout, $q, overrideService, helpers) {
	angular.extend(this, $controller('AbstractReportCtrl', {$scope: $scope}));

	let timeZone = $scope.data.locationData.locationTimeZone;
	let startDate = $scope.range.from;
	let locationId = $scope.locationId;

	let deviceMapping = {};
	$scope.list = [];
	$scope.checkAll = false;
	$scope.updateCheckboxes = $timeout.bind(null, updateSelectAllBtn);
	$scope.selectDeselectAll = selectDeselectAll;
	$scope.isGeneratingReport = false;
	// TODO make sure user requirements are defined correctly,
	// otherwise make this promise be reset upon every change in top bar controls
	$scope.overrideTypesLoadingPromise = $q.defer();

	overrideService
		.getDeviceType(locationId)
		.then(storeDeviceInformation)
		.then(overrideService.getOverridePriorityNames)
		.then(cleanFetchedLevelList)
		.then(updateList)
		.then(() => {
			$scope.overrideTypesLoadingPromise.resolve();
		})
		.catch(() => {
			// TODO add error message display, when chain gets broken
			$scope.overrideTypesLoadingPromise.resolve();
		});

	function storeDeviceInformation(result) {
		let deviceData = result.data.locationConnectivityList;
		let deviceType;
		if (deviceData && deviceData.length > 0) {
			deviceType = helpers.getPropertyByPath(result, 'data.locationConnectivityList[0].devices[0].device.deviceType');
			storeDeviceNamesAndIds(deviceData[0].devices);
			return $q.resolve(deviceType);
		} else {
			return $q.reject();
		}
	}

	function storeDeviceNamesAndIds(devices) {
		devices.forEach(device => {
			device = device.device;
			if (device.duiId) {
				deviceMapping[device.duiId] = device.deviceName;
			}
		});
	}

	function updateSelectAllBtn() {
		let checkedCount = $filter('filter')($scope.list || [], {checked: true}).length;

		if (!checkedCount) {
			$scope.checkAll = false;
		} else if ($scope.list.length === checkedCount) {
			$scope.checkAll = true;
		} else {
			$scope.checkAll = null;
		}
	}

	function cleanFetchedLevelList(result) {
		return $q.resolve(helpers.getPropertyByPath(result, 'data.presentationCodeList[0].presentationCodeValueList') || []);
	}

	function updateList(overrideLevelList) {
		$scope.list = overrideLevelList.map(function(item) {
			return {
				id: item.presentationCodeValueName,
				name: item.presentationCodeValueDescription,
				checked: item.checked || false,
			};
		});
		updateSelectAllBtn();
	}

	function selectDeselectAll(status) {
		for (let i = $scope.list.length; i--; ) {
			$scope.list[i].checked = status;
		}
	}

	function buildExportValues(response) {
		const createdDate = moment().format('DD - MMM - YYYY / hh:mm A Z');
		const selectedDate = $scope.range.from.format('DD - MM - YYYY');
		let overrides = [];
		let convertedData = {
			createdByUser: 'ccbxce',
			type: 'report',
			subtype: 'overrides',
			format: 'pdf',
			filename: $scope.data.locationData.locationName + '_' + moment().format('DD-MM-YYYY') + '_Override.pdf',
			data: {
				accountName: $scope.data.locationData.accountName,
				locationName: $scope.data.locationData.locationName,
				facilityAddress: $scope.data.locationData.address,
				dateSelected: selectedDate,
				dateGenerated: createdDate,
				sections: [],
			},
		};
		let sectionRowTemplate = {
			result: {},
			mapping: {
				objectType: 'automationObjectType',
				objectName: 'automationObjectName',
				presentValue: 'priorityOverridePresentValue',
				createdTime: rowData => {
					return moment(rowData.createdTimestamp)
						.tz(timeZone)
						.format('h:mm A');
				},
				createdDate: rowData => {
					return moment(rowData.createdTimestamp)
						.tz(timeZone)
						.format('DD-MMM-YYYY');
				},
				overrideLevel: function(rowData) {
					let overrideLevel = $scope.list.find(current => {
						return String(rowData.priorityOverrideIndex) === current.id;
					});
					return (overrideLevel && overrideLevel.name) || '';
				},
				priorityOwner: 'priorityOverrideOwnerIdentifier',
				id: 'automationObjectOverrideSummaryId',
			},
			applyValues: function(values) {
				let that = this;
				this.result = {};
				Object.keys(that.mapping).forEach(mappingKey => {
					if (that.mapping[mappingKey] instanceof Function) {
						that.result[mappingKey] = that.mapping[mappingKey](values);
					} else {
						that.result[mappingKey] = values[that.mapping[mappingKey]] || '';
					}
				});
			},
		};

		response.data.deviceOverrideList.forEach(responseSection => {
			if (responseSection && responseSection.automationObjectOverrideSummaryList) {
				let sectionIndex =
					convertedData.data.sections.push({
						title: deviceMapping[responseSection.duiId],
						table: {
							rows: [],
						},
					}) - 1;

				responseSection.automationObjectOverrideSummaryList.forEach(override => {
					let resultSection = convertedData.data.sections[sectionIndex];
					sectionRowTemplate.applyValues(override);
					resultSection.table.rows.push(sectionRowTemplate.result);
				});
			}
		});
		$scope.changeExportData(JSON.stringify(convertedData));
	}

	function generateReportHandler() {
		$scope.isGeneratingReport = true;
		let duiIds = Object.keys(deviceMapping);
		let overrideLevelIds = $filter('filter')($scope.list || [], {checked: true}).map(item => item.id);

		overrideService
			.getOverrideList(duiIds, startDate, timeZone, overrideLevelIds)
			.then(buildExportValues)
			.then($scope.submitExportForm)
			.finally(function() {
				$scope.$emit('hideLoader');
				$scope.isGeneratingReport = false;
			});
	}
	$scope.$on('generateReport', generateReportHandler);
	$scope.$watch(
		function() {
			return '' + $scope.checkAll;
		},
		function() {
			$scope.setDisableGenerateButton($scope.checkAll === false);
		}
	);
}
