import {USER_EVENTS} from '../../common/usage-tracking/categories';

const {HOME_PAGE: {events: HOME_PAGE_EVENTS, properties: HOME_PAGE_PROPERTIES, categoryName: HOME_PAGE_CATEGORY_NAME}} = USER_EVENTS;

angular.module('TISCC').controller('MyViewsListDialogCtrl', MyViewsListDialogCtrl);

function MyViewsListDialogCtrl($scope, $modalInstance, modalHelperService, viewDataEvent, list, currentViewName, userDataService, usageTrackingService) {
	const trackEvent = usageTrackingService.trackEventByCategory(HOME_PAGE_CATEGORY_NAME);
	$scope.list = list;
	$scope.currentViewName = currentViewName;
	$scope.userService = userDataService;
	$scope.viewDataEvent = viewDataEvent;

	$scope.cancel = function() {
		$modalInstance.dismiss('cancel');
	};

	$scope.create = function() {
		openWindow(false);
	};

	$scope.edit = function(index) {
		openWindow(index);
	};
	$scope.onEditViewClick = function(index) {
		$scope.edit(index);
		trackEvent(HOME_PAGE_EVENTS.EDIT_VIEW);
	};

	$scope.delete = function(index) {
		const data = $scope.list === undefined ? false : $scope.list[index];

		modalHelperService.open({
			templateUrl: 'components/my-views/my-views-delete-confirmation.html',
			controller: 'MyViewsDeleteDialogCtrl',
			backdrop: 'static',
			resolve: {
				viewDataEvent: () => {
					return $scope.viewDataEvent;
				},
				data: function() {
					return data;
				},
				index: function() {
					return index;
				},
				list: function() {
					return $scope.list;
				},
			},
		});
	};
	$scope.onDeleteViewClick = function(index) {
		$scope.delete(index);
		trackEvent(HOME_PAGE_EVENTS.DELETE_VIEW);
	};
	const openWindow = function(index) {
		let data = $scope.list === undefined ? false : $scope.list[index];
		$modalInstance.dismiss('cancel');
		modalHelperService.open({
			templateUrl: 'components/my-views/my-views-edit-dialog.html',
			controller: 'MyViewsEditDialogCtrl',
			backdrop: 'static',
			windowClass: 'my-view-edit-window',
			resolve: {
				viewDataEvent: () => {
					return $scope.viewDataEvent;
				},
				data: function() {
					return data;
				},
				index: function() {
					return index;
				},
				list: function() {
					return $scope.list;
				},
				currentViewName: function() {
					return $scope.currentViewName;
				},
			},
		});
	};
}
