(function(d3, AbstractChartRenderer) {
	const SUM_BAR_WIDTH = 100;
	const MAX_LABEL_HEIGHT = 125;
	const MAX_BAR_WIDTH = 50;
	const SUM_BAR_RIGHT_MARGIN = 5;
	const TOOLTIP_SHIFT = 3;
	const DEFAULT_LOWER_RANGE_END = 60;
	const DEFAULT_UPPER_RANGE_END = 95;
	const PERIOD_TIME = 15 * 60 * 1000;
	const VAV_AIR_SYSTEM_TRANSLATION_KEY = 'VAV_AIR_SYSTEM';
	const CHILLED_WATER_VALVE_DISTRIBUTION_TRANSLATION_KEY = 'CHILLED_WATER_VALVE_DISTRIBUTION';
	const DATE_FORMAT = 'M/D/YY h:mm A';

	class BarChartRenderer extends AbstractChartRenderer {
		constructor(svg, externalMethods, clipPathId, x) {
			super(externalMethods.tooltip, externalMethods.translate);
			let barX = d3.scale.ordinal();

			this.startPoint = null;
			this.endPoint = null;
			this.barHeight = 0;

			this.x = x;
			this.gridNode = svg.append('g').attr('class', 'grid');
			this.CHART_LABEL_OFFSET = {left: -2, right: 2, top: -2, bottom: 11};
			this.dataNode = svg.append('g');

			this.barX = barX;
			this.barXAxis = d3.svg
				.axis()
				.scale(barX)
				.orient('bottom');
			this.barY = d3.scale.linear().domain([0, 100]);

			this.barLabelHeight = 0;
			this.barYAxisNode = svg.append('g').attr('class', 'axis');

			this.leftLabel = svg
				.append('g')
				.append('text')
				.attr('class', 'axis-label');
		}

		onBrushRange(range, chartOptions, chartData) {
			super.onBrushRange(range, chartOptions, chartData);
			this.startPoint = range.brush.from.format(DATE_FORMAT);
			this.endPoint = range.brush.to.format(DATE_FORMAT);
		}

		onSvgReceivedSize(updateData) {
			this.width = updateData.width;
			this.height = updateData.height;
			this.barX.rangeBands([0, updateData.width - SUM_BAR_WIDTH - SUM_BAR_RIGHT_MARGIN], 0.3, 0.3);
			this.barHeight = updateData.chartHeight;
		}

		onSvgSizeUpdate(updateData) {
			this.onSvgReceivedSize(updateData);
			this.barY.range([this.barHeight - this.barLabelHeight, 0]);
			this.updateYAxes();
		}

		onRangeXUpdated(range) {
			this.startPoint = range.from.format(DATE_FORMAT);
			this.endPoint = range.to.format(DATE_FORMAT);
		}

		drawData(chartOptions, chartData) {
			const SUM_TEXT_TRANSLATION_KEY = chartOptions.isLoadValvePositionChart
				? CHILLED_WATER_VALVE_DISTRIBUTION_TRANSLATION_KEY
				: VAV_AIR_SYSTEM_TRANSLATION_KEY;

			this.tooltip.hideTooltip();

			if (!chartOptions.lines) {
				return;
			}

			let brushRange = {
				from: this.x.domain()[0].getTime(),
				to: this.x.domain()[1].getTime(),
			};

			brushRange.from += brushRange.from % PERIOD_TIME ? PERIOD_TIME - brushRange.from % PERIOD_TIME : 0;
			brushRange.to -= brushRange.to % PERIOD_TIME;

			let sumBar = [
				{
					y: 0,
					count: 0,
					className: 'unoccupied',
				},
				{
					y: 0,
					count: 0,
					className: 'lower',
				},
				{
					y: 0,
					count: 0,
					className: 'upper',
				},
				{
					y: 0,
					count: 0,
					className: 'above',
				},
			];

			function calculateBarsData(data, name) {
				const LOWER_RANGE_END = chartOptions.controlRanges.low || DEFAULT_LOWER_RANGE_END;
				const UPPER_RANGE_END = chartOptions.controlRanges.high || DEFAULT_UPPER_RANGE_END;
				let res = {
					name: name,
					above: 0,
					upper: 0,
					lower: 0,
					unoccupied: 0,
					all: 0,
					percentages: [],
				};
				data.forEach(item => {
					if (brushRange.from > item.x.getTime() || brushRange.to < item.x.getTime() || item.y === null || item.y === undefined) {
						return;
					}
					if (item.unoccupied) {
						chartOptions.unoccupied && res.unoccupied++;
					} else if (item.y < LOWER_RANGE_END) {
						res.lower++;
					} else if (item.y < UPPER_RANGE_END) {
						res.upper++;
					} else {
						res.above++;
					}
				});
				res.all = res.above + res.upper + res.lower + res.unoccupied;

				res.percentages.push({
					y0: 0,
					y1: getDataByPercent(res.unoccupied, res.all),
					className: 'unoccupied',
				});
				res.percentages.push({
					y0: res.percentages[0].y1,
					y1: res.percentages[0].y1 + getDataByPercent(res.lower, res.all),
					className: 'lower',
				});
				res.percentages.push({
					y0: res.percentages[1].y1,
					y1: res.percentages[1].y1 + getDataByPercent(res.upper, res.all),
					className: 'upper',
				});
				res.percentages.push({
					y0: res.percentages[2].y1,
					y1: res.percentages[2].y1 + getDataByPercent(res.above, res.all),
					className: 'above',
				});

				sumBar[0].count += res.unoccupied;
				sumBar[1].count += res.lower;
				sumBar[2].count += res.upper;
				sumBar[3].count += res.above;
				return res;
			}

			function getDataByPercent(count, barTimesCount) {
				return Math.round(count / barTimesCount * 10000) / 100 || 0;
			}

			let data = [];
			for (let i = 0; i < chartOptions.lines.length; i++) {
				let line = chartOptions.lines[i];

				if (!line.visible || !chartData[i]) {
					continue;
				}

				data.push(calculateBarsData(chartData[i], line.name));
			}

			this.updateAxes(chartOptions, data);

			let {barWidth, shift} = getBarWidthAndShift(this.barX.rangeBand());

			let bars = this.dataNode
				.selectAll('.bars')
				.data(data)
				.enter()
				.append('g')
				.attr('class', 'g bars')
				.attr('transform', d => 'translate(' + (this.barX(d.name) + shift) + ',0)')
				.on('mouseover', d => {
					const {barWidth, shift} = getBarWidthAndShift(this.barX.rangeBand());

					let data = {
						unoccupied: chartOptions.unoccupied && chartOptions.isStackedBarWithOccupancy ? getDataByPercent(d.unoccupied, d.all) : null,
						above: getDataByPercent(d.above, d.all),
						upper: getDataByPercent(d.upper, d.all),
						lower: getDataByPercent(d.lower, d.all),
						startPoint: this.startPoint,
						endPoint: this.endPoint,
					};
					let xPos = this.barX(d.name) + barWidth / 2 + shift;
					let yPos = this.barY((data.above + data.upper + data.lower + data.unoccupied) / 2) - TOOLTIP_SHIFT;
					let parentElem = d3.event.target.parentNode;

					this.tooltip.showTooltip({
						xPos: xPos,
						yPos: yPos,
						name: d.name,
						barData: data,
						parentElem: parentElem,
					});
				})
				.on('mouseout', () => {
					let hoverTo = d3.event.relatedTarget;
					this.tooltip.hideTooltip(hoverTo);
				});

			bars
				.selectAll('rect')
				.data(d => d.percentages)
				.enter()
				.append('rect')
				.attr('width', barWidth)
				.attr('y', d => this.barY(d.y1 || 0))
				.attr('height', d => this.barY(d.y0 || 0) - this.barY(d.y1 || 0))
				.attr('class', d => 'bar ' + d.className);

			let sumBarTotalCount = sumBar[0].count + sumBar[1].count + sumBar[2].count + sumBar[3].count;

			sumBar[0].y0 = 0;
			sumBar[0].y1 = getDataByPercent(sumBar[0].count, sumBarTotalCount);

			sumBar[1].y0 = sumBar[0].y1;
			sumBar[1].y1 = sumBar[0].y1 + getDataByPercent(sumBar[1].count, sumBarTotalCount);

			sumBar[2].y0 = sumBar[1].y1;
			sumBar[2].y1 = sumBar[1].y1 + getDataByPercent(sumBar[2].count, sumBarTotalCount);

			sumBar[3].y0 = sumBar[2].y1;
			sumBar[3].y1 = sumBar[2].y1 + getDataByPercent(sumBar[3].count, sumBarTotalCount);

			let sumNode = this.dataNode
				.append('g')
				.attr('class', 'g sum')
				.attr('transform', d => 'translate(' + (this.width - SUM_BAR_WIDTH - SUM_BAR_RIGHT_MARGIN) + ',0)')
				.on('mouseover', d => {
					let data = {
						above: Math.round((sumBar[3].y1 - sumBar[2].y1) * 100) / 100,
						upper: Math.round((sumBar[2].y1 - sumBar[1].y1) * 100) / 100,
						lower: Math.round((sumBar[1].y1 - sumBar[0].y1) * 100) / 100,
						unoccupied: chartOptions.unoccupied && chartOptions.isStackedBarWithOccupancy ? Math.round(sumBar[0].y1 * 100) / 100 : null,
						startPoint: this.startPoint,
						endPoint: this.endPoint,
					};
					let xPos = this.width - SUM_BAR_WIDTH / 2 - SUM_BAR_RIGHT_MARGIN;
					let yPos = this.barY((data.above + data.upper + data.lower + data.unoccupied) / 2) - TOOLTIP_SHIFT;
					let parentElem = d3.event.target.parentNode;

					this.tooltip.showTooltip({
						xPos: xPos,
						yPos: yPos,
						name: this.translate(SUM_TEXT_TRANSLATION_KEY),
						barData: data,
						parentElem: parentElem,
					});
				})
				.on('mouseout', () => {
					let hoverTo = d3.event.relatedTarget;
					this.tooltip.hideTooltip(hoverTo);
				});

			sumNode
				.selectAll('rect')
				.data(sumBar)
				.enter()
				.append('rect')
				.attr('width', SUM_BAR_WIDTH - SUM_BAR_RIGHT_MARGIN)
				.attr('y', d => this.barY(d.y1 || 0))
				.attr('height', d => this.barY(d.y0 || 0) - this.barY(d.y1 || 0))
				.attr('class', d => 'bar ' + d.className);

			const sumLabelOffsetX = this.width - SUM_BAR_WIDTH / 2 - SUM_BAR_RIGHT_MARGIN;
			const sumLabelOffsetY = this.barHeight - this.barLabelHeight + 5;
			this.dataNode
				.append('g')
				.attr('class', 'sum-label')
				.attr('transform', `translate(${sumLabelOffsetX},${sumLabelOffsetY})`)
				.append('text')
				.attr('class', 'axis-label')
				.text(this.translate(SUM_TEXT_TRANSLATION_KEY))
				.attr('y', '9')
				.call(wrapText, SUM_BAR_WIDTH - SUM_BAR_RIGHT_MARGIN, 1.0);

			data.push({
				percentages: sumBar,
				name: this.translate(SUM_TEXT_TRANSLATION_KEY),
			});
			chartOptions.exportData = data;
		}

		updateAxes(chartOptions, data) {
			let domain = [];

			for (let i = 0; i < chartOptions.lines.length; i++) {
				if (chartOptions.lines[i].visible) {
					domain.push(chartOptions.lines[i].name);
				}
			}

			if (chartOptions.sortBy === 'percent') {
				domain = domain.sort((a1, b1) => {
					let a = data.find(item => item.name === a1);
					let b = data.find(item => item.name === b1);

					return rangeComparator(a, b);
				});
				data = data.sort(rangeComparator);
				this.barX.domain(domain);
			} else {
				domain = domain.sort((a1, b1) => {
					let a = {name: a1};
					let b = {name: b1};
					return naturalCompare(a, b);
				});
				data = data.sort(naturalCompare);
				this.barX.domain(domain);
			}

			let xAxisNode = this.dataNode.append('g').attr('class', 'x axis');

			xAxisNode
				.call(this.barXAxis)
				.selectAll('text')
				.attr('dy', '0em')
				.call(wrapText, MAX_LABEL_HEIGHT, 1.0, 'middle')
				.style('text-anchor', 'end')
				.attr('transform', 'rotate(-90)');

			let xAxisNodeSize = xAxisNode.node().getBBox();
			this.barLabelHeight = xAxisNodeSize.height;
			xAxisNode.attr('transform', 'translate(0,' + (this.barHeight - this.barLabelHeight + 5) + ')');

			this.barY.range([this.barHeight - this.barLabelHeight, 0]);

			this.updateYAxes();
		}

		updateYAxesScale() {
			this.barY.range([this.barHeight - this.barLabelHeight, 0]);
		}

		updateAxisLabel() {
			this.leftLabel.text('% time in each range');
		}

		updateYAxes() {
			let yAxis = d3.svg
				.axis()
				.scale(this.barY)
				.orient('left')
				.ticks(5);
			this.barYAxisNode
				.call(yAxis.tickSize(0, 0, 0))
				.selectAll('text')
				.attr('dx', this.CHART_LABEL_OFFSET.left);
			this.gridNode.call(yAxis.tickSize(-this.width, 0, 0).tickFormat(''));
			this.leftLabel.attr('transform', 'translate(-30,' + (this.barHeight - this.barLabelHeight) / 2 + ') rotate(-90)');
		}

		updateChartPos() {
			const {barWidth, shift} = getBarWidthAndShift(this.barX.rangeBand());

			this.dataNode.selectAll('.bars').attr('transform', d => 'translate(' + (this.barX(d.name) + shift) + ',0)');

			this.dataNode.selectAll('.sum').attr('transform', d => `translate(${this.width - SUM_BAR_WIDTH - SUM_BAR_RIGHT_MARGIN},0)`);

			this.dataNode
				.selectAll('rect')
				.attr('width', barWidth)
				.attr('y', d => this.barY(d.y1))
				.attr('height', d => this.barY(d.y0) - this.barY(d.y1));

			this.dataNode.selectAll('.sum rect').attr('width', SUM_BAR_WIDTH - SUM_BAR_RIGHT_MARGIN);

			let xAxisNode = this.dataNode.selectAll('.x.axis');
			xAxisNode.selectAll('*').remove();

			xAxisNode
				.attr('transform', 'translate(0,' + (this.barHeight - this.barLabelHeight + 5) + ')')
				.call(this.barXAxis)
				.selectAll('text')
				.attr('dy', '0em')
				.call(wrapText, MAX_LABEL_HEIGHT, 1.0, 'middle')
				.style('text-anchor', 'end')
				.attr('transform', 'rotate(-90)');

			const sumLabelOffsetX = this.width - SUM_BAR_WIDTH / 2 - SUM_BAR_RIGHT_MARGIN;
			const sumLabelOffsetY = this.barHeight - this.barLabelHeight + 5;
			this.dataNode.selectAll('.sum-label').attr('transform', `translate(${sumLabelOffsetX},${sumLabelOffsetY})`);
		}
	}

	/**
	 *
	 * @param text
	 * @param width
	 * @param lineHeight // ems
	 * @param vAlign
	 */
	function wrapText(text, width, lineHeight = 1.1, vAlign) {
		text.each(function() {
			let text = d3.select(this);
			let words = text
				.text()
				.split(/\s+/)
				.reverse();
			let word = words.pop();
			let line = [];
			let lineNumber = 0;
			let y = text.attr('y');
			let dy = parseFloat(text.attr('dy')) || 0;
			let tspan = text
				.text(null)
				.append('tspan')
				.attr('x', 0)
				.attr('y', y)
				.attr('dy', dy + 'em');
			let tspanArr = [tspan];

			while (word) {
				line.push(word);
				tspan.text(line.join(' '));

				if (tspan.node().getComputedTextLength() > width) {
					line.pop();
					tspan.text(line.join(' '));
					line = [word];
					tspan = text
						.append('tspan')
						.attr('x', 0)
						.attr('dy', lineHeight + 'em')
						.text(word);
					tspanArr.push(tspan);
					lineNumber++;
				}

				word = words.pop();
			}

			if (vAlign === 'middle') {
				dy -= tspanArr.length * lineHeight / 2;
				tspanArr[0].attr('dy', dy + 'em');
			}
		});
	}

	function getBarWidthAndShift(rangeBand) {
		let barWidth = rangeBand;
		let shift = 0;

		if (barWidth > MAX_BAR_WIDTH) {
			shift = (barWidth - MAX_BAR_WIDTH) / 2;
			barWidth = MAX_BAR_WIDTH;
		}

		return {shift, barWidth};
	}

	function naturalCompare(a, b) {
		let ax = [];
		let bx = [];

		a.name.replace(/(\d+)|(\D+)/g, (_, $1, $2) => ax.push([$1 || Infinity, $2 || '']));
		b.name.replace(/(\d+)|(\D+)/g, (_, $1, $2) => bx.push([$1 || Infinity, $2 || '']));

		while (ax.length && bx.length) {
			let an = ax.shift();
			let bn = bx.shift();
			let nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
			if (nn) {
				return nn;
			}
		}

		return ax.length - bx.length;
	}

	function rangeComparator(a, b) {
		const isAboveEqual = a.above === b.above;
		const isUpperEqual = a.upper === b.upper;
		const isLowerEqual = a.lower === b.lower;
		const isUnoccupiedEqual = a.unoccupied === b.unoccupied;
		const isAboveBigger = a.above > b.above;
		const isUpperBigger = a.upper > b.upper;
		const isLowerBigger = a.lower > b.lower;
		const isUnoccupiedBigger = a.unoccupied > b.unoccupied;

		if (isAboveEqual && isUpperEqual && isLowerEqual && isUnoccupiedEqual) {
			return naturalCompare(a, b);
		} else if (
			isAboveBigger ||
			(isAboveEqual && isUpperBigger) ||
			(isAboveEqual && isUpperEqual && isLowerBigger) ||
			(isAboveEqual && isUpperEqual && isLowerEqual && isUnoccupiedBigger)
		) {
			return -1;
		} else {
			return 1;
		}
	}

	window.BarChartRenderer = BarChartRenderer;
})(d3, window.AbstractChartRenderer);
