export default {
	amplitudeKey: '', // being obtained from environment config
	featureToggles: {
		serviceAdvisories: true,
		facilityPerformanceReport: true,
		paretoCharts: true,
		findings: true,
		userNotifications: true,
		serviceAdvisoryLinks: false,
		serviceAdvisoryStatusUpdate: true,
		digitalInspection: true, // Digital Inspection Page
		digitalInspectionReport: true, // Digital Inspection Report PDF
		accessToBetaScreen: true, // Enable Access to features for Beta Users
	},
	// Refactor Base URL
	externalLinks: {
		buildingSetupLink: ({locationId}) => {
			return `https://connect.tis-dev.trane.com/building-setup/${locationId}`;
		},
		notesLink: ({locationId, tisObjectName}) => {
			let url = `https://connect.tis-dev.trane.com/notes?location=${locationId}`;
			tisObjectName && (url += `&noteType=Equipment&q=${tisObjectName}`);
			return url;
		},
		addNoteLink: ({locationId, tisObjectId}) => {
			let url = `https://connect.tis-dev.trane.com/notes/add?location=${locationId}`;
			tisObjectId && (url += `&equipment=${tisObjectId}`);
			return url;
		},
		remoteAccessLink: ({accountId, locationId}) => {
			return `https://connect.tis-dev.trane.com/connect-device?organization=${accountId}&location=${locationId}`;
		},
		equipmentSetupLink: ({locationId = '', organizationId = '', objectId = ''}) => {
			let url = '';

			if (objectId) url = `/${objectId}`;
			if (locationId) url += `?location=${locationId}`;
			if (organizationId) url += `${url ? '&' : '?'}organization=${organizationId}`;

			return `https://connect.tis-dev.trane.com/equipment-setup${url}`;
		},
		automatedTestSettingsLink: ({accountId, locationId, tisObjectId}) => {
			return `https://connect.tis-dev.trane.com/automated-test-settings?organization=${accountId}&location=${locationId}${
				tisObjectId ? `&object=${tisObjectId}` : ''
			}`;
		},
		automatedTestSuppressionsLink: ({accountId, locationId}) => {
			return `https://connect.tis-dev.trane.com/automated-test-suppressions?organization=${accountId}&location=${locationId}`;
		},
		addFindingLink: ({organizationId, locationId, tisObjectId}) => {
			let url = `https://connect.tis-dev.trane.com/findings/add?organization=${organizationId}&location=${locationId}`;
			tisObjectId && (url += `&equipment=${tisObjectId}`);
			return url;
		},
	},
	rawDataReport: {
		maxDataCellsQuantity: 2500000,
	},
	zendeskConfig: {
		zendeskKey: '', // being obtained from environment config
		zendeskTicketFormID: '', // being obtained from environment config
	},
	tisOfferings: {
		tisOfferingsToHide: ['TC', 'TA', 'SA', 'Eview'],
	},
	hideEquipmentFromEquipmentDropdown: {
		// key (equipment group id) : value (equipment group name)
		41: 'CPLProgram',
	},
	reportAPIURL: 'https://export.tis-dev.net',
};
