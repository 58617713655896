/* eslint quotes: [0, 'single'] */

let Module = angular.module('datePicker');

Module.directive('dateRange', function($timeout) {
	return {
		templateUrl: 'common/calendar/angular-daterange.html',
		scope: {
			start: '=',
			end: '=',
			before: '=',
			after: '=',
			range: '=',
			onSubmit: '&',
		},
		link: function(scope, element, attrs) {
			const dateNow = {
				date: moment().date(),
				month: moment().month(),
				year: moment().year(),
				now: moment(),
			};

			scope.beforeStartDt = updateRangeOffset(moment(scope.before), -1);
			updateAfterStartDt();
			scope.minView = attrs.minView || 'date';
			scope.maxView = attrs.maxView || 'date';
			scope.view = attrs.view || 'date';
			scope.step = attrs.step || 5;
			scope.customDateRange = attrs.customDateRange;
			scope.startLabel = attrs.startLabel || 'START_DATE';
			scope.endLabel = attrs.endLabel || 'END_DATE';

			function updateRangeOffset(dt, direction) {
				switch (scope.range) {
					case '1 week':
						dt.add(6 * direction, 'days');
						break;
					case '2 week':
						dt.add(13 * direction, 'days');
						break;
					case '4 week':
						dt.add(27 * direction, 'days');
						break;
					case '1 month': {
						const isCurrentMonth = moment(dt).month() === dateNow.month && moment(dt).year() === dateNow.year;

						if (direction === -1 && isCurrentMonth) {
							dt.startOf('month');
						} else if (isCurrentMonth) {
							dt.date(dateNow.date);
							dt.month(dateNow.month);
							dt.year(dateNow.year);
						} else if (direction === -1) {
							dt.startOf('month');
						} else {
							dt.endOf('month');
						}
						break;
					}
					case '3 month':
						dt.add(3 * direction, 'months');
						break;
					case '1 year':
						dt.add(11 * direction, 'months');
						break;
					case '2D-1Y':
						dt.add(1 * direction, 'days');
						break;
				}
				return dt;
			}

			function isCustomRange() {
				return !scope.range || scope.range === 'custom';
			}

			function updateEndDate() {
				if (scope.range === '1 year') {
					scope.end = moment(scope.rangeEnd);
					scope.end.add(1, 'months');

					const now = moment(new Date()).tz(scope.end.tz());

					if (scope.end.isAfter(now, 'month')) {
						scope.end = now;
						scope.start = scope.end.clone().add(-1, 'year');
					}
				} else if (scope.range === '3 month') {
					scope.end = moment(scope.rangeEnd);
					scope.end.add(23, 'hours').add(45, 'minutes');
				} else if (scope.range === '2D-1Y') {
					scope.end = moment(scope.rangeEnd);
				} else {
					scope.end = moment(scope.rangeEnd).add(1, 'days');
				}
			}

			function updateRangeEndDate() {
				if (scope.range === '1 year') {
					scope.rangeEnd = moment(scope.end);
					scope.rangeEnd.date(1);
				} else if (scope.range === '3 month') {
					scope.rangeEnd = moment(scope.end);
					scope.rangeEnd.add(23, 'hours').add(45, 'minutes');
				} else {
					scope.rangeEnd = moment(scope.end).add(-1, 'days');
				}
			}

			function updateAfterStartDt() {
				if (scope.after) {
					const afterStartDt = updateRangeOffset(moment(scope.after), 1);
					if (afterStartDt.isSameOrBefore(moment())) {
						scope.afterStartDt = afterStartDt;
					} else {
						scope.afterStartDt = scope.after;
					}
				}
			}

			function apply() {
				if (scope.rangeEnd) {
					updateEndDate();
				}
				if (!(scope.start && scope.start.isSame(scope.rangeStart))) {
					scope.start = moment(scope.rangeStart);
				}
			}

			function updateCalendar(rangeType, value) {
				let noOfDays = moment.duration(scope.rangeEnd.diff(scope.rangeStart)).asDays();
				let noOfYears = moment.duration(scope.rangeEnd.diff(scope.rangeStart)).asYears();
				let newDate = moment(value);
				if (noOfDays < 1) {
					if (rangeType === 'rangeStart') {
						if (newDate.isSameOrAfter(scope.$parent.$parent.maxDt)) {
							newDate.add(-1, 'days');
							scope.rangeStart = newDate;
						} else {
							newDate.add(1, 'days');
							scope.rangeEnd = newDate;
						}
					} else {
						newDate.add(-1, 'days');
						scope.rangeStart = newDate;
					}
				} else if (noOfYears > 1) {
					if (rangeType === 'rangeStart') {
						newDate.add(1, 'years').add(-1, 'days');
						scope.rangeEnd = newDate;
					} else {
						newDate.add(-1, 'years').add(1, 'days');
						scope.rangeStart = newDate;
					}
				}
			}

			scope.submit = function() {
				apply();
				scope.onSubmit &&
					scope.onSubmit({
						data: {
							start: scope.start,
							end: scope.end,
						},
					});
				$timeout(() => {
					document.body.click();
				}, 0);
			};

			attrs.$observe('disabled', function(isDisabled) {
				scope.disableDatePickers = !!isDisabled;
			});

			function checkAndUpdateCustomRange({value, direction = 1}) {
				if (scope.customDateRange) {
					const [limit, unit] = scope.customDateRange.split(' ');
					const diff = scope.rangeEnd.diff(scope.rangeStart, unit || 'days', true);
					const _limit = parseInt(limit);
					if (diff > _limit) {
						scope[direction === 1 ? 'rangeEnd' : 'rangeStart'] = moment(value).add(direction * _limit, unit);
					}
				}
			}

			scope.$watch('rangeStart', function(value, oldvalue) {
				if (value && (!oldvalue || !value.isSame(oldvalue)) && scope.range !== '2D-1Y') {
					if (!isCustomRange()) {
						scope.rangeEnd = updateRangeOffset(moment(value), 1);
					} else {
						if (!scope.rangeEnd || value.isAfter(scope.rangeEnd)) {
							scope.rangeEnd = moment(value);
						}
						checkAndUpdateCustomRange({value, direction: 1});
					}
					attrs.autosave && apply();
				} else if (scope.range === '2D-1Y') {
					if (scope.rangeEnd && scope.rangeStart && oldvalue) {
						updateCalendar('rangeStart', value);
					}
				}
			});

			scope.$watch('rangeEnd', function(value, oldvalue) {
				if (value && (!oldvalue || !value.isSame(oldvalue)) && scope.range !== '2D-1Y') {
					if (!isCustomRange()) {
						const newRangeStart = updateRangeOffset(moment(value), -1);
						(newRangeStart.isSameOrAfter(scope.after) || !scope.after) && (scope.rangeStart = newRangeStart);
					} else {
						if (scope.rangeStart && value.isBefore(scope.rangeStart)) {
							scope.rangeStart = moment(value);
						}
						checkAndUpdateCustomRange({value, direction: -1});
					}
					attrs.autosave && apply();
				} else if (scope.range === '2D-1Y') {
					if (scope.rangeEnd && scope.rangeStart && oldvalue) {
						updateCalendar('rangeEnd', value);
					}
				}
			});

			scope.$watch('start', function(value) {
				if (value === null) {
					scope.start = moment();
				}
				if (!isCustomRange() && scope.range !== '2D-1Y') {
					scope.rangeEnd = updateRangeOffset(moment(value), 1);
					updateEndDate();
				} else if (!scope.rangeEnd || value.isAfter(scope.rangeEnd)) {
					scope.rangeEnd = moment(scope.end);
				}
				scope.rangeStart = moment(value);
			});

			scope.$watch('end', function(value) {
				if (value && isCustomRange()) {
					updateRangeEndDate();
				}
			});

			scope.$watch('range', function() {
				scope.beforeStartDt = updateRangeOffset(moment(scope.before), -1);
				updateAfterStartDt();

				if (!isCustomRange() && scope.range !== '2D-1Y') {
					if (scope.beforeStartDt.isBefore(scope.start)) {
						scope.start = moment(scope.beforeStartDt);
						scope.rangeStart = moment(scope.beforeStartDt);
					}
					if (scope.after && scope.afterStartDt.isAfter(scope.start)) {
						scope.end = moment(scope.afterStartDt);
						scope.rangeEnd = moment(scope.afterStartDt);
					}
					scope.rangeEnd = updateRangeOffset(moment(scope.start), 1);
					updateEndDate();
				}
			});
		},
	};
});
