angular.module('TISCC').controller('SelectExceptionsDialogCtrl', SelectExceptionsDialogCtrl);

function SelectExceptionsDialogCtrl($scope, $modalInstance, $filter, $timeout, list, addFindingLink, onSelected) {
	$scope.list = list;
	$scope.addFindingLink = addFindingLink;
	$scope.updateCheckboxes = updateCheckboxes;
	$scope.cancel = function() {
		$modalInstance.dismiss('cancel');
	};

	$scope.submit = function() {
		onSelected($filter('filter')($scope.list || [], {checked: true}));
		$modalInstance.close('confirm');
	};

	$scope.selectDeselectAll = function(status) {
		if (status && $scope.list.every(prop => prop.checked === true)) {
			status = false;
		}
		for (let i = $scope.list.length; i--; ) {
			$scope.list[i].checked = status;
		}
		updateCheckboxes();
	};

	function updateCheckboxes() {
		$timeout(updateSelectAllBtn, 0);
	}

	function updateSelectAllBtn() {
		const checkedCount = $filter('filter')($scope.list || [], {checked: true}).length;
		$scope.submitEnabled = checkedCount > 0;

		if (!checkedCount) {
			$scope.checkAll = false;
		} else if ($scope.list.length === checkedCount) {
			$scope.checkAll = true;
		} else {
			$scope.checkAll = null;
		}
	}
}
