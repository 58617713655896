import MetricsFactory from './metrics-service';

let requestCache = {};
let timeoutPromise = null;
const METRICS_EVENTS = {
	ROUTE_CHANGE_START: 'metrics--start-nav',
	ROUTE_CHANGE_END: 'metrics--end-nav',
};

angular
	.module('metrics', [])
	.config(function($httpProvider) {
		$httpProvider.interceptors.push('metricsHttpInterceptor');
	})
	.factory('metricsHttpInterceptor', function($rootScope, $timeout, MetricsFactory) {
		return {
			request: function(config) {
				if (config.url === '/metrics') {
					return config;
				}

				if (!requestCache[config.url]) {
					requestCache[config.url] = config;
					requestCache[config.url].count = 1;
				} else {
					requestCache[config.url].count += 1;
				}

				return config;
			},
			response: function(response) {
				if (response.config.url === '/metrics') {
					return response;
				}

				if (requestCache[response.config.url]) {
					// for the same requests
					if (requestCache[response.config.url].count === 1) {
						delete requestCache[response.config.url];
					} else {
						requestCache[response.config.url].count -= 1;
					}

					if (timeoutPromise) {
						$timeout.cancel(timeoutPromise);
					}

					timeoutPromise = $timeout(function() {
						if (Object.keys(requestCache).length === 0) {
							MetricsFactory.measure(MetricsFactory.METRIC_TYPES.PAGE_LOAD);

							$rootScope.$broadcast(METRICS_EVENTS.ROUTE_CHANGE_END);
						}
					}, 0);
				}

				return response;
			},
		};
	})
	.run(function($rootScope, $location, MetricsFactory) {
		$rootScope.$on('$routeChangeStart', (event, route) => {
			if (route.$$route.metricsConfig && !route.$$route.metricsConfig.exclude) {
				MetricsFactory.mark(MetricsFactory.METRIC_TYPES.PAGE_LOAD);
			}

			$rootScope.$broadcast(METRICS_EVENTS.ROUTE_CHANGE_START);
		});
	})
	.service('MetricsFactory', MetricsFactory);
