angular.module('TISCC').directive('timeSelector', function($window, $rootScope, $translate) {
	return {
		restrict: 'A',
		scope: {
			range: '=',
			oneday: '=',
			chartObj: '<',
			eventObject: '<',
			onBrush: '&',
		},
		link: function(scope, element) {
			const margin = {left: 1};
			const parent = d3.select(element[0]);
			const prevRange = {
				from: null,
				to: null,
			};
			let range = {
				from: null,
				to: null,
			};
			let width;
			let startTime;
			let endTime;

			const svg = parent.append('g').attr('transform', 'translate(' + margin.left + ', 0)');
			const x = d3.time.scale();
			const parentElement = element.parent();

			function updateRangeX() {
				let from, to;
				if (!scope.range || !scope.range.from) {
					return;
				}

				from = new Date(scope.range.from.format('DD MMMM YYYY HH:mm'));
				to = new Date(scope.range.to.format('DD MMMM YYYY HH:mm'));

				if (!(from instanceof Date)) return;
				if (prevRange.from !== from.getTime() || prevRange.to !== to.getTime()) {
					range = {
						from: from,
						to: to,
					};
					startTime = from && from.getTime();
					endTime = to && to.getTime();
					prevRange.from = from.getTime();
					prevRange.to = to.getTime();
					x.domain([from, to]);
					updateRange();
				}
			}

			// ----------------------------- Time Range Selector -------------------------//

			const rangeHeight = 35;
			const tickHeight = 10;
			const strokeWidth = 1;
			const rangeBar = svg.append('g');
			const rangeX = d3.time.scale();
			const rangeAxis = d3.svg.axis().scale(rangeX);

			let rangeBarRect;
			let rangeTopGrid;
			let rangeBottomGrid;
			let rangeLabel;
			let brushScale;
			let brush;
			let gBrush;
			let timeIndicatorFormat;
			let leftGrabber;
			let rightGrabber;

			rangeBar
				.append('defs')
				.append('linearGradient')
				.attr('x1', '0%')
				.attr('y1', '0%')
				.attr('x2', '0')
				.attr('y2', '100%')
				.attr('id', 'barGradient')
				.call(function(gradient) {
					gradient
						.append('stop')
						.attr('class', 'stop1')
						.attr('offset', '0%');
					gradient
						.append('stop')
						.attr('class', 'stop2')
						.attr('offset', '100%');
				});
			rangeBar
				.append('defs')
				.append('linearGradient')
				.attr('x1', '0%')
				.attr('y1', '0%')
				.attr('x2', '0')
				.attr('y2', '100%')
				.attr('id', 'buttonGradient')
				.call(function(gradient) {
					gradient
						.append('stop')
						.attr('class', 'stop1')
						.attr('offset', '0%');
					gradient
						.append('stop')
						.attr('class', 'stop2')
						.attr('offset', '100%');
				});
			rangeBar
				.append('defs')
				.append('linearGradient')
				.attr('x1', '0%')
				.attr('y1', '0%')
				.attr('x2', '0')
				.attr('y2', '100%')
				.attr('id', 'brushGradient')
				.call(function(gradient) {
					gradient
						.append('stop')
						.attr('class', 'stop1')
						.attr('offset', '0%');
					gradient
						.append('stop')
						.attr('class', 'stop2')
						.attr('offset', '54%');
					gradient
						.append('stop')
						.attr('class', 'stop3')
						.attr('offset', '100%');
				});
			rangeBar
				.append('defs')
				.append('linearGradient')
				.attr('x1', '0%')
				.attr('y1', '0%')
				.attr('x2', '0')
				.attr('y2', '100%')
				.attr('id', 'timeGradient')
				.call(function(gradient) {
					gradient
						.append('stop')
						.attr('class', 'stop1')
						.attr('offset', '0%');
					gradient
						.append('stop')
						.attr('class', 'stop2')
						.attr('offset', '100%');
				});
			rangeBar
				.append('defs')
				.append('linearGradient')
				.attr('x1', '0%')
				.attr('y1', '0%')
				.attr('x2', '0')
				.attr('y2', '100%')
				.attr('id', 'grabberGradient')
				.call(function(gradient) {
					gradient
						.append('stop')
						.attr('class', 'stop1')
						.attr('offset', '0%');
					gradient
						.append('stop')
						.attr('class', 'stop2')
						.attr('offset', '100%');
				});
			rangeBarRect = rangeBar
				.append('rect')
				.attr('id', 'rangeBar')
				.attr('fill', 'url(#barGradient)')
				.attr('class', 'range-bar')
				.attr('height', rangeHeight);

			rangeTopGrid = rangeBar.append('g').attr('class', 'grid');
			rangeBottomGrid = rangeBar.append('g').attr('class', 'grid');
			rangeLabel = rangeBar.append('g').attr('class', 'axis');

			brushScale = d3.time.scale();
			brush = d3.svg
				.brush()
				.on('brush', brushed)
				.on('brushstart', brushStart)
				.on('brushend', brushEnd);

			gBrush = rangeBar
				.append('g')
				.attr('class', 'brush')
				.attr('fill', 'url(#brushGradient)');
			let timeIndicator = false;
			let brushStarted = false;
			let grabber = 'left';

			function brushed(rangeBrush) {
				let extent = rangeBrush || brush.extent();
				const calendarRange = moment(scope.range.to).diff(moment(scope.range.from), 'days');
				let hourLimit = calendarRange === 1 ? 3600000 : 10800000;
				if (calendarRange > 14 || scope.oneday) {
					hourLimit = 86400000;
					extent = snapToDayRanges(extent);
				} else {
					extent = roundBrushRanges(extent);
				}
				if (extent[1] - extent[0] <= hourLimit) {
					extent = [new Date(extent[1].getTime() - hourLimit), extent[1]];

					if (extent[0] < range.from) {
						extent = [range.from, new Date(startTime + hourLimit)];
					} else if (extent[1] > range.to) {
						extent = [new Date(endTime - hourLimit), range.to];
					}
					d3.select(this).call(brush.extent(extent));
				}
				if (timeIndicator) {
					updateTimeIndicator();
				}

				x.domain(brush.empty() ? [range.from, range.to] : extent);
				if (!extent[2]) {
					scope.eventObject.emit('setLinesModified');
					scope.eventObject.emit('brushChart', x.domain());
				}
				scope.eventObject.emit('brushChartAdjust', x.domain());

				scope.onBrush && scope.onBrush();
			}

			function brushStart() {
				brushStarted = true;
			}

			function brushEnd() {
				brushStarted = false;
				hideTimeIndicator();
			}

			function snapToDateEdges(date) {
				if (date.getHours() < 12) {
					date.setHours(-1, 60, 0);
				} else {
					date.setHours(24, 0, 0);
				}
				return date;
			}

			function snapToDayRanges(extent) {
				if (!extent) {
					return;
				}
				snapToDateEdges(extent[0]);
				snapToDateEdges(extent[1]);
				gBrush.call(brush.extent(extent));
				return extent;
			}

			function roundBrushRanges(extent) {
				// Round range start and end values to 15-minute intervals (i.e. 00:00, 00:15, 00:30, 00:45 ...)
				const timeInterval = 15 * 60 * 1000;
				if (!extent) {
					return;
				}
				extent[0] = new Date(~~(extent[0].getTime() / timeInterval) * timeInterval);
				extent[1] = new Date(~~(extent[1].getTime() / timeInterval) * timeInterval);
				if (scope.range.brush) {
					const brushFrom = scope.range.brush.from;
					const brushTo = scope.range.brush.to;
					const roundedMinutesFrom = brushFrom.minutes() / 15 * 15;
					const roundedMinutesTo = brushTo.minutes() / 15 * 15;
					scope.range.brush.from.set('minute', roundedMinutesFrom);
					scope.range.brush.from.set('minute', roundedMinutesTo);
				}

				gBrush.call(brush.extent(extent));
				return extent;
			}

			function updateRange(external = null) {
				width = parentElement.prop('offsetWidth') - 2 * margin.left;
				rangeX.domain([moment(range.from).toDate(), moment(range.to).toDate()]).range([0, width]);

				rangeBarRect.attr('width', width);
				rangeBottomGrid.attr('transform', 'translate(0, ' + (rangeHeight - tickHeight) + ')');

				updateRangeLabel();
				updateBrush(external);

				element.attr('viewBox', '0 0 ' + parentElement.prop('offsetWidth') + ' 36');
				element.attr('preserveAspectRatio', 'none');
			}

			function updateRangeLabel() {
				const isPeriodDay = scope.range.calendarRange === 1;
				let timeFormat = d3Locale.current.timeFormat;
				let localeId = d3Locale.localeId;
				let d3LocaleId = d3Locale[localeId];
				let rangeTopTick = isPeriodDay ? 24 : 28;
				let rangeLabelTick = isPeriodDay ? 8 : 7;

				timeIndicatorFormat = isPeriodDay ? timeFormat(d3Locale[localeId].minutesLong) : timeFormat(d3Locale[localeId].dateTimeShort);

				rangeTopGrid
					.call(
						rangeAxis
							.ticks(rangeTopTick)
							.tickFormat('')
							.tickSize(tickHeight + strokeWidth, 0)
					)
					.selectAll('line')
					.attr('y1', strokeWidth)
					.attr('display', function(d) {
						if (d.getTime() === startTime || d.getTime() === endTime) {
							return 'none';
						}
					});
				rangeBottomGrid
					.call(
						rangeAxis
							.ticks(rangeLabelTick)
							.tickFormat('')
							.tickSize(tickHeight, 0)
					)
					.selectAll('line')
					.attr('display', function(d) {
						if (d.getTime() === startTime || d.getTime() === endTime) {
							return 'none';
						}
					});
				rangeLabel.selectAll('*').remove();
				rangeLabel.call(
					rangeAxis
						.ticks(rangeLabelTick)
						.tickFormat(
							d3Locale.current.timeFormat.multi([
								[
									d3LocaleId.minutes,
									function(d) {
										return d.getMinutes();
									},
								],
								[
									d3LocaleId.timeShort,
									function(d) {
										return d.getHours();
									},
								],
								[
									d3LocaleId.dateShort,
									function(d) {
										return d.getDate();
									},
								],
							])
						)
						.tickSize(0, 0, 0)
				);

				updateRangeLabelPos();
			}

			function updateRangeLabelPos() {
				rangeLabel
					.selectAll('text')
					.attr('dy', rangeHeight / 2)
					.attr('class', 'range-text')
					.attr('display', function(d) {
						if (d.getTime() === startTime || d.getTime() === endTime) {
							return 'none';
						}
					});
			}

			function updateBrush(external = null) {
				const isUpdateBrush = scope.lastSelectedRange === scope.range.rangeMode;

				scope.lastSelectedRange = scope.range.rangeMode;

				if (isUpdateBrush && scope.range.brush && scope.range.brush.from.isBefore(scope.range.brush.to)) {
					const {from, to} = scope.range.brush;

					if (from.isBefore(scope.range.from)) {
						scope.range.brush.from = moment(scope.range.from);
					}
					if (to.isAfter(scope.range.to)) {
						scope.range.brush.to = moment(scope.range.to);
					}
					if (
						from.isBetween(scope.range.from, scope.range.to) ||
						to.isBetween(scope.range.from, scope.range.to) ||
						(from.isSame(scope.range.from) && to.isSame(scope.range.to))
					) {
						const extent = [
							from
								.clone()
								.add(from.utcOffset() - moment().utcOffset(), 'minutes')
								.toDate(),
							to
								.clone()
								.add(to.utcOffset() - moment().utcOffset(), 'minutes')
								.toDate(),
							external,
						];
						brushed(extent);
					}
				} else {
					scope.range.brush = null;
				}
				brushScale.domain([range.from, range.to]).range([0, width]);
				brush.x(brushScale).extent(x.domain());
				gBrush.call(brush);
				gBrush.select('.background').style('pointer-events', 'none');
				gBrush
					.selectAll('rect')
					.attr('y', 1)
					.attr('height', rangeHeight - 1);
				if (!leftGrabber && !rightGrabber) {
					leftGrabber = gBrush.select('.w').append('rect');
					setGrabbersLine(gBrush.select('.w').append('line'), 2, 4, 16, 16, '#262626');
					setGrabbersLine(gBrush.select('.w').append('line'), 2, 4, 17, 17, '#545454');
					setGrabbersLine(gBrush.select('.w').append('line'), 2, 4, 20, 20, '#262626');
					setGrabbersLine(gBrush.select('.w').append('line'), 2, 4, 21, 21, '#545454');

					rightGrabber = gBrush.select('.e').append('rect');
					setGrabbersLine(gBrush.select('.e').append('line'), -3, -1, 16, 16, '#262626');
					setGrabbersLine(gBrush.select('.e').append('line'), -3, -1, 17, 17, '#545454');
					setGrabbersLine(gBrush.select('.e').append('line'), -3, -1, 20, 20, '#262626');
					setGrabbersLine(gBrush.select('.e').append('line'), -3, -1, 21, 21, '#545454');
				}
				leftGrabber
					.attr('transform', 'translate(0, 11)')
					.attr('width', 8)
					.attr('height', 13)
					.attr('class', 'grabber')
					.attr('side', 'left')
					.attr('fill', 'url(#buttonGradient)')
					.attr('x', -1);
				rightGrabber
					.attr('transform', 'translate(0, 11)')
					.attr('width', 8)
					.attr('height', 13)
					.attr('class', 'grabber')
					.attr('side', 'right')
					.attr('fill', 'url(#buttonGradient)')
					.attr('x', -7);

				gBrush
					.selectAll('.resize')
					.on('mouseover', function() {
						showTimeIndicator(d3.event);
					})
					.on('mouseout', hideTimeIndicator);
			}

			function setGrabbersLine(line, x1, x2, y1, y2, stroke) {
				line
					.attr('x1', x1)
					.attr('y1', y1)
					.attr('x2', x2)
					.attr('y2', y2)
					.attr('stroke', stroke);
			}

			function showTimeIndicator(event) {
				let target = event.target.nextSibling;
				grabber = target.getAttribute('side');
				gBrush.selectAll('.grabber').attr('fill', 'url(#buttonGradient)');
				target.setAttribute('fill', 'url(#grabberGradient)');

				if (timeIndicator) {
					return;
				}

				timeIndicator = gBrush.append('g').attr('class', 'time-indicator');
				timeIndicator
					.append('rect')
					.attr('height', 15)
					.attr('fill', 'url(#timeGradient)')
					.attr('ry', 2);
				timeIndicator
					.append('text')
					.attr('y', 11)
					.attr('dx', 5);
				updateTimeIndicator();
			}

			function updateTimeIndicator() {
				let timeIndicatorWidth;
				let extent = brush.extent();
				let date = grabber === 'left' ? timeIndicatorFormat(extent[0]) : timeIndicatorFormat(extent[1]);
				timeIndicator
					.select('text')
					.text(date)
					.attr('width', function() {
						timeIndicatorWidth = this.getComputedTextLength() + 10;
						return timeIndicatorWidth;
					});
				timeIndicator.select('rect').attr('width', timeIndicatorWidth);
				let positionMargin = 30;
				let position = grabber === 'left' ? rangeX(extent[0]) + positionMargin : rangeX(extent[1]) - positionMargin - timeIndicatorWidth;

				if (position + timeIndicatorWidth + positionMargin > width) {
					position = width - positionMargin - timeIndicatorWidth;
				} else if (position < 0) {
					position = positionMargin;
				}
				timeIndicator.attr('transform', 'translate(' + position + ', 11)');
			}

			function hideTimeIndicator() {
				if (timeIndicator && !brushStarted) {
					timeIndicator.remove();
					timeIndicator = false;
					gBrush.selectAll('.grabber').attr('fill', 'url(#buttonGradient)');
				}
			}

			const updateRangeExternal = extent => {
				if (extent && extent[2]) {
					updateRange(1);
				}
			};

			const updateRangeEvent = () => {
				if (this.updateRangeTimer) {
					window.clearTimeout(this.updateRangeTimer);
				}
				this.updateRangeTimer = window.setTimeout(() => {
					updateRange();
				}, 50);
			};

			const onOffEventListeners = action => {
				angular.element($window)[action]('resize', updateRange);
				scope.eventObject[action]('chartResize', updateRangeEvent);
				scope.eventObject[action]('brushChart', updateRangeExternal);
			};

			onOffEventListeners('on');

			scope.$watch(function() {
				return scope.$parent.maximizeCharts;
			}, updateRange);
			scope.$watch('range', updateRangeX, true);
			scope.$watch(function() {
				return d3Locale.current;
			}, updateRangeLabel);
			scope.$on('$destroy', function() {
				const panel = element[0].getElementsByClassName('time-range-panel');
				if (panel && panel[0] && typeof panel[0].remove === 'function') {
					panel[0].remove();
				}
				onOffEventListeners('off');
			});
		},
	};
});
