/* globals process */

angular.module('TISCC').provider('testProvider', function() {
	return {
		$get: function($log) {
			let pushMethodToDocument = function(name, method) {
				if (process.env.NODE_ENV === 'development') {
					if (!document[name]) {
						document[name] = method;
					} else {
						$log.error(`method  ${name} is existing already`);
					}
				}
			};

			return {
				pushMethodToDocument,
			};
		},
	};
});
