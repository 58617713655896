angular.module('TISCC').controller('MyViewsDeleteDialogCtrl', MyViewsDeleteDialogCtrl);

function MyViewsDeleteDialogCtrl($scope, $modalInstance, viewDataEvent, data, index, list, userDataService) {
	$scope.list = list;
	$scope.userService = userDataService;
	$scope.index = index;
	$scope.viewDataEvent = viewDataEvent;
	$scope.cancel = function() {
		$modalInstance.dismiss('cancel');
	};

	$scope.approve = function() {
		$scope.list.splice($scope.index, 1);
		$scope.viewDataEvent.emit('openMyViewDelete', $scope.index);
		$scope.viewDataEvent.emit('updateMyView', {
			data: $scope.list,
			deletedViewName: data.name,
		});
		$scope.cancel();
	};
}
