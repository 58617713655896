export const getColumnName = column => {
	switch (column) {
		case 'tisObjectName':
			return 'TIS Equipment Name';
		case 'groupName':
			return 'TIS Equipment Type';
	}

	return column;
};
