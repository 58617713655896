/* eslint max-len: ["error", 220]*/
/* eslint quotes: [0, 'single'] */
/* eslint eqeqeq: [0, 'always'] */
/* eslint one-var: 0 */

angular
	.module('ui.bootstrap.calendar', ['ui.bootstrap.position'])
	.constant('calendarConfig', {
		dayFormat: 'dd',
		monthFormat: 'MMMM',
		yearFormat: 'yyyy',
		dayHeaderFormat: 'EEE',
		dayTitleFormat: 'MMMM yyyy',
		monthTitleFormat: 'yyyy',
		showWeeks: true,
		startingDay: 0,
		yearRange: 20,
		minDate: null,
		maxDate: null,
		range: 1,
	})
	.controller('calendarController', function($scope, $attrs, dateFilter, calendarConfig) {
		let format = {
				day: getValue($attrs.dayFormat, calendarConfig.dayFormat),
				month: getValue($attrs.monthFormat, calendarConfig.monthFormat),
				year: getValue($attrs.yearFormat, calendarConfig.yearFormat),
				dayHeader: getValue($attrs.dayHeaderFormat, calendarConfig.dayHeaderFormat),
				dayTitle: getValue($attrs.dayTitleFormat, calendarConfig.dayTitleFormat),
				monthTitle: getValue($attrs.monthTitleFormat, calendarConfig.monthTitleFormat),
			},
			startingDay = getValue($attrs.startingDay, calendarConfig.startingDay),
			yearRange = getValue($attrs.yearRange, calendarConfig.yearRange),
			self = this;

		this.minDate = calendarConfig.minDate ? new Date(calendarConfig.minDate) : null;
		this.maxDate = calendarConfig.maxDate ? new Date(calendarConfig.maxDate) : null;
		this.range = calendarConfig.range;

		function getValue(value, defaultValue) {
			return angular.isDefined(value) ? $scope.$parent.$eval(value) : defaultValue;
		}

		function getDaysInMonth(year, month) {
			return new Date(year, month, 0).getDate();
		}

		function getDates(startDate, n) {
			let dates = new Array(n);
			let current = startDate,
				i = 0;
			while (i < n) {
				dates[i++] = new Date(current);
				current.setDate(current.getDate() + 1);
			}
			return dates;
		}

		function makeDate(date, format, isSelected, isSecondary) {
			return {date: date, label: dateFilter(date, format), selected: !!isSelected, secondary: !!isSecondary};
		}

		this.modes = [
			{
				name: 'day',
				getVisibleDates: function(date, selected) {
					let year = date.getFullYear(),
						month = date.getMonth(),
						firstDayOfMonth = new Date(year, month, 1);
					let prevMount = self.range > 1 ? Math.ceil(self.range / 7) * 7 : 0;
					let difference = startingDay - firstDayOfMonth.getDay(),
						numDisplayedFromPreviousMonth = difference > 0 ? 7 + prevMount - difference : -difference + prevMount,
						firstDate = new Date(firstDayOfMonth),
						numDates = 0;

					if (numDisplayedFromPreviousMonth > 0) {
						firstDate.setDate(-numDisplayedFromPreviousMonth + 1);
						numDates += numDisplayedFromPreviousMonth; // Previous
					}
					numDates += getDaysInMonth(year, month + 1); // Current
					numDates += (7 - numDates % 7) % 7; // Next

					let days = getDates(firstDate, numDates),
						labels = new Array(7);
					for (let i = 0; i < numDates; i++) {
						let dt = new Date(days[i]);
						days[i] = makeDate(
							dt,
							format.day,
							selected &&
								selected.getDate() === dt.getDate() &&
								selected.getMonth() === dt.getMonth() &&
								selected.getFullYear() === dt.getFullYear(),
							dt.getMonth() !== month
						);
					}
					for (let j = 0; j < 7; j++) {
						labels[j] = dateFilter(days[j].date, format.dayHeader);
					}
					return {objects: days, title: dateFilter(date, format.dayTitle), labels: labels};
				},
				compare: function(date1, date2) {
					return new Date(date1.getFullYear(), date1.getMonth(), date1.getDate()) - new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
				},
				split: 7,
				step: {months: 1},
			},
			{
				name: 'month',
				getVisibleDates: function(date, selected) {
					let months = new Array(12),
						year = date.getFullYear();
					for (let i = 0; i < 12; i++) {
						let dt = new Date(year, i, 1);
						months[i] = makeDate(dt, format.month, selected && selected.getMonth() === i && selected.getFullYear() === year);
					}
					return {objects: months, title: dateFilter(date, format.monthTitle)};
				},
				compare: function(date1, date2) {
					return new Date(date1.getFullYear(), date1.getMonth()) - new Date(date2.getFullYear(), date2.getMonth());
				},
				split: 3,
				step: {years: 1},
			},
			{
				name: 'year',
				getVisibleDates: function(date, selected) {
					let years = new Array(yearRange),
						year = date.getFullYear(),
						startYear = parseInt((year - 1) / yearRange, 10) * yearRange + 1;
					for (let i = 0; i < yearRange; i++) {
						let dt = new Date(startYear + i, 0, 1);
						years[i] = makeDate(dt, format.year, selected && selected.getFullYear() === dt.getFullYear());
					}
					return {objects: years, title: [years[0].label, years[yearRange - 1].label].join(' - ')};
				},
				compare: function(date1, date2) {
					return date1.getFullYear() - date2.getFullYear();
				},
				split: 5,
				step: {years: yearRange},
			},
		];

		this.isDisabled = function(date, mode) {
			let currentMode = this.modes[mode || 0];
			return (
				(this.minDate && currentMode.compare(date, this.minDate) < 0) ||
				(this.maxDate && currentMode.compare(date, this.maxDate) > 0) ||
				($scope.dateDisabled && $scope.dateDisabled({date: date, mode: currentMode.name}))
			);
		};

		this.isCurrent = function(date) {
			let currDate = new Date();
			return date.getFullYear() == currDate.getFullYear() && date.getMonth() == currDate.getMonth() && date.getDate() == currDate.getDate();
		};
	})
	.directive('calendar', [
		'dateFilter',
		'$parse',
		'calendarConfig',
		'$log',
		function(dateFilter, $parse, calendarConfig, $log) {
			return {
				restrict: 'EA',
				replace: true,
				templateUrl: 'common/calendar/datepicker.html',
				scope: {
					dateDisabled: '&',
				},
				require: ['calendar', '?^ngModel'],
				controller: 'calendarController',
				link: function(scope, element, attrs, ctrls) {
					let calendarCtrl = ctrls[0],
						ngModel = ctrls[1],
						disableIndex;

					if (!ngModel) {
						return; // do nothing if no ng-model
					}

					// Configuration parameters
					let mode = 0,
						selected = new Date(),
						showWeeks = calendarConfig.showWeeks;

					if (attrs.showWeeks) {
						scope.$parent.$watch($parse(attrs.showWeeks), function(value) {
							showWeeks = !!value;
							updateShowWeekNumbers();
						});
					} else {
						updateShowWeekNumbers();
					}

					if (attrs.min) {
						scope.$parent.$watch($parse(attrs.min), function(value) {
							calendarCtrl.minDate = value ? new Date(value) : null;
							refill();
						});
					}
					if (attrs.max) {
						scope.$parent.$watch($parse(attrs.max), function(value) {
							calendarCtrl.maxDate = value ? new Date(value) : null;
							refill();
						});
					}
					if (attrs.range) {
						scope.$parent.$watch($parse(attrs.range), function(value) {
							calendarCtrl.range = value;
							refill();
						});
					}

					function updateShowWeekNumbers() {
						scope.showWeekNumbers = mode === 0 && showWeeks;
					}

					// Split array into smaller arrays
					function split(arr, size) {
						let arrays = [];
						while (arr.length > 0) {
							arrays.push(arr.splice(0, size));
						}
						return arrays;
					}

					function refill(updateSelected) {
						let date = null,
							valid = true;

						if (ngModel.$modelValue) {
							date = new Date(ngModel.$modelValue);

							if (isNaN(date)) {
								valid = false;
								$log.error(
									'calendar directive: "ng-model" value must be a Date object, a number of milliseconds since 01.01.1970 or a string representing an RFC2822 or ISO 8601 date.'
								);
							} else if (updateSelected) {
								selected = date;
							}
						}
						ngModel.$setValidity('date', valid);

						disableIndex = null;
						let currentMode = calendarCtrl.modes[mode],
							data = currentMode.getVisibleDates(selected, date);
						angular.forEach(data.objects, function(obj, index) {
							obj.disabled = calendarCtrl.isDisabled(obj.date, mode);
							obj.current = calendarCtrl.isCurrent(obj.date);
							if (disableIndex === null && obj.disabled && mode === 0) {
								disableIndex = index;
							}
						});

						ngModel.$setValidity('date-disabled', !date || !calendarCtrl.isDisabled(date));

						scope.rows = data.objects;
						scope.labels = data.labels || [];
						scope.title = data.title;
						scope.mode = calendarCtrl.modes[mode];
					}

					function setMode(value) {
						mode = value;
						updateShowWeekNumbers();
						refill();
					}

					ngModel.$render = function() {
						refill(true);
					};

					scope.select = function(i) {
						let index = disableIndex !== null && i > disableIndex - calendarCtrl.range ? disableIndex - calendarCtrl.range : i,
							date = scope.rows[index].date;
						if (mode === 0) {
							let dt = new Date(ngModel.$modelValue);
							dt.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
							ngModel.$setViewValue(dt);
							refill(true);
						} else {
							selected = date;
							setMode(mode - 1);
						}
					};
					scope.move = function(direction) {
						let step = calendarCtrl.modes[mode].step;
						selected.setMonth(selected.getMonth() + direction * (step.months || 0));
						selected.setFullYear(selected.getFullYear() + direction * (step.years || 0));
						refill();
					};
					scope.toggleMode = function() {
						setMode((mode + 1) % calendarCtrl.modes.length);
					};
					scope.getWeekNumber = function(row) {
						return mode === 0 && scope.showWeekNumbers && row.length === 7 ? getISO8601WeekNumber(row[0].date) : null;
					};
					scope.position = function(i, item) {
						let index = disableIndex !== null && i > disableIndex - calendarCtrl.range ? disableIndex - calendarCtrl.range : i,
							split = calendarCtrl.modes[mode].split;
						return (
							'day-' +
							index % split +
							' row-' +
							Math.floor(index / split) +
							(item.current ? ' cday-' + i % split + ' crow-' + Math.floor(i / split) : '')
						);
					};

					function getISO8601WeekNumber(date) {
						let checkDate = new Date(date);
						checkDate.setDate(checkDate.getDate() + 4 - (checkDate.getDay() || 7)); // Thursday
						let time = checkDate.getTime();
						checkDate.setMonth(0); // Compare with Jan 1
						checkDate.setDate(1);
						return Math.floor(Math.round((time - checkDate) / 86400000) / 7) + 1;
					}
				},
			};
		},
	])

	.constant('calendarPopupConfig', {
		dateFormat: 'yyyy-MM-dd',
		currentText: 'Today',
		toggleWeeksText: 'Weeks',
		clearText: 'Clear',
		closeText: 'Done',
		closeOnDateSelection: true,
		appendToBody: false,
	})

	.directive('calendarPopup', [
		'$compile',
		'$parse',
		'$document',
		'$position',
		'dateFilter',
		'calendarPopupConfig',
		'calendarConfig',
		function($compile, $parse, $document, $position, dateFilter, calendarPopupConfig, calendarConfig) {
			return {
				restrict: 'EA',
				require: 'ngModel',
				link: function(originalScope, element, attrs, ngModel) {
					let dateFormat;
					attrs.$observe('calendarPopup', function(value) {
						dateFormat = value || calendarPopupConfig.dateFormat;
						ngModel.$render();
					});

					let closeOnDateSelection = angular.isDefined(attrs.closeOnDateSelection)
						? originalScope.$eval(attrs.closeOnDateSelection)
						: calendarPopupConfig.closeOnDateSelection;
					let appendToBody = angular.isDefined(attrs.calendarAppendToBody)
						? originalScope.$eval(attrs.calendarAppendToBody)
						: calendarPopupConfig.appendToBody;

					// create a child scope for the calendar directive so we are not polluting original scope
					let scope = originalScope.$new();

					originalScope.$on('$destroy', function() {
						scope.$destroy();
					});

					attrs.$observe('currentText', function(text) {
						scope.currentText = angular.isDefined(text) ? text : calendarPopupConfig.currentText;
					});
					attrs.$observe('toggleWeeksText', function(text) {
						scope.toggleWeeksText = angular.isDefined(text) ? text : calendarPopupConfig.toggleWeeksText;
					});
					attrs.$observe('clearText', function(text) {
						scope.clearText = angular.isDefined(text) ? text : calendarPopupConfig.clearText;
					});
					attrs.$observe('closeText', function(text) {
						scope.closeText = angular.isDefined(text) ? text : calendarPopupConfig.closeText;
					});

					let getIsOpen, setIsOpen;
					if (attrs.isOpen) {
						getIsOpen = $parse(attrs.isOpen);
						setIsOpen = getIsOpen.assign;

						originalScope.$watch(getIsOpen, function updateOpen(value) {
							scope.isOpen = !!value;
						});
					}
					scope.isOpen = getIsOpen ? getIsOpen(originalScope) : false; // Initial state

					function setOpen(value) {
						if (setIsOpen) {
							setIsOpen(originalScope, !!value);
						} else {
							scope.isOpen = !!value;
						}
					}

					let documentClickBind = function(event) {
						if (scope.isOpen && event.target !== element[0]) {
							scope.$apply(function() {
								setOpen(false);
							});
						}
					};

					let elementFocusBind = function() {
						scope.$apply(function() {
							setOpen(true);
						});
					};

					// popup element used to display calendar
					let popupEl = angular.element('<div calendar-popup-wrap><div calendar></div></div>');
					popupEl.attr({
						'ng-model': 'date',
						'ng-change': 'dateSelection()',
					});
					let calendarEl = angular.element(popupEl.children()[0]);
					if (attrs.calendarOptions) {
						calendarEl.attr(angular.extend({}, originalScope.$eval(attrs.calendarOptions)));
					}

					// TODO: reverse from dateFilter string to Date object
					function parseDate(viewValue) {
						if (!viewValue) {
							ngModel.$setValidity('date', true);
							return null;
						} else if (angular.isDate(viewValue)) {
							ngModel.$setValidity('date', true);
							return viewValue;
						} else if (angular.isString(viewValue)) {
							let date = new Date(viewValue);
							if (isNaN(date)) {
								ngModel.$setValidity('date', false);
								return undefined;
							} else {
								ngModel.$setValidity('date', true);
								return date;
							}
						} else {
							ngModel.$setValidity('date', false);
							return undefined;
						}
					}

					ngModel.$parsers.unshift(parseDate);

					// Inner change
					scope.dateSelection = function() {
						ngModel.$setViewValue(scope.date);
						ngModel.$render();

						if (closeOnDateSelection) {
							setOpen(false);
						}
					};

					element.bind('input change keyup', function() {
						scope.$apply(function() {
							updateCalendar();
						});
					});

					// Outter change
					ngModel.$render = function() {
						let date = ngModel.$viewValue ? dateFilter(ngModel.$viewValue, dateFormat) : '';
						element.val(date);

						updateCalendar();
					};

					function updateCalendar() {
						scope.date = ngModel.$modelValue;
						updatePosition();
					}

					function addWatchableAttribute(attribute, scopeProperty, calendarAttribute) {
						if (attribute) {
							originalScope.$watch($parse(attribute), function(value) {
								scope[scopeProperty] = value;
							});
							calendarEl.attr(calendarAttribute || scopeProperty, scopeProperty);
						}
					}

					addWatchableAttribute(attrs.min, 'min');
					addWatchableAttribute(attrs.max, 'max');
					addWatchableAttribute(attrs.range, 'range');
					if (attrs.showWeeks) {
						addWatchableAttribute(attrs.showWeeks, 'showWeeks', 'show-weeks');
					} else {
						scope.showWeeks = calendarConfig.showWeeks;
						calendarEl.attr('show-weeks', 'showWeeks');
					}
					if (attrs.dateDisabled) {
						calendarEl.attr('date-disabled', attrs.dateDisabled);
					}

					function updatePosition() {
						scope.position = appendToBody ? $position.offset(element) : $position.position(element);
						scope.position.top = scope.position.top + element.prop('offsetHeight');
					}

					let documentBindingInitialized = false,
						elementFocusInitialized = false;
					scope.$watch('isOpen', function(value) {
						if (value) {
							updatePosition();
							$document.bind('click', documentClickBind);
							if (elementFocusInitialized) {
								element.unbind('focus', elementFocusBind);
							}
							element[0].focus();
							documentBindingInitialized = true;
						} else {
							if (documentBindingInitialized) {
								$document.unbind('click', documentClickBind);
							}
							element.bind('focus', elementFocusBind);
							elementFocusInitialized = true;
						}

						if (setIsOpen) {
							setIsOpen(originalScope, value);
						}
					});

					let $setModelValue = $parse(attrs.ngModel).assign;

					scope.today = function() {
						$setModelValue(originalScope, new Date());
					};
					scope.clear = function() {
						$setModelValue(originalScope, null);
					};

					let $popup = $compile(popupEl)(scope);
					if (appendToBody) {
						$document.find('body').append($popup);
					} else {
						element.after($popup);
					}
				},
			};
		},
	])

	.directive('calendarPopupWrap', function() {
		return {
			restrict: 'EA',
			replace: true,
			transclude: true,
			templateUrl: 'common/calendar/datepicker-popup.html',
			link: function(scope, element) {
				element.bind('click', function(event) {
					event.preventDefault();
					event.stopPropagation();
				});
			},
		};
	});
