/* eslint quotes: [0, 'single'] */
/* eslint eqeqeq: [0, 'always'] */
/* eslint semi: [0, 'always'] */
/* eslint one-var: 0 */

/**
 * Angucomplete
 * Autocomplete directive for AngularJS
 * By Daryl Rowland
 */

angular.module('angucomplete', []).directive('angucomplete', function($parse, $http, $sce, $timeout, uiStateService) {
	return {
		restrict: 'EA',
		scope: {
			id: '@id',
			placeholder: '@placeholder',
			selectedObject: '=selectedobject',
			searchModel: '=searchtext',
			url: '@url',
			dataField: '@datafield',
			titleField: '@titlefield',
			descriptionField: '@descriptionfield',
			imageField: '@imagefield',
			imageUri: '@imageuri',
			inputClass: '@inputclass',
			userPause: '@pause',
			userSearchPause: '@searchpause',
			localData: '=localdata',
			searchFields: '@searchfields',
			minLengthUser: '@minlength',
			matchClass: '@matchclass',
			disabled: '=disabled',
			uiStateKey: '=?uiStateKey',
			onBlur: '&',
		},
		template:
			'<div class="angucomplete-holder">' +
			'<input id="{{id}}_value" ng-model="searchModel.searchText" type="text" ' +
			' placeholder="{{placeholder}}" ng-disabled="disabled" ng-class="{\'disabled\': disabled}" ' +
			'onmouseup="this.select();" ng-focus="resetHideResults()" ng-blur="hideResults();onBlur()" />' +
			'<div class="icon icon-search"></div><div class="clear icon icon-critical" ng-click="clearInput()"></div>' +
			'<div id="{{id}}_dropdown" class="angucomplete-dropdown" ' +
			'ng-if="showDropdown"><div class="angucomplete-row" ng-repeat="result in results" ' +
			'ng-click="selectResult(result)" ng-mouseover="hoverRow()" ' +
			'ng-class="{\'angucomplete-selected-row\': $index == currentIndex}">' +
			'<div ng-if="imageField" class="angucomplete-image-holder">' +
			'<img ng-if="result.image && result.image != \'\'" ng-src="{{result.image}}" ' +
			'class="angucomplete-image"/><div ng-if="!result.image && result.image != \'\'" ' +
			'class="angucomplete-image-default"></div></div><div class="angucomplete-title" ng-if="matchClass" ' +
			'ng-bind-html="result.title"></div>' +
			'<div class="angucomplete-title" ng-if="!matchClass">{{ result.title }}</div>' +
			'<div ng-if="result.description && result.description != \'\'" class="angucomplete-description">' +
			'{{result.description}}</div></div></div></div>',

		link: function($scope, elem, attrs) {
			$scope.lastSearchTerm = null;
			$scope.currentIndex = null;
			$scope.justChanged = false;
			$scope.searchTimer = null;
			$scope.hideTimer = null;
			$scope.searching = false;
			$scope.pause = 200;
			$scope.closePause = 500;
			$scope.searchPause = 500;
			$scope.minSearchPause = 50;
			$scope.minLength = 3;
			$scope.searchModel = $scope.searchModel || {searchText: ''};
			$scope.searchStr = $scope.searchModel.searchText;
			let inputField;

			if (!$scope.uiStateKey) {
				$scope.uiStateKey = attrs.uistatekey ? attrs.uistatekey : null;
			}
			if ($scope.uiStateKey) {
				$scope.uiStateSearchTextKey = $scope.uiStateKey + '.searchText';
				if (uiStateService.stateExists($scope.uiStateSearchTextKey)) {
					$scope.searchModel.searchText = uiStateService.getState($scope.uiStateSearchTextKey);
				}
			}

			if ($scope.minLengthUser && $scope.minLengthUser != '') {
				$scope.minLength = $scope.minLengthUser;
			}

			if ($scope.userPause) {
				$scope.pause = $scope.userPause;
			}

			if ($scope.userSearchPause) {
				$scope.searchPause = $scope.userSearchPause;
			}

			if ($scope.inputClass) {
				elem.find('input').addClass($scope.inputClass);
			}

			let isNewSearchNeeded = function(newTerm, oldTerm) {
				return newTerm.length >= $scope.minLength && newTerm != oldTerm;
			};

			$scope.processResults = function(responseData, str) {
				if (responseData && responseData.length > 0) {
					$scope.results = [];

					let titleFields = [];
					if ($scope.titleField && $scope.titleField != '') {
						titleFields = $scope.titleField.split(',');
					}

					for (let i = 0; i < responseData.length; i++) {
						// Get title variables
						let titleCode = [];

						for (let t = 0; t < titleFields.length; t++) {
							titleCode.push(responseData[i][titleFields[t]]);
						}

						let description = '';
						if ($scope.descriptionField) {
							description = responseData[i][$scope.descriptionField];
						}

						let imageUri = '';
						if ($scope.imageUri) {
							imageUri = $scope.imageUri;
						}

						let image = '';
						if ($scope.imageField) {
							image = imageUri + responseData[i][$scope.imageField];
						}

						let text = titleCode.join(' ');
						if ($scope.matchClass) {
							let re = new RegExp(str, 'i');
							let strPart = text.match(re)[0];
							text = $sce.trustAsHtml(text.replace(re, '<span class="' + $scope.matchClass + '">' + strPart + '</span>'));
						}

						let resultRow = {
							title: text,
							description: description,
							image: image,
							originalObject: responseData[i],
						};

						$scope.results[$scope.results.length] = resultRow;
					}
					if ($scope.results.length) {
						$scope.showDropdown = true;
					}
				} else {
					$scope.results = [];
				}
			};

			$scope.searchTimerComplete = function(str) {
				// Begin the search
				if (!$scope.localData) $scope.localData = [];

				if (str.length >= $scope.minLength) {
					let searchFields = $scope.searchFields.split(',');

					let matches = [];

					for (let i = 0; i < $scope.localData.length; i++) {
						let match = false;

						for (let s = 0; s < searchFields.length; s++) {
							match =
								match ||
								(typeof $scope.localData[i][searchFields[s]] === 'string' &&
									typeof str === 'string' &&
									$scope.localData[i][searchFields[s]].toLowerCase().indexOf(str.toLowerCase()) === 0);
						}

						if (match) {
							matches[matches.length] = $scope.localData[i];
						}
					}

					$scope.searching = false;
					$scope.processResults(matches, str);
				}
			};

			$scope.clearInput = function() {
				$scope.searchStr = '';
				$scope.searchModel.searchText = '';
				inputField[0].focus();
				updateSearchModel();
			};

			$scope.hideResults = function() {
				$scope.hideTimer = $timeout(function() {
					$scope.showDropdown = false;
				}, $scope.closePause);
			};

			$scope.resetHideResults = function() {
				if ($scope.hideTimer) {
					$timeout.cancel($scope.hideTimer);
				}
			};

			$scope.hoverRow = function(index) {
				$scope.currentIndex = index;
			};

			$scope.keyPressed = function(event) {
				if (!(event.which == 38 || event.which == 40 || event.which == 13)) {
					const searchStr = $scope.searchModel.searchText;
					if (!searchStr || searchStr == '') {
						$scope.showDropdown = false;
						$scope.lastSearchTerm = null;
					} else if (isNewSearchNeeded(searchStr, $scope.lastSearchTerm)) {
						$scope.lastSearchTerm = searchStr;
						$scope.showDropdown = false;
						$scope.currentIndex = -1;
						$scope.results = [];

						if ($scope.searchTimer) {
							$timeout.cancel($scope.searchTimer);
						}

						$scope.searching = true;

						$scope.searchTimer = $timeout(function() {
							$scope.searchTimerComplete(searchStr);
						}, $scope.pause);
					}
					updateSearchModel($scope.searchPause);
				} else {
					event.preventDefault();
				}
			};

			$scope.selectResult = function(result) {
				if ($scope.matchClass) {
					result.title = result.title.toString().replace(/(<([^>]+)>)/gi, '');
				}
				$scope.searchStr = $scope.lastSearchTerm = result.title;
				$scope.searchModel.searchText = $scope.searchStr;
				$scope.selectedObject = result;
				$scope.showDropdown = false;
				$scope.results = [];
				updateSearchModel();
				// $scope.$apply();
			};

			let updateSearchModelTimer = null,
				addSearchDataTimer = null;

			function updateSearchModel(pause) {
				$timeout.cancel(updateSearchModelTimer);
				$timeout.cancel(addSearchDataTimer);

				updateSearchModelTimer = $timeout(function() {
					$scope.searchStr = $scope.searchModel.searchText;
					if ($scope.uiStateSearchTextKey) {
						uiStateService.registerState($scope.uiStateSearchTextKey, $scope.searchModel.searchText);
					}

					addSearchDataTimer = $timeout(addSearchData, 2500);
				}, pause || $scope.minSearchPause);
			}

			function addSearchData() {
				if (
					$scope.localData &&
					!$scope.localData.some(function(value) {
						return value.text == $scope.searchModel.searchText;
					})
				) {
					$scope.localData.push({text: $scope.searchModel.searchText});
				}
			}

			inputField = elem.find('input');

			inputField.on('keyup', $scope.keyPressed);

			elem.on('keyup', function(event) {
				if (event.which === 40) {
					if ($scope.results && $scope.currentIndex + 1 < $scope.results.length) {
						$scope.currentIndex++;
						$scope.$apply();
						event.preventDefault;
						event.stopPropagation();
					}

					$scope.$apply();
				} else if (event.which == 38) {
					if ($scope.currentIndex >= 1) {
						$scope.currentIndex--;
						$scope.$apply();
						event.preventDefault;
						event.stopPropagation();
					}
				} else if (event.which == 13) {
					if ($scope.results && $scope.currentIndex >= 0 && $scope.currentIndex < $scope.results.length) {
						$scope.selectResult($scope.results[$scope.currentIndex]);
						$scope.$apply();
						event.preventDefault;
						event.stopPropagation();
					} else {
						$scope.results = [];
						$scope.$apply();
						// event.preventDefault;
						// event.stopPropagation();
					}
				} else if (event.which == 27) {
					$scope.results = [];
					$scope.showDropdown = false;
					$scope.$apply();
				} else if (event.which == 8) {
					$scope.selectedObject = null;
					$scope.$apply();
				}
			});
		},
	};
});
