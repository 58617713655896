import './my-views-group-rule.scss';

const services = new WeakMap();

class MyViewsGroupRuleController {
	constructor(helpers) {
		services.set(this, {helpers});
		this.isVariantsDropdownMenuOpened = false;
		this.modes = {
			ADD: 'add',
			REMOVE: 'remove',
		};
	}

	toggleVariantsDropdownMenu(isOpened) {
		this.isVariantsDropdownMenuOpened = isOpened;
	}

	checkIsRuleFilled() {
		const {helpers} = services.get(this);
		const {rule} = this;
		return rule.name && rule.criteria && helpers.isNumber(rule.operator) && rule.operator > -1;
	}
}

export default {
	templateUrl: 'components/my-views/my-views-group/my-views-group-rule/my-views-group-rule.html',
	controller: MyViewsGroupRuleController,
	bindings: {
		index: '<',
		mode: '@',
		last: '<',
		listElements: '<',
		dimensions: '<',
		rule: '<',
		removeRule: '&',
		addRule: '&',
	},
};
