(function(LineChartRenderer) {
	const CONTROL_RANGE_LABEL_X_OFFSET = 5;
	const CONTROL_RANGE_LABEL_Y_OFFSET = -11;
	const CONTROL_RANGE_SHADE_OPACITY = '0.07';

	class LineChartWithControlRangeRenderer extends LineChartRenderer {
		constructor(svg, externalMethods, clipPathId, x, xAxis) {
			super(svg, externalMethods, clipPathId, x, xAxis);
		}

		drawControlRangeShades(chartOptions, chartData, controlBoundariesLayer) {
			let that = this;
			let controlRangeBoundaryParamY = 0;

			function byControlRangeLineWithData(line, i) {
				return line.isControlRangeBoundary && chartData[i] && chartData[i][0];
			}

			function appendControlRangeShadeToSVG(line, i) {
				let boundaryValue = chartData[i][0];
				let yScale = that.yAxes.find(axis => axis.id === line.chartAxisId);
				let shadeHeight = yScale.y(parseFloat(boundaryValue.y)) - controlRangeBoundaryParamY;

				if (shadeHeight < 0 || isNaN(shadeHeight)) {
					shadeHeight = 0;
				}

				if (line.visible) {
					controlBoundariesLayer
						.append('rect')
						.datum(boundaryValue)
						.attr('fill', line.color)
						.attr('fill-opacity', CONTROL_RANGE_SHADE_OPACITY)
						.attr('class', 'control-range-shade axis-' + yScale.index)
						.attr('x', 0)
						.attr('y', controlRangeBoundaryParamY)
						.attr('width', that.x(that.x.domain()[1]))
						.attr('height', shadeHeight);
				}

				controlRangeBoundaryParamY = yScale.y(parseFloat(boundaryValue.y)) || 0;

				if (controlRangeBoundaryParamY < 0) {
					controlRangeBoundaryParamY = 0;
				}
			}

			chartOptions.lines.filter(byControlRangeLineWithData).forEach(appendControlRangeShadeToSVG);
		}

		drawControlRangeBoundary(line, data, yScale, controlBoundariesLayer) {
			let that = this;
			let boundaryValue = data[0];

			controlBoundariesLayer
				.append('line')
				.datum(boundaryValue)
				.attr('x1', 0)
				.attr('y1', d => yScale.y(d.y))
				.attr('x2', that.x(that.x.domain()[1]))
				.attr('y2', d => yScale.y(d.y))
				.attr('class', 'line axis-' + yScale.index + ' control-range')
				.attr('stroke', line.color);

			controlBoundariesLayer
				.append('text')
				.attr('class', 'control-range-title axis-' + yScale.index)
				.data(data)
				.attr('x', 0)
				.attr('y', d => yScale.y(d.y))
				.attr('dx', CONTROL_RANGE_LABEL_X_OFFSET)
				.attr('dy', CONTROL_RANGE_LABEL_Y_OFFSET)
				.attr('text-anchor', 'start')
				.text(line.name);
		}
	}

	window.LineChartWithControlRangeRenderer = LineChartWithControlRangeRenderer;
})(window.LineChartRenderer);
