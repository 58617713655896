(function() {
	class PageHeaderController {
		constructor() {}
	}

	angular.module('TISCC').component('cprPageHeaderComponent', {
		templateUrl: 'components/reports/chiller-performance/page/page-header/page-header-component.html',
		controller: PageHeaderController,
		bindings: {
			isMain: '<',
			facilityName: '<',
			addressLine: '<',
			startTimePeriod: '<',
			endTimePeriod: '<',
			reportCreatedDate: '<',
			chillerName: '<',
			placeHolder: '<',
		},
	});
})();
