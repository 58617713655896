export const USER_EVENTS = {
	REPORT_GENERATION: {
		categoryName: 'TCC4 Reports',
		events: {
			GENERATE_REPORT: 'Generate Report',
			EDIT_FACILITY_FOR_REPORT: 'Edit Facility for Report',
		},
		properties: {
			// Common
			REPORT: 'Report',
			TIME_PERIOD: 'Time Period',
			EQUIPMENT_TYPE: 'Equipment Type',
			BUILDING_ID: 'BuildingID',
			BUILDING_SALES_OFFICE: 'Building Sales Office',
			BUILDING_OFFERINGS: 'Building Offerings',
			// Common - performance tracking
			FROM_DATE: 'From Date',
			TO_DATE: 'To Date',
			TOTAL_TIME: 'Total Time',
			REPORT_GENERATION_STATUS: 'Report Generation Status',

			// CPR
			LANGUAGE: 'Language',
			UNIT_OF_MEASURE: 'Unit of Measure',
			SECTIONS_INCLUDED: 'Sections Included',
			START_DATE: 'Start Date',
			FROM: 'From',
			TO: 'To',

			// EHR
			EXCEPTIONS_INCLUDED: 'Exceptions Included',

			// AER
			DEVICES_INCLUDED: 'Devices Included',

			// RDR
			PROPERTIES: 'Properties',
			FORMAT: 'Format',
			INCLUDE_WEATHER_DATA: 'Include weather data',
			INCLUDE_OUT_OF_RANGE: 'Include Out of Range',
		},
	},
};
