angular.module('TISCC').controller('ChartConfiguratorCtrl', ChartConfiguratorCtrl);

function ChartConfiguratorCtrl($scope, objectTypeService, dimensionsService, facilityService, locationEquipmentService) {
	objectTypeService.getObjectTypes(function(data) {
		$scope.objectTypes = data.tisObjectTypeList;
	});
	dimensionsService.getDimensions(function(data) {
		$scope.dimensions = data.dimensionList;
	});
	$scope.$watch('objectType', function(objectType) {
		if (objectType) {
			objectTypeService.getObjectProperties(objectType.tisProductFamilyId).then(function(data) {
				$scope.objectProperties = data.data.tisObjectPropertyList;
			});
		}
	});
	$scope.timeNumber = 1;
	$scope.checkDualAxis = true;

	$scope.facilities = facilityService.getFacilitiesAsync(function() {});
	$scope.$watch('facility', function(facility) {
		$scope.previewObject = null;
		if (facility) {
			let hardcodedLocationId = 1898;
			// TODO: use facility.locationId when API will have data

			locationEquipmentService.getLocationObjectsList(hardcodedLocationId).then(function(data) {
				$scope.previewObjects = data.tisObjectList;
			});
		}
	});
}
