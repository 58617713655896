import {USER_EVENTS} from '../common/usage-tracking/categories';

const {
	ERROR_CAPTURE_EVENTS: {events: ERROR_CAPTURE_EVENTS_EVENTS, properties: ERROR_CAPTURE_EVENTS_PROPERTIES, categoryName: ERROR_CAPTURE_EVENTS_CATEGORY_NAME},
} = USER_EVENTS;
/**
 * Service which provides useful functions.
 */
angular.module('TISCC').service('errorHandler', function(modalHelperService, usageTrackingService) {
	let that = this;
	const API_COMPONENTS = ['api', 'analytics-api', 'cmsa-api', 'security-api', 'export'];

	that.showErrorModal = function(httpData) {
		if (httpData.singleReason) {
			showErrorMessage(httpData.singleReason);
		} else {
			showErrorMessage(createTechDetails(httpData));
		}
		amplitudeErrorCapture(httpData);
	};

	function amplitudeErrorCapture(httpData) {
		const {status, statusText, config: {url: fullUrl, method, params}} = httpData;
		const apiEndpoint = fullUrl.includes('?') ? fullUrl.split('?')[0] : fullUrl;
		const apiComponent = getAPIComponentFromUrl(fullUrl);
		const pageUrl = window.location.href;

		usageTrackingService.trackEvent(ERROR_CAPTURE_EVENTS_CATEGORY_NAME, ERROR_CAPTURE_EVENTS_EVENTS.CC4_API_ALERTS, {
			[ERROR_CAPTURE_EVENTS_PROPERTIES.STATUS]: status,
			[ERROR_CAPTURE_EVENTS_PROPERTIES.STATUS_TEXT]: status === -1 ? 'Request timeout' : statusText,
			[ERROR_CAPTURE_EVENTS_PROPERTIES.HTTP_METHOD]: method,
			[ERROR_CAPTURE_EVENTS_PROPERTIES.CURRENT_PAGE]: pageUrl,
			[ERROR_CAPTURE_EVENTS_PROPERTIES.URL]: fullUrl,
			[ERROR_CAPTURE_EVENTS_PROPERTIES.API_ENDPOINT]: apiEndpoint,
			[ERROR_CAPTURE_EVENTS_PROPERTIES.API_COMPONENET]: apiComponent,
			[ERROR_CAPTURE_EVENTS_PROPERTIES.PARAMS]: params,
		});
	}

	function getAPIComponentFromUrl(url) {
		return API_COMPONENTS.find(item => {
			if (url.includes(`/${item}`)) {
				return item;
			}
		});
	}

	function showErrorMessage(message) {
		modalHelperService.open({
			templateUrl: 'common/messages/error-message.html',
			controller: 'ErrorMessageCtrl',
			backdrop: 'static',
			resolve: {
				details: () => message,
			},
		});
	}

	function createTechDetails(httpData) {
		const techDetails = JSON.parse(JSON.stringify(httpData));
		techDetails.responseHeaders = httpData.headers();

		if (httpData.headers('content-type') === 'text/html') {
			techDetails.data = getTextContentFromHtmlString(techDetails.data);
		}

		delete techDetails.config.headers.Authorization;
		return techDetails;
	}

	function getTextContentFromHtmlString(htmlString) {
		const template = document.createElement('template');
		template.innerHTML = htmlString;
		return template.content.textContent.replace(/\s+/g, ' ');
	}
});
