import _get from 'lodash.get';

angular
	.module('TISCC')
	.service('digitalInspectionService', function(
		$http,
		$rootScope,
		$q,
		$log,
		cacheService,
		tisObjectService,
		AUTOMATED_TEST_LEVELS,
		locationDetailsService,
		CHART_DATE_FORMAT,
		CHART_TYPE_LEVEL,
		helpers
	) {
		this.getActions = function(callback) {
			$http.get('translations/en-US/automated-test-suggestions.json').success(callback);
		};

		this.getDigitalInspections = function(locationId, organizationId, tisObjectIds, serviceAdvisoryTypeIds, range, enableSuppressions, callback) {
			const serviceAdvisoryTypeIDsToList = !Array.isArray(serviceAdvisoryTypeIds) ? serviceAdvisoryTypeIds.split(',') : serviceAdvisoryTypeIds;
			const serviceAdvisoryTypeIDsChunks = helpers.chunks(serviceAdvisoryTypeIDsToList, 3);
			const startTime = new Date().getTime();
			$log.log('started at ', new Date());
			return $q
				.all(
					serviceAdvisoryTypeIDsChunks.map(serviceAdvisoryTypeIDsChunk => {
						let config = {};
						const timezone = locationDetailsService.getLocationTimezone();
						range.from = moment(range.from)
							.tz(timezone)
							.startOf('day');
						range.to = moment(range.to)
							.tz(timezone)
							.startOf('day');
						const now = moment().tz(timezone);
						if (range.to.isAfter(now, 'day')) {
							range = {
								...range,
								to: moment(now),
							};
						}
						const startDate = moment.utc(range.from).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'); // Should be ISO 8601 format in UTC timezone
						const endDate = moment.utc(range.to).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'); // Should be ISO 8601 format in UTC timezone
						let cacheKey = [tisObjectIds, serviceAdvisoryTypeIDsChunk, range.from.format('x'), range.to.format('x')].join('/');
						config.params = {
							locationId,
							organizationId,
							startDate,
							endDate,
							enableSuppressions: enableSuppressions,
							saIdRef: serviceAdvisoryTypeIDsChunk[0],
						};
						config.headers = {
							tisObjectIds: tisObjectIds,
							// If there is no serviceAdvisoryTypeIds API accepts 0 as non-value
							serviceAdvisoryTypeIds: serviceAdvisoryTypeIDsChunk.join(',') || 0,
						};

						config.cache = cacheService.getCacheInstance('digitalInspectionService', 'digitalInspections', cacheKey);
						let request = $http.get('/ext_api/cmsa-api/failurerate', config);
						return request.then(response => response);
					})
				)
				.then(responses => {
					$log.log('finished at ', `${(new Date().getTime() - startTime) / 1000} s`);
					return {failureRateList: helpers.flattenDeep(responses.map(res => res.data.failureRateList))};
				});
		};

		// Currently the only source of suggestions is JSON file (src/js/translations/automated-test-suggestion-[en/uk].json)
		this.generateGetActionsFunction = function(actions) {
			return ({id, returnDefault = false}) => {
				if (returnDefault) {
					return actions.default;
				}
				return _get(actions, id, null);
			};
		};
	});
