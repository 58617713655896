import _get from 'lodash.get';
import {CPR_DATE_FORMATS} from '../../../chiller-performance-report-config';

(function() {
	angular.module('TISCC').directive('cprHeatmap', helpers => {
		return {
			restrict: 'A',
			scope: {
				fullData: '<',
				additionalXLine: '<',
				numberDaysInMonth: '<',
				heatMapDataThresholds: '<',
				heatMapDataColors: '<',
			},
			link: (scope, $element) => {
				drawHeatMap(Object.assign({$element, helpers}, scope));
			},
		};
	});

	function drawHeatMap({$element, helpers, fullData, numberDaysInMonth, heatMapDataThresholds, heatMapDataColors, additionalXLine}) {
		const numberHoursInDay = 24;
		const margin = {top: 20, left: 25};
		const xAxisCellHeight = 15;
		const xAxisCellColor = '#E6E7E7';
		const xAxisCellColorWeekEnd = '#BABABA';
		const weekEnds = ['Sat', 'Sun'];
		const yTickColor = '#B7B8BA';
		const cellHeight = 10;
		const cellMargin = 1;
		const svgWidth = $element[0].clientWidth;
		const svgHeight = numberHoursInDay * cellHeight + (additionalXLine && additionalXLine.data ? xAxisCellHeight * 3 : 0) + margin.top;
		const cellWidth = (svgWidth - margin.left) / numberDaysInMonth;
		const yAxisWidth = margin.left;
		const characterWidth = 2.5;

		const additionalXLineTitles = helpers.splitWords(additionalXLine ? additionalXLine.title() : '', characterWidth, yAxisWidth);

		const heatMapData = fullData.map(item => {
			const newItem = {};

			newItem.date = `${item.x.format(CPR_DATE_FORMATS.MEDIUM_DAY_FORMAT)}_${item.x.format(CPR_DATE_FORMATS.SHORT_DAY_FORMAT)}`;
			newItem.time = `${item.x.format(CPR_DATE_FORMATS.TIME_FORMAT_HH_MM)}`;
			newItem.value = item.y;

			return newItem;
		});

		const xElements = d3.set(heatMapData.map(item => item.date)).values();
		const yElements = d3.set(heatMapData.map(item => item.time)).values();
		if (additionalXLine && additionalXLine.titleKey) {
			yElements.push(additionalXLine.titleKey);
		}

		const xScale = d3.scale
			.ordinal()
			.domain(xElements)
			.rangeBands([0, numberDaysInMonth * cellWidth]);

		const yScale = d3.scale
			.ordinal()
			.domain(yElements)
			.rangeBands([0, numberHoursInDay * cellHeight]);

		const colorScale = d3.scale
			.threshold()
			.domain(heatMapDataThresholds)
			.range(heatMapDataColors);

		// x-axis labels must be shown as column as first letter of the day name and the number of the day.
		// example:
		// S
		// 1
		// That's why there are two x-axis. One for the first letter of the day, and the second is the number of the day.
		const xAxisDay = d3.svg
			.axis()
			.scale(xScale)
			.tickFormat(d => d.split('_')[0])
			.tickSize(0)
			.tickPadding(8)
			.orient('top');

		const xAxisNumber = d3.svg
			.axis()
			.scale(xScale)
			.tickFormat(d => d.split('_')[1])
			.tickSize(0)
			.tickPadding(2)
			.orient('top');

		const xAxisWeather = d3.svg
			.axis()
			.scale(xScale)
			.tickFormat(d => {
				return _get(additionalXLine, `data.${d}.coolingDegreeDays`, 0);
			})
			.tickSize(0)
			.tickPadding(2)
			.orient('bottom');

		const yAxis = d3.svg
			.axis()
			.scale(yScale)
			.tickFormat(d => {
				return additionalXLine && additionalXLine.titleKey === d ? additionalXLineTitles[0] : d;
			})
			.tickSize(0)
			.tickValues(yElements)
			.orient('left');

		const svg = d3
			.select($element[0])
			.append('svg')
			.attr('width', svgWidth)
			.attr('height', svgHeight)
			.append('g')
			.attr('class', 'data heatmap')
			.attr('transform', `translate(${margin.left}, ${margin.top})`);

		svg
			.append('g')
			.selectAll('rect')
			.data(heatMapData)
			.enter()
			.append('rect')
			.attr('width', cellWidth - cellMargin)
			.attr('height', cellHeight - cellMargin)
			.attr('y', d => yScale(d.time))
			.attr('x', d => xScale(d.date))
			.attr('fill', d => colorScale(d.value));

		const yAxisTicks = svg
			.append('g')
			.attr('class', 'y axis')
			.call(yAxis);

		if (additionalXLineTitles.length > 1) {
			additionalXLineTitles.forEach((text, index) => {
				if (index) {
					yAxisTicks
						.selectAll('g.tick')
						.filter(function(d) {
							return additionalXLine && additionalXLine.titleKey === d;
						})
						.append('text')
						.text(text)
						.attr('x', -3)
						.attr('y', (cellHeight - 2) * index)
						.attr('dy', '.32em')
						.attr('letter-spacing', '-0.3')
						.style('text-anchor', 'end');
				}
			});
		}

		yAxisTicks
			.selectAll('g.tick text')
			.attr('x', -yAxisWidth)
			.style('text-anchor', 'start');

		yAxisTicks
			.selectAll('g.tick line')
			.filter(function(d) {
				return !additionalXLine || additionalXLine.titleKey !== d;
			})
			.attr('x1', 0)
			.attr('y1', cellHeight / 2)
			.attr('x2', '-100%')
			.attr('y2', cellHeight / 2)
			.attr('stroke', yTickColor)
			.style('stroke', yTickColor);

		const xDays = svg
			.append('g')
			.attr('class', 'x axis')
			.call(xAxisDay);

		// The x-axis labels must have the background.
		xDays
			.selectAll('g.tick')
			.insert('rect', ':first-child')
			.attr('fill', d => (weekEnds.includes(d.split('_')[0]) ? xAxisCellColorWeekEnd : xAxisCellColor))
			.attr('x', -(cellWidth / 2))
			.attr('y', -xAxisCellHeight)
			.attr('width', cellWidth - cellMargin)
			.attr('height', xAxisCellHeight);

		xDays.selectAll('text').attr('x', -(cellMargin / 2));

		const xNumbers = svg
			.append('g')
			.attr('class', 'x axis')
			.call(xAxisNumber);

		xNumbers.selectAll('text').attr('x', -(cellMargin / 2));

		if (additionalXLine && additionalXLine.data) {
			const xWeatherHeigth = (cellHeight - 2) * additionalXLineTitles.length;
			const xWeather = svg
				.append('g')
				.attr('class', 'x axis weather')
				.attr('transform', `translate(0, ${yScale('24:00')})`)
				.call(xAxisWeather);

			xWeather
				.selectAll('g.tick')
				.insert('rect', ':first-child')
				.attr('fill', xAxisCellColor)
				.attr('x', -(cellWidth / 2))
				.attr('y', 0)
				.attr('width', cellWidth - cellMargin)
				.attr('height', xWeatherHeigth);

			xWeather.selectAll('g.tick text').attr('y', xWeatherHeigth / 2 - 4);
		}
	}
})();
