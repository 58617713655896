/* eslint no-control-regex: "off"*/

/**
 * Service which provides useful functions.
 */
angular.module('TISCC').service('helpers', function(TRANE_DOMAINS) {
	let that = this;

	/**
	 * Executes function 'func' only when it will be called n + 1 times
	 *
	 * @param n number of times to ignore calls before wrapped function will be executed
	 * @param func function to wrap
	 * @param cb called when counter n was decremented
	 * @returns {Function} wrapper over func
	 */
	that.after = function(n, func, cb) {
		return function() {
			return n < 1 ? func() : cb(--n);
		};
	};

	let ARRAY_NOTATION_PATTERN = new RegExp('^(\\w|\\$)+\\[\\d\\]$');

	/**
	 * Safely extracts property by propPath from composite objects chain.
	 *
	 * @param obj target object
	 * @param propPath path to nested property
	 * @returns value or void value;
	 */
	that.getPropertyByPath = function(obj, propPath) {
		return propPath.split('.').reduce(function(o, pathPart) {
			if (typeof o === 'undefined' || o === null) {
				return o;
			} else if (ARRAY_NOTATION_PATTERN.test(pathPart)) {
				// [arrayProp, arrayPropIndex]
				let p = pathPart.split(/\[|]/);
				let val = o[p[0]];
				return Array.isArray(val) ? val[p[1]] : void 0;
			}

			return o[pathPart];
		}, obj);
	};

	/**
	 * Checks if composite object contains property by propPath.
	 *
	 * @param obj target object
	 * @param propPath path to nested property
	 * @returns {boolean};
	 */
	that.objectHasProperty = function(obj, propPath) {
		let prop = that.getPropertyByPath(obj, propPath);
		return typeof prop !== 'undefined' && prop !== null;
	};

	/** Returns a function, that, as long as it continues to be invoked, will not
	 be triggered. The function will be called after it stops being called for
	 N milliseconds. If `immediate` is passed, trigger the function on the
	 leading edge, instead of the trailing.
	 */
	that.debounce = function(func, wait, immediate) {
		let timeout;

		return function() {
			let context = this;
			let args = arguments;

			let later = function() {
				timeout = null;

				if (!immediate) {
					func.apply(context, args);
				}
			};

			let callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);

			if (callNow) {
				func.apply(context, args);
			}
		};
	};

	/**
	 * Removes duplicated values from array.
	 *
	 * @param array
	 * @param fieldExtractor
	 * @returns {*} array with unique values.
	 */
	that.arrayUnique = function(array, fieldExtractor = f => f) {
		const seen = new Set();

		return array.filter(x => {
			const field = fieldExtractor(x);

			if (seen.has(field)) {
				return false;
			}

			seen.add(field);
			return true;
		});
	};

	/**
	 * Tries to convert string to number.
	 *
	 * @param val
	 * @returns {*} null if value can't be represented as number or instance of Number;
	 */
	that.asNumber = function(val) {
		if (val === null || isNaN(val)) {
			return null;
		}

		return Number(val);
	};

	that.reduceArrayToSet = function(array, valueExtractor) {
		return array.reduce(function(set, current) {
			set[valueExtractor ? valueExtractor(current) : current] = true;
			return set;
		}, {});
	};

	that.mapToObject = function(arr, f) {
		let map = {};

		(arr || []).forEach(function(el) {
			let kv = f(el);
			map[kv[0]] = kv[1];
		});

		return map;
	};

	/**
	 *
	 * @param scope AngularJS scope object.
	 * @param key property key to search.
	 * @returns {*} value by key.
	 */
	that.findInScopeChain = function(scope, key) {
		if (scope === null) {
			throw new Error(`Can't find '${key}' in scope chain!`);
		}

		if (key in scope) {
			return scope[key];
		}

		return that.findInScopeChain(scope.$parent, key);
	};

	/**
	 *
	 * @param a {Array} first array
	 * @param b {Array} second array
	 * @param equalFunction if it wasn't passed entries will be compared by triple equals.
	 * @returns {boolean}
	 */
	that.arrayEquals = function(a, b, equalFunction = (a, b) => a === b) {
		if (a === b) {
			return true;
		}

		if (!a !== !b || a.length !== b.length) {
			return false;
		}

		for (let i = 0; i < a.length; ++i) {
			if (!equalFunction(a[i], b[i])) {
				return false;
			}
		}

		return true;
	};

	/**
	 *
	 * @param targetArray
	 * @param entriesToRemove
	 * @param f optional value extractor
	 * @returns {*}
	 */
	that.arrayRemoveIntersections = function(targetArray, entriesToRemove, f = e => e) {
		const set = new Set(entriesToRemove.map(f));
		return targetArray.filter(entry => !set.has(f(entry)));
	};
	/**
	 *
	 * @param from {moment}
	 * @param to {moment}
	 * @param fromString {String}
	 * @param toString {String}
	 * @param dateFormat {String}
	 * @returns {boolean}
	 */
	that.checkUrlRangeFormat = function(from, to, fromString, toString, dateFormat) {
		const fromDateIsValid = from !== null && from.isValid() && from.format(dateFormat) === fromString;
		const toDateIsValid = to !== null && to.isValid() && to.format(dateFormat) === toString;

		return fromDateIsValid && toDateIsValid;
	};
	/**
	 *
	 * @param from {moment}
	 * @param to {moment}
	 * @param timezone {String}
	 * @returns {boolean}
	 */
	that.checkUrlRangeValidity = function(from, to, timezone) {
		const now = moment().tz(timezone);

		return to.isSameOrAfter(from) && from.isSameOrBefore(now) && to.isSameOrBefore(now);
	};
	/**
	 * Copies text to clipboard.
	 *
	 * @param text {String}
	 */
	that.copyTextToClipboard = function(text) {
		const textArea = document.createElement('textarea');

		textArea.classList.add('copy-to-clipboard');
		textArea.value = text;

		document.body.appendChild(textArea);

		textArea.select();

		document.execCommand('copy');

		document.body.removeChild(textArea);
	};
	/**
	 * Sanitize a string to be safe for use as a filename by removing directory paths and invalid characters.
	 *
	 * @param fileName {String}
	 */
	that.sanitizeFilename = function(fileName = '') {
		const illegalRe = /[\/\?<>\\:\*\|":]/g;
		const controlRe = /[\x00-\x1f\x80-\x9f]/g;
		const reservedRe = /^\.+$/;
		const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;
		const windowsTrailingRe = /[\. ]+$/;
		const replacement = '';

		return fileName
			.replace(illegalRe, replacement)
			.replace(controlRe, replacement)
			.replace(reservedRe, replacement)
			.replace(windowsReservedRe, replacement)
			.replace(windowsTrailingRe, replacement);
	};
	/**
	 * Determine if something is a non-infinite javascript number.
	 *
	 * @param value {number} A (potential) number to see if it is a number.
	 * @returns {boolean} True for non-infinite numbers, false for all else.
	 */
	that.isNumber = function(value) {
		return !isNaN(parseFloat(value)) && isFinite(value);
	};

	/**
	 * Generate Unique Id.
	 *
	 * This format of 8 chars, followed by 3 groups of 4 chars, followed by 12 chars
	 * is known as a UUID and is defined in RFC4122 and is a standard for generating unique IDs.
	 * This function DOES NOT implement this standard. It simply outputs a string
	 * that looks similar. The standard is found here: https://www.ietf.org/rfc/rfc4122.txt
	 *
	 * @returns {string} unique id.
	 */
	that.generateUniqueId = function() {
		const chr4 = () =>
			Math.random()
				.toString(16)
				.slice(-4);

		return chr4() + chr4() + '-' + chr4() + '-' + chr4() + '-' + chr4() + '-' + chr4() + chr4() + chr4();
	};

	this.capitalizeFirstLetter = function(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};

	/**
	 * normalizeList - Normalizes list by provided property
	 *
	 * @param list  - List to normalize
	 * @param property  - Normalized key. ex: "key" or "key1.key2"
	 * @param handler   - Handler is used to process list item. Not necessary.
	 * @returns {Object} normalized object
	 *
	 * Example:
	 *      const list = [{id: 10, name: 'a'}, {id: 20, name: 'b'}];
	 *      const normalizedObject = normalizeList(list, 'id');
	 *
	 *      normalizedObject = {
	 *          "10": {id: 10, name: 'a'},
	 *          "20": {id: 20, name: 'b'}
	 *      }
	 *
	 */
	this.normalizeList = function(list, property, handler = null) {
		return list.reduce((accum, item) => {
			return Object.assign(accum, {[this.getPropertyByPath(item, property)]: handler ? handler(item) : item});
		}, {});
	};

	/**
	 * encodeString - Encode string.
	 *
	 * @param str
	 * @returns {string}
	 */
	this.encodeString = function(str = '') {
		return btoa(encodeURIComponent(str));
	};

	/**
	 * decodeString - Decode string.
	 *
	 * @param str
	 * @returns {string}
	 */
	this.decodeString = function(str = '') {
		return decodeURIComponent(atob(str));
	};

	/**
	 * Recursively flattens array.
	 *
	 * @param arr
	 * @returns {*[]}
	 */
	this.flattenDeep = function flattenDeep(arr) {
		return Array.isArray(arr) ? arr.reduce((a, b) => a.concat(flattenDeep(b)), []) : [arr];
	};

	/**
	 * Extracts suffix from the currentString.
	 *
	 * @param baseString, currentString
	 * @returns {string}
	 */
	this.getSuffix = function(baseString, currentString) {
		return currentString.replace(baseString, '');
	};

	this.isTraneDomainOrLocalhost = function(url) {
		const domain = url.split(/[/:]/)[3];
		return !!domain && (TRANE_DOMAINS.some(item => domain.endsWith(item)) || ['localhost', '127.0.0.1'].includes(domain));
	};

	/**
	 * Compress the array of objects.
	 * @example
	 * compressArrayOfObjects([
	 *  {
	 *  	"color": "#b0b0b0",
	 *  	"hash": "0ChilledWaterTemperatureSetpointActive|27022",
	 *  	"markerType": "DOT",
	 *  	"visible": true
	 *  },
	 *  {
	 *  	"color": "#3399ff",
	 *  	"hash": "0ChilledWaterTemperatureEntering|27022",
	 *  	"markerType": "DOT",
	 *  	"visible": true
	 *  },
	 *  {
	 *  	"color": "#0000ff",
	 *  	"hash": "0ChilledWaterTemperatureLeaving|27022",
	 *  	"markerType": "DOT",
	 *  	"visible": true
	 *  }
	 * ])
	 *
	 * // returns
	 * [
	 *  ["color", "hash", "markerType", "visible"],
	 *  ["0ChilledWaterTemperatureSetpointActive|27022", "DOT", 1]
	 *  ["0ChilledWaterTemperatureEntering|27022", "DOT", 1],
	 *  ["0ChilledWaterTemperatureLeaving|27022", "DOT", 1],
	 * ]
	 *
	 * @param array
	 * @returns {*[]}
	 */
	this.compressArrayOfObjects = function(array) {
		if (!array) {
			return array;
		}

		const headers = Array.from(
			new Set(
				array.reduce((result, obj) => {
					Array.prototype.push.apply(result, Object.keys(obj));
					return result;
				}, [])
			)
		);

		return [
			headers,
			...array.map(obj =>
				headers.map(header => {
					const value = obj[header];
					if (typeof value === 'boolean') {
						return this.convertBooleanToBinary(value);
					}

					return value;
				})
			),
		];
	};

	/**
	 * Decompress the array of objects.
	 * @example
	 * decompressArrayOfObjects([
	 *  ["color", "hash", "markerType", "visible"],
	 *  ["0ChilledWaterTemperatureSetpointActive|27022", "DOT", 1]
	 *  ["0ChilledWaterTemperatureEntering|27022", "DOT", 1],
	 *  ["0ChilledWaterTemperatureLeaving|27022", "DOT", 1],
	 * ])
	 *
	 * // returns
	 * [
	 *  {
	 *  	"color": "#b0b0b0",
	 *  	"hash": "0ChilledWaterTemperatureSetpointActive|27022",
	 *  	"markerType": "DOT",
	 *  	"visible": true
	 *  },
	 *  {
	 *  	"color": "#3399ff",
	 *  	"hash": "0ChilledWaterTemperatureEntering|27022",
	 *  	"markerType": "DOT",
	 *  	"visible": true
	 *  },
	 *  {
	 *  	"color": "#0000ff",
	 *  	"hash": "0ChilledWaterTemperatureLeaving|27022",
	 *  	"markerType": "DOT",
	 *  	"visible": true
	 *  }
	 * ]
	 *
	 * @param array
	 * @returns {*[]}
	 */
	this.decompressArrayOfObjects = function(array) {
		if (!array) {
			return array;
		}

		const headers = array.shift();
		return array.map(item => headers.reduce((result, header, index) => Object.assign(result, {[header]: item[index]}), {}));
	};

	/**
	 * Convert Boolean value to binary
	 * @example
	 * // returns 1
	 * convertBooleanToBinary(true)
	 * * @example
	 * // returns 0
	 * convertBooleanToBinary(false)
	 * @param boolean
	 * @returns {number}
	 */
	this.convertBooleanToBinary = function(boolean) {
		return boolean ? 1 : 0;
	};

	/**
	 * Divide a string into an ordered set of substrings to be fit in allowed width
	 *  * @example
	 * // returns ['Degré-', 'jours de', 'refroidis-', 'sement']
	 * convertBooleanToBinary('Degré-jours de refroidissement', 3, 30)
	 * @param text string
	 * @param characterWidth number
	 * @param lineWidth number
	 * @returns {[]}
	 */
	this.splitWords = (text, characterWidth, lineWidth) => {
		const SPACES_REG_EXP = /\s+/;
		const HYPHEN_CHARACTER = '-';
		const allowedNumberOfCharacters = Math.floor(lineWidth / characterWidth);

		return text
			.split(SPACES_REG_EXP)
			.reduce((accum, word) => {
				// Split the words with hyphen as well: 'Degré-jours' -> ['Degré-', 'jours']
				word.includes(HYPHEN_CHARACTER)
					? accum.push(
							...word.split(HYPHEN_CHARACTER).map((item, index, array) => (array.length - 1 !== index ? `${item}${HYPHEN_CHARACTER}` : item))
					  )
					: accum.push(word);

				return accum;
			}, [])
			.reduce((accum, word) => {
				if (word.length > allowedNumberOfCharacters) {
					// Split the long words on chunk: 'refroidissement' -> ['refroidis-', 'sement']
					const firstWordPart = word.slice(0, allowedNumberOfCharacters - 1);
					const secondWordPart = word.slice(allowedNumberOfCharacters - 1);

					accum.push(`${firstWordPart}${HYPHEN_CHARACTER}`, secondWordPart);
				} else {
					accum.push(word);
				}

				return accum;
			}, [])
			.reduce((accum, word) => {
				// Concat small words into one item: ['jour', 'de'] -> ['jour de']
				if (!accum.length) {
					accum.push(word);
				} else {
					const previousWord = accum[accum.length - 1];
					const nextWord = `${previousWord} ${word}`;
					nextWord.length <= allowedNumberOfCharacters ? (accum[accum.length - 1] = nextWord) : accum.push(word);
				}

				return accum;
			}, []);
	};

	/**
	 * Split array into smaller array chunks
	 *
	 * @param array [1,2,3,4]
	 * @param chunkSize 2
	 * @returns array of arrays [[1,2],[3,4]]
	 */
	this.chunks = (array, size) =>
		array.reduce((acc, _, index) => {
			if (index % size === 0) {
				acc.push(array.slice(index, index + size));
			}
			return acc;
		}, []);

	/**
	 * Convert utf8 to base64
	 *
	 * @param {*} str
	 * @returns str
	 */
	this.utf8ToBase64 = function(str = '') {
		return window.btoa(unescape(encodeURIComponent(str)));
	};

	/**
	 * Convert base64 to utf8
	 *
	 * @param {*} str
	 * @returns str
	 */
	this.base64ToUtf8 = function(str = '') {
		return decodeURIComponent(escape(window.atob(str)));
	};
});
