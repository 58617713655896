/* eslint quotes: [0, 'single'] */
/* eslint eqeqeq: [0, 'always'] */

angular.module('TISCC').controller('ErrorPageCtrl', ErrorPageCtrl);

function ErrorPageCtrl($scope, $rootScope, ErrorPageFactory) {
	const GENERIC_MESSAGE_KEY = 'PLEASE_GO_BACK_OR_HOME';
	const NO_BACK_URL_MESSAGE_KEY = 'YOU_CAN_NAVIGATE_HOME';
	const BACK_URL_MESSAGE_KEY = 'PLEASE_GO_BACK_TO_PAGE_OR_HOME';

	const page = ErrorPageFactory.getPageType();
	let backUrl = ErrorPageFactory.getBackUrl();
	if (!page) {
		$scope.errorAdvisoryKey = GENERIC_MESSAGE_KEY;
		backUrl = 'javascript:history.back()';
		history.pushState({}, '');
	} else if (!backUrl) {
		$scope.errorAdvisoryKey = NO_BACK_URL_MESSAGE_KEY;
	} else {
		$scope.errorAdvisoryKey = BACK_URL_MESSAGE_KEY;
		backUrl = '#' + backUrl;
	}
	$scope.errorMessage = ErrorPageFactory.getMessage();

	$scope.errorAdvisoryAttrs = {
		home: '#' + $rootScope.redirectUrl,
		back: backUrl,
		page: page,
	};
	ErrorPageFactory.clear();
}
