angular.module('TISCC').directive('moreInfoPopup', moreInfoPopupDirective);

function moreInfoPopupDirective() {
	return {
		restrict: 'E',
		transclude: true,
		scope: {
			closeMoreInfoPopup: '=close',
			facility: '=facility',
			offering: '=offering',
		},
		templateUrl: 'components/home/more-info-popup-directives/more-info.html',
	};
}
