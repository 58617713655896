export const USER_EVENTS = {
	HOME_PAGE: {
		categoryName: 'Home Page',
		events: {
			HOME_PAGE_LOAD: 'Home Page Load',
			CHANGE_TO_TILE_VIEW: 'Change to Tile View',
			CHANGE_TO_LIST_VIEW: 'Change to List View',
			SEARCH_FACILITIES: 'Search Facilities',
			CHOOSE_VIEW: 'Choose View',
			SET_MY_VIEW_AS_DEFAULT: 'Set My View as Default',
			MANAGE_MY_VIEWS: 'Manage My Views',
			CREATE_NEW_VIEW: 'Create New View',
			DELETE_VIEW: 'Delete View',
			EDIT_VIEW: 'Edit View',
			SELECT_A_FACILITY: 'Select a Facility',
			GO_TO_FACILITY_SUMMARY_PAGE: 'Go to Facility Summary Page',
			// Notifications
			OPEN_CLOSE_CC4_NOTIFICATIONS: 'Open/Close CC4 Notifications',
			VIEW_CC4_NOTIFICATION: 'View CC4 Notification',
			SHOW_CC4_NOTIFICATIONS_ON_STARTUP: 'Show CC4 Notifications on Startup',
			DELETE_CC4_NOTIFICATION: 'Delete CC4 Notification',
			DELETE_ALL_CC4_NOTIFICATIONS: 'Delete all CC4 Notifications',
			MARK_CC4_NOTIFICATION_AS_READ: 'Mark CC4 Notification as Read',
			MARK_ALL_CC4_NOTIFICATIONS_AS_READ: 'Mark all CC4 Notifications as Read',
		},
		properties: {
			VIEW: 'View',
			CUSTOM_VIEW: 'Custom View',
		},
	},
};
