/* eslint max-depth: ["error", 6]*/
import throttle from 'lodash.throttle';
import _get from 'lodash.get';

(function(d3, AbstractChartRenderer) {
	const BINARY_AXIS_UOM_NAMES = ['heatCool', 'onOff', 'offOn', 'oneZero', 'trueFalse', 'trueFalseOneZero', 'zeroOne', 'lowHigh', 'noYes'];
	const PROPERTY_VALUE_DEVIATION = 0.25;
	const PROPERTY_VALUE_VERTICAL_PADDING = 0.01;
	const UOM_LABEL_HEIGHT = 20;
	const CIRCLE_RADIUS = 1;
	const MEAN_LINE_CIRCLE_RADIUS = 4;
	// TODO We should know the interval provided to the Data API call as it might change from 'PT15m' to something else
	const TIME_INTERVAL_BETWEEN_DATA_POINTS = 15 * 60 * 1000;
	const DOWN_LABEL_SHIFT = 5;
	const UP_LABEL_SHIFT = -10;
	const VERTICAL_LINE_START = -4;
	const VERTICAL_LINE_SHIFT = 17;
	const HORIZONTAL_LABEL_SHIFT = 5;
	const VERTICAL_LABEL_SHIFT = '0.3em';

	function daysDiff(first, second) {
		return (second - first) / (1000 * 60 * 60 * 24);
	}
	class LineChartRenderer extends AbstractChartRenderer {
		constructor(svg, externalMethods, clipPathId, x, xAxis) {
			super(externalMethods.tooltip, externalMethods.translate);
			this.chartId = clipPathId;

			this.tooltipMultiplePoints = {
				disabledLines: {},
				points: [],
			};
			this.isNumber = externalMethods.isNumber;
			this.extraDataFormatting = externalMethods.extraDataFormatting;
			this.propertyStoreService = externalMethods.propertyStoreService;
			this.yAxes = [];
			this.x = x;
			this.xAxis = xAxis;
			this.chartHeight = 0;
			this.gridNode = svg.append('g').attr('class', 'grid');
			this.minorGrid = svg.append('g').attr('class', 'grid minor');
			this.majorGrid = svg.append('g').attr('class', 'grid major');
			this.dataNode = svg.append('g').attr('class', 'data-node');
			this.topAxisLabel = svg.append('g').attr('class', 'axis');
			this.bottomAxisLabel = svg.append('g').attr('class', 'axis');
			this.CHART_TYPE = externalMethods.CHART_TYPE;
			this.LINE_TYPE = externalMethods.LINE_TYPE;
			this.LINE_THICKNESS = externalMethods.LINE_THICKNESS;
			this.MARKER_TYPE = externalMethods.MARKER_TYPE;
			this.MARKER_SIZE = externalMethods.MARKER_SIZE;
			this.CHART_LABEL_OFFSET = {
				left: -2,
				right: 2,
				top: -2,
				bottom: 11,
			};
			this.width = 0;
			this.height = 0;
			this.endTime = null;
			this.labelAxis = d3.svg.axis().scale(x);

			this.yAxes.push(addYAxis('left', svg, this.x));
			this.yAxes.push(addYAxis('right', svg, this.x));
			this.yAxes.push(addYAxis('right', svg, this.x));
			this.yAxes.push(addYAxis('left', svg, this.x));
			this.enableCircleHover = true;
			this.svg = svg;

			svg.node().addEventListener('mouseover', this._dotHoverTooltipHandler.bind(this));
		}

		onSvgReceivedSize({width, height, chartHeight}) {
			this.width = width;
			this.height = height;
			this.chartHeight = chartHeight;
		}

		onSvgSizeUpdate(updateData) {
			this.onSvgReceivedSize(updateData);
			this.updateAxesHeight();
		}

		onRangeXUpdated(range) {
			let tz = range.to.tz();
			this.endTime = moment(range.to).tz(tz);
		}

		onSwitchRuller(enabledRuller) {
			this.enabledRuller = enabledRuller;
			this.drawRullers();
		}

		toggleChartLine(chartOptions, chartData, toggledLine) {
			if (!toggledLine.isControlRangeBoundary) {
				const toggledLineName = toggledLine.name;
				const d3ToggledLineRepresentation = this.dataNode.select(`.lines[line-name="${toggledLineName}"]`);
				const visibilityPropertyValue = toggledLine.visible ? '' : 'none';
				d3ToggledLineRepresentation.attr('display', visibilityPropertyValue);
				this.tooltipMultiplePoints.disabledLines[toggledLineName] = !toggledLine.visible;
			} else {
				this.dataNode.selectAll('*').remove();
				this.drawData(chartOptions, chartData);
			}
		}

		updateAxisLabel(LABEL_NUMBER) {
			setAxisLabel(this.labelAxis, this.topAxisLabel, 'top', this.CHART_LABEL_OFFSET.top - UOM_LABEL_HEIGHT, LABEL_NUMBER);
			this.bottomAxisLabel.attr('transform', 'translate(0,' + this.chartHeight + ')');
			setAxisLabel(this.labelAxis, this.bottomAxisLabel, 'bottom', this.CHART_LABEL_OFFSET.bottom, LABEL_NUMBER);
		}

		updateAxesHeight() {
			this.yAxes.forEach(item => {
				item.y.range([this.chartHeight, 0]);
				item.axis = d3.svg
					.axis()
					.orient(item.orient)
					.scale(item.y);
			}, this);

			this.updateYAxes();
		}

		updateGrid(period, labelNumber) {
			let tickNumber = period === 1 ? 20 : 14;
			this.minorGrid.call(
				this.xAxis
					.ticks(tickNumber)
					.tickSize(this.chartHeight, 0, 0)
					.tickFormat('')
			);
			const x = this.xAxis
				.ticks(labelNumber)
				.tickSize(this.chartHeight, 0, 0)
				.tickFormat('');
			this.majorGrid
				.call(x)
				.selectAll('.tick')
				.select('line')
				.attr('class', 'major');
		}

		onBrushRange() {
			this.updateChartPos();
		}

		updateChartPos() {
			let that = this;
			this.dataNode
				.selectAll('.lines')
				.selectAll('circle')
				.attr('cx', d => that.x(d.x));
			this.yAxes.forEach(function(item) {
				this.dataNode.selectAll('path.axis-' + item.index).attr('d', item.line);
				this.dataNode.selectAll('circle.axis-' + item.index).attr('cy', d => item.y(d.y));
				this.dataNode.selectAll('text.control-range-title.axis-' + item.index).attr('y', d => item.y(d.y));
				this.dataNode
					.selectAll('line.control-range.axis-' + item.index)
					.attr('y1', d => item.y(d.y))
					.attr('x2', that.x(that.x.domain()[1]))
					.attr('y2', d => item.y(d.y));
				let controlRangeBoundaryParamY = 0;
				this.dataNode.selectAll('rect.control-range-shade.axis-' + item.index).each(function(d) {
					d3
						.select(this)
						.attr('y', controlRangeBoundaryParamY)
						.attr('width', that.x(that.x.domain()[1]))
						.attr('height', d => item.y(d.y) - controlRangeBoundaryParamY);
					controlRangeBoundaryParamY = item.y(d.y);
				});
			}, this);
		}

		updateYAxesScale(chartOptions, chartData) {
			let that = this;
			let isBinaryChart = chartOptions.chartType === that.CHART_TYPE.LINE_WITH_BINARY_STATES;
			let isLineChart = chartOptions.chartType.startsWith(that.CHART_TYPE.LINE);
			const axisToScaleArray = new Map();
			const [minDate, maxDate] = this.x.domain();

			function fixPercentageRange(min = 0, max = 100) {
				return [Math.min(0, min), Math.max(100, max)];
			}

			function generateValueRange(value, resolution = 0.1) {
				const multiplied = Math.abs(value * PROPERTY_VALUE_DEVIATION);
				const minValue = +that.extraDataFormatting.applyDecimalFormatting(value - multiplied, resolution);
				const maxValue = +that.extraDataFormatting.applyDecimalFormatting(value + multiplied, resolution);
				return [minValue, maxValue];
			}

			function addValueRangePadding(min, max, resolution) {
				const padding = resolution === 1 ? 1 : (max - min) * PROPERTY_VALUE_VERTICAL_PADDING;
				const minValue = +that.extraDataFormatting.applyDecimalFormatting(min - padding, resolution);
				const maxValue = +that.extraDataFormatting.applyDecimalFormatting(max + padding, resolution);
				return [minValue, maxValue];
			}

			function getMinMaxValues(extents) {
				const minValue = d3.min(extents, d => d[0]);
				const maxValue = d3.max(extents, d => d[1]);
				return [minValue, maxValue];
			}

			this.yAxes.forEach((axis, index) => {
				let maxValue, minValue;
				let scaleArray;
				let data = chartOptions.yAxis ? chartOptions.yAxis[index] : null;
				let isPercentageAxis = that._isPercentageDataAxis(isLineChart, data);
				const currentAxisLines = chartOptions.lines.filter(line => data && line.chartAxisId === data.chartAxisId);
				const atLeastOneLineVisible = currentAxisLines.some(line => line.visible);
				let extents = [];
				let valueRangeExtents = [];
				let isAxisDataPresent = false;
				let resolution;
				axis.hasData = false;
				if (data) {
					extents = [];
					for (let i = 0; i < chartOptions.lines.length; i++) {
						let line = chartOptions.lines[i];
						if (line === undefined || !line.visible) {
							continue;
						}
						if (line.chartAxisId === data.chartAxisId) {
							axis.binaryFormat =
								line.fullPropertyInfo && line.fullPropertyInfo.propertyAttribute && line.fullPropertyInfo.propertyAttribute.unitOfMeasureName
									? line.fullPropertyInfo.propertyAttribute.unitOfMeasureName
									: 'default';

							if (chartData[i] && chartData[i].length) {
								const filteredData = chartData[i].filter(point => point.x >= minDate && maxDate >= point.x);
								const extent = d3.extent(filteredData, d => parseFloat(d.y));
								const [minValue, maxValue] = extent;
								if (this.isNumber(minValue) && this.isNumber(maxValue)) {
									extents.push(extent);
									isAxisDataPresent = true;
									line.extent = extent;
								}
							}
							if (line.valueRange && line.valueRange.length) {
								valueRangeExtents.push(line.valueRange);
							}
							if (line.resolution) {
								if (!resolution || resolution > line.resolution) {
									resolution = line.resolution;
								}
							}
						}
					}

					axis.hasData = isAxisDataPresent;
					axis.side = data.side;
					axis.resolution = resolution;
					axis.id = data.chartAxisId;

					if (isBinaryChart && BINARY_AXIS_UOM_NAMES.includes(data.uom.name)) {
						minValue = 0;
						maxValue = d3.max(extents, d => d[1]);
						maxValue && maxValue % 2 ? (maxValue += 2) : (maxValue += 1);
						axis.binary = true;
					} else {
						[minValue, maxValue] = getMinMaxValues(extents);
					}
				}
				isPercentageAxis = isPercentageAxis && (atLeastOneLineVisible || !isAxisDataPresent);
				if (minValue && maxValue && minValue === maxValue) {
					[minValue, maxValue] = generateValueRange(minValue, resolution);
				} else if (!axis.hasData && valueRangeExtents.length > 0) {
					[minValue, maxValue] = getMinMaxValues(valueRangeExtents);
				} else if (!axis.binary && axis.resolution && axis.resolution !== 1) {
					[minValue, maxValue] = addValueRangePadding(minValue, maxValue, resolution);
				}

				if (data && data.customRange) {
					scaleArray = data.customRange;
				} else {
					scaleArray = isPercentageAxis ? fixPercentageRange(minValue, maxValue) : [minValue, maxValue];
				}

				axis.isPercentageAxis = isPercentageAxis;
				axis.y.range([this.chartHeight, 0]).domain(scaleArray);
				axis.axis = d3.svg
					.axis()
					.orient(axis.orient)
					.scale(axis.y);
				axis.index = index;

				if (data && data.uom) {
					axis.uomSymbol = chartOptions.yAxis[index].uom.symbol;
				}

				axis.visible = extents.length > 0 || valueRangeExtents.length > 0;

				// Store range to calculate tick count if value range is within zero (-1 > 0 < 1)
				axis.scaleArray = scaleArray;

				axisToScaleArray.set(axis.id, scaleArray);
			}, this);

			this.updateYAxes();
			return axisToScaleArray;
		}

		_dotHoverTooltipHandler(e) {
			if (this.enableCircleHover) {
				const cursorTargetElement = e.target;
				const tagName = cursorTargetElement.tagName.toLowerCase();

				if (tagName === 'circle') {
					const that = this;
					const dataAssociatedWithPoint = d3.select(cursorTargetElement).data()[0];
					this._dotMouseOverHandler(dataAssociatedWithPoint, cursorTargetElement);

					const outHandler = function f() {
						const dotRadius = parseFloat(cursorTargetElement.getAttribute('dotRadius'));
						d3.select(cursorTargetElement).attr('r', dotRadius);
						d3
							.select(cursorTargetElement.parentNode)
							.select('line.markerZoom')
							.remove();
						cursorTargetElement.removeEventListener('mouseout', f);
						that.tooltip.hideTooltip();
					};

					cursorTargetElement.addEventListener('mouseout', outHandler);
				}
			}
		}

		_dotMouseOverHandler(d, dot) {
			let that = this;
			let xPos = parseInt(that.x(d.x));
			let hoveredDotRadius = parseFloat(dot.getAttribute('dotRadius')) + 1;
			let yPos = parseInt(dot.getAttribute('cy'));
			let yScaleMin = parseFloat(dot.parentNode.getAttribute('yScaleMin'));
			let yScaleMax = parseFloat(dot.parentNode.getAttribute('yScaleMax'));
			let xValue = d.x.getTime();
			let yPosPercent = yScaleMax - yScaleMin ? (100 * (yScaleMax - d.y) / (yScaleMax - yScaleMin)).toFixed(1) : 0;
			let yAxisData =
				dot.getAttribute('binaryLabelDisplay') === 'true'
					? that.extraDataFormatting.applyBoolean(!(d.y % 2 > 0), dot.getAttribute('extraBinaryLabelFormat'))
					: d.y;
			let multiPoints = getPointsInRadius(xValue, yPosPercent, this.tooltipMultiplePoints).filter(
				point => !this.tooltipMultiplePoints.disabledLines[point.name]
			);

			const tooltipParams = {
				xPos: xPos,
				yPos: yPos,
				xAxisData: moment(d.x).format('M/D/YY h:mm A'),
			};
			if (multiPoints.length > 1) {
				tooltipParams.multiPoints = multiPoints;
				tooltipParams.chartType = 'lineMultiPoints';
			} else {
				tooltipParams.name = dot.parentNode.getAttribute('line-name');
				tooltipParams.color = dot.getAttribute('fill');
				tooltipParams.uom = getCorrectUomSymbol(dot.parentNode.getAttribute('uom'));
				tooltipParams.yAxisData = yAxisData;
				tooltipParams.chartType = 'line';
			}
			const currentMarkerEnd = d3
				.select(dot.parentNode)
				.select('path.line')
				.attr('marker-end');
			d3
				.select(dot.parentNode)
				.append('line')
				.attr('marker-end', currentMarkerEnd)
				.attr('class', 'markerZoom')
				.attr('transform', `translate(${xPos}, ${yPos}) scale(1.2)`);
			that.tooltip.showTooltip(tooltipParams);
			d3.select(dot).attr('r', hoveredDotRadius);
		}

		_getStrokeIntervalByLineType(lineType, lineThickness) {
			const isThicknessSizeThreeOrFour = lineThickness === this.LINE_THICKNESS.SIZE_3 || lineThickness === this.LINE_THICKNESS.SIZE_4;
			let result = '';

			switch (lineType) {
				case this.LINE_TYPE.DASHED:
					if (isThicknessSizeThreeOrFour) {
						result = '20, 20';
					} else {
						result = '5, 5';
					}
					break;

				case this.LINE_TYPE.DOTTED:
					if (isThicknessSizeThreeOrFour) {
						result = '1, 10';
					} else if (lineThickness === this.LINE_THICKNESS.SIZE_2) {
						result = '1, 4';
					} else {
						result = '1, 2';
					}
					break;

				case this.LINE_TYPE.DASH_DOT:
					if (isThicknessSizeThreeOrFour) {
						result = '15, 15, 1, 15';
					} else {
						result = '5, 5, 1, 5';
					}

					break;

				case this.LINE_TYPE.LONG_DASH:
					if (isThicknessSizeThreeOrFour) {
						result = '30, 30';
					} else {
						result = '15, 10';
					}
					break;
			}

			return result;
		}

		_updateMultiTooltipsColor(lineHash, color) {
			let that = this;
			const cxPointsKeys = Object.keys(this.tooltipMultiplePoints.points);
			cxPointsKeys.forEach(cxPoint => {
				const cyPointsKeys = Object.keys(that.tooltipMultiplePoints.points[cxPoint]);
				cyPointsKeys.forEach(cyPoint => {
					for (let i = 0; i < that.tooltipMultiplePoints.points[cxPoint][cyPoint].length; i++) {
						if (that.tooltipMultiplePoints.points[cxPoint][cyPoint][i].lineHash === lineHash) {
							that.tooltipMultiplePoints.points[cxPoint][cyPoint][i].color = color;
						}
					}
				});
			});
		}

		_getStrokeShapeByLineType(lineType) {
			if (lineType === this.LINE_TYPE.DOTTED || lineType === this.LINE_TYPE.DASH_DOT) {
				return 'round';
			} else {
				return 'square';
			}
		}

		changeLine(lineHash, changes = {}) {
			this.dataNode
				.selectAll(`g[line-hash="${lineHash}"] > path`)
				.attr('stroke-dasharray', this._getStrokeIntervalByLineType(changes.lineType, changes.lineThickness))
				.attr('stroke-linecap', this._getStrokeShapeByLineType(changes.lineType))
				.attr('stroke-width', changes.lineThickness)
				.attr('marker-start', `url(#${lineHash}_${changes.markerType})`)
				.attr('marker-mid', `url(#${lineHash}_${changes.markerType})`)
				.attr('marker-end', `url(#${lineHash}_${changes.markerType})`);
			this.dataNode
				.select(`g[line-hash="${lineHash}"]`)
				.attr('stroke', changes.color)
				.selectAll('circle')
				.attr('fill', changes.color);
			if (changes.markerType !== this.MARKER_TYPE.NONE.name) {
				this.dataNode
					.selectAll(`g[line-hash="${lineHash}"] marker`)
					.attr('markerWidth', changes.markerSize)
					.attr('markerHeight', changes.markerSize)
					.attr('fill', changes.color);
			}
			if (changes.markerType === this.MARKER_TYPE.DOT.name) {
				let dotRadius = CIRCLE_RADIUS;
				changes.markerSize && (dotRadius = changes.markerSize / 2 - 0.5);
				this.dataNode
					.select(`g[line-hash="${lineHash}"]`)
					.selectAll('circle')
					.attr('opacity', '1')
					.attr('r', dotRadius)
					.attr('dotRadius', dotRadius);
			} else {
				this.dataNode
					.select(`g[line-hash="${lineHash}"]`)
					.selectAll('circle')
					.attr('opacity', '0');
			}
			this._updateMultiTooltipsColor(lineHash, changes.color);
		}

		drawData(chartOptions, chartData) {
			this.tooltip.hideTooltip();

			if (!chartOptions.lines) {
				return;
			}

			let that = this;
			let isBinaryChart = chartOptions.chartType === that.CHART_TYPE.LINE_WITH_BINARY_STATES;
			let isLineWithControlRangeAndMean = chartOptions.chartType === that.CHART_TYPE.LINE_WITH_CONTROL_RANGE_AND_MEAN;
			let chunks = new Array(chartOptions.lines.length);
			let controlBoundariesLayer = that.dataNode.append('g');
			let regularLayer = that.dataNode.append('g');
			let meanLineLayer = that.dataNode.append('g');

			that.tooltipMultiplePoints = {
				disabledLines: {},
				points: [],
			};

			if (isLineWithControlRangeAndMean) {
				that.drawControlRangeShades(chartOptions, chartData, controlBoundariesLayer);
			}

			let lastLine = chartOptions.lines.length - 1;

			for (let k = 0; k <= lastLine; k++) {
				// Order of layers matters for chart rendering
				const i = chartOptions.isChartLinesSortedAsc ? k : lastLine - k;
				const line = chartOptions.lines[i];
				const {visible: isLineVisible, isControlRangeBoundary} = line;

				if ((!isLineVisible && isControlRangeBoundary) || !chartData[i]) {
					continue;
				}

				// split chart data into series by missing data sequences
				// E.g. [null,null,1,2,3,4,null,null,2] becomes [[1,2,3,4],[1,2]]
				chunks[i] = splitData.call(this, chartData[i], chartOptions, i);

				let yScale = this.yAxes.find(axis => axis.visible && axis.id === line.chartAxisId);
				if (!yScale) continue;

				let fullPropInfo = line.fullPropertyInfo;
				let extraBinaryLabelFormat =
					fullPropInfo && fullPropInfo.propertyAttribute && fullPropInfo.propertyAttribute.unitOfMeasureName
						? fullPropInfo.propertyAttribute.unitOfMeasureName
						: null;
				let isMeanLine = line.isMeanLine || false;
				let dotRadius = isMeanLine ? MEAN_LINE_CIRCLE_RADIUS : CIRCLE_RADIUS;
				dotRadius = line.markerSize ? line.markerSize / 2 - 0.5 : dotRadius;
				let targetNode = isMeanLine ? meanLineLayer : regularLayer;
				line.markerType = line.markerType || this.MARKER_TYPE.DOT.name;
				let lines = targetNode
					.append('g')
					.attr('line-name', line.name)
					.attr('uom', line.uom.symbol)
					.attr('line-hash', line.lineHash)
					.attr('stroke', line.color)
					.attr('yScaleMin', yScale.y.domain()[0])
					.attr('yScaleMax', yScale.y.domain()[1])
					.attr('class', 'lines')
					.attr('clip-path', 'url(#chartClipBorder' + this.chartId + ')')
					.attr('display', isLineVisible ? '' : 'none');
				this._createLineMarkers(lines, line);

				for (let j = 0; j < chunks[i].length; j++) {
					if (isControlRangeBoundary) {
						that.drawControlRangeBoundary(line, chunks[i][j], yScale, controlBoundariesLayer);
					} else {
						lines
							.append('path')
							.datum(chunks[i][j])
							.attr('class', 'line axis-' + yScale.index + (isMeanLine ? ' mean-line' : ''))
							.attr('d', yScale.line)
							// This is line settings which were passed for export.
							// If these are empty - they are not applied.
							.attr('stroke-dasharray', this._getStrokeIntervalByLineType(line.lineType, line.lineThickness))
							.attr('stroke-linecap', this._getStrokeShapeByLineType(line.lineType))
							.attr('stroke-width', line.lineThickness)
							.attr('marker-start', `url(#${line.lineHash}_${line.markerType})`)
							.attr('marker-mid', `url(#${line.lineHash}_${line.markerType})`)
							.attr('marker-end', `url(#${line.lineHash}_${line.markerType})`);

						lines
							.selectAll('dot')
							.data(chunks[i][j])
							.enter()
							.append('circle')
							.attr('class', `axis-${yScale.index}`)
							.attr('clip-path', `url(#chartClipBorder${that.chartId})`)
							.attr('fill', line.color)
							.attr('isChild', line.isChild)
							.attr('dotRadius', dotRadius)
							.attr('binaryLabelDisplay', isBinaryChart && BINARY_AXIS_UOM_NAMES.includes(line.uom.name))
							.attr('extraBinaryLabelFormat', isBinaryChart && extraBinaryLabelFormat ? extraBinaryLabelFormat : false)
							.attr('r', dotRadius)
							.attr('cx', d => that.x(d.x))
							.attr('cy', d => yScale.y(d.y))
							.attr('opacity', line.markerType === that.MARKER_TYPE.DOT.name ? 1 : 0);
					}
				}
			}
			this.drawRullers();

			for (let k = 0; k <= lastLine; k++) {
				// Order of layers matters for chart rendering
				const i = chartOptions.isChartLinesSortedAsc ? k : lastLine - k;
				const line = chartOptions.lines[i];
				if (line.markerType !== that.MARKER_TYPE.DOT.name) {
					this.changeLine(line.lineHash, {
						color: line.color,
						lineThickness: line.lineThickness,
						lineType: line.lineType,
						markerSize: line.markerSize,
						markerType: line.markerType,
					});
				}
			}
		}

		drawRullers() {
			const dataNode = this.dataNode;
			const dataNodeNode = dataNode.node();
			const context = this;
			const svg = document.querySelector('svg.stretch.ng-isolate-scope');
			dataNode.select('#click-capture-rect').remove();
			dataNode.select('#ruller-group-vertical').remove();
			dataNode.select('#ruller-group-horizontal').remove();
			delete this.rullers;

			const reSetRullers = function() {
				context.enableCircleHover = true;
				context.rullers.horizontal.line.classed('grabbing', false).classed('grab', true);
				context.rullers.vertical.line.classed('grabbing', false).classed('grab', true);
				context.rullers.captEvents.classed('grabbing', false);
				context.rullers.captEvents.on('mousemove', null);
				dataNode.on('mouseup', null);
				dataNodeNode.removeEventListener('mouseout', mouseoutHandler);
			};

			const mouseoutHandler = function(e) {
				if (e.relatedTarget === svg) {
					reSetRullers();
				}
			};

			dataNodeNode.removeEventListener('mouseout', mouseoutHandler);
			if (this.enabledRuller) {
				const isPeriodDay = daysDiff(this.x.domain()[0], this.x.domain()[1]) <= 1;
				this.rullers = {};
				const initialVerticalValue = this.width / 2;
				this.rullers.captEvents = dataNode
					.append('rect')
					.each(function() {
						this.parentNode.insertBefore(this, this.parentNode.firstChild);
					})
					.attr('id', 'click-capture-rect')
					.attr('pointer-events', 'all')
					.style('visibility', 'hidden')
					.attr('x', 0)
					.attr('y', 0)
					.attr('width', this.width)
					.attr('height', this.chartHeight);

				this.rullers.vertical = {
					node: dataNode
						.append('g')
						.attr('id', 'ruller-group-vertical')
						.attr('transform', `translate(${initialVerticalValue},0)`),
				};

				const {left: leftEdge, right: rightEdge, top: topEdge} = dataNodeNode.getBoundingClientRect();
				const {top: svgTop, height: svgHeight} = svg.getBoundingClientRect();
				const height = svgTop + svgHeight - topEdge;

				this.rullers.vertical.labelUp = this.rullers.vertical.node
					.append('text')
					.attr('class', 'ruller-label vertical')
					.attr('y', UP_LABEL_SHIFT);

				this.rullers.vertical.labelDown = this.rullers.vertical.node
					.append('text')
					.attr('class', 'ruller-label vertical')
					.attr('y', height - DOWN_LABEL_SHIFT);

				this.rullers.vertical.line = this.rullers.vertical.node
					.append('line')
					.attr('class', 'ruler vertical')
					.classed('grab', true)
					.attr('stroke', '#FF0000')
					.attr('stroke-width', 2)
					.attr('x1', 0)
					.attr('y1', VERTICAL_LINE_START)
					.attr('x2', 0)
					.attr('y2', height - VERTICAL_LINE_SHIFT);

				const setHorozontalRuller = value => {
					const {line, labels, width} = this.rullers.horizontal;
					let [leftLabelWidth, rightLabelWidth] = [0, 0];
					labels.forEach(label => {
						label.node.text(
							`${label.axis.isPercentageAxis ? Math.round(label.axis.y.invert(value)) : label.axis.tickFormat(label.axis.y.invert(value))}`
						);
						if (label.axis.orient === 'left') {
							leftLabelWidth = label.node.node().getBoundingClientRect().width;
						}
						if (label.axis.orient === 'right') {
							rightLabelWidth = label.node.node().getBoundingClientRect().width;
						}
					});
					line.attr('x1', 0 + leftLabelWidth + HORIZONTAL_LABEL_SHIFT * 2).attr('x2', width - (rightLabelWidth + HORIZONTAL_LABEL_SHIFT * 2));
				};

				const setXLabel = value => {
					const AXIS_RULLER_LABEPADDING = 10;
					let timeFormat = d3Locale.current.timeFormat;
					let localeId = d3Locale.localeId;
					const timeIndicatorFormat = isPeriodDay ? timeFormat(d3Locale[localeId].minutesLong) : timeFormat(d3Locale[localeId].dateTimeShort);
					const label = timeIndicatorFormat(value);
					this.rullers.vertical.labelUp.text(label);
					this.rullers.vertical.labelDown.text(label);
					const {width: labelWidth} = this.rullers.vertical.labelUp.node().getBoundingClientRect();
					const {x: lineX} = this.rullers.vertical.line.node().getBoundingClientRect();
					const labelLeftEdge = lineX - labelWidth / 2;
					const labelRightEdge = lineX + labelWidth / 2;
					if (leftEdge > labelLeftEdge + AXIS_RULLER_LABEPADDING) {
						this.rullers.vertical.labelUp.attr('dx', AXIS_RULLER_LABEPADDING + (leftEdge - labelLeftEdge));
						this.rullers.vertical.labelDown.attr('dx', AXIS_RULLER_LABEPADDING + (leftEdge - labelLeftEdge));
					} else if (rightEdge < labelRightEdge + AXIS_RULLER_LABEPADDING) {
						this.rullers.vertical.labelUp.attr('dx', rightEdge - (labelRightEdge + AXIS_RULLER_LABEPADDING));
						this.rullers.vertical.labelDown.attr('dx', rightEdge - (labelRightEdge + AXIS_RULLER_LABEPADDING));
					} else {
						this.rullers.vertical.labelUp.attr('dx', 0);
						this.rullers.vertical.labelDown.attr('dx', 0);
					}
				};
				setXLabel(this.x.invert(initialVerticalValue));

				const initialHorizontalValue = this.chartHeight / 2;
				this.yAxes.forEach(axis => {
					if (axis.visible) {
						if (!this.rullers.horizontal) {
							this.rullers.horizontal = {
								node: dataNode
									.append('g')
									.attr('id', 'ruller-group-horizontal')
									.attr('transform', `translate(0,${initialHorizontalValue})`),
								labels: [],
							};
							this.rullers.horizontal.line = this.rullers.horizontal.node
								.append('line')
								.attr('class', 'ruler horizontal')
								.classed('grab', true)
								.attr('stroke', '#FF0000')
								.attr('stroke-width', 2)
								.attr('x1', 0)
								.attr('y1', 0)
								.attr('x2', this.width)
								.attr('y2', 0);
							this.rullers.horizontal.width = this.width;
						}
						if (axis.orient === 'left') {
							const rulerLabelsY1 = this.rullers.horizontal.node
								.append('text')
								.attr('x', HORIZONTAL_LABEL_SHIFT)
								.attr('dy', VERTICAL_LABEL_SHIFT)
								.attr('class', 'ruller-label left');
							this.rullers.horizontal.labels.push({
								node: rulerLabelsY1,
								axis,
							});
						} else if (axis.orient === 'right') {
							const rulerLabelsY2 = this.rullers.horizontal.node
								.append('text')
								.attr('x', this.width - HORIZONTAL_LABEL_SHIFT)
								.attr('dy', VERTICAL_LABEL_SHIFT)
								.attr('class', 'ruller-label right');
							this.rullers.horizontal.labels.push({
								node: rulerLabelsY2,
								axis,
							});
						}
						setHorozontalRuller(initialHorizontalValue);
					}
				});

				const setRullersThrottled = function(coords) {
					context.rullers.vertical.node.attr('transform', `translate(${coords[0]},0)`);
					context.rullers.horizontal.node.attr('transform', `translate(0,${coords[1]})`);
					setXLabel(context.x.invert(coords[0]));
					setHorozontalRuller(coords[1]);
				};

				const setRullers = throttle(setRullersThrottled, 50, {trailing: true});

				dataNode.on('mousedown', function() {
					const nodes = svg.querySelectorAll('g line, g circle');
					const filtered = Array.prototype.filter.call(nodes, function(item) {
						if (item.closest('.lines')) {
							return !(item.closest('.lines').getAttribute('display') === 'none');
						}
						return true;
					});

					const {button, target} = d3.event;
					reSetRullers();
					context.enableCircleHover = false;
					if (button === 0 && (target === context.rullers.horizontal.line.node() || target === context.rullers.vertical.line.node())) {
						context.rullers.horizontal.line.classed('grabbing', true).classed('grab', false);
						context.rullers.vertical.line.classed('grabbing', true).classed('grab', false);
						context.rullers.captEvents.classed('grabbing', true);

						setRullers(d3.mouse(this));
						context.rullers.captEvents.on('mousemove', function() {
							setRullers(d3.mouse(this));
						});

						context.dataNode.on('mouseup', function() {
							reSetRullers();
						});

						context.dataNode.node().addEventListener('mouseout', mouseoutHandler);
					}
				});
			}
		}

		_createLineMarkers(container, line) {
			const defs = container.append('defs');
			const MARKER_SIZE = line.markerSize || 5;
			defs
				.append('marker')
				.attr('id', `${line.lineHash}_${this.MARKER_TYPE.SQUARE.name}`)
				.attr('markerUnits', 'userSpaceOnUse')
				.attr('viewBox', '-5 -5 10 10')
				.attr('refX', 0)
				.attr('refY', 0)
				.attr('stroke-width', 10)
				.attr('markerWidth', MARKER_SIZE)
				.attr('markerHeight', MARKER_SIZE)
				.attr('orient', 0)
				.attr('fill', line.color)
				.append('path')
				.attr('d', 'M 0,0 m -5,-5 L 5,-5 L 5,5 L -5,5 Z');
			defs
				.append('marker')
				.attr('id', `${line.lineHash}_${this.MARKER_TYPE.TRIANGLE.name}`)
				.attr('markerUnits', 'userSpaceOnUse')
				.attr('viewBox', '-50 -50 100 95')
				.attr('refX', 0)
				.attr('refY', 0)
				.attr('stroke-width', 10)
				.attr('markerWidth', MARKER_SIZE)
				.attr('markerHeight', MARKER_SIZE)
				.attr('orient', 0)
				.attr('fill', line.color)
				.append('polygon')
				.attr('points', '0 -40, 50 45, -50 45');
			const xMarker = defs
				.append('marker')
				.attr('id', `${line.lineHash}_${this.MARKER_TYPE.X.name}`)
				.attr('markerUnits', 'userSpaceOnUse')
				.attr('viewBox', '-5 -5 10 10')
				.attr('refX', 0)
				.attr('refY', 0)
				.attr('stroke-width', 3)
				.attr('markerWidth', MARKER_SIZE)
				.attr('markerHeight', MARKER_SIZE)
				.attr('orient', 0)
				.attr('fill', line.color);
			xMarker
				.append('line')
				.attr('x1', -5)
				.attr('y1', -5)
				.attr('x2', 5)
				.attr('y2', 5);
			xMarker
				.append('line')
				.attr('x1', -5)
				.attr('y1', 5)
				.attr('x2', 5)
				.attr('y2', -5);
		}

		updateYAxes() {
			let that = this;
			let ticks;
			let isValueRangeWithInZero = false;
			let defaultDisplayLabel = function(resolution, isPercentageAxis, props, d) {
				props = props || {};
				const isValueRangeWithInZero = props.isValueRangeWithInZero || false;

				if (isPercentageAxis) return d;
				else {
					const formattedValue = that.extraDataFormatting.applyDecimalFormatting(d, resolution, true);
					const isSameValue = Number(formattedValue) === d;
					return isValueRangeWithInZero ? (isSameValue ? formattedValue : '') : formattedValue;
				}
			};
			let binaryDisplayLabel = function(maxValue, binaryFormat, d) {
				if (d === maxValue || d === 0) {
					return '';
				}
				return that.extraDataFormatting.applyBoolean(!(d % 2 > 0), binaryFormat);
			};

			const calculateSubtractFn = {
				'1': () => 10, // let the chart use default tickts
				'0.1': (max, min) => (max * 10 - min * 10) / 10,
				'0.01': (max, min) => (max * 100 - min * 100) / 100,
				'0.001': (max, min) => (max * 1000 - min * 1000) / 1000,
				'0.0001': (max, min) => (max * 10000 - min * 10000) / 10000, // for future range
			};

			// (Immutable) function to calculate ticks if min and max range value is not sufficient to generate tick range
			function findValueRangeIsWithInZeroOrNot(min, max, resolution) {
				// conver to positive number incase if values are negative
				let minAbs = Math.abs(min);
				let maxAbs = Math.abs(max);

				// Get min & max value
				let minValue = Math.min.apply(null, [minAbs, maxAbs]);
				let maxValue = Math.max.apply(null, [minAbs, maxAbs]);
				let diff = calculateSubtractFn[resolution] ? calculateSubtractFn[resolution](maxValue, minValue) : 1;

				return diff >= 1 ? false : true;
			}

			that.yAxes.forEach(function(yAxis, i) {
				const UOM_LABEL_PADDING = 10;
				const UOM_LABEL_PADDING_HORIZONTAL = 4;
				const UOM_LABEL_MAX_WIDTH = 35;
				let uomLabelOffset = 0;
				let currentBinaryDisplayLabel;
				let currentDefaultDisplayLabel;
				let uomLabelText = yAxis.uomLabel.append('text');
				if (yAxis.visible) {
					if (yAxis.binary) {
						let maxValue = yAxis.y.domain()[1];
						ticks = maxValue + 1;
						currentBinaryDisplayLabel = binaryDisplayLabel.bind(null, maxValue, yAxis.binaryFormat);
					} else {
						if (yAxis.scaleArray && yAxis.scaleArray.length === 2 && yAxis.resolution) {
							isValueRangeWithInZero = findValueRangeIsWithInZeroOrNot(yAxis.scaleArray[0], yAxis.scaleArray[1], yAxis.resolution);
						}

						const props = {isValueRangeWithInZero};

						currentDefaultDisplayLabel = defaultDisplayLabel.bind(null, yAxis.resolution, yAxis.isPercentageAxis, props);
					}
					// display binary values for second axis if it has not data
					if (i > 0 && currentBinaryDisplayLabel && that.yAxes[0].y.domain().toString() === yAxis.y.domain().toString()) {
						currentDefaultDisplayLabel = currentBinaryDisplayLabel;
					}
					yAxis.tickFormat = yAxis.binary ? currentBinaryDisplayLabel : currentDefaultDisplayLabel;
					yAxis.node
						.attr('display', '')
						.call(
							yAxis.axis
								.tickSize(0, 0, 0)
								.tickFormat(yAxis.tickFormat)
								.ticks(ticks)
						)
						.selectAll('text')
						.attr('dx', that.CHART_LABEL_OFFSET[yAxis.orient]);
					uomLabelText.attr('class', 'axis-label').text(getCorrectUomSymbol(yAxis.uomSymbol));
					if (yAxis.side === 'right') {
						yAxis.uomLabel.attr('transform', `translate(${that.width},0)`);
						yAxis.node.attr('transform', `translate(${that.width},0)`);
					} else {
						yAxis.uomLabel.attr('transform', 'translate(0,0)');
					}
					const uomNode = uomLabelText.node();
					const textContent = uomNode.textContent;

					const labelBoxWidth = uomNode.getBBox().width;

					if (labelBoxWidth > UOM_LABEL_MAX_WIDTH) {
						const fontScale = UOM_LABEL_MAX_WIDTH / labelBoxWidth;
						uomNode.style.fontSize = `${fontScale}em`;
					}

					if (textContent.length > 3) {
						uomNode.style.fontSize = '12px';
					}

					if (yAxis.orient === 'left') {
						uomLabelOffset = -UOM_LABEL_PADDING_HORIZONTAL;
						uomLabelText.attr('class', 'axis-label left');
					} else {
						uomLabelOffset = UOM_LABEL_PADDING_HORIZONTAL;
						uomLabelText.attr('class', 'axis-label right');
					}
					uomLabelText.attr('transform', `translate(${uomLabelOffset},${-UOM_LABEL_PADDING})`).attr('display', '');
				} else {
					yAxis.node.attr('display', 'none');
					uomLabelText.attr('display', 'none');
				}
			});

			let isAnyAxisVisible = that.yAxes.some(function(item) {
				if (item && item.visible) {
					let tickSize = item.orient === 'left' ? -that.width : that.width;
					that.gridNode.attr('display', '').call(item.axis.tickSize(tickSize, 0, 0).tickFormat(''));
					return true;
				}
			});
			if (!isAnyAxisVisible) {
				that.gridNode.attr('display', 'none');
			}
		}
	}

	function addYAxis(orientation, svg, x) {
		let y = d3.scale.linear().domain([0, 0]);
		let node = svg.append('g').attr('class', 'axis vertical-axis');

		return {
			y: y,
			node: node,
			axis: d3.svg
				.axis()
				.scale(y)
				.orient(orientation),
			orient: orientation,
			uomLabel: svg.append('g'),
			line: d3.svg
				.line()
				.x(d => x(d.x))
				.y(d => y(d.y)),
		};
	}

	function setAxisLabel(labelAxis, axis, orient, offset, labelNumber) {
		let d3LocaleId = d3Locale[d3Locale.localeId];

		axis
			.call(
				labelAxis
					.orient(orient)
					.ticks(labelNumber)
					.tickFormat(
						d3Locale.current.timeFormat.multi([
							[d3LocaleId.minutes, d => d.getMinutes()],
							[d3LocaleId.timeShort, d => d.getHours()],
							[d3LocaleId.dateShort, d => d.getDate()],
						])
					)
					.tickSize(0, 0, 0)
			)
			.selectAll('text')
			.attr('dy', offset);
	}

	function splitData(data, chartOptions, lineIndex) {
		let chunks = [];
		let that = this;
		let line = chartOptions.lines[lineIndex];
		let yScale = this.yAxes.find(axis => axis.id === line.chartAxisId);
		let isBinaryChart = chartOptions.chartType === that.CHART_TYPE.LINE_WITH_BINARY_STATES;
		let isControlRange = line.isControlRangeBoundary;
		let binaryLabelDisplay = isBinaryChart && BINARY_AXIS_UOM_NAMES.includes(line.uom.name);
		let extraBinaryLabelFormat = _get(line, 'fullPropertyInfo.propertyAttribute.unitOfMeasureName', null);

		// TODO: Reduce should return smth!! Use for each or smth else!
		(data || []).reduce(function(dataChunk, currentValue, index) {
			if (currentValue.y === null || currentValue.y === undefined || isNaN(currentValue.y) || !yScale) {
				if (dataChunk.length) {
					chunks.push(dataChunk);
				}

				return [];
			} else {
				if (dataChunk.length) {
					const prevValue = dataChunk[dataChunk.length - 1];
					if (currentValue.x - prevValue.x !== TIME_INTERVAL_BETWEEN_DATA_POINTS) {
						chunks.push(dataChunk);
						dataChunk = [];
					}
				}
				const yAxisData = binaryLabelDisplay
					? that.extraDataFormatting.applyBoolean(!(currentValue.y % 2 > 0), isBinaryChart && extraBinaryLabelFormat)
					: currentValue.y;
				const yScaleMin = yScale.y.domain()[0];
				const yScaleMax = yScale.y.domain()[1];
				const cx = parseInt(currentValue.x.getTime());
				const cy = yScaleMax - yScaleMin ? (100 * (yScaleMax - currentValue.y) / (yScaleMax - yScaleMin)).toFixed(1) : 0;
				const pointData = {
					name: line.name,
					lineHash: line.lineHash,
					color: line.color,
					yAxisData: yAxisData,
					uom: line.uom.symbol,
				};

				if (!isControlRange) {
					if (!that.tooltipMultiplePoints.points[cx]) {
						that.tooltipMultiplePoints.points[cx] = [];
					}
					if (!that.tooltipMultiplePoints.points[cx][cy]) {
						that.tooltipMultiplePoints.points[cx][cy] = [pointData];
					} else {
						that.tooltipMultiplePoints.points[cx][cy].push(pointData);
					}
				}

				dataChunk.push(currentValue);

				if (index === data.length - 1) {
					chunks.push(dataChunk);
				}
			}
			return dataChunk;
		}, []);

		chartOptions.lines.forEach(line => {
			if (!line.visible) {
				that.tooltipMultiplePoints.disabledLines[line.name] = true;
			}
		});

		return chunks;
	}

	function getPointsInRadius(x, y, tooltipMultiplePoints) {
		const MARGIN_TO_SHOW_POINTS = 5;

		let yNum = Number(y);
		let range = {
			min: yNum - MARGIN_TO_SHOW_POINTS,
			max: yNum + MARGIN_TO_SHOW_POINTS,
		};
		let pointsColumn = tooltipMultiplePoints.points[x];
		let points = [];

		Object.keys(pointsColumn).forEach(pointKey => {
			let pointKeyNum = Number(pointKey);

			if (pointKeyNum >= range.min && pointKeyNum <= range.max) {
				points = points.concat(pointsColumn[pointKey]);
			}
		});
		return points;
	}

	function getCorrectUomSymbol(uomSymbol) {
		// "No Unit of Measure" symbol by default is '0'.
		// It's not understandable. And it was suggested to replace it by nothing.
		const uomSymbolToBeReplaced = '0';
		return uomSymbol !== uomSymbolToBeReplaced ? uomSymbol : '';
	}

	LineChartRenderer.prototype._isPercentageDataAxis = function(isLineChart, data = {uom: {}}) {
		return isLineChart && data.uom.symbol && data.uom.symbol === '%';
	};

	window.LineChartRenderer = LineChartRenderer;
})(window.d3, window.AbstractChartRenderer);
