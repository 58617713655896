import MyViewsGroup from './my-views-group-class';

export default class MyViewsAdapterV2 {
	constructor(data) {
		this.version = MyViewsAdapterV2.VERSION;
		this.name = data.name;
		this.groups = [
			new MyViewsGroup({
				groupOperator: MyViewsGroup.AND_OPERATOR,
				ruleOperator: MyViewsGroup.OR_OPERATOR,
				rules: data.list.map(({name, equal, value}) => ({
					name,
					value,
					operator: equal,
				})),
			}),
		];
	}
}

MyViewsAdapterV2.VERSION = 2;
