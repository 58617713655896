/* eslint eqeqeq: [0, 'always'] */
/* eslint semi: [0, 'always'] */

import {USER_EVENTS} from '../../../common/usage-tracking/categories';
import {
	getTimePeriodNameByRangeMode,
	getReportNameByKey,
	getRdrFormatByFormatKey,
	getEquipmentTypeByKey,
	getYesNoFromBoolean,
	getReportGenerationStatus,
} from '../../../common/usage-tracking/categories/report-generation/utils.js';

import {rangeModeListener, rangeDateListener} from '../../../common/event-listener/date-range-listener';
const {REPORT_GENERATION: {properties: REPORT_GENERATION_PROPERTIES}} = USER_EVENTS;

angular.module('TISCC').controller('RawDataReportServerlessCtrl', RawDataReportServerlessCtrl);

export function RawDataReportServerlessCtrl(
	$scope,
	$filter,
	$timeout,
	$controller,
	$routeParams,
	modalHelperService,
	rawDataServerlessService,
	exportProgressServiceServerless,
	locationEquipmentService,
	tisObjectTypePropertiesService,
	translateService,
	helpers,
	utilityService,
	configService,
	googleAnalyticsService,
	DEFAULTS,
	REPORT_TYPES,
	MetricsFactory
) {
	angular.extend(this, $controller('AbstractReportCtrl', {$scope: $scope}));

	const isRDRDataLimitEnabled = $routeParams.isRDRDataLimitEnabled ? $routeParams.isRDRDataLimitEnabled === 'true' : true;

	let that = this;
	let translate = $filter('translate');
	let defaultExportProgressMessages = {
		message: translate('LOADING_REPORT'),
		progressPercentage: 0,
		loading: false,
	};
	const MAX_DATA_CELLS_QUANTITY = configService.getRawDataReportConfig().maxDataCellsQuantity;
	let reportGenerationStartTime = null;

	$scope.FORMAT_TYPES = {
		STANDARD: 'standard',
		SINGLE: 'single',
		STANDARD_CSV: 'standardCsv',
	};

	$scope.viewModel = {
		checkAllRight: false,
		checkAll: false,
		format: $scope.FORMAT_TYPES.STANDARD,
		isIncludeWeather: false,
		// This selection will include all raw data
		// even if it is outside of the normal range or for some reason in an unrecognizable format.
		isIncludeOutOfRangeData: true,
		isIncludeNonStandardProperties: false,
		isLoadingStarted: false,
		isSearchActive: false,
	};

	$scope.equipmentTypes = [];
	$scope.popupLeftListData = [];
	$scope.popupRightListData = [];
	$scope.filteredPopupRightListData = [];
	$scope.filteredPopupLeftListData = [];
	$scope.isGeneratingReport = false;
	$scope.selectDeselectRight = selectDeselectRight;
	$scope.updateCheckboxes = updateCheckboxes;
	$scope.selectDeselectAll = selectDeselectAll;
	$scope.updateRightCheckboxes = updateRightCheckboxes;
	$scope.updateSelectAllBtn = updateSelectAllBtn;
	$scope.onIncludeNonStandardProperties = onIncludeNonStandardProperties;
	$scope.onChangeIncludedWeather = onChangeIncludedWeather;
	$scope.rightListLoadingPromise = null;
	$scope.proceedWithRequests = null;
	$scope.cancelRequests = null;
	$scope.cancelLoading = null;
	$scope.isMultipleRequest = false;
	$scope.responsesLength = 0;
	$scope.triggerEmail = triggerEmail;
	$scope.exportProgressMessages = angular.copy(defaultExportProgressMessages);
	$scope.progressKey = null;
	initializeData();

	function initializeData() {
		// Force update the `isCellLimitExceeded` property to false
		setIsCellLimitExceeded(false);

		if (!$scope.selectedEquipmentType.initValue) {
			filterLeftPanelData();
		} else {
			updateSelectAllBtn();
		}

		locationEquipmentService.getLocationObjectsList($scope.locationId, null, true).then(function(data) {
			$scope.data.equipmentsData = data.tisObjectList;

			$scope.equipmentTypes = tisObjectTypePropertiesService.getEquipmentTypes(data.tisObjectList);

			$scope.$emit('hideEquipmentTypesLoader');

			if ($scope.defaultEquipment && ($scope.data.isChart || $scope.data.isEquipmentSummary)) {
				that.checkEquipments($scope.data.equipmentsData, $scope.defaultEquipment.tisObjectName);
				$scope.updateSelectedEquipmentType(getSelectedEquipmentType());
			}
		});
	}

	function getSelectedEquipmentType() {
		let equipments = $scope.data.equipmentsData;
		let defaultEquipment = $scope.defaultEquipment;
		let foundParent = null;

		const groupName = defaultEquipment.tisObjectType.tisObjectTypeGroupName;

		if (['LoadValve', 'VAV-BOX'].includes(groupName)) {
			foundParent = findParent(equipments, defaultEquipment.tisObjectId);
		}
		let equipment = foundParent || defaultEquipment;
		return {
			name: translateService.translateEquipment(equipment.tisObjectType.tisObjectTypeGroupName),
			id: equipment.tisObjectId,
			groupId: equipment.tisObjectType.tisObjectTypeGroupNumber,
			typeName: $scope.defaultEquipment.tisObjectType.tisObjectTypeGroupName,
			sortBy: 1,
		};
	}

	function findParent(equipments, id, parentEq) {
		let foundParent = null;
		for (let i = 0; i < equipments.length; i++) {
			let equipment = equipments[i];

			if (equipment.tisObjectId === id && parentEq) {
				foundParent = parentEq;
				break;
			} else if (equipment.children) {
				foundParent = findParent(equipment.children, id, equipment);
				if (foundParent) {
					break;
				}
			}
		}
		return foundParent;
	}

	function generateReportHandler() {
		/* eslint camelcase: 0*/
		MetricsFactory.mark(MetricsFactory.METRIC_TYPES.RDR_LOAD, {
			range_type: $scope.rangeMode,
			range_duration: $scope.range.to - $scope.range.from,
			report_type: $scope.selectedEquipmentType.name,
			objects_count: $scope.filteredPopupLeftListData.reduce((acc, value) => (value.checked ? acc + 1 : acc), 0) || 0,
			properties_count: $scope.filteredPopupRightListData.reduce((acc, value) => (value.checked ? acc + 1 : acc), 0) || 0,
		});

		const {SINGLE} = $scope.FORMAT_TYPES;
		let selectedProperties = getSelectedProperties();
		let selectedEquipments = getCheckedItems($scope.popupLeftListData);

		utilityService.getApplicationInfo();

		$scope.rightListLoadingPromise = null;
		$scope.isGeneratingReport = true;

		$scope.$emit('resetDownloadLink');

		let shouldIncludeWeatherColumns = $scope.viewModel.isIncludeWeather && $scope.viewModel.format !== SINGLE;
		googleAnalyticsService.sendFlowEvent('Report generation window', 'Start RDR generation', {
			label: '',
		});

		reportGenerationStartTime = performance.now();

		const apiData = {
			isIncludeWeather: shouldIncludeWeatherColumns,
			includeDataWithErrors: $routeParams.includeDataWithErrors || $scope.viewModel.isIncludeOutOfRangeData,
			selectedEquipments: selectedEquipments,
			from: $scope.range.from,
			to: $scope.range.to,
			reportFormat: $scope.viewModel.format,
			equipmentList: $scope.data.equipmentsData,

			locationName: $scope.data.locationData.locationName,
			locationId: $scope.data.locationData.locationId,
			equipmentType: $scope.selectedEquipmentType.name,
			isAddChildPrefixToHpath: $scope.isAddChildPrefixToHpath || false,
			isIncludeNonStandardProperties: $scope.viewModel.isIncludeNonStandardProperties,
			allProperties: $scope.viewModel.checkAllRight ? true : false,
			selectedProperties,
		};
		rawDataServerlessService
			.generateReportMetadata(apiData)
			.then(sendExportRequest)
			.then(({data}) => {
				const {progressKey} = data;
				$scope.progressKey = progressKey;
				googleAnalyticsService.sendFlowEvent('Report generation window', 'RDR generation started', {
					label: '',
				});
				$scope.viewModel.isLoadingStarted = true;
				$timeout(function() {
					generationStartedShowProgressDialog(progressKey).then(() => {
						MetricsFactory.measure(MetricsFactory.METRIC_TYPES.RDR_LOAD);
					});
				}, 3000);
			})
			.catch(result => {
				failExport(false);
			});

		const fromDate = $scope.range.from.clone().format('MM-DD-YYYY');
		const toDate = $scope.range.to
			.clone()
			.add(-1, 'day')
			.format('MM-DD-YYYY');
		$scope.trackGenerateReportEvent({
			[REPORT_GENERATION_PROPERTIES.REPORT]: getReportNameByKey(REPORT_TYPES.RAW_DATA.name),
			[REPORT_GENERATION_PROPERTIES.TIME_PERIOD]: getTimePeriodNameByRangeMode($scope.rangeMode),
			[REPORT_GENERATION_PROPERTIES.REPORT_GENERATION_STATUS]: getReportGenerationStatus('started'),
			[REPORT_GENERATION_PROPERTIES.FROM_DATE]: fromDate,
			[REPORT_GENERATION_PROPERTIES.TO_DATE]: toDate,
			[REPORT_GENERATION_PROPERTIES.EQUIPMENT_TYPE]: getEquipmentTypeByKey($scope.selectedEquipmentType.typeName),
			[REPORT_GENERATION_PROPERTIES.FORMAT]: getRdrFormatByFormatKey($scope.viewModel.format),
			[REPORT_GENERATION_PROPERTIES.INCLUDE_WEATHER_DATA]: getYesNoFromBoolean(shouldIncludeWeatherColumns),
			[REPORT_GENERATION_PROPERTIES.INCLUDE_OUT_OF_RANGE]: getYesNoFromBoolean($scope.viewModel.isIncludeOutOfRangeData),
			[REPORT_GENERATION_PROPERTIES.PROPERTIES]: getCheckedItems($scope.popupRightListData)
				.map(property => property.propertyName)
				.sort(),
		});
	}

	async function triggerEmail() {
		$scope.exportProgressMessages.loading = true;
		const response = await exportProgressServiceServerless.sendEmailRequest({
			progressKey: $scope.progressKey,
		});
		$scope.exportProgressMessages.loading = false;
		$scope.viewModel.isLoadingStarted = false;
		$scope.isGeneratingReport = false;
		$scope.$emit('generateComplete');
		$scope.$emit('hideLoader');
	}

	/**
	 * Checks if the cell limit exceeded
	 * @return {boolean}
	 *
	 * @example
	 * Calculate the cells number for the foregoing report:
	 * 14 Chillers * 792 properties * 96 timestamps per day * 28 days (4 weeks) = 29804544 data cells
	 * It's bigger than the cell limit = 1500000
	 * That's why the user will see the message and will not be able to generate the report
	 *
	 * @link https://tranetis.jira.com/browse/TCCFOUR-14600?focusedCommentId=404700
	 */
	function checkIsCellLimitExceeded() {
		if (!isRDRDataLimitEnabled) {
			return false;
		}

		const objectsQty = $scope.viewModel.checkAll === false ? 0 : getCheckedItemsCount($scope.popupLeftListData);
		const propertiesQty =
			$scope.viewModel.checkAllRight === false && $scope.viewModel.format !== $scope.FORMAT_TYPES.SINGLE ? 0 : getSelectedProperties().length;
		const timestampsQty = moment($scope.range.to).diff($scope.range.from, 'minutes') / DEFAULTS.DATA_INTERVAL_MINUTES;
		const isCellLimitExceeded = MAX_DATA_CELLS_QUANTITY < objectsQty * propertiesQty * timestampsQty;

		setIsCellLimitExceeded(isCellLimitExceeded);

		$scope.setDisableGenerateButton(isCellLimitExceeded || objectsQty === 0 || propertiesQty === 0);

		return isCellLimitExceeded;
	}

	function setIsCellLimitExceeded(isCellLimitExceeded) {
		$scope.isCellLimitExceeded = isCellLimitExceeded;
		$scope.$emit('updateIsCellLimitExceeded', {isCellLimitExceeded});
	}

	function getSelectedProperties() {
		let selectedProperties = getCheckedItems($scope.popupRightListData);
		selectedProperties = $filter('orderBy')(selectedProperties, 'value');

		return selectedProperties;
	}

	function getCheckedItems(items = []) {
		return $filter('filter')(items, {checked: true});
	}

	function getCheckedItemsCount(items = []) {
		return getCheckedItems(items).length;
	}

	/**
	 * Handles click on Include weather data checkbox
	 */
	function onChangeIncludedWeather() {
		checkIsCellLimitExceeded();
	}

	// ----------------- LEFT PANEL --------------------------------

	function filterLeftPanelData() {
		const leftPanelData = tisObjectTypePropertiesService.getLeftPanelData({
			defaultEquipment: $scope.defaultEquipment,
			selectedEquipmentType: $scope.selectedEquipmentType,
			tisObjects: $scope.data.equipmentsData,
		});
		rawDataServerlessService.setCurrentSelectedElements(leftPanelData.selectedElements);

		$scope.rightListLoadingPromise = null;
		$scope.popupLeftListData = leftPanelData.preparedTisObjects;
		$scope.selectedEquipmentType = leftPanelData.selectedEquipmentType;

		// Force update the `isCellLimitExceeded` property to false
		setIsCellLimitExceeded(false);
		updateSelectAllBtn();
		deselectRight();
		updateRightSelectAllBtn();
		$scope.setDisableGenerateButton(true);
	}

	/**
	 * Updates state of Select/Deselect All Objects checkbox
	 */
	function updateSelectAllBtn() {
		const objects = $scope.popupLeftListData;
		const objectsCount = objects.length;
		const checkedObjectsCount = getCheckedItemsCount($scope.popupLeftListData);

		if (!checkedObjectsCount) {
			$scope.viewModel.checkAll = false;
		} else if (objectsCount === checkedObjectsCount) {
			$scope.viewModel.checkAll = true;
		} else {
			$scope.viewModel.checkAll = null;
		}

		if (checkedObjectsCount) {
			$scope.rightListLoadingPromise = tisObjectTypePropertiesService
				.getRightPanelData(objects, $scope.isAddChildPrefixToHpath, $scope.viewModel.isIncludeNonStandardProperties)
				.then(successRightListLoaded);
		}
	}

	/**
	 * Checks the equality of previous and new lists of properties
	 * @param {object[]} oldList
	 * @param {object[]} newList
	 * @return {boolean}
	 */
	function isRightListActuallyUpdated(oldList, newList) {
		if (oldList.length !== newList.length) {
			return true;
		}

		const oldListProps = helpers.mapToObject(oldList, prop => [prop.propertyName, true]);

		const oldListPropsResolution = helpers.mapToObject(oldList, prop => [prop.propertyName, prop.digitResolution]);

		for (let i = 0; i < newList.length; i++) {
			if (!oldListProps[newList[i].propertyName]) {
				return true;
			}
			if (oldListPropsResolution[newList[i].propertyName] !== newList[i].digitResolution) {
				return true;
			}
		}

		return false;
	}

	function successRightListLoaded(newProperties = []) {
		const prevProperties = $scope.popupRightListData;

		if (isRightListActuallyUpdated(prevProperties, newProperties)) {
			$scope.popupRightListData = newProperties;
			$scope.$emit('rightListUpdated');

			checkIsCellLimitExceeded();
		} else {
			$scope.popupRightListData = prevProperties;
		}

		$scope.rightListLoadingPromise = null;

		updateRightSelectAllBtn();
	}

	/**
	 * Handles click on an object checkbox
	 */
	function updateCheckboxes() {
		updateSelectAllBtn();
		checkIsCellLimitExceeded();
	}

	/**
	 * Handles click on Select/Deselect All Objects checkbox
	 * Selects/Deselects filtered objects list
	 * @param {boolean} status
	 */
	function selectDeselectAll(status = false) {
		const objects = $scope.filteredPopupLeftListData;
		const objectsCount = objects.length;

		// unchecks the filtered objects, when the search filter is applied and all of filtered objects are checked
		if (status && objectsCount && objects.every(prop => prop.checked === true)) {
			status = false;
		}

		for (let i = objectsCount; i--; ) {
			objects[i].checked = status;
		}

		deselectRight();
		updateSelectAllBtn();
		checkIsCellLimitExceeded();

		googleAnalyticsService.events.RDR.selectDeselectAllObjects(status);
	}

	// ----------------- RIGHT PANEL --------------------------------

	/**
	 * Updates state of Select/Deselect All Properties checkbox
	 */
	function updateRightSelectAllBtn() {
		const properties = $scope.popupRightListData;
		const propertiesCount = properties.filter(item => !item.disabled).length;
		const checkedPropertiesCount = getCheckedItemsCount(properties);

		if (!checkedPropertiesCount) {
			$scope.viewModel.checkAllRight = false;
		} else if (propertiesCount === checkedPropertiesCount) {
			$scope.viewModel.checkAllRight = true;
		} else {
			$scope.viewModel.checkAllRight = null;
		}
	}

	/**
	 * Deselects all properties
	 */
	function deselectRight() {
		const properties = $scope.popupRightListData;
		const propertiesCount = properties.length;

		for (let i = propertiesCount; i--; ) {
			properties[i].checked = false;
		}
	}

	/**
	 * Handles click on Select/Deselect All Properties checkbox
	 * @param {boolean} status
	 */
	function selectDeselectRight(status) {
		const properties = $scope.filteredPopupRightListData;
		const propertiesCount = properties.length;

		// unchecks only filtered properties, when search filter is applied and all filtered properties are checked
		if (status && propertiesCount && properties.every(prop => prop.checked === true)) {
			status = false;
		}

		for (let i = propertiesCount; i--; ) {
			properties[i].checked = status;
		}

		updateRightSelectAllBtn();
		checkIsCellLimitExceeded();
	}

	/**
	 * Handles click on a property checkbox/radio
	 * @param {object} item
	 */
	function updateRightCheckboxes(item) {
		// when the properties list rendered as radio buttons
		// only one can be selected
		if ($scope.viewModel.format === $scope.FORMAT_TYPES.SINGLE) {
			// uncheck all, then check current
			const checked = item.checked;

			deselectRight();

			item.checked = checked;
		} else {
			updateRightSelectAllBtn();
		}

		checkIsCellLimitExceeded();

		googleAnalyticsService.events.RDR.selectObject(item.propertyName || '', item.tisObjectType.tisObjectTypeGroupName || '');
	}

	function onIncludeNonStandardProperties() {
		$timeout(updateSelectAllBtn);
	}

	// ----------------- EXPORT PROGRESS ------------------------------
	function _resetModalValues() {
		$scope.exportProgressMessages = angular.copy(defaultExportProgressMessages);
	}

	function finalizeExport(input = {}) {
		googleAnalyticsService.sendFlowEvent('Report generation window', 'RDR generation finished: success', {
			label: '',
			value: 1,
			progressKey: exportProgressServiceServerless.getProgressKey() || null,
		});

		const {progressPercentage, message, data} = input;
		if (progressPercentage) {
			$scope.exportProgressMessages.progressPercentage = progressPercentage;
			if (progressPercentage === 100) startDownloadProcess(data);
		}
		if (message) $scope.exportProgressMessages.message = message;

		$scope.isGeneratingReport = false;
		_resetModalValues();

		let timeTaken = performance.now() - reportGenerationStartTime;
		const fromDate = $scope.range.from.clone().format('MM-DD-YYYY');
		const toDate = $scope.range.to
			.clone()
			.add(-1, 'day')
			.format('MM-DD-YYYY');
		$scope.trackGenerateReportEvent({
			[REPORT_GENERATION_PROPERTIES.REPORT]: getReportNameByKey(REPORT_TYPES.RAW_DATA.name),
			[REPORT_GENERATION_PROPERTIES.TIME_PERIOD]: getTimePeriodNameByRangeMode($scope.rangeMode),
			[REPORT_GENERATION_PROPERTIES.FROM_DATE]: fromDate,
			[REPORT_GENERATION_PROPERTIES.TO_DATE]: toDate,
			[REPORT_GENERATION_PROPERTIES.TOTAL_TIME]: timeTaken,
			[REPORT_GENERATION_PROPERTIES.REPORT_GENERATION_STATUS]: getReportGenerationStatus('success'),
			[REPORT_GENERATION_PROPERTIES.EQUIPMENT_TYPE]: getEquipmentTypeByKey($scope.selectedEquipmentType.typeName),
			[REPORT_GENERATION_PROPERTIES.FORMAT]: getRdrFormatByFormatKey($scope.viewModel.format),
			[REPORT_GENERATION_PROPERTIES.INCLUDE_OUT_OF_RANGE]: getYesNoFromBoolean($scope.viewModel.isIncludeOutOfRangeData),
			[REPORT_GENERATION_PROPERTIES.PROPERTIES]: getCheckedItems($scope.popupRightListData)
				.map(property => property.propertyName)
				.sort(),
		});
	}

	/**
	 * @param {Object} [input = {}]
	 */
	function showExportProgress(input = {}) {
		if (input.emailRequested) return;
		const {progressPercentage, message} = input;
		if (message) {
			$scope.exportProgressMessages.message = message;
		}
		if (progressPercentage) {
			$scope.exportProgressMessages.progressPercentage = progressPercentage;
		}
	}

	/**
	 * @param {boolean} [showPopup = true]
	 * @param {(string | boolean)} [message = false]
	 */
	function failExport(showPopup = true, message = false) {
		googleAnalyticsService.sendFlowEvent('Report generation window', 'RDR generation finished: failure', {
			label: '',
			value: 0,
			progressKey: exportProgressServiceServerless.getProgressKey(),
		});
		$scope.isGeneratingReport = false;
		$scope.viewModel.isLoadingStarted = false;
		$scope.$emit('hideLoader');
		_resetModalValues();
		if (showPopup) {
			modalHelperService.open({
				templateUrl: 'common/messages/rdr-error-message.html',
				controller: 'ErrorMessageCtrl',
				backdrop: 'static',
				resolve: {
					details: function() {
						return message || $filter('translate')('SEND_REQUEST_FAILED');
					},
				},
				scope: $scope,
			});
		}

		let timeTaken = performance.now() - reportGenerationStartTime;
		const fromDate = $scope.range.from.clone().format('MM-DD-YYYY');
		const toDate = $scope.range.to
			.clone()
			.add(-1, 'day')
			.format('MM-DD-YYYY');
		$scope.trackGenerateReportEvent({
			[REPORT_GENERATION_PROPERTIES.REPORT]: getReportNameByKey(REPORT_TYPES.RAW_DATA.name),
			[REPORT_GENERATION_PROPERTIES.TIME_PERIOD]: getTimePeriodNameByRangeMode($scope.rangeMode),
			[REPORT_GENERATION_PROPERTIES.FROM_DATE]: fromDate,
			[REPORT_GENERATION_PROPERTIES.TO_DATE]: toDate,
			[REPORT_GENERATION_PROPERTIES.TOTAL_TIME]: timeTaken,
			[REPORT_GENERATION_PROPERTIES.REPORT_GENERATION_STATUS]: getReportGenerationStatus('failed'),
			[REPORT_GENERATION_PROPERTIES.EQUIPMENT_TYPE]: getEquipmentTypeByKey($scope.selectedEquipmentType.typeName),
			[REPORT_GENERATION_PROPERTIES.FORMAT]: getRdrFormatByFormatKey($scope.viewModel.format),
			[REPORT_GENERATION_PROPERTIES.INCLUDE_OUT_OF_RANGE]: getYesNoFromBoolean($scope.viewModel.isIncludeOutOfRangeData),
			[REPORT_GENERATION_PROPERTIES.PROPERTIES]: getCheckedItems($scope.popupRightListData)
				.map(property => property.propertyName)
				.sort(),
		});
	}

	/**
	 * @param data
	 * @returns {*}
	 */
	function sendExportRequest(data) {
		return rawDataServerlessService.sendExportRequest(data);
	}

	function startDownloadProcess(data) {
		let progressKey = exportProgressServiceServerless.getProgressKey();
		if (progressKey && data) {
			$scope.submitExportFormServerless(data);
		} else {
			failExport(true, false);
		}
	}

	$scope.cancelLoading = function() {
		$scope.exportProgressMessages.loading = true;
		exportProgressServiceServerless
			.sendAbortRequest($scope.progressKey)
			.then(() => {
				$scope.progressKey = null;
				$scope.exportProgressMessages.loading = false;
				$scope.viewModel.isLoadingStarted = false;
				$scope.isGeneratingReport = false;
				$scope.$emit('hideLoader');
			})
			.catch(() => {});
	};
	function generationStartedShowProgressDialog(progressKey) {
		function failExportWrapper(message) {
			return failExport(true, message);
		}
		return exportProgressServiceServerless.open(progressKey).then(finalizeExport, failExportWrapper, showExportProgress);
	}

	$scope.$on('updateSelectedEquipmentType', filterLeftPanelData);
	$scope.$on('generateReport', generateReportHandler);
	$scope.$watch('viewModel.format', function(newValue, oldValue) {
		if ([newValue, oldValue].includes($scope.FORMAT_TYPES.SINGLE)) {
			$scope.viewModel.isIncludeWeather = false;

			deselectRight();
			updateRightSelectAllBtn();
			setIsCellLimitExceeded(false);
			$scope.setDisableGenerateButton(true);
		}
	});

	$scope.$watch('isGeneratingReport', function(newValue, oldValue) {
		if (newValue === oldValue) {
			return;
		}

		return $scope.setDisableGenerateButton($scope.isGeneratingReport);
	});

	rangeModeListener($scope, $scope.setGlobalDateRange);
	rangeDateListener($scope, $scope.setGlobalDateRange);

	$scope.$watch('range.from.format("x")/range.to.format("x")', function(newValue, oldValue) {
		if (newValue === oldValue) {
			return;
		}

		checkIsCellLimitExceeded();

		googleAnalyticsService.events.RDR.changeDate($scope.range.from, $scope.range.to);
	});
}
