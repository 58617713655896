const ErrorPageFactory = function($location) {
	const DEFAULT_ERROR_MESSAGE_KEY = 'pageDoesntExist';
	const errorMessageMap = {
		invalidDate: 'ERROR_INVALID_DATE',
		invalidDateFuture: 'ERROR_INVALID_DATE_FUTURE',
		invalidDateFormat: 'ERROR_INVALID_DATE_FORMAT',
		pageDoesntExist: 'ERROR_PAGE_DOESNT_EXIST',
	};
	let errorMessageKey;
	let backUrl;
	let pageType;

	return {
		createErrorPage: function(key, url, type) {
			if (!url) url = $location.path();
			errorMessageKey = key;
			backUrl = url;
			pageType = type;
		},
		getMessage: function() {
			errorMessageKey = errorMessageKey || DEFAULT_ERROR_MESSAGE_KEY;
			return errorMessageMap[errorMessageKey];
		},
		getBackUrl: function() {
			return backUrl;
		},
		getPageType: function() {
			return pageType;
		},
		clear: function() {
			errorMessageKey = DEFAULT_ERROR_MESSAGE_KEY;
			backUrl = null;
			pageType = null;
		},
	};
};

angular.module('TISCC').factory('ErrorPageFactory', ErrorPageFactory);
