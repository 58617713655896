angular.module('TISCC').directive('alignMoreInfoPopup', alignMoreInfoPopupDirective);

/**
 * Align popup depending on the remaining space.
 */
function alignMoreInfoPopupDirective($timeout) {
	return {
		restrict: 'A',
		scope: {
			alignMoreInfoPopup: '<',
			facility: '<',
		},
		link: function(scope, element, attrs) {
			const {
				alignMoreInfoPopup: {
					clickTargetContainerPath,
					scrollableContainerPath,
					addTargetOffsetTopToCalculation = false,
					alignHorizontally = false,
					alignHorizontallyBase = 'right',
				},
				facility,
			} = scope;
			const target = element[0].previousElementSibling; // element which was clicked to show popup
			const clickTargetContainer = target.closest(clickTargetContainerPath);
			const scrollableContainer = target.closest(scrollableContainerPath);
			const lengthToTop = clickTargetContainer.offsetTop + (addTargetOffsetTopToCalculation ? target.offsetTop : 0) - scrollableContainer.scrollTop;
			const lengthToBottom = scrollableContainer.offsetHeight - lengthToTop;
			const lengthToRight =
				scrollableContainer.clientWidth -
				(clickTargetContainer.offsetLeft + (alignHorizontallyBase === 'right' ? clickTargetContainer.offsetWidth : 0));

			// Add firstly the "top" and "left" classes to the popup window just to prevent flickering of scrollbars
			// if the popup resides behind the visible area.
			facility.popupClass = 'top left';

			// First of all we need to render popup with "visibility: hidden" style, then calculate the height
			// and only then decide where it should be shown - at the top or at the bottom
			// That's why the timeout is used to calculate the popup height.
			$timeout(() => {
				const offset = 35; // the popup window offset to the bottom
				const popup = element[0];
				const popupHeight = popup.offsetHeight + offset;
				const popupWidth = popup.offsetWidth;

				// Add the "top" class if there is no place to show popup at the bottom
				facility.popupClass = lengthToBottom < popupHeight && lengthToTop > popupHeight ? 'top' : '';
				facility.popupClass += ' visible';

				if (alignHorizontally && lengthToRight < popupWidth) {
					facility.popupClass += ' left';
				}
			}, 100);
		},
	};
}
