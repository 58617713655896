import {CPR_DATE_FORMATS} from '../../../chiller-performance-report-config';
import _get from 'lodash.get';

(function() {
	const calculateDiffWithThisMonth = (data, current, original) => {
		if (data && data[current] && data[current].data && data[original] && data[original].data) {
			data[current].percentDiff = 100 * ((data[original].data - data[current].data) / data[current].data);
			data[current].percent = Math.abs(Math.round(data[current].percentDiff));
		}
	};

	const calculateDateDiff = ({calculatedRange = {}} = {}) => {
		let dateDiff = 0;
		const {toDate, fromDate} = calculatedRange;
		if (fromDate && toDate) {
			dateDiff = toDate.diff(fromDate, 'days');
			dateDiff = dateDiff ? dateDiff + 1 : dateDiff;
		}
		return dateDiff;
	};

	class ChillerRunHoursAndStartsComponentController {
		constructor(helpers) {
			this.isNumber = helpers.isNumber;
			this.PLACE_HOLDER = '--';
			this.compressorDetail = {
				circuitInstance: 'CKT',
				instance: '',
				type: 'Compressor',
			};
		}

		showAsterisk(data) {
			const fallback = _get(data, 'fallback', false);
			return fallback ? '*' : '';
		}

		$onInit() {
			if (Array.isArray(this.sectionData) && this.sectionData.length) {
				const {compressorDetail, ...data} = this.sectionData.reduce(
					(previousData, sectionDataItem) => {
						const dataKeys = Object.keys(sectionDataItem);
						dataKeys.forEach(dataKey => {
							previousData[dataKey] = sectionDataItem[dataKey];
						});
						return previousData;
					},
					{
						totalRunHoursThisMonth: {},
						totalRunHoursLastMonth: {},
						totalRunHoursTotalLife: {},
						totalStartsThisMonth: {},
						totalStartsLastMonth: {},
						totalStartsTotalLife: {},
						compressorDetail: {},
					}
				);

				if (compressorDetail) this.compressorDetail = compressorDetail;

				// These values are mentioned by user and must be added to totalRunHoursTotalLife and totalStartsTotalLife respectively.
				const {totalRunHours = 0, totalStarts = 0} = this.chillerPerformanceReportData || {};

				// As total Run Hours and Starts are being calculated at UI, No need to add totalRunHours / totalStarts (from api) with its calcualted data

				// data.totalRunHoursTotalLife.data = data.totalRunHoursTotalLife.data.toFixed(2) * 1;
				// data.totalStartsTotalLife.data = (data.totalStartsTotalLife.data || 0) + totalStarts;

				this.lastMonthTitle =
					data.totalRunHoursLastMonth.calculatedRange &&
					data.totalRunHoursLastMonth.calculatedRange.fromDate.format(CPR_DATE_FORMATS.MEDIUM_MONTH_YEAR_FORMAT);

				const getDivisionResultOrNull = (x1, x2) => {
					const {isNumber} = this;
					const isZero = x => x && x.toString() === '0';

					if (isNumber(x1) && isNumber(x2) && !isZero(x2)) {
						return isZero(x1) ? 0 : (x1 / x2).toFixed(2) * 1;
					}

					if (!isNumber(x1)) return null;

					if (isZero(x2)) return x1;

					return null;
				};

				this.isfallBackCalculated = data.totalRunHoursTotalLife.fallback;

				data.averageRunHoursStartThisMonth = {
					data: getDivisionResultOrNull(data.totalRunHoursThisMonth.data, data.totalStartsThisMonth.data),
				};
				data.averageRunHoursStartLastMonth = {
					data: getDivisionResultOrNull(data.totalRunHoursLastMonth.data, data.totalStartsLastMonth.data),
				};
				data.averageRunHoursStartTotalLife = {
					fallback: data.totalRunHoursTotalLife.fallback,
					data: getDivisionResultOrNull(data.totalRunHoursTotalLife.data, data.totalStartsTotalLife.data),
				};
				data.averageDailyStartsThisMonth = {
					data: getDivisionResultOrNull(data.totalStartsThisMonth.data, calculateDateDiff(data.totalStartsThisMonth)),
				};
				data.averageDailyStartsLastMonth = {
					data: getDivisionResultOrNull(data.totalStartsLastMonth.data, calculateDateDiff(data.totalStartsLastMonth)),
				};

				data.averageDailyStartsTotalLife = {
					fallback: data.totalStartsTotalLife.fallback,
					data: getDivisionResultOrNull(data.totalStartsTotalLife.data, calculateDateDiff(data.totalStartsTotalLife)),
				};

				calculateDiffWithThisMonth(data, 'totalRunHoursLastMonth', 'totalRunHoursThisMonth');
				calculateDiffWithThisMonth(data, 'totalStartsLastMonth', 'totalStartsThisMonth');
				calculateDiffWithThisMonth(data, 'averageDailyStartsLastMonth', 'averageDailyStartsThisMonth');
				calculateDiffWithThisMonth(data, 'averageRunHoursStartLastMonth', 'averageRunHoursStartThisMonth');
				this.data = [
					{
						order: 1,
						title: 'TOTAL_RUN_HOURS',
						thisMonth: data.totalRunHoursThisMonth,
						lastMonth: data.totalRunHoursLastMonth,
						totalLife: data.totalRunHoursTotalLife,
					},
					{
						order: 2,
						title: 'TOTAL_STARTS',
						thisMonth: data.totalStartsThisMonth,
						lastMonth: data.totalStartsLastMonth,
						totalLife: data.totalStartsTotalLife,
					},
					{
						order: 3,
						title: 'AVERAGE_DAILY_STARTS',
						thisMonth: data.averageDailyStartsThisMonth,
						lastMonth: data.averageDailyStartsLastMonth,
						totalLife: data.averageDailyStartsTotalLife,
					},
					{
						order: 4,
						title: 'AVERAGE_RUN_HOURS_START',
						thisMonth: data.averageRunHoursStartThisMonth,
						lastMonth: data.averageRunHoursStartLastMonth,
						totalLife: data.averageRunHoursStartTotalLife,
					},
				];
			} else {
				this.data = [
					{
						order: 1,
						title: 'TOTAL_RUN_HOURS',
						thisMonth: this.PLACE_HOLDER,
						lastMonth: this.PLACE_HOLDER,
						totalLife: this.PLACE_HOLDER,
					},
					{
						order: 2,
						title: 'TOTAL_STARTS',
						thisMonth: this.PLACE_HOLDER,
						lastMonth: this.PLACE_HOLDER,
						totalLife: this.PLACE_HOLDER,
					},
					{
						order: 3,
						title: 'AVERAGE_DAILY_STARTS',
						thisMonth: this.PLACE_HOLDER,
						lastMonth: this.PLACE_HOLDER,
						totalLife: this.PLACE_HOLDER,
					},
					{
						order: 4,
						title: 'AVERAGE_RUN_HOURS_START',
						thisMonth: this.PLACE_HOLDER,
						lastMonth: this.PLACE_HOLDER,
						totalLife: this.PLACE_HOLDER,
					},
				];
			}
		}
	}

	angular.module('TISCC').component('chillerRunHoursAndStartsComponent', {
		templateUrl: 'components/reports/chiller-performance/page/report-section/chiller-run-hours-and-starts/chiller-run-hours-and-starts-component.html',
		controller: ChillerRunHoursAndStartsComponentController,
		bindings: {
			sectionHeader: '<',
			sectionData: '<',
			chillerPerformanceReportData: '<',
		},
	});
})();
