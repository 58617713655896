export const getViewNameByQueryParameter = parameter => {
	const TILE = 'Tile';
	const LIST = 'List';

	switch (parameter) {
		case 'tiles':
			return TILE;
		case 'list':
			return LIST;
	}

	return LIST;
};
