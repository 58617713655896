import {USER_EVENTS} from '../../../common/usage-tracking/categories';
import {CURRENT_PAGE, PRIMARY_OFFERING} from '../../../common/usage-tracking/common/properties-names';
import {PRIMARY_OFFERINGS} from '../../../common/usage-tracking/common/primary-offerings';
import _get from 'lodash.get';

const {
	EQUIPMENT_PERFORMANCE_CHARTS: {
		events: EQUIPMENT_PERFORMANCE_CHARTS_EVENTS,
		properties: EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES,
		categoryName: EQUIPMENT_PERFORMANCE_CHARTS_CATEGORY_NAME,
	},
	NAVIGATION: {events: NAVIGATION_EVENTS},
} = USER_EVENTS;

angular.module('TISCC').controller('EquipmentChartCtrl', EquipmentChartCtrl);

function EquipmentChartCtrl(
	$rootScope,
	$scope,
	$filter,
	$controller,
	$routeParams,
	$route,
	$location,
	locationEquipmentService,
	locationDetailsService,
	helpers,
	configService,
	ChartFactory,
	urlService,
	usageTrackingService,
	CONTROLLER_CHART_TYPES,
	instanceBasedChartService,
	ENVIRONMENT
) {
	angular.extend(this, $controller('AbstractChartCtrl', {$scope: $scope}));

	const lastRoute = $route.current;

	// Public vars
	let tisObjectsHierarchy;
	$scope.equipmentLevel = $route.current.$$route.equipmentLevel;
	$scope.equipmentTypeChart = $route.current.$$route.equipmentType; // check for the chart
	$scope.isCprAllowed = locationDetailsService.isCprAllowed;
	$scope.isDirAllowed = locationDetailsService.isDirAllowed;
	$scope.isOfferingExpired = locationDetailsService.isOfferingExpired;
	$scope.EVENTS = {...EQUIPMENT_PERFORMANCE_CHARTS_EVENTS, ...NAVIGATION_EVENTS};
	$scope.PROPERTIES = EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES;
	$scope.PRIMARY_OFFERING = PRIMARY_OFFERING;
	$scope.PRIMARY_OFFERINGS = PRIMARY_OFFERINGS;
	$scope.environment = configService.getEnvironmentType();
	$scope.trackOnLinkClick = $rootScope.getTrackOnLinkClickHandler(EQUIPMENT_PERFORMANCE_CHARTS_CATEGORY_NAME);
	$scope.trackEvent = usageTrackingService.trackEventByCategory(EQUIPMENT_PERFORMANCE_CHARTS_CATEGORY_NAME, {
		[CURRENT_PAGE]: _get($route, 'current.metricsConfig.pathName', $route.current.templateUrl),
	});
	const chillerSubComponents = {
		Circuit: [],
		Compressor: [],
	};
	// This property is used to display the loader on Chart page while changing the direction of timeline slider.
	$rootScope.timelineRangeDirection = null;

	$scope.trackChartTimePeriodChange = (chart, direction) => {
		$rootScope.timelineRangeDirection = direction;

		$scope.trackEvent(EQUIPMENT_PERFORMANCE_CHARTS_EVENTS.CHANGE_TIME_PERIOD_OF_CHART, {
			[PRIMARY_OFFERING]: PRIMARY_OFFERINGS.IS,
			[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_NAME]: chart.chartObj.selectedChart.title,
			[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.DIRECTION]: direction,
		});
	};
	$scope.trackChartTimePeriodZoom = helpers.debounce(chart => {
		$scope.trackEvent(EQUIPMENT_PERFORMANCE_CHARTS_EVENTS.ZOOM_TIME_PERIOD_OF_CHART, {
			[PRIMARY_OFFERING]: PRIMARY_OFFERINGS.IS,
			[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_NAME]: chart.chartObj.selectedChart.title,
		});
	}, 100);
	$scope.trackCreateFindingLinkClick = helpers.debounce(chart => {
		$scope.trackEvent(EQUIPMENT_PERFORMANCE_CHARTS_EVENTS.CREATE_FINDING_FROM_CHART, {
			[PRIMARY_OFFERING]: PRIMARY_OFFERINGS.IS,
			[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_NAME]: chart.chartObj.selectedChart.title,
			[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.EQUIPMENT_TYPE]: chart.chartObj.selectedEquipment.tisObjectType.tisObjectTypeGroupName,
		});
	}, 100);

	const addChangeChart = $scope.generateAddChartFunction(CONTROLLER_CHART_TYPES.EQUIPMENT_CHART, ChartFactory);
	let chart = null;

	if ($scope.passedChartState) {
		const topChartState = $scope.passedChartState.shift();
		const {getAppropriateRange} = $scope;

		chart = ChartFactory.getNewEquipmentChart(
			getAppropriateRange(topChartState.range),
			topChartState.locationId,
			topChartState.chartId,
			parseInt(topChartState.equipmentId) || null,
			parseInt($routeParams.equipmentTypeNumber) || null,
			topChartState.equipmentTypeChart,
			parseInt(topChartState.chartIndex),
			// To support instance level chart (ex : Motor power performance)
			topChartState.selectedInstanceEquipmentType || null,
			topChartState.instanceName || null,
			parseInt(topChartState.instanceId) || null
		);

		chart.passedChartState = topChartState;
		$scope.charts = [chart];

		$scope.passedChartState.forEach(state => {
			const chart = addChangeChart({
				locationId: state.locationId,
				chartId: state.chartId,
				equipmentId: parseInt(state.equipmentId) || null,
				equipmentTypeNumber: parseInt($routeParams.equipmentTypeNumber) || null,
				equipmentTypeChart: state.equipmentTypeChart,
				range: getAppropriateRange(state.range),
				chartIndex: parseInt(state.chartIndex),
				// To support instance level chart (ex : Motor power performance)
				selectedInstanceEquipmentType: state.selectedInstanceEquipmentType || null,
				instanceName: state.instanceName || null,
				instanceId: parseInt(state.instanceId) || null,
			});

			chart.passedChartState = state;
		});
	} else {
		const newChartIndex = $scope.getNewChartIndex();

		/**
		 *  On Page load - route params are injected  & initailized into chartObj which will be used later to get data / render chart properly
		 *
		 * Initalized params if available in route
		 *
		 *  facility id
		 * 	chart id
		 * 	equipment Id
		 * 	equipment Type Number
		 *  instance Type (Compressor for now)
		 *  instance name (1A / 2B ...)
		 *  instance id
		 *
		 * */
		chart = ChartFactory.getNewEquipmentChart(
			this.initRange,
			$routeParams.locationId,
			$routeParams.chartId,
			parseInt($routeParams.equipmentId) || null,
			parseInt($routeParams.equipmentTypeNumber) || null,
			$scope.equipmentTypeChart,
			newChartIndex,
			// To support instance level chart (ex : Motor power performance)
			$routeParams.selectedInstanceEquipmentType || null,
			$routeParams.instanceName || null,
			parseInt($routeParams.instanceId) || null
		);

		$scope.charts = [chart];
	}

	$rootScope.isExport && $scope.charts.forEach($scope.addEventListenersToChartForExport);

	if (chart.eventObject) {
		$scope.addCommonEventListenersToChart(chart);

		chart.eventObject.on('rangeReady', () => {
			if ($scope.equipmentLevel) {
				$scope.equipmentId = chart.chartObj.equipmentId;
			} else if ($scope.equipmentTypeChart) {
				$scope.equipmentType = chart.chartObj.equipmentType;
				// for beta version
				$scope.equipmentTypeChartBeta = true;
			}
		});

		chart.eventObject.on('addChart', () => {
			const {chartObj: {locationId, chartId, equipmentId, selectedInstanceEquipmentType, instanceName, instanceId}, range} = chart;

			$scope.trackAddChartButtonClick(chart);

			addChangeChart(
				{
					range: $scope.getAppropriateRange(range),
					locationId,
					chartId,
					equipmentId,
					equipmentTypeNumber: parseInt($routeParams.equipmentTypeNumber) || null,
					equipmentTypeChart: $scope.equipmentTypeChart,
					chartIndex: $scope.getNewChartIndex(),
					// To support instance level chart (ex : Motor power performance)
					selectedInstanceEquipmentType: selectedInstanceEquipmentType || null,
					instanceName: instanceName || null,
					instanceId: instanceId || null,
				},
				chart
			);
		});
	}

	/**
	 * Watchers
	 */
	// Show Digital Inspection Button only for Chillers
	$scope.$watchGroup(['chartObj.selectedEquipment'], (newValues, oldValues) => {
		if ($scope.chartObj && $scope.chartObj.selectedEquipment) {
			$scope.groupName = $scope.chartObj.selectedEquipment.tisObjectType.tisObjectTypeGroupName;
		}
	});

	$scope.$watchGroup(
		[
			'chartObj.locationId',
			'chartObj.equipmentType',
			'chartObj.chartId',
			'chartObj.equipmentId',
			'chartObj.selectedInstanceEquipmentType',
			'chartObj.instanceName',
			'chartObj.instanceId',
		],
		(newValues, oldValues) => {
			let [locationId, equipmentType, chartId, equipmentId, selectedInstanceEquipmentType, instanceName, instanceId] = newValues;
			let [oldLocationId, , oldChartId, oldEquipmentId] = oldValues;
			let oldPath = $location.path();
			let newPath;
			// Clear old equipment Count from Scope
			delete $scope.equipmentSACount;
			delete $scope.equipmentServiceAdvisoryCount;
			delete $scope.groupName;

			if (angular.equals(newValues, oldValues)) {
				return;
			}

			if ($scope.equipmentTypeChart) {
				// Genreate route path based on equipment type (ex : VAV, AHU...)

				newPath = `/facility/${locationId}/equipment-type/${equipmentType}/chart/${chartId}`;
			} else if (selectedInstanceEquipmentType && instanceName && instanceId) {
				// Generate route path if any instance wise chart selected (ex : Moto power peformance)

				newPath = instanceBasedChartService.createInstanceRoute({
					locationId,
					equipmentId,
					chartId,
					selectedInstanceEquipmentType,
					instanceName,
					instanceId,
				});
			} else {
				// Generate route for all equipment

				newPath = `/facility/${locationId}/equipment/${equipmentId}/chart/${chartId}`;
			}

			if (equipmentId && equipmentId !== oldEquipmentId) {
				$scope.equipmentId = equipmentId;
				getEquipmentSACount(equipmentId);
				locationEquipmentService.getEquipmentNotesCount(equipmentId, val => {
					$scope.charts[0].setEquipmentNotesCountInScope(val);
				});
			}
			if (equipmentId !== oldEquipmentId && oldChartId !== chartId) {
				if (helpers.objectHasProperty($scope, 'chartObj.timeline')) {
					$scope.chartObj.timeline.lanes = [];
				}
			}

			if (locationId && oldLocationId && locationId !== oldLocationId) {
				$scope.locationId = locationId;
				$scope.equipmentServiceAdvisoryCount = '';
				$scope.equipmentNotesCount = '';
			}

			// Keep date range between selections
			$scope.chartConfig.clear();
			if (oldPath !== newPath) {
				urlService.changePath(newPath);
			}
		}
	);
	function getEquipmentSACount(equipmentId) {
		locationEquipmentService.getLocationObjectsList($routeParams.locationId, null, true).then(function(data) {
			let groupNames = [];
			tisObjectsHierarchy = data.tisObjectList;
			$scope.equipment = $filter('filterNested')(tisObjectsHierarchy, {tisObjectId: parseInt(equipmentId)}, true).pop();

			$scope.groupName = $scope.equipment.tisObjectType.tisObjectTypeGroupName;

			const workingTisObjectIds = [$scope.equipment.tisObjectId];

			if ($scope.groupName === 'Chiller') {
				groupNames = [$scope.groupName, 'Circuit', 'Compressor'];
				saveChillerSubComponents($scope.equipment.children);

				Object.keys(chillerSubComponents).forEach(key =>
					chillerSubComponents[key].forEach(equipment => workingTisObjectIds.push(equipment.tisObjectId))
				);
			} else {
				groupNames = [$scope.groupName];
			}
			workingTisObjectIds.map(tisObjectId => {
				locationEquipmentService.getEquipmentServiceAdvisoryCount(tisObjectId, function(result) {
					if ($scope.equipmentSACount) {
						$scope.equipmentSACount = result.serviceAdvisoryStatisticsList[0].serviceAdvisoriesCount + $scope.equipmentServiceAdvisoryCount;
						$scope.charts[0].setEquipmentServiceAdvisoryCountInScope($scope.equipmentSACount);
					} else {
						$scope.equipmentSACount = result.serviceAdvisoryStatisticsList[0].serviceAdvisoriesCount;
						$scope.charts[0].setEquipmentServiceAdvisoryCountInScope($scope.equipmentSACount);
					}
				});
			});
		});
	}

	function saveChillerSubComponents(tisObjects = []) {
		tisObjects.forEach(function(tisObject) {
			if (chillerSubComponents[tisObject.tisObjectType.tisObjectTypeGroupName]) {
				chillerSubComponents[tisObject.tisObjectType.tisObjectTypeGroupName].push(tisObject);
			}
			if (tisObject.children) {
				saveChillerSubComponents(tisObject.children);
			}
		});
	}

	/**
	 * Avoid controller reload when user changes chart or equipment (saves few API calls)
	 */
	$scope.$on('$locationChangeSuccess', function() {
		const isNeedToReloadCtrl = $route.current.params.reload === 'true';
		const isTheSameCtrl = helpers.getPropertyByPath($route.current, '$$route.controller') === 'EquipmentChartCtrl';

		if (isTheSameCtrl && !isNeedToReloadCtrl) {
			$route.current = lastRoute;
		} else if (isNeedToReloadCtrl) {
			// clean up history and url from 'reload=true' query parameter
			$location.replace();
			$location.search('reload', null);
		}
	});
}
