/* eslint eqeqeq: [0, 'always'] */
/* eslint max-nested-callbacks: ["error", 5]*/
import _get from 'lodash.get';
import * as R from 'ramda';

import {DEFAULT_DESIGN_VALUE} from './chiller-performance-report-config';
import {CPR_DATE_FORMATS} from './chiller-performance-report-config';

export const SYSTEM_OF_MEASURE_LIST = [{name: 'Imperial (IP)', value: 'ip'}, {name: 'Metric (SI)', value: 'si'}];
export const DEFAULT_SYSTEM_OF_MEASURE = SYSTEM_OF_MEASURE_LIST[0];
export const REPORT_LANGUAGE_LIST = [
	{name: 'English', value: 'en', system: SYSTEM_OF_MEASURE_LIST[0]},
	{name: 'French (Canadian)', value: 'fr', system: SYSTEM_OF_MEASURE_LIST[1]},
];
export const DEFAULT_REPORT_LANGUAGE = REPORT_LANGUAGE_LIST[0];

export const COMP_COMMUNICATION_STATE_DOWN = 'Down';
export const COMP_TOTAL_LIFE = 'totalLife';
export const COMP_TOTALS_STARTS_TOTAL_LIFE = 'totalStartsTotalLife';
export const CHILLER_ENTERED_SERVICE = 'ChillerEnteredService';
const PLACE_HOLDER = '--';

const services = new WeakMap();
const CHILLER_LOAD_PROPERTY = 'ChillerCurrentEnteringDrawRla';
const HOURS_IN_DAY = 24;
const PROPERTY_NAME_DIVIDER = '###';
const {CHILLER_DATA_DATE_FORMAT, TIME_FORMAT_H_MM_A, TIME_FORMAT_HH_MM, EU_DATA_DATE_FORMAT, LONG_DATE_FORMAT} = CPR_DATE_FORMATS;
let calcTotalRunHours = true;
let totalRunHours;

const getInstance = objectName => {
	const regex = /(?<=\*).*(?=\*)/g;
	return _get(objectName.match(regex), '[0]', '');
};

export const conversionTable = {
	si: {
		fahrenheit: {
			convertedUom: {
				name: 'celsius',
				displayName: 'Celsius',
				symbol: '°C',
				jscienceName: 'CELSIUS',
			},
			converterFunction: value => (value - 32) * 5 / 9,
		},
		deltaDegreesFahrenheit: {
			convertedUom: {
				name: 'deltaDegreesCelsius',
				displayName: 'Delta Degrees Celsius',
				symbol: 'Δ°C',
				jscienceName: 'DELTA_DEGREES_CELSIUS',
			},
			converterFunction: value => value * 5 / 9,
		},
		poundsForcePerSquareInch: {
			convertedUom: {
				name: 'kiloPascal',
				displayName: 'Kilo Pascal',
				symbol: 'kPa',
				jscienceName: 'KILO_PASCAL',
			},
			converterFunction: value => value * 6.8947572932,
		},
		tonRefrigeration: {
			convertedUom: {
				name: 'kiloWatt',
				displayName: 'Kilo Watt',
				symbol: 'kW',
				jscienceName: 'KILO_WATT',
			},
			converterFunction: value => value * 3.516852842067,
		},
	},
};

export const dateFormatByLang = {
	fr: {
		reportCreatedDateFormat: EU_DATA_DATE_FORMAT,
		chillerEnteredServiceFormat: EU_DATA_DATE_FORMAT,
	},
	en: {
		reportCreatedDateFormat: CHILLER_DATA_DATE_FORMAT,
		chillerEnteredServiceFormat: LONG_DATE_FORMAT,
	},
};

class ChillerPerformanceReportService {
	constructor(
		$q,
		$translate,
		$filter,
		dataFormattingService,
		tisObjectService,
		locationDetailsService,
		colorService,
		translateService,
		chillerPerformanceReportConfig,
		analyticParameterService,
		chillerPerformanceReportApiService,
		subtypeFilterService,
		CPR_REPORT_SECTIONS,
		CPR_DETAILS_ACTION_TYPE,
		helpers,
		compressorRunHoursAndStartsService
	) {
		services.set(this, {
			$q,
			$translate,
			$filter,
			dataFormattingService,
			tisObjectService,
			locationDetailsService,
			colorService,
			translateService,
			cprConfig: chillerPerformanceReportConfig,
			analyticParameterService,
			chillerPerformanceReportApiService,
			subtypeFilterService,
			CPR_REPORT_SECTIONS,
			CPR_DETAILS_ACTION_TYPE,
			helpers,
			compressorRunHoursAndStartsService,
		});
	}

	getEditableSectionsData(tisObjectId, range, hpath, chillerDataStorage) {
		const {$q, tisObjectService, chillerPerformanceReportApiService, CPR_REPORT_SECTIONS, CPR_DETAILS_ACTION_TYPE} = services.get(this);
		let {[CPR_REPORT_SECTIONS.CHILLER_PROFILE]: chillerProfileData = {}} = chillerDataStorage;
		const parametersPromise = tisObjectService
			.getAllValuesByIds({
				timeZone: range.from.tz(),
				from: range.from.clone(),
				to: range.to.clone(),
				ids: [tisObjectId],
				hpath,
			})
			.then(({tisObjectDataList: [{parameters}]}) => {
				return parameters.reduce((obj, param) => {
					obj[param.name] = chillerProfileData[param.name] ? chillerProfileData[param.name] : param.values[0] ? param.values[0].value : null;
					return obj;
				}, {});
			});

		const cprDataPromise = chillerPerformanceReportApiService.getChillerPerformanceReportData({tisObjectId}).then(result => {
			const TOTAL_RUN_HOURS_DEFAULT_VALUE = 0;
			const TOTAL_STARTS_DEFAULT_VALUE = 0;

			if (result === null) {
				return {
					details: [],
					totalRunHours: TOTAL_RUN_HOURS_DEFAULT_VALUE,
					totalStarts: TOTAL_STARTS_DEFAULT_VALUE,
				};
			}
			const {chillerPerformanceReportId, totalRunHours, totalStarts, details} = result;
			const improvements = details.filter(item => item.actionType === CPR_DETAILS_ACTION_TYPE.IMPROVEMENT).map(item => {
				item.id = item.detailsId;
				return Object.assign({}, item);
			});
			const milestones = details.filter(item => item.actionType === CPR_DETAILS_ACTION_TYPE.MILESTONE).map(item => {
				item.id = item.detailsId;
				return Object.assign({}, item);
			});
			const chillerRunHoursAndStarts = {
				chillerPerformanceReportId,
				tisObjectId,
				totalRunHours: angular.isDefined(totalRunHours) ? totalRunHours : TOTAL_RUN_HOURS_DEFAULT_VALUE,
				totalStarts: angular.isDefined(totalStarts) ? totalStarts : TOTAL_STARTS_DEFAULT_VALUE,
			};
			return {...chillerRunHoursAndStarts, details: [...improvements, ...milestones]};
		});
		return $q.all([parametersPromise, cprDataPromise]);
	}

	getActionByCprSectionName(cprSectionName, {tisObjectId, equipment, fromDate, toDate}) {
		const {
			CPR_REPORT_SECTIONS: {
				CHILLER_PROFILE,
				CHILLER_LIFE,
				COMPRESSOR_RUN_HOURS_AND_STARTS,
				MILESTONES,
				PERFORMANCE_IMPROVEMENTS,
				CONDENSER_HEAT_TRANSFER,
				PERFORMANCE_ASSESSMENT,
				EVAPORATOR_HEAT_TRANSFER,
				PLANT_LOAD_ANALYSIS,
				PERFORMANCE_EFFICIENCY,
				PERFORMANCE_WATER,
				LOAD_PROFILE,
				DIAGNOSTIC_CONDITIONS,
				OPERATING_MODES,
				SHUTDOWNS,
			},
			compressorRunHoursAndStartsService,
		} = services.get(this);

		let action = data => Promise.resolve({});

		switch (cprSectionName) {
			case CHILLER_LIFE:
			case MILESTONES:
			case PERFORMANCE_IMPROVEMENTS:
			case CHILLER_PROFILE:
				break;

			case CONDENSER_HEAT_TRANSFER:
			case EVAPORATOR_HEAT_TRANSFER:
			case PLANT_LOAD_ANALYSIS:
			case PERFORMANCE_EFFICIENCY:
			case PERFORMANCE_WATER:
			case LOAD_PROFILE: {
				action = () => this.getDataForChart({cprSectionName, tisObjectId, fromDate, toDate, equipment});
				break;
			}
			case PERFORMANCE_ASSESSMENT: {
				action = () => this.getDataForPerformanceAssessment({equipment, fromDate, toDate});
				break;
			}
			case DIAGNOSTIC_CONDITIONS:
			case OPERATING_MODES: {
				action = others => this.getDataForComparableTable({cprSectionName, tisObjectId, fromDate, toDate, ...others});
				break;
			}
			case SHUTDOWNS: {
				action = () => this.getDataForShutDowns({cprSectionName, tisObjectId, fromDate, toDate});
				break;
			}
			case COMPRESSOR_RUN_HOURS_AND_STARTS: {
				action = others =>
					compressorRunHoursAndStartsService.getCompressorRunHoursAndStarts({cprSectionName, equipment, tisObjectId, fromDate, toDate, ...others});
				break;
			}
		}

		return action;
	}

	getDataForChart({cprSectionName, tisObjectId, fromDate, toDate, equipment}) {
		const {cprConfig} = services.get(this);
		const {others = {}} = cprConfig[cprSectionName];
		const params = {};
		const propertiesInfoMap = {};
		let additionalXLine;

		return this._getData({cprSectionName, tisObjectId, fromDate, toDate, params, propertiesInfoMap, equipment}).then(processedData => {
			_addUomsToProcessedData(processedData, propertiesInfoMap, undefined, cprConfig.systemOfMeasurement);
			Object.keys(processedData).forEach(propertyName => {
				if (typeof processedData[propertyName].postPropertyProcessFunc === 'function') {
					processedData[propertyName].postPropertyProcessFunc(processedData[propertyName]);
				}
				if (processedData[propertyName].additionalXLine) {
					additionalXLine = processedData[propertyName];
					delete processedData[propertyName];
				}
			});

			return {
				yTicsCount: others.yTicsCount,
				yTicsGenerator: others.yTicsGenerator,
				xTicsGenerator: others.xTicsGenerator,
				xTicFormat: others.xTicFormat,
				from: params.from,
				to: params.to,
				axes: processedData,
				additionalXLine,
			};
		});
	}

	getDataForTotalRunHours({cprSectionName, tisObjectId, fromDate, toDate, dataCollectionStartTimestamp}) {
		if (calcTotalRunHours) {
			cprSectionName = 'COMPRESSOR_RUN_HOURS_AND_STARTS';
			const {$q, locationDetailsService, dataFormattingService, tisObjectService, cprConfig, helpers} = services.get(this);
			const {properties, hpath, others, uoms} = cprConfig['COMPRESSOR_RUN_HOURS_AND_STARTS'];
			if (properties) {
				const propertiesKeys = ['totalRunHoursThisMonth', 'totalRunHoursLastMonth'];
				const queries = {};
				propertiesKeys.forEach(propertyName => {
					const property = properties[propertyName];
					const dataFilter = property.dataFilter ? property.dataFilter.bind(property) : undefined;
					if (property.rangeKey) {
						if (!queries[property.rangeKey] && property && property.range && typeof property.range === 'function') {
							const range = property.range.bind(cprConfig['COMPRESSOR_RUN_HOURS_AND_STARTS'])(fromDate, toDate, dataCollectionStartTimestamp);
							// updating hpath to correct Run Hours section
							const hpath1 = '//Compressor@CompressorRunningTime,//Compressor@CompressorStarts,@CommunicationState';
							const params1 = {
								ids: [tisObjectId],
								hpath: hpath1,
								enumerations: '@CommunicationState=communicationStatusChiller/communicationStatusChillerDisplay',
								from: moment(range.fromDate),
								to: moment(range.toDate),
								timeZone: locationDetailsService.getLocationTimezone(),
								interval: 'PT15M',
								uoms,
							};
							let dataPromise = tisObjectService
								.getAllValuesByIds(params1)
								.then(({tisObjectDataList: dataList}) => {
									if (!dataList || dataList.length === 0) {
										return dataList;
									}
									let compStateData = dataList[0] && dataList[0].columns[0] ? dataList[0].columns[0] : [];
									let isRelatedDataEntriesFound = dataList[0] && dataList[0].relatedDataEntries;
									let compRunData = _get(dataList[0], 'relatedDataEntries[0].dataEntries[0].columns[0]', []);
									let compStartsData =
										isRelatedDataEntriesFound &&
										dataList[0].relatedDataEntries[0].dataEntries[0] &&
										dataList[0].relatedDataEntries[0].dataEntries[0].columns[1]
											? dataList[0].relatedDataEntries[0].dataEntries[0].columns[1]
											: [];
									let totalRunHours = 0;
									if (compRunData.values.length != 0) {
										let runHoursEffectiveBegin = 0;
										let runHoursEffectiveEnd = 0;
										if (property.rangeKey !== COMP_TOTAL_LIFE) {
											for (let i = 0; i < compRunData.values.length - 1; i++) {
												if (
													compStateData.values[i].timestamp === compRunData.values[i].timestamp &&
													compStateData.values[i].value !== COMP_COMMUNICATION_STATE_DOWN &&
													compRunData.values[i].value
												) {
													runHoursEffectiveBegin = compRunData.values[i].value;
													break;
												}
											}
										}
										for (let i = compRunData.values.length - 1; i > 0; i--) {
											if (
												compStateData.values[i].timestamp === compRunData.values[i].timestamp &&
												compStateData.values[i].value !== COMP_COMMUNICATION_STATE_DOWN &&
												compRunData.values[i].value
											) {
												runHoursEffectiveEnd = compRunData.values[i].value;
												break;
											}
										}
										if (property.rangeKey === COMP_TOTAL_LIFE) {
											totalRunHours = runHoursEffectiveEnd.toFixed(2) * 1 || 0;
										} else if (runHoursEffectiveEnd === 0 || runHoursEffectiveBegin === 0) {
											totalRunHours = 0;
										} else totalRunHours = (runHoursEffectiveEnd - runHoursEffectiveBegin).toFixed(2) * 1 || 0;
									}
									(compRunData.name = 'ActualRunTime'),
										(compRunData.values = [
											{
												timestamp: dataList[0].startDate,
												value: totalRunHours,
											},
										]),
										(dataList[0].columns = [compRunData]);
									return dataList;
								})
								.then(tisObjectDataList => {
									return {
										tisObjectDataList,
										tisObjectIds: this._getTisObjectIds(tisObjectDataList),
									};
								})
								.then(({tisObjectDataList, tisObjectIds}) => {
									return {
										tisObjectDataList,
										tisObjectIds,
										propertiesData: $q.all(
											tisObjectIds.map(tisObjectId =>
												this.getObjectProperties({
													cprSectionName,
													tisObjectId,
												})
											)
										),
									};
								});
							queries[property.rangeKey] = {
								range,
								dataPromise: dataPromise,
								configData: {},
							};
						}
						queries[property.rangeKey].configData[propertyName] = property;
					}
				});
				const queriesKeys = Object.keys(queries);
				const dataQueries = [];
				queriesKeys.forEach(queryKey => {
					const query = queries[queryKey];
					const properties = query.configData;
					let dataQuery = query.dataPromise
						.then(({tisObjectDataList, tisObjectIds, propertiesData}) => {
							let propertiesInfoMap = {};
							let propertiesConfigData = {};
							return propertiesData.then(propertiesDataList => {
								const propertiesConfigDataTemp = {};
								propertiesDataList.forEach(propertiesData => {
									const propertiesDataMapped = propertiesData.reduce((previousVal, property) => {
										previousVal[property.propertyName] = property;
										return previousVal;
									}, {});
									Object.keys(properties).forEach(propertyName => {
										let propertyNameCalculated = properties[propertyName].propertyName
											? properties[propertyName].propertyName
											: propertyName;
										if (propertiesDataMapped[propertyNameCalculated]) {
											propertiesInfoMap[propertyName] = propertiesDataMapped[propertyNameCalculated];
											propertiesConfigDataTemp[propertyName] = properties[propertyName];
										}
									});
								});
								const fromDatePromises = [];

								Object.keys(properties).forEach(propertyName => {
									propertiesConfigData[propertyName] = propertiesConfigDataTemp[propertyName];
									if (propertiesConfigData[propertyName]) {
										propertiesConfigData[propertyName].calculatedRange = {...query.range};
										if (propertiesConfigData[propertyName].rangeKey === COMP_TOTAL_LIFE && propertyName === COMP_TOTALS_STARTS_TOTAL_LIFE) {
											const params = {
												ids: [tisObjectId],
												enumerations: '',
												timeZone: locationDetailsService.getLocationTimezone(),
											};
											const cprReportHeaderService = tisObjectService.getCprReportHeader(params).then(res => {
												let chillerEnteredService = res[CHILLER_ENTERED_SERVICE];
												propertiesConfigData[propertyName].calculatedRange.fromDate = chillerEnteredService
													? moment(chillerEnteredService)
													: 0;
												return chillerEnteredService;
											});
											fromDatePromises.push(cprReportHeaderService);
										}
									}
								});

								return $q.all(fromDatePromises).then(
									() => {
										return {
											propertiesInfoMap,
											propertiesConfigData,
											tisObjectDataList,
										};
									},
									() => {
										return {
											propertiesInfoMap,
											propertiesConfigData,
											tisObjectDataList,
										};
									}
								);
							});
						})
						.then(({propertiesInfoMap, propertiesConfigData, tisObjectDataList}) => {
							let data = _processDataList({
								tisObjectDataList,
								propertiesList: Object.keys(properties),
								timezone: locationDetailsService.getLocationTimezone(),
								propertiesInfoMap,
								propertiesConfigData,
								dataFormattingService,
								isFormatResponseValues: false,
							});
							return data;
						});
					dataQueries.push(dataQuery);
				});
				calcTotalRunHours = false;
				return $q.all(dataQueries);
			} else {
				return Promise.resolve({});
			}
		}
	}

	async getDataForComparableTable({cprSectionName, tisObjectId, fromDate, toDate, dataCollectionStartTimestamp}) {
		if (!totalRunHours) {
			totalRunHours = await this.getDataForTotalRunHours({cprSectionName, tisObjectId, fromDate, toDate, dataCollectionStartTimestamp}).then(function(
				res
			) {
				return res;
			});
		}
		const {locationDetailsService} = services.get(this);
		const propertiesInfoMap = {};
		const params = {};

		return this._getData({
			cprSectionName,
			tisObjectId,
			fromDate,
			toDate,
			propertiesInfoMap,
			params,
			isFormatResponseValues: false,
		})
			.then(processedData => Object.values(processedData))
			.then(([{data = []} = {}]) => {
				const TIMESTAMPS_NUMBER_PER_HOUR = 4;
				const {from, to} = params;
				const hoursInPreviousMonth = from.daysInMonth() * HOURS_IN_DAY;
				const hoursInCurrentMonth =
					to.date() ===
					to
						.clone()
						.endOf('month')
						.date()
						? to.daysInMonth() * HOURS_IN_DAY
						: to.date() * HOURS_IN_DAY;

				const previousMonthData = {data: []};
				const currentMonthData = {data: []};

				_divideDataPerTwoMonths({
					data,
					previousMonthNumber: from.month(),
					timezone: locationDetailsService.getLocationTimezone(),
					previousMonthData: previousMonthData.data,
					currentMonthData: currentMonthData.data,
				});

				previousMonthData.missingDataHours = hoursInPreviousMonth - previousMonthData.data.length / TIMESTAMPS_NUMBER_PER_HOUR;
				currentMonthData.missingDataHours = hoursInCurrentMonth - currentMonthData.data.length / TIMESTAMPS_NUMBER_PER_HOUR;

				previousMonthData.data = sum(previousMonthData.data);
				currentMonthData.data = sum(currentMonthData.data);

				function sum(items = []) {
					const sumMap = items.reduce((sumMap, item) => {
						return Object.assign(sumMap, {
							[item.value]: sumMap[item.value] ? ++sumMap[item.value] : 1,
						});
					}, {});

					Object.keys(sumMap).forEach(key => Object.assign(sumMap, {[key]: sumMap[key] / TIMESTAMPS_NUMBER_PER_HOUR}));

					return sumMap;
				}
				if (totalRunHours) {
					currentMonthData.data['Chiller In Run Mode'] = totalRunHours[0].totalRunHoursThisMonth.data;
					previousMonthData.data['Chiller In Run Mode'] = totalRunHours[1].totalRunHoursLastMonth.data;
				}
				return {previousMonthData, currentMonthData};
			});
	}

	getDataForShutDowns({cprSectionName, tisObjectId, fromDate, toDate}) {
		const {locationDetailsService, cprConfig} = services.get(this);
		const propertiesInfoMap = {};
		const params = {};

		return this._getData({
			cprSectionName,
			tisObjectId,
			fromDate,
			toDate,
			propertiesInfoMap,
			params,
			isFormatResponseValues: false,
		})
			.then(processedData => Object.values(processedData))
			.then((processedData = []) => {
				const {
					properties,
					others: {additionalNames: {numbersColumnName}, additionalStyles: {numbersRowBackgroundColor}, additionalHandlers: {getCorrectValue}},
				} = cprConfig[cprSectionName];

				const yesValues = ['yes', 'true'];
				const noValues = ['no', 'false'];

				const previousMonthData = {data: {}};
				const currentMonthData = {data: {}};

				const calculateShutdowns = monthData => {
					Object.keys(monthData).forEach(propertyName => {
						monthData[propertyName] = monthData[propertyName].reduce((sum, item, index, array) => {
							if (index !== 0) {
								yesValues.includes(item.value.toString().toLowerCase()) &&
									noValues.includes(array[index - 1].value.toString().toLowerCase()) &&
									sum++;
							}

							return sum;
						}, 0);
					});
				};

				Object.keys(properties)
					.reverse()
					.forEach(propertyName => {
						const propertyAlias = properties[propertyName].translation || propertyName;

						previousMonthData.data[propertyAlias] = [];
						currentMonthData.data[propertyAlias] = [];

						const propertyInfo = processedData.find(item => item.name === propertyName) || {};

						_divideDataPerTwoMonths({
							data: propertyInfo.data || [],
							previousMonthNumber: params.from.month(),
							timezone: locationDetailsService.getLocationTimezone(),
							previousMonthData: previousMonthData.data[propertyAlias],
							currentMonthData: currentMonthData.data[propertyAlias],
						});
					});

				calculateShutdowns(previousMonthData.data);
				calculateShutdowns(currentMonthData.data);

				return {previousMonthData, currentMonthData, numbersColumnName, numbersRowBackgroundColor, getCorrectValue};
			});
	}

	getCharacteristicsForPerformanceAssessment({hpath, tisObjectId, fromDate, toDate}) {
		const {analyticParameterService} = services.get(this);

		return analyticParameterService.getAnalyticParametersByTisObjectIdsAndTisObjectProperties({
			hpath,
			tisObjectIds: [tisObjectId],
			from: moment(fromDate),
			to: moment(toDate),
		});
	}

	getDataForPerformanceAssessment({equipment, fromDate, toDate}) {
		const {
			$q,
			$translate,
			dataFormattingService,
			tisObjectService,
			locationDetailsService,
			helpers,
			cprConfig,
			translateService,
			subtypeFilterService,
			CPR_REPORT_SECTIONS: {PERFORMANCE_ASSESSMENT},
			chillerPerformanceReportConfig,
		} = services.get(this);
		const {enumerations, others = {}} = cprConfig[PERFORMANCE_ASSESSMENT];
		const propertiesInfoMap = {};
		const propertiesConfigData = {};
		let {hpath, properties} = cprConfig[PERFORMANCE_ASSESSMENT];
		let tisObjectDataList = [];
		let characteristicsDataList = [];
		let params = {
			ids: [equipment.tisObjectId],
			hpath: hpath,
			enumerations,
			from: moment(fromDate),
			to: moment(toDate),
			timeZone: locationDetailsService.getLocationTimezone(),
			interval: others.interval || 'P1D',
		};

		const processTableDataList = _processTableDataList.bind(this);

		return subtypeFilterService
			.getPropertiesFilter([subtypeFilterService.getFilterKeyNameFromObjectWithHierarchy(equipment)])
			.then(excludedProperties => ({properties, params} = _filterExcludedProperties({properties, excludedProperties, params})))
			.then(() => this.getCharacteristicsForPerformanceAssessment({hpath, tisObjectId: equipment.tisObjectId, fromDate, toDate}))
			.then(characteristics => (characteristicsDataList = characteristics))
			.then(() => tisObjectService.getAllValuesByIds(params))
			.then(({tisObjectDataList: dataList}) => (tisObjectDataList = dataList))
			.then(() => this._getTisObjectPropsByPropertyPath(tisObjectDataList, 'tisObject.tisObjectType.tisObjectTypeGroupName'))
			.then(tisObjectTypeGroupNames =>
				this.getObjectProperties({
					cprSectionName: PERFORMANCE_ASSESSMENT,
					tisObjectTypeGroupNames,
				})
			)
			.then(propertiesDataList => {
				const propertiesDataMap = helpers.normalizeList(propertiesDataList, 'propertyName');

				Object.keys(properties)
					.filter(propertyName => propertiesDataMap[propertyName])
					.forEach(propertyName => {
						propertiesInfoMap[propertyName] = propertiesDataMap[propertyName];
						propertiesConfigData[propertyName] = properties[propertyName];
					});
			})
			.then(() => {
				return processTableDataList(
					{
						tisObjectDataList,
						properties,
						timezone: locationDetailsService.getLocationTimezone(),
						propertiesInfoMap,
						propertiesConfigData,
						dataFormattingService,
						translateService,
						characteristicsDataList,
					},
					helpers,
					$translate,
					cprConfig
				);
			})
			.then(processedData => {
				const validValues = getChillerData(processedData, propertiesConfigData);
				const isPropertyToBeEmpty = propertyName => {
					const properties = ['CompressorRunning'];
					return properties.indexOf(propertyName) !== -1;
				};
				Object.keys(processedData).forEach(propertyName => {
					if (isPropertyToBeEmpty(propertyName)) {
						processedData[propertyName].values = [PLACE_HOLDER, PLACE_HOLDER, PLACE_HOLDER];
					} else if (processedData[propertyName]) {
						processedData[propertyName].values = calculateAvgMinMax(validValues[propertyName]);
					}
				});
				return processedData;

				// const hpathArray = params.hpath.split(',').filter(h => !h.startsWith('~'));
				// const hpathAvg = hpathArray.map(item => `${item}|AVG('month')`).join(',');
				// const hpathMin = hpathArray.map(item => `${item}|MIN('month')`).join(',');
				// const hpathMax = hpathArray.map(item => `${item}|MAX('month')`).join(',');

				// const dataPromises = [hpathAvg, hpathMin, hpathMax].map(currentHpath => {
				// 	return tisObjectService
				// 		.getAllValuesByIds(Object.assign({}, params, {hpath: currentHpath}))
				// 		.then(({tisObjectDataList}) => tisObjectDataList)
				// 		.then(tisObjectDataList => {
				// 			return processTableDataList(
				// 				{
				// 					tisObjectDataList,
				// 					properties,
				// 					timezone: locationDetailsService.getLocationTimezone(),
				// 					propertiesInfoMap,
				// 					propertiesConfigData,
				// 					dataFormattingService,
				// 					translateService,
				// 				},
				// 				helpers,
				// 				$translate,
				// 				cprConfig
				// 			);
				// 		});
				// });
				// return $q
				// 	.all(dataPromises)
				// 	.then(additionalDataResults => {
				// 		additionalDataResults.forEach(additionalData => {
				// 			Object.keys(additionalData).forEach(propertyName => {
				// 				processedData[propertyName].values = processedData[propertyName].values || [];
				// 				processedData[propertyName].values.push(additionalData[propertyName].data);
				// 			});
				// 		});
				// 	})
				// 	.then(() => processedData);
			})
			.then(processedData => {
				const orderedData = {};
				Object.keys(properties).forEach(propertyName => {
					if (processedData[propertyName]) {
						orderedData[propertyName] = processedData[propertyName];
					}
				});
				Object.keys(processedData).forEach(propertyName => {
					if (!orderedData[propertyName]) {
						orderedData[propertyName] = processedData[propertyName];
					}
				});
				_calculateAverageByChillerLoadBins(orderedData, propertiesConfigData);
				_addUomsToProcessedData(orderedData, propertiesInfoMap, propertiesConfigData, cprConfig.systemOfMeasurement, dataFormattingService);

				return orderedData;
			})
			.then(processedData => {
				Object.keys(processedData).forEach(propertyName => {
					!processedData[propertyName] && delete processedData[propertyName];
				});
				return others.postProcessData(processedData);
			});
	}

	getObjectProperties({cprSectionName, tisObjectId, tisObjectTypeGroupNames}) {
		const {tisObjectService, cprConfig} = services.get(this);
		const promise = tisObjectId
			? tisObjectService.getObjectProperties(tisObjectId)
			: tisObjectService.getObjectPropertiesByTisObjectTypeGroupNames(tisObjectTypeGroupNames);

		return promise
			.then(response => response.data)
			.then(({tisObjectPropertyList}) =>
				tisObjectPropertyList.filter(
					property =>
						!!cprConfig[cprSectionName].properties[property.propertyName] ||
						Object.values(cprConfig[cprSectionName].properties).some(configProperty => configProperty.propertyName === property.propertyName)
				)
			);
	}

	_getData({cprSectionName, tisObjectId, fromDate, toDate, propertiesInfoMap = {}, params = {}, isFormatResponseValues = true, equipment}) {
		const {$q, dataFormattingService, tisObjectService, locationDetailsService, cprConfig, colorService} = services.get(this);

		const {properties, hpathForMultipleCircuit = '', others = {}} = cprConfig[cprSectionName];

		const isMultipleCircuit = !!hpathForMultipleCircuit && hasMultipleCircuit(equipment);

		const hPathKey = isMultipleCircuit ? 'hpathForMultipleCircuit' : 'hpath';

		const additionalQueriesRelatedToPropertiesKey = isMultipleCircuit
			? 'additionalQueriesRelatedToPropertiesForMultipleCircuit'
			: 'additionalQueriesRelatedToProperties';

		const hpath = cprConfig[cprSectionName][hPathKey];

		const additionalQueriesRelatedToProperties = cprConfig[cprSectionName][additionalQueriesRelatedToPropertiesKey];

		const dataFilter = cprConfig[cprSectionName].dataFilter ? cprConfig[cprSectionName].dataFilter.bind(cprConfig[cprSectionName]) : undefined;
		const {range} = others;
		const propertiesConfigData = {};
		let tisObjectDataList = [];

		Object.assign(params, {
			ids: [tisObjectId],
			hpath: hpath,
			enumerations: cprConfig[cprSectionName].enumerations || '',
			from:
				typeof range.from === 'function'
					? range.from(fromDate, toDate)
					: moment(toDate)
							.add(-1, 'month')
							.startOf('month'),
			to: typeof range.from === 'function' ? range.to(fromDate, toDate) : moment(toDate),
			timeZone: locationDetailsService.getLocationTimezone(),
			interval: others.interval || 'P1D',
		});

		return tisObjectService
			.getAllValuesByIds(params)
			.then(({tisObjectDataList: dataList}) => {
				dataList = sortBy(dataList, ['relatedDataEntries', 0, 'dataEntries'], ['tisObject', 'tisObjectName'], true);
				return (tisObjectDataList = dataList);
			})
			.then(() => this._getTisObjectIds(tisObjectDataList))
			.then(tisObjectIds =>
				$q.all(
					tisObjectIds.filter(tisObjectId => tisObjectId).map(tisObjectId =>
						this.getObjectProperties({
							cprSectionName,
							tisObjectId,
						})
					)
				)
			)
			.then(propertiesDataList => {
				propertiesDataList.forEach(propertiesData => {
					Object.keys(properties).forEach(propertyKey => {
						propertiesData.forEach(property => {
							if (propertyKey === property.propertyName || properties[propertyKey].propertyName === property.propertyName) {
								propertiesInfoMap[propertyKey] = property;
								propertiesConfigData[propertyKey] = properties[propertyKey];
							}
						});
					});
				});
				for (let property in properties) {
					if (!propertiesConfigData[property]) {
						propertiesInfoMap[property] = properties[property].propertiesInfoMap;
						propertiesConfigData[property] = properties[property];
					}
				}
			})
			.then(() =>
				_processDataList({
					tisObjectDataList,
					propertiesList: Object.keys(properties),
					timezone: locationDetailsService.getLocationTimezone(),
					propertiesInfoMap,
					propertiesConfigData,
					dataFormattingService,
					colorService,
					isFormatResponseValues,
					isProcessAllChildren: true,
					from: params.from,
					to: params.to,
				})
			)
			.then(processedData => {
				if (typeof dataFilter === 'function') {
					return dataFilter(processedData);
				}

				return processedData;
			})
			.then(processedData => {
				if (additionalQueriesRelatedToProperties) {
					return $q
						.all(
							additionalQueriesRelatedToProperties.map(query => {
								return tisObjectService
									.getAllValuesByIds(Object.assign({}, params, {hpath: query.hpath}))
									.then(({tisObjectDataList}) =>
										sortBy(tisObjectDataList, ['relatedDataEntries', 0, 'dataEntries'], ['tisObject', 'tisObjectName'], true)
									)
									.then(tisObjectDataList =>
										_processDataList({
											tisObjectDataList,
											propertiesList: Object.keys(properties),
											timezone: locationDetailsService.getLocationTimezone(),
											propertiesInfoMap,
											propertiesConfigData,
											dataFormattingService,
											colorService,
											isProcessAllChildren: true,
											from: params.from,
											to: params.to,
										})
									)
									.then(additionalData => {
										Object.keys(additionalData).forEach(propertyName => {
											let data = processedData[propertyName];
											const addData = additionalData[propertyName];

											// For multi-circuit, ChillerCurrentEnteringDrawRla will not be available
											// So use alternate properties that used to get RLA data to store additionalLinesData i.e CircuitCurrentDrawRLACalc
											if (!data && addData) {
												const config = propertiesConfigData[propertyName];

												if (config && config.additionaLinesCanBeStoredOn) {
													data = processedData[config.additionaLinesCanBeStoredOn];
												}
											}

											if (!data) return;

											const addAdditionalLinesDataToData = (data, addData) => {
												const update = propertyData => {
													propertyData.additionalLines = propertyData.additionalLines || [];
													propertyData.additionalLines.push({
														name: query.name,
														type: query.type,
														data: addData.data,
													});
												};
												Array.isArray(data) ? data.forEach(update) : update(data);
											};

											Array.isArray(addData)
												? addData.forEach((_, index) => addAdditionalLinesDataToData(data[index], addData[index]))
												: addAdditionalLinesDataToData(data, addData);
										});
									});
							})
						)
						.then(() => processedData);
				} else {
					return processedData;
				}
			});
	}

	_getTisObjectIds(tisObjectDataList = []) {
		return this._getTisObjectPropsByPropertyPath(tisObjectDataList, 'tisObjectId');
	}

	_getTisObjectPropsByPropertyPath(tisObjectDataList = [], propertyPath) {
		const {getPropertyByPath} = services.get(this).helpers;
		const tisObjectProps = new Set();
		tisObjectDataList.forEach(tisObjectData => {
			tisObjectProps.add(getPropertyByPath(tisObjectData, propertyPath));
			tisObjectData.relatedDataEntries &&
				tisObjectData.relatedDataEntries.forEach(relatedDataEntry => {
					relatedDataEntry.dataEntries &&
						relatedDataEntry.dataEntries.forEach(dataEntry => {
							const propertyByPath = getPropertyByPath(dataEntry, propertyPath);
							if (propertyByPath) {
								tisObjectProps.add(getPropertyByPath(dataEntry, propertyPath));
							}
						});
				});
		});

		return Array.from(tisObjectProps);
	}
}

angular.module('TISCC').service('chillerPerformanceReportService', ChillerPerformanceReportService);

function _processDataList({
	tisObjectDataList = [],
	propertiesList,
	timezone,
	propertiesInfoMap,
	propertiesConfigData,
	dataFormattingService,
	colorService,
	isFormatResponseValues = true,
	isProcessAllChildren = false,
	from,
	to,
}) {
	const result = {};
	const colorGenerator = colorService ? colorService.generator() : null;
	const processColumns = (columns = [], tisObject = {}) => {
		const columnsMapped = columns.reduce((previousValue, column) => {
			previousValue[column.name] = column;
			return previousValue;
		}, {});
		Object.keys(propertiesConfigData).forEach(propertyKey => {
			const propertyConfigData = propertiesConfigData[propertyKey];
			if (!propertyConfigData) {
				return;
			}

			const key = propertyConfigData.propertyName || propertyKey;
			if (columnsMapped[key]) {
				const column = columnsMapped[key];
				const {propertyAttribute = {}} = propertiesInfoMap[propertyKey] || {};
				const isNeedExtraFormatting = propertyAttribute.resolution !== null;

				let newPropertyObject = {};
				let colorValue = '';

				if (propertyAttribute.colorValue) {
					colorValue = colorGenerator.next(`#${propertyAttribute.colorValue}`).value;
				}
				if (!colorValue && colorGenerator) {
					colorValue = colorGenerator.next().value;
				}

				const tisObjectName = _get(tisObject, 'tisObjectName', '');
				const tisObjectTypeGroupName = _get(tisObject, 'tisObjectType.tisObjectTypeGroupName', '');
				const instance = getInstance(tisObjectName);

				if (isProcessAllChildren && result[propertyKey]) {
					// Process all chiller's related data entries.
					Array.isArray(result[propertyKey])
						? result[propertyKey].push(newPropertyObject)
						: (() => {
								result[propertyKey] = [result[propertyKey]];
								result[propertyKey].push(newPropertyObject);
						  })();
				} else {
					result[propertyKey] = newPropertyObject;
				}

				Object.assign(
					newPropertyObject,
					{
						name: propertyKey,
						colorValue: colorValue,
					},
					propertyConfigData
				);
				newPropertyObject.instance = instance;
				newPropertyObject.tisObjectTypeGroupName = tisObjectTypeGroupName;

				if (propertyConfigData.propertyName) {
					newPropertyObject.propertyName = propertyConfigData.propertyName;
				}
				if (typeof propertyConfigData.propertyNameFunc === 'function') {
					newPropertyObject.propertyName = propertiesConfigData[propertyKey].propertyNameFunc(tisObjectDataList[0]);
				}
				if (typeof propertyConfigData.postPropertyProcessFunc === 'function') {
					newPropertyObject.postPropertyProcessFunc = propertyConfigData.postPropertyProcessFunc;
				}
				if (propertyConfigData.title) {
					newPropertyObject.propertyName = propertyConfigData.title;
				}
				let preData = !isFormatResponseValues
					? column.values
					: column.values.filter(value => value.timestamp !== undefined && value.value !== undefined).map(value => {
							let y = value.value;
							if (isNeedExtraFormatting) {
								y = dataFormattingService.applyDecimalFormatting(y, propertyAttribute.resolution);
							}
							if (!isNaN(y)) {
								y = parseFloat(y);
							}
							return {
								x: moment(value.timestamp).tz(timezone),
								y,
								dateString: moment(value.timestamp)
									.tz(timezone)
									.format('YYYY-MM-DDTHH:mm:ssZ'),
							};
					  });
				if (typeof propertiesConfigData[propertyKey].dataFilter === 'function') {
					preData = propertiesConfigData[propertyKey].dataFilter.bind(propertiesConfigData[propertyKey])(preData, from, to);
				}
				newPropertyObject.data = preData;
			}
		});
	};
	tisObjectDataList.forEach(tisObjectData => {
		processColumns(tisObjectData.columns);
		tisObjectData.relatedDataEntries &&
			tisObjectData.relatedDataEntries.forEach(relatedDataEntry => {
				relatedDataEntry.dataEntries &&
					relatedDataEntry.dataEntries.forEach(dataEntry => {
						processColumns(dataEntry.columns, dataEntry.tisObject);
					});
			});
	});
	return result;
}

function _processTableDataList(
	{
		tisObjectDataList = [],
		properties,
		timezone,
		propertiesInfoMap,
		propertiesConfigData,
		dataFormattingService,
		translateService,
		characteristicsDataList = [],
	},
	helpers,
	$translate,
	cprConfig
) {
	const result = {};
	const getUniqPropertyName = propertyName => {
		const [name, number] = propertyName.split(PROPERTY_NAME_DIVIDER);

		return number ? `${name}${PROPERTY_NAME_DIVIDER}${Number(number) + 1}` : `${name}${PROPERTY_NAME_DIVIDER}1`;
	};
	const getTranslatedProperty = ({propertyName, newPropertyName, tisObjectTypeGroupName, instance}) => {
		const {$filter} = services.get(this);
		const customName = properties[propertyName].customName;
		const translation = customName ? $translate(customName) : translateService.translateProperty(propertyName);
		if (newPropertyName) {
			const value = $filter('translateProperty')('M_' + propertyName, tisObjectTypeGroupName, {
				name: $translate(instance),
			});
			return value;
		}
		return translation;
	};

	const processColumns = (columns = [], {tisObjectType: {tisObjectTypeGroupName}, tisObjectName}) => {
		const columnsMapped = columns.reduce((previousValue, column) => {
			previousValue[column.name] = column;
			return previousValue;
		}, {});
		Object.keys(propertiesConfigData).forEach(propertyName => {
			if (columnsMapped[propertyName] && propertiesInfoMap[propertyName]) {
				let newPropertyName = propertyName;
				let isPropertyAlreadyExists = false;

				if (propertyName in result) {
					if (result[propertyName] !== null) {
						// For chiller with multiple circuits save properties with different names.
						// Example: PropertyName###1, PropertyName###2
						// where ### - divider between property name and index
						const instance = getInstance(tisObjectName);
						newPropertyName = getUniqPropertyName(propertyName);
						result[newPropertyName] = {
							...result[propertyName],
							propertyName: result[propertyName].multiCircuitPropertyName,
						};
						result[propertyName] = null;
					}

					while (newPropertyName in result) {
						newPropertyName = getUniqPropertyName(newPropertyName);
					}

					isPropertyAlreadyExists = true;
				}
				const instance = getInstance(tisObjectName);
				result[newPropertyName] = {
					multiCircuitPropertyName: getTranslatedProperty({
						propertyName,
						newPropertyName: (isPropertyAlreadyExists || instance) && newPropertyName,
						tisObjectTypeGroupName,
						instance,
					}),
					propertyName: getTranslatedProperty({
						propertyName,
						newPropertyName: isPropertyAlreadyExists && newPropertyName,
						tisObjectTypeGroupName,
						instance: isPropertyAlreadyExists && instance,
					}),
					designValue: getDesignValue({
						propertyName,
						characteristicsDataList,
					}),
				};
				Object.assign(result[newPropertyName], propertiesConfigData[propertyName]);
				const uomName = _get(columnsMapped[propertyName], 'sourceUnitOfMeasure.name');
				let converterFunction = null;
				if (uomName) {
					const converter = _get(conversionTable, `${cprConfig.systemOfMeasurement}.${uomName}`);
					if (converter) {
						converterFunction = converter.converterFunction;
						const designValue = parseFloat(result[newPropertyName].designValue);
						if (!isNaN(designValue)) {
							result[newPropertyName].designValue = converterFunction(designValue);
						}
					}
				}
				result[newPropertyName].data = columnsMapped[propertyName].values
					.map(value => {
						if (typeof converterFunction === 'function' && value.value !== undefined && value.value !== null) {
							value.value = converterFunction(value.value);
							value.converted = true;
						}
						return value;
					})
					.filter(value => value.timestamp !== undefined && value.value !== undefined)
					.map(value => {
						let y =
							typeof propertiesConfigData[propertyName].formatData === 'function'
								? propertiesConfigData[propertyName].formatData(value.value)
								: value.value;
						if (helpers.isNumber(y)) {
							y = parseFloat(y);
						}
						value.value = y;

						return value;
					});

				result[newPropertyName].resolution = propertiesInfoMap[propertyName].propertyAttribute.resolution;
			}
		});
		Object.keys(result).forEach(propertyName => {
			const property = _get(result, propertyName);
			if (property && property.getDesignValue) {
				property.designValue = property.getDesignValue({propertyName, result});
			}
		});
	};

	const getDesignValue = ({propertyName, characteristicsDataList = []}) => {
		const characteristic = characteristicsDataList.find(characteristic => characteristic.propertyName === propertyName);
		return (characteristic && characteristic.analyticParameterValue) || DEFAULT_DESIGN_VALUE;
	};

	tisObjectDataList.forEach(tisObjectData => {
		processColumns(tisObjectData.columns, tisObjectData.tisObject);

		tisObjectData.relatedDataEntries &&
			tisObjectData.relatedDataEntries.forEach(relatedDataEntry => {
				if (relatedDataEntry.dataEntries) {
					relatedDataEntry.dataEntries = relatedDataEntry.dataEntries.filter(dataEntry => dataEntry.tisObject.tisObjectType);
					relatedDataEntry.dataEntries.forEach(dataEntry => {
						processColumns(dataEntry.columns, dataEntry.tisObject);
					});
				}
			});
	});

	// Remove all null values.
	Object.keys(result).forEach(propertyName => {
		!result[propertyName] && delete result[propertyName];
	});

	return result;
}

function calculateAvgMinMax(propertyValues = []) {
	return propertyValues.reduce(
		(response, propertyValue, index) => {
			if (response[1] === null || propertyValue.value < response[1]) {
				response[1] = propertyValue.value;
			}
			if (response[2] === null || propertyValue.value > response[2]) {
				response[2] = propertyValue.value;
			}
			response[0] = response[0] + propertyValue.value;
			if (propertyValues.length - 1 === index) {
				response[0] = response[0] / propertyValues.length;
			}
			return response;
		},
		[0, null, null]
	);
}

function isValidValue(value) {
	return value !== null && value !== undefined;
}

function getValuesWhenRunning(dataPoint, processedData, propertyName, validValues, index) {
	if (dataPoint.value !== 0 && dataPoint.value !== null && processedData[propertyName]) {
		validValues[propertyName] = validValues[propertyName] || [];
		const value = _get(processedData, `${propertyName}.data[${index}].value`);
		if (isValidValue(value)) {
			validValues[propertyName].push(processedData[propertyName].data[index]);
		}
	}
}

function getValuesWhenIdle(dataPoint, processedData, propertyName, validValues, index) {
	if (dataPoint.value === 0 && dataPoint.value !== null && processedData[propertyName]) {
		validValues[propertyName] = validValues[propertyName] || [];
		const value = _get(processedData, `${propertyName}.data[${index}].value`);
		if (isValidValue(value)) {
			validValues[propertyName].push(processedData[propertyName].data[index]);
		}
	}
}

function getallValues(processedData, propertyName, validValues, index) {
	if (processedData[propertyName]) {
		validValues[propertyName] = validValues[propertyName] || [];
		const value = _get(processedData, `${propertyName}.data[${index}].value`);
		if (isValidValue(value)) {
			validValues[propertyName].push(processedData[propertyName].data[index]);
		}
	}
}

function getChillerData(processedData = {}, propertiesConfigData = {}) {
	const PURGE_LIQUID_TEMPERATURE = 'PurgeLiquidTemperature';
	const PURGE_DAILY_PUMPOUT_RATE = 'PurgeDailyPumpoutRate';
	const PURGE_PUMPOUT_RATE_LIFE = 'PurgePumpoutRateLife';
	const PURGE_PUMPOUT_RATE_CHILLER_OFF = 'PurgePumpoutRateChillerOff7Days';
	const PURGE_PUMPOUT_RATE_CHILLER_ON = 'PurgePumpoutRateChillerOn7Days';
	const validValues = {};
	const chillerLoadData = processedData[CHILLER_LOAD_PROPERTY].data; // rla
	chillerLoadData.forEach((dataPoint, index) => {
		Object.keys(processedData).forEach(propertyName => {
			switch (propertyName) {
				case PURGE_LIQUID_TEMPERATURE:
				case PURGE_DAILY_PUMPOUT_RATE:
				case PURGE_PUMPOUT_RATE_LIFE:
					getallValues(processedData, propertyName, validValues, index);
					break;
				case PURGE_PUMPOUT_RATE_CHILLER_OFF:
					getValuesWhenIdle(dataPoint, processedData, propertyName, validValues, index);
					break;
				case PURGE_PUMPOUT_RATE_CHILLER_ON:
				default:
					getValuesWhenRunning(dataPoint, processedData, propertyName, validValues, index);
			}
		});
	});
	return validValues;
}

function _calculateAverageByChillerLoadBins(processedData = {}, propertiesConfigData = {}) {
	const BIN_NUMBER = 6;
	const chillerLoadData = processedData[CHILLER_LOAD_PROPERTY].data;
	const dataBins = {};

	const calculateValueForBin = (infoValues, allValues) => {
		const sum = infoValues.reduce((a, b) => a + b, 0);
		const avg = sum / allValues.length;
		return avg;
	};

	Object.keys(processedData).forEach(propertyName => {
		if (processedData[propertyName]) {
			processedData[propertyName].bins = new Array(BIN_NUMBER).fill(null);
		}
	});

	chillerLoadData.forEach((dataPoint, index) => {
		let binIndex = Math.ceil(dataPoint.value / 20);
		if (binIndex >= BIN_NUMBER) {
			binIndex = BIN_NUMBER - 1;
		}
		Object.keys(processedData).forEach(propertyName => {
			if (processedData[propertyName]) {
				dataBins[propertyName] = dataBins[propertyName] || [];
				dataBins[propertyName][binIndex] = dataBins[propertyName][binIndex] || [];
				dataBins[propertyName][binIndex].push(processedData[propertyName].data[index]);
			}
		});
	});
	Object.keys(dataBins).forEach(propertyName => {
		dataBins[propertyName].forEach((propertyDataBin, index) => {
			const infoValues = propertyDataBin.filter(data => data && data.value !== null && data.value !== undefined).map(data => data.value);

			if (infoValues.length) {
				processedData[propertyName].bins[index] = (propertiesConfigData[propertyName.split(PROPERTY_NAME_DIVIDER)[0]].calculateValueForBin ||
					calculateValueForBin)(infoValues, infoValues);
			} else {
				processedData[propertyName].bins[index] = null;
			}
		});
	});
}

function _divideDataPerTwoMonths({data, previousMonthNumber, timezone, previousMonthData = [], currentMonthData = []}) {
	data.filter(({value}) => value).forEach(({timestamp, value}) => {
		const timestampDate = moment(timestamp).tz(timezone);

		timestampDate.month() === previousMonthNumber
			? previousMonthData.push({timestampDate, timestamp, value})
			: currentMonthData.push({timestampDate, timestamp, value});
	});
}

function _filterExcludedProperties({properties, excludedProperties, params}) {
	properties = {...properties};
	params = {...params};

	Object.keys(properties).forEach(propertyName => {
		excludedProperties.includes(propertyName) && delete properties[propertyName];
	});

	params.hpath = params.hpath
		.split(',')
		.filter(hpathEntry => !excludedProperties.some(propertyName => hpathEntry.includes(propertyName)))
		.join();

	return {
		properties,
		params,
	};
}

export function _addUomsToProcessedData(processedData = {}, propertiesMap = {}, propertiesConfigData = {}, systemOfMeasurement, dataFormattingService) {
	if (typeof processedData !== 'object' || typeof propertiesMap !== 'object' || typeof propertiesConfigData !== 'object') {
		throw new TypeError('All passed arguments must be objects');
	}

	if (processedData === null || propertiesMap === null || propertiesConfigData === null) {
		throw new TypeError('Passed arguments can not be null');
	}
	Object.keys(processedData).forEach(propertyName => {
		const data = processedData[propertyName];

		// As property of processedData can be written as PROPERTY_NAME###1, obtain only part before PROPERTY_NAME_DIVIDER.
		propertyName = propertyName.split(PROPERTY_NAME_DIVIDER)[0];

		let uom =
			(propertiesConfigData[propertyName] && propertiesConfigData[propertyName].customUom) ||
			(propertiesMap[propertyName] && propertiesMap[propertyName].unitOfMeasure);
		const uomName = _get(uom, 'name');
		if (uomName) {
			const converter = _get(conversionTable, `${systemOfMeasurement}.${uomName}`);
			if (converter) {
				uom = converter.convertedUom;
				const converterFunction = converter.converterFunction;
				if (Array.isArray(data.data)) {
					data.data.forEach(item => {
						if (!item.converted) {
							item.y = converterFunction(item.y);
							item.converted = true;
						}
					});
				}
			}
		}
		data && (Array.isArray(data) ? data.forEach(dataItem => (dataItem.unitOfMeasure = uom)) : (data.unitOfMeasure = uom));

		// Applying resolution post calculation to fix minor differences in Bin calculation between CPR & RDR.
		const isNeedExtraFormatting = data ? !!data.resolution : false;
		const propertyResolution = data.resolution;
		if (isNeedExtraFormatting) {
			const applyFormatting = dataArr => {
				dataArr.forEach((item, index) => {
					dataArr[index] = item != null ? parseFloat(dataFormattingService.applyDecimalFormatting(item, propertyResolution)) : item;
				});
				return dataArr;
			};
			// Formatting Bins values based on resolution
			data.bins = applyFormatting(data.bins);

			// Formatting Bins values based on resolution
			data.values = applyFormatting(data.values);
		}
	});
}

function hasMultipleCircuit(data) {
	const children = R.pathOr([], ['children'], data);
	if (children.length >= 2) {
		return R.filter(R.pathEq(['tisObjectType', 'tisObjectTypeGroupName'], 'Circuit'), children).length > 1;
	}
	return false;
}

function sortBy(sourceData, path = [], by = [], isMutateSource = false) {
	const sortFn = R.compose(R.sortBy(R.compose(R.toLower, R.pathOr('', by))), R.pathOr([], path));

	const mapFn = object => (isMutateSource ? R.assocPath(path, sortFn(object), object) : sortFn(object));

	return (Array.isArray(sourceData) ? R.map(mapFn) : sortFn)(sourceData);
}
