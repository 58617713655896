angular.module('TISCC').controller('CPRInfoPopupMessageCtrl', CPRInfoPopupMessageCtrl);

function CPRInfoPopupMessageCtrl($scope, $modalInstance, title, imageSource, infoText, imageStyle, dialogStyle) {
	$scope.title = title;
	$scope.imageSource = imageSource;
	$scope.infoText = infoText;
	$scope.imageStyle = imageStyle;
	$scope.dialogStyle = dialogStyle;

	$scope.cancel = function() {
		$modalInstance.dismiss('cancel');
	};
}
