(function() {
	class ChartSelectionDropdownController {
		constructor(googleAnalyticsService) {
			this.GA = googleAnalyticsService;
		}

		updateDrpDwnPosition(event) {
			let parentNode = event.currentTarget.parentNode;
			let drpMenuNode = parentNode.querySelector('.dropdown-menu');

			drpMenuNode.style.display = 'block';

			let shift = parentNode.offsetLeft + parentNode.offsetWidth - drpMenuNode.offsetWidth;
			drpMenuNode.style.right = shift < 0 ? shift + 'px' : '';
			drpMenuNode.style.display = '';
		}

		setChartHandler(item) {
			this.GA.events.CHARTS.changeChartSelection(item);
			this.setChart({item: item});
		}
	}

	angular.module('TISCC').component('chartSelectionDropdown', {
		templateUrl: 'components/chart-selection-dropdown/chart-selection-dropdown.html',
		controller: ChartSelectionDropdownController,
		bindings: {
			itemTitle: '@',
			chartList: '<',
			selectedChartTitle: '<',
			buttonDisabled: '<',
			isChartListLoading: '<',
			isDescription: '<',
			setChart: '&',
		},
	});
})();
