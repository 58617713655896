/* global require*/

const requireAll = require => require.keys().forEach(require);

require('./main.scss');

requireAll(require.context('./common', true, /^(?!.*\.spec\.js$).*\.js$/));
requireAll(require.context('./providers', true, /^(?!.*\.spec\.js$).*\.js$/));
requireAll(require.context('./services', true, /^(?!.*\.spec\.js$).*\.js$/));
requireAll(require.context('./factories', true, /^(?!.*\.spec\.js$).*\.js$/));
requireAll(require.context('./directives', true, /^(?!.*\.spec\.js$).*\.js$/));
requireAll(require.context('./components', true, /^(?!.*\.spec\.js$).*\.js$/));
