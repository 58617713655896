/* eslint quotes: [0, 'single'] */
/* eslint eqeqeq: [0, 'always'] */
/* eslint max-nested-callbacks: [2, 4] */

angular.module('TISCC').service('ExportDataGenerationService', function(dataFormattingService, $filter, locationDetailsService, CHART_TYPE, helpers) {
	let that = this;
	const PERFORMANCE_CURVE = 'performanceCurve';
	const TIME_INTERVAL_MINUTES = 15;
	const TIME_INTERVAL = TIME_INTERVAL_MINUTES * 60 * 1000;
	const EMPTY_ADDRESS = {
		city: '',
		region: '',
		postalCode: '',
	};

	const PARETO_HIGH_TO_LOW_SORT_KEY = 'highToLow';
	const PARETO_LOW_TO_HIGH_SORT_KEY = 'lowToHigh';
	const PARETO_EQUIPMENT_BY_DATE_SORT_KEY = 'equipmentByDate';

	// TODO remove it from public API when getCsvXlsxData in compare charts is refactored.
	that.translateProperty = function(property, tisObjectType) {
		let type = tisObjectType ? tisObjectType : '';
		return $filter('translateProperty')(property, type);
	};

	let xlsxBuilderFunctions = {
		stackedBar: function(ws, item) {
			let tisObjectType = item.selectedEquipment.tisObjectType.tisObjectTypeGroupName;
			ws.format = CHART_TYPE.BAR;
			ws.schema.push($filter('translate')('EQUIPMENT_NAME'));
			ws.column.push({wch: 30});

			if (item.chart.options.unoccupied && item.chart.options.isStackedBarWithOccupancy) {
				ws.schema.push($filter('translate')('UNOCCUPIED'));
				ws.column.push({wch: 20});
			}

			ws.schema.push($filter('translate')('LOWER'));
			ws.column.push({wch: 20});
			ws.schema.push($filter('translate')('UPPER'));
			ws.column.push({wch: 20});
			ws.schema.push($filter('translate')('ABOVE'));
			ws.column.push({wch: 20});

			item.chart.options.exportData.forEach(function(row) {
				let key = 'PROPERTIES.' + row.name;
				let name = that.translateProperty(row.name, tisObjectType);
				let values = [key == name ? row.name : name];

				if (item.chart.options.unoccupied && item.chart.options.isStackedBarWithOccupancy) {
					values.push(+(row.percentages[0].y1 - row.percentages[0].y0).toFixed(2));
				}

				values.push(+(row.percentages[1].y1 - row.percentages[1].y0).toFixed(2));
				values.push(+(row.percentages[2].y1 - row.percentages[2].y0).toFixed(2));
				values.push(+(row.percentages[3].y1 - row.percentages[3].y0).toFixed(2));
				ws.values.push(values);
			});
		},
		pie: function(ws, item) {
			let tisObjectType = item.selectedEquipment.tisObjectType.tisObjectTypeGroupName;
			ws.format = CHART_TYPE.BAR;
			ws.schema.push($filter('translate')('EQUIPMENT_NAME'));
			ws.column.push({wch: 30});
			ws.schema.push($filter('translate')('LOWER'));
			ws.column.push({wch: 20});
			ws.schema.push($filter('translate')('UPPER'));
			ws.column.push({wch: 20});
			ws.schema.push($filter('translate')('ABOVE'));
			ws.column.push({wch: 20});

			(item.chart.options.exportData || []).forEach(function(row) {
				let key = 'PROPERTIES.' + row.name;
				let name = that.translateProperty(row.name, tisObjectType);

				let values = [key == name ? row.name : name];

				row.values.forEach(function(value) {
					values.push(value);
				});

				ws.values.push(values);
			});
		},
		pareto: function(ws, item) {
			const test = item.selectedChart.chartName || item.selectedChart.title;
			let values = [];

			ws.schema.push(
				$filter('translate')('FACILITY'),
				$filter('translate')('EQUIPMENT_NAME'),
				$filter('translate')('TEST'),
				$filter('translate')('DATE'),
				$filter('translate')('TIME'),
				$filter('translate')('MEAN')
			);
			ws.column.push({wch: 20}, {wch: 30}, {wch: 30}, {wch: 13}, {wch: 13}, {wch: 13});
			(item.chart.options.thresholds || []).forEach(function(row) {
				ws.schema.push(row.name);
				ws.column.push({wch: 30});
			});

			(item.chart.data || []).forEach(function(point) {
				const facility = point.location.locationName;
				const momentUtcTimestamp = dataFormattingService.getDateInUTC(point.timestamp);
				const timestamp = momentUtcTimestamp.toDate();
				const equipmentName = point.equipmentName;
				const mean = point.valueAtMaxDeviancePerDay;
				const dateCell = createDateCell('XLSX_REPORT_DATE_FORMAT', timestamp, timestamp, 'date', {
					dateString: momentUtcTimestamp.format('D-MMM-YYYY'),
				});
				const timeCell = createDateCell('XLSX_REPORT_TIME_FORMAT', timestamp, timestamp, 'time', {
					timeString: momentUtcTimestamp.format('h:mm A'),
				});
				const thresholds = Object.keys(point.thresholds).map(key => point.thresholds[key].value);
				let row = [facility, equipmentName, test, dateCell, timeCell, mean, ...thresholds];
				values.push({timestamp, mean, equipmentName, row});
			});
			switch (item.paretoChartSortOrder) {
				case PARETO_HIGH_TO_LOW_SORT_KEY:
					values.sort((a, b) => b.mean - a.mean);
					break;
				case PARETO_LOW_TO_HIGH_SORT_KEY:
					values.sort((a, b) => a.mean - b.mean);
					break;
				case PARETO_EQUIPMENT_BY_DATE_SORT_KEY:
					values.sort(byEquipmentNameAndTimestamp);
					break;
			}
			ws.values = values.map(value => value.row);
		},
	};

	function byEquipmentNameAndTimestamp(a, b) {
		const res = naturalCompare(a.equipmentName, b.equipmentName);
		if (res === 0) {
			return a.timestamp - b.timestamp;
		} else {
			return res;
		}
	}

	function naturalCompare(a, b) {
		let ax = [];
		let bx = [];

		a.replace(/(\d+)|(\D+)/g, (_, $1, $2) => ax.push([$1 || Infinity, $2 || '']));
		b.replace(/(\d+)|(\D+)/g, (_, $1, $2) => bx.push([$1 || Infinity, $2 || '']));

		while (ax.length && bx.length) {
			let an = ax.shift();
			let bn = bx.shift();
			let nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
			if (nn) {
				return nn;
			}
		}

		return ax.length - bx.length;
	}

	that.naturalCompare = naturalCompare;

	function defaultBuilderFunction(ws, item, opts) {
		let data = {};
		let schemaIds = {};
		let values = [];
		let date = generateTimeValue(opts.exportRange.from);

		ws.format = 'chartExport';
		ws.schema = ['Property Date', 'Property Time'];
		ws.resolution = [null, null];
		ws.column = [{wch: 13}, {wch: 13}];

		function addPropertyToWs(ws, lineOrLane, columnData) {
			let laneIdInSchema = ws.schema.push(lineOrLane.name);
			let resolution = helpers.getPropertyByPath(lineOrLane, 'fullPropertyInfo.propertyAttribute.resolution');
			ws.resolution.push(helpers.asNumber(resolution));
			schemaIds[lineOrLane.name] = laneIdInSchema - 1;
			ws.column.push(columnData);
		}

		item.chart.options.lines.forEach(function(line) {
			if (line.visible && line.propertyName !== PERFORMANCE_CURVE) {
				addPropertyToWs(ws, line, {wch: 7});
			}
		});

		item.timeline.lanes.filter(lane => !lane.isException).forEach(function(lane) {
			if (lane.visible && lane.hasData !== false && lane.propertyName !== PERFORMANCE_CURVE) {
				addPropertyToWs(ws, lane, {wch: 10});
			}
		});

		let exportRangeDateEnd = generateTimeValue(opts.exportRange.to);
		let now = generateTimeValue(moment().tz(locationDetailsService.getLocationTimezone()));

		if (exportRangeDateEnd > now) {
			exportRangeDateEnd = now;
		}

		while (date <= exportRangeDateEnd) {
			createItem(data, date);
			date.add(TIME_INTERVAL_MINUTES, 'minutes');
		}

		item.chart.options.lines.forEach(function(line, i) {
			if (line.visible && item.chart.data[i]) {
				let id = schemaIds[line.name];
				if (line.uom && line.uom.name === 'zeroOne' && item.chart.options.chartType === CHART_TYPE.LINE_WITH_BINARY_STATES) {
					item.chart.data[i].forEach(function(value) {
						let timestamp = +dataFormattingService.getDateInUTC(value.x);

						if (timestamp && data[timestamp]) {
							data[timestamp][id] = value.y % 2 ? 'Off' : 'On';
						}
					});
				} else {
					item.chart.data[i].forEach(function(value) {
						let timestamp = +dataFormattingService.getDateInUTC(value.x);

						if (timestamp && data[timestamp]) {
							data[timestamp][id] = value.value;
						}
					});
				}
			}
		});

		const lanesByLaneId = (item.timeline.lanes || []).reduce((av, cv) => {
			const laneId = cv.lane;
			av['lane-id-' + laneId] = cv;
			return av;
		}, {});

		item.timeline.exportData.forEach(function(value) {
			let lane = lanesByLaneId['lane-id-' + value.lane];

			if (lane === undefined || lane === null) {
				return;
			}

			let laneName = lane.name;
			let laneProperty = lane.property;
			let id = schemaIds[laneName];
			let valueStart = +dataFormattingService.getDateInUTC(value.start);

			if (lane.visible && valueStart && data[valueStart]) {
				data[valueStart][id] = value.text;

				if (dataFormattingService.isEnumeration(laneProperty)) {
					data[valueStart][id] = dataFormattingService.remapEnumeration(laneProperty, value.text);
				}
			}
		});

		for (let key in data) {
			values.push(data[key]);
		}

		values.sort(byTimestamp);

		values.forEach(function(item) {
			ws.schema.forEach(function(prop, index) {
				if (item[index] === undefined) item[index] = '';
			});
		});

		let len = values.length;

		while (len--) {
			let blankValues = true;

			// Loop through the line item's data (excluding date / time)
			for (let i = 2; i < values[len].length; i++) {
				if (values[len][i]) {
					blankValues = false;
				}
			}

			// Remove the data if blank
			if (blankValues) {
				values.splice(len, 1);
			} else {
				break; // If the data isn't blank, get out of the loop and print what is left
			}
		}

		ws.values = values;
	}

	that.getCsvXlsxData = function({charts, filename}) {
		const res = {
			data: [],
			filename,
		};

		const workSheetsOptions = createWorkSheetsOptions(charts);

		charts.forEach((chart, index) => {
			const {chartObj} = chart;
			const ws = {
				values: [],
				resolution: [],
				schema: [],
				column: [],
				sheetName: createWorkSheetName(workSheetsOptions, chart, index),
			};

			let specialBuilderFunction = xlsxBuilderFunctions[chartObj.chart.options.chartType];

			if (specialBuilderFunction) {
				specialBuilderFunction(ws, chartObj);
			} else {
				defaultBuilderFunction(ws, chartObj, chart);
			}

			res.data.push(ws);
		});

		return res;
	};
	that.getExportData = function({charts, filename, language, unitSystem}) {
		let result = {exportedDataArray: [], filename, language, unitSystem};
		charts.forEach(chart => {
			let exportData = createExportDataFromOpts(chart);
			const sourceChartObj = chart.chartObject;
			let targetChartObj = createChartEntity(
				Object.assign({}, sourceChartObj, {
					isFacilityChart: chart.isFacilityChart,
					isParetoChart: chart.isParetoChart,
					chartConfig: chart.chartConfig,
					chartIndex: chart.chartIndex,
					isEquipmentLevel: chart.isEquipmentLevel,
				})
			);
			exportData.charts.push(targetChartObj);

			copyLinesFromChartOptions(targetChartObj, sourceChartObj);

			if ((sourceChartObj.viewTimeline && chart.isCompareChartExport) || !chart.isCompareChartExport) {
				copyTimeLineDataFromChartOptions(targetChartObj, sourceChartObj);
			}

			if (!targetChartObj.timeline.lanes.length) {
				delete targetChartObj.timeline;
			}
			result.exportedDataArray.push(exportData);
		});
		return result;
	};

	that.getMappedChartsData = function(charts) {
		return charts.map(chart => {
			return {
				chartIndex: chart.chartIndex,
				chartObject: chart.chartObj,
				chartConfig: chart.chartConfig,
				calendarRange: chart.range.calendarRange,
				rangeMode: chart.range.rangeMode,
				exportRange: chart.range.exportRange,
				visualExportEndDate: chart.range.visualExportEndDate,
				equipmentId: chart.chartObj.equipmentId,
				locationId: chart.chartObj.locationId,
				chartId: chart.chartObj.chartId,
				equipmentTypeChart: chart.equipmentTypeChart,
				isFacilityChart: chart.isFacilityChart,
				isParetoChart: chart.isParetoChart,
				isEquipmentLevel: chart.equipmentLevel,
				// To support instance level chart (ex : Motor power performance)
				selectedInstanceEquipmentType: chart.chartObj.selectedInstanceEquipmentType || null,
				instanceName: chart.chartObj.instanceName || null,
				instanceId: chart.chartObj.instanceId || null,
			};
		});
	};

	that.encodeChartState = function({exportedDataArray}) {
		const decodedData = [];
		const {convertBooleanToBinary} = helpers;

		exportedDataArray.forEach(data => {
			const chartData = data.charts[0];
			const chart = chartData.chart || {};
			const {options: {unoccupied, lines, meanLines, thresholds, sortOrder, yAxis: yAxes, currentlyPulledSlice} = {}} = chart;
			const timeline = chartData.timeline || {};
			const range = data.range;
			const decodedState = {};

			if (chart) {
				decodedState.currentlyPulledSlice = currentlyPulledSlice;
				// Lines
				if (lines) {
					decodedState.lines = helpers.compressArrayOfObjects(
						// Send all lines.
						lines.map(line => {
							const {lineHash, visible, lineType, lineThickness, color, markerType, markerSize} = line;
							return {
								hash: lineHash,
								visible,
								lineType,
								lineThickness,
								color,
								markerType,
								markerSize,
							};
						})
					);
				}
				// Mean lines
				if (meanLines) {
					decodedState.meanLines = helpers.compressArrayOfObjects(
						meanLines.map(meanLine => {
							return {
								hash: meanLine.lineHash,
								visible: meanLine.visible,
							};
						})
					);
				}
				// Pareto thresholds
				if (thresholds) {
					decodedState.thresholds = helpers.compressArrayOfObjects(
						thresholds.map(threshold => {
							return {
								hash: threshold.lineHash,
								visible: threshold.visible,
							};
						})
					);
				}
				// Pareto sort order
				if (sortOrder) {
					decodedState.sortOrder = sortOrder;
				}
				// Y-Axes calculated ranges
				if (yAxes) {
					decodedState.yAxes = helpers.compressArrayOfObjects(yAxes.map(({chartAxisId, calculatedRange}) => ({chartAxisId, calculatedRange})));
				}
				// Timeline lanes
				if (timeline.lanes) {
					decodedState.lanes = helpers.compressArrayOfObjects(
						// Send just touched lanes.
						timeline.lanes.filter(lane => lane.touched).map(lane => {
							return {
								hash: lane.lineHash,
								visible: lane.visible,
							};
						})
					);
				}
				// Narrowed down range, if applicable
				decodedState.range = {
					from: +range.from.format('x'),
					to: +range.to.format('x'),
				};

				// Encode boolean values as a binary mask.
				// [_, unoccupied, isFacilityChart, isParetoChart, isSuppressionsEnabled]
				// Example: [1, true, false, true, true] => [1, 1, 0, 1, 1] => `11011` => 27
				decodedState.booleanValues = parseInt(
					[
						1,
						convertBooleanToBinary(unoccupied),
						convertBooleanToBinary(chartData.isFacilityChart),
						convertBooleanToBinary(chartData.isParetoChart),
						convertBooleanToBinary(chartData.isSuppressionsEnabled),
					].join(''),
					2
				);

				// Send just values for this properties.
				// [equipmentId, locationId, chartId, equipmentTypeChart, equipmentType, serviceAdvisoryTypeId, chartIndex, selectedInstanceEquipmentType, instanceName, instanceId]
				decodedState.otherInfo = [
					data.equipmentId,
					data.locationId,
					data.chartId,
					data.equipmentTypeChart,
					chartData.equipmentType,
					chartData.serviceAdvisoryTypeId,
					chartData.chartIndex,
					// To support instance level chart (ex : Motor power performance)
					data.selectedInstanceEquipmentType,
					data.instanceName,
					data.instanceId,
				].map(i => i || 0);

				if (chart.config) {
					// Chart config
					decodedState.chartConfig = {};

					// Compress originalProps
					const originalProps = (decodedState.chartConfig.originalProps = angular.copy(chart.config.originalProps));
					Object.keys(originalProps).forEach(tisObjectType =>
						Object.keys(originalProps[tisObjectType]).forEach(tisObjectId => {
							originalProps[tisObjectType][tisObjectId] = originalProps[tisObjectType][tisObjectId].map(({propertyName}) => propertyName);
						})
					);

					if (Object.keys(chart.config.customChartProps).length) {
						// Compress customChartProps
						const customChartProps = (decodedState.chartConfig.customChartProps = angular.copy(chart.config.customChartProps));

						// Store uoms data in one place to avoid duplicates.
						decodedState.chartConfig.uomsById = {};

						Object.keys(customChartProps).forEach(tisObjectType => {
							Object.keys(customChartProps[tisObjectType]).forEach(tisObjectId => {
								customChartProps[tisObjectType][tisObjectId].forEach(customProp => {
									if (typeof customProp.uom === 'object') {
										decodedState.chartConfig.uomsById[customProp.uom.uomId] = customProp.uom;
										customProp.uom = customProp.uom.uomId;
									}
								});

								customChartProps[tisObjectType][tisObjectId] = helpers.compressArrayOfObjects(customChartProps[tisObjectType][tisObjectId]);
							});
						});
					}

					if (Object.keys(chart.config.formattingProps).length) {
						decodedState.chartConfig.formattingProps = chart.config.formattingProps;
					}
				}

				if (chartData.selectedFacilities) {
					decodedState.selectedFacilities = helpers.compressArrayOfObjects(chartData.selectedFacilities.map(({locationId}) => ({locationId})));
				}

				decodedData.push(decodedState);
			}
		});

		return JSON.stringify(decodedData);
	};

	function createWorkSheetsOptions(charts) {
		const options = {};

		const locationNames = {};
		const dates = {};
		const tisObjectNames = {};
		const chartNames = {};

		charts.forEach(({chartObj, range}) => {
			if (!options.isParetoChart && chartObj.chart.options.chartType === CHART_TYPE.PARETO) {
				options.isParetoChart = chartObj.chart.options.chartType;
			}

			chartObj.selectedFacilities.forEach(({locationName}) => {
				locationNames[locationName] = null;
			});

			dates[range.to.format('YYYY-MM-DD')] = null;
			tisObjectNames[helpers.getPropertyByPath(chartObj, 'selectedEquipment.tisObjectName')] = null;
			chartNames[chartObj.selectedChart.chartName || chartObj.selectedChart.title] = null;
		});

		options.isSameFacilities = Object.keys(locationNames).length === 1;
		options.isSameEquipment = Object.keys(tisObjectNames).length === 1;
		options.isSameCharts = Object.keys(chartNames).length === 1;
		options.isSameDates = Object.keys(dates).length === 1;

		return options;
	}

	function createWorkSheetName(options, currentChart, index) {
		const workSheetMaxLength = 31;
		const prohibitedCharactersRegExp = /[\/*?:[\]&]/g;
		const indexString = ` #${index + 1}`;

		let result = [];

		if (!options.isSameDates) {
			result.push(currentChart.range.to.format('YYYY-MM-DD'));
		}

		if (!options.isSameFacilities) {
			result.push(
				currentChart.chartObj.selectedFacilities.length > 1
					? $filter('translate')('MULTIPLE_FACILITIES')
					: currentChart.chartObj.selectedFacilities[0].locationName
			);
		}

		if (!options.isSameEquipment) {
			!options.isParetoChart && result.push(helpers.getPropertyByPath(currentChart.chartObj, 'selectedEquipment.tisObjectName'));
		}

		if (!options.isSameCharts || !result.length) {
			result.push(currentChart.chartObj.selectedChart.chartName || currentChart.chartObj.selectedChart.title);
		}

		result = result.join('_').replace(prohibitedCharactersRegExp, '');

		result =
			result.length > workSheetMaxLength - indexString.length
				? result.substr(0, workSheetMaxLength - indexString.length) + indexString
				: result + indexString;

		return result.replace(/\s\s+/g, ' ');
	}

	function byTimestamp(a, b) {
		if (a.timestamp < b.timestamp) {
			return -1;
		} else {
			return 1;
		}
	}

	function createDateCell(translationKey, dt, timestamp, type = 'date', optionsToMerge = {}) {
		return {
			type,
			value: dt,
			format: $filter('translate')(translationKey),
			timestamp,
			...optionsToMerge,
		};
	}

	function createItem(data, date) {
		const dt = moment(date);
		const dtUtc = dt.utc();
		const dateString = dtUtc.format('D-MMM-YYYY');
		const timeString = dtUtc.format('h:mm A');

		data[Number(date)] = [
			createDateCell('XLSX_REPORT_DATE_FORMAT', dateString, dtUtc, 'date', {dateString}),
			createDateCell('XLSX_REPORT_TIME_FORMAT', timeString, dtUtc, 'time', {timeString}),
		];
		data[Number(date)].timestamp = dt.toDate();
	}

	function generateTimeValue(exportDate) {
		return moment(~~(dataFormattingService.getDateInUTC(exportDate) / TIME_INTERVAL) * TIME_INTERVAL);
	}

	function createChartEntity(item) {
		let address = item.selectedLocation.address || EMPTY_ADDRESS;
		let optsCopy = angular.copy(item.chart.options);
		let equipmentName = helpers.getPropertyByPath(item, 'selectedEquipment.tisObjectName');
		const chartType = item.chart.options.chartType;
		const isParetoChart = chartType === CHART_TYPE.PARETO;
		if (isParetoChart) {
			equipmentName = item.chart.options.equipmentTypeName;
		}
		optsCopy.lines = [];

		return {
			location: {
				name: item.selectedLocation.locationName,
				city: address.city,
				region: address.region,
				postalCode: address.postalCode,
			},
			selectedFacilities: item.selectedFacilities,
			equipmentName: equipmentName,
			equipmentIsComponentObj: item.equipmentIsComponentObj, // TODO: was absent in compare charts, why??
			chartName: item.selectedChart.chartName || item.selectedChart.title,
			chart: {
				options: optsCopy,
				data: [],
				config: item.chartConfig,
			},
			chartIndex: item.chartIndex,
			timeline: {
				data: [],
				lanes: [],
			},
			isSuppressionsEnabled: item.isSuppressionsEnabled,
			isFacilityChart: item.isFacilityChart,
			isParetoChart: item.isParetoChart,
			equipmentType: item.equipmentType,
			serviceAdvisoryTypeId: item.serviceAdvisoryTypeId,
			isEquipmentLevel: item.isEquipmentLevel,
		};
	}

	function createExportDataFromOpts(opts) {
		return {
			charts: [],
			language: opts.language,
			uom: opts.unitsSystem,
			range: {
				from: dataFormattingService.getDateInUTC(opts.exportRange.from),
				to: dataFormattingService.getDateInUTC(opts.exportRange.to),
			},
			visualExportEndDate: opts.visualExportEndDate,
			rangeMode: opts.rangeMode,
			calendarRange: opts.calendarRange,
			filename: opts.filename,
			equipmentId: opts.equipmentId,
			locationId: opts.locationId,
			chartId: opts.chartId,
			equipmentTypeChart: opts.equipmentTypeChart,
			// To support instance level chart (ex : Motor power performance)
			selectedInstanceEquipmentType: opts.selectedInstanceEquipmentType,
			instanceName: opts.instanceName,
			instanceId: opts.instanceId,
		};
	}

	function copyTimeLineDataFromChartOptions(targetChartObject, sourceChartObj) {
		let tisObjectType = helpers.getPropertyByPath(sourceChartObj, 'selectedEquipment.tisObjectType.tisObjectTypeGroupName');

		sourceChartObj.timeline.lanes.forEach(function(lane) {
			let exportLane = angular.copy(lane);

			!exportLane.isChild && (exportLane.name = that.translateProperty(exportLane.name, tisObjectType));
			exportLane.lane = targetChartObject.timeline.lanes.length || 0;
			targetChartObject.timeline.lanes.push(exportLane);
		});
	}

	function getChartData(data, chartType) {
		let res = angular.copy(data);
		const isScatterChart = chartType.includes(CHART_TYPE.SCATTER);
		const isParetoChart = chartType === CHART_TYPE.PARETO;

		if (!isScatterChart && !isParetoChart) {
			res.forEach(item => {
				item.x = dataFormattingService.getDateInUTC(item.x);
			});
		}
		return res;
	}

	function copyLinesFromChartOptions(targetChartObject, sourceChartObj) {
		const chartType = sourceChartObj.chart.options.chartType;
		const isParetoChart = chartType === CHART_TYPE.PARETO;
		sourceChartObj.chart.options.lines.forEach(function(line, i) {
			let exportLine = angular.copy(line);
			targetChartObject.chart.options.lines.push(exportLine);
			if (!isParetoChart) {
				// let chartData = (sourceChartObj.chart.data[i]) ? getChartData(sourceChartObj.chart.data[i],chartType) : [];
				// targetChartObject.chart.data.push(chartData);
			} else {
				// targetChartObject.chart.data.push(sourceChartObj.chart.options.exportData.chartData);
				// targetChartObject.chart.options.backgrounds = sourceChartObj.chart.options.exportData.backgrounds;
			}
		});
		if (isParetoChart) {
			targetChartObject.chart.options.thresholds = sourceChartObj.chart.options.thresholds;
		}
	}
});
