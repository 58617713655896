import _get from 'lodash.get';
import translations from '../../../../translations/en';

export const getTimePeriodNameByRangeMode = rangeMode => {
	const CUSTOM = 'Custom';

	switch (rangeMode) {
		case 'custom':
			return CUSTOM;
		case '4 week':
			return '4 weeks';
		default:
			return rangeMode;
	}
};

export const getReportNameByKey = key => translations[key] || key;

export const getCprSectionNameBySectionKey = sectionKey => translations.CPR_SECTIONS[sectionKey] || sectionKey;

export const getCprSettingNameByKey = key => _get(translations, `CPR_SETTINGS.${key}`, key);

export const getEquipmentTypeByKey = key => translations.EQUIPMENT_TYPE_NAME_TABLE[key] || key;

export const getPropertiesTranslation = () => translations.PROPERTIES;

export const getRdrFormatByFormatKey = formatKey => {
	const STANDARD = 'Standard';
	const MULTI_OBJECT = 'Multi-Object';
	const CSV = 'CSV';

	switch (formatKey) {
		case 'standard':
			return STANDARD;
		case 'single':
			return MULTI_OBJECT;
		case 'standardCsv':
			return CSV;
	}
};

export const getReportGenerationStatus = status => {
	switch (status) {
		case 'started':
			return 'STARTED';
		case 'success':
			return 'SUCCESS';
		case 'failed':
			return 'FAILED';
	}
};

export const getYesNoFromBoolean = bool => (bool ? 'Yes' : 'No');

export const CHILLER_COMPONENTS = ['Circuit', 'Compressor'];
