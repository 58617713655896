/* eslint eqeqeq: [0, 'always'] */
/* eslint one-var: 0 */

angular
	.module('TISCC')
	.directive('fixThWidth', function(uiStateService) {
		return {
			restrict: 'A',
			link: function(scope, element, attrs) {
				const widths = uiStateService.getState(scope.uiStateKey);

				if (!widths.headers) {
					widths.headers = ['20%', '20%'];
				}

				element.css('width', widths.headers[attrs.fixThWidth]);
			},
		};
	})
	.directive('ngRepeatEndedCb', function($timeout, helpers) {
		return {
			restrict: 'A',
			link: function(scope, element, attr) {
				if (scope.$last === true) {
					$timeout(helpers.getPropertyByPath(scope, attr.ngRepeatEndedCb));
				}
			},
		};
	})
	.directive('animEnd', function(helpers) {
		function whichTransitionEvent() {
			let t;
			const el = document.createElement('fakeelement');
			const transitions = {
				transition: 'transitionend',
				OTransition: 'oTransitionEnd',
				MozTransition: 'transitionend',
				WebkitTransition: 'webkitTransitionEnd',
			};

			for (t in transitions) {
				if (el.style[t] !== undefined) {
					return transitions[t];
				}
			}
		}

		return {
			restrict: 'A',
			link: function(scope, element, attrs) {
				const handler = helpers.getPropertyByPath(scope, attrs.animEnd);
				const transitionEvent = whichTransitionEvent();
				transitionEvent && element[0].addEventListener(transitionEvent, handler);
			},
		};
	})
	.directive('tooltip', function($http, $templateCache, $compile, $timeout) {
		function getOffsetRect(elem) {
			let box = elem.getBoundingClientRect(),
				body = document.body,
				docElem = document.documentElement,
				scrollTop = window.pageYOffset || docElem.scrollTop || body.scrollTop,
				scrollLeft = window.pageXOffset || docElem.scrollLeft || body.scrollLeft,
				clientTop = docElem.clientTop || body.clientTop || 0,
				clientLeft = docElem.clientLeft || body.clientLeft || 0,
				top = box.top + scrollTop - clientTop,
				left = box.left + scrollLeft - clientLeft;

			return {top: Math.round(top), left: Math.round(left), height: box.height, width: box.width};
		}
		function updateTooltipVerticalPosition(attrs) {
			let ARROW_Y_OFFSET = 5,
				tooltipHeight = attrs.tooltipHeight,
				browserHeight = attrs.browserHeight,
				chartRect = attrs.chartRect,
				chartYOffset = attrs.chartYOffset,
				yPos = attrs.yPos,
				tooltipContainer = angular.element(document.getElementById('multi-tooltip-container')),
				top,
				arrowTop;
			if (tooltipHeight > browserHeight) {
				if (chartRect.height < browserHeight) {
					tooltipContainer.height(chartRect.height);
					top = chartRect.top;
					arrowTop = chartYOffset + yPos - ARROW_Y_OFFSET;
				} else {
					tooltipContainer.height(browserHeight);
					top = window.pageYOffset;
					arrowTop = chartRect.top + yPos + chartYOffset - ARROW_Y_OFFSET;
				}
			} else if (tooltipHeight > chartRect.height) {
				tooltipContainer.height(chartRect.height);
				top = chartRect.top;
				arrowTop = chartYOffset + yPos - ARROW_Y_OFFSET;
			} else {
				if (yPos - tooltipHeight / 2 < 0) {
					top = chartRect.top;
					arrowTop = chartYOffset + yPos - ARROW_Y_OFFSET;
				} else if (yPos + chartYOffset + tooltipHeight / 2 > chartRect.height) {
					top = chartRect.top + chartRect.height - tooltipHeight;
					arrowTop = chartYOffset + yPos - chartRect.height + tooltipHeight - ARROW_Y_OFFSET;
				} else {
					top = chartRect.top + yPos + chartYOffset - tooltipHeight / 2;
					arrowTop = tooltipHeight / 2 - ARROW_Y_OFFSET;
				}

				if (top < window.pageYOffset) {
					top = window.pageYOffset;
					arrowTop = chartRect.top + yPos + chartYOffset - top - ARROW_Y_OFFSET;
				}
				if (top + tooltipHeight - window.pageYOffset > browserHeight) {
					top = window.pageYOffset + browserHeight - tooltipHeight;
					arrowTop = chartRect.top + yPos + chartYOffset - top - ARROW_Y_OFFSET;
				}
			}
			return {top: top, arrowTop: arrowTop};
		}

		function updateTooltipHorizontalPosition(attrs) {
			let ARROW_X_OFFSET = 7,
				element = attrs.element,
				tooltipWidth = attrs.tooltipWidth,
				browserWidth = attrs.browserWidth,
				chartRect = attrs.chartAreaElement.getBoundingClientRect(),
				chartXOffset = attrs.chartXOffset,
				xPos = attrs.xPos,
				elementLeftOffset = xPos + chartXOffset + chartRect.left + window.pageXOffset,
				left;
			if (
				(xPos > (chartRect.width - 2 * chartXOffset) / 2 || elementLeftOffset + tooltipWidth + ARROW_X_OFFSET > browserWidth) &&
				elementLeftOffset > window.pageXOffset + chartXOffset &&
				elementLeftOffset - tooltipWidth - ARROW_X_OFFSET > 0
			) {
				left = elementLeftOffset - tooltipWidth - ARROW_X_OFFSET;
				element.addClass('left');
			} else {
				left = elementLeftOffset + ARROW_X_OFFSET;
			}
			if (left + tooltipWidth - window.pageXOffset > browserWidth) {
				left = window.pageXOffset + browserWidth - tooltipWidth;
				angular.element(document.getElementById('tooltipArrow')).addClass('ng-hide');
			}
			return left;
		}

		function checkLinesCount(scope) {
			const multiTooltipContainer = document.getElementById('multi-tooltip-container');

			if (multiTooltipContainer === null) {
				return;
			}

			const tooltipContainer = angular.element(multiTooltipContainer);

			// "clientWidth + 1" is applied to avoid rounding errors while page zooming, scrollWidth and clientWidth are always integers
			if (tooltipContainer[0].scrollWidth > tooltipContainer[0].clientWidth + 1) {
				let moreMessage = angular.element(document.getElementById('multi-tooltip-more')),
					tooltipLines = document.getElementsByClassName('tooltip-line'),
					lineCount = 0,
					i = tooltipLines.length - 1;
				moreMessage.removeClass('ng-hide');
				while (tooltipContainer[0].scrollWidth > tooltipContainer[0].clientWidth) {
					const el = tooltipLines[i--];
					el.parentNode.removeChild(el);
					lineCount++;
				}
				scope.tooltipData.moreLines = lineCount;
			}
		}

		return {
			templateUrl: function(elem, attrs) {
				return attrs.templateUrl;
			},
			link: function(scope, element) {
				if (['lineMultiPoints', 'paretoMultipoints', 'scatterMultipoints'].includes(scope.tooltipData.chartType)) {
					element.css('visibility', 'hidden');
					$timeout(function() {
						const pixelsToBottom = 300;
						const {offsetTop} = element[0];

						// This is needed to prevent twinkling of vertical scroll when multipoint tooltip appears in the bottom of the page
						const elementOffsetTop = offsetTop ? offsetTop + pixelsToBottom : offsetTop;

						element.css('left', 0);
						element.css('top', 0);

						let tooltipHeight = element.height(),
							tooltipWidth = element[0].offsetWidth,
							xPos = scope.tooltipData.xPos,
							yPos = scope.tooltipData.yPos,
							chartXOffset = +element.attr('chartXOffset'),
							chartYOffset = +element.attr('chartYOffset'),
							chartAreaElements = Array.from(document.getElementsByClassName('chart-area')),
							tooltipArrow = angular.element(document.getElementById('tooltipArrow')),
							browserWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
							browserHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
							top,
							left,
							arrowTop,
							chartRect,
							chartArea;

						if (chartAreaElements) {
							chartAreaElements.forEach(item => {
								if (item && item instanceof HTMLElement) {
									const offsets = getOffsetRect(item);
									chartArea = elementOffsetTop > offsets.top ? item : chartArea;
								}
							});
							element.css('width', tooltipWidth + 'px');
							if (chartArea && chartArea instanceof HTMLElement) {
								chartRect = getOffsetRect(chartArea);

								if (tooltipHeight > browserHeight || tooltipHeight > chartRect.height) {
									element.addClass('two-col');
									element.css('width', 'auto');
									tooltipWidth = element[0].offsetWidth;
									tooltipHeight = element.height();
								}

								const vertPos = updateTooltipVerticalPosition({
									tooltipHeight: tooltipHeight,
									browserHeight: browserHeight,
									chartRect: chartRect,
									chartYOffset: chartYOffset,
									yPos: yPos,
								});
								top = vertPos.top;
								arrowTop = vertPos.arrowTop;

								left = updateTooltipHorizontalPosition({
									element: element,
									tooltipWidth: tooltipWidth,
									browserWidth: browserWidth,
									chartRect: chartRect,
									chartXOffset: chartXOffset,
									xPos: xPos,
									chartAreaElement: chartArea,
								});

								checkLinesCount(scope);

								element.css('left', left + 'px');
								element.css('top', top + 'px');
								tooltipArrow.css('top', arrowTop + 'px');
								element.css('visibility', 'visible');
							}
						}
					}, 0);
				} else {
					// Calculate tooltip offset based on its height
					//
					const topOffset = element.find('div').height();
					const elementOffset = parseInt(element.css('top'), 10);
					element.css('top', elementOffset - topOffset + scope.tooltipData.offset.top + 'px');
				}
			},
		};
	})

	.directive('dropdownSubmenu', function($timeout) {
		let previousSubmenuShown = null;
		return {
			restrict: 'C',
			link: function(scope, element) {
				const submenu = element.find('ul');
				let timer;
				element.bind('mouseover', function() {
					if (previousSubmenuShown) {
						previousSubmenuShown.removeClass('active');
					}
					previousSubmenuShown = submenu;
					submenu.addClass('active');
					if (timer) {
						$timeout.cancel(timer);
					}
				});
				element.bind('mouseout', function() {
					timer = $timeout(function() {
						submenu.removeClass('active');
						// check if 'previousSubmenuShown' was not changed
						// before callback invokes
						if (previousSubmenuShown == submenu) {
							previousSubmenuShown = null;
						}
					}, 800);
				});
			},
		};
	})

	.directive('editableRow', function() {
		return {
			templateUrl: 'components/settings/editable-row.html',
		};
	})
	.directive('delayedModel', function() {
		return {
			scope: {
				model: '=delayedModel',
			},
			link: function(scope, element, attrs) {
				element.val(scope.model);

				scope.$watch('model', function(newVal, oldVal) {
					if (newVal !== oldVal) {
						element.val(scope.model);
					}
				});

				let timeout;
				element.on('keyup paste search', function() {
					clearTimeout(timeout);
					timeout = setTimeout(function() {
						if (scope.model !== element[0].value) {
							scope.model = element[0].value;
							element.val(scope.model);
							scope.$apply();
						}
					}, attrs.delay || 500);
				});
			},
		};
	})
	.directive('beta', function($translate) {
		return {
			restrict: 'C',
			compile: function(elem) {
				elem.attr('title', $translate('BETA_TEXT'));
				return function(scope, elem) {
					elem[0].addEventListener(
						'click',
						function(e) {
							e.preventDefault();
							e.stopPropagation();
						},
						true
					);
				};
			},
		};
	})
	.directive('submenuLeft', function($rootScope, $timeout) {
		return {
			restrict: 'A',
			link: function(scope, element) {
				scope.$watch('subMenuItems', function(newValue, oldValue) {
					if (newValue !== null && newValue !== oldValue) {
						$timeout(function() {
							$rootScope.subMenuWidth = element[0].getBoundingClientRect().width;
						}, 0);
					}
				});
			},
		};
	})
	// This directive is used as input[type=number] doesn't work properly in IE11 and Edge - user can type digits and letters
	.directive('numbersOnly', function() {
		return {
			restrict: 'A',
			require: 'ngModel',
			scope: {
				disableNegativeValues: '<',
				returnAsString: '<',
			},
			link: function(scope, element, attr, ngModelCtrl) {
				function fromUser(text) {
					if (text) {
						const originalText = text;
						const {disableNegativeValues, returnAsString} = scope;
						let transformedInput = '';
						let match = null;

						if (text[0] === '.') {
							text = `0${text}`;
						}

						// Replace all non digits by empty row
						transformedInput = text.replace(/[^\-0-9.]/g, '');

						// Remove all redundant dots excluding the first one
						match = transformedInput.match(/^[^.]*\.|[^.]+/g);
						transformedInput = match ? match.join('') : transformedInput;

						// Remove all redundant minuses excluding the first one
						match = transformedInput.match(/^[^-]*\-|[^-]+/g);
						transformedInput = match ? match.join('') : transformedInput;

						// Remove minus if it isn't first symbol or negative values are disabled
						if (disableNegativeValues || transformedInput.indexOf('-') > 0) {
							transformedInput = transformedInput.replace(/-/g, '');
						}

						if (transformedInput !== originalText) {
							ngModelCtrl.$setViewValue(transformedInput);
							ngModelCtrl.$render();
						}

						return returnAsString ? transformedInput : Number(transformedInput);
					}
				}
				ngModelCtrl.$parsers.push(fromUser);
			},
		};
	})
	// This directive is used because [autofocus] attribute in modal doesn't work a second time.
	// https://github.com/angular-ui/bootstrap/issues/2802
	.directive('autofocus', function($window) {
		return {
			restrict: 'A',
			link: function(scope, element) {
				$window.setTimeout(() => element[0].focus());
			},
		};
	})
	/**
	 * Used to execute expression on document click
	 * @example
	 * <span on-window-click="count=count+1"></span>
	 */
	.directive('onWindowClick', function($document) {
		return {
			restrict: 'A',
			scope: {
				onWindowClick: '&',
			},
			link: function(scope, element) {
				function click() {
					scope.$apply(scope.onWindowClick());
				}

				$document.on('click', click);

				element.on('$destroy', function() {
					$document.off('click', click);
				});
			},
		};
	});
