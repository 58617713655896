export const getColumnName = column => {
	switch (column) {
		case 'actionNameTitle':
			return 'Automated Test';
		case 'testStatus.text':
			return 'Test Status';
	}

	return column;
};
