import _get from 'lodash.get';

/**
 * A service layer to get resolution of selected equipments and update them on passed properties
 */

angular.module('TISCC').service('propertyResolutionService', function($http, cacheService) {
	// ------------------------------ Constants ------------------------------

	const GET_RESOULTION_API_ENDPOINT = '/ext_api/api/tisObject/data?hpath=~DisplayResolution%7CLatest()&interval=PT15m&timeZone=local&stp=past3Months';

	const ALLOWED_EQUIPMENT_TYPES = {
		15: true, // 'Points',
	};

	const ALLOWED_PROPERTIES = {
		15: {
			PresentValue: true,
		},
	};

	// ------------------------------ Api methods ------------------------------

	return {
		getAnalogResolution,
		getPropertyResoultionWithKeyValPair,
		isAllowedEquipmentType,
		isAllowedProperties,
		updateResolutionForAllProperties,
	};

	// ------------------------------ Implemenation ------------------------------

	/**
	 * (Immutable) method to get analog resolution for given equipment id
	 *
	 * @param {*} tisObjectTypeGroupNumber
	 * @param {*} tisObjectIds
	 * @returns
	 * 			[{tisObjectId:123 , resolution: 1}]
	 */
	function getAnalogResolution(tisObjectTypeGroupNumber, tisObjectIds) {
		return isAllowedEquipmentType(tisObjectTypeGroupNumber) ? getAnalogResolutionApi(tisObjectIds, tisObjectTypeGroupNumber) : Promise.resolve([]);
	}

	/**
	 * method to handle Api request
	 * @param {*} tisObjectIds
	 * @param {*} tisObjectTypeGroupNumber
	 * @returns
	 */
	function getAnalogResolutionApi(tisObjectIds, tisObjectTypeGroupNumber) {
		const combinedTisObjectIds = Array.isArray(tisObjectIds) ? tisObjectIds.join(',') : tisObjectIds;

		if (!combinedTisObjectIds) {
			return Promise.resolve([]);
		}

		const cacheKey = (Array.isArray(tisObjectIds) ? tisObjectIds.join(',') : tisObjectIds) + '-' + tisObjectTypeGroupNumber;

		const config = {
			cache: cacheService.getCacheInstance('propertyResolutionService', 'resolution', cacheKey),
			headers: {
				TisObjectIds: combinedTisObjectIds,
				'Hydration-Strategy-Default': 'nullCopyforward',
				'Missing-Updates-Default': 'skip',
				'Filter-Special-Mark-Values': false,
			},
		};

		const extractValue = response => {
			const resolution = _get(response, 'parameters[0].values[0].value', null);
			const tisObjectId = _get(response, 'tisObjectId', null);

			return {resolution, tisObjectId};
		};

		const success = response => _get(response, 'data.tisObjectDataList', []).map(extractValue);

		return $http.get(GET_RESOULTION_API_ENDPOINT, config).then(success, () => []);
	}

	function isAllowedEquipmentType(tisObjectTypeGroupNumber) {
		return !!ALLOWED_EQUIPMENT_TYPES[tisObjectTypeGroupNumber];
	}

	function isAllowedProperties(tisObjectTypeGroupNumber, propertyDisplayName) {
		return isAllowedEquipmentType(tisObjectTypeGroupNumber) && !!(ALLOWED_PROPERTIES[tisObjectTypeGroupNumber] || {})[propertyDisplayName];
	}

	/**
	 * (Immutable) method to update resolution on given properties only if properties are configured at ALLOWED_PROPERTIES
	 *
	 * @param {*} properties
	 * @param {*} resolution
	 * @param {*} tisObjectTypeGroupNumber
	 * @returns
	 */
	function updateResolutionForAllProperties(properties = [], resolution, tisObjectTypeGroupNumber) {
		if (resolution === null || resolution === undefined) return properties;

		return [...properties].map(prop => {
			const propertyAttribute = prop.propertyAttribute;

			if (!propertyAttribute) return prop;

			const isAllowedToEditResolution = isAllowedProperties(tisObjectTypeGroupNumber, propertyAttribute.displayName);

			if (propertyAttribute && propertyAttribute.resolution && isAllowedToEditResolution) {
				propertyAttribute.resolution = resolution;
			}
			return prop;
		});
	}

	/**
	 * (Immutable) method to build key-val pair for given property resolutions
	 *
	 * @param {Array[{tisObjectId:*, resolution: *}]} propertyResolutions
	 * @returns
	 * 			If the resolution of multiple equipments are same, it will return [key]: resoultion like [key]:1
	 * 			If the resolution of multiple equipments are  differnt, it will return [key]: [{resolution:0.1, tisObjectId : 123}, {resolution:0.001, tisObjectId : 111}]
	 */
	function getPropertyResoultionWithKeyValPair(propertyResolutions = []) {
		return propertyResolutions.reduce(
			(av, cv) => {
				av.key += '-' + cv.tisObjectId;

				if (av.resolution === null) {
					av.resolution = cv.resolution;
					av.isSame = true;
				}

				if (av.resolution !== cv.resolution && av.isSame) {
					av.isSame = false;
					av.value = propertyResolutions;
				} else if (av.isSame) {
					av.resolution = cv.resolution;
					av.value = av.resolution;
				}

				return av;
			},
			{
				key: 'key',
				value: 1,
				isSame: null,
				resolution: null,
			}
		);
	}
});
