/* eslint max-depth: ["error", 8]*/
/* eslint quotes: [0, 'single'] */
/* eslint eqeqeq: [0, 'always'] */
/* eslint semi: [0, 'always'] */
/* eslint one-var: 0 */

angular
	.module('TISCC')
	.filter('cut', function() {
		return function(value, max, tail) {
			if (!value) return '';

			max = parseInt(max, 10);
			if (!max) return value;
			if (value.length <= max) return value;

			value = value.substr(0, max);
			return value + (tail || '');
		};
	})
	.filter('bytes', function($translate) {
		return function(bytes, precision) {
			if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
			if (typeof precision === 'undefined') precision = 1;
			let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'],
				number = Math.floor(Math.log(bytes) / Math.log(1024));
			return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + $translate(units[number]);
		};
	})
	.filter('diff', function() {
		return function(inputArray, filterArray) {
			return inputArray.filter(function(i) {
				return !(filterArray.indexOf(i) > -1);
			});
		};
	})
	.filter('unique', function() {
		return function(inputArray, key) {
			let unique = {};
			let uniqueList = [];

			if (isMultiDimensional(key)) {
				inputArray.forEach(function(item) {
					let found = key.every(function(key) {
						if (isPresent(item[key])) {
							item = item[key];
							return true;
						}
					});
					if (found && isUnique(item)) {
						addValue(item);
					}
				});
			} else {
				inputArray.forEach(function(item) {
					let value = item[key];
					if (isPresent(value) && isUnique(value)) {
						addValue(value);
					}
				});
			}

			return uniqueList;

			function isPresent(value) {
				if (typeof value != 'undefined') {
					return true;
				}
			}
			function isUnique(value) {
				if (typeof unique[value] == 'undefined') {
					return true;
				}
			}
			function isMultiDimensional(value) {
				if (value.indexOf('.') != -1) {
					key = value.split('.');
					return true;
				}
			}
			function addValue(value) {
				unique[value] = '';
				uniqueList.push(value);
			}
		};
	})
	.filter('cut', function() {
		return function(value, wordwise, max, tail) {
			if (!value) {
				return '';
			}

			max = parseInt(max, 10);

			if (!max) {
				return value;
			}

			if (value.length <= max) {
				return value;
			}

			value = value.substr(0, max);

			if (wordwise) {
				let lastspace = value.lastIndexOf(' ');
				if (lastspace != -1) {
					value = value.substr(0, lastspace);
				}
			}

			return value + (tail || ' …');
		};
	})
	.filter('tableSort', function($filter) {
		return function(inputArray = [], params) {
			let expression = [];

			for (let i = 0; i < params.length; i++) {
				let order = params[i].order ? '+' : '-';
				expression.push(order + params[i].column);
			}

			return $filter('orderBy')(inputArray, expression);
		};
	})
	// TODO: great candidate for unit tests!
	.filter('tableTreeSort', function($filter) {
		return function(inputArray, params, exceptions) {
			let expression = [];
			let order;
			let applyFor;
			let hasExceptions;
			let sortProperty;
			let sortPropertyParam;
			if (params && params.length) {
				expression.push('branch.testType');
				for (let i = 0; i < params.length; i++) {
					order = params[i].order ? '+' : '-';
					expression.push(order + params[i].column);
				}
			}

			if (exceptions) {
				let exceptionalValues = exceptions.exceptionalValues;
				applyFor = exceptions.applyFor;
				hasExceptions = exceptionalValues.length;
				sortProperty = exceptions.sortProperty;

				// This function will be passed to orderBy filter
				sortPropertyParam = function(item) {
					let value = ''; // Field value items will be ordered by
					order = order || '+';

					if (params && params.length) {
						value = item[params[0].column];
					}

					if (item.branch && item.branch[sortProperty]) {
						// exceptionalIdx depicts sorting priority (0 is highest)
						// If item's sortProperty is in the list of "exceptional" values, it will have the priority equal to its index
						let exceptionalIdx = exceptionalValues.indexOf(item.branch[sortProperty]);
						if (exceptionalIdx == -1) {
							// otherwise priority is the lowest
							exceptionalIdx = exceptionalValues.length;
						}
						// We prepend the priority to the value so the items would be sorted first by priority and then by field value
						if (order != '+') {
							// "invert" the priority number for ascending order
							value = hasExceptions - exceptionalIdx + value;
						} else {
							value = exceptionalIdx + value;
						}
					}

					let isAutomatedTest = item.branch.testType === 'hierarchical';

					if (isAutomatedTest) {
						// Exclamation mark is smaller than any other utf-8 character.
						// Make property lexicographically smaller that anything else,
						// until there is no string with 4 ! in beginning.
						value = '!!!' + value;
					}

					return value;
				};
			}

			let rootItemsArr = inputArray.reduce(function(out, item, i) {
				if (item.level === 1) {
					out.push(sortChildrenOfItem(i));
				}

				return out;
			}, []);

			function sortChildrenOfItem(index) {
				let childrenArr = [];
				let parentItem = inputArray[index];
				let parentLevel = parentItem.level;
				let nextItem;

				if (inputArray[index + 1] && inputArray[index + 1].level > parentLevel) {
					let item = inputArray[++index];

					while (item && item.level > parentLevel) {
						let diff = item.level - parentLevel;

						if (diff === 1) {
							childrenArr.push(item);
						}

						nextItem = inputArray[++index];

						if (nextItem && nextItem.level > item.level) {
							item = sortChildrenOfItem(index - 1);
						}

						item = nextItem;
					}
					// sorting array of the same level items
					if (parentItem.branch[sortProperty] == applyFor && hasExceptions) {
						childrenArr = $filter('orderBy')(childrenArr, sortPropertyParam);
					} else {
						childrenArr = $filter('orderBy')(childrenArr, expression);
					}
					// return item with the list of its sorted chilren
					parentItem.childrenArr = childrenArr;
				}

				return parentItem;
			}

			rootItemsArr = $filter('orderBy')(rootItemsArr, expression);
			return expandHierarchy(rootItemsArr, []);
		};

		function expandHierarchy(arr, out) {
			arr.forEach(function(val) {
				out.push(val);

				if (val.childrenArr) {
					expandHierarchy(val.childrenArr, out);
				}
			});

			return out;
		}
	})
	.filter('tableFilter', function(helpers) {
		return function(inputArray, params) {
			let filterColumns = [];
			let res = inputArray;
			let indexFound;
			let column;

			for (let key in params) {
				//			if(params[key].search) {
				//				expression[key] = params[key].search;
				//				search = true;
				//			}
				if ((params[key].equals && params[key].equals.length) || params[key].date || params[key].range) {
					filterColumns.push(key);
				}
			}

			function checkData(item, column) {
				let param = params[column];
				let value;
				if (column.includes('.')) {
					value = helpers.getPropertyByPath(item, column);
				} else {
					value = item[column];
				}
				switch (params[column].type) {
					case 'date':
						if (param.date.min <= value && param.date.max > value) return true;
						break;
					case 'time':
					case 'number':
						if (param.range.min <= value && param.range.max >= value) return true;
						break;
					case 'string':
					case 'tree':
						if (param.equals.includes(value)) return true;
						break;
					case 'list':
						if (!value.length && param.equals.includes('')) return true;

						if (!value.length && param.equals.length == 1 && !param.equals[0]) return true;

						if (!item[column].length) for (let i = param.equals.length; i--; ) if (!param.equals[i]) return true;

						for (let j = value.length; j--; ) if (param.equals.includes(value[j])) return true;
						break;
				}
				return false;
			}

			let lenI = 0;
			if (inputArray && inputArray.length > 0 && filterColumns && filterColumns.length) {
				let j;
				let item;
				let index;
				let currentLevel;
				let lenC = filterColumns.length;
				let isAdded = new Array(inputArray.length);
				res = [];
				indexFound = [];

				for (let i = 0; i < inputArray.length; i++) {
					item = inputArray[i];
					for (j = lenC; j--; ) {
						column = filterColumns[j];
						if (checkData(item, column)) {
							if (!j && !isAdded[i]) {
								indexFound.push(i);
								isAdded[i] = true;
								index = i - 1;
								currentLevel = item.level;

								while (index >= 0 && currentLevel >= 1) {
									if (inputArray[index].level < currentLevel) {
										if (!isAdded[index]) {
											indexFound.push(index);
											isAdded[index] = true;
											--currentLevel;
										} else {
											break;
										}
									} else if (inputArray[index].level == 1) {
										break;
									}

									index--;
								}
							}
						} else {
							break;
						}
					}
				}
			}

			if (indexFound) {
				indexFound.sort((a, b) => a - b);
				let i;
				for (i = 0, lenI = indexFound.length; i < lenI; i++) {
					res[i] = inputArray[indexFound[i]];
				}
			}
			return res;
		};
	})
	.filter('join', function() {
		return function(inputArray, column, separator) {
			let res = [];

			for (let i = 0, len = inputArray.length; i < len; i++) {
				res.push(inputArray[i][column]);
			}

			return res.join(separator || ', ');
		};
	})
	.filter('filterNested', function($filter) {
		let res = [],
			arr;
		return function filterNested(inputArray, params, comparator) {
			inputArray.forEach(function(val) {
				if (val.children) {
					filterNested(val.children, params, comparator);
				}
				arr = $filter('filter')([val], params, comparator);
				arr.forEach(function(item) {
					res.push(item);
				});
			});
			return res;
		};
	})
	.filter('feetToMeter', function() {
		return function(input, numberOfDigits) {
			if (isNaN(parseFloat(input)) || !isFinite(input)) {
				return '';
			}
			if (typeof numberOfDigits === 'undefined') {
				numberOfDigits = 0;
			}
			return (input * 0.3048).toFixed(numberOfDigits);
		};
	})
	.filter('translateProperty', function($translate) {
		return function(propertyName, tisObjectType, params, isMultiple, multiplePrefix = 'M') {
			const isNotTranslated = t => t.startsWith('PROPERTIES');
			let translation = 'PROPERTIES';

			if (isMultiple) {
				propertyName = multiplePrefix + '_' + propertyName;
			}

			if (tisObjectType) {
				translation = $translate(`PROPERTIES.${tisObjectType}${propertyName}`, params);
			}

			if (isNotTranslated(translation)) {
				translation = $translate(`PROPERTIES.${propertyName}`, params);
			}

			return isNotTranslated(translation) ? $translate(propertyName, params) : translation;
		};
	})
	.filter('translateTest', function($translate) {
		return function(propertyName, params) {
			let translation;

			translation = $translate('AUTOMATED_TEST_ACTIONS.' + propertyName, params);
			if (translation.startsWith('AUTOMATED_TEST_ACTIONS')) {
				translation = $translate(propertyName, params);
			}
			return translation;
		};
	})
	.filter('filterOutCharacteristics', function() {
		return (input = []) => input.filter(item => !item.isCharacteristic || item.highlighted);
	})
	.filter('filterBySearchText', function($translate) {
		return function(inputArray = [], conditions) {
			const returnValue = [];
			const childrenToShow = [];

			function addNodeAndAllNestedChildrenToVisible(node) {
				if (node.branch) {
					node = node.branch;
				}
				if (!~childrenToShow.indexOf(node.uid)) childrenToShow.push(node.uid);
				if (node.children && node.children.length) {
					for (let i = 0, n = node.children.length; i < n; ++i) {
						addNodeAndAllNestedChildrenToVisible(node.children[i]);
					}
				}
			}

			function nodeShouldBeShown(uid) {
				return ~childrenToShow.indexOf(uid);
			}

			function translateIfNeeded(text = '') {
				const {useTranslation, translationPrefix = ''} = conditions;

				if (useTranslation) {
					return $translate(translationPrefix + text);
				}

				return '' + text;
			}

			function checkMatching(subject) {
				let childrenSearchResult = false;

				if (subject.branch && conditions.searchNested) {
					subject = subject.branch;
				}

				const ownSearchResult = conditions.searchFieldsList.some(function(fieldName) {
					let result;
					const text = translateIfNeeded(subject[fieldName]);

					result =
						text &&
						(typeof subject[fieldName] === 'string' || typeof subject[fieldName] === 'number') &&
						text.toLowerCase().includes(conditions.searchText);
					if (result && conditions.searchNested) {
						addNodeAndAllNestedChildrenToVisible(subject);
					}
					return result;
				});

				if (subject.children && subject.children.length && subject.children.length > 0) {
					childrenSearchResult = subject.children.some(checkMatching);
				}

				return ownSearchResult || childrenSearchResult || (conditions.searchNested && nodeShouldBeShown(subject.uid));
			}

			if (!conditions.searchText || !conditions.searchFieldsList.length || conditions.searchFieldsList.length < 1) {
				return inputArray;
			}

			conditions.searchText = conditions.searchText.toLowerCase();

			inputArray.forEach(function(item) {
				if (checkMatching(item)) {
					returnValue.push(item);
				}
			});
			return returnValue;
		};
	})
	// Sets index of element to start iterate from.
	// Used in ng-repeat directive.
	.filter('startFrom', function() {
		return function(input, start) {
			return input ? input.slice(+start) : input;
		};
	});
