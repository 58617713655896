import _get from 'lodash.get';
import {CPR_DATE_FORMATS} from '../chiller-performance-report-config';
import {conversionTable, dateFormatByLang} from '../chiller-performance-report-service';

const {CHILLER_DATA_DATE_FORMAT, TIMEZONE_FORMAT} = CPR_DATE_FORMATS;

(function() {
	const CHILLER_GROUP_NAME = 'Chiller';
	const services = new WeakMap();
	const privateMembers = new WeakMap();

	class ChillerPerformanceReportController {
		constructor(
			$location,
			$rootScope,
			$routeParams,
			$filter,
			$log,
			$q,
			urlService,
			helpers,
			ErrorPageFactory,
			locationDetailsService,
			locationEquipmentService,
			LocationSettingsFctry,
			tisObjectService,
			CHART_DATE_FORMAT,
			CPR_REPORT_SECTIONS,
			CPR_DETAILS_ACTION_TYPE,
			chillerPerformanceReportConfig,
			chillerPerformanceReportService,
			chillerPerformanceReportApiService
		) {
			services.set(this, {
				$location,
				$rootScope,
				$routeParams,
				$filter,
				$log,
				$q,
				urlService,
				helpers,
				ErrorPageFactory,
				locationDetailsService,
				locationEquipmentService,
				LocationSettingsFctry,
				tisObjectService,
				CHART_DATE_FORMAT,
				CPR_REPORT_SECTIONS,
				CPR_DETAILS_ACTION_TYPE,
				chillerPerformanceReportConfig,
				chillerPerformanceReportService,
				chillerPerformanceReportApiService,
			});
			privateMembers.set(this, {
				equipmentId: $routeParams.equipmentId,
			});

			$rootScope.backgroundColor = 'white';
			$rootScope.isExport = true;
			$rootScope.exportClass = 'page';

			this.CPR_REPORT_SECTIONS = CPR_REPORT_SECTIONS;
			this.PLACE_HOLDER = '*****';
			this.isGenerated = false;
			this.notAChiller = false;
			this.currentYear = new Date().getFullYear();
			this.facilityName = '';
			this.addressLine = '';
			this.chillerName = '';
			this.equipmentImage = '';
			this.chillerSerialNumber = '';
			this.chillerModelNumber = '';
			this.startTimePeriod = '';
			this.endTimePeriod = '';
			this.reportCreatedDate = '';
			this.customerName = '';
			this.salesOrderNumber = '';
			this.chillerEnteredService = '';
			this.chillerCapacity = '';
			this.chillerLife = '';
			this.reportSectionsData = {};
			this.pages = [];

			this.from = moment();
			this.to = moment();
			this.lang = chillerPerformanceReportConfig.lang;

			this.initialize();
		}

		initialize() {
			const {$log} = services.get(this);
			const _privateMembers = privateMembers.get(this);

			return this._getLocationDetails()
				.then(() => {
					_privateMembers.allowedReportSections = this._getAllowedReportSections();
					_privateMembers.otherInfoFromUrl = this._getOtherInfoFromUrl();
				})
				.then(() => this._getLocationObjectsList())
				.then(() => this._setProperDates())
				.then(() => this._getChillerPerformanceReportData())
				.then(() => this._getOtherChillerInfo())
				.then(() => this._getProperDataAndFillReportSections())
				.then(() => this._getStaticInfo())
				.then(() => this._divideReportSectionsPerPages())
				.then(() => {
					this.isGenerated = true;
				})
				.catch(error => $log.error(error));
		}

		_getLocationDetails() {
			const {$routeParams, locationDetailsService} = services.get(this);

			return locationDetailsService
				.getLocationDetailsWithoutServiceAdvisories($routeParams.locationId)
				.then(data => (privateMembers.get(this).locationDetails = data));
		}

		_getLocationObjectsList() {
			const {$filter, $routeParams, locationEquipmentService} = services.get(this);
			const _privateMembers = privateMembers.get(this);

			return locationEquipmentService.getLocationObjectsList($routeParams.locationId, null, true).then(data => {
				_privateMembers['locationObjectsList'] = data;
				_privateMembers['equipment'] = $filter('filterNested')(
					_privateMembers.locationObjectsList.tisObjectList,
					{
						tisObjectId: parseInt(_privateMembers.equipmentId),
					},
					true
				).pop();
			});
		}

		_setProperDates() {
			const {$routeParams, locationDetailsService, CHART_DATE_FORMAT, chillerPerformanceReportConfig: cprConfig} = services.get(this);
			const timezone = locationDetailsService.getLocationTimezone();
			const now = moment().tz(moment.tz.guess());
			const month = moment()
				.tz(timezone)
				.startOf('month');
			let fromDate = moment(month);
			let toDate = moment()
				.tz(timezone)
				.add(-1, 'days')
				.endOf('day');

			if ($routeParams.startDate) {
				const lastAllowedDay = moment()
					.tz(timezone)
					.add(-1, 'day')
					.endOf('day');

				fromDate = moment.tz($routeParams.startDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, timezone).startOf('month');
				toDate = moment.tz($routeParams.startDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, timezone).endOf('month');

				if (toDate.isAfter(lastAllowedDay, 'day')) {
					toDate = moment(lastAllowedDay);
				}
			}

			if (toDate.isBefore(fromDate)) {
				toDate = moment().tz(timezone);
			}

			this.from = fromDate;
			this.to = toDate;
			this.startTimePeriod = this.from.format(CHILLER_DATA_DATE_FORMAT);
			this.endTimePeriod = this.to.format(CHILLER_DATA_DATE_FORMAT);
			this.reportCreatedDate = now.format(_get(dateFormatByLang, `${cprConfig.lang}.reportCreatedDateFormat`));
		}

		_getAllowedReportSections() {
			const {$routeParams, chillerPerformanceReportConfig, CPR_REPORT_SECTIONS} = services.get(this);
			let allowedReportSections = [];

			try {
				const reportSections = ($routeParams.reportSections || '').split('key:').slice(1);
				allowedReportSections = reportSections;
			} catch (error) {
				// Include all enabled CPR sections

				allowedReportSections = Object.keys(CPR_REPORT_SECTIONS).filter(section => {
					const sectionConfig = chillerPerformanceReportConfig[section] || {};
					return sectionConfig.isEnabled;
				});
			}
			return allowedReportSections.sort((a, b) => {
				const {orderInPopup: aOrderInPopup} = chillerPerformanceReportConfig[a];
				const {orderInPopup: bOrderInPopup} = chillerPerformanceReportConfig[b];
				if (!aOrderInPopup) {
					return 1;
				}
				if (!bOrderInPopup) {
					return -1;
				}
				if (aOrderInPopup < bOrderInPopup) {
					return -1;
				}
				if (aOrderInPopup > bOrderInPopup) {
					return 1;
				}
				return 0;
			});
		}

		_getOtherInfoFromUrl() {
			const {$routeParams, $log, helpers, chillerPerformanceReportConfig: cprConfig} = services.get(this);
			const _privateMembers = privateMembers.get(this);
			let otherInfo;

			try {
				otherInfo = JSON.parse(helpers.decodeString($routeParams.otherInfo));
			} catch (error) {
				$log.error(error);

				otherInfo = {};
			}

			_privateMembers.allowedReportSections.forEach(section => {
				const processInfoFromUrl = cprConfig[section].others && cprConfig[section].others.processInfoFromUrl;

				processInfoFromUrl && processInfoFromUrl(otherInfo);
			});

			return otherInfo;
		}

		_getProperDataAndFillReportSections() {
			const {$q, chillerPerformanceReportService} = services.get(this);
			const {otherInfoFromUrl, allowedReportSections, equipmentId, equipment} = privateMembers.get(this);
			const promises = [];

			const getAndAssignProperData = (reportSectionName, data) => {
				return chillerPerformanceReportService
					.getActionByCprSectionName(reportSectionName, data)({dataCollectionStartTimestamp: this.dataCollectionStartTimestamp})
					.then(data => {
						if (this.reportSectionsData[reportSectionName]) {
							Object.assign(this.reportSectionsData[reportSectionName], data);
						} else {
							this.reportSectionsData[reportSectionName] = data;
						}
					});
			};

			allowedReportSections.forEach(reportSectionName => {
				promises.push(
					getAndAssignProperData(reportSectionName, {
						tisObjectId: equipmentId,
						equipment: equipment,
						fromDate: this.from,
						toDate: this.to,
					})
						.then(() => {
							if (otherInfoFromUrl && otherInfoFromUrl[reportSectionName]) {
								if (!this.reportSectionsData[reportSectionName]) {
									this.reportSectionsData[reportSectionName] = {};
								}
								this.reportSectionsData[reportSectionName].otherInfoFromUrl = otherInfoFromUrl[reportSectionName];
							}
						})
						.catch(error => {
							this.reportSectionsData[reportSectionName] = {
								isFailed: true,
							};
						})
				);
			});

			return $q.all(promises);
		}
		_getStaticInfo() {
			const {chillerPerformanceReportConfig: cprConfig} = services.get(this);
			const _privateMembers = privateMembers.get(this);

			_privateMembers.allowedReportSections.forEach(section => {
				const staticData = cprConfig[section].others && cprConfig[section].others.staticData;
				if (staticData) {
					if (this.reportSectionsData[section]) {
						Object.assign(this.reportSectionsData[section], staticData);
					} else {
						this.reportSectionsData[section] = staticData;
					}
				}
			});
		}

		async _getChillerPerformanceReportData() {
			const {chillerPerformanceReportApiService, locationDetailsService, CPR_REPORT_SECTIONS, CPR_DETAILS_ACTION_TYPE} = services.get(this);
			const _privateMembers = privateMembers.get(this);
			const timezone = locationDetailsService.getLocationTimezone();

			this.chillerPerformanceReportData = await chillerPerformanceReportApiService.getChillerPerformanceReportData({
				tisObjectId: _privateMembers.equipmentId,
			});

			let improvements = [];
			let milestones = [];
			if (this.chillerPerformanceReportData && this.chillerPerformanceReportData.details && this.chillerPerformanceReportData.details.length) {
				improvements = this.chillerPerformanceReportData.details.filter(item => item.actionType === CPR_DETAILS_ACTION_TYPE.IMPROVEMENT);
				milestones = this.chillerPerformanceReportData.details.filter(item => item.actionType === CPR_DETAILS_ACTION_TYPE.MILESTONE).map(milestone => {
					milestone.date = moment(milestone.actionDate);
					milestone.formattedDate = milestone.date.format(CPR_DATE_FORMATS.MEDIUM_DATE_FORMAT);

					const daysDiff = moment
						.duration(
							milestone.date.diff(
								moment()
									.tz(timezone)
									.startOf('day')
							)
						)
						.asDays();

					milestone.daysDiff = {
						value: daysDiff >= 0 ? `${Math.round(daysDiff)}` : `(${Math.round(Math.abs(daysDiff))})`,
						style: {
							color: daysDiff < 0 ? '#8F1733' : void 0,
						},
					};
					return milestone;
				});
			}
			this.reportSectionsData[CPR_REPORT_SECTIONS.PERFORMANCE_IMPROVEMENTS] = {
				improvements,
				tableHeader: {
					actionItem: {name: 'ITEM', style: {width: '420px'}},
					actionPriority: {name: 'PRIORITY', style: {width: '92px'}},
					actionImprovement: {name: 'IMPROVEMENT_IN', style: {width: 'auto'}},
				},
			};
			this.reportSectionsData[CPR_REPORT_SECTIONS.MILESTONES] = {
				milestones,
				tableHeader: {
					actionItem: {name: 'MILESTONE', style: {width: '88%'}},
					formattedDate: {name: 'DATE', style: {width: '12%'}},
				},
			};
		}

		// TODO: Move this method to chillerPerformanceReportApiService.
		_getOtherChillerInfo() {
			const _privateMembers = privateMembers.get(this);
			const properties = {
				ChillerCapacityNominal: {
					name: 'ChillerCapacityNominal',
					sign: '~',
					sourceUnitOfMeasure: {
						name: 'tonRefrigeration',
						symbol: 'Tons',
					},
				},
				SalesOrderNumber: {
					name: 'SalesOrderNumber',
					sign: '~',
					sourceUnitOfMeasure: 'N/A',
				},
				UnitSerialNumber: {
					name: 'UnitSerialNumber',
					sign: '~',
					sourceUnitOfMeasure: 'N/A',
				},
				UnitModelNumber: {
					name: 'UnitModelNumber',
					sign: '~',
					sourceUnitOfMeasure: 'N/A',
				},
				ChillerEnteredService: {
					name: 'ChillerEnteredService',
					sign: '~',
					sourceUnitOfMeasure: 'N/A',
				},
				ChillerLife: {
					name: 'ChillerLife',
					sign: '~',
					sourceUnitOfMeasure: 'N/A',
				},
			};

			const {tisObjectService, locationDetailsService} = services.get(this);

			const params = {
				ids: [parseInt(privateMembers.get(this).equipmentId)],
				hpath: Object.values(properties).map(property => `${property.sign}${property.name}|LATEST()`),
				enumerations: '',
				from: moment(this.from),
				to: moment(), // to - as the current date since the latest value is needed
				timeZone: locationDetailsService.getLocationTimezone(),
			};

			return tisObjectService
				.getCprReportHeader(params)
				.then(cprRes => {
					const propertiesNames = Object.keys(properties);
					const items = Object.keys(cprRes);
					items &&
						propertiesNames.forEach(name => {
							_privateMembers[name] = {
								name,
								value: items.includes(name) ? cprRes[name] : '',
								sourceUnitOfMeasure: properties[name].sourceUnitOfMeasure,
							};
						});

					this._fillHeaderData();
				})
				.catch(() => {
					this.reportSectionsData[this.CPR_REPORT_SECTIONS.CHILLER_PROFILE] = {
						isFailed: true,
					};
					this.reportSectionsData[this.CPR_REPORT_SECTIONS.COMPRESSOR_RUN_HOURS_AND_STARTS] = {
						isFailed: true,
					};
				});
		}

		_fillHeaderData() {
			const {locationDetailsService, chillerPerformanceReportConfig: cprConfig} = services.get(this);
			const {
				equipment,
				locationDetails,
				UnitSerialNumber,
				UnitModelNumber,
				ChillerCapacityNominal,
				ChillerEnteredService,
				ChillerLife,
				SalesOrderNumber,
				otherInfoFromUrl,
			} = privateMembers.get(this);
			const [location] = locationDetails.locationSummaryList;
			const address = location.address;
			const getChillerCapacityString = value => {
				const converter = _get(conversionTable, `${cprConfig.systemOfMeasurement}.${ChillerCapacityNominal.sourceUnitOfMeasure.name}`);
				if (converter) {
					return `${converter.converterFunction(value).toFixed()} ${converter.convertedUom.symbol}`;
				}

				return `${value} ${ChillerCapacityNominal.sourceUnitOfMeasure.symbol}`;
			};

			this.facilityName = location.locationName;

			if (address && address.line1) {
				this.addressLine =
					address.line1 + (address.city ? ', ' + address.city : '') + ', ' + (address.regionCode || '') + ' ' + (address.postalCode || '');
			}

			this.chillerName = equipment.tisObjectName;
			this.equipmentImage = equipment.tisObjectImageURL;
			this.chillerSerialNumber = UnitSerialNumber.value;
			this.chillerModelNumber = UnitModelNumber.value;
			this.salesOrderNumber = SalesOrderNumber.value;
			this.chillerCapacity = ChillerCapacityNominal.value && getChillerCapacityString(ChillerCapacityNominal.value);
			let chillerEnteredServiceUnFormatted = ChillerEnteredService.value && moment(ChillerEnteredService.value);

			this.userEditedChillerLife = otherInfoFromUrl['CHILLER_PROFILE'] && otherInfoFromUrl['CHILLER_PROFILE'].ChillerLife;
			this.chillerLife = this.userEditedChillerLife || ChillerLife.value;

			this.chillerEnteredService =
				chillerEnteredServiceUnFormatted &&
				moment(chillerEnteredServiceUnFormatted).format(_get(dateFormatByLang, `${cprConfig.lang}.chillerEnteredServiceFormat`));

			if (this.chillerEnteredService) {
				this.chillerAge = -chillerEnteredServiceUnFormatted.diff(moment(), 'years', true);
			}
			if (equipment.tisObjectType.tisObjectTypeGroupName !== CHILLER_GROUP_NAME) {
				this.notAChiller = true;
				throw new Error('Not a Chiller');
			}
		}

		_divideReportSectionsPerPages() {
			const {chillerPerformanceReportConfig: cprConfig, CPR_REPORT_SECTIONS} = services.get(this);
			const {allowedReportSections} = privateMembers.get(this);
			// MAX_MAIN_CONTENT_HEIGHT calculated manually (approximately): [PAGE_HEIGHT - HEADER_HEIGHT - FOOTER_HEIGHT]
			const MAX_MAIN_CONTENT_HEIGHT = 860; // px

			const generateNewPageObject = () => ({height: 0, sections: []});
			const generateNewChartSectionObject = () => ({charts: [], isAddedToPage: false});

			const updatePage = (page, {height, section, isCreateAndReturnNewPageAfter = false} = {}) => {
				let push = true;
				if (cprConfig[section] && cprConfig[section].coSection) {
					page.sections.forEach(currentSection => {
						if (currentSection.includes(cprConfig[section].coSection)) {
							push = false;
						}
					});
				}
				if (push) {
					page.height += height;
					page.sections.push(section);
				}

				return isCreateAndReturnNewPageAfter && createNewPage();
			};

			const createNewPage = () => {
				let page = generateNewPageObject();
				pages.push(page);

				return page;
			};

			const addNewPageIfNeededAndReturn = (page, {height} = {}) => {
				if (page.height + height > MAX_MAIN_CONTENT_HEIGHT) {
					page = createNewPage();
				}

				return page;
			};

			const addFailedSectionsPage = () => {
				this.failedSections = Object.keys(this.reportSectionsData)
					.filter(key => this.reportSectionsData[key].isFailed)
					.map(key => key.replace(/_\d*$/g, ''));

				if (this.failedSections.length > 0) {
					let page = createNewPage();
					page.sections = [CPR_REPORT_SECTIONS.FAILED_SECTIONS];
				}
			};

			const pages = [generateNewPageObject()];
			const chartSections = [generateNewChartSectionObject()];

			allowedReportSections.forEach((section, index) => {
				let page = pages[pages.length - 1];

				cprConfig[section].divideSectionPerPages({
					CPR_REPORT_SECTIONS,
					MAX_MAIN_CONTENT_HEIGHT,
					index,
					section,
					allowedReportSections,
					chartSections,
					page,
					cprConfig,
					reportSectionsData: this.reportSectionsData,
					generateNewChartSectionObject,
					addNewPageIfNeededAndReturn,
					createNewPage,
					updatePage,
				});
			});

			addFailedSectionsPage();

			this.pages = pages;
		}
	}

	angular.module('TISCC').controller('ChillerPerformanceReportController', ChillerPerformanceReportController);
})();
