import {CPR_DATE_FORMATS} from './chiller-performance-report-config';
import {REPORT_LANGUAGE_LIST, DEFAULT_REPORT_LANGUAGE, SYSTEM_OF_MEASURE_LIST, DEFAULT_SYSTEM_OF_MEASURE} from './chiller-performance-report-service';
import {USER_EVENTS} from '../../../common/usage-tracking/categories';
import {
	getTimePeriodNameByRangeMode,
	getCprSectionNameBySectionKey,
	getReportNameByKey,
	getCprSettingNameByKey,
	getReportGenerationStatus,
} from '../../../common/usage-tracking/categories/report-generation/utils.js';

const {REPORT_GENERATION: {properties: REPORT_GENERATION_PROPERTIES}} = USER_EVENTS;

angular.module('TISCC').controller('ChillerPerformanceReportCtrl', ChillerPerformanceReportCtrl);

function ChillerPerformanceReportCtrl(
	$scope,
	$timeout,
	$controller,
	$location,
	$filter,
	$translate,
	$routeParams,
	$log,
	$q,
	authorization,
	locationDetailsService,
	locationEquipmentService,
	helpers,
	exportService,
	exportProgressService,
	CHART_DATE_FORMAT,
	REPORT_TYPES,
	CPR_REPORT_SECTIONS,
	CPR_DETAILS_ACTION_TYPE,
	chillerPerformanceReportConfig,
	modalHelperService,
	chillerPerformanceModalsService,
	chillerPerformanceReportService,
	googleAnalyticsService,
	MetricsFactory,
	urlService,
	GA_ACTION_NAMES,
	configService
) {
	angular.extend(this, $controller('AbstractReportCtrl', {$scope, $log}));
	const FILE_FORMAT = 'pdf';
	let reportGenerationStartTime = null;

	function showErrorMessage(message) {
		modalHelperService.open({
			templateUrl: 'common/messages/error-message.html',
			controller: 'ErrorMessageCtrl',
			backdrop: 'static',
			resolve: {
				details: () => message,
			},
		});
	}

	// Store editable data by tisObjectId
	const editableDataStorage = {};
	let reportGenerationStarted = null;
	let reportGenerationTime = null;
	const externalLinks = configService.getExternalLinks();

	$scope.rangeMode = '1 month';
	$scope.viewModel = {
		selectedSystemName: 'Chiller',
		checkAllRight: true,
		isLoadingStarted: false,
	};
	if ($routeParams && $routeParams.startDate) {
		$scope.range.from = moment
			.tz($routeParams.startDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, locationDetailsService.getLocationTimezone())
			.startOf('month');
	}
	$scope.rightListLoadingPromise = null;
	$scope.CPR_REPORT_SECTIONS = CPR_REPORT_SECTIONS;
	$scope.CPR_DETAILS_ACTION_TYPE = CPR_DETAILS_ACTION_TYPE;

	$scope.updateLeftCheckboxes = updateLeftCheckboxes;
	$scope.isEquipmentSelected = isEquipmentSelected;
	$scope.updateRightCheckboxes = updateRightCheckboxes;
	$scope.cancelLoading = cancelLoading;
	$scope.showInfoPopup = showInfoPopup;
	$scope.formatDate = formatDate;
	$scope.countItems = countItems;
	$scope.switchLanguage = switchLanguage;
	$scope.switchSystemOfMeasure = switchSystemOfMeasure;
	$scope.redirectToTC = redirectToTC;
	$scope.showPopup = showPopup;
	$scope.activeLanguage = DEFAULT_REPORT_LANGUAGE;
	$scope.reportLanguageList = REPORT_LANGUAGE_LIST;
	$scope.activeSystemOfMesure = DEFAULT_SYSTEM_OF_MEASURE;
	$scope.reportSystemOfMesureList = SYSTEM_OF_MEASURE_LIST;

	initialize();

	$scope.$on('generateReport', generateReportHandler);

	$scope.$watch('searchProp.searchText', function() {
		$timeout(updateRightSelectAllBtn);
	});

	$scope.$watch('data', function(data) {
		if (data && data.locationData && data.locationData.locationId) {
			googleAnalyticsService.setLocationID(data.locationData.locationId);
		}
	});

	$scope.$watchGroup(
		[
			() => $scope.popupLeftListData && $scope.popupLeftListData.some(item => item.checked),
			() => $scope.popupRightListData && $scope.popupRightListData.filter(item => item.checked).length,
			() => $scope.isGeneratingReport,
		],
		() => {
			const leftListData = $scope.popupLeftListData || [];
			const isLeftCheckedItemsPresent = leftListData.some(item => {
				googleAnalyticsService.setEquipmentId(item.tisObjectId);
				return item.checked;
			});
			const rightListData = $scope.popupRightListData || [];
			const isRightCheckedItemsPresent = rightListData.some(item => item.checked);

			$scope.setDisableGenerateButton($scope.isGeneratingReport || !(isLeftCheckedItemsPresent && isRightCheckedItemsPresent));
		}
	);

	function switchLanguage(language) {
		$scope.activeLanguage = language;
		if (language.system) {
			$scope.activeSystemOfMesure = language.system;
		}
	}

	function switchSystemOfMeasure(systemOfMesure) {
		$scope.activeSystemOfMesure = systemOfMesure;
	}

	function initialize() {
		updateExportProgressMessages();

		$q
			.all([
				locationEquipmentService.getLocationObjectsList($scope.locationId, $scope.viewModel.selectedSystemName, false),
				locationEquipmentService.getLocationObjectsList($scope.locationId, null, true),
			])
			.then(data => {
				let [{tisObjectList: flatTisObjectList}, {tisObjectList}] = data;
				const {tisObjectId} = $scope.defaultEquipment || {};

				$scope.popupLeftListData = flatTisObjectList.map(tisObject => {
					return {
						value: tisObject.tisObjectName,
						objectId: tisObject.objectId,
						tisObjectId: tisObject.tisObjectId,
						tisObjectType: tisObject.tisObjectType,
						subTypeFilterKey: tisObject.subTypeFilterKey,
						id: tisObject.tisObjectType.tisObjectTypeGroupNumber,
						checked: tisObjectId === tisObject.tisObjectId,
						children: tisObject.children,
						isStandalone: locationEquipmentService.searchParentTisObject(tisObject, tisObjectList, null) === null,
					};
				});
			})
			.then(fetchPopupRightListData)
			.catch(() => {
				$scope.popupLeftListData = [];
			});
	}

	function fetchPopupRightListData() {
		if (!isEquipmentSelected()) return;

		const tisObject = getSelectedEquipment();
		$scope.popupRightListData = [];
		const REPORT_SECTIONS = Object.keys(getCPRSectionsByChillerType(tisObject)).map((section, index) => {
			const sectionConfig = chillerPerformanceReportConfig[section] || {};
			const isEditable = sectionConfig.isEditable || !!sectionConfig.popupHandler;
			return {
				isEditable,
				editParameters: sectionConfig.editParameters,
				value: section,
				checked: sectionConfig.isChecked,
				disabled: false,
				dialogWidth: sectionConfig.dialogWidth,
				imageSource: sectionConfig.imageSource,
				imageWidth: sectionConfig.imageWidth,
				imageHeight: sectionConfig.imageHeight,
				infoText: sectionConfig.infoText,
				orderInPopup: sectionConfig.orderInPopup,
			};
		});
		!editableDataStorage[tisObject.tisObjectId] && (editableDataStorage[tisObject.tisObjectId] = {});
		const otherInfo = editableDataStorage[tisObject.tisObjectId];

		const hpath = REPORT_SECTIONS.filter(item => item.isEditable && item.editParameters)
			.reduce((accum, section) => [...accum, ...section.editParameters], [])
			.filter((value, index, array) => array.indexOf(value) === index)
			.map(item => `~${item}|LATEST()`)
			.join(',');

		$scope.rightListLoadingPromise = chillerPerformanceReportService
			// to - as the current date since the latest value is needed
			.getEditableSectionsData(tisObject.tisObjectId, {...$scope.range, to: moment()}, hpath, otherInfo)
			.then(([parameters, cprData]) => {
				otherInfo[CPR_REPORT_SECTIONS.CHILLER_PROFILE] = parameters;
				otherInfo[CPR_REPORT_SECTIONS.CHILLER_LIFE] = parameters;
				otherInfo.cprSavedData = cprData;

				$scope.editableData = otherInfo;
				$scope.popupRightListData = REPORT_SECTIONS.filter(item => item.orderInPopup !== undefined);
				updateReportSections();
			})
			.catch(error => {
				$log.error(error);
			});
	}

	function updateReportSections() {
		const {cprSavedData: cprData, [CPR_REPORT_SECTIONS.CHILLER_LIFE]: chillerLifeData} = $scope.editableData;
		$scope.popupRightListData.filter(item => item.isEditable).forEach(section => {
			switch (section.value) {
				case CPR_REPORT_SECTIONS.CHILLER_LIFE:
					section.checked = !!chillerLifeData.ChillerEnteredService;
					section.disabled = !section.checked;
					break;
				case CPR_REPORT_SECTIONS.MILESTONES:
					section.checked = !!countItems(CPR_DETAILS_ACTION_TYPE.MILESTONE);
					section.disabled = !section.checked;
					break;
				case CPR_REPORT_SECTIONS.PERFORMANCE_IMPROVEMENTS:
					section.checked = !!countItems(CPR_DETAILS_ACTION_TYPE.IMPROVEMENT);
					section.disabled = !section.checked;
					break;
			}
		});
	}

	function showInfoPopup(cprSection, {infoText, title, imageSource, dialogWidth} = {}) {
		hideConfirmationMessage();

		const {
			value: cprSectionName,
			imageSource: cprSectionImageSource,
			dialogWidth: cprSectionDialogWidth,
			imageWidth,
			imageHeight,
			infoText: cprSectionInfoText,
		} = cprSection;

		modalHelperService.open({
			templateUrl: 'components/reports/chiller-performance/modals/chiller-performance-section-info/chiller-performance-section-info.html',
			controller: 'CPRInfoPopupMessageCtrl',
			backdrop: 'static',
			resolve: {
				title: () => $filter('translate')(infoText || `CPR_SECTIONS.${cprSectionName}`),
				imageSource: () => (typeof imageSource !== 'undefined' ? imageSource : cprSectionImageSource),
				imageStyle: () => ({width: imageWidth, height: imageHeight}),
				dialogStyle: () => ({width: dialogWidth || cprSectionDialogWidth}),
				infoText: () => $filter('translate')(title || cprSectionInfoText),
			},
			scope: this,
			windowClass: 'chiller-performance-info',
		});
	}

	function redirectToTC() {
		const {locationId} = $scope;
		const {objectId} = getSelectedEquipment();
		const url = externalLinks.equipmentSetupLink({locationId, objectId}) + '&tab=2';
		window.open(url, '_blank');
	}
	async function showPopup(cprSection) {
		const {value: cprSectionName} = cprSection;
		const {popupHandler} = chillerPerformanceReportConfig[cprSectionName];

		if (!popupHandler) return;

		hideConfirmationMessage();

		const tisObject = getSelectedEquipment();
		const otherInfo = $scope.editableData;
		const {range, maxDt} = $scope;
		let result;
		try {
			result = await chillerPerformanceModalsService[popupHandler]({
				tisObject,
				range,
				maxDt,
				otherInfo,
			});
		} catch (error) {
			$log.error(error);
			if (error !== 'cancel') {
				showErrorMessage(`${$translate(`CPR_SECTIONS.${cprSectionName}`)} data ${$translate('NOT_SAVED')}`);
			}
			return;
		}
		if (typeof result === 'object') {
			Object.assign(otherInfo, result);
		}
		updateReportSections();
		// showConfirmationMessage(`${$translate(`CPR_SECTIONS.${cprSectionName}`)} ${$translate('SAVED')}`);
	}

	function formatDate(dateString) {
		const timeZone = locationDetailsService.getLocationTimezone();
		return dateString && moment.tz(dateString, timeZone).format(CPR_DATE_FORMATS.LONG_DATE_FORMAT);
	}

	function countItems(actionType) {
		return ($scope.editableData.cprSavedData.details || []).filter(item => item.actionType === actionType).length;
	}

	function showConfirmationMessage(message) {
		$scope.$emit('showConfirmationMessage', message);
	}

	function hideConfirmationMessage() {
		$scope.$emit('hideConfirmationMessage');
	}

	function getSelectedEquipment() {
		return $scope.popupLeftListData.find(item => item.checked);
	}

	function updateLeftCheckboxes(item) {
		$scope.popupLeftListData.forEach(tisObject => {
			if (tisObject !== item) {
				tisObject.checked = false;
			}
		});
		fetchPopupRightListData();
		hideConfirmationMessage();
	}

	function isEquipmentSelected() {
		const {popupLeftListData = []} = $scope;

		return popupLeftListData.find(item => item.checked);
	}

	function updateRightSelectAllBtn() {
		if (!helpers.getPropertyByPath($scope.filteredPopupRightListData, 'length')) return;

		const currentData = $scope.filteredPopupRightListData || [];
		const checkedPropertiesCount = currentData.filter(item => !item.disabled && item.checked).length;

		if (!checkedPropertiesCount) {
			$scope.viewModel.checkAllRight = false;
		} else if (currentData.filter(item => !item.disabled).length === checkedPropertiesCount) {
			$scope.viewModel.checkAllRight = true;
		} else {
			$scope.viewModel.checkAllRight = null;
		}
	}

	function updateSectionSettingsCheckboxes(section) {
		if (section.value === CPR_REPORT_SECTIONS.PLANT_LOAD_ANALYSIS) {
			$scope.editableData[section.value].includeCoolingDegreeDays = false;
		}
	}

	function updateRightCheckboxes(item) {
		updateSectionSettingsCheckboxes(item);
		updateRightSelectAllBtn();
		hideConfirmationMessage();
	}

	function generateReportHandler() {
		/* eslint camelcase: 0*/
		MetricsFactory.mark(MetricsFactory.METRIC_TYPES.CPR_LOAD, {
			range_type: $scope.rangeMode,
			range_duration: $scope.range.to - $scope.range.from,
			report_type: 'Chiller',
			objects_count: 1,
			properties_count: $scope.filteredPopupRightListData.reduce((acc, value) => (value.checked ? acc + 1 : acc), 0) || 0,
		});

		const data = prepareData();
		const {CPR: labels} = GA_ACTION_NAMES;
		reportGenerationStarted = new Date();
		hideConfirmationMessage();

		$scope.isGeneratingReport = true;
		$scope.viewModel.isLoadingStarted = true;

		reportGenerationStartTime = performance.now();

		exportService
			.sendExportRequest(data)
			.then(({progressKey}) => {
				googleAnalyticsService.setCprLanguage($scope.activeLanguage.value);
				googleAnalyticsService.setCprUnitsOfMeasure($scope.activeSystemOfMesure.value);
				googleAnalyticsService.sendFlowEvent(labels.CPR, labels.GENERATION, {
					label: labels.GENERATION_STARTED,
					progressKey: progressKey,
				});
				$scope.popupRightListData.filter(item => item.checked).forEach(item => {
					googleAnalyticsService.events.CPR.sendIncludedSection(item.value);
				});
				exportProgressService
					.open(progressKey, {'is-visual-export': true})
					.then(
						finalizeExport.bind(null, progressKey, data.filename),
						failExportWrapper.bind(null, data.format, null, progressKey),
						showExportProgress
					)
					.then(() => {
						MetricsFactory.measure(MetricsFactory.METRIC_TYPES.CPR_LOAD);
					});
			})
			.catch(() => {
				$scope.isGeneratingReport = false;
				$scope.viewModel.isLoadingStarted = false;
				$scope.$emit('hideLoader');
				reportGenerationStarted = null;
				googleAnalyticsService.events.CPR.generationFailed();

				let timeTaken = performance.now() - reportGenerationStartTime;
				const fromDate = $scope.range.from.clone().format('MM-DD-YYYY');
				const toDate = $scope.range.to
					.clone()
					.add(-1, 'day')
					.format('MM-DD-YYYY');
				$scope.trackGenerateReportEvent({
					[REPORT_GENERATION_PROPERTIES.REPORT]: getReportNameByKey(REPORT_TYPES.REPORT_CHILLER_PERFORMANCE_ENGLISH.name),
					[REPORT_GENERATION_PROPERTIES.TIME_PERIOD]: getTimePeriodNameByRangeMode($scope.rangeMode),
					[REPORT_GENERATION_PROPERTIES.FROM_DATE]: fromDate,
					[REPORT_GENERATION_PROPERTIES.TO_DATE]: toDate,
					[REPORT_GENERATION_PROPERTIES.TOTAL_TIME]: timeTaken,
					[REPORT_GENERATION_PROPERTIES.REPORT_GENERATION_STATUS]: getReportGenerationStatus('failed'),
					[REPORT_GENERATION_PROPERTIES.LANGUAGE]: $scope.activeLanguage.name,
					[REPORT_GENERATION_PROPERTIES.UNIT_OF_MEASURE]: $scope.activeSystemOfMesure.name,
					[REPORT_GENERATION_PROPERTIES.SECTIONS_INCLUDED]: $scope.popupRightListData
						.filter(item => item.checked)
						.reduce((result, section) => {
							result.push(section.value);

							if (section.value === CPR_REPORT_SECTIONS.PLANT_LOAD_ANALYSIS) {
								$scope.editableData[CPR_REPORT_SECTIONS.PLANT_LOAD_ANALYSIS].includeCoolingDegreeDays &&
									result.push(getCprSettingNameByKey('INCLUDE_COOLING_DEGREE_DAYS'));
							}

							return result;
						}, [])
						.map(section => getCprSectionNameBySectionKey(section)),
					[REPORT_GENERATION_PROPERTIES.START_DATE]: $scope.range.from
						.clone()
						.startOf('month')
						.format('MM-DD-YYYY'),
				});
			});

		const fromDate = $scope.range.from.clone().format('MM-DD-YYYY');
		const toDate = $scope.range.to
			.clone()
			.add(-1, 'day')
			.format('MM-DD-YYYY');
		$scope.trackGenerateReportEvent({
			[REPORT_GENERATION_PROPERTIES.REPORT]: getReportNameByKey(REPORT_TYPES.REPORT_CHILLER_PERFORMANCE_ENGLISH.name),
			[REPORT_GENERATION_PROPERTIES.TIME_PERIOD]: getTimePeriodNameByRangeMode($scope.rangeMode),
			[REPORT_GENERATION_PROPERTIES.LANGUAGE]: $scope.activeLanguage.name,
			[REPORT_GENERATION_PROPERTIES.FROM_DATE]: fromDate,
			[REPORT_GENERATION_PROPERTIES.TO_DATE]: toDate,
			[REPORT_GENERATION_PROPERTIES.REPORT_GENERATION_STATUS]: getReportGenerationStatus('started'),
			[REPORT_GENERATION_PROPERTIES.UNIT_OF_MEASURE]: $scope.activeSystemOfMesure.name,
			[REPORT_GENERATION_PROPERTIES.SECTIONS_INCLUDED]: $scope.popupRightListData
				.filter(item => item.checked)
				.reduce((result, section) => {
					result.push(section.value);

					if (section.value === CPR_REPORT_SECTIONS.PLANT_LOAD_ANALYSIS) {
						$scope.editableData[CPR_REPORT_SECTIONS.PLANT_LOAD_ANALYSIS].includeCoolingDegreeDays &&
							result.push(getCprSettingNameByKey('INCLUDE_COOLING_DEGREE_DAYS'));
					}

					return result;
				}, [])
				.map(section => getCprSectionNameBySectionKey(section)),
			[REPORT_GENERATION_PROPERTIES.START_DATE]: $scope.range.from
				.clone()
				.startOf('month')
				.format('MM-DD-YYYY'),
		});
	}

	function prepareData() {
		const selectedEquipment = getSelectedEquipment();
		const selectedReportSections = $scope.popupRightListData.filter(item => item.checked);
		const startDate = $scope.range.from
			.clone()
			.startOf('month')
			.format('MM-DD-YYYY');
		const endDate = $scope.range.to
			.clone()
			.startOf('month')
			.format('MM-DD-YYYY');
		const data = {};
		googleAnalyticsService.events.CPR.setReportDate(startDate);
		data.visualExport = true;
		const reportSections = selectedReportSections.reduce((av, item) => {
			if (item.value) {
				av += 'key:' + item.value;
			}
			return av;
		}, '');
		data.url = [
			urlService.updateHttpsToHttp($location.absUrl()).split('#')[0],
			`#/facility/${$scope.locationId}/equipment/${selectedEquipment.tisObjectId}/chiller-performance-report?`,
			`lang=${$scope.activeLanguage.value}`,
			`&system=${$scope.activeSystemOfMesure.value}`,
			`&startDate=${startDate}`,
			`&endDate=${endDate}`,
			`&reportSections=${reportSections}`,
			`&otherInfo=${helpers.encodeString(angular.toJson(editableDataStorage[selectedEquipment.tisObjectId] || {}))}`,
		].join('');
		$log.log('CPR url: ', data.url);
		data.filename = `${$scope.data.locationData.locationName}_${startDate}_${selectedEquipment.value}_Chiller Performance.${FILE_FORMAT}`;
		data.format = FILE_FORMAT;
		data.visualExportType = REPORT_TYPES.REPORT_CHILLER_PERFORMANCE_ENGLISH.report;

		return data;
	}

	function finalizeExport(progressKey, filename, input = {data: {}}) {
		const {CPR: labels} = GA_ACTION_NAMES;
		if (input.data.readyToDownload) {
			startDownloadProcess({progressKey, filename});
			googleAnalyticsService.sendFlowEvent(labels.CPR, labels.GENERATION, {
				label: labels.GENERATION_DONE,
				progressKey: progressKey,
			});
			googleAnalyticsService.timings.CPR.sendReportGenerationTime(new Date() - reportGenerationStarted);

			let timeTaken = performance.now() - reportGenerationStartTime;

			const fromDate = $scope.range.from.clone().format('MM-DD-YYYY');
			const toDate = $scope.range.to
				.clone()
				.add(-1, 'day')
				.format('MM-DD-YYYY');
			$scope.trackGenerateReportEvent({
				[REPORT_GENERATION_PROPERTIES.REPORT]: getReportNameByKey(REPORT_TYPES.REPORT_CHILLER_PERFORMANCE_ENGLISH.name),
				[REPORT_GENERATION_PROPERTIES.TIME_PERIOD]: getTimePeriodNameByRangeMode($scope.rangeMode),
				[REPORT_GENERATION_PROPERTIES.FROM_DATE]: fromDate,
				[REPORT_GENERATION_PROPERTIES.TO_DATE]: toDate,
				[REPORT_GENERATION_PROPERTIES.TOTAL_TIME]: timeTaken,
				[REPORT_GENERATION_PROPERTIES.REPORT_GENERATION_STATUS]: getReportGenerationStatus('success'),
				[REPORT_GENERATION_PROPERTIES.LANGUAGE]: $scope.activeLanguage.name,
				[REPORT_GENERATION_PROPERTIES.UNIT_OF_MEASURE]: $scope.activeSystemOfMesure.name,
				[REPORT_GENERATION_PROPERTIES.SECTIONS_INCLUDED]: $scope.popupRightListData
					.filter(item => item.checked)
					.reduce((result, section) => {
						result.push(section.value);
						if (section.value === CPR_REPORT_SECTIONS.PLANT_LOAD_ANALYSIS) {
							$scope.editableData[CPR_REPORT_SECTIONS.PLANT_LOAD_ANALYSIS].includeCoolingDegreeDays &&
								result.push(getCprSettingNameByKey('INCLUDE_COOLING_DEGREE_DAYS'));
						}

						return result;
					}, [])
					.map(section => getCprSectionNameBySectionKey(section)),
				[REPORT_GENERATION_PROPERTIES.START_DATE]: $scope.range.from
					.clone()
					.startOf('month')
					.format('MM-DD-YYYY'),
			});
		}
	}

	function failExportWrapper(format, error, progressKey) {
		const {CPR: labels} = GA_ACTION_NAMES;
		startDownloadProcess({format, isDownloadFailureFile: true});
		reportGenerationStarted = null;
		googleAnalyticsService.sendFlowEvent(labels.CPR, labels.GENERATION, {
			label: labels.GENERATION_FAILED,
			progressKey: progressKey,
		});
	}

	function showExportProgress(input = {}) {
		if (input.message) {
			$scope.exportProgressMessages.currentMessage = input.message;
		}
	}

	function startDownloadProcess({progressKey, filename, format, isDownloadFailureFile = false}) {
		const exportData = {
			progressKey,
			filename,
			format,
			isDownloadFailureFile,
			downloadVisualData: !isDownloadFailureFile,
		};

		sendRequestViaForm(exportData);

		$scope.isGeneratingReport = false;
		$scope.viewModel.isLoadingStarted = false;
		$scope.$emit('hideLoader');
		$scope.$emit('generateComplete');

		updateExportProgressMessages();
	}

	function sendRequestViaForm(data) {
		angular.element(document.getElementById('export-data')).val(JSON.stringify(data));
		document.getElementById('export-form').submit();
	}

	function updateExportProgressMessages() {
		$scope.exportProgressMessages = {
			currentMessage: $filter('translate')('LOADING_REPORT'),
		};
	}

	function cancelLoading() {
		if ($scope.isGeneratingReport) {
			exportProgressService.cancel({'is-visual-export': true});
			$scope.viewModel.isLoadingStarted = false;
			$scope.isGeneratingReport = false;
			$scope.$emit('hideLoader');
		}
	}

	function getCPRSectionsByChillerType(tisObject) {
		let sections = {...CPR_REPORT_SECTIONS};

		// if it's Standalone Chiller remove Plant and Load Analysis section
		if (tisObject.isStandalone) {
			delete sections.PLANT_LOAD_ANALYSIS;
		}

		delete sections.MILESTONES;
		delete sections.PERFORMANCE_IMPROVEMENTS;
		delete sections.DIAGNOSTIC_CONDITIONS;

		return {...sections};
	}
}
