const services = new WeakMap();

class SelectionDropdownController {
	constructor(helpers) {
		services.set(this, {helpers});
		this.isRenderDropDown = false;
	}

	$onChanges(changes) {
		if (changes.items || changes.valueField) {
			this.prepareList();
		}
	}

	prepareList() {
		const {helpers} = services.get(this);
		this.list = this.valueField ? this.items.map(item => helpers.getPropertyByPath(item, this.valueField)) : this.items;
	}

	onKeyDown(event) {
		const key = event.key || event.keyCode;
		const parentLi = event.target.parentElement;

		switch (key) {
			case 'ArrowUp':
			case 38:
				parentLi.previousElementSibling && parentLi.previousElementSibling.firstElementChild.focus();
				event.preventDefault();
				event.stopPropagation();
				break;
			case 'ArrowDown':
			case 40:
				parentLi.nextElementSibling && parentLi.nextElementSibling.firstElementChild.focus();
				event.preventDefault();
				event.stopPropagation();
				break;
		}
	}

	onItemSelected(selectedValue) {
		const {helpers} = services.get(this);
		const selectedItem = this.valueField ? this.items.find(item => helpers.getPropertyByPath(item, this.valueField) === selectedValue) : selectedValue;

		this.onSelect({
			item: selectedItem,
			index: this.items.indexOf(selectedItem),
		});
	}
}

angular.module('TISCC').component('selectionDropdown', {
	templateUrl: 'components/selection-dropdown/selection-dropdown.html',
	controller: SelectionDropdownController,
	bindings: {
		selectedItemValue: '<',
		items: '<',
		valueField: '<',
		onSelect: '&',
	},
});
