import {USER_EVENTS} from '../../../common/usage-tracking/categories';
import {
	getTimePeriodNameByRangeMode,
	getReportNameByKey,
	getReportGenerationStatus,
} from '../../../common/usage-tracking/categories/report-generation/utils.js';

const {
	REPORT_GENERATION: {categoryName: REPORT_GENERATION_CATEGORY_NAME, events: REPORT_GENERATION_EVENTS, properties: REPORT_GENERATION_PROPERTIES},
} = USER_EVENTS;

angular.module('TISCC').controller('AlarmsAndEventsReportCtrl', AlarmsAndEventsReportCtrl);
import {rangeModeListener, rangeDateListener} from '../../../common/event-listener/date-range-listener';

function AlarmsAndEventsReportCtrl(
	$http,
	$scope,
	$filter,
	$timeout,
	$controller,
	configService,
	alarmAndEventsService,
	locationDetailsService,
	exportProgressServiceServerless,
	REPORT_TYPES,
	usageTrackingService
) {
	angular.extend(this, $controller('AbstractReportCtrl', {$scope: $scope}));

	const defaultExportProgressMessages = {
		currentMessage: $filter('translate')('LOADING_REPORT'),
		requestsProcessed: 0,
		requestsTotal: 0,
	};
	let reportGenerationStartTime = null;
	$scope.equipmentTypesExc = [];
	$scope.list = [];
	$scope.filteredList = [];
	$scope.isGeneratingReport = false;
	$scope.selectDeselectAll = selectDeselectAll;
	$scope.updateCheckboxes = updateCheckboxes;
	$scope.cancelLoading = cancelLoading;
	$scope.triggerEmail = triggerEmail;
	$scope.trackGenerateReportEvent = trackGenerateReportEvent;
	$scope.emailRequested = false;
	$scope.resetProgressRequest = resetProgressRequest;
	$scope.reportCompletion = reportCompletion;
	$scope.selectedFacilities = [$scope.data.locationData];
	$scope.showEmailTrigger = false;
	$scope.FORMAT_TYPES = {
		STANDARD: 'standard',
		STANDARD_CSV: 'standardCsv',
	};
	$scope.viewModel = {
		isLoadingStarted: false,
		format: $scope.FORMAT_TYPES.STANDARD_CSV,
	};
	$scope.exportProgressMessages = {...defaultExportProgressMessages};

	$scope.onFacilitiesDialogOpen = () => {
		usageTrackingService.trackEvent(REPORT_GENERATION_CATEGORY_NAME, REPORT_GENERATION_EVENTS.EDIT_FACILITY_FOR_REPORT);
	};

	updateSelectAllBtn();

	alarmAndEventsService.getLocationDeviceList($scope.locationId).success(({locationConnectivityList}) => {
		$scope.list = locationConnectivityList[0].devices.map(device => {
			const value = !device.device.deviceName ? device.device.identifier : device.device.deviceName;
			return {value, checked: false, id: device.device.duiId};
		});
	});

	function sendExportRequest(data) {
		return $http.post(`${configService.getExportAPIConfig()}/export`, data, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		});
	}

	function toFacilityTimeZone(date, tz) {
		return date && tz ? moment(date).tz(tz) : moment(date);
	}

	function prepareData() {
		const timezone = locationDetailsService.getLocationTimezone();
		const startDate = toFacilityTimeZone($scope.range.from, timezone);
		let endDate = toFacilityTimeZone($scope.rangeEnd, timezone);
		if (moment($scope.rangeEnd).isSame($scope.range.from)) {
			endDate = moment($scope.rangeEnd).add('1', 'days');
			const now = moment.utc();
			if (moment.utc(endDate).isAfter(moment.utc())) {
				endDate = now;
			}
		}
		const deviceIds = $scope.list.reduce((acc, device) => {
			acc = device.checked ? (acc ? `${acc},${device.id}` : device.id) : acc;
			return acc;
		}, '');
		return {
			locationName: $scope.data.locationData.locationName,
			locationId: $scope.data.locationData.locationId,
			format: 'csv',
			// format: /CSV/i.test($scope.viewModel.format) ? 'csv' : 'xlsx'
			reportType: 'alarm-event',
			deviceIds: deviceIds,
			startDate,
			endDate,
			timeZone: timezone,
		};
	}

	function trackGenerateReportEvent(options = {}) {
		usageTrackingService.trackEvent(REPORT_GENERATION_CATEGORY_NAME, REPORT_GENERATION_EVENTS.GENERATE_REPORT, {
			[REPORT_GENERATION_PROPERTIES.BUILDING_ID]: $scope.locationId,
			...options,
		});
	}

	function generateReportHandler() {
		const checkedServiceAdvisoryTypes = $scope.list.filter(({checked}) => checked);
		$scope.isGeneratingReport = true;
		const request = prepareData();
		$scope.$emit('resetDownloadLink');
		sendExportRequest(request)
			.then(({data}) => {
				const {progressKey} = data;
				$scope.progressKey = progressKey;
				$scope.viewModel.isLoadingStarted = true;
				exportProgressServiceServerless.open(progressKey).then(finalizeExport, failExportWrapper, showExportProgress);
			})
			.catch(() => {
				$scope.resetProgressRequest();
				$scope.$emit('hideLoader');
			});
		reportGenerationStartTime = performance.now();
		const fromDate = $scope.range.from.clone().format('MM-DD-YYYY');
		const toDate = $scope.range.to
			.clone()
			.add(-1, 'day')
			.format('MM-DD-YYYY');
		$scope.trackGenerateReportEvent({
			[REPORT_GENERATION_PROPERTIES.BUILDING_ID]:
				$scope.selectedFacilities.length > 1 ? $scope.selectedFacilities.map(({locationId}) => locationId) : $scope.selectedFacilities[0].locationId,
			[REPORT_GENERATION_PROPERTIES.BUILDING_SALES_OFFICE]:
				$scope.selectedFacilities.length > 1
					? $scope.selectedFacilities.map(({salesOffice}) => salesOffice.officeCode)
					: $scope.selectedFacilities[0].salesOffice.officeCode,
			[REPORT_GENERATION_PROPERTIES.BUILDING_OFFERINGS]:
				$scope.selectedFacilities.length > 1
					? // Get unique offerings from all facilities
					  $scope.selectedFacilities
							.map(({offeringSourceMaps}) => offeringSourceMaps.map(({shortName}) => shortName))
							.reduce((list, offerings) => [...list, ...offerings], [])
							.filter((offering, index, array) => array.indexOf(offering) === index)
					: $scope.selectedFacilities[0].offeringSourceMaps.map(({shortName}) => shortName),
			[REPORT_GENERATION_PROPERTIES.REPORT]: getReportNameByKey(REPORT_TYPES.ALARMS_AND_EVENTS.name),
			[REPORT_GENERATION_PROPERTIES.TIME_PERIOD]: getTimePeriodNameByRangeMode($scope.rangeMode),
			[REPORT_GENERATION_PROPERTIES.FROM_DATE]: fromDate,
			[REPORT_GENERATION_PROPERTIES.TO_DATE]: toDate,
			[REPORT_GENERATION_PROPERTIES.REPORT_GENERATION_STATUS]: getReportGenerationStatus('started'),
			[REPORT_GENERATION_PROPERTIES.DEVICES_INCLUDED]: checkedServiceAdvisoryTypes.map(({value}) => value).sort(),
		});
	}

	function startDownloadProcess(data) {
		let progressKey = exportProgressServiceServerless.getProgressKey();
		if (progressKey && data) {
			$scope.submitExportFormServerless(data);
		} else {
			failExportWrapper(true, false);
		}
	}

	function finalizeExport(input = {}) {
		$scope.viewModel.isLoadingStarted = false;
		$scope.isGeneratingReport = false;
		$scope.$emit('generateComplete');
		$scope.$emit('hideLoader');
		$scope.exportProgressMessages = {...defaultExportProgressMessages};
		const {progressPercentage, data} = input;
		if (progressPercentage) {
			$scope.exportProgressMessages.progressPercentage = progressPercentage;
			if (progressPercentage === 100) startDownloadProcess(data);
		}
		let timeTaken = performance.now() - reportGenerationStartTime;
		const fromDate = $scope.range.from.clone().format('MM-DD-YYYY');
		const toDate = $scope.range.to
			.clone()
			.add(-1, 'day')
			.format('MM-DD-YYYY');
		$scope.trackGenerateReportEvent({
			[REPORT_GENERATION_PROPERTIES.BUILDING_ID]:
				$scope.selectedFacilities.length > 1 ? $scope.selectedFacilities.map(({locationId}) => locationId) : $scope.selectedFacilities[0].locationId,
			[REPORT_GENERATION_PROPERTIES.BUILDING_SALES_OFFICE]:
				$scope.selectedFacilities.length > 1
					? $scope.selectedFacilities.map(({salesOffice}) => salesOffice.officeCode)
					: $scope.selectedFacilities[0].salesOffice.officeCode,
			[REPORT_GENERATION_PROPERTIES.BUILDING_OFFERINGS]:
				$scope.selectedFacilities.length > 1
					? // Get unique offerings from all facilities
					  $scope.selectedFacilities
							.map(({offeringSourceMaps}) => offeringSourceMaps.map(({shortName}) => shortName))
							.reduce((list, offerings) => [...list, ...offerings], [])
							.filter((offering, index, array) => array.indexOf(offering) === index)
					: $scope.selectedFacilities[0].offeringSourceMaps.map(({shortName}) => shortName),
			[REPORT_GENERATION_PROPERTIES.REPORT]: getReportNameByKey(REPORT_TYPES.ALARMS_AND_EVENTS.name),
			[REPORT_GENERATION_PROPERTIES.TIME_PERIOD]: getTimePeriodNameByRangeMode($scope.rangeMode),
			[REPORT_GENERATION_PROPERTIES.FROM_DATE]: fromDate,
			[REPORT_GENERATION_PROPERTIES.TO_DATE]: toDate,
			[REPORT_GENERATION_PROPERTIES.REPORT_GENERATION_STATUS]: getReportGenerationStatus('success'),
			[REPORT_GENERATION_PROPERTIES.TOTAL_TIME]: timeTaken,
		});
	}

	function failExportWrapper() {
		$scope.$emit('hideLoader');
		$scope.resetProgressRequest();
		$scope.viewModel.isLoadingStarted = false;

		let timeTaken = performance.now() - reportGenerationStartTime;
		const fromDate = $scope.range.from.clone().format('MM-DD-YYYY');
		const toDate = $scope.range.to
			.clone()
			.add(-1, 'day')
			.format('MM-DD-YYYY');
		$scope.trackGenerateReportEvent({
			[REPORT_GENERATION_PROPERTIES.BUILDING_ID]:
				$scope.selectedFacilities.length > 1 ? $scope.selectedFacilities.map(({locationId}) => locationId) : $scope.selectedFacilities[0].locationId,
			[REPORT_GENERATION_PROPERTIES.BUILDING_SALES_OFFICE]:
				$scope.selectedFacilities.length > 1
					? $scope.selectedFacilities.map(({salesOffice}) => salesOffice.officeCode)
					: $scope.selectedFacilities[0].salesOffice.officeCode,
			[REPORT_GENERATION_PROPERTIES.BUILDING_OFFERINGS]:
				$scope.selectedFacilities.length > 1
					? // Get unique offerings from all facilities
					  $scope.selectedFacilities
							.map(({offeringSourceMaps}) => offeringSourceMaps.map(({shortName}) => shortName))
							.reduce((list, offerings) => [...list, ...offerings], [])
							.filter((offering, index, array) => array.indexOf(offering) === index)
					: $scope.selectedFacilities[0].offeringSourceMaps.map(({shortName}) => shortName),
			[REPORT_GENERATION_PROPERTIES.REPORT]: getReportNameByKey(REPORT_TYPES.ALARMS_AND_EVENTS.name),
			[REPORT_GENERATION_PROPERTIES.TIME_PERIOD]: getTimePeriodNameByRangeMode($scope.rangeMode),
			[REPORT_GENERATION_PROPERTIES.FROM_DATE]: fromDate,
			[REPORT_GENERATION_PROPERTIES.TO_DATE]: toDate,
			[REPORT_GENERATION_PROPERTIES.REPORT_GENERATION_STATUS]: getReportGenerationStatus('failed'),
			[REPORT_GENERATION_PROPERTIES.TOTAL_TIME]: timeTaken,
		});
	}

	function updateSelectAllBtn() {
		const checkedCount = $filter('filter')($scope.list || [], {checked: true}).length;

		if (!checkedCount) {
			$scope.checkAll = false;
		} else if ($scope.list.length === checkedCount) {
			$scope.checkAll = true;
		} else {
			$scope.checkAll = null;
		}
	}

	function selectDeselectAll(status) {
		const selectionLength = $scope.filteredList.length;
		if (status && selectionLength && $scope.filteredList.every(prop => prop.checked === true)) {
			status = false;
		}
		for (let i = selectionLength; i--; ) {
			$scope.filteredList[i].checked = status;
		}
		updateCheckboxes();
	}

	function updateCheckboxes() {
		$timeout(updateSelectAllBtn, 0);
	}

	rangeModeListener($scope, $scope.setGlobalDateRange);
	rangeDateListener($scope, $scope.setGlobalDateRange);

	$scope.$on('generateReport', generateReportHandler);
	$scope.$watch(
		function() {
			return '' + $scope.checkAll + $scope.isGeneratingReport;
		},
		function() {
			$scope.setDisableGenerateButton($scope.checkAll === false || $scope.isGeneratingReport);
		}
	);

	function showExportProgress(input = {}) {
		if (input.emailRequested) return;
		const {progressPercentage, message} = input;
		if (message) {
			$scope.exportProgressMessages.message = message;
		}
		if (progressPercentage) {
			$scope.exportProgressMessages.progressPercentage = progressPercentage;
		}
	}

	function reportCompletion() {
		$scope.resetProgressRequest();
		$scope.$emit('hideLoader');
		$scope.$emit('generateComplete');
		$scope.viewModel.isLoadingStarted = false;
	}

	async function triggerEmail() {
		$scope.emailRequested = true;
		$scope.exportProgressMessages.loading = true;
		const response = await exportProgressServiceServerless.sendEmailRequest({
			progressKey: $scope.progressKey,
		});
		$scope.reportCompletion();
	}

	function resetProgressRequest() {
		$scope.isGeneratingReport = false;
		$scope.progressKey = null;
		$scope.exportProgressMessages = angular.copy(defaultExportProgressMessages);
		$scope.emailRequested = false;
	}

	function cancelLoading() {
		if ($scope.isGeneratingReport) {
			$scope.exportProgressMessages.loading = true;
			exportProgressServiceServerless
				.sendAbortRequest($scope.progressKey)
				.then(() => {
					$scope.exportProgressMessages.loading = false;
					$scope.resetProgressRequest();
					$scope.$emit('hideLoader');
					$scope.viewModel.isLoadingStarted = false;
				})
				.catch(() => {
					$scope.reportCompletion();
				});
		}
	}
}
