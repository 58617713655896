/* global EventEmitter*/

import {USER_EVENTS} from '../../common/usage-tracking/categories';

const {NAVIGATION: {events: NAVIGATION_EVENTS, categoryName: NAVIGATION_CATEGORY_NAME}} = USER_EVENTS;
const callback = function(data) {};
const errorCallback = function(data) {};

angular.module('TISCC').component('navHeader', {
	templateUrl: 'components/nav-header/nav-header.html',
	bindings: {
		navigation: '<',
		pageTitle: '<',
	},
	controller: function(
		$rootScope,
		$location,
		$route,
		modalHelperService,
		$element,
		$localStorage,
		utilityService,
		$cookies,
		authorization,
		usageTrackingService,
		notificationsService,
		userDataService,
		configService,
		AUTH_CONFIGURATION,
		ENVIRONMENT,
		handleServiceWorker
	) {
		const that = this;

		this.EVENTS = NAVIGATION_EVENTS;
		this.showLegend = false;
		this.hideSubMenu = $rootScope.hideSubMenu;
		this.changeUnits = $rootScope.changeUnits;
		this.versionData = null;
		this.environment = ENVIRONMENT.PROD;
		this.$storage = $localStorage;
		this.trackOnLinkClick = $rootScope.getTrackOnLinkClickHandler(NAVIGATION_CATEGORY_NAME);

		const redistributeSpace = function f() {
			f.prevResizeZone = f.resizeZone;
			f.resizeZone = f.resizeZone || Infinity;

			const breadcrumbsWrapper = $element[0].getElementsByClassName('breadcrumbs-wrapper')[0];
			const accountWrapperWidth = $element[0].getElementsByClassName('account-wrapper')[0].offsetWidth;
			const pageTitleWrapperWidth = $element[0].getElementsByClassName('page-title')[0].offsetWidth;
			const breadcrumbsWrapperWidth = breadcrumbsWrapper.offsetWidth;
			const pageWidth = document.body.clientWidth;
			const widthMismatch = pageWidth - accountWrapperWidth - pageTitleWrapperWidth - breadcrumbsWrapperWidth;

			if (widthMismatch < 0 && f.resizeZone === Infinity) {
				f.resizeZone = pageWidth;
			} else if (f.resizeZone < pageWidth) {
				f.resizeZone = Infinity;
				breadcrumbsWrapper.querySelectorAll('.breadcrumb a').forEach(a => (a.style = {}));
			}

			if (f.resizeZone !== Infinity && that.navigation.length) {
				const widthDiff = widthMismatch / that.navigation.length;

				breadcrumbsWrapper.querySelectorAll('.breadcrumb a').forEach(a => {
					a.style.width = a.offsetWidth + widthDiff + 'px';
				});
			} else if (f.resizeZone !== f.prevResizeZone) {
				// handles case when user loaded page in low resolution and then
				// changed screen size to the width that still need js calculations.
				redistributeSpace();
			}
		};

		this.$onDestroy = () => window.removeEventListener('resize', redistributeSpace);

		this.$onChanges = function(changes) {
			if (!changes.pageTitle.isFirstChange()) {
				window.addEventListener('resize', redistributeSpace);
				setTimeout(redistributeSpace, 100);
			}
		};

		this.logout = function() {
			handleServiceWorker.clearSWCacheOnSignOff();
			const domain = (this.environment === ENVIRONMENT.PROD ? '' : 'staging.') + 'mybuilding.trane.com';
			authorization.removeCookies();
			location.href = `https://${domain}/EBiz/Default.aspx?Action=Process%20Logoff%20Request`;
		};

		this.clearCacheAndReloadPage = function() {
			handleServiceWorker.clearSWCache();
		};

		this.toggleLegend = function(visibility) {
			this.showLegend = visibility;
		};

		this.newChart = function() {
			let dialog = modalHelperService.open({
				templateUrl: 'components/chart/chart-dialog.html',
			});

			// TODO: go to proper configurator when we have more than one
			dialog.result.then(() => $rootScope.go('/chart-configurator'));
		};

		utilityService.getAppVersionData().then(versionData => (this.versionData = versionData));
		utilityService.getEnvironmentType().then(environment => (this.environment = environment));
	},
});
