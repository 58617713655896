/* eslint quotes: [0, 'single'] */
/* eslint eqeqeq: [0, 'always'] */
/* eslint semi: [0, 'always'] */
/* eslint camelcase: 0 */

(function() {
	let treeGridHtml = `<div class="table-container-scroll">
		<table class="table table-hover table-condensed fixed-header">
			<tbody>
			<tr ng-repeat="row in tree_rows | filter:{visible:true} 
					track by row.branch.uid |filter:searchObj.searchText|tableFilter:filterParams|tableSort:sortParams"
				ng-class="'level-' + {{ row.level }} + (row.branch.selected ? ' active':'')" class="tree-grid-row">
				<td><a ng-click="user_clicks_branch(row.branch)"><i ng-class="row.tree_icon" class="indented tree-icon"></i>
					</a><span class="indented tree-label" ng-click="on_user_click(row.branch)">
					{{row.branch[expandingProperty.field] || row.branch[expandingProperty]}}</span>
				</td>
				<td ng-repeat="col in colDefinitions">
					<div ng-if="col.cellTemplate" compile="col.cellTemplate"></div>
					<div ng-if="!col.cellTemplate">{{row.branch[col.field]}}</div>
				</td>
			</tr>
			</tbody>
		</table>
		</div>
		`;

	angular.module('template/treeGrid/treeGrid.html', []).run(function($templateCache) {
		$templateCache.put('template/treeGrid/treeGrid.html', treeGridHtml);
	});

	angular
		.module('treeGrid', ['template/treeGrid/treeGrid.html'])

		.directive('compile', [
			'$compile',
			function($compile) {
				return {
					restrict: 'A',
					link: function(scope, element, attrs) {
						// Watch for changes to expression.
						scope.$watch(attrs.compile, function(new_val) {
							/*
							* Compile creates a linking function
							* that can be used with any scope.
							*/
							let link = $compile(new_val);

							/*
							* Executing the linking function
							* creates a new element.
							*/
							let new_elem = link(scope);

							// Which we can then append to our DOM element.
							element.append(new_elem);
						});
					},
				};
			},
		])

		.directive('treeGrid', [
			'$timeout',
			'treegridTemplate',
			'$rootScope',
			'helpers',
			function($timeout, treegridTemplate, $rootScope, helpers) {
				return {
					restrict: 'E',
					templateUrl: function(tElement, tAttrs) {
						return tAttrs.templateUrl || treegridTemplate.getPath();
					},
					replace: true,
					scope: {
						treeData: '<',
						expandOn: '<',
						secondHeader: '<',
						onSelect: '&',
						onClick: '&',
						initialSelection: '@',
						treeControl: '=',
						searchObj: '=search',
						filterParams: '=filters',
						sortParams: '=parameters',
						exceptionSortingValues: '=exceptions',
						skipValues: '=',
						toggleLegend: '=',
						twoWeekRange: '=range',
					},
					link: function(scope, element, attrs) {
						let expandingProperty;
						let expandingPropertySecond;
						let expand_all_parents;
						let expand_level;
						let for_all_ancestors;
						let for_each_branch;
						let get_parent;
						let n;
						let on_treeData_change;
						let select_branch;
						let selected_branch;
						let tree;

						attrs.iconExpand = attrs.iconExpand ? attrs.iconExpand : 'icon-arrow-right ';
						attrs.iconCollapse = attrs.iconCollapse ? attrs.iconCollapse : 'icon-sort-descending';
						attrs.iconLeaf = attrs.iconLeaf ? attrs.iconLeaf : 'icon-object';
						attrs.iconParent = attrs.iconParent ? attrs.iconParent : 'icon-uniE045';
						attrs.iconFolder = attrs.iconFolder ? attrs.iconFolder : 'icon-parent-2';
						attrs.expandLevel = attrs.expandLevel ? attrs.expandLevel : '3';
						expand_level = parseInt(attrs.expandLevel, 10);

						if (!scope.treeData) {
							alert('No data was defined for the tree, please define treeData!');
							return;
						}

						let getExpandingProperty = function getExpandingProperty() {
							if (attrs.secondHeader) {
								expandingPropertySecond = scope.secondHeader;
								scope.expandingPropertySecond = scope.secondHeader;
							}
							if (attrs.expandOn) {
								expandingProperty = scope.expandOn;
								scope.expandingProperty = scope.expandOn;
							} else {
								if (scope.treeData.length) {
									let _firstRow = scope.treeData[0];
									let _keys = Object.keys(_firstRow);
									for (let i = 0, len = _keys.length; i < len; i++) {
										if (typeof _firstRow[_keys[i]] === 'string') {
											expandingProperty = _keys[i];
											break;
										}
									}
									if (!expandingProperty) expandingProperty = _keys[0];
									scope.expandingProperty = expandingProperty;
								}
							}
						};

						for_each_branch = function(f) {
							let do_f;
							let root_branch;
							let _i;
							let _len;
							let _ref;
							let _results;
							do_f = function(branch, level) {
								let child;
								let _i;
								let _len;
								let _ref;
								let _results;
								f(branch, level);
								if (branch.children != null) {
									_ref = branch.children;
									_results = [];
									for (_i = 0, _len = _ref.length; _i < _len; _i++) {
										child = _ref[_i];
										_results.push(do_f(child, level + 1));
									}
									return _results;
								}
							};
							_ref = scope.treeData;
							_results = [];
							for (_i = 0, _len = _ref.length; _i < _len; _i++) {
								root_branch = _ref[_i];
								_results.push(do_f(root_branch, 1));
							}
							return _results;
						};
						selected_branch = null;
						select_branch = function(branch) {
							if (!branch) {
								if (selected_branch != null) {
									selected_branch.selected = false;
								}
								selected_branch = null;
								return;
							}
							if (branch !== selected_branch) {
								if (selected_branch != null) {
									selected_branch.selected = false;
								}
								branch.selected = true;
								selected_branch = branch;
								expand_all_parents(branch);
								if (branch.onSelect != null) {
									return $timeout(function() {
										return branch.onSelect(branch);
									});
								} else {
									if (scope.onSelect != null) {
										return $timeout(function() {
											return scope.onSelect({
												branch: branch,
											});
										});
									}
								}
							}
						};

						scope.on_user_click = function(branch) {
							if (scope.onClick) {
								scope.onClick({
									branch: branch,
								});
							}
						};

						scope.toggleDisplayChildren = function(row) {
							row.branch.expanded = !row.branch.expanded;
							$rootScope.$broadcast('updateHeader');
						};

						scope.user_clicks_branch = function($event, row) {
							const branch = row.branch;

							if (row.isParent) {
								scope.toggleDisplayChildren(row);
							} else if (branch !== selected_branch) {
								return select_branch(branch);
							}
						};

						get_parent = function(child) {
							let parent;
							parent = void 0;
							if (child.parent_uid) {
								for_each_branch(function(b) {
									if (b.uid === child.parent_uid) {
										return (parent = b);
									}
								});
							}
							return parent;
						};
						for_all_ancestors = function(child, fn) {
							let parent;
							parent = get_parent(child);
							if (parent != null) {
								fn(parent);
								return for_all_ancestors(parent, fn);
							}
						};
						expand_all_parents = function(child) {
							return for_all_ancestors(child, function(b) {
								return (b.expanded = true);
							});
						};

						scope.tree_rows = [];
						scope.colDefinitions = [];

						on_treeData_change = function(newval, oldval) {
							if (!newval.length && !oldval.length) return;
							getExpandingProperty();

							if (!scope.colDefinitions.length && scope.treeData.length) {
								const _firstRow = scope.treeData[0];
								let _col_defs = [];
								let _unwantedColumn = [
									'children',
									'locationId',
									'level',
									'expanded',
									'uid',
									'$$hashKey',
									expandingProperty,
									expandingPropertySecond,
								];
								if (scope.skipValues) {
									_unwantedColumn = _unwantedColumn.concat(scope.skipValues);
								}
								// TODO Should be changed into object.entries() when added to Babel config
								for (let idx in _firstRow) {
									if (_unwantedColumn.indexOf(idx) === -1) {
										_col_defs.push({
											field: idx,
										});
									}
								}
								scope.colDefinitions = _col_defs;
							}

							let add_branch_to_list;
							let root_branch;
							let _i;
							let _len;
							let _ref;
							let _results;

							for_each_branch(function(b) {
								if (!b.uid) {
									return (b.uid = '' + Math.random());
								}
							});
							for_each_branch(function(b) {
								let child;
								let _i;
								let _len;
								let _ref;
								let _results;
								if (angular.isArray(b.children)) {
									_ref = b.children;
									_results = [];
									for (_i = 0, _len = _ref.length; _i < _len; _i++) {
										child = _ref[_i];
										_results.push((child.parent_uid = b.uid));
									}
									return _results;
								}
							});
							scope.tree_rows = [];
							for_each_branch(function(branch) {
								if (!branch.children) {
									branch.children = [];
								}

								return branch.children;
							});

							add_branch_to_list = function(level, branch, visible) {
								let isVFD = false;

								if (branch && branch.tisObjectTypeGroupName && branch.tisObjectTypeGroupName === 'VFD') {
									isVFD = true;
								}

								const link = isVFD ? '' : '#/facility/' + branch.locationId + '/equipment/' + branch.tisObjectId + '/summary';

								let treeIcon;
								let parentIcon;

								branch.level = level;
								branch.expanded = !!branch.expanded;
								branch.children = branch.children.filter(branchIsComponentEquipment);
								branch.children = branch.children.filter(isExcludedTisObjectType);

								const isParent = branch.children && branch.children.length !== 0;
								if (isParent) {
									if (branch.expanded) {
										treeIcon = attrs.iconCollapse;
									} else {
										treeIcon = [attrs.iconExpand, 'normal'];
									}
									// change icon if parent is folder
									if (branch.type == 'folder') {
										parentIcon = level === 1 ? [attrs.iconFolder, 'icon16'] : [attrs.iconFolder, 'icon12'];
									} else {
										parentIcon = level === 1 ? [attrs.iconParent, 'icon16'] : [attrs.iconParent, 'icon12'];
									}
								} else {
									treeIcon = [attrs.iconLeaf, 'icon9'];
									parentIcon = '';
								}

								scope.tree_rows.push({
									locationId: branch.locationId,
									level: level,
									branch: branch,
									tree_icon: treeIcon,
									parent_icon: parentIcon,
									visible: visible,
									isParent: isParent,
									link: link,
									isVFD,
								});
								const lastIndex = scope.tree_rows.length - 1;
								scope.tree_rows[lastIndex][expandingProperty] = branch[expandingProperty];
								if (expandingPropertySecond) {
									scope.tree_rows[lastIndex][expandingPropertySecond] = branch[expandingPropertySecond];
								}

								// add fields to root of array for sorting/filtering purposes
								scope.colDefinitions.forEach(function(val) {
									scope.tree_rows[lastIndex][val.field] = branch[val.field];
								});
								if (branch.children !== null) {
									let ref = branch.children;
									let results = [];
									branch.children.forEach(function(child) {
										let childVisible = visible && branch.expanded;
										child.locationId = branch.locationId;
										results.push(add_branch_to_list(level + 1, child, childVisible));
									});
									return results;
								}
							};
							function branchIsComponentEquipment(branch = {}) {
								return branch.tisObjectTypeClassification !== 'Component';
							}

							function isExcludedTisObjectType(branch = {}) {
								return !['LoadValve'].includes(branch.tisObjectTypeGroupName);
							}

							_ref = scope.treeData;
							_results = [];
							for (_i = 0, _len = _ref.length; _i < _len; _i++) {
								root_branch = _ref[_i];
								let isShouldBeAdded = branchIsComponentEquipment(root_branch) && isExcludedTisObjectType(root_branch);
								if (isShouldBeAdded) {
									_results.push(add_branch_to_list(1, root_branch, isShouldBeAdded));
								}
							}
							return _results;
						};

						scope.$watch('treeData', on_treeData_change, true);
						$rootScope.$broadcast('treeGridLoaded');

						if (attrs.initialSelection != null) {
							for_each_branch(function(b) {
								if (b[expandingProperty] === attrs.initialSelection) {
									return $timeout(function() {
										return select_branch(b);
									});
								}
							});
						}
						n = scope.treeData.length;
						for_each_branch(function(b, level) {
							b.level = level;
							return (b.expanded = b.level < expand_level);
						});
						if (scope.treeControl != null) {
							if (angular.isObject(scope.treeControl)) {
								tree = scope.treeControl;
								tree.expand_all = function() {
									return for_each_branch(function(b) {
										return (b.expanded = true);
									});
								};
								tree.collapse_all = function() {
									return for_each_branch(function(b) {
										return (b.expanded = false);
									});
								};
								tree.get_first_branch = function() {
									n = scope.treeData.length;
									if (n > 0) {
										return scope.treeData[0];
									}
								};
								tree.select_first_branch = function() {
									const b = tree.get_first_branch();
									return tree.select_branch(b);
								};
								tree.get_selected_branch = function() {
									return selected_branch;
								};
								tree.get_parent_branch = function(b) {
									return get_parent(b);
								};
								tree.select_branch = function(b) {
									select_branch(b);
									return b;
								};
								tree.get_children = function(b) {
									return b.children;
								};
								tree.select_parent_branch = function(b) {
									let p;
									if (b == null) {
										b = tree.get_selected_branch();
									}
									if (b != null) {
										p = tree.get_parent_branch(b);
										if (p != null) {
											tree.select_branch(p);
											return p;
										}
									}
								};
								tree.add_branch = function(parent, new_branch) {
									if (parent != null) {
										parent.children.push(new_branch);
										parent.expanded = true;
									} else {
										scope.treeData.push(new_branch);
									}
									return new_branch;
								};
								tree.add_root_branch = function(new_branch) {
									tree.add_branch(null, new_branch);
									return new_branch;
								};
								tree.expand_branch = function(b) {
									if (b == null) {
										b = tree.get_selected_branch();
									}
									if (b != null) {
										b.expanded = true;
										return b;
									}
								};
								tree.collapse_branch = function(b) {
									if (b == null) {
										b = selected_branch;
									}
									if (b != null) {
										b.expanded = false;
										return b;
									}
								};
								tree.get_siblings = function(b) {
									let p;
									let siblings;
									if (b == null) {
										b = selected_branch;
									}
									if (b != null) {
										p = tree.get_parent_branch(b);
										if (p) {
											siblings = p.children;
										} else {
											siblings = scope.treeData;
										}
										return siblings;
									}
								};
								tree.get_next_sibling = function(b) {
									let i;
									let siblings;
									if (b == null) {
										b = selected_branch;
									}
									if (b != null) {
										siblings = tree.get_siblings(b);
										n = siblings.length;
										i = siblings.indexOf(b);
										if (i < n) {
											return siblings[i + 1];
										}
									}
								};
								tree.get_prev_sibling = function(b) {
									let i;
									let siblings;
									if (b == null) {
										b = selected_branch;
									}
									siblings = tree.get_siblings(b);
									n = siblings.length;
									i = siblings.indexOf(b);
									if (i > 0) {
										return siblings[i - 1];
									}
								};
								tree.select_next_sibling = function(b) {
									let next;
									if (b == null) {
										b = selected_branch;
									}
									if (b != null) {
										next = tree.get_next_sibling(b);
										if (next != null) {
											return tree.select_branch(next);
										}
									}
								};
								tree.select_prev_sibling = function(b) {
									let prev;
									if (b == null) {
										b = selected_branch;
									}
									if (b != null) {
										prev = tree.get_prev_sibling(b);
										if (prev != null) {
											return tree.select_branch(prev);
										}
									}
								};
								tree.get_first_child = function(b) {
									let _ref;
									if (b == null) {
										b = selected_branch;
									}
									if (b != null) {
										if (((_ref = b.children) != null ? _ref.length : void 0) > 0) {
											return b.children[0];
										}
									}
								};
								tree.get_closest_ancestor_next_sibling = function(b) {
									let next;
									let parent;
									next = tree.get_next_sibling(b);
									if (next != null) {
										return next;
									} else {
										parent = tree.get_parent_branch(b);
										return tree.get_closest_ancestor_next_sibling(parent);
									}
								};
								tree.get_next_branch = function(b) {
									let next;
									if (b == null) {
										b = selected_branch;
									}
									if (b != null) {
										next = tree.get_first_child(b);
										if (next != null) {
											return next;
										} else {
											next = tree.get_closest_ancestor_next_sibling(b);
											return next;
										}
									}
								};
								tree.select_next_branch = function(b) {
									let next;
									if (b == null) {
										b = selected_branch;
									}
									if (b != null) {
										next = tree.get_next_branch(b);
										if (next != null) {
											tree.select_branch(next);
											return next;
										}
									}
								};
								tree.last_descendant = function(b) {
									let last_child;
									n = b.children.length;
									if (n === 0) {
										return b;
									} else {
										last_child = b.children[n - 1];
										return tree.last_descendant(last_child);
									}
								};
								tree.get_prev_branch = function(b) {
									let parent;
									let prev_sibling;
									if (b == null) {
										b = selected_branch;
									}
									if (b != null) {
										prev_sibling = tree.get_prev_sibling(b);
										if (prev_sibling != null) {
											return tree.last_descendant(prev_sibling);
										} else {
											parent = tree.get_parent_branch(b);
											return parent;
										}
									}
								};
								return (tree.select_prev_branch = function(b) {
									let prev;
									if (b == null) {
										b = selected_branch;
									}
									if (b != null) {
										prev = tree.get_prev_branch(b);
										if (prev != null) {
											tree.select_branch(prev);
											return prev;
										}
									}
								});
							}
						}

						scope.$watch(
							'searchObj.searchText',
							helpers.debounce((searchText = '', oldValue) => {
								const searchTextMinLength = 3;
								const isSearchTextLengthAccepted = searchText.length >= searchTextMinLength;
								const isSearchTextLengthEmpty = searchText.length === 0;
								const expandMatchedBranches = () => {
									Array.from(scope.treeData).forEach(branch => {
										const fieldsToCheck = ['tisObjectName', 'groupName'];
										const makeParentsAsExpanded = child => for_all_ancestors(child, parent => (parent.expanded = true));
										const checkIfMatch = (branch, text) => {
											text = text.toLowerCase();
											branch.expanded = false;

											if (
												fieldsToCheck
													.map(field => (branch[field] ? branch[field].toLowerCase() : ''))
													.some(value => value.includes(text))
											) {
												branch.expanded = true;
												makeParentsAsExpanded(branch);
											}

											helpers.objectHasProperty(branch, 'children') &&
												branch.children.forEach(childBranch => checkIfMatch(childBranch, text));
										};

										checkIfMatch(branch, searchText);
									});
								};
								const collapseAllBranches = () => {
									const collapseBranch = branch => {
										branch.expanded = false;

										helpers.objectHasProperty(branch, 'children') && branch.children.forEach(childBranch => collapseBranch(childBranch));
									};

									Array.from(scope.treeData).forEach(collapseBranch);
								};

								if (searchText === oldValue) {
									// If it's first call, wait until scope.treeData will be available.
									isSearchTextLengthAccepted && $timeout(expandMatchedBranches, 1000);
								} else if (isSearchTextLengthAccepted) {
									expandMatchedBranches();
								} else if (isSearchTextLengthEmpty) {
									collapseAllBranches();
								}
							}, 200)
						);
					},
				};
			},
		])

		.provider('treegridTemplate', function() {
			let templatePath = 'template/treeGrid/treeGrid.html';
			this.setPath = function(path) {
				templatePath = path;
			};

			this.$get = function() {
				return {
					getPath: function() {
						return templatePath;
					},
				};
			};
		});
}.call(window));
