/* eslint max-depth: ["error", 6]*/
/* eslint quotes: [0, 'single'] */
/* eslint eqeqeq: [0, 'always'] */
import _get from 'lodash.get';

// TODO: reduce amount of loop iterations in this service
angular.module('TISCC').service('tisObjectListTransformService', function($filter) {
	let that = this;

	// Exception rules that uses to filter out equipments
	let excludeRules = [
		{
			childkey: 'tisObjectType.tisObjectTypeClassification',
			parentkey: 'tisObjectType.tisObjectTypeClassification',
			childvalue: 'Component',
			parentvalue: 'ComponentObject',
		},
	];

	/**
	 * Checks the showing equipment rules described here
	 * https://tranetis.jira.com/wiki/spaces/CC4R2/pages/116195589/Sub-Feature+Facility+Summary+Data+Aggregation
	 * only for the 1st rule of `Other Data Details` section
	 * @returns {Object[]} - returns the parent object
	 * May contain `children` property when at least one child exists after filtration
	 */
	const excludeExceptions = function(item) {
		let calculatedChildren = [];
		if (item.children && item.children.length > 0) {
			item.children.forEach(function(child) {
				if (
					!excludeRules.some(function(keyPair) {
						return _get(child, keyPair.childkey) === keyPair.childvalue && _get(item, keyPair.parentkey) === keyPair.parentvalue;
					})
				) {
					calculatedChildren.push(child);
				}
			});

			if (calculatedChildren && calculatedChildren.length > 0) {
				item.children = calculatedChildren;
			} else {
				delete item.children;
			}
		}
		return item;
	};

	/**
	 * Checks the showing equipment rules described here
	 * https://tranetis.jira.com/wiki/spaces/CC4R2/pages/116195589/Sub-Feature+Facility+Summary+Data+Aggregation
	 * for the 2nd, 3rd and 4th rule of `Other Data Details` section
	 * @returns {boolean} - returns true when equipment can be shown
	 */
	const checkRules = function(tisObject, uniqueTypes) {
		let groupName = '';
		if (tisObject && tisObject.tisObjectType) {
			groupName = tisObject.tisObjectType.tisObjectTypeGroupName;
		}
		return tisObject.children || uniqueTypes.has(groupName);
	};

	function isStandaloneItemToHide(currentObj) {
		return excludeRules.some(function(keyPair) {
			return _get(currentObj, keyPair.childkey) === keyPair.childvalue;
		});
	}

	/**
	 *
	 * @param {Object[]} inputArray - objects tree
	 * @param {number|string} locationId
	 * @param {Object[] | undefined} uniqueTypes - the list of unique equipment types. Used for rule if the equipment has automated test or has chart link it is shown
	 * @return {{flat: Object[], tree: Object[]}} - flat and tree structure of equipments
	 */
	that.transform = function(inputArray, locationId, uniqueTypes) {
		let tree = buildTree(inputArray, 'tisObjectTypeGroupName', 0, 'groupName', uniqueTypes);

		updateTranslations(tree);
		addLocationId(tree, locationId);
		buildAutomatedTestRows(tree);

		return {
			tree: tree,
			flat: buildFullEquipmentsList(tree),
		};
	};

	function buildTree(inputArray, typeProp, nestingLevel, translName, uniqueTypes) {
		// the collection of standalone equipments grouped by type
		let groupedStandaloneEquipments = {};

		const equipments = inputArray.reduce((acc, currentEquipment) => {
			if (checkRules(currentEquipment, uniqueTypes)) {
				const {
					children = [],
					tisObjectName,
					tisObjectId,
					tisObjectType: {tisObjectTypeGroupName, tisObjectTypeClassification},
				} = currentEquipment.children ? excludeExceptions(currentEquipment) : currentEquipment;

				// takes just needed fields
				const newCurrentEquipment = {
					tisObjectName,
					tisObjectTypeGroupName,
					tisObjectTypeClassification,
					tisObjectId,
				};

				if (children.length || nestingLevel !== 0) {
					return [
						...acc,
						{
							...newCurrentEquipment,
							children: children.length ? buildTree(children || [], typeProp, 1, translName, uniqueTypes) : null,
						},
					];
				} else if (nestingLevel === 0 && typeProp && !isStandaloneItemToHide(currentEquipment)) {
					const standaloneFolderName = newCurrentEquipment[typeProp];

					// creates new standalone folder when it is not exist
					if (!groupedStandaloneEquipments[standaloneFolderName]) {
						groupedStandaloneEquipments[standaloneFolderName] = {
							[typeProp]: standaloneFolderName,
							children: [],
							type: 'folder',
						};
					}

					groupedStandaloneEquipments[standaloneFolderName].children.push(newCurrentEquipment);
				}

				return acc;
			}

			return acc;
		}, []);

		return [...equipments, ...Object.values(groupedStandaloneEquipments)];
	}

	function buildFullEquipmentsList(data, seenIds) {
		let allEquipments = [];
		seenIds = seenIds || {};

		for (let i = 0; i < data.length; i++) {
			if (data[i].tisObjectName && !seenIds[data[i].tisObjectId]) {
				seenIds[data[i].tisObjectId] = true;

				allEquipments.push({
					tisObjectId: data[i].tisObjectId,
					tisObjectName: data[i].tisObjectName,
					tisObjectTypeClassification: data[i].tisObjectTypeClassification,
					tisObjectTypeGroupName: data[i].tisObjectTypeGroupName,
					groupName: $filter('translate')('EQUIPMENT_TYPE_NAME_TABLE.' + data[i].tisObjectTypeGroupName),
				});
			}

			if (data[i].children) {
				let child = buildFullEquipmentsList(data[i].children, seenIds);

				if (child.length) {
					Array.prototype.push.apply(allEquipments, child);
				}
			}
		}

		return allEquipments;
	}

	function updateTranslations(data) {
		for (let i = data.length; i--; ) {
			data[i].groupName = $filter('translate')('EQUIPMENT_TYPE_NAME_TABLE.' + data[i].tisObjectTypeGroupName);

			if (data[i].type == 'folder') {
				let folderTransl = $filter('translate')('EQUIPMENT_TYPE_NAME_TABLE.STANDALONE');
				data[i].groupName = data[i].groupNameHeader = folderTransl + ' ' + data[i].groupName;
			}

			if (data[i].children) {
				updateTranslations(data[i].children);
			}
		}
	}

	function addLocationId(data, locationId) {
		data.forEach(function(equipment) {
			equipment.locationId = locationId;
		});
	}

	// Creates an item for an Automated Test (received from Service Advisory API) under each parent
	function buildAutomatedTestRows(data) {
		for (let i = 0; i < data.length; i++) {
			if (data[i].children && data[i].tisObjectName) {
				data[i].children.unshift({
					tisObjectId: data[i].tisObjectId,
					tisObjectTypeGroupName: data[i].tisObjectTypeGroupName,
					tisObjectName: $filter('translate')('AUTOMATED_TESTS') + ' - ' + data[i].tisObjectName,
					groupName: $filter('translate')('EQUIPMENT_TYPE_NAME_TABLE.' + data[i].tisObjectTypeGroupName),
					testType: 'hierarchical',
				});
				buildAutomatedTestRows(data[i].children);
			}
		}
	}
});
