/* eslint quotes: [0, 'single'] */

angular
	.module('TISCC')
	.controller('FacilitySettingsCtrl', FacilitySettingsCtrl)
	.controller('SettingsFacilityDetailsCtrl', SettingsFacilityDetailsCtrl);

function FacilitySettingsCtrl($scope, $locale, $routeParams, locationDetailsService, configService) {
	$scope.days = $locale.DATETIME_FORMATS.SHORTDAY;
	$scope.featureToggles = configService.getFeatureToggles();
	$scope.locationId = $routeParams.locationId;

	locationDetailsService.getLocationDetailsWithoutServiceAdvisories($scope.locationId).then(function(data) {
		$scope.location = data.locationSummaryList[0];
		$scope.navigation = [
			{
				href: '#/facility/' + $scope.locationId,
				title: $scope.location.locationName,
				active: true,
			},
		];
		$scope.pageTitle = {
			title: $scope.location.locationName,
		};
	});

	locationDetailsService
		.getLocationServiceAdvisoriesCount($scope.locationId)
		.then(count => ($scope.facilityServiceAdvisoryCount = Number.isInteger(+count) ? Number(count) : '?'))
		.catch(() => ($scope.facilityServiceAdvisoryCount = '?'));

	$scope.activeTab = 'settings';

	$scope.showSettings = function(name) {
		$scope.menu = name;
		$scope.template = 'components/settings/' + name + '.html';
	};
	$scope.showSettings('facility-details');
}

SettingsFacilityDetailsCtrl.$inject = ['$scope', '$locale', 'modalHelperService', 'locationDetailsService'];
function SettingsFacilityDetailsCtrl($scope, $locale, modalHelperService, locationDetailsService) {
	let dt = new Date();
	$scope.energyBaseYear = {
		from: new Date(dt.getFullYear(), dt.getMonth() - 11, 1, 0, 0, 0, 0),
		to: new Date(),
	};

	$scope.facilityBaseline = {
		from: new Date(dt.getFullYear(), dt.getMonth() - 3, dt.getDate() + 1, 0, 0, 0, 0),
		to: new Date(),
	};

	$scope.buildingBuilt = new Date();
	$scope.maxDt = new Date();

	$scope.sortParams = [
		{
			column: 'cycleStart.value',
			order: true,
		},
	];

	$scope.columnOrderMap = [
		'cycleStart',
		'cycleEnd',
		'meterId',
		'units',
		'totalCost',
		'totalUnitsBilled',
		'energyRate',
		'peakDemand',
		'demandBilled',
		'reactiveDemand',
		'energyType',
		'comments',
	];

	locationDetailsService.getEnergyStarData(function(data) {
		$scope.tableRows = data;
	});

	$scope.edit = function(row) {
		row.editMode = true;
		$scope.saveState(row);
	};

	$scope.remove = function(row) {
		let modalInstance = modalHelperService.open({
			templateUrl: 'common/messages/confirm-message.html',
			controller: 'ConfirmMessageCtrl',
			backdrop: 'static',
			resolve: {
				content: function() {
					let content = {
						title: 'Confirmation message',
						body: 'Are you sure to delete this item?',
					};
					return content;
				},
			},
		});

		modalInstance.result.then(function(response) {
			let index = $scope.tableRows.indexOf(row);
			$scope.tableRows.splice(index, 1);
			$scope.$broadcast('updateHeader');
		});
	};

	$scope.save = function(row) {
		row.editMode = false;
	};

	$scope.cancel = function(row) {
		row.editMode = false;
		$scope.revertState(row);
	};

	$scope.saveState = function(row) {
		angular.forEach(row, function(column, key) {
			if (column.prevVal) column.prevVal = column.value;

			if (column.editType === 'mixed') {
				column.prevUom = column.uom;
			}
		});
	};

	$scope.revertState = function(row) {
		angular.forEach(row, function(column, key) {
			if (column.prevVal) column.value = column.prevVal;

			if (column.editType === 'mixed') {
				column.uom = column.prevUom;
			}
		});
	};
}
