(function() {
	class CprEditableSectionComponentController {
		constructor() {}

		$onInit() {
			const tableHeaders = this.cprData && this.cprData.tableHeader;
			const tableData = this.cprData && this.cprData[this.infoKey];
			if (tableHeaders) {
				this.tableKeys = Object.keys(tableHeaders);
				this.tableHeaders = tableHeaders;
			}
			this.tableData = tableData;
		}
	}

	angular.module('TISCC').component('cprEditableSectionComponent', {
		templateUrl: 'components/reports/chiller-performance/page/report-section/editable-section/cpr-editable-section-component.html',
		controller: CprEditableSectionComponentController,
		bindings: {
			cprIntroHeader: '<',
			cprIntroDescription: '<',
			cprHeader: '<',
			cprDescription: '<',
			cprData: '<',
			infoKey: '@',
		},
	});
})();
