import _get from 'lodash.get';

angular
	.module('TISCC')
	.service('serviceAdvisoryService', function(
		$http,
		$rootScope,
		cacheService,
		tisObjectService,
		handleServiceWorker,
		AUTOMATED_TEST_LEVELS,
		CHART_DATE_FORMAT,
		CHART_TYPE_LEVEL
	) {
		this.getLocationList = function(locationId, useCache, callback) {
			let request;
			const config = {
				params: {
					locationId,
					automatedTestLevels: 'Combined,Individual,AddonException',
				},
			};

			if (useCache) {
				request = $http.get('/ext_api/analytics-api/serviceAdvisory', {
					...config,
					cache: cacheService.getCacheInstance('serviceAdvisoryService', 'LocationList', locationId),
				});
			} else {
				cacheService.getCacheInstance('serviceAdvisoryService', 'LocationList', locationId).removeAll();
				request = $http.get('/ext_api/analytics-api/serviceAdvisory', config);
			}
			if (callback) {
				return request.success(callback);
			} else {
				return request.then(resp => resp.data.serviceAdvisoryList);
			}
		};

		this.getEquipmentList = function(tisObjectId, callback) {
			const config = {
				params: {
					tisObjectId,
					automatedTestLevels: 'Combined,Individual,AddonException',
				},
			};
			let request = $http.get('/ext_api/analytics-api/serviceAdvisory', config);
			if (callback) {
				return request.success(callback);
			} else {
				return request.then(resp => resp.data.serviceAdvisoryList);
			}
		};

		this.getTypesByLocationId = function(locationId, callback) {
			const automatedTestLevels = 'Combined,Individual,AddonException';
			const config = {
				params: {
					locationId: locationId,
					automatedTestLevels,
				},
				headers: handleServiceWorker.createServiceWorkerCacheKeyForRequest({
					cacheKey: `serviceAdvisoryService_TypesByByLocationId_/ext_api/analytics-api/serviceAdvisoryType/${locationId}?${automatedTestLevels}`,
					expiryTime: handleServiceWorker.CACHE_EXPIRE_TIME.IN_3_DAYS,
				}),
				cache: cacheService.getCacheInstance('serviceAdvisoryService', 'TypesByByLocationId', locationId),
				timeout: 300000,
			};

			const request = $http.get('/ext_api/analytics-api/serviceAdvisoryType', config);
			return callback
				? request.success(data => {
						callback(data);
				  })
				: request.then(resp => {
						return resp.data.serviceAdvisoryTypeList;
				  });
		};

		this.getTypesByTisObjectTypeGroupName = function(name, callback) {
			const config = {
				cache: cacheService.getCacheInstance('serviceAdvisoryService', 'TypesByTisObjectTypeGroupName', name),
				headers: handleServiceWorker.createServiceWorkerCacheKeyForRequest({
					cacheKey: `serviceAdvisoryService_tisObjectTypeGroupName_/ext_api/analytics-api/serviceAdvisoryType?tisObjectTypeGroupName=${name}`,
					expiryTime: handleServiceWorker.CACHE_EXPIRE_TIME.IN_3_DAYS,
				}),
			};

			const request = $http.get(`/ext_api/analytics-api/serviceAdvisoryType?tisObjectTypeGroupName=${name}`, config);
			return callback
				? request.success(data => {
						callback(data);
				  })
				: request.then(resp => resp.data.serviceAdvisoryTypeList);
		};

		this.getTypesByTisObjectId = function(tisObjectId) {
			const config = {
				cache: cacheService.getCacheInstance('serviceAdvisoryService', 'TypesByTisObjectId', tisObjectId),
				headers: handleServiceWorker.createServiceWorkerCacheKeyForRequest({
					cacheKey: `serviceAdvisoryService_TypesByTisObjectId_/ext_api/analytics-api/serviceAdvisoryType?tisObjectId=${tisObjectId}`,
					expiryTime: handleServiceWorker.CACHE_EXPIRE_TIME.IN_3_DAYS,
				}),
			};

			return $http.get(`/ext_api/analytics-api/serviceAdvisoryType?tisObjectId=${tisObjectId}`, config).then(resp => resp.data.serviceAdvisoryTypeList);
		};

		this.getAllTypes = function() {
			const config = {
				cache: cacheService.getCacheInstance('serviceAdvisoryService', 'AllTypes'),
				headers: handleServiceWorker.createServiceWorkerCacheKeyForRequest({
					cacheKey: 'serviceAdvisoryService_AllTypes_/ext_api/analytics-api/serviceAdvisoryType',
					expiryTime: handleServiceWorker.CACHE_EXPIRE_TIME.IN_3_DAYS,
				}),
			};

			return $http.get('/ext_api/analytics-api/serviceAdvisoryType', config).then(resp => resp.data.serviceAdvisoryTypeList);
		};

		this.getActions = function(callback) {
			$http.get('translations/en-US/automated-test-suggestions.json').success(callback);
		};

		this.getTranslations = function(callback) {
			$http.get('translations/en.json').success(callback);
		};

		this.getAggregatedServiceAdvisories = function(tisObjectIds, serviceAdvisoryTypeIds, range, enableSuppressions, callback) {
			let config = {};
			let cacheKey = [tisObjectIds, serviceAdvisoryTypeIds, range.from.format('x'), range.to.format('x')].join('/');
			config.params = {
				startDate: moment(range.from)
					.utc()
					.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'), // Should be ISO 8601 format in UTC timezone,
				endDate: moment(range.to)
					.utc()
					.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'), // Should be ISO 8601 format in UTC timezone
				enableSuppressions: enableSuppressions,
			};
			config.headers = {
				tisObjectIds: tisObjectIds,
				// If there is no serviceAdvisoryTypeIds API accepts 0 as non-value
				serviceAdvisoryTypeIds: serviceAdvisoryTypeIds || 0,
			};

			config.cache = cacheService.getCacheInstance('serviceAdvisoryService', 'AggregatedServiceAdvisories', cacheKey);
			let request = $http.get('/ext_api/analytics-api/aggregatedServiceAdvisory', config);

			if (callback) {
				return request.success(callback);
			} else {
				return request;
			}
		};

		this.getAggregatedServiceAdvisoriesByLocation = function(locationId, range, callback) {
			let config = {};
			let request;
			let cacheKey;

			cacheKey = [locationId, range.from.format('x'), range.to.format('x')].join('/');
			config.params = {
				locationId: locationId,
				startDate: moment(range.from)
					.utc()
					.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'), // Should be ISO 8601 format in UTC timezone,
				endDate: moment(range.to)
					.utc()
					.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'), // Should be ISO 8601 format in UTC timezone
			};
			config.cache = cacheService.getCacheInstance('serviceAdvisoryService', 'AggregatedServiceAdvisoriesByLocation', cacheKey);
			request = $http.get('/ext_api/analytics-api/aggregatedServiceAdvisory', config);
			if (callback) {
				return request.success(callback);
			} else {
				return request;
			}
		};

		this.getAggregatedServiceAdvisoriesByEquipmentType = function(
			locationId,
			tisObjectTypeGroupNumber,
			serviceAdvisoryTypeId,
			range,
			omitNoDataEntries,
			callback
		) {
			let config = {};
			let request;
			let cacheKey;

			cacheKey = [locationId, tisObjectTypeGroupNumber, serviceAdvisoryTypeId, range.from.format('x'), range.to.format('x')].join('/');
			config.params = {
				locationId: locationId,
				objectTypeGroupNumber: tisObjectTypeGroupNumber,
				serviceAdvisoryTypeId: serviceAdvisoryTypeId,
				startDate: moment(range.from)
					.utc()
					.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'), // Should be ISO 8601 format in UTC timezone,
				endDate: moment(range.to)
					.utc()
					.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'), // Should be ISO 8601 format in UTC timezone
			};
			config.cache = cacheService.getCacheInstance('serviceAdvisoryService', 'AggregatedServiceAdvisoriesByLocation', cacheKey);
			request = $http.get('/ext_api/analytics-api/aggregatedServiceAdvisory', config);
			if (callback) {
				return request.success(callback);
			} else {
				return request.then(resp => {
					if (omitNoDataEntries) {
						return resp.data.aggregatedServiceAdvisoryList.filter(s => !isNaN(parseFloat(s.valueAtMaxDeviancePerDay)));
					} else {
						return resp.data.aggregatedServiceAdvisoryList;
					}
				});
			}
		};

		this.getAnalyticModelParametersByIds = function(modelIds) {
			const modelIdsStr = Array.from(modelIds).join(',');
			const url = `/ext_api/analytics-api/analytic/model/parameter?analyticModelIds=${modelIdsStr}`;

			return $http.get(url, {
				headers: handleServiceWorker.createServiceWorkerCacheKeyForRequest({
					cacheKey: `propertyStoreService_getPropertiesByType_${url}`,
					expiryTime: handleServiceWorker.CACHE_EXPIRE_TIME.IN_1_DAY,
				}),
			});
		};
		this.getAnalyticThresholdName = function(analyticThresholdId) {
			let config = {};
			config.cache = cacheService.getCacheInstance('serviceAdvisoryService', 'getAnalyticThresholdName', analyticThresholdId);
			return $http
				.get(`/ext_api/analytics-api/threshold/${analyticThresholdId}`, config)
				.then(result => ({name: result.data.analyticThresholdList[0].name || null, parameters: result.data.analyticThresholdList[0].parameters}));
		};

		this.filterOutIntermediateTests = function(automatedTests) {
			if (automatedTests !== null && automatedTests !== undefined && !Array.isArray(automatedTests)) {
				throw new TypeError('The type of passed parameter is wrong');
			}

			automatedTests = automatedTests || [];

			return automatedTests.filter(automatedTest => automatedTest.automatedTestLevel !== AUTOMATED_TEST_LEVELS.INTERMEDIATE);
		};

		this.filterOutAddonExceptionTests = function(automatedTests) {
			if (automatedTests !== null && automatedTests !== undefined && !Array.isArray(automatedTests)) {
				throw new TypeError('The type of passed parameter is wrong');
			}

			automatedTests = automatedTests || [];

			return automatedTests.filter(automatedTest => automatedTest.automatedTestLevel !== AUTOMATED_TEST_LEVELS.ADDONEXCEPTION);
		};

		this.generateChartLink = function(equipment, serviceAdvisoryType, {tisObjectsHierarchy = [], chartList = []} = {}) {
			const rangeTo = equipment.worstDate && moment(equipment.worstDate).format(CHART_DATE_FORMAT.RANGE_DATE_FORMAT);
			const rangeFrom =
				equipment.worstDate &&
				moment(equipment.worstDate)
					.subtract(6, 'days')
					.format(CHART_DATE_FORMAT.RANGE_DATE_FORMAT);

			const chart = chartList.find(({chartId}) => chartId === serviceAdvisoryType.chartId);
			const rangeString = rangeFrom && rangeFrom ? `?startDate=${rangeFrom}&endDate=${rangeTo}` : '';

			if (chart && chart.chartTypeLevel === CHART_TYPE_LEVEL.LOCATION) {
				return `#/facility/${equipment.locationId}/type/facility/chart/${serviceAdvisoryType.chartId}${rangeString}`;
			}

			let equipmentId = equipment.tisObjectId;

			if (chart && serviceAdvisoryType.tisObjectType.tisObjectTypeGroupNumber !== chart.tisObjectType.tisObjectTypeGroupNumber) {
				const parentTisObject = tisObjectService.findParentTisObjectForChart(
					chart.tisObjectType.tisObjectTypeGroupNumber,
					equipment,
					tisObjectsHierarchy
				);

				equipmentId = parentTisObject ? parentTisObject.tisObjectId : equipmentId;
			}

			return `#/facility/${equipment.locationId}/equipment/${equipmentId}/chart/${serviceAdvisoryType.chartId}${rangeString}`;
		};

		this.updateServiceAdvisoryStatus = function(selectedServiceAdvisory, status, callback) {
			let item = [];
			selectedServiceAdvisory.filter(data => {
				item.push({
					tisObjectId: data[['tisObjectId']],
					status: status,
					serviceAdvisoryTypeId: data[['serviceAdvisoryTypeId']],
				});
			});

			let request = $http.put('/ext_api/cmsa-api/serviceAdvisory/status', item);

			if (callback) {
				return request.success(callback);
			} else {
				return request;
			}
		};
		// Currently the only source of suggestions is JSON file (src/js/translations/automated-test-suggestion-[en/uk].json)
		this.generateGetActionsFunction = function(actions) {
			return ({id, returnDefault = false}) => {
				if (returnDefault) {
					return actions.default;
				}
				return _get(actions, id, null);
			};
		};
	});
