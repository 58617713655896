import _get from 'lodash.get';

angular
	.module('TISCC')
	.service('tisObjectTypePropertiesService', function(
		$filter,
		$q,
		helpers,
		locationDetailsService,
		serviceAdvisoryService,
		chartService,
		locationEquipmentService,
		objectTypeService,
		rawDataService,
		rawDataServerlessService,
		translateService,
		subtypeFilterService,
		propertyResolutionService,
		configService,
		ENVIRONMENT
	) {
		const LOAD_VALVE = 'LoadValve';
		const VAV_BOX = 'VAV-BOX';
		const COMPONENT = 'Component';
		const CIRCUIT_DETAILS = 'circuit_details';
		const excludedCompressorCapacityNominal = 'CompressorCapacityNominal';

		// Its temp fix at client side, Improvement ticket has created for API team. It should be removed once its fixed at api.
		const tempFixToMarkAnalyticPropertyAsCalculatedProperty = {
			CondenserWaterGPMPerTonNominal: true,
		};

		let loadValves = [];
		let loadValveNames = [];
		let vavBoxes = [];
		let vavBoxesNames = [];
		let equipmentTypes = [];
		let typeNames = [];
		let typesList = [];
		let allProperties = {};
		let allRightListData = {};

		// Disabled Serverless functioality in all environment
		// const environment = configService.getEnvironmentType();
		// const isProdEnv = environment === ENVIRONMENT.PROD;
		// if (!isProdEnv) rawDataService = rawDataServerlessService;

		this.getEquipmentTypes = function(tisObjects) {
			setVariablesToDefault();
			clearSubMenus();
			generateEquipmentDropDown(tisObjects);
			addEquipmentSubmenus();
			return equipmentTypes;
		};

		this.getLeftPanelData = function(params) {
			let {defaultEquipment, selectedEquipmentType, tisObjects} = params;
			let selectedElements = [];
			let preparedTisObjects = [];

			if (defaultEquipment) {
				selectedEquipmentType = getSelectedEquipmentType(selectedEquipmentType);
			}

			if (selectedEquipmentType.children) {
				selectedElements = selectedEquipmentType.children;
			} else {
				selectedElements = rawDataService.createEquipmentsList({
					equipments: tisObjects,
					typeId: selectedEquipmentType.groupId,
					rootId: selectedEquipmentType.id,
				});
			}

			selectedElements.forEach(function(tisObject) {
				let isValidNotAddedTisObject = !isTisObjectInArray(tisObject, preparedTisObjects);
				tisObject.tisObjectType && tisObject.tisObjectType.tisObjectTypeGroupNumber === selectedEquipmentType.groupId;

				if (isValidNotAddedTisObject) {
					preparedTisObjects.push({
						value: tisObject.tisObjectName,
						tisObjectId: tisObject.tisObjectId,
						tisObjectType: tisObject.tisObjectType,
						subTypeFilterKey: tisObject.subTypeFilterKey,
						id: tisObject.tisObjectType.tisObjectTypeGroupNumber,
						checked: tisObject.checked || false,
						children: tisObject.children,
					});
				}
			});
			return {selectedElements, preparedTisObjects, selectedEquipmentType};
		};

		this.getRightPanelData = function(tisObjects, isAddChildPrefixToHpath, all) {
			const tisObjectIdsByTisObjectType = rawDataService.getTisObjectTypesFromTisObjects(tisObjects);

			if (tisObjectIdsByTisObjectType && Object.keys(tisObjectIdsByTisObjectType).length !== 0) {
				const tisObjectTypeData = Object.keys(tisObjectIdsByTisObjectType).map(function(typeKey) {
					return tisObjectIdsByTisObjectType[typeKey];
				});
				return getData(tisObjectTypeData, tisObjects, isAddChildPrefixToHpath, all);
			}

			return $q.when([]);
		};

		function getSelectedEquipmentType(type) {
			if (![LOAD_VALVE, VAV_BOX].includes(type.typeName)) {
				return type;
			}

			let foundItem = equipmentTypes.find(equipmentType => equipmentType.typeName === type.typeName);
			return foundItem.subMenuItems.find(subItem => subItem.id === type.id) || type;
		}

		function setVariablesToDefault() {
			equipmentTypes = [];
			typesList = [];
			typeNames = [];
			allProperties = {};
			allRightListData = {};
		}

		function getData(tisObjectTypeData, tisObjects, isAddChildPrefixToHpath, all) {
			let promises = [];
			let rightPanelProperties = [];
			const objectGroups = [];
			const subTypesList = rawDataService.getTisObjectSubTypesFromTisObjects(tisObjects);
			tisObjects.forEach(tisObject => {
				if (tisObject.tisObjectType && tisObject.tisObjectType.tisObjectTypeGroupName) {
					objectGroups.push(tisObject.tisObjectType.tisObjectTypeGroupName);
				}
			});
			const excludedPropertiesPromise = subtypeFilterService.getPropertiesFilter(subTypesList, objectGroups);
			promises.push(excludedPropertiesPromise);

			tisObjectTypeData.forEach(function(data) {
				const tisObjectTypeGroupNumber = data.tisObjectType.tisObjectTypeGroupNumber;
				// Get property resoultion of a selected equipments
				const propertyResolutionPromise = propertyResolutionService.getAnalogResolution(tisObjectTypeGroupNumber, data.tisObjectIds || []);

				const propertiesPromise = objectTypeService.getObjectProperties(tisObjectTypeGroupNumber, all);
				const characteristicsPromise = objectTypeService.getObjectCharacteristics(tisObjectTypeGroupNumber);

				promises.push(propertyResolutionPromise);
				promises.push(propertiesPromise);
				promises.push(characteristicsPromise);
				$q
					.all([propertiesPromise, propertyResolutionPromise, characteristicsPromise, excludedPropertiesPromise])
					.then(processProperties(data, rightPanelProperties));
			});

			return $q.all(promises).then(function() {
				rightPanelProperties.forEach(property => buildAndAppendHpathToProperty(property, isAddChildPrefixToHpath));
				return rightPanelProperties;
			});
		}

		function buildAndAppendHpathToProperty(property, isAddChildPrefixToHpath) {
			let sourcePrefix = '';

			if (property.typeName && isAddChildPrefixToHpath) {
				sourcePrefix = `//child::${property.typeName}`;
			}

			let namePrefix = property.isCharacteristic ? '~' : '@';
			let fullPrefix = sourcePrefix + namePrefix;
			property.hpath = fullPrefix + property.propertyName;
		}

		function processProperties(tisObjectTypeData, rightPanelProperties) {
			const tisObjectType = tisObjectTypeData.tisObjectType;
			const typeGroupName = tisObjectType.tisObjectTypeGroupName;
			const instancesTisObjectId = tisObjectTypeData.instancesTisObjectId;

			const isSingleCircuitChiller = (tisObjectTypeData[CIRCUIT_DETAILS] || {}).single && !(tisObjectTypeData[CIRCUIT_DETAILS] || {}).multiple;

			return function(results) {
				const [{data: propertiesData}, propertyResolution, {data: characteristicsData}, excludedProperties] = results;
				let typeNumber = tisObjectType.tisObjectTypeGroupNumber;
				const typeName = tisObjectType.tisObjectTypeClassification === COMPONENT ? tisObjectType.tisObjectTypeGroupName : null;

				propertiesData.tisObjectPropertyList.forEach(property => (property.typeName = typeName));

				let equipmentGroupType = typeNumber;

				// update and store propertilist with updated resolution
				if (propertyResolution.length) {
					const {key, value} = propertyResolutionService.getPropertyResoultionWithKeyValPair(propertyResolution);
					equipmentGroupType = key + '-' + typeNumber;

					allProperties[equipmentGroupType] = propertyResolutionService.updateResolutionForAllProperties(
						propertiesData.tisObjectPropertyList,
						value,
						tisObjectType.tisObjectTypeGroupNumber
					);
				}

				allProperties[typeNumber] = propertiesData.tisObjectPropertyList;
				characteristicsData.analyticParameters.forEach(function(characteristic) {
					allProperties[typeNumber].push(updateCharacteristic(characteristic, typeName));

					// store characteristic parameters against selected equipment
					if (equipmentGroupType !== typeNumber && propertyResolution.length)
						allProperties[equipmentGroupType].push(updateCharacteristic(characteristic, typeName));
				});

				// As per MPT, Single Circuit Chiller uses Chiller Capacity: Design label (ChillerCapacityNominal) for RDR. So hiding CompressorCapacityNominal prop
				// CompressorCapacityNominal will be used for multiple circuit chiller only.
				if (isSingleCircuitChiller) {
					excludedProperties.push(excludedCompressorCapacityNominal);
				}

				allRightListData[equipmentGroupType] = [];
				allProperties[equipmentGroupType].forEach(function(prop) {
					const propNameWithType = `${typeGroupName}${prop.propertyName}`;
					if (!excludedProperties.length || (!excludedProperties.includes(prop.propertyName) && !excludedProperties.includes(propNameWithType)))
						allRightListData[equipmentGroupType].push({
							instances: tisObjectTypeData.instances,
							property: prop,
							tisObjectType: tisObjectType,
							instancesTisObjectId,
						});
				});
				updatePopupListWithProperties(allRightListData[equipmentGroupType], rightPanelProperties, excludedProperties);
			};
		}
		function updatePopupListWithProperties(properties, rightPanelProperties, excludedProperties) {
			properties.forEach(function(prop) {
				let propertiesForList = rawDataService.getPropertiesForRightList(prop);

				propertiesForList.forEach(function(propertyForList) {
					if (!isPropertyAdded(propertyForList, rightPanelProperties)) {
						rightPanelProperties.push(propertyForList);
					}
				});
			});
			rightPanelProperties = rightPanelProperties.filter(rightProps => !excludedProperties.includes(rightProps.propertyName));
		}

		function isPropertyAdded(property, arr) {
			return arr.some(function(item) {
				return item.value === property.value && item.propertyName === property.propertyName;
			});
		}

		function isTisObjectInArray(tisObject, arr) {
			return arr.some(function(item) {
				return item.tisObjectId === tisObject.tisObjectId;
			});
		}

		function updateCharacteristic(characteristic, typeName) {
			characteristic.isCharacteristic = true;
			if (tempFixToMarkAnalyticPropertyAsCalculatedProperty[characteristic.name]) {
				characteristic.isCharacteristic = false;
			}
			characteristic.propertyName = characteristic.name;
			characteristic.typeName = typeName;
			characteristic.propertyAttribute = characteristic.attribute;
			delete characteristic.attribute;
			return characteristic;
		}

		function generateEquipmentDropDown(equipments, isChildren, parentEquipment) {
			const hiddenEquipmentsObject = configService.getHiddenEquipmentsForDropdown();

			equipments.forEach(function(equipment) {
				const id = equipment.tisObjectId;
				const tisObjectTypeGroupName = equipment.tisObjectType.tisObjectTypeGroupName;
				const tisObjectTypeGroupNumber = equipment.tisObjectType.tisObjectTypeGroupNumber;
				const tisObjectTypeClassification = equipment.tisObjectType.tisObjectTypeClassification;
				const isVavBox = tisObjectTypeGroupName === VAV_BOX;
				const isLoadValve = tisObjectTypeGroupName === LOAD_VALVE;

				// if an equipment is marked as hidden in config file, dont allow to add into dropwond object
				const hiddenEquipmentGroupName = hiddenEquipmentsObject[tisObjectTypeGroupNumber];
				if (hiddenEquipmentGroupName === tisObjectTypeGroupName) {
					return;
				}

				if (!typesList.includes(tisObjectTypeGroupName)) {
					typesList.push(tisObjectTypeGroupName);
				}
				if (!isChildren && tisObjectTypeClassification !== COMPONENT) {
					if (isVavBox) {
						!parentEquipment &&
							addDropDownSubMenuItem({
								name: translateService.translateEquipment('VAVBoxes:Standalone'),
								typeName: tisObjectTypeGroupName,
								id: id,
								groupId: tisObjectTypeGroupNumber,
								arrData: vavBoxes,
								arrDataNames: vavBoxesNames,
								sortBy: 1,
							});
					} else if (isLoadValve) {
						!parentEquipment &&
							addDropDownSubMenuItem({
								name: translateService.translateEquipment('LoadValves:Standalone'),
								typeName: tisObjectTypeGroupName,
								id: id,
								groupId: tisObjectTypeGroupNumber,
								arrData: loadValves,
								arrDataNames: loadValveNames,
								sortBy: 1,
							});
					} else {
						addDropDownItem({
							name: translateService.translateEquipment(tisObjectTypeGroupName),
							id: id,
							groupId: tisObjectTypeGroupNumber,
							typeName: tisObjectTypeGroupName,
						});
					}
				} else if (isChildren && tisObjectTypeClassification !== COMPONENT) {
					const children = parentEquipment.children.filter(child => child.tisObjectType.tisObjectTypeGroupNumber === tisObjectTypeGroupNumber);
					if (isVavBox) {
						addDropDownSubMenuItem({
							name: translateService.translateEquipment('VAVBoxes') + parentEquipment.tisObjectName,
							typeName: tisObjectTypeGroupName,
							id: parentEquipment.tisObjectId,
							groupId: tisObjectTypeGroupNumber,
							arrData: vavBoxes,
							arrDataNames: vavBoxesNames,
							children,
							sortBy: 0,
						});
					} else if (isLoadValve) {
						addDropDownSubMenuItem({
							name: translateService.translateEquipment('LoadValves') + parentEquipment.tisObjectName,
							typeName: tisObjectTypeGroupName,
							id: parentEquipment.tisObjectId,
							groupId: tisObjectTypeGroupNumber,
							arrData: loadValves,
							arrDataNames: loadValveNames,
							children,
							sortBy: 0,
						});
					} else {
						addDropDownItem({
							name: translateService.translateEquipment(tisObjectTypeGroupName),
							id: id,
							groupId: tisObjectTypeGroupNumber,
							typeName: tisObjectTypeGroupName,
						});
					}
				}
				if (equipment.children && equipment.children.length) {
					generateEquipmentDropDown(equipment.children, true, equipment);
				}
			});
		}

		function addDropDownItem(opts) {
			if (!typeNames.includes(opts.name)) {
				equipmentTypes.push({
					typeName: opts.typeName,
					name: opts.name,
					id: opts.id,
					groupId: opts.groupId,
					sortBy: opts.sort || 0,
					subMenuItems: opts.subMenuItems || null,
				});
				typeNames.push(opts.name);
			}
		}

		function addDropDownSubMenuItem(opts) {
			if (!opts.arrDataNames.includes(opts.name)) {
				opts.arrData.push(opts);
				opts.arrDataNames.push(opts.name);
			}
		}

		function clearSubMenus() {
			loadValves = [];
			loadValveNames = [];
			vavBoxes = [];
			vavBoxesNames = [];
		}

		function addEquipmentSubmenus() {
			if (loadValves.length && loadValves.length !== 0) {
				loadValves.forEach(e => (e.typeName = LOAD_VALVE));

				addDropDownItem({
					name: translateService.translateEquipment(LOAD_VALVE),
					typeName: LOAD_VALVE,
					subMenuItems: loadValves,
				});
			}
			if (vavBoxes.length && vavBoxes.length !== 0) {
				vavBoxes.forEach(e => (e.typeName = VAV_BOX));

				addDropDownItem({
					name: translateService.translateEquipment(VAV_BOX),
					typeName: VAV_BOX,
					subMenuItems: vavBoxes,
				});
			}
		}
	});
