import {ENVIRONMENT} from '../../common/constants';
import developmentConfig from './dev';
import stagingConfig from './stg';
import productionConfig from './prod';

const CONFIGURATION = {
	[ENVIRONMENT.DEV]: developmentConfig,
	[ENVIRONMENT.STG]: stagingConfig,
	[ENVIRONMENT.PROD]: productionConfig,
};

export const getConfig = envType => {
	return CONFIGURATION[envType];
};

export const updateConfig = ({amplitudeKey, zendeskKey, zendeskTicketFormID}, envType) => {
	const config = getConfig(envType);

	config.amplitudeKey = amplitudeKey;
	config.zendeskConfig.zendeskKey = zendeskKey;
	config.zendeskConfig.zendeskTicketFormID = zendeskTicketFormID;
};
