import {getConfig, updateConfig} from '../common/config';

const services = new WeakMap();
const privateFields = new WeakMap();

class ConfigService {
	constructor(utilityService) {
		services.set(this, {utilityService});

		privateFields.set(this, {
			isInitialized: false,
			currentEnvType: null,
		});
	}

	initialize() {
		/* 	if (privateFields.get(this).isInitialized) {
				throw new Error('The Service is already initialized');
			}
 		*/
		return new Promise((resolve, reject) => {
			if (privateFields.get(this).isInitialized) {
				// eslint-disable-next-line no-console
				console.log('The Service is already initialized');
				resolve('The Service is already initialized');
			} else {
				services
					.get(this)
					.utilityService.getEnvironmentConfig()
					.then(({ENV_TYPE, ...config}) => {
						updateConfig(config, ENV_TYPE);
						privateFields.set(this, {
							isInitialized: true,
							currentEnvType: ENV_TYPE,
						});
						// eslint-disable-next-line no-console
						console.log('The Service is initialized first time.');
						resolve('The Service is initialized first time.');
					})
					.catch(err => {
						reject(err);
					});
			}
		});

		/* return services
			.get(this)
			.utilityService.getEnvironmentConfig()
			.then(({ENV_TYPE, ...config}) => {
				updateConfig(config, ENV_TYPE);
				privateFields.set(this, {
					isInitialized: true,
					currentEnvType: ENV_TYPE,
				});
			}); */
	}

	getEnvironmentType() {
		return privateFields.get(this).currentEnvType;
	}

	getFeatureToggles() {
		return getConfig(privateFields.get(this).currentEnvType).featureToggles;
	}

	getExternalLinks() {
		return getConfig(privateFields.get(this).currentEnvType || {}).externalLinks;
	}

	getRawDataReportConfig() {
		return getConfig(privateFields.get(this).currentEnvType).rawDataReport;
	}

	getAmplitudeKey() {
		return getConfig(privateFields.get(this).currentEnvType).amplitudeKey;
	}

	getZendeskConfig() {
		return getConfig(privateFields.get(this).currentEnvType).zendeskConfig;
	}

	getAutomatedTestsResultsConfig() {
		return getConfig(privateFields.get(this).currentEnvType).automatedTestsResults;
	}

	getTisOfferingsConfig() {
		return getConfig(privateFields.get(this).currentEnvType).tisOfferings;
	}

	getHiddenEquipmentsForDropdown() {
		return getConfig(privateFields.get(this).currentEnvType).hideEquipmentFromEquipmentDropdown;
	}
	getExportAPIConfig() {
		return getConfig(privateFields.get(this).currentEnvType).reportAPIURL;
	}
}

angular.module('TISCC').service('configService', ConfigService);
