/* eslint eqeqeq: [0, 'always'] */
/* eslint one-var: 0 */
import _ from 'lodash';

angular
	.module('TISCC')
	.directive('chart', function(
		$window,
		$rootScope,
		$translate,
		$compile,
		$location,
		helpers,
		$routeParams,
		dataFormattingService,
		propertyStoreService,
		CHART_TYPE,
		LINE_TYPE,
		LINE_THICKNESS,
		MARKER_TYPE,
		MARKER_SIZE
	) {
		const EMPTY_TIME_LINE = {
			data: [],
			lanes: [],
		};

		return {
			restrict: 'A',
			scope: {
				chartData: '=',
				chartOptions: '=',
				timeline: '=',
				chartName: '=',
				range: '=',
				loader: '=',
				eventObject: '<',
			},
			link: function(scope, elements) {
				let tooltipOffset = {top: 32};
				let parentNode = angular.element(document.body);
				let tooltip = '';
				let chartObj;
				let chartData;
				let timelineData;

				const rootSvgElement = d3.select(elements[0]);
				let offsetNode = elements[0].parentNode;
				let isSvgVisible = false;
				let svgMainGroup = rootSvgElement.append('g').attr('display', 'none');

				function createChartObj() {
					const chartType = scope.chartOptions.chartType;

					rootSvgElement.selectAll('*').remove();

					setSvgBox();

					svgMainGroup = rootSvgElement.append('g').attr('display', isSvgVisible ? '' : 'none');

					if (!chartType) {
						return;
					}

					const tooltipObj = {
						closeTooltip: null,
						openToolTip: new WeakMap(),

						showTooltip: function(params, isTimeLine, handlers) {
							if (!params.chartType) {
								params.chartType = isTimeLine ? 'timeline' : chartType;
							}

							showTooltip.bind(this)(params, handlers);
						},

						hideTooltip: hideTooltip,
					};

					const externalMethods = {
						tooltip: tooltipObj,
						translate: $translate,
						extraDataFormatting: dataFormattingService,
						debounce: helpers.debounce,
						isNumber: helpers.isNumber,
						loadChildEquipment,
						propertyStoreService,
						CHART_TYPE,
						LINE_TYPE,
						LINE_THICKNESS,
						MARKER_TYPE,
						MARKER_SIZE,
					};

					const timeLine = scope.timeline === false ? EMPTY_TIME_LINE : scope.timeline;

					chartObj = new window.CommonRenderer(scope.range, timeLine, svgMainGroup, externalMethods, chartType);
					tooltipObj.hideTooltip();
				}

				function loadChildEquipment(equipmentId) {
					const locationId = parseInt($routeParams.locationId) || null;
					if (locationId) {
						tooltip && angular.element(tooltip).remove();
						// :TODO refactor this potential problem
						$rootScope.$apply(function() {
							$location.url('/facility/' + locationId + '/equipment/' + equipmentId + '/chart/null?reload=true');
						});
					}
				}

				function showTooltip(params, handlers) {
					if (isTooltipAlreadyPresent(params.parentElem)) {
						return;
					}

					const tooltipUrlMap = {
						line: 'components/chart/tooltips/line-chart.html',
						lineWithControlRangeAndMean: 'components/chart/tooltips/line-chart.html',
						lineMultiPoints: 'components/chart/tooltips/line-chart-multipoints.html',
						pareto: 'components/chart/tooltips/pareto-chart.html',
						paretoMultipoints: 'components/chart/tooltips/pareto-chart-multipoint.html',
						pie: 'components/chart/tooltips/pie-chart.html',
						stackedBar: 'components/chart/tooltips/bar-chart.html',
						scatter: 'components/chart/tooltips/scatter-chart.html',
						scatterWithPerformanceCurve: 'components/chart/tooltips/scatter-chart.html',
						scatterWithLimits: 'components/chart/tooltips/scatter-chart.html',
						scatterMultipoints: 'components/chart/tooltips/scatter-chart-multipoint.html',
						timeline: 'components/chart/tooltips/timeline.html',
					};

					params.offset = params.offset || tooltipOffset;
					const tooltipScope = scope.$new(true);
					tooltipScope.tooltipData = {};

					angular.extend(tooltipScope.tooltipData, params);

					this.hideTooltip();
					tooltip = angular.element(`<div tooltip id='chart-tooltip' template-url='${tooltipUrlMap[params.chartType]}'></div>`);
					parentNode.append($compile(tooltip)(tooltipScope));

					const rect = offsetNode.getBoundingClientRect();

					let left = params.xPos + chartObj.CHART_MARGIN.left + rect.left + window.pageXOffset;
					let top = params.yPos + rect.top + window.pageYOffset;

					// This check is needed to prevent twinkling of vertical scroll when multipoint tooltip appears in the bottom of the page
					if (['lineMultiPoints', 'paretoMultipoints', 'scatterMultipoints'].includes(tooltipScope.tooltipData.chartType)) {
						const scrollbarVisible = element => element.scrollHeight > document.documentElement.clientHeight;
						const bodyElement = document.getElementsByTagName('body')[0];

						if (!scrollbarVisible(bodyElement)) {
							angular.element(bodyElement).css('overflow', 'hidden');
						}
					}

					tooltip.css('left', left + 'px').css('top', top + 'px');
					tooltip.attr('chartXOffset', chartObj.CHART_MARGIN.left);
					tooltip.attr('chartYOffset', chartObj.CHART_MARGIN.top);
					tooltip.tooltipScope = tooltipScope;

					if (handlers && handlers.mouseenter && typeof handlers.mouseenter === 'function') {
						tooltip.bind('mouseenter', handlers.mouseenter);
					}
					if (handlers && handlers.mouseleave && typeof handlers.mouseleave === 'function') {
						tooltip.bind('mouseleave', handlers.mouseleave);
					}

					if (handlers && handlers.mouseclick && typeof handlers.mouseclick === 'function') {
						tooltip.on('click', event => {
							const href = event.target.getAttribute('href');
							if (href) {
								handlers.mouseclick();
							}
						});
					}

					if (params.parentElem) {
						// save link to target element
						// use it to prevent hiding tooltip if hover on it
						tooltip.parentElem = params.parentElem;
						tooltip.bind('mouseout', e => {
							const hoverTo = e.relatedTarget.parentNode;
							if (!isHoverOnParent(hoverTo)) {
								this.hideTooltip();
							}
						});
					}
				}

				function hideTooltip(elem) {
					if (isHoverOnTooltip(elem)) {
						return;
					}

					if (tooltip) {
						const bodyElement = angular.element(document.getElementsByTagName('body')[0]);

						this.openToolTip = new WeakMap();
						tooltip.tooltipScope.$destroy();
						angular.element(tooltip).remove();

						if (bodyElement.css('overflow') === 'hidden') {
							bodyElement.css('overflow', 'auto');
						}
					}
					tooltip = null;
				}

				function isHoverOnTooltip(elem) {
					if (elem && elem == (tooltip && tooltip[0])) {
						return true;
					}
				}

				function isHoverOnParent(elem) {
					if (elem && elem == (tooltip && tooltip.parentElem)) {
						return true;
					}
				}

				function isTooltipAlreadyPresent(parentElem) {
					if (parentElem && parentElem == (tooltip && tooltip.parentElem)) {
						return true;
					}
				}

				function toggleTimelineArea(timelinePresent) {
					if (chartObj && typeof timelinePresent !== 'undefined') {
						const isVisible = !(timelinePresent === false);
						chartObj.timelineRenderer.setVisibilityForAllLanes(isVisible);
					}
				}

				let resolver = () => null;

				// function to create and assign a new promise to the loader to display the loader until complete the chart rendering
				function generatePromise() {
					scope.loader = new Promise(resolve => {
						resolver = resolve;
					});

					// Reset the value to listen next value
					$rootScope.timelineRangeDirection = null;
				}

				// -------------------------------------- Main render ---------------------------------------------
				scope.render = function() {
					const chartOptions = scope.chartOptions;

					if (!chartObj) return;

					if (chartObj.chartRenderer.tooltip) {
						chartObj.chartRenderer.tooltip.hideTooltip();
					}

					chartData = scope.chartData || [];
					timelineData = scope.timeline.data || [];
					chartObj.checkData(chartData, timelineData, chartOptions.lines, scope.timeline.lanes);

					if (!isSvgVisible) {
						svgMainGroup.attr('display', 'block');
						isSvgVisible = true;
					}

					if (chartObj.noData) {
						chartObj.showEmptyDatasetMessage();
						scope.eventObject.emit('chartRendered');

						// Resolver of the loader Promise that were generated @ #236
						resolver();

						return;
					}

					chartObj.updateSvgSize();
					chartObj.updateRangeX();

					updateYAxesScale(chartObj.chartRenderer, chartData, scope.chartOptions);
					chartObj.updateGrid(scope.range.calendarRange);
					chartObj.draw(chartOptions, chartData);

					scope.eventObject.emit('chartRendered');

					// Resolver of the loader Promise that were generated @ #236
					resolver();
				};

				function setSvgBox() {
					const svgWidth = elements.parent().prop('offsetWidth');
					const svgHeight = elements.parent().prop('offsetHeight');
					const chartType = scope.chartOptions.chartType;
					elements.attr('viewBox', '0 0 ' + svgWidth + ' ' + svgHeight);
					if (chartType === 'pie') {
						elements.attr('preserveAspectRatio', 'xMidYMid slice');
					} else {
						elements.attr('preserveAspectRatio', 'none');
					}
				}

				function resize(options = {}) {
					if (!isSvgVisible) {
						return;
					}

					setSvgBox();

					if (chartObj.noData) {
						chartObj.showEmptyDatasetMessage();
						scope.eventObject.emit('chartResized');
						return;
					}
					chartObj.updateSvgSize(true);
					chartObj.updateGrid(scope.range.calendarRange);
					chartObj.chartRenderer.updateChartPos(scope.chartOptions, chartData, options);

					scope.eventObject.emit('chartResized');
				}

				const resizeDebounce = helpers.debounce(resize, 100);

				function hide() {
					svgMainGroup.attr('display', 'none');
					isSvgVisible = false;
				}

				// :TODO check if correct
				$rootScope.$watch('unitsSystem', scope.render);
				// scope.$watch('chartData', (newval) => {
				// 	if (newval.length && (scope.chartOptions && this.type === scope.chartOptions.chartType)) {
				// 		scope.render();
				// 	}
				// });

				// watch the timeline Range direction to display start loading the loader.
				$rootScope.$watch('timelineRangeDirection', newVal => {
					if (newVal !== null) generatePromise();
				});

				scope.$watch('chartOptions.chartType', newval => {
					if (newval) {
						createChartObj();
						scope.render();
						if (scope.chartOptions && scope.chartOptions.chartType) {
							this.type = scope.chartOptions.chartType;
						}
					} else {
						hide();
					}
				});
				scope.$watch('timeline', function(value) {
					toggleTimelineArea(value);
					scope.render();
				});

				// watch isUpdatedByTimeLineNavigation prop in timeline object to rerender chart with updated data while moving prev / next timeline period using timeline Navigation
				scope.$watch('timeline.isUpdatedByTimeLineNavigation', function(value, oldValue) {
					if (chartObj && value !== undefined && value !== oldValue) {
						scope.render();
					}
				});

				scope.$watch(
					function() {
						return d3Locale.current;
					},
					function() {
						chartObj && chartObj.updateGrid(scope.range.calendarRange);
					}
				);
				scope.$watch(() => scope.$parent.maximizeCharts, resizeDebounce);

				function toggleTimelineHandler() {
					if (!isSvgVisible) {
						return;
					}

					resize();
					chartObj.timelineRenderer.drawTimeline(chartObj.chartRenderer);
				}

				function redrawChartLinesHandler() {
					if (!isSvgVisible) {
						return;
					}

					if (chartObj.noData) {
						chartObj.showEmptyDatasetMessage();
						return;
					}
					chartObj.chartRenderer.dataNode.selectAll('*').remove();
					updateYAxesScale(chartObj.chartRenderer, chartData, scope.chartOptions);
					chartObj.chartRenderer.drawData(scope.chartOptions, chartData);
				}

				function toggleChartLineHandler({line}) {
					if (!isSvgVisible) {
						return;
					}

					if (chartObj.noData) {
						chartObj.showEmptyDatasetMessage();
						return;
					}

					// Redraw chart lines if axis isn't visible or axis range is smaller then line range
					if (chartObj.chartRenderer.yAxes && scope.chartOptions.yAxis && line.chartAxisId) {
						const chartRendererYAxis = chartObj.chartRenderer.yAxes.find(axis => axis.id === line.chartAxisId);
						const chartOptionsYAxis = scope.chartOptions.yAxis.find(axis => axis.chartAxisId === line.chartAxisId);

						if (
							chartRendererYAxis &&
							chartOptionsYAxis &&
							line.visible &&
							(!chartRendererYAxis.visible || checkIfCalculatedRangeIsSmallerThenExtent(chartOptionsYAxis.calculatedRange, line.extent))
						) {
							redrawChartLinesHandler();
						}
					}

					// updateYAxesScale(chartObj.chartRenderer, chartData, scope.chartOptions);
					chartObj.chartRenderer.toggleChartLine(scope.chartOptions, chartData, line);
				}

				function hideNegativeValues(val) {
					let newChartData = _.cloneDeep(chartData);
					if (val) {
						if (scope.chartOptions && scope.chartOptions.lines.length > 0) {
							scope.chartOptions.lines.forEach((line, index) => {
								if (line.chartAxisId === 'y') {
									newChartData[index] = _.filter(chartData[index], function(cObj) {
										return cObj.y > 0 || cObj.y === 0;
									});
								}
							});
						}
					}

					if (!isSvgVisible) {
						return;
					}

					if (chartObj.noData) {
						chartObj.showEmptyDatasetMessage();
						return;
					}
					chartObj.chartRenderer.dataNode.selectAll('*').remove();
					updateYAxesScale(chartObj.chartRenderer, newChartData, scope.chartOptions);
					chartObj.chartRenderer.drawData(scope.chartOptions, newChartData);
				}

				function checkIfCalculatedRangeIsSmallerThenExtent(calculatedRange = [], extent = []) {
					const [minCalcRange, maxCalcRange] = calculatedRange;
					const [minExtent, maxExtent] = extent;

					return (
						minExtent < minCalcRange ||
						maxExtent > maxCalcRange ||
						(!minCalcRange && !maxCalcRange && helpers.isNumber(minExtent) && helpers.isNumber(maxExtent))
					);
				}

				function formatChartHandler(changesMap = {}) {
					Object.keys(changesMap).forEach(lineHash => {
						chartObj.chartRenderer.changeLine(lineHash, changesMap[lineHash]);
					});
				}

				function updateYAxesScale(chartRenderer, chartData, chartOptions) {
					const axisToScaleArray = chartRenderer.updateYAxesScale(chartOptions, chartData);

					if (axisToScaleArray) {
						chartOptions.yAxis.forEach(axis => (axis.calculatedRange = axisToScaleArray.get(axis.chartAxisId)));
					}
				}

				const brushChartHandler = helpers.debounce(extent => {
					if (chartObj) {
						const [oldMin, oldMax] = chartObj.x.domain();
						const [newMin, newMax] = extent.slice(0, 2);
						const haveToCalculateScale = oldMin > newMin || oldMax < newMax;
						chartObj.x.domain(extent.slice(0, 2));

						if (!isSvgVisible) {
							return;
						}
						if (haveToCalculateScale) {
							updateYAxesScale(chartObj.chartRenderer, chartData, scope.chartOptions);
						}
						chartObj.updateGrid(scope.range.calendarRange);
						chartObj.brushRange(scope.range, scope.chartOptions, chartData);
					}
				}, 100);

				function switchRuller({rullerEnabled}) {
					if (chartObj && chartObj.chartRenderer) {
						chartObj.chartRenderer.onSwitchRuller(rullerEnabled);
					}
				}

				const eventListeners = {
					toggleTimeline: toggleTimelineHandler,
					redrawChartLines: redrawChartLinesHandler,
					toggleChartLine: toggleChartLineHandler,
					chartResize: resizeDebounce,
					chartResizeImmediately: resize,
					hideChart: hide,
					brushChart: brushChartHandler,
					formatChart: formatChartHandler,
					renderChart: scope.render,
					updateAxisRange: scope.render,
					switchRuller: switchRuller,
					hideNegativeValues: hideNegativeValues,
				};

				const onOffEventListeners = action => {
					Object.keys(eventListeners).forEach(event => {
						scope.eventObject[action](event, eventListeners[event]);
					});

					angular.element($window)[action]('resize', resizeDebounce);
				};

				onOffEventListeners('on');

				scope.$on('$destroy', () => {
					onOffEventListeners('off');
				});
			},
		};
	});
