angular.module('TISCC').service('cacheService', function($cacheFactory) {
	let that = this;

	this.getCacheInstance = function(type, name, id) {
		let cacheKey = '';
		let objRef = that.caches;
		let returnValue;
		let input = Array.prototype.slice.call(arguments);

		if (!type) {
			// TODO throw some error here
			return false;
		}

		returnValue = input.reduce(function(accum, currentItem, currIdx) {
			cacheKey = cacheKey > '' ? cacheKey + '.' + currentItem : currentItem;
			if (!accum[currentItem]) {
				if (currIdx === input.length - 1) {
					accum[currentItem] = $cacheFactory(cacheKey);
				} else {
					accum[currentItem] = {};
				}
			}
			return accum[currentItem];
		}, objRef);
		return returnValue;
	};

	this.caches = {};
});
