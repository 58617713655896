function splitBySemicolon(inputString) {
	const semicolon = ':';
	let result = inputString.split(semicolon);
	if (result.length && result.length > 1) {
		const lastElement = result.pop();
		result = [result.join(semicolon), lastElement.trim()];
	}
	return result;
}

angular.module('TISCC').service('translateService', function($filter, $translate, LITERAL) {
	this.translateEquipment = function(tisObjectTypeGroupName) {
		return $filter('translate')('EQUIPMENT_TYPE_NAME_TABLE.' + tisObjectTypeGroupName);
	};

	this.translateProperty = function(property, tisObjectType, params, isMultiple = false, multiplePrefix) {
		return $filter('translateProperty')(property, tisObjectType || null, params, isMultiple, multiplePrefix);
	};

	this.translateThreshold = (thresholdName, tisObjectType) => {
		const untranslatedPossibleThresholdNames = [
			`${LITERAL.AUTOMATED_TESTS_THRESHOLDS_PREFIX}${tisObjectType}${thresholdName}`,
			`${LITERAL.AUTOMATED_TESTS_THRESHOLDS_PREFIX}${thresholdName}`,
		];

		const translation = untranslatedPossibleThresholdNames.reduce((translatedName, untranslatedPossibleName) => {
			if (!translatedName) {
				translatedName = $translate(untranslatedPossibleName);
				return translatedName !== untranslatedPossibleName && translatedName;
			}

			return translatedName;
		}, null);

		const result = translation ? splitBySemicolon(translation) : untranslatedPossibleThresholdNames[0];

		return Array.isArray(result) ? result : [result];
	};

	this.translate = $translate;
});
