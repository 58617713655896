/* eslint quotes: [0, 'single'] */
/* eslint one-var: 0 */

angular.module('TISCC').controller('StandaloneReportCtrl', StandaloneReportCtrl);

function StandaloneReportCtrl(
	$scope,
	$route,
	$routeParams,
	$location,
	$timeout,
	locationDetailsService,
	locationEquipmentService,
	modalHelperService,
	$q,
	$filter,
	serviceAdvisoryService,
	googleAnalyticsService,
	authorization,
	ROUTES,
	AUTH_CONFIGURATION
) {
	let progressIncrement;
	let stopLoadingDeferred = $q.defer();
	const isRDReport = $route.current.$$route.isRDReport;
	$scope.location = {
		locationId: $routeParams.locationId || null,
	};
	$scope.openReport = openRDReport;
	$scope.modal;
	$scope.equipmentsOriginalHierarchyCache = [];

	// Progress / Loading
	$scope.locationDetailsDeferred = $q.defer();
	$scope.locationDetailsPromise = $scope.locationDetailsDeferred.promise;
	$scope.serviceAdvisoryServiceDeferred = $q.defer();
	$scope.serviceAdvisoryServicePromise = $scope.serviceAdvisoryServiceDeferred.promise;
	$scope.locationObjectListDeferred = $q.defer();
	$scope.locationObjectListPromise = $scope.locationObjectListDeferred.promise;
	$scope.loadingPromises = [];
	$scope.progress = 0;

	function incrementProgressIndicator(deferred) {
		$scope.progress = $scope.progress + progressIncrement;

		if (typeof deferred !== 'undefined') {
			deferred.resolve();
		}
	}

	// Entry point
	if ($scope.location && $scope.location.locationId) {
		$scope.loadingPromises = [
			$scope.serviceAdvisoryServicePromise,
			$scope.locationDetailsPromise,
			$scope.locationObjectListPromise,
			stopLoadingDeferred.promise,
		];
		progressIncrement = 100 / $scope.loadingPromises.length;

		$timeout(function() {
			// Bump the progress right away
			incrementProgressIndicator();
		}, 500);

		(isRDReport ? initRawDataReport : initAllReports)($scope.location.locationId);
	} else {
		showErrorMessage();
	}

	function initAllReports(locationId) {
		$q
			.all([
				serviceAdvisoryService.getAllTypes().then(formatServiceAdvisoryTypeList),
				locationDetailsService.getLocationDetailsWithoutServiceAdvisories(locationId).then(formatLocationDetailsResponse),
			])
			.then(fetchLocationObjectsList)
			.then(openCPReport);
	}

	function initRawDataReport(locationId) {
		$q
			.all([
				serviceAdvisoryService.getAllTypes().then(formatServiceAdvisoryTypeList),
				locationDetailsService.getLocationDetailsWithoutServiceAdvisories(locationId).then(formatLocationDetailsResponse),
			])
			.then(fetchLocationObjectsList)
			.then(buildFetchedLocationObjectsList)
			.then(() => $timeout(stopLoadingDeferred.resolve, 1000)) // allows to show 100% rendering of progress indicator
			.then(openRDReport);
	}

	function formatServiceAdvisoryTypeList(serviceAdvisoryTypeList) {
		if (serviceAdvisoryTypeList) {
			$scope.uniqueServiceAdvisoryTypes = getUniqueTypes(serviceAdvisoryTypeList);
		}

		incrementProgressIndicator($scope.locationDetailsDeferred);
		return $scope.uniqueServiceAdvisoryTypes;
	}

	function formatLocationDetailsResponse(data) {
		let result = (data && data.locationSummaryList && data.locationSummaryList[0]) || false;
		if (result) {
			$scope.location = result;
		}
		incrementProgressIndicator($scope.serviceAdvisoryServiceDeferred);
		return $scope.location;
	}

	function fetchLocationObjectsList() {
		return locationEquipmentService.getLocationObjectsList($scope.location.locationId, null, true).then(function(data) {
			let tisObjectsHierarchy = data.tisObjectList;
			$scope.equipment = $filter('filterNested')(tisObjectsHierarchy, {tisObjectId: parseInt($routeParams.equipmentId)}, true).pop();
		});
	}

	function buildFetchedLocationObjectsList(data) {
		let tisObjectList = (data && data.tisObjectList) || [];
		if (tisObjectList.length > 0) {
			$scope.equipmentsOriginalHierarchyCache = filterByTypes(tisObjectList, $scope.uniqueServiceAdvisoryTypes);
		}
		incrementProgressIndicator($scope.locationObjectListDeferred);
	}

	function filterByTypes(tisObjectList, uniqueTypes) {
		for (let i = tisObjectList.length - 1; i > -1; i--) {
			let groupName = tisObjectList[i].tisObjectType.tisObjectTypeGroupName;

			if (!uniqueTypes[groupName]) {
				tisObjectList.splice(i, 1);
			}
		}

		return tisObjectList;
	}

	function getUniqueTypes(data) {
		return data.reduce(function(acc, el) {
			if (el.tisObjectType !== undefined) {
				acc[el.tisObjectType.tisObjectTypeGroupName] = true;
			}

			return acc;
		}, {});
	}
	function getOnCloseFunction(limitToOneReportOnly) {
		let onClose = undefined;
		let targetURL = undefined;
		const {locationId, equipmentId, startDate, endDate} = $routeParams;
		if (!limitToOneReportOnly) {
			if (locationId && equipmentId) {
				targetURL = ROUTES.EQUIPMENT_SUMMARY.replace(':locationId', locationId).replace(':equipmentId', equipmentId);
			} else if (locationId) {
				targetURL = ROUTES.FACILITY_SUMMARY.replace(':locationId', locationId);
				if (startDate && endDate) {
					targetURL = `${targetURL}?startDate=${startDate}&endDate=${endDate}`;
				}
			}
			if (targetURL) {
				onClose = function() {
					$location.url(targetURL);
				};
			}
		}
		return onClose;
	}
	function openCPReport() {
		googleAnalyticsService.sendFlowEvent('Report generation window', 'Open window', {
			label: 'CPR-standalone',
			value: 0,
		});
		const {hasCPROnlyRole} = authorization;
		const limitToOneReportOnly = hasCPROnlyRole();
		$scope.modal = modalHelperService.open({
			templateUrl: 'components/reports/report-dialog.html',
			controller: 'ReportDialogCtrl',
			backdrop: 'static',
			windowClass: 'report-dialog full-height',
			resolve: {
				data: function() {
					return {
						report: 'chiller-performance',
						locationData: $scope.location,
						defaultSelection: $scope.equipment,
						equipmentsData: [$scope.equipment],
						limitToOneReportOnly,
						onClose: getOnCloseFunction(limitToOneReportOnly),
					};
				},
			},
		});
	}

	function openRDReport() {
		googleAnalyticsService.sendFlowEvent('Report generation window', 'Open window', {
			label: 'RDR-standalone',
			value: 0,
		});
		const {hasRDROnlyRole} = authorization;
		const limitToOneReportOnly = hasRDROnlyRole();

		$scope.modal = modalHelperService.open({
			templateUrl: 'components/reports/report-dialog.html',
			controller: 'ReportDialogCtrl',
			backdrop: 'static',
			windowClass: 'report-dialog full-height',
			resolve: {
				data: function() {
					return {
						report: 'raw-data',
						locationData: $scope.location,
						equipmentsData: $scope.equipmentsOriginalHierarchyCache,
						limitToOneReportOnly,
						onClose: getOnCloseFunction(limitToOneReportOnly),
					};
				},
			},
		});
	}

	function showErrorMessage(message) {
		modalHelperService.open({
			templateUrl: 'common/messages/access-denied-error-message.html',
			controller: 'RoleAccessErrorMessageCtrl',
			backdrop: 'static',
		});
	}

	$scope.$on('$routeChangeStart', function(event, next) {
		const {hasCPROnlyRole, hasRDROnlyRole} = authorization;
		const isLoginURL = next.originalPath === AUTH_CONFIGURATION.ROUTES.LOGIN_URL;
		if (!isLoginURL && (hasCPROnlyRole() || hasRDROnlyRole())) {
			showErrorMessage();
			event.preventDefault();
		}
	});
}
