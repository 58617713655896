// Hardcoded display values, until API properly handles this
export const BINARY_PROPERTY_MAPPING = Object.freeze({
	DiagnosticAutomaticReset: [
		{
			variants: [true, 'true', '1', 1, 'Alarm'],
			displayValueTranslationKey: 'LABEL_TIMELINE_ALARM',
			transparent: false,
			color: '#0066cc', // Blue
			colorIndex: 0,
		},
		{
			variants: [false, 'false', 0, '0', 'Normal'],
			displayValueTranslationKey: 'LABEL_TIMELINE_NORMAL',
			transparent: false,
			color: '#666666', // Gray
			colorIndex: 1,
			forceColor: true,
		},
	],
	DiagnosticAutomaticResetNormalized: [
		{
			variants: [true, 'true', '1', 1, 'Yes'],
			displayValueTranslationKey: 'LABEL_TIMELINE_YES',
			transparent: false,
			color: '#0066cc', // Blue
			colorIndex: 0,
		},
		{
			variants: [false, 'false', 0, '0', 'No'],
			displayValueTranslationKey: 'LABEL_TIMELINE_NO',
			transparent: false,
			color: '#666666', // Gray
			colorIndex: 1,
			forceColor: true,
		},
	],
	DiagnosticManualResetRequired: [
		{
			variants: [true, 'true', 1, '1', 'Alarm'],
			displayValueTranslationKey: 'LABEL_TIMELINE_ALARM',
			transparent: false,
			color: '#aa0000', // Red
			colorIndex: 0,
		},
		{
			variants: [false, 'false', 0, '0', 'Normal'],
			displayValueTranslationKey: 'LABEL_TIMELINE_NORMAL',
			transparent: false,
			color: '#666666', // Gray
			colorIndex: 1,
			forceColor: true,
		},
	],
	DiagnosticManualResetRequiredNormalized: [
		{
			variants: [true, 'true', 1, '1', 'Yes'],
			displayValueTranslationKey: 'LABEL_TIMELINE_YES',
			transparent: false,
			color: '#aa0000', // Red
			colorIndex: 0,
		},
		{
			variants: [false, 'false', 0, '0', 'No'],
			displayValueTranslationKey: 'LABEL_TIMELINE_NO',
			transparent: false,
			color: '#666666', // Gray
			colorIndex: 1,
			forceColor: true,
		},
	],
	OccupancyActive: [
		{
			variants: [false, 'false', '0', 'Occupied'],
			displayValueTranslationKey: 'LABEL_TIMELINE_OCCUPIED',
			transparent: false,
			color: '#00f217', // Green
			colorIndex: 0,
		},
		{
			variants: [true, 'true', '1', 'Unoccupied'],
			displayValueTranslationKey: 'LABEL_TIMELINE_UNOCCUPIED',
			transparent: false,
			color: '#666666', // Gray
			colorIndex: 1,
			forceColor: true,
		},
	],
	HeatCoolModeNormalized: [
		{
			variants: [true, '1', 'Heat'],
			displayValueTranslationKey: 'LABEL_TIMELINE_HEAT',
			transparent: false,
			color: '#8b0000', // Red
			colorIndex: 0,
		},
		{
			variants: [false, '0', 'Cool'],
			displayValueTranslationKey: 'LABEL_TIMELINE_COOL',
			transparent: false,
			color: '#00468b', // Blue
			colorIndex: 1,
		},
	],
	HeatCoolMode: [
		{
			variants: [true, '1', 'Heat'],
			displayValueTranslationKey: 'LABEL_TIMELINE_HEAT',
			transparent: false,
			color: '#8b0000', // Red
			colorIndex: 0,
		},
		{
			variants: [false, '0', 'Cool'],
			displayValueTranslationKey: 'LABEL_TIMELINE_COOL',
			transparent: false,
			color: '#00468b', // Blue
			colorIndex: 1,
		},
	],
	FanState: [
		{
			variants: [true, 'true', 'On', 'On'],
			displayValueTranslationKey: 'LABEL_TIMELINE_ON',
			color: '#FFFF00', // Yellow
			transparent: false,
			colorIndex: 0,
		},
		{
			variants: [false, 'false', 'Off', 'Off'],
			displayValueTranslationKey: 'LABEL_TIMELINE_OFF',
			color: '#666666', // Gray
			transparent: false,
			colorIndex: 1,
			forceColor: true,
		},
	],
	ConstantVolumeOperationNormalized: [
		{
			variants: [true, 'true', '1', 'Yes'],
			displayValueTranslationKey: 'LABEL_TIMELINE_YES',
			transparent: false,
			color: '#ffff00', // Yellow
			colorIndex: 0,
		},
		{
			variants: [false, 'false', '0', 'No'],
			displayValueTranslationKey: 'LABEL_TIMELINE_NO',
			transparent: false,
			color: '#666666', // Gray
			colorIndex: 1,
			forceColor: true,
		},
	],
	ConstantVolumeOperation: [
		{
			variants: [true, 'true', '1', 'Yes'],
			displayValueTranslationKey: 'LABEL_TIMELINE_YES',
			transparent: false,
			color: '#ffff00', // Yellow
			colorIndex: 0,
		},
		{
			variants: [false, 'false', '0', 'No'],
			displayValueTranslationKey: 'LABEL_TIMELINE_NO',
			transparent: false,
			color: '#666666', // Gray
			colorIndex: 1,
			forceColor: true,
		},
	],
	SupplyFanStartStop: [
		{
			variants: [true, 'true', 'On', 'On'],
			displayValueTranslationKey: 'LABEL_TIMELINE_ON',
			transparent: false,
		},
		{
			variants: [false, 'false', 'Off', 'Off'],
			displayValueTranslationKey: 'LABEL_TIMELINE_OFF',
			transparent: true,
		},
	],
	SupplyFanStartStopNormalized: [
		{
			variants: [true, 'true', 'On', 'On'],
			displayValueTranslationKey: 'LABEL_TIMELINE_ON',
			transparent: false,
		},
		{
			variants: [false, 'false', 'Off', 'Off'],
			displayValueTranslationKey: 'LABEL_TIMELINE_OFF',
			transparent: true,
		},
	],
	DefaultBinaryProperty: [
		{
			variants: [true, 'true', '1', 'Yes'],
			displayValueTranslationKey: 'LABEL_TIMELINE_YES',
			transparent: false,
		},
		{
			variants: [false, 'false', '0', 'No'],
			displayValueTranslationKey: 'LABEL_TIMELINE_NO',
			transparent: true,
		},
	],
	FanOutputNormalized: [
		{
			variants: [true, 'true', 100, 'Yes'],
			displayValueTranslationKey: 'LABEL_TIMELINE_ON',
			color: '#FFFF01',
			transparent: false,
			colorIndex: 0,
		},
		{
			variants: [false, 'false', 0, 'No'],
			displayValueTranslationKey: 'LABEL_TIMELINE_OFF',
			transparent: false,
			color: '#666667', // Gray
			colorIndex: 1,
		},
	],
	PumpBankEnable: [
		{
			variants: [true, 'true', 100, 'Yes'],
			displayValueTranslationKey: 'LABEL_TIMELINE_YES',
			color: '#00F317',
			transparent: false,
			colorIndex: 0,
		},
		{
			variants: [false, 'false', 0, 'No'],
			displayValueTranslationKey: 'LABEL_TIMELINE_NO',
			transparent: false,
			color: '#666667', // Gray
			colorIndex: 1,
		},
	],
	PumpBankRunning: [
		{
			variants: [true, 'true', 100, 'Yes'],
			displayValueTranslationKey: 'LABEL_TIMELINE_YES',
			color: '#FF9999',
			transparent: false,
			colorIndex: 0,
		},
		{
			variants: [false, 'false', 0, 'No'],
			displayValueTranslationKey: 'LABEL_TIMELINE_NO',
			transparent: false,
			color: '#666666', // Gray
			colorIndex: 1,
		},
	],
});
