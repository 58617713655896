angular.module('TISCC').controller('FacilityChartCtrl', FacilityChartCtrl);

function FacilityChartCtrl($rootScope, $scope, $routeParams, $route, $controller, locationDetailsService, ChartFactory, urlService, CONTROLLER_CHART_TYPES) {
	angular.extend(this, $controller('AbstractChartCtrl', {$scope: $scope}));

	const lastRoute = $route.current;
	const eqType = $routeParams.equipmentTypeNumber;

	$scope.isCprAllowed = locationDetailsService.isCprAllowed;
	$scope.isDirAllowed = locationDetailsService.isDirAllowed;
	$scope.isOfferingExpired = locationDetailsService.isOfferingExpired;

	const addChangeChart = $scope.generateAddChartFunction(CONTROLLER_CHART_TYPES.FACILITY_CHART, ChartFactory);
	let chart = null;

	if ($scope.passedChartState) {
		const topChartState = $scope.passedChartState.shift();
		const {getAppropriateRange} = $scope;

		chart = ChartFactory.getNewFacilityChart(
			getAppropriateRange(topChartState.range),
			topChartState.locationId,
			topChartState.chartId,
			eqType,
			topChartState.selectedFacilities || null,
			parseInt(topChartState.chartIndex)
		);

		chart.passedChartState = topChartState;
		$scope.charts = [chart];

		$scope.passedChartState.forEach(state => {
			const chart = addChangeChart({
				charttype: state.isParetoChart ? CONTROLLER_CHART_TYPES.PARETO_CHART : CONTROLLER_CHART_TYPES.FACILITY_CHART,
				locationId: state.locationId,
				equipmentType: state.equipmentType || eqType,
				serviceAdvisoryTypeId: state.serviceAdvisoryTypeId,
				chartId: state.chartId,
				range: getAppropriateRange(state.range),
			});

			chart.passedChartState = state;
		});
	} else {
		const newChartIndex = $scope.getNewChartIndex();
		chart = ChartFactory.getNewFacilityChart(this.initRange, $routeParams.locationId, $routeParams.chartId, eqType, null, newChartIndex);
		$scope.charts = [chart];
	}

	$rootScope.isExport && $scope.charts.forEach($scope.addEventListenersToChartForExport);

	if (chart.eventObject) {
		$scope.addCommonEventListenersToChart(chart);

		chart.eventObject.on('addChart', () => {
			const {chartObj: {locationId, chartId, equipmentType, selectedFacilities}, range} = chart;

			addChangeChart(
				{
					range: $scope.getAppropriateRange(range),
					charttype: CONTROLLER_CHART_TYPES.FACILITY_CHART,
					locationId,
					chartId,
					equipmentType,
					selectedFacilities,
					chartIndex: $scope.getNewChartIndex(),
				},
				chart
			);
		});
	}

	/**
	 * Fixture to avoid controller reload when user changes chart or equipment (saves few API calls)
	 */
	$scope.$on('$locationChangeSuccess', function() {
		// Extra check whether we are going from PieChart to child equipment or back, and really need controller reloaded
		if ($route.current.$$route && $route.current.$$route.controller === 'FacilityChartCtrl') {
			// Will not load only if my view use the same controller,
			$route.current = lastRoute;
		}
	});

	$scope.$watch(
		'chartObj',
		function(newChartObj, oldChartObj) {
			if (angular.equals(newChartObj, oldChartObj)) {
				return;
			}
			let newPath = `/facility/${newChartObj.locationId}/type/${newChartObj.equipmentType}/chart/${newChartObj.chartId}`;
			urlService.changePath(newPath);
		},
		true
	);

	/**
	 * Watch the chart id property in ChartObj to find chart is changed to other type
	 *
	 * if new chart is selected, clear old chart configs like added properties and etc...
	 *
	 */
	$scope.$watch(
		'chartObj.chartId',
		function(newChartId, oldChartId) {
			if ($scope.chartConfig && newChartId !== oldChartId) $scope.chartConfig.clear();
		},
		true
	);
}
