/* global process*/

import {UsageTracker} from '../../usage-tracker';

export class AmplitudeUsageTrackerStub extends UsageTracker {
	initialize() {}

	get isUserSessionStarted() {}

	startUserSession() {}

	endUserSession() {}

	trackUser() {}

	trackEvent() {}
}
