const services = new WeakMap();

class ExceptionHistoryService {
	constructor(helpers, locationDetailsService) {
		services.set(this, {helpers, locationDetailsService});
	}

	createEHRFileName(facilities, to, selectedEquipmentTypeName) {
		const {helpers, locationDetailsService} = services.get(this);
		const generateName = ({facilityName, fileNameDate, selectedEquipmentTypeName}) =>
			`${facilityName}_${fileNameDate.format('YYYY-MM-DD')}_${selectedEquipmentTypeName}_Exception History.xlsx`;
		const isMultipleFacilities = facilities.length > 1;

		// Hotfix to ensure we have valid location timeZone data and wont silently fail
		return locationDetailsService.getLocationDetailsWithoutServiceAdvisories(facilities[0].locationId).then(result => {
			const timeZone = helpers.getPropertyByPath(result, 'locationSummaryList[0].locationTimeZone');
			const facilityName = helpers.getPropertyByPath(result, 'locationSummaryList[0].locationName');
			const now = moment().tz(timeZone);
			const fileNameDate = to.isAfter(now, 'day') ? now : to.clone().add(-1, 'day');
			return generateName({facilityName: isMultipleFacilities ? 'Multiple Facilities' : facilityName, fileNameDate, selectedEquipmentTypeName});
		});
	}
}

angular.module('TISCC').service('exceptionHistoryService', ExceptionHistoryService);
