class ModalHelperService {
	constructor($modal, $rootScope) {
		this.$modal = $modal;
		this.modal = [];

		$rootScope.$on('$routeChangeSuccess', () => {
			this.closeAll();
		});
	}

	open(settings) {
		const item = this.$modal.open(settings);
		this.modal.push(item);
		return item;
	}

	closeAll() {
		this.modal.forEach(item => {
			if (!item.closed) {
				item.close();
			}
		});
		this.modal = [];
	}
}

angular.module('TISCC').service('modalHelperService', ModalHelperService);
