// This is the base class for usage tracking.
// Each concrete usage tracker should extend this one
export class UsageTracker {
	startUserSession() {
		throw new Error('The method is not implemented');
	}

	endUserSession() {
		throw new Error('The method is not implemented');
	}

	trackUser() {
		throw new Error('The method is not implemented');
	}

	trackEvent() {
		throw new Error('The method is not implemented');
	}

	trackEventByCategory(category) {
		return () => {
			throw new Error('The method is not implemented');
		};
	}
}

UsageTracker.USER_SESSION_STATUS = {
	PRISTINE: 'PRISTINE',
	STARTED: 'STARTED',
	ENDED: 'ENDED',
};
