/* eslint max-nested-callbacks: ["error", 4]*/
import _get from 'lodash.get';
import {BINARY_PROPERTY_MAPPING} from '../../settings/property/property-mapping';
import {USER_EVENTS} from '../../common/usage-tracking/categories';
import {PRIMARY_OFFERING} from '../../common/usage-tracking/common/properties-names';
import {PRIMARY_OFFERINGS} from '../../common/usage-tracking/common/primary-offerings';
import {getChangedFieldsOnFormatChart} from '../../common/usage-tracking/categories/equipment-performance-charts/utils';
const {
	EQUIPMENT_PERFORMANCE_CHARTS: {
		events: EQUIPMENT_PERFORMANCE_CHARTS_EVENTS,
		properties: EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES,
		categoryName: EQUIPMENT_PERFORMANCE_CHARTS_CATEGORY_NAME,
	},
} = USER_EVENTS;

const FILTER_EMUMERATIONS_CHART = ['@FanOutputNormalized'];
const CHILLER_MODE = 'ChillerOperatingModeNormalized';

(function() {
	const services = new WeakMap();
	const propertyLineHashToChartDataMap = new WeakMap();
	const chartLegendColorGenerator = new WeakMap();
	const timeLineColorGenerator = new WeakMap();
	const theMapOfHpathMap = new WeakMap();
	const theMapOfTisObjectTypeToTisObjectIds = new WeakMap();
	const legendReadyMap = new WeakMap();
	const chartOptionsMap = new WeakMap();
	const passedStateMap = new WeakMap();
	const originalAxesUomsMap = new WeakMap();
	const chartDataMap = new WeakMap();
	const propertiesMap = new WeakMap();
	const propertiesListMap = new WeakMap();
	const exceptionPropertiesMap = new WeakMap();
	const exceptionMap = new WeakMap();
	const serviceAdvisoryTypeIdsMap = new WeakMap();
	const linePropertyToAxisMap = new WeakMap();
	const analyticModelIdsMap = new WeakMap();
	const suppressionAnalyticParametersMap = new WeakMap();
	const propertyEnumerationsToTypeMap = new WeakMap();
	const hiddenPropertiesMap = new WeakMap();
	const disabledPropertiesMap = new WeakMap();
	const locationTisObjectsMap = new WeakMap();
	const eventListeners = new WeakMap();
	const existingChartDataCallMap = new WeakMap();
	const selectedChartDetailsMap = new Map();
	let allExceptionsName = new Set();
	let suppressionsHpath = '';

	const TIME_LINE_AXIS_NAME = 'timeline';
	const LIMITS_AXIS_NAME = 'limits';
	const OCCUPANCY_AXIS_NAME = 'ZOccupancyActive';
	const PURPLE_COLOR = '#6855a1';
	const ABOVE_CONTROL_RANGE_COLOR = '#ff001a';
	const UPPER_CONTROL_RANGE_COLOR = '#67d25b';
	const LOWER_CONTROL_RANGE_COLOR = '#87b1e1';
	const PARETO_HIGH_TO_LOW_SORT_KEY = 'highToLow';
	const PARETO_CRITICAL_LIMIT_COLOR = '#FF001A';
	const PARETO_CAUTINARY_LIMIT_COLOR = '#F4FF00';
	const MARKER_TYPES = ['DOT', 'SQUARE', 'TRIANGLE', 'X'];
	const PARETO_CRITICAL_LIMIT_COLOR_MATCH_KEY = 'RedLimit';
	const PARETO_CAUTINARY_LIMIT_COLOR_MATCH_KEY = 'YellowLimit';
	const DEFAULT_LOWER_RANGE_END = 60;
	const DEFAULT_UPPER_RANGE_END = 95;
	const DEFAULT_MARKER_SIZE = 6;
	const FACILITY_KEY = 'FACILITY';
	const AUTOMATED_TEST_LEVELS_INDIVIDUAL = 'Individual';
	const AUTOMATED_TEST_LEVELS_COMBINED = 'Combined';
	const AUTOMATED_TEST_LEVELS_ADDONEXCEPTION = 'AddonException';
	const PERFORMANCE_CURVE = 'performanceCurve';
	const WEATHERGROUP_ID = 29;
	const ADD_PROPERTY_TO_CHART_STORE = 'add-properties-to-chart-data';
	const ADD_PROPERTY_TO_CHART_HPAHT = 'add-properties-to-chart-hpaths';

	const CURVE_TYPE_NAME_MAPPING = [
		{
			title: 'Approach Temperature (Evap): Scatter',
			curveTypeName: 'Evaporator Approach Temperature',
		},
		{
			title: 'Approach Temperature (Cond): Scatter',
			curveTypeName: 'Condenser Approach Temperature',
		},
	];

	const AT_SYMBOL = '@';

	let LOWER_CONTROL_RANGE_TEXT;
	let UPPER_CONTROL_RANGE_TEXT;
	let ABOVE_CONTROL_RANGE_TEXT;
	let MEAN_OF_ALL_TEXT;
	let MARKER_HASH_ARRAY = [];
	const AUTOMATED_TEST_RESULT_COLOR = '#FFFFFF';
	const applicableAutomatedTestLevels = [AUTOMATED_TEST_LEVELS_INDIVIDUAL, AUTOMATED_TEST_LEVELS_COMBINED, AUTOMATED_TEST_LEVELS_ADDONEXCEPTION];
	let AUTOMATED_TEST_RESULT_TEXT;

	const DEFAULT_SORT_INFO = {
		priority: 0,
		isReversible: true,
	};

	const ABOVE_RANGE_SORT_INFO = {
		priority: 60,
		isReversible: false,
	};

	const UPPER_RANGE_SORT_INFO = {
		priority: 40,
		isReversible: false,
	};

	const LOWER_RANGE_SORT_INFO = {
		priority: 20,
		isReversible: false,
	};

	const MEAN_OF_ALL_SORT_INFO = {
		priority: 10,
		isReversible: false,
	};

	const SORT_MODES = {
		PERCENT: 'percent',
		ALPHA: 'alpha',
	};

	let prefixes;

	const getSortInfo = function(line) {
		return line.sortInfo || DEFAULT_SORT_INFO;
	};

	const getThresholdColor = function(thresholdName) {
		return thresholdName.endsWith(PARETO_CRITICAL_LIMIT_COLOR_MATCH_KEY) ? PARETO_CRITICAL_LIMIT_COLOR : PARETO_CAUTINARY_LIMIT_COLOR;
	};

	const linesComparator = function(isAscOrder, a, b) {
		const sortFactor = isAscOrder ? 1 : -1;
		let aSortPriority = getSortInfo(a).priority;
		let bSortPriority = getSortInfo(b).priority;

		if (getSortInfo(a).isReversible) {
			aSortPriority = aSortPriority * sortFactor;
		}

		if (getSortInfo(b).isReversible) {
			bSortPriority = bSortPriority * sortFactor;
		}

		if (bSortPriority !== aSortPriority) {
			return bSortPriority - aSortPriority;
		}

		// if (a.description === b.description) {
		// 	if (a.name === b.name) {
		// 		return 0;
		// 	}

		// 	return (a.name < b.name ? -1 : 1) * sortFactor;
		// }

		return (a.name < b.name ? -1 : 1) * sortFactor;
	};

	const getPropertyNameFromHpath = function(hpath, isWeatherProperty) {
		let propertyName;

		for (let i = 0; i < prefixes.length; i++) {
			const prefix = prefixes[i];

			if (hpath.includes(prefix)) {
				if (isWeatherProperty) {
					propertyName = hpath;
				} else {
					propertyName = hpath.split(prefix)[1];
				}
				propertyName = propertyName.trim();
				break;
			}
		}

		if (!propertyName && !isWeatherProperty) {
			propertyName = hpath.includes(AT_SYMBOL) ? hpath : null;
		}

		const hasCleanPropertyName = propertyName && propertyName.includes(AT_SYMBOL);
		return hasCleanPropertyName ? propertyName : null;
	};

	const createEmptyChildComponents = function() {
		return {
			list: [],
			isAllChecked: true,
			isAllIntermediate: false,
		};
	};

	const byDisplayName = function(a, b) {
		return a.displayName > b.displayName;
	};

	const disableFilteredChartLines = function(childComponentsList, chartLegendToggleState) {
		childComponentsList.forEach(function(e) {
			if (!e.isIntermediate) {
				e.lines.forEach(function(line) {
					line.touched = true;
					line.isDisabledByLegendFilter = !e.isChecked && chartLegendToggleState === 'show';
					line.visible = e.isChecked;
				});
			}
		});
	};

	const setScopeChartData = function(scope, data, options) {
		const chartState = passedStateMap.get(scope);
		if (chartState && chartState.unoccupied !== undefined) {
			options.unoccupied = chartState.unoccupied;
		}
		const {dataFormattingService} = services.get(scope);
		let timeline = scope.chartObj.timeline || {};
		let range = scope.range || {};
		if (scope.chartObj) {
			if (!scope.isAppliedChartState && chartState && chartState.chartConfig) {
				let chartConfig = chartState.chartConfig;
				scope.chartConfig.setFromClone(chartConfig);
			}
			if (chartState) {
				[options, timeline, range] = applyPassedState({
					options,
					timeline,
					range,
					state: chartState,
					isAppliedChartState: scope.isAppliedChartState,
					dataFormattingService,
				});
				scope.chartObj.isSuppressionsEnabled = chartState.isSuppressionsEnabled;
				scope.isAppliedChartState = true;
			}

			scope.chartObj.chart = {data, options};

			scope.chartObj.timeline = timeline;

			// To tigger a watch fn in chart.js to rerender chart with updated data while moving prev / next timeline period using timeline Navigation
			scope.chartObj.timeline.isUpdatedByTimeLineNavigation = Math.random();

			if (range) {
				Object.assign(scope.range, range);
			}
			if (options.sortOrder && options.sortOrder !== scope.chartObj.paretoChartSortOrder) {
				scope.chartObj.paretoChartSortOrder = options.sortOrder;
			}
		}
	};

	const applyPassedState = function({options = {}, timeline, range, state, isAppliedChartState, dataFormattingService}) {
		const applyState = (source, key) => {
			if (source[key] && state[key]) {
				source[key] = source[key].map(option => {
					const foundState = state[key].find(stateRow => stateRow.hash === option.lineHash);

					if (foundState) {
						option.visible = foundState.visible;
						option.lineType = foundState.lineType;
						option.lineThickness = foundState.lineThickness;
						option.color = foundState.color || option.color;
						option.markerType = foundState.markerType;
						option.markerSize = foundState.markerSize;
					}

					return option;
				});
			} else if (state[key] && key === 'currentlyPulledSlice') {
				source[key] = state[key];
			}
		};
		const applySortOrder = (source, key) => {
			if (source[key] && state[key]) {
				source[key] = state[key];
			}
		};
		const applyBrushRange = (range, stateKey) => {
			// Convert dates, as dates in encoded charts state are kept in utc.
			const convertDate = date => {
				return dataFormattingService.getDateInUTC(date).format('x');
			};

			if (range && range.from && range.to && state[stateKey]) {
				let rangeFrom = parseInt(convertDate(range.from));
				let rangeTo = parseInt(convertDate(range.to));
				let stateFrom = parseInt(state[stateKey].from);
				let stateTo = parseInt(state[stateKey].to);

				if (rangeFrom !== stateFrom) {
					let diff = stateFrom - rangeFrom;
					if (diff > 0) {
						// Only apply if newFrom is later then left range
						range.from = range.from.add(diff, 'ms');
					}
				}
				if (rangeTo !== stateTo) {
					let diff = rangeTo - stateTo;
					if (diff > 0) {
						// Only apply if newTo is earlier then right range
						range.to = range.to.subtract(diff, 'ms');
					} else if (diff < 0) {
						// To update newTo to exact timeframe
						range.to = range.to.add(Math.abs(diff), 'ms');
					}
				}
			}
		};
		const applyCustomAxisRange = ({yAxis: sourceYAxes}) => {
			if (sourceYAxes && state.yAxes) {
				sourceYAxes.forEach(sourceYAxisItem => {
					const stateYAxisItem = state.yAxes.find(stateYAxisItem => stateYAxisItem.chartAxisId === sourceYAxisItem.chartAxisId);
					sourceYAxisItem.customRange = stateYAxisItem && stateYAxisItem.calculatedRange;
				});
			}
		};
		applyState(options, 'currentlyPulledSlice');
		applyState(options, 'lines');
		applyState(options, 'meanLines');
		applyState(options, 'thresholds');
		applySortOrder(options, 'sortOrder');
		applyState(timeline, 'lanes');
		applyCustomAxisRange(options);
		!isAppliedChartState && applyBrushRange(range, 'range');
		return [options, timeline, range];
	};
	const sortChartOptionsLines = function(chartOptions, propertyLineHashToChartData, isChartLegendHasAscOrder) {
		const comparator = linesComparator.bind(null, isChartLegendHasAscOrder);
		chartOptions.lines.sort(comparator);

		const reorderedChartData = new Array(chartOptions.lines.length);

		chartOptions.lines.forEach(function(line, i) {
			reorderedChartData[i] = propertyLineHashToChartData[line.lineHash];
		});

		chartOptions.isChartLinesSortedAsc = isChartLegendHasAscOrder;
		return reorderedChartData;
	};

	const getPropertyEnumerationValue = function(propertyList, propertyName, helpers) {
		let property = null;
		propertyName = propertyName.split(propertyName.indexOf(AT_SYMBOL) !== -1 ? AT_SYMBOL : '~')[1];
		propertyList.some(item => {
			if (item.propertyName === propertyName && helpers.objectHasProperty(item, 'propertyAttribute.enumerationGroupNameAndName')) {
				property = item.propertyAttribute.enumerationGroupNameAndName;
				return true;
			}
		});
		return property;
	};

	const getColorByPropertyName = (function() {
		let cache = {};

		return function(propertyList, propertyName) {
			if (!cache[propertyName]) {
				if (propertyName === PERFORMANCE_CURVE) {
					cache[propertyName] = PURPLE_COLOR;
				} else {
					const prop = propertyList.find(item => item.propertyName === propertyName);

					if (prop && prop.propertyAttribute && prop.propertyAttribute.colorValue) {
						cache[propertyName] = `#${prop.propertyAttribute.colorValue.toLowerCase()}`;
					}
				}
			}

			return cache[propertyName];
		};
	})();

	const storeHiddenProperties = function(actualArray, hiddenArray, checkedPropertyName = '') {
		(actualArray || []).forEach(function(actualProp) {
			const uniquePropName = actualProp.name + actualProp.description;
			let hiddenIndex;

			if (actualProp.visible) {
				hiddenIndex = hiddenArray.indexOf(uniquePropName);
				hiddenIndex !== -1 && hiddenArray.splice(hiddenIndex);
			} else {
				checkedPropertyName ? actualProp[checkedPropertyName] && hiddenArray.push(uniquePropName) : hiddenArray.push(uniquePropName);
			}
		});
	};

	const parseHpathString = function(str, LITERAL) {
		let symbols = [AT_SYMBOL, '~', '^'];

		function hpathToPropertyInfo(propertyHpath) {
			const isWeatherProperty = propertyHpath.includes(LITERAL.WEATHER_PREFIX) || propertyHpath.includes(LITERAL.WEATHER_PREFIX_OLD);
			let propertyName = '';

			for (let i = 0; i < symbols.length; i++) {
				if (propertyHpath.includes(symbols[i])) {
					propertyName = propertyHpath.split(symbols[i])[1].trim();
					break;
				}
			}

			return {
				name: propertyName,
				isWeatherProperty: isWeatherProperty,
				hpath: propertyHpath,
			};
		}

		return str.split(',').map(hpathToPropertyInfo);
	};

	const checkIsAllAxisesHasSameTisObjectType = function(axises) {
		let tisObjectTypeGroupName = null;

		axises.forEach(function(axis) {
			if (axis.tisObjectType && !tisObjectTypeGroupName) {
				tisObjectTypeGroupName = axis.tisObjectType.tisObjectTypeGroupName;
			} else if (axis.tisObjectType && axis.tisObjectType.tisObjectTypeGroupName !== tisObjectTypeGroupName) {
				throw Error('Chart was not rendered because tis object type for chart axes is not unique!');
			}
		});
	};

	const getTisObjectTypeFromAxises = function(axises) {
		const axisTisObjectType = axises.find(function(axis) {
			// don't check if axis is custom ( Add properties to chart )
			return !axis.isCustomAxis && axis.tisObjectType && axis.tisObjectType.tisObjectTypeGroupName;
		});
		if (axisTisObjectType) {
			return axisTisObjectType.tisObjectType.tisObjectTypeGroupName;
		} else {
			return null;
		}
	};

	const fetchDataForTisObjectFromDataResult = function(data, tisObjectId) {
		let tisObjectDataList = data.tisObjectDataList;

		const found = tisObjectDataList.find(tisObjectData => {
			return tisObjectData.tisObjectId && tisObjectData.tisObjectId === tisObjectId;
		});

		return found || tisObjectDataList[0];
	};

	const filterColumnErrors = function(column) {
		if (column.errorDetailsResource) {
			column.values = [];
		} else {
			column.values = column.values.filter(value => !value.errorDetails || value.explicitNull || value.missingUpdate);
		}
	};

	const mergeOccupancyData = function([yAxis, occupancyAxis]) {
		for (let i = 0; i < yAxis.values.length; i++) {
			yAxis.values[i].unoccupied = occupancyAxis.values[i] && occupancyAxis.values[i].value === 'Unoccupied';
		}

		return yAxis;
	};

	const setAxisSideAndOrientation = function(axis, index, array) {
		axis.orientation = index === 0 || index === 3 ? 'left' : 'right';
		axis.side = index % 2 === 1 ? 'right' : 'left';
		axis.count = getCount();

		/**
		 * Get count of the axis.
		 *
		 * Order of axes must be the following:
		 *  - for 3 Axes
		 *  Y1  Y3  CHART   Y2
		 *  1   2           1
		 *
		 *  - for 4 Axes
		 *  Y1  Y3  CHART   Y4  Y2
		 *  1   2           1   2
		 */
		function getCount() {
			const isEven = index % 2 === 0;

			if (array.length <= 2) return '';

			if (array.length === 3) return index < 2 ? 1 : 2;

			return index < 2 ? (isEven ? 1 : 2) : isEven ? 2 : 1;
		}
	};

	const changeOccupancyAxesName = function(chartAxis) {
		// :todo That is a hack from legacy code. New axis name helps to achieve some special order
		// after sorting. Too fragile and prone to bugs code. Respect the guy who will dare to refactor it.
		if (chartAxis.axisName === 'Occupancy Active') {
			chartAxis.axisName = OCCUPANCY_AXIS_NAME;
		}
	};

	const isCartesianAxis = function(axisName) {
		// is x n-th or y n-th axis
		return ![TIME_LINE_AXIS_NAME, OCCUPANCY_AXIS_NAME, LIMITS_AXIS_NAME].includes(axisName);
	};

	const isYAxis = function(axisName) {
		return axisName.startsWith('y');
	};

	const fillChartConfigWithOriginalChartProps = function(chartConfig, lines) {
		const originalProps = {};

		lines.forEach(({tisObjectType, propertyName, tisObjectId}) => {
			if (!originalProps[tisObjectType]) {
				originalProps[tisObjectType] = {};
			}

			if (!originalProps[tisObjectType][tisObjectId]) {
				originalProps[tisObjectType][tisObjectId] = [];
			}

			originalProps[tisObjectType][tisObjectId].push({propertyName});
		});

		chartConfig.setOriginalProps(originalProps);
	};

	const loopThroughTisobjectsHierarchy = function(data, func, obj) {
		data.columns.forEach(column => func(column, obj));

		(data.parameters || []).forEach(parameter => func(parameter, obj));

		(data.relatedDataEntries || []).forEach(relatedDataEntry => {
			relatedDataEntry.dataEntries.forEach(entry => {
				loopThroughTisobjectsHierarchy(entry, func, obj);
			});
		});
	};

	const loopThroughTisobjectDataList = function(dataList, func, obj = {}) {
		dataList.forEach(data => loopThroughTisobjectsHierarchy(data, func, obj));
	};

	const fillPropertiesByTisObjectCount = function(column, obj) {
		obj[column.name] = (obj[column.name] || 0) + 1;
	};

	const addTisObjectsCountToColumn = function(column, obj) {
		column.tisObjectsCount = obj[column.name];
	};

	const createChildComponentName = function(tisObjectType, name) {
		if (tisObjectType === 'Circuit') {
			name = name.replace('CKT', '');
		}

		return `${tisObjectType} ${name}`;
	};

	const isFalsyValue = function(value) {
		return [0, false, '0', 'false', 'False', 'Off', 'No'].indexOf(value) !== -1;
	};

	class HpathMapManager {
		constructor(controller) {
			theMapOfHpathMap.set(controller, new Map());
			this.controller = controller;
		}

		set(type, hpath) {
			let hpathMap = theMapOfHpathMap.get(this.controller);
			hpathMap.set(type, hpath);
		}

		get(type) {
			let hpathMap = theMapOfHpathMap.get(this.controller);
			return hpathMap.get(type);
		}

		clear() {
			let hpathMap = theMapOfHpathMap.get(this.controller);
			hpathMap.clear();
		}

		item() {
			return theMapOfHpathMap.get(this.controller);
		}
	}

	class TisObjectTypeToTisObjectIdsManager {
		constructor(controller) {
			theMapOfTisObjectTypeToTisObjectIds.set(controller, new Map());
			this.controller = controller;
		}

		set(type, ids) {
			let tisObjectTypeToTisObjectIds = theMapOfTisObjectTypeToTisObjectIds.get(this.controller);
			tisObjectTypeToTisObjectIds.set(type, ids);
		}

		get(type) {
			let tisObjectTypeToTisObjectIds = theMapOfTisObjectTypeToTisObjectIds.get(this.controller);
			return tisObjectTypeToTisObjectIds.get(type);
		}

		clear() {
			let tisObjectTypeToTisObjectIds = theMapOfTisObjectTypeToTisObjectIds.get(this.controller);
			tisObjectTypeToTisObjectIds.clear();
		}

		item() {
			return theMapOfTisObjectTypeToTisObjectIds.get(this.controller);
		}
	}

	class ChartComponentController {
		// const isFacilityChart = $route.current.$$route.isFacilityChart;
		// const chartConfig = isFacilityChart ? null : helpers.findInScopeChain($scope, 'chartConfig');
		// var isIncludeDataWithErrors = $routeParams.includeDataWithErrors === 'true';
		// $rootScope.embedded = $routeParams.embedded || false;

		constructor(
			$scope,
			rawDataService,
			rawDataServerlessService,
			dataFormattingService,
			tisObjectService,
			locationEquipmentService,
			propertyStoreService,
			chartService,
			translateService,
			serviceAdvisoryService,
			colorService,
			characteristicCurveService,
			suppressionDataService,
			$filter,
			$timeout,
			helpers,
			modalHelperService,
			LITERAL,
			CHART_TYPE,
			DESIGN_CURVES_COMPONENT_FILTER,
			DEFAULTS,
			$q,
			$translate,
			usageTrackingService,
			instanceBasedChartService,
			configService,
			ENVIRONMENT
		) {
			prefixes = LITERAL.HPATH_PREFIXES;
			LOWER_CONTROL_RANGE_TEXT = translateService.translateProperty('LOWER_CONTROL_RANGE');
			UPPER_CONTROL_RANGE_TEXT = translateService.translateProperty('UPPER_CONTROL_RANGE');
			ABOVE_CONTROL_RANGE_TEXT = translateService.translateProperty('ABOVE_CONTROL_RANGE');
			MEAN_OF_ALL_TEXT = translateService.translateProperty('MeanOfAll');
			AUTOMATED_TEST_RESULT_TEXT = $filter('translate')('AUTOMATED_TEST_RESULT');

			$scope.environment = configService.getEnvironmentType();
			$scope.isProdEnv = $scope.environment === ENVIRONMENT.PROD;
			if (!$scope.isProdEnv) rawDataService = rawDataServerlessService;

			services.set(this, {
				$scope,
				$filter,
				$timeout,
				helpers,
				chartService,
				propertyStoreService,
				translateService,
				serviceAdvisoryService,
				locationEquipmentService,
				tisObjectService,
				dataFormattingService,
				rawDataService,
				characteristicCurveService,
				suppressionDataService,
				modalHelperService,
				LITERAL,
				CHART_TYPE,
				DESIGN_CURVES_COMPONENT_FILTER,
				DEFAULTS,
				colorService,
				$q,
				$translate,
				instanceBasedChartService,
			});

			propertyLineHashToChartDataMap.set(this, {});
			chartLegendColorGenerator.set(this, colorService.generator());
			timeLineColorGenerator.set(this, colorService.generator());
			propertiesMap.set(this, []);

			legendReadyMap.set(this, false);

			originalAxesUomsMap.set(this, []);
			chartDataMap.set(this, []);
			propertiesListMap.set(this, []);
			exceptionPropertiesMap.set(this, []);
			exceptionMap.set(this, []);
			serviceAdvisoryTypeIdsMap.set(this, []);
			linePropertyToAxisMap.set(this, {});
			analyticModelIdsMap.set(this, new Set());
			suppressionAnalyticParametersMap.set(this, []);
			propertyEnumerationsToTypeMap.set(this, new Map());
			locationTisObjectsMap.set(this, {});
			hiddenPropertiesMap.set(this, {
				lines: [],
				lanes: [],
			});
			disabledPropertiesMap.set(this, {
				lines: [],
				lanes: [],
			});
			let chartOptions = {isChartLinesSortedAsc: null};
			chartOptionsMap.set(this, chartOptions);
			existingChartDataCallMap.set(this, new Map());
			selectedChartDetailsMap.set(this, null);

			this.isOneYAxis = undefined;
			this.sortModes = SORT_MODES;
			this.linesPreservedColors = {};
			this.timelineLanesPreservedColors = {};

			this.$onInit = () => {
				this.hpathMap = new HpathMapManager(this);
				this.tisObjectTypeToTisObjectIds = new TisObjectTypeToTisObjectIdsManager(this);
				// Add property to chart - store added properties
				this.addPropertiesToChartMap = new TisObjectTypeToTisObjectIdsManager(this);
				this.loadingPromise = [];
				this.legendSettings = {
					isChartLegendHasAscOrder: true,
					chartLegendToggleState: 'disabled',
				};

				this.childComponents = createEmptyChildComponents();

				if (this.chartObj) {
					this.chartObj.isSuppressionsEnabled = false;
					this.chartObj.timeline = {
						data: [],
						lanes: [],
						exportData: [],
					};
				}
				passedStateMap.set(this, this.passedState);

				setScopeChartData(this, [], {});
				this.sortBy = SORT_MODES.PERCENT;
				this.redrawSortBy();

				if (this.eventObject) {
					const listeners = eventListeners
						.set(this, {
							addEditProperties: this.editProperties.bind(this),
							removeAllAddedProperties: this.removeAllAddedProperties.bind(this),
							reloadChart: this.reload.bind(this),
							applySortOrder: this.setSort.bind(this),
							chartRedraw: () => this.render(),
							updateChartRange: () => this.render(),
							clearChart: () => {
								this.storeHiddenLinesAndLanes();
								this.clearChartAndTimeline();

								// :todo sends message to the child directive chart.js better to use EventEmitter object to send such messages
								$scope.$broadcast('hideChart');
							},
						})
						.get(this);

					Object.keys(listeners).forEach(key => this.eventObject.on(key, listeners[key]));
				}

				this.$onDestroy = () => {
					this.isFinished = true;
					if (this.eventObject && typeof this.eventObject.off === 'function') {
						const listeners = eventListeners.get(this);
						Object.keys(listeners).forEach(key => this.eventObject.off(key, listeners[key]));
					}
				};

				this.trackEvent = usageTrackingService.trackEventByCategory(EQUIPMENT_PERFORMANCE_CHARTS_CATEGORY_NAME);
			};
		}

		getLineItemHash(propertyName, nullableData) {
			const tisObjectData = nullableData || {};
			let lineHash = this.chartIndex + propertyName + '|' + (tisObjectData.id || tisObjectData.tisObjectName) + '|' + tisObjectData.tisObjectId;
			return lineHash.replace(/\W+/g, '');
		}

		getMarkerType(hash) {
			if (!MARKER_HASH_ARRAY[hash]) {
				MARKER_HASH_ARRAY[hash] = 0;
			}
			const markerIndex = MARKER_HASH_ARRAY[hash] % MARKER_TYPES.length;
			MARKER_HASH_ARRAY[hash]++;
			return MARKER_TYPES[markerIndex];
		}

		fitDataIntoWindow() {
			if (!this.checkIsAllLinesHidden()) {
				this.redrawChartLines.bind(this)();

				this.trackEvent(EQUIPMENT_PERFORMANCE_CHARTS_EVENTS.REFRESH_Y_AXIS, {
					[PRIMARY_OFFERING]: PRIMARY_OFFERINGS.IS,
					[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_NAME]: this.chartObj.selectedChart.title,
				});
			}
		}

		checkIsAllLinesHidden() {
			const {helpers} = services.get(this);
			const lines = helpers.getPropertyByPath(this, 'chartObj.chart.options.lines') || [];
			return lines.length && lines.every(line => !line.visible);
		}

		redrawSortBy() {
			const chartOptions = chartOptionsMap.get(this);
			chartOptions.sortBy = this.sortBy;
			this.redrawChartLines();
		}

		setSortBy(sort) {
			this.sortBy = sort;
			this.redrawSortBy();
		}

		isRequestedChartType(chartType) {
			const chartOptions = chartOptionsMap.get(this);
			return chartOptions.chartType === chartType;
		}

		redrawChartLines() {
			let {CHART_TYPE} = services.get(this);

			if (this.isRequestedChartType(CHART_TYPE.LINE_WITH_BINARY_STATES)) {
				this.calculateBinaryValues();
			}
			this.eventObject.emit('redrawChartLines');
		}

		editProperties() {
			const {modalHelperService} = services.get(this);
			this.isChartWithAddPropsSupport &&
				modalHelperService.open({
					templateUrl: 'components/chart/edit-properties-modal.html',
					controller: 'EditPropertiesCtrl',
					backdrop: 'static',
					windowClass: 'edit-properties-dialog fixed full-height',
					resolve: {
						data: () => {
							const originalAxesUoms = originalAxesUomsMap.get(this);
							return {
								locationData: this.location,
								defaultSelection: this.getSelectedTisObj(),
								equipmentsData: [this.getSelectedTisObj()],
								isChart: true,
								isCustomPropertiesAdded: this.isCustomPropertiesAdded(),
								chartConfig: this.chartConfig,
								originalAxesUoms: originalAxesUoms,
								timelines: this.chartObj.timeline.lanes,
								isFacilityChartOnly: this.isFacilityChart && !this.isParetoChart,
							};
						},
						onApplyHandler: () => selectedProperties => {
							this.trackEvent(EQUIPMENT_PERFORMANCE_CHARTS_EVENTS.ADD_PROPERTY, {
								[PRIMARY_OFFERING]: PRIMARY_OFFERINGS.IS,
								[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_NAME]: this.chartObj.selectedChart.title,
								[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.PROPERTIES]: selectedProperties,
							});
						},
					},
				});
		}

		formatChart() {
			const {modalHelperService} = services.get(this);
			const chartOptions = chartOptionsMap.get(this);
			modalHelperService.open({
				templateUrl: 'components/chart/format-chart.html',
				controller: 'FormatChartCtrl',
				backdrop: 'static',
				windowClass: 'format-chart flex vertical',
				resolve: {
					data: () => {
						return {
							lines: chartOptions.lines,
							eventObject: this.eventObject,
							chartConfig: this.chartConfig,
							yAxes: chartOptions.yAxis,
						};
					},
					onApplyHandler: () => settings => {
						this.trackEvent(EQUIPMENT_PERFORMANCE_CHARTS_EVENTS.FORMAT_CHART, {
							[PRIMARY_OFFERING]: PRIMARY_OFFERINGS.IS,
							[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_NAME]: this.chartObj.selectedChart.title,
							[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHANGED_FIELDS]: getChangedFieldsOnFormatChart(settings),
						});
					},
				},
			});
		}

		getSelectedTisObj() {
			return this.chartObj && this.chartObj.selectedEquipment ? this.chartObj.selectedEquipment : {};
		}

		isCustomPropertiesAdded() {
			if (this.chartConfig && typeof this.chartConfig.hasCustomProps === 'function') {
				return this.chartConfig.hasCustomProps();
			}
		}

		openFilterByComponentSetup() {
			const {modalHelperService, $filter} = services.get(this);
			// :todo recheck I added list.length
			let checkedLen = this.childComponents.list.length;

			this.childComponents.list.forEach(entry => {
				let visibleLinesAmount = entry.lines.length;

				entry.lines.forEach(line => {
					if (!line.visible) {
						visibleLinesAmount--;
					}
				});

				entry.isIntermediate = visibleLinesAmount !== 0 && visibleLinesAmount !== entry.lines.length;
				entry.isChecked = entry.isIntermediate ? false : visibleLinesAmount !== 0;

				if (!entry.isChecked) {
					checkedLen--;
				}
			});

			this.childComponents.isAllChecked = checkedLen === this.childComponents.list.length;
			this.childComponents.isAllIntermediate = this.childComponents.isAllChecked ? false : checkedLen !== 0;

			this.childComponents.list.sort(byDisplayName);

			let modal = modalHelperService.open({
				templateUrl: 'components/chart/filter-components-modal.html',
				controller: 'FilterComponentsModalCtrl as $modal',
				backdrop: 'static',
				windowClass: 'small',
				resolve: {
					modalData: () => {
						return {
							childComponents: this.childComponents,
							applyFilter: this.applyFilter.bind(this),
						};
					},
				},
			});
		}

		applyFilter(shouldWeCloseModal, instance) {
			disableFilteredChartLines(this.childComponents.list, this.legendSettings.chartLegendToggleState);
			this.eventObject && this.eventObject.emit('updateChartLegendToggleState');

			if (shouldWeCloseModal && typeof instance === 'object') {
				instance.close();
			}

			this.redrawChartLines();
		}

		sortChartLegend() {
			this.sortChartDataAndLines();
		}

		sortChartDataAndLines() {
			const chartOptions = chartOptionsMap.get(this);
			const propertyLineHashToChartData = propertyLineHashToChartDataMap.get(this);
			const chartData = sortChartOptionsLines(chartOptions, propertyLineHashToChartData, this.legendSettings.isChartLegendHasAscOrder);
			chartDataMap.set(this, chartData);
			setScopeChartData(this, chartData, chartOptions);
			if (this.chartConfig && this.chartConfig.formattingProps) {
				this.applyCustomChartFormatting(); // Applies custom formatting for add/remove property to chart cases.
			}
		}

		applyCustomChartFormatting() {
			const chartOptions = chartOptionsMap.get(this);
			const lines = chartOptions.lines;
			const customFormattedLines = this.chartConfig.getFormattingProps();
			Object.keys(customFormattedLines).forEach(lineHash => {
				const lineFormatting = customFormattedLines[lineHash];
				let line = lines.find(line => line.lineHash === lineHash);
				if (line) {
					line = Object.assign(line, lineFormatting);
				} else {
					this.chartConfig.clearFormattingFor(lineHash);
				}
			});
		}
		removeAllAddedProperties() {
			this.chartConfig.clear();
			this.reload();

			this.trackEvent(EQUIPMENT_PERFORMANCE_CHARTS_EVENTS.REMOVE_ADDED_PROPERTIES, {
				[PRIMARY_OFFERING]: PRIMARY_OFFERINGS.IS,
				[EQUIPMENT_PERFORMANCE_CHARTS_PROPERTIES.CHART_NAME]: this.chartObj.selectedChart.title,
			});
		}

		storeHiddenLinesAndLanes() {
			const hiddenProperties = hiddenPropertiesMap.get(this);
			const disabledProperties = disabledPropertiesMap.get(this);
			storeHiddenProperties(this.chartObj.timeline.lanes, hiddenProperties.lanes);
			storeHiddenProperties(this.chartObj.chart.options.lines, hiddenProperties.lines);
			storeHiddenProperties(this.chartObj.chart.options.lanes, disabledProperties.lanes, 'isDisabledByLegendFilter');
			storeHiddenProperties(this.chartObj.chart.options.lines, disabledProperties.lines, 'isDisabledByLegendFilter');
		}

		clearChartMetadata(editProperties) {
			const propertyEnumerationsToType = propertyEnumerationsToTypeMap.get(this);
			propertyEnumerationsToType.clear();

			propertiesMap.set(this, []);
			exceptionPropertiesMap.set(this, []);
			exceptionMap.set(this, []);
			serviceAdvisoryTypeIdsMap.set(this, []);

			const analyticModelIds = analyticModelIdsMap.get(this);
			analyticModelIds.clear();

			// Clear all stored exceptions
			allExceptionsName.clear();

			suppressionAnalyticParametersMap.set(this, []);
			linePropertyToAxisMap.set(this, {});
			legendReadyMap.set(this, false);

			const chartOptions = chartOptionsMap.get(this);
			const linesPreservedColors = [];

			if (editProperties) {
				if (chartOptions.lines && chartOptions.lines.length) {
					chartOptions.lines.forEach(line => {
						this.linesPreservedColors[line.lineHash] = line.color;
						linesPreservedColors.push(line.color);
					});
				}
			}
			this.chartObj.timeline.lanes = [];

			chartOptions.lines = [];
			chartOptions.meanLines = [];
			chartOptions.thresholds = [];
			chartOptions.controlRanges = {
				low: DEFAULT_LOWER_RANGE_END,
				high: DEFAULT_UPPER_RANGE_END,
			};
			chartOptions.isChartLinesSortedAsc = null;

			this.loadingPromise = [];

			this.childComponents = createEmptyChildComponents();

			timeLineColorGenerator.get(this).reset();
			chartLegendColorGenerator.get(this).reset();
			if (linesPreservedColors && linesPreservedColors.length) {
				chartLegendColorGenerator.get(this).occupy(linesPreservedColors);
			}
		}

		clearChartAndTimeline() {
			this.chartObj.chart.data = [];
			this.chartObj.chart.options = {};
			this.chartObj.timeline.exportData = [];
			this.chartObj.timeline.data = [];
			chartDataMap.set(this, []);
			propertyLineHashToChartDataMap.set(this, {});
			this.loadingPromise = [];
		}

		errorCallback() {
			const {$scope} = services.get(this);
			// :todo sends message to the child directive chart.js better to use EventEmitter object to send such messages
			$scope.$broadcast('hideChart');
		}

		addThresholdDefinitionToChartOption(threshold, tisObjectTypeName, chartOptions) {
			const {translateService} = services.get(this);
			chartOptions.thresholds.push({
				name: threshold.name,
				lineHash: threshold.name,
				unitOfMeasure: threshold.unitOfMeasure,
				sourceUnitOfMeasure: threshold.sourceUnitOfMeasure,
				color: getThresholdColor(threshold.name),
				displayName: [threshold.displayName], // translateService.translateThreshold(threshold.name, tisObjectTypeName),
				visible: threshold.visible || threshold.visible === undefined,
			});
		}

		extractThresholdValues(aggregatedServiceAdvisoryTimestamp, tisObjectId, allThresholds, tisObjectTypeName, chartOptions) {
			let thresholds = allThresholds[tisObjectId] || null;
			let result = {};
			if (thresholds) {
				thresholds.forEach(threshold => {
					if (!chartOptions.thresholds.some(item => item.name === threshold.name)) {
						this.addThresholdDefinitionToChartOption(threshold, tisObjectTypeName, chartOptions);
					}
					// Yes, not optimal performance-wise, but i don't see a way to reliably bind two API
					// response's values together.
					let matchingThreshold = threshold.values.find(element => {
						return aggregatedServiceAdvisoryTimestamp === element.timestamp;
					});
					result[threshold.name] = {
						sourceUnitOfMeasure: threshold.sourceUnitOfMeasure,
						unitOfMeasure: threshold.unitOfMeasure,
						value: matchingThreshold ? +matchingThreshold.value : null,
					};
				});
			}
			return result;
		}

		handleParetoChartResponses(chartInfo, responses, emitRenderChartEvent = false) {
			const {$timeout, $filter, propertyStoreService} = services.get(this);
			const [locationData, propertiesByType, analyticThresholds] = responses;
			const hpath = chartInfo.tisObjectType.tisObjectTypeGroupName + AT_SYMBOL + chartInfo.analyticResultPropertyName;
			const propertyInfo = propertyStoreService.getByName(hpath);
			const equipmentList = locationData.tisObjectList;
			const [equipmentType] = equipmentList;
			const equipmentTypeName = $filter('translate')('EQUIPMENT_TYPE_NAME_TABLE.' + equipmentType.tisObjectType.tisObjectTypeGroupName);
			const chartData = [];
			this.chartObj.paretoChartSortOrder = this.chartObj.paretoChartSortOrder || PARETO_HIGH_TO_LOW_SORT_KEY;

			let chartOptions = {
				lines: this.chartObj.chart.options.lines || [],
				sortOrder: this.chartObj.paretoChartSortOrder,
				locationId: this.locationId,
				chartId: chartInfo.chartId,
				chartType: chartInfo.chartTypeName,
				equipmentTypeName: equipmentTypeName,
				propertyInfo: propertyInfo,
				thresholds: this.chartObj.chart.options.thresholds || [],
			};

			equipmentList.forEach(equipment => {
				const {tisObjectName, locationId, tisObjectId} = equipment;
				let analyticThresholdVals = analyticThresholds.values[tisObjectId];

				analyticThresholdVals &&
					analyticThresholdVals.forEach(val => {
						chartData.push({
							timestamp: this.stripTz(val.timestamp),
							equipmentName: tisObjectName,
							location: locationData.facilities.find(f => f.locationId === locationId),
							equipmentId: tisObjectId,
							valueAtMaxDeviancePerDay: _get(val, 'values'),
							thresholds: this.extractThresholdValues(
								val.timestamp,
								tisObjectId,
								analyticThresholds.threshold,
								equipmentType.tisObjectType.tisObjectTypeGroupName,
								chartOptions
							),
						});
					});
			});

			!chartOptions.lines.length &&
				chartOptions.lines.push({
					name: AUTOMATED_TEST_RESULT_TEXT,
					lineHash: AUTOMATED_TEST_RESULT_TEXT,
					visible: chartData.length > 0,
					color: AUTOMATED_TEST_RESULT_COLOR,
					unitOfMeasure: chartOptions.propertyInfo.unitOfMeasure,
				});

			chartOptionsMap.set(this, chartOptions);
			setScopeChartData(this, chartData, chartOptions);
			emitRenderChartEvent && $timeout(() => this.eventObject.emit('renderChart'));
		}

		loadParetoChartMetadata(chartInfo) {
			const {propertyStoreService, locationEquipmentService, serviceAdvisoryService, $q} = services.get(this);
			this.clearChartMetadata();
			this.clearChartAndTimeline();
			this.loadingPromise = [];

			const locationIds = this.chartObj.selectedFacilities.map(facility => facility.locationId);
			const propertiesByTypePromise = propertyStoreService.getPropertiesByType(chartInfo.tisObjectType.tisObjectTypeGroupNumber);
			const locationEquipmentPromise = $q
				.all(locationIds.map(locationId => locationEquipmentService.getLocationObjectsList(locationId, chartInfo.tisObjectType.tisObjectTypeGroupName)))
				.then(data => {
					return data.reduce(
						(result, value, index) => {
							value.tisObjectList.forEach(item => {
								item.locationId = locationIds[index];
							});
							result.tisObjectList = result.tisObjectList.concat(value.tisObjectList);
							return result;
						},
						{tisObjectList: [], facilities: this.chartObj.selectedFacilities}
					);
				});
			const analyticThresholdPromise = serviceAdvisoryService.getAnalyticThresholdName(chartInfo.analyticThresholdId);

			const promise = $q
				.all([locationEquipmentPromise, propertiesByTypePromise, analyticThresholdPromise])
				.then(responses => this.fetchThresholdData(responses, chartInfo))
				.then(responses => this.handleParetoChartResponses(chartInfo, responses, true));
			this.loadingPromise.push(promise);
			this.eventObject.emit('disableControls');
			promise
				.then(() => {
					this.eventObject.emit('enableControls');
				})
				.catch(err => {
					this.eventObject.emit('enableControls');
					throw err;
				});
			return promise;
		}

		onParetoChartSortChange(chartInfo, cachedResponses) {
			this.handleParetoChartResponses(chartInfo, cachedResponses, true);
		}

		getDisplayname(parameters, parametername) {
			return parameters.find(item => item.name === parametername).description;
		}

		sortThresholdData(thresholdData) {
			return thresholdData.sort((a, b) => {
				// Prioritize items with "red" in the displayName case-insensitively
				const hasRedA = _get(a, 'displayName', '')
					.toLowerCase()
					.includes('red');
				const hasRedB = _get(b, 'displayName', '')
					.toLowerCase()
					.includes('red');
				if (hasRedA && !hasRedB) {
					return 1; // Move a to the front
				} else if (!hasRedA && hasRedB) {
					return -1; // Move b to the front
				} else {
					// If both or neither have "red", maintain original order
					return 0;
				}
			});
		}

		fetchThresholdData(responses, chartInfo) {
			const {tisObjectService} = services.get(this);
			const {analyticResultPropertyName, performanceIndicatorPropertyName} = chartInfo;
			this.loadingPromise = [];
			const [locationData, propertiesByType, analyticThreshold] = responses;
			const hpath = `^${analyticThreshold.name}`;
			const parameters = analyticThreshold.parameters;
			const linesToRemove = ['upperboundary', 'lowerboundary'];
			const tisObjectIds = locationData.tisObjectList.map(tisObject => tisObject.tisObjectId);

			const IDS_PER_REQUEST_LIMIT = 10;
			const promises = [];

			while (tisObjectIds.length) {
				const params = {
					ids: tisObjectIds.splice(0, IDS_PER_REQUEST_LIMIT),
					hpath: `${hpath},@${analyticResultPropertyName},@Daily${performanceIndicatorPropertyName}`,
					from: this.range.from,
					to: moment(this.range.to).subtract(1, 'minute'),
					interval: 'PT24h',
					timeZone: this.range.timeZone,
					isPareto: true,
				};

				promises.push(tisObjectService.getAllValuesByIds(params));
			}
			this.loadingPromise.push(...promises);
			return Promise.allSettled(promises).then(results => {
				const accum = {
					tisObjectDataList: [],
				};
				results.forEach(result => {
					if (result.status === 'fulfilled') {
						const item = result.value;
						accum.tisObjectDataList = [...accum.tisObjectDataList, ...item.tisObjectDataList];
					} else {
						// fail scenarios to be handled.
					}
				});

				let thresholds = accum.tisObjectDataList.reduce(
					(accum, item) => {
						let resultingThresholds = [];
						let resultingValues = [];

						item.tisObjectId &&
							item.thresholds &&
							item.thresholds[0] &&
							item.thresholds[0].thresholdParameters &&
							item.thresholds[0].thresholdParameters.length &&
							item.thresholds[0].thresholdParameters.forEach(item => {
								if (!linesToRemove.some(term => item.name.toLowerCase().includes(term))) {
									let displayName = this.getDisplayname(parameters, item.name);
									displayName = displayName.replace('Yellow', 'Cautionary').replace('Red', 'Critical');

									resultingThresholds.push({
										displayName: displayName,
										name: item.name,
										sourceUnitOfMeasure: item.sourceUnitOfMeasure,
										unitOfMeasure: item.unitOfMeasure,
										values: item.values,
									});
								}
							});

						let column = item.tisObjectId && item.columns.find(column => _get(column, 'name', '') === analyticResultPropertyName);

						if (column && column.values) {
							resultingValues = column.values.filter(i => typeof i.value === 'number').map(i => ({
								name: column.name,
								sourceUnitOfMeasure: column.sourceUnitOfMeasure,
								timestamp: i.timestamp,
								unitOfMeasure: column.sourceUnitOfMeasure, // unitOfMeasure is not from API
								values: i.value,
							}));
						}
						if (resultingThresholds.length > 0) {
							accum.threshold[item.tisObjectId] = this.sortThresholdData(resultingThresholds);
						}
						if (resultingValues && resultingValues.length > 0) {
							accum.values[item.tisObjectId] = resultingValues;
						}

						return accum;
					},
					{threshold: [], values: []}
				);

				this.cachedParetoResponses = [locationData, propertiesByType, thresholds];
				return this.cachedParetoResponses;
			});
		}

		loadFacilityChartMetadata(chartId, editProperties) {
			const {chartService} = services.get(this);
			this.storeHiddenLinesAndLanes();
			this.clearChartMetadata(editProperties);
			this.clearChartAndTimeline();

			return chartService.getChartMetaData(chartId).then(this.loadChartsDataAndRender.bind(this));
		}

		/**
		 * Generates axis config for each hpath and stores it
		 *
		 * this will be used later by chartLegendCreateNewLine fn to create lines
		 *
		 * @param {*} axis
		 * @param {*} cartesianAxes
		 * @param {*} hpath
		 * @param {*} addedPropertiesUomWithHpath
		 */
		processChartAxis(axis, cartesianAxes, hpath, addedPropertiesUomWithHpath = {}) {
			const {LITERAL} = services.get(this);
			const linePropertyToAxis = linePropertyToAxisMap.get(this);

			let exceptionProperties = exceptionPropertiesMap.get(this);
			let properties = propertiesMap.get(this);
			let chartAxis = {
				uom: axis.unitOfMeasure,
				name: axis.axisName,
				chartAxisId: axis.axisName,
				// required below props on this name @ updateLegendForHpathBasedProperty fn
				axisName: axis.axisName,
				unitOfMeasure: axis.unitOfMeasure,
			};
			let axisHpath = axis.hpath;

			const customPropsUom = addedPropertiesUomWithHpath[axis.unitOfMeasure.name] || false;
			if (customPropsUom) {
				axisHpath = customPropsUom.reduce((av, hpath) => {
					if (av.split(',').includes(hpath)) return av;
					return av.concat(',', hpath);
				}, axisHpath || '');
			}

			if (axisHpath) {
				chartAxis.hpath = parseHpathString(axisHpath, LITERAL);
			} else {
				chartAxis.hpath = [];
			}

			chartAxis.hpath.forEach(property => {
				if (property.name.includes(LITERAL.EXCEPTION) && !exceptionProperties.includes(property.name)) {
					exceptionProperties.push(property.name);
				}

				hpath.push(property.hpath);
				properties.push(property.name);
				linePropertyToAxis[property.name] = chartAxis;
			});

			if (isCartesianAxis(chartAxis.name)) {
				cartesianAxes.push(chartAxis);
			}
		}

		getFacilityWeatherChartData() {
			const {$q, propertyStoreService, chartService} = services.get(this);
			const facilityHpath = this.hpathMap.get(FACILITY_KEY) || [];

			if (facilityHpath.length) {
				return propertyStoreService.getPropertiesByType(WEATHERGROUP_ID).then(propertiesData => {
					// Building propertiesList array
					let propertiesList = this.getPropertiesArrayFromObject(propertiesData);
					propertiesListMap.set(this, propertiesList);

					return chartService.getFacilityChartData(this.locationId, facilityHpath.join(','), this.range);
				});
			} else {
				return $q.when({});
			}
		}

		/**
		 * Get all equipments data with in a location based on tisObject Group type
		 *
		 * @param {*} params
		 * @returns promise
		 */
		getFacilityTisObjectsChartData(params) {
			const {tisObjectTypeGroupName} = params;
			const {propertyStoreService, locationEquipmentService} = services.get(this);
			const hpath = this.hpathMap.get(tisObjectTypeGroupName);

			let propertyTypeGroupId = propertyStoreService.getPropertyTypeGroupIdByName(tisObjectTypeGroupName);

			// get all properties associted to the tisObject group
			return propertyStoreService.getPropertiesByType(propertyTypeGroupId).then(propertiesData => {
				let propertiesList = this.getPropertiesArrayFromObject(propertiesData);
				propertiesListMap.set(this, propertiesList);

				// get all equipments details and filtered it by tisObjectGroup name
				return locationEquipmentService
					.getLocationObjectsList(this.locationId, tisObjectTypeGroupName)
					.then(this.loadTisObjectDataForFacilityChart.bind(this, hpath));
			});
		}

		/**
		 * Add Property To Chart : get data for all added properties to chart (custom properties)
		 *
		 * @param {*} addedPropertiesToChart
		 * @returns promise
		 */
		getFacilityTisObjectsChartDataForAddPropertiesToChart(addedPropertiesToChart = {}) {
			const {$q} = services.get(this);

			const promises = [];

			Object.keys(addedPropertiesToChart).forEach(equipmentType => {
				addedPropertiesToChart[equipmentType].map(({id, hpath}) => {
					const tisObjectList = [{tisObjectId: id}];
					promises.push(this.loadTisObjectDataForFacilityChart.bind(this)([...hpath], {tisObjectList}));
				});
			});

			return $q.all(promises).then(data => data);
		}

		overrideHpathForOriginalTisObjectType(chartConfig, axisList) {
			const axisNameToHpathSet = new Map();
			const yAxisList = axisList.filter(e => isCartesianAxis(e.axisName));

			yAxisList.forEach(axis => {
				const hpathSet = new Set((axis.hpath || '').split(',').filter(Boolean));
				axisNameToHpathSet.set(axis.axisName, hpathSet);
			});

			const tisObjectType = this.getSelectedTisObj().tisObjectType.tisObjectTypeGroupName;
			const checkedProperties = chartConfig.getPropertiesByType(tisObjectType) || [];

			checkedProperties.forEach(checkedProperty => {
				const axis = yAxisList.find(e => e.unitOfMeasure.uomId === checkedProperty.uom.uomId);
				axisNameToHpathSet.get(axis.axisName).add(checkedProperty.hpath);
			});

			yAxisList.forEach(axis => {
				axis.hpath = Array.from(axisNameToHpathSet.get(axis.axisName)).join(',');
			});
		}

		/**
		 * Update hpath of axies from facility chart
		 *
		 * adds hpath to axis (created due to a added property)
		 *
		 * @param {*} chartConfig
		 * @param {*} axisList
		 */
		overrideHpathForOriginalTisObjectTypeForAddPropertyToChart(chartConfig, axisList) {
			const axisNameToHpathSet = new Map();
			const yAxisList = axisList.filter(e => isCartesianAxis(e.axisName));

			yAxisList.forEach(axis => {
				const hpathSet = new Set((axis.hpath || '').split(',').filter(Boolean));
				axisNameToHpathSet.set(axis.axisName, hpathSet);
			});

			yAxisList.forEach(axis => {
				if (!axis.isCustomAxis) return;
				// map hpath to custom axis from chartConfig.customProps
				const tisObjectType = axis.tisObjectType.tisObjectTypeGroupName;
				const checkedProperties = chartConfig.getPropertiesByType(tisObjectType) || [];

				checkedProperties.forEach(checkedProperty => {
					const isSame = axis.unitOfMeasure.uomId === checkedProperty.uom.uomId;
					if (isSame) {
						axisNameToHpathSet.get(axis.axisName).add(checkedProperty.hpath);
					}
				});
			});

			yAxisList.forEach(axis => {
				if (!axis.isCustomAxis) return;
				axis.hpath = Array.from(axisNameToHpathSet.get(axis.axisName)).join(',');
			});
		}

		updateAxisList(axisList) {
			const equipmentTypeNames = this.chartConfig.getEquipmentTypes();
			const getYAxisList = () => axisList.filter(e => isCartesianAxis(e.axisName));
			const isNotExistingAxisUom = uom => getYAxisList().every(item => item.unitOfMeasure.name !== uom.name);
			equipmentTypeNames.forEach(equipmentTypeName => {
				const customAddedUoms = this.chartConfig.getCustomAddedUoms(equipmentTypeName);
				if (customAddedUoms.length) {
					let tisObjectTypeObj = {
						tisObjectTypeGroupName: equipmentTypeName,
					};
					customAddedUoms.filter(isNotExistingAxisUom).forEach(uom => {
						const yAxisCount = getYAxisList().length;
						if (yAxisCount < 4) {
							axisList.push({
								axisName: `y${yAxisCount + 1}`,
								unitOfMeasure: uom,
								tisObjectType: tisObjectTypeObj,
								hpath: '',
								isCustomAxis: true,
							});
						}
					});
				}
			});
		}

		loadChartsDataAndRender(chartMetadata) {
			const {$filter} = services.get(this);

			const chartOptions = chartOptionsMap.get(this);
			const cartesianAxes = [];
			const hpath = [];
			let facilityHpath = [];
			const addedPropertiesToChart = {};
			const addedPropertiesUomWithHpath = {};
			this.addPropertiesToChartMap.clear();

			// Add Property To Chart : Enable ADD PROPERTY TO CHART option if chart has support to edit
			this.setChartWithAddPropsSupport({value: true});

			// Add Property To Chart : originalAxesUoms is required for edit-properties-controller.js
			const axisList = $filter('orderBy')(chartMetadata.chartAxisList, 'axisName');
			let originalAxesUoms = axisList.filter(axis => isCartesianAxis(axis.axisName)).map(axis => axis.unitOfMeasure);
			originalAxesUomsMap.set(this, originalAxesUoms);

			// Add Property To Chart : collect details (equipment id, hpath & type) from properties if properties added to chart
			if (this.chartConfig.hasCustomProps()) {
				const addedPropertiesEquipmentTypes = this.chartConfig.getEquipmentTypes();

				addedPropertiesEquipmentTypes.map(equipmentType => {
					const selectedEquipments = this.chartConfig.customChartProps[equipmentType];

					if (!addedPropertiesToChart[equipmentType]) {
						addedPropertiesToChart[equipmentType] = [];
					}

					Object.keys(selectedEquipments).forEach(id => {
						const equipmentDetail = selectedEquipments[id];
						const hpath = equipmentDetail.map(e => {
							if ((e.uom || {}).name && !addedPropertiesUomWithHpath[e.uom.name]) {
								addedPropertiesUomWithHpath[e.uom.name] = [];
							}
							addedPropertiesUomWithHpath[e.uom.name].push(e.hpath);
							return e.hpath;
						});
						addedPropertiesToChart[equipmentType].push({id, hpath});
					});
				});

				// Save values to Map
				this.addPropertiesToChartMap.set(ADD_PROPERTY_TO_CHART_STORE, addedPropertiesToChart);

				// add new axis if a selected propertiy has different UoM
				this.updateAxisList(axisList);

				// update hpath for axies
				this.overrideHpathForOriginalTisObjectTypeForAddPropertyToChart(this.chartConfig, axisList);
			}

			axisList.forEach(axis => this.processChartAxis(axis, cartesianAxes, hpath, addedPropertiesUomWithHpath));

			chartOptions.chartType = 'line';
			cartesianAxes.sort((a, b) => a.chartAxisId > b.chartAxisId);
			cartesianAxes.forEach(setAxisSideAndOrientation.bind(this));
			chartOptions.yAxis = cartesianAxes;

			if (chartMetadata.hpath.facility.size) {
				facilityHpath = Array.from(chartMetadata.hpath.facility);
			}

			return this.getFacilityChartData(Array.from(chartMetadata.hpath.tisObject), facilityHpath, axisList, addedPropertiesToChart);
		}

		getFacilityChartData(hpath, facilityHpath, axisList, addedPropertiesToChart) {
			const {$q} = services.get(this);

			let isPropertiesAddedToChart = !!Object.keys(addedPropertiesToChart || {}).length;
			const existingChartDataCalls = existingChartDataCallMap.get(this);
			const range = {from: this.range.from.clone(), to: this.range.to.clone()};
			let chartDataCalls = [];
			let tisObjectTypeGroupName;

			this.clearChartAndTimeline();

			if (hpath) {
				tisObjectTypeGroupName = getTisObjectTypeFromAxises(axisList);
				this.hpathMap.clear();
				this.hpathMap.set(tisObjectTypeGroupName, hpath);
				if (facilityHpath.length) {
					this.hpathMap.set(FACILITY_KEY, facilityHpath);
				}
			} else {
				tisObjectTypeGroupName = Array.from(this.hpathMap.item().keys()).find(item => item !== FACILITY_KEY);
			}

			// Load stored values from Map if chart is updated with external changes like date change
			if (!addedPropertiesToChart) {
				addedPropertiesToChart = this.addPropertiesToChartMap.get(ADD_PROPERTY_TO_CHART_STORE);
				isPropertiesAddedToChart = !!Object.keys(addedPropertiesToChart || {}).length;
			}

			// Add Property To Chart : collect hpaths of added properties and store it
			if (isPropertiesAddedToChart) {
				const hPaths = Object.keys(addedPropertiesToChart).reduce((av, type) => {
					addedPropertiesToChart[type].forEach(({hpath}) => {
						av.push(...hpath);
					});
					return av;
				}, []);
				this.hpathMap.set(ADD_PROPERTY_TO_CHART_HPAHT, hPaths);
			}

			hpath = [].concat(...Array.from(this.hpathMap.item().values()));
			existingChartDataCalls.set(`${range.from.format()}${range.to.format()}${hpath}`, new Date());
			chartDataCalls.push(this.getFacilityWeatherChartData());

			// Get all equipment data within a facility based on  tisObject Group (AHU. Chiller, etc...)
			if (tisObjectTypeGroupName) {
				chartDataCalls.push(
					this.getFacilityTisObjectsChartData({
						tisObjectTypeGroupName: tisObjectTypeGroupName,
					})
				);
			} else {
				chartDataCalls.push($q.resolve());
			}

			// Add Property To Chart : get data of added properties
			if (isPropertiesAddedToChart) {
				chartDataCalls.push(this.getFacilityTisObjectsChartDataForAddPropertiesToChart(addedPropertiesToChart));
			}

			this.loadingPromise.push(...chartDataCalls);
			return $q.all(chartDataCalls).then(data => this.handleChartDataCallsResponses(data, hpath, range));
		}

		handleChartDataCallsResponses([facilityChartData, tisObjectChartData, addPropertiesToChartData], hpath, range) {
			const existingChartDataCalls = existingChartDataCallMap.get(this);
			if (Array.from(existingChartDataCalls).length) {
				const currentCallTimestamp = existingChartDataCalls.get(`${range.from.format()}${range.to.format()}${hpath}`);
				const latestCallTimestamp = Array.from(existingChartDataCalls.values())
					.sort()
					.pop();
				if (currentCallTimestamp !== latestCallTimestamp) return;
			}
			if (facilityChartData) {
				this.renderFacilityChart(facilityChartData);
			}

			if (tisObjectChartData) {
				this.loadFacilityTisObjectsCharts(tisObjectChartData, hpath);
			}

			// Add Property To Chart : prepare chart (lines, legend) from Added Properties Data.
			if (Array.isArray(addPropertiesToChartData) && addPropertiesToChartData.length) {
				addPropertiesToChartData.forEach(AddedPropertyTisObjectChartData => {
					this.loadFacilityTisObjectsCharts(AddedPropertyTisObjectChartData, hpath);
				});
			}

			this.addNonHpathChartData();
			this.sortChartDataAndLines();

			// Add Property To Chart : Stores only original equipment details of the chart. Don't save any added property equipment details
			if (this.chartConfig.isEmpty()) {
				fillChartConfigWithOriginalChartProps(this.chartConfig, this.chartObj.chart.options.lines);
			}
		}

		/**
		 *  get all object data of passed tisObject ids
		 *
		 * @param {*} hpath
		 * @param {*} {tisObjectList}
		 * @returns promise
		 */
		loadTisObjectDataForFacilityChart(hpath, {tisObjectList}) {
			const {tisObjectService} = services.get(this);

			let ids = tisObjectList.map(o => o.tisObjectId);

			if (ids.length === 0) {
				this.showMissingTisObjectsErrorPopup();

				return {
					data: {},
					ids: ids,
				};
			}

			let params = {
				ids: ids,
				enumerations: '',
				hpath: Array.from(hpath).join(','),
				from: this.range.from,
				to: this.range.to,
				timeZone: this.range.timeZone,
			};

			this.fixedEndDate = this.range.to;

			return tisObjectService.getAllValuesByIds(params).then(data => {
				return {
					data: data,
					ids: ids,
				};
			});
		}

		showMissingTisObjectsErrorPopup() {
			const {$filter, modalHelperService} = services.get(this);
			modalHelperService.open({
				templateUrl: 'common/messages/generic-error-message.html',
				controller: 'GenericErrorMessageCtrl',
				backdrop: 'static',
				resolve: {
					title: () => $filter('translate')('MISSING_TIS_OBJECTS'),
					details: () => $filter('translate')('NO_OBJECTS_WITH_SENSORS'),
				},
				scope: this,
			});
		}

		chartLegendCreateNewLine(column, tisObject, name, description, hpath) {
			const {propertyStoreService} = services.get(this);
			const linePropertyToAxis = linePropertyToAxisMap.get(this);

			const propertyName = column.name;
			const axis = linePropertyToAxis[propertyName];
			const lineHash = this.getLineItemHash(propertyName, tisObject);
			const color = this.getColor(false, propertyName, lineHash);
			const markerType = this.getMarkerType(color);
			const fullPropertyInfo = propertyStoreService.getPropertyByHpathAndName(propertyName, hpath, this.tisObjectTypeGroupNumber);

			let propertyObj = {
				tisObjectId: tisObject.tisObjectId,
				name,
				lineHash,
				description,
				color,
				visible: !this.isHiddenProperty(name, description),
				fullPropertyInfo,
				markerType,
				markerSize: DEFAULT_MARKER_SIZE,
				propertyName: propertyName,
				tisObjectType: (tisObject.tisObjectType || {}).tisObjectTypeGroupName || null,
			};

			propertyObj.chartAxisId = axis.chartAxisId;
			propertyObj.uom = axis.uom;
			propertyObj.isDisabledByLegendFilter = this.isHiddenProperty(name, description, false, disabledPropertiesMap);
			propertyObj.isControlRangeBoundary = false;
			propertyObj.valueRange = this.getTisObjectTypeValueRange(propertyName);

			return propertyObj;
		}

		collectChartDataForDataParameters(parameters = [], tisObjectId, hpath) {
			const {LITERAL} = services.get(this);

			parameters.sort(this.byPropertiesArrayOrder.bind(this)).forEach(item => {
				let isNotDataRange = !item.name.includes(LITERAL.DATA_RANGE);
				isNotDataRange && this.collectChartData(item, {id: tisObjectId}, hpath);
			});
		}

		byPropertiesArrayOrder(a, b) {
			let properties = propertiesMap.get(this);

			if (properties.indexOf(a.name) < properties.indexOf(b.name)) {
				return -1;
			} else if (properties.indexOf(a.name) > properties.indexOf(b.name)) {
				return 1;
			}
			return 0;
		}

		collectChartData(data, childData, hpath, isWeather) {
			const isAllowed = this.chartConfig.validateCustomPropertyWithOwnTisObjectId(childData.relatedToTisObjectId, data.name, childData.id);

			// if isAllowed === 3 means, dont allow further to add / render line & legend
			if (isAllowed === 3) {
				return;
			}

			const {LITERAL, CHART_TYPE} = services.get(this);

			let propertyLineHashToChartData = propertyLineHashToChartDataMap.get(this);
			let legendReady = legendReadyMap.get(this);

			// Add weather prefix, to avoid duplicate naming, example: @OutdoorWeatherTemperature, WeatherSourceV2::@OutdoorWeatherTemperature
			let propertyName = isWeather ? LITERAL.WEATHER_PREFIX + data.name : data.name;

			const isTimeLine = this.isTimelineData(propertyName);
			const isException = propertyName.includes(LITERAL.EXCEPTION) || allExceptionsName.has(propertyName);
			const isBarOrPieChartControlRangeProperty =
				this.isControlRangeProperty(propertyName) && (this.isRequestedChartType(CHART_TYPE.BAR) || this.isRequestedChartType(CHART_TYPE.PIE));

			// TODO: incorrect counter
			childData.tisObjectsCount = data.tisObjectsCount;

			if (!legendReady && !isBarOrPieChartControlRangeProperty) {
				this.updateLegend(propertyName, childData, isTimeLine, null, hpath);
			}

			if (!isException && !isBarOrPieChartControlRangeProperty) {
				if (isTimeLine) {
					this.addDataToTimeline(data, childData.id);
				} else {
					const lineHash = this.getLineItemHash(propertyName, childData);
					propertyLineHashToChartData[lineHash] = this.addDataToChart(data);
				}
			}
			// Add Control Range Values where they are present
			if (this.isControlRangeProperty(propertyName)) {
				this.addControlRangeValues(data, propertyName);
			}
		}

		addControlRangeValues(data, propertyName) {
			let chartOptions = chartOptionsMap.get(this);
			let controlRangeValues;
			chartOptions.controlRanges = chartOptions.controlRanges || {};
			if (data.values && data.values.length) {
				controlRangeValues = +data.values[data.values.length - 1].value;
			}
			if (propertyName.includes('Low')) {
				chartOptions.controlRanges.low = controlRangeValues || DEFAULT_LOWER_RANGE_END;
			} else if (propertyName.includes('High')) {
				chartOptions.controlRanges.high = controlRangeValues || DEFAULT_UPPER_RANGE_END;
			}
		}

		isTimelineData(propertyName) {
			const linePropertyToAxis = linePropertyToAxisMap.get(this);

			return linePropertyToAxis[propertyName] && linePropertyToAxis[propertyName].axisName === TIME_LINE_AXIS_NAME;
		}

		loadFacilityTisObjectsCharts(tisObjectChartData, hpath) {
			const {dataFormattingService, CHART_TYPE} = services.get(this);
			const chartOptions = chartOptionsMap.get(this);
			const chartData = chartDataMap.get(this);

			const supressionsData = this.getSuppressionTimelinesData();
			let isRunSetScopeChartData = false;

			chartOptions.isSuppressionsAvailable = supressionsData.isSuppressionsAvailable;

			tisObjectChartData.ids.forEach(tisObjectId => {
				const data = fetchDataForTisObjectFromDataResult(tisObjectChartData.data, tisObjectId);

				// collect chart data for parent properties and analytical parameters
				data.columns.sort(this.byPropertiesArrayOrder.bind(this)).forEach(column => {
					if (!this.isIncludeDataWithErrors && dataFormattingService.isEnumeration(column.name)) {
						filterColumnErrors(column);
					}

					this.collectChartDataForFacilityChart(column, data.tisObject, hpath);
				});

				this.collectChartDataForDataParameters(data.parameters, tisObjectId, hpath);

				(data.relatedDataEntries || []).forEach(relatedDataEntry => {
					this.collectChartDataForChildrenPropertiesAndAnalyticalParameters(relatedDataEntry, data.tisObjectId, hpath);
				});

				const exceptionsData = this.getExceptionTimelinesData();
				if (exceptionsData.properties.length) {
					this.getWorstExceptionsData(exceptionsData, data.parameters);
				} else {
					isRunSetScopeChartData = true;
				}
			});

			isRunSetScopeChartData && setScopeChartData(this, chartData, chartOptions);
		}

		isMergableStackedBarData(sortedColumns, relationship) {
			const chartOptions = chartOptionsMap.get(this);
			const linePropertyToAxis = linePropertyToAxisMap.get(this);

			return (
				chartOptions.chartType === 'stackedBar' &&
				relationship === 'child' &&
				sortedColumns.length === 2 &&
				linePropertyToAxis[sortedColumns[1].name].axisName === OCCUPANCY_AXIS_NAME
			);
		}

		addExtraFlagToCustomPropertyChild(relatedToTisObjectId, childData, dataEntry) {
			if (!this.chartConfig) {
				return;
			}

			dataEntry.columns.forEach(column => {
				if (this.chartConfig.hasCustomProperty(relatedToTisObjectId, column.name)) {
					childData.isDeepChild = true;
					childData.isCustomChildObject = true;
				}
			});
		}

		collectChartDataForChildrenPropertiesAndAnalyticalParameters(entry, relatedToTisObjectId, hpath, depth = 0) {
			entry.dataEntries.forEach(dataEntry => {
				const chartOptions = chartOptionsMap.get(this);
				const dataEntryId = (dataEntry.tisObject || {}).tisObjectId;
				const child = dataEntryId ? this.equipmentList.find(eq => eq.tisObjectId === dataEntryId) : null;

				const isWeatherProperty =
					!dataEntryId && dataEntry.weatherStation && dataEntry.weatherStation.stationKey && dataEntry.weatherStation.wxStationId;

				const childData = {
					name: null,
					id: null,
					relatedToTisObjectId: relatedToTisObjectId,
					isInstance: false,
				};

				if (child) {
					// To update hPath name, need to know about component is standalone or multiple
					if (child.isStandaloneCompressorInstance !== undefined) {
						childData.isStandaloneCompressorInstance = child.isStandaloneCompressorInstance;
						if (child.parentCircuitDetails !== undefined) {
							childData.isCompressorParentCircuitStandalone = (child.parentCircuitDetails || {}).isStandaloneCircuitInstance;
						}
					}
					if (child.isStandaloneCircuitInstance !== undefined) {
						childData.isStandaloneCircuitInstance = child.isStandaloneCircuitInstance;
					}

					childData.isInstance = !!child.instance;
					childData.name = child.instance || child.tisObjectName;
					childData.tisObjectName = child.tisObjectName;
					childData.id = child.tisObjectId;
					childData.tisObjectType = child.tisObjectType.tisObjectTypeGroupName;
					childData.tisObjectTypeGroupNumber = child.tisObjectType.tisObjectTypeGroupNumber;
					// Verify is relation of child equipment is grandchild in response.
					childData.isDeepChild = depth > 0;
					this.addExtraFlagToCustomPropertyChild(relatedToTisObjectId, childData, dataEntry);
				}

				const sortedColumns = dataEntry.columns.sort(this.byPropertiesArrayOrder.bind(this));

				if (this.isMergableStackedBarData(sortedColumns, entry.relationship)) {
					chartOptions.isStackedBarWithOccupancy = true;
					this.collectChartData(mergeOccupancyData(sortedColumns), childData, hpath, isWeatherProperty);
				} else {
					sortedColumns.forEach(item => this.collectChartData(item, childData, hpath, isWeatherProperty));
				}

				(dataEntry.parameters || []).sort(this.byPropertiesArrayOrder.bind(this)).forEach(item => {
					this.collectChartData(item, childData, hpath);
				});

				if (dataEntry.relatedDataEntries) {
					depth++;
					dataEntry.relatedDataEntries.forEach(relatedDataEntry => {
						this.collectChartDataForChildrenPropertiesAndAnalyticalParameters(relatedDataEntry, dataEntry.tisObjectId, hpath, depth);
					});
				}
			});
		}

		collectFacilityChartData(column) {
			const {helpers, translateService, LITERAL} = services.get(this);
			const chartOptions = chartOptionsMap.get(this);
			const propertyLineHashToChartData = propertyLineHashToChartDataMap.get(this);

			let propertyTranslationKey = LITERAL.WEATHER_PREFIX + column.name;
			let tisObjectType = helpers.getPropertyByPath(this.equipment, 'tisObjectType.tisObjectTypeGroupName') || '';

			let name = translateService.translateProperty(propertyTranslationKey, tisObjectType);
			let propertyHpath = column.name;
			let line = this.chartLegendCreateNewLine(column, {}, name, name, [propertyHpath]);

			if (!chartOptions.lines.find(item => item.lineHash === line.lineHash)) {
				chartOptions.lines.push(line);
			}
			propertyLineHashToChartData[line.lineHash] = this.addDataToChart(column);
		}

		renderFacilityChart(data) {
			const chartOptions = chartOptionsMap.get(this);
			const chartData = chartDataMap.get(this);
			this.fixedEndDate = this.range.to;

			// collect chart data for parent properties and analytical parameters
			data.columns.sort(this.byPropertiesArrayOrder.bind(this)).forEach(this.collectFacilityChartData.bind(this));

			if (data.relatedDataEntries) {
				data.relatedDataEntries.forEach(this.collectFacilityChartDataForRelated.bind(this));
			}
			setScopeChartData(this, chartData, chartOptions);
		}

		collectFacilityChartDataForRelated(entry) {
			entry.dataEntries.forEach(dataEntry => {
				dataEntry.columns.sort(this.byPropertiesArrayOrder.bind(this)).forEach(this.collectFacilityChartData.bind(this));

				if (dataEntry.relatedDataEntries) {
					dataEntry.relatedDataEntries.forEach(this.collectFacilityChartDataForRelated);
				}
			});
		}

		getPropertiesArrayFromObject(obj) {
			const {propertyStoreService} = services.get(this);
			return Object.keys(obj).map(propertyStoreService.getByName);
		}

		getLocationTisObjects(locationId) {
			const {locationEquipmentService} = services.get(this);

			return locationEquipmentService.getLocationObjectsList(locationId).then(resp => {
				const locationTisObjects = locationTisObjectsMap.get(this);
				(resp.tisObjectList || []).forEach(obj => {
					locationTisObjects[obj.tisObjectId] = obj;
				});
			});
		}

		createPropertyEnumerationString(hpathArray) {
			let {helpers} = services.get(this);
			const hpathToEnum = propertyName => {
				const propertiesList = propertiesListMap.get(this);
				const enumValue = getPropertyEnumerationValue(propertiesList, propertyName, helpers);
				return enumValue ? `${propertyName}=${enumValue}` : null;
			};

			return hpathArray
				.map(hpathToEnum)
				.filter(item => item)
				.join(',');
		}

		loadChartMetadata(selectedChartObj, editProperties) {
			const {chartService, propertyStoreService, serviceAdvisoryService, $filter, $q, LITERAL, CHART_TYPE} = services.get(this);
			const {chartId, instanceId, instanceName, selectedInstanceEquipmentType} = selectedChartObj;

			this.storeHiddenLinesAndLanes();
			this.clearChartMetadata(editProperties);
			this.clearChartAndTimeline();

			const tisObjectTypeGroupName = this.getSelectedTisObj().tisObjectType.tisObjectTypeGroupName;

			const hpath = [];

			let getLocationObjectPromise = $q.resolve({});
			if (this.chartConfig.hasCustomProps()) {
				getLocationObjectPromise = this.getLocationTisObjects(this.locationId);
			}

			// Start: To support instance level chart (ex : Motor power performance)
			const isChartForDisplayInstance = instanceId && instanceName && selectedInstanceEquipmentType;

			const chartMetaDataPromise = chartService.getChartMetaData(chartId, isChartForDisplayInstance ? instanceName : null);

			const selectedEquipment = this.getSelectedTisObj().tisObjectId;

			selectedChartDetailsMap.clear();

			selectedChartDetailsMap.set('default', {
				instanceName,
				[selectedEquipment]: {
					instanceId,
					instanceName,
					selectedInstanceEquipmentType,
				},
			});
			// End: To support instance level chart (ex : Motor power performance)

			const getPropertiesByTypePromise = propertyStoreService.getPropertiesByType(this.tisObjectTypeGroupNumber);

			// Get all service advisories to extract exception name from each SA and will be stored on allExceptionsName variable to use further.
			const getServiceAvisoryTypesList = serviceAdvisoryService.getAllTypes();

			const sucessCallback = chartMetadata => {
				let propertyEnumerationsToType = propertyEnumerationsToTypeMap.get(this);
				let analyticModelIds = analyticModelIdsMap.get(this);
				let exceptionProperties = exceptionPropertiesMap.get(this);
				const propertiesList = propertiesListMap.get(this);

				const yAxis = [];
				let extraPropertyInfoNeeded = [];
				let axisList;

				chartMetadata.chartAxisList.forEach(changeOccupancyAxesName);
				// Need to be placed right in this place. Needed by occupancy charts code implemented by Volodymyr
				axisList = $filter('orderBy')(chartMetadata.chartAxisList, 'axisName');

				let originalAxesUoms = axisList.filter(axis => isCartesianAxis(axis.axisName)).map(axis => axis.unitOfMeasure);
				originalAxesUomsMap.set(this, originalAxesUoms);

				if (this.chartConfig.hasCustomProps()) {
					this.updateAxisList(axisList);
					this.overrideHpathForOriginalTisObjectType(this.chartConfig, axisList);
				}
				axisList.forEach(axis => {
					if (axis.hpath) {
						parseHpathString(axis.hpath, LITERAL).forEach(property => {
							const linePropertyToAxis = linePropertyToAxisMap.get(this);
							const properties = propertiesMap.get(this);

							let propertyName = getPropertyNameFromHpath(property.hpath, property.isWeatherProperty);

							if (propertyName) {
								let propertyInfoFetched = propertiesList[property.name];

								if (!propertyInfoFetched) {
									extraPropertyInfoNeeded.push(propertyName);
								}
							}

							if (property.isWeatherProperty) {
								property.name = LITERAL.WEATHER_PREFIX + property.name;
							}

							hpath.push(property.hpath);
							properties.push(property.name);
							this.updateExceptionPropertiesList(property.name);

							linePropertyToAxis[property.name] = axis;
						});
					}
					if (isYAxis(axis.axisName)) {
						yAxis.push({
							uom: axis.unitOfMeasure,
							name: axis.axisName,
							chartAxisId: axis.axisName,
						});
					}
				});

				yAxis.forEach(setAxisSideAndOrientation);

				this.isOneYAxis = yAxis.length === 1;

				let typesByTisObjectTypeGroupNamePromise = $q.resolve({});

				if (exceptionProperties.length) {
					let serviceAdvisoryTypeIds = [];
					serviceAdvisoryTypeIdsMap.set(this, serviceAdvisoryTypeIds);
					analyticModelIds.clear();
					let exceptionMapItems = [];
					exceptionMap.set(this, exceptionMapItems);

					typesByTisObjectTypeGroupNamePromise = serviceAdvisoryService.getAllTypes().then(data => {
						if (data && data.forEach && typeof data.forEach === 'function') {
							data
								.filter(t => applicableAutomatedTestLevels.includes(t.automatedTestLevel))
								.filter(t => exceptionProperties.includes(t.exceptionPropertyName))
								.forEach(item => {
									serviceAdvisoryTypeIds.push(item.serviceAdvisoryTypeId);
									if (item.suppressionAnalyticParameter) {
										const suppressionAnalyticParameters = suppressionAnalyticParametersMap.get(this);
										suppressionAnalyticParameters.push({
											name: item.suppressionAnalyticParameter.name,
											exceptionPropertyName: item.exceptionPropertyName,
											isComponent: item.tisObjectType.tisObjectTypeClassification === 'Component',
											tisObjectTypeGroupName: item.tisObjectType.tisObjectTypeGroupName,
										});
									}
									analyticModelIds.add(item.analyticModelId);

									exceptionMapItems[item.serviceAdvisoryTypeId] = {
										propertyName: item.exceptionPropertyName,
										analyticModelId: item.analyticModelId,
									};
								});
						}
					});
				}

				typesByTisObjectTypeGroupNamePromise
					.then(promise => {
						if (analyticModelIds.size) {
							return serviceAdvisoryService.getAnalyticModelParametersByIds(analyticModelIds);
						}

						return promise;
					})
					// Fetch extra properties metadata
					.then(promise => {
						let returnValue = promise;
						let missingPropertyTypes = [];
						let exceptionMapItems = exceptionMap.get(this);

						if (exceptionMapItems.length > 0) {
							this.updateHpathWithExceptionDataRangeParameters(promise, hpath);
						}
						if (extraPropertyInfoNeeded.length > 0) {
							extraPropertyInfoNeeded.forEach(item => {
								let typeID =
									item.indexOf(AT_SYMBOL) !== -1 && item.indexOf(AT_SYMBOL) > 0
										? propertyStoreService.getPropertyTypeGroupIdByName(item.split(AT_SYMBOL)[0])
										: this.tisObjectTypeGroupNumber;
								if (missingPropertyTypes.indexOf(typeID) === -1 && typeID > 0) {
									missingPropertyTypes.push(typeID);
								}
							});
						}
						if (missingPropertyTypes.length > 0) {
							returnValue = $q.all(
								missingPropertyTypes.map(propertyType => {
									return propertyStoreService.getPropertiesByType(propertyType);
								})
							);
						}

						return returnValue;
					})
					.then(promise => {
						const propertiesList = propertiesListMap.get(this);
						const linePropertyToAxis = linePropertyToAxisMap.get(this);

						let chartOptions = {
							lines: [],
							unoccupied: true,
							equipmentIsComponentObj: this.chartObj.equipmentIsComponentObj,
							yAxis: yAxis,
							sortBy: this.sortBy,
							chartType: chartMetadata.chartTypeName,
							meanLines: [],
							thresholds: [],
							controlRanges: {
								low: DEFAULT_LOWER_RANGE_END,
								high: DEFAULT_UPPER_RANGE_END,
							},
							isChartLinesSortedAsc: null,
							isLoadValvePositionChart: this.chartObj.selectedChart.title.includes('Load Valve Position:', 0),
							isAirValvePositionChart: this.chartObj.selectedChart.title.includes('Air Valve Position:', 0),
						};
						chartOptionsMap.set(this, chartOptions);

						if (!promise.length) {
							propertiesList.push(...this.getPropertiesArrayFromObject(promise));
						} else if (promise.length !== 0) {
							promise.forEach(promResponse => {
								let propertiesList = propertiesListMap.get(this);
								propertiesList.push(...this.getPropertiesArrayFromObject(promResponse));
							});
						}

						if (this.isRequestedChartType(CHART_TYPE.SCATTER_WITH_PERFORMANCE_CURVE)) {
							linePropertyToAxis[PERFORMANCE_CURVE] = {
								axisName: PERFORMANCE_CURVE,
							};
						}
						const enums = chartMetadata.chartAxisList
							.map(chartAxis => {
								const hpaths = chartAxis.hpath.split(',').filter(i => FILTER_EMUMERATIONS_CHART.indexOf(i) === -1);
								return this.createPropertyEnumerationString(hpaths);
							})
							.filter(Boolean)
							.join(',');

						propertyEnumerationsToType.set(tisObjectTypeGroupName, enums);

						this.setChartWithAddPropsSupport({value: chartMetadata.hasAddPropsSupport});
						this.getEquimentChartData(hpath, axisList);
					})
					.catch(this.errorCallback.bind(this));
			};
			return $q
				.all([getPropertiesByTypePromise, chartMetaDataPromise, getServiceAvisoryTypesList, getLocationObjectPromise])
				.then(([propertiesData, chartMetadata, serviceAvisoryTypesList = []]) => {
					// Building propertiesList array
					let propertiesList = this.getPropertiesArrayFromObject(propertiesData);
					propertiesListMap.set(this, propertiesList);

					// Store all exceptions name, which will be used later to check a timeline property is exception or normal property
					(serviceAvisoryTypesList || []).forEach(t => {
						allExceptionsName.add(t.exceptionPropertyName);
					});

					return chartMetadata;
				})
				.then(sucessCallback.bind(this))
				.catch(this.errorCallback.bind(this));
		}

		updateExceptionPropertiesList(property) {
			const {LITERAL} = services.get(this);
			let exceptionProperties = exceptionPropertiesMap.get(this);

			if ((property.includes(LITERAL.EXCEPTION) || allExceptionsName.has(property)) && !exceptionProperties.includes(property)) {
				exceptionProperties.push(property);
			}
		}

		updateHpathWithExceptionDataRangeParameters(promise, hpath) {
			const {LITERAL} = services.get(this);
			let parameters = promise.data.parameters;
			let exceptionMapItems = exceptionMap.get(this);

			parameters.forEach(p => {
				let parameter = p.parameter;
				let parameterName = parameter.parameterName;

				if (parameterName.includes(LITERAL.DATA_RANGE)) {
					let exceptionDataRangeParameter = '~' + parameterName;

					if (!hpath.includes(exceptionDataRangeParameter)) {
						hpath.push(exceptionDataRangeParameter);
					}

					exceptionMapItems.filter(item => item.analyticModelId === p.analyticModelId).forEach(element => {
						element.dataRangeParameter = parameterName;
					});
				}
			});
		}

		addPropsDataForAdditionalObjectTypes(chartConfig, type, hpathMap, tisObjectTypeToTisObjectIds, axisList) {
			const {LITERAL} = services.get(this);
			const hpath = new Set();
			const ids = chartConfig.getCustomTisObjectIdsByType(type);
			const props = chartConfig.getPropertiesByType(type);
			const linePropertyToAxis = linePropertyToAxisMap.get(this);

			let propertyEnumerationsToType = propertyEnumerationsToTypeMap.get(this);

			props.forEach(property => hpath.add(property.hpath));
			const hpathString = Array.from(hpath.values()).join(',');

			parseHpathString(hpathString, LITERAL).forEach(({name, hpath}) => {
				const thatProperty = props.find(p => p.hpath === hpath);
				const thatPropertyAxis = axisList.find(axis => {
					return axis.axisName !== TIME_LINE_AXIS_NAME && axis.unitOfMeasure.uomId === thatProperty.uom.uomId;
				});
				const properties = propertiesMap.get(this);
				properties.push(name);
				linePropertyToAxis[name] = thatPropertyAxis;
			});

			const enums = this.createPropertyEnumerationString(Array.from(hpath));
			propertyEnumerationsToType.set(type, enums);

			tisObjectTypeToTisObjectIds.set(type, ids);
			hpathMap.set(type, Array.from(hpath));
		}

		getEquimentChartData(hpath, axisList) {
			const {helpers, $q} = services.get(this);
			const existingChartDataCalls = existingChartDataCallMap.get(this);

			this.storeHiddenLinesAndLanes();
			this.clearChartAndTimeline();
			if (hpath) {
				const originalTisObjectType = this.getSelectedTisObj().tisObjectType.tisObjectTypeGroupName;
				let originalTypeIds = [this.getSelectedTisObj().tisObjectId];
				let customIdsOfOriginalType = this.chartConfig.getCustomTisObjectIdsByType(originalTisObjectType) || [];

				originalTypeIds = helpers.arrayUnique(originalTypeIds.concat(customIdsOfOriginalType));

				this.tisObjectTypeToTisObjectIds.clear();
				this.hpathMap.clear();
				this.tisObjectTypeToTisObjectIds.set(originalTisObjectType, originalTypeIds);
				this.hpathMap.set(originalTisObjectType, hpath);
				if (this.chartConfig.hasAdditionalEquipmentTypes()) {
					this.chartConfig
						.getEquipmentTypes()
						.filter(type => type !== originalTisObjectType)
						.forEach(type => {
							this.addPropsDataForAdditionalObjectTypes(
								this.chartConfig,
								type,
								this.hpathMap.item(),
								this.tisObjectTypeToTisObjectIds.item(),
								axisList
							);
						});
				}
			}

			// The callUniqueId is needed to reject data processing if call is repeated
			const callUniqueId = helpers.generateUniqueId();
			existingChartDataCalls.set(callUniqueId, new Date());

			const allGetChartDataPromises = [];
			this.tisObjectTypeToTisObjectIds.item().forEach((ids, type) => {
				const promise = this.getChartData(type, ids, this.hpathMap.get(type), callUniqueId);
				allGetChartDataPromises.push(promise);
			});

			return $q.all(allGetChartDataPromises).then(() => {
				if (Array.from(existingChartDataCalls).length) {
					const currentCallTimestamp = existingChartDataCalls.get(callUniqueId);
					const latestCallTimestamp = Array.from(existingChartDataCalls.values())
						.sort()
						.pop();
					if (currentCallTimestamp !== latestCallTimestamp) return;
				}
				this.sortChartDataAndLines();
				if (this.chartConfig.isEmpty()) {
					fillChartConfigWithOriginalChartProps(this.chartConfig, this.chartObj.chart.options.lines);
				}
				legendReadyMap.set(this, true);
			});
		}

		filterRelatedColumnsByPropsConfig(entry, data) {
			entry.columns.filter(column => {
				return (
					this.chartConfig.hasCustomProperty(entry.tisObjectId, column.name) ||
					this.chartConfig.hasCustomProperty(data.tisObjectId, column.name) ||
					this.chartConfig.hasOriginalProperty(entry.tisObjectId, column.name)
				);
			});
		}

		byPropsConfig(column, isPrimaryTisObject, tisObjectsType, tisObjectId) {
			if (!isPrimaryTisObject && this.isTimelineData(column.name)) {
				return false;
			}

			if (this.isTimelineData(column.name)) {
				return true;
			}

			if (isPrimaryTisObject && this.chartConfig.hasOriginalProperty(tisObjectId, column.name)) {
				return true;
			}

			return this.chartConfig.hasCustomProperty(tisObjectId, column.name, tisObjectsType);
		}

		getChartData(tisObjectsType, tisObjectIds, hpath, callUniqueId) {
			const {helpers, tisObjectService, dataFormattingService, LITERAL, CHART_TYPE, $q, instanceBasedChartService} = services.get(this);

			let propertyEnumerationsToType = propertyEnumerationsToTypeMap.get(this);
			const existingChartDataCalls = existingChartDataCallMap.get(this);

			const uniqueHpath = helpers.arrayUnique(hpath).join(',');

			const params = {
				ids: tisObjectIds,
				hpath: uniqueHpath,
				enumerations: propertyEnumerationsToType.get(tisObjectsType) || '',
				from: this.range.from,
				to: this.range.to,
				timeZone: this.range.timeZone,
			};
			const promise = $q
				.all([tisObjectService.getAllValuesByIds(params), this.getDataFromNonHpathSources(params)])
				.then(([a, b]) => {
					return this.isFinished ? $q.reject() : $q.resolve([a, b]);
				})
				.then(([responseData, nonHpathResponseData]) => {
					if (Array.from(existingChartDataCalls).length) {
						const currentCallTimestamp = existingChartDataCalls.get(callUniqueId);
						const latestCallTimestamp = Array.from(existingChartDataCalls.values())
							.sort()
							.pop();
						if (currentCallTimestamp !== latestCallTimestamp) return;
					}

					let propertiesByTisObjectCount = {};
					this.fixedEndDate = this.range.to;

					// To support instance level chart (ex : Motor power performance)
					const selectedChartDetails = selectedChartDetailsMap.get('default');

					responseData.tisObjectDataList = instanceBasedChartService.processApiDataBySelectedInstance(
						responseData.tisObjectDataList,
						selectedChartDetails
					);

					loopThroughTisobjectDataList(responseData.tisObjectDataList, fillPropertiesByTisObjectCount, propertiesByTisObjectCount);
					loopThroughTisobjectDataList(responseData.tisObjectDataList, addTisObjectsCountToColumn, propertiesByTisObjectCount);

					const exceptionsDataPromises = tisObjectIds.map(tisObjectId => {
						const isPrimaryTisObject = this.getSelectedTisObj().tisObjectId === tisObjectId;
						const data = fetchDataForTisObjectFromDataResult(responseData, tisObjectId);
						const chartOptions = chartOptionsMap.get(this);
						const chartData = chartDataMap.get(this);

						data.columns.forEach(column => {
							if (!this.isIncludeDataWithErrors && dataFormattingService.isEnumeration(column.name)) {
								filterColumnErrors(column);
							}

							if (this.isTimelineData(column.name)) {
								if (column.name === CHILLER_MODE) {
									for (let value of column.values) {
										if (value.errorDetails && Object.keys(value.errorDetails).length > 0) {
											value.value = null;
										}
									}
								}

								if (column.values.length > 0) {
									const lastValue = column.values[column.values.length - 1];
									const lastValueTime = moment(lastValue.timestamp).tz(this.range.timeZone);
									if (lastValueTime.isAfter(this.fixedEndDate)) {
										this.fixedEndDate = lastValueTime;
									}
								}
							}
						});

						if (this.chartConfig.hasCustomProps()) {
							data.columns = data.columns.filter(column => this.byPropsConfig(column, isPrimaryTisObject, tisObjectsType, tisObjectId));

							data.parameters = (data.parameters || []).filter(parameter =>
								this.byPropsConfig(parameter, isPrimaryTisObject, tisObjectsType, tisObjectId)
							);
						}

						// collect chart data for parent properties and analytical parameters
						data.columns.sort(this.byPropertiesArrayOrder.bind(this)).forEach(item => {
							this.collectChartData(item, {id: tisObjectId}, hpath, false, tisObjectsType);
						});

						this.collectChartDataForDataParameters(data.parameters, tisObjectId, hpath);

						// collect chart data for children properties and analytical parameters
						(data.relatedDataEntries || []).forEach(relatedDataEntry => {
							if (this.chartConfig.hasCustomProps()) {
								const isWeatherSource =
									relatedDataEntry.relationship.toLowerCase() === 'weathersourcev2' ||
									relatedDataEntry.relationship.toLowerCase() === 'weathersource';

								if (isWeatherSource && !isPrimaryTisObject) {
									return;
								} else if (!isWeatherSource) {
									relatedDataEntry.dataEntries.forEach(e => this.filterRelatedColumnsByPropsConfig(e, data));
								}
							}

							this.collectChartDataForChildrenPropertiesAndAnalyticalParameters(relatedDataEntry, data.tisObjectId, hpath);
						});

						if (this.isRequestedChartType(CHART_TYPE.SCATTER_WITH_LIMITS)) {
							if (data.thresholds && data.thresholds.length) {
								data.thresholds[0].thresholdParameters.forEach(child => {
									if (child.name.includes(LITERAL.RED_LIMIT)) {
										this.addDataToChart(child);
										this.addDataToThresholds(child, chartData.length - 1);
									}
								});
							}

							if (data.parameters && data.parameters.length) {
								this.addDataToChart(data.parameters[0]);
								this.addDataToThresholds(data.parameters[0], chartData.length - 1);
							}
						}

						const supressionsData = this.getSuppressionTimelinesData();
						chartOptions.isSuppressionsAvailable = supressionsData.isSuppressionsAvailable;
						let exceptionsData = this.getExceptionTimelinesData();
						if (exceptionsData.properties.length) {
							const exceptionsDataPromise = this.getWorstExceptionsData(exceptionsData, data.parameters, false);
							if (suppressionsHpath.length !== 0) {
								this.loadingPromise.push(exceptionsDataPromise);
							} else {
								return $q.resolve();
							}
							return exceptionsDataPromise;
						} else {
							return $q.resolve();
						}
					});

					this.addNonHpathChartData(hpath, nonHpathResponseData);

					return $q.all(exceptionsDataPromises);
				}, this.errorCallback.bind(this));
			this.loadingPromise.push(promise);
			this.eventObject.emit('disableControls');
			promise
				.then(() => {
					this.eventObject.emit('enableControls');
				})
				.catch(err => {
					this.eventObject.emit('enableControls');
					throw err;
				});
			return promise;
		}

		getCurveTypeName() {
			const curveMapping = CURVE_TYPE_NAME_MAPPING.find(curve => curve.title === _get(this.rootSelectedChart, 'title', ''));
			return _get(curveMapping, 'curveTypeName', '');
		}

		getUniqueValues(array, keyName) {
			return [
				...array
					.reduce((map, item) => {
						const key = item[keyName];
						map.has(key) || map.set(key, item);
						return map;
					}, new Map())
					.values(),
			];
		}

		getDataFromNonHpathSources(params) {
			const {characteristicCurveService, CHART_TYPE, DESIGN_CURVES_COMPONENT_FILTER, $q} = services.get(this);
			let returnValue;
			if (this.isRequestedChartType(CHART_TYPE.SCATTER_WITH_PERFORMANCE_CURVE)) {
				params = this.getChildCircuitIds(params);
				params.curveTypeName = this.getCurveTypeName();
				returnValue = characteristicCurveService.getCurveValuesForTisObjectIds(params);
				let filterString = '';
				if (this.chartObj.selectedChart.description.toLowerCase().includes(DESIGN_CURVES_COMPONENT_FILTER.EVAPORATOR)) {
					filterString = DESIGN_CURVES_COMPONENT_FILTER.EVAPORATOR;
				} else if (this.chartObj.selectedChart.description.toLowerCase().includes(DESIGN_CURVES_COMPONENT_FILTER.CONDENSER)) {
					filterString = DESIGN_CURVES_COMPONENT_FILTER.CONDENSER;
				}
				if (filterString) {
					returnValue = returnValue.then(data => {
						let result = data.filter(item => {
							return item.curveTypeName.toLowerCase().startsWith(filterString) && item.runAnalytics;
						});
						return this.getUniqueValues(result, 'tisObjectId');
					});
				}
			} else {
				returnValue = $q.resolve([]);
			}
			return returnValue;
		}

		getChildCircuitIds(params) {
			let ids = params.ids;

			let circuitIds = this.equipmentList.reduce((accum, equipment) => {
				if (ids.includes(equipment.parentId) && equipment.tisObjectType && equipment.tisObjectType.tisObjectTypeGroupName === 'Circuit') {
					accum.push(equipment.tisObjectId);
				}
				return accum;
			}, []);

			params.ids = circuitIds;
			return params;
		}

		getExceptionTimelinesData() {
			const {LITERAL} = services.get(this);

			const exceptionsData = {
				tisObjectIds: [],
				properties: [],
				lanes: [],
			};

			this.chartObj.timeline.lanes.forEach(item => {
				if (item.property.includes(LITERAL.EXCEPTION) || allExceptionsName.has(item.property)) {
					item.isException = true;
					item.hasData = false;
					exceptionsData.lanes.push({
						tisObjectId: item.tisObjectId,
						property: item.property,
					});
					if (!exceptionsData.tisObjectIds.includes(item.tisObjectId)) {
						exceptionsData.tisObjectIds.push(item.tisObjectId);
					}
					if (!exceptionsData.properties.includes(item.property)) {
						exceptionsData.properties.push(item.property);
					}
				}
			});
			return exceptionsData;
		}

		getSuppressionTimelinesData() {
			const {LITERAL, $filter} = services.get(this);
			const suppressionsData = {
				isSuppressionsAvailable: false,
			};
			this.chartObj.timeline.lanes.forEach(item => {
				if (item.property.endsWith(LITERAL.SUPPRESSION)) {
					item.isSuppression = true;
					item.isDisabledByLegendFilter = !this.chartObj.isSuppressionsEnabled;
					const suppressionName = $filter('translate')(item.name);
					item.name = suppressionName;
					suppressionsData.isSuppressionsAvailable = true;
				}
			});
			return suppressionsData;
		}

		fillNoDataTimelines() {
			const {LITERAL} = services.get(this);
			this.chartObj.timeline.lanes.forEach(item => {
				const isException = item.property.includes(LITERAL.EXCEPTION) || allExceptionsName.has(item.property);
				if (isException && (!item.hasData || item.isCompletelySuppressed)) {
					this.addDataToTimeline({name: item.propertyName, values: []}, item.tisObjectId);
					item.visible = false;
				}
			});
		}

		hideCompletelySuppressedTimelines(suppressionData) {
			const {suppressionDataService} = services.get(this);
			const suppressionAnalyticParameters = suppressionAnalyticParametersMap.get(this);

			Object.keys(suppressionData).forEach(key => {
				const item = suppressionData[key];
				const tisObjectId = item.tisObjectId;
				const suppressionParam = suppressionAnalyticParameters.find(
					({name, tisObjectTypeGroupName}) => name === item.name && tisObjectTypeGroupName === item.tisObject.tisObjectType.tisObjectTypeGroupName
				);
				if (suppressionParam) {
					const propertyName = suppressionParam.exceptionPropertyName;
					const isWholeRangeSuppressed = suppressionDataService.checkIsWholeRangeSuppressed(item);
					const relevantLane = this.chartObj.timeline.lanes.find(item => item.propertyName === propertyName && item.tisObjectId === tisObjectId);
					relevantLane && (relevantLane.isCompletelySuppressed = isWholeRangeSuppressed);
				}
			}, this);
		}

		getWorstExceptionsData(exceptionsData, parameters, setScopeChartDataAndToggleTimeline = true) {
			const {DEFAULTS, serviceAdvisoryService, suppressionDataService, $q} = services.get(this);
			const deferred = $q.defer();
			// There are cases when exceptionsData.tisObjectIds includes null values, that's why filter is used.
			// This is caused because of the following:
			// when relatedDataEntries (from getData response) include some tisObjects which are not present in the location tisObjects hierarchy.
			const tisObjectIds = exceptionsData.tisObjectIds.filter(Boolean);
			const exceptionMapItems = exceptionMap.get(this);
			const serviceAdvisoryTypeIds = serviceAdvisoryTypeIdsMap.get(this);
			const suppressionAnalyticParameters = suppressionAnalyticParametersMap.get(this);

			function processWorstExceptionsData(suppressionData, data) {
				const worstExceptionsData = {};
				function filterIfAddOnException(serviceAdvisory) {
					return serviceAdvisory.performanceIndicatorPerDay === 'Red' && serviceAdvisory.timeAtMaxDeviance;
				}
				data = data.aggregatedServiceAdvisoryList.filter(filterIfAddOnException);
				data.forEach(item => {
					const tisObjectId = item.tisObjectId;
					const timeZone = this.range.timeZone;
					if (exceptionMapItems[item.serviceAdvisoryTypeId]) {
						const propertyName = exceptionMapItems[item.serviceAdvisoryTypeId].propertyName;
						const suppressionParam = suppressionAnalyticParameters.find(param => param.exceptionPropertyName === propertyName);
						const key = tisObjectId + propertyName;
						// The timeline should be “colored in” assuming the timestamp represents the start time of the state we are trying to show
						// and we should fill in the color from that start time to the beginning of the next timestamp.
						// https://tranetis.jira.com/browse/TCCFOUR-17756?focusedCommentId=595231
						const endTime = moment(getTimeAtMaxDevianceTimestamp(item, timeZone))
							.add(DEFAULTS.DATA_INTERVAL_MINUTES, 'minutes')
							.tz(timeZone);
						const endTimestamp = endTime.format();
						const startTimestamp = suppressionDataService.calculateWorstExceptionStartTime(
							exceptionMapItems,
							endTimestamp,
							timeZone,
							item.serviceAdvisoryTypeId,
							parameters
						);
						const startTime = moment(startTimestamp).tz(timeZone);
						const isSuppressed = suppressionDataService.checkSuppression(
							tisObjectId,
							suppressionParam,
							suppressionData,
							startTimestamp,
							endTimestamp,
							timeZone
						);
						const isWithinRange =
							startTime.isBetween(this.range.from, this.range.to) ||
							endTime.isBetween(this.range.from, this.range.to) ||
							startTime.isSame(this.range.from) ||
							endTime.isSame(this.range.to) ||
							(this.range.from.isBetween(startTime, endTime) && this.range.to.isBetween(startTime, endTime));
						if (isSuppressed || !isWithinRange) {
							return;
						}

						if (!worstExceptionsData[key]) {
							worstExceptionsData[key] = {
								tisObjectId: tisObjectId,
								propertyName: propertyName,
								values: [],
							};
						}
						worstExceptionsData[key].values.push({
							startTimestamp: startTimestamp,
							endTimestamp: endTimestamp,
						});
					}
				}, this);
				Object.keys(worstExceptionsData).forEach(key => {
					const tisObjectId = worstExceptionsData[key].tisObjectId;
					const propertyName = worstExceptionsData[key].propertyName;
					const data = {
						name: propertyName,
						values: worstExceptionsData[key].values,
					};

					this.addWorstExceptionsDataToTimeline(data, tisObjectId);
				});
				this.hideCompletelySuppressedTimelines(suppressionData);
				this.fillNoDataTimelines();
				if (setScopeChartDataAndToggleTimeline) {
					const chartOptions = chartOptionsMap.get(this);
					const chartData = chartDataMap.get(this);
					setScopeChartData(this, chartData, chartOptions);
					this.eventObject.emit('toggleTimeline');
				}
				deferred.resolve();
			}

			function getTimeAtMaxDevianceTimestamp(aggregatedServiceAdvisoryItem, timeZone) {
				const {startDate, timeAtMaxDeviance} = aggregatedServiceAdvisoryItem;

				if (timeAtMaxDeviance) {
					return timeAtMaxDeviance;
				}

				// Treat the timeAtMaxDeviance as though it were 23:45
				return moment(startDate)
					.tz(timeZone)
					.startOf('day')
					.set({hour: 23, minute: 45})
					.format();
			}

			if (this.chartObj.selectedChart && tisObjectIds.length) {
				const callRange = {
					from: moment(this.range.from).add(1, 'second'),
					to: moment(this.range.to).add(1, 'day'),
				};
				suppressionsHpath = suppressionDataService.buildSuppressionsHpath(suppressionAnalyticParameters);
				if (suppressionsHpath.length !== 0) {
					const loadSuppressionStatusesPromise = suppressionDataService
						.loadSuppressionStatuses(tisObjectIds, suppressionsHpath, callRange, true)
						.then(data =>
							serviceAdvisoryService
								.getAggregatedServiceAdvisories(tisObjectIds.join(','), serviceAdvisoryTypeIds.join(','), callRange)
								.success(processWorstExceptionsData.bind(this, data))
						);
					this.loadingPromise.push(loadSuppressionStatusesPromise);
				}
			}
			return deferred.promise;
		}

		collectChartDataForFacilityChart(column, tisObj, hpath) {
			const {translateService, LITERAL} = services.get(this);
			let propertyLineHashToChartData = propertyLineHashToChartDataMap.get(this);
			let chartOptions = chartOptionsMap.get(this);

			const description = translateService.translateProperty(column.name);
			const name = tisObj.tisObjectName + ': ' + description;
			let line = this.chartLegendCreateNewLine(column, tisObj, name, description, hpath);
			if (!chartOptions.lines.find(item => item.lineHash === line.lineHash)) {
				chartOptions.lines.push(line);
			}

			if (!column.name.includes(LITERAL.EXCEPTION) || !allExceptionsName.has(column.name)) {
				propertyLineHashToChartData[line.lineHash] = this.addDataToChart(column);
			}
		}

		getCircuit(params) {
			const circuit = this.equipmentList.find(eq => eq.tisObjectId === params.tisObjectId);
			let instance = circuit.instance;
			if (circuit.isStandaloneCircuitInstance !== undefined && circuit.isStandaloneCircuitInstance && instance) {
				instance = 'Ckt';
			}
			return instance;
		}

		getDesignCurveLabel(params) {
			let {$translate} = services.get(this);
			const circuit = this.equipmentList.find(eq => eq.tisObjectId === params.tisObjectId);

			let instance = circuit.instance;
			if (circuit.isStandaloneCircuitInstance !== undefined && circuit.isStandaloneCircuitInstance && instance) {
				instance = 'Ckt';
			}

			return instance ? `${$translate(instance)} ${params.name}` : `${params.name}`;
		}

		getTranslatedPropertyNameLabel(params) {
			const {$filter, translateService, rawDataService, LITERAL} = services.get(this);
			const chartOptions = chartOptionsMap.get(this);
			const {isChild, description, childData, isControlRangeBoundary} = params;
			const {isAirValvePositionChart, isLoadValvePositionChart} = chartOptions;
			const isInstance = childData.isInstance;
			const isComponentObject = this.chartObj.equipmentIsComponentObj;
			const relatedToObjectId = childData && (childData.id || childData.relatedToTisObjectId);
			const isAdditionalObject = !!relatedToObjectId && relatedToObjectId !== this.getSelectedTisObj().tisObjectId;
			const separator = isChild ? (!isInstance ? ': ' : '') : isAdditionalObject ? ': ' : '';
			const isChildObject = childData.relatedToTisObjectId === this.getSelectedTisObj().tisObjectId;
			const isMultipleChiller = childData.tisObjectsCount > 1;
			let relatedToObject = isAdditionalObject ? rawDataService.findTisObjectById(relatedToObjectId, this.equipmentList) : null;
			let name;

			let firstCase = isInstance && isAdditionalObject && childData.isDeepChild;
			const locationTisObjects = locationTisObjectsMap.get(this);

			if (firstCase && !childData.isCustomChildObject) {
				name = relatedToObject.tisObjectName + ': ' + translateService.translateProperty(params.propertyName, childData.tisObjectType);
			} else if (isChild) {
				if (childData.isInstance) {
					let multiple = 'M_';

					if (
						childData.isStandaloneCompressorInstance !== undefined ||
						childData.isStandaloneCircuitInstance !== undefined ||
						childData.isCompressorParentCircuitStandalone !== undefined
					) {
						multiple =
							childData.isStandaloneCircuitInstance || (childData.isStandaloneCompressorInstance && childData.isCompressorParentCircuitStandalone)
								? ''
								: multiple;
					}

					if (childData.isDeepChild) {
						name = translateService.translateProperty(`${multiple}${params.propertyName}`, childData.tisObjectType);
					} else {
						const scope = {name: $filter('translate')(childData.name)};
						name = translateService.translateProperty(`${multiple}${params.propertyName}`, childData.tisObjectType, scope);
					}

					if (isAdditionalObject && (!isChildObject || isMultipleChiller) && relatedToObject && childData.isDeepChild) {
						name = relatedToObject.tisObjectName + ': ' + name;
					}
				} else {
					name = translateService.translateProperty(childData.name, childData.tisObjectType);
				}
			} else {
				name = translateService.translateProperty(params.propertyName, childData.tisObjectType);
			}

			if (isControlRangeBoundary) {
				if (params.propertyName.includes(LITERAL.LOW)) {
					name = UPPER_CONTROL_RANGE_TEXT;
				} else if (params.propertyName.includes(LITERAL.HIGH)) {
					name = ABOVE_CONTROL_RANGE_TEXT;
				}
			} else if (!firstCase && !isChild && isAdditionalObject) {
				name = locationTisObjects[relatedToObjectId] && locationTisObjects[relatedToObjectId].tisObjectName + separator + name;
			} else if (!firstCase && !isComponentObject) {
				if (!isAirValvePositionChart && !isLoadValvePositionChart) {
					name = (isChild ? name : '') + separator + (!isInstance ? description : '');
				}
			}

			return name || '';
		}

		getColor(isTimeLine, propertyName, lineHash) {
			const {LITERAL} = services.get(this);
			let propertiesList = propertiesListMap.get(this);
			const definedColor = getColorByPropertyName(propertiesList, propertyName);
			const colorGenerator = isTimeLine ? timeLineColorGenerator.get(this) : chartLegendColorGenerator.get(this);

			if (isTimeLine && BINARY_PROPERTY_MAPPING[propertyName]) {
				const propertyMapping = BINARY_PROPERTY_MAPPING[propertyName];

				// If a binary property has separate color per each value, the array of two colors should be returned, for instance, ['#000000', '#ffffff'].
				// This color should be properly handler in the next processing steps, depending on if it an array of string or just string.
				if (propertyMapping.every(({color}) => color)) {
					return propertyMapping.map(({color, forceColor}) => colorGenerator.next(color, forceColor).value);
				}
			}

			if (this.isControlRangeProperty(propertyName)) {
				if (propertyName.includes(LITERAL.LOW)) {
					return UPPER_CONTROL_RANGE_COLOR;
				} else if (propertyName.includes(LITERAL.HIGH)) {
					return ABOVE_CONTROL_RANGE_COLOR;
				}
			}

			return lineHash && this.linesPreservedColors[lineHash] ? this.linesPreservedColors[lineHash] : colorGenerator.next(definedColor).value;
		}

		/**
		 * @param childData
		 */
		updateLegendWithDesignCurveInstance(propertyName, childData) {
			const {translateService} = services.get(this);
			let chartOptions = chartOptionsMap.get(this);
			const lineHash = this.getLineItemHash(propertyName, childData);
			const color = this.getColor(false, propertyName, lineHash);
			let tisObjectType = this.getSelectedTisObj().tisObjectType.tisObjectTypeGroupName;
			let description = translateService.translateProperty(propertyName, tisObjectType);
			const linePropertyToAxis = linePropertyToAxisMap.get(this);
			let axis = linePropertyToAxis[propertyName];
			let name = this.getDesignCurveLabel(childData);

			const propertyObj = {
				tisObjectId: childData.tisObjectId,
				tisObjectType: tisObjectType,
				tisObjectName: childData.tisObjectName,
				circuit: this.getCircuit(childData),
				name: name,
				lineHash,
				description: description,
				isChild: false,
				isInstance: false,
				color: color,
				visible: true,
				fullPropertyInfo: {},
				sortInfo: null,
				propertyName: propertyName,
				isDisabledByLegendFilter: false,
				isControlRangeBoundary: false,
				valueRange: [],
				componentName: childData.name,
				resolution: null,
				chartAxisId: axis.axisName,
			};
			chartOptions.lines.push(propertyObj);
		}

		/**
		 * @param propertyName
		 * @param nullAbleChildData
		 * @param isTimeLine
		 * @param propertyTranslationKey
		 * @param hpath
		 */
		// TODO refactor this spaghetti into something meningful
		updateLegendForHpathBasedProperty(propertyName, nullAbleChildData = {}, isTimeLine, propertyTranslationKey, hpath) {
			const {propertyStoreService, helpers, translateService} = services.get(this);
			const linePropertyToAxis = linePropertyToAxisMap.get(this);
			const childData = nullAbleChildData || {};
			let chartOptions = chartOptionsMap.get(this);

			let tisObjectTypeGroupNumber = childData.tisObjectTypeGroupNumber || this.tisObjectTypeGroupNumber;
			let fullPropertyInfo = propertyStoreService.getPropertyByHpathAndName(propertyName, hpath, tisObjectTypeGroupNumber);
			let axis = linePropertyToAxis[propertyName];
			const isControlRangeBoundary = this.isControlRangeProperty(propertyName);
			const lineHash = this.getLineItemHash(propertyName, childData);
			const color = this.getColor(isTimeLine, propertyName, lineHash);
			let isComponentObject =
				helpers.getPropertyByPath(this.chartObj, 'selectedEquipment.tisObjectType.tisObjectTypeClassification') === 'ComponentObject';
			let isChild = childData.name !== null && typeof childData.name !== 'undefined';
			let tisObjectType = childData.tisObjectType || this.getSelectedTisObj().tisObjectType.tisObjectTypeGroupName;
			let description = translateService.translateProperty(propertyTranslationKey || propertyName, tisObjectType);
			let sortInfo = this.getPropertySortInfo(propertyName);

			let name = this.getTranslatedPropertyNameLabel({
				propertyName: propertyName,
				childData: childData,
				isChild: isChild,
				tisObjectType: tisObjectType,
				description: description,
				tisObjectsCount: childData.tisObjectsCount,
				isControlRangeBoundary: isControlRangeBoundary,
			});

			this.replaceScatterChartPropertySymbol(axis);

			const isHiddenProperty = this.isHiddenProperty(name, description, isTimeLine, hiddenPropertiesMap);
			const propertyObj = {
				tisObjectId: childData.id,
				tisObjectType: tisObjectType,
				tisObjectName: childData.tisObjectName,
				name: name,
				lineHash,
				description: description,
				isChild: isChild,
				isInstance: childData.isInstance,
				color: color,
				visible: !isHiddenProperty,
				fullPropertyInfo: fullPropertyInfo,
				sortInfo: sortInfo,
				propertyName: propertyName,
				touched: isHiddenProperty,
				// For debug purpose on UI to know the object is standalone or not
				...(childData.isStandaloneCompressorInstance !== undefined ? {isStandaloneCompressorInstance: childData.isStandaloneCompressorInstance} : {}),
				...(childData.isStandaloneCircuitInstance !== undefined ? {isStandaloneCircuitInstance: childData.isStandaloneCircuitInstance} : {}),
				...(childData.isCompressorParentCircuitStandalone !== undefined
					? {isCompressorParentCircuitStandalone: childData.isCompressorParentCircuitStandalone}
					: {}),
			};
			if (isTimeLine) {
				propertyObj.tisObjectType = tisObjectType;
				propertyObj.property = propertyName;
				propertyObj.multiState = true;
				propertyObj.lane = this.chartObj.timeline.lanes.length;
				this.chartObj.timeline.lanes.push(propertyObj);
			} else {
				propertyObj.chartAxisId = axis.axisName;
				propertyObj.uom = axis.unitOfMeasure;
				propertyObj.isDisabledByLegendFilter = this.isHiddenProperty(name, description, isTimeLine, disabledPropertiesMap);
				propertyObj.isControlRangeBoundary = isControlRangeBoundary;
				propertyObj.componentName = childData.name;
				propertyObj.valueRange = this.getTisObjectTypeValueRange(propertyName);
				propertyObj.resolution = this.getPropertyResolution(propertyName);

				if (!chartOptions.lines.find(item => item.lineHash === propertyObj.lineHash)) {
					chartOptions.lines.push(propertyObj);
				}
			}

			if (isComponentObject && isChild) {
				this.saveChildComponent(childData, propertyObj);
			}
		}

		updateLegend(propertyName) {
			if (propertyName === PERFORMANCE_CURVE) {
				this.updateLegendWithDesignCurveInstance(...arguments);
			} else {
				this.updateLegendForHpathBasedProperty(...arguments);
			}
		}

		getPropertySortInfo(propertyName) {
			const {LITERAL} = services.get(this);

			if (this.isControlRangeProperty(propertyName)) {
				if (propertyName.includes(LITERAL.LOW)) {
					return UPPER_RANGE_SORT_INFO;
				} else if (propertyName.includes(LITERAL.HIGH)) {
					return ABOVE_RANGE_SORT_INFO;
				}
			}
			return null;
		}

		isControlRangeProperty(propertyName) {
			const {LITERAL} = services.get(this);

			return propertyName.includes(LITERAL.BOUNDARY) && propertyName.includes(LITERAL.CONTROL_RANGE);
		}

		replaceScatterChartPropertySymbol(axis) {
			const {CHART_TYPE, $filter} = services.get(this);

			if (
				this.isRequestedChartType(CHART_TYPE.SCATTER) &&
				axis.unitOfMeasure &&
				axis.unitOfMeasure.symbol &&
				axis.axisName === 'x' &&
				axis.unitOfMeasure.symbol.startsWith($filter('translate')('TONS'))
			) {
				axis.unitOfMeasure.symbol = $filter('translate')('PLANT_TONS');
			}
		}

		getTisObjectTypeValueRange(propertyName) {
			const {$filter} = services.get(this);

			let propertiesList = propertiesListMap.get(this);
			const currentProperty = $filter('filter')(propertiesList, {propertyName}, true).pop();

			let range = [];

			if (currentProperty && currentProperty.propertyAttribute) {
				let {lowerValidRange = null, upperValidRange = null} = currentProperty.propertyAttribute;
				if (lowerValidRange || upperValidRange) {
					range = [parseFloat(lowerValidRange), parseFloat(upperValidRange)];
				}
			}
			return range;
		}

		saveChildComponent(childData, propertyObj) {
			let slotId = this.childComponents.list.findIndex(e => {
				return e.name === childData.name;
			});

			if (slotId === -1) {
				const newObj = {
					displayName: createChildComponentName(childData.tisObjectType, childData.name),
					name: childData.name,
					isChecked: true,
					isIntermediate: false,
					lines: [],
				};

				slotId = this.childComponents.list.push(newObj) - 1;
			}

			this.childComponents.list[slotId].lines.push(propertyObj);
		}

		isHiddenProperty(name, description, isTimeLine, propertiesMap = hiddenPropertiesMap) {
			const hiddenProperties = propertiesMap.get(this);

			let searchArr = isTimeLine ? hiddenProperties.lanes : hiddenProperties.lines;
			return (searchArr || []).includes(name + description);
		}

		getValidLaneValue(propertyName, objectId) {
			for (let i = 0, lanes = this.chartObj.timeline.lanes; i < lanes.length; i++) {
				if (lanes[i].property === propertyName && lanes[i].tisObjectId === objectId) {
					return i;
				}
			}
			return 0;
		}

		addDataToTimeline(data, objectId) {
			let currObj;
			let prevItem;
			let lane = this.getValidLaneValue(data.name, objectId);

			const addTimeline = endTimestamp => {
				currObj.end = this.stripTz(endTimestamp);
				this.chartObj.timeline.data.push(currObj);
			};

			const addToExportData = obj => {
				this.chartObj.timeline.exportData.push(obj);
			};

			if (data.values.length > 0) {
				data.values.forEach(item => {
					item.value = item.value === undefined ? null : item.value;
					if (!prevItem || item.value !== prevItem.value) {
						currObj && addTimeline(item.timestamp);
						currObj = {
							start: this.stripTz(item.timestamp),
							text: item.value,
							lane: lane,
						};
						prevItem = item;
					}
					addToExportData({
						start: this.stripTz(item.timestamp),
						text: item.value,
						lane: lane,
					});
				});
			} else {
				currObj = {
					start: this.stripTz(this.range.from),
					text: null,
					lane: lane,
				};
				currObj && addTimeline(this.fixedEndDate);
			}
			currObj && addTimeline(this.fixedEndDate);
		}

		addTimelineItem(start, end, text, lane) {
			let item = {
				start: start,
				end: end,
				text: text,
				lane: lane,
			};
			this.chartObj.timeline.data.push(item);
			this.chartObj.timeline.exportData.push(item);
		}

		addWorstExceptionsDataToTimeline(data, objectId) {
			let lane = this.getValidLaneValue(data.name, objectId);
			let currentLane = this.chartObj.timeline.lanes.find(item => item.lane === lane);

			const updateNoExceptionValues = () => {
				let rangeStart = this.stripTz(this.range.from);
				let rangeEnd = this.stripTz(this.range.to);
				let values = this.chartObj.timeline.data.filter(item => item.lane === lane);
				let prevItem = null;

				values.forEach((currentItem, currIdx) => {
					if (!prevItem) {
						if (currentItem.start > rangeStart) {
							this.addTimelineItem(rangeStart, currentItem.start, false, lane);
						}
					} else {
						if (prevItem.end < currentItem.start) {
							this.addTimelineItem(prevItem.end, currentItem.start, false, lane);
						}
					}
					if (currIdx === values.length - 1) {
						if (rangeEnd > currentItem.end) {
							this.addTimelineItem(currentItem.end, rangeEnd, false, lane);
						}
					}
					prevItem = {
						start: currentItem.start,
						end: currentItem.end,
					};
				});
				if (!values.length) {
					this.addTimelineItem(rangeStart, rangeEnd, false, lane);
				}
			};

			if (data.values.length) {
				data.values.forEach(item => {
					this.addTimelineItem(this.stripTz(item.startTimestamp), this.stripTz(item.endTimestamp), true, lane);
				});
				updateNoExceptionValues();
				if (currentLane) {
					currentLane.hasData = true;
					currentLane.visible = true;
					currentLane.isCompletelySuppressed = false;
				}
			}
		}

		addDataToChart(data) {
			const {dataFormattingService} = services.get(this);
			const resolution = this.getPropertyResolution(data.name);
			const isNeedExtraFormatting = resolution !== null;
			const chartData = chartDataMap.get(this);
			let result = data.values.reduce((filteredData, value) => {
				if (value.value !== undefined && value.value !== null) {
					let y = value.value;

					if (isNeedExtraFormatting) {
						y = dataFormattingService.applyDecimalFormatting(y, resolution);
					}

					if (!isNaN(y)) {
						y = parseFloat(y);
					}

					let formatted = {
						x: this.stripTz(value.timestamp),
						y: y,
						value: value.value,
					};

					if ('unoccupied' in value) {
						formatted.unoccupied = value.unoccupied;
					}

					// Filter out explicit nulls and null values
					if ((!value.explicitNull && !value.missingUpdate) || !isNaN(formatted.y)) {
						filteredData.push(formatted);
					}
				}
				return filteredData;
			}, []);

			// :todo this should be refactored to return data only.
			chartData.push(result);
			return result;
		}

		getPropertyResolution(propertyName) {
			const {helpers} = services.get(this);
			const propertiesList = propertiesListMap.get(this);

			let propertyInfo = propertiesList.find(prop => {
				return prop.propertyAttribute && prop.propertyAttribute.displayName === propertyName;
			});

			return helpers.asNumber(helpers.getPropertyByPath(propertyInfo, 'propertyAttribute.resolution'));
		}

		addDataToThresholds(data, index) {
			let chartOptions = chartOptionsMap.get(this);
			if (
				!chartOptions.thresholds.length ||
				!chartOptions.thresholds.filter(item => {
					return item.name === data.name;
				}).length
			) {
				chartOptions.thresholds.push({
					name: data.name,
					chartDataIndex: index,
				});
			}
		}

		addPerformanceCurveData(hpath, nonHpathData = []) {
			/** @namespace point.curveXAxisValue */
			/** @namespace point.curveYAxisValue */
			/** @namespace performanceCurveMetadata.characteristicCurveId */
			/** @namespace performanceCurveMetadata.characteristicCurveName */
			const chartData = chartDataMap.get(this);
			const legendReady = legendReadyMap.get(this);
			let propertyLineHashToChartData = propertyLineHashToChartDataMap.get(this);
			nonHpathData.forEach(performanceCurveMetadata => {
				let characteristicCurveChildData = {
					id: performanceCurveMetadata.characteristicCurveId,
					tisObjectId: performanceCurveMetadata.tisObjectId,
					name: performanceCurveMetadata.characteristicCurveName,
				};
				if (!legendReady) {
					this.updateLegend(PERFORMANCE_CURVE, characteristicCurveChildData, false, null, hpath);
				}

				if (performanceCurveMetadata && performanceCurveMetadata.points && performanceCurveMetadata.points.length) {
					let dateValue;
					if (chartData[0] && chartData[0].length) {
						dateValue = chartData[0][0].x;
					} else {
						dateValue = moment(this.range.from).format();
					}
					let lineHash = this.getLineItemHash(PERFORMANCE_CURVE, characteristicCurveChildData);
					let performanceCurveData = performanceCurveMetadata.points.map(point => {
						return {
							x: point.curveXAxisValue,
							y: point.curveYAxisValue,
							date: dateValue,
						};
					});
					performanceCurveData.sort((a, b) => a.x > b.x);
					propertyLineHashToChartData[lineHash] = performanceCurveData;
					chartData.push(performanceCurveData);
				}
			});
		}

		addNonHpathChartData(hpath, nonHpathData = []) {
			const {CHART_TYPE} = services.get(this);
			if (this.isRequestedChartType(CHART_TYPE.SCATTER_WITH_PERFORMANCE_CURVE)) {
				this.addPerformanceCurveData(hpath, nonHpathData);
			} else if (this.isRequestedChartType(CHART_TYPE.LINE_WITH_CONTROL_RANGE_AND_MEAN)) {
				this.addMeanLines();
				this.calculateMeanValues();
			} else if (this.isRequestedChartType(CHART_TYPE.LINE_WITH_BINARY_STATES)) {
				const chartData = chartDataMap.get(this);
				this.calculateBinaryValues(chartData);
			}
		}

		addMeanLines() {
			const legendReady = legendReadyMap.get(this);
			let propertyLineHashToChartData = propertyLineHashToChartDataMap.get(this);
			let chartOptions = chartOptionsMap.get(this);

			chartOptions.meanLines = [];
			chartOptions.yAxis.forEach(axis => {
				let amountOfProperties = 0;

				chartOptions.lines.forEach(line => {
					if (
						axis.chartAxisId === line.chartAxisId &&
						line.name !== UPPER_CONTROL_RANGE_TEXT &&
						line.name !== LOWER_CONTROL_RANGE_TEXT &&
						line.name !== ABOVE_CONTROL_RANGE_TEXT
					) {
						amountOfProperties++;
					}
				});

				const lowerControlRangeLineHash = `lowerControlRange${axis.chartAxisId}`;

				// TODO: remove "if" some day;
				if (!legendReady) {
					chartOptions.lines.push({
						name: LOWER_CONTROL_RANGE_TEXT,
						description: LOWER_CONTROL_RANGE_TEXT,
						color: LOWER_CONTROL_RANGE_COLOR,
						visible: true,
						lineHash: lowerControlRangeLineHash,
						chartAxisId: axis.chartAxisId,
						uom: axis.uom,
						isControlRangeBoundary: true,
						sortInfo: LOWER_RANGE_SORT_INFO,
					});
				}
				propertyLineHashToChartData[lowerControlRangeLineHash] = this.addDataToChart({
					name: LOWER_CONTROL_RANGE_TEXT,
					values: [{value: 0}],
				});

				if (amountOfProperties > 1) {
					chartOptions.meanLines.push({
						name: MEAN_OF_ALL_TEXT,
						description: MEAN_OF_ALL_TEXT,
						color: chartLegendColorGenerator.get(this).next().value,
						visible: true,
						lineHash: `MeanOfAll${axis.chartAxisId}`,
						chartAxisId: axis.chartAxisId,
						amountOfProperties: amountOfProperties,
						uom: axis.uom,
						isMeanLine: true,
						sortInfo: MEAN_OF_ALL_SORT_INFO,
					});
				}
			});
			if (!legendReady) {
				chartOptions.lines = chartOptions.lines.concat(chartOptions.meanLines);
				this.linesVisible = true;
				this.toggleLinesCheckbox = true;
			}
		}

		calculateMeanValues() {
			let propertyLineHashToChartData = propertyLineHashToChartDataMap.get(this);
			const chartOptions = chartOptionsMap.get(this);
			const chartData = chartDataMap.get(this);

			const controlRangeAndMeanPropertyNames = [MEAN_OF_ALL_TEXT, UPPER_CONTROL_RANGE_TEXT, LOWER_CONTROL_RANGE_TEXT, ABOVE_CONTROL_RANGE_TEXT];

			chartOptions.meanLines.forEach(meanAxis => {
				const meanAxisData = [];
				const processMeanLineChartData = data => {
					data.forEach((dataObj, key) => {
						if (meanAxisData[key] === undefined) {
							meanAxisData[key] = {};
							Object.keys(dataObj).forEach(prop => {
								meanAxisData[key][prop] = dataObj[prop];
								meanAxisData[key].y = parseFloat(meanAxisData[key].y);
							});
						} else {
							meanAxisData[key].y += parseFloat(dataObj.y);
						}
					});
				};

				chartOptions.lines.forEach(line => {
					let chartData = propertyLineHashToChartData[line.lineHash];
					if (chartData && chartData.length && meanAxis.chartAxisId === line.chartAxisId && !controlRangeAndMeanPropertyNames.includes(line.name)) {
						processMeanLineChartData(chartData);
					}
				});

				const meanAxisChartData = meanAxisData.map(data => {
					let meanValue = data.y / meanAxis.amountOfProperties;
					// Export data (excel/csv) uses data.value as cell value.
					data.y = data.value = Number(meanValue.toFixed(2));
					return data;
				});

				const lineHash = `MeanOfAll${meanAxis.chartAxisId}`;
				propertyLineHashToChartData[lineHash] = meanAxisChartData;
				chartData.push(meanAxisChartData);
			});
		}

		calculateBinaryValues(chartDataToCopy) {
			let increment = 1;
			const chartOptions = chartOptionsMap.get(this);
			const rawChartData = [];
			if (chartDataToCopy) {
				angular.copy(chartDataToCopy, rawChartData);
			}

			if (chartOptions.lines && chartOptions.lines.length) {
				for (let i = chartOptions.lines.length; i--; ) {
					if (chartOptions.lines[i].visible && chartOptions.lines[i].uom.name === 'zeroOne' && rawChartData[i] && rawChartData[i].length) {
						rawChartData[i].forEach((dataObj, key) => {
							const chartData = chartDataMap.get(this);
							chartData[i][key].y = (isFalsyValue(dataObj.y) ? 0 : 1) + increment;
						});

						// Used for binary charts, 2 is the constant to expand chart 2 more lines
						increment += 2;
					}
				}
			}
		}

		toggleDisabled() {
			this.redrawChartLines();
		}

		toggleLine(line) {
			this.eventObject.emit('toggleChartLine', {line: line});
		}

		toggleLines(value) {
			this.linesVisible = value;
			this.redrawChartLines();
		}

		toggleUnoccupied() {
			let chartOptions = chartOptionsMap.get(this);
			chartOptions.unoccupied = !chartOptions.unoccupied;

			this.redrawChartLines();
		}

		toggleTimeline(lane) {
			this.eventObject.emit('toggleTimeline');
		}

		reload(params = {}) {
			let promise;
			if (this.rootSelectedChart) {
				if (this.getSelectedTisObj() && !this.isFacilityChart) {
					promise = this.loadChartMetadata(this.rootSelectedChart, params ? params.editProperties : undefined);
				} else if (this.isParetoChart) {
					promise = this.loadParetoChartMetadata(this.rootSelectedChart);
				} else if (this.isFacilityChart) {
					promise = this.loadFacilityChartMetadata(this.rootSelectedChart.chartId, params.editProperties);
				}
				if (promise && typeof promise.then === 'function') {
					promise.then(() => {
						this.setChartReady();
					});
				} else {
					this.setChartReady();
				}
			}
			MARKER_HASH_ARRAY = [];
		}

		renderDebounced() {
			if (this.rootSelectedChart) {
				if (this.getSelectedTisObj() && !this.isFacilityChart) {
					this.getEquimentChartData();
				} else if (this.isParetoChart) {
					this.loadParetoChartMetadata(this.rootSelectedChart);
				} else if (this.isFacilityChart) {
					// this.loadFacilityChartMetadata(this.rootSelectedChart.chartId);
					this.getFacilityChartData();
				}
			}
		}

		render() {
			if (this.timerID) {
				window.clearTimeout(this.timerID);
			}
			this.timerID = window.setTimeout(() => {
				this.renderDebounced();
				this.timerID = null;
			}, 600);
		}

		setSort() {
			if (this.rootSelectedChart && this.isParetoChart) {
				if (this.cachedParetoResponses) {
					this.onParetoChartSortChange(this.rootSelectedChart, this.cachedParetoResponses);
				} else {
					this.loadParetoChartMetadata(this.rootSelectedChart);
				}
			}
		}

		stripTz(date, useFullFormat = true) {
			if (useFullFormat) {
				return new Date(
					moment(date)
						.tz(this.range.timeZone)
						.format('DD MMMM YYYY HH:mm')
				);
			} else {
				return moment(date)
					.tz(this.range.timeZone)
					.format('YYYY-MM-DDTHH:mm:ssZ');
			}
		}
	}

	angular.module('TISCC').component('chartComponent', {
		templateUrl: 'components/chart-component/chart-component.html',
		controller: ChartComponentController,
		bindings: {
			isFacilityChart: '<',
			isParetoChart: '<',
			chartConfig: '<',
			passedState: '<',
			chartObj: '<',
			searchObj: '<', // looks like I need to remove this line
			isIncludeDataWithErrors: '<',
			locationId: '<',
			range: '<',
			location: '<',
			tisObjectTypeGroupNumber: '<',
			rootSelectedChart: '<', // the same as $scope.$parent.chartObj.selectedChart
			equipmentList: '<',
			eventObject: '<',
			equipment: '<',
			isChartWithAddPropsSupport: '<',
			setChartWithAddPropsSupport: '&',
			isChartLegendExpanded: '=',
			setChartReady: '&',
			chartIndex: '<',
		},
	});
})();
