import {AmplitudeUsageTracker} from './usage-tracker-implementations/amplitude/amplitude-usage-tracker';
import {UsageTracker} from './usage-tracker';
import {AmplitudeUsageTrackerStub} from './usage-tracker-implementations/amplitude/amplitude-usage-tracker.stub';

const isExportRun = window.location.hash.includes('isExport=1');
// eslint-disable-next-line no-undef
const isTestBuild = Boolean(process.env.TEST_BUILD);
const useStubUsageTracker = isExportRun || isTestBuild;

const UsageTrackerImplementation = useStubUsageTracker ? AmplitudeUsageTrackerStub : AmplitudeUsageTracker;

export default UsageTrackerImplementation;

if (!(UsageTrackerImplementation.prototype instanceof UsageTracker)) {
	throw new Error('UsageTrackerImplementation should inherit the UsageTracker class');
}
