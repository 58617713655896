angular.module('TISCC').service('suppressionDataService', function(locationDetailsService, tisObjectService, helpers, $q) {
	this.loadSuppressionStatuses = function(tisObjectIds, hpath, range, showAllValues = false) {
		let deferred = $q.defer();
		let timeZone = locationDetailsService.getLocationTimezone();
		let from, to;
		if (range) {
			from = range.from.tz(timeZone);
			to = range.to.tz(timeZone);
		} else {
			from = moment()
				.subtract(1, 'days')
				.tz(timeZone);
			to = moment().tz(timeZone);
		}

		let params = {
			hpath: hpath,
			enumerations: '',
			ids: tisObjectIds,
			from: from,
			to: to,
			timeZone: timeZone,
		};

		tisObjectService.getAllValuesByIds(params).then(function(data) {
			deferred.resolve(parseSuppressionStatusResponse(data, showAllValues));
		}, deferred.reject.bind(deferred));

		return deferred.promise;
	};

	this.buildSuppressionsHpath = function(suppressionAnalyticParameters) {
		let saw = {};
		let hpath = '';

		suppressionAnalyticParameters.forEach(function(item) {
			let prefix = item.isComponent ? '//' + item.tisObjectTypeGroupName : '';
			let analyticParameterName = prefix + '~' + item.name;
			if (!saw[analyticParameterName]) {
				saw[analyticParameterName] = true;
				hpath += (hpath ? ',' : '') + analyticParameterName;
			}
		});

		return hpath;
	};

	this.calculateWorstExceptionStartTime = function(exceptionMap, endTime, timeZone, index, parameters = []) {
		let dataRange = 24;
		let timeValue = moment(endTime).tz(timeZone);
		let timestamp = timeValue.minutes(timeValue.minutes() - timeValue.minutes() % 15).seconds(0);
		let timestampIndex = (timestamp.hours() * 60 + timestamp.minutes()) / 15;
		let dataRangeParameterName = exceptionMap[index].dataRangeParameter;

		for (let i = 0; i < parameters.length; i++) {
			if (parameters[i].name === dataRangeParameterName && parameters[i].values[timestampIndex]) {
				dataRange = +parameters[i].values[timestampIndex].value || dataRange;
				break;
			}
		}

		return timeValue
			.tz(timeZone)
			.subtract(dataRange, 'hours')
			.format();
	};

	this.checkIsWholeRangeSuppressed = function(suppressionParam) {
		const suppressionValues = suppressionParam.values;
		const isSuppressed = suppressionValues.length > 0 && suppressionValues.every(value => !!Number(value.value));
		return isSuppressed;
	};

	this.checkSuppression = function(tisObjectId, suppressionParam, suppressionData, exceptionStartTimestamp, exceptionEndTimestamp, timeZone) {
		let isSuppressed = false;
		if (suppressionParam) {
			const key = tisObjectId + suppressionParam.name;
			if (suppressionData[key] !== undefined) {
				let suppressionValues = suppressionData[key].values;
				let exceptionStartTime = moment(exceptionStartTimestamp)
					.tz(timeZone)
					.startOf('minute');
				let exceptionEndTime = moment(exceptionEndTimestamp)
					.tz(timeZone)
					.startOf('minute');
				let prevSuppressionTime = null;
				for (let i = 0; i < suppressionValues.length; i++) {
					const value = suppressionValues[i];
					const suppressionTime = moment(value.timestamp)
						.tz(timeZone)
						.startOf('minute');
					const isExceptionTimeWithinRange =
						(prevSuppressionTime && exceptionStartTime.isBetween(prevSuppressionTime, suppressionTime)) ||
						suppressionTime.isBetween(exceptionStartTime, exceptionEndTime);
					if (suppressionTime.isSame(exceptionStartTime) || isExceptionTimeWithinRange) {
						isSuppressed = !!Number(value.value);
						break;
					}
					prevSuppressionTime = suppressionTime;
				}
			}
		}
		return isSuppressed;
	};

	// param.values[0].value has "1" if test is suppressed;
	let isNotSuppressedParam = function(param) {
		const hasData = param.values && param.values.length;
		return !hasData ? true : !Number(param.values[0].value);
	};

	function parseSuppressionStatusResponse(responseData, showAllValues) {
		let dataList = responseData.tisObjectDataList[0];
		const nameToSuppressionStatus = helpers.mapToObject(dataList.parameters, function(param) {
			param.tisObject = dataList.tisObject;
			param.tisObjectId = dataList.tisObjectId;
			return [param.tisObjectId + param.name, showAllValues ? param : isNotSuppressedParam(param)];
		});

		const relatedEntries = helpers.getPropertyByPath(dataList, 'relatedDataEntries[0].dataEntries');
		(relatedEntries || []).forEach(function(relatedEntry) {
			// Fix for TCCFOUR-14632: relatedEntry can be without parameters, hierarchy issue
			(relatedEntry.parameters || []).forEach(function(param) {
				const key = relatedEntry.tisObjectId + param.name;
				param.tisObject = relatedEntry.tisObject;
				param.tisObjectId = relatedEntry.tisObjectId;
				nameToSuppressionStatus[key] = showAllValues ? param : isNotSuppressedParam(param);
			});
		});

		return nameToSuppressionStatus;
	}
});
