import _get from 'lodash.get';

(function() {
	const services = new WeakMap();

	class ReportSectionController {
		constructor($scope, helpers, CPR_REPORT_SECTIONS) {
			services.set(this, {
				$scope,
				helpers,
				CPR_REPORT_SECTIONS,
			});

			this.CPR_REPORT_SECTIONS = CPR_REPORT_SECTIONS;
		}

		$onInit() {
			const {$scope} = services.get(this);
			$scope.cpr = this.cprCtrl;
		}

		isDataPresent(obj = {}) {
			return !!Object.keys(obj).length;
		}

		isPerformanceAssessmentSection(sectionName = '') {
			if (typeof sectionName !== 'string') return false;

			const {PERFORMANCE_ASSESSMENT} = services.get(this).CPR_REPORT_SECTIONS;

			return sectionName.includes(PERFORMANCE_ASSESSMENT);
		}

		isFirstPerformanceAssessmentSection(sectionName = '') {
			if (typeof sectionName !== 'string') return false;

			const {PERFORMANCE_ASSESSMENT} = services.get(this).CPR_REPORT_SECTIONS;
			const {cprCtrl} = this;

			const firstSection = Object.keys(cprCtrl.reportSectionsData)
				.filter(item => item.includes(PERFORMANCE_ASSESSMENT + '_'))
				.sort()[0];

			return firstSection === sectionName;
		}

		isChartsSection(section = {charts: []}) {
			if (typeof section !== 'object') return false;

			return section.charts.every(chart => chart);
		}

		isShowChartDescription(section = {charts: []}) {
			const {CONDENSER_HEAT_TRANSFER, EVAPORATOR_HEAT_TRANSFER} = this.CPR_REPORT_SECTIONS;
			return section.charts.find(sectionName => sectionName.includes(CONDENSER_HEAT_TRANSFER) || sectionName.includes(EVAPORATOR_HEAT_TRANSFER));
		}

		isMultiSection(sectionName) {
			const {helpers} = services.get(this);

			// Return true, is sectionName likes as SECTION_NAME_{Number}.
			// ex.: SECTION_NAME_0, SECTION_NAME_1 etc.
			return helpers.isNumber(sectionName.split('_').reverse()[0]);
		}

		isFirstSection(sectionName) {
			let sectionNumber = this.getSectionNumberFromSectionName(sectionName) || 0;

			return !this.cprCtrl.reportSectionsData[sectionName.replace(sectionNumber, --sectionNumber)];
		}

		getSectionNumberFromSectionName(sectionName) {
			if (this.isMultiSection(sectionName)) {
				const axes = _get(this.cprCtrl, `reportSectionsData[${sectionName}].axes`, {});
				const circuitNumber = _get(axes, 'ChillerCurrentEnteringDrawRla.instance');
				return circuitNumber ? circuitNumber.replace('CKT', '') : Number(sectionName.split('_').reverse()[0]);
			}
			return null;
		}

		concatParams(...params) {
			return params.join(' ');
		}
	}

	angular.module('TISCC').component('cprReportSectionComponent', {
		templateUrl: 'components/reports/chiller-performance/page/report-section/report-section-component.html',
		controller: ReportSectionController,
		bindings: {
			section: '<',
			cprCtrl: '<',
		},
	});
})();
