angular.module('TISCC').directive('expandableSection', expandableSectionDirective);

const BLOCK_STATES = {
	open: 'open',
	closed: 'closed',
};

function expandableSectionDirective() {
	return {
		restrict: 'E',
		transclude: true,
		scope: {
			sectionClass: '@',
			innerClass: '@',
			sectionTitle: '@',
			actionTitle: '@',
			sectionTitleCount: '@',
			actionCallback: '<',
		},
		templateUrl: 'directives/expandable-section-directive.html',
		link: function(scope, element) {
			scope.blockState = 'open';
			const stateActionElement = angular.element(element[0].getElementsByClassName('sub-block-title'));
			const actionElement = angular.element(element[0].getElementsByClassName('sub-block-control-action'));

			scope.toggleBlockState = function(e) {
				e.preventDefault();
				e.stopPropagation();
				if (scope.blockState === BLOCK_STATES.open) {
					scope.blockState = BLOCK_STATES.closed;
				} else {
					scope.blockState = BLOCK_STATES.open;
				}
			};

			actionElement.on('click', function(event) {
				scope.actionCallback(event);
			});
		},
	};
}
