/* eslint quotes: [0, 'single'] */
/* eslint eqeqeq: [0, 'always'] */

angular.module('TISCC').controller('LoginCtrl', LoginCtrl);

function LoginCtrl($scope, $rootScope, $location, $localStorage, $http, authorization, $timeout) {
	let begin = false;

	$scope.login = '';
	$scope.password = '';
	$scope.pageTitle = {
		title: 'Command Center',
	};
	$scope.loader = false;
	$scope.errorLogin = false;
	$scope.errorAuthentication = false;
	$scope.errorPassword = false;
	$scope.emptyLoginAndPassword = false;
	$rootScope.userData = {};

	const checkLogin = function(iteration) {
		const login = document.getElementsByName('login');
		const password = document.getElementsByName('password');
		const click = new CustomEvent('click');
		login[0].dispatchEvent(click);
		password[0].dispatchEvent(click);
		let nextIteration = iteration ? ++iteration : 1;
		$timeout(() => {
			begin = true;
			loginButtonShown();
			if (document.querySelector('input[name="login"]:-webkit-autofill') && document.querySelector('input[name="password"]:-webkit-autofill')) {
				// Hack to detect autofill in Chrome browser
				$scope.emptyLoginAndPassword = false;
			} else if (!$scope.login) {
				checkLogin(nextIteration);
			}
		}, 200 * nextIteration);
	};
	checkLogin();

	function loginButtonShown() {
		if (!begin) return;
		$scope.errorAuthentication = false;
		$scope.emptyLoginAndPassword = $scope.login === '' || $scope.password === '';
	}

	$scope.changeLogin = function() {
		$scope.errorLogin = $scope.login === '';
		loginButtonShown();
	};

	$scope.changePassword = function() {
		$scope.errorPassword = $scope.password === '';
		loginButtonShown();
	};

	$scope.loginProccess = function() {
		$scope.loader = false;
		$scope.changeLogin();
		$scope.changePassword();
		loginButtonShown();
		if ($scope.emptyLoginAndPassword) return;
		$scope.loader = true;
		authorization
			.authenticate($scope.login, $scope.password)
			.then(hideLoader)
			.catch(loginFailureCallback);
	};
	$scope.errorLogin = false;
	$scope.errorPassword = false;
	$scope.emptyLoginAndPassword = false;

	function hideLoader() {
		$scope.loader = false;
	}

	function loginFailureCallback() {
		$scope.errorAuthentication = true;
		$scope.loader = false;
	}
}
