/* global require */
/* globals process */
/* eslint max-nested-callbacks: [2, 4] */

import {CURRENT_PAGE, PREVIOUS_PAGE} from './usage-tracking/common/properties-names';
import _get from 'lodash.get';

angular.module('templates', []).run(function($templateCache) {
	function requireAll(requireContext) {
		return requireContext.keys().map(function(val) {
			return {
				// tpl will hold the value of your html string because thanks to wepbpack "raw-loader" **important**
				tpl: requireContext(val),

				// name is just the filename
				name: val.substring(2),
			};
		});
	}

	let modules = requireAll(require.context('../', true, /\.html$/));

	modules.map(function(val) {
		$templateCache.put(val.name, val.tpl);
	});
});

angular
	.module('TISCC', [
		'metrics',
		'templates',
		'ngRoute',
		'ngCookies',
		'ngStorage',
		'ngSanitize',
		'ngAnimate',
		'ui.utils',
		'ui.bootstrap',
		'pascalprecht.translate',
		'tmh.dynamicLocale',
		'monospaced.elastic',
		'ui.bootstrap.calendar',
		'cgBusy',
		'datePicker',
		'fixedHeader',
		'angucomplete',
		'treeGrid',
		'uiSlider',
		'infinite-scroll',
		'colorpicker.module',
	])
	.config(function($provide, $locationProvider, $routeProvider, $translateProvider, tmhDynamicLocaleProvider, $httpProvider) {
		const preferredLanguage = new URLSearchParams(location.hash.split('?')[1]).get('lang') || 'en';
		const secureRouteResolve = {
			validateAuthentication: function($localStorage, authorization, usageTrackingService) {
				return authorization.checkAccess().then(() => {
					if (!usageTrackingService.isUserSessionStarted) {
						usageTrackingService.startUserSession($localStorage.userData);
					}
				});
			},
			resolveEnvDependecy: function(configService) {
				return configService.initialize();
			},
		};

		$provide.decorator('$xhrFactory', function($delegate, $injector) {
			return function(method, url) {
				const xhr = $delegate(method, url);
				const $http = $injector.get('$http');
				const callConfig = $http.pendingRequests[$http.pendingRequests.length - 1];

				if (angular.isFunction(callConfig.onProgress)) {
					xhr.addEventListener('progress', callConfig.onProgress);
				}

				return xhr;
			};
		});

		$provide.decorator('$log', function($delegate) {
			Object.keys($delegate).forEach(method => {
				$delegate[method] = process.env.NODE_ENV === 'development' || method === 'error' ? $delegate[method] : angular.noop;
			});

			return $delegate;
		});
		$translateProvider.useStaticFilesLoader({
			prefix: 'translations/',
			suffix: '.json',
		});
		$translateProvider.useLocalStorage();
		$translateProvider.preferredLanguage(preferredLanguage);
		tmhDynamicLocaleProvider.localeLocationPattern('libraries/i18n/angular-locale_{{locale}}.js');
		moment.locale(preferredLanguage);

		$routeProvider.when('/login', {
			templateUrl: 'components/login/login.html',
			controller: 'LoginCtrl',
			reloadOnSearch: false,
			metricsConfig: {
				pathName: 'Login Page',
			},
		});
		$routeProvider.when('/', {
			redirectTo: '/facilities',
		});
		$routeProvider.when('/facilities', {
			templateUrl: 'components/home/locations.html',
			controller: 'LocationsCtrl',
			reloadOnSearch: false,
			resolve: secureRouteResolve,
			metricsConfig: {
				isPage: true,
				pathName: 'Home Page',
			},
		});
		$routeProvider.when('/facility/:locationId', {
			templateUrl: 'components/facility-summary/location-details.html',
			controller: 'LocationDetailsCtrl',
			resolve: secureRouteResolve,
			reloadOnSearch: false,
			metricsConfig: {
				isPage: true,
				pathName: 'Facility Summary Page',
			},
		});
		$routeProvider.when('/facility/:locationId/reports/raw-data', {
			templateUrl: 'components/reports/standalone/standalone-report.html',
			controller: 'StandaloneReportCtrl',
			isRDReport: true,
			resolve: secureRouteResolve,
			metricsConfig: {
				pathName: 'Raw Data Report',
			},
		});
		$routeProvider.when('/raw-data-report', {
			templateUrl: 'components/reports/standalone/standalone-report.html',
			controller: 'StandaloneReportCtrl',
			isRDReport: true,
			resolve: secureRouteResolve,
			metricsConfig: {
				pathName: 'Raw Data Report',
			},
		});
		$routeProvider.when('/facility/:locationId/reports/chiller-performance', {
			templateUrl: 'components/reports/standalone/standalone-report.html',
			controller: 'StandaloneReportCtrl',
			resolve: secureRouteResolve,
			metricsConfig: {
				pathName: 'Chiller Performance Report',
			},
		});
		$routeProvider.when('/facility/:locationId/equipment/:equipmentId/reports/chiller-performance', {
			templateUrl: 'components/reports/standalone/standalone-report.html',
			controller: 'StandaloneReportCtrl',
			resolve: secureRouteResolve,
			metricsConfig: {
				pathName: 'Chiller Performance Report',
			},
		});
		$routeProvider.when('/facility/:locationId/settings', {
			templateUrl: 'components/settings/facility-settings.html',
			controller: 'FacilitySettingsCtrl',
			resolve: secureRouteResolve,
		});
		$routeProvider.when('/facility/:locationId/advisories', {
			templateUrl: 'components/service-advisory/service-advisory.html',
			controller: 'ServiceAdvisoryCtrl',
			resolve: secureRouteResolve,
			metricsConfig: {
				isPage: true,
				pathName: 'Facility Service Advisories Page',
			},
		});
		$routeProvider.when('/facility/:locationId/notes', {
			templateUrl: 'components/notes/notes.html',
			controller: 'NotesCtrl',
			resolve: secureRouteResolve,
		});
		$routeProvider.when('/facility/:locationId/performance', {
			redirectTo: '/facility/:locationId/type/null/chart/null',
		});
		$routeProvider.when('/facility/:locationId/type/:equipmentTypeNumber/chart/:chartId', {
			templateUrl: 'components/chart/facility-chart/facility-chart.html',
			controller: 'FacilityChartCtrl',
			isFacilityChart: true,
			isParetoChart: false,
			resolve: secureRouteResolve,
			metricsConfig: {
				isPage: true,
				pathName: 'Facility Performance Chart',
			},
		});
		$routeProvider.when('/facility/:locationId/type/:equipmentTypeNumber/pareto/:serviceAdvisoryTypeId', {
			templateUrl: 'components/chart/pareto-chart/pareto-chart.html',
			controller: 'ParetoChartCtrl',
			reloadOnSearch: false,
			isFacilityChart: true,
			isParetoChart: true,
			resolve: secureRouteResolve,
			metricsConfig: {
				isPage: true,
				pathName: 'Pareto Performance Chart',
			},
		});
		$routeProvider.when('/facility/:locationId/equipment-type/:equipmentTypeNumber', {
			templateUrl: 'components/chart/equipment-chart/equipment-chart.html',
			controller: 'EquipmentChartCtrl',
			reloadOnSearch: false,
			equipmentType: true,
			resolve: secureRouteResolve,
		});
		$routeProvider.when('/facility/:locationId/equipment-type/:equipmentTypeNumber/chart/:chartId', {
			templateUrl: 'components/chart/equipment-chart/equipment-chart.html',
			controller: 'EquipmentChartCtrl',
			reloadOnSearch: false,
			equipmentType: true,
			resolve: secureRouteResolve,
		});
		$routeProvider.when('/facility/:locationId/equipment/:equipmentId/summary', {
			templateUrl: 'components/equipment-summary/equipment-summary.html',
			controller: 'EquipmentSummaryCtrl',
			resolve: secureRouteResolve,
			reloadOnSearch: false,
			metricsConfig: {
				isPage: true,
				pathName: 'Equipment Summary Page',
			},
		});
		$routeProvider.when('/facility/:locationId/equipment/:equipmentId/performance', {
			redirectTo: '/facility/:locationId/equipment/:equipmentId/chart/null',
		});
		$routeProvider.when('/facility/:locationId/equipment/:equipmentId/advisories', {
			templateUrl: 'components/service-advisory/equipment-advisories.html',
			controller: 'ServiceAdvisoryCtrl',
			equipmentLevel: true,
			resolve: secureRouteResolve,
			metricsConfig: {
				isPage: true,
				pathName: 'Equipment Service Advisories Page',
			},
		});
		$routeProvider.when('/facility/:locationId/equipment/:equipmentId/digital-inspection', {
			templateUrl: 'components/digital-inspection/equipment-digital-inspection.html',
			controller: 'DigitalInspectionCtrl',
			equipmentLevel: true,
			resolve: secureRouteResolve,
			metricsConfig: {
				isPage: true,
				pathName: 'Equipment Digital Inspection Page',
			},
		});
		$routeProvider.when('/facility/:locationId/equipment/:equipmentId/notes', {
			templateUrl: 'components/notes/equipment-notes.html',
			controller: 'NotesCtrl',
			equipmentLevel: true,
			resolve: secureRouteResolve,
		});
		$routeProvider.when('/facility/:locationId/equipment/:equipmentId/chart/:chartId', {
			templateUrl: 'components/chart/equipment-chart/equipment-chart.html',
			controller: 'EquipmentChartCtrl',
			equipmentLevel: true,
			reloadOnSearch: false,
			resolve: secureRouteResolve,
			metricsConfig: {
				isPage: true,
				pathName: 'Equipment Performance Chart',
			},
		});
		// To support instance level chart (ex : Motor power performance)
		$routeProvider.when(
			'/facility/:locationId/equipment/:equipmentId/chart/:chartId/instance-type/:selectedInstanceEquipmentType/instance/:instanceName/id/:instanceId',
			{
				templateUrl: 'components/chart/equipment-chart/equipment-chart.html',
				controller: 'EquipmentChartCtrl',
				equipmentLevel: true,
				reloadOnSearch: false,
				resolve: secureRouteResolve,
				metricsConfig: {
					isPage: true,
					pathName: 'Equipment Performance Chart',
				},
			}
		);
		$routeProvider.when('/facility/:locationId/equipment/:equipmentId', {
			templateUrl: 'components/chart/equipment-chart/equipment-chart.html',
			controller: 'EquipmentChartCtrl',
			equipmentLevel: true,
			reloadOnSearch: false,
			resolve: secureRouteResolve,
			metricsConfig: {
				isPage: true,
				pathName: 'Equipment Performance Chart',
			},
		});
		$routeProvider.when('/chart-configurator', {
			templateUrl: 'components/chart/chart-configurator.html',
			controller: 'ChartConfiguratorCtrl',
			resolve: secureRouteResolve,
		});
		$routeProvider.when('/facility/:locationId/equipment/:equipmentId/chiller-performance-report', {
			templateUrl: 'components/reports/chiller-performance/page/chiller-performance-report.html',
			controller: 'ChillerPerformanceReportController',
			controllerAs: 'cpr',
			resolve: secureRouteResolve,
			metricsConfig: {
				exclude: true,
			},
			zendeskConfig: {
				disable: true,
			},
		});
		$routeProvider.when('/facility/:locationId/equipment/:equipmentId/digital-inspection-report', {
			templateUrl: 'components/reports/digital-inspection/page/digital-inspection-report.html',
			controller: 'DigitalInspectionReportController',
			controllerAs: 'dir',
			resolve: secureRouteResolve,
			metricsConfig: {
				exclude: true,
			},
			zendeskConfig: {
				disable: true,
			},
		});
		$routeProvider.when('/404', {
			templateUrl: 'components/error/error.html',
			controller: 'ErrorPageCtrl',
			reloadOnSearch: false,
			resolve: secureRouteResolve,
		});
		$routeProvider.otherwise({
			redirectTo: '/404',
			resolve: secureRouteResolve,
		});

		// Legacy links support
		$routeProvider.when('/locations', {
			redirectTo: '/facilities',
		});
		$routeProvider.when('/locations/:locationId', {
			redirectTo: '/facility/:locationId',
		});
		$routeProvider.when('/locations/:locationId/range', {
			redirectTo: '/facility/:locationId',
		});
		$routeProvider.when('/locations/:locationId/reports/raw-data', {
			redirectTo: '/facility/:locationId/reports/raw-data',
		});
		$routeProvider.when('/locations/:locationId/reports/cpr', {
			redirectTo: '/facility/:locationId/reports/cpr',
		});
		$routeProvider.when('/locations/:locationId/settings', {
			redirectTo: '/facility/:locationId/settings',
		});
		$routeProvider.when('/locations/:locationId/advisories', {
			redirectTo: '/facility/:locationId/advisories',
		});
		$routeProvider.when('/locations/:locationId/notes', {
			redirectTo: '/facility/:locationId/notes',
		});
		$routeProvider.when('/locations/:locationId/performance', {
			redirectTo: '/facility/:locationId/type/null/chart/null',
		});
		$routeProvider.when('/locations/:locationId/type/:equipmentTypeNumber/chart/:chartId', {
			redirectTo: '/facility/:locationId/type/:equipmentTypeNumber/chart/:chartId',
		});
		$routeProvider.when('/locations/:locationId/type/:equipmentTypeNumber/chart/:chartId/range/', {
			redirectTo: '/facility/:locationId/type/:equipmentTypeNumber/chart/:chartId',
		});
		$routeProvider.when('/locations/:locationId/type/:equipmentTypeNumber/pareto/:serviceAdvisoryTypeId', {
			redirectTo: '/facility/:locationId/type/:equipmentTypeNumber/pareto/:serviceAdvisoryTypeId',
		});
		$routeProvider.when('/locations/:locationId/type/:equipmentTypeNumber/pareto/:serviceAdvisoryTypeId/range/', {
			redirectTo: '/facility/:locationId/type/:equipmentTypeNumber/pareto/:serviceAdvisoryTypeId',
		});
		$routeProvider.when('/locations/:locationId/equipment-type/:equipmentTypeNumber', {
			redirectTo: '/facility/:locationId/equipment-type/:equipmentTypeNumber',
		});
		$routeProvider.when('/locations/:locationId/equipment-type/:equipmentTypeNumber/chart/:chartId', {
			redirectTo: '/facility/:locationId/equipment-type/:equipmentTypeNumber/chart/:chartId',
		});
		$routeProvider.when('/locations/:locationId/equipment/:equipmentId/summary', {
			redirectTo: '/facility/:locationId/equipment/:equipmentId/summary',
		});
		$routeProvider.when('/locations/:locationId/equipment/:equipmentId/summary/range', {
			redirectTo: '/facility/:locationId/equipment/:equipmentId/summary',
		});
		$routeProvider.when('/locations/:locationId/equipment/:equipmentId/chart/null/range/', {
			redirectTo: '/facility/:locationId/equipment/:equipmentId/chart/null',
		});
		$routeProvider.when('/locations/:locationId/equipment/:equipmentId/advisories', {
			redirectTo: '/facility/:locationId/equipment/:equipmentId/advisories',
		});
		$routeProvider.when('/locations/:locationId/equipment/:equipmentId/notes', {
			redirectTo: '/facility/:locationId/equipment/:equipmentId/notes',
		});
		$routeProvider.when('/locations/:locationId/equipment/:equipmentId/chart/:chartId/range/', {
			redirectTo: '/facility/:locationId/equipment/:equipmentId/chart/:chartId',
		});
		$routeProvider.when('/locations/:locationId/equipment/:equipmentId/chart/:chartId', {
			redirectTo: '/facility/:locationId/equipment/:equipmentId/chart/:chartId',
		});
		$routeProvider.when('/locations/:locationId/equipment/:equipmentId', {
			redirectTo: '/facility/:locationId/equipment/:equipmentId',
		});
		$routeProvider.when('/facility/:locationId/equipment/:equipmentId/chart/:chartId/range/', {
			redirectTo: '/facility/:locationId/equipment/:equipmentId/chart/:chartId',
		});
		$routeProvider.when('/facility/:locationId/type/:equipmentTypeNumber/chart/:chartId/range/', {
			redirectTo: '/facility/:locationId/type/:equipmentTypeNumber/chart/:chartId',
		});
		$routeProvider.when('/facility/:locationId/type/:equipmentTypeNumber/pareto/:serviceAdvisoryTypeId/range/', {
			redirectTo: '/facility/:locationId/type/:equipmentTypeNumber/pareto/:serviceAdvisoryTypeId',
		});
		$routeProvider.when('/facility/:locationId/equipment/:equipmentId/summary/range', {
			redirectTo: '/facility/:locationId/equipment/:equipmentId/summary',
		});
		$routeProvider.when('/facility/:locationId/range', {
			redirectTo: '/facility/:locationId',
		});

		$httpProvider.interceptors.push('httpInterceptor');
	})
	.factory('httpInterceptor', function($q, $injector, $location, $rootScope, $timeout, AUTH_CONFIGURATION, $translate) {
		return {
			response: function(response) {
				const authorization = $injector.get('authorization');
				const usageTrackingService = $injector.get('usageTrackingService');
				const zendeskHelperService = $injector.get('zendeskHelperService');
				const token = authorization.getToken();
				if (!token) {
					authorization.setRedirectionUrl($location.path()).then(() => {
						$rootScope.globalDateSelectionRange = {};
						usageTrackingService.endUserSession();
						zendeskHelperService.dispose();
						$location.url(AUTH_CONFIGURATION.ROUTES.LOGIN_URL);
					});
				}

				return response;
			},
			responseError: function(response) {
				if ($location.path() !== '/login') {
					const authorization = $injector.get('authorization');
					const googleAnalyticsService = $injector.get('googleAnalyticsService');
					const usageTrackingService = $injector.get('usageTrackingService');
					const zendeskHelperService = $injector.get('zendeskHelperService');
					if (response.status === 401) {
						const modalStack = $injector.get('$modalStack');
						let topWindow = modalStack.getTop();

						while (topWindow !== undefined) {
							topWindow.value.modalScope.$close();
							topWindow = modalStack.getTop();
						}

						authorization.setRedirectionUrl($location.path()).then(() => {
							$rootScope.globalDateSelectionRange = {};
							usageTrackingService.endUserSession();
							zendeskHelperService.dispose();
							$location.url(AUTH_CONFIGURATION.ROUTES.LOGIN_URL);
						});
					} else if (response.status === 403) {
						const isLocationDetailsCall = response.config.url.match(/\/location\/([0-9]*)\/summary/g);
						if (isLocationDetailsCall) {
							googleAnalyticsService.authorizationError($location.path(), '403 error while fetching location details');
							$injector.get('errorHandler').showErrorModal({
								singleReason: $translate('WRONG_AUTHORIZATION'),
							});
						}
					} else {
						const noMessage = response && response.config && response.config.noMessage;
						const requestFailureSeverityConfig = {
							default: true,
							exceptions: [
								response.config.url.includes('/FloorArea'),
								response.config.url.includes('/applicationData'),
								response.config.url.includes('/user/data'),
								response.config.url.includes('/metrics'),
								response.config.url.includes('/chillerPerformanceReport'),
							],
						};
						const testRequestSeverity = function() {
							return requestFailureSeverityConfig.exceptions.some(item => item) ? false : requestFailureSeverityConfig.default;
						};
						if (testRequestSeverity()) {
							googleAnalyticsService.apiError(response.config.url, response.status);
							if (!noMessage) {
								$injector.get('errorHandler').showErrorModal(response);
							}
						}
					}
				}
				return $q.reject(response);
			},
		};
	})
	.run(function(
		$q,
		$rootScope,
		$localStorage,
		$location,
		$translate,
		$timeout,
		tmhDynamicLocale,
		unitsService,
		$modal,
		$http,
		$cookies,
		authorization,
		utilityService,
		zendeskHelperService,
		$route,
		$routeParams,
		configService,
		usageTrackingService,
		notificationsService,
		MetricsFactory
	) {
		$q
			.resolve()
			.then(() => configService.initialize())
			.then(() => usageTrackingService.initialize())
			.then(() => zendeskHelperService.initialize())
			.then(() => checkBetaAccess());

		// Clear marks on page load to remove outliers
		window.performance.clearMarks();

		$rootScope.loginToken = undefined; // Save the token returned if the user went through the login process or the token was revalidated on page load
		$rootScope.defaultRedirectUrl = '/facilities';
		$rootScope.redirectUrl = '/facilities';
		$rootScope.globalDateSelectionRange = {};
		$rootScope.isIE11 = !window.ActiveXObject && 'ActiveXObject' in window;
		$rootScope.isFF = navigator.userAgent.toLowerCase().includes('firefox');
		$rootScope.isEdge = navigator.userAgent.toLowerCase().includes('edge');
		$rootScope.getTrackOnLinkClickHandler = (categoryName, defaultProps = {}) => (event, props = {}, usePreviousPageInsteadOfCurrent) =>
			usageTrackingService.trackEventByCategory(categoryName)(event, {
				[usePreviousPageInsteadOfCurrent ? PREVIOUS_PAGE : CURRENT_PAGE]: _get($route, 'current.metricsConfig.pathName', $route.current.templateUrl),
				...defaultProps,
				...props,
			});
		MetricsFactory.getUsageTrackingService(usageTrackingService);
		$rootScope.$on('$routeChangeSuccess', () => {
			if (!$rootScope.isExport) $rootScope.isExport = $routeParams.isExport;
			if (!$rootScope.isSideLegend) $rootScope.isSideLegend = $routeParams.isSideLegend;
			if (!$rootScope.hideChartHeader) $rootScope.hideChartHeader = $routeParams.hideChartHeader;

			if ($rootScope.isExport && $routeParams.backgroundColor && !$rootScope.backgroundColor) {
				const {backgroundColor} = $routeParams;

				$rootScope.backgroundColor = backgroundColor;
				document.querySelector('html').classList.add(backgroundColor);
			}
			checkBetaAccess(true); // Checking Beta Access for User
		});

		$rootScope.$on('$routeChangeError', function(event, current, previous, rejection) {
			authorization
				.defineRedirectionUrl(rejection)
				.then(authorization.doLoginFormRedirection)
				.then(() => {
					$rootScope.globalDateSelectionRange = {};
					usageTrackingService.endUserSession();
					zendeskHelperService.dispose();
				});
		});

		let timeout;

		function checkBetaAccess(fTServiceCall) {
			$rootScope.$storage = $localStorage;
			if (!fTServiceCall) {
				$rootScope.featureToggles = configService.getFeatureToggles();
			}
			if ($rootScope.featureToggles && $rootScope.featureToggles.accessToBetaScreen) {
				if ($localStorage.userData) {
					{
						$rootScope.accessToBetaScreen = $localStorage.userData.accessToBetaScreen || false;
					}
				}
			} else {
				$rootScope.accessToBetaScreen = false;
			}
		}

		$rootScope.go = function(route) {
			$location.url(route);
		};
		$rootScope.$on('$translateChangeSuccess', function() {
			d3Locale.localeId = $translate.uses();
			d3Locale.current = d3.locale(d3Locale[$translate.uses()]);
			tmhDynamicLocale.set(
				$translate
					.uses()
					.toLowerCase()
					.replace('_', '-')
			);
			moment.locale($translate.uses());
		});
		$rootScope.list = function() {
			return Array.prototype.slice.call(arguments, 0).filter(Boolean);
		};
		$rootScope.lang = $translate.uses;
		$rootScope.changeLanguage = function(lang) {
			$translate.uses(lang);
			moment.locale(lang);
		};
		$rootScope.unitsSystem = unitsService.system;
		$rootScope.changeUnits = function(unit) {
			unitsService.system = unit;
			$rootScope.unitsSystem = unitsService.system;
		};

		function getSubMenuParams(items, event) {
			const MAX_MENU_HEIGHT = 307;
			const MENU_PADDING = 12;
			const node = event.currentTarget.parentNode;
			const currentRect = event.currentTarget.getBoundingClientRect();
			const top = currentRect.top - node.getBoundingClientRect().top;
			const left = node.offsetWidth + node.offsetLeft;
			const right = node.offsetWidth;
			const subMenuActualHeight = items.length * currentRect.height + MENU_PADDING;
			const subMenuMaxHeight = subMenuActualHeight <= MAX_MENU_HEIGHT ? subMenuActualHeight : MAX_MENU_HEIGHT;

			return {
				top: top,
				left: left,
				right: right,
				subMenuMaxHeight: subMenuMaxHeight,
			};
		}

		function setSubMenuPosition(params) {
			$rootScope.subMenuPosition = {
				top: params.top,
				left: params.left,
				right: params.right,
			};

			if (params.rectTop + params.subMenuMaxHeight > document.body.getBoundingClientRect().bottom) {
				$rootScope.subMenuPosition.top = 'auto';
				$rootScope.subMenuPosition.bottom = -params.node.getBoundingClientRect().height + 'px';
			}
		}

		function processLeftSideSubMenuParams(params) {
			if ($rootScope.subMenuWidth) {
				timeout && $timeout.cancel(timeout);
				params.left = -$rootScope.subMenuWidth + 'px';
				setSubMenuPosition(params);
			} else {
				timeout = $timeout(function() {
					processLeftSideSubMenuParams(params);
				}, 50);
			}
		}

		// :todo We need to move this code into directive because we are using it in several types of dropdown menu
		$rootScope.showSubMenu = function(items, event, passedSide) {
			if (items && items.length) {
				const MENU_TOP = 25;
				const subMenuParams = getSubMenuParams(items, event);
				const currentRect = event.currentTarget.getBoundingClientRect();
				const side = passedSide || 'right';
				const params = {
					top: subMenuParams.top + MENU_TOP + 'px',
					left: subMenuParams.left + 'px',
					right: subMenuParams.right + 'px',
					subMenuMaxHeight: subMenuParams.subMenuMaxHeight,
					rectTop: currentRect.top,
					node: event.currentTarget.parentNode,
				};

				$rootScope.subSubMenuItems = null;
				$rootScope.subMenuItems = items || null;

				if (side === 'left') {
					processLeftSideSubMenuParams(params);
				} else {
					setSubMenuPosition(params);
				}
			}
		};

		$rootScope.showSubSubMenu = function(items, event) {
			const subMenuParams = getSubMenuParams(items, event);
			const currentRect = event.currentTarget.getBoundingClientRect();

			$rootScope.subSubMenuItems = items || null;
			$rootScope.subSubMenuPosition = {
				top: subMenuParams.top + parseInt($rootScope.subMenuPosition.top, 10) + 'px',
				left: subMenuParams.left + 'px',
				right: subMenuParams.right + 'px',
			};

			if (currentRect.top + subMenuParams.subMenuMaxHeight > document.body.getBoundingClientRect().bottom) {
				$rootScope.subSubMenuPosition.top = document.body.getBoundingClientRect().height - subMenuParams.subMenuMaxHeight - subMenuParams.top + 'px';
			}
		};

		$rootScope.hideSubMenu = function() {
			$rootScope.subMenuItems = null;
			$rootScope.subSubMenuItems = null;
			$rootScope.subMenuWidth = null;
		};

		$rootScope.useSACache = true;
		utilityService.getAppVersionData().then(versionData => ($rootScope.versionData = versionData));
		utilityService.getEnvironmentType();

		notificationsService.setClosed();
	});
