/* eslint max-nested-callbacks: [2, 5] */
import {USER_EVENTS} from '../../common/usage-tracking/categories';
import {getColumnName} from '../../common/usage-tracking/categories/equipment-summary-page/utils';
import {PRIMARY_OFFERING} from '../../common/usage-tracking/common/properties-names';
import {PRIMARY_OFFERINGS} from '../../common/usage-tracking/common/primary-offerings';
const {
	EQUIPMENT_SUMMARY_PAGE: {
		events: EQUIPMENT_SUMMARY_PAGE_EVENTS,
		properties: EQUIPMENT_SUMMARY_PAGE_PROPERTIES,
		categoryName: EQUIPMENT_SUMMARY_PAGE_CATEGORY_NAME,
	},
	DIGITAL_INSPECTION_PAGE: {properties: DIGITAL_INSPECTION_PAGE_PROPERTIES},
	NAVIGATION: {events: NAVIGATION_EVENTS},
} = USER_EVENTS;

angular
	.module('TISCC')
	.controller('EquipmentSummaryCtrl', EquipmentSummaryCtrl)
	.controller('ViewAutomatedTestCtrl', ViewAutomatedTestCtrl);

function EquipmentSummaryCtrl(
	$scope,
	$locale,
	$route,
	$routeParams,
	$filter,
	modalHelperService,
	$q,
	$timeout,
	$translate,
	$location,
	$rootScope,
	LocationSettingsFctry,
	locationDetailsService,
	locationEquipmentService,
	serviceAdvisoryService,
	chartService,
	STATUS,
	helpers,
	suppressionDataService,
	tisObjectService,
	uiStateService,
	errorHandler,
	urlService,
	ErrorPageFactory,
	subtypeFilterService,
	utilityService,
	CHART_DATE_FORMAT,
	PAGE_TYPE,
	GEN3_URL,
	TC_URL,
	REPORT_TYPES,
	configService,
	CHART_TYPE_LEVEL,
	usageTrackingService,
	ENVIRONMENT,
	$sessionStorage
) {
	const MILLISECONDS_IN_DAY = 86400000;
	const DAYS_IN_WEEK = 7;
	const DAYS_IN_TWO_WEEKS = 14;
	const PATH_404 = '404';
	const INVALID_DATE_FUTURE_KEY = 'invalidDateFuture';
	const INVALID_DATE_FORMAT_KEY = 'invalidDateFormat';
	const allChartsServicePromise = chartService.getAllCharts();
	const chillerSubComponents = {
		Circuit: [],
		Compressor: [],
	};
	const TIS_GROUP_NAMES_SHORT = {
		CIRCUIT: 'Ckt',
		COMPRESSOR: '',
	};
	let equipments = [];
	let modalInstance = null;
	let loadedStatusProcess = false;
	let watchDate = false;
	let progressIncrement;
	let tisObjectsHierarchy;
	const NO_DATA_KEY = 'No data';
	const TURNING_OFF_KEY = 'Turning off';
	const UNKNOWN = 'Unknown';
	const externalLinks = configService.getExternalLinks();
	let getSuggestionByServiceAdvisoryTypeActionName = angular.noop;
	const trackEvent = usageTrackingService.trackEventByCategory(EQUIPMENT_SUMMARY_PAGE_CATEGORY_NAME);

	$scope.featureToggles = configService.getFeatureToggles();
	$scope.uiStateKey = 'equipmentSummaryCtrl.uiState.' + $routeParams.locationId + '.' + $routeParams.equipmentId;
	$scope.locationId = $routeParams.locationId;
	$scope.equipmentId = $routeParams.equipmentId;
	$scope.activeTab = 'summary';
	$scope.days = $locale.DATETIME_FORMATS.SHORTDAY;
	$scope.isShowSuppressed = false;
	$scope.weatherLoading = true;
	$scope.serialNumberLoading = true;
	$scope.modelNumberLoading = true;
	$scope.openReport = openReport;
	$scope.environment = configService.getEnvironmentType();
	$scope.isCprAllowed = locationDetailsService.isCprAllowed;
	$scope.isDirAllowed = locationDetailsService.isDirAllowed;
	$scope.isOfferingExpired = locationDetailsService.isOfferingExpired;
	$scope.suppressionFilter = suppressionFilter;
	$scope.toggleSuppression = toggleSuppression;
	const locationSettings = LocationSettingsFctry.getLocation($routeParams.locationId);
	$scope.rangeOffset = Number.isNaN(locationSettings.rangeOffset) ? 0 : locationSettings.rangeOffset;
	$scope.hasSuggestion = angular.noop;
	$scope.EVENTS = {...EQUIPMENT_SUMMARY_PAGE_EVENTS, ...NAVIGATION_EVENTS};
	$scope.PROPERTIES = EQUIPMENT_SUMMARY_PAGE_PROPERTIES;
	$scope.PRIMARY_OFFERING = PRIMARY_OFFERING;
	$scope.PRIMARY_OFFERINGS = PRIMARY_OFFERINGS;
	$scope.trackOnLinkClick = $rootScope.getTrackOnLinkClickHandler(EQUIPMENT_SUMMARY_PAGE_CATEGORY_NAME);
	$scope.trackGoToPerformanceChartLinkClick = () => {
		trackEvent(EQUIPMENT_SUMMARY_PAGE_EVENTS.GO_TO_PERFORMANCE_CHART, {
			[EQUIPMENT_SUMMARY_PAGE_PROPERTIES.BUILDING_ID]: $scope.location.locationId,
			[EQUIPMENT_SUMMARY_PAGE_PROPERTIES.BUILDING_SALES_OFFICE]: $scope.location.salesOffice.officeCode,
			[EQUIPMENT_SUMMARY_PAGE_PROPERTIES.BUILDING_OFFERINGS]: ($scope.location.offeringSourceMaps || []).map(({shortName}) => shortName),
		});
	};
	$scope.boilersList = $sessionStorage.boilersList || [];
	$scope.trackAutomatedTest = (test, isSingleTest) => {
		let {statusList, status, ...PROPERTIES_TO_SEND} = test;
		const offering = {
			[EQUIPMENT_SUMMARY_PAGE_PROPERTIES.OFFERING]:
				$scope.equipment && $scope.equipment.tisObjectType.tisObjectTypeGroupName === 'Chiller' ? 'CMSA' : UNKNOWN,
		};
		const {date = '', title = ''} = isSingleTest ? status : statusList[statusList.length - 1];
		PROPERTIES_TO_SEND = {...PROPERTIES_TO_SEND};
		$scope.trackOnLinkClick(EQUIPMENT_SUMMARY_PAGE_EVENTS.GO_TO_PERFORMANCE_CHART, {...PROPERTIES_TO_SEND});
		$scope.trackOnLinkClick(EQUIPMENT_SUMMARY_PAGE_EVENTS.SELECT_AUTOMATED_TEST, {
			...PROPERTIES_TO_SEND,
			...offering,
			[EQUIPMENT_SUMMARY_PAGE_PROPERTIES.DATE]: date,
			[EQUIPMENT_SUMMARY_PAGE_PROPERTIES.TEST_STATUS]: title,
		});
	};

	$scope.trackViewSuggestionClick = test => {
		let {statusList, ...PROPERTIES_TO_SEND} = test;
		PROPERTIES_TO_SEND = {...PROPERTIES_TO_SEND};
		const offering = {
			[EQUIPMENT_SUMMARY_PAGE_PROPERTIES.OFFERING]:
				$scope.equipment && $scope.equipment.tisObjectType.tisObjectTypeGroupName === 'Chiller' ? 'CMSA' : UNKNOWN,
		};
		$scope.trackOnLinkClick(EQUIPMENT_SUMMARY_PAGE_EVENTS.VIEW_SUGGESTIONS, {...PROPERTIES_TO_SEND, ...offering});
	};

	$scope.onApplyFilterHandler = ({column}) => {
		$scope.trackOnLinkClick(EQUIPMENT_SUMMARY_PAGE_EVENTS.APPLY_FILTER, {
			[EQUIPMENT_SUMMARY_PAGE_PROPERTIES.COLUMN]: getColumnName(column),
		});
		trackEvent(EQUIPMENT_SUMMARY_PAGE_EVENTS.APPLY_FILTER);
	};

	$scope.onRemoveFilterHandler = ({column}) => {
		$scope.trackOnLinkClick(EQUIPMENT_SUMMARY_PAGE_EVENTS.REMOVE_FILTER, {
			[EQUIPMENT_SUMMARY_PAGE_PROPERTIES.COLUMN]: getColumnName(column),
		});
		trackEvent(EQUIPMENT_SUMMARY_PAGE_EVENTS.REMOVE_FILTER);
	};

	$scope.onExpandTestsButtonClick = () => {
		$scope.trackOnLinkClick(EQUIPMENT_SUMMARY_PAGE_EVENTS.EXPAND_TEST_RESULTS_VIEW);
		trackEvent(EQUIPMENT_SUMMARY_PAGE_EVENTS.EXPAND_TEST_RESULTS_VIEW);
	};

	$scope.onDatesRangeChange = () => {
		const offering = {
			[DIGITAL_INSPECTION_PAGE_PROPERTIES.OFFERING]:
				$scope.equipment && $scope.equipment.tisObjectType.tisObjectTypeGroupName === 'Chiller' ? 'CMSA' : UNKNOWN,
		};
		const difference = moment($scope.to).diff(moment($scope.from), 'days');
		$scope.trackOnLinkClick(EQUIPMENT_SUMMARY_PAGE_EVENTS.CHANGE_DATES_OF_TEST_RESULTS, {
			[DIGITAL_INSPECTION_PAGE_PROPERTIES.DAYS_SELECTED]: difference,
			...offering,
		});
	};

	$scope.onTestSearch = text => {
		text && $scope.trackOnLinkClick(EQUIPMENT_SUMMARY_PAGE_EVENTS.SEARCH_TESTS);
		trackEvent(EQUIPMENT_SUMMARY_PAGE_EVENTS.SEARCH_TESTS);
	};

	serviceAdvisoryService.getActions(actions => {
		getSuggestionByServiceAdvisoryTypeActionName = serviceAdvisoryService.generateGetActionsFunction(actions);
		$scope.hasSuggestion = serviceAdvisoryTypeActionName => Boolean(getSuggestionByServiceAdvisoryTypeActionName({id: serviceAdvisoryTypeActionName}));
	});

	if (uiStateService.getState($scope.uiStateKey)) {
		$scope.isShowSuppressed = uiStateService.getState($scope.uiStateKey).isShowSuppressed;
		$scope.toggleSuppression.isShowSuppressed = $scope.isShowSuppressed;
	} else {
		uiStateService.registerState($scope.uiStateKey, {isShowSuppressed: false});
	}

	function incrementProgressIndicator(deferred) {
		if (typeof deferred !== 'undefined') {
			deferred.resolve();
		}
		$scope.progress = $scope.progress + progressIncrement;
	}

	function openReport(report) {
		if (report === REPORT_TYPES.REPORT_CHILLER_PERFORMANCE_ENGLISH.report && !locationDetailsService.isCprAllowed($scope.location)) {
			return;
		}

		if (report !== REPORT_TYPES.RAW_DATA.report || ($scope.location && $scope.location.locationName)) {
			modalInstance = modalHelperService.open({
				templateUrl: 'components/reports/report-dialog.html',
				controller: 'ReportDialogCtrl',
				backdrop: 'static',
				windowClass: 'report-dialog full-height',
				resolve: {
					data: function() {
						return {
							report: report,
							locationData: $scope.location,
							defaultSelection: $scope.equipment,
							equipmentsData: [$scope.equipment],
							isEquipmentSummary: true,
							rangeFrom: $scope.from,
							rangeTo: $scope.to,
							maxDt: $scope.maxDt,
							rangeMode: 'custom',
						};
					},
				},
			});
		}
	}

	function updateRange() {
		$scope.twoWeekRange = [];

		const now = moment()
			.tz(locationDetailsService.getLocationTimezone())
			.startOf('day');
		const toDate = moment(now);
		const fromDate = moment(now).subtract($scope.rangeOffset + 13, 'days');

		if ($scope.rangeOffset) {
			toDate.subtract($scope.rangeOffset, 'days');
		}

		for (let i = 0; i < DAYS_IN_TWO_WEEKS; i++) {
			let d = moment(now).subtract($scope.rangeOffset + i, 'days');
			$scope.twoWeekRange.unshift({
				name: d.format('d'),
				title: d.format('dddd, MMMM D, YYYY'),
				date: formatMomentDateForUrl(d),
				fromDate: formatMomentDateForUrl(d.clone().subtract(DAYS_IN_WEEK - 1, 'days')),
			});
		}
		watchDate = false;
		$scope.from = moment(fromDate);
		$scope.to = moment(toDate).add(1, 'days');
		$scope.maxDt = moment(now);
		$scope.toText = toDate.format('LL');
		$scope.toDateTestUrl = formatMomentDateForUrl(toDate);
		$scope.fromDateTestUrl = formatMomentDateForUrl(toDate.clone().subtract(DAYS_IN_WEEK - 1, 'days'));
		$scope.fromText = $scope.from.format('LL');
		$scope.nextPeriodTitle = $scope.rangeOffset ? $filter('translate')('NEXT_TWO_WEEKS_SCROLL_TOOLTIP') : undefined;

		setTimeout(function() {
			watchDate = true;
		}, 50);
	}
	// Event Listener to update AppDateRange Global Variable
	$scope.$watch('to', function() {
		let appDateRange = {
			startDate: $scope.from,
			endDate: moment($scope.to).subtract(1, 'days'),
		};
		$rootScope.appDateRange = appDateRange;
	});

	function formatMomentDateForUrl(date) {
		return date instanceof moment
			? date.format(CHART_DATE_FORMAT.RANGE_DATE_FORMAT)
			: moment(date, CHART_DATE_FORMAT.RANGE_DATE_FORMAT).format(CHART_DATE_FORMAT.RANGE_DATE_FORMAT);
	}

	function checkRouteParamDates() {
		if ($routeParams.startDate && $routeParams.endDate) {
			$scope.from = moment.tz($routeParams.startDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, locationDetailsService.getLocationTimezone()).startOf('day');
			$scope.to = moment
				.tz($routeParams.endDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, locationDetailsService.getLocationTimezone())
				.startOf('day')
				.add(1, 'days');
			$scope.rangeOffset = calculateRangeOffset($scope.to);

			// if endDate is invalid, use startDate to calculate range offset
			if (!$scope.to.isValid() && $scope.from.isValid()) {
				$scope.rangeOffset = calculateRangeOffset($scope.from) - DAYS_IN_TWO_WEEKS;
			}
			locationSettings.rangeOffset = $scope.rangeOffset;

			locationDetailsService.getLocationDetailsWithoutServiceAdvisories($routeParams.locationId).then(() => {
				const timezone = locationDetailsService.getLocationTimezone();
				const from = moment.tz($routeParams.startDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, timezone).startOf('day');
				const to = moment.tz($routeParams.endDate, CHART_DATE_FORMAT.RANGE_DATE_FORMAT, timezone).startOf('day');
				const urlDateRangeFormatIsValid = helpers.checkUrlRangeFormat(
					from,
					to,
					$routeParams.startDate,
					$routeParams.endDate,
					CHART_DATE_FORMAT.RANGE_DATE_FORMAT
				);
				const urlDateRangeIsValid = helpers.checkUrlRangeValidity(from, to, timezone);
				if (!urlDateRangeFormatIsValid) {
					ErrorPageFactory.createErrorPage(INVALID_DATE_FORMAT_KEY, $location.path(), PAGE_TYPE.EQUIPMENT);
					urlService.changeUrl(PATH_404);
				} else if (!urlDateRangeIsValid) {
					ErrorPageFactory.createErrorPage(INVALID_DATE_FUTURE_KEY, $location.path(), PAGE_TYPE.EQUIPMENT);
					urlService.changeUrl(PATH_404);
				} else {
					updateRange();
				}
			});
		} else {
			const replaceDates = true;
			urlService.changeDateRangeInUrl($scope.from.clone(), $scope.to.clone().add(-1, 'days'), replaceDates);
		}
	}

	function loadAggregatedServiceAdvisories() {
		if (!$scope.serviceAdvisoryTypeIDs.length || loadedStatusProcess) {
			return;
		}
		loadedStatusProcess = true;
		let serviceAdvisoryTypeIDs = $scope.serviceAdvisoryTypeIDs.join(',');
		let callRange = {
			from: $scope.from,
			to: $scope.to,
		};

		const {tisObjectId} = $scope.equipment;
		let selectedEquipmentDetails = {};

		for (let boiler of $scope.boilersList) {
			if (boiler.tisObjectId === tisObjectId) {
				selectedEquipmentDetails = {...boiler};
			}
		}

		const equipmentServiceAdvisoryIds = $scope.automatedTests.map(at => at.serviceAdvisoryTypeId);

		const {applicationType} = selectedEquipmentDetails;
		if (applicationType) {
			subtypeFilterService.getBoilerTestsFilter(applicationType).then(automatedTests => {
				if (automatedTests && automatedTests.length) {
					const overlappingTests = equipmentServiceAdvisoryIds.filter(servId => automatedTests.includes(servId));
					$scope.automatedTests = $scope.automatedTests.filter(at => !overlappingTests.includes(at.serviceAdvisoryTypeId));
				}
			});
		}

		$scope.serviceAdvisoryPromise = serviceAdvisoryService
			.getAggregatedServiceAdvisories(equipments, serviceAdvisoryTypeIDs, callRange, true)
			.success(({aggregatedServiceAdvisoryList: data}) => {
				loadedStatusProcess = false;

				$scope.automatedTests.forEach(item => {
					const isTestSuppressed = item.testStatus && !item.testStatus.isNotSuppressed;

					if (!item.statusList) return;

					item.dateTo = $scope.toDateTestUrl;
					item.dateFrom = $scope.fromDateTestUrl;

					$scope.twoWeekRange.forEach(({title, date, fromDate}, index) => {
						const statusListItem = item.statusList[index];

						statusListItem.className = isTestSuppressed ? STATUS.MODE[TURNING_OFF_KEY].className : STATUS.MODE[NO_DATA_KEY].className;
						statusListItem.title = isTestSuppressed ? STATUS.MODE[TURNING_OFF_KEY].title : STATUS.MODE[NO_DATA_KEY].title;
						statusListItem.date = title;
						statusListItem.dateTo = date;
						statusListItem.dateFrom = fromDate;
					});
				});

				Array.isArray(data) &&
					data.forEach(item => {
						let testItem = $scope.automatedTestsCollection[getUniqueAutomatedTestSelector(item)];

						if (!testItem || !testItem.statusList) return;

						const rangeItem = $scope.twoWeekRange.find(
							({date}) => date === formatMomentDateForUrl(moment(item.propertyTimestamp).tz(locationDetailsService.getLocationTimezone()))
						);

						if (rangeItem) {
							const statusListItem = testItem.statusList[$scope.twoWeekRange.indexOf(rangeItem)];
							const performanceIndicatorName = Object.keys(STATUS.MODE).find(
								statusMode => statusMode.toLowerCase() === item.performanceIndicatorPerDay.toLowerCase()
							);
							const mode = STATUS.MODE[performanceIndicatorName] || STATUS.MODE['Insufficient Data'];
							statusListItem.className = mode.className;
							statusListItem.title = mode.title;
							statusListItem.date = rangeItem.title;
							statusListItem.dateTo = rangeItem.date;
						}
					});
			});
	}

	function findParentComponentObject(tisObject) {
		let parent = locationEquipmentService.searchParentTisObject(tisObject, tisObjectsHierarchy, null);
		for (
			let parentTypeClassification = helpers.getPropertyByPath(parent, 'tisObjectType.tisObjectTypeClassification');
			parentTypeClassification !== 'ComponentObject';
			parentTypeClassification = helpers.getPropertyByPath(parent, 'tisObjectType.tisObjectTypeClassification')
		) {
			parent = locationEquipmentService.searchParentTisObject(parent, tisObjectsHierarchy, null);
		}
		return parent;
	}

	function saveChillerSubComponents(tisObjects = []) {
		tisObjects.forEach(function(tisObject) {
			if (chillerSubComponents[tisObject.tisObjectType.tisObjectTypeGroupName]) {
				chillerSubComponents[tisObject.tisObjectType.tisObjectTypeGroupName].push(tisObject);
			}
			if (tisObject.children) {
				saveChillerSubComponents(tisObject.children);
			}
		});
	}

	function getUniqueAutomatedTestSelector(automatedTest) {
		return automatedTest.serviceAdvisoryTypeId + '$' + (automatedTest.subComponentTisObjectId || automatedTest.tisObjectId);
	}

	$scope.weather = false;
	$scope.summaryVisible = true;
	$scope.twoWeekRange = [];
	$scope.showLegend = {
		text: false,
		date: '',
		element: false,
	};
	$scope.serviceAdvisoryTypeIDs = [];
	$scope.automatedTestsCollection = {};
	$scope.showGenericCauses = true;

	// Progress / Loading
	$scope.locationDetailsDeferred = $q.defer();
	$scope.locationDetailsPromise = $scope.locationDetailsDeferred.promise;
	$scope.serviceAdvisoryTypesDeferred = $q.defer();
	$scope.serviceAdvisoryTypesPromise = $scope.serviceAdvisoryTypesDeferred.promise;
	$scope.locationEquipmentObjectsDeferred = $q.defer();
	$scope.locationEquipmentObjectsPromise = $scope.locationEquipmentObjectsDeferred.promise;
	$scope.loadingPromises = [$scope.serviceAdvisoryTypesPromise, $scope.locationDetailsPromise, $scope.locationEquipmentObjectsPromise];
	$scope.progress = 0;
	$scope.automatedTestsLoaded = false;

	progressIncrement = 100 / ($scope.loadingPromises.length + 1);

	$scope.showViewsDialog = function(item) {
		let suggestion = getSuggestionByServiceAdvisoryTypeActionName({id: item.actionName});
		modalHelperService.open({
			templateUrl: 'components/service-advisory/view-automated-test.html',
			controller: 'ViewAutomatedTestCtrl',
			backdrop: 'static',
			scope: $scope,
			resolve: {
				suggestion: function() {
					return suggestion;
				},
			},
		});
	};

	$scope.toggleLegend = function(status, event) {
		if (!$scope.showLegend.element) {
			$scope.showLegend.element = document.getElementsByClassName('bottom-tooltip')[0];
		}

		$scope.showLegend.text = status ? $filter('translate')(status.title) : false;

		if (!status) {
			return;
		}

		const MIN_WIDTH = 140;
		const MIN_HEIGHT = 40;
		const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
		const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
		const clientX = event.clientX;
		const clientY = event.clientY;
		const x =
			w - clientX - MIN_WIDTH > 0
				? {
						left: `${clientX}px`,
						right: null,
				  }
				: {
						left: null,
						right: `${w - clientX}px`,
				  };
		const y = h - (clientY + 10) - MIN_HEIGHT > 0 ? `${clientY + 10}px` : `${clientY - MIN_HEIGHT - 10}px`;

		if (status.className === 'invalid-application') {
			$scope.showLegend.text = $filter('translate')('AGGREGATED_STATUSES.NOT_APPLICABLE');
		}

		$scope.showLegend.date = status.date;

		// FIX for IE
		$scope.showLegend.element.style.top = y;
		$scope.showLegend.element.style.left = x.left;
		$scope.showLegend.element.style.right = x.right;
	};

	$scope.updateBetaText = function(selector) {
		let list = document.querySelectorAll(selector + ' li.beta');
		let betaText = $filter('translate')('BETA_TEXT');

		for (let i = 0; i < list.length; i++) {
			list[i].setAttribute('title', betaText);
		}
	};
	$scope.closeCalendar = function() {
		$timeout(() => {
			document.body.click();
		}, 0);
	};

	$scope.openCalendar = function() {
		if (angular.element(document.getElementById('equipmentDataRangeCalendar').parentNode).hasClass('open')) return;
		setTimeout(function() {
			document.getElementById('equipmentDataRangeCalendar').dispatchEvent(
				new MouseEvent('click', {
					view: window,
				})
			);
		}, 10);
	};

	$scope.sortParams = [
		{
			column: 'actionNameTitle',
			order: true,
		},
	];

	$scope.nextPeriod = helpers.debounce(function() {
		$scope.rangeOffset -= DAYS_IN_TWO_WEEKS;
		$scope.rangeOffset = Math.max($scope.rangeOffset, 0);
		locationSettings.rangeOffset = $scope.rangeOffset;
		updateRange();
		urlService.changeDateRangeInUrl($scope.from, $scope.to.clone().add(-1, 'days'));
		loadAggregatedServiceAdvisories();

		$scope.trackOnLinkClick(EQUIPMENT_SUMMARY_PAGE_EVENTS.CLICK_ON_PAGINATION_OF_TEST_RESULTS, {
			[EQUIPMENT_SUMMARY_PAGE_PROPERTIES.DIRECTION]: 'Forward',
		});
	}, 200);

	$scope.prevPeriod = helpers.debounce(function() {
		$scope.rangeOffset += DAYS_IN_TWO_WEEKS;
		locationSettings.rangeOffset = $scope.rangeOffset;
		updateRange();
		urlService.changeDateRangeInUrl($scope.from, $scope.to.clone().add(-1, 'days'));
		loadAggregatedServiceAdvisories();

		$scope.trackOnLinkClick(EQUIPMENT_SUMMARY_PAGE_EVENTS.CLICK_ON_PAGINATION_OF_TEST_RESULTS, {
			[EQUIPMENT_SUMMARY_PAGE_PROPERTIES.DIRECTION]: 'Backward',
		});
	}, 200);

	$scope.getAutomatedTests = function(searchText) {
		if (!$scope.automatedTests) {
			return undefined;
		}
		function byVisibleProperties(test) {
			let testTitle = (test.actionNameTitle || '').toLowerCase();
			return testTitle.indexOf(searchText.toLowerCase()) !== -1;
		}

		let data = searchText ? $scope.automatedTests.filter(byVisibleProperties) : $scope.automatedTests;
		data = $filter('tableFilter')(data, this.filterParams);

		$scope.toggleSuppression.canToggleSuppression = false;
		data.some(test => {
			if (test.testStatus && !test.testStatus.isNotSuppressed) {
				$scope.toggleSuppression.canToggleSuppression = true;
				return true;
			}
		});
		return data;
	};

	$scope.fromText = '';
	$scope.toText = '';
	// Crap here, refactor asap

	// Condition to check if app has date set when navigating from other page
	if (!$rootScope.appDateRange) {
		$scope.to = moment()
			.tz(locationDetailsService.getLocationTimezone())
			.startOf('day');
		$scope.from = moment()
			.tz(locationDetailsService.getLocationTimezone())
			.startOf('day');
	} else {
		$routeParams.endDate = moment($rootScope.appDateRange.endDate).format('MM-DD-YYYY');
		$scope.to = moment($rootScope.appDateRange.endDate);
		$scope.rangeOffset = calculateRangeOffset($scope.to);
		locationSettings.rangeOffset = $scope.rangeOffset;
		updateRange();
		urlService.changeDateRangeInUrl($scope.from, $scope.to.clone());
		loadAggregatedServiceAdvisories();
	}
	$scope.maxDt = moment()
		.tz(locationDetailsService.getLocationTimezone())
		.startOf('day');

	$timeout(incrementProgressIndicator, 500);

	locationDetailsService.getLocationDetailsWithoutServiceAdvisories($routeParams.locationId).then(function(data) {
		[$scope.location] = data.locationSummaryList;
		const {address: addr, facilityId, locationId, organizationId} = $scope.location;
		$scope.automatedTestSettingsLink = externalLinks.automatedTestSettingsLink({accountId: organizationId, locationId, tisObjectId: $scope.equipmentId});
		$scope.automatedTestSuppressionsLink = externalLinks.automatedTestSuppressionsLink({accountId: organizationId, locationId});
		$scope.addressLine = '';

		if (addr && addr.line1) {
			$scope.addressLine =
				addr.line1 + (addr.city ? ', ' + addr.city : '') + ', ' + (addr.region || '') + ' ' + (addr.postalCode || '') + ' ' + (addr.country || '');
		}

		locationEquipmentService.getLocationObjectsList($routeParams.locationId, null, true).then(function(data) {
			let groupNames = [];

			tisObjectsHierarchy = data.tisObjectList;
			$scope.equipment = $filter('filterNested')(tisObjectsHierarchy, {tisObjectId: parseInt($routeParams.equipmentId)}, true).pop();

			if ($scope.equipment && helpers.getPropertyByPath($scope.equipment, 'tisObjectType.tisObjectTypeClassification') === 'Component') {
				let parent = findParentComponentObject($scope.equipment);
				if (parent) {
					$route.updateParams({equipmentId: parent.tisObjectId});
				}
			}

			const {tisObjectId, tisObjectName} = $scope.equipment;

			$scope.notesLink = externalLinks.notesLink({locationId, tisObjectName, facilityId});
			$scope.addNoteLink = externalLinks.addNoteLink({locationId, tisObjectId, facilityId});

			utilityService.getEnvironmentType().then(env => {
				const {objectId} = $scope.equipment;

				$scope.GEN3FacilitySummaryURL = `${GEN3_URL[env.toUpperCase()]}?linkParameters=|page=facility|facilityId=${facilityId}`;
				$scope.GEN3ReportsURL = `${GEN3_URL[env.toUpperCase()]}?linkParameters=|page=Reports|facilityId=${facilityId}|report=`;
				$scope.TCEquipmentSetupURL = `${TC_URL[env.toUpperCase()]}/equipment-setup/${objectId}?location=${locationId}&tab=3`;
			});

			$scope.navigation = [
				{
					href: '#/facility/' + $routeParams.locationId,
					title: $scope.location.locationName,
				},
			];
			$scope.pageTitle = {
				title: $scope.equipment.tisObjectName,
			};
			$scope.groupName = $scope.equipment.tisObjectType.tisObjectTypeGroupName;

			const workingTisObjectIds = [$scope.equipment.tisObjectId];

			if ($scope.groupName === 'Chiller') {
				groupNames = [$scope.groupName, 'Circuit', 'Compressor'];
				saveChillerSubComponents($scope.equipment.children);

				Object.keys(chillerSubComponents).forEach(key =>
					chillerSubComponents[key].forEach(equipment => workingTisObjectIds.push(equipment.tisObjectId))
				);
			} else {
				groupNames = [$scope.groupName];
			}

			incrementProgressIndicator($scope.locationEquipmentObjectsDeferred);
			const tisObjectsSatsPromise = Promise.all(workingTisObjectIds.map(tisObjectId => serviceAdvisoryService.getTypesByTisObjectId(tisObjectId))).then(
				tisObjectSats =>
					// Add tisObjectId to each SAT and return all SATs as one array
					helpers.flattenDeep(
						tisObjectSats.map((serviceAdvisoryTypes, index) => [
							...serviceAdvisoryTypes.map(sat => ({
								...sat,
								tisObjectId: workingTisObjectIds[index],
							})),
						])
					)
			);

			$q
				.all([allChartsServicePromise, tisObjectsSatsPromise])
				.then(function([chartList, serviceAdvisoryTypeList]) {
					let charts = {};
					chartList.forEach(function(chart) {
						if (chart && chart.tisObjectType && chart.tisObjectType.tisObjectTypeGroupNumber) {
							charts[chart.chartId] = chart.tisObjectType.tisObjectTypeGroupNumber;
						}
					});

					$scope.automatedTests = $filter('orderBy')(serviceAdvisoryTypeList, 'serviceAdvisoryTypeName');
					$scope.automatedTests = serviceAdvisoryService.filterOutAddonExceptionTests($scope.automatedTests);
					$scope.automatedTests = serviceAdvisoryService.filterOutIntermediateTests($scope.automatedTests).filter(automatedTest => {
						return automatedTest.tisObjectType && groupNames.includes(automatedTest.tisObjectType.tisObjectTypeGroupName);
					});

					$scope.automatedTests = $scope.automatedTests.map(function(item) {
						const itemType = item.tisObjectType.tisObjectTypeGroupNumber;
						const chart = chartList.find(({chartId}) => chartId === item.chartId);

						if (item.actionName) {
							item.actionNameTitle = $filter('translate')('AUTOMATED_TEST_ACTIONS.' + item.actionName);
							item.statusList = [];
							for (let i = 0; i < DAYS_IN_TWO_WEEKS; i++) {
								item.statusList.push({
									className: '',
									title: '',
								});
							}
							$scope.serviceAdvisoryTypeIDs.push(item.serviceAdvisoryTypeId);
						}
						if (item.chartId && chart) {
							const parentTisObject = tisObjectService.findParentTisObjectForChart(charts[item.chartId], item, tisObjectsHierarchy);
							if (chart.chartTypeLevel === CHART_TYPE_LEVEL.TIS_OBJECT && itemType !== charts[item.chartId] && !parentTisObject) {
								let tisObjectTypeName = item.tisObjectType.tisObjectTypeGroupName;
								let isChillerSubComponentPresent =
									chillerSubComponents && chillerSubComponents[tisObjectTypeName] && chillerSubComponents[tisObjectTypeName].length;
								if (tisObjectTypeName !== 'Circuit' && tisObjectTypeName !== 'Compressor' && !isChillerSubComponentPresent) {
									item.chartId = null;
									item.noChartInRelationship = true;
								}
							}

							item.chartLink =
								item.chartId &&
								serviceAdvisoryService.generateChartLink(
									{tisObjectId: Number($scope.equipmentId), locationId: Number($scope.locationId)},
									item,
									{tisObjectsHierarchy, chartList}
								);
						}
						return item;
					});

					$scope.automatedTests.forEach(processCircuitAndCompressorAutomatedTests);

					function processCircuitAndCompressorAutomatedTests(automatedTest) {
						const {tisObjectTypeGroupName} = automatedTest.tisObjectType;
						if (tisObjectTypeGroupName === 'Circuit' || tisObjectTypeGroupName === 'Compressor') {
							if (!equipments.includes(automatedTest.tisObjectId)) {
								equipments.push(automatedTest.tisObjectId);
							}
							if (chillerSubComponents[tisObjectTypeGroupName].length > 1) {
								automatedTest.actionNameTitle = $filter('translateTest')(automatedTest.actionName, {
									name: $filter('translate')(
										helpers.getPropertyByPath(
											chillerSubComponents[tisObjectTypeGroupName].find(({tisObjectId}) => tisObjectId === automatedTest.tisObjectId),
											'instance'
										)
									),
								});
							} else {
								automatedTest.actionNameTitle = $filter('translateTest')(automatedTest.actionName, {
									name: tisObjectTypeGroupName === 'Circuit' ? TIS_GROUP_NAMES_SHORT.CIRCUIT : TIS_GROUP_NAMES_SHORT.COMPRESSOR,
								});
							}
							automatedTest.subComponentParentTisObjectId = $scope.equipment.tisObjectId;
							automatedTest.subComponentTisObjectId = automatedTest.tisObjectId;
						}
					}

					$scope.automatedTests.forEach(function(automatedTest) {
						let selector = getUniqueAutomatedTestSelector(automatedTest);

						if (automatedTest.serviceAdvisoryTypeId && automatedTest.statusList) {
							$scope.automatedTestsCollection[selector] = automatedTest;
						}
					});

					$scope.automatedTestsLoaded = true;

					updateTestStatusColumn();

					getOtherTISObjectInfo();
					incrementProgressIndicator($scope.serviceAdvisoryTypesDeferred);
					equipments.push($scope.equipmentId);
					equipments = equipments.join(',');

					locationDetailsService
						.getLocationDetailsWithoutServiceAdvisories($routeParams.locationId)
						.then(checkRouteParamDates)
						.then(loadAggregatedServiceAdvisories);
				})
				.catch(errorHandler.showErrorMessage);

			workingTisObjectIds.map(tisObjectId => {
				locationEquipmentService.getEquipmentServiceAdvisoryCount(tisObjectId, function(result) {
					if ($scope.equipmentServiceAdvisoryCount) {
						$scope.equipmentServiceAdvisoryCount =
							result.serviceAdvisoryStatisticsList[0].serviceAdvisoriesCount + $scope.equipmentServiceAdvisoryCount;
					} else {
						$scope.equipmentServiceAdvisoryCount = result.serviceAdvisoryStatisticsList[0].serviceAdvisoriesCount;
					}
				});
			});

			locationEquipmentService.getEquipmentNotesCount($scope.equipment.tisObjectId, function(count) {
				$scope.equipmentNotesCount = count;
			});
		});

		locationDetailsService.getWeatherForLocation($scope.location).then(function(weatherObj) {
			$scope.weatherLoading = false;
			$scope.weather = weatherObj;
			if (weatherObj.lastCollected) {
				$scope.weather.lastCollected = weatherObj.lastCollected.tz(locationDetailsService.getLocationTimezone()).format('LLLL');
			} else {
				$scope.weather.lastCollected = $translate('N/A');
			}
			$scope.weather.isMoreWeather = false;
			$scope.weatherNA = typeof $scope.weather === 'undefined';
		});

		$scope.loadtime = moment()
			.tz(locationDetailsService.getLocationTimezone())
			.format('LLLL');
		$scope.locationSearchURL = 'https://www.google.com/maps/search/' + $scope.addressLine.replace(/,/g, '');
		updateRange();
		incrementProgressIndicator($scope.locationDetailsDeferred);
	});

	function getOtherTISObjectInfo() {
		const properties = {
			UnitSerialNumber: {
				name: 'UnitSerialNumber',
				sign: '~',
			},
			UnitModelNumber: {
				name: 'UnitModelNumber',
				sign: '~',
			},
		};

		const params = {
			ids: [$scope.equipment.tisObjectId],
			hpath: Object.values(properties).map(property => `${property.sign}${property.name}|LATEST()`),
			enumerations: '',
			from: moment($scope.from),
			to: moment($scope.to),
			timeZone: locationDetailsService.getLocationTimezone(),
		};

		tisObjectService.getAllValuesByIds(params).then(({tisObjectDataList: [{parameters}]}) => {
			const values = parameters.reduce((obj, {name, values: [value = {}], sourceUnitOfMeasure}) => {
				obj[name] = {name, value: value.value, sourceUnitOfMeasure};
				return obj;
			}, {});

			$scope.serialNumberLoading = false;
			$scope.serialNumber = values.UnitSerialNumber.value ? values.UnitSerialNumber.value : $translate('N/A');

			$scope.modelNumberLoading = false;
			$scope.modelNumber = values.UnitModelNumber.value ? values.UnitModelNumber.value : $translate('N/A');
		});
	}

	/**
	 * Updates test status table column
	 */
	function updateTestStatusColumn() {
		const checkIsSuppressed = (analyticParameterValue = null) => (analyticParameterValue === null ? false : Boolean(Number(analyticParameterValue)));

		$scope.automatedTests.forEach(test => {
			const suppressionAnalyticParameterValue = helpers.getPropertyByPath(test, 'suppressionAnalyticParameter.values[0]');
			const isSuppressed = suppressionAnalyticParameterValue ? checkIsSuppressed(suppressionAnalyticParameterValue.analyticParameterValue) : false;

			test.testStatus = {
				isNotSuppressed: !isSuppressed,
				text: $filter('translate')(isSuppressed ? 'SUPPRESSED' : 'ACTIVE'),
			};
		});

		$scope.$broadcast('treeGridLoaded');
	}

	/**
	 * @param automatedTest automated test object
	 * @returns {boolean} true if row should be shown.
	 */
	function suppressionFilter(automatedTest) {
		if (!automatedTest.testStatus) {
			return true;
		}

		return !(!automatedTest.testStatus.isNotSuppressed && !$scope.isShowSuppressed);
	}

	function calculateRangeOffset(toDate) {
		let to = moment(+toDate.format('x'));
		let diffWithNowInMs = moment().diff(to);
		return Math.abs(Math.ceil(diffWithNowInMs / MILLISECONDS_IN_DAY));
	}

	$scope.$on('$routeChangeStart', function() {
		try {
			modalInstance.dismiss();
		} catch (err) {
			// Ask Anriy why do we need to catch it.
		}
	});

	$scope.$watch('from', function() {
		if (watchDate) {
			$scope.rangeOffset = calculateRangeOffset($scope.to);

			// if endDate is invalid, use startDate to calculate range offset
			if (!$scope.to.isValid() && $scope.from.isValid()) {
				$scope.rangeOffset = calculateRangeOffset($scope.from) - DAYS_IN_TWO_WEEKS;
			}

			locationSettings.rangeOffset = $scope.rangeOffset;
			updateRange();
			urlService.changeDateRangeInUrl($scope.from, $scope.to.clone().add(-1, 'days'));
			loadAggregatedServiceAdvisories();
		}
	});

	function toggleSuppression() {
		if (!toggleSuppression.canToggleSuppression) {
			return;
		}
		$scope.isShowSuppressed = !$scope.isShowSuppressed;
		toggleSuppression.isShowSuppressed = $scope.isShowSuppressed;

		// TODO: add function updateState(state, updateFunction)
		let storedState = uiStateService.getState($scope.uiStateKey);
		storedState.isShowSuppressed = $scope.isShowSuppressed;
		uiStateService.registerState($scope.uiStateKey, storedState);
	}
}

function ViewAutomatedTestCtrl($scope, $modalInstance, suggestion) {
	$scope.suggestion = suggestion;
	$scope.cancel = function() {
		$modalInstance.dismiss('cancel');
	};
}
