/* eslint camelcase: 0*/

import {USER_EVENTS} from '../usage-tracking/categories';

const {PAGE_LOAD: {categoryName: PAGE_LOAD_CATEGORY_NAME, events: PAGE_LOAD_EVENTS, properties: PAGE_LOAD_PROPERTIES}} = USER_EVENTS;

const APP_NAME = 'TCC4';

let activePage = {};
export default class MetricsFactory {
	constructor(API_ENDPOINTS, DEFAULTS, $injector, $filter, $location, $rootScope) {
		const METRIC_TYPES = {
			RDR_LOAD: 'rdr_load',
			EHR_LOAD: 'ehr_load',
			CPR_LOAD: 'cpr_load',
			DIR_LOAD: 'dir_load',
			PAGE_LOAD: 'page_load',
			REPORT_LOAD: 'report_load',
			CHART_LOAD: 'chart_load',
			FACILITY_PERFORMANCE_CHART_LOAD: 'facility_performance_chart_load',
		};
		const REPORTS_PATH_NAMES = {
			[METRIC_TYPES.RDR_LOAD]: 'Raw Data Report',
			[METRIC_TYPES.EHR_LOAD]: 'Exception History Report',
			[METRIC_TYPES.CPR_LOAD]: 'Chiller Performance Report',
			[METRIC_TYPES.DIR_LOAD]: 'Digital Inspection Report',
			[METRIC_TYPES.FACILITY_PERFORMANCE_CHART_LOAD]: 'Facility Performance Chart',
		};

		Object.assign(this, {
			$injector,
			$filter,
			$location,
			$rootScope,
			API_ENDPOINTS,
			DEFAULTS,
			entries: {},
			isFirstMark: true,
			METRIC_TYPES,
			REPORTS_PATH_NAMES,
			usageTrackingService: () => {
				throw new Error('The method is not implemented');
			},
		});
	}

	getUsageTrackingService(service) {
		this.usageTrackingService = service;
	}

	/**
	 * Creates new metric
	 * @param {string} name
	 */
	mark(name = this.METRIC_TYPES.PAGE_LOAD, data = null) {
		if (!this.entries[name]) {
			window.performance.mark(name);

			this.entries[name] = new Metric({
				routeChanges: 0,
				name,
				data,
				startTime: window.performance.getEntriesByName(name, 'mark')[0].startTime,
			});
		} else {
			this.entries[name].routeChanges += 1;
		}
	}

	/**
	 * Calculates metric duration and store data to server
	 * @param {string} name
	 */
	measure(name = this.METRIC_TYPES.PAGE_LOAD) {
		if (this.entries[name]) {
			window.performance.measure(name);

			this.entries[name].endTime = window.performance.getEntriesByName(name, 'measure')[0].duration;

			this.sendMetrics(name).finally(() => {
				delete this.entries[name];

				if (Object.keys(this.entries).length === 0) {
					window.performance.clearMarks();
					window.performance.clearMeasures();
					window.performance.clearResourceTimings();
				}
			});
		}
	}

	/**
	 * Sends request to save metrics
	 * @param {string} name
	 */
	sendMetrics(name) {
		const $http = this.$injector.get('$http');
		const $route = this.$injector.get('$route');

		const {startTime, endTime, data} = this.entries[name];
		const regPath = new RegExp(`(${$route.current.keys.map(({name}) => ':' + name).join('|') || null})`, 'g');
		const fields = {
			// prettier-ignore
			'duration_ms': this.isFirstMark ? endTime : endTime - startTime
		};

		let {username, firstName, lastName} = this.$rootScope.$storage.userData;
		const hostname = this.$location.host();
		const version = this.$rootScope.versionData.version;
		let tags = {
			hostname,
			app: APP_NAME,
			version,
			user: username,
			user_name: `${firstName} ${lastName}`,
		};

		const pageName = $route.current.$$route.metricsConfig.pathName;

		switch (name) {
			case this.METRIC_TYPES.PAGE_LOAD:
				Object.assign(tags, {
					key: this.METRIC_TYPES.PAGE_LOAD,
					path_template: $route.current.originalPath.replace(regPath, '{id}'),
					path_name: pageName || null,
					original_path: this.$location.$$absUrl,
				});
				break;
			case this.METRIC_TYPES.RDR_LOAD:
			case this.METRIC_TYPES.EHR_LOAD:
			case this.METRIC_TYPES.CPR_LOAD:
				Object.assign(tags, {
					key: this.METRIC_TYPES.REPORT_LOAD,
					path_template: null,
					path_name: this.REPORTS_PATH_NAMES[name],
					original_path: null,
					report_type: data.report_type || null,
					objects_count: data.objects_count || null,
					properties_count: data.properties_count || null,
					range_type: data.range_type ? this.$filter('translate')('RANGE_' + data.range_type) : null,
					range_duration: data.range_duration || null,
				});
				break;
			case this.METRIC_TYPES.DIR_LOAD:
				Object.assign(tags, {
					key: this.METRIC_TYPES.REPORT_LOAD,
					path_template: null,
					path_name: this.REPORTS_PATH_NAMES[name],
					original_path: null,
					report_type: data.report_type || null,
					objects_count: data.objects_count || null,
					properties_count: data.properties_count || null,
					range_type: data.range_type ? this.$filter('translate')('RANGE_' + data.range_type) : null,
					range_duration: data.range_duration || null,
				});
				break;
			case this.METRIC_TYPES.FACILITY_PERFORMANCE_CHART_LOAD:
				Object.assign(tags, {
					key: this.METRIC_TYPES.CHART_LOAD,
					path_template: null,
					path_name: this.REPORTS_PATH_NAMES[name],
					original_path: null,
					facilities_count: data.facilities_count || null,
					equipment_name: data.equipment_name || null,
					equipment_id: data.equipment_id || null,
					chart_id: data.chart_id || null,
					chart_name: data.chart_name || null,
					range_type: data.range_type ? this.$filter('translate')('RANGE_' + data.range_type) : null,
					range_duration: data.range_duration || null,
				});
				break;
		}

		// should log events only if the page completes loading.
		const isPage = $route.current.$$route.metricsConfig.isPage;

		// Check is prevent logging events if any url changes in same loaded pages i.e loading chart on performance chart page, opening up any report model
		if (isPage && activePage && activePage.name !== pageName) {
			activePage = {
				name: pageName,
				logEvent: true,
			};
		} else {
			activePage = {
				name: pageName,
				logEvent: false,
			};
		}
		if (activePage.logEvent) {
			this.usageTrackingService.trackEvent(PAGE_LOAD_CATEGORY_NAME, PAGE_LOAD_EVENTS.PAGE_LOAD, {
				[PAGE_LOAD_PROPERTIES.PAGE_LOAD_TIME]: fields.duration_ms,
				[PAGE_LOAD_PROPERTIES.PATH_TEMPLATE]: $route.current.originalPath.replace(regPath, '{id}'),
				[PAGE_LOAD_PROPERTIES.PATH_NAME]: pageName || null,
				[PAGE_LOAD_PROPERTIES.ORIGINAL_PATH]: this.$location.$$absUrl,
				[PAGE_LOAD_PROPERTIES.HOST_NAME]: hostname,
				[PAGE_LOAD_PROPERTIES.APP_NAME]: APP_NAME,
				[PAGE_LOAD_PROPERTIES.VERSION]: version,
				[PAGE_LOAD_PROPERTIES.USER_ID]: username,
				[PAGE_LOAD_PROPERTIES.USER_NAME]: `${firstName} ${lastName}`,
			});
		}

		this.isFirstMark = false;

		return $http.post(this.API_ENDPOINTS.tisMetrics, {tags, fields}).then(response => {
			return response.data;
		});
	}
}

class Metric {
	constructor(config) {
		const {routeChanges = 0, name, data, startTime = -1, endTime = -1} = config;

		Object.assign(this, {name, data, routeChanges, _startTime: startTime, _endTime: endTime});
	}

	get startTime() {
		return this._startTime;
	}

	set startTime(time) {
		this._startTime = time;
	}

	get endTime() {
		return this._endTime;
	}

	set endTime(time) {
		this._endTime = time;
	}
}
