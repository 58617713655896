const services = new WeakMap();

class SecureImageController {
	constructor($http, authorization, helpers, $location) {
		services.set(this, {$location, $http, authorization, helpers});
		this._showImage = false;
	}

	async $onInit() {
		this.src = this.getSrcAsBlob(await this.loadImageAsBlob());
		this._showImage = true;
	}

	getSrcAsBlob(blob) {
		return URL.createObjectURL(blob);
	}

	shouldAuthHeaderSend(url) {
		const {helpers} = services.get(this);
		return helpers.isTraneDomainOrLocalhost(url);
	}

	async loadImageAsBlob() {
		const {$location, $http, authorization} = services.get(this);
		const {src, defaultSrc} = this;

		let url = undefined;
		if (src) {
			const protocol = $location.protocol();
			const isLocalHost = $location.host().includes('localhost') || $location.host().includes('127.0.0.1');
			url = new URL(src);
			if (!isLocalHost) {
				url.protocol = protocol;
			}
		}
		try {
			return (await $http.get((url && url.toString()) || defaultSrc, {
				headers: src && this.shouldAuthHeaderSend(src) ? {Authorization: `token ${authorization.getToken()}`} : undefined,
				responseType: 'blob',
			})).data;
		} catch (e) {
			if (src) {
				return (await $http.get(defaultSrc, {responseType: 'blob'})).data;
			}
		}
	}
}

export default {
	templateUrl: 'common/secure-image/secure-image.html',
	controller: SecureImageController,
	bindings: {
		src: '<',
		defaultSrc: '<',
	},
};
