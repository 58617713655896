angular
	.module('TISCC')
	.service('chartService', function(
		$http,
		$q,
		$filter,
		cacheService,
		serviceAdvisoryService,
		locationEquipmentService,
		CHART_TYPE,
		stateBasedHpathService,
		instanceBasedChartService,
		handleServiceWorker
	) {
		let that = this;

		that.getAvailableCharts = function(selectedTypeNumber, selectedEquipment) {
			function bySelectedTypeNumber(chart) {
				let chartTisObjectType;

				if (chart.tisObjectType) {
					chartTisObjectType = chart.tisObjectType.tisObjectTypeGroupNumber;
				}

				const chartTypeIsSameAsSelectedType = chartTisObjectType === selectedTypeNumber;
				const isTisObjectChart = chart.chartTypeLevel === 'TisObject';

				return isTisObjectChart && chartTypeIsSameAsSelectedType;
			}

			function filterCharts(av, cv) {
				const isSelectedType = bySelectedTypeNumber(cv);

				if (!isSelectedType) return av;

				const compressorTypeCharts = instanceBasedChartService.createInstanceChart(cv, selectedEquipment);

				av = av.concat(compressorTypeCharts ? compressorTypeCharts : cv);

				return av;
			}

			return that.getAllCharts().then(chartList => chartList.reduce(filterCharts, []));
			// return that.getAllCharts().then(chartList => chartList.filter(bySelectedTypeNumber));
		};

		that.getAllCharts = function() {
			const config = {
				cache: cacheService.getCacheInstance('chartService', 'AllCharts'),
				headers: handleServiceWorker.createServiceWorkerCacheKeyForRequest({
					cacheKey: 'all-charts',
					expiryTime: handleServiceWorker.CACHE_EXPIRE_TIME.IN_3_DAYS,
				}),
			};

			return $http.get('/ext_api/api/chart', config).then(resp => resp.data.chartList);
		};

		that.getAllFacilityCharts = function(locationIds, tisObjectTypeGroupName, allowThisGroupNames = []) {
			function isNeededTisObjectType(objTisObjectType, chartTypeLevel, desiredTisObjectTypeGroupName) {
				if (!desiredTisObjectTypeGroupName) {
					return true;
				}

				if (desiredTisObjectTypeGroupName === 'FACILITY') {
					return isWeatherChart({objTisObjectType, chartTypeLevel});
				}

				if (objTisObjectType) {
					// Some charts will pass selected equipment child objects group name like Chiller, to display all objects details
					if (Array.isArray(allowThisGroupNames) && allowThisGroupNames.includes(objTisObjectType.tisObjectTypeGroupName)) {
						return true;
					}

					return objTisObjectType.tisObjectTypeGroupName === desiredTisObjectTypeGroupName;
				}

				return false;
			}

			function byTisObjectTypeGroupNumber(obj) {
				return isNeededTisObjectType(obj.tisObjectType, obj.chartTypeLevel, tisObjectTypeGroupName);
			}

			function isWeatherChart(chart) {
				return !chart.tisObjectType && chart.chartTypeLevel === 'Location';
			}

			function handleResponsesData(responses) {
				const [locationObjectsLists, serviceAdvisoryTypesList, chartsList] = responses;

				const equipmentList = that.addUniqueTisObjectToList(locationObjectsLists, [], []) || [];
				const uniqueTypeList = that.getUniqueTypeList(equipmentList, allowThisGroupNames);
				const serviceAdvisoryTypes = serviceAdvisoryTypesList.filter(t => uniqueTypeList.includes(t.tisObjectType.tisObjectTypeGroupName));
				serviceAdvisoryTypes.forEach(t => {
					t.title = $filter('translateTest')(t.actionName);
					t.chartTypeName = CHART_TYPE.PARETO;
					t.chartTypeLevel = 'TisObject';
				});
				const weatherCharts = chartsList.filter(item => isWeatherChart(item));
				return [...weatherCharts, ...serviceAdvisoryTypes].filter(byTisObjectTypeGroupNumber);
			}

			if (!(locationIds instanceof Array)) {
				locationIds = [locationIds];
			}
			const locationObjectsListPromises = $q
				.all(locationIds.map(locationId => locationEquipmentService.getLocationObjectsList(locationId, null, true)))
				.then(data => {
					return data.reduce((tisObjectList, value) => {
						tisObjectList = tisObjectList.concat(value.tisObjectList);
						return tisObjectList;
					}, []);
				});
			const allServiceAdvisoryTypesServicePromise = serviceAdvisoryService.getAllTypes();
			const allChartsPromise = that.getAllCharts();

			return $q.all([locationObjectsListPromises, allServiceAdvisoryTypesServicePromise, allChartsPromise]).then(handleResponsesData);
		};

		that.getFacilityChartData = function(locationId, hpath, range) {
			return $http
				.get('/ext_api/api/location/' + locationId + '/data', {
					params: {
						hpath: hpath,
						startDate: range.from.format(),
						endDate: range.to.format(),
						timeZone: range.timeZone,
						interval: 'PT15m',
					},
					headers: {
						Accept: 'application/json',
						'Hydration-Strategy-Default': 'nullcopyforward',
						'Missing-Updates-Default': 'skip',
						'Filter-Special-Mark-Values': false,
					},
				})
				.then(resp => resp.data.locationDataList[0]);
		};

		that.getChartMetaData = function(id, instanceName) {
			let config = {
				cache: cacheService.getCacheInstance('chartService', 'Chart', id),
				headers: handleServiceWorker.createServiceWorkerCacheKeyForRequest({
					cacheKey: 'chartService_Chart_/ext_api/api/chart/' + id,
					expiryTime: handleServiceWorker.CACHE_EXPIRE_TIME.IN_1_DAY,
				}),
			};

			return $http.get('/ext_api/api/chart/' + id, config).then(function(data) {
				const chartMetadata = data.data.chartList[0];

				const hpath = {
					facility: new Set(),
					tisObject: new Set(),
				};

				chartMetadata.chartAxisList.forEach(function(o) {
					if ((o.hpath || '').includes('CustomHpath-')) {
						o.hpath = updateStateBasedHpath(o.hpath, stateBasedHpathService, instanceName);
					}

					if (o.tisObjectType) {
						hpath.tisObject.add(o.hpath);
					} else {
						hpath.facility.add(o.hpath);
					}
				});

				const mergedChartAxisList = mergeSameAxes(chartMetadata.chartAxisList);

				// remove axis that doesnt have any value on hpath to display on chart
				// For timeline, there is an internal logic implemented to hide legends if no hpaths available
				const chartAxisList = (mergedChartAxisList || []).filter(v => v.axisName === 'timeline' || !!(v.hpath || '').length);

				return {
					hpath,
					chartAxisList,
					chartTypeName: chartMetadata.chartTypeName,
					hasAddPropsSupport: chartMetadata.editable || false,
				};
			});
		};
		that.addUniqueTisObjectToList = function(tisObjects, list, listIds, parentId) {
			tisObjects.forEach(function(tisObject) {
				if (listIds.indexOf(tisObject.tisObjectId) === -1) {
					tisObject.parentId = parentId || null;
					listIds.push(tisObject.tisObjectId);
					list.push(tisObject);
				}
				if (tisObject.children) {
					that.addUniqueTisObjectToList(tisObject.children, list, listIds, tisObject.tisObjectId);
				}
			});
			return list;
		};
		that.getUniqueTypeList = function(equipmentList, allowThisGroupNames = []) {
			// Modified this existing functionality to optimize it. No functionality change
			const excludeEquipmentTypeNames = {
				Circuit: true,
				Compressor: true,
			};

			let uniqueTypeList = [];

			equipmentList.reduce((av, cv) => {
				const type = (cv.tisObjectType || {}).tisObjectTypeGroupName;

				if (!type) return av;

				if (!av[type]) {
					av[type] = true;
					if (!excludeEquipmentTypeNames[type] || allowThisGroupNames.includes(type)) {
						uniqueTypeList.push(type);
					}
				}
				return av;
			}, {});
			return uniqueTypeList;
		};
	});

// Check axes are same or not
function isSameAxes(av, cv) {
	return av.symbol === cv.symbol && av.uomId === cv.uomId;
}

// Get axes name y2 <= y1 gives y1 as output
function getAxesName(avAxisName = '', cvAxisName = '') {
	if (!avAxisName || !cvAxisName) false;

	if (!avAxisName.startsWith('y') || !cvAxisName.startsWith('y')) return false;
	return avAxisName <= cvAxisName ? avAxisName : cvAxisName;
}

// Check axies has same tis object group name
// if object group is not same, let chart functionality to decide whether render or show error message
function isSameTisObjectType(av, cv) {
	if (!av || !cv) return null;

	return av.tisObjectTypeGroupName === cv.tisObjectTypeGroupName;
}

// check if axes have same unit of meause, merge those axes and give it as single axis
function mergeSameAxes(chartAxes = []) {
	const axes = chartAxes.reduce((av, cv) => {
		const {uomId, symbol} = cv.unitOfMeasure || {};
		let key = symbol + uomId + (cv.axisName || '').charAt(0);
		if (!av[key]) {
			av[key] = cv;
		} else {
			const val = av[key];
			const isSame = isSameAxes(val.unitOfMeasure, cv.unitOfMeasure);
			const axisName = getAxesName(val.axisName, cv.axisName);
			const isSameObjectType = isSameTisObjectType(val.tisObjectType, cv.tisObjectType);
			if (isSame && axisName && (isSameObjectType === null || isSameObjectType === true)) {
				const hpath = cv.hpath ? val.hpath + ',' + cv.hpath : val.hpath;
				av[key] = {...val, ...cv, hpath, axisName};
			} else {
				key = symbol + uomId + cv.axisName;
				av[key] = cv;
			}
		}
		return av;
	}, {});

	return Object.values(axes || {});
}

/**
 * (Immutable) function to update hpath based on state of components condition like compressor type, circuit.
 * @param {*} hPath
 * @param {*} stateBasedHpathService
 * @returns string<hPath>
 */
function updateStateBasedHpath(hPath, stateBasedHpathService, instance) {
	// chiller Validator
	const validator = id => {
		const state = stateBasedHpathService.getValidator(id);

		if (state === null) return;

		const chillerState = stateBasedHpathService.chillerState;
		const {chillerType, compressorType, cricuitType, compressorCountType} = stateBasedHpathService.getArgumentsValueForChillerValidator(chillerState);

		return state.test(chillerType, compressorType, cricuitType, compressorCountType, instance);
	};

	return updateCustomHpathOnAxiesIfAvailable(hPath, validator) || '';
}

/**
 *
 *  (Immutable) function to extract custom path from source, validate it against filters and update (add / remove) it on source hpaths
 *
 * @param {*} hPaths
 * @param {*} selectedEquipment
 * @returns []
 */
function updateCustomHpathOnAxiesIfAvailable(hPaths, isValidHpathFn) {
	if (!hPaths) return hPaths;

	const allHpaths = hPaths.split(',');

	const extractedHpaths = allHpaths.reduce(
		(av, cv) => {
			if (cv.startsWith('CustomHpath-')) av.custom.push(cv.replace('CustomHpath-', ''));
			else av.default.push(cv);

			return av;
		},
		{default: [], custom: []}
	);

	if (!extractedHpaths.custom.length) {
		return extractedHpaths.default.join();
	}

	const customHPathsForThisChart = extractedHpaths.custom.reduce((av, hPath) => {
		const splitConditionsAndHpath = hPath.split('$');
		if (isValidHpathFn(splitConditionsAndHpath[0])) {
			av.push(splitConditionsAndHpath[1]);
		}
		return av;
	}, []);

	return [].concat(extractedHpaths.default, customHPathsForThisChart).join(',');
}
