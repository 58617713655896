(function() {
	const services = new WeakMap();

	class ChartExportHeaderController {
		constructor($translate) {
			services.set(this, {$translate});
		}

		$onInit() {
			this._updateLabelLocation();
		}

		$onChanges(changes) {
			if (changes.selectedFacilities || changes.selectedLocation) {
				this._updateLabelLocation();
			}
		}

		_updateLabelLocation() {
			if (this.isFacilityChart || this.isParetoChart) {
				const {$translate} = services.get(this);

				if (this.selectedFacilities.length > 1) {
					this.labelLocation = {locationName: $translate('MULTIPLE_FACILITIES')};
				} else {
					this.labelLocation = this.selectedFacilities[0];
				}
			} else {
				this.labelLocation = this.selectedLocation;
			}
		}

		getMultipleFacilitiesNames() {
			const {selectedFacilities} = this;
			return selectedFacilities && selectedFacilities.map(facility => facility.locationName).join(', ');
		}

		checkIsMultipleFacilities() {
			return this.selectedFacilities && this.selectedFacilities.length > 1;
		}

		list() {
			return Array.prototype.slice.call(arguments, 0).filter(Boolean);
		}

		getParetoSortOrderNameForTranslating(sortOrder) {
			let result = sortOrder;

			switch (sortOrder) {
				case 'highToLow':
					result = 'PARETO_HIGH_TO_LOW';
					break;
				case 'lowToHigh':
					result = 'PARETO_LOW_TO_HIGH';
					break;
				case 'equipmentByDate':
					result = 'PARETO_EQUIPMENT_BY_DATE';
					break;
			}

			return result;
		}
	}

	angular.module('TISCC').component('chartExportHeader', {
		templateUrl: 'components/chart/chart-export-header/chart-export-header.html',
		controller: ChartExportHeaderController,
		bindings: {
			chartTypeName: '<',
			chartTypeLevel: '<',
			isFacilityChart: '<',
			isParetoChart: '<',
			selectedLocation: '<',
			selectedFacilities: '<',
			selectedChart: '<',
			selectedTypeLabel: '<', // getSelectedTypeLabel()
			tisObjectName: '<', // chartObj.selectedEquipment.tisObjectName || 'OPTIONS_SELECT_ONE'|translate
			range: '<',
			sortOrder: '<',
		},
	});
})();
