/* global jsonpipe:true*/

(() => {
	let _token;
	const EXPORT_STARTED = 'Export process has started';
	const ERROR_MESSAGES = {
		'401': 'Not Authenticated, try to log out and log in again',
	};
	angular.module('TISCC').service('exportProgressServiceServerless', [
		'$http',
		'$q',
		'$injector',
		'configService',
		'$timeout',
		'$localStorage',
		function($http, $q, $injector, configService, $timeout, $localStorage) {
			const that = this;
			that.REPORT_API_ENDPOINT = configService.getExportAPIConfig();
			that._token = null;
			that._progressKey = null;
			that._deferred = null;
			that.authorization = $injector.get('authorization');
			that.emailRquested = false;
			that.userCancelled = false;
			this.getProgressKey = function() {
				return that._progressKey;
			};

			this.cancel = async function() {
				that._deferred.resolve({
					userCancelled: true,
				});
				that.userCancelled = true;
			};

			this.sendAbortRequest = async progressKey => {
				const token = that.authorization.getToken();
				const headers = {
					Authorization: `token ${token}`,
					'Content-Type': 'application/x-www-form-urlencoded',
				};
				that.userCancelled = true;
				return await $http({
					method: 'DELETE',
					url: that.REPORT_API_ENDPOINT + '/export',
					headers,
					params: {
						progressKey,
					},
					withCredentials: false,
				});
			};

			this.startFlow = async function(additionalHeaders = {}) {
				if (that.emailRquested || that.userCancelled) return;
				try {
					const headers = {
						Authorization: `token ${that._token}`,
						'Content-Type': 'application/x-www-form-urlencoded',
					};
					Object.assign(headers, additionalHeaders);
					const response = await $http({
						method: 'GET',
						url: that.REPORT_API_ENDPOINT + '/export/status',
						params: {
							progressKey: that._progressKey,
						},
						headers,
						withCredentials: false,
					});
					if (response.status === 204) {
						$timeout(that.startFlow, 3000);
						that._deferred.notify({
							progressPercentage: 10,
							message: EXPORT_STARTED,
						});
					} else {
						const {data} = response;
						if (data.error) {
							that._deferred.reject(data);
							return false;
						}
						if (data.emailRequested || data.userCancelled) {
							that._deferred.resolve(data);
						} else if (data && data.progressPercentage) {
							if (data.progressPercentage !== 100) {
								$timeout(that.startFlow, 3000);
								that._deferred.notify(data);
							} else that._deferred.resolve(data);
						}
					}
				} catch (e) {
					that._deferred.reject(e);
				}
			};

			this.sendEmailRequest = async function(params = {}) {
				that.emailRquested = true;
				const headers = {
					Authorization: `token ${_token}`,
					'Content-Type': 'application/x-www-form-urlencoded',
				};
				const {email} = $localStorage.userData;
				const response = await $http({
					method: 'GET',
					url: that.REPORT_API_ENDPOINT + '/export/status',
					params: {
						emailAddress: email.toLowerCase(),
						...params,
					},
					headers,
					withCredentials: false,
				});
				that.emailRquested = false;
				return response;
			};
			this.open = function(key, headers = {}) {
				that._token = that.authorization.getToken();
				that.emailRquested = false;
				that.userCancelled = false;
				that._deferred = $q.defer();
				that._progressKey = key;
				that.startFlow(headers);
				return that._deferred.promise;
			};
		},
	]);
})();
