import {saveAs} from 'file-saver';

class FileSaver {
	constructor($http) {
		this.$http = $http;
	}

	downloadFromApi({url, method = 'POST', data = {}, onProgress}) {
		return this.$http({
			url,
			method,
			data,
			responseType: 'arraybuffer',
			onProgress:
				onProgress &&
				function({loaded, total}) {
					onProgress({
						loaded,
						total,
						loadedInPercents: Math.floor(loaded / total * 100),
					});
				},
		}).then(({data, headers}) => {
			const fileName = headers('content-disposition').split('filename=')[1];
			const contentType = headers('content-type');
			const blob = new Blob([data], {type: contentType});
			saveAs(blob, fileName);
		});
	}
}

angular.module('TISCC').service('fileSaverService', FileSaver);
