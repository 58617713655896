import _get from 'lodash.get';

angular
	.module('TISCC')
	.controller('AbstractReportCtrl', function(
		$scope,
		$filter,
		$timeout,
		$rootScope,
		$routeParams,
		$q,
		locationDetailsService,
		utilityService,
		ENVIRONMENT,
		DEFAULTS
	) {
		let that = this;
		let defaultEquipment = {name: $filter('translate')('OPTIONS_SELECT_ONE'), initValue: true};
		let timeZone = locationDetailsService.getLocationTimezone();
		let now = moment()
			.tz(timeZone)
			.startOf('day');

		$scope.optionAll = {
			name: $filter('translate')('FILTER_ALL'),
			id: 'all',
			groupId: 'all',
		};
		$scope.range = {};
		$scope.range.from = _get($rootScope, 'globalDateSelectionRange.rangeFrom', '') || $scope.data.rangeFrom || moment(now);
		$scope.range.to = _get($rootScope, 'globalDateSelectionRange.rangeTo', '') || $scope.data.rangeTo || moment(now).add(1, 'days');
		$scope.rangeEnd = $scope.data.rangeEnd || moment(now);
		$scope.maxDt = $scope.data.maxDt || moment(now);
		$scope.rangeMode = $scope.globalDateSelectionRange.rangeMode || $scope.data.rangeMode || '1 day';
		$scope.defaultEquipment = $scope.data.defaultSelection || null;
		$scope.locationId = $routeParams.locationId ? $routeParams.locationId : $scope.data.locationData.locationId;
		$scope.isEquipmentTypesLoading = true;
		$scope.updateSelectedEquipmentType = updateSelectedEquipmentType;
		$scope.processSubMenu = processSubMenu;
		$scope.changeExportData = changeExportData;
		$scope.submitExportForm = submitExportForm;
		$scope.submitExportFormServerless = submitExportFormServerless;
		that.checkEquipments = checkEquipments;
		$scope.downloadFileUsingS3 = downloadFileUsingS3;
		$scope.selectedEquipmentType = defaultEquipment;

		function checkEquipments(equipments, name) {
			equipments.forEach(function(equipment) {
				if (equipment.tisObjectName === name) {
					equipment.checked = true;
				}
				if (equipment.children) {
					checkEquipments(equipment.children, name);
				}
			});
		}

		function updateSelectedEquipmentType(equipmentType) {
			if (!equipmentType) {
				$scope.selectedEquipmentType = defaultEquipment;
			} else {
				$scope.selectedEquipmentType = equipmentType;
			}
			$scope.$broadcast('updateSelectedEquipmentType');
		}

		function changeExportData(data) {
			angular.element(document.getElementById('export-data')).val(data);
		}

		function submitExportForm() {
			let form = document.getElementById('export-form');
			form.submit();
			$scope.$emit('generateComplete');
			$scope.viewModel.isLoadingStarted = false;
			$scope.setDisableGenerateButton(false);
			$scope.$emit('hideLoader');
		}

		function processSubMenu(items, $event, side = 'left') {
			if (items && items.length && items.length !== 0) {
				$rootScope.showSubMenu(items, $event, side);
			} else {
				$rootScope.hideSubMenu();
			}
		}

		$scope.$on('showEquipmentTypesLoader', function() {
			$scope.isEquipmentTypesLoading = true;
		});
		$scope.$on('hideEquipmentTypesLoader', function() {
			$scope.isEquipmentTypesLoading = false;
		});
		$scope.$watch(
			function() {
				return $scope.range.from.format('x') + '/' + $scope.range.to.format('x');
			},
			function() {
				$scope.rangeEnd = moment($scope.range.to).add(-1, 'days');
			}
		);

		// Serverless migration - functions
		function submitExportFormServerless(data) {
			$scope.viewModel.isLoadingStarted = false;
			$scope.setDisableGenerateButton(false);
			$scope.$emit('hideLoader');
			$scope.downloadFileUsingS3(data);
		}

		function downloadFileUsingS3({signedUrl, filename}) {
			let link = document.getElementById('download-file');
			link.href = signedUrl;
			link.setAttribute('download', filename);
			$timeout(function() {
				link.click();
				$scope.$emit('generateComplete');
			});
		}
	});
