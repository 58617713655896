const services = new WeakMap();

class ChillerPerformanceReportApiService {
	constructor($http, $q) {
		services.set(this, {$http, $q});

		this.apiUrl = '/ext_api/api/chillerPerformanceReport';
	}

	getChillerPerformanceReportData({tisObjectId}) {
		const {$http} = services.get(this);

		return $http
			.get(`${this.apiUrl}?tisObjectId=${tisObjectId}`)
			.then(this._getDataFromResponse)
			.catch(error => {
				if (error.status === 404) {
					return null;
				}

				throw error;
			});
	}

	createChillerPerformanceReportData(data) {
		const {$http} = services.get(this);

		return $http.post(`${this.apiUrl}`, data).then(this._getDataFromResponse);
	}

	updateChillerPerformanceReportData(data) {
		const {$http} = services.get(this);

		return $http.put(`${this.apiUrl}/${data.chillerPerformanceReportId}`, data).then(this._getDataFromResponse);
	}

	getChillerPerformanceReportDetails() {
		const {$http, $q} = services.get(this);

		return $q.resolve([]);
	}

	_getDataFromResponse({data: {chillerPerformanceReportList: [data] = []}}) {
		return data;
	}
}

angular.module('TISCC').service('chillerPerformanceReportApiService', ChillerPerformanceReportApiService);
