export const USER_EVENTS = {
	FACILITY_SUMMARY_PAGE: {
		categoryName: 'TCC4 Facility Summary',
		events: {
			GO_TO_NOTES: 'Go to Notes',
			GO_TO_EQUIPMENT_SETUP: 'Go to Equipment Setup',
			VIEW_FACILITY_MAP: 'View Facility Map',
			VIEW_WEATHER_DETAILS: 'View Weather Details',
			APPLY_FILTER: 'Apply Filter',
			REMOVE_FILTER: 'Remove Filter',
			EXPAND_TEST_RESULTS_VIEW: 'Expand Test Results View',
			CHANGE_PAGINATION_OF_TEST_RESULTS: 'Change Pagination of Test Results',
			CHANGE_DATES_OF_TEST_RESULTS: 'Change Dates of Test Results',
			SEARCH_EQUIPMENT: 'Search Equipment',
		},
		properties: {
			COLUMN: 'Column',
			DIRECTION: 'Direction',
		},
	},
};
