export const USER_EVENTS = {
	EQUIPMENT_PERFORMANCE_CHARTS: {
		categoryName: 'Equipment Performance Charts',
		events: {
			GO_TO_EQUIPMENT_SUMMARY: 'Go to Equipment Summary',
			GO_TO_NOTES: 'Go to Notes',
			CHANGE_EQUIPMENT: 'Change Equipment',
			CHANGE_CHART: 'Change Chart',
			CHANGE_CHART_SORT_ORDER: 'Change Chart Sort Order',
			ACTIVATE_CROSSHAIR_TOOL: 'Activate Crosshair Tool',
			CHANGE_CHART_TIME_PERIOD: 'Change Chart Time Period',
			ADD_CHART_BELOW: 'Add Chart Below',
			SYNC_CHARTS: 'Sync Charts',
			ADD_PROPERTY: 'Add Property',
			REMOVE_ADDED_PROPERTIES: 'Remove Added Properties',
			FORMAT_CHART: 'Format Chart',
			REFRESH_Y_AXIS: 'Refresh Y Axis',
			HIDE_SHOW_TIMELINE: 'Hide/Show Timeline',
			SHOW_HIDE_SUPPRESSIONS: 'Show/Hide Suppressions',
			CHANGE_TIME_PERIOD_OF_CHART: 'Change Time Period of Chart',
			ZOOM_TIME_PERIOD_OF_CHART: 'Zoom Time Period of Chart',
			EXPORT_CHART: 'Export Chart',
			COPY_CHART_LINK: 'Copy Chart Link',
			CLOSE_OPEN_CHART_LEGEND: 'Close/Open Chart Legend',
			CREATE_FINDING_FROM_CHART: 'Create Finding from Chart',
			FIT_CHARTS: 'Fit Charts',
		},
		properties: {
			CHART_NAME: 'Chart Name',
			CHART_TYPE: 'Chart Type',
			// Indicates which chart on the page the user are working with in the case he has multiple charts open.
			CHART_ID: 'ChartID',
			EQUIPMENT_TYPE: 'Equipment Type',
			SORT_ORDER: 'Sort Order',
			TIME_PERIOD: 'Time Period',
			STATUS: 'Status',
			PROPERTIES: 'Properties',
			CHANGED_FIELDS: 'Changed Fields',
			DIRECTION: 'Direction',
			EXPORT_TYPE: 'Export Type',
			BACKGROUND_COLOR: 'Background Color',
			ORIENTATION: 'Orientation',
			FORMAT: 'Format',
		},
	},
};
