/* eslint-disable camelcase */
/* eslint eqeqeq: [0, 'always'] */
/* eslint max-nested-callbacks: ["error", 5]*/
import _get from 'lodash.get';
import R, {composeP, filter} from 'ramda';
export const SYSTEM_OF_MEASURE_LIST = [{name: 'Imperial (IP)', value: 'ip'}, {name: 'Metric (SI)', value: 'si'}];
export const DEFAULT_SYSTEM_OF_MEASURE = SYSTEM_OF_MEASURE_LIST[0];
export const REPORT_LANGUAGE_LIST = [
	{name: 'English', value: 'en', system: SYSTEM_OF_MEASURE_LIST[0]},
	{name: 'French (Canadian)', value: 'fr', system: SYSTEM_OF_MEASURE_LIST[1]},
];
export const DEFAULT_REPORT_LANGUAGE = REPORT_LANGUAGE_LIST[0];
const services = new WeakMap();
const PROPERTY_NAME_DIVIDER = '###';
export const conversionTable = {
	si: {
		fahrenheit: {
			convertedUom: {
				name: 'celsius',
				displayName: 'Celsius',
				symbol: '°C',
				jscienceName: 'CELSIUS',
			},
			converterFunction: value => (value - 32) * 5 / 9,
		},
		deltaDegreesFahrenheit: {
			convertedUom: {
				name: 'deltaDegreesCelsius',
				displayName: 'Delta Degrees Celsius',
				symbol: 'Δ°C',
				jscienceName: 'DELTA_DEGREES_CELSIUS',
			},
			converterFunction: value => value * 5 / 9,
		},
		poundsForcePerSquareInch: {
			convertedUom: {
				name: 'kiloPascal',
				displayName: 'Kilo Pascal',
				symbol: 'kPa',
				jscienceName: 'KILO_PASCAL',
			},
			converterFunction: value => value * 6.8947572932,
		},
		tonRefrigeration: {
			convertedUom: {
				name: 'kiloWatt',
				displayName: 'Kilo Watt',
				symbol: 'kW',
				jscienceName: 'KILO_WATT',
			},
			converterFunction: value => value * 3.516852842067,
		},
	},
};

export const DIR_DATE_FORMATS = {
	FULL_DATE_TIME_TZ_FORMAT: 'YYYY-MM-DDTHH:mm:ssZ',
	LONG_MONTH_YEAR_FORMAT: 'MMMM YYYY',
	MEDIUM_MONTH_YEAR_FORMAT: 'MMM YYYY',
	LONG_MONTH_FORMAT: 'MMMM',
	MEDIUM_MONTH_FORMAT: 'MMM',
	LONG_DATE_FORMAT: 'MMMM D, YYYY',
	MEDIUM_DATE_FORMAT: 'MMM D, YYYY',
	DATA_DATE_FORMAT: 'YYYY-MM-DD',
	EU_DATA_DATE_FORMAT: 'DD/MM/YYYY',
	CHILLER_DATA_DATE_FORMAT: 'MM/DD/YYYY',
	TIME_FORMAT_H_MM_A: 'h:mm A',
	TIME_FORMAT_H_MM: 'h:mm',
	TIME_FORMAT_HH_MM: 'HH:mm',
	MEDIUM_DAY_FORMAT: 'ddd',
	SHORT_DAY_FORMAT: 'D',
	TIMEZONE_FORMAT: 'z',
};
class DigitalInspectionReportService {
	constructor(
		$q,
		$rootScope,
		$routeParams,
		$translate,
		$filter,
		locationDetailsService,
		locationEquipmentService,
		digitalInspectionService,
		serviceAdvisoryService,
		translateService,
		helpers
	) {
		services.set(this, {
			$q,
			$rootScope,
			$translate,
			$routeParams,
			$filter,
			digitalInspectionService,
			locationDetailsService,
			locationEquipmentService,
			serviceAdvisoryService,
			translateService,
			helpers,
		});
	}
	getObjectData() {
		function saveChillerSubComponents(tisObjects = []) {
			tisObjects.forEach(function(tisObject) {
				if (chillerSubComponents[tisObject.tisObjectType.tisObjectTypeGroupName]) {
					chillerSubComponents[tisObject.tisObjectType.tisObjectTypeGroupName].push(tisObject);
				}
				if (tisObject.children) {
					saveChillerSubComponents(tisObject.children);
				}
			});
		}
		const {locationEquipmentService, serviceAdvisoryService, $filter, $routeParams, $rootScope, helpers, $q} = services.get(this);
		const chillerSubComponents = {
			Circuit: [],
			Compressor: [],
		};
		return locationEquipmentService.getLocationObjectsList($routeParams.locationId, null, true).then(function(data) {
			let groupNames = [];
			$rootScope.tisObjectsHierarchy = data.tisObjectList;
			$rootScope.equipment = $filter('filterNested')($rootScope.tisObjectsHierarchy, {tisObjectId: parseInt($routeParams.equipmentId)}, true).pop();
			$rootScope.groupName = $rootScope.equipment.tisObjectType.tisObjectTypeGroupName;

			$rootScope.workingTisObjectIds = [$rootScope.equipment.tisObjectId];
			if ($rootScope.groupName === 'Chiller') {
				groupNames = [$rootScope.groupName, 'Circuit', 'Compressor'];
				saveChillerSubComponents($rootScope.equipment.children);

				Object.keys(chillerSubComponents).forEach(key =>
					chillerSubComponents[key].forEach(equipment => $rootScope.workingTisObjectIds.push(equipment.tisObjectId))
				);
			} else {
				groupNames = [$rootScope.groupName];
			}
			const sAList = $q
				.all($rootScope.workingTisObjectIds.map(tisObjectId => serviceAdvisoryService.getTypesByTisObjectId(tisObjectId)))
				.then(tisObjectSats =>
					// Add tisObjectId to each SAT and return all SATs as one array
					helpers.flattenDeep(
						tisObjectSats.map((serviceAdvisoryTypes, index) => [
							...serviceAdvisoryTypes.map(sat => ({
								...sat,
								tisObjectId: $rootScope.workingTisObjectIds[index],
							})),
						])
					)
				)
				.then(data => {
					return {sAList: data, equipmentList: $rootScope.workingTisObjectIds};
				});
			return sAList;
		});
	}
	getFailRateData(locationId, organizationId, equipments, serviceAdvisoryTypeIDs, callRange) {
		const {digitalInspectionService} = services.get(this);
		return digitalInspectionService
			.getDigitalInspections(locationId, organizationId, equipments, serviceAdvisoryTypeIDs, callRange, true)
			.then(response => {
				return response;
			});
	}
}
angular.module('TISCC').service('digitalInspectionReportService', DigitalInspectionReportService);
