angular.module('TISCC').controller('ParetoChartCtrl', ParetoChartCtrl);

function ParetoChartCtrl(
	$rootScope,
	$scope,
	$routeParams,
	$route,
	$controller,
	$timeout,
	$q,
	locationDetailsService,
	urlService,
	ChartFactory,
	MetricsFactory,
	CONTROLLER_CHART_TYPES
) {
	angular.extend(this, $controller('AbstractChartCtrl', {$scope: $scope}));

	let lastRoute = $route.current;
	let eqType = $routeParams.equipmentTypeNumber;
	let serviceAdvisoryTypeId = $routeParams.serviceAdvisoryTypeId;

	$scope.isCprAllowed = locationDetailsService.isCprAllowed;
	$scope.isDirAllowed = locationDetailsService.isDirAllowed;
	$scope.isOfferingExpired = locationDetailsService.isOfferingExpired;

	const addChangeChart = $scope.generateAddChartFunction(CONTROLLER_CHART_TYPES.PARETO_CHART, ChartFactory);
	let chart = null;
	let promise = $q.resolve();

	if ($scope.passedChartState) {
		const topChartState = $scope.passedChartState.shift();
		const {getAppropriateRange} = $scope;

		chart = ChartFactory.getNewParetoChart(
			getAppropriateRange(topChartState.range),
			topChartState.locationId,
			topChartState.chartId,
			eqType,
			serviceAdvisoryTypeId,
			topChartState.paretoChartSortOrder,
			topChartState.selectedFacilities || null,
			parseInt(topChartState.chartIndex)
		);

		chart.passedChartState = topChartState;
		$scope.charts = [chart];

		// Load detailed info about selected facilities
		if ($scope.passedChartState.filter(state => state.selectedFacilities && state.selectedFacilities.length).length) {
			promise = $q.all(
				$scope.passedChartState.map(state => {
					return $q
						.all(
							state.selectedFacilities.map(location => {
								return locationDetailsService.getLocationDetailsWithoutServiceAdvisories(location.locationId);
							})
						)
						.then(list => (state.selectedFacilities = list.map(item => item.locationSummaryList[0])));
				})
			);
		}

		promise = promise.then(() =>
			$scope.passedChartState.forEach(state => {
				const chart = addChangeChart({
					charttype: state.isParetoChart ? CONTROLLER_CHART_TYPES.PARETO_CHART : CONTROLLER_CHART_TYPES.FACILITY_CHART,
					locationId: state.locationId,
					equipmentType: state.equipmentType || eqType,
					chartId: state.chartId,
					serviceAdvisoryTypeId: state.serviceAdvisoryTypeId || serviceAdvisoryTypeId,
					range: getAppropriateRange(state.range),
					selectedFacilities: state.selectedFacilities,
				});

				chart.passedChartState = state;
			})
		);
	} else {
		const newChartIndex = $scope.getNewChartIndex();
		chart = ChartFactory.getNewParetoChart(this.initRange, $routeParams.locationId, null, eqType, serviceAdvisoryTypeId, null, null, newChartIndex);
		$scope.charts = [chart];
	}

	promise.then(() => {
		$rootScope.isExport && $scope.charts.forEach($scope.addEventListenersToChartForExport);

		if (chart.eventObject) {
			$scope.addCommonEventListenersToChart(chart);

			chart.eventObject.on('addChart', () => {
				const {chartObj: {locationId, equipmentType, serviceAdvisoryTypeId, paretoChartSortOrder, selectedFacilities}, range} = chart;

				addChangeChart(
					{
						range: $scope.getAppropriateRange(range),
						charttype: CONTROLLER_CHART_TYPES.PARETO_CHART,
						locationId,
						equipmentType,
						serviceAdvisoryTypeId,
						paretoChartSortOrder,
						selectedFacilities,
						chartIndex: $scope.getNewChartIndex(),
					},
					chart
				);
			});
		}
	});

	/**
	 * Fixture to avoid controller reload when user changes chart or equipment (saves few API calls)
	 */
	$scope.$on('$locationChangeSuccess', function() {
		// Extra check whether we are going from PieChart to child equipment or back, and really need controller reloaded
		if ($route.current.$$route && $route.current.$$route.controller === 'ParetoChartCtrl') {
			// Will not load only if my view use the same controller,
			$route.current = lastRoute;
		}
	});

	$scope.$watch(
		'chartObj',
		function(newChartObj, oldChartObj) {
			if (angular.equals(newChartObj, oldChartObj)) {
				return;
			}
			let newPath = `/facility/${newChartObj.locationId}/type/${newChartObj.equipmentType}/pareto/${newChartObj.serviceAdvisoryTypeId}`;
			urlService.changePath(newPath);
		},
		true
	);
}
