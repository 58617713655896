export default class ChillerPerformanceReportSectionConfig {
	constructor(options) {
		this.isEnabled = true;
		this.hpath = '';
		this.imageSource = '';
		this.infoText = '';
		this.height = 0;
		this.others = {};
		this.properties = [];
		this.rowHeight = 16;

		Object.assign(this, options);
	}

	divideSectionPerPages(options) {
		this.commonDivider(options);
	}

	divideChartPerPages({
		CPR_REPORT_SECTIONS,
		chartSections,
		section,
		allowedReportSections,
		index,
		cprConfig,
		page,
		reportSectionsData,
		generateNewChartSectionObject,
		addNewPageIfNeededAndReturn,
		updatePage,
	}) {
		const CHARTS_PER_SECTION_NUMBER = 2;

		let chartSection = chartSections[chartSections.length - 1];

		const addChartToPage = ({chartSectionHeight}) => {
			page = addNewPageIfNeededAndReturn(page, {height: chartSectionHeight});
			updatePage(page, {height: chartSectionHeight, section: chartSection});
			chartSection.isAddedToPage = true;
		};
		const generateNewChartSectionAndAddToCommon = () => {
			chartSection = generateNewChartSectionObject();
			chartSections.push(chartSection);
		};
		const addChartToPageAndGenerateNewSection = ({chartSectionHeight}) => {
			addChartToPage({chartSectionHeight});
			generateNewChartSectionAndAddToCommon();
		};

		if (reportSectionsData[section].isFailed) return;

		if (chartSection.isAddedToPage) {
			generateNewChartSectionAndAddToCommon();
		}

		// Process multi circuit chillers. Each chart must be rendered for each circuit.
		// Generate new chart sections for each circuit.
		if (ChillerPerformanceReportSectionConfig._isMultiCircuitChiller({cprConfig, reportSectionsData, section})) {
			const sectionNames = {};

			const getAnotherChartWithCircuitProp = () =>
				Object.keys(cprConfig)
					.filter(key => cprConfig[key].hasCircuitProperty)
					.find(key => key !== section);
			const checkIfAnotherChartWithCircuitPropIsEnabledInReport = () => {
				const section = getAnotherChartWithCircuitProp();
				return allowedReportSections.includes(section) && !reportSectionsData[section].isFailed;
			};
			const updateReportSectionsData = ({section, index, circuitPropertyName, circuitPropertyDataList}) => {
				sectionNames[section] = sectionNames[section] || [];

				let sectionName = `${section}_${index + 1}`;

				if (!sectionNames[section].includes(sectionName)) {
					sectionNames[section].push(sectionName);
				}

				if (!reportSectionsData[sectionName]) {
					reportSectionsData[sectionName] = {
						...reportSectionsData[section],
					};
				}

				if (circuitPropertyDataList.length > 1) {
					circuitPropertyDataList[index]['isMultiCircuit'] = true;
				}
				reportSectionsData[sectionName].axes = {
					...reportSectionsData[sectionName].axes,
					[circuitPropertyName]: circuitPropertyDataList[index],
				};
			};

			// Generate new reportSectionData properties for each chiller circuit.
			// Ex: {
			//      CONDENSER_HEAT_TRANSFER_0: {...data},
			//      CONDENSER_HEAT_TRANSFER_1: {...data}
			// }
			const sectionCircuitPropertiesName = cprConfig[section].getCircuitPropertyName();

			sectionCircuitPropertiesName.forEach(propertyName => {
				if (!reportSectionsData[section].axes[propertyName]) return;

				reportSectionsData[section].axes[propertyName].forEach((_, index, array) => {
					updateReportSectionsData({
						section,
						index,
						circuitPropertyName: propertyName,
						circuitPropertyDataList: array,
					});
				});
			});

			if (checkIfAnotherChartWithCircuitPropIsEnabledInReport()) {
				const nextSection = getAnotherChartWithCircuitProp();
				// Remove section from allowed report sections to prevent its process in _divideReportSectionsPerPages()
				allowedReportSections.splice(allowedReportSections.indexOf(nextSection), 1);

				const nextSectionCircuitPropertiesName = cprConfig[nextSection].getCircuitPropertyName();

				nextSectionCircuitPropertiesName.forEach(propertyName => {
					if (!reportSectionsData[nextSection].axes[propertyName]) return;

					reportSectionsData[nextSection].axes[propertyName].forEach((_, index, array) => {
						updateReportSectionsData({
							section: nextSection,
							index,
							circuitPropertyName: propertyName,
							circuitPropertyDataList: array,
						});
					});
				});

				// Remove default section name from reportSectionData as Its splited into mutiple section like CONDENSER_HEAT_TRANSFER_1: {...data}, CONDENSER_HEAT_TRANSFER_2: {...data}
				if (sectionNames[section].length) delete reportSectionsData[section];
				if (sectionNames[nextSection].length) delete reportSectionsData[nextSection];
			}
			// Charts with multi circuit must be in separate sections.
			chartSection.charts.length &&
				addChartToPageAndGenerateNewSection({
					chartSectionHeight: Math.max(...chartSection.charts.map(chartSection => cprConfig[chartSection].height)),
				});
			sectionNames[section].forEach((sectionName, index, array) => {
				const anotherChartWithCircuitProperty = getAnotherChartWithCircuitProp();
				chartSection.showNote = array.length - 1 === index;
				chartSection.charts.push(sectionName);
				Array.isArray(sectionNames[anotherChartWithCircuitProperty]) && chartSection.charts.push(sectionNames[anotherChartWithCircuitProperty][index]);

				addChartToPageAndGenerateNewSection({
					chartSectionHeight: Math.max(
						// We need to cut the section number (SECTION_NAME_10 -> SECTION_NAME)
						...chartSection.charts.map(chartSection => cprConfig[chartSection.replace(/_\d+/, '')].height)
					),
				});
			});
		} else {
			chartSection.charts.push(section);
			chartSection.showNote = true;
			const isLastChartItemInListOrFullSection = !(
				(cprConfig[allowedReportSections[index + 1]] || {}).isChart && chartSection.charts.length < CHARTS_PER_SECTION_NUMBER
			);

			isLastChartItemInListOrFullSection &&
				addChartToPage({
					chartSectionHeight: Math.max(...chartSection.charts.map(chartSection => cprConfig[chartSection].height)),
				});
		}
		return page;
	}

	divideEditableTablePerPages({MAX_MAIN_CONTENT_HEIGHT, page, section, reportSectionsData, createNewPage, updatePage}, {propertyName}) {
		const remainingHeight = MAX_MAIN_CONTENT_HEIGHT - page.height;

		for (let itemNumber = 0; reportSectionsData[section][propertyName].length; itemNumber++) {
			const existedItems = reportSectionsData[section][propertyName];

			let allowedHeight = (itemNumber ? MAX_MAIN_CONTENT_HEIGHT : remainingHeight) - this.minHeight;
			let allowedRowsNumber = (() => {
				let index = 0;
				while (allowedHeight > 0 && existedItems[index]) {
					const rowHeight = this.calculateRowHeight(existedItems[index]);

					allowedHeight =
						allowedHeight > rowHeight
							? (() => {
									index++;
									return allowedHeight - rowHeight;
							  })()
							: -1;
				}

				return index;
			})();

			if (!allowedRowsNumber) {
				page = createNewPage();
				continue;
			}

			const newSectionName = `${section}_${itemNumber}`;

			reportSectionsData[newSectionName] = {
				...reportSectionsData[section],
			};

			reportSectionsData[newSectionName][propertyName] = existedItems.filter((_, index) => index < allowedRowsNumber);
			reportSectionsData[section][propertyName] = existedItems.filter((_, index) => index >= allowedRowsNumber);

			page =
				updatePage(page, {
					height: this.calculateHeight({[propertyName]: reportSectionsData[newSectionName][propertyName]}, !itemNumber),
					section: newSectionName,
					isCreateAndReturnNewPageAfter: !!reportSectionsData[section][propertyName].length,
				}) || page;
		}

		return page;
	}

	commonDivider({reportSectionsData, section, page, addNewPageIfNeededAndReturn, updatePage}) {
		let {height, calculateHeight} = this;

		height = calculateHeight ? this.calculateHeight(reportSectionsData[section]) : height;
		page = addNewPageIfNeededAndReturn(page, {height});

		updatePage(page, {height, section});

		return page;
	}

	static _isMultiCircuitChiller({cprConfig, reportSectionsData, section}) {
		return (
			cprConfig[section].hasCircuitProperty &&
			reportSectionsData[section].axes &&
			Array.isArray(reportSectionsData[section].axes[cprConfig[section].getCircuitPropertyName()[0]])
		);
	}
}
