import {CPR_DATE_FORMATS} from '../../chiller-performance-report-config';

const services = new WeakMap();
class ChillerRunHoursAndStartsSettingsPopupComponentController {
	constructor(helpers) {
		services.set(this, {helpers});
		this.formDisabled = true;
	}

	$onInit() {
		const {helpers: {isNumber}} = services.get(this);
		this.CPR_DATE_FORMATS = CPR_DATE_FORMATS;
		this.intactValues = {};

		const {totalRunHours, totalStarts} = this.data.values;

		this.intactValues.totalRunHours = totalRunHours;
		this.intactValues.totalStarts = totalStarts;

		this.totalRunHours = isNumber(totalRunHours) ? totalRunHours : null;
		this.totalStarts = isNumber(totalStarts) ? totalStarts : null;

		this.formDisabled = false;

		this.header = this.data.header || 'ENTER_COMPRESSOR_RUN_HOURS_AND_STARTS_DATA';
	}

	save() {
		const {helpers: {isNumber}} = services.get(this);

		this.modalInstance.close({
			totalRunHours: isNumber(this.totalRunHours) ? this.totalRunHours : null,
			totalStarts: isNumber(this.totalStarts) ? this.totalStarts : null,
		});
	}

	cancel() {
		this.modalInstance.dismiss('cancel');
	}

	isSaveButtonEnabled() {
		const {helpers} = services.get(this);
		const isFormIntact = this.totalRunHours === this.intactValues.totalRunHours && this.totalStarts === this.intactValues.totalStarts;

		// totalRunHours or totalStarts can be saved with empty value only if their initial values is not a number
		return (
			!(helpers.isNumber(this.intactValues.totalRunHours) && !helpers.isNumber(this.totalRunHours)) &&
			!(helpers.isNumber(this.intactValues.totalStarts) && !helpers.isNumber(this.totalStarts)) &&
			!isFormIntact
		);
	}
}

export default {
	templateUrl: 'components/reports/chiller-performance/modals/chiller-run-hours-and-starts-settings-popup/chiller-run-hours-and-starts-settings-popup.html',
	controller: ChillerRunHoursAndStartsSettingsPopupComponentController,
	bindings: {
		modalInstance: '<',
		data: '<',
	},
};
