class ProgressbarWrapperController {
	constructor() {}

	$onInit() {
		isNaN(this.value) && (this.value = 100);
		angular.isUndefined(this.backdrop) && (this.backdrop = true);
		angular.isUndefined(this.backdrop) && (this.position = 'absolute');
	}
}

angular.module('TISCC').component('tisccProgressbarWrapper', {
	templateUrl: 'components/progressbar-wrapper/progressbar-wrapper.html',
	controller: ProgressbarWrapperController,
	bindings: {
		backdrop: '<',
		message: '@',
		position: '@',
		value: '<',
	},
});
