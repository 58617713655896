(function() {
	// :todo Next step is to convert equipment selection component to drop down selector,
	// because the same showSubMenu code is used in several places
	const timeoutMap = new WeakMap();
	const leaveMap = new WeakMap();
	const services = new WeakMap();
	const MENU_TOP = 25;

	const getSubMenuParams = function(items, event) {
		const MAX_MENU_HEIGHT = 307;
		const MENU_PADDING = 12;
		const node = event.currentTarget.parentNode;
		const currentRect = event.currentTarget.getBoundingClientRect();
		const top = currentRect.top - node.getBoundingClientRect().top;
		const left = node.offsetWidth + node.offsetLeft;
		const right = node.offsetWidth;
		const subMenuActualHeight = items.length * currentRect.height + MENU_PADDING;
		const subMenuMaxHeight = subMenuActualHeight <= MAX_MENU_HEIGHT ? subMenuActualHeight : MAX_MENU_HEIGHT;

		return {
			top: top,
			left: left,
			right: right,
			subMenuMaxHeight: subMenuMaxHeight,
		};
	};

	const getParentContainerRect = node => {
		let p = node.parentNode;
		while (p.tagName !== 'CHART-BLOCK') {
			p = p.parentNode;
		}
		return p.getBoundingClientRect();
	};

	class EquipmentSelectionDropdownController {
		constructor($timeout) {
			services.set(this, {$timeout});
		}

		updateDrpDwnPosition(event) {
			let parentNode = event.currentTarget.parentNode;
			let drpMenuNode = parentNode.querySelector('.dropdown-menu');

			drpMenuNode.style.display = 'block';

			let shift = parentNode.offsetLeft + parentNode.offsetWidth - drpMenuNode.offsetWidth;
			drpMenuNode.style.right = shift < 0 ? shift + 'px' : '';
			drpMenuNode.style.display = '';
		}

		menuClickHandler(equipment, $event) {
			this.menuClick({equipment});
			$event.preventDefault();
			$event.stopPropagation();
		}

		subMenuClickHandler(item) {
			this.subMenuClick({item});
		}

		subSubMenuClickHandler(item) {
			this.subSubMenuClick({item});
		}

		showSubMenu(items, event, passedSide) {
			if (items && items.length) {
				const subMenuParams = getSubMenuParams(items, event);
				const currentRect = event.currentTarget.getBoundingClientRect();
				const side = passedSide || 'right';
				const params = {
					top: subMenuParams.top + MENU_TOP + 'px',
					left: subMenuParams.left + 'px',
					right: subMenuParams.right + 'px',
					subMenuMaxHeight: subMenuParams.subMenuMaxHeight,
					rectTop: currentRect.top,
					node: event.currentTarget.parentNode,
				};

				this.subSubMenuItems = null;
				this.subMenuItems = items || null;

				if (side === 'left') {
					this.processLeftSideSubMenuParams(params);
				} else {
					this.setSubMenuPosition(params);
				}
			}
		}

		showSubSubMenu(items, event) {
			const subMenuParams = getSubMenuParams(items, event);
			const currentRect = event.currentTarget.getBoundingClientRect();
			const parentContainerRect = getParentContainerRect(event.currentTarget);

			this.subSubMenuItems = items || null;
			this.subSubMenuPosition = {
				top: subMenuParams.top + parseInt(this.subMenuPosition.top, 10) + 'px',
				left: subMenuParams.left + 'px',
				right: subMenuParams.right + 'px',
			};

			if (currentRect.top + subMenuParams.subMenuMaxHeight > parentContainerRect.bottom) {
				this.subSubMenuPosition.top = parentContainerRect.height - subMenuParams.subMenuMaxHeight - MENU_TOP + 'px';
			}
		}

		setSubMenuPosition(params) {
			const parentContainerRect = getParentContainerRect(params.node);
			this.subMenuPosition = {
				top: params.top,
				left: params.left,
				right: params.right,
			};

			if (params.rectTop + params.subMenuMaxHeight > parentContainerRect.bottom) {
				this.subMenuPosition.top = parentContainerRect.height - params.subMenuMaxHeight - MENU_TOP + 'px';
			}
		}

		processLeftSideSubMenuParams(params) {
			const {$timeout} = services.get(this);
			let timeout = timeoutMap.get(this);

			if (this.subMenuWidth) {
				timeout && $timeout.cancel(timeout);
				params.left = -this.subMenuWidth + 'px';
				this.setSubMenuPosition(params);
			} else {
				timeout = $timeout(() => {
					this.processLeftSideSubMenuParams(params);
				}, 50);
				timeoutMap.set(this, timeout);
			}
		}

		hideSubMenu() {
			this.subMenuItems = null;
			this.subSubMenuItems = null;
			this.subMenuWidth = null;
		}

		hideSubSubMenu() {
			this.subSubMenuItems = null;
		}

		menuLeave() {
			const {$timeout} = services.get(this);
			let leave = leaveMap.get(this);

			leave && $timeout.cancel(leave);
			leave = $timeout(this.hideSubMenu.bind(this), 500);
			leaveMap.set(this, leave);
		}

		menuEnter() {
			const {$timeout} = services.get(this);
			let leave = leaveMap.get(this);

			leave && $timeout.cancel(leave);
		}
	}

	angular.module('TISCC').component('equipmentSelectionDropdown', {
		templateUrl: 'components/equipment-selection-dropdown/equipment-selection-dropdown.html',
		controller: EquipmentSelectionDropdownController,
		bindings: {
			itemTitle: '@',
			groupedEquipments: '<',
			isEquipmentListLoading: '<',
			buttonLabel: '<',
			menuClick: '&',
			hasSubmenu: '<',
			subMenuClick: '&',
			hasSubSubmenu: '<',
			subSubMenuClick: '&',
		},
	});

	angular.module('TISCC').filter('sortEquipment', $translate => {
		return function(obj) {
			if (obj) {
				obj.sort((prev, past) => {
					if (prev.type === 'FACILITY') {
						return 1;
					}
					if (past.type === 'FACILITY') {
						return -1;
					}

					return $translate(`EQUIPMENT_TYPE_NAME_TABLE.${prev.type}`).localeCompare($translate(`EQUIPMENT_TYPE_NAME_TABLE.${past.type}`), undefined, {
						numeric: true,
						sensitivity: 'base',
					});
				});
			}
			return obj;
		};
	});
})();
