angular.module('TISCC').provider('authorization', function(AUTH_CONFIGURATION) {
	let currentRole = [];
	let redirectUrl = AUTH_CONFIGURATION.ROUTES.DEFAULT_FALLBACK_URL;
	let loginToken = false;
	let permissionsList = null;
	const cookieDomain = AUTH_CONFIGURATION.COOKIES.COOKIE_DOMAINS.find(domain => {
		return document.location.origin.includes(domain);
	});

	return {
		$get: function(
			$q,
			$localStorage,
			$sessionStorage,
			$cookies,
			$location,
			$http,
			$httpParamSerializer,
			cacheService,
			testProvider,
			googleAnalyticsService,
			usageTrackingService,
			configService,
			zendeskHelperService,
			handleServiceWorker
		) {
			testProvider.pushMethodToDocument('setCookies', setCookies.bind(this));
			testProvider.pushMethodToDocument('setPermissions', setPermissions.bind(this));

			return {
				// Main Authorization entry point
				handlePermissionsSuccess,
				checkAccess: checkAccess,
				authenticate: authenticate,
				checkAuthentication: checkAuthentication,
				checkAuthorization: checkAuthorization,
				setRedirectionUrl: setRedirectionUrl,
				defineRedirectionUrl: defineRedirectionUrl,
				doLoginFormRedirection: doLoginFormRedirection,
				removeCookies: removeCookies,
				getToken: getAuthToken,
				hasCPROnlyRole,
				hasRDROnlyRole,
				hasTCC4Role,
			};

			function getAuthToken() {
				let authToken = false;
				const tokenParser = new RegExp(/^.*token=([a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12})(?:.*$|&.*$|$)/);
				const absUrl = $location.$$absUrl;
				const tokenParseResult = tokenParser.exec(absUrl);
				if (tokenParseResult && tokenParseResult[1]) {
					authToken = tokenParseResult[1];
					const oldHash = document.location.hash;
					let newHash = oldHash.replace(`token=${authToken}`, '');
					if (newHash.slice(-1) === '?') {
						newHash = newHash.substring(0, newHash.length - 1);
					}
					$cookies.put(AUTH_CONFIGURATION.COOKIES.COOKIE_NAME_41, authToken, {
						path: '/',
						domain: `.${cookieDomain}`,
					});
					$cookies.put(AUTH_CONFIGURATION.COOKIES.COOKIE_NAME_40, authToken, {
						path: '/',
						domain: `.${cookieDomain}`,
					});
					document.location.hash = newHash;
				} else if ($cookies.get(AUTH_CONFIGURATION.COOKIES.COOKIE_NAME_41)) {
					authToken = $cookies.get(AUTH_CONFIGURATION.COOKIES.COOKIE_NAME_41);
				} else if ($cookies.get(AUTH_CONFIGURATION.COOKIES.COOKIE_NAME_40)) {
					authToken = $cookies.get(AUTH_CONFIGURATION.COOKIES.COOKIE_NAME_40);
				} else if (authToken === 'undefined' || authToken === 'false') {
					// Extra sanity check for broken cookie value written as string
					authToken = false;
				}
				return authToken;
			}

			function checkAccess() {
				storePreAuthLink();
				return checkAuthentication()
					.then(getPermissions)
					.then(handlePermissionsSuccess)
					.catch(handlePermissionsFailure)
					.then(setUserRole)
					.then(checkAuthorization);
			}

			function authenticate(login, password) {
				zendeskHelperService.dispose();
				// Make login call
				return $http
					.post(
						AUTH_CONFIGURATION.AUTH_API_CONFIG.ENDPOINTS.LOGIN,
						AUTH_CONFIGURATION.AUTH_API_CONFIG.HEADERS.USERNAME_STRING +
							encodeURIComponent(login) +
							AUTH_CONFIGURATION.AUTH_API_CONFIG.HEADERS.PASSWORD_STRING +
							encodeURIComponent(password),
						{
							headers: {
								'Content-Type': AUTH_CONFIGURATION.AUTH_API_CONFIG.HEADERS.CONTENT_TYPE_FORM_ENCODED,
							},
						}
					)
					.then(checkReceivedToken)
					.then(setCookies)
					.then(setHeaders)
					.then(getPermissions)
					.then(handlePermissionsSuccess)
					.catch(handlePermissionsFailure)
					.then(setUserRole)
					.then(checkAuthorization)
					.then(defineRedirectionUrl)
					.then(doRedirection);
			}

			function checkAuthentication() {
				let deferred = $q.defer();
				// User went through the login process and a loginToken was set that way, or a revalidation attempt (below) was successful
				if (loginToken) {
					setHeaders();
					deferred.resolve(AUTH_CONFIGURATION.LOGIN_STATUSES.AUTHENTICATED);
				} else {
					// No loginToken was found, try to find one (at this point saved in cookies)
					loginToken = getAuthToken();
					if (loginToken) {
						// token was found in the cookies, we have already saved it;
						setHeaders();
						deferred.resolve(AUTH_CONFIGURATION.LOGIN_STATUSES.AUTHENTICATED);
					} else {
						// No tokens found in cookies... reject
						googleAnalyticsService.authenticationError(redirectUrl);
						deferred.reject(AUTH_CONFIGURATION.LOGIN_STATUSES.AUTHENTICATION_REJECTED_NO_TOKEN);
					}
				}
				return deferred.promise;
			}

			function checkAuthorization() {
				let deferred = $q.defer();
				let path = $location.path();
				const currentPathLeadsToRDR = path.indexOf(AUTH_CONFIGURATION.ROUTES.RDRONLY_URL_MATCH) !== -1;
				const currentPathLeadsToCPR =
					path.indexOf(AUTH_CONFIGURATION.ROUTES.CPRONLY_URL_MATCH) !== -1 || path.includes(AUTH_CONFIGURATION.ROUTES.CPRREPORT_URL_MATCH);
				const currentPathLeadsToRDRErrorPage = path.indexOf(AUTH_CONFIGURATION.ROUTES.RDRONLY_DEFAULT_MATCH) !== -1;
				if (path !== AUTH_CONFIGURATION.ROUTES.LOGIN_URL) {
					if (currentRole.includes(AUTH_CONFIGURATION.ROLE_LIST.ALL)) {
						googleAnalyticsService.trackPage(path);
						deferred.resolve(AUTH_CONFIGURATION.LOGIN_STATUSES.AUTHORIZED);
					} else if ((currentPathLeadsToRDR || currentPathLeadsToRDRErrorPage) && currentRole.includes(AUTH_CONFIGURATION.ROLE_LIST.RDR_ONLY)) {
						if (currentPathLeadsToRDR) {
							$localStorage.mostRecentReportLink = path;
						}
						googleAnalyticsService.trackPage(path);
						deferred.resolve(AUTH_CONFIGURATION.LOGIN_STATUSES.AUTHORIZED);
					} else if (currentPathLeadsToCPR && currentRole.includes(AUTH_CONFIGURATION.ROLE_LIST.CPR_ONLY)) {
						$localStorage.mostRecentReportLink = path;
						googleAnalyticsService.trackPage(path);
						deferred.resolve(AUTH_CONFIGURATION.LOGIN_STATUSES.AUTHORIZED);
					} else {
						googleAnalyticsService.authorizationError(redirectUrl, AUTH_CONFIGURATION.LOGIN_STATUSES.NOT_AUTHORIZED);
						deferred.reject(AUTH_CONFIGURATION.LOGIN_STATUSES.NOT_AUTHORIZED);
					}
				} else {
					googleAnalyticsService.trackPage(path);
					deferred.resolve(AUTH_CONFIGURATION.LOGIN_STATUSES.AUTHORIZED);
				}
				return deferred.promise;
			}

			function handlePermissionsSuccess({data}) {
				// Here we do set fetched role
				if (data && data.permissions) {
					const {permissions, firstName, lastName, username, email} = data;
					$localStorage.userData = data;
					permissionsList = permissions;
					googleAnalyticsService.setUser(`${firstName} ${lastName} [${username}]`);
					zendeskHelperService.identify(firstName, lastName, email);
					return $q.resolve();
				} else {
					permissionsList = null;
					return $q.reject(AUTH_CONFIGURATION.LOGIN_STATUSES.AUTHENTICATION_REJECTED_NO_PERMISSIONS);
				}
			}

			function handlePermissionsFailure(data) {
				if (data && data.data && data.data.username) {
					googleAnalyticsService.setUser(data.data.username);
				}
				googleAnalyticsService.authorizationError(redirectUrl, AUTH_CONFIGURATION.LOGIN_STATUSES.AUTHENTICATION_REJECTED_NO_TOKEN);
				return $q.reject(AUTH_CONFIGURATION.LOGIN_STATUSES.AUTHENTICATION_REJECTED_NO_PERMISSIONS);
			}

			function setCookies(token) {
				$cookies.put(AUTH_CONFIGURATION.COOKIES.COOKIE_NAME_40, token, {
					path: '/',
					domain: `.${cookieDomain}`,
				});
				$cookies.put(AUTH_CONFIGURATION.COOKIES.COOKIE_NAME_41, token, {
					path: '/',
					domain: `.${cookieDomain}`,
				});
				loginToken = token;
				return $q.resolve(token);
			}

			function checkReceivedToken(data) {
				if (data.data.token !== undefined) {
					let token = data.data.token;
					return $q.resolve(token);
				} else {
					return $q.reject(AUTH_CONFIGURATION.LOGIN_STATUSES.AUTHENTICATION_REJECTED_NO_TOKEN);
				}
			}

			function setUserRole() {
				let roleToSet = [];
				// Check if we have RDROnly string in permissions
				if (permissionsList) {
					if (hasTCC4Role()) {
						roleToSet.push(AUTH_CONFIGURATION.ROLE_LIST.ALL);
						googleAnalyticsService.setRoles(AUTH_CONFIGURATION.ROLE_CONDITIONS.TCC4Role);
					}
					if (roleToSet.length === 0) {
						if (hasRDROnlyRole()) {
							googleAnalyticsService.setRoles(AUTH_CONFIGURATION.ROLE_CONDITIONS.TCC4Role, AUTH_CONFIGURATION.ROLE_CONDITIONS.RDR_ONLY);
							roleToSet.push(AUTH_CONFIGURATION.ROLE_LIST.RDR_ONLY);
						}
						if (hasCPROnlyRole()) {
							googleAnalyticsService.setRoles(AUTH_CONFIGURATION.ROLE_CONDITIONS.TCC4Role, AUTH_CONFIGURATION.ROLE_CONDITIONS.CPR_ONLY);
							roleToSet.push(AUTH_CONFIGURATION.ROLE_LIST.CPR_ONLY);
						}
					}
					if (roleToSet.length === 0) {
						roleToSet.push(AUTH_CONFIGURATION.ROLE_LIST.ALL);
						googleAnalyticsService.setRoles(AUTH_CONFIGURATION.ROLE_CONDITIONS.TCC4Role);
					}
				}
				currentRole = roleToSet;
			}

			function defineRedirectionUrl() {
				if (currentRole.includes(AUTH_CONFIGURATION.ROLE_LIST.RDR_ONLY) || currentRole.includes(AUTH_CONFIGURATION.ROLE_LIST.CPR_ONLY)) {
					const mostRecentReportLink = $localStorage.mostRecentReportLink;
					if (mostRecentReportLink) {
						redirectUrl = mostRecentReportLink;
					} else if (currentRole.includes(AUTH_CONFIGURATION.ROLE_LIST.RDR_ONLY)) {
						redirectUrl = AUTH_CONFIGURATION.ROUTES.RDRONLY_DEFAULT_MATCH;
					}
					delete $localStorage.mostRecentReportLink;
				}
				return $q.resolve();
			}

			function doRedirection() {
				$location.url(redirectUrl);
			}

			function doLoginFormRedirection() {
				$location.url(AUTH_CONFIGURATION.ROUTES.LOGIN_URL);
			}

			function getPermissions() {
				if ($sessionStorage.userDataTemp) {
					// In order to run automated ui tests we need to allow to get user permissions from session storage
					return $q.resolve({data: $sessionStorage.userDataTemp});
				}
				return $http.get(AUTH_CONFIGURATION.AUTH_API_CONFIG.ENDPOINTS.USER_PERMISSIONS, {
					headers: {
						Accept: AUTH_CONFIGURATION.AUTH_API_CONFIG.HEADERS.ACCEPT_APPLICATION_JSON,
						Authorization: AUTH_CONFIGURATION.AUTH_API_CONFIG.HEADERS.TOKEN + loginToken,
						...handleServiceWorker.createServiceWorkerCacheKeyForRequest({
							cacheKey: 'authorizationProvider_GetUserData_' + AUTH_CONFIGURATION.AUTH_API_CONFIG.ENDPOINTS.USER_PERMISSIONS,
							expiryTime: handleServiceWorker.CACHE_EXPIRE_TIME.IN_1_DAY,
							cacheName: handleServiceWorker.CACHE_NAME.SESSION,
						}),
					},
					cache: cacheService.getCacheInstance('authorizationProvider', 'GetUserData'),
				});
			}
			function setPermissions(userData) {
				$sessionStorage.userDataTemp = userData;
				return redirectUrl;
			}

			function setHeaders() {
				$http.defaults.headers.common['Authorization'] = 'token ' + loginToken;
			}

			function setRedirectionUrl(url) {
				return $q.when(function() {
					redirectUrl = url;
				});
			}

			function storePreAuthLink() {
				let path = $location.path();
				const params = $location.search();
				delete params['token'];
				const queryString = $httpParamSerializer(params);
				path = `${path}${queryString ? '?' : ''}${queryString}`;
				if (
					path.includes(AUTH_CONFIGURATION.ROUTES.RDRONLY_URL_MATCH) ||
					path.includes(AUTH_CONFIGURATION.ROUTES.CPRONLY_URL_MATCH) ||
					path.includes(AUTH_CONFIGURATION.ROUTES.CPRREPORT_URL_MATCH)
				) {
					$localStorage.mostRecentReportLink = path;
					redirectUrl = path;
				} else if (!path.includes(AUTH_CONFIGURATION.ROUTES.LOGIN_URL)) {
					redirectUrl = path;
				}
			}
			function hasTCC4Role() {
				return permissionsList.some(permission => permission === AUTH_CONFIGURATION.ROLE_CONDITIONS.TCC4Role);
			}
			function hasRDROnlyRole() {
				return permissionsList.some(permission => permission === AUTH_CONFIGURATION.ROLE_CONDITIONS.RDR_ONLY);
			}
			function hasCPROnlyRole() {
				return permissionsList.some(permission => permission === AUTH_CONFIGURATION.ROLE_CONDITIONS.CPR_ONLY);
			}
			function removeCookies() {
				$cookies.remove(AUTH_CONFIGURATION.COOKIES.COOKIE_NAME_40, {
					path: '/',
					domain: `.${cookieDomain}`,
					expires: AUTH_CONFIGURATION.COOKIES.COOKIE_EXPIRES,
				});
				$cookies.remove(AUTH_CONFIGURATION.COOKIES.COOKIE_NAME_41, {
					path: '/',
					domain: `.${cookieDomain}`,
					expires: AUTH_CONFIGURATION.COOKIES.COOKIE_EXPIRES,
				});
			}
		},
	};
});
