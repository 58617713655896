export const SPACE_DEHUMIDIFICATION_CONTROL = 'SpaceDehumidificationControl';
export const SUPPLY_AIR_PRESSURE_CONTROL = 'SupplyAirPressureControl';
export const ECONOMIZER_PRESENT = 'EconomizerPresent';
export const SPACE_HUMIDIFICATION_CONTROL = 'SpaceHumidificationControl';
export const SPACE_PRESSURE_CONTROL = 'SpacePressureControl';
export const SPACE_TEMPERATURE_CONTROL = 'SpaceTemperatureControl';
export const SUPPLY_AIR_TEMPERATURE_CONTROL = 'SupplyAirTemperatureControl';
export const SUPPLY_FAN_TYPE = 'SupplyFanType';
export const UNIT_TYPE_PROFILE = 'UnitTypeProfile';
export const UNIT_TYPE_TRANE = 'UnitTypeTrane';
export const COOL_TYPE = 'CoolType';
export const NUMBER_OF_COMPRESSORS = 'NumberOfCompressors';
export const OUT_DOOR_AIR_DAMPER_CAPACITY = 'OutdoorAirDamperCapacity';
export const SINGLE_ZONE_VAV = 'SingleZoneVAV';
export const DEFAULT_UNIT_TYPE = 'DefaultUnitType';

export const FILTER_CHECK = 'No';

export const AHU_FILTER_CONFIG_OBJ_CHART = {
	[SPACE_DEHUMIDIFICATION_CONTROL]: ['Space Humidity Control'],
	[SPACE_HUMIDIFICATION_CONTROL]: ['Space Humidity Control'],
	[SPACE_PRESSURE_CONTROL]: ['Space Pressure Control'],
	[SUPPLY_AIR_PRESSURE_CONTROL]: ['Supply Air Pressure Control'],
	[SUPPLY_AIR_TEMPERATURE_CONTROL]: ['Supply Air Temperature Control'],
	[SINGLE_ZONE_VAV]: ['Single Zone VAV'],
};
